import { SagaIterator } from 'redux-saga';

import { ObjectSerialized } from 'src/common/types';
import { apiClient } from 'src/common/client';
import { SignaturePayload, SignatureId, StampId } from 'src/models/signature';

export function fetchSignaturesApi(): SagaIterator<ObjectSerialized | null> {
  return apiClient.get('/signature');
}

export function createSignatureApi(payload: SignaturePayload): SagaIterator {
  const data = {
    data: {
      attributes: payload,
    },
  };
  return apiClient.post('/signature', data);
}

export function updateSignatureApi(
  signatureId: SignatureId,
  payload: SignaturePayload,
): SagaIterator {
  return apiClient.put(`/signature/${signatureId}/edit`, {
    data: {
      attributes: payload,
    },
  });
}

export function uploadSignatureApi(file: File, signatureId: SignatureId): SagaIterator {
  const data = new FormData();
  data.append('file', file);

  return apiClient.post(`/signature/${signatureId}/upload`, data);
}

export function uploadSignatureStampApi(file: File): SagaIterator<ObjectSerialized | null> {
  const data = new FormData();
  data.append('file', file);

  return apiClient.post('/signature/stamp', data);
}

export function setDefaultSignatureApi(signatureId: SignatureId): SagaIterator {
  return apiClient.put(`/signature/${signatureId}/setAsDefault`);
}

export function updateDefaultSignatureStampApi(
  signatureId: SignatureId,
  stampId: StampId,
): SagaIterator {
  return apiClient.put(`/signature/${signatureId}/stamp/${stampId}/setAsDefault`, { data: {} });
}
