import React, { FC } from 'react';

import { DynamicSettingsContextProviderForList } from './providers/DynamicSettingsContextProviderForList';
import { SidebarNodeContextProvider } from './providers/SidebarNodeContextProvider';
import { TemplateListPageWithoutContext } from './TemplateListPageWithoutContext';

export const TemplateStandardListPage: FC = () => (
  <SidebarNodeContextProvider>
    <DynamicSettingsContextProviderForList>
      <TemplateListPageWithoutContext />
    </DynamicSettingsContextProviderForList>
  </SidebarNodeContextProvider>
);
