import React from 'react';
import { Box } from '@theme-ui/components';

import { Header } from './Header';
import { Footer } from './Footer';

const styles = {
  page: {
    minWidth: '1200px',
    '@media (max-width: 600px)': {
      minWidth: '320px',
    },
  },

  article: {
    color: '#222',
    fontSize: '16px',
    lineHeight: 1.4,
    px: '32px',
    pt: '96px',
    pb: '72px',
    h1: {
      fontSize: '34px',
      fontStretch: 'condensed',
      lineHeight: 1.2,
    },
    a: {
      color: '#2e92f9',
    },
    '@media (max-width: 600px)': {
      fontSize: '14px',
      px: '20px',
      pt: '60px',
      pb: '36px',
      h1: {
        fontSize: '30px',
      },
    },
  },
};

export const TinyCabinTermsPage = (): JSX.Element => (
  <Box sx={styles.page}>
    <Header />
    <Box sx={styles.article} as="article">
      <h1>ShelterZoom Tiny Cabin Raffle Rules</h1>
      <p>
        This raffle is organized and administered by ShelterZoom Corp., a Delaware Corporation with
        an office address of{' '}
        <em>
          14 Wall Street, 20<sup>th</sup> Floor, New York, NY 10005, USA
        </em>
        . We can be reached by contacting us at <em>+1833-710-9666</em>, or sending a message
        through our e-mail address{' '}
        <a href="mailto:info@shelterzoom.com" target="_blank" rel="noopener noreferrer">
          info@shelterzoom.com
        </a>
        .
      </p>
      <p>
        The Rules and Regulations of the ShelterZoom Tiny Cabin Raffle are set forth below. By
        participating in the raffle, the participant agrees to be bound by these rules and
        regulations. The IRS has taken the position that amounts paid for chances in raffles,
        lotteries or similar drawings for valuable prizes are not gifts, and consequently do not
        qualify as deductible charitable contribution.
      </p>
      <p>
        <b>
          BY ENTERING THIS RAFFLE, PARTICIPANTS ACCEPT AND AGREE (1) TO BE BOUND BY ALL THE RULES,
          LIMITATIONS AND RESTRICTIONS SET FORTH HERE AND (2) THAT THEIR NAMES AND/OR LIKENESSES MAY
          BE DISCLOSED TO AND USED BY THE NEWS MEDIA AND MAY OTHERWISE BE USED BY SHELTERZOOM FOR
          PUBLICITY PURPOSES AND BE PUBLISHED IN AREA NEWSPAPERS AND ANNOUNCED ON THE SHELTERZOOM
          WEBSITE, SOCIAL MEDIA PAGES AND NEWSLETTER FOR THE SHELTERZOOM TINY CABIN RAFFLE. OTHER
          RULES AND REGULATIONS MAY APPLY. PLEASE CONTACT SHELTERZOOM IF YOU HAVE QUESTIONS.
          SHELTERZOOM’S INTERPRETATION AND APPLICATION OF THE RULES AND REGULATIONS SHALL BE FINAL.
          BY ENTERING THIS RAFFLE, EACH PARTICIPANT RELEASES SHELTERZOOM, ITS DIRECTORS, OFFICERS,
          EMPLOYEES AND AGENTS FROM ANY AND ALL LIABILITY FOR INJURIES, LOSSES OR DAMAGES OF ANY
          KIND CAUSED BY PARTICIPATING IN THE RAFFLE OR WINNING ANY PRIZE OR RESULTING FROM
          ACCEPTANCE, POSSESSION, USE OR MISUSE OF ANY PRIZE, AND THE WINNER AGREES TO INDEMNIFY AND
          HOLD SHELTERZOOM, INCLUDING ITS DIRECTORS, OFFICERS, EMPLOYEES AND AGENTS OR
          REPRESENTATIVES HARMLESS FROM ANY AND ALL LOSSES, DAMAGES, RIGHTS, CLAIMS AND ACTIONS OF
          ANY KIND RISING IN CONNECTION WITH OR AS A RESULT OF PARTICIPATING IN THE RAFFLE OR THE
          WINNER’S ACCEPTANCE OR USE OF ANY PRIZE.
        </b>
      </p>
      <p>
        The Tiny Cabin Winner assumes all fees, local, state and federal taxes which must be paid
        when the Cabin is transferred to the winner. ShelterZoom takes no responsibility for any tax
        liabilities. In addition the winner will be responsible for all representations or
        warranties regarding zoning requirements or other government or non-government conditions,
        restrictions or other limits on the placement or use of the ShelterZoom Tiny Cabin.
      </p>
      <h3>1. ENTRY REQUIREMENTS</h3>
      <p>
        Participants are automatically entered in this raffle by signing up through the DocuWalk
        website.
      </p>
      <p>
        By signing up you will be providing on our website your name, address, phone number and
        other contact details. There are no other options of submitting a raffle entry except as
        described above. Entry is free, though you may incur fees in connection with using an
        internet connection to sign up.
      </p>
      <p>
        One name and one mailing address are permitted per raffle entry. Only one eligible person
        may be entered in the raffle per sign up. If the name of more than one person is submitted
        and that raffle entry is selected as a winning entry, then the person named first will be
        deemed the holder of record of that ticket and declared the winner.
      </p>
      <p>
        ShelterZoom reserves the right to reject any offer that violates the terms and conditions
        set forth under these rules. All defective sign-ups e will be immediately disqualified by
        ShelterZoom. ShelterZoom assumes no responsibility for lost, late, misdirected or
        non-delivered mail or fax messages, or any other failure to receive orders or deliver
        receipts prior to the drawing deadlines.
      </p>
      <p>
        <b>
          IN NO EVENT SHALL SHELTERZOOM, ITS DIRECTORS, OFFICERS, EMPLOYEES, AGENTS OR
          REPRESENTATIVES BE LIABLE TO ANY PARTY FOR ANY LOSS OR INJURIES TO EARNINGS, PROFITS OR
          GOODWILL, OR FOR ANY INCIDENTAL, SPECIAL, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY PERSON
          OR ENTITY WHETHER ARISING IN CONTRACT, TORT OR OTHERWISE, EVEN IF ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES, IN CONNECTION WITH THIS RAFFLE.
        </b>
      </p>
      <h3>2. ELIGIBILITY</h3>
      <p>
        Anyone 18 years of age or older and a U.S. resident physically residing in the within the 48
        contiguous states area of the United States may enter. ShelterZoom officers, authorized
        agents and employees thereof, consultants, attorneys, independent accountant firm, and their
        spouses and children living in the same household are excluded from participating and are
        not eligible to win a prize. New York laws and regulations apply. An affidavit of
        eligibility may be required from prize winners.
      </p>
      <h3>3. PRIZE</h3>
      <p>
        The prize consists of one cabin kit as it is currently displayed on ShelterZoom’s website,
        or a similar substitute model selected at the sole discretion of ShelterZoom, delivered to a
        suitable location within the 48 contiguous states area of the United States. The prize will
        not be delivered in assembled form, and assembly is not included in the prize. Land, taxes,
        and permits are also not included and are the responsibility of the prize winner.
      </p>
      <h3>4. CLOSING DATE</h3>
      <p>
        The closing date for the raffle is <u>June 30, 2020 at 9:00 pm EST</u>. Offers made after
        this date will not entitle the offeror to be entered into the draw.
      </p>
      <h3>5. SELECTION OF WINNERS</h3>
      <p>
        The winning entry will be selected from all offers received by the closing date. The person
        named on the offer will be the only person entitled to the prize. Winners need not be
        present to win. Drawing will be held under the supervision of an independent raffle auditor.
        Winners will be notified according to the contact information provided to ShelterZoom at the
        time of sign up. The winner will be posted in the ShelterZoom website, social media pages
        and newsletters.
      </p>
      <h3>6. CLAIMING OF PRIZE</h3>
      <p>
        ShelterZoom shall contact the winner and will be responsible in the transport of the grand
        prize to the winner. ShelterZoom shall not be responsible for the assembly of the cabin.
        ShelterZoom is only responsible for the reasonable costs of delivering the cabin kit to the
        raffle winner.
      </p>
      <p>
        ShelterZoom’s decisions in all matters relating to the Raffle are final and binding. The
        winner will be contacted by telephone or email. In the event that we cannot contact the
        winner within 10 working days after the draw, the prize will be awarded to a new winner.
      </p>
      <p>The prize is non-transferable.</p>
      <h3>7. PRIVACY</h3>
      <p>
        ShelterZoom respects, and is committed to protecting your privacy. Our Privacy Policy is
        incorporated into these rules. Please see ShelterZoom’s Privacy Policy for more information.
      </p>
      <p>
        Notwithstanding the applicability of ShelterZoom’s Privacy Policy, except where prohibited,
        participation in the raffle constitutes the winner’s consent to ShelterZoom’s use of
        winner’s name, likeness, photograph, voice, opinion, and/or hometown and state for
        promotional purposes in any media, worldwide, without further payment or consideration. The
        winner’s name will be announced publicly unless the winner requests in writing that they
        wish their name to be kept private.
      </p>
      <h3>8. GENERAL TERMS AND CONDITIONS</h3>
      <p>
        <b>
          The Tiny Cabin is provided “AS IS, WHERE IS, AND WITH ALL FAULTS”. SHELTERZOOM DOES NOT
          PROVIDE ANY GUARANTEE OR WARRANTY, EXPRESSED OR IMPLIED, IN CONNECTION WITH THE CABIN AND
          ACCEPTS NO LIABILITY OR RESPONSIBILITY REGARDING THE CONSTRUCTION OR CONDITION OF THE
          CABIN. SHELTERZOOM DOES NOT WARRANT THAT THE CABIN IS OF MERCANTILE QUALITY OR THAT IT CAN
          BE USED FOR ANY PARTICULAR PURPOSE. NO EXPRESS WARRANTIES ARE GIVEN AND NO AFFIRMATION OF
          SHELTERZOOM BY WORDS AND/OR ACTIONS WILL CONSTITUTE.
        </b>
      </p>
      <p>
        ShelterZoom reserves the right to cancel, suspend and/or modify the Raffle, or any part of
        it, if any fraud, or other factors beyond the ShelterZoom’s reasonable control impairs the
        integrity or proper functioning of the Raffle, as determined by ShelterZoom in its sole
        discretion. In such event, ShelterZoom reserves the right to award the prize at random from
        among eligible entries received up to the time of the impairment. ShelterZoom reserves the
        right, in its sole discretion to disqualify any individual, without compensation, it finds
        to be tampering with the entry process or the operation of the Raffle or any other promotion
        or in an unsportsmanlike or disruptive manner. Any attempt by any person to deliberately
        undermine the legitimate operation of the Raffle may be a violation of criminal and civil
        law, and, should such an attempt be made, ShelterZoom reserves the right to seek damages
        from any such person to the fullest extent permitted by law. ShelterZoom’s failure to
        enforce any term of these Rules shall not constitute a waiver of that provision.
      </p>
      <p>
        <b>
          YOU AND SHELTERZOOM AGREE TO GIVE UP ANY RIGHTS TO LITIGATE CLAIMS IN A COURT OR BEFORE A
          JURY OR TO PARTICIPATE IN A CLASS ACTION OR REPRESENTATIVE ACTION WITH RESPECT TO A CLAIM.
          OTHER RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO COURT, SUCH AS ACCESS TO DISCOVERY, ALSO
          MAY BE UNAVAILABLE OR LIMITED IN ARBITRATION.
        </b>
      </p>
      <p>
        Any dispute between you and ShelterZoom and its agents, employees, officers, directors,
        principals, successors, assigns, subsidiaries or affiliates (collectively for purposes of
        this section, “ShelterZoom”) arising from or relating to these Terms and their
        interpretation or the breach, termination or validity thereof, the relationships which
        result from these Terms, including disputes about the validity, scope or enforceability of
        this arbitration provision (collectively, “Covered Disputes”) will be settled by binding
        arbitration administered by the American Arbitration Association (AAA) as follows:
      </p>
      <p>
        Arbitration shall be conducted under the AAA’s Consumer Arbitration Rules in effect on the
        date of initiation of arbitration (a “Consumer Arbitration”). If an in-person hearing is
        required, the parties will attempt to agree on a location reasonably convenient to both
        parties with due consideration of their ability to travel and other pertinent circumstances.
        If the parties are unable to agree on a location, the determination will be made as provided
        in the Consumer Arbitration Rules. If a claim is within the jurisdiction of a small claims
        court, either party may choose to take the claim to that court instead of arbitration, as
        set out in the Consumer Arbitration Rules, provided that the matter remains in small claims
        court and proceeds only on an individual (not as or on behalf of a class, as a
        representative or as a private attorney general) basis. Additional information about the
        arbitration process under the AAA’s Consumer Arbitration rules is available at the AAA’s
        website,{' '}
        <a href="https://www.adr.org/consumer" target="_blank" rel="noopener noreferrer">
          https://www.adr.org/consumer
        </a>
        . Payment of all filing, administration and arbitrator fees will be governed by the AAA’s
        rules. For Consumer Arbitrations, if you are able to demonstrate that the costs of
        arbitration will be prohibitive as compared to the costs of litigation, ShelterZoom will pay
        as much of the filing or any other fees as the arbitrator deems necessary to prevent the
        arbitration from being cost-prohibitive for you. If the arbitrator determines the claim(s)
        you assert in the arbitration are frivolous, you agree to reimburse ShelterZoom for all fees
        associated with the arbitration that ShelterZoom paid on your behalf, which you otherwise
        would have been obligated to pay under the AAA’s rules.
      </p>
      <p>
        Prior to initiating any arbitration, the initiating party will give the other party at least
        60-days’ advance written notice of its intent to file for arbitration. ShelterZoom will
        provide such notice by email to your email address on file with ShelterZoom and you must
        provide such notice by completing the form on the ShelterZoom website{' '}
        <a href="https://www.shelterzoom.com/contact" target="_blank" rel="noopener noreferrer">
          https://www.shelterzoom.com/contact
        </a>{' '}
        with “Legal Dispute” appearing in the subject line. During such 60-day notice period, the
        parties will endeavor to settle amicably by mutual discussions any Covered Disputes. Failing
        such amicable settlement and expiration of the notice period, either party may initiate
        arbitration.
      </p>
      <p>
        The arbitrator will have the power to grant whatever relief would be available in court
        under law or in equity and any award of the arbitrator(s) will be final and binding on each
        of the parties and may be entered as a judgment in any court of competent jurisdiction. The
        arbitrator will not, however, have the power to award punitive or exemplary damages, the
        right to which each party hereby waives, and the arbitrator will apply applicable law and
        the provisions of these Terms and the failure to do so will be deemed an excess of arbitral
        authority and grounds for judicial review. ShelterZoom and you agree that any Covered
        Dispute will be submitted to arbitration on an individual basis only. Neither ShelterZoom
        nor you are entitled to arbitrate any Covered Dispute as a class, representative or private
        attorney general action and the arbitrator(s) will have no authority to proceed on a class,
        representative or private attorney general basis. If any provision of the agreement to
        arbitrate in this section is found unenforceable, the unenforceable provision will be
        severed and the remaining arbitration terms will be enforced (but in no case will there be a
        class, representative or private attorney general arbitration). Regardless of any statute or
        law to the contrary, notice on any claim arising from or related to these Terms must be made
        within one (1) year after such claim arose or be forever barred. For purposes of this
        section, these Terms and related transactions will be subject to and governed by the Federal
        Arbitration Act, 9 U.S.C. sec. 1-16 (FAA).
      </p>
    </Box>
    <Footer />
  </Box>
);
