import React, { ReactNode } from 'react';

interface Props {
  children?: ReactNode[];
  template?: boolean;
}

export const SearchCardGrid: React.FC<Props> = ({ children }) => (
  <div className="c-card">{children}</div>
);
