import React, { FC, useEffect } from 'react';
import { initialize, InjectedFormProps, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isUndefined } from 'lodash';

import { validateUserForm } from 'src/utils/validate';
import { UserForm } from 'src/v2/features/profile/profileSidebar/UserForm';
import { InviteFormProps } from 'src/v2/features/profile/profileSidebar/types';
import { getCurrentOrganizationMembers } from 'src/shared/organization';
import { RootState } from 'src/app/types';
import { book } from 'src/app/book';
import { EditUserInOrganizationActionPayload } from 'src/v2/boundary/actionPayload/organization';
import { updateInviteUser } from 'src/v2/features/organization';
import { getIsLoading } from 'src/shared/organization';

interface Props {
  userId: string;
}

const FORM_ID = 'updateUser';

export const EditUserFormView: FC<InjectedFormProps<InviteFormProps, Props> & Props> = (props) => {
  const { reset, userId } = props;
  const dispatch = useDispatch();
  const authUser = useSelector((store: RootState) => store.auth);
  const navigate = useNavigate();
  const orgUsers = useSelector(getCurrentOrganizationMembers);
  const found = !isUndefined(orgUsers) ? orgUsers.find(({ id }) => id === userId) : undefined;
  const isLoading = useSelector(getIsLoading);

  const handleFormSubmit = (values: InviteFormProps, ...other): void => {
    const invitingUser: EditUserInOrganizationActionPayload = {
      organizationId: authUser.companyId || '',
      userId,
      roleId: values.permission,
      functionalRole: values.role,
      user: {
        phone: values.phone,
        email: values.email,
        profile: {
          firstName: values.firstName,
          lastName: values.lastName,
        },
      },
    };

    dispatch(updateInviteUser(invitingUser));
    reset();
    navigate(book.profile.users.generatePath());
  };

  useEffect(() => {
    if (!isUndefined(found)) {
      dispatch(initialize(FORM_ID, found));
    }
  }, [dispatch, found]);

  return (
    <UserForm<Props>
      handleFormSubmit={handleFormSubmit}
      actionLabel={'UPDATE USER'}
      fieldDisabled={true}
      isLoading={isLoading}
      {...props}
    />
  );
};

export const EditUserForm = reduxForm<InviteFormProps, Props>({
  validate: validateUserForm,
  form: FORM_ID,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(EditUserFormView);
