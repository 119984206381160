import { DocumentContextMenu } from 'src/v2/features/document/DocumentContextMenu';
import { ContractContextMenu } from 'src/v2/features/contract/ContractContextMenu';
import { TemplateContextMenu } from 'src/v2/features/template/TemplateContextMenu';
import { SystemTemplateContextMenu } from 'src/v2/features/template/SystemTemplateContextMenu';
import { DocumentFolderContextMenu } from 'src/v2/features/document/DocumentFolderContextMenu';
import { ContractFolderContextMenu } from 'src/v2/features/contract/ContractFolderContextMenu';
import { TemplateFolderContextMenu } from 'src/v2/features/template/TemplateFolderContextMenu';
import { DocumentProjectContextMenu } from 'src/v2/features/document/DocumentProjectContextMenu';
import { ContractProjectContextMenu } from 'src/v2/features/contract/ContractProjectContextMenu';
import { ContextMenuBook, ContextMenuRegistryType } from 'src/app/types';

export const ContextMenuRegistry: ContextMenuRegistryType = Object.freeze({
  [ContextMenuBook.Document]: DocumentContextMenu,
  [ContextMenuBook.Contract]: ContractContextMenu,
  [ContextMenuBook.Template]: TemplateContextMenu,
  [ContextMenuBook.SystemTemplate]: SystemTemplateContextMenu,
  [ContextMenuBook.DocumentFolder]: DocumentFolderContextMenu,
  [ContextMenuBook.ContractFolder]: ContractFolderContextMenu,
  [ContextMenuBook.TemplateFolder]: TemplateFolderContextMenu,
  [ContextMenuBook.DocumentProject]: DocumentProjectContextMenu,
  [ContextMenuBook.ContractProject]: ContractProjectContextMenu,
});
