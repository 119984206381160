import axios, { AxiosRequestConfig } from 'axios';

import { ObjectSerialized, DataModificationPayload } from 'src/common/types';
import fileDownload from 'js-file-download';
import { SagaIterator } from 'redux-saga';

interface Get {
  <T = ObjectSerialized, R = T>(url: string, config?: AxiosRequestConfig): Promise<R>;
}

interface Post {
  <T = ObjectSerialized>(
    url: string,
    data?: DataModificationPayload | FormData | any,
    config?: AxiosRequestConfig,
  ): Promise<T>;
}

export interface Put {
  <T = ObjectSerialized | null>(
    url: string,
    data: DataModificationPayload,
    config?: AxiosRequestConfig,
  ): Promise<T>;
}

interface Download {
  (url: string, name: string, config?: AxiosRequestConfig): Promise<void> | SagaIterator;
}

/** @deprecated */
export interface PromisedClient {
  get: Get;
  post: Post;
  put: Put;
  download: Download;
}

const get: Get = async (url, config = {}) => {
  const resp = await axios.request({ method: 'get', url, ...config });
  return resp.data;
};

const post: Post = async (url, data, config = {}) => {
  const resp = await axios.request({ method: 'post', url, data, ...config });
  return resp.data;
};

const put: Put = async (url, data, config = {}) => {
  const resp = await axios.request({ method: 'put', url, data, ...config });
  return resp.data;
};

export const download: Download = async (url, name, config) => {
  const data = await get(url, {
    ...config,
    responseType: 'blob',
  });

  // @ts-ignore
  fileDownload(data, name);
};

export const basePromisedClientFactory = (
  url: string,
  defaultConfigGenerator: (config: AxiosRequestConfig) => AxiosRequestConfig,
): PromisedClient => {
  return {
    get: ((url, optionalConfig = {}) => get(url, defaultConfigGenerator(optionalConfig))) as Get,
    post: ((url, data, optionalConfig = {}) =>
      post(url, data, defaultConfigGenerator(optionalConfig))) as Post,
    put: ((url, data, optionalConfig = {}) =>
      put(url, data, defaultConfigGenerator(optionalConfig))) as Put,
    download: ((url, name, optionalConfig = {}) =>
      download(url, name, defaultConfigGenerator(optionalConfig))) as Download,
  };
};
