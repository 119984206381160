import React, { FC, useState, useRef, useCallback, useMemo } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { isNil, isNull } from 'lodash';

import { RotateRight } from 'src/v2/icons/RotateRight/RotateRight';
import { RotateLeft } from 'src/v2/icons/RotateLeft/RotateLeft';
import { Crop } from 'src/v2/icons/Crop/Crop';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

export interface ImageEditorProps {
  src: string | null;
  style?: React.CSSProperties | undefined;
  aspectRatio?: number | undefined;
  className?: string;
  onSave: (dataURL: string) => void;
  onTransform?: (canvas: HTMLCanvasElement) => HTMLCanvasElement;
}

const defaultTransformation = (canvas: HTMLCanvasElement) => canvas;

const ROTATE_ANGLE_STEP = 15;
const ZOOM_STEP = 0.1;
const POSSIBLE_ZOOM_STEPS = 10;

export const ImageEditor: FC<ImageEditorProps> = ({
  src,
  style,
  aspectRatio,
  onSave,
  className = '',
  onTransform = defaultTransformation,
}) => {
  const [zoom, setZoom] = useState<number>(0);
  const [minZoom, setMinZoom] = useState<number>(0);
  const [maxZoom, setMaxZoom] = useState<number>(0);
  const cropperRef = useRef<HTMLImageElement>(null);

  const mimeType = useMemo(() => {
    if (isNull(src)) return '';

    const arr = src.split(',');
    const searchMime = arr[0].match(/:(.*?);/);
    return searchMime ? searchMime[1] : 'image/png';
  }, [src]);

  const getCropper = useCallback((): Cropper | null => {
    const imageElement: any = cropperRef ? cropperRef.current : null;
    const cropper: Cropper = imageElement ? imageElement.cropper : null;

    return cropper;
  }, []);

  const setZoomRanges = useCallback((ratio: number): void => {
    const minZoomValue = ratio - ZOOM_STEP * POSSIBLE_ZOOM_STEPS;
    const maxZoomValue = ratio + ZOOM_STEP * POSSIBLE_ZOOM_STEPS;
    setMinZoom(minZoomValue);
    setMaxZoom(maxZoomValue);
    setZoom(ratio);
  }, []);

  const onReady = useCallback(() => {
    const cropper = getCropper();
    if (!isNil(cropper)) {
      const canvasData = cropper.getCanvasData();
      setZoomRanges(canvasData.width / canvasData.naturalWidth);
    }
  }, [getCropper, setZoomRanges]);

  const getImageDataURL = useCallback((): string => {
    const cropper = getCropper();
    if (isNil(cropper)) return '';

    const croppedCanvas = cropper.getCroppedCanvas({
      fillColor: 'transparent',
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high',
    });

    const dataURL = onTransform(croppedCanvas).toDataURL(mimeType);

    return dataURL;
  }, [getCropper, onTransform, mimeType]);

  const onZoom = useCallback(
    (currentZoom: number) => {
      const cropper = getCropper();
      if (!isNil(cropper)) {
        cropper.zoom(currentZoom - zoom);
        setZoom(currentZoom);
      }
    },
    [getCropper, zoom],
  );

  const rotate = useCallback(
    (isLeft = false): void => {
      const cropper = getCropper();
      if (!isNil(cropper)) {
        const rotateAngle = isLeft ? -1 * ROTATE_ANGLE_STEP : ROTATE_ANGLE_STEP;
        cropper.rotate(rotateAngle);
      }
    },
    [getCropper],
  );

  const handleRotateRight = useCallback((): void => rotate(), [rotate]);
  const handleRotateLeft = useCallback((): void => rotate(true), [rotate]);

  const handleSliderChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const currentZoom = parseFloat(event.target.value);
      onZoom(currentZoom);
    },
    [onZoom],
  );

  const handleSave = useCallback((): void => onSave(getImageDataURL()), [getImageDataURL, onSave]);

  return isNil(src) ? null : (
    <div className="c-signature__edit">
      <div className="c-signature__edit--img">
        <Cropper
          src={src}
          className={className}
          style={style}
          initialAspectRatio={1}
          aspectRatio={aspectRatio}
          zoomOnWheel={false}
          guides={false}
          ready={onReady}
          ref={cropperRef}
        />
      </div>
      <div className="c-signature__edit--action">
        <button className="c-signature__edit--button" type="button" onClick={handleRotateRight}>
          <span className="c-signature__edit--icon">
            <RotateRight />
            <span className="c-signature__edit--square"></span>
          </span>
        </button>
        <button className="c-signature__edit--button" type="button" onClick={handleRotateLeft}>
          <span className="c-signature__edit--icon">
            <RotateLeft />
            <span className="c-signature__edit--square"></span>
          </span>
        </button>
      </div>
      <div className="c-signature__edit--crop">
        <button className="c-signature__edit--button" type="button">
          <span className="c-signature__edit--icon">
            <Crop />
          </span>
        </button>
        <div className="c-signature__edit--range">
          <input
            type="range"
            min={minZoom}
            max={maxZoom}
            step={ZOOM_STEP}
            value={zoom}
            onChange={handleSliderChange}
          />
        </div>
      </div>
      <button className="c-button" type="submit" onClick={handleSave}>
        <span className="c-button__inner">{i18n(translationKeys.buttons.save)}</span>
      </button>
    </div>
  );
};
