import { ReactElement, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { normalizeNumbers } from 'src/utils/normalize';
import { PhoneVerificationLight } from 'src/v2/features/signUp/PhoneVerificationLight';
import { SignUpStepComponent, useSignUpProps } from 'src/v2/features/signUp/pages/hooks';
import { ResendSmsPayload } from 'src/v2/features/signUp/types';
import { verifyPhoneAction, resendSmsAsync } from 'src/v2/features/signUp/store';

export const usePhoneVerificationLightComponent = (): SignUpStepComponent => {
  const { step } = useSignUpProps();
  const dispatch = useDispatch();

  const submitVerifyCode = useCallback(
    (values: any) => {
      dispatch(
        verifyPhoneAction({
          ...values,
          phone: `+${normalizeNumbers(values.phone)}`,
        }),
      );
    },
    [dispatch],
  );

  const submitResendSms = useCallback(
    (payload: ResendSmsPayload): void => {
      dispatch(resendSmsAsync(payload));
    },
    [dispatch],
  );

  const props = {
    verifyCode: submitVerifyCode,
    resendSms: submitResendSms,
  };

  return [(): ReactElement => <PhoneVerificationLight {...props} />, { step }];
};
