import React, { useState, useEffect, useCallback } from 'react';
import { Flex, Link } from '@theme-ui/components';
import { formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
import OtpInput from 'react-otp-input';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { validateAuthForm } from 'src/utils/validate';
import { ProfileFormData } from 'src/v2/features/profile/types';
import { getProfileFormDataFromProfile } from 'src/v2/features/profile';
import { Button } from 'src/v2/features/button';
import { CForm, CFormRow } from 'src/v2/components/CForm';
import { ResendSmsPayload } from 'src/v2/features/signUp/types';
import { RootState } from 'src/app/types';
import { EditPhone } from 'src/v2/features/signUp/components/EditPhone';
import { Capture } from '../../../components/Сapture/Capture';
import { isNull } from 'lodash';
import { useIsNoCaptureMode } from '../../../../utils/hooks';

type Props = {
  verifyCode: (props: Values) => void;
  resendSms: (payload: ResendSmsPayload) => void;
};

export type Values = {
  code: string;
  email: string;
  phone: string;
};

type PhoneVerificationLightFormProps = Props & InjectedFormProps<Values, Props>;

const styles = {
  otpContainer: {
    justifyContent: 'left',
  },
  link: {
    cursor: 'pointer',
  },
};

export const Form: React.FC<PhoneVerificationLightFormProps> = ({
  handleSubmit,
  verifyCode,
  resendSms,
  submitting,
}) => {
  const { email: emailFromSignUpValue, phone: phoneFromSignUpValue } = useSelector(
    (state: RootState) => formValueSelector(verifyPhoneLightFormName)(state, 'phone', 'email'),
  );
  const profile = useSelector(getProfileFormDataFromProfile) as ProfileFormData;
  const phoneFromProfile = profile ? profile.phoneNumber : null;
  const emailFromProfile = profile ? profile.email : null;
  const phoneValue = phoneFromSignUpValue || phoneFromProfile || '';
  const emailValue = emailFromProfile || emailFromSignUpValue;
  const [phoneNumber, setPhoneNumber] = useState(phoneValue);
  const [phoneNumberChanged, setPhoneNumberChanged] = useState(false);
  const [smsSent, setSmsSent] = useState(false);
  const [otpCode, setOtpCode] = useState('');
  const [captureToken, setCaptureToken] = useState<string | null>(null);
  const [showCapture, setShowCapture] = useState<boolean>(false);
  const invalid = otpCode.length !== 6;
  const isNoCaptureMode = useIsNoCaptureMode();

  useEffect(() => {
    setPhoneNumber(phoneValue);
  }, [phoneValue]);

  const handleResendSms = useCallback((): void => {
    setOtpCode('');
    setShowCapture(false);
    const payload: ResendSmsPayload = {
      email: emailValue,
      phone: phoneNumber,
      isSignup: true,
      withUpdatePhone: phoneNumberChanged,
      captureToken,
    };
    resendSms(payload);
    setSmsSent(true);
    setPhoneNumberChanged(false);
    setCaptureToken(null);
  }, [emailValue, phoneNumber, phoneNumberChanged, resendSms, captureToken]);

  const submitForm = (): void => {
    verifyCode({
      code: otpCode,
      email: emailValue,
      phone: phoneNumber,
    });
  };

  const handleChange = useCallback((number: string): void => {
    setSmsSent(false);
    setPhoneNumberChanged(true);
    setPhoneNumber(number);
  }, []);

  const handleSubmitCapture = (token: string) => {
    setCaptureToken(token);
  };

  const handleShowCapture = () => {
    setShowCapture(true);
  };

  return (
    <div className="c-modal__row c-modal--mt-24">
      <CForm className="c-form c-form--grow" onSubmit={handleSubmit(submitForm)} noValidate>
        {smsSent && (
          <div className="c-verification__title c-verification--gray">
            {i18n(translationKeys.forms.phoneVerification.successMessage, { phoneNumber })}
          </div>
        )}
        <EditPhone isTitled={false} onChange={handleChange} phone={phoneNumber} />
        <CFormRow>
          <div className="c-form__column">
            <Flex data-type="otp-inputs" sx={styles.otpContainer}>
              <OtpInput
                containerStyle="c-verification__form c-verification--mt-0"
                numInputs={6}
                errorStyle="error"
                value={otpCode}
                onChange={(val: string): void => setOtpCode(val)}
                shouldAutoFocus
              />
            </Flex>
          </div>
        </CFormRow>
        <CFormRow>
          <Button
            className="c-button c-button--fs-12 c-button--w-full h-uppercase"
            type="submit"
            disabled={invalid || submitting}
          >
            {i18n(translationKeys.buttons.verify)}
          </Button>
        </CFormRow>
        {!isNoCaptureMode && (
          <Capture
            onChange={handleSubmitCapture}
            isShow={showCapture}
            resetCapture={!showCapture}
          />
        )}
        <CFormRow className="c-form--mt-32">
          <div className="c-form__column">
            {i18n(translationKeys.forms.phoneVerificationQR.notrecievedCode)}{' '}
            <Link
              data-type="readTerms-link"
              href={null}
              sx={styles.link}
              className="c-checkbox__link"
              target="_blank"
              onClick={
                isNull(captureToken) && !isNoCaptureMode ? handleShowCapture : handleResendSms
              }
            >
              {i18n(translationKeys.forms.phoneVerificationQR.resend)}
            </Link>
          </div>
        </CFormRow>
      </CForm>
    </div>
  );
};

export const verifyPhoneLightFormName = 'verifyPhoneLight';
export const PhoneVerificationLightForm = reduxForm<Values, Props>({
  form: verifyPhoneLightFormName,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateAuthForm,
})(Form);
