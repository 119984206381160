import React from 'react';

import { DynamicSettingsContext } from 'src/v2/modules/layout';

import { SearchHeader } from './SearchHeader';
import { DynamicSettings } from '../../modules/layout/types';
import { useNotificationTogglePush } from '../document/DocumentSidebar';

export const DynamicSettingsContextProvider: React.FC = ({ children }) => {
  const toggleNotifications = useNotificationTogglePush();

  // @TODO
  const ctx: DynamicSettings = {
    headerChildren: <SearchHeader />,
    onBellClick: toggleNotifications,
  };

  return <DynamicSettingsContext.Provider value={ctx}>{children}</DynamicSettingsContext.Provider>;
};
