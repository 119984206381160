import { EventData } from 'src/app/eventBus';
import { CompleteObject } from 'src/common/types';
import { LanguageCode } from 'src/instruments/languages';

export type StoredUser = {
  token: string;
  userId: string;
  companyId?: string;
};

export interface StorageUser extends CompleteObject {
  id: string;
  profile: StorageProfileData[];
}

export interface StorageProfileData {
  id: string;
  firstName: string;
  address: ProfileAddress[];
  lastName: string;
  avatar: Avatar[];
  dateOfBirth: string;
  jobTitle: string;
  about: string;
}

export interface ProfileData {
  address: ProfileAddress;
  firstName: string;
  lastName: string;
  dateOfBirth?: Date;
  avatar?: string;
  avatarData?: Avatar;
  signature?: string;
  companyName?: string;
  signatureName?: string;
  companyTitle?: string;
  about?: string;
  language?: LanguageCode;
}

export interface User {
  phoneVerified: boolean;
  username: string;
  userId: string;
  email: string;
  phone: string;
  profile: ProfileData;
  id: number;
}

export interface Avatar {
  uriExtraLarge: string;
  uriLarge: string;
  uriMedium: string;
  uriSmall: string;
}

export interface ProfileFormData {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  number?: string;
  address?: string;
  country?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  dateOfBirth?: string | Date;
  about?: string;
  language?: LanguageCode;
}

export interface OrganizationFormData {
  organizationName: string;
  jobTitle: string;
  industry: string;
  contactPerson: string;
  email: string;
  address: string;
  country: string;
  city: string;
  zipCode: string;
  state: string;
  logo: string;
}

export interface ProfileAddress {
  country: string;
  state: string;
  city: string;
  addressLine: string;
  zipCode: string;
}

export enum AvatarSize {
  Small,
  Medium,
  Large,
  ExtraLarge,
}

export enum ProfileEvent {
  RemoveUserFromOrganization,
}

export type ProfileEventData = EventData<ProfileEvent>;

export interface ChangePasswordPayload {
  oldPassword: string;
  newPassword: string;
}
