import React, { FC } from 'react';
import { Box, Flex } from '@theme-ui/components';

import { ConfirmSign } from 'src/v2/components/ConfirmSign';
import { SelectSignature } from 'src/v2/components/SelectSignature';
import { LegalAgreement } from 'src/v2/components/LegalAgreement';
import { SignatureWithStampModel } from 'src/models/signature';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

const styles = {
  signContainer: {
    mt: '20px',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },

  signButton: {
    mr: '14px',
    mb: '10px',
  },
};

interface Props {
  signatures: SignatureWithStampModel[];
  defaultSignatureId: string | null;
  fullName: string;
  showSelectSignature: boolean;
  onSelectSignature: (id: string) => void;
  showSignConfirm: boolean;
  onSignConfirm: () => void;
  onSignCancel: () => void;
  onSign: () => void;
}

export const SignContractButton: FC<Props> = ({
  signatures,
  defaultSignatureId,
  fullName,
  showSignConfirm,
  showSelectSignature,
  onSelectSignature,
  onSignConfirm,
  onSignCancel,
  onSign,
}) => (
  <>
    <Flex sx={styles.signContainer}>
      <Box sx={styles.signButton}>
        <button
          type="submit"
          className="c-button c-button--uppercase c-button--green"
          disabled={showSignConfirm}
          onClick={onSignConfirm}
          data-type="sign-contract-btn"
        >
          <span className="c-button__inner">{i18n(translationKeys.contract.sign)}</span>
        </button>
      </Box>

      {showSelectSignature && (
        <SelectSignature
          signatures={signatures}
          defaultSignatureId={defaultSignatureId}
          fullName={fullName}
          onConfirm={onSelectSignature}
          onCancel={onSignCancel}
        />
      )}

      {showSignConfirm && <ConfirmSign onConfirm={onSign} onClose={onSignCancel} />}
    </Flex>

    {!showSelectSignature && !showSignConfirm && <LegalAgreement />}
  </>
);
