import React, { FC } from 'react';

import { DynamicSettingsContext, HeaderItem } from 'src/v2/modules/layout';
import { DynamicSettings } from 'src/v2/modules/layout/types';

import { useNotificationTogglePush, useToggle9Square, useToggleQRCode } from '../ContractSidebar';
import { ContractQuantumNavigator } from '../ContractQuantumNavigator';
import { useHasQRCodeFeature } from '../../billing';

export const DynamicSettingsContextProviderForShared: FC = ({ children }) => {
  const toggleNotifications = useNotificationTogglePush();
  const toggle9squareSidebar = useToggle9Square();
  const toggleQRCodeSidebar = useToggleQRCode();
  const hasQRCodeFeature = useHasQRCodeFeature();

  const ctx: DynamicSettings = {
    headerChildren: <HeaderItem />,
    onBellClick: toggleNotifications,
    onGridClick: toggle9squareSidebar,
    onQRCodeClick: toggleQRCodeSidebar,
    navigatorChildren: <ContractQuantumNavigator />,
    hasQRCodeFeature,
  };

  return <DynamicSettingsContext.Provider value={ctx}>{children}</DynamicSettingsContext.Provider>;
};
