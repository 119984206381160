import { PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';
import { isNil } from 'lodash';

import { CardData } from 'src/v2/features/sharedEntity/types';
import { DropdownDownload } from 'src/v2/icons/DropdownDownload';
import { DropdownShare } from 'src/v2/icons/DropdownShare';
import { useNavigateToShare } from 'src/v2/features/sharedEntity/EntitySidebar/hooks';
import { ContextMenuItem, ContextMenu, ContextMenuSeparator } from 'src/v2/components/contextMenu';
import { ContextMenuProps } from 'src/v2/features/contextMenu/types';
import { useDownloadAsFile } from 'src/v2/features/sharedEntity/hooks';
import { fetchDocumentInfo } from 'src/v2/features/documentDetails/documentDetailsSlice';

import { useGetEntityById } from './hooks';

interface Props extends ContextMenuProps {
  isAllowedToShareEntity: boolean;
  isAllowedToDownloadEntity: boolean;
}

export const EntityContextMenu = <R,>({
  closeOnClick,
  position,
  payload,
  children,
  participantChildren,
  isAllowedToShareEntity,
  isAllowedToDownloadEntity,
}: PropsWithChildren<Props>) => {
  const { id } = payload as CardData<R>;
  const entity = useGetEntityById(id);
  const handleOnDownload = useDownloadAsFile(entity);

  const dispatch = useDispatch();
  if (isNil(entity)) dispatch(fetchDocumentInfo(id));

  const handleShare = closeOnClick(useNavigateToShare(id));

  return (
    <ContextMenu {...position}>
      {participantChildren}
      <ContextMenuSeparator />
      <ContextMenuItem
        icon={DropdownShare}
        text="Share"
        disabled={!isAllowedToShareEntity}
        onClick={handleShare}
        data-id="share-menuButton"
      />
      {children}
      <ContextMenuSeparator />
      <ContextMenuItem
        icon={DropdownDownload}
        text="Download"
        disabled={!isAllowedToDownloadEntity}
        onClick={handleOnDownload}
        data-id="download-menuButton"
      />
    </ContextMenu>
  );
};
