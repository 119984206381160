import React, { FC } from 'react';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

export interface Props {
  onEdit?: () => void;
  onRemove?: () => void;
}

export const ParticipantControls: FC<Props> = ({ onEdit, onRemove }) => {
  return (
    <div className="c-user__action">
      {onEdit && (
        <div className="c-user__edit" onClick={onEdit}>
          {i18n(translationKeys.buttons.edit)}
        </div>
      )}
      {onRemove && (
        <>
          {onEdit && <div className="c-user__delimiter"></div>}
          <div className="c-user__remove" onClick={onRemove}>
            {i18n(translationKeys.buttons.remove)}
          </div>
        </>
      )}
    </div>
  );
};
