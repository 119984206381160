import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isNull } from 'lodash';

import { BillingState } from 'src/app/types';
import {
  BillingDetails,
  SubscribePayload,
  BillingInfoResponseData,
  CardInfoResponseData,
  BillingInfoPayload,
  BillingPlanSubscriptionPayload,
  FetchPricingInfoPayload,
  SubscriptionPrice,
  SubscriptionStatus,
} from 'src/models/billing';
import { defaultActionsFactory } from 'src/utils/defaultSlice';
import {
  billingInfoInitialState,
  normalizeBillingInfo,
  normalizeCardInfo,
} from './billingNormalizers';

const billingInitialState: BillingState = {
  isLoading: false,
  error: '',
  subscriptionStatus: null,
  billingInfo: billingInfoInitialState,
  basePrice: 0,
  salesTax: 0,
  totalPrice: 0,
  showUpgradeModal: false,
};

const { onStart, onError, onSuccess } = defaultActionsFactory();

const billing = createSlice({
  name: 'billing',
  initialState: billingInitialState,
  reducers: {
    startLoading: onStart,
    stopLoading: onError,
    finishLoading: onSuccess,
    setBillingInfo: (state, action: PayloadAction<BillingInfoResponseData>): void => {
      state.subscriptionStatus = action.payload.state;
      state.billingInfo = normalizeBillingInfo(action.payload);
    },
    setBillingDetails: (state, action: PayloadAction<BillingDetails>): void => {
      if (isNull(state.billingInfo)) {
        state.billingInfo = billingInfoInitialState;
      }
      state.billingInfo.billingDetails = action.payload;
    },
    setBillingCard: (state, action: PayloadAction<CardInfoResponseData>): void => {
      state.billingInfo.card = normalizeCardInfo(action.payload.card);
    },
    setCalculatedPrice: (state, action: PayloadAction<SubscriptionPrice>): void => {
      const { price, sales_tax, total } = action.payload;
      state.basePrice = price;
      state.salesTax = sales_tax;
      state.totalPrice = total;
    },
    resetCalculatedPrice: (state) => {
      state.basePrice = 0;
      state.salesTax = 0;
      state.totalPrice = 0;
    },
    setSubscriptionSuccess: (state) => {
      state.subscriptionStatus = SubscriptionStatus.Active;
    },
    showUpgradeModal: (state) => {
      state.showUpgradeModal = true;
    },

    hideUpgradeModal: (state) => {
      state.showUpgradeModal = false;
    },
  },
});

export const {
  setBillingInfo,
  setBillingDetails,
  setBillingCard,
  setCalculatedPrice,
  resetCalculatedPrice,
  setSubscriptionSuccess,
  startLoading,
  stopLoading,
  finishLoading,
  showUpgradeModal,
  hideUpgradeModal,
} = billing.actions;

export const billingReducer = billing.reducer;

export const fetchBillingDetails = createAction('billing/fetchBillingDetails');

export const fetchBillingDetailsWithCard = createAction('billing/fetchBillingDetailsWithCard');

export const fetchPricingInfo = createAction<FetchPricingInfoPayload>('billing/fetchPricingInfo');

export const continueWithBasicSubscription = createAction('billing/continueWithBasicSubscription');

export const submitBillingDetails = createAction<BillingInfoPayload>(
  'billing/submitBillingDetails',
);

export const submitBillingPlan = createAction<BillingPlanSubscriptionPayload>(
  'billing/submitBillingPlan',
);

export const upgradeBillingPlan = createAction<SubscribePayload>('billing/upgradeBillingPlan');

export const upgradeBillingCard = createAction<SubscribePayload>('billing/upgradeBillingCard');
