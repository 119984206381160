import React, { FC } from 'react';

import { EditUserForm } from 'src/v2/features/profile/profileSidebar/EditUserForm';

interface Props {
  userId: string;
}

export const ProfileEditUserView: FC<Props> = ({ userId }) => {
  return <EditUserForm userId={userId} />;
};
