import { FC, useCallback, ChangeEvent, KeyboardEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { Button } from 'src/v2/components/Button';
import { Message } from 'src/v2/icons/Message';

import {
  getIsLoading,
  getIsSending,
  getIsDisconnected,
  getOppositeMemberSelector,
  getCurrentMessage,
  setCurrentMessage,
  availableChatsSelector,
  sendMessage,
} from './store';
import { getFullName } from '../document/selectors';
import { getIsNetworkOnline } from '../network/store';

const INPUT_MAX_LENGTH = 300;
const ENTER_KEY_CODE = 13;

export const Input: FC = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const isSending = useSelector(getIsSending);
  const isDisconnected = useSelector(getIsDisconnected);
  const isOnline = useSelector(getIsNetworkOnline);

  const oppositeMember = useSelector(getOppositeMemberSelector);
  const message = useSelector(getCurrentMessage);
  const availableChats = useSelector(availableChatsSelector);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>): void => {
      dispatch(setCurrentMessage(event.currentTarget.value));
    },
    [dispatch],
  );

  const handleSubmit = useCallback((): void => {
    if (!message.length && !isSending) return;
    dispatch(sendMessage());
  }, [dispatch, message, isSending]);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent<HTMLTextAreaElement>): void => {
      if (event.which === ENTER_KEY_CODE && !event.shiftKey) {
        event.preventDefault();
        event.stopPropagation();
        handleSubmit();
      }
    },
    [handleSubmit],
  );

  const errorClass = message.length >= INPUT_MAX_LENGTH ? 'c-message__maxlength--error' : '';

  if (isLoading) return null;

  return (
    <>
      <div className="c-message__row">
        <div className="c-message__textarea">
          <div className={`c-message__maxlength  ${errorClass}`}>
            {`${message.length}/${INPUT_MAX_LENGTH}`}
          </div>
          <textarea
            placeholder={
              oppositeMember
                ? `${i18n(translationKeys.forms.chat.message)} ${getFullName(
                    oppositeMember.firstName,
                    oppositeMember.lastName,
                  )}`
                : undefined
            }
            onChange={handleChange}
            disabled={isSending || isDisconnected}
            value={message}
            onKeyPress={handleKeyPress}
            maxLength={INPUT_MAX_LENGTH}
          />
        </div>
      </div>
      <div className="c-message__row">
        <Button
          className="c-button c-button--w-full c-button--fs-12 h-uppercase"
          onClick={handleSubmit}
          isLoading={isSending}
          disabled={!message.length || isSending || isDisconnected || !isOnline}
        >
          {i18n(translationKeys.buttons.send)}
        </Button>
      </div>
      <div className="c-message__row">
        <div className="c-message__alert">
          {availableChats.length >= 1 && (
            <>
              <Message />
              {i18n(translationKeys.forms.chat.description)}
            </>
          )}
        </div>
      </div>
    </>
  );
};
