import { createSelector } from '@reduxjs/toolkit';
import { isNull } from 'lodash';

import { RootState } from 'src/app/types';

export const getStoredUser = (state: RootState) => state.auth;
export const getUserId = createSelector(getStoredUser, (state) => state.userId);
export const getCompanyId = createSelector(getStoredUser, (state) => state.companyId);
export const getIsLoginAvailable = createSelector(getStoredUser, (state) => state.isLoginAvailable);
export const getIsLoading = createSelector(getStoredUser, (state) => state.isLoading);
export const getToken = createSelector(getStoredUser, (state) => state.token);
export const getIsAuthenticated = createSelector(getToken, (token) => !isNull(token));
