import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getMessage } from './accountRecoverySelectors';

export const AccountRecoveryMessage: FC = () => {
  const message = useSelector(getMessage);

  return (
    <>
      <div className="c-modal__row">
        <div className="c-title c-title--center c-title--fs-32">ACCOUNT RECOVERY</div>
      </div>
      <div className="c-modal__row">
        <div className="c-description__text c-description--center c-description--fs-14">
          {message}
        </div>
      </div>
    </>
  );
};
