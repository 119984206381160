import React from 'react';
import ReduxToastr from 'react-redux-toastr';
// @ts-ignore
import { ThemeProvider } from 'emotion-theming';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-dropzone-uploader/dist/styles.css';

import { ContextMenuRoot } from 'src/v2/features/contextMenu';
import { Modal as ModalV2 } from 'src/v2/features/modal';
import { AppContextProvider } from 'src/app/AppContextProvider';
import { ClickOutsideWrapper } from 'src/app/ClickOutsideWrapper';
import { useGlobalEffct } from 'src/app/useGlobalEffct';
import { Routes } from 'src/app/Routes';

import './libsOverrides';
import { ContextMenuRegistry } from './contextMenuRegistry';
import { theme } from './theme';

const App = (): JSX.Element => {
  useGlobalEffct();

  return (
    <ClickOutsideWrapper>
      <ThemeProvider theme={theme}>
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-left"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
        <div className="l-body">
          <AppContextProvider>
            <Routes />
          </AppContextProvider>
          <ModalV2 />
        </div>
        <ContextMenuRoot registry={ContextMenuRegistry} />
      </ThemeProvider>
    </ClickOutsideWrapper>
  );
};

export default App;
