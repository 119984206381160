import React, { FC } from 'react';
import { Flex } from '@theme-ui/components';

import { UploadData } from 'src/models/editor';
import { Section } from 'src/models/document';
import {
  InsertSectionPayload,
  UpdateSectionPayload,
  MoveSectionPayload,
  RemoveSectionPayload,
  UploadFilePayload,
  UploadFileByUrlPayload,
} from 'src/models/entity';
import { EditableSections } from 'src/v2/features/entitySections/EditableSections';
import { StaticSections } from 'src/v2/features/entitySections/StaticSections';

export interface OwnProps {
  templateId: string;
  sections: Section[];
  isAllowedToEdit: boolean;
}

export interface DispatchProps {
  insertSection: (payload: InsertSectionPayload) => Promise<{}>;
  updateSection: (payload: UpdateSectionPayload) => Promise<{}>;
  moveSection: (payload: MoveSectionPayload) => Promise<{}>;
  removeSection: (payload: RemoveSectionPayload) => Promise<{}>;
  uploadFile: (payload: UploadFilePayload) => Promise<UploadData>;
  uploadFileByUrl: (payload: UploadFileByUrlPayload) => Promise<UploadData>;
}

export interface Props extends DispatchProps, OwnProps {}

export const EditorSection: FC<Props> = (props) => (
  <Flex sx={{ flex: '1', flexDirection: 'column' }}>
    {props.isAllowedToEdit ? (
      <EditableSections
        entityId={props.templateId}
        sections={props.sections}
        insertSection={props.insertSection}
        updateSection={props.updateSection}
        moveSection={props.moveSection}
        removeSection={props.removeSection}
        uploadFile={props.uploadFile}
        uploadFileByUrl={props.uploadFileByUrl}
      />
    ) : (
      <div>
        <StaticSections sections={props.sections} negotiable={false} />
      </div>
    )}
  </Flex>
);
