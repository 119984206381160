import React from 'react';

import { SidebarFactoryComponent } from 'src/v2/features/sidebarController/types';
import { useNormalizeURLHashValues } from 'src/v2/features/sharedEntity/EntitySidebar/EntitySidebar';
import { SidebarRoute } from 'src/v2/features/sidebarController';
import { book as controllerBook } from 'src/v2/features/sidebarController/book';

// import { useLoadSidebarDataEffct } from 'src/v2/features/sharedEntity/EntitySidebar/hooks/helper-hooks';
// import { useGetTemplateById } from 'src/v2/features/template/hooks';
// import { isNull } from 'lodash';

import { NineSquareMenu } from './pages/NineSquareMenu';
import { book } from './book';

interface Props {
  sidebarComponent: SidebarFactoryComponent;
  sidebarName: string;
  isHidden: boolean;
}

export const SidebarWrapper: React.FC<Props> = ({
  sidebarComponent: SidebarComponent,
  isHidden,
  sidebarName,
}) => {
  const criteria = useNormalizeURLHashValues(book.view);
  const id = criteria.id as string;

  // @TODO implement chat with sockets for templates
  // const template = useGetTemplateById(id);
  // const payload = isNull(template) ? null : { id: template.templateId, type: template.type };
  // useLoadSidebarDataEffct(payload);

  if (isHidden) return null;

  return id ? (
    <SidebarComponent criteria={criteria}>
      <SidebarRoute value={controllerBook.nineSquare} sidebarName={sidebarName}>
        <NineSquareMenu id={id} />
      </SidebarRoute>
    </SidebarComponent>
  ) : (
    <SidebarComponent criteria={criteria} />
  );
};
