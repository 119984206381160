import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isUndefined, isNull } from 'lodash';

import { ContractType, ContractRole } from 'src/models/contract';
import {
  getError,
  getIsFile,
  fetchDocument,
  getIsLoading,
  isReadyToSignVisible as getIsReadyToSignVisible,
} from 'src/v2/features/documentDetails/documentDetailsSlice';
import { getIsNegotiable } from 'src/v2/features/documentWorkflow';
import { openRoom } from 'src/v2/features/chat/store/chatReducers';
import { fetchSignatures } from 'src/v2/features/signature/store';
import { EntityType } from 'src/models/paper';
import { useGetEntityById } from 'src/v2/features/sharedEntity';
import { useGetEntityIdFromUrl } from 'src/v2/features/sharedEntity/hooks';
import { useFetchFolders } from 'src/v2/features/quantumNavigator/hooks';

import { ContractLayout } from './ContractLayout';
import { ContractContent } from './ContractContent';
import { DynamicSettingsContextProvider } from './providers/DynamicSettingsContextProvider';
import { SidebarNodeContextProvider } from './providers/SidebarNodeContextProvider';

export const ContractDetailsPage: React.FC = () => {
  const [isReady, setIsReady] = useState(false);

  const id = useGetEntityIdFromUrl();
  const contract = useGetEntityById<ContractType, ContractRole>(id);
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const error = useSelector(getError);
  const isNegotiable = useSelector(getIsNegotiable);
  const isFile = useSelector(getIsFile);
  const isReadyToSignVisible = useSelector(getIsReadyToSignVisible);

  useFetchFolders();
  useEffect(() => {
    if (!isUndefined(id)) {
      dispatch(fetchDocument(id));
      dispatch(fetchSignatures());
      // todo: chat
      dispatch(openRoom({ affiliationId: id, affiliationType: EntityType.contract }));
      setIsReady(true);
    }
  }, [dispatch, id]);

  const isContractLoaded = isReady && !isNull(contract) && !isLoading && !error;
  const contractId = isUndefined(id) ? null : id;

  return (
    <SidebarNodeContextProvider>
      <DynamicSettingsContextProvider
        documentId={contractId}
        isNegotiable={isNegotiable}
        isFile={isFile}
        isReadyToSignVisible={isReadyToSignVisible}
      >
        <ContractLayout isLoading={isLoading} error={error}>
          {!isUndefined(id) && isContractLoaded && <ContractContent id={id} />}
        </ContractLayout>
      </DynamicSettingsContextProvider>
    </SidebarNodeContextProvider>
  );
};
