import { FC } from 'react';
import { Field } from 'redux-form';
import { required } from 'redux-form-validators';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { Checkbox, RadioSetField } from 'src/v2/features/reduxForm';
import { SelectOptionType } from 'src/v2/components/Form/withReduxForm';

export interface Props {
  isConditionRequiredVisible: boolean;
  isConditionRequiredDisabled: boolean;
  isUpdating: boolean;
  conditionLabel: string;
  roleOptions: SelectOptionType[];
}

export const PermissionsFieldset: FC<Props> = ({
  isConditionRequiredVisible,
  isUpdating,
  conditionLabel,
  roleOptions,
  isConditionRequiredDisabled,
  children,
}) => {
  return (
    <div className="c-fieldset__group">
      <div className="c-title c-title--uppercase c-title--lh-1">
        {i18n(translationKeys.forms.userPermissions.title)}
      </div>
      {children}
      <div className="c-fieldset__group">
        <RadioSetField
          name="roleId"
          options={roleOptions}
          validate={required({ message: i18n(translationKeys.forms.userPermissions.chooseRole) })}
        />
      </div>

      {isConditionRequiredVisible && (
        <Field
          component={Checkbox}
          props={{
            disabled: isUpdating || isConditionRequiredDisabled,
          }}
          disabled={isUpdating}
          name="conditionRequired"
          label={conditionLabel}
          type="checkbox"
        />
      )}
    </div>
  );
};
