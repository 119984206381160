import React, { FC } from 'react';

import { BillingModalsService } from 'src/v2/features/billing/BillingModalsService';
import { ClearStateService } from './ClearStateService';

const PrivateServices: FC = ({ children }) => {
  return (
    <ClearStateService>
      <BillingModalsService>{children}</BillingModalsService>
    </ClearStateService>
  );
};

export default PrivateServices;
