// @TODO double check with BE that optional params are valid
import { head, isUndefined } from 'lodash';
import { ProfileEntity } from 'src/v2/entities/participants';
import { createErrorFactory } from 'src/common/error/createErrorFactory';

export interface UserBaseModel {
  id: string;
  avatar: string;
  createdAt: string;
  email: string;
  emailVerified: boolean;
  firstName: string;
  lastName: string;
  phone: string;
  phoneVerified: boolean;
}

export interface UserCompleteModel extends UserBaseModel {
  profile: ProfileCompleteModel[];
}

export interface ProfileBaseModel {
  firstName: string;
  lastName: string;
}

export interface ProfileCompleteModel extends ProfileBaseModel {
  avatar: AvatarCompleteModel[];
}

export interface AvatarBaseModel {
  uriExtraLarge: string;
  uriLarge: string;
  uriMedium: string;
  uriSmall: string;
}

export type AvatarCompleteModel = AvatarBaseModel;

export const convertUserCompleteModelToProfileEntity = (user: UserCompleteModel): ProfileEntity => {
  const createError = createErrorFactory('convertUserCompleteModelToProfileEntity');

  const { id } = user;

  const profile = head(user.profile);
  if (isUndefined(profile)) {
    throw createError('profile not found');
  }
  const { firstName, lastName } = profile;

  let avatarObject = head(profile.avatar);
  let avatar = '';
  if (isUndefined(avatarObject)) {
    console.warn('convertUserCompleteModelToProfileEntity: profile.avatar is empty', user);
  } else {
    avatar = avatarObject.uriMedium;
  }

  return {
    id,
    firstName,
    lastName,
    avatar,
  };
};
