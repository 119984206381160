import { AccountType } from 'src/models/signUp';

import { SignUpLightForm } from './components/SignUpLightForm';

export interface Props {
  accountType: AccountType;
  onLogInFlow?: () => void;
}

export const SignUpLight: React.FC<Props> = ({
  accountType = AccountType.Personal,
  onLogInFlow,
}) => (
  <div className="l-row l-row--mt-24">
    <SignUpLightForm accountType={accountType} onLogInFlow={onLogInFlow} />
  </div>
);
