/* eslint-disable no-param-reassign */
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { capitalize, get } from 'lodash';
import { toastr } from 'react-redux-toastr';
import { call, put, takeLatest } from 'redux-saga/effects';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import {
  createDocumentInFolderAPI,
  deleteDocumentApi,
  updateDocumentAccessAPI,
  updateDocumentApi,
} from 'src/api/documents';
import { TemplatePayload } from 'src/models/template';
import { DocumentPayload } from 'src/models/document';
import { ContractPayload } from 'src/models/contract';
import { handleErrorSaga } from 'src/utils/handleErrorSaga';
import { DocumentListState, RootState } from 'src/app/types';
import { clearWorkflow } from 'src/v2/features/documentWorkflow';
import { DbRecordType } from 'src/v2/features/objectsStorage/types';
import { emitCreate, emitDelete } from 'src/v2/features/sharedEntity/entityEventBus';

import {
  CreateDocumentDTO,
  UpdateDocumentAccessPayload,
} from 'src/v2/boundary/requestDTO/document';
import { CreateContractDTO } from 'src/v2/boundary/requestDTO/contract';
import { createContractInFolderAPI } from 'src/api/contractsAPI';

import { deleteData, storeData } from '../objectsStorage/objectsStorageSlice';

const initialState: DocumentListState = {
  data: [],
  isLoading: false,
  isCreating: false,
  deletedId: null,
  error: '',
};

const documentList = createSlice({
  name: 'documentList',
  initialState,
  reducers: {
    createDocumentStart(state: DocumentListState): void {
      state.isCreating = true;
    },

    createDocumentFinished(state: DocumentListState): void {
      state.isCreating = false;
    },

    deleteDocumentStart(state: DocumentListState, action: PayloadAction<string | null>): void {
      state.deletedId = action.payload;
    },

    deleteDocumentFinished(state: DocumentListState): void {
      state.deletedId = null;
    },
  },
});

function* updateDocumentAccessSaga(action: PayloadAction<UpdateDocumentAccessPayload>) {
  try {
    yield call(updateDocumentAccessAPI, action.payload.id, action.payload.accessLevel);
  } catch (err) {
    yield call(toastr.error, 'Error', 'We were unable to share your QR Code');
  }
}

export interface UpdateDocumentPayload {
  id: string;
  data: DocumentPayload | ContractPayload | TemplatePayload;
}

type DeleteDocumentPayload = string;
type DeleteContractPayload = string;

export const createDocument = createAction<CreateDocumentDTO>('documentList/createDocument');
export const createContract = createAction<CreateContractDTO>('documentList/createContract');
export const updateDocument = createAction<UpdateDocumentPayload>('documentList/updateDocument');
export const deleteDocument = createAction<DeleteDocumentPayload>('documentList/deleteDocument');
export const deleteContract = createAction<DeleteContractPayload>('documentList/deleteContract');
export const updateDocumentAccess = createAction<UpdateDocumentAccessPayload>(
  'documentList/updateDocumentAccess',
);

export const {
  createDocumentStart,
  createDocumentFinished,
  deleteDocumentStart,
  deleteDocumentFinished,
} = documentList.actions;

function* createDocumentSaga(action: PayloadAction<CreateDocumentDTO>) {
  try {
    yield put(createDocumentStart());
    yield put(clearWorkflow());
    const data = action.payload;

    const response = yield call(createDocumentInFolderAPI, data);
    yield put(storeData(response));
    yield put(createDocumentFinished());
    emitCreate({ id: get(response, 'data[0].id'), type: data.data.type, folderId: data.folderId });
    yield call(
      toastr.success,
      i18n(translationKeys.messages.success),
      i18n(translationKeys.messages.documentCreated),
    );
  } catch (error) {
    yield call(handleErrorSaga, error);
    yield put(createDocumentFinished());
  }
}

function* createContractSaga(action: PayloadAction<CreateContractDTO>) {
  try {
    yield put(createDocumentStart());
    yield put(clearWorkflow());
    const data = action.payload;

    const response = yield call(createContractInFolderAPI, data);
    yield put(storeData(response));
    yield put(createDocumentFinished());
    emitCreate({ id: get(response, 'data[0].id'), type: data.data.type, folderId: data.folderId });
    yield call(
      toastr.success,
      i18n(translationKeys.messages.success),
      i18n(translationKeys.messages.contractCreated),
    );
  } catch (error) {
    yield call(handleErrorSaga, error);
    yield put(createDocumentFinished());
  }
}

function* updateDocumentSaga(action: PayloadAction<UpdateDocumentPayload>) {
  try {
    yield put(createDocumentStart());
    const { data, id } = action.payload;

    const document = yield call(updateDocumentApi, data as DocumentPayload, id);
    yield put(storeData(document));
    yield put(createDocumentFinished());
    yield call(
      toastr.success,
      i18n(translationKeys.messages.success),
      i18n(translationKeys.messages.entityTypeUpdated, {
        entityType: capitalize(i18n(translationKeys.forms.template.type[data.type])),
      }),
    );
  } catch (error) {
    yield call(handleErrorSaga, error);
    yield put(createDocumentFinished());
  }
}

function* deleteEntity(id: DeleteDocumentPayload) {
  try {
    yield call(deleteDocumentApi, id);
    yield put(deleteData({ id, type: DbRecordType.Paper }));
    yield put(deleteData({ id, type: DbRecordType.CardPaper }));
    yield call(emitDelete);
  } catch (error) {
    yield call(handleErrorSaga, error);
  }
}

function* deleteDocumentSaga({ payload: id }: PayloadAction<DeleteDocumentPayload>) {
  try {
    yield put(deleteDocumentStart(id));
    yield call(deleteEntity, id);
    yield call(
      toastr.success,
      i18n(translationKeys.messages.success),
      i18n(translationKeys.messages.documentDeleted),
    );
    yield put(deleteDocumentFinished());
  } catch (error) {
    yield call(handleErrorSaga, error);
    yield put(deleteDocumentFinished());
  }
}

function* deleteContractSaga({ payload: id }: PayloadAction<DeleteDocumentPayload>) {
  try {
    yield put(deleteDocumentStart(id));
    yield call(deleteEntity, id);
    yield call(
      toastr.success,
      i18n(translationKeys.messages.success),
      i18n(translationKeys.messages.contractDeleted),
    );
    yield put(deleteDocumentFinished());
  } catch (error) {
    yield call(handleErrorSaga, error);
    yield put(deleteDocumentFinished());
  }
}

export function* watchDocumentListSagas() {
  yield takeLatest(createDocument, createDocumentSaga);
  yield takeLatest(createContract, createContractSaga);
  yield takeLatest(updateDocument, updateDocumentSaga);
  yield takeLatest(deleteDocument, deleteDocumentSaga);
  yield takeLatest(deleteContract, deleteContractSaga);
  yield takeLatest(updateDocumentAccess, updateDocumentAccessSaga);
}

const getState = (state: RootState): DocumentListState => state.documentList;
export const getDocumentListData = (state: RootState) => getState(state).data;
export const isLoading = (state: RootState): boolean => getState(state).isLoading;
export const getIsCreating = (state: RootState): boolean => getState(state).isCreating;
export const getError = (state: RootState): string => getState(state).error;
export const getDeletedId = (state: RootState): string | null => getState(state).deletedId;

export default documentList.reducer;
