import { includes } from 'lodash';

import { DocumentRole } from 'src/models/document';

export interface UserWithRole {
  id: string;
  role: DocumentRole;
}

export const canAddUserWithManagerRole = (actorRole: DocumentRole): boolean =>
  includes([DocumentRole.Manager, DocumentRole.Owner], actorRole);

export const canEditUserWithManagerRole = (actorRole: DocumentRole): boolean =>
  includes([DocumentRole.Manager, DocumentRole.Owner], actorRole);

export const canAddUserWithEditorRole = (actorRole: DocumentRole): boolean =>
  includes([DocumentRole.Manager, DocumentRole.Owner, DocumentRole.Editor], actorRole);

export const canEditUserWithEditorRole = (actorRole: DocumentRole): boolean =>
  includes([DocumentRole.Manager, DocumentRole.Owner, DocumentRole.Editor], actorRole);

export const canAddUserWithAcknowledgerRole = (actorRole: DocumentRole): boolean =>
  includes(
    [DocumentRole.Manager, DocumentRole.Owner, DocumentRole.Acknowledger, DocumentRole.Editor],
    actorRole,
  );

export const canEditUserWithAcknowledgerRole = (actorRole: DocumentRole): boolean =>
  includes([DocumentRole.Manager, DocumentRole.Owner, DocumentRole.Editor], actorRole);

export const canAddUserWithCommentatorRole = (actorRole: DocumentRole): boolean =>
  includes(
    [
      DocumentRole.Manager,
      DocumentRole.Owner,
      DocumentRole.Acknowledger,
      DocumentRole.Commentator,
      DocumentRole.Editor,
    ],
    actorRole,
  );

export const canEditUserWithCommentatorRole = (actorRole: DocumentRole): boolean =>
  includes([DocumentRole.Manager, DocumentRole.Owner, DocumentRole.Editor], actorRole);

export const canEditUserWithQRViewerRole = (actorRole: DocumentRole): boolean =>
  includes([DocumentRole.Manager, DocumentRole.Owner, DocumentRole.Editor], actorRole);

export const canAddUserWithViewerRole = (actorRole: DocumentRole): boolean =>
  includes(
    [
      DocumentRole.Manager,
      DocumentRole.Owner,
      DocumentRole.Acknowledger,
      DocumentRole.Commentator,
      DocumentRole.Viewer,
      DocumentRole.Editor,
    ],
    actorRole,
  );

export const canEditUserWithViewerRole = (actorRole: DocumentRole): boolean =>
  includes([DocumentRole.Manager, DocumentRole.Owner, DocumentRole.Editor], actorRole);

export const canAddUserWithSharedViewerRole = (actorRole: DocumentRole): boolean =>
  includes(
    [
      DocumentRole.Manager,
      DocumentRole.Owner,
      DocumentRole.Acknowledger,
      DocumentRole.Commentator,
      DocumentRole.Viewer,
    ],
    actorRole,
  );

export const canEditUserWithSharedViewerRole = (): boolean => false;

export const canRemoveUserWithSharedViewerRole = (actorRole: DocumentRole): boolean =>
  includes([DocumentRole.Manager, DocumentRole.Owner], actorRole);

export const canAddUserWithDocumentSharerDownloaderRole = (actorRole: DocumentRole): boolean =>
  includes(
    [
      DocumentRole.Manager,
      DocumentRole.Owner,
      DocumentRole.Acknowledger,
      DocumentRole.Commentator,
      DocumentRole.Viewer,
      DocumentRole.DocumentSharerDownloader,
    ],
    actorRole,
  );

export const canEditUserWithDocumentSharerDownloaderRole = (actorRole: DocumentRole): boolean =>
  includes([DocumentRole.Owner], actorRole);

export const canAddUserWithDocumentDownloaderRole = (actorRole: DocumentRole): boolean =>
  includes(
    [
      DocumentRole.Manager,
      DocumentRole.Owner,
      DocumentRole.Acknowledger,
      DocumentRole.Commentator,
      DocumentRole.Viewer,
      DocumentRole.DocumentSharerDownloader,
    ],
    actorRole,
  );

export const canEditUserWithDocumentDownloaderRole = (actorRole: DocumentRole): boolean =>
  includes([DocumentRole.Owner], actorRole);

export const canAddUserWithDocumentSharerRole = (actorRole: DocumentRole): boolean =>
  includes(
    [
      DocumentRole.Manager,
      DocumentRole.Owner,
      DocumentRole.Acknowledger,
      DocumentRole.Commentator,
      DocumentRole.Viewer,
      DocumentRole.DocumentSharerDownloader,
      DocumentRole.DocumentSharer,
    ],
    actorRole,
  );

export const canEditUserWithDocumentSharerRole = (actorRole: DocumentRole): boolean =>
  includes([DocumentRole.Owner], actorRole);

export const canAddUserWithDocumentClippedViewerRole = (actorRole: DocumentRole): boolean =>
  includes(
    [
      DocumentRole.Manager,
      DocumentRole.Owner,
      DocumentRole.Acknowledger,
      DocumentRole.Commentator,
      DocumentRole.Viewer,
      DocumentRole.DocumentSharerDownloader,
      DocumentRole.DocumentSharer,
    ],
    actorRole,
  );

export const canEditUserWithDocumentClippedViewerRole = (actorRole: DocumentRole): boolean =>
  includes([DocumentRole.Owner], actorRole);

const canAddFnByRole: Record<DocumentRole, (role: DocumentRole) => boolean> = {
  [DocumentRole.Owner]: () => false,
  [DocumentRole.Manager]: canAddUserWithManagerRole,
  [DocumentRole.Editor]: canAddUserWithEditorRole,
  [DocumentRole.Acknowledger]: canAddUserWithAcknowledgerRole,
  [DocumentRole.Commentator]: canAddUserWithCommentatorRole,
  [DocumentRole.QRViewer]: () => false,
  [DocumentRole.Viewer]: canAddUserWithViewerRole,
  [DocumentRole.SharedViewer]: canAddUserWithSharedViewerRole,
  [DocumentRole.DocumentSharerDownloader]: canAddUserWithDocumentSharerDownloaderRole,
  [DocumentRole.Downloader]: canAddUserWithDocumentDownloaderRole,
  [DocumentRole.DocumentSharer]: canAddUserWithDocumentSharerRole,
  [DocumentRole.DocumentClippedViewer]: canAddUserWithDocumentClippedViewerRole,
};

const canEditFnByRole: Record<DocumentRole, (role: DocumentRole) => boolean> = {
  [DocumentRole.Owner]: () => false,
  [DocumentRole.Manager]: canEditUserWithManagerRole,
  [DocumentRole.Editor]: canEditUserWithEditorRole,
  [DocumentRole.Acknowledger]: canEditUserWithAcknowledgerRole,
  [DocumentRole.Commentator]: canEditUserWithCommentatorRole,
  [DocumentRole.QRViewer]: canEditUserWithQRViewerRole,
  [DocumentRole.Viewer]: canEditUserWithViewerRole,
  [DocumentRole.SharedViewer]: canEditUserWithSharedViewerRole,
  [DocumentRole.DocumentSharerDownloader]: canEditUserWithDocumentSharerDownloaderRole,
  [DocumentRole.Downloader]: canEditUserWithDocumentDownloaderRole,
  [DocumentRole.DocumentSharer]: canEditUserWithDocumentSharerRole,
  [DocumentRole.DocumentClippedViewer]: canEditUserWithDocumentClippedViewerRole,
};

export const canAddUser = (actorRole: DocumentRole, targetRole: DocumentRole): boolean =>
  canAddFnByRole[targetRole](actorRole);

const canEditDocumentUser = (actorRole: DocumentRole, invitedByActor: boolean): boolean =>
  includes([DocumentRole.Owner, DocumentRole.Manager], actorRole) ||
  (includes(
    [DocumentRole.Editor, DocumentRole.Acknowledger, DocumentRole.Commentator],
    actorRole,
  ) &&
    invitedByActor);

export const canEditUser = (
  actor: UserWithRole,
  target: UserWithRole,
  invitedByActor = true,
): boolean =>
  actor.id !== target.id &&
  canEditDocumentUser(actor.role, invitedByActor) &&
  canEditFnByRole[target.role] &&
  canEditFnByRole[target.role](actor.role);

export const canEditUserPermissions = (
  actor: UserWithRole,
  target: UserWithRole,
  invitedByActor = true,
): boolean =>
  !includes(
    [
      DocumentRole.QRViewer,
      DocumentRole.Downloader,
      DocumentRole.DocumentSharer,
      DocumentRole.DocumentSharerDownloader,
      DocumentRole.DocumentClippedViewer,
    ],
    target.role,
  ) && canEditUser(actor, target, invitedByActor);

const canRemoveFnByRole: Record<DocumentRole, (role: DocumentRole) => boolean> = {
  ...canEditFnByRole,
  [DocumentRole.SharedViewer]: canRemoveUserWithSharedViewerRole,
};

export const canRemoveUser = (actor: UserWithRole, target: UserWithRole): boolean =>
  actor.id !== target.id && canRemoveFnByRole[target.role](actor.role);
