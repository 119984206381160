import { ChangeEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNil, isNull } from 'lodash';
import EmailValidator from 'email-validator';
import { InjectedFormProps } from 'redux-form';

import { clearFoundUser, findUserByFiled } from 'src/v2/features/organization';
import { FindUser } from 'src/v2/features/organization/types';
import { getWorkflow, getWorkflowParticipants } from 'src/v2/features/documentWorkflow';
import {
  convertPaperParticipantToCompleteParticipantsV2,
  getMyRole,
  getOwnerConditionRequired,
} from 'src/v2/features/documentParticipants';
import { SidebarParticipantEntity } from 'src/v2/entities/participants';
import { DocumentRole, DocumentType } from 'src/models/document';
import { availableChatsSelector } from 'src/v2/features/chat/store';

import { getCurrentOrganizationFoundUser } from 'src/shared/organization';
import { getUserId } from 'src/shared/auth';
import { useGetEntityById } from '../sharedEntity';
import { PaperModel } from 'src/models/paper';

export interface AutofillHookArgs
  extends Pick<InjectedFormProps, 'change' | 'autofill' | 'submitSucceeded'> {
  onClose: () => void;
}

export const useAutofill = ({
  change,
  autofill,
  submitSucceeded,
  onClose,
}: AutofillHookArgs): [(event: ChangeEvent<HTMLInputElement>) => void, boolean] => {
  const dispatch = useDispatch();

  const foundUser = useSelector(getCurrentOrganizationFoundUser);
  const prevFoundUserRef = useRef<typeof foundUser>(null);

  useEffect(() => {
    if (submitSucceeded) {
      dispatch(clearFoundUser());
      onClose();
    }
  }, [dispatch, submitSucceeded, onClose]);

  useEffect(() => {
    if (!isNull(foundUser) && foundUser !== prevFoundUserRef.current) {
      autofill('firstName', foundUser.firstName);
      autofill('lastName', foundUser.lastName);
      autofill('phone', foundUser.phone);
      autofill('role', foundUser.role);
      prevFoundUserRef.current = foundUser;
    }

    if (foundUser !== prevFoundUserRef.current) {
      change('firstName', '');
      change('lastName', '');
      change('phone', '');
      change('role', '');
      prevFoundUserRef.current = foundUser;
    }
  }, [foundUser, autofill, change]);

  const handleChangeEmail = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const validateResult = EmailValidator.validate(event.target.value);
      if (validateResult) {
        dispatch(
          findUserByFiled({ fieldName: 'emails', fieldValue: event.target.value } as FindUser),
        );
      } else {
        dispatch(clearFoundUser());
      }
    },
    [dispatch],
  );

  return [handleChangeEmail, !isNil(foundUser)];
};

export const useGetFullParticipantsByDocumentV2 = <T, R>(
  document: PaperModel<T, R> | null | undefined,
) => {
  const availableChats = useSelector(availableChatsSelector);
  const workflow = useSelector(getWorkflow);
  const workflowParticipants = useSelector(getWorkflowParticipants);

  return useMemo(() => {
    const participantsFromDocument = !isNil(document) ? document.paperParticipant : [];
    const result = convertPaperParticipantToCompleteParticipantsV2<R>(
      participantsFromDocument,
      availableChats,
      workflow,
      workflowParticipants,
    );

    return result;
  }, [document, availableChats, workflow, workflowParticipants]);
};

export const useGetFullParticipantsByDocumentId = <T = DocumentType, R = DocumentRole>(
  entityId?: string,
) => {
  const entity = useGetEntityById<T, R>(entityId);

  return useGetFullParticipantsByDocumentV2<T, R>(entity);
};

export const useGetCurrentUserRoleByEntityId = (entityId: string | undefined) => {
  const entityParticipants = useGetFullParticipantsByDocumentId(entityId);
  const currentUserId = useSelector(getUserId);
  return getMyRole(entityParticipants, currentUserId);
};

export const useGetOwnerConditionRequired = (entityId: string | undefined) => {
  const entityParticipants = useGetFullParticipantsByDocumentId(entityId);
  return getOwnerConditionRequired(entityParticipants);
};

export const useEditParticipantCallback = (
  onEdit: (params: { participantId?: string }) => () => void,
) => {
  return useCallback(
    (participant?: SidebarParticipantEntity<DocumentRole>) => {
      if (participant) {
        return onEdit({ participantId: participant.id });
      }

      return onEdit({});
    },
    [onEdit],
  );
};

export const useRemoveParticipantCallback = (
  onRemove: (params: { participantId: string }) => () => void,
) => {
  return useCallback(
    (participant: SidebarParticipantEntity<DocumentRole>) =>
      onRemove({ participantId: participant.id }),
    [onRemove],
  );
};
