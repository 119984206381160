import { map, isEqual, isArray, findIndex, isUndefined, isNull, isNil } from 'lodash';

import { Document, DocumentContent, Section, EntityLock, Field } from 'src/models/document';
import { UserSignature } from 'src/models/profile';
import { WorkflowCondition } from 'src/v2/entities/workflow';
import { createErrorFactory } from 'src/common/error/createErrorFactory';
import {
  convertUserCompleteModelToProfileEntity,
  UserCompleteModel,
} from 'src/v2/boundary/storageModels/user';
import { getFullName } from 'src/utils/normalize';
import { nbspReplacer } from 'src/utils/string';

export function formatDate(createdAt: string): string {
  return new Date(createdAt).toLocaleString('us-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
}

export function areSidesEqual(leftSide: Section[], rightSide: Section[]): boolean {
  const leftSections = map(leftSide, 'text') as string[];
  const rightSections = map(rightSide, 'text') as string[];
  return isEqual(leftSections, rightSections);
}

export function convertDocumentToDocumentContent(document: Document): DocumentContent {
  const createError = createErrorFactory('convertDocumentToDocumentContent');

  if (isNil(document.entity)) {
    throw createError('Document should contain an associated entity');
  }

  const entity = document.entity;

  if (isNil(entity.currentCommit.tree)) {
    throw createError('Entity tree should contain a list of sections');
  }

  const tree = entity.currentCommit.tree;

  if (isNil(tree.children)) {
    throw createError('');
  }

  if (!isArray(entity.locks)) {
    throw createError('Entity object should contain a list of locks');
  }

  const sections = tree.children.map((section, index) => {
    return {
      index,
      id: section.id,
      text: section.text,
      children: section.children,
      isLocked: isNil(findIndex(entity.locks, (lock) => lock.id === section.id)),
    };
  });

  return {
    id: document.id,
    title: document.title,
    sections: sections,
    locks: entity.locks,
  };
}

export function createUserSignaturesFromUserConditions(
  userConditions: WorkflowCondition[],
  userCompleteObjects: UserCompleteModel[],
): UserSignature[] {
  const createError = createErrorFactory('createUserSignaturesFromUserConditions');

  return userCompleteObjects.reduce((acc: UserSignature[], user: UserCompleteModel) => {
    try {
      const condition = userConditions.find((c) => c.userId === user.id);

      if (!condition) {
        throw createError(`Workflow condition for userId: ${user.id} not found`);
      }

      const { userId, actor, completedAt } = condition;
      const userProfile = convertUserCompleteModelToProfileEntity(user);

      return [
        ...acc,
        {
          userId,
          firstName: actor.first_name,
          lastName: actor.last_name,
          avatarUrl: userProfile.avatar,
          signatureHash: actor.signature_hash,
          signatureUrl: actor.signature,
          signatureName: actor.signature_name || getFullName(actor.first_name, actor.last_name),
          stampUrl: actor.stamp || '',
          companyName: actor.company_name,
          companyTitle: actor.company_title,
          timestamp: formatDate(completedAt),
        },
      ];
    } catch {
      return acc;
    }
  }, [] as UserSignature[]);
}

export function getLockPath({ path }: EntityLock): number {
  const createError = createErrorFactory('getLockPath');

  if (path.length !== 1) {
    throw createError(`Lock path is not a single section index, ${path.length} ones found instead`);
  }

  return path[0];
}

export function mergeSectionsWithLocks(
  sections: Section[],
  locks?: EntityLock[] | undefined,
  userId?: string | null,
): Section[] {
  const othersLocks =
    !isNull(userId) && !isUndefined(locks) ? locks.filter((lock) => lock.userId !== userId) : [];
  const lockedSectionIndexes = othersLocks.map(getLockPath);

  return sections.map((section, index) => ({
    ...section,
    isLocked: lockedSectionIndexes.includes(index),
  }));
}

export function uniquifySectionIds(sections: Section[]) {
  return sections.map((section, index) => {
    const firstIndex = findIndex(sections, { id: section.id });
    return firstIndex < index ? { ...section, id: `${section.id}-${index}` } : section;
  });
}

export const fieldsWithSameNameMapper = (sections: Section[], fields?: Field[]) => {
  const sectionsCopy = [...sections];

  if (!isUndefined(fields) && fields.length > 0) {
    sectionsCopy.forEach((section, index) => {
      const fragment = document.createElement('div');
      fragment.innerHTML = nbspReplacer(section.text);
      const fieldsFroQuery = fragment.querySelectorAll(`.editable-field`);
      fieldsFroQuery.forEach(
        () => (sectionsCopy[index] = { ...section, text: fragment.innerHTML }),
      );
    });
  }
  return sectionsCopy;
};
