import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { confirmation } from 'redux-form-validators';
import { Button, Link } from '@theme-ui/components';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { SignUpLightActionPayload } from 'src/v2/boundary/actionPayload/signup';
import { AccountType } from 'src/models/signUp';
import { CForm, CFormRow } from 'src/v2/components/CForm';
import { Input } from 'src/v2/features/reduxForm/Input';
import { Email } from 'src/v2/features/reduxForm/Email';
import { Password } from 'src/v2/features/reduxForm/Password';
import { PhoneNumber } from 'src/v2/components/PhoneNumber';
import { validateAuthForm } from 'src/utils/validate';
import { book } from 'src/app/book';
import { Checkbox } from 'src/v2/features/reduxForm/Checkbox';
import { useGetPlanAndPeriod, useGetQRParameters } from 'src/v2/features/signUp/hooks';
import { getSignupFormData, signUpLightAction } from 'src/v2/features/signUp/store';
import { SignUpPayload } from 'src/v2/features/signUp/types';
import { validatePassword } from 'src/utils/passwordStrength';
import { isNull, isUndefined } from 'lodash';
import { SubscriptionPlan } from 'src/models/billing';
import { Capture } from '../../../components/Сapture/Capture';
import { useIsNoCaptureMode } from '../../../../utils/hooks';

type Props = {
  accountType: AccountType;
  onLogInFlow?: () => void;
};

type Values = SignUpPayload & { password: string };

type SingUpLightFormProps = Props & InjectedFormProps<Values, Props>;

export const SignUpLightFormName = 'signup-light';

export const Form: React.FC<SingUpLightFormProps> = ({
  handleSubmit,
  invalid,
  submitting,
  pristine,
  initialize,
  onLogInFlow,
}) => {
  const dispatch = useDispatch();
  const data = useSelector(getSignupFormData);
  const [plan, period] = useGetPlanAndPeriod();
  const [entityType, entityId] = useGetQRParameters();
  const { pathname, search } = useLocation();
  const [captureToken, setCaptureToken] = useState<string | null>(null);
  const isNoCaptureMode = useIsNoCaptureMode();

  useEffect(() => {
    if (data.email) initialize(data);
  }, [initialize, data]);

  const submitForm = useCallback(
    (values): void => {
      const payload: SignUpLightActionPayload = {
        ...values,
        plan: isUndefined(plan) ? SubscriptionPlan.Basic : plan,
        period,
        entityType,
        entityId,
        captureToken,
      };

      if (!isNull(entityType) && !isNull(entityId)) {
        dispatch(signUpLightAction(payload));
      }
    },
    [dispatch, plan, period, entityType, entityId, captureToken],
  );

  const handleSubmitCapture = (token: string) => {
    setCaptureToken(token);
  };

  return (
    <>
      <div className="c-modal__row c-modal--mt-24">
        <div className="c-title c-title__auth c-title--color-theme c-title--fs-64">
          {i18n(translationKeys.forms.loginLight.signUp)}
        </div>
      </div>
      <div className="c-modal__row">
        <div className="c-title c-title__auth c-title--color-theme c-title--fw-400">
          {i18n(translationKeys.forms.loginLight.noAccountSignUp)}
        </div>
      </div>
      <div className="c-modal__row c-modal--mt-24">
        <CForm className="c-form c-form--grow" onSubmit={handleSubmit(submitForm)} noValidate>
          <CFormRow>
            <Field
              className="c-form__column"
              name="firstName"
              placeholder={i18n(translationKeys.forms.signup.fields.firstName)}
              component={Input}
            />
          </CFormRow>
          <CFormRow>
            <Field
              className="c-form__column"
              name="lastName"
              placeholder={i18n(translationKeys.forms.signup.fields.lastName)}
              component={Input}
            />
          </CFormRow>
          <CFormRow>
            <Field
              className="c-form__column"
              name="email"
              type="email"
              placeholder={i18n(translationKeys.forms.signup.fields.email)}
              component={Email}
            />
          </CFormRow>
          <CFormRow>
            <Field
              className="c-form__column"
              name="password"
              type="password"
              placeholder={i18n(translationKeys.forms.createPassword.password)}
              component={Password}
              validate={validatePassword}
              required
            />
          </CFormRow>
          <CFormRow>
            <Field
              className="c-form__column"
              name="confirmPassword"
              type="text"
              placeholder={i18n(translationKeys.forms.createPassword.verifyPassword)}
              component={Password}
              validate={confirmation({
                field: 'password',
                message: i18n(translationKeys.errors.PASSWORD_NOT_MATCH),
              })}
              autoComplete="off"
              required
            />
          </CFormRow>
          <CFormRow>
            <Field
              className="c-form__column"
              sx={{ display: 'flex', flexDirection: 'column-reverse' }}
              name="phone"
              component={PhoneNumber}
            />
          </CFormRow>
          <CFormRow className="c-form--mt-32">
            <Field
              className="c-form__column"
              name="terms"
              label={
                <>
                  {i18n(translationKeys.buttons.agree)}{' '}
                  <Link
                    data-type="readTerms-link"
                    href={book.termsAndConditions}
                    className="c-checkbox__link"
                    target="_blank"
                  >
                    {i18n(translationKeys.buttons.termsAndCondition)}
                  </Link>
                </>
              }
              component={Checkbox}
              type="checkbox"
              data-type="readTerms-agree"
            />
          </CFormRow>
          {!isNoCaptureMode && <Capture onChange={handleSubmitCapture} isShow={!invalid} />}
          <CFormRow>
            <div className="c-form__column">
              <Button
                className="c-button c-button--fs-12 c-button--w-full h-uppercase"
                data-type="login-submit"
                variant="accentLogin"
                type="submit"
                disabled={
                  invalid || submitting || pristine || (isNull(captureToken) && !isNoCaptureMode)
                }
              >
                {i18n(translationKeys.buttons.signUp)}
              </Button>
            </div>
          </CFormRow>
          <CFormRow className="c-form--mt-16">
            <div className="c-form__column">
              {i18n(translationKeys.forms.loginLight.haveAccount)}{' '}
              <Link
                to={{ pathname, search }}
                className="c-form__link"
                data-type="signin-link"
                as={RouterLink}
                onClick={onLogInFlow}
              >
                {i18n(translationKeys.forms.loginLight.signIn)}
              </Link>
            </div>
          </CFormRow>
        </CForm>
      </div>
    </>
  );
};

export const SignUpLightForm = reduxForm<Values, Props>({
  form: SignUpLightFormName,
  forceUnregisterOnUnmount: true,
  initialValues: { password: '' },
  validate: validateAuthForm,
})(Form);
