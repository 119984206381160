import React, { FC, ReactEventHandler } from 'react';
import cn from 'classnames';

import { ListItemViewComponentProps } from 'src/v2/modules/FsItem/types';
import { EllipsisV } from 'src/v2/icons/EllipsisV';
import { FolderOpen } from 'src/v2/icons/FolderOpen';
import { FolderLinear } from 'src/v2/icons/FolderLinear';
import { size } from 'lodash';

interface Props {
  isSelected?: boolean;
  setSelected: ReactEventHandler;
}

const openClass = 'c-library__item--active';
const activeLinkClass = 'c-library__link--active';

export const FolderView: FC<ListItemViewComponentProps & Props> = ({
  children,
  onToggleExpand,
  model,
  isExpanded,
  isSelected,
  setSelected,
}) => {
  return (
    <div className={cn('c-library__item', isExpanded && openClass)}>
      <span className={cn('c-library__link', isSelected && activeLinkClass)} onClick={setSelected}>
        {size(model.children) > 0 && <div className="c-library__arrow" onClick={onToggleExpand} />}
        <div className="c-library__icon c-library__icon--toggle">
          <FolderLinear />
          <FolderOpen />
        </div>
        <div className="c-library__info">
          <div className="c-library__title">{model.data.name}</div>
        </div>
        <div className="c-library__dots">
          <EllipsisV />
        </div>
      </span>
      <div className="c-library__dropdown">{children}</div>
    </div>
  );
};
