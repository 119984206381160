import React, { FC, useContext, useRef } from 'react';

import { useToggle } from 'src/common/hooks/useToggle';
import { useOnClickOutside } from 'src/common/hooks/useOnClickOutside';

import { BaseLayout } from '../BaseLayout';
import { SmallHeader } from '../header';
import { Navigation } from '../navigation';
import { SidebarNode } from '../types';
import { SidebarNodeContext } from '../contexts/SidebarNodeContext';

export const AppLayout: FC = ({ children }) => {
  const navRef = useRef(null);
  const [navIsOpen, , openNavigation, closeNavigation] = useToggle();
  useOnClickOutside(navRef, closeNavigation);
  const sidebar = useContext<SidebarNode>(SidebarNodeContext);

  return (
    <BaseLayout
      header={<SmallHeader onHamburgerClick={openNavigation} />}
      navigation={<Navigation ref={navRef} active={navIsOpen} />}
      isShortHeader
      sidebar={sidebar}
    >
      {children}
    </BaseLayout>
  );
};
