import { ParsedQuery } from 'query-string';
import { isUndefined } from 'lodash';

import {
  useOmitHashUrlFactory,
  useViewLinkCallbackFactory,
  useViewLinkFactory,
  useWithHistoryFactory,
} from 'src/v2/features/sidebarController';
import { useUrlManagerHash } from 'src/common/hooks/urlManager';
import { book as defaultBook } from 'src/v2/features/sidebarController/book';
import { ListItem } from 'src/models/list';
import { NodeTree } from 'src/models/node';

import {
  useCreateNavigateToFn,
  useNavigateToFn,
} from 'src/v2/features/sidebarController/helpers-hooks';
import { useCallback } from 'react';
import { book } from '../book';

export type RedirectHook = (
  params?: ParsedQuery<string>,
) => [ReturnType<typeof useViewLinkFactory>, ReturnType<typeof useViewLinkCallbackFactory>];

export const useToggle9Square = (): (() => void) => {
  const [hash, , createUpdatedHash] = useUrlManagerHash();

  const openLink = createUpdatedHash({ v: defaultBook.nineSquare });
  const closeLink = useOmitHashUrlFactory('v');
  const show = useWithHistoryFactory(openLink);
  const hide = useWithHistoryFactory(closeLink);

  return hash.v === defaultBook.nineSquare ? hide : show;
};

export const useToggleQRCode = (): (() => void) => {
  const [hash, , createUpdatedHash] = useUrlManagerHash();

  const openLink = createUpdatedHash({ v: defaultBook.qr });
  const closeLink = useOmitHashUrlFactory('v');
  const show = useWithHistoryFactory(openLink);
  const hide = useWithHistoryFactory(closeLink);

  return hash.v === defaultBook.qr ? hide : show;
};

export const useShowMetadataCreate = () => useNavigateToFn({ params: { v: book.create } });

export const useShowMetadataView: RedirectHook = (params?: ParsedQuery<string>) => [
  useViewLinkFactory(book.view, params),
  useViewLinkCallbackFactory(book.view),
];

export const useShowMetadataViewFn = (params?: ParsedQuery<string>) =>
  useNavigateToFn({ params: { v: book.view, ...params } });

export const useCreateShowMetadataViewFn = () =>
  useCreateNavigateToFn({ params: { v: book.view } });

export const useShowMetadataEdit: RedirectHook = (params?: ParsedQuery<string>) => [
  useViewLinkFactory(book.edit, params),
  useViewLinkCallbackFactory(book.edit),
];

export const useNavigateToDefault = (entityId?: string) =>
  useNavigateToFn({
    params: { v: book.default, id: !isUndefined(entityId) ? entityId : null },
  });

export const useCreateNavigateToDefaultBy = () => {
  const createNavigateTo = useCreateNavigateToFn({
    params: { v: book.default },
  });

  return useCallback((entityId: string) => createNavigateTo({ id: entityId }), [createNavigateTo]);
};

export const useNavigateToConfirmRemoveFolder = (folder: NodeTree<ListItem>) =>
  useNavigateToFn({
    params: {
      v: book.removeFolder,
      folderId: folder.data.id,
      folderName: folder.data.name,
    },
  });

export const useNavigateToShare = (entityId?: string) =>
  useNavigateToFn({
    params: { v: book.shareEntity, id: !isUndefined(entityId) ? entityId : null },
  });

export const useNavigateToQrShare = (entityId?: string) =>
  useNavigateToFn({
    params: { v: book.qrShareEntity, id: !isUndefined(entityId) ? entityId : null },
  });

export const useNavigateToConfirmRemoveParticipant = (entityId?: string) =>
  useCreateNavigateToFn({
    params: { v: book.removeParticipant, id: !isUndefined(entityId) ? entityId : null },
  });
