import { FC } from 'react';
import { useSelector } from 'react-redux';

import { ContractRole } from 'src/models/contract';
import { CardData } from 'src/v2/features/sharedEntity/types';
import { Copy } from 'src/v2/icons/Copy';
import { FileMove } from 'src/v2/icons/FileMove';
import { ContextMenuItem } from 'src/v2/components/contextMenu';
import { ContextMenuProps } from 'src/v2/features/contextMenu/types';
import { EntityContextMenu } from 'src/v2/features/sharedEntity/EntityContextMenu';
import { isContractFolder } from 'src/v2/features/quantumNavigator';
import { useGetRootFolderId } from 'src/v2/features/sharedEntity/hooks';
import { isProjectsFolder } from 'src/v2/features/quantumNavigator/utils';
import { useOpenModal, useOpenModalWithProjects } from 'src/v2/features/folderSelector/hooks';
import { ModalType } from 'src/v2/features/modal/types';
import { DropdownAddUser } from 'src/v2/icons/DropdownAddUser';
import { DropdownEditUser } from 'src/v2/icons/DropdownEditUser';
import { useEditParticipantCallback } from 'src/v2/features/entityParticipants/hooks';
import { getActivePlan } from 'src/v2/features/billing';
import {
  useNavigateToEditParticipant,
  useNavigateToInviteParticipant,
} from 'src/v2/features/contract/ContractSidebar/nav-hooks';
import {
  canCopyContract,
  canDownloadContract,
  canShareContract,
  canManageParticipantInContract,
  canMoveContract,
} from 'src/v2/features/contract/permissions/contractPermissions/contractPermissions';

export const ContractContextMenu: FC<ContextMenuProps> = (props) => {
  const plan = useSelector(getActivePlan);
  const { payload, closeOnClick } = props;
  const { id: contractId, role } = payload as CardData<ContractRole>;
  const contractRootFolderId = useGetRootFolderId(isContractFolder);
  const projectsRootFolderId = useGetRootFolderId(isProjectsFolder);

  const isAllowedToShareEntity = canShareContract(role);
  const isAllowedToDownloadContract = canDownloadContract(role);
  const isAllowedToCopyContract = canCopyContract(role, plan);
  const isAllowedToMoveContract = canMoveContract(plan);
  const isAllowedToManageParticipants = canManageParticipantInContract(role);

  const handleMove = useOpenModal({
    type: ModalType.ContractMoveTo,
    menuItemFolderId: contractRootFolderId,
    targetId: contractId,
  });

  const handleCopyTo = useOpenModalWithProjects({
    type: ModalType.ContractCopyTo,
    menuItemFolderId: contractRootFolderId,
    projectsRootFolderId,
    targetId: contractId,
  });

  const navigateToInviteParticipant = useNavigateToInviteParticipant(contractId);
  const navigateToEditParticipant = useNavigateToEditParticipant(contractId);

  const handleInviteUser = closeOnClick(navigateToInviteParticipant);
  const handleEditUser = closeOnClick(useEditParticipantCallback(navigateToEditParticipant)());

  return (
    <EntityContextMenu<ContractRole>
      {...props}
      isAllowedToDownloadEntity={isAllowedToDownloadContract}
      isAllowedToShareEntity={isAllowedToShareEntity}
      participantChildren={
        <>
          <ContextMenuItem
            icon={DropdownAddUser}
            text="Add user"
            disabled={!isAllowedToManageParticipants}
            onClick={handleInviteUser}
            data-id="addUser-menuButton"
          />
          <ContextMenuItem
            icon={DropdownEditUser}
            text="Edit user"
            onClick={handleEditUser}
            data-id="editUser-menuButton"
          />
        </>
      }
    >
      <ContextMenuItem
        icon={Copy}
        text="Copy To"
        disabled={!isAllowedToCopyContract}
        onClick={handleCopyTo}
        data-id="copyto-menuButton"
      />

      <ContextMenuItem
        icon={FileMove}
        text="Move To"
        disabled={!isAllowedToMoveContract}
        onClick={handleMove}
        data-id="movefile-menuButton"
      />
    </EntityContextMenu>
  );
};
