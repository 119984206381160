import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SidebarSettingsContext } from 'src/common/contexts/SidebarSettingsContext';

import { SidebarSettings } from './types';
import {
  getIsSidebarOpen,
  getIsSidebarHidden,
  toggleSidebarOpen,
  toggleSidebarHidden,
} from './store';

export const SidebarSettingsContextProvider: FC = ({ children }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(getIsSidebarOpen);
  const isHidden = useSelector(getIsSidebarHidden);
  const toggleIsOpen = useCallback(() => dispatch(toggleSidebarOpen()), [dispatch]);
  const toggleIsHidden = useCallback(() => dispatch(toggleSidebarHidden()), [dispatch]);

  const ctx: SidebarSettings = {
    isCollapsedMobile: !isOpen,
    toggleIsCollapsedMobile: toggleIsOpen,
    isHidden,
    toggleIsHidden,
  };

  return <SidebarSettingsContext.Provider value={ctx}>{children}</SidebarSettingsContext.Provider>;
};
