import { SagaIterator } from 'redux-saga';
import { call, delay, put, select, takeLatest } from '@redux-saga/core/effects';
import { toastr } from 'react-redux-toastr';
import { PayloadAction } from '@reduxjs/toolkit';
import { isNull, get, isEmpty } from 'lodash';

import { emitRemoveUserFromOrganization } from 'src/v2/features/profile';
import { storeData } from 'src/v2/features/objectsStorage/objectsStorageSlice';
import { getUserByField } from 'src/api/usersApi';
import { fetchBillingDetails } from 'src/v2/features/billing/store/billingReducers';
import {
  inviteUserToOrganization,
  removeInviteUserFromOrganization,
  setOrganizationLogoData,
  updateInviteUserInOrganization,
  getOrganizationV2,
  setOrganizationDataApi,
} from 'src/api/organizationApi';
import { handleErrorSaga } from 'src/utils/handleErrorSaga';
import { ObjectSerialized } from 'src/common/types';
import { fetchOrganization } from 'src/shared/organization/actions';
import { getCurrentOrganizationId } from 'src/shared/organization/organizationSelectors';
import {
  clearFoundUser,
  fillFoundUser,
  findUserByFiled,
  inviteUser,
  removeFoundUserReducer,
  removeUser,
  setOrganizationLogo,
  startLoading,
  stopLoading,
  updateInviteUser,
  fillOrganizationData,
  setCurrentOrganization,
  setCurrentOrganizationOwner,
  setOrganizationData,
} from './organizationReducer';
import { AvatarPayload, FindUser } from '../types';
import { SetOrganizationData } from './types';
import {
  EditUserInOrganizationActionPayload,
  InviteUserToOrganizationActionPayload,
} from 'src/v2/boundary/actionPayload/organization';

function* removeFoundUser(): SagaIterator {
  yield put(removeFoundUserReducer());
}

export function* fetchOrganizationSaga(): SagaIterator {
  try {
    yield put(startLoading());
    const response = yield call(getOrganizationV2);
    const normalizedResponse = {
      ...response,
      data: response.data.filter(({ id }: { id?: string }) => !isEmpty(id)),
    };
    yield put(storeData(normalizedResponse));

    const organizations = ObjectSerialized.dataToBaseObjects(normalizedResponse.data).filter(
      (record) => record.type === 'organization',
    );
    if (!isEmpty(organizations)) {
      yield put(fillOrganizationData(organizations));
      const orgId = yield select(getCurrentOrganizationId);
      if (isNull(orgId)) {
        const firstOrgId = get(organizations, '[0].id');
        yield put(setCurrentOrganization(firstOrgId));
        const ownerId = get(normalizedResponse.data, '[0].attributes.ownerId');
        yield put(setCurrentOrganizationOwner(ownerId));
      }
    }
  } catch (err) {
    toastr.error('Error', get(err, 'response.data.error.message') || get(err, 'message'));
  } finally {
    yield put(stopLoading());
  }
}

function* findUserByField({ payload }: PayloadAction<FindUser>): SagaIterator {
  try {
    const user = yield call(getUserByField, payload.fieldName, payload.fieldValue);
    if (isEmpty(user.data)) {
      yield call(removeFoundUser);
    } else {
      yield put(storeData(user));
      yield put(fillFoundUser(user));
    }
  } catch (error) {
    yield call(removeFoundUser);
    yield call(handleErrorSaga, error);
  }
}

function* inviteUserSaga({
  payload,
}: PayloadAction<InviteUserToOrganizationActionPayload>): SagaIterator {
  try {
    yield put(startLoading());
    const organization = yield call(inviteUserToOrganization, payload);
    yield put(storeData(organization));
    yield put(clearFoundUser());
    yield put(fetchBillingDetails());
    toastr.success('Success', 'Invite has been sent');
    yield put(stopLoading());
  } catch (error) {
    yield call(handleErrorSaga, error);
    yield put(stopLoading());
  }
}

function* updateInviteUserSaga({
  payload,
}: PayloadAction<EditUserInOrganizationActionPayload>): SagaIterator {
  try {
    yield put(startLoading());
    const organization = yield call(updateInviteUserInOrganization, payload);
    yield put(storeData(organization));
    yield put(clearFoundUser());
    toastr.success('Success', 'User has been updated');
    yield put(stopLoading());
  } catch (error) {
    yield call(handleErrorSaga, error);
    yield put(stopLoading());
  }
}

// @TODO Reimplement this is not the best solution in general
// using authSlice is misleading and organizationId should be fetched from other place
// updating org after delete is also not the best way, should be delete user from objectsStore
function* removeUserSaga({ payload: userId }: PayloadAction<string>): SagaIterator {
  try {
    yield put(startLoading());
    yield call(removeInviteUserFromOrganization, {
      userId,
    });
    yield put(fetchOrganization());
    yield put(fetchBillingDetails());
    toastr.success('Success', `User removed successfully`);
    yield delay(200);
    yield call(emitRemoveUserFromOrganization);
    yield put(stopLoading());
  } catch (error) {
    yield call(handleErrorSaga, error);
    yield put(stopLoading());
  }
}

export function* setOrganizationDataSaga(action: PayloadAction<SetOrganizationData>): SagaIterator {
  try {
    const responseData = yield call(setOrganizationDataApi, action.payload);
    yield put(storeData(responseData));

    toastr.success('Success', 'Your organization profile was updated.');
  } catch (error) {
    yield call(handleErrorSaga, error);
  }
}

export function* setOrganizationLogoSaga(action: PayloadAction<AvatarPayload>): SagaIterator {
  try {
    yield put(startLoading());
    yield call(setOrganizationLogoData, action.payload);
    yield put(fetchOrganization());
    yield put(stopLoading());
  } catch (error) {
    yield call(handleErrorSaga, error);
  }
}

export function* watchOrganizationSagas(): SagaIterator {
  yield takeLatest(findUserByFiled, findUserByField);
  yield takeLatest(fetchOrganization, fetchOrganizationSaga);
  yield takeLatest(clearFoundUser, removeFoundUser);
  yield takeLatest(inviteUser, inviteUserSaga);
  yield takeLatest(updateInviteUser, updateInviteUserSaga);
  yield takeLatest(removeUser, removeUserSaga);
  yield takeLatest(setOrganizationData, setOrganizationDataSaga);
  yield takeLatest(setOrganizationLogo, setOrganizationLogoSaga);
}
