import { MutableRefObject, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { isNil } from 'lodash';

import { checkboxDiffRenderer, checkBoxRenderer } from './utils';
import { FieldDiff, FieldUpdateType } from '../../../../models/editor';
import { CHECKBOX_ATTRIBUTES, CHECKBOX_CLASSES } from './constants';

export const useCheckBoxStub = (
  container: MutableRefObject<HTMLDivElement | undefined>,
  content: string,
  isCheckboxesDisabled: boolean,
  isUpdating?: boolean,
) => {
  const [onCheckboxChanged, setOnCheckboxChanged] = useState<{ id: string; value: boolean } | null>(
    null,
  );
  const [onCheckboxDiffReview, setOnCheckboxDiffReview] = useState<{
    id: string;
    value: boolean;
    fieldUpdateTyp: FieldUpdateType;
  } | null>(null);

  const checkboxHandler = (isCheckboxesDisabled, isUpdating) => {
    if (isCheckboxesDisabled) {
      disableCheckBoxes(true);
    } else if (!isNil(isUpdating)) {
      disableCheckBoxes(isUpdating);
    }
  };

  const checkboxHandlerCallback = useCallback(() => {
    checkboxHandler(isCheckboxesDisabled, isUpdating);
  }, [isCheckboxesDisabled, isUpdating]);

  useEffect(() => {
    checkboxHandlerCallback();
  }, [checkboxHandlerCallback]);

  const disableCheckBoxes = (isDisable: boolean) => {
    if (!isNil(container.current)) {
      const checkboxes = container.current.querySelectorAll(`.${CHECKBOX_CLASSES.DW_CHECKBOX}`);
      if (!isNil(checkboxes) && checkboxes.length > 0) {
        checkboxes.forEach((item) => {
          if (isDisable) {
            item.classList.add(CHECKBOX_CLASSES.CHECKBOX_DISABLED);
          } else {
            item.classList.remove(CHECKBOX_CLASSES.CHECKBOX_DISABLED);
          }
        });
      }
    }
  };

  const changeEvent = (id: string, value: boolean) =>
    new CustomEvent('changeEvent', {
      detail: { id, value },
    });

  const diffBulkCheckboxes = (diffs: FieldDiff[], canReview: boolean) => {
    if (!isNil(container.current)) {
      const checkboxes = container.current.querySelectorAll(`.${CHECKBOX_CLASSES.DW_CHECKBOX}`);

      diffs.forEach(({ newValue, name }) => {
        checkboxes.forEach((element) => {
          if (element.getAttribute(CHECKBOX_ATTRIBUTES.ID) === name && !isNil(container.current)) {
            const newValueToBoolean = newValue === 'true';
            //TODO move functions higher
            const acceptClick = () => {
              const clearCheckBox = checkBoxRenderer(name, newValueToBoolean);
              diffContainer.replaceWith(clearCheckBox);
              setOnCheckboxDiffReview({
                id: name,
                value: newValueToBoolean,
                fieldUpdateTyp: FieldUpdateType.Accept,
              });
            };

            const rejectClick = () => {
              const clearCheckBox = checkBoxRenderer(name, !newValueToBoolean);
              diffContainer.replaceWith(clearCheckBox);
              setOnCheckboxDiffReview({
                id: name,
                value: !newValueToBoolean,
                fieldUpdateTyp: FieldUpdateType.Reject,
              });
            };
            const diffContainer = checkboxDiffRenderer(
              newValueToBoolean,
              canReview,
              acceptClick,
              rejectClick,
            );
            element.replaceWith(diffContainer);
          }
        });
      });
    }
  };

  useLayoutEffect(() => {
    let checkboxes: HTMLDivElement[] = [];

    const handleChange = (e) => {
      const checkbox = e.target;
      const checkedAttr = checkbox.getAttribute(CHECKBOX_ATTRIBUTES.CHECKED);
      const checked = checkedAttr === 'true';
      const id = checkbox.getAttribute(CHECKBOX_ATTRIBUTES.ID);

      if (checked) checkbox.classList.remove(CHECKBOX_CLASSES.CHECKBOX_ACTIVE);
      if (!checked) checkbox.classList.add(CHECKBOX_CLASSES.CHECKBOX_ACTIVE);

      checkbox.setAttribute(CHECKBOX_ATTRIBUTES.CHECKED, checked ? 'false' : 'true');
      checkbox.dispatchEvent(changeEvent(id, !checked));
    };

    const onChange = (event) => {
      setOnCheckboxChanged({ id: event.detail.id, value: event.detail.value });
    };

    if (!isNil(container.current)) {
      checkboxes = Array.from(
        container.current.querySelectorAll(`.${CHECKBOX_CLASSES.DW_CHECKBOX}`),
      );
      checkboxes.forEach((item) => {
        item.addEventListener('click', handleChange);
        item.addEventListener('changeEvent', onChange);
      });
    }

    return () => {
      checkboxes.forEach((item) => {
        item.removeEventListener('click', handleChange);
        item.removeEventListener('click', onChange);
      });
    };
  }, [content]);

  return { diffBulkCheckboxes, onCheckboxChanged, onCheckboxDiffReview };
};
