import React, { FC } from 'react';

import { DynamicSettingsContextProviderForShared } from './providers/DynamicSettingsContextProviderForShared';
import { SidebarNodeContextProvider } from './providers/SidebarNodeContextProvider';
import { TemplateListPageWithoutContext } from './TemplateListPageWithoutContext';

export const TemplateSharedListPage: FC = () => (
  <SidebarNodeContextProvider>
    <DynamicSettingsContextProviderForShared>
      <TemplateListPageWithoutContext />
    </DynamicSettingsContextProviderForShared>
  </SidebarNodeContextProvider>
);
