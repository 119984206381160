import { LanguageCode } from 'src/instruments/languages';
import {
  SignatureCompleteModel,
  SignatureStampModel,
  SignatureWithStampModel,
  StampId,
} from 'src/models/signature';

export const shouldUseHanko = (language?: LanguageCode): boolean => language === LanguageCode.JPN;

export const getStampById = (
  list: SignatureStampModel[],
  stampId?: StampId,
): SignatureStampModel | undefined => list.find((stamp) => stamp.stamp_id === stampId);

export const getHankoUrlIfIncluded = (
  isHankoStampIncluded: boolean,
  hankoUrl?: string | null,
): string | undefined => (isHankoStampIncluded && hankoUrl ? hankoUrl : undefined);

export const mergeSignaturesWithStamps = (
  signatures: SignatureCompleteModel[],
  stamps: SignatureStampModel[],
): SignatureWithStampModel[] =>
  signatures.map((item) => {
    const stamp = stamps.find((stamp) => stamp.stamp_id === item.default_stamp_id);
    return { ...item, default_stamp: stamp };
  });
