import { isUndefined, size, head } from 'lodash';

import { CardProjectEntity, ProjectRole } from 'src/v2/entities/project';
import { createErrorFactory } from 'src/common/error/createErrorFactory';
import { CardParticipantEntity } from 'src/v2/entities/participants';
import defaultAvatar from 'src/assets/placeholders/user-avatar.png';

import { ProjectObjectsCompleteModel } from './projectObjects';
import { ProjectParticipantCompleteModel } from './projectParticipant';

export interface ProjectCardBaseModel {
  created_at: string;
  description?: string;
  parent_id: string;
  project_id: string;
  title: string;
}

export interface ProjectCardCompleteModel extends ProjectCardBaseModel {
  project_objects?: ProjectObjectsCompleteModel[];
  project_participants?: ProjectParticipantCompleteModel[];
}

export const convertProjectCardCompleteModelToCardProjectEntity = (
  projectCardModel: ProjectCardCompleteModel,
): CardProjectEntity => {
  const createError = createErrorFactory('convertProjectCardCompleteModelToCardProjectEntity');
  const { title, description, project_id } = projectCardModel;

  if (isUndefined(projectCardModel.project_participants)) {
    throw createError(`project_participants can't be undefined`);
  }

  if (size(projectCardModel.project_participants) === 0) {
    throw createError(`project_participants can't be empty`);
  }

  const participants = projectCardModel.project_participants.reduce(
    (convertedParticipants, currentParticipant) => {
      const user = head(currentParticipant.project_participant_users);

      if (isUndefined(user)) {
        throw createError(`project_participant_users can't be undefined or empty`);
      }

      const { id, role } = currentParticipant;
      const { user_id, first_name, last_name, avatar, email } = user;
      const result: CardParticipantEntity<ProjectRole> = {
        id,
        userId: user_id,
        roleId: role,
        firstName: first_name || '',
        lastName: last_name || '',
        avatar: avatar || defaultAvatar,
        email,
      };

      convertedParticipants.push(result);

      return convertedParticipants;
    },
    [] as CardParticipantEntity<ProjectRole>[],
  );

  const ownerParticipant = participants.find(({ roleId }) => roleId === ProjectRole.ProjectOwner);

  if (isUndefined(ownerParticipant)) {
    throw createError(`ownerParticipant can't be undefined`);
  }

  return {
    id: project_id,
    title,
    description,
    ownerParticipant,
  };
};
