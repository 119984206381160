// Core
import React, { useState, useEffect, ReactNode } from 'react';
import { WrappedFieldProps } from 'redux-form';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { LanguageCode, LanguageFormat } from 'src/instruments/languages';
import { isValidDate } from 'src/v2/features/profile';

type Props = {
  label?: string;
  id?: string;
  disabled?: boolean;
  language?: LanguageCode;
} & WrappedFieldProps;

const getMonths = () => [
  { value: '0', label: i18n(translationKeys.months.jan) },
  { value: '1', label: i18n(translationKeys.months.feb) },
  { value: '2', label: i18n(translationKeys.months.mar) },
  { value: '3', label: i18n(translationKeys.months.apr) },
  { value: '4', label: i18n(translationKeys.months.may) },
  { value: '5', label: i18n(translationKeys.months.jun) },
  { value: '6', label: i18n(translationKeys.months.jul) },
  { value: '7', label: i18n(translationKeys.months.aug) },
  { value: '8', label: i18n(translationKeys.months.sep) },
  { value: '9', label: i18n(translationKeys.months.oct) },
  { value: '10', label: i18n(translationKeys.months.nov) },
  { value: '11', label: i18n(translationKeys.months.dec) },
];

const getDefaultDate = (date: string) => {
  const defaultDate = isValidDate(date) ? new Date(date) : new Date();
  return {
    day: defaultDate.getDate().toString(),
    month: defaultDate.getMonth().toString(),
    year: defaultDate.getFullYear().toString(),
  };
};

export const DateSelect: React.FC<Props> = ({ input, ...rest }) => {
  const { value, onChange } = input;
  const defaultDate = getDefaultDate(value);
  const { language = LanguageFormat.eng } = rest;
  const [day, setDay] = useState(defaultDate.day);
  const [month, setMonth] = useState(defaultDate.month);
  const [year, setYear] = useState(defaultDate.year);
  const [monthDays, setMonthDays] = useState(31);

  useEffect(() => {
    const checkDaysInMonth = (): void => {
      const date = new Date(Number(year), Number(month), 32);
      setMonthDays(32 - date.getDate());
      if (Number(day) > 32 - date.getDate()) {
        setDay('1');
      }
      if (!year) {
        setMonth('');
        setDay('');
      }
      if (!month) {
        setDay('');
      }
    };
    checkDaysInMonth();
    if (year && month && day) {
      try {
        const date = new Intl.DateTimeFormat(LanguageFormat[language], {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        }).format(new Date(Number(year), Number(month), Number(day)));

        const stringDate = new Date(date).toISOString();
        onChange(stringDate);
      } catch (e) {
        console.warn(e);
      }
    }
  }, [year, month, day, language, onChange]);

  const getOptionsYears = (): ReactNode => {
    const options: JSX.Element[] = [];
    for (let i = 1900; i <= new Date().getFullYear(); i += 1) {
      options.push(
        <option key={i.toString()} value={i.toString()}>
          {i.toString()}
        </option>,
      );
    }

    return options.reverse();
  };

  const getOptionsMonth = (): ReactNode => {
    return getMonths().map((item) => {
      return (
        <option key={item.value} value={item.value}>
          {item.label}
        </option>
      );
    });
  };

  const getOptionsDays = (): ReactNode => {
    const options: JSX.Element[] = [];
    for (let i = 1; i <= monthDays; i += 1) {
      options.push(
        <option key={i.toString()} value={i.toString()}>
          {i.toString()}
        </option>,
      );
    }

    return options;
  };

  const changeYear = (val: string): void => {
    onChange(val);
    setYear(val);
  };

  const changeMonth = (val: string): void => {
    onChange(val);
    setMonth(val);
  };

  const changeDay = (val: string): void => {
    onChange(val);
    setDay(val);
  };

  return (
    <>
      <div className="c-form__column c-form__column--3">
        <div className="c-select__selected">
          <select
            name="year"
            value={year}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
              changeYear(e.target.value);
            }}
            placeholder={i18n(translationKeys.profile.selectYear)}
          >
            {getOptionsYears()}
          </select>
        </div>
      </div>
      <div className="c-form__column c-form__column--3">
        <div className="c-select__selected">
          <select
            name="month"
            value={month}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
              changeMonth(e.target.value);
            }}
            placeholder={i18n(translationKeys.profile.selectMonth)}
            disabled={!year}
          >
            {getOptionsMonth()}
          </select>
        </div>
      </div>
      <div className="c-form__column c-form__column--3">
        <div className="c-select">
          <div className="c-select__selected">
            <select
              name="day"
              value={day}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                changeDay(e.target.value);
              }}
              placeholder={i18n(translationKeys.profile.selectDay)}
              disabled={!year || !month}
            >
              {getOptionsDays()}
            </select>
          </div>
        </div>
      </div>
    </>
  );
};
