import React, { PropsWithChildren } from 'react';

interface Props {
  label: string;
  dataType?: string;
}

export const MetadataViewRow = ({
  dataType,
  label,
  children,
}: PropsWithChildren<Props>): JSX.Element => (
  <div data-type={dataType} className="c-employmenеt__row">
    <span className="c-employmenеt__title">{label}: </span>
    <span className="c-employmenеt__text">{children}</span>
  </div>
);
