// Core
import React, { useCallback, useRef, useState } from 'react';
import { isUndefined } from 'lodash';

import { MimeType } from 'src/common/types';
import { useOnClickOutside } from 'src/common/hooks/useOnClickOutside';
import { Attachment as CAttachment } from 'src/v2/components/Attachments';
import { AttachmentType } from 'src/v2/components/Attachments/types';

interface Props {
  file: {
    id: string;
    name: string;
    lastModified: Date;
    extension: MimeType;
    url: string;
  };
  onImgClick: (id: string) => void;
  onDelete: (id: string) => void;
  deleteCallback?: () => void;
  canDelete: boolean;
}

const AttachmentTypeByMimeType: Record<MimeType, AttachmentType> = {
  [MimeType.pdf]: AttachmentType.Doc,
  [MimeType.doc]: AttachmentType.Doc,
  [MimeType.docx]: AttachmentType.Doc,
  [MimeType.xls]: AttachmentType.Doc,
  [MimeType.xlsx]: AttachmentType.Doc,
  [MimeType.ppt]: AttachmentType.Doc,
  [MimeType.pptx]: AttachmentType.Doc,
  [MimeType.png]: AttachmentType.Img,
  [MimeType.jpeg]: AttachmentType.Img,
  [MimeType.gif]: AttachmentType.Img,
};

export const Attachment: React.FC<Props> = ({
  file,
  onImgClick,
  onDelete,
  deleteCallback,
  canDelete = true,
}) => {
  const { id, name, lastModified, url, extension } = file;

  const containerRef = useRef(null);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  const clickHandler = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onImgClick(id);
    },
    [id, onImgClick],
  );

  const cancelConfirmDeleteHandler = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setShowConfirmPopup(false);
    },
    [setShowConfirmPopup],
  );

  const deleteHandler = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setShowConfirmPopup(true);
      if (!isUndefined(deleteCallback)) deleteCallback();
    },
    [setShowConfirmPopup, deleteCallback],
  );

  const confirmDeleteHandler = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onDelete(id);
      setShowConfirmPopup(false);
    },
    [id, onDelete, setShowConfirmPopup],
  );

  useOnClickOutside(containerRef, cancelConfirmDeleteHandler);

  return (
    <>
      <CAttachment
        ref={containerRef}
        showDeleteConfirmation={showConfirmPopup}
        title={name}
        lastModified={lastModified.toLocaleString()}
        type={AttachmentTypeByMimeType[extension]}
        url={url}
        showDelete={canDelete}
        onClickIcon={clickHandler}
        onClickDelete={deleteHandler}
        onClickConfirmDelete={confirmDeleteHandler}
        onClickCancelDelete={cancelConfirmDeleteHandler}
      />
    </>
  );
};
