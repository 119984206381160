import { useCallback, useRef, MouseEventHandler } from 'react';
import { useDispatch } from 'react-redux';

import { ContextMenuBook } from 'src/app/types';

import { openContextMenu } from './store';

type MouseEventsMap = 'contextmenu' | 'click' | 'dblclick';
export const useContextMenu = <PayloadType>(
  sourceType: ContextMenuBook,
  payload: PayloadType,
  eventType: MouseEventsMap = 'contextmenu',
) => {
  const ref = useRef<HTMLElement | null>(null);
  const dispatch = useDispatch();

  const handler: any = useCallback<MouseEventHandler>(
    (event) => {
      dispatch(
        openContextMenu({
          sourceType,
          payload,
          position: { x: event.clientX, y: event.clientY },
        }),
      );
      event.preventDefault();
      event.stopPropagation();
    },
    [dispatch, payload, sourceType],
  );

  const attachEvent = useCallback(
    (node: HTMLElement | null) => {
      if (ref.current) {
        ref.current.removeEventListener(eventType, handler);
      }

      if (node) {
        node.addEventListener(eventType, handler);
      }

      ref.current = node;
    },
    [eventType, handler],
  );

  return attachEvent;
};
