import React from 'react';
import { Flex, Text, Image } from '@theme-ui/components';

import tokenIcon from 'src/assets/tokens-icon.png';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

const style = {
  container: {
    m: '10px 0px',
    alignItems: 'center',
  },
  text: {
    flex: 1,
    maxWidth: '680px',
    color: '#707070',
    letterSpacing: '0px',
    fontSize: '12px',
  },
  image: {
    mr: '7px',
    width: '56px',
    height: '56px',
  },
};

export const LegalAgreement: React.FC = () => (
  <Flex sx={style.container}>
    <Image src={tokenIcon} sx={style.image} />
    <Text sx={style.text}>{i18n(translationKeys.legalAgreement.description)}</Text>
  </Flex>
);
