import { SagaIterator } from 'redux-saga';
import { apiClient } from 'src/common/client';
import { ObjectSerialized } from 'src/common/types';

export function landingFormApi(form: object, type: string): SagaIterator<ObjectSerialized | null> {
  const data = {
    attributes: {
      ...form,
    },
  };

  return apiClient.post(`/${type}`, { data });
}

export enum LandingForm {
  contactUs = 'contactUs',
  leads = 'leads',
  subscribe = 'subscribe',
}
