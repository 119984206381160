import { ReplaySubject } from 'rxjs';
import { AxiosRequestConfig } from 'axios';

import { baseClientFactory, Client } from './baseClient';
import { SelfResovedClientFactory } from './selfResovedClientFactory';
import { basePromisedClientFactory, PromisedClient } from './basePromisedClient';

export const getDefaultHeaders = (token: string, orgId?: string): AxiosRequestConfig => {
  const defaultHeaders = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  if (orgId) {
    return {
      ...defaultHeaders,
      headers: {
        ...defaultHeaders.headers,
        organizationid: orgId,
      },
    };
  }

  return defaultHeaders;
};

const configFactory =
  (
    url: string,
    token: string,
    orgId?: string,
  ): { (restConfig: AxiosRequestConfig): AxiosRequestConfig } =>
  (restConfig): AxiosRequestConfig => ({
    ...getDefaultHeaders(token, orgId),
    baseURL: url,
    timeout: 30000,
    ...restConfig,
  });

interface ApiClientFactory {
  (url?: string, token?: string, orgId?: string): {
    client: Client;
    promisedClient: PromisedClient;
  };
}

export const apiClientFactory: ApiClientFactory = (url = '', token = '', orgId) => {
  return {
    client: baseClientFactory(url, configFactory(url, token, orgId)),
    promisedClient: basePromisedClientFactory(url, configFactory(url, token)),
  };
};

// @TODO remove after fixing deprecated libs
// eslint-disable-next-line import/no-mutable-exports
export let { promisedClient: promisedApiClient } = apiClientFactory();

const $apiClient = new ReplaySubject<Client>(1);

export const setApiClient = ({
  client,
  promisedClient,
}: {
  client: Client;
  promisedClient: PromisedClient;
}): void => {
  $apiClient.next(client);
  promisedApiClient = promisedClient;
};

export const apiClient = SelfResovedClientFactory($apiClient);
