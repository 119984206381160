// Core
import React from 'react';
// Components
import { sidebarControllerFactory } from 'src/v2/features/sidebarController';
import { ParsedQuery } from 'query-string';
import { useUrlManagerHash } from 'src/common/hooks/urlManager';
import { ProfileSidebarNavView } from './ProfileSidebarNavView';
import { book } from './book';
import { ProfileCreateUserView } from './ProfileCreateUserView';
import { ProfileEditUserView } from './ProfileEditUserView';
import { ProfileDeleteUserView } from './ProfileDeleteUserView';

const sidebarName = 'notificationSidebar';

const [, , , Sidebar] = sidebarControllerFactory(sidebarName, [
  {
    path: book.nav,
    render: ProfileSidebarNavView,
  },
  {
    path: book.createUser,
    render: ProfileCreateUserView,
  },
  {
    path: book.editUser,
    render: ProfileEditUserView,
  },
  {
    path: book.deleteUser,
    render: ProfileDeleteUserView,
  },
]);

const useNormalizeValues = (): ParsedQuery<string> => {
  const [hash] = useUrlManagerHash();
  const { v, id, ...rest } = hash;

  const normalizedV = v || book.default;

  return {
    ...rest,
    v: normalizedV,
    userId: id,
  };
};

export const ProfileSidebar: React.FC = () => {
  const criteria = useNormalizeValues();

  return <Sidebar criteria={criteria} />;
};
