import { FC } from 'react';
import { Dictionary, get } from 'lodash';
import { NotificationTarget } from 'src/v2/entities/notification';
import { isWrapperSupported } from '../utils';
import { getWrapper } from './Wrappers';

interface Props {
  payload: Dictionary<string>;
  target: NotificationTarget;
  token: string;
}

export const Chunk: FC<Props> = ({ payload, target, token }) => {
  // Use key as a value when no such keys in the payload
  const value = get(payload, token, token);

  if (!isWrapperSupported(token)) {
    return <>{value}</>;
  }

  const Wrapper = getWrapper(token);

  return (
    <Wrapper key={token} target={target}>
      {value}
    </Wrapper>
  );
};
