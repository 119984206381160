import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery, takeLatest, delay } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { isString } from 'lodash';

import { listenWSEvents, sendWSCommand } from 'src/api/socket/connection';
import { socketConnected, socketDisconnected } from 'src/api/socket/store';
import { SocketConnectedPayload } from 'src/v2/boundary/actionPayload/socket';
import { WSObjectSerialized } from 'src/common/types';
import { storeData } from 'src/v2/features/objectsStorage/objectsStorageSlice';

import { ChatModel, ChatMessage, RoomPayload } from '../types';
import {
  chatContent,
  chatLoadMore,
  chatMessage,
  chatUnreadCount,
  closeChat,
  closeCurrentChat,
  closeCurrentRoom,
  markMessagesAsRead,
  newChatMessage,
  openChat,
  openRoom,
  openCurrentChat,
  prependMessages,
  roomContent,
  sendMessage,
  setChatContent,
  setRoomContent,
  setSubscribedRoomPayload,
  setIsLoading,
  setIsSending,
  setIsDisconnected,
  setShouldStickToBottom,
} from './chatReducers';
import {
  currentChatIdSelector,
  currentChatMessages,
  getCurrentMessage,
  defaultChatSelector,
  subscribedRoomPayloadSelector,
  getIsSending,
} from './chatSelectors';

const MESSAGES_LIMIT = 100;

enum ChatWSCommand {
  RoomSubscribe = 'chat/roomSubscribe',
  RoomUnsubscribe = 'chat/roomUnSubscribe',
  ChatSubscribe = 'chat/chatSubscribe',
  ChatUnsubscribe = 'chat/chatUnSubscribe',
  SendMessage = 'chat/sendMessage',
  ChatMessages = 'chat/chatMessages',
  MarkMessagesViewed = 'chat/markMessagesViewed',
}

function* openRoomSaga({ payload }: PayloadAction<RoomPayload>): Generator {
  try {
    yield put(closeCurrentRoom());
    // todo: introduce either nonce or input event channel to only proceed as unsubscribe is done
    yield delay(100);
    yield call(sendWSCommand, { command: ChatWSCommand.RoomSubscribe, payload });
    yield put(setSubscribedRoomPayload(payload));
  } catch (err) {
    toastr.error('Error', 'Error loading chat');
    console.error('openRoomSaga: ', err);
  }
}

function* openChatSaga({ payload }: PayloadAction<string>): Generator {
  try {
    yield put(closeCurrentChat());
    yield put(setIsLoading(true));
    // todo: introduce either nonce or input event channel to only proceed as unsubscribe is done
    yield delay(100);
    yield call(sendWSCommand, {
      command: ChatWSCommand.ChatSubscribe,
      payload: { chatId: payload },
    });
    // yield put(setChatId(payload));
    yield put(setShouldStickToBottom(true));
  } catch (err) {
    toastr.error('Error', 'Error loading chat');
    console.error('openChatSaga: ', err);
  }
}

function* openCurrentChatSaga(): Generator {
  const chatId = yield select(currentChatIdSelector);
  if (isString(chatId)) yield put(openChat(chatId));
}

function* sendMessageSaga(): Generator {
  try {
    yield put(setIsSending(true));
    const chatId = yield select(currentChatIdSelector);
    const message = yield select(getCurrentMessage);

    yield call(sendWSCommand, {
      command: ChatWSCommand.SendMessage,
      payload: { chatId, message },
    });
  } catch (err) {
    toastr.error('Error', 'Error sending message');
    console.error('sendMessageSaga: ', err);
  }
}

function* storeDataSaga({ payload }: PayloadAction<WSObjectSerialized>): Generator {
  yield put(storeData(payload));
}

function* chatMessageSaga({ payload }: PayloadAction<WSObjectSerialized>): Generator {
  yield put(prependMessages(payload));
}

function* openDefaultChat(): Generator {
  const defaultChat = (yield select(defaultChatSelector) as unknown) as ChatModel | null;

  if (defaultChat) {
    yield put(openChat(defaultChat.id));
  } else {
    yield put(closeCurrentChat());
  }
}

function* roomContentSaga({ payload }: PayloadAction<WSObjectSerialized>): Generator {
  yield put(storeData(payload));
  yield put(setRoomContent(payload));
  yield call(openDefaultChat);
}

function* chatContentSaga({ payload }: PayloadAction<WSObjectSerialized>): Generator {
  yield put(storeData(payload));
  yield put(setChatContent(payload));
  yield put(markMessagesAsRead());
  yield put(setIsLoading(false));
}

function* chatLoadMoreSaga(): Generator {
  try {
    const chatId = yield select(currentChatIdSelector);
    const messages = (yield select(currentChatMessages)) as ChatMessage[];

    yield call(sendWSCommand, {
      command: ChatWSCommand.ChatMessages,
      payload: { chatId, messagesLimit: MESSAGES_LIMIT, messagesOffset: messages.length },
    });
    // yield put(setChatId(payload));
  } catch (err) {
    toastr.error('Error', 'Error loading more message');
    console.error('chatLoadMoreSaga: ', err);
  }
}

function* closeCurrentChatSaga(): Generator {
  try {
    const chatId = yield select(currentChatIdSelector);

    if (chatId) {
      yield call(sendWSCommand, { command: ChatWSCommand.ChatUnsubscribe, payload: { chatId } });
      yield put(closeChat());
    }
  } catch (err) {
    toastr.error('Error', 'Error closing current room');
    console.error('closeCurrentChatSaga: ', err);
  }
}

function* closeCurrentRoomSaga(): Generator {
  try {
    const currRoom = yield select(subscribedRoomPayloadSelector);

    if (currRoom) {
      yield call(sendWSCommand, { command: ChatWSCommand.RoomUnsubscribe, payload: currRoom });
      yield put(setSubscribedRoomPayload(null));
    }
  } catch (err) {
    toastr.error('Error', 'Error closing current room');
    console.error('closeCurrentRoomSaga: ', err);
  }
}

function* markMessagesAsReadSaga(): Generator {
  const chatId = yield select(currentChatIdSelector);
  yield call(sendWSCommand, {
    command: ChatWSCommand.MarkMessagesViewed,
    payload: {
      chatId,
    },
  });
}

function* socketConnectedSaga({ payload }: PayloadAction<SocketConnectedPayload>): Generator {
  if (payload.initialConnection) return;
  yield put(setIsDisconnected(false));
}

function* socketDisconnectedSaga(): Generator {
  const isSending = yield select(getIsSending);
  if (isSending) {
    toastr.error('Error', 'Error sending message');
  }
  yield put(setIsDisconnected(true));
  yield put(setIsSending(false));
}

export function* watchChatSagas(): Generator {
  yield takeLatest(roomContent, roomContentSaga);
  yield takeLatest(chatContent, chatContentSaga);
  yield takeLatest(chatUnreadCount, storeDataSaga);
  yield takeLatest(openRoom, openRoomSaga);
  yield takeLatest(openChat, openChatSaga);
  yield takeLatest(openCurrentChat, openCurrentChatSaga);
  yield takeEvery(sendMessage, sendMessageSaga);
  yield takeEvery(closeCurrentRoom, closeCurrentRoomSaga);
  yield takeEvery(closeCurrentChat, closeCurrentChatSaga);
  yield takeEvery(chatMessage, chatMessageSaga);
  yield takeLatest(chatLoadMore, chatLoadMoreSaga);
  yield takeLatest(markMessagesAsRead, markMessagesAsReadSaga);
  yield takeLatest(socketConnected, socketConnectedSaga);
  yield takeLatest(socketDisconnected, socketDisconnectedSaga);
}

export const initializeChatStore = (): void => {
  listenWSEvents(roomContent, chatContent, chatUnreadCount, chatMessage, newChatMessage);
};
