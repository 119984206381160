import React from 'react';

import { UserEdit } from 'src/v2/icons/UserEdit';
import { EditComment } from 'src/v2/icons/EditComment';
import { Eye } from 'src/v2/icons/Eye';
import { Pencil } from 'src/v2/icons/Pencil';

interface Props {
  icon: IconOption;
}

export enum IconOption {
  UserEdit,
  Pencil,
  Comment,
  View,
}

const componentMap = {
  [IconOption.UserEdit]: UserEdit,
  [IconOption.Pencil]: Pencil,
  [IconOption.Comment]: EditComment,
  [IconOption.View]: Eye,
};

export const Icon: React.FC<Props> = ({ icon }) => {
  const InnerIcon = componentMap[icon];
  return (
    <div className="c-card__edit">
      <InnerIcon />
    </div>
  );
};
