import { isUndefined } from 'lodash';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { DocumentRole } from 'src/models/document';
import { canAddUser } from 'src/v2/features/document/permissions/manageParticipantsPermissions/manageParticipantsPermissions';
import { SelectOptionType } from 'src/v2/components/Form/withReduxForm';
import {
  canEditUserPermissions,
  UserWithRole,
} from 'src/v2/features/document/permissions/manageParticipantsPermissions/manageParticipantsPermissions';

const getParticipantFormAvailableRoles = (): { value: DocumentRole; label: string }[] => {
  return [
    {
      value: DocumentRole.Manager,
      label: i18n(translationKeys.forms.document.userRole.managerTitle),
    },
    {
      value: DocumentRole.Editor,
      label: i18n(translationKeys.forms.document.userRole.editorTitle),
    },
    {
      value: DocumentRole.Acknowledger,
      label: i18n(translationKeys.forms.document.userRole.acknowledgerTitle),
    },
    {
      value: DocumentRole.Commentator,
      label: i18n(translationKeys.forms.document.userRole.commentatorTitle),
    },
    {
      value: DocumentRole.QRViewer,
      label: i18n(translationKeys.forms.document.userRole.qrViewerTitle),
    },
    {
      value: DocumentRole.Viewer,
      label: i18n(translationKeys.forms.document.userRole.viewerTitle),
    },
  ];
};

export const getRoleOption = (targetRole: DocumentRole): SelectOptionType | null =>
  getParticipantFormAvailableRoles().find(({ value }) => value === targetRole) || null;

export const getEditRoleOptions = (actor: UserWithRole, targetId: string): SelectOptionType[] =>
  getParticipantFormAvailableRoles().filter(({ value }) =>
    canEditUserPermissions(actor, { id: targetId, role: value }),
  );

export const getInviteRoleOptions = (actorRole: DocumentRole): SelectOptionType[] =>
  getParticipantFormAvailableRoles().filter(({ value }) => canAddUser(actorRole, value));

export const conditionRequiredVisible = (roleId: DocumentRole): boolean =>
  [DocumentRole.Manager, DocumentRole.Editor, DocumentRole.Acknowledger].includes(roleId);

export const getShownRole = (roleId: DocumentRole, role?: string): string => {
  const funcRole = !isUndefined(role) ? `, ${role}` : '';
  switch (roleId) {
    case DocumentRole.Owner:
      return `${i18n(translationKeys.forms.document.userRole.ownerDescription)}${funcRole}`;
    case DocumentRole.Manager:
      return `${i18n(translationKeys.forms.document.userRole.managerDescription)}${funcRole}`;
    case DocumentRole.Editor:
      return `${i18n(translationKeys.forms.document.userRole.editorDescription)}${funcRole}`;
    case DocumentRole.Acknowledger:
      return `${i18n(translationKeys.forms.document.userRole.acknowledgerDescription)}${funcRole}`;
    case DocumentRole.Commentator:
    case DocumentRole.QRViewer:
      return `${i18n(translationKeys.forms.document.userRole.commentatorDescription)}${funcRole}`;
    case DocumentRole.Viewer:
      return `${i18n(translationKeys.forms.document.userRole.viewerDescription)}${funcRole}`;
    case DocumentRole.SharedViewer:
      return `${i18n(translationKeys.forms.document.userRole.sharedViewerDescription)}`;
    case DocumentRole.DocumentSharerDownloader:
      return `${i18n(translationKeys.forms.document.userRole.documentSharerDownloaderDescription)}`;
    case DocumentRole.Downloader:
      return `${i18n(translationKeys.forms.document.userRole.documentDownloaderDescription)}`;
    case DocumentRole.DocumentSharer:
      return `${i18n(translationKeys.forms.document.userRole.documentDocumentSharerDescription)}`;
    case DocumentRole.DocumentClippedViewer:
      return `${i18n(translationKeys.forms.document.userRole.documentClippedViewerDescription)}`;
    default:
      return `${i18n(translationKeys.forms.document.userRole.unknownDescription)}`;
  }
};

export const getRequiredCondition = (
  roleId: DocumentRole,
  conditionRequiredChecked: boolean,
): boolean => {
  if (roleId === DocumentRole.Acknowledger) {
    return true;
  } else if (
    [
      DocumentRole.Commentator,
      DocumentRole.QRViewer,
      DocumentRole.SharedViewer,
      DocumentRole.Viewer,
    ].includes(roleId)
  ) {
    return false;
  }

  return conditionRequiredChecked;
};
