import { createSelector } from '@reduxjs/toolkit';
import { get, head, isNull } from 'lodash';

import {
  getEntities,
  getFields,
  getLocks,
  getTemplateParticipants,
  getTemplates,
  getTemplateUsers,
  getSharedTemplates,
  getTerms,
  getTrees,
  getSystemTemplates,
} from 'src/v2/features/objectsStorage/selectors';
import { isTemplatesFolder } from 'src/v2/features/quantumNavigator/utils';
import { createGetRootFolderId } from 'src/v2/features/sharedEntity/createGetRootFolderId';
import { getUserId } from 'src/shared/auth';
import { getObject, getObjects } from 'src/v2/features/objectsStorage/objectsStorageSlice';
import { DbRecordType, ObjectsContainer } from 'src/v2/features/objectsStorage/types';
import { TemplateCompleteModel, Template } from 'src/models/template';
import { ObjectBase } from 'src/common/types';
import { RootState, TemplatesState } from 'src/app/types';
import { Section } from 'src/models/document';
import { EntityType } from 'src/models/paper';
import { getPapers } from 'src/v2/features/objectsStorage';

import {
  convertTemplateCompleteModelToTemplateModel,
  convertTemplateCompleteModelToBaseModel,
} from './utils';

const getState = (state: RootState): TemplatesState => state.templates;
export const getIsLoading = (state: RootState) => getState(state).isLoading;
export const getError = (state: RootState) => getState(state).error;
export const getDeletedId = (state: RootState): string | null => getState(state).deletedId;

/** @deprecated */
export const getTemplate = createSelector(
  (state: RootState) => getState(state).data,
  getPapers,
  (state: RootState) => state.objectsStorage.objects.entity,
  (state: RootState) => state.objectsStorage.objects.tree,
  (state: RootState) => state.objectsStorage.objects.term,
  (state: RootState) => state.objectsStorage.objects.multi_party_workflow,
  (state: RootState) => state.objectsStorage.objects.parties,
  (state: RootState) => state.objectsStorage.objects.conditions,
  (item, papers, entity, tree, term, multi_party_workflow, parties, conditions) =>
    item &&
    (head(
      getObjects([item], {
        papers,
        entity,
        tree,
        term,
        multi_party_workflow,
        parties,
        conditions,
      }),
    ) as Template),
);

export const getId = createSelector(getState, (state) => {
  if (isNull(state.data)) {
    console.warn(`Can't select current template, data is empty`);
    return '';
  }

  return state.data.id;
});

export const getTitle = createSelector(getTemplate, (template) => template && template.title);

export const getSections = createSelector(
  getTemplate,
  (template) => get(template, 'entity[0].tree[0].term', []) as Section[],
);

export const getChosenFolderId = (state: RootState): string => getState(state).chosenFolder;

export const getChosenFolderType = (state: RootState): EntityType =>
  getState(state).chosenType || EntityType.document;

export const getCreateFolderMode = (state: RootState): boolean => getState(state).createFolderMode;

export const getRootTemplatesFolderId = createGetRootFolderId(isTemplatesFolder);

export const getTemplateByIdFn = createSelector(
  getTemplates,
  getTemplateParticipants,
  getTemplateUsers,
  getEntities,
  getLocks,
  getTrees,
  getTerms,
  getFields,
  getUserId,
  (templates, templateParticipants, templateUsers, entities, lock, tree, term, field, userId) =>
    (entityId: string) => {
      if (isNull(userId)) {
        return null;
      }

      const completeTemplate = getObject<TemplateCompleteModel>(
        {
          id: entityId,
          type: DbRecordType.Template,
        },
        {
          [DbRecordType.Template]: templates,
          [DbRecordType.TemplateParticipant]: templateParticipants,
          [DbRecordType.TemplateUser]: templateUsers,
          [DbRecordType.Entity]: entities,
          [DbRecordType.Lock]: lock,
          [DbRecordType.Tree]: tree,
          [DbRecordType.Term]: term,
          [DbRecordType.Field]: field,
        },
      );

      if (isNull(completeTemplate)) {
        return null;
      }

      try {
        return convertTemplateCompleteModelToTemplateModel(completeTemplate, userId);
      } catch (e) {
        console.warn(e);
        return null;
      }
    },
);

export const getTemplatesListFactory = createSelector(
  getTemplateParticipants,
  getTemplateUsers,
  getSharedTemplates,
  getSystemTemplates,
  getEntities,
  getLocks,
  getTrees,
  getTerms,
  getFields,
  getUserId,
  (
      templateParticipants,
      templateUsers,
      templateShared,
      templateSystem,
      entities,
      lock,
      tree,
      term,
      field,
      userId,
    ) =>
    (list: ObjectBase[], restObjects: Record<string, ObjectsContainer>) => {
      if (isNull(userId)) {
        return null;
      }

      const completeTemplates = getObjects<TemplateCompleteModel>(list, {
        ...restObjects,
        [DbRecordType.TemplateParticipant]: templateParticipants,
        [DbRecordType.TemplateUser]: templateUsers,
        [DbRecordType.SharedTemplate]: templateShared,
        [DbRecordType.SystemTemplate]: templateSystem,
        [DbRecordType.Entity]: entities,
        [DbRecordType.Lock]: lock,
        [DbRecordType.Tree]: tree,
        [DbRecordType.Term]: term,
        [DbRecordType.Field]: field,
      });

      if (isNull(completeTemplates)) {
        return null;
      }

      try {
        return completeTemplates.map((completeTemplate) =>
          convertTemplateCompleteModelToBaseModel(completeTemplate),
        );
      } catch (e) {
        console.warn(e);
        return null;
      }
    },
);
