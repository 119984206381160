import { FC, useMemo, useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { isNull } from 'lodash';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { env } from 'src/env';
import { useToggle } from 'src/common/hooks/useToggle';
import { decodeContentToDomainUrl } from 'src/utils/url';
import { ProfilePage } from 'src/v2/features/profile';
import {
  getAvatarFromProfile,
  getAvatarUrl,
  getFirstNameFromProfile,
  getLastNameFromProfile,
} from 'src/v2/features/profile';
import { useIsAccountOwner, useIsInOrganization } from 'src/v2/features/organization/hooks';

import { UpgradePayload } from './types';
import {
  getSubscribedPlan,
  getSubscribedPeriod,
  getBillingInfo,
  getPaymentCard,
  fetchBillingDetailsWithCard,
  fetchBillingDetails,
} from './store';
import { BillingPlanForm } from './BillingPlanForm';
import { BillingDetailsForm } from './BillingDetailsForm';
import { getDescriptionForBillingPeriod, getTitleForBillingInfo } from './utils';
import { PaymentCardInfo } from './components/CardInfo';
import { PaymentCardInfoEmpty } from './components/CardInfoEmpty';
import { useHasUpdatePlanFeature, useHasGetPaymentCardInfoFeature } from './hasFeature/hooks';

const stripePromise = loadStripe(env.STRIPE_API_KEY);

export const BillingInfoPage: FC = () => {
  const userAvatar = useSelector(getAvatarFromProfile);
  const firstName = useSelector(getFirstNameFromProfile);
  const lastName = useSelector(getLastNameFromProfile);
  const isInOrganization = useIsInOrganization();
  const billingInfo = useSelector(getBillingInfo);
  const billingCard = useSelector(getPaymentCard);
  const isAccountOwner = useIsAccountOwner();
  const isPlanEditable = useHasUpdatePlanFeature();
  const isCanFetchCardInfo = useHasGetPaymentCardInfoFeature();
  const preselectedPlan = useSelector(getSubscribedPlan);
  const preselectedPeriod = useSelector(getSubscribedPeriod);
  const dispatch = useDispatch();

  const [billingDetailsForm, , showBillingDetailsForm, hideBillingDetailsForm] = useToggle();

  const [migrateTo, setMigrateTo] = useState<UpgradePayload>({
    plan: preselectedPlan,
    period: preselectedPeriod,
  });

  useEffect(() => {
    setMigrateTo({ plan: preselectedPlan, period: preselectedPeriod });
    if (isCanFetchCardInfo && !isNull(preselectedPlan)) {
      dispatch(fetchBillingDetailsWithCard());
    } else {
      dispatch(fetchBillingDetails());
    }
  }, [preselectedPlan, preselectedPeriod, dispatch, isCanFetchCardInfo]);

  useEffect(() => {
    if (isCanFetchCardInfo) {
      dispatch(fetchBillingDetailsWithCard());
    }
  }, [billingDetailsForm, dispatch, isCanFetchCardInfo]);

  const billingTitle = useMemo(
    () => getTitleForBillingInfo(billingInfo, isInOrganization),
    [billingInfo, isInOrganization],
  );
  const billingDescription = useMemo(
    () => getDescriptionForBillingPeriod(billingInfo, isInOrganization),
    [billingInfo, isInOrganization],
  );

  const onMigrate = useCallback(
    (migrateTo: UpgradePayload): void => {
      setMigrateTo(migrateTo);
      showBillingDetailsForm();
    },
    [showBillingDetailsForm],
  );

  const billingInfoSave = useCallback((): void => {
    hideBillingDetailsForm();
  }, [hideBillingDetailsForm]);

  const onBillingCardClick = useCallback((): void => {
    showBillingDetailsForm();
  }, [showBillingDetailsForm]);

  return (
    <ProfilePage>
      {billingInfo && (
        <div className="c-setup c-setup--bg-transparent">
          <div className="c-setup__top">
            <div className="l-row">
              <div className="c-title c-title--color-theme c-title--fs-24">
                {i18n(translationKeys.forms.billing.title)}
              </div>
            </div>
            <div className="l-row l-row--mt-16">
              <div className="c-setup__user">
                <div className="c-setup__avatar">
                  <img src={decodeContentToDomainUrl(getAvatarUrl(userAvatar))} alt="User avatar" />
                </div>
                <div className="c-setup__user--info">
                  {i18n(translationKeys.forms.billing.you)} • {firstName} {lastName}
                  {isAccountOwner && (
                    <>
                      <br />
                      {i18n(translationKeys.forms.billing.accountOwner)}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="c-setup__content">
            {billingDetailsForm ? (
              <Elements stripe={stripePromise}>
                <BillingDetailsForm
                  plan={migrateTo.plan}
                  period={migrateTo.period}
                  onBillingInfoSave={billingInfoSave}
                ></BillingDetailsForm>
              </Elements>
            ) : (
              <>
                <BillingPlanForm onMigration={onMigrate} isPlanEditable={isPlanEditable}>
                  <b>
                    {billingTitle}
                    <br />
                    <span className="c-setup--green">{billingDescription}</span>
                  </b>
                </BillingPlanForm>
                <div className="c-form__row">
                  <div className="c-line"></div>
                </div>
                <div className="c-form__row">
                  <div className="c-setup__visa">
                    {isPlanEditable && billingCard && billingCard.brand ? (
                      <PaymentCardInfo
                        billingCard={billingCard}
                        firstName={firstName || ''}
                        lastName={lastName || ''}
                        onBillingCardClick={onBillingCardClick}
                      />
                    ) : null}
                    {isPlanEditable && (isNull(billingCard) || !billingCard.brand) ? (
                      <PaymentCardInfoEmpty onBillingCardClick={onBillingCardClick} />
                    ) : null}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </ProfilePage>
  );
};
