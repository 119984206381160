import React from 'react';
import { Box } from '@theme-ui/components';
import { mergeStyles } from 'src/utils/styles';

const style = {
  container: {
    left: '0px',
    bg: '#ffffff',
    zIndex: '999',
    position: 'absolute',
    p: '21px 40px 12px 26px',
    alignItems: 'center',
    border: '1px solid #E4B62C',
  },

  top: {
    bottom: 'calc(100% + 20px)',
  },

  center: {
    top: '50%',
    transform: 'translateY(-50%)',
  },

  bottom: {
    top: 'calc(100% + 20px)',
  },

  cross: {
    top: '10px',
    right: '8px',
    width: '16px',
    height: '16px',
    cursor: 'pointer',
    position: 'absolute',
    '&:after': {
      content: '""',
      width: '16px',
      height: '2px',
      top: '0px',
      left: '0px',
      background: '#D57872',
      position: 'absolute',
      transform: 'rotate(45deg)',
    },
    '&:before': {
      content: '""',
      width: '16px',
      height: '2px',
      top: '0px',
      left: '0px',
      background: '#D57872',
      position: 'absolute',
      transform: 'rotate(-45deg)',
    },
  },
};

export enum TooltipPosition {
  Top,
  Center,
  Bottom,
}

const tooltipPositionStyle = {
  [TooltipPosition.Top]: mergeStyles(style.container, style.top),
  [TooltipPosition.Center]: mergeStyles(style.container, style.center),
  [TooltipPosition.Bottom]: mergeStyles(style.container, style.bottom),
};

interface Props {
  isOpen: boolean;
  position?: TooltipPosition;
  onCrossClick?: () => void;
}

export const Tooltip: React.FC<Props> = ({
  isOpen,
  children,
  onCrossClick,
  position = TooltipPosition.Center,
}) => {
  const closeTooltip = (event: React.FormEvent<HTMLInputElement>): void => {
    event.stopPropagation();
    if (onCrossClick) {
      onCrossClick();
    }
  };

  return (
    <Box>
      {isOpen && (
        <Box sx={tooltipPositionStyle[position]}>
          {onCrossClick && <Box sx={style.cross} onClick={closeTooltip}></Box>}
          {children}
        </Box>
      )}
    </Box>
  );
};
