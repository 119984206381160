import { chain, cond, constant, matches, stubTrue } from 'lodash';

import { book } from 'src/app/book';
import {
  NotificationTemplateItemType,
  NotificationTargetType,
  NotificationTarget,
  NotificationData,
} from 'src/v2/entities/notification';

// Parses the given template:
// {{inviterName}} has made template {{documentTitle}} available for you to use
// to the tokens array: ['inviterName', ' has made template ', 'documentTitle', ' available for you to use']
const r = /((?!\{{2})\w+(?=\}{2}))|(((\s+(\w|\d|!|\?|'|"|,)*)+))|(((\w|\d|!|\?|'|"|,)+))/g;
export const parseTemplate = (template: string) => template.match(r) || [];

const notificationTemplateSupportedWrappers: string[] = [
  NotificationTemplateItemType.InviterName,
  NotificationTemplateItemType.DocumentTitle,
];

const notificationTargets: string[] = [
  NotificationTargetType.Document,
  NotificationTargetType.Contract,
  NotificationTargetType.Template,
  NotificationTargetType.Chat,
];

export const isWrapperSupported = (key: string): key is NotificationTemplateItemType =>
  notificationTemplateSupportedWrappers.includes(key);

export const isNotificationTypeSupported = (type: string) => notificationTargets.includes(type);

export const getEntityLinkByNotificationTarget = (target: NotificationTarget): string | null =>
  cond([
    [matches(NotificationTargetType.Document), constant(book.document.generatePath(target.id))],
    [matches(NotificationTargetType.Contract), constant(book.contract.generatePath(target.id))],
    [matches(NotificationTargetType.Template), constant(book.template.generatePath(target.id))],
    [stubTrue, constant(null)],
  ])(target.type);

export const convertPayloadToMap = (payload: NotificationData[]) =>
  chain(payload).keyBy('key').mapValues('value').value();
