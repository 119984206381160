import React, { FC } from 'react';

export const NavigatorBody: FC = ({ children }) => {
  return (
    <div className="c-library c-library--quantum">
      <div className="c-library__row c-library--mt-8">
        <div className="c-library__list">{children}</div>
      </div>
    </div>
  );
};
