import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import UploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript';
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import Link from '@ckeditor/ckeditor5-link/src/link';
import List from '@ckeditor/ckeditor5-list/src/list';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import TodoList from '@ckeditor/ckeditor5-list/src/todolist';
import Code from '@ckeditor/ckeditor5-basic-styles/src/code';
import CodeBlock from '@ckeditor/ckeditor5-code-block/src/codeblock';
import { Paragraph } from 'ckeditor5/src/paragraph';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters';
import SpecialCharactersEssentials from '@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
import Font from '@ckeditor/ckeditor5-font/src/font';
import { Clipboard } from 'ckeditor5/src/clipboard';
import EditableField from '@shelterzoom/ckeditor5-editable-field/src/editableField';
import Checkbox from '@shelterzoom/ckeditor5-checkbox/src/checkbox';
import Autosave from '@ckeditor/ckeditor5-autosave/src/autosave.js';
import LineHeight from '@shelterzoom/ckeditor5-line-height-plugin/src/lineheight';
import RestrictedEditingMode from '@ckeditor/ckeditor5-restricted-editing/src/restrictededitingmode';
import StandardEditingMode from '@ckeditor/ckeditor5-restricted-editing/src/standardeditingmode';
import { EditorConfig } from 'ckeditor5/src/core';

type Configuration = EditorConfig & {
  editableField?: {
    allowValuePropagation: boolean;
  };
};

export const defaultConfiguration: Configuration = {
  plugins: [
    Essentials,
    Autoformat,
    Checkbox,
    EditableField, //Need use upper RestrictedEditingMode
    RestrictedEditingMode,
    StandardEditingMode,
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    BlockQuote,
    Heading,
    Image,
    ImageCaption,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    ImageInsert,
    ImageResize,
    Link,
    List,
    Indent,
    IndentBlock,
    TodoList,
    Code,
    CodeBlock,
    Paragraph,
    UploadAdapter,
    Table,
    TableToolbar,
    TableProperties,
    TableCellProperties,
    Alignment,
    SpecialCharacters,
    SpecialCharactersEssentials,
    MediaEmbed,
    Font,
    Clipboard,
    LineHeight,
    Autosave,
  ],
  lineHeight: {
    options: [1, 1.5, 2, 2.5],
  },

  restrictedEditing: {
    allowedCommands: [],
    allowedAttributes: ['EditableFieldName'],
  },
  mediaEmbed: { previewsInData: true },
  image: {
    resizeUnit: 'px',
    toolbar: [
      'imageStyle:alignLeft',
      'imageStyle:block',
      'imageStyle:alignRight',
      '|',
      'imageTextAlternative',
    ],
    styles: {
      options: ['block', 'alignLeft', 'alignRight'],
    },
    // @ts-ignore
    type: ['JPEG', 'JPG', 'GIF', 'PNG'],
  },
  table: {
    contentToolbar: [
      'tableColumn',
      'tableRow',
      'mergeTableCells',
      'tableProperties',
      'tableCellProperties',
    ],
  },
  fontFamily: {
    options: [
      'default',
      'Arial, Helvetica, sans-serif',
      'Courier New, Courier, monospace',
      'Georgia, serif',
      'Lucida Sans Unicode, Lucida Grande, sans-serif',
      'Tahoma, Geneva, sans-serif',
      'Times New Roman, Times, serif',
      'Trebuchet MS, Helvetica, sans-serif',
      'Verdana, Geneva, sans-serif',
    ],
  },
  language: 'en',
  editableField: {
    allowValuePropagation: false,
  },
};
