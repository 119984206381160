import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isNull, size } from 'lodash';

import { DocumentRole } from 'src/models/document';
import { RootState } from 'src/app/types';
import { SidebarParticipantEntity } from 'src/v2/entities/participants';
import { getIsUpdating } from 'src/v2/features/documentParticipants/updateParticipantStore';
import { useGetEntityById } from 'src/v2/features/sharedEntity';
import {
  EditParticipantForm,
  formSelector,
} from 'src/v2/features/entityParticipants/EditParticipantForm';
import { DocumentPermissionsFieldset } from 'src/v2/features/document/participants/DocumentPermissionsFieldset';
import { conditionRequiredVisible } from 'src/v2/features/document/participants/utils';
import {
  getEditRoleOptions,
  getRoleOption,
  getRequiredCondition,
} from 'src/v2/features/document/participants/utils';
import { canEditUserPermissions } from 'src/v2/features/document/permissions/manageParticipantsPermissions/manageParticipantsPermissions';

export interface Props {
  onClose: () => void;
  documentId: string;
  participant: SidebarParticipantEntity<DocumentRole>;
}

export const DocumentEditParticipantForm: FC<Props> = (props) => {
  const { documentId, participant, onClose } = props;
  const { id: participantId, roleId: participantRole } = participant;
  const entity = useGetEntityById<DocumentType, DocumentRole>(documentId);
  const myRole = !isNull(entity) ? entity.myRole : null;
  const myParticipantId = !isNull(entity) ? entity.myParticipant.participantId : '';
  const isUpdating = useSelector(getIsUpdating);
  const roleId: DocumentRole = useSelector((state: RootState) => formSelector(state, 'roleId'));
  const conditionRequired: boolean = useSelector((state: RootState) =>
    formSelector(state, 'conditionRequired'),
  );
  const isConditionRequiredVisible = conditionRequiredVisible(roleId);
  const predefinedConditionRequired = getRequiredCondition(roleId, conditionRequired);
  const canEditParticipantRole = useMemo(
    () =>
      !isNull(participantRole) &&
      !isNull(myRole) &&
      canEditUserPermissions(
        { id: myParticipantId, role: myRole },
        { id: participantId, role: participantRole },
      ),
    [myParticipantId, myRole, participantId, participantRole],
  );
  const roleOptions = useMemo(() => {
    if (!canEditParticipantRole && !isNull(participantRole)) {
      const participantRoleOption = getRoleOption(participantRole);
      return !isNull(participantRoleOption) ? [participantRoleOption] : [];
    }

    return isNull(myRole)
      ? []
      : getEditRoleOptions({ id: myParticipantId, role: myRole }, participantId);
  }, [myParticipantId, myRole, participantId, participantRole, canEditParticipantRole]);

  return (
    <EditParticipantForm
      predefinedConditionRequired={predefinedConditionRequired}
      documentId={documentId}
      onClose={onClose}
      initialValues={participant}
      userId={participant.userId}
    >
      {!isNull(myRole) && size(roleOptions) > 0 && (
        <DocumentPermissionsFieldset
          roleOptions={roleOptions}
          myRole={myRole}
          isConditionRequiredVisible={isConditionRequiredVisible}
          isUpdating={isUpdating}
          participantId={participantId}
        />
      )}
    </EditParticipantForm>
  );
};
