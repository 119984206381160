import { apiClient } from 'src/common/client';
import { CreateFolderPayload } from 'src/models/folder';
import { FetchObjectsPayload } from 'src/v2/features/objectsList/types';
import qs from 'qs';

export const createFolderAPI = ({ name, parentId }: CreateFolderPayload) =>
  apiClient.post('/folder', {
    data: {
      attributes: {
        name,
        parent_id: parentId,
      },
    },
  });

export const renameFolderAPI = (id: string, name: string) =>
  apiClient.put(`/folder/${id}/rename`, {
    data: {
      attributes: {
        name,
      },
    },
  });

export const removeFolderAPI = (id: string) => apiClient.delete(`/folder/${id}`);

export const fetchFoldersAPI = () => apiClient.get('/folder');

export const moveFolderAPI = (targetId: string, destinationId: string) =>
  apiClient.put(`/folder/${targetId}/move/${destinationId}`);

export const linkFolderObjectAPI = (objectId: string, targetFolderId: string) =>
  apiClient.put(`/folder/${targetFolderId}/object/${objectId}/createLink`);

export const unlinkFolderObjectAPI = (object_id: string, folder_id: string) =>
  apiClient.post(`/folder/object/deleteLink`, {
    data: {
      attributes: {
        object_id,
        folder_id,
      },
    },
  });
/* eslint-enable */

export const moveFolderObjectAPI = (
  object_id: string,
  from_folder_id: string,
  to_folder_id: string,
) =>
  apiClient.post(`/folder/object/move`, {
    data: {
      attributes: {
        object_id,
        from_folder_id,
        to_folder_id,
      },
    },
  });
/* eslint-enable */

export function fetchFolderObjectsAPI(params: FetchObjectsPayload) {
  const { folderId, sortBy, sortDirection } = params;

  return apiClient.get(
    `/folder/${folderId}/object?${qs.stringify({
      sortBy,
      sortDirection,
    })}`,
  );
}
