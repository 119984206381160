import React, { ReactElement, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';
import { useSignUpProps, SignUpStepComponent } from './hooks';
import {
  FormProps as OrganizationInformationFormProps,
  organizationInformationFormName,
  OrganizationInformationForm,
} from '../OrganizationInformationForm';
import { OrganizationInformationFormPayload } from '../types';
import { saveOrgInfoAction } from '../store';

export const useOrganizationInformationComponent = (): SignUpStepComponent => {
  const { maxSteps, step } = useSignUpProps();
  const dispatch = useDispatch();

  const emitOrganizationInformationFormSubmission = useCallback(() => {
    dispatch(submit(organizationInformationFormName));
  }, [dispatch]);

  const submitOrganizationInformation = useCallback(
    (values: OrganizationInformationFormProps) => {
      const payload: OrganizationInformationFormPayload = {
        companyName: values.companyName,
        jobTitle: values.jobTitle,
        industry: values.industry,
        address: {
          addressLine: values.address,
          country: values.country,
          state: values.state,
          city: values.city,
          zipCode: values.zipCode,
        },
      };
      dispatch(saveOrgInfoAction(payload));
    },
    [dispatch],
  );

  const props = {
    onSubmit: submitOrganizationInformation,
  };

  return [
    (): ReactElement => <OrganizationInformationForm {...props} />,
    { step, maxSteps, onNext: emitOrganizationInformationFormSubmission },
  ];
};
