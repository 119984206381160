import { useCallback, ReactElement, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formValueSelector, submit } from 'redux-form';
import { isNull } from 'lodash';

import { signUpAction } from '../store';
import {
  emailPasswordFormName,
  EmailPasswordForm,
  FormProps as EmailPasswordProps,
} from '../EmailPasswordForm';
import { SignUpStepComponent, useSignUpProps } from './hooks';
import { SignUpAsyncPayload } from '../types';
import { useIsNoCaptureMode } from '../../../../utils/hooks';
import { RootState } from '../../../../app/types';

export const useEmailPasswordComponent = (): SignUpStepComponent => {
  const [captureToken, setCaptureToken] = useState<string | null>(null);
  const [resetCapture, setResetCapture] = useState<boolean>(false);
  const isNotCaptureMode = useIsNoCaptureMode();
  const dispatch = useDispatch();
  const { email, companyName, accountType, maxSteps, step, plan, period, token, inviteType } =
    useSignUpProps();

  const emailFromForm = useSelector((state: RootState) => {
    return formValueSelector(emailPasswordFormName)(state, 'email');
  });

  const handleSubmitCapture = (token: string) => {
    setCaptureToken(token);
    setResetCapture(false);
  };

  useEffect(() => {
    setResetCapture(email !== emailFromForm);
  }, [email, emailFromForm]);

  useEffect(() => {
    if (resetCapture) {
      setCaptureToken(null);
    }
  }, [resetCapture]);

  const submitPasswordForm = useCallback(
    (values: EmailPasswordProps): void => {
      const payload: SignUpAsyncPayload = {
        ...values,
        plan,
        period,
        accountType,
        inviteType,
        captureToken: !isNull(captureToken) ? captureToken : '',
      };
      if (token) payload.inviteCode = token;

      if ((isNotCaptureMode && isNull(captureToken)) || !isNull(captureToken)) {
        setResetCapture(true);
        dispatch(signUpAction(payload));
      }
    },
    [dispatch, plan, period, token, accountType, inviteType, captureToken, isNotCaptureMode],
  );

  const emitPasswordFormSubmission = useCallback(() => {
    dispatch(submit(emailPasswordFormName));
  }, [dispatch]);

  const props = {
    onSubmit: submitPasswordForm,
    accountType,
    initialValues: {
      email,
      companyName,
    },
    handleSubmitCapture,
    isNotCaptureMode,
    resetCapture: resetCapture,
  };

  return [
    (): ReactElement => <EmailPasswordForm {...props} />,
    {
      step,
      maxSteps,
      onNext: emitPasswordFormSubmission,
      isValid: (isNotCaptureMode && isNull(captureToken)) || !isNull(captureToken),
    },
  ];
};
