export enum AccountType {
  Personal = 'personal',
  Business = 'business',
}

export enum WizardStepV2 {
  SignUp = 'sign-up',
  StartPersonal = 'start-personal',
  StartBusiness = 'start-business',
  StartInvite = 'start-invite',
  PhoneVerification = 'phone-verification',
  Profile = 'profile',
  BusinessProfile = 'business-profile',
  ForgotPassword = 'forgot-password',
  Complete = 'complete',
}

export enum QrCodeAccountRecoveryStep {
  Start = 'start',
  PhoneVerification = 'phone-verification',
  PasswordRecovery = 'password-recovery',
  Complete = 'complete',
}

export enum InviteType {
  Common = 'common',
  Viewer = 'viewInvite',
  Signer = 'signInvite',
  Organization = 'organizationInvite',
  QRCode = 'qrCodeInvite',
}

export enum RegistrationType {
  Personal = 'personal',
  Business = 'business',
  QRInvite = 'QRPersonal',
  SignInvite = 'signInvite',
  ViewInvite = 'viewInvite',
  Organization = 'organizationInvite',
}

export const RegistrationTypeToInviteType = {
  [RegistrationType.Personal]: InviteType.Common,
  [RegistrationType.Business]: InviteType.Common,
  [RegistrationType.QRInvite]: InviteType.QRCode,
  [RegistrationType.SignInvite]: InviteType.Signer,
  [RegistrationType.ViewInvite]: InviteType.Viewer,
  [RegistrationType.Organization]: InviteType.Organization,
};

export interface NotificationSetting {
  active: boolean;
  type: string;
  value: string;
}

export enum RedirectEntityType {
  Document = 'document',
  Contract = 'contract',
  Template = 'template',
}

export enum QRRedirectEntityType {
  Document = 'documents',
  Contract = 'contracts',
}

export const QRRedirectEntityToRedirectEntity = {
  [QRRedirectEntityType.Document]: RedirectEntityType.Document,
  [QRRedirectEntityType.Contract]: RedirectEntityType.Contract,
};
