import createSagaMiddleware from 'redux-saga';
import { configureStore, Store } from '@reduxjs/toolkit';
import { promiseMiddleware } from '@adobe/redux-saga-promise';
import { persistStore, Persistor } from 'redux-persist';

import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

// @TODO remove after fixing deprecated libs that are using store directly inside api calls
// eslint-disable-next-line import/no-mutable-exports
export let singletonLikeStore = null;

export const initializeStore = (): { store: Store; persistor: Persistor } => {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: rootReducer,
    middleware: [promiseMiddleware, sagaMiddleware],
  });
  sagaMiddleware.run(rootSaga);

  const persistor = persistStore(store);
  // @ts-ignore
  singletonLikeStore = store;
  return { store, persistor };
};
