import { FC, useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNull } from 'lodash';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { CardPaperModel } from 'src/models/card';
import { DocumentType } from 'src/models/document';
import { ContractType } from 'src/models/contract';
import { TemplateRole } from 'src/models/template';
import { ObjectsListPage } from 'src/v2/features/objectsList/ObjectsListPage';
import { useGetCurrentFolderId } from 'src/v2/features/quantumNavigator/hooks';
import { useNormalizedEntityId } from 'src/v2/features/sharedEntity';
import { DynamicSettingsContext } from 'src/v2/modules/layout';

import { useGetTemplateById } from './hooks';
import { canDeleteTemplateByCardPaper } from './permissions';
import { getRootTemplatesFolderId, getDeletedId } from './selectors';
import { TemplateCard } from './components/TemplateCard';
import { TemplateLayout } from './TemplateLayout';
import { parseCardData, useCreateNavigateToTemplateDetails } from './utils';
import { deleteTemplate, fetchTemplate } from './reducer';

export type TemplateCardPaperModel = CardPaperModel<DocumentType | ContractType, TemplateRole>;

export const TemplateListPageWithoutContext: FC = () => {
  const dispatch = useDispatch();
  const templatesFolderId = useSelector(getRootTemplatesFolderId);
  const currentFolderId = useGetCurrentFolderId();
  const folderId = currentFolderId || templatesFolderId;
  const createHandleOpenTemplate = useCreateNavigateToTemplateDetails(folderId);
  const id = useNormalizedEntityId();
  const selectedTemplate = useGetTemplateById(id);
  const selectedTemplateId = isNull(selectedTemplate) ? null : selectedTemplate.templateId;
  const { onPlusClick: onAdd } = useContext(DynamicSettingsContext);
  const deletedTemplateId = useSelector(getDeletedId);

  const handleDeleteDocument = useCallback(
    (paper: TemplateCardPaperModel) => {
      if (deletedTemplateId !== paper.id) {
        dispatch(deleteTemplate(paper.id));
      }
    },
    [deletedTemplateId, dispatch],
  );

  const preventOperationForDeletedTemplate = useCallback(
    (id, operation) => {
      if (deletedTemplateId !== id) {
        return operation;
      } else {
        return () => {};
      }
    },
    [deletedTemplateId],
  );

  return (
    <ObjectsListPage<TemplateCardPaperModel>
      onIdChanged={fetchTemplate}
      onDelete={handleDeleteDocument}
      onCreate={onAdd}
      renderLayout={({ isLoading, error, children }) => (
        <TemplateLayout isLoading={isLoading} error={error}>
          {children}
        </TemplateLayout>
      )}
      renderCard={({ cardPaper, onDelete, selected, onClick }) => (
        <TemplateCard
          key={cardPaper.id}
          data={parseCardData(cardPaper)}
          onClick={preventOperationForDeletedTemplate(cardPaper.id, onClick)}
          onDelete={onDelete}
          onDoubleClick={preventOperationForDeletedTemplate(
            cardPaper.id,
            createHandleOpenTemplate(cardPaper.id),
          )}
          selected={selected}
          canDeleteTemplate={canDeleteTemplateByCardPaper()}
        />
      )}
      rootFolderId={templatesFolderId}
      selectedPaperId={selectedTemplateId}
      defaultGridTitle={i18n(translationKeys.card.template.title)}
    />
  );
};
