import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { ProfilePage } from 'src/v2/features/profile';
import { Signature } from 'src/v2/features/signature';
import { fetchSignatures } from 'src/v2/features/signature/store';
import { SignatureWithHanko } from 'src/v2/features/hankoSignature';
import { useShouldUseHanko } from 'src/v2/features/hankoSignature/hooks';
import { useAuthAndFillOrganization } from '../hooks';

export const SignaturePage: FC = () => {
  const dispatch = useDispatch();
  const shouldUseHanko = useShouldUseHanko();

  useAuthAndFillOrganization();
  useEffect(() => {
    dispatch(fetchSignatures());
  }, [dispatch]);

  return <ProfilePage>{shouldUseHanko ? <SignatureWithHanko /> : <Signature />}</ProfilePage>;
};
