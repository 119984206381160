import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@theme-ui/components';

import {
  getSectionsWithLocks,
  lockSection,
  unlockSection,
  getActiveLockIndex,
  insertSection,
  updateSection,
  moveSection,
  removeSection,
  uploadFile,
  uploadFileByUrl,
  getPreviewSectionsWithLocks,
  getCounterpartyFields,
  getOwnerFields,
  isOwnerParty as getIsOwnerParty,
} from 'src/v2/features/documentDetails/documentDetailsSlice';
import { EditableSections } from 'src/v2/features/entitySections/EditableSections';
import { getIsLoginAvailable } from 'src/shared/auth';
import { isNull } from 'lodash';

const styles = {
  sections: {
    mt: '5px',
  },
};

interface Props {
  id: string;
}

const createDispatcherFromPromise = (dispatch: any, promiseAction: any) => {
  return (payload: any): Promise<any> => {
    return new Promise<void>((resolve, reject) => {
      dispatch(promiseAction(payload)).then(resolve).catch(reject);
    });
  };
};

export const EditableBody: React.FC<Props> = ({ id }) => {
  const isLoginAvailable = useSelector(getIsLoginAvailable);
  const section = useSelector(getSectionsWithLocks);
  const previewSection = useSelector(getPreviewSectionsWithLocks);
  const sections =
    (isNull(isLoginAvailable) || !isLoginAvailable) && isNull(section) && !isNull(previewSection)
      ? previewSection
      : section || [];
  const activeLockIndex = useSelector(getActiveLockIndex);
  const dispatch = useDispatch();

  const handleInsertSection = createDispatcherFromPromise(dispatch, insertSection);
  const handleUpdateSection = createDispatcherFromPromise(dispatch, updateSection);
  const handleMoveSection = createDispatcherFromPromise(dispatch, moveSection);
  const handleRemoveSection = createDispatcherFromPromise(dispatch, removeSection);
  const handleUploadFile = createDispatcherFromPromise(dispatch, uploadFile);
  const handleUploadFileByUrl = createDispatcherFromPromise(dispatch, uploadFileByUrl);
  const isOwnerParty = useSelector(getIsOwnerParty);
  const counterpartyFields = useSelector(getCounterpartyFields);
  const ownerFields = useSelector(getOwnerFields);
  const handleLockSection = useCallback(
    (index: number) => dispatch(lockSection(index)),
    [dispatch],
  );
  const handleUnlockSection = useCallback(
    (index: number) => dispatch(unlockSection(index)),
    [dispatch],
  );

  return (
    <Box sx={styles.sections}>
      <EditableSections
        fields={isOwnerParty ? ownerFields : counterpartyFields}
        entityId={id}
        sections={sections}
        insertSection={handleInsertSection}
        updateSection={handleUpdateSection}
        moveSection={handleMoveSection}
        removeSection={handleRemoveSection}
        uploadFile={handleUploadFile}
        uploadFileByUrl={handleUploadFileByUrl}
        activeLockIndex={activeLockIndex}
        lockSection={handleLockSection}
        unlockSection={handleUnlockSection}
      />
    </Box>
  );
};
