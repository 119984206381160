import { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { constant } from 'lodash';

import { Textarea } from 'src/v2/features/reduxForm';
import { Emails } from 'src/v2/features/reduxForm/Emails';
import { DocumentRole } from 'src/models/document';
import { qrCodeShareDocument } from 'src/v2/features/document/share';
import { getDefaultShareText } from 'src/v2/features/document';
import { validateInviteForm } from 'src/utils/validate';
import { ParticipantList } from 'src/v2/features/entityParticipants/ParticipantList';
import { SidebarParticipantEntity } from 'src/v2/entities/participants';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

interface FormPayload {
  emails: string[];
  message: string;
}

interface Props {
  entityId?: string;
  myParticipant: SidebarParticipantEntity;
  participants: SidebarParticipantEntity[];
  onClose: () => void;
}

const QrCodeShare: FC<Props & InjectedFormProps<FormPayload, Props>> = ({
  myParticipant,
  participants,
  onClose,
  invalid,
  handleSubmit,
  submitting,
  submitSucceeded,
  entityId,
}) => {
  const dispatch = useDispatch();

  const shared = useMemo(
    () => participants.filter(({ roleId }) => roleId === DocumentRole.SharedViewer),
    [participants],
  );

  const messagePlaceholder = getDefaultShareText(myParticipant.firstName);

  const submitForm = useCallback(
    ({ emails, message }: FormPayload): void => {
      if (entityId) {
        dispatch(
          qrCodeShareDocument({
            emails,
            message,
            entityId,
          }),
        );
      } else {
        throw new Error('entityId in not defined');
      }
    },
    [dispatch, entityId],
  );

  useEffect(() => {
    if (submitSucceeded) {
      onClose();
    }
  }, [submitSucceeded, onClose]);

  return (
    <form className="c-fieldset" onSubmit={handleSubmit(submitForm)}>
      <div className="c-title c-title--uppercase c-title--lh-1">
        {i18n(translationKeys.forms.item.shareWithUsers)}
      </div>
      <Field name="emails" component={Emails} />
      {!!shared.length && (
        <ParticipantList
          list={shared}
          getShownRole={constant(
            i18n(translationKeys.forms.document.userRole.sharedViewerDescription),
          )}
        />
      )}
      <div className="c-line"></div>
      <div className="c-title c-title--uppercase c-title--lh-1">
        {i18n(translationKeys.forms.chat.addPersonalMessage)}
      </div>
      <Field
        name="message"
        type="text"
        component={Textarea}
        rows={3}
        placeholder={messagePlaceholder}
        multiline={true}
      />

      <button
        type="submit"
        className="c-button c-button--uppercase"
        disabled={invalid || submitting}
      >
        <span className="c-button__inner">{i18n(translationKeys.buttons.submit)}</span>
      </button>
    </form>
  );
};

/** @deprecated */
export const QrCodeShareForm = reduxForm<FormPayload, Props>({
  form: 'inviteParticipantsForm',
  validate: validateInviteForm,
})(QrCodeShare);
