import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchCompanyData } from 'src/v2/features/auth';
import { AppLayout } from 'src/v2/modules/layout';
import { SidebarNodeContextProvider } from './SidebarNodeContextProvider';

export const ProfilePage: FC = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCompanyData());
  }, [dispatch]);

  return (
    <SidebarNodeContextProvider>
      <AppLayout>{children}</AppLayout>
    </SidebarNodeContextProvider>
  );
};
