import React, { FC } from 'react';

import { ContextMenuBook } from 'src/app/types';
import { ContextMenuTypeContext } from './ContextMenuTypeContext';

export const SharedFolderContextMenuTypeContextProvider: FC = ({ children }) => (
  <ContextMenuTypeContext.Provider value={ContextMenuBook.SharedFolder}>
    {children}
  </ContextMenuTypeContext.Provider>
);
