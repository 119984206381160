import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { getIsEditing, getIsDisabled } from 'src/v2/features/signature/store';
import { HankoSignatureActionContext } from '../contexts/HankoSignatureActionContext';
import { useSubmitHankoSignatureHandler } from '../hooks';
import { getIsLoading } from '../store';

export const HankoSignatureActionContextProvider: FC = ({ children }) => {
  const isEditing = useSelector(getIsEditing);
  const isDisabled = useSelector(getIsDisabled);
  const isLoading = useSelector(getIsLoading);
  const onSubmit = useSubmitHankoSignatureHandler();

  const ctx = {
    isEditing,
    isDisabled,
    isLoading,
    onSubmit,
  };

  return (
    <HankoSignatureActionContext.Provider value={ctx}>
      {children}
    </HankoSignatureActionContext.Provider>
  );
};
