import html2canvas from 'html2canvas';

function base64ToBlob(data: string): Blob {
  const byteCharacters = atob(data);
  const byteArrays: Uint8Array[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: 'image/png' });
}

export async function renderElementToImage(text: HTMLElement): Promise<string> {
  return html2canvas(text, {
    scrollX: 0,
    scrollY: -window.scrollY,
    backgroundColor: null,
    height: 80,
  }).then(
    /*  eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["canvas"] }]  */
    (canvas: HTMLCanvasElement) => {
      canvas.style.opacity = '0';
      canvas.style.position = 'fixed';
      canvas.style.pointerEvents = 'none';

      document.body.appendChild(canvas);

      // create an image from the text
      const result = canvas.toDataURL('image/png');
      canvas.remove();

      return result;
    },
  );
}

export async function renderElementToFile(text: HTMLElement): Promise<File> {
  const dataUrl = await renderElementToImage(text);
  const base64 = dataUrl.split(',')[1];
  return base64ToBlob(base64) as File;
}
