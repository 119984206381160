import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import i18n from 'src/i18n';

import { translationKeys } from 'src/common/translations';
import { PDFToolbarItems } from 'src/v2/components/PDFContainer/types';
import { PDFContainer } from 'src/v2/components/PDFContainer';
import { isAllowedToSign as getIsAllowedToSign } from 'src/v2/features/documentDetails/documentDetailsSlice';
import { UploadedEntityExtension } from 'src/v2/features/sharedEntity/types';
import { usePrompt } from 'src/common/hooks/usePrompt';

interface Props {
  file: {
    url: string;
    name: string;
    extension: UploadedEntityExtension;
    shortUrl?: string;
  };
  savePDFFile: (file: File) => void;
  onExportPdf?: () => void;
  isAllowedToEdit: boolean;
  isNegotiable?: boolean;
  isAllowedToSign?: boolean;
  isWithAuthorization?: boolean;
  disableAfterSigned?: boolean;
  enabledToolbarItems?: PDFToolbarItems[];
  fileFullyLoaded?: () => void;
}

export const PDFBody: React.FC<Props> = ({
  file,
  savePDFFile,
  onExportPdf,
  isAllowedToEdit,
  isNegotiable = false,
  isAllowedToSign: hasSignPermissions,
  isWithAuthorization = false,
  disableAfterSigned,
  enabledToolbarItems,
  fileFullyLoaded,
}) => {
  const [pdfContentChanged, setPdfContentChanged] = useState(false);
  const isAllowedToSign = useSelector(getIsAllowedToSign);
  const unsavedChangesMessage = i18n(translationKeys.messages.unsavedChanges);
  const { showPrompt, cancelNavigation, confirmNavigation } = usePrompt(pdfContentChanged);

  useEffect(() => {
    if (showPrompt) {
      if (window.confirm(unsavedChangesMessage)) {
        confirmNavigation();
      } else {
        cancelNavigation();
      }
    }
  }, [showPrompt, unsavedChangesMessage, confirmNavigation, cancelNavigation]);

  useEffect(() => {
    const handleBeforeunload = (event: BeforeUnloadEvent) => {
      if (pdfContentChanged) {
        // eslint-disable-next-line no-param-reassign
        event.returnValue = unsavedChangesMessage;
        return unsavedChangesMessage;
      }

      return null;
    };

    window.addEventListener('beforeunload', handleBeforeunload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeunload);
    };
  }, [pdfContentChanged, unsavedChangesMessage]);

  const pdfSaveCallback = useCallback(
    (file: File) => {
      savePDFFile(file);
      setPdfContentChanged(false);
    },
    [savePDFFile],
  );

  const pdfChangedCallback = useCallback((isUserUpdate) => {
    if (isUserUpdate) {
      setPdfContentChanged(true);
    }
  }, []);

  const allowSigning = hasSignPermissions && (isAllowedToSign || isNegotiable);
  return (
    <>
      <PDFContainer
        url={file.url}
        fileName={file.name}
        allowEditing={isAllowedToEdit}
        allowSigning={allowSigning}
        isSaveButtonEnabled={pdfContentChanged}
        isWithAuthorization={isWithAuthorization}
        disableAfterSigned={disableAfterSigned}
        enabledToolbarItems={enabledToolbarItems}
        onSave={pdfSaveCallback}
        onExport={onExportPdf}
        onChange={pdfChangedCallback}
        fileFullyLoaded={fileFullyLoaded}
      />
    </>
  );
};
