import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { TemplateRole } from 'src/models/template';
import { FileMove } from 'src/v2/icons/FileMove';
import { Copy } from 'src/v2/icons/Copy';
import { CardData } from 'src/v2/features/sharedEntity/types';
import { DropdownDownload } from 'src/v2/icons/DropdownDownload';
import { ContextMenuItem, ContextMenu } from 'src/v2/components/contextMenu';
import { ContextMenuProps } from 'src/v2/features/contextMenu/types';
import { downloadAsPDF } from 'src/v2/features/template/reducer';
import { ModalType } from 'src/v2/features/modal/types';
import { useOpenModal } from 'src/v2/features/folderSelector/hooks';
import { useGetRootFolderId } from 'src/v2/features/sharedEntity/hooks';
import { isTemplatesFolder } from 'src/v2/features/quantumNavigator/utils';

import { canCopyTemplate, canMoveTemplate } from './permissions';

export const TemplateContextMenu: FC<ContextMenuProps> = ({ closeOnClick, position, payload }) => {
  const dispatch = useDispatch();
  const { id, title, role } = payload as CardData<TemplateRole>;

  const templateRootFolderId = useGetRootFolderId(isTemplatesFolder);
  const isAllowedToCopyTemplate = canCopyTemplate(role);
  const isAllowedToMoveTemplate = canMoveTemplate(role);

  const handleDownload = closeOnClick(
    useCallback(() => {
      dispatch(downloadAsPDF({ templateId: id, title }));
    }, [dispatch, id, title]),
  );

  const handleMove = useOpenModal({
    type: ModalType.TemplateMoveTo,
    menuItemFolderId: templateRootFolderId,
    targetId: id,
  });

  const handleCopy = useOpenModal({
    type: ModalType.TemplateCopyTo,
    menuItemFolderId: templateRootFolderId,
    targetId: id,
  });

  return (
    <ContextMenu {...position}>
      <ContextMenuItem
        icon={DropdownDownload}
        text="Download"
        onClick={handleDownload}
        data-id="download-menuButton"
      />
      <ContextMenuItem
        icon={Copy}
        text="Copy To"
        disabled={!isAllowedToCopyTemplate}
        onClick={handleCopy}
        data-id="copyto-menuButton"
      />
      <ContextMenuItem
        icon={FileMove}
        text="Move To"
        disabled={!isAllowedToMoveTemplate}
        onClick={handleMove}
        data-id="movefile-menuButton"
      />
    </ContextMenu>
  );
};
