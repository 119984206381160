import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isUndefined } from 'lodash';

import { createErrorFactory } from 'src/common/error/createErrorFactory';
import { CardPaperCompleteModel, CardPaperModel } from 'src/models/card';
import { convertCardPaperParticipantsModelToCardParticipantsEntity } from 'src/v2/boundary/storageModels/paper/cardPaperParticipant';
import { folderSubscribe, folderUnsubscribe } from 'src/v2/features/folderObjects/actions';

export const convertCardPaperCompleteToCardPaperModel = <T, R>(
  paper: CardPaperCompleteModel<T, R>,
  userId: string,
): CardPaperModel<T, R> => {
  const createError = createErrorFactory('convertProjectCompleteModelToProjectEntity');
  const cardPaperAllParticipants = paper.cardPaperParticipant;

  if (!isUndefined(cardPaperAllParticipants)) {
    const cardPaperParticipants =
      convertCardPaperParticipantsModelToCardParticipantsEntity<R>(cardPaperAllParticipants);

    const currentParticipant = cardPaperParticipants.find(
      (participant) => participant.userId === userId,
    );

    if (isUndefined(currentParticipant)) {
      throw createError(
        `Can't convert model - user ${userId} not found in participant list in paper ${paper.id}`,
      );
    }

    const myRole = currentParticipant.roleId;

    return {
      ...paper,
      cardPaperParticipant: cardPaperParticipants,
      myRole,
    };
  }

  throw createError(`Can't convert model - participants are undefined`);
};

export const useSubscribeFolderContentChangeEffct = (folderId?: string) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isUndefined(folderId)) {
      dispatch(folderSubscribe(folderId));

      return () => {
        dispatch(folderUnsubscribe());
      };
    }
  }, [folderId, dispatch]);
};
