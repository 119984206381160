import React, { useCallback } from 'react';
import { noop } from 'lodash';

import { Button as CButton, Color } from 'src/v2/components/Button';

interface Props {
  actionType?: Type;
  label?: string;
  isLoading?: boolean;
  isInline?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export enum Type {
  Primary,
  Secondary,
  Warning,
  Danger,
}

const colorByType = {
  [Type.Primary]: Color.Default,
  [Type.Secondary]: Color.Green,
  [Type.Danger]: Color.Red,
  [Type.Warning]: Color.Yellow,
};

export const Button: React.FC<Props & React.ButtonHTMLAttributes<{}>> = ({
  isLoading,
  children,
  disabled = false,
  actionType = Type.Primary,
  onClick = noop,
  ...other
}) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (isLoading) {
        e.preventDefault();
        e.stopPropagation();
      } else {
        onClick();
      }
    },
    [isLoading, onClick],
  );
  return (
    <CButton
      {...other}
      isLoading={isLoading}
      color={colorByType[actionType] as any}
      disabled={disabled}
      onClick={handleClick}
    >
      {children}
    </CButton>
  );
};
