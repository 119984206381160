import { call, put, ForkEffect, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { storeData, storeNewData } from 'src/v2/features/objectsStorage/objectsStorageSlice';
import { SagaIterator } from 'redux-saga';
import { toastr } from 'react-redux-toastr';

import { fetchUsers } from 'src/v2/features/profile';
import { clearFoundUser } from 'src/v2/features/organization';
import { updateUserApi, removeUserApi, inviteUserApi, fetchDocumentApi } from 'src/api/documents';
import { responseErrorExtract } from 'src/utils/responseErrorExtract';
import { fetchWorkflow } from 'src/v2/features/documentWorkflow';
import { handleErrorSaga, getTranslatedErrorFromResponse } from 'src/utils/handleErrorSaga';
import {
  emitInviteParticipant,
  emitEditParticipant,
  emitRemoveParticipant,
} from 'src/v2/features/document';
import { InviteParticipantActionPayload } from 'src/v2/boundary/actionPayload/inviteParticipant';

import { fetchParticipantsSlice } from './fetchParticipantsStore';
import {
  fetchDocumentParticipants,
  fetchTemplateParticipants,
  inviteUser,
  updateParticipant,
  removeParticipant,
} from './actions';
import { updateParticipantSlice } from './updateParticipantStore';
import { removeParticipantSlice } from './removeParticipantStore';
import { RemoveParticipantPayload } from './types';
import { inviteParticipantSlice } from './inviteParticipantStore';
import { DbRecordType } from '../objectsStorage/types';

const { fetchParticipantsStart, fetchParticipantsFailed, fetchParticipantsSuccess } =
  fetchParticipantsSlice.actions;

export function* fetchParticipantsSaga(action: PayloadAction<string[]>): SagaIterator {
  try {
    yield put(fetchParticipantsStart());
    const res = yield call(fetchUsers, action.payload);
    yield put(storeData(res));
    yield put(fetchParticipantsSuccess(res));
  } catch (err) {
    yield put(fetchParticipantsFailed(err.toString()));
    const { title, detail } = responseErrorExtract(err);
    toastr.error(title, detail);
  }
}

const inviteParticipantActions = inviteParticipantSlice.actions;

function* inviteUserSaga({ payload }: PayloadAction<InviteParticipantActionPayload>): SagaIterator {
  try {
    yield put(inviteParticipantActions.start());
    yield call(inviteUserApi, payload.documentId, payload.data);
    yield put(inviteParticipantActions.success());
    toastr.success('Success', 'Invite has been sent');

    const document = yield call(fetchDocumentApi, payload.documentId);
    yield put(storeNewData({ ...document, type: DbRecordType.Paper }));

    yield put(fetchWorkflow(payload.documentId));

    yield put(clearFoundUser());
    emitInviteParticipant(payload.documentId);
  } catch (err) {
    const { detail } = getTranslatedErrorFromResponse(err);
    yield put(inviteParticipantActions.error(detail));
    yield call(handleErrorSaga, err);
  }
}

const updateParticipantActions = updateParticipantSlice.actions;

export function* updateParticipantSaga({
  payload,
}: PayloadAction<InviteParticipantActionPayload>): SagaIterator {
  try {
    yield put(updateParticipantActions.start());
    const res = yield call(updateUserApi, payload.documentId, payload.data, payload.userId);
    yield put(storeNewData({ ...res, entity: payload.documentEntity, type: DbRecordType.Paper }));
    yield put(updateParticipantActions.success());
    toastr.success('Success', 'Successfully updated');

    // Fetch document is not required here, updateUserApi takes care of it
    yield put(fetchWorkflow(payload.documentId));

    emitEditParticipant(payload.documentId);
  } catch (err) {
    yield put(updateParticipantActions.error(err.toString()));
    const { title, detail } = responseErrorExtract(err);
    toastr.error(title, detail);
  }
}

const removeParticipantActions = removeParticipantSlice.actions;

export function* removeParticipantSaga({
  payload: { documentId, userId },
}: PayloadAction<RemoveParticipantPayload>): SagaIterator {
  try {
    yield put(removeParticipantActions.start());
    yield call(removeUserApi, documentId, userId);
    yield put(removeParticipantActions.success());
    toastr.success('Success', 'Successfully removed');

    const document = yield call(fetchDocumentApi, documentId);
    yield put(storeNewData({ ...document, type: DbRecordType.Paper }));
    yield put(fetchWorkflow(documentId));

    emitRemoveParticipant(documentId);
  } catch (err) {
    yield put(removeParticipantActions.error(err.toString()));
    const { title, detail } = responseErrorExtract(err);
    toastr.error(title, detail);
  }
}

export function* watchDocumentParticipantsSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(fetchDocumentParticipants, fetchParticipantsSaga);
  yield takeLatest(fetchTemplateParticipants, fetchParticipantsSaga);
  yield takeLatest(inviteUser, inviteUserSaga);
  yield takeLatest(updateParticipant, updateParticipantSaga);
  yield takeLatest(removeParticipant, removeParticipantSaga);
}
