import React, { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  useGetEntityFieldsDiff,
  useGetUnmatchingSections,
} from 'src/v2/features/sharedEntity/hooks';
import {
  getFile,
  getIsFile,
  modifyFile,
  isOwnerParty as getIsOwnerParty,
  hasCounterparty as getHasCounterparty,
  getPreviewFile,
  getIsPreviewFile,
  setFileFullyLoaded,
} from 'src/v2/features/documentDetails/documentDetailsSlice';
import { UploadedEntityExtension } from 'src/v2/features/sharedEntity/types';
import { PDFToolbarItems } from 'src/v2/components/PDFContainer/types';

import { EditableBody } from './components/EditableBody';
import { StaticBody } from './components/StaticBody';
import { PDFBody } from './components/PDFBody';
import { FilePreviewBody } from './components/FilePreviewBody';
import { getIsLoginAvailable } from 'src/shared/auth';
import { isNull } from 'lodash';

interface Props {
  id: string;
  isNegotiable: boolean;
  isAllowedToEdit: boolean;
  isAllowedToSign?: boolean;
  disableAfterSigned?: boolean;
  enabledToolbarItems?: PDFToolbarItems[];
  onExportPdf?: () => void;
}

export const EntityBody: React.FC<Props> = ({
  id,
  isNegotiable,
  isAllowedToEdit,
  isAllowedToSign,
  disableAfterSigned,
  enabledToolbarItems,
  onExportPdf,
}) => {
  const dispatch = useDispatch();
  const isFile = useSelector(getIsFile);
  const isPreviewFile = useSelector(getIsPreviewFile);
  const isLoginAvailable = useSelector(getIsLoginAvailable);
  const file = useSelector(getFile);
  const previewFile = useSelector(getPreviewFile);
  const fileContent = isNull(isLoginAvailable) || !isLoginAvailable ? previewFile : file;
  const isOwnerParty = useSelector(getIsOwnerParty);
  const hasCounterparty = useSelector(getHasCounterparty);
  const isPdf = useMemo(
    () => fileContent.extension === UploadedEntityExtension.pdf,
    [fileContent.extension],
  );
  const isEdit = (!isFile && isOwnerParty && !hasCounterparty && isAllowedToEdit) || false;
  const isStatic = !isFile && !isPreviewFile && !isEdit;
  const { ownerFieldsDiff, counterpartyFieldsDiff } = useGetEntityFieldsDiff();
  const unmatchingSections = useGetUnmatchingSections();

  const savePDFFile = useCallback(
    (file: File) => {
      dispatch(modifyFile({ file, entityId: id }));
    },
    [dispatch, id],
  );

  const fileFullyLoaded = useCallback(() => {
    dispatch(setFileFullyLoaded());
  }, [dispatch]);

  return (
    <>
      {isEdit && <EditableBody id={id} />}
      {isStatic && (
        <StaticBody
          ownerFieldsDiff={ownerFieldsDiff}
          counterpartyFieldsDiff={counterpartyFieldsDiff}
          unmatchingSections={unmatchingSections}
          isNegotiable={isNegotiable}
          fileFullyLoaded={fileFullyLoaded}
        />
      )}
      {isPdf && (
        <PDFBody
          file={fileContent}
          savePDFFile={savePDFFile}
          onExportPdf={onExportPdf}
          isNegotiable={isNegotiable}
          isAllowedToEdit={isAllowedToEdit}
          isAllowedToSign={isAllowedToSign}
          disableAfterSigned={disableAfterSigned}
          enabledToolbarItems={enabledToolbarItems}
          fileFullyLoaded={fileFullyLoaded}
        />
      )}
      {(isFile || isPreviewFile) && !isPdf && (
        <FilePreviewBody file={fileContent} fileFullyLoaded={fileFullyLoaded} />
      )}
    </>
  );
};
