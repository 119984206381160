import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ObjectBase } from 'src/common/types';
import { ProfileState } from 'src/app/types';
import { UpdateProfilePayload } from 'src/models/account';

import { ChangePasswordPayload } from '../types';
import { UpdatePhonePayload } from '../../../../models/phone';

export const fetchProfile = createAction('profile/fetchProfile');
export const updateProfileData = createAction<UpdateProfilePayload>('profile/updateProfileData');
export const updateProfileAvatar = createAction<File>('profile/updateProfileAvatar');
export const updatePhoneNumber = createAction<UpdatePhonePayload>('profile/updatePhoneNumber');
export const verifyPhoneNumber = createAction<string>('profile/verifyPhoneNumber');
export const changePassword = createAction<ChangePasswordPayload>('profile/changePassword');

const initialState: ProfileState = {
  data: null,
  signature: null,
  isLoading: false,
  error: '',
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    profileUpdateStart(state: ProfileState): void {
      state.isLoading = true;
      state.error = '';
    },

    profileUpdateFailed(state: ProfileState, action: PayloadAction<string>): void {
      state.isLoading = false;
      state.error = action.payload;
    },

    setProfile(state: ProfileState, { payload }: PayloadAction<ObjectBase>): void {
      state.data = payload;
    },

    profileUpdateSuccess(state: ProfileState): void {
      state.isLoading = false;
      state.error = '';
    },
  },
});

export const profileReducer = profileSlice.reducer;

export const { profileUpdateStart, profileUpdateFailed, profileUpdateSuccess, setProfile } =
  profileSlice.actions;
