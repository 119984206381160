import React from 'react';

import { UploadedEntityExtension } from 'src/v2/features/sharedEntity/types';
import { FilePreview } from 'src/v2/components/FilePreview';

const styles = {
  preview: {
    height: 'calc(100% - 88px)',
  },
};

interface Props {
  file: {
    url: string;
    shortUrl?: string;
    name: string;
    extension: UploadedEntityExtension;
  };
  fileFullyLoaded?: () => void;
}

export const FilePreviewBody: React.FC<Props> = ({ file, fileFullyLoaded }) => (
  <FilePreview
    shortUrl={file.shortUrl}
    url={file.url}
    extension={file.extension}
    style={styles.preview}
    fileFullyLoaded={fileFullyLoaded}
  />
);
