import React, { useCallback } from 'react';
import ReactPhone from 'react-phone-input-2';

import withReduxForm, { WrappedReduxFieldProps } from '../withReduxForm';

const ReduxFormPhone: React.FC<WrappedReduxFieldProps> = (props) => {
  const handleBlur = useCallback((event, newValue, previousValue) => {
    if (newValue === previousValue) {
      event.preventDefault();
    }
  }, []);

  return (
    <div className="c-input__label">
      <ReactPhone country="us" {...props} onBlur={handleBlur} dropdownClass="c-country__dropdown" />
    </div>
  );
};

export default withReduxForm(ReduxFormPhone);
