import { useSelector } from 'react-redux';

import { NodeTree } from 'src/models/node';
import { ListItem } from 'src/models/list';
import { getActivePlan } from 'src/v2/features/billing';
import { canCreateFolder } from 'src/v2/features/quantumNavigator/utils';

import { canCreateProject, canCreateFolderInProjects } from './projectPermissions';

export const useCanCreateProject = (): boolean => {
  const plan = useSelector(getActivePlan);

  return canCreateProject(plan);
};

export const useCanCreateEntityInProject = (): boolean => useCanCreateProject();

export const useCanCreateFolderInProjects = (folder: NodeTree<ListItem>) => {
  const plan = useSelector(getActivePlan);
  const isCanCreateFolder = canCreateFolder(folder);

  return canCreateFolderInProjects(plan) && isCanCreateFolder;
};
