import { FC, useContext } from 'react';
import { isEmpty } from 'lodash';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { Button } from 'src/v2/components/Button';
import { SignaturePreviewContext } from 'src/v2/features/signature/contexts/SignaturePreviewContext';

const submitStyle = {
  marginLeft: 'auto',
};

interface Props {
  label: 'signature' | 'hanko';
  isEditing: boolean;
  isDisabled: boolean;
  isLoading: boolean;
  onSubmit: () => void;
}

export const CommonAction: FC<Props> = ({
  label,
  isEditing,
  isDisabled,
  isLoading,
  onSubmit,
  children,
}) => {
  const actionType = isEditing ? 'update' : 'create';
  const { signaturePreviewUrl, uploadedImageUrl } = useContext(SignaturePreviewContext);
  const disableAction = isDisabled || isLoading || isEmpty(signaturePreviewUrl || uploadedImageUrl);

  return (
    <div className="c-signature__action">
      {children}

      <Button
        data-type="signature-submit"
        onClick={onSubmit}
        disabled={disableAction}
        isLoading={isLoading}
        className="h-uppercase"
        style={submitStyle}
      >
        {i18n(translationKeys.forms[label][actionType])}
      </Button>
    </div>
  );
};
