import React, { useState, useCallback } from 'react';
import { Flex, Link } from '@theme-ui/components';
import { formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import OtpInput from 'react-otp-input';
import { useLocation } from 'react-router-dom';
import { isNull } from 'lodash';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { validateAuthForm } from 'src/utils/validate';
import { ProfileFormData } from 'src/v2/features/profile/types';
import { getProfileFormDataFromProfile } from 'src/v2/features/profile';
import { Button } from 'src/v2/features/button';

import { CForm, CFormRow } from 'src/v2/components/CForm';
import { RootState } from 'src/app/types';
import { normalizeNumbers } from 'src/utils/normalize';
import { requestOptCode, submitOtpCode } from './qrCodeAccountRecoveryStore';
import { Capture } from '../../components/Сapture/Capture';
import { useIsNoCaptureMode } from '../../../utils/hooks';

export type Values = {
  code: string;
  email: string;
  phone: string;
};

type PhoneVerificationLightFormProps = InjectedFormProps<Values>;

const styles = {
  otpContainer: {
    justifyContent: 'left',
  },
  link: {
    cursor: 'pointer',
  },
};

export const verifyPhoneLightForgotPasswordFormName = 'verifyPhoneLightForgotPassword';

export const Form: React.FC<PhoneVerificationLightFormProps> = ({ handleSubmit, submitting }) => {
  const { email: emailFromSignUpValue, phone: phoneFromSignUpValue } = useSelector(
    (state: RootState) =>
      formValueSelector('qr-code-account-recovery-formlight')(state, 'phone', 'email'),
  );
  const { pathname } = useLocation();
  const profile = useSelector(getProfileFormDataFromProfile) as ProfileFormData;
  const phoneFromProfile = profile ? profile.phoneNumber : null;
  const emailFromProfile = profile ? profile.email : null;
  const phoneValue = phoneFromSignUpValue || phoneFromProfile || '';
  const emailValue = emailFromProfile || emailFromSignUpValue;
  const [otpCode, setOtpCode] = useState('');
  const [captureToken, setCaptureToken] = useState<string | null>(null);
  const [isCaptureShown, setIsCaptureShow] = useState<boolean>(false);
  const invalid = otpCode.length !== 6;
  const isNoCaptureMode = useIsNoCaptureMode();
  const dispatch = useDispatch();

  const resendSms = useCallback((): void => {
    dispatch(
      requestOptCode({
        email: emailValue,
        phone: `+${normalizeNumbers(phoneValue)}`,
        formName: verifyPhoneLightForgotPasswordFormName,
        captureToken: !isNull(captureToken) ? captureToken : '',
      }),
    );
  }, [dispatch, emailValue, phoneValue, captureToken]);

  const handleResendSms = (): void => {
    setOtpCode('');
    resendSms();
    setCaptureToken(null);
    setIsCaptureShow(false);
  };

  const submitForm = useCallback((): void => {
    dispatch(
      submitOtpCode({
        code: otpCode,
        formName: verifyPhoneLightForgotPasswordFormName,
        email: emailValue,
        url: pathname,
      }),
    );
  }, [dispatch, otpCode, emailValue, pathname]);

  const handleSubmitCapture = (token: string) => {
    setCaptureToken(token);
  };

  const handleShowCapture = () => {
    setIsCaptureShow(true);
  };

  return (
    <div className="c-modal__row c-modal--mt-24">
      <CForm className="c-form c-form--grow" onSubmit={handleSubmit(submitForm)} noValidate>
        <CFormRow>
          <div className="c-form__column">
            <Flex data-type="otp-inputs" sx={styles.otpContainer}>
              <OtpInput
                containerStyle="c-verification__form c-verification--mt-0"
                numInputs={6}
                errorStyle="error"
                value={otpCode}
                onChange={(val: string): void => setOtpCode(val)}
                shouldAutoFocus
              />
            </Flex>
          </div>
        </CFormRow>
        <CFormRow>
          <Button
            className="c-button c-button--fs-12 c-button--w-full h-uppercase"
            type="submit"
            disabled={invalid || submitting}
          >
            {i18n(translationKeys.buttons.verify)}
          </Button>
        </CFormRow>
        {!isNoCaptureMode && (
          <Capture
            onChange={handleSubmitCapture}
            isShow={isCaptureShown}
            resetCapture={isCaptureShown}
          />
        )}
        <CFormRow className="c-form--mt-32">
          <div className="c-form__column">
            {i18n(translationKeys.forms.phoneVerificationQR.notrecievedCode)}{' '}
            <Link
              data-type="readTerms-link"
              href={null}
              sx={styles.link}
              className="c-checkbox__link"
              target="_blank"
              onClick={
                isNull(captureToken) && !isNoCaptureMode
                  ? handleShowCapture
                  : (): void => handleResendSms()
              }
            >
              {i18n(translationKeys.forms.phoneVerificationQR.resend)}
            </Link>
          </div>
        </CFormRow>
      </CForm>
    </div>
  );
};

export const AccountRecoveryStepTwoForm = reduxForm<Values>({
  form: verifyPhoneLightForgotPasswordFormName,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateAuthForm,
})(Form);
