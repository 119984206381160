import React, { MouseEvent, useContext } from 'react';

import { Chat } from 'src/v2/features/chat/Chat';
import { getOwnerName } from 'src/v2/features/document';
import { DocumentSidebarAttachments } from 'src/v2/features/documentSidebar/documentSidebarAttachments/documentSidebarAttachments';
import { ParticipantsSection } from 'src/v2/features/sharedEntity';
import { useGetEntityById } from 'src/v2/features/sharedEntity/hooks';
import {
  useCanEditContract,
  useCanAddContractAttachments,
} from 'src/v2/features/contract/permissions/contractPermissions/contractPermissionsHooks';
import {
  useCanEditParticipant,
  useCanRemoveParticipant,
} from 'src/v2/features/contract/workflow/contractWorkflow';
import { ContractType, ContractRole } from 'src/models/contract';
import { SharedSidebarContext } from 'src/v2/features/sharedEntity/EntitySidebar/SharedSidebarContext';

import { ContractMetadataView } from '../../components/ContractMetadata';

interface Props {
  id: string;
  onEdit: (e: MouseEvent) => void;
}

export const AbstractView: React.FC<Props> = ({ id, onEdit }) => {
  const contract = useGetEntityById<ContractType, ContractRole>(id);
  const isAllowedToEdit = useCanEditContract(contract);
  const isAllowedToEditAttachments = useCanAddContractAttachments(contract);
  const { isAllowedToChat } = useContext(SharedSidebarContext);
  const isAllowedToEditParticipant = useCanEditParticipant(id);
  const isAllowedToRemoveParticipant = useCanRemoveParticipant(id);

  return contract ? (
    <>
      <ContractMetadataView
        title={contract.title}
        description={contract.description}
        createdAt={contract.createdAt}
        updatedAt={contract.updatedAt}
        subType={contract.subType}
        subSubType={contract.subSubType}
        state={contract.state}
        owner={getOwnerName(contract)}
        role={contract.ownerFunctionRole}
        isAllowedToEdit={isAllowedToEdit}
        onEdit={onEdit}
      />
      <DocumentSidebarAttachments
        documentId={contract.id}
        isAllowedToEditAttachments={isAllowedToEditAttachments}
      />
      <ParticipantsSection
        entityId={contract.id}
        isAllowedToEditParticipant={isAllowedToEditParticipant}
        isAllowedToRemoveParticipant={isAllowedToRemoveParticipant}
      />
      {isAllowedToChat && <Chat />}
    </>
  ) : null;
};
