import { createContext } from 'react';

interface ContextProps {
  updatedStaticSectionsField: { name: string; sectionIndex: number; id: string } | null;
  setUpdatedStaticSectionsField: any | null;
}

export const StaticSectionsContext = createContext<ContextProps>({
  updatedStaticSectionsField: null,
  setUpdatedStaticSectionsField: null,
});
