import { isNil } from 'lodash';

import { EDITABLE_FIELDS_ATTRIBUTES, EDITABLE_FIELDS_CLASSES } from './constants';
import i18n from '../../../../i18n';
import { translationKeys } from '../../../../common/translations';

export const editableFieldsDiffRenderer = (
  element: Element,
  oldValue: string,
  newValue: string,
  canReview: boolean,
  acceptClick?: () => void,
  rejectClick?: () => void,
) => {
  element.classList.add(EDITABLE_FIELDS_CLASSES.WIDGET_SELECTED);
  element.setAttribute(EDITABLE_FIELDS_ATTRIBUTES.DATA_UPDATING, 'false');
  if (newValue !== oldValue) {
    element.setAttribute(EDITABLE_FIELDS_ATTRIBUTES.NEW_VALUE, newValue);
  }
  element.setAttribute(EDITABLE_FIELDS_ATTRIBUTES.CAN_REVIEW, canReview ? 'true' : 'false');
  element.setAttribute(EDITABLE_FIELDS_ATTRIBUTES.CONTENT_EDITABLE, 'false');

  const fieldTitle = element.querySelector(`.${EDITABLE_FIELDS_CLASSES.EDITABLE_FIELD_TITLE}`);

  if (!isNil(fieldTitle)) {
    fieldTitle.classList.add(EDITABLE_FIELDS_CLASSES.EDITOR_EDITABLE);
    fieldTitle.classList.add(EDITABLE_FIELDS_CLASSES.EDITOR_NESTED_EDITABLE);
  }

  if (!isNil(acceptClick) && !isNil(rejectClick) && canReview && newValue !== oldValue) {
    const buttons = diffReviewButtons(acceptClick, rejectClick);
    element.appendChild(buttons);
  }
};

const diffReviewButtons = (acceptClick: () => void, rejectClick: () => void): HTMLSpanElement => {
  const buttonsContainer = document.createElement('span');
  buttonsContainer.classList.add(EDITABLE_FIELDS_CLASSES.EDITABLE_FIELD_BUTTONS);

  const acceptButton = document.createElement('button');
  acceptButton.classList.add(EDITABLE_FIELDS_CLASSES.BUTTON);
  acceptButton.classList.add(EDITABLE_FIELDS_CLASSES.BUTTON_ACCEPT);
  acceptButton.innerText = i18n(translationKeys.forms.editor.field.accept);
  acceptButton.addEventListener('click', acceptClick);

  const rejectButton = document.createElement('button');
  rejectButton.classList.add(EDITABLE_FIELDS_CLASSES.BUTTON);
  rejectButton.classList.add(EDITABLE_FIELDS_CLASSES.BUTTON_REJECT);
  rejectButton.innerText = i18n(translationKeys.forms.editor.field.reject);
  rejectButton.addEventListener('click', rejectClick);

  buttonsContainer.appendChild(acceptButton);
  buttonsContainer.appendChild(rejectButton);

  return buttonsContainer;
};

export const editableFieldRenderer = (name: string, id: string, value: string): HTMLSpanElement => {
  const field = document.createElement('span');
  field.classList.add(EDITABLE_FIELDS_CLASSES.EDITABLE_FIELD);
  field.setAttribute(EDITABLE_FIELDS_ATTRIBUTES.ID, id);
  field.setAttribute(EDITABLE_FIELDS_ATTRIBUTES.NAME, name);

  const restricted = document.createElement('span');
  restricted.classList.add(EDITABLE_FIELDS_CLASSES.RESTRICTED_EDITING_EXTENSION);

  const fieldTitle = document.createElement('span');
  fieldTitle.classList.add(EDITABLE_FIELDS_CLASSES.EDITABLE_FIELD_TITLE);
  fieldTitle.innerText = value;

  restricted.appendChild(fieldTitle);
  field.appendChild(restricted);
  return field;
};
