export enum UploadError {
  WrongExtension,
  Unknown,
  MaxFileSize,
  DamagedFile,
  NetworkError,
}

export interface ErrorBody {
  code: number;
  detail: string;
  title: string;
}

export interface ErrorsResponse {
  response: {
    data: {
      data: ErrorBody[];
    };
  };
}

export interface FilePayload {
  id: string;
  name: string;
  documentId: string;
}

export interface UploadableFile extends FilePayload {
  progress: number;
  err?: UploadError;
}

export interface CreateUploadFileChannelPayload {
  data?: any;
  progress?: number;
  err?: UploadError;
}

export interface FileUploadState {
  files: UploadableFile[];
}
