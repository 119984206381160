import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { createNewSignatureQueryKey } from 'src/app/book';
import { Signature as UserSignature } from 'src/v2/components/Signature';
import {
  getDefaultSignatureId,
  getDefaultSignatureUserName,
  getUserSignatureList,
} from 'src/v2/features/signature/store';
import { useQuery } from 'src/utils/hooks';

import { CommonSignature } from '../../components/CommonSignature';
import { SignatureList } from '../../components/SignatureList';
import { SignatureAction } from '../../components/SignatureAction';
import { SignatureSettings } from '../../components/SignatureSettings';
import { SignaturePreview } from '../../components/SignaturePreview';
import { signatureFonts } from '../../config';
import {
  useCreateSignatureHandler,
  useEditSignatureHandler,
  useDefaultSignatureChangeHandler,
} from '../../hooks';
import { SignatureAggregatedProvider } from '../../providers/SignatureAggregatedProvider';

interface Props {
  hideList?: boolean;
}

export const Signature: FC<Props> = ({ hideList }) => {
  const query = useQuery();

  const signatures = useSelector(getUserSignatureList);
  const defaultSignatureId = useSelector(getDefaultSignatureId);
  const defaultSignatureUserName = useSelector(getDefaultSignatureUserName);

  const handleCreateNewSignature = useCreateSignatureHandler();
  const handleEditSignature = useEditSignatureHandler();
  const handleDefaultSignatureChange = useDefaultSignatureChangeHandler();

  useEffect(() => {
    if (query.has(createNewSignatureQueryKey)) handleCreateNewSignature();
    else if (defaultSignatureId) handleEditSignature(defaultSignatureId);
  }, [handleCreateNewSignature, handleEditSignature, defaultSignatureId, query]);

  return (
    <SignatureAggregatedProvider>
      <div
        data-id="profile-container-signature"
        className="c-setup c-setup--signature c-setup--bg-transparent"
      >
        <CommonSignature
          signatureFonts={signatureFonts}
          signaturePlaceholder={i18n(translationKeys.forms.signature.yourSignature)}
        >
          <SignaturePreview component={UserSignature} />
          <SignatureAction>
            <SignatureSettings />
          </SignatureAction>
          {!hideList && (
            <SignatureList
              label={i18n(translationKeys.forms.signature.selectDefault)}
              signatures={signatures}
              userName={defaultSignatureUserName}
              defaultSignatureId={defaultSignatureId}
              onSelectDefault={handleDefaultSignatureChange}
              onCreateNew={handleCreateNewSignature}
              onEdit={handleEditSignature}
            />
          )}
        </CommonSignature>
      </div>
    </SignatureAggregatedProvider>
  );
};
