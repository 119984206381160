import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { parseCardPaperDataFactory } from 'src/v2/features/sharedEntity/utils';
import { book } from 'src/app/book';
import { ContractRole } from 'src/models/contract';
import { fetchDocumentInfo } from 'src/v2/features/documentDetails/documentDetailsSlice';

export const parseCardData = parseCardPaperDataFactory<ContractRole>(
  i18n(translationKeys.contract.noTitle),
  ContractRole.Owner,
);

export const useCreateNavigateToContractDetails = (
  folderId?: string,
): ((id: string) => () => void) => {
  const navigate = useNavigate();

  return useCallback(
    (id: string) => (): void => {
      navigate(book.contract.generatePath(id, folderId));
    },
    [navigate, folderId],
  );
};

export const useCreateGetContractInfo = (): ((id: string) => () => void) => {
  const dispatch = useDispatch();

  return useCallback(
    (id: string) => (): void => {
      dispatch(fetchDocumentInfo(id));
    },
    [dispatch],
  );
};
