import React from 'react';
import { useSelector } from 'react-redux';
import { confirmation } from 'redux-form-validators';
import { Field, reduxForm, InjectedFormProps, formValueSelector } from 'redux-form';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { Input } from 'src/v2/features/reduxForm';
import { CForm, CFormRow } from 'src/v2/components/CForm';
import { RootState } from 'src/app/types';
import { AccountType } from 'src/models/signUp';
import { validateAuthForm } from 'src/utils/validate';
import { validatePassword, checkPassStrength } from 'src/utils/passwordStrength';
import { Password } from 'src/v2/features/reduxForm/Password';

import { PasswordStrengthIndicator } from './components/PasswordStrenghIndicator';
import { FormHeader } from './components/FormHeader';
import { useIsSignupFromInvite } from './pages/hooks';
import { isOrgAccount } from './utils';
import { Capture } from '../../components/Сapture/Capture';
import { getIsLoading } from './store';

export interface FormProps {
  email: string;
  password: string;
  companyName?: string;
}

export interface Props {
  accountType: AccountType;
  handleSubmitCapture: (token: string) => void;
  isNotCaptureMode: boolean;
  resetCapture: boolean;
}

export const emailPasswordFormName = 'email-password';

export const Form: React.FC<Props & InjectedFormProps<FormProps, Props>> = ({
  handleSubmit,
  accountType,
  invalid,
  handleSubmitCapture,
  isNotCaptureMode,
  resetCapture,
}) => {
  const { password: passwordValue } = useSelector((state: RootState) => {
    const result = formValueSelector(emailPasswordFormName)(state, 'password');
    return { password: result };
  });
  const isLoading = useSelector(getIsLoading);

  const strength = passwordValue ? checkPassStrength(passwordValue) : undefined;
  const [isSignupFromInvite] = useIsSignupFromInvite();

  return (
    <>
      <FormHeader
        title={i18n(translationKeys.forms.createPassword.title)}
        description={i18n(translationKeys.forms.createPassword.subTitle)}
      />
      <div className="c-setup__content">
        <CForm data-id="signup-form-email-password" onSubmit={handleSubmit} noValidate>
          <CFormRow required>
            <Field
              className="c-form__column"
              name="email"
              type="email"
              placeholder={i18n(translationKeys.forms.createPassword.email)}
              component={Input}
              autoComplete="off"
              required
              disabled={isSignupFromInvite}
            />
          </CFormRow>

          {isOrgAccount(accountType) && (
            <CFormRow required>
              <Field
                className="c-form__column"
                name="companyName"
                type="companyName"
                placeholder={i18n(translationKeys.forms.createPassword.companyName)}
                autoComplete="off"
                component={Input}
                required
              />
            </CFormRow>
          )}
          <CFormRow required>
            <Field
              className="c-form__column"
              name="password"
              type="password"
              placeholder={i18n(translationKeys.forms.createPassword.password)}
              component={Password}
              validate={validatePassword}
              required
            />
          </CFormRow>
          <CFormRow required>
            <Field
              className="c-form__column"
              name="confirmPassword"
              type="password"
              placeholder={i18n(translationKeys.forms.createPassword.verifyPassword)}
              component={Password}
              validate={confirmation({
                field: 'password',
                message: i18n(translationKeys.errors.PASSWORD_NOT_MATCH),
              })}
              required
            />
          </CFormRow>
          <PasswordStrengthIndicator strength={strength} />
          <CFormRow required>{i18n(translationKeys.errors.REQUIRED)}</CFormRow>
          {!isNotCaptureMode && (
            <Capture
              onChange={handleSubmitCapture}
              isShow={!invalid && !isLoading}
              position={'center'}
              resetCapture={resetCapture}
            />
          )}
        </CForm>
      </div>
    </>
  );
};

export const EmailPasswordForm = reduxForm<FormProps, Props>({
  form: emailPasswordFormName,
  initialValues: { password: '' },
  validate: validateAuthForm,
})(Form);
