/* eslint-disable no-param-reassign */
// Core
import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { call, takeLatest, put } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { get } from 'lodash';
import { startSubmit as startFormSubmit, stopSubmit as stopFormSubmit } from 'redux-form';

// Instruments
import { requestResetCode, sendResetToken, resetPassword } from 'src/api/authApi';
import {
  openRecoveryModal,
  requestOptCode,
  resetPasswordAsync,
  setMessage,
  submitOtpCode,
} from './accountRecoveryStore';
import {
  RequestOptCodeActionPayload,
  ResetPasswordActionPayload,
  SubmitOtpCodeActionPayload,
} from 'src/v2/boundary/actionPayload/accountRecovery';

export function* requestOptCodeSaga(
  action: PayloadAction<RequestOptCodeActionPayload>,
): SagaIterator {
  const { email, phone, formName, captureToken } = action.payload;

  try {
    yield put(startFormSubmit(formName));
    yield call(requestResetCode, { email, phone }, captureToken);
    yield put(openRecoveryModal.otp());
  } catch (err) {
    toastr.error('Error', 'Send SMS with reset code has failed.');
  } finally {
    yield put(stopFormSubmit(formName));
  }
}

export function* submitOtpSaga(action: PayloadAction<SubmitOtpCodeActionPayload>): SagaIterator {
  try {
    const { code, formName, email, url } = action.payload;
    yield put(startFormSubmit(formName));
    yield call(sendResetToken, { email, code, url });
    yield put(
      setMessage(
        'You should have received an email at the address we have on file. Please check your email and follow the instructions.',
      ),
    );
    yield put(openRecoveryModal.message());
  } catch (err) {
    toastr.error(
      'Error',
      'Verify phone request has failed. Please make sure that you have verified your email.',
    );
  } finally {
    const { formName } = action.payload;

    yield put(stopFormSubmit(formName));
  }
}

export function* resetPasswordSaga(
  action: PayloadAction<ResetPasswordActionPayload>,
): SagaIterator {
  try {
    const { formName, newPassword, token } = action.payload;

    yield put(startFormSubmit(formName));

    yield call(resetPassword, { newPassword, token });

    yield put(setMessage('You have successfully updated your password!\nYou can now log in.'));

    yield put(openRecoveryModal.message());
  } catch (err) {
    const errorMessage =
      get(err, 'response.status') === 500
        ? 'Reset token has expired. Please click Forgot Login again.'
        : 'Reset password request has failed';
    toastr.error('Error', errorMessage);
  } finally {
    const { formName } = action.payload;

    yield put(stopFormSubmit(formName));
  }
}

export function* watchAccountRecoverySagas(): SagaIterator {
  yield takeLatest(requestOptCode, requestOptCodeSaga);
  yield takeLatest(submitOtpCode, submitOtpSaga);
  yield takeLatest(resetPasswordAsync, resetPasswordSaga);
}
