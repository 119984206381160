const basicBook = {
  create: 'create',
  edit: 'edit',
  view: 'view',
  remove: 'remove',
  removeFolder: 'removeFolder',
  removeSection: 'removeSection',
  removeParticipant: 'removeParticipant',
};

export const book = Object.freeze({
  ...basicBook,
  default: basicBook.create,
});
