const basicBook = {
  nav: 'nav',
  createUser: 'createUser',
  editUser: 'editUser',
  deleteUser: 'deleteUser',
};

export const book = Object.freeze({
  ...basicBook,
  default: basicBook.nav,
});
