import { useCallback, useEffect } from 'react';

import { eventBusFactory } from 'src/app/eventBus';
import { Subscription } from 'rxjs';

import { SystemEvent, SystemEventData } from './types';

const eventBusApi = eventBusFactory<SystemEventData>();

const { emit, subscribeToEvent } = eventBusApi;

export const emitLogout = (): void => emit({ type: SystemEvent.Logout });
export const subscribeLogout = (callback: (eventData: SystemEventData) => void): Subscription =>
  subscribeToEvent(SystemEvent.Logout, callback);

export const useGetClickOnRootListener = (shouldClose: { value: boolean }) => {
  const listener = useCallback(() => {
    if (shouldClose.value) {
      emit({ type: SystemEvent.ClickOnRootElement });
    }
  }, [shouldClose.value]);

  return listener;
};

export const useGetClickOnRootListenerCapture = (shouldClose: { value: boolean }) => {
  const listener = useCallback(() => {
    shouldClose.value = true;
  }, [shouldClose]);

  return listener;
};

export const useSubscribeToClickOnRootEffct = (cb: (eventData: SystemEventData) => void) =>
  useEffect(() => {
    const subscription$ = subscribeToEvent(SystemEvent.ClickOnRootElement, cb);

    return () => subscription$.unsubscribe();
  }, [cb]);
