import { useEffect } from 'react';
import { isNull, isUndefined } from 'lodash';
import { useDispatch } from 'react-redux';

import { ProjectEntity, ProjectRole } from 'src/v2/entities/project';
import { CardParticipantEntity } from 'src/v2/entities/participants';
import { PaperState } from 'src/models/paper';
import { fetchProjectsList } from 'src/v2/features/project/store/projectReducers';
import { SortDirection, SortedFields } from 'src/models/sort';
import { getFullName } from '../document/selectors';

export function getOwnerName(owner: CardParticipantEntity<ProjectRole>): string {
  return getFullName(owner.firstName, owner.lastName);
}

export const getIsProjectHasSignedContracts = (project: ProjectEntity | null) =>
  isNull(project)
    ? false
    : project.papers.filter((p) => p.state === PaperState.completed).length > 0;

export const useFetchProjectsListEffct = (
  projectRootFolderId: string | undefined,
  sortDirection: SortDirection = SortDirection.Desc,
  sortedField: SortedFields = SortedFields.Title,
) => {
  const dispatch = useDispatch();

  useEffect((): void => {
    if (!isUndefined(projectRootFolderId)) {
      dispatch(fetchProjectsList(projectRootFolderId));
    }
  }, [dispatch, sortDirection, sortedField, projectRootFolderId]);
};
