import { EventData } from 'src/app/eventBus';

export interface CreateProjectFormPayload {
  title: string;
  description: string;
}

export enum ProjectEvent {
  CreateProject,
}

export type ProjectEventData = EventData<ProjectEvent>;

export interface CreateProjectPayload {
  id: string;
}
export interface ProjectEventDataWithPayload extends EventData<ProjectEvent> {
  payload: CreateProjectPayload;
}
