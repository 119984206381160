/* eslint-disable no-console */
import { Subject } from 'rxjs';
import { PayloadAction, PayloadActionCreator } from '@reduxjs/toolkit';
import { Store } from 'redux';
import { first, timeout } from 'rxjs/operators';

import { SocketConnectionId, WSEvent } from 'src/v2/boundary/socket';

import { socketConnected, socketDisconnected } from './store';
import { SocketConnectionOptions } from './types';
import { logWarning, logError, logInfo } from './logger';

const WS_EMIT_TIMEOUT = 30000;
const WS_RECONNECTION_TIMEOUT = 2500;
const WS_KEEP_ALIVE_INTERVAL = 30000;

const SOCKET_CLOSE_LOGOUT_CODE = 4100;

export class SocketConnection {
  private connection: WebSocket | null = null;

  private currentToken: string | null = null;

  private reconnectionTimeout: ReturnType<typeof setTimeout> | null = null;

  private keepAliveInterval: ReturnType<typeof setInterval> | null = null;

  private initialConnection = true;

  // subject which emits each time WebSocket is opened
  private connectionOpened$ = new Subject<WebSocket>();

  // registered actions to be listened from backend
  private socketEvents = new Map<string, PayloadActionCreator<unknown>>();

  private store: Store | null = null;

  constructor(
    private id: SocketConnectionId,
    private url: string,
    private options?: SocketConnectionOptions,
  ) {}

  public setStore(store: Store): void {
    this.store = store;
  }

  public setToken(token: string): void {
    this.currentToken = token !== '' ? token : null;
    this.reconnect();
    this.initialConnection = true;
  }

  private connect(): void {
    if (this.url && this.currentToken) {
      this.connection = new WebSocket(`${this.url}?token=${this.currentToken}`);

      this.connection.addEventListener('open', this.onOpen.bind(this));
      this.connection.addEventListener('error', this.onError.bind(this));
      this.connection.addEventListener('close', this.onClose.bind(this));
      this.connection.addEventListener('message', this.onMessage.bind(this));

      if (this.options && this.options.keepAlive) {
        logInfo(this.id, 'Setting up heartbeat interval');
        this.keepAliveInterval = setInterval(() => this.sendHeartbeat(), WS_KEEP_ALIVE_INTERVAL);
      }
    } else {
      logError(this.id, 'Invalid token or url');

      this.reconnectionTimeout = setTimeout(() => {
        logInfo(this.id, 'WebSocket reconnect');
        this.connect();
      }, WS_RECONNECTION_TIMEOUT);
    }
  }

  private disconnect(): void {
    if (this.reconnectionTimeout) clearTimeout(this.reconnectionTimeout);
    if (this.keepAliveInterval) clearInterval(this.keepAliveInterval);

    if (!this.isClosed() && this.connection) {
      this.connection.close(SOCKET_CLOSE_LOGOUT_CODE, 'Logout');
    }
  }

  private reconnect(): void {
    if (!this.isClosed()) this.disconnect();
    if (this.currentToken === null) return;

    this.connect();
  }

  private isClosed(): boolean {
    return (
      !this.connection || [WebSocket.CLOSED, WebSocket.CLOSING].includes(this.connection.readyState)
    );
  }

  getConnection = async (): Promise<WebSocket> => {
    if (this.connection && this.connection.readyState === WebSocket.OPEN) {
      return this.connection;
    }

    const socket = await this.connectionOpened$
      .pipe(timeout(WS_EMIT_TIMEOUT))
      .pipe(first())
      .toPromise();

    if (!socket) {
      throw new Error('Failed to get a socket connection');
    }

    return socket;
  };

  private dispatch(action: PayloadAction<unknown>): void {
    if (!this.store) throw new Error('Store is not attached');

    this.store.dispatch(action);
  }

  private onOpen(): void {
    this.dispatch(
      socketConnected({
        connectionId: this.id,
        initialConnection: this.initialConnection,
      }),
    );

    this.connectionOpened$.next(this.connection as WebSocket);
  }

  private onError(event: Event): void {
    logError(this.id, 'Socket encountered error: ', event, 'Closing socket');
    if (!this.isClosed()) {
      this.disconnect();
    }
  }

  private onClose(event: CloseEvent): void {
    logInfo(
      this.id,
      `Socket is closed. Code: ${event.code}. Reason: ${event.reason}; Datetime: ${new Date()}`,
    );

    this.connection = null;

    if (event.code !== SOCKET_CLOSE_LOGOUT_CODE) {
      logInfo(this.id, `Attempting reconnect in ${WS_RECONNECTION_TIMEOUT} ms`);

      this.initialConnection = false;
      this.reconnectionTimeout = setTimeout(() => {
        this.connect();
      }, WS_RECONNECTION_TIMEOUT);
    }

    this.dispatch(socketDisconnected({ connectionId: this.id }));
  }

  private onMessage(event: MessageEvent): void {
    let data: { type: string } | null = null;
    try {
      data = JSON.parse(event.data);
    } catch (e) {
      logError(this.id, 'Malformed websocket event received', e.toString());
      return;
    }

    if (!data || !data.type) {
      logError(this.id, 'Malformed websocket event received');
      return;
    }

    if (!this.socketEvents.has(data.type)) {
      logWarning(this.id, `WebSocket event ${data.type} is not registered to listen`);
      return;
    }

    const action = this.socketEvents.get(data.type) as PayloadActionCreator<unknown>;
    this.dispatch(action(data));
  }

  public listenEvent(action: PayloadActionCreator<unknown>): void {
    this.socketEvents.set(action.type, action);
  }

  private async sendHeartbeat(): Promise<void> {
    const socket = await this.getConnection();
    socket.send('heartbeat');
  }

  public async sendCommand<T>(event: WSEvent<T>): Promise<void> {
    const socket = await this.getConnection();
    socket.send(JSON.stringify(event));
  }
}
