import { createContext } from 'react';
import { noop } from 'lodash';

import { normalizeAvatar } from 'src/v2/features/profile/utils';

import { SignaturePreviewContextValue } from '../types';

export const SignaturePreviewContext = createContext<SignaturePreviewContextValue>({
  avatarUrl: normalizeAvatar(),
  signaturePreviewUrl: null,
  uploadedImageUrl: null,
  meta: {
    hash: '',
    signedBy: null,
    setSignedBy: noop,
    companyName: null,
    setCompanyName: noop,
    companyTitle: null,
    setCompanyTitle: noop,
  },
});
