import React, { FC } from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { flow } from 'lodash';

import { withInitialValues } from 'src/utils/hocs';

import { ProjectMetadataForm } from './ProjectMetadataForm';

interface Props {
  title?: string;
  description?: string;
  isLoading: boolean;
  onSubmit: (data: Payload) => void;
}

interface Payload {
  title: string;
  description: string;
}

const Form: FC<Props & InjectedFormProps<Payload, Props>> = ({
  isLoading,
  onSubmit,
  handleSubmit,
  pristine,
}) => (
  <ProjectMetadataForm
    label="Update Project"
    isLoading={isLoading}
    isPristine={pristine}
    onSubmit={handleSubmit(onSubmit)}
  />
);

export const ProjectMetadataEdit = flow([
  reduxForm<Payload, Props>({ form: 'editProjectMetadata', enableReinitialize: true }),
  withInitialValues<Payload, Props>(['title', 'description']),
])(Form);
