import { entitySidebarControllerFactory } from 'src/v2/features/sharedEntity/EntitySidebar/entitySidebarControllerFactory';
import { book } from 'src/v2/features/sharedEntity/EntitySidebar/book';
import { entityParticipantRoutes } from 'src/v2/features/sharedEntity/EntitySidebar/routes';

import { EditDocumentParticipant } from './pages/EditDocumentParticipant';
import { InviteDocumentParticipant } from './pages/InviteDocumentParticipant';
import { View } from './pages/View';
import { Edit } from './pages/Edit';
import { Create } from './pages/Create';
import { book as documentSidebarBook } from './book';
import { QRCode } from '../../qrCode/QrCode';

export const sidebarName = 'documentSidebar';

export const sidebarController = entitySidebarControllerFactory(sidebarName, [
  {
    path: book.view,
    render: View,
  },
  {
    path: book.create,
    render: Create,
  },
  {
    path: book.edit,
    render: Edit,
  },
  {
    path: documentSidebarBook.documentQRCode,
    render: QRCode,
  },
  {
    path: documentSidebarBook.editDocumentParticipant,
    render: EditDocumentParticipant,
  },
  {
    path: documentSidebarBook.inviteDocumentParticipant,
    render: InviteDocumentParticipant,
  },
  ...entityParticipantRoutes,
]);
