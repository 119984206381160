import { includes } from 'lodash';

import { SubscriptionPlan, UserPlan } from 'src/models/billing';
import { ContractRole } from 'src/models/contract';
import { PaperState } from 'src/models/paper';

export const canEditContractMetadata = (actorRole: ContractRole) =>
  includes([ContractRole.Owner, ContractRole.Manager], actorRole);

export const canEditContractFields = (actorRole: ContractRole) =>
  includes([ContractRole.Owner, ContractRole.Manager], actorRole);

export const canEditContract = (actorRole: ContractRole) =>
  includes([ContractRole.Owner, ContractRole.Manager, ContractRole.Editor], actorRole);

export const canEditAccessInContract = (actorRole: ContractRole) =>
  includes([ContractRole.Owner], actorRole);

export const canDeleteContract = (state: PaperState) => state !== PaperState.completed;

export const canNegotiateContract = (actorRole: ContractRole) =>
  includes([ContractRole.Owner, ContractRole.Manager, ContractRole.Editor], actorRole);

export const canAddContractAttachments = (actorRole: ContractRole) =>
  includes([ContractRole.Owner, ContractRole.Manager, ContractRole.Editor], actorRole);

export const canCommentInContract = (actorRole: ContractRole) =>
  includes(
    [
      ContractRole.Owner,
      ContractRole.Manager,
      ContractRole.Editor,
      ContractRole.Signatory,
      ContractRole.Commentator,
    ],
    actorRole,
  );

export const canSignContract = (actorRole: ContractRole) =>
  includes(
    [ContractRole.Owner, ContractRole.Manager, ContractRole.Editor, ContractRole.Signatory],
    actorRole,
  );

export const canManageParticipantInContract = (actorRole: ContractRole) =>
  includes(
    [
      ContractRole.Owner,
      ContractRole.Manager,
      ContractRole.Editor,
      ContractRole.Signatory,
      ContractRole.Commentator,
      ContractRole.Viewer,
    ],
    actorRole,
  );

export const canCreateContract = (plan: UserPlan) => true;

export const canCopyContract = (actorRole: ContractRole, plan: UserPlan) =>
  includes([ContractRole.Owner], actorRole) &&
  !includes([SubscriptionPlan.Basic, SubscriptionPlan.Premium], plan);

export const canMoveContract = (plan: UserPlan) =>
  !includes([SubscriptionPlan.Basic, SubscriptionPlan.Premium], plan);

export const canShareContract = (actorRole: ContractRole) =>
  includes(
    [
      ContractRole.Owner,
      ContractRole.Manager,
      ContractRole.Editor,
      ContractRole.Signatory,
      ContractRole.Commentator,
      ContractRole.QRViewer,
      ContractRole.Viewer,
      ContractRole.SharedViewer,
      ContractRole.DocumentSharer,
      ContractRole.DocumentSharerDownloader,
    ],
    actorRole,
  );

export const canDownloadContract = (actorRole: ContractRole) =>
  includes(
    [
      ContractRole.Owner,
      ContractRole.Manager,
      ContractRole.Editor,
      ContractRole.Signatory,
      ContractRole.Commentator,
      ContractRole.QRViewer,
      ContractRole.Viewer,
      ContractRole.SharedViewer,
      ContractRole.Downloader,
      ContractRole.DocumentSharerDownloader,
    ],
    actorRole,
  );

export const canCreateContractTemplate = (actorRole: ContractRole) =>
  includes([ContractRole.Owner], actorRole);

export const canViewContractHistory = (actorRole: ContractRole): boolean =>
  includes([ContractRole.Owner, ContractRole.Manager], actorRole);

export const canViewContractVersions = (actorRole: ContractRole): boolean =>
  includes([ContractRole.Owner, ContractRole.Manager], actorRole);

export const canRestoreContract = (actorRole: ContractRole) =>
  includes([ContractRole.Owner], actorRole);

export const canViewContractNotification = (actorRole: ContractRole) =>
  includes(
    [
      ContractRole.Owner,
      ContractRole.Manager,
      ContractRole.Editor,
      ContractRole.Signatory,
      ContractRole.Commentator,
      ContractRole.QRViewer,
      ContractRole.Viewer,
      ContractRole.SharedViewer,
    ],
    actorRole,
  );

export const canChatInContract = (actorRole: ContractRole) =>
  includes(
    [
      ContractRole.Owner,
      ContractRole.Manager,
      ContractRole.Editor,
      ContractRole.Signatory,
      ContractRole.Commentator,
      ContractRole.QRViewer,
    ],
    actorRole,
  );

export const canViewOnlineParticipantsContract = (actorRole: ContractRole) =>
  includes(
    [
      ContractRole.Owner,
      ContractRole.Manager,
      ContractRole.Editor,
      ContractRole.Signatory,
      ContractRole.Commentator,
      ContractRole.QRViewer,
      ContractRole.Viewer,
    ],
    actorRole,
  );

export const canViewQRCodeInContract = (plan: UserPlan): boolean =>
  !includes([SubscriptionPlan.Basic, SubscriptionPlan.Standard, SubscriptionPlan.Premium], plan);

export const canCreateFolderInContracts = (plan: UserPlan): boolean =>
  !includes([SubscriptionPlan.Basic, SubscriptionPlan.Premium], plan);
