import { SubscriptionPlan, BillingPeriod, PlanAndPeriod } from 'src/models/billing';
import { EventData } from 'src/app/eventBus';

export interface UpgradePayload {
  plan: SubscriptionPlan | null;
  period: BillingPeriod | null;
}

export interface UpgradePayloadWithSave extends UpgradePayload {
  onBillingInfoSave: () => void;
}

export interface BillingPlanFormValues {
  planAndPeriod: PlanAndPeriod;
}

export interface BillingMigrationCallback {
  onMigration: (migrateTo: UpgradePayload) => void;
}

export enum BillingEvent {
  PaymentSuccess,
  UserNeedsToUpgradePlan,
  BillingInfoSuccess,
}

export type BillingEventData = EventData<BillingEvent>;

export interface UpgradeEvent extends BillingEventData {
  payload: UpgradePayload;
}
