import { get, isEmpty, isNull, isUndefined } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'src/app/types';
import { Document } from 'src/models/document';
import { getMyRole } from 'src/v2/features/documentParticipants';
import { getUserId } from 'src/shared/auth';
import { PaperModel } from 'src/models/paper';
import { CardUserIconData } from 'src/models/card';
import defaultAvatar from 'src/assets/placeholders/user-avatar.png';

export const getOwnerName = (data: PaperModel<unknown, unknown>): string => {
  if (isUndefined(data.owner)) return getFullName(undefined, undefined);

  return getFullName(data.owner.firstName, data.owner.lastName);
};

export const getOwnerAvatar = (data: PaperModel<unknown, unknown>): string => {
  if (isUndefined(data.owner) || isUndefined(data.owner.avatar)) {
    return defaultAvatar;
  }

  return data.owner.avatar;
};

export const getFullName = (firstName: string | undefined, lastName: string | undefined): string =>
  `${firstName} ${lastName}`.trim();

export const getShownName = (
  firstName: string | undefined,
  lastName: string | undefined,
  placeholder: string,
): string =>
  isEmpty(firstName) && isEmpty(lastName) ? placeholder : getFullName(firstName, lastName);

export const getInitials = (firstName: string, lastName: string, placeholder: string): string =>
  isEmpty(firstName) || isEmpty(lastName)
    ? placeholder
    : `${firstName.charAt(0)}${lastName.charAt(0)}`;

// used only in search should be redone with search
/** @deprecated */
export const getUserFeedObjectsWithInitials = (data: Document): CardUserIconData[] =>
  data.paperParticipant.map(({ userId, user: { avatar, firstName, lastName, email } }) => ({
    id: userId,
    fullName: getShownName(firstName, lastName, email),
    initials: getInitials(firstName, lastName, email.substr(0, 2)),
    avatarUrl: avatar || null,
  }));

/** @deprecated */
export const documentSelectorsFactory = (getDocument: (state: RootState) => Document | null) => {
  const getDocumentId = (state: RootState) => get(getDocument(state), 'id', '') as string;
  const getDocumentBase = (state: RootState) => getDocument(state);
  const getTitle = (state: RootState) => get(getDocument(state), 'title', '') as string;

  const getAllParticipants = (state: RootState) => {
    const paper = getDocument(state);
    return isNull(paper) ? [] : paper.paperParticipant;
  };

  const getAllParticipantsIds = (state: RootState) =>
    getAllParticipants(state).map((p) => p.userId);

  return {
    getMyRole: createSelector(getAllParticipants, getUserId, getMyRole),
    getDocumentId,
    getDocumentBase,
    getTitle,
    getAllParticipants,
    getAllParticipantsIds,
  };
};
