import { buttons } from '.';

export const links = {
  header: {
    display: 'inline-block',
    fontFamily: 'Arial',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 1.2,
    letterSpacing: 'normal',
    color: '#4d6982',
    textTransform: 'uppercase',
    textDecoration: 'none',
    m: 0,
    p: '5px',
    '&:hover': {
      opacity: 0.8,
    },
  },
  headerInverted: {
    display: 'inline-block',
    fontFamily: 'Arial',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 1.2,
    letterSpacing: 'normal',
    color: '#ffffff',
    textDecoration: 'none',
    m: 0,
    p: '5px',
    '&:hover': {
      color: '#e6e6e6',
    },
  },
  inline: {
    fontFamily: 'inherit',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    letterSpacing: 'inherit',
    textDecoration: 'underline',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  accentButton: {
    ...buttons.accent,
    textDecoration: 'none',
  },
  outlineButton: {
    ...buttons.outline,
    textDecoration: 'none',
    '&:hover': {
      opacity: 0.8,
    },
  },
};
