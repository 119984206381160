import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SignatureFont } from 'src/models/signature';

import { TextSignatureContextValue } from '../types';
import { TextSignatureContext } from '../contexts/TextSignatureContext';
import { getFont, getLegalName, setFont, setLegalName, renderPreview } from '../store';

export const TextSignatureContextProvider: FC = ({ children }) => {
  const text = useSelector(getLegalName);
  const font = useSelector(getFont);
  const dispatch = useDispatch();

  const updateFont = useCallback(
    (font: SignatureFont) => {
      dispatch(setFont(font));
      dispatch(renderPreview());
    },
    [dispatch],
  );

  const updateText = useCallback(
    (text: string) => {
      dispatch(setLegalName(text));
      dispatch(renderPreview());
    },
    [dispatch],
  );

  const ctxValue: TextSignatureContextValue = {
    text,
    font,
    updateFont,
    updateText,
  };

  return <TextSignatureContext.Provider value={ctxValue}>{children}</TextSignatureContext.Provider>;
};
