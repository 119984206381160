import { FC, useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNull, isUndefined } from 'lodash';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { EntityType } from 'src/models/paper';
import { DocumentCardPaperModel } from 'src/models/document';
import { useCreateNavigateToDocumentDetails } from 'src/v2/features/document';
import { canDeleteDocument } from 'src/v2/features/document/permissions/documentPermissions/documentPermissions';
import {
  ObjectsListPage,
  RenderSystemCardProps,
} from 'src/v2/features/objectsList/ObjectsListPage';
import { fetchDocumentInfo } from 'src/v2/features/documentDetails/documentDetailsSlice';
import { deleteDocument, getDeletedId } from 'src/v2/features/documentList/documentListSlice';
import { useGetCurrentFolderId } from 'src/v2/features/quantumNavigator/hooks';
import { useGetEntityById, useNormalizedEntityId } from 'src/v2/features/sharedEntity';
import { usePreventOperationForDeletedEntity } from 'src/v2/features/sharedEntity/hooks';

import { DocumentCard } from './components/DocumentCard';
import { DocumentSystemCard } from './components/DocumentSystemCard';
import { parseCardData } from './utils';
import { DocumentLayout } from './DocumentLayout';
import { getRootDocumentFolderId } from './store';
import { createDocumentFromTemplate } from 'src/v2/features/createFromTemplate/actions';
import { DynamicSettingsContext } from 'src/v2/modules/layout';

export const DocumentListPageWithoutContext: FC = () => {
  const documentsFolderId = useSelector(getRootDocumentFolderId);
  const currentFolderId = useGetCurrentFolderId();
  const folderId = currentFolderId || documentsFolderId;
  const createOpenDocumentHandler = useCreateNavigateToDocumentDetails(folderId);
  const id = useNormalizedEntityId();
  const selectedDocument = useGetEntityById(id);
  const selectedDocumentId = isNull(selectedDocument) ? null : selectedDocument.id;
  const deletedDocumentId = useSelector(getDeletedId);
  const preventOperationForDeletedDocument = usePreventOperationForDeletedEntity(deletedDocumentId);
  const { onPlusClick: onAdd } = useContext(DynamicSettingsContext);
  const dispatch = useDispatch();

  const handleDeleteDocument = useCallback(
    (paper: DocumentCardPaperModel) => {
      if (canDeleteDocument(paper.state)) {
        dispatch(deleteDocument(paper.id));
      }
    },
    [dispatch],
  );

  const handleCreateDocument = useCallback(
    (values) => {
      if (!isUndefined(folderId)) {
        const { title, description, type, subType, templateId } = values;
        const paper = {
          data: {
            role: 'Document Creator',
            title,
            description,
            subType: type,
            subSubType: subType,
            type: EntityType.document,
          },
          folderId,
          sourceId: templateId,
        };

        dispatch(createDocumentFromTemplate({ ...paper }));
      }
    },
    [dispatch, folderId],
  );

  return (
    <ObjectsListPage<DocumentCardPaperModel>
      onIdChanged={fetchDocumentInfo}
      onDelete={preventOperationForDeletedDocument(id, handleDeleteDocument)}
      onCreate={onAdd}
      renderLayout={({ isLoading, error, children }) => (
        <DocumentLayout isLoading={isLoading} error={error}>
          {children}
        </DocumentLayout>
      )}
      renderCard={({ cardPaper, onDelete, selected, onClick }) => (
        <DocumentCard
          key={cardPaper.id}
          data={parseCardData(cardPaper)}
          onClick={preventOperationForDeletedDocument(cardPaper.id, onClick)}
          onDelete={onDelete}
          onDoubleClick={preventOperationForDeletedDocument(
            cardPaper.id,
            createOpenDocumentHandler(cardPaper.id),
          )}
          selected={selected}
        />
      )}
      renderSystemCard={(systemCardPaper: RenderSystemCardProps) => (
        <DocumentSystemCard
          key={systemCardPaper.templateId}
          systemCardPaper={systemCardPaper}
          createDocument={() => handleCreateDocument(systemCardPaper)}
        />
      )}
      rootFolderId={documentsFolderId}
      selectedPaperId={selectedDocumentId}
      defaultGridTitle={i18n(translationKeys.card.document.title)}
      entityType={EntityType.document}
    />
  );
};
