import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ObjectBase } from 'src/common/types';
import { SignatureState } from 'src/app/types';
import { SignatureFont } from 'src/models/signature';
import { defaultActionsFactory } from 'src/utils/defaultSlice';

export const fetchSignatures = createAction('signature/fetchSignatures');
export const setDefaultSignature = createAction<string>('signature/setDefaultSignature');
export const renderPreview = createAction('signature/renderPreview');
export const saveSignature = createAction('signature/saveSignature');

const initialState: SignatureState = {
  signatureId: null,
  hash: '',
  legalName: null,
  isLoading: false,
  error: '',
  data: null,
  font: null,
  signedBy: null,
  companyName: null,
  companyTitle: null,
  includeCompanyMeta: false,
  signaturePreview: null,
  signatureFile: null,
  uploadedImageUrl: null,
};

const { onStart, onError, onSuccess } = defaultActionsFactory();

const signatureSlice = createSlice({
  name: 'signature',
  initialState,
  reducers: {
    startLoading: onStart,

    stopLoading: onError,

    finishLoading: onSuccess,

    setData(state: SignatureState, { payload }: PayloadAction<ObjectBase>): void {
      state.data = payload;
    },

    createNewSignature(state: SignatureState): void {
      state.signatureId = null;
      state.hash = '';
      state.legalName = null;
      state.font = null;
      state.signedBy = null;
      state.companyName = null;
      state.companyTitle = null;
      state.includeCompanyMeta = false;
      state.signaturePreview = null;
      state.signatureFile = null;
      state.uploadedImageUrl = null;
    },

    editSignature(state: SignatureState, action: PayloadAction<string>): void {
      state.signatureId = action.payload;
    },

    setLegalName(state: SignatureState, action: PayloadAction<string>): void {
      state.legalName = action.payload;
    },

    setFont(state: SignatureState, action: PayloadAction<SignatureFont>): void {
      state.font = action.payload;
    },

    setUploadedImageUrl(state: SignatureState, action: PayloadAction<string | null>): void {
      state.uploadedImageUrl = action.payload;
      state.signatureFile = null;
      state.signaturePreview = null;
    },

    setSignatureFile(state: SignatureState, action: PayloadAction<File>): void {
      state.signatureFile = action.payload;
      state.font = null;
    },

    setSignatureHash(state: SignatureState, action: PayloadAction<string>): void {
      state.hash = action.payload;
    },

    removeSignatureFile(state: SignatureState): void {
      state.signatureFile = null;
      state.signaturePreview = null;
      state.uploadedImageUrl = null;
    },

    setSignaturePreview(state: SignatureState, action: PayloadAction<string>): void {
      state.signaturePreview = action.payload;
    },

    removeSignaturePreview(state: SignatureState): void {
      state.signaturePreview = null;
    },

    setSignedBy(state: SignatureState, action: PayloadAction<string>): void {
      state.signedBy = action.payload;
    },

    setCompanyName(state: SignatureState, action: PayloadAction<string>): void {
      state.companyName = action.payload;
    },

    setCompanyTitle(state: SignatureState, action: PayloadAction<string>): void {
      state.companyTitle = action.payload;
    },

    setIncludeCompanyMeta(state: SignatureState, action: PayloadAction<boolean>): void {
      state.includeCompanyMeta = action.payload;
    },
  },
});

export const signatureReducer = signatureSlice.reducer;

export const {
  startLoading,
  stopLoading,
  finishLoading,
  setData,
  createNewSignature,
  editSignature,
  setLegalName,
  setFont,
  setUploadedImageUrl,
  setSignatureFile,
  setSignatureHash,
  removeSignatureFile,
  setSignaturePreview,
  removeSignaturePreview,
  setSignedBy,
  setCompanyName,
  setCompanyTitle,
  setIncludeCompanyMeta,
} = signatureSlice.actions;
