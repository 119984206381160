import { FC, useMemo } from 'react';
import { Field, InjectedFormProps } from 'redux-form';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { CreationMode } from 'src/api/documents';
import { Divider } from 'src/v2/components/Divider';
import { connectForm, MetadataForm, RoleField } from 'src/v2/features/metadata';
import { Radio } from 'src/v2/features/reduxForm';
import { usePreventEntityWithTemplateCreation } from 'src/v2/features/sharedEntity/hooks';
import { EntityType } from 'src/models/paper';

import { FormDocumentCreatePayload, DocumentMetaProps } from './types';

interface Props extends DocumentMetaProps {
  isLoading: boolean;
  onSubmit: (data: FormDocumentCreatePayload) => void;
}

const getCreationModes = () => {
  return [
    { value: CreationMode.blank, label: i18n(translationKeys.forms.document.creationModes.blank) },
    {
      value: CreationMode.template,
      label: i18n(translationKeys.forms.document.creationModes.template),
    },
    {
      value: CreationMode.upload,
      label: i18n(translationKeys.forms.document.creationModes.upload),
    },
  ];
};

const Create: FC<Props & InjectedFormProps<FormDocumentCreatePayload, Props>> = ({
  isLoading,
  pristine,
  onSubmit,
  handleSubmit,
}) => {
  const preventSelectDocumentTypeHandler = usePreventEntityWithTemplateCreation();

  const creationModes = useMemo(() => getCreationModes(), []);

  const currentModes = useMemo(
    () =>
      creationModes.map(({ value, label }) => (
        <Field
          key={value}
          component={Radio}
          name="createDocumentOption"
          value={value}
          label={label}
          type="radio"
          onChange={preventSelectDocumentTypeHandler}
          mb8
        />
      )),
    [preventSelectDocumentTypeHandler, creationModes],
  );

  return (
    <MetadataForm
      data-id="metadata-form-create"
      label={i18n(translationKeys.forms.document.create.createNew)}
      infoLabel={i18n(translationKeys.forms.document.create.createNewInfo)}
      type={EntityType.document}
      isLoading={isLoading}
      isPristine={pristine}
      onSubmit={handleSubmit(onSubmit)}
    >
      <RoleField />
      <Divider />
      <div className="c-title c-title--uppercase c-title--lh-1">
        {i18n(translationKeys.forms.document.create.type)}
      </div>

      {currentModes}
    </MetadataForm>
  );
};

const fields = ['role', 'createDocumentOption'];
export const DocumentMetadataCreate = connectForm<Props, FormDocumentCreatePayload>(
  Create,
  fields,
  {
    createDocumentOption: CreationMode.blank,
  },
);
