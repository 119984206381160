// Core
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Box } from '@theme-ui/components';
import { useDispatch, useSelector } from 'react-redux';
import { findIndex } from 'lodash';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { toastr } from 'react-redux-toastr';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import {
  getUploadsListByDocumentIdFactory,
  retryUploadAttachmentFile,
  cancelUploadAttachmentFile,
  uploadAttachmentFile,
} from 'src/v2/features/document/uploadAttachments/uploadAttachmentsStore';
import {
  deleteAttachment,
  fetchAttachments,
  getAttachmentsList,
} from 'src/v2/features/document/attachmentsList/attachmentsListStore';
import { FilesUploader } from 'src/v2/components/FilesUploader';
import { extensionByMimeType } from 'src/v2/components/FileUpload/types';
import { Modal } from 'src/v2/components/Modal';
import { FilePreview } from 'src/v2/components/FilePreview';
import { Attachments } from 'src/v2/components/Attachments';
import { MimeType } from 'src/common/types';
import { useToggle } from 'src/common/hooks/useToggle';
import { useModal } from 'src/utils/hooks';

import { Attachment } from './components/Attachment';

const styles = {
  container: {
    paddingBottom: '30px',
    borderBottom: '1px solid #D6D6D6',
  },
  header: {
    fontSize: '12px',
    fontWeight: 'bold',
    paddingBottom: '12px',
  },
  slideItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '100vh',
    padding: '75px',
    lineHeight: '1.5',
    fontSize: '1.5em',
  },
  filePreview: {
    minWidth: '600px',
    backgroundColor: '#f2f2f2',
    height: '100%',
  },
};

interface Props {
  documentId: string;
  isAllowedToEditAttachments: boolean;
}

const acceptedMimeTypes = [
  MimeType.pdf,
  MimeType.doc,
  MimeType.docx,
  MimeType.png,
  MimeType.jpeg,
  MimeType.xls,
  MimeType.xlsx,
  MimeType.ppt,
  MimeType.pptx,
];

export const DocumentSidebarAttachments: React.FC<Props> = ({
  documentId,
  isAllowedToEditAttachments,
}) => {
  const getUploadsListByDocumentId = getUploadsListByDocumentIdFactory(documentId);
  const uploadingFiles = useSelector(getUploadsListByDocumentId);
  const files = useSelector(getAttachmentsList);
  const [showMore, toggle, setShowMoreActive] = useToggle();

  const dispatch = useDispatch();

  const onDropValid = useCallback(
    (file: File) => dispatch(uploadAttachmentFile({ file, documentId })),
    [dispatch, documentId],
  );
  const [fileId, setFileId] = useState<string | null>(null);
  const idx = findIndex(files, ({ id }) => id === fileId);

  const onRetry = useCallback(
    (fileId: string) => dispatch(retryUploadAttachmentFile(fileId)),
    [dispatch],
  );

  const onCancel = useCallback(
    (fileId: string) => dispatch(cancelUploadAttachmentFile(fileId)),
    [dispatch],
  );

  useEffect(() => {
    dispatch(fetchAttachments(documentId));
  }, [dispatch, documentId]);
  const [shouldModalOpen, openModal, closeModal] = useModal();

  const showCarousel = useCallback(
    (fileId: string) => {
      setFileId(fileId);
      openModal();
    },
    [setFileId, openModal],
  );

  const deleteAttachmentHandler = useCallback(
    (attachmentId: string) => dispatch(deleteAttachment({ documentId, attachmentId })),
    [dispatch, documentId],
  );

  const extensions = useMemo(
    () => acceptedMimeTypes.map((type) => `.${extensionByMimeType[type]}`).join(', '),
    [],
  );

  const onValidationError = () => {
    toastr.error(` ${i18n(translationKeys.errors.FILE_FORMAT_ERROR)}`, '');
  };

  return (
    <Attachments
      list={files.map(({ id, name, url, extension, lastModified }) => (
        <Attachment
          key={id}
          file={{
            id,
            name,
            url,
            extension,
            lastModified,
          }}
          canDelete={isAllowedToEditAttachments}
          onDelete={deleteAttachmentHandler}
          onImgClick={showCarousel}
          deleteCallback={setShowMoreActive}
        />
      ))}
      showMore={showMore}
      toggleShowMore={toggle}
    >
      {isAllowedToEditAttachments && (
        <FilesUploader
          files={uploadingFiles}
          onDropValid={onDropValid}
          onRetry={onRetry}
          onCancel={onCancel}
          accept={acceptedMimeTypes}
          onValidationError={onValidationError}
          multiple
        >
          <span className="c-attachment__message">
            {i18n(translationKeys.forms.upload.dragDocumentToUpload)}
          </span>
          <span className="c-attachment__message">
            {i18n(translationKeys.forms.document.type.acceptedExtension)}:
            <br />
            {extensions}
          </span>
        </FilesUploader>
      )}

      <Modal isOpen={shouldModalOpen()} onRequestClose={closeModal}>
        <Carousel width="80vw" selectedItem={idx}>
          {files.map(({ id, name, url, extension }) => (
            <Box key={id} sx={styles.slideItem}>
              <Box sx={styles.filePreview}>
                <FilePreview url={url} extension={extensionByMimeType[extension]} />
              </Box>
              <p className="legend">{name}</p>
            </Box>
          ))}
        </Carousel>
      </Modal>
    </Attachments>
  );
};
