import React from 'react';

import { useWithHistoryCallbackFactory } from 'src/v2/features/sidebarController';
import { useShowMetadataEdit } from 'src/v2/features/sharedEntity';
import { AbstractView } from 'src/v2/features/contract/ContractSidebar/pages/AbstractView';

interface Props {
  id: string;
}

export const View: React.FC<Props> = ({ id }) => {
  const [, editMetadataCb] = useShowMetadataEdit();
  const editDocumentMetadata = useWithHistoryCallbackFactory(editMetadataCb)({
    id,
  });

  return <AbstractView id={id} onEdit={editDocumentMetadata} />;
};
