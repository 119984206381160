import { keys } from 'lodash';
import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Switch, Case } from 'src/v2/components/SwitchCase';

import { useSubscribeToClickOnRootEffct } from 'src/common/systemEventBus';
import { ContextMenuRegistryType } from 'src/app/types';
import { closeContextMenu } from './store';
import { getContextMenuState, getIsContextMenuOpen } from './selectors';
import { ShouldCloseHandler } from 'src/app/ShouldCloseWrapper';

interface Props {
  registry: ContextMenuRegistryType;
}

export const ContextMenuRoot: FC<Props> = ({ registry }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(getIsContextMenuOpen);

  const onClose = useCallback(() => {
    if (isOpen) dispatch(closeContextMenu());
  }, [dispatch, isOpen]);

  const { payload, position, sourceType } = useSelector(getContextMenuState);

  const cases = useMemo(
    () =>
      keys(registry).map((key) => ({
        key,
        component: registry[key],
      })),
    [registry],
  );

  const closeOnClick = useCallback(
    (action: (() => void) | undefined) => () => {
      if (action) {
        action();
      }
      onClose();
    },
    [onClose],
  );

  useSubscribeToClickOnRootEffct(() => {
    onClose();
  });

  return (
    <ShouldCloseHandler>
      <Switch criteria={sourceType}>
        {cases.map(({ key, component: Menu }) => (
          <Case key={key} value={key}>
            <Menu
              position={position}
              payload={payload}
              onClose={onClose}
              closeOnClick={closeOnClick}
            />
          </Case>
        ))}
      </Switch>
    </ShouldCloseHandler>
  );
};
