import React, { FC } from 'react';

export interface Props {
  title: string;
}

export const SidebarLibrary: FC<Props> = ({ children, title }) => {
  return (
    <div className="c-library">
      <div className="c-library__row">
        <div className="c-title c-title--uppercase">{title}</div>
      </div>
      <div className="c-library__row c-library--mt-8">
        <div className="c-library__list">{children}</div>
      </div>
    </div>
  );
};
