import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNull } from 'lodash';

import { MenuDocuments } from 'src/v2/icons/MenuDocuments';
import { isDocumentFolder } from 'src/v2/features/quantumNavigator';
import {
  useGetEntityCurrentFolderId,
  useGetRootFolderId,
} from 'src/v2/features/sharedEntity/hooks';
import { isSharedFolder } from 'src/v2/features/quantumNavigator/utils';
import { getTargetId, clearTargetId } from 'src/v2/features/folderSelector/store';
import { ListItem } from 'src/models/list';
import { NodeTree } from 'src/models/node';
import { closeModal } from 'src/v2/features/modal/store';
import { FolderSelectorContainer } from 'src/v2/features/folderSelector/FolderSelector/FolderSelectorContainer';
import { createGetObjectIdByEntityAndFolderId } from 'src/v2/features/folderObjects/selectors';
import { moveFolderObject } from 'src/v2/features/folderObjects';
import { useCreateGoToFolderByIdHandler } from 'src/v2/features/folderSelector/hooks';

const validateFn = (model: NodeTree<ListItem>) => {
  return !isSharedFolder(model.data);
};

export const DocumentMoveToModal: FC = () => {
  const documentId = useSelector(getTargetId);
  const currentFolderId = useGetEntityCurrentFolderId(isDocumentFolder);
  const objectId = useSelector(createGetObjectIdByEntityAndFolderId)(
    documentId || '',
    currentFolderId,
  );
  const documentRootFolderId = useGetRootFolderId(isDocumentFolder);
  const dispatch = useDispatch();
  const handleActionButtonClick = useCallback(
    (targetFolderId: string) => {
      if (!isNull(objectId)) {
        dispatch(
          moveFolderObject({
            currentFolderId,
            targetFolderId,
            objectId,
          }),
        );
        dispatch(closeModal());
        dispatch(clearTargetId());
      }
    },
    [dispatch, currentFolderId, objectId],
  );

  const handleDocumentsItemClick = useCreateGoToFolderByIdHandler(documentRootFolderId);

  const menuItems = useMemo(
    () => [
      {
        icon: <MenuDocuments />,
        title: 'Documents',
        onClick: handleDocumentsItemClick,
      },
    ],
    [handleDocumentsItemClick],
  );

  return (
    <FolderSelectorContainer
      heading="Move to Folder"
      actionButtonLabel="Move"
      menuItems={menuItems}
      onActionButtonClick={handleActionButtonClick}
      validateFn={validateFn}
    />
  );
};
