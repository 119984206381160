import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigateToDefault } from 'src/v2/features/sharedEntity';
import { ConfirmSection } from 'src/v2/components/ConfirmSection';
import { removeProject } from 'src/v2/features/project/store/projectReducers';

import { getProjectByIdFn } from '../../store/projectSelectors';

interface Props {
  id: string;
}

export const RemoveProject: React.FC<Props> = ({ id }) => {
  const dispatch = useDispatch();

  const getProjectById = useSelector(getProjectByIdFn);
  const project = getProjectById(id);

  const navigateBack = useNavigateToDefault();
  const handleDelete = useCallback(() => {
    dispatch(removeProject(id));
    navigateBack();
  }, [dispatch, id, navigateBack]);

  const isProjectHasPapers = useMemo(
    () => (project ? project.papers.length > 0 : false),
    [project],
  );

  const projectHasPapersMsg = useMemo(
    () => (isProjectHasPapers ? 'This project has nested papers.' : ''),
    [isProjectHasPapers],
  );

  return (
    <>
      <ConfirmSection
        message={`${projectHasPapersMsg} Delete the project?`}
        onClose={navigateBack}
        onConfirm={handleDelete}
      />
    </>
  );
};
