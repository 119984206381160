import React, { FC, useMemo } from 'react';
import { isNil } from 'lodash';
import cn from 'classnames';

import { formatToLocaleString } from 'src/utils/dates';

import { NotificationTemplateRenderer } from './TemplateRenderer';
import { isNotificationTypeSupported } from '../utils';
import { NotificationEntity } from 'src/v2/entities/notification';

interface Props {
  value: NotificationEntity;
}

export const NotificationListItem: FC<Props> = ({ value }) => {
  const date = formatToLocaleString(value.createdAt);

  const isTemplateDrivenNotification = useMemo(() => {
    if (isNil(value.notification_payload)) {
      return false;
    }

    const { type } = value.target;
    const isSupported = isNotificationTypeSupported(type);

    if (!isSupported) {
      console.error(`Notification: Not supported type "${type}" provided`);
    }

    return isSupported;
  }, [value.notification_payload, value.target]);

  return (
    <div className={cn('c-history__item', { 'c-history--border-blue': !value.viewed })}>
      {isTemplateDrivenNotification ? (
        <NotificationTemplateRenderer
          template={value.template_notification}
          payload={value.notification_payload}
          target={value.target}
        />
      ) : (
        value.notification
      )}
      {' on '}
      {date}
    </div>
  );
};
