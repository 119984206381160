import { Subscription } from 'rxjs';

import { eventBusFactory } from 'src/app/eventBus';

import { UpgradePayload, BillingEvent, BillingEventData, UpgradeEvent } from './types';

const eventBusApi = eventBusFactory<BillingEventData>();

const { emit, subscribeToEvent } = eventBusApi;

export const emitPaymentSuccess = (): void => emit({ type: BillingEvent.PaymentSuccess });
export const subscribePaymentSuccess = (
  callback: (eventData: BillingEventData) => void,
): Subscription => subscribeToEvent(BillingEvent.PaymentSuccess, callback);

export const emitUserNeedsToCreatePlan = (payload: UpgradePayload): void =>
  emit<UpgradeEvent>({ type: BillingEvent.UserNeedsToUpgradePlan, payload });
export const subscribeUserNeedsToCreatePlan = (
  callback: (eventData: UpgradeEvent) => void,
): Subscription => subscribeToEvent(BillingEvent.UserNeedsToUpgradePlan, callback);

export const emitBillingInfoSuccess = (): void => emit({ type: BillingEvent.BillingInfoSuccess });
export const subscribeBillingInfoSuccess = (
  callback: (eventData: BillingEventData) => void,
): Subscription => subscribeToEvent(BillingEvent.BillingInfoSuccess, callback);
