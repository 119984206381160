/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createAction } from '@reduxjs/toolkit';
import { takeLatest, put, select, call } from 'redux-saga/effects';

import { handleErrorSaga } from 'src/utils/handleErrorSaga';

import { fetchWorkflowSliceFactory, fetchWorkflowSagaFactory } from './documentWorkflowSlice';
import { deleteData } from '../objectsStorage/objectsStorageSlice';
import { getWorkflow } from './documentWorkflowSelectorsFactory';

export const fetchWorkflow = createAction<string>('document/fetchWorkflow');
export const fetchWorkflowSlice = fetchWorkflowSliceFactory('fetchWorkflowSlice');
export const fetchWorkflowSaga = fetchWorkflowSagaFactory(fetchWorkflowSlice.actions);

export const clearWorkflow = createAction('document/clearWorkflow');
export function* clearWorkflowSaga() {
  try {
    const workflow = yield select(getWorkflow);
    if (workflow && workflow.id) {
      yield put(deleteData({ id: workflow.id, type: 'workflow' }));
    }
  } catch (error) {
    yield call(handleErrorSaga, error);
  }
}

export function* watchDocumentWorkflowSagas() {
  yield takeLatest(fetchWorkflow, fetchWorkflowSaga);
  yield takeLatest(clearWorkflow, clearWorkflowSaga);
}

export default fetchWorkflowSlice.reducer;
