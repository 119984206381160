/* eslint-disable no-param-reassign,@typescript-eslint/no-use-before-define */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { has, isNull, isUndefined } from 'lodash';

import { ObjectsStorageState } from 'src/app/types';
import { CompleteObject, ObjectBase, ObjectData, ObjectSerialized } from 'src/common/types';

import { ObjectsContainer } from './types';

const objectsInitialState: ObjectsStorageState = {
  objects: {},
};

const mergeObjectData = (data1: ObjectData, data2: ObjectData): ObjectData => {
  return {
    ...(data1 || {}),
    ...(data2 || {}),
    attributes: { ...(data1 && data1.attributes), ...(data2 && data2.attributes) },
    relationships: { ...(data1 && data1.relationships), ...(data2 && data2.relationships) },
  };
};

const putObject = (state: ObjectsStorageState, data: ObjectData) => {
  if (!state.objects[data.type]) {
    state.objects[data.type] = {};
  }

  const storedData = state.objects[data.type][data.id];

  state.objects[data.type][data.id] = mergeObjectData(storedData, data);
};

function putNewObject(state: ObjectsStorageState, data) {
  if (!state.objects[data.type]) {
    state.objects[data.type] = {};
  }

  const storedData = state.objects[data.type];

  storedData[data.id] = data;
}

export const deleteDataReducer = (
  state: ObjectsStorageState,
  { payload }: PayloadAction<{ id: string; type: string }>,
): void => {
  const { id, type } = payload;
  if (!isUndefined(state.objects[type]) && !isUndefined(state.objects[type][id])) {
    delete state.objects[type][id];
  } else {
    console.warn(`Can't find record type: ${type} id: ${id}`);
  }
};

const users = createSlice({
  name: 'objectsStorage',
  initialState: objectsInitialState,
  reducers: {
    storeData(state, { payload }: PayloadAction<ObjectSerialized>): void {
      [...payload.data, ...(payload.included || [])].forEach((itm) => {
        putObject(state, itm);
      });
    },
    storeNewData<T = unknown>(state, { payload }: PayloadAction<T>): void {
      putNewObject(state, payload);
    },
    deleteData: deleteDataReducer,
  },
});

const buildObject = (
  object: ObjectData,
  objects: Record<string, ObjectsContainer>,
): CompleteObject => {
  const res: CompleteObject = {
    id: object.id,
    __type: object.type,
    ...object.attributes,
  };

  if (object.relationships) {
    Object.entries(object.relationships).forEach(([relName, relCntr]) => {
      if (!isNull(relCntr.data)) {
        res[relName] = getObjects(relCntr.data, objects);
      }
    });
  }

  return res;
};

export const getObject = <T = CompleteObject>(
  obj: ObjectBase | null | undefined,
  objects: Record<string, ObjectsContainer>,
): T | null => {
  if (!obj) {
    return null;
  }

  const res = getObjects<T>([obj], objects);

  if (!res || res.length === 0) {
    return null;
  }

  return res[0];
};

export const getObjectById = <T = CompleteObject>(
  id: string | null | undefined,
  objects: Record<string, ObjectsContainer>,
): T | null => {
  const containerKeys = Object.keys(objects);

  if (!id || containerKeys.length !== 1) {
    return null;
  }

  return getObject<T>({ id, type: containerKeys[0] }, objects);
};

export const getObjects = <T = CompleteObject>(
  list: ObjectBase[],
  objects: Record<string, ObjectsContainer>,
): T[] => {
  return list
    .filter((obj) => has(objects, `${obj.type}.${obj.id}`))
    .map((obj) => buildObject(objects[obj.type][obj.id], objects)) as unknown as T[];
};

export const getObjectsMap = (...args: Array<ObjectData[]>) => {
  return ([] as ObjectData[])
    .concat(...args)
    .reduce((resp: Record<string, ObjectsContainer>, itm) => {
      if (!resp[itm.type]) {
        resp[itm.type] = {};
      }

      resp[itm.type][itm.id] = itm;
      return resp;
    }, {});
};

export const { storeData, storeNewData, deleteData } = users.actions;

export default users.reducer;
