import React, { FC, ReactNode } from 'react';

import { useTranslation } from 'src/common/hooks/useTranslation';
import { translationKeys } from 'src/common/translations';
import cn from 'classnames';
import { size } from 'lodash';

interface Props {
  list: ReactNode[];
  showMore: boolean;
  toggleShowMore: () => void;
}

export const Attachments: FC<Props> = ({ list, showMore, toggleShowMore, children }) => {
  const i18n = useTranslation();
  return (
    <div className="c-attachment">
      <div className="c-attachment__row c-attachment__header">
        <div className="c-title c-title--uppercase">
          {i18n(translationKeys.heading.attachment)}:
        </div>
      </div>
      {children}

      <div
        className={cn('c-attachment__list c-attachment--mt-16 ', {
          'c-attachment__list--active': showMore,
        })}
      >
        {list.map((item) => item)}
      </div>
      {size(list) > 0 ? (
        <span className="c-more c-more--active" onClick={toggleShowMore}>
          {showMore
            ? i18n(translationKeys.forms.item.showLess)
            : i18n(translationKeys.forms.item.showMore)}
        </span>
      ) : null}
    </div>
  );
};
