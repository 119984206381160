import { required } from 'redux-form-validators';
import { Field } from 'redux-form';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { Select } from 'src/v2/features/reduxForm';
import { PermissionsFieldset } from 'src/v2/features/entityParticipants/PermissionsFieldset';
import { affiliationOptions } from 'src/v2/features/documentParticipants/forms/constants';
import { SelectOptionType } from 'src/v2/components/Form/withReduxForm';
import { PaperParticipantsAffiliationType } from 'src/v2/boundary/storageModels/paper/paperParticipant';

interface Props {
  isConditionRequiredVisible: boolean;
  isUpdating: boolean;
  roleOptions: SelectOptionType[];
  affiliationType?: PaperParticipantsAffiliationType | null;
}
export const ContractPermissionsFieldset = (props: Props) => {
  const { isUpdating, affiliationType } = props;

  return (
    <PermissionsFieldset
      conditionLabel={i18n(translationKeys.forms.contract.userRole.signatureRequired)}
      isConditionRequiredDisabled={false}
      {...props}
    >
      <Field
        props={{ disabled: isUpdating || affiliationType }}
        component={Select}
        name="affiliationType"
        placeholder={i18n(translationKeys.forms.contract.userRole.chooseAffiliationType)}
        disabled={isUpdating}
        options={affiliationOptions}
        validate={required()}
      />
    </PermissionsFieldset>
  );
};
