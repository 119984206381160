import React, { FC } from 'react';

import { useNavigateToDefault } from 'src/v2/features/sharedEntity';
import { ContractInviteParticipantForm } from 'src/v2/features/contract/participants/ContractInviteParticipantForm';

export interface Props {
  id: string;
}

export const InviteContractParticipant: FC<Props> = ({ id }) => {
  const navigateToDefault = useNavigateToDefault(id);

  return <ContractInviteParticipantForm documentId={id} onClose={navigateToDefault} />;
};
