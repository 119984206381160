import { MutableRefObject, useEffect, useState, useRef, useCallback } from 'react';
import { isNil } from 'lodash';

import { nbspReplacer } from 'src/utils/string';

import { FieldDiff, FieldUpdateType } from '../../../../models/editor';
import { EDITABLE_FIELDS_ATTRIBUTES, EDITABLE_FIELDS_CLASSES } from './constants';
import { editableFieldRenderer, editableFieldsDiffRenderer } from './utils';
import i18n from '../../../../i18n';
import { translationKeys } from '../../../../common/translations';

interface OnFieldChangeProps {
  id: string;
  name: string;
  value: string;
}

export const useEditableFieldsStub = (
  container: MutableRefObject<HTMLDivElement | undefined>,
  mapFieldsWithSameName: boolean,
  content: string,
  isFieldsDisable: boolean,
  isFieldUpdating?: boolean,
  index?: number,
) => {
  const valueOnFocus = useRef('');
  const [onEditableFieldChange, setOnEditableFieldChange] = useState<OnFieldChangeProps | null>(
    null,
  );
  const [onEditableFieldDiffReview, setOnEditableFieldDiffReview] = useState<{
    id: string;
    name: string;
    value: string;
    fieldUpdateType: FieldUpdateType;
  } | null>(null);

  const sameNameFieldsUpdater = useCallback((name: string, isUpdating: boolean, index?: number) => {
    const editorStubContent = Array.from(document.querySelectorAll('.content'));
    const fieldBySection = {};
    editorStubContent.forEach((element, index) => {
      fieldBySection[index] = element.querySelectorAll(
        `.${EDITABLE_FIELDS_CLASSES.EDITABLE_FIELD}[data-id]`,
      );
    });

    const sorterFields: any = [];
    !isNil(index) &&
      Array.from(fieldBySection[index])
        .filter((element: any) => {
          return element.getAttribute('data-name') === name;
        })
        .forEach((element: any) => {
          return sorterFields.push(element);
        });

    sorterFields.forEach((item) => {
      if (item.getAttribute(EDITABLE_FIELDS_ATTRIBUTES.NAME) === name) {
        if (!item.classList.contains(EDITABLE_FIELDS_CLASSES.WIDGET_SELECTED)) {
          changeFieldToUpdatingView(item, isUpdating);
        }
      }
    });
  }, []);

  const setEnabledFieldUpdating = useCallback(
    (field: OnFieldChangeProps, isUpdating: boolean, index?: number) => {
      if (!isNil(container.current)) {
        const fields = container.current.querySelectorAll(
          `.${EDITABLE_FIELDS_CLASSES.EDITABLE_FIELD}`,
        );
        if (!isNil(fields) && fields.length > 0) {
          fields.forEach((item) => {
            if (item.getAttribute(EDITABLE_FIELDS_ATTRIBUTES.ID) === field.id) {
              const fieldTitle = item.querySelector(
                `.${EDITABLE_FIELDS_CLASSES.EDITABLE_FIELD_TITLE}`,
              );
              fieldTitle?.setAttribute(
                EDITABLE_FIELDS_ATTRIBUTES.UPDATING_TEXT,
                i18n(translationKeys.forms.editor.field.update),
              );
              item.setAttribute(
                EDITABLE_FIELDS_ATTRIBUTES.DATA_UPDATING,
                isUpdating ? 'true' : 'false',
              );
            }
            if (mapFieldsWithSameName) {
              sameNameFieldsUpdater(field.name, isUpdating, index);
            }
          });
        }
      }
    },
    [container, mapFieldsWithSameName, sameNameFieldsUpdater],
  );

  const disableFields = useCallback(
    (isDisable: boolean) => {
      if (!isNil(container.current)) {
        const fields = container.current.querySelectorAll(
          `.${EDITABLE_FIELDS_CLASSES.RESTRICTED_EDITING_EXTENSION}`,
        );
        if (!isNil(fields) && fields.length > 0) {
          fields.forEach((item) => {
            const fieldTitle = item.querySelectorAll(
              `.${EDITABLE_FIELDS_CLASSES.EDITABLE_FIELD_TITLE}`,
            );
            fieldTitle.forEach((title) => {
              title.setAttribute(
                EDITABLE_FIELDS_ATTRIBUTES.CONTENT_EDITABLE,
                isDisable ? 'false' : 'true',
              );
            });
          });
        }
      }
    },
    [container],
  );

  useEffect(() => {
    if (isFieldsDisable) {
      disableFields(true);
    } else if (!isNil(isFieldUpdating)) {
      disableFields(isFieldUpdating);
      if (!isNil(onEditableFieldChange)) {
        setEnabledFieldUpdating(onEditableFieldChange, isFieldUpdating, index);
      }
    }
  }, [
    isFieldsDisable,
    isFieldUpdating,
    onEditableFieldChange,
    disableFields,
    setEnabledFieldUpdating,
    index,
  ]);

  const changeFieldToUpdatingView = (item: Element, isUpdating: boolean) => {
    const fieldTitle = item.querySelector(`.${EDITABLE_FIELDS_CLASSES.EDITABLE_FIELD_TITLE}`);
    fieldTitle?.setAttribute(
      EDITABLE_FIELDS_ATTRIBUTES.UPDATING_TEXT,
      i18n(translationKeys.forms.editor.field.update),
    );
    item.setAttribute(EDITABLE_FIELDS_ATTRIBUTES.DATA_UPDATING, isUpdating ? 'true' : 'false');
  };

  const diffReviewReplacer = (name: string, id: string, value: string): HTMLSpanElement => {
    return editableFieldRenderer(name, id, value);
  };

  const handleReview = (
    name: string,
    id: string,
    value: string,
    element: Element,
    fieldUpdateType: FieldUpdateType,
  ) => {
    const clearField = diffReviewReplacer(name, id, value);
    element.replaceWith(clearField);
    setOnEditableFieldDiffReview({ id, name, value, fieldUpdateType });
  };

  const diffBulkEditableFields = (diffs: FieldDiff[], canReview: boolean) => {
    if (!isNil(container.current)) {
      const fields = container.current.querySelectorAll(
        `.${EDITABLE_FIELDS_CLASSES.EDITABLE_FIELD}`,
      );
      const workingFields = Array.from(fields).filter((field) =>
        diffs.some((f) => f.editorFieldId === field.getAttribute(EDITABLE_FIELDS_ATTRIBUTES.ID)),
      );

      diffs.forEach(({ newValue, name, editorFieldId }) => {
        workingFields.forEach((element) => {
          const condition = mapFieldsWithSameName
            ? element.getAttribute(EDITABLE_FIELDS_ATTRIBUTES.NAME) === name &&
              !isNil(container.current)
            : element.getAttribute(EDITABLE_FIELDS_ATTRIBUTES.ID) === editorFieldId &&
              !isNil(container.current);
          if (condition) {
            const id = element.getAttribute(EDITABLE_FIELDS_ATTRIBUTES.ID);
            const fieldName = element.getAttribute(EDITABLE_FIELDS_ATTRIBUTES.NAME);
            const oldValue = element.querySelector(
              `.${EDITABLE_FIELDS_CLASSES.EDITABLE_FIELD_TITLE}`,
            )?.innerHTML;

            //TODO move functions higher
            const acceptClick = () => {
              if (!isNil(id) && !isNil(fieldName)) {
                handleReview(name, id, nbspReplacer(newValue), element, FieldUpdateType.Accept);
              }
            };

            const rejectClick = () => {
              if (!isNil(id) && !isNil(fieldName) && !isNil(oldValue)) {
                handleReview(name, id, nbspReplacer(oldValue), element, FieldUpdateType.Reject);
              }
            };

            editableFieldsDiffRenderer(
              element,
              oldValue ? nbspReplacer(oldValue) : '',
              nbspReplacer(newValue),
              canReview,
              acceptClick,
              rejectClick,
            );
          }
        });
      });
    }
  };

  useEffect(() => {
    let fields: HTMLDivElement[] = [];

    const onFocus = (event) => {
      valueOnFocus.current = event.target.innerText;
    };

    const onBlur = (event) => {
      const editableField = event.target.offsetParent;
      const id = editableField.getAttribute(EDITABLE_FIELDS_ATTRIBUTES.ID);
      const name = editableField.getAttribute(EDITABLE_FIELDS_ATTRIBUTES.NAME);

      if (valueOnFocus.current !== event.target.innerText) {
        setOnEditableFieldChange({ id: id, name, value: event.target.innerText });
      }
    };

    if (!isNil(container.current)) {
      fields = Array.from(
        container.current.querySelectorAll(
          `.${EDITABLE_FIELDS_CLASSES.RESTRICTED_EDITING_EXTENSION}`,
        ),
      );
      fields.forEach((item) => {
        const fieldTitle = item.querySelectorAll(
          `.${EDITABLE_FIELDS_CLASSES.EDITABLE_FIELD_TITLE}`,
        );
        fieldTitle.forEach((title) => {
          title.addEventListener('focus', onFocus);
          title.addEventListener('blur', onBlur);
        });
      });
    }
    return () => {
      fields.forEach((item) => {
        const fieldTitle = item.querySelectorAll(
          `.${EDITABLE_FIELDS_CLASSES.EDITABLE_FIELD_TITLE}`,
        );
        fieldTitle.forEach((title) => {
          title.removeEventListener('focus', onFocus);
          title.removeEventListener('blur', onBlur);
        });
      });
    };
  }, [container, content]);

  return { onEditableFieldChange, onEditableFieldDiffReview, diffBulkEditableFields };
};
