import React from 'react';

import { useCreateEditContractMetadataView } from 'src/v2/features/project/ProjectSidebar/nav-hooks';
import { AbstractView } from 'src/v2/features/contract/ContractSidebar/pages/AbstractView';

interface Props {
  id: string;
}

export const ViewContract: React.FC<Props> = ({ id }) => {
  const handleEdit = useCreateEditContractMetadataView();

  return <AbstractView id={id} onEdit={handleEdit} />;
};
