import { FC } from 'react';

import { ModalType } from 'src/v2/features/modal/types';
import { ContractMoveToModal } from 'src/v2/features/contract/modals/ContractMoveToModal';
import { ContractCopyToModal } from 'src/v2/features/contract/modals/ContractCopyToModal';
import { DocumentCopyToModal } from 'src/v2/features/document/modals/DocumentCopyToModal';
import { DocumentMoveToModal } from 'src/v2/features/document/modals/DocumentMoveToModal';
import { DocumentMoveFolderToModal } from 'src/v2/features/document/modals/DocumentMoveFolderToModal';
import { TemplateMoveToModal } from 'src/v2/features/template/modals/TemplateMoveToModal';
import { TemplateCopyToModal } from 'src/v2/features/template/modals/TemplateCopyToModal';
import { TemplateMoveFolderToModal } from 'src/v2/features/template/TemplateMoveFolderToModal';
import { ContractMoveFolderToModal } from 'src/v2/features/contract/modals/ContractMoveFolderToModal';

import { AccountRecoveryStepOneModal } from '../accountRecovery/AccountRecoveryStepOne';
import { AccountRecoveryStepTwoModal } from '../accountRecovery/AccountRecoveryStepTwo';
import { AccountRecoveryStepThreeModal } from '../accountRecovery/AccountRecoveryStepThree';
import { AccountRecoveryMessage } from '../accountRecovery/AccountRecoveryMessage';

export const componentsMap: Record<ModalType, FC> = {
  [ModalType.ContractMoveTo]: ContractMoveToModal,
  [ModalType.ContractCopyTo]: ContractCopyToModal,
  [ModalType.ContractMoveFolderTo]: ContractMoveFolderToModal,
  [ModalType.DocumentCopyTo]: DocumentCopyToModal,
  [ModalType.DocumentMoveTo]: DocumentMoveToModal,
  [ModalType.DocumentMoveFolderTo]: DocumentMoveFolderToModal,
  [ModalType.TemplateMoveTo]: TemplateMoveToModal,
  [ModalType.TemplateCopyTo]: TemplateCopyToModal,
  [ModalType.TemplateMoveFolderTo]: TemplateMoveFolderToModal,
  [ModalType.AccountRecoveryStepOne]: AccountRecoveryStepOneModal,
  [ModalType.AccountRecoveryStepTwo]: AccountRecoveryStepTwoModal,
  [ModalType.AccountRecoveryStepThree]: AccountRecoveryStepThreeModal,
  [ModalType.AccountRecoveryMessage]: AccountRecoveryMessage,
};
