import React, { useCallback } from 'react';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { isNull, noop } from 'lodash';
import { useGetTemplateById } from 'src/v2/features/template/hooks';
import { convertTemplateParticipantsToParticipantModel } from 'src/v2/features/template/utils';
import { useNavigateToConfirmRemoveParticipant } from 'src/v2/features/sharedEntity/EntitySidebar/hooks';
import { ParticipantControls, ParticipantList } from 'src/v2/features/entityParticipants';
import { SidebarParticipantEntity } from 'src/v2/entities/participants';
import { TemplateRole } from 'src/models/template';
import { canDeleteTemplateParticipant } from 'src/v2/features/template/permissions';
import { getShownRole } from 'src/v2/features/template/utils';

export interface Props {
  entityId: string;
}

export const ParticipantsSection: React.FC<Props> = ({ entityId }) => {
  const template = useGetTemplateById(entityId);
  const participants = convertTemplateParticipantsToParticipantModel(
    template ? template.participants : [],
  );

  const navigateToConfirmRemoveParticipant = useNavigateToConfirmRemoveParticipant(entityId);

  const onRemove = useCallback(
    (participant: SidebarParticipantEntity<TemplateRole>) => {
      if (isNull(template)) return undefined;

      if (
        canDeleteTemplateParticipant(template.currentUserParticipant.roleId, participant.roleId)
      ) {
        return navigateToConfirmRemoveParticipant({ participantId: participant.id });
      }

      return undefined;
    },
    [navigateToConfirmRemoveParticipant, template],
  );

  if (isNull(template)) return null;

  return (
    <>
      <ParticipantList
        label={i18n(translationKeys.forms.item.users)}
        list={participants}
        onClick={noop}
        canStartChat={() => false}
        controls={(participant: SidebarParticipantEntity<TemplateRole>) => {
          return <ParticipantControls onRemove={onRemove(participant)} />;
        }}
        getShownRole={getShownRole}
      />
    </>
  );
};
