import { FC, CSSProperties, useMemo } from 'react';

import { UploadedEntityExtension } from 'src/v2/features/sharedEntity/types';
import { decodeContentToDomainUrl } from 'src/utils/url';

const styles: { [key: string]: CSSProperties } = {
  element: {
    height: '100%',
    width: '100%',
  },

  img: {
    width: '100%',
    objectFit: 'contain',
  },
};

interface Props {
  url: string;
  shortUrl?: string;
  extension: UploadedEntityExtension;
  style?: CSSProperties;
  fileFullyLoaded?: () => void;
}

export const useIsOfficeDocument = (extension: UploadedEntityExtension): boolean =>
  extension === UploadedEntityExtension.doc || extension === UploadedEntityExtension.docx;

export const useIsExcelDocument = (extension: UploadedEntityExtension): boolean =>
  extension === UploadedEntityExtension.xls || extension === UploadedEntityExtension.xlsx;

export const useIsPowerPointDocument = (extension: UploadedEntityExtension): boolean =>
  extension === UploadedEntityExtension.ppt || extension === UploadedEntityExtension.pptx;

export const useIsImage = (extension: UploadedEntityExtension): boolean =>
  useMemo(
    () =>
      extension === UploadedEntityExtension.jpeg ||
      extension === UploadedEntityExtension.png ||
      extension === UploadedEntityExtension.jpg,
    [extension],
  );

export const useIsPdf = (extension: UploadedEntityExtension): boolean =>
  extension === UploadedEntityExtension.pdf;

export const FilePreview: FC<Props> = ({
  url,
  shortUrl,
  extension,
  style = {},
  fileFullyLoaded = () => {},
}) => {
  const isImage = useIsImage(extension);
  const isPdf = useIsPdf(extension);
  const isExcelDocument = useIsExcelDocument(extension);
  const isOfficeDocument = useIsOfficeDocument(extension);
  const isPowerPointDocument = useIsPowerPointDocument(extension);
  return (
    <>
      {(isOfficeDocument || isExcelDocument || isPowerPointDocument) && (
        <iframe
          style={{ ...styles.element, ...style }}
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${shortUrl}`}
          width="100%"
          scrolling="yes"
          frameBorder="0"
          title={`filePreview${shortUrl}`}
          onLoad={fileFullyLoaded}
        />
      )}

      {isImage && (
        <img
          onLoad={fileFullyLoaded}
          src={decodeContentToDomainUrl(url)}
          style={{ ...styles.img, ...style }}
          alt="Preview"
        />
      )}

      {isPdf && (
        <embed onLoad={fileFullyLoaded} src={url} style={{ ...styles.element, ...style }} />
      )}
    </>
  );
};
