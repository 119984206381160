import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { SignatureActionContext } from '../contexts/SignatureActionContext';
import { getIsEditing, getIsDisabled, getIsLoading } from '../store';
import { useSubmitSignatureHandler } from '../hooks';

export const SignatureActionContextProvider: FC = ({ children }) => {
  const isEditing = useSelector(getIsEditing);
  const isDisabled = useSelector(getIsDisabled);
  const isLoading = useSelector(getIsLoading);
  const onSubmit = useSubmitSignatureHandler();

  const ctx = {
    isEditing,
    isDisabled,
    isLoading,
    onSubmit,
  };

  return <SignatureActionContext.Provider value={ctx}>{children}</SignatureActionContext.Provider>;
};
