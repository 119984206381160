import { SagaIterator } from 'redux-saga';
import { apiClient } from 'src/common/client';

import { AvatarPayload } from 'src/v2/features/organization/types';
import { ObjectSerialized } from 'src/common/types';
import { SetOrganizationData } from 'src/v2/features/organization/store/types';
import {
  RemoveUserDTO,
  InviteUserToOrganizationDTO,
} from 'src/v2/boundary/requestDTO/organization';

export function setOrganizationDataApi(organization: SetOrganizationData) {
  const data = {
    attributes: {
      ...organization,
    },
  };

  return apiClient.put(`/organization-v2`, { data });
}

export function setOrganizationLogoData({
  file,
}: AvatarPayload): SagaIterator<ObjectSerialized | null> {
  const data = new FormData();
  data.append('file', file);

  return apiClient.post(`/logo/organization`, data);
}

export function inviteUserToOrganization(
  invite: InviteUserToOrganizationDTO,
): SagaIterator<ObjectSerialized | null> {
  const data = {
    attributes: { ...invite },
  };

  return apiClient.put(`/organization-v2/invite`, { data });
}

export function updateInviteUserInOrganization(
  invite: InviteUserToOrganizationDTO,
): SagaIterator<ObjectSerialized | null> {
  const data = {
    attributes: { ...invite },
  };

  return apiClient.put(`/organization-v2/member`, { data });
}

export function removeInviteUserFromOrganization({
  userId,
}: RemoveUserDTO): SagaIterator<ObjectSerialized | null> {
  return apiClient.delete(`/organization-v2/member/${userId}`);
}

export function getOrganizationV2(): SagaIterator<ObjectSerialized> | null {
  return apiClient.get(`/organization-v2/mine`);
}
