import React, { FC } from 'react';
import cn from 'classnames';
import { Field, Validator, WrappedFieldProps } from 'redux-form';

import { WithGroupedValidationMessage } from 'src/v2/components/Form/WithValidationMessage';
import { Radio } from 'src/v2/components/Form/Radio';
import { WithValidationProps } from 'src/v2/components/Form/types';
import { withReduxForm } from './withReduxForm';

export interface RadioSetProps extends Omit<WithValidationProps, 'classPrefix'>, WrappedFieldProps {
  className?: string;
  options: { value: string; label: string; disabled?: boolean }[];
  validate?: Validator | Validator[];
}

interface RadioSetFieldProps {
  name: string;
  className?: string;
  options: { value: string; label: string; disabled?: boolean }[];
  validate?: Validator | Validator[];
}

export const RadioSet: FC<RadioSetProps> = ({
  errorMessage,
  successMessage,
  options,
  name,
  className,
  validate,
}): JSX.Element => (
  <WithGroupedValidationMessage
    className={cn(className, 'c-radio__set')}
    errorMessage={errorMessage}
    successMessage={successMessage}
    classPrefix="c-radio"
  >
    {options.map(({ label, value, disabled, ...rest }) => (
      <Radio
        key={`${label}-${name}`}
        label={label}
        name={name}
        value={value}
        disabled={disabled}
        component={
          <Field
            component="input"
            name={name}
            type="radio"
            value={value}
            hidden
            validate={validate}
          />
        }
        {...rest}
      />
    ))}
  </WithGroupedValidationMessage>
);

const RadioSetWithRedux = withReduxForm<RadioSetProps>(RadioSet);

export const RadioSetField: FC<RadioSetFieldProps> = ({ name, options, validate }) => (
  <Field
    component={RadioSetWithRedux}
    name={name}
    type="radio"
    options={options}
    validate={validate}
    props={{ validate }}
  />
);
