import React from 'react';
import { isUndefined } from 'lodash';
import cn from 'classnames';
import { WithValidationProps } from '../types';
import { ValidationMessage } from './ValidationMessage';

const classModifiers = {
  validation: '--validation',
};

export const WithValidationMessage = ({
  errorMessage,
  successMessage,
  classPrefix,
  className,
  children,
}: WithValidationProps): JSX.Element => {
  return (
    <div className={className}>
      <div
        className={cn({
          [`${classPrefix}${classModifiers.validation}`]:
            !isUndefined(errorMessage) || !isUndefined(successMessage),
        })}
      >
        <ValidationMessage
          classPrefix={classPrefix}
          errorMessage={errorMessage}
          successMessage={successMessage}
        />

        {children}
      </div>
    </div>
  );
};
