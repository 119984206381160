import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAvatarFromProfile, getAvatarUrl } from 'src/v2/features/profile';

import { SignaturePreviewContext } from '../contexts/SignaturePreviewContext';
import {
  setSignedBy as setSignedByAction,
  setCompanyName as setCompanyNameAction,
  setCompanyTitle as setCompanyTitleAction,
  getSignedBy,
  getCompanyName,
  getCompanyTitle,
  getSignaturePreview,
  getUploadedImageUrl,
  getSignatureHash,
} from '../store';

export const SignaturePreviewContextProvider: FC = ({ children }) => {
  const dispatch = useDispatch();
  const avatar = useSelector(getAvatarFromProfile);
  const avatarUrl = getAvatarUrl(avatar);
  const signedBy = useSelector(getSignedBy);
  const companyName = useSelector(getCompanyName);
  const companyTitle = useSelector(getCompanyTitle);
  const signaturePreviewUrl = useSelector(getSignaturePreview);
  const uploadedImageUrl = useSelector(getUploadedImageUrl);
  const hash = useSelector(getSignatureHash);

  const setSignedBy = useCallback(
    (value: string) => dispatch(setSignedByAction(value)),
    [dispatch],
  );
  const setCompanyName = useCallback(
    (value: string) => dispatch(setCompanyNameAction(value)),
    [dispatch],
  );
  const setCompanyTitle = useCallback(
    (value: string) => dispatch(setCompanyTitleAction(value)),
    [dispatch],
  );
  const ctx = {
    avatarUrl,
    signaturePreviewUrl,
    uploadedImageUrl,
    meta: {
      hash,
      signedBy,
      setSignedBy,
      companyName,
      setCompanyName,
      companyTitle,
      setCompanyTitle,
    },
  };
  return (
    <SignaturePreviewContext.Provider value={ctx}>{children}</SignaturePreviewContext.Provider>
  );
};
