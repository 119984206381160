import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import i18n from 'src/i18n';
import { translationKeys as tk } from 'src/common/translations';

import { useSubscribeToLanguageChangeEffct } from 'src/v2/features/profile';
import { getToken } from 'src/shared/auth';
import { fetchOrganization } from 'src/shared/organization/actions';
import { checkSignUpComplete } from 'src/v2/features/signUp';
import { setWebsocketsToken } from 'src/api/socket/connection';
import { getIsNetworkOnline, setNetworkOnline } from 'src/v2/features/network/store';

export const useGlobalEffct = () => {
  const dispatch = useDispatch();
  const authToken = useSelector(getToken);

  useSubscribeToLanguageChangeEffct();

  useEffect(() => {
    if (authToken) {
      dispatch(fetchOrganization());
      dispatch(checkSignUpComplete());
    }
  }, [dispatch, authToken]);

  const isNetworkOnlineSet = useSelector(getIsNetworkOnline);

  useEffect(() => {
    const handleNetworkIsOnline = () => {
      if (!isNetworkOnlineSet) {
        toastr.clean();
        toastr.success(i18n(tk.networkNotifications.title), i18n(tk.networkNotifications.online));
        dispatch(setNetworkOnline(true));
      }
    };

    const handleNetworkIsOffine = () => {
      setTimeout(() => {
        if (isNetworkOnlineSet) {
          dispatch(setNetworkOnline(false));
          toastr.error(i18n(tk.networkNotifications.title), i18n(tk.networkNotifications.offline));
        }
      }, 10000);
    };

    window.addEventListener('online', handleNetworkIsOnline);
    window.addEventListener('offline', handleNetworkIsOffine);

    return () => {
      window.removeEventListener('online', handleNetworkIsOnline);
      window.removeEventListener('offline', handleNetworkIsOffine);
    };
  }, [authToken, dispatch, isNetworkOnlineSet]);

  useEffect(() => {
    if (authToken) setWebsocketsToken(authToken);
  }, [authToken]);
};
