import { FC, useState, useCallback, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { book } from 'src/app/book';
import { Button, Type } from 'src/v2/features/button';
import { SignatureWithStampModel } from 'src/models/signature';
import { decodeContentToDomainUrl } from 'src/utils/url';

interface Props {
  signatures: SignatureWithStampModel[];
  defaultSignatureId: string | null;
  fullName: string;
  onConfirm: (id: string) => void;
  onCancel: () => void;
}

export const SelectSignature: FC<Props> = ({
  signatures,
  defaultSignatureId,
  fullName,
  onConfirm,
  onCancel,
}) => {
  const [current, setCurrent] = useState(defaultSignatureId || '');

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setCurrent(event.target.value);
  }, []);

  const handleConfirm = useCallback(() => {
    onConfirm(current);
  }, [current, onConfirm]);

  return (
    <div className="c-signature__type">
      <div className="l-row">
        <div className="c-title c-title--color-theme c-title--fs-24">
          {i18n(translationKeys.forms.signature.useExistingSignature)}
        </div>
      </div>
      {signatures.map((item) => (
        <div className="l-row l-row--mt-16" key={item.signature_id}>
          <div className="c-checkbox c-checkbox--signature">
            <label className="c-checkbox__label">
              <input
                type="checkbox"
                name="current"
                value={item.signature_id}
                checked={current === item.signature_id}
                onChange={handleChange}
                hidden
              />
              <span className="c-checkbox__icon" />
              {item.signature_file_link ? (
                <span className="c-checkbox__img">
                  <img
                    src={decodeContentToDomainUrl(item.signature_file_link)}
                    alt={i18n(translationKeys.forms.signature.signatureLink)}
                  />
                </span>
              ) : (
                <span className="c-checkbox__title">{`/${item.legal_name || fullName}/`}</span>
              )}
              {item.default_stamp && (
                <span className="c-checkbox__img h-ml-8">
                  <img
                    src={decodeContentToDomainUrl(item.default_stamp.stamp_file_link)}
                    alt={i18n(translationKeys.forms.signature.stampLink)}
                  />
                </span>
              )}
            </label>
          </div>
        </div>
      ))}
      <div className="l-row l-row--mt-16">
        <Link className="c-letter__link" to={book.profile.createNewSignature}>
          {i18n(translationKeys.forms.signature.createNewSignature)}
        </Link>
      </div>
      <div className="l-row l-row--mt-16">
        <Button actionType={Type.Secondary} disabled={!current} onClick={handleConfirm} isInline>
          {i18n(translationKeys.buttons.yes)}
        </Button>
        <Button actionType={Type.Danger} onClick={onCancel} isInline>
          {i18n(translationKeys.buttons.no)}
        </Button>
      </div>
    </div>
  );
};
