import React, { FC } from 'react';
import { isNull } from 'lodash';

import {
  useGetCurrentFolderId,
  useGetFoldersTreeById,
} from 'src/v2/features/quantumNavigator/hooks';
import { isSharedDocumentFolder } from 'src/v2/features/quantumNavigator/utils';

import { DocumentSharedListPage } from './DocumentSharedListPage';
import { DocumentStandardListPage } from './DocumentStandardListPage';

export const DocumentListPage: FC = () => {
  const folderId = useGetCurrentFolderId();
  const current = useGetFoldersTreeById(folderId);
  const isSharedFolder = isNull(current) ? false : isSharedDocumentFolder(current.data);

  return isSharedFolder ? <DocumentSharedListPage /> : <DocumentStandardListPage />;
};
