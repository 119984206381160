import React, { FC } from 'react';

import { DocumentListPageWithoutContext } from './DocumentListPageWithoutContext';
import { DynamicSettingsContextProviderForList } from './providers/DynamicSettingsContextProviderForList';
import { SidebarNodeContextProvider } from './providers/SidebarNodeContextProvider';

export const DocumentStandardListPage: FC = () => {
  return (
    <SidebarNodeContextProvider>
      <DynamicSettingsContextProviderForList>
        <DocumentListPageWithoutContext />
      </DynamicSettingsContextProviderForList>
    </SidebarNodeContextProvider>
  );
};
