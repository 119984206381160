import { Link } from '@theme-ui/components';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { isUndefined } from 'lodash';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { SystemRole } from 'src/models/user';
import { useAuthAndFillOrganization } from 'src/v2/features/profile/hooks';
import { UserState } from 'src/v2/features/organization/types';
import { RootState } from 'src/app/types';
import { canAddOrganizationUser } from 'src/utils/permissions';
import { ProfilePage } from 'src/v2/features/profile';
import { useShowUserCreate } from 'src/v2/features/profile/profileSidebar';
import {
  getCurrentOrganizationMembers,
  getCurrentOrganizationUserSystemRole,
  getIsLoading,
} from 'src/shared/organization';
import { getIsLoading as getIsBillingLoading } from 'src/v2/features/billing/store';
import { useToggle } from 'src/common/hooks/useToggle';
import { getSeats } from 'src/v2/features/billing/store/billingSelectors';
import { AddUsersColor } from 'src/v2/icons/AddUsersColor';
import { useShouldShowWarningModal } from 'src/v2/features/billing/hooks';

import { User } from './User';
import { styles } from './styles';
import { WarningModal } from './WarningModal';
import { SeatsInformation } from './SeatsInformation';

export type FormValues = UserState;

export const Form: React.FC<InjectedFormProps<FormValues[]>> = ({ initialValues }) => {
  const userSystemRole = useSelector(getCurrentOrganizationUserSystemRole);
  const isAbleToAddOrgUser = canAddOrganizationUser(userSystemRole, SystemRole.User);
  const isOrganizationLoading = useSelector(getIsLoading);
  const isBillingLoading = useSelector(getIsBillingLoading);
  const users = initialValues as UserState[];
  useAuthAndFillOrganization();

  const [linkToCreateUser] = useShowUserCreate();
  const { pathname } = useLocation();
  const [showModal, setShowModal] = useToggle();
  const navigate = useNavigate();
  const numberOfSeats = useSelector(getSeats);
  const shouldShowWarningModal = useShouldShowWarningModal(users, numberOfSeats);

  const linkToAddUser = !shouldShowWarningModal ? linkToCreateUser : pathname;

  const addUser = () => shouldShowWarningModal && setShowModal();

  const continueAddUser = () => {
    navigate(linkToCreateUser);
    setShowModal();
  };

  const isLoading = isBillingLoading || isOrganizationLoading;

  return (
    <ProfilePage>
      {showModal && (
        <WarningModal
          continueAddUser={continueAddUser}
          onClose={setShowModal}
          numberOfSeats={numberOfSeats}
        />
      )}
      {!isUndefined(users) && !isLoading && (
        <SeatsInformation numberOfMembers={users.length} numberOfTotalSeats={numberOfSeats} />
      )}
      <div className="l-row l-row--mt-16">
        <div className="c-manage">
          <table className="c-manage__table">
            <thead className="c-manage__thead">
              <tr className="c-manage__tr">
                <td className="c-manage__td" colSpan={8}>
                  <div className="c-manage__add" onClick={addUser}>
                    {isAbleToAddOrgUser && (
                      <Link
                        data-type="addUsers-link"
                        variant="inline"
                        as={RouterLink}
                        to={linkToAddUser}
                        sx={styles.addUserLink}
                      >
                        <div className="c-manage__add--icon">
                          <AddUsersColor />
                        </div>
                        <div className="c-manage__add--text">
                          <div className="c-manage__add--plus"></div>
                          {i18n(translationKeys.organization.addUsers)}
                        </div>
                      </Link>
                    )}
                  </div>
                </td>
              </tr>
              <tr className="c-manage__tr">
                <td className="c-manage__td"></td>
                <td className="c-manage__td">
                  {i18n(translationKeys.organization.tableHeaders.name)}
                </td>
                <td className="c-manage__td">
                  {i18n(translationKeys.organization.tableHeaders.position)}
                </td>
                <td className="c-manage__td">
                  {i18n(translationKeys.organization.tableHeaders.role)}
                </td>
                <td className="c-manage__td">
                  {i18n(translationKeys.organization.tableHeaders.email)}
                </td>
                <td className="c-manage__td">
                  {i18n(translationKeys.organization.tableHeaders.phoneNumber)}
                </td>
                <td className="c-manage__td">
                  {i18n(translationKeys.organization.tableHeaders.status)}
                </td>
                <td className="c-manage__td"></td>
              </tr>
            </thead>
            <tbody className="c-manage__tbody">
              {users && users.map((user: any) => <User key={user.id} {...user} />)}
            </tbody>
          </table>
        </div>
      </div>
    </ProfilePage>
  );
};

const ManageUsersReduxForm = reduxForm<FormValues[]>({
  form: 'organization',
  enableReinitialize: true,
  pure: false,
})(Form);

export const ManageOrgUsers = connect((state) => ({
  initialValues: getCurrentOrganizationMembers(state as RootState) as unknown as FormValues[],
  isLoading: getIsLoading(state as RootState),
}))(ManageUsersReduxForm);
