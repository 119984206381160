import React, { PropsWithChildren } from 'react';
import cn from 'classnames';
import { omit } from 'lodash';
import { Link as RouterLink, useMatch } from 'react-router-dom';

interface Props {
  to: string;
  linkToMatchActive?: string;
  activeClassName?: string;
  external?: boolean;
  dataType?: string;
}

export const Link: React.FC<PropsWithChildren<Props> & React.AnchorHTMLAttributes<{}>> = ({
  children,
  to,
  linkToMatchActive = '',
  activeClassName,
  external = false,
  className,
  ...props
}) => {
  const match = useMatch({
    path: to,
  });

  const extraMatch = useMatch({
    path: linkToMatchActive,
  });

  const isActive = match || extraMatch;
  const computedClassName = cn(className, isActive && activeClassName);
  const finalProps = external
    ? {
        ...omit(props, 'dataType'),
        'data-type': props.dataType,
      }
    : props;

  return external ? (
    <a href={to} className={computedClassName} {...finalProps}>
      {children}
    </a>
  ) : (
    <RouterLink to={to} className={computedClassName} {...finalProps}>
      {children}
    </RouterLink>
  );
};
