import { FC } from 'react';

import { SharedSidebarContext } from 'src/v2/features/sharedEntity/EntitySidebar/SharedSidebarContext';
import {
  useNavigateToEditParticipant,
  useNavigateToInviteParticipant,
  useNavigateToQRCode,
} from 'src/v2/features/contract/ContractSidebar/nav-hooks';
import { SharedSidebarContextProps } from 'src/v2/features/sharedEntity/EntitySidebar/types';
import { useGetEntityById } from 'src/v2/features/sharedEntity';
import { ContractRole, ContractType } from 'src/models/contract';
import {
  useCanChatInContract,
  useCanManageParticipantInContract,
  useCanEditAccessInContract,
} from 'src/v2/features/contract/permissions/contractPermissions/contractPermissionsHooks';
import { getShownRole } from 'src/v2/features/contract/participants/utils';
import { castType } from 'src/utils/types';

export const SharedContractSidebarContextProvider: FC<SharedSidebarContextProps> = ({
  id,
  children,
}) => {
  const contract = useGetEntityById<ContractType, ContractRole>(id);
  const navigateToInviteParticipant = useNavigateToInviteParticipant(id);
  const navigateToQRCode = useNavigateToQRCode(id);

  const navigateToEditParticipant = useNavigateToEditParticipant(id);
  const isAllowedToManageParticipants = useCanManageParticipantInContract(contract);
  const isAllowedToChat = useCanChatInContract(contract);
  const getShownParticipantRole = castType<ContractRole>(getShownRole);
  const isAllowedToEditAccess = useCanEditAccessInContract(contract);

  return (
    <SharedSidebarContext.Provider
      value={{
        isAllowedToManageParticipants,
        isAllowedToChat,
        isAllowedToEditAccess,
        navigateToInviteParticipant,
        navigateToQRCode,
        navigateToEditParticipant,
        getShownParticipantRole,
      }}
    >
      {children}
    </SharedSidebarContext.Provider>
  );
};
