import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { isUndefined } from 'lodash';

import { ShareForm } from 'src/v2/features/documentSidebar/documentShareForm/ShareForm';
import { getUserId } from 'src/shared/auth';
import { useGetFullParticipantsByDocumentId } from 'src/v2/features/entityParticipants/hooks';

import { SidebarParticipantEntity } from 'src/v2/entities/participants';
import { useNavigateToDefault } from '../hooks';

interface Props {
  id?: string;
}

export const ShareEntityForm: FC<Props> = ({ id: entityId }) => {
  const currentUserId = useSelector(getUserId);
  const participants: SidebarParticipantEntity[] = useGetFullParticipantsByDocumentId(entityId);
  const navigateToDefault = useNavigateToDefault(entityId);
  const currentUserParticipant = participants.find(
    (participant) => participant.userId === currentUserId,
  );

  return (
    <>
      {!isUndefined(currentUserParticipant) && (
        <ShareForm
          entityId={entityId}
          participants={participants}
          onClose={navigateToDefault}
          myParticipant={currentUserParticipant}
        />
      )}
    </>
  );
};
