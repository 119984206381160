import { isNil } from 'lodash';
import i18n from 'src/i18n';

import { translationKeys } from '../../../../common/translations';
import { CHECKBOX_ATTRIBUTES, CHECKBOX_CLASSES } from './constants';

const buttonsRenderer = (acceptClick: () => void, rejectClick: () => void): HTMLSpanElement => {
  const buttonsContainer = document.createElement('span');
  buttonsContainer.classList.add(CHECKBOX_CLASSES.CHECKBOX_BUTTONS);

  const acceptButton = document.createElement('button');
  acceptButton.classList.add(CHECKBOX_CLASSES.BUTTON);
  acceptButton.classList.add(CHECKBOX_CLASSES.BUTTON_ACCEPT);
  acceptButton.innerText = i18n(translationKeys.forms.editor.field.accept);
  acceptButton.addEventListener('click', acceptClick);

  const rejectButton = document.createElement('button');
  rejectButton.classList.add(CHECKBOX_CLASSES.BUTTON);
  rejectButton.classList.add(CHECKBOX_CLASSES.BUTTON_REJECT);
  rejectButton.innerText = i18n(translationKeys.forms.editor.field.reject);
  rejectButton.addEventListener('click', rejectClick);

  buttonsContainer.appendChild(acceptButton);
  buttonsContainer.appendChild(rejectButton);

  return buttonsContainer;
};

export const checkboxDiffRenderer = (
  newValue: boolean,
  canReview: boolean,
  acceptClick?: () => void,
  rejectClick?: () => void,
): HTMLSpanElement => {
  const diffContainer = document.createElement('span');
  diffContainer.classList.add(CHECKBOX_CLASSES.DIFF_CONTAINER);

  const leftFiller = document.createElement('br');
  leftFiller.setAttribute(CHECKBOX_ATTRIBUTES.FILLER, 'true');
  const rightFiller = document.createElement('br');
  leftFiller.setAttribute(CHECKBOX_ATTRIBUTES.FILLER, 'true');

  const leftBox = document.createElement('span');
  leftBox.appendChild(leftFiller);
  leftBox.classList.add(CHECKBOX_CLASSES.DIFF_CHECKBOX);
  leftBox.classList.add(
    newValue ? CHECKBOX_CLASSES.DIFF_CHECKBOX_RED : CHECKBOX_CLASSES.DIFF_CHECKBOX_RED_ACTIVE,
  );
  leftBox.setAttribute(CHECKBOX_ATTRIBUTES.DATA_NEW_VALUE, newValue ? 'true' : 'false');
  leftBox.setAttribute(CHECKBOX_ATTRIBUTES.DATA_CAN_REVIEW, canReview ? 'true' : 'false');

  const rightBox = document.createElement('span');
  rightBox.appendChild(rightFiller);
  rightBox.classList.add(CHECKBOX_CLASSES.DIFF_CHECKBOX);
  rightBox.classList.add(
    newValue
      ? CHECKBOX_CLASSES.DIFF_CHECKBOX_DISABLE_ACTIVE
      : CHECKBOX_CLASSES.DIFF_CHECKBOX_DISABLE,
  );

  diffContainer.appendChild(leftBox);
  diffContainer.appendChild(rightBox);

  if (!isNil(acceptClick) && !isNil(rejectClick) && canReview) {
    const buttons = buttonsRenderer(acceptClick, rejectClick);
    diffContainer.appendChild(buttons);
  }

  return diffContainer;
};

export const checkBoxRenderer = (id: string, checked: boolean): HTMLSpanElement => {
  const checkbox = document.createElement('span');
  checkbox.classList.add(CHECKBOX_CLASSES.DW_CHECKBOX);
  checkbox.setAttribute(CHECKBOX_ATTRIBUTES.CHECKED, checked ? 'true' : 'false');
  checkbox.setAttribute(CHECKBOX_ATTRIBUTES.ID, id);

  if (checked) {
    checkbox.classList.add(CHECKBOX_CLASSES.CHECKBOX_ACTIVE);
  }

  return checkbox;
};
