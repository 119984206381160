import { FC } from 'react';

import { DocumentRole } from 'src/models/document';
import { ContextMenuProps } from 'src/v2/features/contextMenu/types';
import { EntityProjectContextMenu } from 'src/v2/features/sharedEntity/EntityProjectContextMenu';
import { CardData } from 'src/v2/features/sharedEntity/types';
import {
  useNavigateToEditParticipant,
  useNavigateToInviteParticipant,
} from 'src/v2/features/document/DocumentSidebar/nav-hooks';
import { useEditParticipantCallback } from 'src/v2/features/entityParticipants/hooks';
import { canManageParticipantInDocument } from 'src/v2/features/document/permissions/documentPermissions/documentPermissions';
import { ContextMenuItem } from 'src/v2/components/contextMenu';
import { DropdownAddUser } from 'src/v2/icons/DropdownAddUser';
import { DropdownEditUser } from 'src/v2/icons/DropdownEditUser';

export const DocumentProjectContextMenu: FC<ContextMenuProps> = (props) => {
  const { closeOnClick, payload } = props;
  const { id: documentId, role } = payload as CardData<DocumentRole>;

  const navigateToInviteParticipant = useNavigateToInviteParticipant(documentId);
  const navigateToEditParticipant = useNavigateToEditParticipant(documentId);
  const isAllowedToManageParticipants = canManageParticipantInDocument(role);

  const handleInviteUser = closeOnClick(navigateToInviteParticipant);
  const handleEditUser = closeOnClick(useEditParticipantCallback(navigateToEditParticipant)());

  return (
    <EntityProjectContextMenu<DocumentRole> {...props}>
      <ContextMenuItem
        icon={DropdownAddUser}
        text="Add user"
        disabled={!isAllowedToManageParticipants}
        onClick={handleInviteUser}
        data-id="addUser-menuButton"
      />
      <ContextMenuItem
        icon={DropdownEditUser}
        text="Edit user"
        onClick={handleEditUser}
        data-id="editUser-menuButton"
      />
    </EntityProjectContextMenu>
  );
};
