import React from 'react';

import { useWithHistoryCallbackFactory } from 'src/v2/features/sidebarController';
import { useShowMetadataEdit } from 'src/v2/features/sharedEntity';
import { useGetTemplateById } from 'src/v2/features/template/hooks';
import { canEditTemplateMetaData, getOwnerName } from 'src/v2/features/template/utils';

import { TemplateMetadataView } from '../../components/TemplateMetadata';
import { Publish } from '../components/Publish';
import { ParticipantsSection } from '../ParticipantsSection';

interface Props {
  id: string;
}

export const View: React.FC<Props> = (props) => {
  const { id } = props;
  const template = useGetTemplateById(id);

  const [, editMetadataCb] = useShowMetadataEdit();
  const editTemplateMetadata = useWithHistoryCallbackFactory(editMetadataCb)({
    id,
  });

  return template ? (
    <>
      <TemplateMetadataView
        title={template.title}
        description={template.description}
        createdAt={template.createdAt}
        updatedAt={template.updatedAt}
        paperType={template.paperType}
        type={template.type}
        subType={template.subType}
        industry={template.industry}
        province={template.province}
        city={template.city}
        country={template.country}
        owner={getOwnerName(template)}
        onEdit={editTemplateMetadata}
        isAllowedToEdit={canEditTemplateMetaData(template)}
      />
      <Publish templateId={id} />
      <ParticipantsSection entityId={template.templateId} />
    </>
  ) : null;
};
