import React from 'react';

import { useWithHistoryCallbackFactory } from 'src/v2/features/sidebarController';
import { useShowMetadataEdit } from 'src/v2/features/sharedEntity';

import { AbstractView } from './AbstractView';

interface Props {
  id: string;
}

export const View: React.FC<Props> = ({ id }) => {
  const [, editMetadataCb] = useShowMetadataEdit();
  const editDocumentMetadata = useWithHistoryCallbackFactory(editMetadataCb)({
    id,
  });

  const editDocumentMetadataWithOldSidebarState = (): void => {
    editDocumentMetadata();
  };

  return <AbstractView id={id} onEdit={editDocumentMetadataWithOldSidebarState} />;
};
