import React, { FC, useContext } from 'react';
import { useSelector } from 'react-redux';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

import { ParticipantList, ParticipantControls } from 'src/v2/features/entityParticipants';
import { availableChatsSelector } from 'src/v2/features/chat/store';
import { useOpenChat, useCreateCanStartChat } from 'src/v2/features/chat/hooks';
import { SidebarParticipantEntity } from 'src/v2/entities/participants';
import { DocumentRole } from 'src/models/document';
import { useNavigateToConfirmRemoveParticipant } from './hooks';
import {
  useRemoveParticipantCallback,
  useEditParticipantCallback,
} from '../../entityParticipants/hooks';
import { SharedSidebarContext } from 'src/v2/features/sharedEntity/EntitySidebar/SharedSidebarContext';

export interface Props {
  entityId: string;
  participants: SidebarParticipantEntity<DocumentRole>[];
  isAllowedToEditParticipant: (participantId: string) => boolean;
  isAllowedToRemoveParticipant: (participantId: string) => boolean;
}

export const ParticipantsSectionWithoutUsers: FC<Props> = ({
  entityId,
  participants,
  isAllowedToEditParticipant,
  isAllowedToRemoveParticipant,
}) => {
  const {
    isAllowedToManageParticipants,
    isAllowedToChat,
    navigateToInviteParticipant,
    navigateToEditParticipant,
    getShownParticipantRole,
  } = useContext(SharedSidebarContext);

  const availableChats = useSelector(availableChatsSelector);
  const navigateToConfirmRemoveParticipant = useNavigateToConfirmRemoveParticipant(entityId);

  const onAddCb = navigateToInviteParticipant;
  const onAdd = isAllowedToManageParticipants ? onAddCb : undefined;
  const onEditParticipants = useEditParticipantCallback(navigateToEditParticipant);
  const onRemoveParticipants = useRemoveParticipantCallback(navigateToConfirmRemoveParticipant);

  const participantClick = useOpenChat(availableChats);
  const canStartChat = useCreateCanStartChat(availableChats);
  const canChat = isAllowedToChat ? canStartChat : undefined;

  const getParticipantControls = (p: SidebarParticipantEntity<DocumentRole>) => {
    const onEdit = isAllowedToEditParticipant(p.id) ? onEditParticipants(p) : undefined;
    const onRemove = isAllowedToRemoveParticipant(p.id) ? onRemoveParticipants(p) : undefined;

    return <ParticipantControls onEdit={onEdit} onRemove={onRemove} />;
  };

  return (
    <ParticipantList
      label={i18n(translationKeys.forms.item.users)}
      onAdd={onAdd}
      list={participants}
      onClick={participantClick}
      canStartChat={canChat}
      controls={getParticipantControls}
      getShownRole={getShownParticipantRole}
    />
  );
};
