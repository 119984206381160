import { DocumentType } from 'src/models/document';
import { SearchEntityType } from 'src/v2/features/search/store';
import { ContractType } from 'src/models/contract';

export const emptyOption = { label: 'Select...', value: '' };
const documentTypes = [
  emptyOption,
  { label: DocumentType.application, value: DocumentType.application },
  { label: DocumentType.letter, value: DocumentType.letter },
  { label: DocumentType.bill, value: DocumentType.bill },
  { label: DocumentType.certificate, value: DocumentType.certificate },
  { label: DocumentType.instruction, value: DocumentType.instruction },
  { label: DocumentType.insurance, value: DocumentType.insurance },
  { label: DocumentType.memorandum, value: DocumentType.memorandum },
  { label: DocumentType.record, value: DocumentType.record },
  { label: DocumentType.receipt, value: DocumentType.receipt },
  { label: DocumentType.warranty, value: DocumentType.warranty },
  { label: DocumentType.script, value: DocumentType.script },
  { label: DocumentType.other, value: DocumentType.other },
  { label: DocumentType.report, value: DocumentType.report },
];

const contractTypes = [
  emptyOption,
  { label: ContractType.nonDisclosureAgreement, value: ContractType.nonDisclosureAgreement },
  { label: ContractType.consultingAgreement, value: ContractType.consultingAgreement },
  { label: ContractType.serviceAgreement, value: ContractType.serviceAgreement },
  { label: ContractType.salesAgreement, value: ContractType.salesAgreement },
  { label: ContractType.will, value: ContractType.will },
  { label: ContractType.employmentContract, value: ContractType.employmentContract },
  { label: ContractType.leasingAgreement, value: ContractType.leasingAgreement },
  { label: ContractType.financialAgreement, value: ContractType.financialAgreement },
  { label: ContractType.purchasingAgreement, value: ContractType.purchasingAgreement },
  { label: ContractType.disclosureAgreement, value: ContractType.disclosureAgreement },
  { label: ContractType.jointVentureAgreement, value: ContractType.jointVentureAgreement },
  { label: ContractType.loanAgreement, value: ContractType.loanAgreement },
  { label: ContractType.medicalRecordsAgreement, value: ContractType.medicalRecordsAgreement },
  { label: ContractType.mortgageAgreement, value: ContractType.mortgageAgreement },
  { label: ContractType.paymentAgreement, value: ContractType.paymentAgreement },
  { label: ContractType.billOfSale, value: ContractType.billOfSale },
  { label: ContractType.mergerAcquisition, value: ContractType.mergerAcquisition },
  { label: ContractType.other, value: ContractType.other },
];

export const typesOptions = [
  emptyOption,
  { label: SearchEntityType.Document, value: SearchEntityType.Document },
  { label: SearchEntityType.Contract, value: SearchEntityType.Contract },
  { label: SearchEntityType.DocumentTemplate, value: SearchEntityType.DocumentTemplate },
  { label: SearchEntityType.ContractTemplate, value: SearchEntityType.ContractTemplate },
];

export const documentOptions = {
  [SearchEntityType.Document]: documentTypes,
  [SearchEntityType.Contract]: contractTypes,
  [SearchEntityType.DocumentTemplate]: documentTypes,
  [SearchEntityType.ContractTemplate]: contractTypes,
};
