import { useContext } from 'react';

import { DocumentRole, DocumentType } from 'src/models/document';
import {
  NineSquareMenu as CNineSquareMenu,
  AddUser,
  EditUser,
  SaveTemplate,
  Download,
  Share,
  QRCode,
} from 'src/v2/features/nineSquareMenu';
import { useNavigateToShare } from 'src/v2/features/sharedEntity/EntitySidebar/hooks';
import { useGetEntityById, useDownloadAsFile } from 'src/v2/features/sharedEntity/hooks';
import { useEditParticipantCallback } from 'src/v2/features/entityParticipants/hooks';
import { SharedSidebarContext } from 'src/v2/features/sharedEntity/EntitySidebar/SharedSidebarContext';
import {
  useCanDownloadDocument,
  useCanManageParticipantInDocument,
  useCanShareDocument,
} from 'src/v2/features/document/permissions/documentPermissions/documentPermissionsHooks';
import { useHasQRCodeFeature } from 'src/v2/features/billing/hasFeature/hooks';

interface Props {
  id?: string;
}

export const DocumentNineSquareMenu = ({ id }: Props): JSX.Element => {
  const document = useGetEntityById<DocumentType, DocumentRole>(id);
  const handleOnDownload = useDownloadAsFile<DocumentType, DocumentRole>(document);

  const { navigateToInviteParticipant, navigateToEditParticipant, navigateToQRCode } =
    useContext(SharedSidebarContext);
  const handleInviteUser = navigateToInviteParticipant;
  const canInviteUser = useCanManageParticipantInDocument(document);
  const canDownloadDocument = useCanDownloadDocument(document);
  const canEditUsersInDocument = useCanManageParticipantInDocument(document);
  const canUserShareDocument = useCanShareDocument(document);
  const handleEditUser = useEditParticipantCallback(navigateToEditParticipant)();
  const handleShare = useNavigateToShare(id);
  const handleQRCode = navigateToQRCode;
  const hasQRCodeFeature = useHasQRCodeFeature();

  return (
    <CNineSquareMenu>
      <AddUser onClick={handleInviteUser} data-id="addUser-menuButton" disabled={!canInviteUser} />
      <EditUser
        onClick={handleEditUser}
        data-id="editUser-menuButton"
        disabled={!canEditUsersInDocument}
      />
      <SaveTemplate data-id="saveTemplate-menuButton" disabled />
      <Download
        onClick={handleOnDownload}
        data-id="download-menuButton"
        disabled={!canDownloadDocument}
      />
      <Share onClick={handleShare} data-id="share-menuButton" disabled={!canUserShareDocument} />
      {hasQRCodeFeature && <QRCode onClick={handleQRCode} data-id="qrCode-menuButton" />}
    </CNineSquareMenu>
  );
};
