import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { fetchDocumentApi, qrCodeShareAPI, shareApi } from 'src/api/documents';
import { toastr } from 'react-redux-toastr';
import { PayloadAction } from '@reduxjs/toolkit';

import { storeNewData } from 'src/v2/features/objectsStorage/objectsStorageSlice';

import { SharePayload } from './types';
import { DbRecordType } from '../../objectsStorage/types';

export function* shareSaga({ payload }: PayloadAction<SharePayload>): SagaIterator {
  try {
    const { entityId } = payload;
    yield call(shareApi, entityId, payload);
    const entity = yield call(fetchDocumentApi, entityId);
    yield put(storeNewData({ ...entity, entityType: entity.type, type: DbRecordType.Paper }));
    toastr.success('Success', 'Document has been shared');
  } catch (err) {
    toastr.error('Error', 'Share request has failed');
  }
}

export function* qrCodeShareSaga({ payload }: PayloadAction<SharePayload>): SagaIterator {
  try {
    yield call(qrCodeShareAPI, payload.entityId, payload);
    const document = yield call(fetchDocumentApi, payload.entityId);
    yield put(storeNewData({ ...document, entityType: document.type, type: DbRecordType.Paper }));
    yield call(toastr.success, 'Success', 'Your QR Code has been shared');
  } catch (err) {
    toastr.error('Error', 'Share request has failed');
  }
}
