import { SagaIterator } from 'redux-saga';
import qs from 'qs';

import { apiClient } from 'src/common/client';
import { ObjectSerialized } from 'src/common/types';
import { DocumentType } from 'src/models/document';
import { SearchEntityType } from 'src/v2/features/search/store';
import { SortUrlParams } from 'src/models/sort';

export interface SearchParams extends SortUrlParams {
  keyword: string;
  refineKeyword: string;
  author: string;
  type: SearchEntityType | string;
  subSubType: string;
  subType: DocumentType | string;
  seeMore?: SearchEntityType;
}

export function searchByParams(params: SearchParams): SagaIterator<ObjectSerialized | null> {
  const { keyword, refineKeyword, author, type, subType, subSubType, sortBy, sortDirection } =
    params;
  return apiClient.get(
    `/documents/search?${qs.stringify({
      filter: {
        keyword,
      },
      refineFilter: {
        keyword: refineKeyword,
        author,
        type,
        subType,
        subSubType,
      },
      sortBy,
      sortDirection,
    })}`,
  );
}
