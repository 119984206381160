import React, { createContext } from 'react';
import { noop } from 'lodash';
import { StaticSettings } from 'src/common/types';

const Empty = () => <></>;

export const StaticSettingsContext = createContext<StaticSettings>({
  navChildren: <Empty />,
  onSearchSubmit: noop,
  onLogoutClick: noop,
  logoUrl: '',
  helpUrl: '',
  profileUrl: '',
  userAvatarUrl: '',
});
