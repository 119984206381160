import { SocketConnectionId } from 'src/v2/boundary/socket';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Body = any[];
type Args = [SocketConnectionId, ...Body];

function logMessage(logFn: typeof console.log, id: SocketConnectionId, ...body: Body) {
  if (process.env.NODE_ENV === 'development') logFn(`[${id}]`, ...body);
}

export const logWarning = (...args: Args) => logMessage(console.warn, ...args);
export const logError = (...args: Args) => logMessage(console.error, ...args);
export const logInfo = (...args: Args) => logMessage(console.info, ...args);
