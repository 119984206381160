import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNull } from 'lodash';

import { EntityType } from 'src/models/paper';
import { TemplatePayload } from 'src/models/template';
import { Case, Switch } from 'src/v2/components/SwitchCase';
import { useGetEntityCurrentFolderId } from 'src/v2/features/sharedEntity/hooks';
import { isTemplatesFolder } from 'src/v2/features/quantumNavigator/utils';
import { TemplateSidebarUpload } from 'src/v2/features/template/TemplateSidebar/templateSidebarUpload/TemplateSidebarUpload';
import { history } from 'src/initializeHistory';

import { CreateTemplatePayload, TemplateCreationMode } from '../../types';
import { TemplateMetadataCreate } from '../../components/TemplateMetadata';
import { getIsLoading } from '../../selectors';
import { createTemplate } from '../../reducer';

export const useCreateTemplatePageData = (
  folderId: string,
): [TemplateCreationMode, TemplatePayload | null, (value: CreateTemplatePayload) => void] => {
  const dispatch = useDispatch();
  const [state, setState] = useState<TemplateCreationMode>(TemplateCreationMode.Form);
  const [prepopulatedMetadata, setPrepupulatedMetadata] = useState<TemplatePayload | null>(null);

  const handleSubmit = useCallback(
    (value: CreateTemplatePayload) => {
      const payload = { ...value, folderId };

      if (value.createTemplateOption === TemplateCreationMode.File) {
        setState(TemplateCreationMode.File);
        setPrepupulatedMetadata(payload);
      } else {
        if (isNull(value)) return;
        dispatch(createTemplate({ history, payload }));
      }
    },
    [dispatch, folderId],
  );

  return [state, prepopulatedMetadata, handleSubmit];
};

export const Create: React.FC = () => {
  const folderId = useGetEntityCurrentFolderId(isTemplatesFolder);

  const isLoading = useSelector(getIsLoading);
  const [state, payload, handleSubmit] = useCreateTemplatePageData(folderId);

  return (
    <Switch criteria={state}>
      <Case value={TemplateCreationMode.Form}>
        <TemplateMetadataCreate
          paperType={EntityType.document}
          isLoading={isLoading}
          onSubmit={handleSubmit}
        />
      </Case>
      <Case value={TemplateCreationMode.File}>
        {!isNull(payload) && <TemplateSidebarUpload payload={payload} folderId={folderId} />}
      </Case>
    </Switch>
  );
};
