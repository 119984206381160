import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ListItemViewComponentProps } from 'src/v2/modules/FsItem/types';

import { FolderView } from './FolderView';
import { getActiveFolderId, setActiveFolderId } from '../store';

export const FolderViewContainer: FC<ListItemViewComponentProps> = (props) => {
  const dispatch = useDispatch();
  const selectedFolderId = useSelector(getActiveFolderId);
  const isSelected = props.model.data.id === selectedFolderId;
  const setSelected = () => dispatch(setActiveFolderId(props.model.data.id));

  return <FolderView {...props} isSelected={isSelected} setSelected={setSelected} />;
};
