import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SearchPageState } from 'src/app/types';
import { ObjectSerialized } from 'src/common/types';

import { Counters, SearchEntityType } from './searchTypes';

export const initialCounters = {
  contracts: 0,
  documents: 0,
  contractTemplates: 0,
  documentTemplates: 0,
};

const initialState: SearchPageState = {
  keyword: '',
  list: [],
  counters: initialCounters,
  authors: [],
  subTypes: [],
  seeMoreType: null,
  isLoading: false,
  error: '',
};

const searchListSlice = createSlice({
  name: 'searchListSlice',
  initialState,
  reducers: {
    fetchSearchListStart(state: SearchPageState): void {
      state.isLoading = true;
      state.error = '';
    },

    fetchSearchListFailed(state: SearchPageState, action: PayloadAction<string>): void {
      state.isLoading = false;
      state.error = action.payload;
    },

    fetchSearchListSuccess(state: SearchPageState, action: PayloadAction<ObjectSerialized>): void {
      state.isLoading = false;
      state.list = ObjectSerialized.dataToBaseObjects(action.payload.included);
    },
    setCounters(state: SearchPageState, action: PayloadAction<Counters>): void {
      state.counters = action.payload;
    },
    setSeeMoreType(state: SearchPageState, action: PayloadAction<SearchEntityType | null>): void {
      state.seeMoreType = action.payload;
    },
    setAuthors(state: SearchPageState, action: PayloadAction<ObjectSerialized>): void {
      state.authors = ObjectSerialized.dataToBaseObjects(action.payload.data);
    },
    setSubTypes(state: SearchPageState, action: PayloadAction<string[]>): void {
      state.subTypes = action.payload;
    },
    setKeyword(state: SearchPageState, action: PayloadAction<string>): void {
      state.keyword = action.payload;
    },
  },
});

export const {
  setKeyword,
  setAuthors,
  setCounters,
  setSubTypes,
  setSeeMoreType,
  fetchSearchListStart,
  fetchSearchListFailed,
  fetchSearchListSuccess,
} = searchListSlice.actions;

export default searchListSlice.reducer;
