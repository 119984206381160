import { FC, ReactNode, useContext } from 'react';
import { isUndefined } from 'lodash';

import { DynamicSettingsContext } from 'src/v2/modules/layout/contexts/DynamicSettingsContext';
import { DynamicSettings } from 'src/v2/modules/layout/types';

import { ActionColumn } from './ActionColumn';
import { BaseActions } from './BaseActions';

interface Props {
  plusTitle: string;
  children: ReactNode;
  className?: string;
}

export const DriveActions: FC<Props> = ({ plusTitle, children }) => {
  const { onPlusClick } = useContext<DynamicSettings>(DynamicSettingsContext);

  return (
    <BaseActions>
      <ActionColumn onClick={onPlusClick}>
        {!isUndefined(onPlusClick) && (
          <div className="c-action__item">
            <div className="c-action__plus" data-type="newItem-button" />
            <div className="c-action__title min-1280">{plusTitle}</div>
          </div>
        )}
      </ActionColumn>
      <ActionColumn>{children}</ActionColumn>
    </BaseActions>
  );
};
