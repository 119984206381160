import { CardParticipantEntity, SidebarParticipantEntity } from 'src/v2/entities/participants';
import { CardPaperModel } from 'src/models/card';

export interface ProjectEntity {
  id: string;
  title: string;
  description?: string;
  participants: SidebarParticipantEntity<ProjectRole>[];
  ownerParticipant: SidebarParticipantEntity<ProjectRole>;
  papers: CardPaperModel<ProjectCardType, ProjectRole>[];
}

export enum ProjectRole {
  ProjectOwner = 'project_owner',
  ProjectUser = 'project_user',
}

export enum ProjectCardType {
  Document = 'document',
  Contract = 'contract',
}

export interface CardProjectEntity {
  id: string;
  title: string;
  description?: string;
  ownerParticipant: CardParticipantEntity<ProjectRole>;
}
