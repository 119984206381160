import React from 'react';

import { EntityContext, EntityLayout } from 'src/v2/features/sharedEntity';
import { EntityLayoutProps } from 'src/v2/features/sharedEntity/types';

import { EntityType } from 'src/models/paper';
import { ContextMenuTypeContextProvider } from './providers/ContextMenuTypeContextProvider';

export const ContractLayout: React.FC<EntityLayoutProps> = ({ children, isLoading, error }) => (
  <EntityContext.Provider value={EntityType.contract}>
    <ContextMenuTypeContextProvider>
      <EntityLayout isLoading={isLoading} error={error}>
        {children}
      </EntityLayout>
    </ContextMenuTypeContextProvider>
  </EntityContext.Provider>
);
