import { useSelector } from 'react-redux';
import { isUndefined } from 'lodash';

import { NodeTree } from 'src/models/node';
import { ListItem } from 'src/models/list';
import { getTemplateByIdFn } from 'src/v2/features/template/selectors';
import { getActivePlan } from 'src/v2/features/billing';
import { convertTemplateParticipantsToParticipantModel } from 'src/v2/features/template/utils';
import { canCreateFolder } from 'src/v2/features/quantumNavigator/utils';

import { canCreateFolderInTemplates } from './permissions';

export const useGetTemplateById = (templateId: string | undefined) => {
  const getTemplateById = useSelector(getTemplateByIdFn);

  if (isUndefined(templateId)) return null;

  return getTemplateById(templateId);
};

export const useGetParticipantsByTemplateId = (templateId: string | undefined) => {
  const template = useGetTemplateById(templateId);
  const participants = convertTemplateParticipantsToParticipantModel(
    template ? template.participants : [],
  );

  return participants;
};

export const useCanCreateFolderInTemplates = (folder: NodeTree<ListItem>) => {
  const plan = useSelector(getActivePlan);
  const isCanCreateFolder = canCreateFolder(folder);

  return canCreateFolderInTemplates(plan) && isCanCreateFolder;
};
