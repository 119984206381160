import { PayloadAction } from '@reduxjs/toolkit';
import { call, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { toastr } from 'react-redux-toastr';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { File, FileUrl } from 'src/v2/features/sharedEntity/types';
import { downloadAsFileAPI } from 'src/api/documents';
import { handleErrorSaga } from 'src/utils/handleErrorSaga';
import { extensionByMimeType } from 'src/v2/components/FileUpload/types';

import { downloadAsFile } from './actions';
import { downloadFileViaLink } from './utils';

export function* downloadAsFileSaga(action: PayloadAction<File>) {
  const { name, id } = action.payload;
  const extension = extensionByMimeType[action.payload.extension];
  const title = `${name}.${extension}`;

  try {
    yield call(
      toastr.info,
      i18n(translationKeys.messages.info),
      i18n(translationKeys.messages.fileIsDownloading),
      { timeOut: 1000 },
    );
    const { fileUrl }: FileUrl = yield call(downloadAsFileAPI, id);

    if (fileUrl) {
      const response = yield call(fetch, fileUrl);
      const blob = yield response.blob();
      const fr = new FileReader();

      fr.readAsDataURL(new window.Blob([blob], { type: 'application/octet-stream' }));

      fr.addEventListener('load', () => {
        if (fr.result) {
          downloadFileViaLink(fr.result, title);
        }
      });

      toastr.success(
        i18n(translationKeys.messages.success),
        i18n(translationKeys.messages.fileDownloaded),
      );
    }
  } catch (error) {
    yield call(handleErrorSaga, error);
  }
}

export function* watchSharedEntitySagas(): SagaIterator {
  yield takeLatest(downloadAsFile, downloadAsFileSaga);
}
