import { FC, MouseEvent } from 'react';
import { Trans } from 'react-i18next';

import { translationKeys } from 'src/common/translations';

type Props = {
  onClick: (event: MouseEvent<HTMLInputElement>) => void;
};

export const TermsLink: FC<Props> = ({ onClick }) => (
  <>
    <span className="c-checkbox__title">
      <Trans
        i18nKey={translationKeys.checkout.agreement}
        components={{
          termsLink: <span className="c-checkbox__link" onClick={onClick} />,
        }}
      />
    </span>
  </>
);
