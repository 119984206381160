import React from 'react';
import { Box } from '@theme-ui/components';

interface Props {
  label: string;
  name?: string;
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  sx?: object;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Radio = ({ label, sx, ...other }: Props): JSX.Element => (
  <Box className="c-radio" sx={sx}>
    <label className="c-radio__label">
      <input type="radio" name="radio" hidden {...other} />
      <span className="c-radio__icon"></span>
      <span className="c-radio__title">{label}</span>
    </label>
  </Box>
);
