import { CompleteObject } from 'src/common/types';

export type SignatureId = string;
export type StampId = string;

export enum SignatureFont {
  Anita = 'anita',
  Silently = 'silently',
  Amorbetia = 'amorbetia',
  NotoSansJP = 'notosansjp',
  NotoSerifJP = 'notoserifjp',
  Roboto = 'roboto',
}

export interface SignatureStorageModel extends CompleteObject {
  default_signature_id: SignatureId;
  signature: SignatureCompleteModel[];
  stamp: SignatureStampModel[];
}

export interface SignatureCompleteModel {
  signature_id: SignatureId;
  hash: string;
  signature_file_link: string;
  created_at: Date;
  default_stamp_id: StampId;
  include_company: boolean;
  include_stamp: boolean;
  legal_name: string;
  style: string;
  signature_description: SignatureDescription[];
}

export interface SignatureStampModel {
  stamp_id: StampId;
  stamp_file_link: string;
}

export interface SignatureWithStampModel extends SignatureCompleteModel {
  default_stamp?: SignatureStampModel;
}

export interface SignatureDescription {
  company: string;
  name: string;
  title: string;
}

export interface SignaturePayload {
  legal_name: string;
  style: string;
  include_stamp: boolean;
  include_company: boolean;
  localizing: boolean;
  signature_description: SignatureDescription;
}

export interface SignatureDataNormalized {
  legalName: string;
  hash: string;
  style: string;
  companyName: string;
  userTitle: string;
  userName: string;
  includeCompany: boolean;
  includeStamp: boolean;
  imageUrl?: string;
  defaultStampId?: SignatureId;
}
