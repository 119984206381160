import React from 'react';
import { Flex, Text } from '@theme-ui/components';

import { SidebarParticipantEntity } from 'src/v2/entities/participants';
import { getAvatarUrl } from 'src/v2/features/profile';
import { UsersFeed } from 'src/v2/components/UsersFeed';

import { useGetFullParticipantsByDocumentId } from '../../entityParticipants/hooks';
import { getShownName } from '../../document/selectors';

const styles = {
  userBlock: {
    alignItems: 'center',
    flex: 1,
  },

  userName: {
    color: '#324353',
    fontSize: 'normal',
    ml: '8px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
};

interface Props {
  id: string;
  members: SidebarParticipantEntity[];
  owner?: boolean;
}

export const ContractSideUsers: React.FC<Props> = ({ id, members, owner = false }) => {
  const participants = useGetFullParticipantsByDocumentId(id);

  const names = participants
    .filter(({ userId: id1 }) => members.some(({ userId: id2 }) => id2 === id1))
    .map(({ userId, firstName, lastName, email }) => {
      const fullName = getShownName(firstName, lastName, email);
      return userId === id ? `${fullName} (you)` : fullName;
    });
  const avatars = members.map(({ userId, avatar }) => ({
    id: userId,
    src: getAvatarUrl(avatar),
  }));
  return (
    <Flex sx={styles.userBlock}>
      <UsersFeed users={avatars} size={60} borderColor={owner ? '#e4b62c' : '#29cb97'} />
      <Text sx={styles.userName}>{names.join(', ')}</Text>
    </Flex>
  );
};
