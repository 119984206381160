const basicBook = {
  viewProject: 'viewProject',
  editProject: 'editProject',
  createProject: 'createProject',
  removeProject: 'removeProject',
  createDocument: 'createDocument',
  editDocument: 'editDocument',
  viewDocument: 'viewDocument',
  createContract: 'createContract',
  editContract: 'editContract',
  viewContract: 'viewContract',
  nineSquareMenu: 'nineSquareMenu',
  removeParticipant: 'removeParticipant',
};

export const book = Object.freeze({
  ...basicBook,
  default: basicBook.viewProject,
});
