/* eslint-disable */
import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { call, delay, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  rejectPromiseAction,
  resolvePromiseAction,
} from '@adobe/redux-saga-promise';
import { toastr } from 'react-redux-toastr';
import { every, get, isEmpty, last, some, isNull, lowerCase, isUndefined } from 'lodash';

import { ObjectBase, ObjectSerialized, WSObjectSerialized } from 'src/common/types';
import { RootState, DocumentState } from 'src/app/types';
import { getContentFields } from 'src/v2/components/Editor';
import { AccessLevel, ContentMetadata, ContentType } from 'src/models/paper';
import {
  Document,
  DocumentContent,
  DocumentRole,
  EntityLock,
  Field,
  Section,
} from 'src/models/document';
import {
  WorkflowCondition,
  WorkflowConditionState,
  WorkflowConditionType,
  WorkflowParty,
  WorkflowType,
} from 'src/v2/entities/workflow';
import { SidebarParticipantEntity } from 'src/v2/entities/participants';
import {
  fetchDocumentApi,
  fetchDocumentInfoApi,
  insertSectionApi,
  modifyFileApi,
  moveSectionApi,
  readyToSignApi,
  removeSectionApi,
  updateFieldApi,
  updateFieldsApi,
  updateSectionApi,
  uploadFileApi,
  uploadFileByUrlApi,
  workflowActionApi,
  fetchDocumentQRCodeAPI,
  fetchDocumentPreviewAPI,
} from 'src/api/documents';
import {
  getObject,
  getObjects,
  getObjectsMap,
  storeData,
  storeNewData,
} from 'src/v2/features/objectsStorage/objectsStorageSlice';
import { DbRecordType, ObjectsContainer } from 'src/v2/features/objectsStorage/types';
import { getUserObj } from 'src/v2/features/objectsStorage/users';
import { getIsAuthenticated, getUserId } from 'src/shared/auth';
import { fetchUsers, getAvatarUrlByProfile } from 'src/v2/features/profile';
import { UserAcknowledge, UserSignature } from 'src/models/profile';
import { documentSelectorsFactory } from 'src/v2/features/document/selectors';
import { navigateToDocumentDetailsFactory } from 'src/v2/features/document/utils';
import { UploadedEntityExtension } from 'src/v2/features/sharedEntity/types';
import { getProfile } from 'src/v2/features/documentParticipants';
import {
  fetchWorkflow,
  getWorkflow,
  getWorkflowType,
  isInWork,
} from 'src/v2/features/documentWorkflow';
import { responseErrorExtract } from 'src/utils/responseErrorExtract';
import { listenWSEvents, sendWSCommand } from 'src/api/socket/connection';
import { socketConnected } from 'src/api/socket/store';
import { SocketConnectionId } from 'src/v2/boundary/socket';
import { SocketConnectedPayload } from 'src/v2/boundary/actionPayload/socket';
import { history } from 'src/initializeHistory';
import { getEntityById, getPreviewEntityById } from 'src/v2/features/sharedEntity/selectors';
import {
  InsertSectionPayload,
  UpdateSectionPayload,
  MoveSectionPayload,
  RemoveSectionPayload,
  UploadFilePayload,
  UploadFileByUrlPayload,
  ModifyFilePayload,
} from 'src/models/entity';
import { FetchEntityByQRActionPayload } from 'src/v2/boundary/actionPayload/signup';
import { getUsers, getProfiles, getAvatars } from 'src/v2/features/objectsStorage';
import { UserCompleteModel } from 'src/v2/boundary/storageModels/user';
import { clearWorkflow } from 'src/v2/features/documentWorkflow';

import { UpdateFieldPayload, WorkflowActionPayload } from './types';
import {
  areSidesEqual,
  convertDocumentToDocumentContent,
  createUserSignaturesFromUserConditions,
  mergeSectionsWithLocks,
  uniquifySectionIds,
  getLockPath,
} from './utils';
import { MimeType } from 'src/common/types';

const REFRESH_LOCK_INTERVAL = 10000;

enum DocumentWSCommand {
  Lock = 'document/lock',
  Unlock = 'document/unlock',
  Subscribe = 'document/subscribe',
  Unsubscribe = 'document/unsubscribe',
}

enum WorkflowWSCommand {
  Subscribe = 'workflow/workflowSubscribe',
  Unsubscribe = 'workflow/workflowUnSubscribe',
}

const initialState: DocumentState = {
  data: null,
  isLoading: false,
  isFieldUpdating: false,
  error: '',
  activeLock: null,
  isFileFullyLoaded: false,
};

const documentDetails = createSlice({
  name: 'documentDetails',
  initialState,
  reducers: {
    startFieldUpdate(state): void {
      state.isFieldUpdating = true;
    },

    finishFieldUpdate(state): void {
      state.isFieldUpdating = false;
    },

    fetchDocumentStart(state): void {
      state.isLoading = true;
      state.data = null;
      state.error = '';
    },

    fetchDocumentFailed(state, action: PayloadAction<string>): void {
      state.isLoading = false;
      state.error = action.payload;
    },

    fetchDocumentSuccess(state, action): void {
      state.isLoading = false;
      state.data = action.payload;
    },

    fetchDocumentPreviewStart(state): void {
      state.isLoading = true;
      state.data = null;
      state.error = '';
    },

    fetchDocumentPreviewFailed(state, action: PayloadAction<string>): void {
      state.isLoading = false;
      state.error = action.payload;
    },

    fetchDocumentPreviewSuccess(state, action: PayloadAction<ObjectSerialized>): void {
      state.isLoading = false;
      state.data = ObjectSerialized.dataToBaseObjects(action.payload.data)[0];
    },

    setActiveLock(state, action: PayloadAction<EntityLock | null>): void {
      state.activeLock = action.payload;
    },

    setFileFullyLoaded(state): void {
      state.isFileFullyLoaded = true;
    },
  },
});

export const fetchDocument = createAction<string>('documents/fetchDocument');
export const fetchDocumentInfo = createAction<string>('documents/fetchDocumentInfo');
export const fetchDocumentQR = createAction<FetchEntityByQRActionPayload>(
  'documents/fetchDocumentQR',
);
export const fetchDocumentPreview = createAction<FetchEntityByQRActionPayload>(
  'documents/fetchDocumentPreview',
);
export const insertSection = createPromiseAction('documents/insertSection');
export const updateSection = createPromiseAction('documents/updateSection');
export const moveSection = createPromiseAction('documents/moveSection');
export const removeSection = createPromiseAction('documents/removeSection');
export const uploadFile = createPromiseAction('documents/uploadFile');
export const modifyFile = createAction<ModifyFilePayload>('documents/modifyFile');
export const uploadFileByUrl = createPromiseAction('documents/uploadFileByUrl');
export const workflowAction = createAction<WorkflowActionPayload>('documents/workflowAction');
export const updateField = createPromiseAction('documents/updateField');
export const updateFields = createPromiseAction('documents/updateFields');
export const readyToSign = createAction<void>('contracts/readyToSign');
export const lockSection = createAction<number>('documents/lock');
export const unlockSection = createAction<number>('documents/unlock');

//input from ws
export const documentContent = createAction<WSObjectSerialized>('document/content');
export const documentLocked = createAction<WSObjectSerialized>('document/locked');
export const workflowContent = createAction<WSObjectSerialized>('workflow/workflowContent');

export const {
  startFieldUpdate,
  finishFieldUpdate,
  fetchDocumentStart,
  fetchDocumentFailed,
  fetchDocumentSuccess,
  fetchDocumentPreviewStart,
  fetchDocumentPreviewFailed,
  fetchDocumentPreviewSuccess,
  setActiveLock,
  setFileFullyLoaded,
} = documentDetails.actions;

/**
 * Selectors
 */
const getState = (state: RootState): DocumentState => state.documentDetails;

export const getPreviewDocument = createSelector(
  (state: RootState) => getState(state).data,
  getPreviewEntityById,
  (item, getPreviewEntityById): Document | null => item && getPreviewEntityById(item.id),
);

export const getDocument = createSelector(
  (state: RootState) => getState(state).data,
  getEntityById,
  (item, getEntityById): Document | null => item && getEntityById(item.id),
);

export const getDocumentContent = createSelector(
  getDocument,
  (document): DocumentContent | null => document && convertDocumentToDocumentContent(document),
);

export const getPreviewDocumentContent = createSelector(
  getPreviewDocument,
  (document): DocumentContent | null => document && convertDocumentToDocumentContent(document),
);

export const getIsLoading = createSelector(getState, (state) => state.isLoading);
export const getIsFieldUpdating = createSelector(getState, (state) => state.isFieldUpdating);
export const getError = createSelector(getState, (state) => state.error);
export const getDocumentBase = createSelector(getState, (state) => state.data as ObjectBase);
export const getActiveLock = createSelector(getState, (state) => state.activeLock);
export const getIsFileFullyLoaded = createSelector(getState, (state) => state.isFileFullyLoaded);

export const getDocumentId = createSelector(getDocumentContent, (data) => (data ? data.id : null));
export const getPreviewTitle = createSelector(getPreviewDocumentContent, (data) =>
  !isNull(data) ? data.title : null,
);
export const getTitle = createSelector(getDocumentContent, (data) => (data ? data.title : null));
export const getSections = createSelector(getDocumentContent, (data) =>
  data ? data.sections : null,
);
export const getPreviewSections = createSelector(getPreviewDocumentContent, (data) =>
  data ? data.sections : null,
);
export const getLocks = createSelector(getDocumentContent, (data) => (data ? data.locks : null));

export const getSectionsWithLocks = createSelector(
  getSections,
  getLocks,
  getUserId,
  (sections, locks, userId): Section[] | null => {
    if (isNull(sections) || isNull(locks)) return null;

    try {
      const lockableSections = mergeSectionsWithLocks(sections, locks, userId);
      return uniquifySectionIds(lockableSections);
    } catch (e) {
      console.warn(e);
      return null;
    }
  },
);

export const getPreviewSectionsWithLocks = createSelector(
  getPreviewSections,
  (sections): Section[] | null => {
    if (isNull(sections)) return null;

    try {
      const lockableSections = mergeSectionsWithLocks(sections);
      return uniquifySectionIds(lockableSections);
    } catch (e) {
      console.warn(e);
      return null;
    }
  },
);

export const getActiveLockIndex = createSelector(
  getActiveLock,
  (activeLock): number | null => activeLock && getLockPath(activeLock),
);

export const getOwnerSections = (state: RootState): Section[] =>
  get(getWorkflow(state), 'owner.entity.tree.term', []) as Section[];

export const getCounterpartySections = (state: RootState): Section[] =>
  get(getWorkflow(state), 'parties[0].entity.tree.term', []) as Section[];

export const getOwnerFields = (state: RootState) =>
  get(getWorkflow(state), 'owner.entity.fields', []) as Field[];

export const getCounterpartyFields = (state: RootState) =>
  get(getWorkflow(state), 'parties[0].entity.fields', []) as Field[];

export const getOwnerParty = (state: RootState): WorkflowParty | undefined =>
  get(getWorkflow(state), 'owner') as WorkflowParty;

export const getCounterParty = (state: RootState): WorkflowParty | undefined =>
  get(getWorkflow(state), 'parties[0]') as WorkflowParty;

const getPartyMembers = (state: RootState, party: WorkflowParty): SidebarParticipantEntity[] => {
  const { user, profile, avatar } = state.objectsStorage.objects;
  if (!party || !party.participants) return [];
  return party.participants.map((participant) => {
    const userObj = getUserObj(participant.userId, { user, profile, avatar });
    if (userObj) {
      const profile = getProfile(userObj);
      const avatarUrl = getAvatarUrlByProfile(profile);
      const firstName = get(profile, 'firstName');
      const lastName = get(profile, 'lastName');
      return { ...participant, firstName, lastName, avatar: avatarUrl };
    }
    return participant;
  });
};

export const getOwnerSideMembers = (state: RootState) =>
  getPartyMembers(state, getOwnerParty(state)!);

export const getCounterpartySideMembers = (state: RootState) =>
  getPartyMembers(state, getCounterParty(state)!);

const isUserInParty = (userId: string, party: WorkflowParty): boolean =>
  party && party.participants && party.participants.some((itm) => itm.userId === userId);

export const isOwnerParty = (state: RootState): boolean =>
  isUserInParty(getUserId(state)!, getOwnerParty(state)!);

export const isCounterParty = (state: RootState): boolean =>
  isUserInParty(getUserId(state)!, getCounterParty(state)!);

export const hasCounterparty = (state: RootState) => {
  const members = getCounterpartySideMembers(state);
  return !!members.length;
};

export const getMyParty = (state: RootState): WorkflowParty | undefined => {
  const ownerParty = getOwnerParty(state);
  const counterParty = getCounterParty(state);
  if (isUserInParty(getUserId(state)!, ownerParty!)) return ownerParty;
  if (isUserInParty(getUserId(state)!, counterParty!)) return counterParty;
};

const getConditions = (party: WorkflowParty, type: WorkflowConditionType): WorkflowCondition[] =>
  party && party.conditions ? party.conditions.filter((condition) => condition.type === type) : [];

const getConditionByTypeAndUserId = (
  party: WorkflowParty,
  type: WorkflowConditionType,
  userId: string,
): WorkflowCondition | null =>
  party && party.conditions
    ? party.conditions.filter(
        (condition) => condition.type === type && condition.userId === userId,
      )[0]
    : null;

const hasStatus = (party: WorkflowParty, type: WorkflowConditionType, userId?: string): boolean =>
  userId
    ? some(getConditions(party, type), { state: WorkflowConditionState.Complete, userId })
    : every(getConditions(party, type), { state: WorkflowConditionState.Complete });

export const isSignedByMe = (state: RootState): boolean =>
  hasStatus(getMyParty(state)!, WorkflowConditionType.Signature, getUserId(state)!);

export const isAcknowledgedByMe = (state: RootState): boolean =>
  hasStatus(getMyParty(state)!, WorkflowConditionType.Acknowledge, getUserId(state)!);

export const isReadyToSignByMe = (state: RootState): boolean => {
  const myParty = getMyParty(state);
  const condition = getConditionByTypeAndUserId(
    myParty!,
    WorkflowConditionType.Signature,
    getUserId(state)!,
  );

  if (!condition) return false;
  return condition.state === WorkflowConditionState.UserReadyToSign;
};

const isPartySigned = (party: WorkflowParty | undefined, type: WorkflowConditionType): boolean => {
  if (!party) {
    return false;
  }
  const conditions = getConditions(party, type);

  return conditions.length > 0 && hasStatus(party, type);
};

export const isOwnerSigned = (state: RootState): boolean =>
  isPartySigned(getOwnerParty(state), WorkflowConditionType.Signature);
export const isCounterpartySigned = (state: RootState): boolean =>
  isPartySigned(getCounterParty(state), WorkflowConditionType.Signature);

const { getAllParticipantsIds, getMyRole } = documentSelectorsFactory(getDocument);

const editsEligibleRoles = [DocumentRole.Owner, DocumentRole.Manager, DocumentRole.Editor];

export const isAllowedToEdit = (state: RootState): boolean =>
  editsEligibleRoles.includes(getMyRole(state));

export const isAllowedToSign = (state: RootState): boolean => {
  const conditions = getConditions(getMyParty(state)!, WorkflowConditionType.Signature);
  const myId = getUserId(state);
  return conditions.some((condition) => condition.userId === myId);
};

export const isAllowedToAcknowledge = (state: RootState): boolean => {
  const conditions = getConditions(getMyParty(state)!, WorkflowConditionType.Acknowledge);
  const myId = getUserId(state);
  return conditions.some((condition) => condition.userId === myId);
};

const getPartySignatures = (
  party: WorkflowParty | undefined,
  user: ObjectsContainer,
  profile: ObjectsContainer,
  avatar: ObjectsContainer,
): UserSignature[] => {
  if (!party || !party.conditions) return [];

  const userConditions = party.conditions.filter((c) =>
    hasStatus(party, WorkflowConditionType.Signature, c.userId),
  );
  const userBaseObjects = userConditions.map(({ userId }) => ({
    id: userId,
    type: DbRecordType.User,
  }));
  const userCompleteObjects = getObjects<UserCompleteModel>(userBaseObjects, {
    [DbRecordType.User]: user,
    [DbRecordType.Profile]: profile,
    [DbRecordType.Avatar]: avatar,
  });

  return createUserSignaturesFromUserConditions(userConditions, userCompleteObjects);
};

export const getOwnerSideSignatures = createSelector(
  getOwnerParty,
  getUsers,
  getProfiles,
  getAvatars,
  getPartySignatures,
);

export const getCounterPartySideSignatures = createSelector(
  getCounterParty,
  getUsers,
  getProfiles,
  getAvatars,
  getPartySignatures,
);

export const getAcknowledges = createSelector(
  getMyParty,
  getUsers,
  getProfiles,
  getAvatars,
  (party, user, profile, avatar) => {
    if (!party || !party.conditions) return [] as UserSignature[];

    const baseParticipantsObject = party.conditions.map((p) => {
      return hasStatus(party, WorkflowConditionType.Acknowledge, p.userId)
        ? { id: p.userId, type: 'user' }
        : {};
    }) as ObjectBase[];

    return getUsersWithSignatures(
      baseParticipantsObject,
      user,
      profile,
      avatar,
    ) as UserAcknowledge[];
  },
);

const getUsersWithSignatures = (
  baseUsers: ObjectBase[],
  user: ObjectsContainer,
  profile: ObjectsContainer,
  avatar: ObjectsContainer,
) => {
  const userObjects = getObjects(baseUsers, {
    user,
    profile,
    avatar,
  }) as SidebarParticipantEntity[];
  return userObjects.map((u) => {
    const profile = getProfile(u);

    return {
      userId: u.id,
      firstName: profile.firstName,
      lastName: profile.lastName,
      avatarUrl: getAvatarUrlByProfile(profile),
      signatureUrl: profile.signature,
    };
  });
};

export const getIsPreviewFile = createSelector(getPreviewDocument, (document) => {
  if (!document) return false;

  return document.contentType === ContentType.File;
});

export const getIsFile = createSelector(getDocument, (document) => {
  if (!document) return false;

  return document.contentType === ContentType.File;
});

export const getAccessLevel = createSelector(getDocument, (document) => {
  if (document && !isEmpty(document.accessLevel)) {
    return document.accessLevel as AccessLevel;
  }
});

export const getFile = createSelector(getDocument, (document) => {
  if (document && !isEmpty(document.contentMetadata)) {
    const metadata = document.contentMetadata as ContentMetadata;
    const { url, key, type, shortUrl } = metadata;

    return {
      url,
      shortUrl,
      name: last(key.split('/')) || '',
      extension:
        (lowerCase(last(key.split('.'))) as UploadedEntityExtension) || UploadedEntityExtension.png,
      type,
    };
  }

  return {
    url: '',
    shortUrl: '',
    name: '',
    extension: UploadedEntityExtension.png,
    type: MimeType.pdf,
  };
});

export const getPreviewFile = createSelector(getPreviewDocument, (document) => {
  if (document && !isEmpty(document.contentMetadata)) {
    const metadata = document.contentMetadata as ContentMetadata;
    const { url, key, type, shortUrl } = metadata;

    return {
      url,
      shortUrl,
      name: last(key.split('/')) || '',
      extension:
        (lowerCase(last(key.split('.'))) as UploadedEntityExtension) || UploadedEntityExtension.png,
      type,
    };
  }

  return {
    url: '',
    shortUrl: '',
    name: '',
    extension: UploadedEntityExtension.png,
    type: MimeType.pdf,
  };
});

const isNegotiable = (state: RootState): boolean =>
  getWorkflowType(state) === WorkflowType.Negotiation;

const sidesMatch = createSelector(getOwnerSections, getCounterpartySections, areSidesEqual);

export const isReadyToSignVisible = createSelector(
  isNegotiable,
  sidesMatch,
  getMyRole,
  isInWork,
  isOwnerParty,
  getOwnerSections,
  (isNegotiable, match, role, isInWork, isOwnerParty, ownerSections): boolean => {
    return (
      isNegotiable &&
      match &&
      isInWork &&
      isOwnerParty &&
      ownerSections.length > 0 &&
      (role === DocumentRole.Owner || role === DocumentRole.Manager)
    );
  },
);

/**
 * Sagas
 */

export function* fetchDocumentQRSaga(action: PayloadAction<FetchEntityByQRActionPayload>) {
  try {
    const { entityId } = action.payload;
    const navigateToDocumentDetails = navigateToDocumentDetailsFactory(history);
    const document = yield call(fetchDocumentQRCodeAPI, entityId);
    yield put(storeData(document));
    navigateToDocumentDetails(entityId, get(document, 'data[0].attributes.type'));
  } catch (error) {
    const { onFetchFailed } = action.payload;
    const toastrOptions = {
      onHideComplete: onFetchFailed,
      onCloseButtonClick: onFetchFailed,
    };
    const { title, detail } = responseErrorExtract(error);
    toastr.error(title, detail, toastrOptions);
  }
}

function* fetchDocumentSaga(action: PayloadAction<string>) {
  try {
    yield call(documentUnsubscribeSaga);
    yield put(fetchDocumentStart());
    yield put(clearWorkflow());
    const document = yield call(fetchDocumentApi, action.payload);
    yield put(storeNewData({ ...document, entityType: document.type, type: DbRecordType.Paper }));
    yield put(fetchDocumentSuccess(document));
    yield call(documentSubscribeSaga, document.id);
  } catch (error) {
    yield put(fetchDocumentFailed(error.toString()));
  }
}

function* fetchDocumentInfoSaga(action: PayloadAction<string>) {
  try {
    yield call(documentUnsubscribeSaga);
    yield put(fetchDocumentStart());
    yield put(clearWorkflow());
    const document = yield call(fetchDocumentInfoApi, action.payload);
    yield put(storeNewData({ ...document, entityType: document.type, type: DbRecordType.Paper }));
    yield put(fetchDocumentSuccess(document));
    yield call(documentSubscribeSaga, document.id);
  } catch (error) {
    yield put(fetchDocumentFailed(error.toString()));
  }
}

function* insertSectionSaga(action: PayloadAction<InsertSectionPayload>) {
  try {
    const { entityId, index } = action.payload;
    yield call(insertSectionApi, entityId, index, '', []);
    const document = yield call(fetchDocumentApi, entityId);
    yield put(storeNewData({ ...document, entityType: document.type, type: DbRecordType.Paper }));
    yield put(fetchWorkflow(entityId));
    yield call(resolvePromiseAction, action);
    yield put(lockSection(index));
  } catch (err) {
    toastr.error('Error', 'Insert term request has failed');
    yield call(rejectPromiseAction, action, new Error());
  }
}

function* updateSectionSaga(action: PayloadAction<UpdateSectionPayload>) {
  try {
    const { entityId, index, content } = action.payload;
    const fields = getContentFields(content);
    yield call(updateSectionApi, entityId, index, content, fields);
    const document = yield call(fetchDocumentApi, entityId);
    yield put(storeNewData({ ...document, entityType: document.type, type: DbRecordType.Paper }));
    yield put(fetchWorkflow(entityId));
    yield call(resolvePromiseAction, action);
  } catch (err) {
    toastr.error('Error', 'Update term request has failed');
    yield call(rejectPromiseAction, action, new Error());
  }
}

function* moveSectionSaga(action: PayloadAction<MoveSectionPayload>) {
  try {
    const { entityId, source, target } = action.payload;
    const entity = yield call(moveSectionApi, entityId, source, target);
    yield put(storeData(entity));
    yield put(fetchWorkflow(entityId));
    yield call(resolvePromiseAction, action);
  } catch (err) {
    toastr.error('Error', 'Move term request has failed');
    yield call(rejectPromiseAction, action, new Error());
  }
}

function* removeSectionSaga(action: PayloadAction<RemoveSectionPayload>) {
  try {
    const { entityId, index } = action.payload;
    yield call(removeSectionApi, entityId, index);
    const document = yield call(fetchDocumentApi, entityId);
    yield put(storeNewData({ ...document, entityType: document.type, type: DbRecordType.Paper }));
    yield put(fetchWorkflow(entityId));
    yield call(resolvePromiseAction, action);
  } catch (err) {
    toastr.error('Error', 'Remove term request has failed');
    yield call(rejectPromiseAction, action, new Error());
  }
}

function* uploadFileSaga(action: PayloadAction<UploadFilePayload>) {
  try {
    const { entityId, file } = action.payload;
    const data = yield call(uploadFileApi, entityId, file);
    yield call(resolvePromiseAction, action, data);
  } catch (err) {
    toastr.error('Error', 'Upload file has failed');
    yield call(rejectPromiseAction, action);
  }
}

function* modifyFileSaga(action: PayloadAction<ModifyFilePayload>) {
  try {
    const { entityId, file } = action.payload;
    yield call(modifyFileApi, entityId, file);
    yield call(toastr.success, 'Success', 'Your file has been saved');
  } catch (err) {
    yield call(toastr.error, 'Error', 'Save file has failed');
  }
}

function* uploadFileByUrlSaga(action: PayloadAction<UploadFileByUrlPayload>) {
  try {
    const { entityId, url } = action.payload;
    const data = yield call(uploadFileByUrlApi, entityId, url);
    yield call(resolvePromiseAction, action, data);
  } catch (err) {
    toastr.error('Error', 'Upload file by URL has failed');
    yield call(rejectPromiseAction, action);
  }
}

function* workflowActionSaga({ payload }: PayloadAction<WorkflowActionPayload>) {
  try {
    yield put(startFieldUpdate());
    const { action, payload: requestPayload } = payload;
    const document = yield select(getDocument);
    const entity = yield call(workflowActionApi, document.id, action, requestPayload);
    yield put(storeData(entity));
    yield put(finishFieldUpdate());
  } catch (err) {
    toastr.error('Error', 'Workflow action request has failed');
    yield put(finishFieldUpdate());
  }
}

function* updateFieldSaga(action: PayloadAction<UpdateFieldPayload>) {
  try {
    yield put(startFieldUpdate());
    const documentId = yield select(getDocumentId);
    const { index, content, updateType, fieldId, fieldValue } = action.payload;
    const entity = yield call(
      updateFieldApi,
      documentId,
      index,
      content,
      updateType,
      fieldId,
      fieldValue,
    );
    yield put(storeData(entity));
    yield call(resolvePromiseAction, action);
    yield put(finishFieldUpdate());
  } catch (err) {
    const { title, detail } = responseErrorExtract(err, 'Update field request has failed');
    toastr.error(title, detail);
    yield call(rejectPromiseAction, action, new Error());
    yield put(finishFieldUpdate());
  }
}

function* updateFieldsSaga(action: PayloadAction<{ fields: UpdateFieldPayload[] }>) {
  try {
    yield put(startFieldUpdate());
    const documentId = yield select(getDocumentId);
    const { fields } = action.payload;
    const entity = yield call(updateFieldsApi, documentId, fields);
    yield put(storeData(entity));
    yield call(resolvePromiseAction, action);
    yield put(finishFieldUpdate());
  } catch (err) {
    const { title, detail } = responseErrorExtract(err, 'Update field request has failed');
    toastr.error(title, detail);
    yield call(rejectPromiseAction, action, new Error());
    yield put(finishFieldUpdate());
  }
}

function* readyToSignSaga() {
  try {
    const documentId = yield select(getDocumentId);
    const workflow = yield call(readyToSignApi, documentId);
    yield put(storeData(workflow));
    toastr.success('Success', 'The contract can now be signed!');
  } catch (err) {
    const { title, detail } = responseErrorExtract(err, 'Ready to sign request has failed');
    toastr.error(title, detail);
  }
}

// ws sagas
function* documentContentSaga({ payload }: PayloadAction<WSObjectSerialized>): Generator {
  yield put(storeData({ ...payload, data: [] })); // eliminating document data to avoid constant refresh

  yield call(syncActiveLock, payload);
}

function* syncActiveLock(data: WSObjectSerialized): Generator {
  const activeLock = (yield select(getActiveLock)) as EntityLock;
  if (!activeLock) {
    return;
  }

  const lockFromData = getObject<EntityLock>(
    { id: activeLock.id, type: 'lock' },
    getObjectsMap(data.included),
  );

  yield put(setActiveLock(lockFromData));
}

function* documentSubscribeSaga(documentId: string): Generator {
  try {
    yield call(sendWSCommand, {
      command: DocumentWSCommand.Subscribe,
      payload: { documentId },
    });
    yield call(sendWSCommand, {
      command: WorkflowWSCommand.Subscribe,
      payload: { documentId },
    });
  } catch (err) {
    console.error('documentSubscribeSaga: ', err);
  }
}

function* documentUnsubscribeSaga(): Generator {
  const state = (yield select(getState)) as DocumentState;
  const docId = state.data ? state.data.id : null;
  if (docId) {
    yield call(sendWSCommand, {
      command: DocumentWSCommand.Unsubscribe,
      payload: { documentId: docId },
    });
    yield call(sendWSCommand, {
      command: WorkflowWSCommand.Unsubscribe,
      payload: { documentId: docId },
    });
  }
}

function* changeLockSectionSaga(command: DocumentWSCommand, index: number): Generator {
  const doc = (yield select(getDocument)) as Document;
  const sections = (yield select(getSections)) as Section[];
  const section = sections[index];

  try {
    if (!section) return;
    yield call(sendWSCommand, {
      command,
      payload: {
        documentId: doc.id,
        path: [index],
        termId: section.id,
        commitId: get(doc, 'entity[0].currentCommit'),
      },
    });
  } catch (err) {
    console.error('lockSectionSaga: ', err);
  }
}

function* lockSectionSaga({ payload }: PayloadAction<number>): Generator {
  const activeLock = (yield select(getActiveLock)) as EntityLock | null;
  if (activeLock) {
    if (activeLock.path[0] !== payload) {
      yield put(unlockSection(activeLock.path[0]));
    }
  }

  yield call(changeLockSectionSaga, DocumentWSCommand.Lock, payload);
}

function* unlockSectionSaga({ payload }: PayloadAction<number>): Generator {
  yield put(setActiveLock(null));
  yield call(changeLockSectionSaga, DocumentWSCommand.Unlock, payload);
}

function* socketConnectedSaga({ payload }: PayloadAction<SocketConnectedPayload>): Generator {
  if (payload.connectionId !== SocketConnectionId.Document || payload.initialConnection) return;

  const state = (yield select(getState)) as DocumentState;
  if (state.data && state.data.id) {
    yield call(documentSubscribeSaga, state.data.id);
  }
}

function* documentLockedSaga({ payload }: PayloadAction<WSObjectSerialized>) {
  const lock = getObject<EntityLock>(
    ObjectSerialized.dataToBaseObjects(payload.data)[0],
    getObjectsMap(payload.data, payload.included),
  );

  yield put(setActiveLock(lock));
}

function* workflowContentSaga({ payload }: PayloadAction<WSObjectSerialized>) {
  yield put(storeData(payload));

  const ids = yield select(getAllParticipantsIds);
  const users = yield call(fetchUsers, ids);
  yield put(storeData(users));
}

function* refreshActiveLockSaga() {
  while (true) {
    const activeLock = yield select(getActiveLock);

    if (activeLock) {
      yield put(lockSection(activeLock.path[0]));
    }

    yield delay(REFRESH_LOCK_INTERVAL);
  }
}

function* fetchDocumentPreviewSaga(action: PayloadAction<FetchEntityByQRActionPayload>) {
  try {
    const { entityId } = action.payload;
    yield put(fetchDocumentPreviewStart());
    yield put(clearWorkflow());
    const document = yield call(fetchDocumentPreviewAPI, entityId);
    yield put(storeData(document));
    yield put(fetchDocumentPreviewSuccess(document));
  } catch (error) {
    yield put(fetchDocumentPreviewFailed(error.toString()));
  }
}

export function* watchDocumentDetailsSagas() {
  yield takeLatest(fetchDocument, fetchDocumentSaga);
  yield takeLatest(fetchDocumentInfo, fetchDocumentInfoSaga);
  yield takeLatest(insertSection, insertSectionSaga);
  yield takeLatest(updateSection, updateSectionSaga);
  yield takeLatest(moveSection, moveSectionSaga);
  yield takeLatest(removeSection, removeSectionSaga);
  yield takeLatest(uploadFile, uploadFileSaga);
  yield takeLatest(modifyFile, modifyFileSaga);
  yield takeEvery(uploadFileByUrl, uploadFileByUrlSaga);
  yield takeLatest(workflowAction, workflowActionSaga);
  yield takeLatest(updateField, updateFieldSaga);
  yield takeLatest(updateFields, updateFieldsSaga);
  yield takeLatest(readyToSign, readyToSignSaga);
  yield takeLatest(fetchDocumentQR, fetchDocumentQRSaga);
  yield takeLatest(fetchDocumentPreview, fetchDocumentPreviewSaga);
  // input from ws
  yield takeLatest(documentContent, documentContentSaga);
  yield takeLatest(lockSection, lockSectionSaga);
  yield takeLatest(unlockSection, unlockSectionSaga);
  yield takeLatest(socketConnected, socketConnectedSaga);
  yield takeLatest(documentLocked, documentLockedSaga);
  yield takeLatest(workflowContent, workflowContentSaga);

  // watch activeLock and refresh it
  yield fork(refreshActiveLockSaga);
}

export default documentDetails.reducer;

export const initializeDocumentStore = (): void => {
  listenWSEvents(documentContent, documentLocked, workflowContent);
};
