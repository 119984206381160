export interface CommonResponseError {
  title: string;
  detail: string;
  errorCode: string;
  responseCode: number;
}

export interface ErrorMessage {
  title: string;
  detail: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type GenericFunction = <T>(...args: any) => any;

export function castType<T>(fn: GenericFunction) {
  return (...args) => fn<T>(...args);
}
