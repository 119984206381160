import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Text } from '@theme-ui/components';
import { isUndefined } from 'lodash';
import cn from 'classnames';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { mergeStyles } from 'src/utils/styles';
import { WizardLayout } from 'src/v2/features/layout/wizard/WizardLayout';
import { Button } from 'src/v2/components/Button';
import { getIsLoading } from 'src/shared/auth/authSelectors';

const styles = {
  container: {
    p: '5% 10%',
    '@media (max-width: 600px)': {
      p: '0',
    },
  },

  stepper: {
    margin: '0 auto',
    padding: '20px 28px',
    width: '100%',
    maxWidth: '780px',
    minHeight: '770px',
    backgroundColor: '#E8E4D9',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '@media (max-width: 600px)': {
      minHeight: '500px',
    },
  },

  controlBar: {
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    text: {
      textAlign: 'center',
      font: 'Bold 20px/25px Helvetica Neue',
      letterSpacing: '0px',
      color: '#2E4354',
    },
    back: {
      borderColor: '#2E4354',
      backgroundColor: '#2E4354',
      visibility: 'hidden',
    },
    next: {
      borderColor: '#29CB97',
      backgroundColor: '#29CB97',
      color: '#2E4354',
      visibility: 'hidden',
    },
    skip: {
      color: '#d57872',
      textDecoration: 'underline',
      cursor: 'pointer',
      mr: '26px',
      display: 'none',
    },
  },

  visible: {
    display: 'block',
  },
};

export interface Props {
  step?: number;
  maxSteps?: number;
  onBack?: () => void;
  onNext?: () => void;
  onSkip?: () => void;
  isValid?: boolean;
}

export const LayoutWithStepper: React.FC<Props> = ({
  children,
  step,
  maxSteps,
  onBack,
  onNext,
  onSkip,
  isValid = true,
}) => {
  const handleBack = useCallback(() => {
    if (onBack) onBack();
  }, [onBack]);

  const handleNext = useCallback(() => {
    if (onNext && isValid) {
      onNext();
    }
  }, [onNext, isValid]);

  const isLoading = useSelector(getIsLoading);

  const handleSkip = useCallback(() => {
    if (onSkip) onSkip();
  }, [onSkip]);

  const showSkip = useMemo(
    () => !isUndefined(onSkip) && step !== maxSteps,
    [onSkip, step, maxSteps],
  );

  return (
    <WizardLayout startStep={isUndefined(step) || isUndefined(maxSteps)}>
      <div className="l-container">
        {!isUndefined(step) && !isUndefined(maxSteps) ? (
          <div className="c-setup">
            <>{children}</>
            <div className="c-setup__bottom">
              <button
                className={cn('c-button c-button--uppercase', {
                  'c-button--invisible': isUndefined(onBack),
                })}
                onClick={handleBack}
              >
                Back
              </button>
              <div className="c-setup__step" data-type="step-label">
                Step {step} of {maxSteps}
              </div>
              <Box sx={styles.controlBar.container}>
                <Text
                  data-type="step-skip-button"
                  sx={mergeStyles(styles.controlBar.skip, [styles.visible, showSkip])}
                  onClick={handleSkip}
                >
                  SKIP
                </Text>

                <Button
                  data-type="step-next-button"
                  className="c-button c-button--uppercase c-button--green"
                  onClick={handleNext}
                  disabled={!isValid}
                  isLoading={isLoading}
                >
                  {step !== maxSteps
                    ? i18n(translationKeys.buttons.next)
                    : i18n(translationKeys.buttons.finish)}
                </Button>
              </Box>
            </div>
          </div>
        ) : (
          children
        )}
      </div>
    </WizardLayout>
  );
};
