import { includes } from 'lodash';
import { ContractRole } from 'src/models/contract';

export interface UserWithRole {
  id: string;
  role: ContractRole;
}

export const canAddUserWithManagerRole = (actorRole: ContractRole): boolean =>
  includes([ContractRole.Manager, ContractRole.Owner], actorRole);

export const canEditUserWithManagerRole = (actorRole: ContractRole): boolean =>
  includes([ContractRole.Manager, ContractRole.Owner], actorRole);

export const canAddUserWithEditorRole = (actorRole: ContractRole): boolean =>
  includes([ContractRole.Manager, ContractRole.Owner, ContractRole.Editor], actorRole);

export const canEditUserWithEditorRole = (actorRole: ContractRole): boolean =>
  includes([ContractRole.Manager, ContractRole.Owner, ContractRole.Editor], actorRole);

export const canAddUserWithSignatoryRole = (actorRole: ContractRole): boolean =>
  includes(
    [ContractRole.Manager, ContractRole.Owner, ContractRole.Signatory, ContractRole.Editor],
    actorRole,
  );

export const canEditUserWithSignatoryRole = (actorRole: ContractRole): boolean =>
  includes([ContractRole.Manager, ContractRole.Owner, ContractRole.Editor], actorRole);

export const canAddUserWithCommentatorRole = (actorRole: ContractRole): boolean =>
  includes(
    [
      ContractRole.Manager,
      ContractRole.Owner,
      ContractRole.Signatory,
      ContractRole.Commentator,
      ContractRole.Editor,
    ],
    actorRole,
  );

export const canEditUserWithCommentatorRole = (actorRole: ContractRole): boolean =>
  includes([ContractRole.Manager, ContractRole.Owner, ContractRole.Editor], actorRole);

export const canEditUserWithQRViewerRole = (actorRole: ContractRole): boolean =>
  includes([ContractRole.Manager, ContractRole.Owner, ContractRole.Editor], actorRole);

export const canAddUserWithViewerRole = (actorRole: ContractRole): boolean =>
  includes(
    [
      ContractRole.Manager,
      ContractRole.Owner,
      ContractRole.Signatory,
      ContractRole.Commentator,
      ContractRole.Viewer,
      ContractRole.Editor,
    ],
    actorRole,
  );

export const canEditUserWithViewerRole = (actorRole: ContractRole): boolean =>
  includes([ContractRole.Manager, ContractRole.Owner, ContractRole.Editor], actorRole);

export const canAddUserWithSharedViewerRole = (actorRole: ContractRole): boolean =>
  includes(
    [
      ContractRole.Manager,
      ContractRole.Owner,
      ContractRole.Signatory,
      ContractRole.Commentator,
      ContractRole.Viewer,
    ],
    actorRole,
  );

export const canEditUserWithSharedViewerRole = (): boolean => false;

export const canRemoveUserWithSharedViewerRole = (actorRole: ContractRole): boolean =>
  includes([ContractRole.Manager, ContractRole.Owner], actorRole);

const canAddFnByRole: Record<ContractRole, (role: ContractRole) => boolean> = {
  [ContractRole.Owner]: () => false,
  [ContractRole.Manager]: canAddUserWithManagerRole,
  [ContractRole.Editor]: canAddUserWithEditorRole,
  [ContractRole.Signatory]: canAddUserWithSignatoryRole,
  [ContractRole.Commentator]: canAddUserWithCommentatorRole,
  [ContractRole.QRViewer]: () => false,
  [ContractRole.Viewer]: canAddUserWithViewerRole,
  [ContractRole.SharedViewer]: canAddUserWithSharedViewerRole,
  [ContractRole.DocumentClippedViewer]: () => false,
  [ContractRole.Downloader]: () => false,
  [ContractRole.DocumentSharer]: () => false,
  [ContractRole.DocumentSharerDownloader]: () => false,
};

const canEditFnByRole: Record<ContractRole, (role: ContractRole) => boolean> = {
  [ContractRole.Owner]: () => false,
  [ContractRole.Manager]: canEditUserWithManagerRole,
  [ContractRole.Editor]: canEditUserWithEditorRole,
  [ContractRole.Signatory]: canEditUserWithSignatoryRole,
  [ContractRole.Commentator]: canEditUserWithCommentatorRole,
  [ContractRole.QRViewer]: canEditUserWithQRViewerRole,
  [ContractRole.Viewer]: canEditUserWithViewerRole,
  [ContractRole.SharedViewer]: canEditUserWithSharedViewerRole,
  [ContractRole.DocumentClippedViewer]: () => false,
  [ContractRole.Downloader]: () => false,
  [ContractRole.DocumentSharer]: () => false,
  [ContractRole.DocumentSharerDownloader]: () => false,
};

export const canAddUser = (actorRole: ContractRole, targetRole: ContractRole): boolean =>
  canAddFnByRole[targetRole](actorRole);

const canEditContactUser = (actorRole: ContractRole, invitedByActor: boolean): boolean =>
  includes([ContractRole.Owner, ContractRole.Manager], actorRole) ||
  (includes([ContractRole.Editor, ContractRole.Signatory, ContractRole.Commentator], actorRole) &&
    invitedByActor);

export const canEditUser = (
  actor: UserWithRole,
  target: UserWithRole,
  invitedByActor = true,
): boolean =>
  actor.id !== target.id &&
  canEditContactUser(actor.role, invitedByActor) &&
  canEditFnByRole[target.role] &&
  canEditFnByRole[target.role](actor.role);

export const canEditUserPermissions = (
  actor: UserWithRole,
  target: UserWithRole,
  invitedByActor = true,
): boolean =>
  !includes(
    [
      ContractRole.QRViewer,
      ContractRole.Downloader,
      ContractRole.DocumentSharer,
      ContractRole.DocumentSharerDownloader,
      ContractRole.DocumentClippedViewer,
    ],
    target.role,
  ) && canEditUser(actor, target, invitedByActor);

const canRemoveFnByRole: Record<ContractRole, (role: ContractRole) => boolean> = {
  ...canEditFnByRole,
  [ContractRole.SharedViewer]: canRemoveUserWithSharedViewerRole,
};

export const canRemoveUser = (actor: UserWithRole, target: UserWithRole): boolean =>
  actor.id !== target.id && canRemoveFnByRole[target.role](actor.role);
