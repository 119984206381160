import { includes } from 'lodash';

import { SubscriptionPlan } from 'src/models/billing';

export const hasTemplateFeature = (plan: SubscriptionPlan | null | undefined) => true;

export const hasQRCodeFeature = (plan: SubscriptionPlan | null | undefined) =>
  includes(
    [
      SubscriptionPlan.Basic,
      SubscriptionPlan.Premium,
      SubscriptionPlan.Business,
      SubscriptionPlan.RealEstate,
      SubscriptionPlan.Enterprise,
      SubscriptionPlan.Vip,
    ],
    plan,
  );

export const hasPreBuiltTemplatesFeature = (plan: SubscriptionPlan | null | undefined) =>
  includes(
    [
      SubscriptionPlan.Standard,
      SubscriptionPlan.Business,
      SubscriptionPlan.RealEstate,
      SubscriptionPlan.Enterprise,
      SubscriptionPlan.Vip,
      SubscriptionPlan.Basic,
      SubscriptionPlan.Premium,
    ],
    plan,
  );

export const hasUpdatePlanFeature = (plan: SubscriptionPlan | null | undefined) =>
  includes(
    [
      SubscriptionPlan.Basic,
      SubscriptionPlan.Premium,
      SubscriptionPlan.Business,
      SubscriptionPlan.RealEstate,
    ],
    plan,
  );

export const hasGetPaymentCardFeature = (plan: SubscriptionPlan | null | undefined) =>
  SubscriptionPlan.Premium !== plan;

export const hasNavigationFeature = (plan: SubscriptionPlan | null | undefined) =>
  includes(
    [
      SubscriptionPlan.Standard,
      SubscriptionPlan.Business,
      SubscriptionPlan.RealEstate,
      SubscriptionPlan.Enterprise,
      SubscriptionPlan.Vip,
      SubscriptionPlan.Basic,
      SubscriptionPlan.Premium,
    ],
    plan,
  );
