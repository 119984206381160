import { FC, PropsWithChildren, useContext } from 'react';

import { StaticSettingsContext } from 'src/common/contexts/StaticSettingsContext';
import { StaticSettings } from 'src/common/types';

import { Logo } from './components/Logo';

interface Props {
  className?: string;
}

export const PreviewHeader: FC<Props> = ({ className }: PropsWithChildren<Props>): JSX.Element => {
  const { logoUrl } = useContext<StaticSettings>(StaticSettingsContext);
  return (
    <header className={className}>
      <a className="c-logo" href={logoUrl}>
        <Logo />
      </a>
    </header>
  );
};
