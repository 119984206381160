import React, { useCallback, useContext } from 'react';
import { Flex, Box, Text, Button } from '@theme-ui/components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isNull } from 'lodash';

import { book } from 'src/app/book';
import { SidebarSettingsContext } from 'src/common/contexts/SidebarSettingsContext';
import { SidebarSettings } from 'src/common/types';
import { BackTitleAndActions as PureBackTitleAndActions } from 'src/v2/modules/layout';
import { readyToSign, getDocument } from 'src/v2/features/documentDetails/documentDetailsSlice';
import { Tooltip, TooltipPosition } from 'src/v2/features/Tooltip';
import { useToggle } from 'src/common/hooks/useToggle';
import { useShowDiffHandler } from 'src/v2/features/sharedEntity';
import { hideSidebar, showSidebar } from 'src/common/store';

interface Props {
  documentId: string | null;
  isReadyToSignVisible: boolean;
  isNegotiable?: boolean;
  isFile?: boolean;
  backToDoc?: boolean;
}

const styles = {
  signatureBtn: {
    position: 'relative',
  },

  signatureIcon: {
    cursor: 'pointer',
    height: '32px',
  },

  signContractPopup: {
    minWidth: '300px',
  },

  btnContainer: {
    mt: '20px',
    alignItems: 'flex-start',
  },

  confirmBtn: {
    color: 'white',
    backgroundColor: '#29cb97',
    border: 'none',
    borderRadius: '5px',
  },

  cancelBtn: {
    color: 'white',
    backgroundColor: '#d57872',
    border: 'none',
    borderRadius: '5px',
    ml: '10px',
  },
};

export const BackTitleAndActions: React.FC<Props> = ({
  documentId,
  isNegotiable = false,
  isFile = false,
  isReadyToSignVisible,
  backToDoc = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const document = useSelector(getDocument);
  const isDocumentLoaded = !isNull(document);

  const showDiff = () => {
    dispatch(hideSidebar());
    navigate(`${book.contracts.pattern}/${documentId}/negotiation`);
  };
  const handleClickDiff = useShowDiffHandler(showDiff);

  const handleBackToDoc = () => {
    dispatch(showSidebar());
    navigate(`${book.contracts.pattern}/${documentId}`);
  };
  const [isTooltipOpen, , openTooltip, closeTooltip] = useToggle();
  const onClose = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      closeTooltip();
    },
    [closeTooltip],
  );

  const onOpen = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      openTooltip();
    },
    [openTooltip],
  );

  const signContract = useCallback(
    (e: React.MouseEvent<HTMLElement>): void => {
      e.preventDefault();
      e.stopPropagation();
      dispatch(readyToSign());
      closeTooltip();
    },
    [dispatch, closeTooltip],
  );
  let restProps = {};
  if (isDocumentLoaded && isNegotiable && !isFile)
    restProps = { ...restProps, onClickDiff: handleClickDiff };
  if (isDocumentLoaded && isReadyToSignVisible) restProps = { ...restProps, onClickSign: onOpen };
  if (isDocumentLoaded && backToDoc && !isFile)
    restProps = { ...restProps, onClickBackFromDiff: handleBackToDoc, onClickDiff: null };

  const { isHidden, toggleIsHidden } = useContext<SidebarSettings>(SidebarSettingsContext);

  return (
    <PureBackTitleAndActions
      onClickEnlarge={toggleIsHidden}
      isEnlargeActive={isHidden}
      {...restProps}
    >
      <Tooltip isOpen={isTooltipOpen} position={TooltipPosition.Bottom}>
        <Box sx={styles.signContractPopup} data-type="ready-to-sign-confirmation-popup">
          <Text>
            <b>You are about to finalize your contract. Do you wish to continue?</b>
          </Text>
          <Flex sx={styles.btnContainer}>
            <Button
              sx={styles.confirmBtn}
              onClick={signContract}
              data-type="ready-to-sign-confirm-btn"
            >
              YES
            </Button>
            <Button sx={styles.cancelBtn} onClick={onClose} data-type="ready-to-sign-cancel-btn">
              NO
            </Button>
          </Flex>
        </Box>
      </Tooltip>
    </PureBackTitleAndActions>
  );
};
