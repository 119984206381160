import React, { FC, useCallback, useContext, useState } from 'react';
import { Text } from '@theme-ui/components';
import { toastr } from 'react-redux-toastr';

import { FormHeader } from 'src/v2/features/signUp/components/FormHeader';
import { Input } from 'src/v2/components/Form/Input';
import { mergeStyles } from 'src/utils/styles';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { TextSignatureContext } from 'src/v2/features/signature/contexts/TextSignatureContext';
import { FileSignatureContext } from 'src/v2/features/signature/contexts/FileSignatureContext';

import { FontConfig } from '../../types';
import { UploadImage } from '../UploadImage';
import { normalizeKey } from '../../utils';

const signatureSupportedFormats = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];

interface Props {
  signatureFonts: FontConfig[];
  signaturePlaceholder: string;
}

const signatureStyle = {
  fontSize: '42px',
  px: ' 25px 15px',
  py: '0',
};

export const CommonSignature: FC<Props> = ({ signatureFonts, signaturePlaceholder, children }) => {
  const { text, font: currentFont, updateText, updateFont } = useContext(TextSignatureContext);
  const { signatureId, setSignatureFile, removeSignatureFile } = useContext(FileSignatureContext);

  const [isFileUploaded, setFileUploaded] = useState(false);

  const handleFontChange = useCallback((event) => updateFont(event.target.value), [updateFont]);

  const handleSignatureTextChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>): void => updateText(event.currentTarget.value),
    [updateText],
  );

  const onUpload = (payload) => {
    if (!signatureSupportedFormats.includes(payload.type)) {
      toastr.error(` ${i18n(translationKeys.errors.FILE_FORMAT_ERROR)}`, '');
    } else {
      setSignatureFile(payload);
      setFileUploaded(true);
    }
  };
  const onDelete = () => {
    removeSignatureFile();
    setFileUploaded(false);
  };

  const signatureTypeClass = isFileUploaded ? 'c-signature__type--disabled' : '';

  return (
    <>
      <div className="c-setup__top">
        <FormHeader
          title={i18n(translationKeys.forms.signature.upload)}
          description={i18n(translationKeys.forms.item.createIndustry)}
        />
      </div>
      <div className="c-setup__content">
        <div className="c-signature">
          <div
            data-id="profile-container-selectSignatureFont"
            className={`c-signature__type ${signatureTypeClass}`}
          >
            <div className="c-title c-title--uppercase c-title--color-theme h-uppercase">
              {i18n(translationKeys.forms.signature.typeName)}
            </div>
            <Input
              onChange={handleSignatureTextChange}
              placeholder={signaturePlaceholder}
              value={text || ''}
            />
            <div className="c-title c-title--uppercase c-title--color-theme h-uppercase">
              {i18n(translationKeys.forms.signature.chooseStyle)}
            </div>
            {signatureFonts.map((font) => {
              return (
                <div key={font.name} className="c-checkbox c-checkbox--signature">
                  <label className="c-checkbox__label">
                    <input
                      type="checkbox"
                      value={font.name}
                      data-type="font1-checkbox"
                      onChange={handleFontChange}
                      checked={currentFont === font.name}
                      hidden
                    />
                    <span className="c-checkbox__icon" />
                    <span className="c-checkbox__img">
                      <Text data-type="font1-text" sx={mergeStyles(signatureStyle, font.style)}>
                        {text || i18n(translationKeys.forms.signature.yourSignature)}
                      </Text>
                    </span>
                  </label>
                </div>
              );
            })}
          </div>
          <UploadImage
            key={normalizeKey(signatureId)}
            onUpload={onUpload}
            onDelete={onDelete}
            titleProps={{
              'data-id': 'profile-container-createSignature',
            }}
          >
            {i18n(translationKeys.forms.signature.uploadExisting)}
          </UploadImage>
          {children}
        </div>
      </div>
    </>
  );
};
