import { PayloadAction } from '@reduxjs/toolkit';
import { toastr } from 'react-redux-toastr';
import { call, put, select, takeLatest, takeEvery } from 'redux-saga/effects';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { handleErrorSaga } from 'src/utils/handleErrorSaga';
import { emitRemoveParticipant } from 'src/v2/features/sharedEntity/entityEventBus';
import { DownloadTemplateAsPDFDTO } from 'src/v2/boundary/requestDTO/template';

import { SagaIterator } from 'redux-saga';
import { resolvePromiseAction, rejectPromiseAction } from '@adobe/redux-saga-promise';
import { get } from 'lodash';

import { uploadFileByUrlApi } from 'src/api/documents';
import {
  fetchTemplateApi,
  createTemplateApi,
  updateTemplateApi,
  deleteTemplateApi,
  inviteParticipantsApi,
  removeParticipantApi,
  removeAllParticipantsApi,
  insertSectionApi,
  updateSectionApi,
  moveSectionApi,
  removeSectionApi,
  updateTemplateFileApi,
  downloadAsFileAPI,
  copyTemplateAPI,
  uploadFileAPI,
} from 'src/api/templates';
import {
  fetchTemplateParticipants,
  getDocumentParticipantsIds,
} from 'src/v2/features/documentParticipants';
import {
  InsertSectionPayload,
  UpdateSectionPayload,
  MoveSectionPayload,
  RemoveSectionPayload,
  UploadFilePayload,
  UploadFileByUrlPayload,
} from 'src/models/entity';
import { getContentFields } from 'src/v2/components/Editor/utils';
import { storeData, deleteData } from 'src/v2/features/objectsStorage/objectsStorageSlice';
import {
  CreateTemplateActionPayload,
  TemplatePayload,
  InviteParticipantsPayload,
  RemoveParticipantPayload,
  UpdateTemplateFilePayload,
  CopyTemplatePayload,
} from 'src/models/template';

import { createNavigateToTemplateDetails } from 'src/v2/features/template/utils';
import { DbRecordType } from 'src/v2/features/objectsStorage/types';
import {
  startLoading,
  stopLoading,
  finishLoading,
  setData,
  deleteTemplateStart,
  deleteTemplateFinished,
  fetchTemplate,
  createTemplate,
  updateTemplate,
  insertSection,
  updateSection,
  moveSection,
  removeSection,
  deleteTemplate,
  uploadFile,
  uploadFileByUrl,
  inviteParticipants,
  removeParticipant,
  removeAllParticipants,
  updateFile,
  downloadAsPDF,
  copyTemplate,
} from './reducer';

function* downloadAsPDFSaga(action: PayloadAction<DownloadTemplateAsPDFDTO>) {
  const { templateId, title } = action.payload;
  try {
    yield call(downloadAsFileAPI, templateId, `${title}.pdf`);
  } catch (error) {
    yield call(handleErrorSaga, error);
  }
}

function* fetchTemplateSaga(action: PayloadAction<string>) {
  try {
    yield put(startLoading());
    const data = yield call(fetchTemplateApi, action.payload);
    yield put(storeData(data));
    yield put(setData(data.data));
    yield put(finishLoading());

    const ids = yield select(getDocumentParticipantsIds);
    yield put(fetchTemplateParticipants(ids));
  } catch (error) {
    yield put(stopLoading(error.toString()));
    yield call(handleErrorSaga, error);
  }
}

function* createTemplateSaga(action: PayloadAction<CreateTemplateActionPayload>) {
  try {
    const { history, payload } = action.payload;
    const navigateToTemplateDetails = createNavigateToTemplateDetails(history);
    yield put(startLoading());
    const response = yield call(createTemplateApi, payload);
    yield put(storeData(response));
    yield put(finishLoading());
    yield call(
      toastr.success,
      i18n(translationKeys.messages.success),
      i18n(translationKeys.messages.templateCreated),
    );

    const templateId = get(response, 'data[0].id');
    navigateToTemplateDetails(templateId, payload.folderId);
  } catch (error) {
    yield put(stopLoading(error.toString()));
    yield call(handleErrorSaga, error);
  }
}

function* updateTemplateSaga(action: PayloadAction<TemplatePayload>) {
  try {
    yield put(startLoading());
    const response = yield call(updateTemplateApi, action.payload);
    yield put(storeData(response));
    yield put(finishLoading());
    yield call(
      toastr.success,
      i18n(translationKeys.messages.success),
      i18n(translationKeys.messages.templateUpdated),
    );
  } catch (error) {
    yield put(stopLoading(error.toString()));
    yield call(handleErrorSaga, error);
  }
}

function* insertSectionSaga(action: PayloadAction<InsertSectionPayload>) {
  try {
    const { entityId, index } = action.payload;
    const entity = yield call(insertSectionApi, entityId, index, '', [], true);
    yield put(storeData(entity));
    yield call(resolvePromiseAction, action);
  } catch (error) {
    yield call(handleErrorSaga, error);
    yield call(rejectPromiseAction, action, new Error());
  }
}

function* updateSectionSaga(action: PayloadAction<UpdateSectionPayload>) {
  try {
    const { entityId, index, content } = action.payload;
    const fields = getContentFields(content);
    const entity = yield call(updateSectionApi, entityId, index, content, fields, true);
    yield put(storeData(entity));
    yield call(resolvePromiseAction, action);
  } catch (error) {
    yield call(handleErrorSaga, error);
    yield call(rejectPromiseAction, action, new Error());
  }
}

function* moveSectionSaga(action: PayloadAction<MoveSectionPayload>) {
  try {
    const { entityId, source, target } = action.payload;
    const entity = yield call(moveSectionApi, entityId, source, target, true);
    yield put(storeData(entity));
    yield call(resolvePromiseAction, action);
  } catch (error) {
    yield call(handleErrorSaga, error);
    yield call(rejectPromiseAction, action, new Error());
  }
}

function* removeSectionSaga(action: PayloadAction<RemoveSectionPayload>) {
  try {
    const { entityId, index } = action.payload;
    const entity = yield call(removeSectionApi, entityId, index, true);
    yield put(storeData(entity));
    yield call(resolvePromiseAction, action);
  } catch (error) {
    yield call(handleErrorSaga, error);
    yield call(rejectPromiseAction, action, new Error());
  }
}

function* deleteTemplateSaga(action: PayloadAction<string>) {
  try {
    const id = action.payload;
    yield put(startLoading());
    yield put(deleteTemplateStart(id));
    yield call(deleteTemplateApi, id);
    yield put(deleteData({ id, type: DbRecordType.Template }));
    yield put(deleteData({ id, type: DbRecordType.CardPaper }));
    yield put(finishLoading());
    yield call(
      toastr.success,
      i18n(translationKeys.messages.success),
      i18n(translationKeys.messages.templateDeleted),
    );
    yield put(deleteTemplateFinished());
  } catch (error) {
    yield put(stopLoading(error.toString()));
    yield call(handleErrorSaga, error);
    yield put(deleteTemplateFinished());
  }
}

function* uploadFileSaga(action: PayloadAction<UploadFilePayload>) {
  try {
    const { entityId, file } = action.payload;
    const data = yield call(uploadFileAPI, entityId, file);
    yield call(resolvePromiseAction, action, data);
  } catch (error) {
    yield call(rejectPromiseAction, action);
    yield call(handleErrorSaga, error);
  }
}

function* uploadFileByUrlSaga(action: PayloadAction<UploadFileByUrlPayload>) {
  try {
    const { entityId, url } = action.payload;
    const data = yield call(uploadFileByUrlApi, entityId, url);
    yield call(resolvePromiseAction, action, data);
  } catch (error) {
    yield call(rejectPromiseAction, action);
    yield call(handleErrorSaga, error);
  }
}

function* inviteParticipantsSaga(action: PayloadAction<InviteParticipantsPayload>) {
  try {
    yield put(startLoading());
    const res = yield call(inviteParticipantsApi, action.payload);
    yield put(storeData(res));
    yield call(toastr.success, 'Success', 'Invitations sent successfully');
    yield put(finishLoading());
  } catch (error) {
    yield put(stopLoading(error.toString));
    yield call(handleErrorSaga, error);
  }
}

function* removeParticipantSaga(action: PayloadAction<RemoveParticipantPayload>) {
  try {
    const { templateId, participantId } = action.payload;

    yield call(removeParticipantApi, { templateId, participantId });
    yield put(fetchTemplate(templateId));
    yield call(toastr.success, 'Success', 'Participant removed successfully');
    yield call(emitRemoveParticipant, templateId);
  } catch (error) {
    yield call(handleErrorSaga, error);
  }
}

function* removeAllParticipantsSaga(action: PayloadAction<string>) {
  try {
    yield put(startLoading());
    yield call(removeAllParticipantsApi, action.payload);
    yield put(fetchTemplate(action.payload));
    yield call(toastr.success, 'Success', 'All participants removed successfully');
    yield put(finishLoading());
  } catch (error) {
    yield put(stopLoading(error.toString));
    yield call(handleErrorSaga, error);
  }
}

function* updateFileSaga(action: PayloadAction<UpdateTemplateFilePayload>): SagaIterator {
  try {
    yield put(startLoading());
    const template = yield call(
      updateTemplateFileApi,
      action.payload.templateId,
      action.payload.file,
    );
    yield put(storeData(template));
    yield put(finishLoading());
    yield call(toastr.success, 'Success', 'Your file has been saved');
  } catch (error) {
    yield put(stopLoading(error.toString()));
    yield call(handleErrorSaga, error);
  }
}

function* copyTemplateSaga(action: PayloadAction<CopyTemplatePayload>): SagaIterator {
  try {
    const { templateId, folderId } = action.payload;
    yield call(copyTemplateAPI, templateId, folderId);
    yield call(toastr.success, 'Success', 'Successfully copied');
  } catch (err) {
    yield call(handleErrorSaga, err);
  }
}

export function* watchTemplatesSagas() {
  yield takeLatest(downloadAsPDF, downloadAsPDFSaga);
  yield takeLatest(fetchTemplate, fetchTemplateSaga);
  yield takeLatest(createTemplate, createTemplateSaga);
  yield takeLatest(updateTemplate, updateTemplateSaga);
  yield takeLatest(insertSection, insertSectionSaga);
  yield takeLatest(updateSection, updateSectionSaga);
  yield takeLatest(moveSection, moveSectionSaga);
  yield takeLatest(removeSection, removeSectionSaga);
  yield takeLatest(deleteTemplate, deleteTemplateSaga);
  yield takeLatest(uploadFile, uploadFileSaga);
  yield takeEvery(uploadFileByUrl, uploadFileByUrlSaga);
  yield takeLatest(inviteParticipants, inviteParticipantsSaga);
  yield takeLatest(removeParticipant, removeParticipantSaga);
  yield takeLatest(removeAllParticipants, removeAllParticipantsSaga);
  yield takeLatest(updateFile, updateFileSaga);
  yield takeLatest(copyTemplate, copyTemplateSaga);
}
