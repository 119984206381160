import { initializeContractDIContainer } from 'src/v2/features/diFactories/contractDIFactory';
import { isContractFolder } from 'src/v2/features/quantumNavigator/utils';
import { createGetRootFolderId } from 'src/v2/features/sharedEntity/createGetRootFolderId';
import { handleErrorSaga } from 'src/utils/handleErrorSaga';
import { copyEntityToProjectAPI } from 'src/api/projectsAPI';
import { copyContractToFolderAPI } from 'src/api/contractsAPI';

initializeContractDIContainer({
  isContractFolder,
  createGetRootFolderId,
  handleErrorSaga,
  copyEntityToProjectAPI,
  copyContractToFolderAPI,
});
