import React from 'react';
import cn from 'classnames';

interface Props {
  active?: boolean;
}

export const Sidebar: React.FC<Props> = ({ active = false, children }) => (
  <div
    className={cn('l-sidebar', 'l-sidebar--version-01', active && 'l-sidebar--active')}
    data-type="sidebar"
  >
    {children}
  </div>
);
