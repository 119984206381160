import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/app/types';

const initialState = {
  isOnline: true,
};

const networkSlice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    setNetworkOnline: (state: any, { payload }: PayloadAction<boolean>) => {
      state.isOnline = payload;
    },
  },
});

export const networkReducer = networkSlice.reducer;

export const { setNetworkOnline } = networkSlice.actions;

export const getIsNetworkOnline = (state: RootState) => state.network.isOnline;
