import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { RootState, NotificationsState } from 'src/app/types';
import { getObjects, storeData } from 'src/v2/features/objectsStorage/objectsStorageSlice';
import { listenWSEvents, sendWSCommand } from 'src/api/socket/connection';
import { socketConnected } from 'src/api/socket/store';
import { SocketConnectionId } from 'src/v2/boundary/socket';
import { SocketConnectedPayload } from 'src/v2/boundary/actionPayload/socket';
import { ObjectBase, ObjectSerialized, WSObjectSerialized } from 'src/common/types';
import { NotificationEntity } from 'src/v2/entities/notification';

const NOTIFICATIONS_LIMIT = 30;

interface CountsType {
  new_count: number;
  total_count: number;
}
type WSObjectSerializedWithCounts = WSObjectSerialized & CountsType;

const initialState: NotificationsState = {
  newCount: 0,
  totalCount: 0,
  notificationList: [],
};

enum NotificationWSCommand {
  BellCount = 'notification/bellCount',
  BellSubscribe = 'notification/bellSubscribe',
  BellMarkEventsViewed = 'notification/bellMarkEventsViewed',
  BellUnSubscribe = 'notification/bellUnSubscribe',
  BellLoadMore = 'notification/bellHistory',
}

const NotificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setCounts: (state, action: PayloadAction<CountsType>): void => {
      [state.newCount, state.totalCount] = [action.payload.new_count, action.payload.total_count];
    },
    setNotificationsViewed: (state): void => {
      state.newCount = 0;
    },
    setNotificationList: (state, action: PayloadAction<ObjectSerialized>): void => {
      state.notificationList = ObjectSerialized.dataToBaseObjects(action.payload.data);
    },
    prependNotificationList: (state, action: PayloadAction<ObjectSerialized>): void => {
      state.notificationList = [
        ...ObjectSerialized.dataToBaseObjects(action.payload.data),
        ...state.notificationList,
      ];
    },
    appendNotificationList: (state, action: PayloadAction<ObjectSerialized>): void => {
      state.notificationList = [
        ...state.notificationList,
        ...ObjectSerialized.dataToBaseObjects(action.payload.data),
      ];
    },
    clearNotificationList: (state): void => {
      state.notificationList = [];
    },
  },
});

export const {
  setCounts,
  setNotificationList,
  setNotificationsViewed,
  prependNotificationList,
  appendNotificationList,
  clearNotificationList,
} = NotificationSlice.actions;

export const loadBellCount = createAction('notification/loadBellCount');
export const bellLoadMore = createAction('notification/loadMore');
export const bellSubscribe = createAction('notification/bellSubscribe');
export const bellMarkEventsViewed = createAction('notification/bellMarkEventsViewed');
export const bellUnSubscribe = createAction('notification/bellUnSubscribe');

export const bellNewEvents = createAction<WSObjectSerializedWithCounts>(
  'notification/bellNewEvents',
);
export const bellEvents = createAction<WSObjectSerializedWithCounts>('notification/bellEvents');
export const bellHistoryEvents = createAction<WSObjectSerializedWithCounts>(
  'notification/bellHistoryEvents',
);

/*
Selector
 */
const getState = (state: RootState): NotificationsState => state.notifications;
export const getNotificationNewCount = (state: RootState): number => getState(state).newCount;
export const getNotificationTotalCount = (state: RootState): number => getState(state).totalCount;

const getNotificationsList = (state: RootState): ObjectBase[] => getState(state).notificationList;

export const getNotifications = createSelector(
  (state: RootState) => getState(state).notificationList,
  (state: RootState) => state.objectsStorage.objects.alarmBellNotification,
  (items, alarmBellNotification) =>
    getObjects(items, { alarmBellNotification }).map((itm) => ({
      ...itm,
      createdAt: new Date((itm.createdAt as number) * 1000),
    })) as NotificationEntity[],
);

/*
Sagas
 */
function* getNotificationCountSaga(): Generator {
  try {
    yield call(sendWSCommand, { command: NotificationWSCommand.BellCount, payload: {} });
  } catch (err) {
    toastr.error('Error', 'Error loading notifications');
    console.error('bellCount: ', err);
  }
}

function* bellSubscribeSaga(): Generator {
  try {
    yield call(sendWSCommand, { command: NotificationWSCommand.BellSubscribe, payload: {} });
  } catch (err) {
    toastr.error('Error', 'Error loading notifications');
    console.error('bellSubscribe: ', err);
  }
}

function* bellMarkEventsViewedSaga(): Generator {
  try {
    yield call(sendWSCommand, { command: NotificationWSCommand.BellMarkEventsViewed, payload: {} });
    yield put(setNotificationsViewed());
  } catch (err) {
    toastr.error('Error', 'Error loading notifications');
    console.error('bellMarkEventsViewed: ', err);
  }
}

function* bellUnSubscribeSaga(): Generator {
  try {
    yield call(sendWSCommand, { command: NotificationWSCommand.BellUnSubscribe, payload: {} });
  } catch (err) {
    toastr.error('Error', 'Error loading notifications');
    console.error('bellMarkEventsViewed: ', err);
  }
}

function* bellEventsSaga({ payload }: PayloadAction<WSObjectSerializedWithCounts>): Generator {
  yield put(storeData(payload));
  yield put(setCounts(payload));
  yield put(setNotificationList(payload));
}

function* bellNewEventsSaga({ payload }: PayloadAction<WSObjectSerializedWithCounts>): Generator {
  yield put(storeData(payload));
  yield put(setCounts(payload));
  yield put(prependNotificationList(payload));
}

function* bellHistoryEventsSaga({
  payload,
}: PayloadAction<WSObjectSerializedWithCounts>): Generator {
  yield put(storeData(payload));
  yield put(setCounts(payload));
  yield put(appendNotificationList(payload));
}

function* bellLoadMoreSaga(): Generator {
  try {
    const notifications = (yield select(getNotificationsList)) as ObjectBase[];

    yield call(sendWSCommand, {
      command: NotificationWSCommand.BellLoadMore,
      payload: { limit: NOTIFICATIONS_LIMIT, offset: notifications.length },
    });
    // yield put(setChatId(payload));
  } catch (err) {
    toastr.error('Error', 'Error loading more message');
    console.error('chatLoadMoreSaga: ', err);
  }
}

function* socketConnectedSaga({ payload }: PayloadAction<SocketConnectedPayload>): Generator {
  if (payload.connectionId !== SocketConnectionId.Notifications) return;

  yield call(bellSubscribeSaga);
}

export function* watchNotificationSagas(): Generator {
  yield takeLatest(loadBellCount, getNotificationCountSaga);
  yield takeLatest(bellSubscribe, bellSubscribeSaga);
  yield takeLatest(bellMarkEventsViewed, bellMarkEventsViewedSaga);
  yield takeLatest(bellUnSubscribe, bellUnSubscribeSaga);
  yield takeLatest(bellLoadMore, bellLoadMoreSaga);
  yield takeLatest(socketConnected, socketConnectedSaga);

  // external
  yield takeLatest(bellEvents, bellEventsSaga);
  yield takeLatest(bellNewEvents, bellNewEventsSaga);
  yield takeLatest(bellHistoryEvents, bellHistoryEventsSaga);
}

export const initializeNotificationStore = (): void => {
  listenWSEvents(bellNewEvents, bellEvents, bellHistoryEvents);
};

export default NotificationSlice.reducer;
