import React from 'react';
import { isUndefined } from 'lodash';

import { IconOption, Intent } from 'src/v2/components/Card';
import { EntityCard } from 'src/v2/features/sharedEntity';
import { EntityCardProps } from 'src/v2/features/sharedEntity/types';
import { canDeleteContract } from 'src/v2/features/contract/permissions/contractPermissions/contractPermissions';
import { ContextMenuBook } from 'src/app/types';
import { useContextMenu } from 'src/v2/features/contextMenu';
import { ContractRole } from 'src/models/contract';

interface Props {
  contextMenu?: ContextMenuBook;
}

export const ContractCard: React.FC<
  Props & Omit<EntityCardProps<ContractRole>, 'intent' | 'icon'>
> = (props) => {
  const contextMenuType = props.contextMenu || ContextMenuBook.Contract;

  const contextMenuHandler = useContextMenu(contextMenuType, props.data);

  const { status, role } = props.data;
  const canDelete = isUndefined(status) ? false : canDeleteContract(status);

  let icon: IconOption = IconOption.View;
  switch (role) {
    case ContractRole.Owner:
      icon = IconOption.UserEdit;
      break;
    case ContractRole.Editor:
      icon = IconOption.Pencil;
      break;
    case ContractRole.Signatory:
      icon = IconOption.Comment;
      break;
  }

  return (
    <EntityCard<ContractRole>
      {...props}
      intent={Intent.GrayPale}
      showSignedStatus
      canDeleteEntity={canDelete}
      onContextMenu={contextMenuHandler}
      icon={icon}
    />
  );
};
