import { FC, useEffect } from 'react';
import { isArray, isString, isUndefined } from 'lodash';
import { ParsedQuery } from 'query-string';
import { useDispatch } from 'react-redux';

import { book as defaultBook } from 'src/v2/features/sidebarController/book';
import { SidebarRoute } from 'src/v2/features/sidebarController/SidebarRoute';
import { useUrlManagerHash } from 'src/common/hooks/urlManager';
import { useGetEntityById, useNormalizedEntityId } from 'src/v2/features/sharedEntity/hooks';
import { SidebarFactoryComponent } from 'src/v2/features/sidebarController/types';
import { useOpenMobileSidebarOnViewChangeEffct } from 'src/v2/features/sidebarController/hooks';
import { resetSidebarState } from 'src/common/store';

import { NineSquareMenuComponent } from './types';
import { useLoadSidebarDataEffct } from 'src/v2/features/sharedEntity/EntitySidebar/hooks/helper-hooks';
import { book } from './book';
import { useSubscribeToParticipantEvents } from './hooks';

interface Props {
  sidebarComponent: SidebarFactoryComponent;
  nineSquareMenuComponent: NineSquareMenuComponent;
  sidebarName: string;
  isHidden: boolean;
}

export const useNormalizeURLHashValues = (defaultView: string): ParsedQuery<string> => {
  const [hash] = useUrlManagerHash();
  const { v, ...rest } = hash;
  const id = useNormalizedEntityId();

  if (isArray(v)) {
    console.warn(
      'Document Sidebar normalizeValues:: v should be a string not array, falling back to default value',
    );
  }

  const normalizedV = isString(v) ? v : defaultView;

  const normalizedHash = { ...rest, v: normalizedV };

  if (!isUndefined(id)) normalizedHash['id'] = id;

  return normalizedHash;
};

export const EntitySidebar: FC<Props> = ({
  sidebarComponent: SidebarComponent,
  nineSquareMenuComponent: NineSquareMenuComponent,
  sidebarName,
  isHidden,
}) => {
  const criteria = useNormalizeURLHashValues(book.default);
  const { v, id } = criteria;

  useSubscribeToParticipantEvents(id as string);
  const entity = useGetEntityById(id as string);
  useLoadSidebarDataEffct(entity);
  useOpenMobileSidebarOnViewChangeEffct(v as string, [book.view]);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetSidebarState());
    };
  }, [dispatch]);

  if (isHidden) return null;

  return id ? (
    <SidebarComponent criteria={criteria}>
      <SidebarRoute value={defaultBook.nineSquare} sidebarName={sidebarName}>
        <NineSquareMenuComponent id={id as string} />
      </SidebarRoute>
    </SidebarComponent>
  ) : (
    <SidebarComponent criteria={criteria} />
  );
};
