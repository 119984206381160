import { ReactElement } from 'react';

import { SignUp } from 'src/v2/features/signUp/SignUp';
import { SignUpStepComponent, useSignUpProps } from './hooks';

export const useSignupFormComponent = (): SignUpStepComponent => {
  const { accountType, inviteType } = useSignUpProps();

  return [(): ReactElement => <SignUp accountType={accountType} inviteType={inviteType} />, {}];
};
