import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { PhoneNumberWithOtp } from 'src/v2/components/PhoneNumberWithOtp';
import { BaseProps as Props } from 'src/v2/components/PhoneNumber/types';
import { updatePhoneNumber, verifyPhoneNumber } from '../../store';

export const UserPhoneNumber: FC<Props> = ({ ...baseProps }) => {
  const dispatch = useDispatch();
  const handleUpdatePhone = useCallback(
    (number, captureToken) => dispatch(updatePhoneNumber({ number, captureToken })),
    [dispatch],
  );
  const handleSendCode = useCallback((code) => dispatch(verifyPhoneNumber(code)), [dispatch]);

  return (
    <PhoneNumberWithOtp
      onUpdatePhone={handleUpdatePhone}
      onSendCode={handleSendCode}
      {...baseProps}
    />
  );
};
