import React, { FC } from 'react';
import { Box } from '@theme-ui/components';

const styles = {
  side: {
    flex: 1,
    p: '25px',
  },

  empty: {
    fontWeight: 'bold',
  },
};

interface Props {
  text: string;
}

export const CounterpartyPlaceholder: FC<Props> = ({ text }): JSX.Element => {
  return (
    <Box sx={styles.side}>
      <Box sx={styles.empty}>{text}</Box>
    </Box>
  );
};
