import { FC } from 'react';

import { TemplateRole } from 'src/models/template';
import { Copy } from 'src/v2/icons/Copy';
import { CardData } from 'src/v2/features/sharedEntity/types';
import { ContextMenuItem, ContextMenu } from 'src/v2/components/contextMenu';
import { ContextMenuProps } from 'src/v2/features/contextMenu/types';
import { ModalType } from 'src/v2/features/modal/types';
import { useOpenModal } from 'src/v2/features/folderSelector/hooks';
import { useGetRootFolderId } from 'src/v2/features/sharedEntity/hooks';
import { isTemplatesFolder } from 'src/v2/features/quantumNavigator/utils';

export const SystemTemplateContextMenu: FC<ContextMenuProps> = ({ position, payload }) => {
  const { id } = payload as CardData<TemplateRole>;

  const templateRootFolderId = useGetRootFolderId(isTemplatesFolder);

  const handleCopy = useOpenModal({
    type: ModalType.TemplateCopyTo,
    menuItemFolderId: templateRootFolderId,
    targetId: id,
  });

  return (
    <ContextMenu {...position}>
      <ContextMenuItem
        icon={Copy}
        text="Copy To"
        onClick={handleCopy}
        data-id="copyto-menuButton"
      />
    </ContextMenu>
  );
};
