import React, { FC, useCallback } from 'react';
import { isNull } from 'lodash';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { NineSquareMenu as CNineSquareMenu, Download } from 'src/v2/features/nineSquareMenu';
import { downloadAsPDF } from 'src/v2/features/template/reducer';
import { useGetTemplateById } from 'src/v2/features/template/hooks';

interface Props {
  id?: string;
}

export const NineSquareMenu: FC<Props> = ({ id }) => {
  const dispatch = useDispatch();
  const { id: idFromUrl } = useParams<{ id: string }>();
  const idFromParamOrUlr = id || idFromUrl;
  const entity = useGetTemplateById(idFromParamOrUlr);

  const handleDownload = useCallback(() => {
    if (!isNull(entity)) {
      dispatch(downloadAsPDF({ templateId: entity.templateId, title: entity.title }));
    }
  }, [dispatch, entity]);

  return (
    <CNineSquareMenu>
      <Download onClick={handleDownload} data-id="download-menuButton" />
    </CNineSquareMenu>
  );
};
