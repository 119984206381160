import { FC } from 'react';

import { CheckoutWizard } from '../CheckoutWizard';
import { CheckoutLayout } from '../CheckoutLayout';
import { upgradeSubscription, retryFailedSubscription } from '../store';
import { useShouldRetrySubscription } from '../utils';

export const UpgradeSubscriptionPage: FC = () => {
  const shouldUseRetry = useShouldRetrySubscription();
  const action = shouldUseRetry ? retryFailedSubscription : upgradeSubscription;

  return (
    <CheckoutLayout>
      <CheckoutWizard submitAction={action} />
    </CheckoutLayout>
  );
};
