import React from 'react';

import { EntityContext, EntityLayout } from 'src/v2/features/sharedEntity';
import { EntityLayoutProps } from 'src/v2/features/sharedEntity/types';

import { EntityType } from 'src/models/paper';
import { ContextMenuTypeContextProvider } from './providers/ContextMenuTypeContextProvider';

export const DocumentLayout: React.FC<EntityLayoutProps> = ({ children, isLoading, error }) => {
  return (
    <EntityContext.Provider value={EntityType.document}>
      <ContextMenuTypeContextProvider>
        <EntityLayout isLoading={isLoading} error={error}>
          {children}
        </EntityLayout>
      </ContextMenuTypeContextProvider>
    </EntityContext.Provider>
  );
};
