import { sidebarControllerFactory } from 'src/v2/features/sidebarController';

import { Edit as DocumentEdit } from 'src/v2/features/document/DocumentSidebar/pages/Edit';
import { CreateDocument } from 'src/v2/features/project/ProjectSidebar/pages/CreateDocument';

import { Edit as ContractEdit } from 'src/v2/features/contract/ContractSidebar/pages/Edit';
import { CreateContract } from 'src/v2/features/project/ProjectSidebar/pages/CreateContract';

import { ViewDocument } from 'src/v2/features/project/ProjectSidebar/pages/ViewDocument';
import { ViewContract } from 'src/v2/features/project/ProjectSidebar/pages/ViewContract';

import { ViewProject as ProjectView } from 'src/v2/features/project/ProjectSidebar/pages/ViewProject';
import { EditProject as ProjectEdit } from 'src/v2/features/project/ProjectSidebar/pages/EditProject';
import { CreateProject as ProjectCreate } from 'src/v2/features/project/ProjectSidebar/pages/CreateProject';
import { RemoveProject as ProjectRemove } from 'src/v2/features/project/ProjectSidebar/pages/RemoveProject';

import { EditDocumentParticipant } from 'src/v2/features/document/DocumentSidebar/pages/EditDocumentParticipant';
import { InviteDocumentParticipant } from 'src/v2/features/document/DocumentSidebar/pages/InviteDocumentParticipant';
import { EditContractParticipant } from 'src/v2/features/contract/ContractSidebar/pages/EditContractParticipant';
import { InviteContractParticipant } from 'src/v2/features/contract/ContractSidebar/pages/InviteContractParticipant';
import { RemoveParticipant } from 'src/v2/features/sharedEntity/EntitySidebar/pages/RemoveParticipant';
import { book as documentSidebarBook } from 'src/v2/features/document/DocumentSidebar/book';
import { book as contractSidebarBook } from 'src/v2/features/contract/ContractSidebar/book';

import { book } from './book';

export const sidebarName = 'projectSidebar';

export const sidebarController = sidebarControllerFactory(sidebarName, [
  {
    path: book.viewProject,
    render: ProjectView,
  },
  {
    path: book.editProject,
    render: ProjectEdit,
  },
  {
    path: book.createProject,
    render: ProjectCreate,
  },
  {
    path: book.removeProject,
    render: ProjectRemove,
  },
  {
    path: book.viewDocument,
    render: ViewDocument,
  },
  {
    path: book.createDocument,
    render: CreateDocument,
  },
  {
    path: book.editDocument,
    render: DocumentEdit,
  },
  {
    path: book.viewContract,
    render: ViewContract,
  },
  {
    path: book.createContract,
    render: CreateContract,
  },
  {
    path: book.editContract,
    render: ContractEdit,
  },
  {
    path: documentSidebarBook.inviteDocumentParticipant,
    render: InviteDocumentParticipant,
  },
  {
    path: documentSidebarBook.editDocumentParticipant,
    render: EditDocumentParticipant,
  },
  {
    path: contractSidebarBook.inviteContractParticipant,
    render: InviteContractParticipant,
  },
  {
    path: contractSidebarBook.editContractParticipant,
    render: EditContractParticipant,
  },
  {
    path: book.removeParticipant,
    render: RemoveParticipant,
  },
]);
