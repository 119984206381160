import React, { useContext } from 'react';

import { Checkbox } from 'src/v2/components/Form/Checkbox';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

import { SignatureSettingsContext } from '../../contexts/SignatureSettingsContext';

export const SignatureSettings: React.FC = ({ children }) => {
  const { includeCompanyMeta, toggleIncludeCompanyMeta } = useContext(SignatureSettingsContext);

  return (
    <div className="c-signature__action">
      <Checkbox
        data-type="include-checkbox"
        label={i18n(translationKeys.forms.signature.companyInclude)}
        checked={includeCompanyMeta}
        onChange={toggleIncludeCompanyMeta}
      />
      {children}
    </div>
  );
};
