import { FC, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import './capture.css';
import { sitekey } from './consts';
import { isNull, isUndefined } from 'lodash';

interface Props {
  onChange: (token: string) => void;
  isShow: boolean;
  position?: string;
  resetCapture?: boolean;
}

export const Capture: FC<Props> = ({ onChange, isShow, position, resetCapture }) => {
  const [captureRef, setCaptureRef] = useState<any | null>(null);
  const handleChange = (value) => {
    onChange(value);
  };

  useEffect(() => {
    if (!isNull(captureRef) && !isUndefined(resetCapture) && resetCapture) {
      captureRef.reset();
    }
  }, [resetCapture, captureRef]);

  const getPosition = () => {
    switch (position) {
      case 'center': {
        return 'center';
      }
      case 'left': {
        return 'flex-start';
      }
      case 'right': {
        return 'flex-end';
      }
      default: {
        return 'flex-start';
      }
    }
  };

  const styleName = isShow ? 'captureBox' : 'captureBox hide';
  const boxPosition = getPosition();

  return (
    <div className={styleName} style={{ justifyContent: boxPosition }}>
      <ReCAPTCHA sitekey={sitekey} onChange={handleChange} ref={(ref) => setCaptureRef(ref)} />
    </div>
  );
};
