import { FC } from 'react';

import { ContractRole } from 'src/models/contract';
import { ContextMenuProps } from 'src/v2/features/contextMenu/types';
import { EntityProjectContextMenu } from 'src/v2/features/sharedEntity/EntityProjectContextMenu';
import { ContextMenuItem } from 'src/v2/components/contextMenu';
import { DropdownAddUser } from 'src/v2/icons/DropdownAddUser';
import { DropdownEditUser } from 'src/v2/icons/DropdownEditUser';
import {
  useNavigateToEditParticipant,
  useNavigateToInviteParticipant,
} from 'src/v2/features/contract/ContractSidebar/nav-hooks';
import { useEditParticipantCallback } from 'src/v2/features/entityParticipants/hooks';
import { CardData } from 'src/v2/features/sharedEntity/types';
import { canManageParticipantInContract } from 'src/v2/features/contract/permissions/contractPermissions/contractPermissions';

export const ContractProjectContextMenu: FC<ContextMenuProps> = (props) => {
  const { closeOnClick, payload } = props;
  const { id: contractId, role } = payload as CardData<ContractRole>;

  const navigateToInviteParticipant = useNavigateToInviteParticipant(contractId);
  const navigateToEditParticipant = useNavigateToEditParticipant(contractId);
  const isAllowedToManageParticipants = canManageParticipantInContract(role);

  const handleInviteUser = closeOnClick(navigateToInviteParticipant);
  const handleEditUser = closeOnClick(useEditParticipantCallback(navigateToEditParticipant)());

  return (
    <EntityProjectContextMenu<ContractRole> {...props}>
      <ContextMenuItem
        icon={DropdownAddUser}
        text="Add user"
        disabled={!isAllowedToManageParticipants}
        onClick={handleInviteUser}
        data-id="addUser-menuButton"
      />
      <ContextMenuItem
        icon={DropdownEditUser}
        text="Edit user"
        onClick={handleEditUser}
        data-id="editUser-menuButton"
      />
    </EntityProjectContextMenu>
  );
};
