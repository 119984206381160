export const styles = {
  container: {
    py: '18px',
    px: '38px',
    height: '100%',
  },

  content: {
    bg: 'white',
    border: '1px solid #d6d6d6',
    borderRadius: [1],
    height: '100%',
    py: '15px',
    px: '8px',
  },

  addUsers: {
    width: '100%',
    py: '10px',
    px: '14px',
    alignItems: 'center',
    fontFamily: 'arial',
    fontSize: 'normal',
    fontWeight: 700,
    color: '#2e4354',
  },
  addUserLinkIcon: {
    size: '35px',
    mr: '8px',
  },
  addUserLink: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'none',
    },
  },
  loader: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
