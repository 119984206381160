import { FC } from 'react';

import { SharedSidebarContext } from 'src/v2/features/sharedEntity/EntitySidebar/SharedSidebarContext';
import {
  useNavigateToEditParticipant,
  useNavigateToInviteParticipant,
  useNavigateToQRCode,
} from 'src/v2/features/document/DocumentSidebar/nav-hooks';
import { SharedSidebarContextProps } from 'src/v2/features/sharedEntity/EntitySidebar/types';
import {
  useCanChatInDocument,
  useCanEditAccessInDocument,
  useCanManageParticipantInDocument,
} from 'src/v2/features/document/permissions/documentPermissions/documentPermissionsHooks';
import { getShownRole } from 'src/v2/features/document/participants/utils';
import { useGetEntityById } from 'src/v2/features/sharedEntity';
import { DocumentRole, DocumentType } from 'src/models/document';
import { castType } from 'src/utils/types';

export const SharedDocumentSidebarContextProvider: FC<SharedSidebarContextProps> = ({
  id,
  children,
}) => {
  const document = useGetEntityById<DocumentType, DocumentRole>(id);
  const isAllowedToManageParticipants = useCanManageParticipantInDocument(document);
  const isAllowedToEditAccess = useCanEditAccessInDocument(document);
  const isAllowedToChat = useCanChatInDocument(document);
  const navigateToInviteParticipant = useNavigateToInviteParticipant(id);
  const navigateToEditParticipant = useNavigateToEditParticipant(id);
  const navigateToQRCode = useNavigateToQRCode(id);
  const getShownParticipantRole = castType<DocumentRole>(getShownRole);

  return (
    <SharedSidebarContext.Provider
      value={{
        isAllowedToManageParticipants,
        isAllowedToChat,
        isAllowedToEditAccess,
        navigateToInviteParticipant,
        navigateToEditParticipant,
        getShownParticipantRole,
        navigateToQRCode,
      }}
    >
      {children}
    </SharedSidebarContext.Provider>
  );
};
