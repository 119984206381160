import { SagaIterator } from '@redux-saga/types';
import { takeLatest, select, put, call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { toastr } from 'react-redux-toastr';
import { get } from 'lodash';

import { fetchProfile } from 'src/v2/features/profile';
import { uploadSignatureStampApi, updateDefaultSignatureStampApi } from 'src/api/signatureApi';
import {
  getUserSignatureList,
  getDefaultSignatureId,
  getLastSignatureId,
  getSignatureId,
  getSignaturePayload,
  fetchSignatures,
  fetchSignaturesSaga,
  updateSignatureSaga,
  createSignatureSaga,
} from 'src/v2/features/signature/store';
import { getSignatureById, normalizeSignatureData } from 'src/v2/features/signature/utils';
import { storeData } from 'src/v2/features/objectsStorage/objectsStorageSlice';
import { readImageFile } from 'src/utils/files';
import { handleErrorSaga } from 'src/utils/handleErrorSaga';

import {
  setDefaultSignatureStamp,
  startLoading,
  stopLoading,
  finishLoading,
  createNewHanko,
  editHanko,
  saveHanko,
  editHankoSignature,
  saveHankoSignature,
  setLocalisedSignature,
  setIncludeHanko,
  setUploadedImageUrl,
  setHankoFile,
  setHankoPreview,
} from './hankoSignatureReducers';
import {
  getSignatureStamps,
  getLocalisedSignature,
  getIncludeHanko,
  getHankoFile,
} from './hankoSignatureSelectors';
import { UpdateSignatureStampPayload } from '../types';
import { getStampById } from '../utils';

export function* updateSignatureStampSaga(payload: UpdateSignatureStampPayload) {
  try {
    const { file, signatureId } = payload;
    const response = yield call(uploadSignatureStampApi, file);
    const stampId = get(response, 'data[0].id');
    yield put(storeData(response));
    yield call(updateDefaultSignatureStampApi, signatureId, stampId);
    yield call(toastr.success, 'Success', 'Your signature stamp has been uploaded.');
    yield call(fetchSignaturesSaga);
    yield put(fetchProfile);
  } catch (error) {
    handleErrorSaga(error);
  }
}

export function* setDefaultSignatureStampSaga(action: PayloadAction<string>): SagaIterator {
  try {
    const signatureId = yield select(getDefaultSignatureId);
    yield call(updateDefaultSignatureStampApi, signatureId, action.payload);
    yield put(fetchSignatures);
    yield call(toastr.success, 'Success', 'Default signature stamp has been updated.');
  } catch (error) {
    handleErrorSaga(error);
  }
}

function* editHankoSaga(action: PayloadAction<string>) {
  try {
    const stamps = yield select(getSignatureStamps);
    const stamp = getStampById(stamps, action.payload);
    if (stamp) {
      yield put(setUploadedImageUrl(stamp ? stamp.stamp_file_link : null));
      yield put(setIncludeHanko(true));
    } else {
      yield put(createNewHanko());
    }
  } catch (error) {
    yield call(handleErrorSaga, error);
  }
}

function* editHankoSignatureSaga(action: PayloadAction<string>) {
  try {
    const signatures = yield select(getUserSignatureList);
    const signature = getSignatureById(signatures, action.payload);
    if (signature) {
      const signatureData = normalizeSignatureData(signature);
      const localizing = signatureData.includeStamp; // TODO: Ask for a separate param
      yield put(setLocalisedSignature(localizing));
      yield put(setIncludeHanko(signatureData.includeStamp));
      if (signatureData.defaultStampId) yield put(editHanko(signatureData.defaultStampId));
    } else {
      yield put(createNewHanko());
    }
  } catch (error) {
    yield call(handleErrorSaga, error);
  }
}

function* setHankoFileSaga(action: PayloadAction<File>) {
  try {
    const image: HTMLImageElement = yield call(readImageFile, action.payload);
    yield put(setHankoPreview(image.src));
  } catch (error) {
    yield call(handleErrorSaga, error);
  }
}

function* submitHankoSaga() {
  try {
    yield put(startLoading());

    const signatureId = yield select(getSignatureId);
    const file = yield select(getHankoFile);

    if (signatureId && file) {
      yield updateSignatureStampSaga({ signatureId, file });
    }

    yield put(finishLoading());
  } catch (error) {
    yield call(handleErrorSaga, error);
    yield put(stopLoading(error));
  }
}

function* submitHankoSignatureSaga() {
  try {
    yield put(startLoading());

    const signaturePayload = yield call(getSignaturePayload);
    let { signatureId } = signaturePayload;
    const { file, payload } = signaturePayload;
    const localized = yield select(getLocalisedSignature);
    const includeStamp = yield select(getIncludeHanko);
    const stamp = yield select(getHankoFile);

    const signature = {
      ...payload,
      include_stamp: includeStamp,
      localizing: localized,
    };

    if (signatureId) {
      yield updateSignatureSaga({ signatureId, file, signature });
    } else {
      yield createSignatureSaga({ signature, file });
      signatureId = yield select(getLastSignatureId);
    }

    if (stamp) {
      yield updateSignatureStampSaga({ signatureId, file: stamp });
    }

    yield put(finishLoading());
  } catch (error) {
    yield call(handleErrorSaga, error);
    yield put(stopLoading(error));
  }
}

export function* watchHankoSignatureSagas(): SagaIterator {
  yield takeLatest(setDefaultSignatureStamp, setDefaultSignatureStampSaga);
  yield takeLatest(editHanko, editHankoSaga);
  yield takeLatest(setHankoFile, setHankoFileSaga);
  yield takeLatest(saveHanko, submitHankoSaga);
  yield takeLatest(editHankoSignature, editHankoSignatureSaga);
  yield takeLatest(saveHankoSignature, submitHankoSignatureSaga);
}
