import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { HankoSignatureSettingsContext } from '../contexts/HankoSignatureSettingsContext';
import {
  getIncludeHanko,
  getIsIncludeHankoVisible,
  getLocalisedSignature,
  setLocalisedSignature,
  getHankoPreview,
  getUploadedImageUrl,
  setIncludeHanko,
} from '../store';

export const HankoSignatureSettingsContextProvider: FC = ({ children }) => {
  const dispatch = useDispatch();
  const localisedSignature = useSelector(getLocalisedSignature);
  const includeHanko = useSelector(getIncludeHanko);
  const isIncludeHankoVisible = useSelector(getIsIncludeHankoVisible);
  const hankoPreviewUrl = useSelector(getHankoPreview);
  const uploadedImageUrl = useSelector(getUploadedImageUrl);

  const toggleLocalisedSignature = useCallback(
    () => dispatch(setLocalisedSignature(!localisedSignature)),
    [dispatch, localisedSignature],
  );
  const toggleIncludeHanko = useCallback(
    () => dispatch(setIncludeHanko(!includeHanko)),
    [dispatch, includeHanko],
  );

  const ctx = {
    localisedSignature,
    includeHanko,
    toggleLocalisedSignature,
    toggleIncludeHanko,
    isIncludeHankoVisible,
    hankoPreviewUrl,
    uploadedImageUrl,
  };

  return (
    <HankoSignatureSettingsContext.Provider value={ctx}>
      {children}
    </HankoSignatureSettingsContext.Provider>
  );
};
