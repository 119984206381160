import { createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

import { CountryCode } from 'src/instruments/countries';
import { getObject } from 'src/v2/features/objectsStorage/objectsStorageSlice';
import { RootState } from 'src/app/types';
import {
  getAddresses,
  getAvatars,
  getProfiles,
  getUsers,
} from 'src/v2/features/objectsStorage/selectors';
import { getFullName } from 'src/utils/normalize';

import {
  StorageProfileData,
  ProfileData,
  ProfileAddress,
  ProfileFormData,
  StorageUser,
} from '../types';
import { isValidDate } from '../utils';

const getState = (state: RootState) => state.profile;
export const getProfileSignature = (state: RootState) => state.profile.signature;
export const getProfileData = (state: RootState) => state.profile.data;
export const getIsLoading = createSelector(getState, (state) => state.isLoading);

/** @deprecated */
/** @todo */
export const getProfileSelector = createSelector(
  getProfileData,
  getUsers,
  getProfiles,
  getAddresses,
  getAvatars,
  (item, user, profile, address, avatar): StorageUser | null =>
    getObject<StorageUser>(item, {
      user,
      profile,
      address,
      avatar,
    }),
);

const getDateOfBirthFromProfile = (profile: StorageProfileData | ProfileData): Date | undefined => {
  return profile && profile.dateOfBirth && isValidDate(profile.dateOfBirth)
    ? new Date(profile.dateOfBirth)
    : undefined;
};

export const getProfileInfo = createSelector(
  getProfileSelector,
  (user: StorageUser | null): ProfileData | undefined => {
    if (!user) return undefined;
    const profileInfo = user.profile && user.profile[0];
    const profileAddress: ProfileAddress = get(profileInfo, 'address[0]');
    const avatarData = get(profileInfo, 'avatar[0]');

    const dateOfBirth = getDateOfBirthFromProfile(profileInfo);

    const userProfile: ProfileData = {
      ...profileInfo,
      address: profileAddress,
      avatar: '',
      avatarData,
      dateOfBirth,
    };

    return userProfile;
  },
);

export const getProfileFormDataFromProfile = createSelector(
  getProfileSelector,
  getProfileInfo,
  (user: StorageUser | null, profile: ProfileData | undefined): ProfileFormData | {} => {
    if (!user || !profile) return {};
    const dateOfBirth = getDateOfBirthFromProfile(profile);
    const address = profile.address || {};
    const profileFormData = {
      firstName: profile.firstName,
      lastName: profile.lastName,
      email: user.email,
      phoneNumber: user.phone,
      phoneVerified: user.phoneVerified,
      address: address.addressLine || '',
      language: user.language,
      country: address.country || CountryCode.US,
      city: address.city || '',
      state: address.state,
      zipCode: address.zipCode,
      dateOfBirth: dateOfBirth,
      about: profile.about,
    };

    return profileFormData;
  },
);

export const getAvatarFromProfile = createSelector(
  getProfileInfo,
  (profileData: ProfileData | undefined) => (profileData ? profileData.avatarData : undefined),
);

export const getFirstNameFromProfile = createSelector(
  getProfileInfo,
  (profile) => profile && profile.firstName,
);

export const getLastNameFromProfile = createSelector(
  getProfileInfo,
  (profile) => profile && profile.lastName,
);

export const getFullNameFromProfile = createSelector(
  getFirstNameFromProfile,
  getLastNameFromProfile,
  (firstName, lastName) => getFullName(firstName, lastName),
);

export const getCountryFromProfile = createSelector(
  getProfileInfo,
  (profile) => profile && profile.address.country,
);

export const getPreferredLanguage = createSelector(
  getProfileFormDataFromProfile,
  (profile: ProfileFormData) => profile.language,
);
