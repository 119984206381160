import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isUndefined, isNull, isArray, first } from 'lodash';

import { MimeType } from 'src/common/types';
import { DocumentRole } from 'src/models/document';
import { ContractRole } from 'src/models/contract';
import { TemplateRole } from 'src/models/template';
import { formatDate } from 'src/instruments';
import { SortDirection, SortedFields } from 'src/models/sort';
import { setSortDirection, setSortField } from 'src/common/sortStore';
import { useUrlManagerQuery } from 'src/common/hooks/urlManager';
import { PaperCompleteModel, PaperParticipantModel } from 'src/models/paper';
import { CardPaperModel, CardUserIconData } from 'src/models/card';
import { CardParticipantEntity } from 'src/v2/entities/participants';
import {
  getFullName,
  getInitials,
  getOwnerAvatar,
  getOwnerName,
  getShownName,
  getUserFeedObjectsWithInitials,
} from 'src/v2/features/document/selectors';
import { EntityModel } from 'src/models/entity';
import { AttachmentModel } from 'src/models/attachment';

import { CardData, EntityViewMode } from './types';

const convertCardPaperParticipantToUserIconData = (
  participant: CardParticipantEntity<unknown>,
): CardUserIconData | null => {
  if (isUndefined(participant)) return null;

  const firstName = participant.firstName || '';
  const lastName = participant.lastName || '';
  const email = participant.email || '';

  return {
    id: participant.userId,
    fullName: getShownName(firstName, lastName, email),
    initials: getInitials(firstName, lastName, email.substr(0, 2)),
    avatarUrl: participant.avatar || null,
  };
};

export type PaperParticipantsRoles = DocumentRole | ContractRole | TemplateRole;

const convertCardPaperParticipantsToUserIconsData = (
  participants: CardParticipantEntity<unknown>[],
): CardUserIconData[] =>
  participants
    .map(convertCardPaperParticipantToUserIconData)
    .filter((participant): participant is CardUserIconData => !isNull(participant));

export const parseCardPaperDataFactory =
  <R extends PaperParticipantsRoles>(defaultTitle: string, ownerRole: R) =>
  (data: CardPaperModel<unknown, R>): CardData<R> => {
    const { cardPaperParticipant, id } = data;
    if (isUndefined(cardPaperParticipant)) {
      throw Error(
        `parseCardPaperDataFactory: Can't parse card paper - participants not found (paper id = ${id})`,
      );
    }

    const owner = cardPaperParticipant.find((participant) => participant.roleId === ownerRole);
    if (isUndefined(owner)) {
      throw Error(
        `parseCardPaperDataFactory: Can't parse card paper - data owner not found (paper id = ${id})`,
      );
    }
    const name = getFullName(owner.firstName, owner.lastName);
    const avatar = !isUndefined(owner.avatar) ? owner.avatar : null;

    return {
      id: data.id,
      title: data.title || defaultTitle,
      description: data.description || 'No description',
      date: data.createdAt ? formatDate(data.createdAt) : 'Unknown',
      signed: false,
      author: {
        name,
        src: avatar,
      },
      role: data.myRole,
      users: convertCardPaperParticipantsToUserIconsData(cardPaperParticipant),
      status: data.state,
    };
  };

// used only in search should be redone with search
/** @deprecated */
export const parseCardDataFactory =
  <R extends PaperParticipantsRoles>(defaultTitle: string) =>
  (data): CardData<R> => ({
    id: data.id,
    title: data.title || defaultTitle,
    description: data.description || 'No description',
    date: data.createdAt ? formatDate(data.createdAt) : 'Unknown',
    signed: false,
    author: {
      name: getOwnerName(data),
      src: getOwnerAvatar(data),
    },
    role: data.myRole,
    users: getUserFeedObjectsWithInitials(data),
    status: data.state,
  });

// @TODO move to hooks file
export const useChangeUrlSortParams = (
  sortDirectionState: SortDirection = SortDirection.Desc,
  sortedFieldState: SortedFields = SortedFields.Title,
): void => {
  const dispatch = useDispatch();
  const [params] = useUrlManagerQuery();
  const { sortBy = SortedFields.Title, sortDirection = SortDirection.Desc } = params;

  useEffect(() => {
    if (sortDirection !== sortDirectionState) {
      dispatch(setSortDirection(sortDirection as SortDirection));
    }
    if (sortBy !== sortedFieldState) {
      dispatch(setSortField(sortBy as SortedFields));
    }
  }, [dispatch, sortBy, sortDirection, sortDirectionState, sortedFieldState]);
};

export const convertPaperCompleteModelToPaperModel = <T, R>(
  paperComplete: PaperCompleteModel<T, R>,
  userId?: string | null,
) => {
  if (isUndefined(paperComplete.participants))
    throw Error('convertPaperCompleteModelToPaperModel: participants are undefined');

  const paperParticipantModel: PaperParticipantModel<R>[] = paperComplete.participants.map(
    (participant) => {
      if (isUndefined(participant.user)) {
        throw Error('convertPaperCompleteModelToPaperModel: participant user is undefined');
      }

      return {
        ...participant,
        user: participant.user,
      };
    },
  );

  const ownerParticipant = paperParticipantModel.find(
    // @ts-ignore
    (participant) => participant.roleId === DocumentRole.Owner,
  );

  const currentParticipant = paperParticipantModel.find(
    (participant) => participant.userId === userId,
  );

  if (isUndefined(currentParticipant))
    throw Error('convertPaperCompleteModelToPaperModel: current user is not found in participants');

  if (isUndefined(paperComplete.entity))
    throw Error('convertPaperCompleteModelToPaperModel: entity is undefined');

  const entity = paperComplete.entity;

  if (isUndefined(entity.currentCommit.tree)) {
    throw Error('convertPaperCompleteModelToPaperModel: entity tree undefined');
  }

  const tree = entity.currentCommit.tree;

  if (isUndefined(tree.children)) {
    throw Error('convertPaperCompleteModelToPaperModel: entity tree children undefined');
  }

  if (!isArray(entity.locks)) {
    throw Error('convertPaperCompleteModelToPaperModel: entity locks children undefined');
  }

  const attachment = isUndefined(paperComplete.attachment)
    ? []
    : paperComplete.attachment.map((currentAttachment): AttachmentModel => {
        if (Object.values(MimeType).includes(currentAttachment.extension as MimeType)) {
          return attachment as unknown as AttachmentModel;
        }

        throw Error(
          `convertPaperCompleteModelToPaperModel: unsupported extension ${currentAttachment.extension}`,
        );
      });

  const result = {
    ...paperComplete,
    myRole: currentParticipant.roleId,
    paperParticipant: paperParticipantModel,
    entity,
    attachment,
    myParticipant: currentParticipant,
    accessLevel: paperComplete.accessLevel,
  };

  if (!isUndefined(ownerParticipant)) {
    return {
      ...result,
      owner: ownerParticipant.user,
      ownerFunctionRole: ownerParticipant.funcRole,
    };
  }

  return result;
};

const getNormalizedEntity = (currentEntities) =>
  first(
    currentEntities
      .map((currentEntity) => {
        let { field } = currentEntity;
        if (isUndefined(currentEntity.field)) {
          field = [];
        }

        return { ...currentEntity, field };
      })
      .filter((currentEntity): currentEntity is EntityModel => {
        if (isUndefined(currentEntity.currentCommit.tree)) {
          throw Error('convertPaperCompleteModelToPaperModel: entity tree undefined');
        }

        return true;
      }),
  );

export const convertPreviewPaperCompleteModelToPaperModel = (paperComplete) => {
  if (isUndefined(paperComplete.entity))
    throw Error('convertPaperCompleteModelToPaperModel: entity is undefined');

  const currentEntities = paperComplete.entity.length
    ? paperComplete.entity
    : [paperComplete.entity];

  const result = {
    ...paperComplete,
    entity: getNormalizedEntity(currentEntities),
    accessLevel: paperComplete.accessLevel,
  };

  return result;
};

export const getEntityViewMode = ({
  isFile,
  isPdf,
  isOwnerParty,
  hasCounterparty,
  isAllowedToEdit,
}: {
  isFile: boolean;
  isPdf: boolean;
  isOwnerParty: boolean;
  hasCounterparty: boolean;
  isAllowedToEdit: boolean;
}) => {
  if (isFile) return isPdf ? EntityViewMode.PDF : EntityViewMode.File;
  if (isOwnerParty && !hasCounterparty && isAllowedToEdit) return EntityViewMode.Editable;
  return EntityViewMode.Static;
};

export const isParticipantInvitedByUser = <R = DocumentRole>(
  targetParticipant: PaperParticipantModel<R>,
  actorUserId: string | null,
): boolean => {
  if (isNull(actorUserId) || isUndefined(targetParticipant)) {
    return false;
  }

  return targetParticipant.inviterId === actorUserId;
};

export const downloadFileViaLink = (base64: string | ArrayBuffer, name: string) => {
  const ext = name.split('.').pop();
  const parsedArray = name.split(`.${ext}`);
  const realName = parsedArray.join('') + `.${ext}`;
  const link = document.createElement('a');
  link.href = String(base64);
  link.download = realName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
