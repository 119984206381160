import { Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface EventData<T = any> {
  type: T;
}

interface CallbackFunction<T> {
  (e: T): void;
}

export const eventBusFactory = <E extends EventData>() => {
  const eventBus = new Subject<E>();

  const emit = <S extends E = E>(e: S): void => eventBus.next(e);

  const subscribe = (callback: CallbackFunction<E>): Subscription =>
    eventBus.subscribe({
      next: callback,
    });

  const subscribeToEvent = <S extends E = E>(
    typeFilter: E['type'],
    // typeFilter: any,
    callback: CallbackFunction<S>,
    callbackContext: any = null,
  ): Subscription =>
    eventBus
      .pipe(
        filter<E>((event): boolean => {
          return event.type === typeFilter;
        }),
      )
      .subscribe((event): void => {
        try {
          callback.call(callbackContext, event as S);
        } catch (error) {
          console.error(error);
        }
      });

  return {
    eventBus,
    emit,
    subscribe,
    subscribeToEvent,
  };
};
