export const CHECKBOX_ATTRIBUTES = {
  ID: 'id',
  CHECKED: 'checked',
  FILLER: 'data-cke-filler',
  DATA_NEW_VALUE: 'data-new-value',
  DATA_CAN_REVIEW: 'data-can-review',
};
export const CHECKBOX_CLASSES = {
  DW_CHECKBOX: 'dw-checkbox',
  CHECKBOX_ACTIVE: 'dw-checkboxActive',
  CHECKBOX_DISABLED: 'dw-checkboxDisabled',
  CHECKBOX_BUTTONS: 'checkbox-buttons',
  BUTTON: 'btn',
  BUTTON_ACCEPT: 'btn-accept',
  BUTTON_REJECT: 'btn-reject',
  DIFF_CONTAINER: 'dw-checkbox-diff-container',
  DIFF_CHECKBOX: 'diff-checkbox',
  DIFF_CHECKBOX_RED: 'dw-diff-checkbox-red',
  DIFF_CHECKBOX_RED_ACTIVE: 'dw-diff-checkbox-red-active',
  DIFF_CHECKBOX_DISABLE: 'dw-diff-checkbox-disabled',
  DIFF_CHECKBOX_DISABLE_ACTIVE: 'dw-diff-checkbox-disabled-active',
};
