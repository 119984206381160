import React from 'react';
import cn from 'classnames';

interface Props {
  label?: string;
  color?: Color;
  isLoading?: boolean;
  isInline?: boolean;
  style?: React.CSSProperties;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export enum Color {
  Default,
  Green,
  Red,
  Yellow,
  Gray,
}

const cssModifierByColor = {
  [Color.Default]: '',
  [Color.Green]: 'c-button--green',
  [Color.Red]: 'c-button--red',
  [Color.Yellow]: 'c-button--yellow',
  [Color.Gray]: 'c-button--disabled',
};

export const Button: React.FC<Props & React.ButtonHTMLAttributes<{}>> = ({
  label,
  color = Color.Default,
  isLoading,
  isInline,
  disabled,
  children,
  onClick,
  ...restButtonProps
}) => {
  return (
    <button
      onClick={onClick}
      {...restButtonProps}
      disabled={disabled}
      className={cn(
        'c-button',
        cssModifierByColor[color],
        {
          'c-button--has-loader': isLoading,
          'c-button--inline': isInline,
          'c-button--disabled': disabled,
        },
        restButtonProps.className,
      )}
    >
      <span className="c-button__inner">{label || children}</span>
      {isLoading && (
        <span className="c-loader">
          <svg viewBox="25 25 50 50">
            <circle cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
          </svg>
        </span>
      )}
    </button>
  );
};
