import { Folder } from 'src/models/folder';
import { ListItem } from 'src/models/list';
import { NodeTree } from 'src/models/node';
import {
  uniqueSharedContractsName,
  uniqueSharedDocumentsName,
  uniqueSharedProjectsName,
  uniqueSharedTemplatesName,
} from 'src/v2/features/quantumNavigator/consts';

import { FolderNodes } from './types';

export const adaptFoldersToNodes = (folders: Folder[]): FolderNodes[] =>
  folders.map((folder) => ({
    ...folder,
    id: folder.folder_id,
    parentId: folder.parent_id,
    isLoaded: false,
  }));

export const rootFolderName = 'root';

export const rootFolderId = 'root';

type FolderItem = Pick<ListItem, 'parentId' | 'name'>;
export const isRootFolder = (model: Pick<ListItem, 'parentId' | 'name' | 'id'>) =>
  model.name === rootFolderName && model.id === rootFolderId;

const isTopLevelFolder = (model: FolderItem) => model.parentId === '';

export const isContractFolder = (model: FolderItem) =>
  model.name === 'Contracts' && isTopLevelFolder(model);

export const isSharedContractFolder = (model: FolderItem) =>
  (model.name === 'Shared_contracts' && isTopLevelFolder(model)) ||
  model.name === uniqueSharedContractsName;

export const isSharedDocumentFolder = (model: FolderItem) =>
  (model.name === 'Shared_documents' && isTopLevelFolder(model)) ||
  model.name === uniqueSharedDocumentsName;

export const isDocumentFolder = (model: FolderItem) =>
  model.name === 'Documents' && isTopLevelFolder(model);

export const isSharedTemplatesFolder = (model: FolderItem) =>
  (model.name === 'Shared_templates' && isTopLevelFolder(model)) ||
  model.name === uniqueSharedTemplatesName;

export const isTemplatesFolder = (model: FolderItem) =>
  model.name === 'Templates' && isTopLevelFolder(model);

export const isProjectsFolder = (model: FolderItem) =>
  model.name === 'Projects' && isTopLevelFolder(model);

export const isSharedProjectsFolder = (model: FolderItem) =>
  (model.name === 'Shared_projects' && isTopLevelFolder(model)) ||
  model.name === uniqueSharedProjectsName;

export const isSharedFolder = (model: FolderItem) =>
  isSharedDocumentFolder(model) ||
  isSharedContractFolder(model) ||
  isSharedTemplatesFolder(model) ||
  isSharedProjectsFolder(model);

export const folderWithRootFactory = (folders: NodeTree<ListItem>[]): NodeTree<ListItem> => ({
  data: {
    id: rootFolderId,
    name: rootFolderName,
    isLoaded: true,
    parentId: '',
  },
  children: folders,
});

export enum FolderType {
  Documents,
  SharedDocuments,
  Contracts,
  SharedContracts,
  Templates,
  SharedTemplates,
  Projects,
  SharedProjects,
  Other,
  Root,
}

export const getFolderTypeByModelBySimpleFolder = (
  folder: Pick<ListItem, 'parentId' | 'name' | 'id'>,
): FolderType => {
  if (isRootFolder(folder)) return FolderType.Root;
  if (isContractFolder(folder)) return FolderType.Contracts;
  if (isSharedContractFolder(folder)) return FolderType.SharedContracts;
  if (isDocumentFolder(folder)) return FolderType.Documents;
  if (isSharedDocumentFolder(folder)) return FolderType.SharedDocuments;
  if (isTemplatesFolder(folder)) return FolderType.Templates;
  if (isSharedTemplatesFolder(folder)) return FolderType.SharedTemplates;
  if (isProjectsFolder(folder)) return FolderType.Projects;
  if (isSharedProjectsFolder(folder)) return FolderType.SharedProjects;

  return FolderType.Other;
};

export const getFolderTypeByModel = (model: NodeTree<ListItem>): FolderType =>
  getFolderTypeByModelBySimpleFolder(model.data);

export const getIsNotEditableFolder = (model: NodeTree<ListItem>) =>
  getFolderTypeByModel(model) !== FolderType.Other;

export const setFolderName = (model: NodeTree<ListItem>, value: string) => ({
  ...model,
  data: { ...model.data, name: value },
});

export const canCreateFolder = (model: NodeTree<ListItem>) =>
  getFolderTypeByModel(model) !== FolderType.Projects;
