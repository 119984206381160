import { SidebarParticipantEntity } from './participants';

export interface WorkflowEntity {
  id: string;
  document_id: string;
  type: WorkflowType;
  state: WorkflowState;
  owner: WorkflowParty;
  parties: WorkflowParty[];
  created_at: string;
}

export enum WorkflowType {
  Acknowledge = 'acknowledge',
  Negotiation = 'negotiation',
  View = 'view',
}

export enum WorkflowState {
  InWork = 'inWork',
  ReadyToSign = 'readyToSign',
  Complete = 'complete',
}

export interface WorkflowParty {
  entity_id: string;
  entity: string;
  commit_id: string;
  participants: SidebarParticipantEntity[];
  conditions: WorkflowCondition[];
}

export enum WorkflowConditionType {
  Signature = 'signature',
  Acknowledge = 'acknowledge',
}

export enum WorkflowConditionState {
  InWork = 'in_work',
  UserReadyToSign = 'ready_to_sign',
  Complete = 'complete',
}

export enum WorkflowAction {
  Sign = 'sign',
  UserReadyToSign = 'user_ready_to_sign',
  Acknowledge = 'acknowledge',
  Submit = 'submit',
}

export interface WorkflowActor {
  company_name: string;
  company_title: string;
  first_name: string;
  last_name: string;
  signature_hash: string;
  signature: string;
  signature_name: string;
  stamp?: string;
}

export interface WorkflowCondition {
  userId: string;
  type: WorkflowConditionType;
  state: WorkflowConditionState;
  actor: WorkflowActor;
  completedAt: string;
}
