import { EventChannel, SagaIterator } from 'redux-saga';

import { apiClient } from 'src/common/client';
import { ObjectSerialized } from 'src/common/types';
import {
  CreateProjectDTO,
  CreateProjectAPIDTO,
  RemoveProjectDTO,
  EditProjectDTO,
  EditProjectAPIDTO,
  DeleteDocumentFromProjectDTO,
  DeleteContractFromProjectDTO,
} from 'src/v2/boundary/requestDTO/project';
import { CreateDocumentAPIDTO, CreateDocumentDTO } from 'src/v2/boundary/requestDTO/document';
import {
  createDocumentAPI,
  createDocumentFromTemplateAPIFactory,
  createDocumentFromUploadAPI,
} from 'src/api/documents';
import { CreateContractAPIDTO, CreateContractDTO } from 'src/v2/boundary/requestDTO/contract';
import {
  createContractFromTemplateAPIFactory,
  createContractFromUploadAPI,
} from 'src/api/contractsAPI';
import { CopyDocumentToProjectAPIDTO } from 'src/v2/boundary/responseDTO/project';

export function getProjects(): SagaIterator<ObjectSerialized | null> {
  return apiClient.get('/project');
}

export function createProjectAPI(data: CreateProjectDTO) {
  const payload: CreateProjectAPIDTO = {
    data: {
      attributes: data,
    },
  };

  return apiClient.post('/project', payload);
}

export function editProjectAPI(data: EditProjectDTO) {
  const payload: EditProjectAPIDTO = {
    data: {
      attributes: data,
    },
  };

  return apiClient.put(`/project/${data.id}`, payload);
}

export function fetchProjectsAPI(projectId: string) {
  return apiClient.get(`/folder/${projectId}/project`);
}

export function fetchProjectDetailsAPI(projectId: string) {
  return apiClient.get(`/folder/project/${projectId}`);
}

export function createDocumentInProjectAPI(
  paper: CreateDocumentDTO,
): SagaIterator<ObjectSerialized | null> {
  const { role, title, description, subType, subSubType, type } = paper.data;
  const { folderId } = paper;

  const data: CreateDocumentAPIDTO = {
    data: {
      type: 'document',
      attributes: {
        title,
        description,
        containerType: 'project',
        containerId: folderId,
        role,
        subType,
        subSubType,
        type,
      },
    },
  };

  return createDocumentAPI(data);
}

export function createContractInProjectAPI(
  paper: CreateContractDTO,
): SagaIterator<ObjectSerialized | null> {
  const {
    role,
    title,
    description,
    subType,
    subSubType,
    type,
    ownerConditionRequired = false,
  } = paper.data;
  const { folderId } = paper;

  const data: CreateContractAPIDTO = {
    data: {
      type: 'document',
      attributes: {
        title,
        description,
        containerType: 'project',
        containerId: folderId,
        role,
        subType,
        subSubType,
        type,
        ownerConditionRequired,
      },
    },
  };

  return createDocumentAPI(data);
}

export function createDocumentInProjectFromUploadAPI(
  file: File,
  document: CreateDocumentDTO,
): SagaIterator<EventChannel<ObjectSerialized>> {
  return createDocumentFromUploadAPI(file, document, 'project');
}

export function createContractInProjectFromUploadAPI(
  file: File,
  contract: CreateContractDTO,
): SagaIterator<EventChannel<ObjectSerialized>> {
  return createContractFromUploadAPI(file, contract, 'project');
}

export const createDocumentFromTemplateInProjectAPI =
  createDocumentFromTemplateAPIFactory('project');

export const createContractFromTemplateInProjectAPI =
  createContractFromTemplateAPIFactory('project');

export function removeProjectAPI(
  payload: RemoveProjectDTO,
): SagaIterator<EventChannel<ObjectSerialized>> {
  return apiClient.delete(`/project/${payload}`);
}

export function deleteDocumentFromProjectAPI({
  entityId,
  projectId,
}: DeleteDocumentFromProjectDTO): SagaIterator<EventChannel<ObjectSerialized>> {
  return apiClient.delete(`/project/${projectId}/document/${entityId}`);
}

export function deleteContractFromProjectAPI({
  entityId,
  projectId,
}: DeleteContractFromProjectDTO): SagaIterator<EventChannel<ObjectSerialized>> {
  return apiClient.delete(`/project/${projectId}/contract/${entityId}`);
}

export function copyEntityToProjectAPI(
  documentId: string,
  projectId: string,
): SagaIterator<ObjectSerialized | null> {
  const payload: CopyDocumentToProjectAPIDTO = {
    data: {
      type: 'document',
      attributes: {
        documentId,
        containerId: projectId,
        containerType: 'project',
      },
    },
  };
  return apiClient.post(`/documents/copy`, payload);
}
