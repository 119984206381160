import { FC } from 'react';
import { Trans } from 'react-i18next';

import { useToggle } from 'src/common/hooks/useToggle';

import { AgreementCheckbox } from '../AgreementCheckbox';
import { TermsModal } from '../TermsModal';

interface Props {
  agreement: string;
}

export const AcceptAgreement: FC<Props> = ({ agreement }) => {
  const [showTerms, , setShowTermsTrue, setShowTermsFalse] = useToggle();

  return (
    <>
      {showTerms && agreement.length && (
        <TermsModal onClose={setShowTermsFalse}>
          <Trans i18nKey={agreement} />
        </TermsModal>
      )}
      <div className="c-checkbox c-checkbox--theme _c-checkbox--validation c-checkbox__checkout">
        <AgreementCheckbox onLinkClick={setShowTermsTrue} />
      </div>
    </>
  );
};
