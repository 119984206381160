import { initializeDocumentDIContainer } from 'src/v2/features/diFactories/documentDIFactory';
import { isDocumentFolder } from 'src/v2/features/quantumNavigator/utils';
import { createGetRootFolderId } from 'src/v2/features/sharedEntity/createGetRootFolderId';
import { handleErrorSaga } from 'src/utils/handleErrorSaga';
import { copyEntityToProjectAPI } from 'src/api/projectsAPI';
import { copyDocumentToFolderAPI } from 'src/api/documents';

initializeDocumentDIContainer({
  isDocumentFolder,
  createGetRootFolderId,
  handleErrorSaga,
  copyEntityToProjectAPI,
  copyDocumentToFolderAPI,
});
