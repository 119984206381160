const messageCommonDate = {
  position: 'absolute',
  top: 0,
  fontSize: '11px',
  color: '#888',
};

export const styles = {
  chat: {
    p: '0px 15px',
    maxHeight: '250px',
    overflow: 'scroll',
    mt: '8px',
    mb: '16px',
  },

  select: {
    flex: '1',
  },

  loader: {
    backgroundColor: '#e1e1e1',
    fontSize: '12px',
    textAlign: 'center',
    px: '16px',
    py: '12px',
    mt: '8px',
    mb: '14px',
  },

  message: {
    mb: '6px',
    pt: '14px',
    position: 'relative',

    img: {
      width: '100%',
    },
  },

  messageMyText: {
    color: '#fff',
    background: '#7FAFD9',
    borderRadius: '14px',
    fontSize: '14px',
    lineHeight: '20px',
    wordBreak: 'break-word',
    letterSpacing: '0.07px',
    fontFamily: 'HelveticaNeue',
    p: '10px 7px 10px 19px',
  },

  messageInterlocutor: {
    color: '#020202',
    background: '#DDDDDD',
    borderRadius: '14px',
    fontSize: '14px',
    lineHeight: '20px',
    wordBreak: 'break-word',
    letterSpacing: '0.07px',
    fontFamily: 'HelveticaNeue',
    p: '10px 19px 10px 19px',
  },

  messageMyImage: {
    top: '0px',
    left: '-14px',
    width: '35px',
    height: '35px',
    overflow: 'hidden',
    borderRadius: '50%',
    position: 'absolute',
    bg: '#fff',
    boxShadow: '0 0 2px rgb(0 0 0 / 60%)',
  },

  messageInterlocutorImage: {
    top: '0px',
    right: '-14px',
    width: '35px',
    height: '35px',
    overflow: 'hidden',
    borderRadius: '50%',
    position: 'absolute',
    bg: '#fff',
    boxShadow: '0 0 2px rgb(0 0 0 / 60%)',
  },

  messageMyDate: { ...messageCommonDate, right: '4px' },

  messageInterlocutorDate: { ...messageCommonDate, left: '4px' },

  textArea: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    textarea: {
      pr: '25px',
      height: '70px',
      fontSize: '12px',
      borderRadius: '5px',
      border: 'solid 0.5px #707070',
    },
  },

  form: {
    flexDirection: 'column',
    mt: '8px',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    button: {
      height: '36px !important',
      ml: 'auto',
      px: '18px !important',
    },
  },

  controlArea: {
    alignItems: 'center',
    mt: '12px',
    width: '100%',
  },

  severalChatsHint: {
    alignItems: 'center',
    color: '#78828b',
    fontSize: '12px',
    lineHeight: 1.2,
    maxWidth: '170px',
    mr: '20px',
    '.icon': {
      fontSize: '32px',
      mr: '8px',
    },
  },

  cross: {
    width: '16px',
    height: '16px',
    cursor: 'pointer',
    position: 'relative',
    '&:after': {
      content: '""',
      width: '16px',
      height: '2px',
      top: '0px',
      left: '0px',
      background: '#D57872',
      position: 'absolute',
      transform: 'rotate(45deg)',
    },
    '&:before': {
      content: '""',
      width: '16px',
      height: '2px',
      top: '0px',
      left: '0px',
      background: '#D57872',
      position: 'absolute',
      transform: 'rotate(-45deg)',
    },
  },
};
