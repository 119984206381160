import { CardPaperModel } from 'src/models/card';
import { PaperModel } from 'src/models/paper';
import { PaperPayload } from 'src/v2/boundary/actionPayload/paperActions';

export enum ContractRole {
  Owner = 'document_owner',
  Manager = 'document_manager',
  Editor = 'document_editor',
  Signatory = 'document_signatory',
  Commentator = 'document_commentator',
  Viewer = 'document_viewer',
  QRViewer = 'document_qr_viewer',
  SharedViewer = 'document_shared_viewer',
  Downloader = 'document_downloader',
  DocumentSharer = 'document_sharer',
  DocumentSharerDownloader = 'document_sharer_downloader',
  DocumentClippedViewer = 'document_clipped_viewer',
}

export const DefaultContractRole = ContractRole.Viewer;

export enum ContractType {
  billOfSale = 'Bill of Sale',
  consultingAgreement = 'Consulting Agreement',
  disclosureAgreement = 'Disclosure Agreement',
  employmentContract = 'Employment Contract',
  financialAgreement = 'Financial Agreement',
  jointVentureAgreement = 'Joint Venture Agreement ',
  leasingAgreement = 'Leasing Agreement',
  listingAgreement = 'Listing Agreement',
  loanAgreement = 'Loan Agreement',
  medicalRecordsAgreement = 'Medical Records Release',
  mergerAcquisition = 'Merger & Acquisition',
  mortgageAgreement = 'Mortgage Agreement',
  nonDisclosureAgreement = 'Non Disclosure Agreement',
  other = 'Other',
  paymentAgreement = 'Payment Agreement',
  purchasingAgreement = 'Purchasing Agreement',
  salesAgreement = 'Sales Agreement',
  serviceAgreement = 'Service Agreement',
  will = 'Will',
}

export type Contract = PaperModel<ContractType, ContractRole>;
export type ContractCardPaperModel = CardPaperModel<ContractType, ContractRole>;

export interface ContractPayload extends PaperPayload {
  subType?: ContractType;
}
