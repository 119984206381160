import { Subscription } from 'rxjs';
import { eventBusFactory } from 'src/app/eventBus';
import {
  CreatePayload,
  DocumentEvent,
  DocumentEventData,
  DocumentEventDataWithPayload,
  ParticipantEvent,
} from './types';

const eventBusApi = eventBusFactory<DocumentEventData>();

const { emit, subscribeToEvent } = eventBusApi;

export const emitCreate = ({ id, type, folderId }: CreatePayload): void =>
  emit<DocumentEventDataWithPayload>({
    type: DocumentEvent.Create,
    payload: {
      id,
      type,
      folderId,
    },
  });

export const emitDelete = (): void => emit({ type: DocumentEvent.Delete });

export const subscribeForCreate = (
  callback: (eventData: DocumentEventDataWithPayload) => void,
): Subscription => subscribeToEvent<DocumentEventDataWithPayload>(DocumentEvent.Create, callback);

export const subscribeForDelete = (
  callback: (eventData: DocumentEventData) => void,
): Subscription => subscribeToEvent(DocumentEvent.Delete, callback);

export const emitInviteParticipant = (documentId: string) =>
  emit<ParticipantEvent>({ type: DocumentEvent.InviteParticipant, payload: { documentId } });

export const subscribeForInviteParticipant = (
  callback: (eventData: ParticipantEvent) => void,
): Subscription => subscribeToEvent<ParticipantEvent>(DocumentEvent.InviteParticipant, callback);

export const emitEditParticipant = (documentId: string) =>
  emit<ParticipantEvent>({ type: DocumentEvent.EditParticipant, payload: { documentId } });

export const subscribeForEditParticipant = (
  callback: (eventData: ParticipantEvent) => void,
): Subscription => subscribeToEvent<ParticipantEvent>(DocumentEvent.EditParticipant, callback);

export const emitRemoveParticipant = (documentId: string) =>
  emit<ParticipantEvent>({ type: DocumentEvent.RemoveParticipant, payload: { documentId } });

export const subscribeForRemoveParticipant = (
  callback: (eventData: ParticipantEvent) => void,
): Subscription => subscribeToEvent<ParticipantEvent>(DocumentEvent.RemoveParticipant, callback);
