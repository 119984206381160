import React, { PropsWithChildren, useContext } from 'react';

import { Case } from 'src/v2/components/SwitchCase';
import { Sidebar } from 'src/v2/components/Sidebar';
import { SidebarSettingsContext } from 'src/common/contexts/SidebarSettingsContext';
import { SidebarSettings } from 'src/common/types';

interface Props {
  value: string;
  // @todo rm
  sidebarName: string;
}

export const SidebarRoute = (props: PropsWithChildren<Props>): JSX.Element => {
  const { value, children } = props;
  const { isCollapsedMobile: isCollapsed } = useContext<SidebarSettings>(SidebarSettingsContext);

  return (
    <>
      <Case value={value}>
        <Sidebar active={!isCollapsed}>{children}</Sidebar>
      </Case>
    </>
  );
};
