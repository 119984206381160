import { includes } from 'lodash';

import { BillingPeriod, SubscriptionPlan } from 'src/models/billing';

export const canMigrateFromBasicNonePlan = (
  plan: SubscriptionPlan,
  period: BillingPeriod,
): boolean => includes([SubscriptionPlan.Business, SubscriptionPlan.RealEstate], plan);

export const canMigrateFromBasicMonthlyPlan = (
  plan: SubscriptionPlan,
  period: BillingPeriod,
): boolean => includes([SubscriptionPlan.Business, SubscriptionPlan.RealEstate], plan);

export const canMigrateFromBasicAnnuallyPlan = (
  plan: SubscriptionPlan,
  period: BillingPeriod,
): boolean => includes([SubscriptionPlan.Business, SubscriptionPlan.RealEstate], plan);

export const canMigrateFromBusinessMonthlyPlan = (
  plan: SubscriptionPlan,
  period: BillingPeriod,
): boolean =>
  (period === BillingPeriod.Monthly && includes([SubscriptionPlan.RealEstate], plan)) ||
  (period === BillingPeriod.Annually &&
    includes([SubscriptionPlan.Business, SubscriptionPlan.RealEstate], plan));

export const canMigrateFromBusinessAnnuallyPlan = (
  plan: SubscriptionPlan,
  period: BillingPeriod,
): boolean => period === BillingPeriod.Annually && includes([SubscriptionPlan.RealEstate], plan);

export const canMigrateFromRealEstateMonthlyPlan = (
  plan: SubscriptionPlan,
  period: BillingPeriod,
): boolean => period === BillingPeriod.Annually && includes([SubscriptionPlan.RealEstate], plan);

export const canMigrateFromRealEstateAnnuallyPlan = (
  plan: SubscriptionPlan,
  period: BillingPeriod,
): boolean => false;

export const canMigrateFnByPlanAndPeriod: Record<
  SubscriptionPlan,
  Record<BillingPeriod, (plan: SubscriptionPlan, period: BillingPeriod) => boolean>
> = {
  [SubscriptionPlan.Basic]: {
    [BillingPeriod.None]: canMigrateFromBasicNonePlan,
    [BillingPeriod.Monthly]: canMigrateFromBasicMonthlyPlan,
    [BillingPeriod.Annually]: canMigrateFromBasicAnnuallyPlan,
  },
  [SubscriptionPlan.Standard]: {
    [BillingPeriod.None]: () => false,
    [BillingPeriod.Monthly]: () => false,
    [BillingPeriod.Annually]: () => false,
  },
  [SubscriptionPlan.Business]: {
    [BillingPeriod.None]: () => false,
    [BillingPeriod.Monthly]: canMigrateFromBusinessMonthlyPlan,
    [BillingPeriod.Annually]: canMigrateFromBusinessAnnuallyPlan,
  },
  [SubscriptionPlan.Premium]: {
    [BillingPeriod.None]: () => false,
    [BillingPeriod.Monthly]: () => false,
    [BillingPeriod.Annually]: () => false,
  },
  [SubscriptionPlan.Teams]: {
    [BillingPeriod.None]: () => false,
    [BillingPeriod.Monthly]: () => false,
    [BillingPeriod.Annually]: () => false,
  },
  [SubscriptionPlan.Enterprise]: {
    [BillingPeriod.None]: () => false,
    [BillingPeriod.Monthly]: () => false,
    [BillingPeriod.Annually]: () => false,
  },
  [SubscriptionPlan.RealEstate]: {
    [BillingPeriod.None]: () => false,
    [BillingPeriod.Monthly]: canMigrateFromRealEstateMonthlyPlan,
    [BillingPeriod.Annually]: canMigrateFromRealEstateAnnuallyPlan,
  },
  [SubscriptionPlan.Vip]: {
    [BillingPeriod.None]: () => false,
    [BillingPeriod.Monthly]: () => false,
    [BillingPeriod.Annually]: () => false,
  },
};

export const canMigrateTo = (
  currentPlan: SubscriptionPlan,
  currentPeriod: BillingPeriod,
  migratedToPlan: SubscriptionPlan,
  migratedToPeriod: BillingPeriod,
): boolean =>
  canMigrateFnByPlanAndPeriod[currentPlan][currentPeriod](migratedToPlan, migratedToPeriod);
