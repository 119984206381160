import React from 'react';
import { Link } from 'src/v2/components/Link';

interface Props {
  link: string;
  icon?: () => JSX.Element;
  className?: string;
  external?: boolean;
  title?: string;
  onClick?: (event: React.MouseEvent) => void;
}

export const MenuItem = ({
  link,
  icon: Icon,
  className = '',
  external,
  title,
  onClick,
  ...other
}: Props): JSX.Element => (
  <Link
    className={`c-menu__link ${className}`}
    to={link}
    external={external}
    {...other}
    title={title}
    onClick={onClick}
  >
    <div className="c-menu__icon">{Icon ? <Icon /> : null}</div>
  </Link>
);
