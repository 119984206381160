import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNull, isEqual, isNil } from 'lodash';
import { useNavigate } from 'react-router-dom';

import {
  getIsCreating,
  deleteDocument as deleteEntity,
} from 'src/v2/features/documentList/documentListSlice';
import { getIsLoading } from 'src/v2/features/createFromTemplate/selectors';
import { CreationMode } from 'src/api/documents';
import { PaperModel } from 'src/models/paper';
import { DocumentType, DocumentRole } from 'src/models/document';
import { canDeleteDocument as canDeleteEntity } from 'src/v2/features/document/permissions/documentPermissions/documentPermissions';
import { loadSidebarData } from 'src/v2/features/sharedEntity/EntitySidebar/sagas';
import { LoadSidebarDataPayload } from 'src/v2/features/sharedEntity/EntitySidebar/types';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

const useIsCreatingEntity = () => {
  const isCreatingBlank = useSelector(getIsCreating);
  const isCreatingFromTemplate = useSelector(getIsLoading);

  return isCreatingBlank || isCreatingFromTemplate;
};

const useCreatePaperStrategy = <T>(
  createPaper: ActionCreatorWithPayload<T>,
  createFromTemplate: ActionCreatorWithPayload<T & { sourceId: string }>,
): {
  [CreationMode.blank]: (data: T) => void;
  [CreationMode.template]: (data: T, templateId: string) => void;
} => {
  const dispatch = useDispatch();

  return {
    [CreationMode.blank]: (data: T): void => {
      dispatch(createPaper(data));
    },
    [CreationMode.template]: (data: T, templateId: string): void => {
      dispatch(
        createFromTemplate({
          ...data,
          sourceId: templateId,
        }),
      );
    },
  };
};

export const useCreatePaperPageData = <
  T extends {
    createDocumentOption: CreationMode;
  },
  DTO,
>(
  convertFormPayloadToDTO: (payload: T) => DTO,
  createPaper: ActionCreatorWithPayload<DTO>,
  createFromTemplate: ActionCreatorWithPayload<DTO & { sourceId: string }>,
): {
  creationMode: CreationMode;
  isLoading: boolean;
  data: T | undefined;
  onFormSubmit: (value: T) => void;
  onLibrarySectionSubmit: (templateId: string) => void;
} => {
  const [creationMode, setCreationMode] = useState(CreationMode.blank);
  const [prePopulatedMetadata, setPrePopulatedMetadata] = useState<T>();
  const createDocumentStrategies = useCreatePaperStrategy<DTO>(createPaper, createFromTemplate);
  const isLoading = useIsCreatingEntity();

  const onFormSubmit = useCallback(
    (value: T) => {
      const { createDocumentOption } = value;
      const data = convertFormPayloadToDTO(value);

      setPrePopulatedMetadata(value);

      if (createDocumentOption === CreationMode.blank) {
        createDocumentStrategies[createDocumentOption](data);
      } else {
        setCreationMode(createDocumentOption);
      }
    },
    [createDocumentStrategies, convertFormPayloadToDTO],
  );

  const onLibrarySectionSubmit = useCallback(
    (templateId: string): void => {
      if (prePopulatedMetadata) {
        const data = convertFormPayloadToDTO(prePopulatedMetadata);
        createDocumentStrategies[CreationMode.template](data, templateId);
      }
    },
    [createDocumentStrategies, prePopulatedMetadata, convertFormPayloadToDTO],
  );

  return {
    creationMode,
    isLoading,
    data: prePopulatedMetadata,
    onFormSubmit,
    onLibrarySectionSubmit,
  };
};

export const useCreateDeleteHandlerByEntityId = ({
  entity,
  backUrlAfterSuccess,
}: {
  entity: PaperModel<DocumentType, DocumentRole> | null;
  backUrlAfterSuccess: string;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return useCallback((): void => {
    if (!isNull(entity) && canDeleteEntity(entity.state)) {
      dispatch(deleteEntity(entity.id));

      navigate(backUrlAfterSuccess);
    }
  }, [navigate, entity, dispatch, backUrlAfterSuccess]);
};

export const useLoadSidebarDataEffct = (payload: LoadSidebarDataPayload | null) => {
  const dispatch = useDispatch();
  const payloadRef = useRef<LoadSidebarDataPayload | null>();

  useEffect(() => {
    if (payload && !isEqual(payloadRef.current, payload) && !isNil(payload)) {
      dispatch(loadSidebarData(payload));
      payloadRef.current = payload;
    }
  }, [dispatch, payload]);
};
