// Core
import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import cn from 'classnames';
import { FileRejection, useDropzone } from 'react-dropzone';
import { isUndefined } from 'lodash';

import { UploadableFile } from 'src/v2/features/fileUpload/types';
import { MimeType } from 'src/common/types';
import { UploadedEntityExtension } from 'src/v2/features/sharedEntity/types';
import { extensionByMimeType } from 'src/v2/components/FileUpload/types';

import { DragContentPlaceholder } from './components/DragContentPlaceholder';
import { FilesListOrPlaceholder } from './components/FilesListOrPlaceholder';

const defaultAccept = Object.values(MimeType);

const typesSeparator = ', ';
const defaultMimeType = 'application/octet-stream';

const getFileExtensionFromName = (name: string): UploadedEntityExtension | undefined => {
  const extension = name.split('.').pop();
  return !isUndefined(extension) && !isUndefined(UploadedEntityExtension[extension])
    ? UploadedEntityExtension[extension]
    : undefined;
};

const getTypeFromExtension = (extension: UploadedEntityExtension) => MimeType[extension];

const getModifiedFileCopy = (source: File): File => {
  const extension = getFileExtensionFromName(source.name);
  const type = !isUndefined(extension) ? getTypeFromExtension(extension) : defaultMimeType;
  const fileParts = source.slice(0, source.size, type);
  const fileMod = new File([fileParts], source.name, {
    type,
    lastModified: source.lastModified,
  });

  return fileMod;
};

interface Props {
  files: UploadableFile[];
  multiple?: boolean;
  accept?: MimeType[];
  validate?: (files?: File[]) => boolean;
  onDropValid: Function;
  onValidationError?: (files: FileRejection[]) => void;
  onRetry?: (fileId: string) => void;
  onCancel?: (fileId: string) => void;
  disabled?: boolean;
}

export const FilesUploader: React.FC<PropsWithChildren<Props>> = ({
  files,
  multiple = false,
  children,
  accept = defaultAccept,
  validate = (): boolean => true,
  onDropValid,
  onValidationError,
  onRetry,
  onCancel,
  disabled = false,
}) => {
  const onDropRejected = (files: FileRejection[]): void => {
    if (onValidationError) onValidationError(files);
  };

  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      if (!validate(acceptedFiles)) return;

      acceptedFiles.forEach((file) => {
        const fileCopy = getModifiedFileCopy(file);
        onDropValid(fileCopy);
      });
    },
    [validate, onDropValid],
  );

  const finalAccept = useMemo(() => {
    const extensions = accept.map((type) => `.${extensionByMimeType[type]}`).join(typesSeparator);
    return extensions + typesSeparator + accept.join(typesSeparator);
  }, [accept]);

  const { getRootProps, getInputProps, isDragActive, isDragReject, isDragAccept } = useDropzone({
    accept: finalAccept,
    multiple,
    onDropAccepted,
    onDropRejected,
    disabled,
  });

  // @TODO fixme later
  // this is known issue that sometimes even valid files are being reject after first drop
  // const { base, rejected, accept: acceptStyle } = styles.container;
  const finalStyles = useMemo(
    () =>
      cn('c-attachment__upload', {
        'c-attachment__upload--valid': isDragAccept,
        'c-attachment__upload--invalid': isDragReject,
      }),
    [isDragReject, isDragAccept],
  );

  return (
    <div className="c-attachment__row" {...getRootProps()}>
      <div className={finalStyles}>
        <input type="file" id="c-attachment__upload" hidden {...getInputProps()} />

        {isDragActive ? (
          <DragContentPlaceholder>{children}</DragContentPlaceholder>
        ) : (
          <FilesListOrPlaceholder files={files} onRetry={onRetry} onCancel={onCancel}>
            {children}
          </FilesListOrPlaceholder>
        )}
      </div>
    </div>
  );
};
