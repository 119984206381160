import { CompleteObject } from 'src/common/types';

export interface NotificationEntity extends CompleteObject {
  notification: string;
  createdAt: Date;
  viewed: boolean;
  target: NotificationTarget;
  template_notification: string; // example: "{{inviterName}} has made template {{documentTitle}} available for you to use"
  notification_payload: { key: string; value: string }[];
}

export interface NotificationTarget {
  id: string;
  type: NotificationTargetType;
}

export interface NotificationData {
  key: string;
  value: string;
}

export enum NotificationTargetType {
  Document = 'document',
  Contract = 'contract',
  Template = 'template',
  Chat = 'chat',
}

export enum NotificationTemplateItemType {
  InviterName = 'inviterName',
  DocumentTitle = 'documentTitle',
}
