import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, omit } from 'lodash';

import { ContractPayload } from 'src/models/contract';
import { getOwnerName } from 'src/v2/features/document';
import { updateDocument, getIsCreating } from 'src/v2/features/documentList/documentListSlice';
import { useGetEntityById, ParticipantsSection } from 'src/v2/features/sharedEntity';
import { useGetOwnerConditionRequired } from 'src/v2/features/entityParticipants/hooks';
import { ContractType, ContractRole } from 'src/models/contract';
import { EntityType } from 'src/models/paper';
import {
  useCanEditParticipant,
  useCanRemoveParticipant,
  useCanChangeOwnerCondition,
} from 'src/v2/features/contract/workflow/contractWorkflow';

import { ContractMetadataEdit } from '../../components/ContractMetadata';

interface Props {
  id: string;
}

export const Edit: React.FC<Props> = ({ id }) => {
  const dispatch = useDispatch();
  const contract = useGetEntityById<ContractType, ContractRole>(id);
  const ownerConditionRequired = useGetOwnerConditionRequired(id);
  const isAllowedToEditParticipant = useCanEditParticipant(id);
  const isAllowedToRemoveParticipant = useCanRemoveParticipant(id);
  const isAllowedToChangeOwnerCondition = useCanChangeOwnerCondition(id);

  const onSubmit = useCallback(
    (data: ContractPayload) => {
      const payload = omit(data, 'createDocumentOption') as ContractPayload;

      if (!contract) return;

      dispatch(
        updateDocument({
          id: get(contract, 'id'),
          data: { ...payload, type: EntityType.contract },
        }),
      );
    },
    [dispatch, contract],
  );

  // @TODO: Use proper selector after refactoring @Dimich-x33
  const isUpdating = useSelector(getIsCreating);

  return contract ? (
    <>
      <ContractMetadataEdit
        isLoading={isUpdating}
        onSubmit={onSubmit}
        id={contract.id}
        title={contract.title}
        description={contract.description}
        createdAt={contract.createdAt}
        subType={contract.subType}
        subSubType={contract.subSubType}
        owner={getOwnerName(contract)}
        state={contract.state}
        role={contract.ownerFunctionRole}
        ownerConditionRequired={ownerConditionRequired}
        isAllowedToChangeOwnerCondition={isAllowedToChangeOwnerCondition}
      />
      <ParticipantsSection
        entityId={contract.id}
        isAllowedToEditParticipant={isAllowedToEditParticipant}
        isAllowedToRemoveParticipant={isAllowedToRemoveParticipant}
      />
    </>
  ) : null;
};
