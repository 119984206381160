import { FC } from 'react';

import { formatToLocaleString } from 'src/utils/dates';
import { normalizeAvatar } from 'src/v2/features/profile';
import { decodeContentToDomainUrl } from 'src/utils/url';

import { getFullName } from '../document/selectors';

import { ChatMember } from './types';
interface Props {
  author: ChatMember;
  text: string;
  createdAt: Date;
  currentUserId: string | null;
}

export const MessageItem: FC<Props> = ({ author, text, createdAt, currentUserId }) => {
  const isSender = author.id === currentUserId;
  const messageClassName = isSender ? 'c-message__post--sender' : 'c-message__post--recipient';

  return (
    <div className={`c-message__post ${messageClassName}`}>
      <div className="c-message__photo" title={getFullName(author.firstName, author.lastName)}>
        <img src={decodeContentToDomainUrl(normalizeAvatar(author.avatar))} alt="" />
      </div>
      <div className="c-message__date">{formatToLocaleString(createdAt)}</div>
      <div className="c-message__text">{text}</div>
    </div>
  );
};
