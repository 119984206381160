import { env } from 'src/env';
import { SocketConnectionId } from 'src/v2/boundary/socket';

import { SocketGatewayConnectionConfig, SocketConnectionOptions } from './types';

export function getConnectionsConfig(): SocketGatewayConnectionConfig[] {
  // @ts-ignore
  const configMap = new Map<string, [string, string, SocketConnectionOptions]>([
    [SocketConnectionId.Chat, [env.CHAT_SOCKET_GATEWAY, env.CHAT_SOCKET_DOMAINS]],
    [
      SocketConnectionId.Document,
      [env.DOCUMENT_SOCKET_GATEWAY, env.DOCUMENT_SOCKET_DOMAINS, { keepAlive: true }],
    ],
    [SocketConnectionId.Workflow, [env.WORKFLOW_SOCKET_GATEWAY, env.WORKFLOW_SOCKET_DOMAINS]],
    [
      SocketConnectionId.Notifications,
      [env.NOTIFICATIONS_SOCKET_GATEWAY, env.NOTIFICATIONS_SOCKET_DOMAINS],
    ],
    [SocketConnectionId.Folder, [env.FOLDER_SOCKET_GATEWAY, env.FOLDER_SOCKET_DOMAINS]],
  ]);

  return Array.from(configMap).map(
    ([name, [url, domains, options]]): SocketGatewayConnectionConfig => {
      if (!/(?:[\w]+,)*[\w]+/g.test(domains || '')) {
        throw new Error(`Bad format of domains specified for service ${name}`);
      }

      return {
        id: name as SocketConnectionId,
        domains: domains.split(','),
        url,
        options,
      };
    },
  );
}
