import { createContext } from 'react';
import { noop } from 'lodash';

import { TextSignatureContextValue } from '../types';

export const TextSignatureContext = createContext<TextSignatureContextValue>({
  text: null,
  font: null,
  updateText: noop,
  updateFont: noop,
});
