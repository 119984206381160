import React, { useCallback, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Document } from 'src/models/document';

import { useQuery } from 'src/utils/hooks';
import { SearchParams } from 'src/api/searchApi';
import { getSearchUrlParams, makeSearchParams } from 'src/utils/search';
import { buildUrlParams } from 'src/utils/url';
import { fetchSearchList } from 'src/v2/features/search/store/searchActions';
import {
  getSearchList,
  isLoading,
  getCounters,
  getError,
  getKeyword,
} from 'src/v2/features/search/store/searchSelectors';
import { Counters, SearchEntityType } from 'src/v2/features/search/store/searchTypes';
import { CardsList, RowLine } from 'src/v2/features/search/components';

import { book } from 'src/app/book';
import { RootState } from 'src/app/types';
import { getSortDirection, getSortField } from 'src/common/sortStore';
import { useDefaultSortEffct } from 'src/common/hooks/useDefaultSort';
import { useChangeUrlSortParams } from 'src/v2/features/sharedEntity/hooks';

import { setSeeMoreType } from 'src/v2/features/search/store/searchReducer';
import { EntityType } from 'src/models/paper';
import { ContractsCardGrid } from '../ContractsCardGrid';
import { DocumentsCardGrid } from '../DocumentsCardGrid';
import { TemplatesCardGrid } from '../TemplatesCardGrid';
import { SearchLayout } from '../SearchLayout';

const cardListLength = 5;

interface StateProps {
  filter: string;
  list: Document[];
  counters: Counters;
  isLoading: boolean;
  error: string;
}

interface DispatchProps {
  fetchSearchList: (param: SearchParams) => void;
}

interface Props extends StateProps, DispatchProps {}

export const filterEntitiesByTypes = (list: Document[], type: EntityType): Document[] => {
  return list.filter((item) => type === item.type).slice(0, cardListLength);
};

export const filterTemplatesByTypes = (list: Document[], type: EntityType): Document[] => {
  return list.filter((item) => type === item.type).slice(0, cardListLength);
};

const SearchPage: React.FC<Props> = ({ list, fetchSearchList, counters, filter, isLoading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();

  const { keyword, subType, type, subSubType, author, refineKeyword } = getSearchUrlParams(query);
  const sortDirection = useSelector(getSortDirection);
  const sortField = useSelector(getSortField);
  useChangeUrlSortParams(sortDirection, sortField);
  useDefaultSortEffct();

  const handleSeeMoreClick = useCallback(
    (seeMoreType: SearchEntityType) => {
      const searchParams = getSearchUrlParams(query);

      const params: SearchParams = makeSearchParams({
        ...searchParams,
        keyword: filter || searchParams.keyword,
        seeMore: seeMoreType,
      });

      dispatch(setSeeMoreType(seeMoreType));
      navigate(`${book.search.all}${buildUrlParams(params)}`);
    },
    [dispatch, query, filter, navigate],
  );

  useEffect((): void => {
    fetchSearchList({
      keyword,
      subSubType,
      subType,
      type,
      author,
      refineKeyword,
      sortBy: sortField,
      sortDirection,
    });
  }, [
    fetchSearchList,
    keyword,
    subSubType,
    subType,
    type,
    author,
    refineKeyword,
    sortField,
    sortDirection,
  ]);

  return (
    <SearchLayout isLoading={isLoading}>
      <CardsList
        title="Document"
        count={counters.documents}
        type={SearchEntityType.Document}
        onSeeMoreClick={handleSeeMoreClick}
      >
        <DocumentsCardGrid list={filterEntitiesByTypes(list, EntityType.document)} />
      </CardsList>
      <RowLine />

      <CardsList
        title="Contract"
        count={counters.contracts}
        type={SearchEntityType.Contract}
        onSeeMoreClick={handleSeeMoreClick}
      >
        <ContractsCardGrid list={filterEntitiesByTypes(list, EntityType.contract)} />
      </CardsList>
      <RowLine />

      <CardsList
        title="Document Templates"
        count={counters.documentTemplates}
        type={SearchEntityType.DocumentTemplate}
        onSeeMoreClick={handleSeeMoreClick}
      >
        <TemplatesCardGrid list={filterTemplatesByTypes(list, EntityType.document)} />
      </CardsList>

      <CardsList
        title="Contract Templates"
        count={counters.contractTemplates}
        type={SearchEntityType.ContractTemplate}
        onSeeMoreClick={handleSeeMoreClick}
      >
        <TemplatesCardGrid list={filterTemplatesByTypes(list, EntityType.contract)} />
      </CardsList>
    </SearchLayout>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  filter: getKeyword(state),
  counters: getCounters(state),
  list: getSearchList(state),
  isLoading: isLoading(state),
  error: getError(state),
});

const mapDispatchToProps: DispatchProps = {
  fetchSearchList,
};

const ConnectedSearchPage = connect(mapStateToProps, mapDispatchToProps)(SearchPage);
export { ConnectedSearchPage as SearchPage };
