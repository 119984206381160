import { PropsWithChildren, ReactNode } from 'react';
import cn from 'classnames';
import { isNil } from 'lodash';

interface Props {
  header?: ReactNode;
  navigation?: ReactNode;
  sidebar?: ReactNode;
  mainClassName?: string;
  modal?: ReactNode | null | undefined;
}

// Can be fully replaced with BaseLayout, only used in wizard layout atm
// D20-1038
/* @deprecated */
export const PublicLayout = ({
  children,
  header,
  navigation,
  sidebar,
  mainClassName,
  modal,
}: PropsWithChildren<Props>): JSX.Element => {
  const isContentShadowed = !isNil(modal) ? 'l-preview--shadow' : '';

  return (
    <div className="l-app">
      <div className={`l-preview ${isContentShadowed}`}>
        {header}
        {navigation}
        <main className={cn('l-main', mainClassName)}>{children}</main>
        {modal}
      </div>
      {sidebar}
    </div>
  );
};
