import { createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState, CommonState } from 'src/app/types';

const commonInitialState: CommonState = {
  isSidebarOpen: false,
  isSidebarHidden: false,
};

const commonSlice = createSlice({
  name: 'common',
  initialState: commonInitialState,
  reducers: {
    toggleSidebarOpen: (state: CommonState) => {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    toggleSidebarHidden: (state: CommonState) => {
      state.isSidebarHidden = !state.isSidebarHidden;
    },
    hideSidebar: (state: CommonState) => {
      state.isSidebarHidden = true;
    },
    showSidebar: (state: CommonState) => {
      state.isSidebarHidden = false;
    },
    resetSidebarState: () => commonInitialState,
  },
});

export const commonReducer = commonSlice.reducer;
export const {
  toggleSidebarOpen,
  toggleSidebarHidden,
  hideSidebar,
  showSidebar,
  resetSidebarState,
} = commonSlice.actions;

const getState = (state: RootState) => state.common;

export const getIsSidebarOpen = createSelector(getState, (state) => state.isSidebarOpen);
export const getIsSidebarHidden = createSelector(getState, (state) => state.isSidebarHidden);
