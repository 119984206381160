import { CompleteObject } from 'src/common/types';

export interface Author extends CompleteObject {
  firstName: string;
  lastName: string;
}
export interface Counters {
  contracts: number;
  documents: number;
  contractTemplates: number;
  documentTemplates: number;
}

export enum SearchEntityType {
  Document = 'Document',
  Contract = 'Contract',
  DocumentTemplate = 'Document Template',
  ContractTemplate = 'Contract Template',
}
