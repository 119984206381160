// Core
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { isString, get, isEmpty, keys, size } from 'lodash';
import { toastr } from 'react-redux-toastr';

// Components
import { State, CountryCode } from 'src/instruments/countries';
import { getStateList, useCountryListMemo } from 'src/utils/countries';
import {
  getCurrentOrganizationLogo,
  getCurrentOrganizationInfo,
  getIsLoading,
} from 'src/shared/organization';
import { TemplateIndustryType } from 'src/models/template';
import { useAuthAndFillOrganization } from 'src/v2/features/profile/hooks';
import { validateOrganizationInformationForm } from 'src/utils/validate';
import { CForm, CFormRow } from 'src/v2/components/CForm';
import { Input, Select } from 'src/v2/features/reduxForm';
import { getProfileFormDataFromProfile } from 'src/v2/features/profile';
import { handleFileChange, dataURLtoFile, readImageFile, changeImageHash } from 'src/utils/files';
import defaultCompanyLogo from 'src/assets/placeholders/company-avatar.png';
import { Avatar } from 'src/v2/components/Avatar';
import { ImageEditor } from 'src/v2/components/ImageEditor/ImageEditor';
import { AVATAR_SUPPORTED_UPLOAD_FORMATS } from 'src/v2/features/profile/constants';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { SearchableSelect } from 'src/v2/features/reduxForm/SeachableSelect';
import { ProfileFormData } from 'src/v2/features/profile/types';
import { setOrganizationLogo } from 'src/v2/features/organization';

import { getOrganizationCountry } from './store';
import { FormHeader } from './components/FormHeader';
import { MimeType } from '../../../common/types';

import { RootState } from 'src/app/types';

export interface FormProps {
  companyName: string;
  industry: string;
  state: string;
  country: string;
  jobTitle: string;
  address: string;
  city: string;
  zipCode: string;
}

const supportedUploadFormats = AVATAR_SUPPORTED_UPLOAD_FORMATS.join(',');

export const organizationInformationFormName = 'signup-org-info';

const industryOptions = keys(TemplateIndustryType).map((key: string) => {
  const value = get(TemplateIndustryType, key);
  return { label: value, value };
});

export const Form: React.FC<InjectedFormProps<FormProps>> = ({ handleSubmit, change }) => {
  const authUser = useAuthAndFillOrganization();
  const dispatch = useDispatch();
  const [imageFile, setImageFile] = useState<string | ArrayBuffer | null>(null);

  const organizationLogo = useSelector(getCurrentOrganizationLogo);

  const normalizeAvatar = isEmpty(organizationLogo) ? defaultCompanyLogo : organizationLogo;

  const onUpdateOrganizationLogo = useCallback(
    (file: File) => {
      dispatch(
        setOrganizationLogo({
          file,
          user: authUser || { userId: '', companyId: '', token: '' },
        }),
      );
    },
    [authUser, dispatch],
  );

  const isLoading = useSelector(getIsLoading);
  const country = useSelector(getOrganizationCountry);
  const stateList = useMemo(() => getStateList(country), [country]);
  const [sameAsProfileAddress, setSameAsProfileAddress] = useState(false);
  const profileInfo: ProfileFormData = useSelector((state: RootState) =>
    getProfileFormDataFromProfile(state),
  );

  const statesOptions = useMemo(
    () => stateList.map((el: State) => ({ label: el.name, value: el.code })),
    [stateList],
  );
  const areStatesAvailable = useMemo(() => size(statesOptions) > 0, [statesOptions]);

  const setLogoFile = useCallback((files: File): void => {
    !AVATAR_SUPPORTED_UPLOAD_FORMATS.includes(files.type as MimeType)
      ? toastr.error(` ${i18n(translationKeys.errors.FILE_FORMAT_ERROR)}`, '')
      : readImageFile(files).then((image: HTMLImageElement): void => {
          setImageFile(changeImageHash(image.src));
        });
  }, []);

  const setLogoImage = useCallback(
    (dataURL: string): void => {
      if (dataURL.length) {
        const imageFile: File = dataURLtoFile(dataURL);
        onUpdateOrganizationLogo(imageFile);
        setImageFile(null);
      }
    },
    [onUpdateOrganizationLogo],
  );

  const handleImageSaved = useCallback(
    (dataURL: string): void => {
      setLogoImage(dataURL);
    },
    [setLogoImage],
  );

  useEffect(() => {
    if (sameAsProfileAddress && profileInfo) {
      change('address', profileInfo.address);
      change('city', profileInfo.city);
      change('state', profileInfo.state);
      change('country', profileInfo.country);
      change('zipCode', profileInfo.zipCode);
      setSameAsProfileAddress(false);
    }
  }, [sameAsProfileAddress, change, profileInfo]);

  const handleFileClick = useCallback((event: React.MouseEvent<HTMLInputElement>) => {
    event.currentTarget.value = '';
  }, []);

  const countriesOptions = useCountryListMemo('Country');

  return (
    <>
      <FormHeader
        title={i18n(translationKeys.organization.informationTitle)}
        description={i18n(translationKeys.organization.informationText)}
      />

      <div className="c-setup__content">
        <CForm data-id="signup-form-org-ingo" onSubmit={handleSubmit} noValidate>
          <CFormRow required>
            <Field
              className="c-form__column"
              name="companyName"
              type="text"
              placeholder={i18n(translationKeys.forms.info.companyName)}
              autoComplete="off"
              component={Input}
              required
            />
          </CFormRow>
          <CFormRow required>
            <Field
              className="c-form__column"
              name="jobTitle"
              type="text"
              placeholder={i18n(translationKeys.forms.info.job)}
              autoComplete="off"
              component={Input}
              required
            />
          </CFormRow>
          <CFormRow required>
            <Field
              className="c-form__column"
              name="industry"
              placeholder={i18n(translationKeys.forms.info.industry)}
              component={Select}
              options={industryOptions}
              required
            />
          </CFormRow>
          <CFormRow required>
            <Field
              className="c-form__column"
              name="address"
              type="text"
              placeholder={i18n(translationKeys.forms.info.address)}
              autoComplete="off"
              component={Input}
              required
            />
          </CFormRow>
          <CFormRow required>
            <Field
              className="c-form__column"
              name="city"
              type="text"
              placeholder={i18n(translationKeys.forms.info.city)}
              autoComplete="off"
              component={Input}
              required
            />
          </CFormRow>
          <CFormRow required>
            <Field
              className="c-form__column"
              name="country"
              placeholder={i18n(translationKeys.forms.info.countrySelect)}
              data-id="profileCountry"
              component={SearchableSelect}
              defaultValue={country}
              options={countriesOptions}
              required
              onChange={() => change('state', null)}
            />
          </CFormRow>
          <CFormRow required={areStatesAvailable}>
            <Field
              className="c-form__column c-form__column--2"
              name="state"
              data-id="profileState"
              placeholder={i18n(translationKeys.forms.info.stateSelect)}
              component={SearchableSelect}
              options={statesOptions}
              disabled={!areStatesAvailable}
              mb8
            />
            <Field
              className="c-form__column c-form__column--2"
              name="zipCode"
              type="text"
              placeholder={i18n(translationKeys.forms.info.zipCode)}
              autoComplete="off"
              component={Input}
              required={!areStatesAvailable}
            />
          </CFormRow>
          <CFormRow required>Required</CFormRow>
        </CForm>
        <div className="l-row">
          <CFormRow>
            <div className="c-title c-title--color-theme c-title--fs-24">
              {i18n(translationKeys.organization.customizedBrandingTitle)}
            </div>
          </CFormRow>
          <div className="l-row l-row--mt-16">
            <div className="c-title c-title--lh-16 c-title--fw-400">
              {i18n(translationKeys.organization.customizedBrandingText)}
            </div>
          </div>
        </div>
        <div className="l-row l-row--mt-16">
          <div className="c-setup__user">
            <div className="c-setup__avatar">
              <input
                type="file"
                hidden
                id="avatar"
                accept={supportedUploadFormats}
                onChange={handleFileChange(setLogoFile)}
                onClick={handleFileClick}
              />
              <Avatar src={normalizeAvatar} />
            </div>
            <label className="c-setup__add" htmlFor="avatar">
              {isLoading
                ? i18n(translationKeys.messages.loading)
                : i18n(translationKeys.buttons.addLogo)}
            </label>
          </div>
        </div>
        {imageFile && isString(imageFile) && (
          <div className="l-row">
            <ImageEditor
              src={imageFile}
              style={{ height: 192, width: '100%' }}
              onSave={handleImageSaved}
            />
          </div>
        )}
      </div>
    </>
  );
};

export const OrgInfoReduxForm = reduxForm<FormProps>({
  form: organizationInformationFormName,
  enableReinitialize: true,
  validate: validateOrganizationInformationForm,
})(Form);

const getOrganizationFormValues = (state: RootState): FormProps | undefined => {
  const organizationInfo = getCurrentOrganizationInfo(state);

  if (!organizationInfo) return undefined;

  return {
    companyName: organizationInfo.organizationName,
    industry: organizationInfo.industry || get(industryOptions, '[0].value'),
    state: '',
    country: CountryCode.US,
    address: '',
    city: '',
    zipCode: '',
    jobTitle: '',
  };
};

export const OrganizationInformationForm = connect((state: RootState) => ({
  initialValues: getOrganizationFormValues(state),
}))(OrgInfoReduxForm);
