import { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, InjectedFormProps, FormState } from 'redux-form';
import { required, email } from 'redux-form-validators';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { createSelector } from '@reduxjs/toolkit';
import { isEmpty, isNil, isNull } from 'lodash';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { useCountryListMemo, useStateListMemo } from 'src/utils/countries';
import { Button } from 'src/v2/features/button';
import { Input, Select } from 'src/v2/features/reduxForm';
import { validateEmail, validatePostalCode } from 'src/utils/validate';
import { BillingDetailsFormValues, PaymentError } from 'src/v2/features/checkout/types';
import {
  getIsLoading as getIsCheckoutLoading,
  getError,
  getCardError,
} from 'src/v2/features/checkout/store';
import { StripeCard } from 'src/v2/features/checkout/StripeCard';
import { StripeInput } from 'src/v2/features/checkout/StripeInput';
import { RootState } from 'src/app/types';

import { UpgradePayloadWithSave } from './types';
import {
  usePopulateFromOrganization,
  usePopulateFromProfile,
  usePopulateFromBilling,
  useSubscribeToUserUpdatedBillingInfo,
  useSubscribeToUserUpgradeBillingInfo,
} from './hooks';
import {
  submitBillingDetails,
  getSubscribedPlan,
  getSubscribedPeriod,
  getIsLoading as getIsBillingLoading,
} from './store';
import { adapterBillingDetailsForm } from './store/billingAdapters';
import { useShouldRetrySubscription } from '../checkout/utils';

const style = {
  width: '100%',
};

const validatePostCode = (value: string, allValues: BillingDetailsFormValues) =>
  !value || (value && validatePostalCode(allValues.country, value))
    ? ''
    : 'Invalid code for this country';

const FORM_ID = 'billingDetails';

const getProfileDataFormState = (state: RootState): FormState => state.form[FORM_ID];

export const getProfileCountry = createSelector(getProfileDataFormState, (state: FormState) =>
  state && state.values ? state.values.country : 'US',
);
const getProfileState = createSelector(getProfileDataFormState, (state: FormState) =>
  state && state.values && state.values.state ? state.values.state : null,
);

export const BillingDetails: FC<
  InjectedFormProps<BillingDetailsFormValues, UpgradePayloadWithSave> & UpgradePayloadWithSave
> = ({ handleSubmit, invalid, autofill, plan, period, onBillingInfoSave }) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const isCheckoutLoading = useSelector(getIsCheckoutLoading);
  const isBillingLoading = useSelector(getIsBillingLoading);
  const isLoading = isCheckoutLoading || isBillingLoading;
  const checkoutError = useSelector(getError);
  const cardError = useSelector(getCardError);
  const cardErrorMessage = !isEmpty(checkoutError) ? checkoutError : 'Error: Invalid card';
  const countries = useCountryListMemo('Country');
  const country = useSelector(getProfileCountry);
  const state = useSelector(getProfileState);
  const stateList = useStateListMemo(country || '');
  const currentSavedPlan = useSelector(getSubscribedPlan);
  const currentSavedPeriod = useSelector(getSubscribedPeriod);
  const shouldUseRetry = useShouldRetrySubscription();

  usePopulateFromProfile(autofill);
  usePopulateFromOrganization(autofill);
  usePopulateFromBilling(autofill);

  useSubscribeToUserUpdatedBillingInfo(onBillingInfoSave);
  useSubscribeToUserUpgradeBillingInfo(onBillingInfoSave);

  useEffect(() => {
    if (isNull(state) && !isNil(stateList[0])) {
      autofill('state', stateList[0].value);
    }
  }, [autofill, state, stateList]);

  const submitForm = useCallback(
    (values: BillingDetailsFormValues) => {
      if (!stripe || !elements || isLoading) return;

      const cardNumberElement = elements.getElement(CardNumberElement);
      const billingDetails = {
        ...adapterBillingDetailsForm(values),
        cardNumberElement,
        plan: plan || currentSavedPlan,
        period: period || currentSavedPeriod,
        stripe,
        shouldUseRetry,
      };
      dispatch(submitBillingDetails(billingDetails));
    },
    [
      stripe,
      elements,
      isLoading,
      plan,
      currentSavedPlan,
      period,
      currentSavedPeriod,
      shouldUseRetry,
      dispatch,
    ],
  );

  return (
    <form className="c-setup c-setup--bg-transparent" onSubmit={handleSubmit(submitForm)}>
      <div className="c-setup__content">
        <div className="c-form c-form__checkout">
          <div className="c-form__row c-form__required">
            <div className="c-form__column">
              <Field
                component={Input}
                name="firstName"
                placeholder="First Name"
                validate={[required()]}
              />
            </div>
          </div>
          <div className="c-form__row c-form__required">
            <div className="c-form__column">
              <Field
                component={Input}
                name="lastName"
                placeholder="Last Name"
                validate={[required()]}
              />
            </div>
          </div>
          <div className="c-form__row c-form__required">
            <div className="c-form__column">
              <Field
                component={Input}
                type="email"
                name="email"
                placeholder="Email"
                validate={[email(), validateEmail]}
              />
            </div>
          </div>
          <div className="c-form__row c-form__required">
            <div className="c-form__column">
              <Field
                component={Input}
                name="address"
                placeholder="Address"
                validate={[required()]}
              />
            </div>
          </div>
          <div className="c-form__row c-form__required">
            <div className="c-form__column">
              <Field component={Input} name="city" placeholder="City" validate={[required()]} />
            </div>
          </div>
          <div className="c-form__row c-form__required">
            <div className="c-form__column c-form__column--2">
              <Field
                component={Select}
                name="state"
                placeholder="Province/State"
                options={stateList}
                validate={[required()]}
              />
            </div>
            <div className="c-form__column c-form__column--2">
              <Field
                component={Input}
                name="postalCode"
                placeholder="Postal Code"
                validate={[required(), validatePostCode]}
              />
            </div>
          </div>
          <div className="c-form__row c-form__required">
            <div className="c-form__column">
              <Field
                component={Select}
                name="country"
                options={countries}
                validate={[required()]}
              />
            </div>
          </div>
          <div className="c-form__row">
            <div className="c-line"></div>
          </div>
          <div className="c-form__row c-form__required">
            <div style={style}>
              <Field
                component={StripeInput}
                element={CardNumberElement}
                name="cardNumber"
                validate={[required()]}
              />
            </div>
          </div>
          <div className="c-form__row c-form__required">
            <div className="c-form__card">
              <Field
                component={StripeInput}
                element={CardExpiryElement}
                name="expiryDate"
                validate={[required()]}
              />
              <Field
                component={StripeInput}
                element={CardCvcElement}
                name="cvc"
                validate={[required()]}
              />
            </div>
          </div>
          {cardError === PaymentError.Card && (
            <div className="c-form__custom-error">{cardErrorMessage}</div>
          )}
          <div className="c-form__row">
            <div className="c-line"></div>
          </div>
          <div className="c-form__row c-form--justify-center">
            <Button
              type="submit"
              className="c-button c-button--fs-12"
              isLoading={isLoading}
              disabled={isLoading || invalid}
            >
              {i18n(translationKeys.buttons.update)}
            </Button>
          </div>
          <div className="c-form__row c-form__required">
            {i18n(translationKeys.errors.REQUIRED)}
          </div>
        </div>
        <div className="c-form__row">
          <StripeCard />
        </div>
      </div>
    </form>
  );
};

export const BillingDetailsForm = reduxForm<BillingDetailsFormValues, UpgradePayloadWithSave>({
  form: FORM_ID,
  destroyOnUnmount: false,
})(BillingDetails);
