export const EDITABLE_FIELDS_ATTRIBUTES = {
  ID: 'data-id',
  NAME: 'data-name',
  DATA_UPDATING: 'data-updating',
  NEW_VALUE: 'data-new-value',
  CAN_REVIEW: 'data-can-review',
  CONTENT_EDITABLE: 'contenteditable',
  EDITING_ENABLED: 'editing-enabled',
  DRAGGABLE: 'draggable',
  UPDATING_TEXT: 'data-updating-text',
};
export const EDITABLE_FIELDS_CLASSES = {
  EDITABLE_FIELD: 'editable-field',
  CK_WIDGET: 'ck-widget',
  EDITABLE_FIELD_TITLE: 'editable-field-title',
  WIDGET_SELECTED: 'ck-widget_selected',
  EDITOR_EDITABLE: 'ck-editor__editable',
  EDITOR_NESTED_EDITABLE: 'ck-editor__nested-editable',
  EDITABLE_FIELD_BUTTONS: 'editable-field-buttons',
  BUTTON: 'btn',
  BUTTON_ACCEPT: 'btn-accept',
  BUTTON_REJECT: 'btn-reject',
  FIELD_DISABLE: 'editable-field-disabled',
  RESTRICTED_EDITING_EXTENSION: 'restricted-editing-exception',
};
