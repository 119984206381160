import { FC } from 'react';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { AppAdvancedLayout } from 'src/v2/modules/layout';

import { DynamicSettingsContextProvider } from './DynamicSettingsContextProvider';
import { SidebarNodeContextProvider } from './SidebarNodeContextProvider';

interface Props {
  isLoading: boolean;
  error?: string;
}

export const BaseSearchLayout: FC<Props> = ({ children, error, isLoading }) => {
  if (isLoading) {
    return (
      <AppAdvancedLayout>
        <b>{i18n(translationKeys.messages.loading)}</b>
      </AppAdvancedLayout>
    );
  }

  if (error) {
    return (
      <AppAdvancedLayout>
        <b style={{ color: 'red' }}>{error}</b>
      </AppAdvancedLayout>
    );
  }

  return <AppAdvancedLayout>{children}</AppAdvancedLayout>;
};

export const SearchLayout: FC<Props> = (props) => (
  <SidebarNodeContextProvider>
    <DynamicSettingsContextProvider>
      <BaseSearchLayout {...props} />
    </DynamicSettingsContextProvider>
  </SidebarNodeContextProvider>
);
