/* eslint-disable @typescript-eslint/explicit-function-return-type */

// This toolchain is intended to simplify creation of regular state selectors
//
// Example of usage:
// const {
//     getState,
//     getData: getMyData,
//     getDataHead: getMyDataFirstElement
//     getIsLoading,
//     getError
//  } = defaultSelectorFactory((state: RootState) => state.myFeatureState);

import { head } from 'lodash';

import { RootState, DefaultSliceStateWithData } from 'src/app/types';

export type GetStateSelector<S> = (state: RootState) => S;

export const defaultSelectorFactory = <PartialState extends DefaultSliceStateWithData>(
  getState: GetStateSelector<PartialState>,
) => ({
  getState,
  getData: (state: RootState) => getState(state).data,
  getDataHead: (state: RootState) => head(getState(state).data),
  getIsLoading: (state: RootState) => getState(state).isLoading,
  getError: (state: RootState) => getState(state).error,
});
