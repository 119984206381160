import { useMemo, useEffect, useCallback, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isString, isNil, isNull, size } from 'lodash';

import { useUrlManagerQuery } from 'src/common/hooks/urlManager';
import { ListItem } from 'src/models/list';
import { listToMapWithChildren } from 'src/utils/tree';
import { book } from 'src/app/book';
import { NodeTree } from 'src/models/node';
import { getProjectsAsFoldersSorted } from 'src/v2/features/quantumNavigator/store/quantumNavigatorSelectors';
import { Folder } from 'src/models/folder';
import { isSharedFolder, rootFolderName } from 'src/v2/features/quantumNavigator/utils';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

import {
  fetchFolders,
  getFoldersSorted,
  getFolderTreeCombiner,
  getFolderTreeByIdCombiner,
} from './store';
import { adaptFoldersToNodes, folderWithRootFactory } from './utils';
import { SubscriptionPlan } from '../../../models/billing';
import { ContextMenuBook } from '../../../app/types';

export const useFetchFolders = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFolders());
  }, [dispatch]);
};

export const useGetFoldersMap = () => {
  const foldersList = useSelector(getFoldersSorted);
  return useMemo(() => {
    const nodes = adaptFoldersToNodes(foldersList);

    return listToMapWithChildren(nodes);
  }, [foldersList]);
};

export const useGetFoldersTreeById = (folderId: string | undefined | null) => {
  const foldersList = useSelector(getFoldersSorted);
  const foldersTreeFromRoot = useMemo(() => {
    const tree = getFolderTreeCombiner(foldersList);
    return folderWithRootFactory(tree);
  }, [foldersList]);

  const foldersTreeFromId = useMemo(() => {
    if (!folderId) return null;
    return getFolderTreeByIdCombiner(foldersList, folderId);
  }, [foldersList, folderId]);

  return isNil(folderId) ? foldersTreeFromRoot : foldersTreeFromId;
};

export const useGetProjectsTreeById = (folderId: string | undefined | null) => {
  const projectsList = useSelector(getProjectsAsFoldersSorted);
  const foldersList = useSelector(getFoldersSorted);

  const projectsWithProjectRoot: Folder[] = useMemo(
    () => [...foldersList, ...projectsList],
    [foldersList, projectsList],
  );

  const foldersTreeFromId = useMemo(() => {
    if (!folderId) return null;
    return getFolderTreeByIdCombiner(projectsWithProjectRoot, folderId);
  }, [projectsWithProjectRoot, folderId]);

  return foldersTreeFromId;
};

const getNodesTreeByIdWithoutSystemFolders = (
  root: NodeTree<ListItem> | null,
  validateFn: (model: NodeTree<ListItem>) => boolean,
): NodeTree<ListItem> | null => {
  const stack: NodeTree<ListItem>[] = [];
  if (isNull(root)) return root;
  stack.push(root);

  let current: NodeTree<ListItem> | undefined;
  current = root;

  while (!isNil(current) && size(stack) > 0) {
    const children: NodeTree<ListItem>[] = [];

    current.children.forEach((currentChild) => {
      const isValid = validateFn(currentChild);

      if (isValid) {
        children.push(currentChild);
        if (size(currentChild.children) > 0) stack.push(currentChild);
      }
    });

    current.children = children;

    current = stack.pop();
  }

  return root;
};

export const useGetFoldersTreeByIdWithoutSystemFolders = (
  folderId: string | undefined | null,
  validateFn: (model: NodeTree<ListItem>) => boolean,
) => {
  const root = useGetFoldersTreeById(folderId);
  return getNodesTreeByIdWithoutSystemFolders(root, validateFn);
};

export const useGetProjectFoldersTreeByIdWithoutSystemFolders = (
  folderId: string | undefined | null,
  validateFn: (model: NodeTree<ListItem>) => boolean,
) => {
  const root = useGetProjectsTreeById(folderId);
  return getNodesTreeByIdWithoutSystemFolders(root, validateFn);
};

export const useGetCurrentFolderId = (): string | undefined => {
  const [params] = useUrlManagerQuery();
  return isString(params.folderId) ? params.folderId : undefined;
};

export const useOpenFolder = () => {
  const navigate = useNavigate();
  const [, , createUpdatedHash] = useUrlManagerQuery();

  return useCallback(
    ({ id: folderId }: ListItem) => {
      if (folderId !== '') {
        const url = createUpdatedHash({ folderId });

        navigate(url);
      }
    },
    [navigate, createUpdatedHash],
  );
};

export const useCreateOpenFolderFn = (folderId: string) => {
  const navigate = useNavigate();
  const [, , createUpdatedHash] = useUrlManagerQuery();

  return useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (folderId !== '') {
        const url = createUpdatedHash({ folderId });

        navigate(url);
      }
    },
    [navigate, createUpdatedHash, folderId],
  );
};

export const useNavigateToFolder = (generatePath: (folderId?: string) => string) => {
  const navigate = useNavigate();

  return useCallback(
    ({ id: folderId }: ListItem) => {
      navigate(generatePath(folderId));
    },
    [navigate, generatePath],
  );
};

export const useNavigateToDocumentFolder = () => useNavigateToFolder(book.documents.generatePath);

export const useNavigateToContractFolder = () => useNavigateToFolder(book.contracts.generatePath);

export const useNavigateToTemplateFolder = () => useNavigateToFolder(book.templates.generatePath);

export const useGetFolderName = () => {
  const folderId = useGetCurrentFolderId();
  const current = useGetFoldersTreeById(folderId as string);

  if (!isNull(current) && isSharedFolder(current.data)) {
    return i18n(translationKeys.navigationMenu.shared);
  }

  return !isNull(current) && rootFolderName !== current.data.name
    ? current.data.name.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
    : '';
};

export const useIsHaveContextMenu = (folderId: string): boolean => {
  const folder = useGetFoldersTreeById(folderId);

  return !isNull(folder) && !isSharedFolder(folder.data);
};

export const useCanShowContextMenu = (
  plan: SubscriptionPlan | null,
  menuType: ContextMenuBook,
): boolean => {
  const restrictedPlans = [SubscriptionPlan.Basic];
  const dontShowMenuTypes = [ContextMenuBook.DocumentProject];

  if (!isNil(plan)) {
    if (restrictedPlans.includes(plan)) {
      return !dontShowMenuTypes.includes(menuType);
    }
  }
  return true;
};
