import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProjectState } from 'src/app/types';
import { defaultActionsFactoryWithData, initialStateFactory } from 'src/utils/defaultSlice';
import {
  CreateProjectDTO,
  DeleteContractFromProjectDTO,
  DeleteDocumentFromProjectDTO,
  EditProjectDTO,
  FetchProjectDetailsDTO,
  FetchProjectsDTO,
  RemoveProjectDTO,
} from 'src/v2/boundary/requestDTO/project';
import {
  CreateDocumentDTO,
  CreateDocumentFromTemplateDTO,
} from 'src/v2/boundary/requestDTO/document';
import {
  CreateContractFromFilePayload,
  CreateDocumentFromFilePayload,
} from 'src/v2/features/documentSidebar/documentSidebarUpload/types';
import {
  CreateContractDTO,
  CreateContractFromTemplateDTO,
} from 'src/v2/boundary/requestDTO/contract';

const projectInitialState: ProjectState = {
  ...initialStateFactory(),
  deletedId: null,
};

const { onStart, onError, onSuccessWithDataSerialized, onSuccess } =
  defaultActionsFactoryWithData();

const project = createSlice({
  name: 'project',
  initialState: projectInitialState,
  reducers: {
    startLoading: onStart,
    stopLoading: onError,
    finishLoading: onSuccess,
    successLoading: onSuccessWithDataSerialized,
    deleteEntityStart(state: ProjectState, action: PayloadAction<string | null>): void {
      state.deletedId = action.payload;
    },
    deleteEntityFinished(state: ProjectState): void {
      state.deletedId = null;
    },
  },
});

export const {
  startLoading,
  stopLoading,
  finishLoading,
  successLoading,
  deleteEntityStart,
  deleteEntityFinished,
} = project.actions;

export const projectReducer = project.reducer;

export const fetchProjectsList = createAction<FetchProjectsDTO>('project/fetchProjectObjects');
export const fetchProjectDetails = createAction<FetchProjectDetailsDTO>(
  'project/fetchProjectDetails',
);
export const createProject = createAction<CreateProjectDTO>('project/createProject');

export const editProject = createAction<EditProjectDTO>('project/editProject');

export const createDocumentInProject = createAction<CreateDocumentDTO>(
  'project/createDocumentInProject',
);
export const createContractInProject = createAction<CreateContractDTO>(
  'project/createContractInProject',
);

export const createDocumentFromFileInProject = createAction<CreateDocumentFromFilePayload>(
  `project/createDocumentFromFileInProject`,
);

export const createContractFromFileInProject = createAction<CreateContractFromFilePayload>(
  `project/createContractFromFileInProject`,
);

export const createDocumentFromTemplateInProject = createAction<CreateDocumentFromTemplateDTO>(
  'project/createDocumentFromTemplateInProject',
);

export const createContractFromTemplateInProject = createAction<CreateContractFromTemplateDTO>(
  'project/createContractFromTemplateInProject',
);

export const removeProject = createAction<RemoveProjectDTO>('project/removeProject');

export const deleteDocumentFromProject = createAction<DeleteDocumentFromProjectDTO>(
  'project/deleteDocumentFromProject',
);
export const deleteContractFromProject = createAction<DeleteContractFromProjectDTO>(
  'project/deleteContractFromProject',
);
