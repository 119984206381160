import { SagaIterator } from 'redux-saga';
import { createAction, PayloadAction } from '@reduxjs/toolkit';
import { put, call, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { startSubmit, stopSubmit, reset } from 'redux-form';
import { get } from 'lodash';

import { landingFormApi, LandingForm } from 'src/api/landingFormApi';

export interface SubscribeData {
  email: string;
}

function* subscribeSaga(action: PayloadAction<SubscribeData>): SagaIterator {
  try {
    yield put(startSubmit('landing/subscribe'));
    yield call(landingFormApi, action.payload, LandingForm.subscribe);
    yield put(reset('landing/subscribe'));
    toastr.success('Success', 'Thanks for subscribing!');
  } catch (err) {
    toastr.error('Error', get(err, 'response.data.error.message') || get(err, 'message'));
  } finally {
    yield put(stopSubmit('landing/subscribe'));
  }
}

export const subscribe = createAction<SubscribeData>('landing/subscribe');

export function* watchSubscribe(): SagaIterator {
  yield takeLatest(subscribe, subscribeSaga);
}
