import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { Box, Input, Textarea, Label, Text } from '@theme-ui/components';
import { v4 as uuidv4 } from 'uuid';

const styles = {
  error: {
    fontSize: 12,
    fontWeight: 400,
    color: 'red',
  },
};

type Props = {
  label?: string;
  type?: string;
  multiline?: boolean;
  variant?: string;
  placeholder?: string;
  required?: boolean;
  rows?: number;
  disabled?: boolean;
  autoFocus?: boolean;
  id?: string;
  style?: object;
} & WrappedFieldProps;

export const TextField = ({
  label,
  multiline,
  variant,
  input,
  meta,
  id: idProp,
  style,
  ...other
}: Props): JSX.Element => {
  const { valid, error, touched } = meta;

  const Component = multiline ? Textarea : Input;

  const labelVariant = variant ? `${variant}Label` : 'label';

  let componentVariant;

  if (variant) {
    componentVariant = multiline ? `${variant}Textarea` : `${variant}Input`;
  } else {
    componentVariant = multiline ? 'textarea' : 'input';
  }

  const id = idProp || uuidv4();

  return (
    <Box style={style}>
      <Label variant={labelVariant} htmlFor={id}>
        {(!touched || valid) && label}

        {touched && error && (
          <Text as="strong" sx={styles.error}>
            {error}
          </Text>
        )}
      </Label>
      <Component {...input} {...other} style={style} variant={componentVariant} id={id} />
    </Box>
  );
};
