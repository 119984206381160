import { isEmpty, isNull } from 'lodash';

export const handleFileChange =
  (cb: (file: File) => void) =>
  (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { files } = e.target;

    if (!isNull(files) && !isEmpty(files) && !isNull(files[0])) {
      // @ts-ignore
      const [file] = files;

      cb(file);
    }
  };

export function readImageFile(file: File): Promise<HTMLImageElement> {
  return new Promise((resolve): void => {
    const reader = new FileReader();
    const image = new Image();
    reader.onload = (): void => {
      image.src = reader.result as string;
      image.onload = (): void => {
        resolve(image);
      };
    };
    reader.readAsDataURL(file);
  });
}

export function dataURLtoFile(dataurl: string, filename = 'blob') {
  const arr = dataurl.split(',');
  const searchMime = arr[0].match(/:(.*?);/);
  const mime = searchMime ? searchMime[1] : 'image/png';
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export const changeImageHash = (dataURL: string): string => {
  const rnd = Math.random();
  return dataURL.replace(/(^data:.*);(.*),/, `$1;charset=${rnd};$2,`);
};
