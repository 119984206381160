import React from 'react';

import { Visa } from 'src/v2/icons/Visa';
import { MasterCard } from 'src/v2/icons/MasterCard';
import { Maestro } from 'src/v2/icons/Maestro';
import { AmericanExpress } from 'src/v2/icons/AmericanExpress';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

export const StripeCard = (): JSX.Element => (
  <div className="c-setup__stripe">
    <div className="c-setup__stripe--title">{i18n(translationKeys.stripe.title)}</div>
    <div className="c-setup__stripe--payment">
      <div className="c-setup__stripe--icon">
        <Visa />
      </div>
      <div className="c-setup__stripe--icon">
        <MasterCard />
      </div>
      <div className="c-setup__stripe--icon">
        <Maestro />
      </div>
      <div className="c-setup__stripe--icon">
        <AmericanExpress />
      </div>
    </div>
  </div>
);
