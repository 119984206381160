import { FC, useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNull, isUndefined } from 'lodash';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { EntityType } from 'src/models/paper';
import { ContractCardPaperModel } from 'src/models/contract';
import { canDeleteContract } from 'src/v2/features/contract/permissions/contractPermissions/contractPermissions';
import {
  ObjectsListPage,
  RenderSystemCardProps,
} from 'src/v2/features/objectsList/ObjectsListPage';
import { useGetEntityById, useNormalizedEntityId } from 'src/v2/features/sharedEntity';
import { ContractType, ContractRole } from 'src/models/contract';
import { fetchDocumentInfo } from 'src/v2/features/documentDetails/documentDetailsSlice';
import { deleteContract, getDeletedId } from 'src/v2/features/documentList/documentListSlice';
import { useGetCurrentFolderId } from 'src/v2/features/quantumNavigator/hooks';
import { usePreventOperationForDeletedEntity } from 'src/v2/features/sharedEntity/hooks';
import { createContractFromTemplate } from 'src/v2/features/createFromTemplate/actions';
import { DynamicSettingsContext } from 'src/v2/modules/layout';

import { ContractCard } from './components/ContractCard';
import { ContractSystemCard } from './components/ContractSystemCard';
import { getRootContractFolderId } from './store';
import { parseCardData, useCreateNavigateToContractDetails } from './utils';
import { ContractLayout } from './ContractLayout';

export const ContractListPageWithoutContext: FC = () => {
  const contractsFolderId = useSelector(getRootContractFolderId);
  const currentFolderId = useGetCurrentFolderId();
  const folderId = currentFolderId || contractsFolderId;
  const createHandleOpenContract = useCreateNavigateToContractDetails(folderId);
  const id = useNormalizedEntityId();
  const selectedContract = useGetEntityById<ContractType, ContractRole>(id);
  const selectedContractId = isNull(selectedContract) ? null : selectedContract.id;
  const deletedContractId = useSelector(getDeletedId);
  const preventOperationForDeletedContract = usePreventOperationForDeletedEntity(deletedContractId);
  const { onPlusClick: onAdd } = useContext(DynamicSettingsContext);
  const dispatch = useDispatch();

  const handleDeleteContract = useCallback(
    (paper: ContractCardPaperModel) => {
      if (canDeleteContract(paper.state)) {
        dispatch(deleteContract(paper.id));
      }
    },
    [dispatch],
  );

  const handleCreateContract = useCallback(
    (values) => {
      if (!isUndefined(folderId)) {
        const { title, description, type, subType, templateId } = values;
        const paper = {
          data: {
            role: 'Contract Creator',
            title,
            description,
            subType: type,
            subSubType: subType,
            type: EntityType.contract,
            ownerConditionRequired: true,
          },
          folderId,
          sourceId: templateId,
        };

        dispatch(createContractFromTemplate({ ...paper }));
      }
    },
    [dispatch, folderId],
  );

  return (
    <ObjectsListPage<ContractCardPaperModel>
      onIdChanged={fetchDocumentInfo}
      onDelete={preventOperationForDeletedContract(id, handleDeleteContract)}
      onCreate={onAdd}
      renderLayout={({ isLoading, error, children }) => (
        <ContractLayout isLoading={isLoading} error={error}>
          {children}
        </ContractLayout>
      )}
      renderCard={({ cardPaper, onDelete, selected, onClick }) => (
        <ContractCard
          key={cardPaper.id}
          data={parseCardData(cardPaper)}
          onClick={preventOperationForDeletedContract(cardPaper.id, onClick)}
          onDelete={onDelete}
          onDoubleClick={preventOperationForDeletedContract(
            cardPaper.id,
            createHandleOpenContract(cardPaper.id),
          )}
          selected={selected}
        />
      )}
      renderSystemCard={(systemCardPaper: RenderSystemCardProps) => (
        <ContractSystemCard
          key={systemCardPaper.templateId}
          systemCardPaper={systemCardPaper}
          createContract={() => handleCreateContract(systemCardPaper)}
        />
      )}
      rootFolderId={contractsFolderId}
      selectedPaperId={selectedContractId}
      defaultGridTitle={i18n(translationKeys.card.contract.title)}
      entityType={EntityType.contract}
    />
  );
};
