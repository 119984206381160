import { FC } from 'react';
import { Field } from 'redux-form';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { Input } from 'src/v2/features/reduxForm';

export const RoleField: FC = () => {
  return (
    <Field
      component={Input}
      name="role"
      placeholder={i18n(translationKeys.forms.item.roleOfCreator)}
    />
  );
};
