import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CreateFolderPayload } from 'src/models/folder';
import { FolderSelectorState, RootState } from 'src/app/types';

export const createFolder = createAction<CreateFolderPayload>('folderSelector/createFolder');

const initialState: FolderSelectorState = {
  folderId: null,
  activeFolderId: null,
  isModalActive: false,
  targetId: null,
};

const folderSelectorSlice = createSlice({
  name: 'folderSelector',
  initialState,
  reducers: {
    setFolderId: (
      state: FolderSelectorState,
      action: PayloadAction<FolderSelectorState['folderId']>,
    ) => {
      state.folderId = action.payload;
    },
    setActiveFolderId: (
      state: FolderSelectorState,
      action: PayloadAction<FolderSelectorState['folderId']>,
    ) => {
      state.activeFolderId = action.payload;
    },
    resetState: () => initialState,
    setTargetId: (state: FolderSelectorState, action: PayloadAction<string>) => {
      state.targetId = action.payload;
    },
    clearTargetId: (state: FolderSelectorState) => {
      state.targetId = null;
    },
  },
});

export const folderSelectorReducer = folderSelectorSlice.reducer;
export const { setFolderId, resetState, setActiveFolderId, setTargetId, clearTargetId } =
  folderSelectorSlice.actions;

const getState = (state: RootState) => state.folderSelector;
export const getFolderId = createSelector(getState, (state) => state.folderId);
export const getActiveFolderId = createSelector(getState, (state) => state.activeFolderId);
export const getIsModalActive = createSelector(getState, (state) => state.isModalActive);
export const getTargetId = createSelector(getState, (state) => state.targetId);
