const basicBook = {
  view: 'view',
  create: 'create',
  edit: 'edit',
  removeFolder: 'removeFolder',
  removeParticipant: 'removeParticipant',
  shareEntity: 'shareEntity',
  qr: 'qr',
  qrShareEntity: 'qrShareEntity',
};

export const book = Object.freeze({
  ...basicBook,
  default: basicBook.view,
});
