import { Field, InjectedFormProps } from 'redux-form';
import { useNavigate } from 'react-router-dom';
import { email, required } from 'redux-form-validators';
import { useSelector } from 'react-redux';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { book } from 'src/app/book';
import { Input, RadioSetField } from 'src/v2/features/reduxForm';
import ReduxFormPhone from 'src/v2/components/Form/ReduxFormPhone';
import { PHONE_MASKS } from 'src/v2/components/PhoneNumber/types';
import { Button, Type } from 'src/v2/features/button';
import { InviteFormProps } from 'src/v2/features/profile/profileSidebar/types';
import { Divider } from 'src/v2/components/Divider';
import { useGetAllowedPermissionsForAddingUsers } from 'src/v2/features/profile/profileSidebar/hooks';
import { getCurrentOrganizationUserSystemRole } from 'src/shared/organization';
import { validateEmail } from 'src/utils/validate';

interface Props {
  fieldDisabled?: boolean;
  handleFormSubmit: (values: InviteFormProps) => void;
  actionLabel: string;
  isLoading: boolean;
}

export const UserForm = <P,>({
  fieldDisabled = false,
  invalid,
  submitting,
  pristine,
  handleFormSubmit,
  handleSubmit,
  actionLabel,
  isLoading,
}: InjectedFormProps<InviteFormProps, P> & Props) => {
  const navigate = useNavigate();
  const handleCancel = () => navigate(book.profile.users.generatePath());
  const userSystemRole = useSelector(getCurrentOrganizationUserSystemRole);
  const permissions = useGetAllowedPermissionsForAddingUsers(userSystemRole);

  return (
    <>
      <div className="c-fieldset">
        <div className="c-fieldset__group">
          <Field
            component={Input}
            name="email"
            type="email"
            placeholder={i18n(translationKeys.forms.permissions.email)}
            validate={[email(), validateEmail]}
            disabled={fieldDisabled}
            mb8
          />

          <Field
            component={Input}
            name="firstName"
            placeholder={i18n(translationKeys.forms.permissions.firstName)}
            disabled={fieldDisabled}
            mb8
          />

          <Field
            component={Input}
            name="lastName"
            placeholder={i18n(translationKeys.forms.permissions.lastName)}
            disabled={fieldDisabled}
            mb8
          />

          <Field
            props={{
              placeholder: '+1-650-450-1212',
              autocomplete: 'tel',
            }}
            component={ReduxFormPhone}
            masks={PHONE_MASKS}
            name="phone"
            placeholder={i18n(translationKeys.forms.permissions.phone)}
            disabled={fieldDisabled}
            mb8
          />

          <Field
            component={Input}
            name="role"
            placeholder={i18n(translationKeys.forms.permissions.role)}
            mb8
          />
        </div>
        <Divider />
        <div className="c-title c-title--uppercase c-title--lh-1">
          {i18n(translationKeys.forms.permissions.title)}
        </div>
        <div className="c-fieldset__group">
          <RadioSetField
            name="permission"
            options={permissions}
            validate={required({ message: i18n(translationKeys.forms.permissions.chooseRole) })}
          />
        </div>

        <Divider />
        <Button
          type="submit"
          className="h-uppercase"
          disabled={invalid || submitting || pristine}
          isLoading={isLoading}
          onClick={handleSubmit(handleFormSubmit)}
          data-type="userForm-submit"
        >
          {actionLabel}
        </Button>
        <Button
          className="h-uppercase"
          actionType={Type.Danger}
          disabled={isLoading}
          onClick={handleCancel}
        >
          {i18n(translationKeys.buttons.cancel)}
        </Button>
      </div>
    </>
  );
};
