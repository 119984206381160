import React, { FC } from 'react';

import { DynamicSettingsContext } from 'src/v2/modules/layout';
import { DynamicSettings } from 'src/v2/modules/layout/types';

import { useNotificationTogglePush, useToggle9Square, useToggleQRCode } from '../ContractSidebar';
import { BackTitleAndActions } from '../components/BackTitleAndActions';
import { useHasQRCodeFeature } from '../../billing';

interface Props {
  documentId: string | null;
  isReadyToSignVisible: boolean;
  isNegotiable: boolean;
  isFile: boolean;
  backToDoc?: boolean;
}

export const DynamicSettingsContextProvider: FC<Props> = ({
  children,
  documentId,
  isReadyToSignVisible,
  isNegotiable,
  isFile,
  backToDoc,
}) => {
  const toggleNotifications = useNotificationTogglePush();
  const toggle9squareSidebar = useToggle9Square();
  const toggleQRCodeSidebar = useToggleQRCode();
  const hasQRCodeFeature = useHasQRCodeFeature();

  const ctx: DynamicSettings = {
    headerChildren: (
      <BackTitleAndActions
        documentId={documentId}
        isReadyToSignVisible={isReadyToSignVisible}
        isNegotiable={isNegotiable}
        isFile={isFile}
        backToDoc={backToDoc}
      />
    ),
    onBellClick: toggleNotifications,
    onGridClick: toggle9squareSidebar,
    onQRCodeClick: toggleQRCodeSidebar,
    hasQRCodeFeature,
  };

  return <DynamicSettingsContext.Provider value={ctx}>{children}</DynamicSettingsContext.Provider>;
};
