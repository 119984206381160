import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { parse } from 'qs';
import { get, isEmpty, isNull } from 'lodash';

import { book } from 'src/app/book';
import { AccountType, InviteType, RedirectEntityType } from 'src/models/signUp';
import { BillingPeriod, SubscriptionPlan } from 'src/models/billing';

import {
  getCompanyName,
  getEmail,
  getMaxSteps,
  getPeriod,
  getToken,
  getPlan,
  getStepNum,
  getInviteType,
  getEntityType,
  getEntityId,
} from '../store';
import { useGetAccountType } from '../hooks';
import { getDefaultPlanByAccountType, getDefaultBillingPeriod } from '../utils';
import { Props as LayoutWithStepperProps } from '../components/LayoutWithStepper';

export type SignUpStepComponent = [() => ReactElement, LayoutWithStepperProps];

interface StepProps {
  email: string;
  companyName: string;
  accountType: AccountType;
  maxSteps: number;
  step: number;
  plan: SubscriptionPlan;
  period: BillingPeriod;
  token: string | null;
  inviteType: InviteType | null;
  entityType: RedirectEntityType | null;
  entityId: string | null;
}

export const useIsSignupFromInvite = (): [boolean, string] => {
  const { pathname, search } = useLocation();
  const params = parse(search, { ignoreQueryPrefix: true });
  const token = get(params, 'token');
  const inviteType = useSelector(getInviteType);
  const isInvited =
    (pathname === book.signupFromInvite && !isEmpty(token)) ||
    inviteType === InviteType.Organization;
  const inviteToken = !isEmpty(token) ? (token as string) : '';

  return [isInvited, inviteToken];
};

export const useIsSignupFromQR = (): boolean => {
  const inviteType = useSelector(getInviteType);

  return inviteType === InviteType.QRCode;
};

const useGetInviteType = (): InviteType | null => {
  const isSignupFromQR = useIsSignupFromQR();
  const [isSignupFromInvite] = useIsSignupFromInvite();
  const inviteType = useSelector(getInviteType);
  if (isSignupFromQR) {
    return InviteType.QRCode;
  }
  if (isSignupFromInvite) {
    return InviteType.Viewer;
  }
  if (!isNull(inviteType)) {
    return inviteType;
  }

  return null;
};

export const useSignUpProps = (): StepProps => {
  const email = useSelector(getEmail) || '';
  const companyName = useSelector(getCompanyName) || '';
  const maxSteps = useSelector(getMaxSteps);
  const step = useSelector(getStepNum);
  const accountType = useGetAccountType();
  const isSignupFromQR = useIsSignupFromQR();
  const [isSignupFromInvite] = useIsSignupFromInvite();
  const isUserInvited = isSignupFromQR || isSignupFromInvite;
  const plan = useSelector(getPlan) || getDefaultPlanByAccountType(accountType, isUserInvited);
  const period = useSelector(getPeriod) || getDefaultBillingPeriod(plan);
  const token = useSelector(getToken);
  const inviteType = useGetInviteType();
  const entityType = useSelector(getEntityType);
  const entityId = useSelector(getEntityId);

  return {
    email,
    companyName,
    accountType,
    maxSteps,
    step,
    plan,
    period,
    token,
    inviteType,
    entityType,
    entityId,
  };
};
