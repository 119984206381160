import React from 'react';
import { Box, Flex, Button, Text } from '@theme-ui/components';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

const styles = {
  container: {
    backgroundColor: 'white',
    borderRadius: '5px',
    px: '25px',
    pt: '20px',
    pb: '25px',
  },

  title: {
    color: '#909fad',
    fontSize: '25px',
    fontWeight: 'bold',
  },

  controls: {
    justifyContent: 'center',
    mt: '16px',
  },

  cancelBtn: {
    ml: '12px',
  },
};

interface Props {
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmRemoveSection = ({ onConfirm, onCancel }: Props): JSX.Element => (
  <Box sx={styles.container}>
    <Text sx={styles.title}>{i18n(translationKeys.sectionDeleteConfirm.title)}</Text>
    <Flex sx={styles.controls}>
      <Button onClick={onConfirm} variant="thirdary">
        {i18n(translationKeys.sectionDeleteConfirm.confirm)}
      </Button>
      <Button onClick={onCancel} sx={styles.cancelBtn} variant="danger">
        {i18n(translationKeys.sectionDeleteConfirm.cancel)}
      </Button>
    </Flex>
  </Box>
);
