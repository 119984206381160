import { FC, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { isNull, get, isEmpty, isUndefined } from 'lodash';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { UploadedEntityExtension } from 'src/v2/features/sharedEntity/types';
import { extensionByMimeType } from 'src/v2/components/FileUpload/types';
import { Section } from 'src/models/document';
import { TemplateContentType, TemplateRole } from 'src/models/template';
import { PDFBody } from 'src/v2/features/sharedEntity/components/PDFBody';
import { EntityTitle } from 'src/v2/components/Title/Title';
import { Breadcrumb } from 'src/v2/features/breadcrumb';
import {
  useFetchFolders,
  useNavigateToTemplateFolder,
} from 'src/v2/features/quantumNavigator/hooks';
import { uniquifySectionIds } from 'src/v2/features/documentDetails/utils';

import { TemplateLayout } from './TemplateLayout';
import EditorSection from './containers/EditorSection';
import { useGetTemplateById } from './hooks';
import { updateFile, fetchTemplate, downloadAsPDF } from './reducer';
import { getIsLoading, getError } from './selectors';
import { DynamicSettingsContextProvider } from './providers/DynamicSettingsContextProvider';
import { SidebarNodeContextProvider } from './providers/SidebarNodeContextProvider';

export const TemplateDetailsPage: FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const isLoading = useSelector(getIsLoading);
  const error = useSelector(getError);
  const template = useGetTemplateById(id);
  const title = isNull(template)
    ? `<i>${i18n(translationKeys.forms.template.create.defaultTitle)}</i>`
    : template.title;
  const lockableSections = isNull(template) ? [] : get(template, 'entities[0].tree[0].term', []);
  const sections = uniquifySectionIds(lockableSections);
  const isAllowedToEdit = isNull(template)
    ? false
    : template.currentUserParticipant.roleId === TemplateRole.Owner;

  const file = useMemo(() => {
    return {
      name: !isNull(template) ? template.file.filename : '',
      url: !isNull(template) ? template.file.uri : '',
      extension: !isNull(template)
        ? extensionByMimeType[template.file.mime]
        : UploadedEntityExtension.png,
    };
  }, [template]);

  const handleOpenFolder = useNavigateToTemplateFolder();
  useFetchFolders();

  useEffect(() => {
    dispatch(fetchTemplate(id as string));
  }, [id, dispatch]);

  const isPDF = useMemo(() => {
    return (
      !isNull(template) && template.contentType === TemplateContentType.file && !isEmpty(file.url)
    );
  }, [template, file]);

  const savePDFFile = useCallback(
    (file: File) => {
      if (isUndefined(id)) {
        return;
      }
      dispatch(updateFile({ file, templateId: id }));
    },
    [dispatch, id],
  );

  const exportPDFCallback = useCallback(() => {
    id && dispatch(downloadAsPDF({ templateId: id, title }));
  }, [dispatch, id, title]);

  return (
    <SidebarNodeContextProvider>
      <DynamicSettingsContextProvider>
        <TemplateLayout isLoading={isLoading} error={error}>
          {template && (
            <>
              <Breadcrumb currentPageName={title} onFolderClick={handleOpenFolder} />
              <EntityTitle title={title} />
              {isPDF ? (
                <PDFBody
                  file={file}
                  savePDFFile={savePDFFile}
                  onExportPdf={exportPDFCallback}
                  isAllowedToEdit={isAllowedToEdit}
                  isAllowedToSign={true}
                  isWithAuthorization={true}
                />
              ) : (
                <EditorSection
                  templateId={template.templateId}
                  sections={sections as Section[]}
                  isAllowedToEdit={isAllowedToEdit}
                />
              )}
            </>
          )}
        </TemplateLayout>
      </DynamicSettingsContextProvider>
    </SidebarNodeContextProvider>
  );
};
