import { RootState } from 'src/app/types';
import { createSelector } from '@reduxjs/toolkit';
import { head, last, isNil } from 'lodash';

import { getObject } from 'src/v2/features/objectsStorage/objectsStorageSlice';
import { getFullNameFromProfile } from 'src/v2/features/profile/store/profileSelectors';
import {
  getUserSignatures,
  getSignatures,
  getSignatureDescriptions,
  getStamps,
} from 'src/v2/features/objectsStorage/selectors';
import {
  SignatureId,
  SignatureStorageModel,
  SignatureCompleteModel,
  SignatureDescription,
} from 'src/models/signature';

const getState = (store: RootState) => store.signature;
export const getIsLoading = createSelector(getState, (state) => state.isLoading);
export const getData = createSelector(getState, (state) => state.data);
export const getSignatureId = createSelector(getState, (state) => state.signatureId);
export const getLegalName = createSelector(getState, (state) => state.legalName);
export const getFont = createSelector(getState, (state) => state.font);
export const getSignedBy = createSelector(getState, (state) => state.signedBy);
export const getCompanyName = createSelector(getState, (state) => state.companyName);
export const getCompanyTitle = createSelector(getState, (state) => state.companyTitle);
export const getSignaturePreview = createSelector(getState, (state) => state.signaturePreview);
export const getIncludeCompanyMeta = createSelector(getState, (state) => state.includeCompanyMeta);
export const getUploadedImageUrl = createSelector(getState, (state) => state.uploadedImageUrl);
export const getSignatureFile = createSelector(getState, (state) => state.signatureFile);
export const getSignatureHash = createSelector(getState, (state) => state.hash);
export const getIsEditing = createSelector(getSignatureId, (signatureId) => !!signatureId);
export const getIsDisabled = createSelector(
  getLegalName,
  getFont,
  getUploadedImageUrl,
  getSignatureFile,
  (legalName, font, uploadedImageUrl, signatureFile) =>
    !(uploadedImageUrl || (legalName && font) || signatureFile),
);

export const getSignaturesSelector = createSelector(
  getData,
  getUserSignatures,
  getSignatures,
  getStamps,
  getSignatureDescriptions,
  (item, userSignature, signature, stamp, description): SignatureStorageModel | null =>
    getObject<SignatureStorageModel>(item, {
      userSignature,
      signature,
      stamp,
      signature_description: description,
    }),
);

export const getUserSignatureList = createSelector(getSignaturesSelector, (userSignatures) =>
  !isNil(userSignatures) ? userSignatures.signature : null,
);

export const getDefaultSignatureId = createSelector(getSignaturesSelector, (userSignatures) =>
  !isNil(userSignatures) ? userSignatures.default_signature_id : null,
);

export const getDefaultSignature = createSelector(
  getUserSignatureList,
  getDefaultSignatureId,
  (signatures, defaultSignatureId): SignatureCompleteModel | null | undefined =>
    !isNil(signatures) ? signatures.find((item) => item.signature_id === defaultSignatureId) : null,
);

const getLastSignature = createSelector(
  getUserSignatureList,
  (signatures): SignatureCompleteModel | undefined => last(signatures),
);

export const getLastSignatureId = createSelector(
  getLastSignature,
  (signature): SignatureId | undefined => signature && signature.signature_id,
);

const getDefaultSignatureDescription = createSelector(
  getDefaultSignature,
  (signature): SignatureDescription | undefined =>
    head(signature && signature.signature_description),
);

export const getDefaultSignatureUserName = createSelector(
  getDefaultSignatureDescription,
  getFullNameFromProfile,
  (signatureDescription, userName) =>
    (signatureDescription && signatureDescription.name) || userName,
);
