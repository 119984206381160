import React, { useCallback, useContext, useRef, useState /*, useEffect*/ } from 'react';
import { isUndefined } from 'lodash';
// import { useDrag, useDrop, DragSourceMonitor, DropTargetMonitor } from 'react-dnd';
// import { getEmptyImage } from 'react-dnd-html5-backend';
import { Box } from '@theme-ui/components';

import { Editor } from 'src/v2/components/Editor';
import { EditorMode, UploadData } from 'src/models/editor';
import { mergeStyles } from 'src/utils/styles';
import { useModal } from 'src/utils/hooks';
import { Modal } from 'src/v2/components/Modal';

import { ConfirmRemoveSection } from './ConfirmRemoveSection';
import { Field } from '../../../../models/document';
import { EditorStub } from '../../../components/EditorStub/EditorStub';
import { EditableSectionsContext } from '../EditableSectionsContext';

const messageStyle = {
  position: 'absolute',
  fontSize: '10px',
  fontWeight: 'bold',
};

const extraToolStyle = {
  backgroundColor: '#d6d6d6',
  cursor: 'pointer',
  outline: 'none',
  width: '58px',
  height: '42px',
  ':hover': {
    opacity: 0.9,
  },
};

const styles = {
  section: {
    mt: '7px',
    backgroundColor: '#efefef',
    borderRadius: '5px',
    position: 'relative',
    ':nth-of-type(odd)': {
      backgroundColor: 'white',
    },
  },

  notFocusable: {
    pointerEvents: 'none',
  },

  isDragging: {
    boxShadow: '0 0 12px rgb(77 105 130 / 30%)',
  },

  lock: {
    ...messageStyle,
    top: '2px',
    right: '10px',
    color: '#800',
    zIndex: 99,
  },

  saving: {
    ...messageStyle,
    zIndex: 1,
    top: '-2px',
    right: '8px',
    px: '4px',
    backgroundColor: 'white',
    borderRadius: '3px',
    lineHeight: '16px',
    color: '#00ac07',
  },

  insertSectionButton: {
    cursor: 'pointer',
    position: 'absolute',
    bottom: '-13px',
    left: '-22px',
    right: '0',
    height: '27px',
    opacity: 0,
    transition: 'opacity 0.15s',
    ':hover': {
      opacity: 1,
    },
  },

  insertSectionImage: {
    height: '27px',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'left',
    display: 'block',
  },

  sortHandle: {
    ...extraToolStyle,
    borderRadius: '5px 0 0 0',
  },

  removeButton: {
    ...extraToolStyle,
    borderRadius: '0 5px 0 0',
    position: 'absolute',
    top: 0,
    right: 0,
  },

  extraToolImage: {
    height: '20px',
  },
};

const AUTOSAVE_INTERVAL = 5000;

interface Props {
  content: string;
  index: number;
  isActive: boolean;
  isLocked: boolean;
  isSaving: boolean;
  isSorting?: boolean;
  onActivate: () => void;
  onDeactivate: () => void;
  onInsert: () => void;
  onChange: (content: string) => void;
  onMove: (from: number, to: number) => void;
  onRemove: () => void;
  onSort: (from: number, to: number) => void;
  onApplySorting: (onMove: (from: number, to: number) => void) => void;
  onResetSorting: () => void;
  onUploadFile: (file: File) => Promise<UploadData>;
  onUploadFileByUrl: (url: string) => Promise<UploadData>;
  onSetIsSorting: (value: boolean) => void;
  editableFieldDisabled?: boolean;
  fields?: Field[];
}

// interface ItemProps {
//   type: string;
//   index: number;
// }

export const EditableSection = ({
  content,
  index,
  isActive,
  isLocked,
  isSaving,
  isSorting,
  onActivate,
  onDeactivate,
  onInsert,
  onChange,
  onMove,
  onRemove,
  onSort,
  onApplySorting,
  onResetSorting,
  onUploadFile,
  onUploadFileByUrl,
  onSetIsSorting,
  editableFieldDisabled,
  fields,
}: Props): JSX.Element => {
  const [shouldModalOpen, openModal, closeModal] = useModal();

  const [isInitiatedEditor, setIsInitiatedEditor] = useState(false);
  const { initializedEditorIndex } = useContext(EditableSectionsContext);
  const ref = useRef<HTMLDivElement>(null);

  // const [{ isDragging }, drag, preview] = useDrag({
  //   item: { type: 'section', index },

  //   collect: (monitor: DragSourceMonitor) => ({
  //     isDragging: monitor.isDragging(),
  //   }),

  //   end() {
  //     onApplySorting(onMove);
  //   },
  // });

  // const [, drop] = useDrop({
  //   accept: 'section',

  //   hover(item: ItemProps, monitor: DropTargetMonitor) {
  //     const source = item.index;
  //     const target = index;

  //     if (!ref.current || source === target) {
  //       onResetSorting();
  //       return;
  //     }

  //     const hoverBoundingRect = ref.current.getBoundingClientRect();
  //     const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
  //     const clientOffset = monitor.getClientOffset();
  //     const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

  //     // Only perform the move when the mouse has crossed half of the items height
  //     // When dragging downwards, only move when the cursor is below 50%
  //     // When dragging upwards, only move when the cursor is above 50%
  //     // Dragging downwards
  //     if (item.index < index && hoverClientY < hoverMiddleY) return;

  //     // Dragging upwards
  //     if (item.index > index && hoverClientY > hoverMiddleY) return;

  //     onSort(source, target);
  //   },
  // });

  const handleRemove = useCallback((): void => {
    onRemove();
    closeModal();
  }, [onRemove, closeModal]);

  // drag(drop(ref));

  // useEffect(() => {
  //   // Disable drag preview
  //   preview(getEmptyImage(), { captureDraggingState: true });
  // }, [preview]);

  // useEffect(() => {
  //   onSetIsSorting(isDragging);
  // }, [onSetIsSorting, isDragging]);

  const handleInitEditor = () => {
    if (!shouldModalOpen()) {
      setIsInitiatedEditor(true);
    }
  };

  const handleOnInsert = (event?: Event) => {
    if (!isUndefined(event)) {
      event.stopPropagation();
    }
    onInsert();
  };

  return (
    <>
      <Box
        sx={mergeStyles(
          styles.section,
          [styles.notFocusable, isLocked],
          // [styles.isDragging, isDragging],
        )}
        data-type="editable-section"
        data-index={index}
      >
        <Box ref={ref}>
          {isLocked && <Box sx={styles.lock}>Locked by another party &#x1F512;</Box>}
          {isSaving && (
            <Box sx={styles.saving} data-type="saving-msg">
              Saving changes
            </Box>
          )}

          {initializedEditorIndex === index ? (
            <Editor
              fields={fields}
              index={index}
              mode={EditorMode.ContentEditable}
              content={content}
              // sortRef={drag}
              onChange={onChange}
              onUploadFile={onUploadFile}
              onUploadFileByUrl={onUploadFileByUrl}
              onRemove={openModal}
              isActive={isActive}
              isLocked={isLocked}
              isSorting={isSorting}
              onActivate={onActivate}
              onDeactivate={onDeactivate}
              autoSaveInterval={AUTOSAVE_INTERVAL}
              onInsert={handleOnInsert}
              isSaving={isSaving}
              editableFieldDisabled={editableFieldDisabled}
              isInitiatedEditor={isInitiatedEditor}
            />
          ) : (
            <EditorStub
              content={content}
              mode={EditorMode.ContentEditable}
              isSorting={isSorting}
              onInsert={handleOnInsert}
              onRemove={openModal}
              handleInitEditor={handleInitEditor}
              onActivate={onActivate}
              index={index}
            />
          )}
        </Box>
      </Box>

      <Modal isOpen={shouldModalOpen()} onRequestClose={closeModal}>
        <ConfirmRemoveSection onConfirm={handleRemove} onCancel={closeModal} />
      </Modal>
    </>
  );
};
