import React, { FC } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { useNavigate } from 'react-router-dom';

import { validateUserForm } from 'src/utils/validate';
import { UserForm } from 'src/v2/features/profile/profileSidebar/UserForm';
import { InviteFormProps } from 'src/v2/features/profile/profileSidebar/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/app/types';
import { book } from 'src/app/book';
import { InviteUserToOrganizationActionPayload } from 'src/v2/boundary/actionPayload/organization';
import { inviteUser } from 'src/v2/features/organization';
import { getIsLoading } from 'src/shared/organization';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

const FORM_ID = 'createUser';
export const InviteUserFormView: FC<InjectedFormProps<InviteFormProps>> = (props) => {
  const { reset } = props;
  const dispatch = useDispatch();
  const authUser = useSelector((store: RootState) => store.auth);
  const navigate = useNavigate();
  const isLoading = useSelector(getIsLoading);
  const handleFormSubmit = (values: InviteFormProps): void => {
    const invitingUser: InviteUserToOrganizationActionPayload = {
      organizationId: authUser.companyId || '',
      roleId: values.permission,
      functionalRole: values.role,
      user: {
        phone: values.phone,
        email: values.email,
        profile: {
          firstName: values.firstName,
          lastName: values.lastName,
        },
      },
    };

    dispatch(inviteUser(invitingUser));
    reset();
    navigate(book.profile.users.generatePath());
  };
  return (
    <UserForm
      {...props}
      handleFormSubmit={handleFormSubmit}
      actionLabel={i18n(translationKeys.buttons.sendInvite)}
      isLoading={isLoading}
    />
  );
};

export const InviteUserForm = reduxForm<InviteFormProps>({
  validate: validateUserForm,
  form: FORM_ID,
  enableReinitialize: true,
})(InviteUserFormView);
