import React, { FC, useContext } from 'react';

import { CommonAction } from '../CommonAction';

import { SignatureActionContext } from '../../contexts/SignatureActionContext';

export const SignatureAction: FC = ({ children }) => {
  const context = useContext(SignatureActionContext);

  return (
    <CommonAction {...context} label="signature">
      {children}
    </CommonAction>
  );
};
