import { isNull } from 'lodash';

import { ListItem } from 'src/models/list';
import { NodeTree, NodeMap } from 'src/models/node';
import { FolderNodes } from 'src/v2/features/quantumNavigator/types';
import { FolderWithEntities } from 'src/v2/features/folderObjects/types';

import { CardPaperModel } from '../../../../models/card';

interface FolderObject {
  id: string;
  cardPaper: CardPaperModel<unknown, unknown>[];
  item_id?: string;
}

export const buildFolderPath = (
  currentFolder: NodeTree<ListItem> | null,
  foldersMap: Record<string, NodeMap<FolderNodes>>,
): ListItem[] => {
  if (isNull(currentFolder)) return [];

  let iterationLimit = 50;
  let { parentId } = currentFolder.data;
  const folders: ListItem[] = [currentFolder.data];

  while (parentId !== '' && iterationLimit) {
    folders.unshift(foldersMap[parentId].data);
    parentId = foldersMap[parentId].data.parentId;
    iterationLimit--;
  }

  return folders;
};

const isFileInFolderExists = (folderObjects: FolderObject[], entityId: string | null): boolean => {
  let result: boolean = false;

  folderObjects.forEach((objectItem) => {
    result = objectItem.item_id === entityId;
  });

  return result;
};

const getFolderId = (
  entityId: string | null,
  rootFolderId: string | undefined,
  foldersList: FolderWithEntities[],
): string | undefined => {
  let folderId: string | undefined = rootFolderId;

  if (entityId) {
    foldersList.forEach((folder) => {
      if (folder.object.length) {
        if (isFileInFolderExists(folder.object, entityId)) folderId = folder.id;
      }
    });
  }

  return folderId;
};

export const getFolderIdWithoutQuery = (
  entityId: string | null,
  rootFolderId: string | undefined,
  foldersList: FolderWithEntities[],
): string | undefined => {
  return getFolderId(entityId, rootFolderId, foldersList);
};
