import React from 'react';

import { LightPlus } from 'src/v2/icons/LightPlus';
import { LightFileAlt } from 'src/v2/icons/LightFileAlt';
import { RenderSystemCardProps } from 'src/v2/features/objectsList/ObjectsListPage';
import { ContextMenuBook } from 'src/app/types';
import { useContextMenu } from 'src/v2/features/contextMenu';

interface Props {
  systemCardPaper: RenderSystemCardProps;
  createContract: () => void;
}

export const ContractSystemCard: React.FC<Props> = ({ systemCardPaper, createContract }) => {
  const { title, paperType, description, templateId } = systemCardPaper;

  const contextMenuHandler = useContextMenu(ContextMenuBook.SystemTemplate, { id: templateId });

  return (
    <div className="c-template__item" ref={contextMenuHandler}>
      <div className="c-template__header">
        <div className="c-template__title">{title}</div>
        <div className="c-template__add">
          <div className="c-template__add--document" onClick={createContract}>
            <LightPlus />
            {paperType.toUpperCase()}
          </div>
        </div>
      </div>
      <div className="c-template__main">
        <div className="c-template__content">
          <div className="c-template__row">
            <div className="c-template__icon">
              <LightFileAlt />
            </div>
            <div className="c-template__line">
              <div className="c-template__line--item"></div>
              <div className="c-template__line--item"></div>
              <div className="c-template__line--item"></div>
            </div>
          </div>
          <div className="c-template__row">
            <div className="c-template__text">{description}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
