import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { openModal } from 'src/v2/features/modal/store';

import { isNull } from 'lodash';
import { ModalType } from 'src/v2/features/modal/types';
import { setActiveFolderId, setFolderId, setTargetId } from './store';

export const useCreateGoToFolderByIdHandler = (folderId: string | null) => {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(setFolderId(folderId));
    dispatch(setActiveFolderId(folderId));
  }, [dispatch, folderId]);
};

export const useOpenModal = ({
  menuItemFolderId,
  type,
  targetId,
}: {
  menuItemFolderId: string | null;
  type: ModalType;
  targetId: string;
}) => {
  const dispatch = useDispatch();

  return useCallback(() => {
    if (isNull(menuItemFolderId)) return;
    dispatch(setFolderId(menuItemFolderId));
    dispatch(setActiveFolderId(menuItemFolderId));
    dispatch(setTargetId(targetId));

    dispatch(
      openModal({
        type,
        wrapperClassName: 'c-modal__move',
      }),
    );
  }, [targetId, menuItemFolderId, dispatch, type]);
};

export const useOpenModalWithProjects = ({
  menuItemFolderId,
  projectsRootFolderId,
  type,
  targetId,
}: {
  projectsRootFolderId: string | null;
  menuItemFolderId: string | null;
  type: ModalType;
  targetId: string;
}) => {
  const dispatch = useDispatch();

  return useCallback(() => {
    if (isNull(projectsRootFolderId) || isNull(menuItemFolderId)) return;
    dispatch(setFolderId(menuItemFolderId));
    dispatch(setActiveFolderId(menuItemFolderId));
    dispatch(setTargetId(targetId));

    dispatch(
      openModal({
        type,
        wrapperClassName: 'c-modal__move',
      }),
    );
  }, [targetId, menuItemFolderId, dispatch, projectsRootFolderId, type]);
};
