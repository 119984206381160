import { SagaIterator } from 'redux-saga';

import { apiClient } from 'src/common/client';
import { ObjectSerialized } from 'src/common/types';
import { UpdateProfilePayload } from '../models/account';
import { getDefaultHeaders } from '../common/client/apiClient';

export function getUserByIds(
  userIds: string[],
): SagaIterator<ObjectSerialized | null> | Promise<null> {
  const userIdsJoined = userIds.join(',');
  if (userIdsJoined.length) {
    return apiClient.get(`/account?ids=${userIdsJoined}`);
  }
  return Promise.resolve(null);
}

export function setProfileData(profile: UpdateProfilePayload) {
  const data = { attributes: { profile } };

  return apiClient.put(`/account`, { data });
}

export function setProfileAvatar(file: File) {
  const data = new FormData();
  data.append('file', file);

  return apiClient.post('/avatar/users', data);
}

export function fetchMyUserData(): SagaIterator {
  return apiClient.get(`/account/me`);
}

export function updatePhone(
  phone: string,
  token: string,
  captureToken?: string | null,
  orgId?: string,
): SagaIterator {
  const headers = getDefaultHeaders(token, orgId);
  return apiClient.put(
    '/account/updatePhone',
    {
      credentials: 'include',
      data: {
        attributes: {
          phone,
        },
      },
    },
    {
      headers: {
        ...headers.headers,
        Capture: captureToken,
      },
    },
  );
}

export function resendSms(
  token: string,
  orgId?: string,
  captureToken?: string | null,
): SagaIterator {
  const headers = getDefaultHeaders(token, orgId);
  return apiClient.put(
    '/account/resendPhoneCode',
    {
      data: {},
    },
    {
      headers: {
        ...headers.headers,
        Capture: captureToken,
      },
    },
  );
}

export function verifyPhone(code: string): SagaIterator {
  return apiClient.put('/account/verifyPhone', {
    data: {
      attributes: {
        code,
      },
    },
  });
}
