import { EventChannel, SagaIterator } from 'redux-saga';
import { get } from 'lodash';

import { FieldUpdateType, UploadData } from 'src/models/editor';
import { DocumentPayload, DocumentRole, DocumentType } from 'src/models/document';
import { apiClient } from 'src/common/client';
import { ObjectSerialized } from 'src/common/types';
import { ContractRole, ContractType } from 'src/models/contract';
import { EntityType } from 'src/models/paper';
import { appendDataObjectIfDefined } from 'src/common/utils';
import {
  CopyDocumentToFolderAPIDTO,
  CreateDocumentAPIDTO,
  CreateDocumentDTO,
  CreateDocumentFromTemplateAPIDTO,
  CreateDocumentFromTemplateDTO,
} from 'src/v2/boundary/requestDTO/document';
import { CreateContractAPIDTO } from 'src/v2/boundary/requestDTO/contract';
import {
  InviteUserWithNotificationsDTO,
  InviteUserDTO,
} from 'src/v2/boundary/requestDTO/inviteUser';
import { JsonApiRequest } from 'src/v2/boundary/requestDTO/jsonApi';
import { BaseInviteParticipantEntity } from 'src/v2/entities/participants';
import { UpdateFieldPayload } from 'src/v2/features/documentDetails/types';

const editApiDataAdapter = (payload: InviteUserData): InviteUserWithNotificationsDTO<unknown> => {
  const {
    firstName,
    lastName,
    phone,
    email,
    roleId,
    funcRole,
    conditionRequired,
    emailNotification = false,
  } = payload;
  return {
    roleId,
    funcRole,
    affiliationType: get(payload, 'affiliationType'),
    conditionRequired,
    user: {
      phone,
      email,
      profile: {
        firstName,
        lastName,
      },
    },
    notifications: {
      alarmBell: true,
      email: emailNotification,
      sms: false,
    },
  };
};

export function fetchDocumentApi(documentId: string): SagaIterator<ObjectSerialized | null> {
  return apiClient.get(`/gateway/v2/papers/${documentId}/open?makeUrl=true`);
}

export function fetchDocumentInfoApi(documentId: string): SagaIterator<ObjectSerialized | null> {
  return apiClient.get(`/gateway/v2/papers/${documentId}`);
}

export function fetchDocumentByQRApi(documentId: string): SagaIterator<ObjectSerialized | null> {
  return apiClient.get(`/documents/qr/${documentId}`);
}

export function fetchWorkflowApi(workflowId: string): SagaIterator<ObjectSerialized | null> {
  return apiClient.get(`/workflow/${workflowId}`);
}

export function createDocumentAPI(
  data: CreateDocumentAPIDTO | CreateContractAPIDTO,
): SagaIterator<ObjectSerialized | null> {
  return apiClient.post('/documents', data);
}

export function createDocumentInFolderAPI(
  paper: CreateDocumentDTO,
): SagaIterator<ObjectSerialized | null> {
  const { role, title, description, subType, subSubType, type } = paper.data;
  const { folderId } = paper;

  const data: CreateDocumentAPIDTO = {
    data: {
      type: 'document',
      attributes: {
        title,
        description,
        containerType: 'folder',
        containerId: folderId,
        role,
        subType,
        subSubType,
        type,
      },
    },
  };

  return createDocumentAPI(data);
}

export function createDocumentFromUploadAPI(
  file: File,
  document: CreateDocumentDTO,
  containerType: 'folder' | 'project',
): SagaIterator<EventChannel<ObjectSerialized>> {
  const data = new FormData();
  const { title, description, subType, subSubType, type, role } = document.data;
  appendDataObjectIfDefined(data, {
    title,
    description,
    subType: subType,
    subSubType: subSubType,
    type,
    role,
    file,
    containerType,
    containerId: document.folderId,
  });

  return apiClient.uploadFile<ObjectSerialized>(`/gateway/v2/papers/fromFile`, data);
}

export function createDocumentInFolderFromUploadAPI(
  file: File,
  document: CreateDocumentDTO,
): SagaIterator<EventChannel<ObjectSerialized>> {
  return createDocumentFromUploadAPI(file, document, 'folder');
}

export function uploadDocumentAttachmentApi(
  file: File,
  documentId: string,
): SagaIterator<EventChannel<ObjectSerialized>> {
  const data = new FormData();
  data.append('file', file);

  return apiClient.uploadFile<ObjectSerialized>(`/documents/${documentId}/attachment`, data);
}

export function fetchDocumentAttachments(
  documentId: string,
): SagaIterator<ObjectSerialized | null> {
  return apiClient.get(`/documents/${documentId}/attachment`);
}

export function deleteDocumentAttachmentApi(
  documentId: string,
  attachmentId: string,
): SagaIterator<ObjectSerialized | null> {
  return apiClient.delete(`/documents/${documentId}/attachment/${attachmentId}`);
}

export function updateDocumentAccessAPI(
  documentId: string,
  accessLevel: string,
): SagaIterator<ObjectSerialized | null> {
  const data = {
    attributes: {
      accessLevel,
    },
  };

  return apiClient.put(`/documents/access/${documentId}`, { data });
}

export const createDocumentFromTemplateAPIFactory =
  (containerType: 'project' | 'folder') =>
  ({
    sourceId,
    folderId,
    ...rest
  }: CreateDocumentFromTemplateDTO): SagaIterator<ObjectSerialized | null> => {
    const { role, title, description, subType, subSubType, type } = rest.data;
    const data: CreateDocumentFromTemplateAPIDTO = {
      data: {
        type: 'document',
        attributes: {
          sourceId,
          role,
          title,
          description,
          subType,
          subSubType,
          type,
          containerType,
          containerId: folderId,
        },
      },
    };
    return apiClient.post('/documents/fromTemplate', data);
  };

export const createDocumentFromTemplateAPI = createDocumentFromTemplateAPIFactory('folder');

export function updateDocumentApi(
  document: DocumentPayload,
  documentId: string,
): SagaIterator<ObjectSerialized | null> {
  const data = {
    type: 'document',
    attributes: document,
  };

  return apiClient.put(`/documents/${documentId}`, { data });
}

export function deleteDocumentApi(id: string): SagaIterator<ObjectSerialized | null> {
  return apiClient.delete(`/gateway/v2/papers/${id}`);
}

export function fetchDocumentQRCodeAPI(id: string): SagaIterator<ObjectSerialized | null> {
  return apiClient.get(`/documents/qr/${id}`);
}

export function insertSectionApi(
  documentId: string,
  index: number,
  content: string,
  fields: FieldData[],
  isTemplate = false,
): SagaIterator<ObjectSerialized | null> {
  const data = { path: [index], text: content, fields, isTemplate };

  return apiClient.post(`/documents/${documentId}/insertSection`, { data });
}

export function updateSectionApi(
  documentId: string,
  index: number,
  content: string,
  fields: FieldData[],
  isTemplate = false,
): SagaIterator<ObjectSerialized | null> {
  const data = { isTemplate, path: [index], text: content, fields };

  return apiClient.post(`/documents/${documentId}/modifySection`, { data });
}

export function moveSectionApi(
  documentId: string,
  source: number,
  target: number,
  isTemplate = false,
): SagaIterator<ObjectSerialized | null> {
  const data = { isTemplate, oldPath: [source], newPath: [target] };

  return apiClient.post(`/documents/${documentId}/moveSection`, { data });
}

export function removeSectionApi(
  documentId: string,
  index: number,
  isTemplate = false,
): SagaIterator<ObjectSerialized | null> {
  const data = { isTemplate, path: [index] };

  return apiClient.post(`/documents/${documentId}/removeSection`, { data });
}

export function uploadFileApi(documentId: string, file: File): SagaIterator<UploadData | null> {
  const data = new FormData();
  data.append('file', file);

  return apiClient.post<UploadData | null>(`/documents/${documentId}/upload`, data);
}

export function modifyFileApi(documentId: string, file: File): SagaIterator<UploadData | null> {
  const data = new FormData();
  data.append('file', file);

  return apiClient.post<UploadData | null>(`/documents/${documentId}/modifyFile`, data);
}

export function uploadFileByUrlApi(
  documentId: string,
  url: string,
): SagaIterator<UploadData | null> {
  const data = { attributes: { url } };

  return apiClient.post<UploadData | null>(`/documents/${documentId}/uploadByUrl`, { data });
}

export function workflowActionApi(
  documentId: string,
  action: string,
  payload?: object,
): SagaIterator<ObjectSerialized | null> {
  const data = {
    type: `${action}_payload`,
    attributes: payload || {},
  };

  return apiClient.post(`/workflow/${documentId}/processAction`, { data });
}

export function downloadAsFileAPI(documentId: string) {
  return apiClient.get(`/gateway/v2/papers/${documentId}/downloadUrl`);
}

export function updateFieldApi(
  documentId: string,
  index: number,
  content: string,
  updateType: FieldUpdateType,
  fieldId: string,
  fieldValue: string,
): SagaIterator<ObjectSerialized | null> {
  const data = {
    type: 'modify_field_payload',
    attributes: {
      path: [index],
      text: content,
      action: updateType,
      fieldId,
      fieldValue,
    },
  };
  return apiClient.post(`/workflow/${documentId}/processAction`, { data });
}

export function updateFieldsApi(
  documentId: string,
  fields: UpdateFieldPayload[],
): SagaIterator<ObjectSerialized | null> {
  const data = {
    type: 'modify_fields_payload',
    attributes: fields,
  };
  return apiClient.post(`/workflow/${documentId}/processAction`, { data });
}

export type InviteUserData =
  | BaseInviteParticipantEntity<DocumentRole>
  | BaseInviteParticipantEntity<ContractRole>;

export function inviteUserApi(
  documentId: string,
  payload: InviteUserData,
): SagaIterator<ObjectSerialized | null> {
  const { firstName, lastName, phone, email, roleId, conditionRequired, funcRole } = payload;
  const data: JsonApiRequest<InviteUserDTO<unknown>> = {
    attributes: {
      roleId,
      funcRole,
      conditionRequired,
      affiliationType: get(payload, 'affiliationType'),
      user: {
        phone,
        email,
        profile: {
          firstName,
          lastName,
        },
      },
    },
  };

  return apiClient.post(`/documents/${documentId}/invite`, { data });
}

interface ShareData {
  emails: string[];
  message: string;
}

export function shareApi(
  documentId: string,
  payload: ShareData,
): SagaIterator<ObjectSerialized | null> {
  const { emails, message } = payload;
  const data = {
    type: 'document',
    attributes: {
      users: emails.map((email) => ({ email })),
      message,
    },
  };

  return apiClient.post(`/documents/${documentId}/share`, { data });
}

export function qrCodeShareAPI(
  documentId: string,
  payload: ShareData,
): SagaIterator<ObjectSerialized | null> {
  const { emails, message } = payload;
  const data = {
    type: 'document',
    attributes: {
      users: emails.map((email) => ({ email })),
      message,
    },
  };

  return apiClient.post(`/documents/${documentId}/qrshare`, { data });
}

export function readyToSignApi(documentId: string): SagaIterator<ObjectSerialized | null> {
  return apiClient.post(`/workflow/${documentId}/readyToSign`);
}

export const updateUserApi = (
  paperId: string,
  payload: InviteUserData,
  userId?: string,
): SagaIterator<ObjectSerialized | null> => {
  const data = editApiDataAdapter(payload);

  return apiClient.patch(`/gateway/v2/papers/${paperId}/participants/${userId}`, {
    ...data,
  });
};

export const removeUserApi = (
  paperId: string,
  userId: string,
): SagaIterator<ObjectSerialized | null> =>
  apiClient.delete(`/gateway/v1/papers/${paperId}/participant/${userId}`, {});

export const DocumentTypeValues = {
  [EntityType.document]: DocumentType,
  [EntityType.contract]: ContractType,
};

export enum AllowCounterPartyEditing {
  Yes = 'true',
  No = 'false',
}

// Values should be the same as a keys
export enum CreationMode {
  blank = 'blank',
  template = 'template',
  upload = 'upload',
}

export interface FieldData {
  id?: string;
  name: string;
  offset: number;
  value: string;
  editorFieldId?: string;
}

export function copyDocumentToFolderAPI(
  documentId: string,
  folderId: string,
): SagaIterator<ObjectSerialized | null> {
  const payload: CopyDocumentToFolderAPIDTO = {
    data: {
      type: 'document',
      attributes: {
        documentId,
        containerId: folderId,
        containerType: 'folder',
      },
    },
  };
  return apiClient.post(`/documents/copy`, payload);
}

export function fetchDocumentPreviewAPI(documentId: string): SagaIterator<ObjectSerialized | null> {
  return apiClient.get(`/documents/public/${documentId}`);
}
