/* eslint-disable no-param-reassign */
import { concat, flatten, get } from 'lodash';
import { InviteUserData } from 'src/api/documents';
import { DocumentRole, DefaultDocumentRole } from 'src/models/document';

import { WorkflowEntity } from 'src/v2/entities/workflow';
import { NormalizedChat } from 'src/v2/features/chat/types';
import defaultAvatar from 'src/assets/placeholders/user-avatar.png';
import { RootState } from 'src/app/types';
import { PaperParticipantModel } from 'src/models/paper';
import { getTemplate as getSelectedTemplate } from 'src/v2/features/template/selectors';
import { SidebarParticipantEntity } from 'src/v2/entities/participants';
import { PaperParticipantsAffiliationType } from 'src/v2/boundary/storageModels/paper/paperParticipant';

import { getParticipantsRaw } from './selectors';

export const getMyRole = (
  participants: { userId: string; roleId: DocumentRole }[],
  myUserId: string | null,
): DocumentRole => {
  const me = participants.find((participant) => participant.userId === myUserId);
  return me ? me.roleId : DefaultDocumentRole;
};

export interface WorkflowConditionsMap {
  [userId: string]: Partial<Pick<InviteUserData, 'conditionRequired'>>;
}

export const getConditionsMap = (workflow: WorkflowEntity): WorkflowConditionsMap => {
  const partyConditions = workflow.parties.map(({ conditions }) => conditions || []);
  const ownerConditions = workflow.owner.conditions || [];
  const allConditions = concat(ownerConditions, flatten(partyConditions));

  const conditionMap = allConditions.reduce((workflowConditionsMap, currentCondition) => {
    workflowConditionsMap[currentCondition.userId] = { conditionRequired: true };
    return workflowConditionsMap;
  }, {} as WorkflowConditionsMap);

  return conditionMap;
};

export const mapConditionsToParticipants = <R extends { userId: string }>(
  participants: R[],
  condsMap: WorkflowConditionsMap,
): R[] & { conditionRequired?: boolean }[] =>
  participants.map((p) => (condsMap[p.userId] ? { ...p, ...condsMap[p.userId] } : p));

/** @deprecated */
export const getDocumentParticipantsIds = (state: RootState): string[] =>
  getParticipantsRaw(getSelectedTemplate)(state).map((p) => p.userId);

export const convertPaperParticipantToCompleteParticipantsV2 = <R>(
  raw: PaperParticipantModel<R>[],
  chats: NormalizedChat[],
  workflow: WorkflowEntity | null,
  workflowParticipants,
): SidebarParticipantEntity<R>[] => {
  const getAffiliationTypeById = (id: string): PaperParticipantsAffiliationType | null =>
    workflowParticipants &&
    workflowParticipants.find((participant) => participant.userId === id)?.affiliationType;

  const usersWithParticipants: SidebarParticipantEntity<R>[] = raw
    .map((participant) => {
      return {
        ...participant,
        ...participant.user,
        id: participant.user.id,
        role: participant.funcRole,
        email: participant.user.email,
        avatar: participant.user.avatar || defaultAvatar,
        affiliationType: getAffiliationTypeById(participant.user.id),
      };
    })
    .map((p) => {
      const chatIdx = chats.findIndex((c) => c.oppositeMember.id === p.id);

      if (chatIdx !== -1) {
        return {
          ...p,
          unreadCount: chats[chatIdx].newCount,
        };
      }

      return p;
    });

  if (workflow) {
    const conditionMap = getConditionsMap(workflow);
    return mapConditionsToParticipants(usersWithParticipants, conditionMap);
  }

  return usersWithParticipants;
};

export const getOwnerConditionRequired = (
  participants: {
    roleId: DocumentRole;
    conditionRequired?: boolean;
  }[],
): boolean => {
  const owner = participants.find((p) => p.roleId === DocumentRole.Owner);
  return get(owner, 'conditionRequired', false) as boolean;
};

export const findParticipantById = (availableChats: NormalizedChat[], userId: string) =>
  availableChats.find((chat) => chat.oppositeMember.id === userId);
