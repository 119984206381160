import React from 'react';

interface Props {
  title: string;
  count: number;
}

export const DocumentCounter = ({ title, count }: Props): JSX.Element => (
  <div className="c-usage__row">
    <div className="c-usage__item">
      <div className="usage__title">{title}</div>
      <div className="c-usage__count">{count}</div>
    </div>
  </div>
);
