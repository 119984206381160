import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { Link } from '@theme-ui/components';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { isNull } from 'lodash';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { CForm, CFormRow } from 'src/v2/components/CForm';
import { Email } from 'src/v2/features/reduxForm/Email';
import { PhoneNumber } from 'src/v2/components/PhoneNumber';
import { validateAuthForm } from 'src/utils/validate';
import { getSignupFormData } from 'src/v2/features/signUp/store';
import { Button } from 'src/v2/features/button';
import { normalizeNumbers } from 'src/utils/normalize';
import { requestOptCode } from './qrCodeAccountRecoveryStore';
import { Capture } from '../../components/Сapture/Capture';
import { useIsNoCaptureMode } from '../../../utils/hooks';

export type Values = {
  email: string;
  phone: string;
};

interface Props {
  onLoginFlow?: () => void;
  onSignUpFlow: () => void;
}

type AccountRecoveryStepOneFormProps = Props & InjectedFormProps<Values, Props>;

export const AccountRecoveryStepOneFormName = 'qr-code-account-recovery-formlight';

export const Form: React.FC<AccountRecoveryStepOneFormProps> = ({
  handleSubmit,
  invalid,
  submitting,
  pristine,
  initialize,
  onLoginFlow,
  onSignUpFlow,
}) => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const data = useSelector(getSignupFormData);
  const [captureToken, setCaptureToken] = useState<string | null>(null);

  const isNoCaptureMode = useIsNoCaptureMode();

  useEffect(() => {
    if (data.email) initialize(data);
  }, [initialize, data]);

  const submitForm = useCallback(
    (values: Values): void => {
      const { email, phone } = values;
      dispatch(
        requestOptCode({
          email,
          phone: `+${normalizeNumbers(phone)}`,
          formName: AccountRecoveryStepOneFormName,
          captureToken: !isNull(captureToken) ? captureToken : '',
        }),
      );
    },
    [dispatch, captureToken],
  );

  const handleSubmitCapture = (token: string) => {
    setCaptureToken(token);
  };

  return (
    <div className="c-modal__row c-modal--mt-24">
      <CForm className="c-form c-form--grow" onSubmit={handleSubmit(submitForm)} noValidate>
        <CFormRow>
          <Field
            className="c-form__column"
            name="email"
            type="email"
            placeholder={i18n(translationKeys.forms.signup.fields.email)}
            component={Email}
          />
        </CFormRow>
        <CFormRow>
          <Field
            className="c-form__column"
            sx={{ display: 'flex', flexDirection: 'column-reverse' }}
            name="phone"
            component={PhoneNumber}
          />
        </CFormRow>
        {!isNoCaptureMode && <Capture onChange={handleSubmitCapture} isShow={!invalid} />}
        <CFormRow>
          <div className="c-form__column c-form__column--2">
            <Button
              className="c-button c-button--fs-12 c-button--w-full h-uppercase"
              data-type="signup-cancel"
              type="button"
              onClick={onLoginFlow}
            >
              {i18n(translationKeys.buttons.cancel)}
            </Button>
          </div>
          <div className="c-form__column c-form__column--2">
            <Button
              className="c-button c-button--fs-12 c-button--w-full h-uppercase"
              data-type="login-submit"
              type="submit"
              disabled={
                invalid || submitting || pristine || (isNull(captureToken) && !isNoCaptureMode)
              }
            >
              {i18n(translationKeys.buttons.sendTheCode)}
            </Button>
          </div>
        </CFormRow>
        <CFormRow className="c-form--mt-32">
          <div className="c-form__column">
            {i18n(translationKeys.forms.loginLight.noAccount)}{' '}
            <Link
              to={{ pathname, search }}
              className="c-form__link"
              data-type="signupNow-link"
              as={RouterLink}
              onClick={onSignUpFlow}
            >
              {i18n(translationKeys.forms.loginLight.signUp)}
            </Link>
          </div>
        </CFormRow>
      </CForm>
    </div>
  );
};

export const AccountRecoveryStepOneForm = reduxForm<Values, Props>({
  form: AccountRecoveryStepOneFormName,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateAuthForm,
})(Form);
