import { SagaIterator } from '@redux-saga/types';
import { takeLatest, select, put, call, debounce } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { toastr } from 'react-redux-toastr';
import { isEmpty } from 'lodash';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { getFullNameFromProfile } from 'src/v2/features/profile';
import { renderElementToImage } from 'src/utils/canvas';
import { readImageFile, dataURLtoFile } from 'src/utils/files';
import { handleErrorSaga } from 'src/utils/handleErrorSaga';
import { storeData } from 'src/v2/features/objectsStorage/objectsStorageSlice';
import {
  fetchSignaturesApi,
  createSignatureApi,
  updateSignatureApi,
  uploadSignatureApi,
  setDefaultSignatureApi,
} from 'src/api/signatureApi';
import { SignatureFont } from 'src/models/signature';

import {
  fetchSignatures,
  editSignature,
  setDefaultSignature,
  startLoading,
  stopLoading,
  finishLoading,
  setData,
  renderPreview,
  setLegalName,
  setFont,
  setSignedBy,
  setCompanyName,
  setCompanyTitle,
  setIncludeCompanyMeta,
  setSignatureFile,
  setSignaturePreview,
  setSignatureHash,
  removeSignaturePreview,
  setUploadedImageUrl,
  saveSignature,
} from './signatureReducers';
import {
  getUserSignatureList,
  getLastSignatureId,
  getSignatureId,
  getCompanyName,
  getCompanyTitle,
  getFont,
  getIncludeCompanyMeta,
  getLegalName,
  getSignaturePreview,
  getSignedBy,
  getSignatureFile,
  getSignatureHash,
} from './signatureSelectors';
import { getSignatureById, normalizeSignatureData } from '../utils';
import { allSignaturesFonts } from '../config';
import { CreateSignaturePayload, UpdateSignaturePayload, FontConfig } from '../types';

interface GeneratedElement {
  el: HTMLElement;
  cleanup: () => void;
}

function findConfigByFont(font: SignatureFont): FontConfig | undefined {
  return allSignaturesFonts.find((config) => config.name === font);
}

function generateSignatureElement(text: string, font: SignatureFont): GeneratedElement {
  const el = document.createElement('span');
  el.innerText = text;
  const config = findConfigByFont(font);

  if (config) {
    el.style.fontFamily = config.style.fontFamily;
  }

  el.style.lineHeight = '1.4';
  el.style.fontSize = '42px';
  el.style.color = 'black';
  el.style.padding = '0 3px';

  document.body.appendChild(el);

  return {
    el,
    cleanup: () => el.remove(),
  };
}

function* editSignatureSaga(action: PayloadAction<string>) {
  try {
    const signatures = yield select(getUserSignatureList);
    const signature = getSignatureById(signatures, action.payload);
    const fullName = yield select(getFullNameFromProfile);

    if (signature) {
      const signatureData = normalizeSignatureData(signature);

      // Prepopulate signature-name input and signature preview of signed-by
      // @link src/v2/features/signature/components/SignaturePreview/SignaturePreview.tsx
      if (fullName && isEmpty(signatureData.userName)) {
        signatureData.userName = fullName;
      }

      yield put(setLegalName(signatureData.legalName));
      yield put(setSignatureHash(signatureData.hash));
      yield put(setFont(signatureData.style as SignatureFont));
      yield put(setIncludeCompanyMeta(signatureData.includeCompany));
      yield put(setCompanyName(signatureData.companyName));
      yield put(setCompanyTitle(signatureData.userTitle));
      yield put(setSignedBy(signatureData.userName));
      if (signatureData.imageUrl) {
        yield put(setUploadedImageUrl(signatureData.imageUrl));
      } else {
        yield put(setUploadedImageUrl(null));
        yield put(renderPreview());
      }
    }
  } catch (error) {
    yield call(handleErrorSaga, error);
  }
}

function* renderPreviewSaga(): SagaIterator {
  try {
    const text = yield select(getLegalName);
    const font = yield select(getFont);

    if (text && font) {
      const { cleanup, el }: GeneratedElement = yield call(generateSignatureElement, text, font);
      const base64DataUrl = yield call(renderElementToImage, el);
      yield put(setSignaturePreview(base64DataUrl));
      cleanup();
    } else {
      yield put(removeSignaturePreview());
    }
  } catch (error) {
    yield call(handleErrorSaga, error);
  }
}

function* setSignatureFileSaga(action: PayloadAction<File>) {
  try {
    const image: HTMLImageElement = yield call(readImageFile, action.payload);
    yield put(setSignaturePreview(image.src));
  } catch (error) {
    yield call(handleErrorSaga, error);
  }
}

export function* getSignaturePayload() {
  const signatureId = yield select(getSignatureId);
  const legalName = yield select(getLegalName);
  const fullName = yield select(getFullNameFromProfile);
  const font = yield select(getFont);
  const includeCompanyMeta = yield select(getIncludeCompanyMeta);
  const companyName = yield select(getCompanyName);
  const companyTitle = yield select(getCompanyTitle);
  const signatureUserName = yield select(getSignedBy);
  const signaturePreview = yield select(getSignaturePreview);
  const hash = yield select(getSignatureHash);
  let file = yield select(getSignatureFile);

  if (!file && signaturePreview) {
    file = yield call(dataURLtoFile, signaturePreview);
  }

  const payload = {
    hash: hash,
    legal_name: legalName || fullName || i18n(translationKeys.forms.signature.signed),
    include_stamp: false,
    localizing: false,
    include_company: includeCompanyMeta,
    style: font,
    signature_description: {
      company: companyName,
      title: companyTitle,
      name: signatureUserName,
    },
  };

  return { signatureId, file, payload };
}

export function* fetchSignaturesSaga(): SagaIterator {
  try {
    const response = yield call(fetchSignaturesApi);
    yield put(storeData(response));

    yield put(
      setData({
        id: response.data[0].id,
        type: 'userSignature',
      }),
    );
  } catch (error) {
    handleErrorSaga(error);
  }
}

export function* createSignatureSaga(payload: CreateSignaturePayload) {
  try {
    const { signature, file } = payload;
    yield call(createSignatureApi, signature);
    yield call(fetchSignaturesSaga);
    const signatureId = yield select(getLastSignatureId);
    if (file) yield call(uploadSignatureApi, file, signatureId);
    yield call(setDefaultSignatureApi, signatureId);
    yield call(
      toastr.success,
      i18n(translationKeys.messages.success),
      i18n(translationKeys.forms.signature.signatureCreated),
    );
    yield call(fetchSignaturesSaga);
  } catch (error) {
    handleErrorSaga(error);
  }
}

export function* updateSignatureSaga(payload: UpdateSignaturePayload) {
  try {
    const { file, signatureId, signature } = payload;
    if (file) yield call(uploadSignatureApi, file, signatureId);
    yield call(updateSignatureApi, signatureId, signature);
    yield call(
      toastr.success,
      i18n(translationKeys.messages.success),
      i18n(translationKeys.forms.signature.signatureUpdated),
    );
    yield call(fetchSignaturesSaga);
  } catch (error) {
    handleErrorSaga(error);
  }
}

function* setDefaultSignatureSaga(action: PayloadAction<string>): SagaIterator {
  try {
    yield call(setDefaultSignatureApi, action.payload);
    yield call(
      toastr.success,
      i18n(translationKeys.messages.success),
      i18n(translationKeys.forms.signature.defaultSignatureUpdated),
    );
    yield call(fetchSignaturesSaga);
  } catch (error) {
    handleErrorSaga(error);
  }
}

function* submitSignatureSaga() {
  try {
    yield put(startLoading());

    const { signatureId, file, payload: signature } = yield call(getSignaturePayload);

    if (signatureId) {
      yield updateSignatureSaga({ signatureId, file, signature });
    } else {
      yield createSignatureSaga({ signature, file });
    }

    yield put(finishLoading());
  } catch (error) {
    yield call(handleErrorSaga, error);
    yield put(stopLoading(error));
  }
}

export function* watchSignatureSagas(): SagaIterator {
  yield takeLatest(fetchSignatures, fetchSignaturesSaga);
  yield takeLatest(editSignature, editSignatureSaga);
  yield takeLatest(setDefaultSignature, setDefaultSignatureSaga);
  yield takeLatest(setSignatureFile, setSignatureFileSaga);
  yield takeLatest(saveSignature, submitSignatureSaga);
  yield debounce(300, renderPreview, renderPreviewSaga);
}
