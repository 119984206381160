export enum PaperParticipantsAffiliationType {
  owner = 'owner',
  counterparty = 'counterparty',
}

export interface PaperParticipantModel<R> {
  roleId: R;
}

export interface PaperUserModel {
  id: string;
  avatar: string;
  created_at: string;
  email: string;
  first_name: string;
  last_name: string;
  user_id: string;
}
