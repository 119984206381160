import { ReactElement, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { normalizeNumbers } from 'src/utils/normalize';

import { SignUpStepComponent, useSignUpProps } from './hooks';
import { ResendSmsPayload } from '../types';
import { verifyPhoneAction, resendSmsAsync } from '../store';
import { PhoneVerificationForm } from '../PhoneVerification';

export const usePhoneVerificationComponent = (): SignUpStepComponent => {
  const { maxSteps, step } = useSignUpProps();
  const dispatch = useDispatch();

  const submitVerifyCode = useCallback(
    (values: any) => {
      dispatch(
        verifyPhoneAction({
          ...values,
          phone: `+${normalizeNumbers(values.phone)}`,
        }),
      );
    },
    [dispatch],
  );

  const submitResendSms = useCallback(
    (payload: ResendSmsPayload): void => {
      dispatch(resendSmsAsync(payload));
    },
    [dispatch],
  );

  const props = {
    verifyCode: submitVerifyCode,
    resendSms: submitResendSms,
  };

  return [(): ReactElement => <PhoneVerificationForm {...props} />, { step, maxSteps }];
};
