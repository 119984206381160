import React, { MouseEvent, useContext } from 'react';

import { Chat } from 'src/v2/features/chat/Chat';
import { DocumentType, DocumentRole } from 'src/models/document';
import { getOwnerName } from 'src/v2/features/document';
import { DocumentSidebarAttachments } from 'src/v2/features/documentSidebar/documentSidebarAttachments/documentSidebarAttachments';
import { ParticipantsSection } from 'src/v2/features/sharedEntity';
import { useGetEntityById } from 'src/v2/features/sharedEntity/hooks';
import {
  useCanEditDocument,
  useCanAddDocumentAttachments,
} from 'src/v2/features/document/permissions/documentPermissions/documentPermissionsHooks';
import {
  useCanEditParticipant,
  useCanRemoveParticipant,
} from 'src/v2/features/document/workflow/documentWorkflow';
import { SharedSidebarContext } from 'src/v2/features/sharedEntity/EntitySidebar/SharedSidebarContext';

import { DocumentMetadataView } from '../../components/DocumentMetadata';

interface Props {
  id: string;
  onEdit: (e: MouseEvent) => void;
}

export const AbstractView: React.FC<Props> = ({ id, onEdit }) => {
  const document = useGetEntityById<DocumentType, DocumentRole>(id);
  const isAllowedToEdit = useCanEditDocument(document);
  const isAllowedToEditAttachments = useCanAddDocumentAttachments(document);
  const { isAllowedToChat } = useContext(SharedSidebarContext);
  const isAllowedToEditParticipant = useCanEditParticipant(id);
  const isAllowedToRemoveParticipant = useCanRemoveParticipant(id);

  return document ? (
    <>
      <DocumentMetadataView
        title={document.title}
        description={document.description}
        createdAt={document.createdAt}
        updatedAt={document.updatedAt}
        subType={document.subType as DocumentType}
        subSubType={document.subSubType}
        state={document.state}
        owner={getOwnerName(document)}
        role={document.ownerFunctionRole}
        isAllowedToEdit={isAllowedToEdit}
        onEdit={onEdit}
      />
      <DocumentSidebarAttachments
        documentId={document.id}
        isAllowedToEditAttachments={isAllowedToEditAttachments}
      />
      <ParticipantsSection
        entityId={document.id}
        isAllowedToEditParticipant={isAllowedToEditParticipant}
        isAllowedToRemoveParticipant={isAllowedToRemoveParticipant}
      />
      {isAllowedToChat && <Chat />}
    </>
  ) : null;
};
