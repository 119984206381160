import React, { FC } from 'react';

import { DynamicSettingsContext } from 'src/v2/modules/layout';
import { DynamicSettings } from 'src/v2/modules/layout/types';
import { useShowMetadataCreate, useToggle9Square } from 'src/v2/features/sharedEntity';

import { TemplateQuantumNavigator } from '../TemplateQuantumNavigator';
import { ListHeader } from '../components/ListHeader';
import { useNotificationTogglePush } from '../TemplateSidebar';
import { useCanCreateDocument } from '../../document/permissions/documentPermissions/documentPermissionsHooks';
import { useCanCreateContract } from '../../contract/permissions/contractPermissions/contractPermissionsHooks';

export const DynamicSettingsContextProviderForList: FC = ({ children }) => {
  const toggleNotifications = useNotificationTogglePush();
  const toggle9squareSidebar = useToggle9Square();
  const showMetadataCreate = useShowMetadataCreate();
  const isCanCreateDocument = useCanCreateDocument();
  const isCanCreateContract = useCanCreateContract();

  const handleOnPlusClick =
    isCanCreateContract && isCanCreateDocument ? showMetadataCreate : undefined;

  const ctx: DynamicSettings = {
    headerChildren: <ListHeader />,
    onBellClick: toggleNotifications,
    onGridClick: toggle9squareSidebar,
    navigatorChildren: <TemplateQuantumNavigator />,
    onPlusClick: handleOnPlusClick,
  };

  return <DynamicSettingsContext.Provider value={ctx}>{children}</DynamicSettingsContext.Provider>;
};
