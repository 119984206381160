import React from 'react';
import cn from 'classnames';

import { InputProps } from '../types';
import { WithValidationMessage } from '../WithValidationMessage';

const classModifiers = {
  disabled: 'c-input--disabled',
  readOnly: 'c-input--readonly',
  mb8: 'c-input--mb-8',
};

export const Input = ({
  errorMessage,
  successMessage,
  className,
  mb8 = false,
  ...inputProps
}: InputProps): JSX.Element => {
  const { disabled, readOnly } = inputProps;

  return (
    <WithValidationMessage
      className={cn('c-input', className, {
        [classModifiers.disabled]: disabled,
        [classModifiers.readOnly]: readOnly,
        [classModifiers.mb8]: mb8,
      })}
      errorMessage={errorMessage}
      successMessage={successMessage}
      classPrefix="c-input"
    >
      <label className="c-input__label">
        <input {...inputProps} />
      </label>
    </WithValidationMessage>
  );
};
