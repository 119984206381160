import React, { FC, useCallback } from 'react';
import { isUndefined, noop } from 'lodash';

import { ListItemComponentProps } from 'src/v2/modules/FsItem/types';
import { useGetCurrentFolderId } from 'src/v2/features/quantumNavigator/hooks';
import { buildFolderPath } from 'src/v2/features/breadcrumb/utils';
import { FsItem } from 'src/v2/modules/FsItem';
import { NavigatorFolderView } from 'src/v2/features/quantumNavigator/NavigatorFolderView';
import { NavigatorBody } from 'src/v2/features/quantumNavigator/NavigatorBody';
import { ListItem } from 'src/models/list';

import { useFetchFolders, useGetFoldersMap, useGetFoldersTreeById } from '../hooks';
import { isRootFolder } from '../utils';

interface Props {
  checkDefaultOpenState: ListItemComponentProps['checkDefaultOpenState'];
}

export const Navigator: FC<Props> = ({ checkDefaultOpenState }) => {
  const folderId = useGetCurrentFolderId();
  const fullTree = useGetFoldersTreeById(undefined);
  const foldersMap = useGetFoldersMap();
  const current = useGetFoldersTreeById(folderId as string);
  const folders = buildFolderPath(current, foldersMap);
  const folderIds = folders.map((folder) => folder.id);

  useFetchFolders();

  const checkIsOpen = useCallback(
    (model: ListItem) => {
      if (isRootFolder(model)) return true;

      if (!isUndefined(checkDefaultOpenState) && checkDefaultOpenState(model)) return true;

      return folderIds.includes(model.id);
    },
    [folderIds, checkDefaultOpenState],
  );

  const checkIsActive = useCallback(
    (model: ListItem) => {
      return model.id === folderId;
    },
    [folderId],
  );

  if (!fullTree) return null;

  return (
    <NavigatorBody>
      <FsItem
        model={fullTree}
        viewComponent={NavigatorFolderView}
        loadFolderContent={noop}
        checkDefaultOpenState={checkIsOpen}
        checkIsActive={checkIsActive}
      />
    </NavigatorBody>
  );
};
