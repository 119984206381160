import { ReactElement, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from 'redux-form';

import { SignUpStepComponent, useSignUpProps } from 'src/v2/features/signUp/pages/hooks';
import { QrCodeAccountRecoveryStep, WizardStepV2 } from 'src/models/signUp';
import {
  getQrCodeAccountRecoveryStep,
  setQrCodeAccountRecoveryStep,
  setStep,
} from 'src/v2/features/signUp/store';

import { AccountRecoveryStepOne } from './AccountRecoveryStepOne';
import { AccountRecoveryStepTwo } from './AccountRecoveryStepTwo';
import { AccountRecoveryStepThree } from './AccountRecoveryStepThree';
import { AccountRecoveryStepFour } from './AccountRecoveryStepFour';

export const useForgotPasswordLightComponent = (): SignUpStepComponent => {
  const dispatch = useDispatch();
  const { step } = useSignUpProps();
  const qrCodeAccountRecoveryStep = useSelector(getQrCodeAccountRecoveryStep);

  const handleLoginFlow = useCallback(() => {
    const resetForgotPasswordLightForm = reset('qr-code-account-recovery-formlight');
    dispatch(
      setStep({
        step: WizardStepV2.StartInvite,
        stepNum: 1,
        maxSteps: 1,
      }),
    );
    dispatch(
      setQrCodeAccountRecoveryStep({
        qrCodeAccountRecoveryStep: QrCodeAccountRecoveryStep.Start,
      }),
    );
    dispatch(resetForgotPasswordLightForm);
  }, [dispatch]);

  const handleSignUpFlow = useCallback(() => {
    dispatch(
      setStep({
        step: WizardStepV2.SignUp,
        stepNum: 1,
        maxSteps: 1,
      }),
    );
  }, [dispatch]);

  switch (qrCodeAccountRecoveryStep) {
    case QrCodeAccountRecoveryStep.Start:
      return [
        (): ReactElement => (
          <AccountRecoveryStepOne onLoginFlow={handleLoginFlow} onSignUpFlow={handleSignUpFlow} />
        ),
        { step },
      ];
    case QrCodeAccountRecoveryStep.PhoneVerification:
      return [
        (): ReactElement => (
          <AccountRecoveryStepTwo onLoginFlow={handleLoginFlow} onSignUpFlow={handleSignUpFlow} />
        ),
        { step },
      ];
    case QrCodeAccountRecoveryStep.Complete:
      return [
        (): ReactElement => <AccountRecoveryStepThree onLoginFlow={handleLoginFlow} />,
        { step },
      ];
    case QrCodeAccountRecoveryStep.PasswordRecovery:
      return [
        (): ReactElement => <AccountRecoveryStepFour onLoginFlow={handleLoginFlow} />,
        { step },
      ];

    default:
      return [
        () => (
          <AccountRecoveryStepOne onLoginFlow={handleLoginFlow} onSignUpFlow={handleSignUpFlow} />
        ),
        { step },
      ];
  }
};
