// Core
import React, { ReactNode, useCallback } from 'react';
import { Box, Input, Label } from '@theme-ui/components';

const styles = {
  container: {
    position: 'relative',
  },

  input: {
    minHeight: '.1px',
    minWidth: '.1px',
    p: 0,
    size: '.1px',
    border: 'none',
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1,
  },

  label: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
};

interface Props {
  name: string;
  children: ReactNode;
  sx?: object;
  multiple?: boolean;
  accept?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FileInput: React.FC<Props> = ({
  name,
  children,
  sx,
  multiple = false,
  accept = 'image/*',
  onChange,
}) => {
  const labelStyle = {
    ...styles.label,
    ...sx,
  };

  const handleFileClick = useCallback((event: React.MouseEvent<HTMLInputElement>) => {
    event.currentTarget.value = '';
  }, []);

  return (
    <Box sx={styles.container}>
      <Input
        type="file"
        id={name}
        name={name}
        sx={styles.input}
        multiple={multiple}
        accept={accept}
        onChange={onChange}
        onClick={handleFileClick}
      />
      <Label htmlFor={name} sx={labelStyle}>
        {children}
      </Label>
    </Box>
  );
};
