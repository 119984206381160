import React, { useCallback, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@theme-ui/components';

import { FieldDiff } from 'src/models/editor';
import { StaticSections } from 'src/v2/features/entitySections/StaticSections';
import {
  getSectionsWithLocks,
  isOwnerParty as getIsOwnerParty,
  hasCounterparty as getHasCounterparty,
  getOwnerSections,
  getCounterpartySections,
  getOwnerFields,
  updateField,
  getCounterpartyFields,
  getIsFieldUpdating,
  getPreviewSectionsWithLocks,
  updateFields,
} from 'src/v2/features/documentDetails/documentDetailsSlice';
import { getIsLoginAvailable } from 'src/shared/auth';
import { has, isNull, isUndefined } from 'lodash';
import { UpdateFieldPayload, UpdateFieldsPayload } from '../../documentDetails/types';
import { isPropagateFieldsValues } from 'src/v2/components/EditorCore/utils';

const styles = {
  sections: {
    mt: '5px',
  },
};

interface Props {
  ownerFieldsDiff: FieldDiff[];
  counterpartyFieldsDiff: FieldDiff[];
  unmatchingSections?: number[];
  isNegotiable: boolean;
  fileFullyLoaded: () => void;
}

export const StaticBody: React.FC<Props> = ({
  ownerFieldsDiff,
  counterpartyFieldsDiff,
  unmatchingSections,
  isNegotiable,
  fileFullyLoaded,
}) => {
  const isLoginAvailable = useSelector(getIsLoginAvailable);
  const section = useSelector(getSectionsWithLocks);
  const previewSection = useSelector(getPreviewSectionsWithLocks);
  const sections =
    (isNull(isLoginAvailable) || !isLoginAvailable) && isNull(section) && !isNull(previewSection)
      ? previewSection
      : section || [];
  const isFieldUpdating = useSelector(getIsFieldUpdating);
  const isOwnerParty = useSelector(getIsOwnerParty);
  const hasCounterparty = useSelector(getHasCounterparty);
  const ownerSections = useSelector(getOwnerSections);
  const counterpartySections = useSelector(getCounterpartySections);
  const ownerFields = useSelector(getOwnerFields);
  const counterpartyFields = useSelector(getCounterpartyFields);
  const dispatch = useDispatch();

  const handleUpdateField = useCallback(
    (data: UpdateFieldPayload | UpdateFieldsPayload) => {
      return new Promise<{}>((resolve, reject) => {
        if (!isFieldUpdating) {
          if (isPropagateFieldsValues() && has(data, 'fields')) {
            dispatch(updateFields(data)).then(resolve).catch(reject);
          } else {
            dispatch(updateField(data)).then(resolve).catch(reject);
          }
        }
        resolve({});
      });
    },
    [dispatch, isFieldUpdating],
  );

  let mySections = sections;
  if (hasCounterparty) mySections = isOwnerParty ? ownerSections : counterpartySections;

  useLayoutEffect(() => {
    if (!isUndefined(fileFullyLoaded)) {
      window.addEventListener('load', fileFullyLoaded);
      return () => window.removeEventListener('load', fileFullyLoaded);
    }
  }, [fileFullyLoaded]);

  const diff = isOwnerParty ? ownerFieldsDiff : counterpartyFieldsDiff;
  const fields = isOwnerParty ? ownerFields : counterpartyFields;

  return (
    <Box sx={styles.sections}>
      <StaticSections
        key={diff?.length}
        sections={mySections}
        fields={fields}
        diff={diff}
        negotiable={isNegotiable}
        unmatchingSections={unmatchingSections}
        onFieldUpdate={handleUpdateField}
        isFieldUpdating={isFieldUpdating}
      />
    </Box>
  );
};
