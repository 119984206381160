import { FC } from 'react';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { ModalView } from 'src/v2/features/modal';
import { Button } from 'src/v2/features/button';

type Props = {
  onClose: () => void;
};

export const TermsModal: FC<Props> = ({ onClose, children }) => (
  <ModalView isActive>
    <div className="c-modal__close" onClick={onClose}></div>
    <div className="c-modal__row c-modal--mt-32">{children}</div>
    <div className="c-modal__row c-modal--mt-32 c-modal--gap-16">
      <Button className="c-button c-button--w-full" onClick={onClose}>
        <span className="c-button__inner h-uppercase">{i18n(translationKeys.buttons.ok)}</span>
      </Button>
    </div>
  </ModalView>
);
