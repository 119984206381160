import { FC } from 'react';
import { Flex } from '@theme-ui/components';

import { UserSignature } from 'src/models/profile';
import { getFullName } from 'src/utils/normalize';
import {
  Signature as SignatureWithoutHanko,
  SignatureWithHanko,
} from 'src/v2/components/Signature';
import { useShouldUseHanko } from 'src/v2/features/hankoSignature/hooks';
import { normalizeAvatar } from 'src/v2/features/profile';

interface Props {
  users: UserSignature[];
}

export const SignaturesList: FC<Props> = ({ users }) => {
  const shouldUseHanko = useShouldUseHanko();
  const Signature = shouldUseHanko ? SignatureWithHanko : SignatureWithoutHanko;

  return (
    <Flex sx={{ flexWrap: 'wrap', flexDirection: 'column' }}>
      {users.map((user) => (
        <Signature
          key={user.userId}
          avatarUrl={normalizeAvatar(user.avatarUrl)}
          signatureHash={user.signatureHash}
          signatureUrl={user.signatureUrl}
          stampUrl={user.stampUrl}
          userName={getFullName(user.firstName, user.lastName)}
          signatureName={user.signatureName}
          companyName={user.companyName}
          companyTitle={user.companyTitle}
          timestamp={user.timestamp}
          legalTooltip
        />
      ))}
    </Flex>
  );
};
