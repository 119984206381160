import { FC, ReactElement, useCallback, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { isUndefined, size } from 'lodash';

import { ListItemViewComponentProps } from 'src/v2/modules/FsItem/types';
import { EllipsisV } from 'src/v2/icons/EllipsisV';
import { FolderOpen } from 'src/v2/icons/FolderOpen';
import { FolderLinear } from 'src/v2/icons/FolderLinear';
import { useContextMenu } from 'src/v2/features/contextMenu/hooks';
import { ListItem } from 'src/models/list';
import { NodeTree } from 'src/models/node';
import { ContextMenuBook } from 'src/app/types';

import { EditFolderForm } from './EditFolderForm';
import {
  getIsParentForNewFactory,
  getIsRenameModeFactory,
} from '../../store/quantumNavigatorSelectors';
import {
  clearFoldersState,
  createFolder,
  renameFolder,
} from '../../store/quantumNavigatorReducers';
import { useCanShowContextMenu, useIsHaveContextMenu } from '../../hooks';
import { getActivePlan } from '../../../billing';

interface Props {
  customIcon?: ReactElement | null;
  onRootClick?: (e: MouseEvent) => void;
  isArrowHidden?: boolean;
  contextMenu?: ContextMenuBook;
}

const openClass = 'c-library__item--active';

export const FolderView: FC<ListItemViewComponentProps & Props> = ({
  children,
  onToggleExpand,
  model,
  isExpanded,
  customIcon,
  onRootClick,
  isArrowHidden,
  isActive,
  contextMenu,
}) => {
  const isParentForNew = useSelector(getIsParentForNewFactory(model.data.id));
  const isRenameMode = useSelector(getIsRenameModeFactory(model.data.id));
  const contextMenuType = contextMenu || ContextMenuBook.DocumentFolder;
  const handler = useContextMenu<NodeTree<ListItem>>(contextMenuType, model);
  const dotsHandler = useContextMenu<NodeTree<ListItem>>(contextMenuType, model, 'click');
  const plan = useSelector(getActivePlan);
  const canShowContextMenu = useCanShowContextMenu(plan, contextMenuType);
  const isHaveContextMenu = useIsHaveContextMenu(model.data.id) && canShowContextMenu;

  const dispatch = useDispatch();

  const onCreateFolder = useCallback(
    ({ name }) => {
      dispatch(createFolder({ name, parentId: model.data.id }));
    },
    [dispatch, model.data.id],
  );

  const onRenameFolder = useCallback(
    ({ name }) => {
      dispatch(renameFolder({ id: model.data.id, name }));
    },
    [dispatch, model.data.id],
  );
  const onCancel = useCallback(() => {
    dispatch(clearFoldersState());
  }, [dispatch]);

  const handleArrowClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      onToggleExpand(e);
    },
    [onToggleExpand],
  );

  return (
    <div
      className={cn('c-library__item', (isExpanded || isParentForNew) && openClass)}
      onClick={onRootClick}
    >
      {isRenameMode ? (
        <EditFolderForm
          onSubmit={onRenameFolder}
          onCancel={onCancel}
          initialValues={{ name: model.data.name }}
        />
      ) : (
        <span
          className={cn('c-library__link', isActive && 'c-library__link--active')}
          ref={isHaveContextMenu ? handler : null}
        >
          {size(model.children) > 0 && !isArrowHidden && (
            <div className="c-library__arrow" onClick={handleArrowClick} />
          )}
          <div className="c-library__icon c-library__icon--toggle">
            {isUndefined(customIcon) ? (
              <>
                <FolderLinear />
                <FolderOpen />
              </>
            ) : (
              customIcon
            )}
          </div>
          <div className="c-library__info">
            <div className="c-library__title">{model.data.name}</div>
          </div>
          {isHaveContextMenu && (
            <div className="c-library__dots" ref={dotsHandler}>
              <EllipsisV />
            </div>
          )}
        </span>
      )}
      <div className="c-library__dropdown">
        {isParentForNew && <EditFolderForm onCancel={onCancel} onSubmit={onCreateFolder} />}
        {children}
      </div>
    </div>
  );
};
