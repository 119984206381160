import React, { FC } from 'react';
import cn from 'classnames';

import { DocumentRealEstate } from 'src/v2/icons/DocumentRealEstate';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

export interface Props {
  title: string;
  lastModified: string;
  active?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void | undefined;
}

export const SidebarLibraryItem: FC<Props> = ({ title, lastModified, active, onClick }) => (
  <div className="c-library__item" onClick={onClick}>
    <span className={cn('c-library__link', { 'c-library__link--active': active })}>
      <div className="c-library__icon">
        <DocumentRealEstate />
      </div>
      <div className="c-library__info">
        <div className="c-library__title">{title}</div>
        <div className="c-library__text">
          {i18n(translationKeys.sidebar.lastModifiedText, {
            date: new Date(lastModified).toUTCString(),
          })}
        </div>
      </div>
    </span>
  </div>
);
