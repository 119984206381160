import { FC, MouseEvent } from 'react';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { formatDate } from 'src/instruments';
import { MetadataView, MetadataViewRow, getDocumentStateLabel } from 'src/v2/features/metadata';

import { DocumentMetaProps } from './types';

interface Props extends DocumentMetaProps {
  isAllowedToEdit: boolean;
  onEdit: (e: MouseEvent) => void;
}

export const DocumentMetadataView: FC<Props> = ({
  title,
  description,
  createdAt,
  updatedAt,
  subType,
  subSubType,
  state,
  owner,
  role,
  isAllowedToEdit,
  onEdit,
}) => (
  <MetadataView
    dataId="metadata-container-view"
    labelType={i18n(translationKeys.forms.document.info.type)}
    labelSubType={i18n(translationKeys.forms.document.info.subType)}
    title={title}
    description={description}
    subType={subType}
    subSubType={subSubType}
    creationDate={createdAt && formatDate(createdAt)}
    updatedData={updatedAt && formatDate(updatedAt)}
    isAllowedToEdit={isAllowedToEdit}
    onEdit={onEdit}
  >
    <MetadataViewRow dataType="state-field" label={i18n(translationKeys.sorting.state)}>
      {state && getDocumentStateLabel(state)}
    </MetadataViewRow>
    <MetadataViewRow dataType="owner-field" label={i18n(translationKeys.sorting.owner)}>
      {owner}
    </MetadataViewRow>
    <MetadataViewRow dataType="role-field" label={i18n(translationKeys.sorting.roleOfCreator)}>
      {role}
    </MetadataViewRow>
  </MetadataView>
);
