import { useEffect } from 'react';
import { useNavigateToDefault } from '.';
import {
  subscribeForInviteParticipant,
  subscribeForEditParticipant,
  subscribeForRemoveParticipant,
} from '../../entityEventBus';

export const useSubscribeToParticipantEventsAndDoCallbackEffct = (eventCallback: () => void) => {
  useEffect(() => {
    const subs = [
      subscribeForInviteParticipant(eventCallback),
      subscribeForEditParticipant(eventCallback),
      subscribeForRemoveParticipant(eventCallback),
    ];

    return () => {
      subs.forEach((s) => s.unsubscribe());
    };
  }, [eventCallback]);
};

export const useSubscribeToParticipantEvents = (documentId: string) => {
  const navigateToDefault = useNavigateToDefault(documentId);

  useSubscribeToParticipantEventsAndDoCallbackEffct(navigateToDefault);
};
