import React, { FC } from 'react';

import { ContractListPageWithoutContext } from 'src/v2/features/contract/ContractListPageWithoutContext';
import { DynamicSettingsContextProviderForList } from './providers/DynamicSettingsContextProviderForList';
import { SidebarNodeContextProvider } from './providers/SidebarNodeContextProvider';

export const ContractStandardListPage: FC = () => (
  <SidebarNodeContextProvider>
    <DynamicSettingsContextProviderForList>
      <ContractListPageWithoutContext />
    </DynamicSettingsContextProviderForList>
  </SidebarNodeContextProvider>
);
