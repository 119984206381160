import { createSelector } from 'reselect';
import { get, isNil } from 'lodash';

import { OrganizationState, RootState } from 'src/app/types';
import { getObject, getObjects } from 'src/v2/features/objectsStorage/objectsStorageSlice';
import { CountryCode } from 'src/instruments/countries';
import { getUserId } from 'src/shared/auth';
import { SystemRole } from 'src/models/user';
import { CompleteObject } from 'src/common/types';

import {
  getAddresses,
  getMembers,
  getOrgProfiles,
  getProfiles,
  getUsers,
  getLogos,
  getOrganizations as getOrganizationsSelector,
} from 'src/v2/features/objectsStorage';

import { UserState } from 'src/v2/features/organization/types';
import { OrganizationData } from 'src/v2/features/organization/store/types';

export const getOrganizationState = (state: RootState): OrganizationState => state.organization;

const getOrganizations = createSelector(
  (state: RootState) => state.organization.data,
  getOrganizationsSelector,
  getAddresses,
  getMembers,
  getUsers,
  getProfiles,
  getOrgProfiles,
  getLogos,
  (
    item,
    organization,
    address,
    member,
    user,
    profile,
    organizationProfile,
    logo,
  ): OrganizationData[] | undefined | null => {
    return (
      item &&
      getObjects<OrganizationData & CompleteObject>(item, {
        organization,
        address,
        member,
        user,
        profile,
        organizationProfile,
        logo,
      })
    );
  },
);

export const getCurrentOrganizationId = createSelector(
  getOrganizationState,
  (state) => state.currentOrgId,
);

export const getCurrentOrganizationOwnerId = createSelector(
  getOrganizationState,
  (state) => state.currentOrgOwnerId,
);

export const getCurrentOrganization = createSelector(
  getOrganizations,
  getCurrentOrganizationId,
  (organizations, currentOrgId): OrganizationData | undefined | null => {
    if (isNil(organizations)) return undefined;
    return organizations.find(({ id }) => id === currentOrgId);
  },
);

export const getCurrentOrganizationInfo = createSelector(
  getCurrentOrganization,
  (organizationInfo) => {
    if (!organizationInfo) return undefined;
    const profile = get(organizationInfo, 'organizationProfile[0]', {});
    const address = get(profile, 'address[0]', {});

    return {
      organizationName: organizationInfo.name || '',
      jobTitle: profile.jobTitle || '',
      industry: profile.industry || '',
      contactPerson: profile.contactPerson || '',
      email: profile.contactEmail || '',
      address: address.addressLine || '',
      country: address.country || CountryCode.US,
      city: address.city || '',
      state: address.state || '',
      zipCode: address.zipCode || '',
      logo: get(profile, 'logo[0].uriExtraLarge') || '',
    };
  },
);

export const getCurrentOrganizationLogo = createSelector(
  getCurrentOrganizationInfo,
  (organizationInfo) => get(organizationInfo, 'logo'),
);

export const getCurrentOrganizationFoundUser = createSelector(
  getCurrentOrganization,
  (state: RootState) => state.organization.foundUser,
  (state: RootState) => state.objectsStorage.objects.user,
  (organization, foundUser, user) => {
    if (!foundUser || !organization || !organization.members) return null;

    const foundUserData = getObject(foundUser, { user });
    if (!foundUserData) return null;

    const member = organization.members.find((el) => get(el, 'user[0].id') === foundUser.id);

    return {
      id: foundUserData.id,
      firstName: foundUserData.firstName,
      lastName: foundUserData.lastName,
      phone: foundUserData.phone as string,
      email: foundUserData.email as string,
      permission: member ? member.roleID : null,
      role: member ? member.functionalRole : null,
    };
  },
);

export const getCurrentOrganizationMembers = createSelector(
  getCurrentOrganization,
  (organizationInfo) => {
    if (!organizationInfo) return undefined;

    const users: UserState[] =
      organizationInfo.members &&
      organizationInfo.members.map((member) => {
        const userMember: UserState = {
          id: member.user[0].id,
          firstName: get(member, 'user[0].firstName'),
          lastName: get(member, 'user[0].lastName'),
          phone: member.user[0].phone,
          email: member.user[0].email,
          role: member.functionalRole,
          permission: member.roleID,
          verified: get(member, 'state') === 'accepted',
          avatar: get(member, 'user[0].avatar[0].uriSmall'),
        };
        return userMember;
      });
    return users;
  },
);

const getUserSystemRoleCombiner = (
  members: UserState[] | undefined,
  userId: string | null,
): SystemRole | null => {
  if (!members || !userId) return null;
  const member = members.find(({ id }) => id === userId);
  if (!member) return null;

  return member.permission;
};

export const getCurrentOrganizationUserSystemRole = createSelector(
  getCurrentOrganizationMembers,
  getUserId,
  getUserSystemRoleCombiner,
);

export const getIsLoading = createSelector(
  getOrganizationState,
  (state: OrganizationState): boolean => state.isLoading,
);
