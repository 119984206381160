import React, { FC } from 'react';

import { SortButton } from 'src/v2/features/sharedEntity';
import { ActionColumn, BaseActions } from 'src/v2/modules/layout';

export const SearchHeader: FC = () => {
  return (
    <BaseActions>
      <ActionColumn />
      <ActionColumn>
        <SortButton />
      </ActionColumn>
    </BaseActions>
  );
};
