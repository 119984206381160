import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import cn from 'classnames';

import { Sort } from 'src/v2/icons/Sort/';
import { useToggle } from 'src/common/hooks/useToggle';
import { SortDirection, SortedFields } from 'src/models/sort';
import { useUrlManagerQuery } from 'src/common/hooks/urlManager';
import {
  getSortDirection,
  getSortField,
  setSortDirection,
  setSortField,
} from 'src/common/sortStore';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

const getSortFieldLabels = (): Record<SortedFields, string> => {
  return {
    [SortedFields.Title]: i18n(translationKeys.sorting.title),
    [SortedFields.Description]: i18n(translationKeys.sorting.description),
    [SortedFields.CreatedAt]: i18n(translationKeys.sorting.dateCreated),
    [SortedFields.UpdatedAt]: i18n(translationKeys.sorting.dateUpdated),
    [SortedFields.State]: i18n(translationKeys.sorting.state),
    [SortedFields.SubTypes]: i18n(translationKeys.sorting.subType),
  };
};

export const SortButton: React.FC = () => {
  const navigate = useNavigate();
  const [params, createQuery] = useUrlManagerQuery();

  const sortField = useSelector(getSortField);
  const sortDirection = useSelector(getSortDirection);

  const [showDropDown, toggleShowDropDown, , hideDropDown] = useToggle();
  const isRotateIcon = sortDirection === SortDirection.Asc;

  const sortFieldsLabels = useMemo(() => getSortFieldLabels(), []);

  const onDropDownItemClick = useCallback(
    (event: React.MouseEvent): void => {
      const value: SortedFields =
        (event.currentTarget.getAttribute('data-value') as SortedFields) || sortField;
      hideDropDown();
      setSortDirection(sortDirection);
      setSortField(value);
      navigate(createQuery({ sortBy: value, sortDirection }));
    },
    [hideDropDown, sortDirection, sortField, navigate, createQuery],
  );

  const onSortIconClick = useCallback(() => {
    const sortDirection = isRotateIcon ? SortDirection.Desc : SortDirection.Asc;
    hideDropDown();
    setSortDirection(sortDirection);
    setSortField(sortField);
    navigate(
      createQuery({
        ...params,
        sortBy: sortField,
        sortDirection,
      }),
    );
  }, [isRotateIcon, hideDropDown, navigate, sortField, createQuery, params]);

  return (
    <div className="c-action__item">
      <div
        className={cn('c-action__icon', 'c-action__toggle', {
          'c-action__toggle--active': isRotateIcon,
        })}
        onClick={onSortIconClick}
      >
        <Sort />
      </div>
      <div
        className={cn('c-action__by', {
          'c-action__by--active': showDropDown,
        })}
      >
        <div className="c-action__title" onClick={toggleShowDropDown}>
          {sortFieldsLabels[sortField] || i18n(translationKeys.sorting.sortBy)}
        </div>
        <div className="c-action__dropdown">
          {Object.entries(sortFieldsLabels).map(([field, label]) => (
            <div
              className="c-action__sort"
              key={field}
              data-value={field}
              onClick={onDropDownItemClick}
            >
              {label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
