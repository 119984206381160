import { SagaIterator } from 'redux-saga';

import { apiClient } from 'src/common/client';
import { ObjectSerialized } from 'src/common/types';

export interface User {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  avatar: string;
}

export interface UsersResult {
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
  data: User[];
}

// @TODO remove in a separate task
export function getUsers(page = 1): Promise<UsersResult> {
  return Promise.resolve({
    page,
    per_page: 0,
    total: 0,
    total_pages: 0,
    data: [],
  });
}

export function getUserByField(
  fieldName: string,
  filedValue: string,
): SagaIterator<ObjectSerialized | null> {
  return apiClient.get(`/account?${fieldName}=${encodeURIComponent(filedValue)}`);
}
