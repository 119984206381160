import { SagaIterator } from 'redux-saga';
import { call, takeLatest, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { toastr } from 'react-redux-toastr';

import { handleErrorSaga } from 'src/utils/handleErrorSaga';
import {
  createFolderAPI,
  fetchFoldersAPI,
  removeFolderAPI,
  renameFolderAPI,
  moveFolderAPI,
} from 'src/api/foldersAPI';
import { storeData } from 'src/v2/features/objectsStorage';
import { ObjectSerialized } from 'src/common/types';
import { CreateFolderPayload, MoveFolderPayload, RenameFolderPayload } from 'src/models/folder';
import { deleteData } from 'src/v2/features/objectsStorage/objectsStorageSlice';

import {
  fetchFolders,
  createFolder,
  renameFolder,
  removeFolder,
  clearFoldersState,
  moveFolder,
} from './quantumNavigatorReducers';

function* fetchFoldersSaga() {
  try {
    const response: ObjectSerialized = yield call(fetchFoldersAPI);
    yield put(storeData(response));
  } catch (err) {
    yield call(handleErrorSaga, err);
  }
}

function* createFolderSaga(action: PayloadAction<CreateFolderPayload>) {
  try {
    const response: ObjectSerialized = yield call(createFolderAPI, action.payload);
    yield put(storeData(response));
    yield call(toastr.success, 'Success', 'Successfully created');
    yield put(clearFoldersState());
  } catch (err) {
    yield call(handleErrorSaga, err);
  }
}

function* renameFolderSaga(action: PayloadAction<RenameFolderPayload>) {
  try {
    const response = yield call(renameFolderAPI, action.payload.id, action.payload.name);
    yield put(storeData(response));
    yield call(toastr.success, 'Success', 'Successfully renamed');
    yield put(clearFoldersState());
  } catch (err) {
    yield call(handleErrorSaga, err);
  }
}

function* removeFolderSaga(action: PayloadAction<string>) {
  try {
    yield call(removeFolderAPI, action.payload);
    yield call(toastr.success, 'Success', 'Successfully removed');

    yield put(deleteData({ id: action.payload, type: 'folder' }));
  } catch (err) {
    yield call(handleErrorSaga, err);
  }
}

function* moveFolderSaga(action: PayloadAction<MoveFolderPayload>) {
  try {
    const response = yield call(
      moveFolderAPI,
      action.payload.targetId,
      action.payload.destinationId,
    );
    yield call(toastr.success, 'Success', 'Successfully moved');
    yield put(storeData(response));
  } catch (err) {
    yield call(handleErrorSaga, err);
  }
}

export function* watchQuantumNavigatorSagas(): SagaIterator {
  yield takeLatest(fetchFolders, fetchFoldersSaga);
  yield takeLatest(createFolder, createFolderSaga);
  yield takeLatest(renameFolder, renameFolderSaga);
  yield takeLatest(removeFolder, removeFolderSaga);
  yield takeLatest(moveFolder, moveFolderSaga);
}
