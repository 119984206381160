import { EventChannel, SagaIterator } from 'redux-saga';

import { apiClient } from 'src/common/client';
import { ObjectSerialized } from 'src/common/types';
import {
  InviteParticipantsPayload,
  RemoveParticipantPayload,
  TemplatePayload,
  TemplateCreatePayload,
  FetchTemplatesByPaperTypePayload,
} from 'src/models/template';
import { appendDataIfDefined } from 'src/common/utils';
import { FieldData } from 'src/api/documents';
import { UploadData } from 'src/models/editor';

export function fetchTemplateApi(templateId: string): SagaIterator<ObjectSerialized | null> {
  return apiClient.get(`/template/${templateId}`);
}

export function createTemplateApi(
  template: TemplatePayload,
): SagaIterator<ObjectSerialized | null> {
  const { industry, country, province, city, folderId, paperType, type, subType } = template;
  const attributes: TemplateCreatePayload = {
    title: template.title,
    description: template.description || '',
    paperType,
    type,
    subType,
    industry,
    country,
    province,
    city,
    folderId,
  };

  const data = {
    type: 'template',
    attributes,
  };

  return apiClient.post('/template', { data });
}

export function inviteParticipantsApi({
  documentId,
  emails,
}: InviteParticipantsPayload): SagaIterator<ObjectSerialized | null> {
  const data = {
    type: 'template',
    attributes: {
      userEmails: emails,
    },
  };

  return apiClient.post(`/template/${documentId}/invite`, { data });
}

export function updateTemplateApi(
  template: TemplatePayload,
): SagaIterator<ObjectSerialized | null> {
  const data = {
    type: 'document',
    attributes: template,
  };

  return apiClient.put(`/template/${template.id}`, { data });
}

export function updateTemplateFileApi(
  templateId: string,
  file: File,
): SagaIterator<UploadData | null> {
  const data = new FormData();
  data.append('file', file);

  return apiClient.put<UploadData | null>(`/template/${templateId}/file`, data);
}

export function fetchTemplatesByPaperTypeApi(
  type: FetchTemplatesByPaperTypePayload,
): SagaIterator<ObjectSerialized | null> {
  return apiClient.get(`/template/my?templateType=${type}`);
}

export function fetchSystemTemplatesByPaperTypeAPI(
  type: FetchTemplatesByPaperTypePayload,
): SagaIterator<ObjectSerialized | null> {
  return apiClient.get(`/template/system?templateType=${type}`);
}

export function deleteTemplateApi(id: string): SagaIterator<ObjectSerialized | null> {
  return apiClient.delete(`/template/${id}`);
}

export function removeParticipantApi({
  templateId,
  participantId,
}: RemoveParticipantPayload): SagaIterator<ObjectSerialized | null> {
  return apiClient.delete(`/template/${templateId}/participant/${participantId}`);
}

export function removeAllParticipantsApi(
  templateId: string,
): SagaIterator<ObjectSerialized | null> {
  return apiClient.delete(`/template/${templateId}/removeAllParticipants`);
}

export function createTemplateFromUploadApi(
  file: File,
  payload: TemplatePayload,
  folderId: string,
): SagaIterator<EventChannel<ObjectSerialized>> {
  const data = new FormData();

  appendDataIfDefined(data, 'title', payload.title);
  appendDataIfDefined(data, 'description', payload.description);
  appendDataIfDefined(data, 'state', payload.state);
  appendDataIfDefined(data, 'type', payload.paperType);
  appendDataIfDefined(data, 'subType', payload.type);
  appendDataIfDefined(data, 'subSubType', payload.subType);
  appendDataIfDefined(data, 'isTemplate', 'true');
  appendDataIfDefined(data, 'createdAt', payload.createdAt);
  appendDataIfDefined(data, 'industry', payload.industry);
  appendDataIfDefined(data, 'country', payload.country);
  appendDataIfDefined(data, 'province', payload.province);
  appendDataIfDefined(data, 'city', payload.city);
  appendDataIfDefined(data, 'folderId', folderId);
  data.append('file', file);

  return apiClient.uploadFile<ObjectSerialized>(`/template/fromFile`, data);
}

export function insertSectionApi(
  templateId: string,
  index: number,
  content: string,
  fields: FieldData[],
  isTemplate = false,
): SagaIterator<ObjectSerialized | null> {
  const data = { path: [index], text: content, fields, isTemplate };

  return apiClient.post(`/template/${templateId}/insertSection`, { data });
}

export function updateSectionApi(
  templateId: string,
  index: number,
  content: string,
  fields: FieldData[],
  isTemplate = false,
): SagaIterator<ObjectSerialized | null> {
  const data = { isTemplate, path: [index], text: content, fields };

  return apiClient.post(`/template/${templateId}/modifySection`, { data });
}

export function moveSectionApi(
  templateId: string,
  source: number,
  target: number,
  isTemplate = false,
): SagaIterator<ObjectSerialized | null> {
  const data = { isTemplate, oldPath: [source], newPath: [target] };

  return apiClient.post(`/template/${templateId}/moveSection`, { data });
}

export function removeSectionApi(
  templateId: string,
  index: number,
  isTemplate = false,
): SagaIterator<ObjectSerialized | null> {
  const data = { isTemplate, path: [index] };

  return apiClient.post(`/template/${templateId}/removeSection`, { data });
}

export function downloadAsFileAPI(templateId: string, title: string) {
  return apiClient.download(`/template/download/${templateId}`, title);
}

export function copyTemplateAPI(templateId: string, folderId: string) {
  const data = {
    type: 'template',
    attributes: {
      folderId,
    },
  };

  return apiClient.post(`/template/${templateId}/copy`, { data });
}

export function uploadFileAPI(templateId: string, file: File): SagaIterator<UploadData | null> {
  const data = new FormData();
  data.append('file', file);

  return apiClient.post<UploadData | null>(`/template/${templateId}/upload`, data);
}
