import i18n from 'src/i18n';

import { translationKeys } from 'src/common/translations';

type Props = {
  onLoginFlow: () => void;
};

export const AccountRecoveryStepThree: React.FC<Props> = (props) => (
  <>
    <div className="c-modal__row c-modal--mt-24">
      <div className="c-title c-title__auth c-title--color-theme c-title--fs-64">
        {i18n(translationKeys.forms.forgotPasswordQR.thirdStep.title)}
      </div>
    </div>
    <div className="c-modal__row">
      <div className="c-title c-title__auth c-title--color-theme c-title--fw-400">
        {i18n(translationKeys.forms.forgotPasswordQR.thirdStep.description)}
      </div>
    </div>
    <div className="c-modal__row c-modal--mt-24">
      <button className="c-button c-button--fs-12 c-button--w-full" onClick={props.onLoginFlow}>
        <span className="c-button__inner h-uppercase">{i18n(translationKeys.buttons.gotIt)}</span>
      </button>
    </div>
  </>
);
