// Variants
import { buttons, links, forms, text } from './variants';

export const theme = {
  // Scales
  space: [0],

  fonts: {
    body: '"Helvetica Neue", Helvetica, Arial, sans-serif',
    arial: 'Arial, sans-serif',
  },

  fontSizes: {
    tiny: 10,
    small: 12,
    normal: 14,
    medium: 16,
    large: 20,
    huge: 30,
  },

  colors: {
    primary: '#4d6982',
    accent: '#e4b62c',
    white: '#ffffff',
    black: '#000000',
    gray: '#f6f6ff',
    mint: '#7bd1b6',
    green: '#29cb97',
    danger: '#d57872',
  },

  radii: ['4px', '14px'],

  // Variants
  links,
  buttons,
  forms,
  text,
  images: {
    avatar: {
      width: 48,
      height: 48,
      borderRadius: '50%',
      border: '1px solid #a3a3a3',
    },
  },
};
