import React, { FC } from 'react';

import { DynamicSettingsContext } from 'src/v2/modules/layout';
import { DynamicSettings } from 'src/v2/modules/layout/types';

import { useNotificationTogglePush, useToggle9Square, useToggleQRCode } from '../DocumentSidebar';
import { BackTitleAndActions } from '../components/BackTitleAndActions';
import { useHasQRCodeFeature } from '../../billing';

export const DynamicSettingsContextProvider: FC = ({ children }) => {
  const toggleNotifications = useNotificationTogglePush();
  const toggle9squareSidebar = useToggle9Square();
  const toggleQRCodeSidebar = useToggleQRCode();
  const hasQRCodeFeature = useHasQRCodeFeature();

  const ctx: DynamicSettings = {
    headerChildren: <BackTitleAndActions />,
    onBellClick: toggleNotifications,
    onGridClick: toggle9squareSidebar,
    onQRCodeClick: toggleQRCodeSidebar,
    hasQRCodeFeature,
  };

  return <DynamicSettingsContext.Provider value={ctx}>{children}</DynamicSettingsContext.Provider>;
};
