import { CardUserIconData } from 'src/models/card';
import { Intent } from 'src/v2/components/Card';
import { MimeType } from 'src/common/types';
import { EventData } from 'src/app/eventBus';
import { EntityType, PaperState } from 'src/models/paper';
import { IconOption } from 'src/v2/components/Card';

export enum DocumentEvent {
  Create,
  Delete,
  InviteParticipant,
  EditParticipant,
  RemoveParticipant,
}

export interface CreatePayload {
  id: string;
  type: EntityType;
  folderId?: string;
}

export type DocumentEventData = EventData<DocumentEvent>;

export interface DocumentEventDataWithPayload extends EventData<DocumentEvent> {
  payload: CreatePayload;
}

export interface ParticipantEvent extends EventData<DocumentEvent> {
  payload: { documentId: string };
}

export interface CardData<R> {
  id: string;
  title: string;
  description: string;
  date?: string;
  signed?: boolean;
  status?: PaperState;
  state?: string;
  role: R;
  author?: {
    name: string;
    src: string | null;
  };
  createdAt?: string;
  updatedAt?: string;
  users?: CardUserIconData[];
}

export interface EntityCardProps<R> {
  data: CardData<R>;
  onContextMenu?: (node: HTMLElement | null) => void;
  onClick?: (event: React.MouseEvent) => void;
  onDoubleClick?: (event: React.MouseEvent) => void;
  onDelete?: (event: React.MouseEvent) => void;
  intent: Intent;
  icon: IconOption;
  selected?: boolean;
  showSignedStatus?: boolean;
  canDeleteEntity?: boolean;
}

export interface Attachment {
  id: string;
  name: string;
  url: string;
  shortUrl?: string;
  extension: MimeType;
  lastModified: Date;
}

export enum UploadedEntityExtension {
  pdf = 'pdf',
  doc = 'doc',
  docx = 'docx',
  png = 'png',
  jpg = 'jpg',
  jpeg = 'jpeg',
  gif = 'gif',
  xls = 'xls',
  xlsx = 'xlsx',
  ppt = 'ppt',
  pptx = 'pptx',
}

export interface EntityLayoutProps {
  error?: string;
  isLoading: boolean;
}

export type FetchUserTemplatesActionPayload = EntityType;

export enum EntityViewMode {
  Editable,
  Static,
  File,
  PDF,
}

export interface File {
  id: string;
  name: string;
  extension: MimeType;
}

export interface FileUrl {
  fileUrl: string;
}
