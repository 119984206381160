import { isNull } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'src/app/types';
import {
  getCardPaperParticipants,
  getCardPapers,
  getCardPaperUsers,
  getProjectObjects,
  getProjectParticipants,
  getProjects,
  getProjectUsers,
} from 'src/v2/features/objectsStorage/selectors';
import { getObject, getObjects } from 'src/v2/features/objectsStorage/objectsStorageSlice';
import { DbRecordType } from 'src/v2/features/objectsStorage/types';
import {
  convertProjectCompleteModelToProjectEntity,
  ProjectCompleteModel,
} from 'src/v2/boundary/storageModels/project/project';
import { createGetRootFolderId } from 'src/v2/features/sharedEntity/createGetRootFolderId';
import { isProjectsFolder } from 'src/v2/features/quantumNavigator/utils';
import {
  convertProjectCardCompleteModelToCardProjectEntity,
  ProjectCardCompleteModel,
} from 'src/v2/boundary/storageModels/project/projectCard';
import { getUserId } from 'src/shared/auth';
import { CardProjectEntity } from 'src/v2/entities/project';

const getStore = (state: RootState) => state.project;
export const getIsLoading = createSelector(getStore, (state) => state.isLoading);
export const getDeletedEntityId = (state: RootState): string | null => getStore(state).deletedId;
export const getError = createSelector(getStore, (state) => state.error);
export const getRootProjectsFolderId = createGetRootFolderId(isProjectsFolder);
export const getList = createSelector(getStore, (state) => state.data);
export const getProjectByIdFn = createSelector(
  getProjects,
  getProjectParticipants,
  getProjectUsers,
  getProjectObjects,
  getCardPapers,
  getCardPaperParticipants,
  getCardPaperUsers,
  getUserId,
  (
      projects,
      participants,
      users,
      objects,
      cardPapers,
      cardPaperParticipants,
      cardPaperUsers,
      userId,
    ) =>
    (id: string) => {
      const completeProject: ProjectCompleteModel | null = getObject(
        {
          id,
          type: DbRecordType.Project,
        },
        {
          [DbRecordType.Project]: projects,
          [DbRecordType.ProjectParticipant]: participants,
          [DbRecordType.ProjectUser]: users,
          [DbRecordType.ProjectObject]: objects,
          [DbRecordType.CardPaper]: cardPapers,
          [DbRecordType.CardPaperParticipant]: cardPaperParticipants,
          [DbRecordType.CardPaperUser]: cardPaperUsers,
        },
      );

      if (isNull(completeProject)) return null;

      if (isNull(userId)) return null;

      try {
        return convertProjectCompleteModelToProjectEntity(completeProject, cardPaperUsers, userId);
      } catch (e) {
        console.warn(e);
        return null;
      }
    },
);

export const getProjectsList = createSelector(
  getList,
  getProjects,
  getProjectParticipants,
  getProjectUsers,
  (projectsList, projects, participants, users) => {
    const projectsListComplete = getObjects<ProjectCardCompleteModel>(projectsList, {
      [DbRecordType.Project]: projects,
      [DbRecordType.ProjectParticipant]: participants,
      [DbRecordType.ProjectUser]: users,
    });

    try {
      const result = projectsListComplete
        .map((data) => {
          try {
            return convertProjectCardCompleteModelToCardProjectEntity(data);
          } catch (e) {
            return null;
          }
        })
        .filter((data): data is CardProjectEntity => !isNull(data));

      return result;
    } catch (e) {
      console.warn(e);
      return [];
    }
  },
);
