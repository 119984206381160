import { ReactElement } from 'react';
import { Routes, Navigate, Route } from 'react-router-dom';
import { isNull } from 'lodash';

import { book } from 'src/app/book';

import { SignUpStepComponent, useSignUpProps } from './hooks';

export const useCompleteComponent = (): SignUpStepComponent => {
  const { entityType, entityId } = useSignUpProps();

  return [
    (): ReactElement =>
      !isNull(entityType) && !isNull(entityId) ? (
        <Routes>
          <Route
            path="*"
            element={
              <Navigate
                replace
                to={book.signup.generateRedirectPath(entityType, entityId, false)}
              />
            }
          />
        </Routes>
      ) : (
        <></>
      ),
    {},
  ];
};
