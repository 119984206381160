import { entitySidebarControllerFactory } from 'src/v2/features/sharedEntity';
import { book } from 'src/v2/features/sharedEntity/EntitySidebar/book';
import { book as contractBook } from './book';

import { entityParticipantRoutes } from 'src/v2/features/sharedEntity/EntitySidebar/routes';
import { View } from './pages/View';
import { Edit } from './pages/Edit';
import { Create } from './pages/Create';
import { InviteContractParticipant } from './pages/InviteContractParticipant';
import { EditContractParticipant } from './pages/EditContractParticipant';
import { QRCode } from '../../qrCode/QrCode';

export const sidebarName = 'contractSidebar';

export const sidebarController = entitySidebarControllerFactory(sidebarName, [
  {
    path: book.view,
    render: View,
  },
  {
    path: book.create,
    render: Create,
  },
  {
    path: book.edit,
    render: Edit,
  },

  {
    path: contractBook.contractQRCode,
    render: QRCode,
  },
  {
    path: contractBook.inviteContractParticipant,
    render: InviteContractParticipant,
  },
  {
    path: contractBook.editContractParticipant,
    render: EditContractParticipant,
  },
  ...entityParticipantRoutes,
]);
