import React, { FC } from 'react';

import { DropdownSaveTemplate } from 'src/v2/icons/DropdownSaveTemplate';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

import { SectionItem } from '../SectionItem';
import { SectionItemProps } from '../types';

export const SaveTemplate: FC<SectionItemProps> = (props) => (
  <SectionItem
    icon={DropdownSaveTemplate}
    label={i18n(translationKeys.nineSquareMenu.saveTemplate)}
    {...props}
  />
);
