import { isEmpty, isNil } from 'lodash';
import { createErrorFactory } from 'src/common/error/createErrorFactory';
import { ObjectBase } from 'src/common/types';
import { getObject } from 'src/v2/features/objectsStorage/objectsStorageSlice';
import { ObjectsContainer } from 'src/v2/features/objectsStorage/types';
import { LogoModel } from './logo';

const createError = createErrorFactory('getLogoFromInvite');

export interface InviteEntity {
  id: string;
  organization: { id: string; logo: LogoModel[]; name: string }[];
  email: string;
  firstName: string;
  inviteCode: string;
  inviteType: string;
  lastName: string;
  organizationId: string;
  phone: string;
  userID: string;
}

export const convertInviteCompleteModelToInviteEntity = (
  item: ObjectBase | undefined,
  invite: ObjectsContainer,
  organization: ObjectsContainer,
  logo: ObjectsContainer,
): InviteEntity | null => {
  if (isNil(invite)) {
    return null;
  }

  if (isEmpty(organization)) {
    return null;
  }

  if (isEmpty(logo)) {
    throw createError('Logo object is empty');
  }

  return item ? getObject(item, { invite, organization, logo }) : null;
};
