import React, { FC, PropsWithChildren, useContext } from 'react';
import classnames from 'classnames';

import { StaticSettingsContext } from 'src/common/contexts/StaticSettingsContext';
import { StaticSettings } from 'src/common/types';
import { decodeContentToDomainUrl } from 'src/utils/url';

import { Logo } from './components/Logo';
import { HeaderItem } from './components/HeaderItem';
import { PoweredBy } from './components/PoweredBy';

interface Props {
  className?: string;
}

export const BaseHeader: FC<Props> = ({
  children,
  className,
}: PropsWithChildren<Props>): JSX.Element => {
  const { logoUrl, organizationLogo } = useContext<StaticSettings>(StaticSettingsContext);

  return (
    <header className={classnames('l-header', className)}>
      <HeaderItem>
        <a className="c-logo" href={logoUrl}>
          {organizationLogo ? (
            <>
              <img src={decodeContentToDomainUrl(organizationLogo)} alt="logo" />
              <PoweredBy />
            </>
          ) : (
            <Logo />
          )}
        </a>
      </HeaderItem>

      {children}
    </header>
  );
};
