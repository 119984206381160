import { get } from 'lodash';

import { SignatureId, SignatureCompleteModel, SignatureDataNormalized } from 'src/models/signature';

export const normalizeKey = (key: string | null): string | undefined => key || undefined;

export function getSignatureById(
  list: SignatureCompleteModel[],
  signatureId: SignatureId,
): SignatureCompleteModel | undefined {
  return list.find((signature) => signature.signature_id === signatureId);
}

export function normalizeSignatureData(signature: SignatureCompleteModel): SignatureDataNormalized {
  return {
    legalName: signature.legal_name,
    style: signature.style,
    companyName: get(signature, 'signature_description[0].company'),
    userTitle: get(signature, 'signature_description[0].title'),
    userName: get(signature, 'signature_description[0].name'),
    includeCompany: signature.include_company,
    includeStamp: signature.include_stamp,
    imageUrl: signature.signature_file_link,
    defaultStampId: signature.default_stamp_id,
    hash: signature.hash,
  };
}
