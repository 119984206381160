import React, { PropsWithChildren } from 'react';
import { Switch } from 'src/v2/components/SwitchCase';

interface Props {
  view: string;
}

export const SidebarRouter = (props: PropsWithChildren<Props>): JSX.Element => {
  const { view, children } = props;
  return <Switch criteria={view}>{children}</Switch>;
};
