export const basicDataStyles = {
  formRow: {
    ':not(:last-of-type)': {
      mb: '14px',
    },
    flex: '1',

    '@media (max-width: 600px)': {
      width: 'auto',
    },
  },

  formInput: {
    mr: '4px',
    ml: '4px',
  },
  field: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
};

export const mixinStyles = {
  flexFormInput: {
    ...basicDataStyles.formInput,
    flex: 1,
  },
  firstName: {
    ...basicDataStyles.formInput,
    flex: 3,
  },
  lastName: {
    ...basicDataStyles.formInput,
    flex: 4,
  },
};

export const membersStyles = {
  container: {
    width: '100%',
    justifyContent: 'space-between',
    m: '0 auto',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
      width: 'auto',
    },
  },
  usersContainer: {
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    py: '10px',
    my: '10px',
    alignContent: 'start',
    flexWrap: 'wrap',
    overflow: 'auto',
    height: '400px',
  },
  newUserForm: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    mt: '10px',
  },
  permissionsHeading: {
    fontFamily: 'body',
    fontSize: 'huge',
    color: '#4d6982',
    '@media (max-width: 600px)': {
      fontSize: 'large',
    },
  },

  permissionOption: {
    my: '6px',
    fontFamily: 'body',
    fontWeight: 400,
    fontSize: 'large',
    color: '#4d6982',
  },
};

export const photoDescStyles = {
  form: {
    justifyContent: 'space-between',
    m: '0 auto',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
      width: 'auto',
    },
  },
  text: {
    fontFamily: 'body',
    fontSize: '20px',
    fontWeight: ' bold',
    lineHeight: '1.25',
    color: '#324353',
    m: '20px 0',
  },
  avatar: {
    '& > div': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
    my: '20px',
  },
  verifiedLabel: {
    alignItems: 'flex-end',
    fontFamily: 'body',
    fontSize: 'large',
    color: '#4d6982',
    '& img': {
      pr: '5px',
    },
  },
  fileInput: {
    cursor: 'pointer',
    fontFamily: 'body',
    fontWeight: 400,
    fontSize: 'large',
    color: '#29cb97',
  },
  description: {
    py: '20px',
    fontFamily: 'body',
    fontSize: 'large',
    lineHeight: 1.2,
    color: '#707070',
    '& h2': {
      fontFamily: 'inherit',
      fontSize: 'inherit',
      lineHeight: 'inherit',
      color: '#000000',
    },
  },
  noAvatarParent: {
    borderRadius: '100%',
    backgroundColor: '#d6d6d6',
    height: '98px',
    minWidth: '98px',
    maxWidth: '98px',
    position: 'relative',
    textAlign: 'center',
  } as React.CSSProperties,
  noAvatarText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  } as React.CSSProperties,
};
export const notificationsStyles = {
  form: {
    width: '550px',
    justifyContent: 'space-between',
    m: '0 auto',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
      width: 'auto',
    },
  },
  text: {
    fontFamily: 'body',
    fontSize: '20px',
    fontWeight: ' bold',
    lineHeight: '1.25',
    color: '#324353',
    m: '20px 0',
  },
  bannerContainer: {
    fontSize: '20px',
    display: 'flex',
    justifyContent: 'center',
    width: '257px',
    height: '63px',
    borderRadius: ' 7px',
    border: 'solid 1px #29cb97',
    backgroundColor: '#ffffff',
  },
  bannerText: {
    fontSize: '18px',
  },
  bannerIcon: { marginLeft: '-20px' },
  group: {
    row: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    heading: {
      color: '#4d6982',
      opacity: '0.3',
      fontFamily: 'body',
      fontSize: '25px',
      fontWeight: 'bold',
      lineHeight: '1.2',
      borderBottom: 'solid 1px #707070',
    },
  },
};

export const styles = {
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  form: {
    width: '450px',
    justifyContent: 'space-between',
    m: '0 auto',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
      width: 'auto',
    },
  },
  innerContainer: {
    flexDirection: 'column',
  },
  formRow: {
    mb: '10px',
  },
  heading: {
    fontFamily: 'body',
    py: '8px',
    color: '#4d6982',
    fontSize: '25px',
    textAlign: 'left',
    lineHeight: 1.2,
    '@media (max-width: 600px)': {
      m: '10px 0',
    },
    '@media (min-width: 1400px)': {
      maxWidth: '900px',
    },
  },
  text: {
    fontFamily: 'Arial',
    fontSize: '20px',
    letterSpacing: 'normal',
    color: '#2E4354',
    textAlign: 'left',
    m: '20px 0',
    '@media (max-width: 600px)': {
      fontSize: '20px',
      m: '10px 0',
    },
    '@media (min-width: 1400px)': {
      maxWidth: '650px',
    },
  },

  province: {
    flexBasis: '105px',
    flexShrink: 0,
    '& > div': {
      flexGrow: 1,
    },
  },
  country: {
    width: '60%',
    mb: '10px',
    '@media (max-width: 600px)': {
      width: '50%',
      minWidth: '272px',
    },
    '@media (max-width: 320px)': {
      width: '100%',
      minWidth: 'unset',
    },
  },
  checkboxFormRow: {
    mb: '10px',
    '> label': {
      color: '#2e4354',
      fontSize: '14px',
      lineHeight: 1.14,
    },
  },
  required: {
    position: 'relative',
    cursor: 'pointer',
    '::before': {
      content: '"*"',
      color: '#29cb97',
      width: '16px',
      height: '17px',
      lineHeight: '1.21',
      top: '-5px',
      left: '-7px',
      position: 'absolute',
    },
    label: {
      fontStyle: '14px',
      fontWeight: 'normal',
    },
  },
};
