import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { handleErrorSaga } from 'src/utils/handleErrorSaga';
import {
  fetchSystemTemplatesByPaperTypeAPI,
  fetchTemplatesByPaperTypeApi,
} from 'src/api/templates';
import { storeData } from 'src/v2/features/objectsStorage/objectsStorageSlice';
import { RootState, TemplatesListState } from 'src/app/types';
import { FetchUserTemplatesActionPayload } from 'src/v2/features/sharedEntity/types';
import { DbRecordType } from 'src/v2/features/objectsStorage/types';
import { ObjectBase } from 'src/common/types';
import {
  getSharedTemplates,
  getTemplates,
  getSystemTemplates,
} from 'src/v2/features/objectsStorage/selectors';
import { getTemplatesListFactory } from 'src/v2/features/template/selectors';

export const fetchUserTemplates = createAction<FetchUserTemplatesActionPayload>(
  'templatesList/fetchUserTemplates',
);

export const fetchUserSystemTemplates = createAction<FetchUserTemplatesActionPayload>(
  'templatesList/fetchUserSystemTemplates',
);

const initialState: TemplatesListState = {
  userTemplatesList: [],
  sharedTemplatesList: [],
  systemTemplatesList: [],
};

const templatesList = createSlice({
  name: 'templatesList',
  initialState,
  reducers: {
    setUserTemplates(state: TemplatesListState, action: PayloadAction<ObjectBase[]>): void {
      state.userTemplatesList = action.payload;
    },

    setSharedTemplates(state: TemplatesListState, action: PayloadAction<ObjectBase[]>): void {
      state.sharedTemplatesList = action.payload;
    },

    setSystemTemplates(state: TemplatesListState, action: PayloadAction<ObjectBase[]>): void {
      state.systemTemplatesList = action.payload;
    },
  },
});

const { setUserTemplates, setSharedTemplates, setSystemTemplates } = templatesList.actions;

const getState = (state: RootState) => state.templatesList;
const getUserTemplatesRecordList = createSelector(getState, (state) => state.userTemplatesList);
const getSharedTemplatesRecordList = createSelector(getState, (state) => state.sharedTemplatesList);
const getSystemTemplatesRecordList = createSelector(getState, (state) => state.systemTemplatesList);

export const getUserTemplatesList = createSelector(
  getTemplates,
  getUserTemplatesRecordList,
  getTemplatesListFactory,
  (templates, userTemplatesRecordList, getTemplatesList) => {
    return getTemplatesList(userTemplatesRecordList, {
      [DbRecordType.Template]: templates,
    });
  },
);

export const getSharedTemplatesList = createSelector(
  getSharedTemplates,
  getSharedTemplatesRecordList,
  getTemplatesListFactory,
  (templates, userTemplatesRecordList, getTemplatesList) => {
    return getTemplatesList(userTemplatesRecordList, {
      [DbRecordType.Template]: templates,
    });
  },
);

export const getSystemTemplatesList = createSelector(
  getSystemTemplates,
  getSystemTemplatesRecordList,
  getTemplatesListFactory,
  (templates, systemTemplatesRecordList, getTemplatesList) => {
    return getTemplatesList(systemTemplatesRecordList, {
      [DbRecordType.SystemTemplate]: templates,
    });
  },
);

export const templatesListReducer = templatesList.reducer;

function* fetchUserTemplatesSaga(action: PayloadAction<FetchUserTemplatesActionPayload>) {
  try {
    const result = yield call(fetchTemplatesByPaperTypeApi, action.payload);
    yield put(storeData(result));
    const list: ObjectBase[] = result.data;
    const userTemplates = list.filter(({ type }) => type === DbRecordType.Template);
    const sharedTemplates = list.filter(({ type }) => type === DbRecordType.SharedTemplate);
    yield put(setUserTemplates(userTemplates));
    yield put(setSharedTemplates(sharedTemplates));
  } catch (error) {
    yield call(handleErrorSaga, error);
  }
}

function* fetchSystemTemplatesSaga(action: PayloadAction<FetchUserTemplatesActionPayload>) {
  try {
    const result = yield call(fetchSystemTemplatesByPaperTypeAPI, action.payload);
    yield put(storeData(result));
    const list: ObjectBase[] = result.data;
    const systemTemplates = list.filter(({ type }) => type === DbRecordType.SystemTemplate);
    yield put(setSystemTemplates(systemTemplates));
  } catch (error) {
    yield call(handleErrorSaga, error);
  }
}

export function* watchTemplatesListSaga() {
  yield takeLatest(fetchUserTemplates, fetchUserTemplatesSaga);
  yield takeLatest(fetchUserSystemTemplates, fetchSystemTemplatesSaga);
}
