import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { removeFolder } from 'src/v2/features/quantumNavigator';
import { ConfirmAction, useNavigateToDefault } from 'src/v2/features/sharedEntity';

export interface Props {
  id: string;
  folderId: string;
  folderName: string;
}

export const RemoveFolder: FC<Props> = ({ id, folderId, folderName }) => {
  const dispatch = useDispatch();

  const navigateToDefault = useNavigateToDefault(id);

  const onRemoveFolder = useCallback(() => {
    dispatch(removeFolder(folderId));
    navigateToDefault();
  }, [dispatch, folderId, navigateToDefault]);

  return (
    <ConfirmAction
      title={`You are about to remove the folder "${folderName}". Do you wish to continue?`}
      onOk={onRemoveFolder}
      onCancel={navigateToDefault}
    />
  );
};
