// Core
import React, { ReactElement } from 'react';
import { WrappedFieldProps } from 'redux-form';

export type WithReduxFormProps = { children?: any } & WrappedFieldProps;

export function withReduxForm<T>(
  Component: React.ComponentType<T>,
): React.ComponentType<WrappedFieldProps & T> {
  const WrappedComponent = (props: WithReduxFormProps & T): ReactElement => {
    const { meta, input, ...otherProps } = props;
    const validationProps: any = {};
    if (meta.touched && meta.error) {
      validationProps.errorMessage = meta.error;
    }

    return (
      <Component {...input} {...validationProps} {...otherProps}>
        {props.children}
      </Component>
    );
  };

  WrappedComponent.displayName = `${Component.displayName || Component.name}WithReduxForm`;

  return WrappedComponent;
}
