import { env } from 'src/env';
export interface HeaderLinkConfig {
  label: string;
  to: string;
}

export interface SignHeaderLinkConfig {
  [key: string]: {
    label: string;
    href: string;
    className?: string;
  };
}

export enum SignHeaderLinkConfigKeys {
  signIn = 'sign-in',
  signUp = 'sign-up',
}

export const headerLinks: HeaderLinkConfig[] = [
  { label: 'Product', to: `${env.WEBSITE_HOST_URI}/product.html` },
  { label: 'Industries', to: `${env.WEBSITE_HOST_URI}/industries.html` },
  { label: 'Pricing', to: `${env.WEBSITE_HOST_URI}/pricing.html` },
  { label: 'Resources', to: `${env.WEBSITE_HOST_URI}/resources.html` },
  { label: 'Company', to: `${env.WEBSITE_HOST_URI}/company.html` },
];

export const signHeaderLinks: SignHeaderLinkConfig = {
  [SignHeaderLinkConfigKeys.signIn]: {
    label: 'Sign In',
    href: `${env.WEBSITE_HOST_URI}/pricing.html`,
    className: 'sign-in',
  },
  [SignHeaderLinkConfigKeys.signUp]: {
    label: 'Sign Up',
    href: `${env.WEBSITE_HOST_URI}/pricing.html`,
    className: 'sign-up',
  },
};
