import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { ConfirmSection } from 'src/v2/components/ConfirmSection';
import { useNavigateToDefault } from 'src/v2/features/sharedEntity';

import { removeSection } from '../../reducer';

interface Props {
  id: string;
  sectionIndex: string;
}

export const RemoveTemplateSection: FC<Props> = ({ id, sectionIndex }) => {
  const dispatch = useDispatch();
  const onCancel = useNavigateToDefault(id);

  const handleRemove = useCallback(() => {
    dispatch(
      removeSection({
        templateId: id,
        sectionIndex: parseInt(sectionIndex, 10),
      }),
    );
  }, [dispatch, id, sectionIndex]);

  return (
    <ConfirmSection
      message="Remove template section?"
      onClose={onCancel}
      onConfirm={handleRemove}
    />
  );
};
