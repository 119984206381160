import React from 'react';
import { StateRed } from './StateRed';
import { StateYellow } from './StateYellow';
import { StateGreen } from './StateGreen';

interface Props {
  state: StateOption;
}

export enum StateOption {
  Red,
  Yellow,
  Green,
}

const componentMap = {
  [StateOption.Red]: StateRed,
  [StateOption.Yellow]: StateYellow,
  [StateOption.Green]: StateGreen,
};

export const State: React.FC<Props> = ({ state }) => {
  const InnerState = componentMap[state];
  return (
    <div className="c-card__info--row">
      <div className="c-card__progress">
        <InnerState />
      </div>
    </div>
  );
};
