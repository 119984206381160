import { useLocation } from 'react-router';
import { parse, ParsedQuery, stringify } from 'query-string';
import { useCallback } from 'react';

type StringOrNumber = string | number;

interface CreateQuery {
  (query: ParsedQuery<StringOrNumber>, preserveHash?: boolean): string;
}

interface CreateUpdatedQuery {
  (query: ParsedQuery<StringOrNumber>, preserveHash?: boolean): string;
}

interface CreateHash {
  (query: ParsedQuery<StringOrNumber>): string;
}

interface CreateUpdatedHash {
  (query: ParsedQuery<StringOrNumber>): string;
}

type QueryApi = [ParsedQuery<string>, CreateQuery, CreateUpdatedQuery];
type HashApi = [ParsedQuery<string>, CreateHash, CreateUpdatedHash];

const createQueryHashUrl = (
  pathname: string,
  hash: string,
  query: ParsedQuery<StringOrNumber>,
  preserveHash: boolean,
): string => {
  const urlWithoutHash = `${pathname}?${stringify(query)}`;

  return preserveHash ? `${urlWithoutHash}${hash}` : urlWithoutHash;
};

export const useUrlManagerQuery = (): QueryApi => {
  const { hash, pathname, search } = useLocation();
  const params = parse(search);
  const createQuery = useCallback(
    (query: ParsedQuery<StringOrNumber>, preserveHash = true): string =>
      createQueryHashUrl(pathname, hash, query, preserveHash),
    [pathname, hash],
  );

  const createUpdatedHash = useCallback(
    (query: ParsedQuery<StringOrNumber>, preserveHash = true): string =>
      createQueryHashUrl(
        pathname,
        hash,
        {
          ...params,
          ...query,
        },
        preserveHash,
      ),
    [pathname, params, hash],
  );

  return [params, createQuery, createUpdatedHash];
};

const createHashUrl = (
  pathname: string,
  search: string,
  query: ParsedQuery<StringOrNumber>,
): string => `${pathname}${search}#!${stringify(query)}`;

export const useUrlManagerHash = (): HashApi => {
  const { hash, pathname, search } = useLocation();
  const params = parse(hash.substr(2));

  const createHash = useCallback(
    (query: ParsedQuery<StringOrNumber>): string => createHashUrl(pathname, search, query),
    [pathname, search],
  );

  const createUpdatedHash = useCallback(
    (query: ParsedQuery<StringOrNumber>): string =>
      createHashUrl(pathname, search, {
        ...params,
        ...query,
      }),
    [pathname, params, search],
  );

  return [params, createHash, createUpdatedHash];
};
