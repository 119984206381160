import React, { FC, useContext } from 'react';

import { UploadImage, normalizeKey } from 'src/v2/features/signature';

import { HankoFileSignatureContext } from '../../contexts/HankoFileSignatureContext';

export const UploadHanko: FC = () => {
  const { signatureId, setHankoFile, removeHankoFile } = useContext(HankoFileSignatureContext);

  return (
    <UploadImage key={normalizeKey(signatureId)} onUpload={setHankoFile} onDelete={removeHankoFile}>
      UPLOAD HANKO SIGNATURE
      <br />
      はんこをアップ
    </UploadImage>
  );
};
