import { useCallback, useState } from 'react';

export const useToggle = (initialState = false): [boolean, () => void, () => void, () => void] => {
  const [flag, setFlag] = useState(initialState);
  const toggle = useCallback(() => setFlag(!flag), [flag]);
  const setFlagTrue = useCallback(() => setFlag(true), []);
  const setFlagFalse = useCallback(() => setFlag(false), []);

  return [flag, toggle, setFlagTrue, setFlagFalse];
};
