import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box } from '@theme-ui/components';
import { ConnectDragSource } from 'react-dnd';
import { isNil, isNull, isUndefined, some } from 'lodash';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { EditorMode, FieldDiff, FieldType, FieldUpdateType, UploadData } from 'src/models/editor';
import { mergeStyles } from 'src/utils/styles';

import { EditorCore } from '../EditorCore';
import { styles } from './styles';
import { editableFieldEventIsError, EditorCoreRef } from '../EditorCore/EditorCoreTypes';
import { StaticSectionsContext } from '../../features/entitySections/StaticSectionsContext';
import { EditableSectionsContext } from '../../features/entitySections/EditableSectionsContext';
import { Field } from '../../../models/document';
import { contentFieldsWithSameNameMapper } from '../../features/entitySections/contentConverter';

interface Props {
  mode: EditorMode;
  content?: string;
  diff?: FieldDiff[];
  variant?: string;
  autoSaveInterval?: number;
  sortRef?: ConnectDragSource;
  isSorting?: boolean;
  onFieldUpdate?: (
    type: FieldType,
    name: string,
    value: string,
    updateType: FieldUpdateType,
    dataId?: string,
  ) => void;
  onUploadFile?: (file: File) => Promise<UploadData>;
  onUploadFileByUrl?: (url: string) => Promise<UploadData>;
  onRemove?: (event?: Event) => void;
  isActive?: boolean;
  isLocked?: boolean;
  onActivate?: () => void;
  onDeactivate?: () => void;
  onInsert?: (event?: Event) => void;
  onChange?: (content: string) => void;
  index?: number;
  isSaving?: boolean;
  editableFieldDisabled?: boolean;
  checkboxDisabled?: boolean;
  isFieldUpdating?: boolean;
  fields?: Field[];
  isInitiatedEditor?: boolean;
  handleDestroyEditor?: () => void;
}

class UploadAdapter {
  constructor(loader, onUploadFile) {
    // @ts-ignore
    this.loader = loader;
    // @ts-ignore
    this.onUploadFile = onUploadFile;
  }

  upload() {
    //@ts-ignore
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          const toBase64 = (file) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = (error) => reject(error);
            });

          return toBase64(file).then(() => {
            //@ts-ignore
            return this.onUploadFile(file)
              .then((response) => {
                //@ts-ignore
                this.loader.uploaded = true;
                resolve({
                  default: response.url,
                });
              })
              .catch(() => reject(`Couldn't upload file: ${file.name}.`));
          });
        }),
    );
  }
}
export const Editor = ({
  mode,
  content,
  variant,
  autoSaveInterval,
  sortRef,
  isSorting,
  onFieldUpdate,
  onUploadFile,
  onRemove,
  isLocked,
  onInsert,
  onChange,
  index,
  isFieldUpdating,
  diff,
  onActivate,
  onDeactivate,
  isActive,
  fields,
  isInitiatedEditor,
}: Props): JSX.Element => {
  const [isEditorActive, setIsEditorActive] = useState<boolean>(false);
  const [currentData, setCurrentData] = useState<string>('');
  const [hideToolbar, setHideToolbar] = useState(true);
  const [isDisable, setIsDisable] = useState(false);
  const [dataCanBeSaved, setDataCanBeSaved] = useState<null | boolean>(null);
  const editorRef = useRef<EditorCoreRef>(null);
  const isEmpty = currentData === '';
  const isReadOnly = mode !== EditorMode.ReadOnly;
  const isExpanded = !isReadOnly && (isActive || isEmpty) && !isSorting && !isLocked;
  const { updatedStaticSectionsField, setUpdatedStaticSectionsField } =
    useContext(StaticSectionsContext);
  const { updatedEditableSectionsField, setUpdatedEditableSectionsField } =
    useContext(EditableSectionsContext);

  useEffect(() => {
    if (isInitiatedEditor) {
      setTimeout(() => {
        editorRef.current?.setFocusOnEditor();
        if (mode === EditorMode.ContentEditable) {
          setHideToolbar(false);
        }
      }, 100);
    }
  }, [isInitiatedEditor, mode]);

  const containerStyles = mergeStyles(
    styles.container,
    [styles.showToolbar, !hideToolbar],
    [styles.disablePointerEvents, isFieldUpdating],
    [styles.editor],
    [styles.active, isExpanded],
    [styles.accepted, variant === 'accepted'],
    [styles.rejected, variant === 'rejected'],
  );

  const insertToolbar = (): void => {
    setHideToolbar(false);
  };

  const removeToolbar = (): void => {
    setHideToolbar(true);
  };

  const enableEditingFieldsCheckBoxesUpdate = (enable: boolean) => {
    editorRef.current?.setCheckboxesEnabled(enable);
    editorRef.current?.setEditableFieldsEnabled(enable);
  };

  const handleFocus = (): void => {
    if (mode === EditorMode.ContentEditable && !isEditorActive && onActivate && hideToolbar)
      onActivate();
  };

  const handleBlur = (): void => {
    if (mode === EditorMode.ContentEditable && isActive && !hideToolbar) {
      if (!isUndefined(onDeactivate)) onDeactivate();
    }
  };

  useEffect(() => {
    if (!isNull(updatedStaticSectionsField) && !isUndefined(isFieldUpdating)) {
      if (
        content?.includes(`data-name="${updatedStaticSectionsField.name}"`) &&
        !some(diff, ['name', updatedStaticSectionsField.name])
      ) {
        editorRef.current?.setEnabledFieldUpdating({
          name: updatedStaticSectionsField.name,
          value: isFieldUpdating,
          id: updatedStaticSectionsField.id,
        });
      }
    }

    if (!isUndefined(isFieldUpdating)) {
      enableEditingFieldsCheckBoxesUpdate(!isFieldUpdating);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFieldUpdating]);

  const setDiffBulk = useCallback(
    (diffArray): void => {
      const convertedDiffs = diffArray.map((diff) =>
        diff.newValue === '&nbsp;' ? { ...diff, newValue: '' } : diff,
      );

      editorRef.current?.setEnabledFieldDiffBulk(
        convertedDiffs,
        mode === EditorMode.FieldsEditable,
      );
      editorRef.current?.setCheckboxesDiffBulk(convertedDiffs, mode === EditorMode.FieldsEditable);
    },
    [mode],
  );

  useEffect(() => {
    if (mode === EditorMode.ReadOnly) {
      enableEditingFieldsCheckBoxesUpdate(false);
    }
    if (!isUndefined(diff) && diff.length > 0) {
      setDiffBulk(diff);
    }
  }, [diff, mode, setDiffBulk]);

  useEffect(() => {
    if (!isNull(updatedEditableSectionsField)) {
      const isFieldUpdatedInAnotherSection = updatedEditableSectionsField.sectionIndex !== index;
      if (isFieldUpdatedInAnotherSection && !isUndefined(onChange)) {
        if (content?.includes(`data-name="${updatedEditableSectionsField.name}"`)) {
          editorRef.current?.setEnabledFieldContent({
            name: updatedEditableSectionsField.name,
            value: updatedEditableSectionsField.value,
            withoutOutEvent: true,
          });
          const editorContent = editorRef.current?.getEditorContent();
          if (!isUndefined(editorContent) && editorContent !== content) {
            onChange(editorContent);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedEditableSectionsField]);

  const handleChangeStatus = () => {
    setIsEditorActive(!isEditorActive);
  };

  const handleDiffReview = useCallback(
    (fieldType: FieldType, name: string, value: string, type: string) => {
      const fieldUpdateType =
        type === FieldUpdateType.Accept ? FieldUpdateType.Accept : FieldUpdateType.Reject;
      if (onFieldUpdate) {
        setTimeout(() => onFieldUpdate(fieldType, name, value, fieldUpdateType), 0);
      }
    },
    [onFieldUpdate],
  );

  const handleUpdateField = (name: string, value: string, id: string): void => {
    if (onFieldUpdate && mode !== EditorMode.ReadOnly) {
      // setEditingEditableFieldName(name);
      setUpdatedStaticSectionsField({ name, sectionIndex: index, id });
      onFieldUpdate(FieldType.Text, name, value, FieldUpdateType.Modify, id);
    } else if (!isNull(setUpdatedEditableSectionsField)) {
      setUpdatedEditableSectionsField({ name, value, sectionIndex: index });
    }
  };

  const handleUpdateCheckbox = (name: string, checked): void => {
    if (mode === EditorMode.FieldsEditable && onFieldUpdate) {
      setUpdatedStaticSectionsField(null);
      onFieldUpdate(FieldType.Checkbox, name, checked, FieldUpdateType.Modify);
    }
  };

  useEffect(() => {
    if (!isNull(dataCanBeSaved)) {
      if (dataCanBeSaved) {
        const editorContent = editorRef.current?.getEditorContent();
        if (!isUndefined(editorContent) && !isUndefined(onChange)) {
          if (currentData !== editorContent) {
            onChange(contentFieldsWithSameNameMapper(editorContent, fields));
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCanBeSaved, currentData]);

  return (
    <Box sx={containerStyles} ref={sortRef}>
      <EditorCore
        ref={editorRef}
        isRestricted={mode === EditorMode.FieldsEditable || mode === EditorMode.ReadOnly}
        showToolbar={mode === EditorMode.ContentEditable}
        config={{
          autosave: {
            waitingTime: autoSaveInterval,
            // @ts-ignore
            save: () => {
              //autoSave();
            },
          },
        }}
        disabled={isDisable}
        onReady={(editor) => {
          if (!isNil(editor)) {
            setCurrentData(editor.getData());
            // @ts-ignore
            return (editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
              return new UploadAdapter(loader, onUploadFile);
            });
          }
        }}
        onFocus={(e, editor) => {
          handleChangeStatus();
          if (mode === EditorMode.ContentEditable) {
            insertToolbar();
            setDataCanBeSaved(false);
            handleFocus();
          }
        }}
        onBlur={(e, editor) => {
          editor.ui.focusTracker.on('change:isFocused', (evt, name, isFocused) => {
            if (!isFocused) {
              removeToolbar();
              handleChangeStatus();
              setDataCanBeSaved(true);
              handleBlur();
            }
          });
        }}
        index={index}
        data={content || ''}
        onEditableFieldSetContent={(data) => {
          !editableFieldEventIsError(data)
            ? handleUpdateField(data.name, data.value, data.id)
            : console.log('error', data);
        }}
        onCheckboxChange={(data) => handleUpdateCheckbox(data.id, data.checked.toString())}
        onCheckboxDiffReview={(data) =>
          handleDiffReview(FieldType.Checkbox, data.id, data.checked.toString(), data.type)
        }
        onDiffReview={(data) => handleDiffReview(FieldType.Text, data.name, data.value, data.type)}
        onEditableFieldDiffBulk={(data) => setIsDisable(data.type === 'ok')}
      />
      <Box
        sx={
          mode === EditorMode.ContentEditable && !isSorting
            ? styles.editorActions
            : styles.editorActionsDisabled
        }
      >
        <Box
          sx={styles.insertButton}
          title={i18n(translationKeys.buttons.createNewSection)}
          onClick={onInsert}
        >
          +
        </Box>
        <Box
          sx={styles.removeButton}
          title={i18n(translationKeys.buttons.removeSection)}
          onClick={onRemove}
        >
          ×
        </Box>
      </Box>
    </Box>
  );
};
