import { FC } from 'react';
import { Field, InjectedFormProps } from 'redux-form';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { CreationMode } from 'src/api/documents';
import { Divider } from 'src/v2/components/Divider';
import { MetadataForm, connectForm, RoleField } from 'src/v2/features/metadata';
import { Radio, Checkbox } from 'src/v2/features/reduxForm';
import { usePreventEntityWithTemplateCreation } from 'src/v2/features/sharedEntity/hooks';
import { EntityType } from 'src/models/paper';
import { ContractMetaProps, FormContractCreatePayload } from './types';

interface Props extends ContractMetaProps {
  isLoading: boolean;
  onSubmit: (data: FormContractCreatePayload) => void;
}

const Create: FC<Props & InjectedFormProps<FormContractCreatePayload, Props>> = ({
  isLoading,
  onSubmit,
  handleSubmit,
}) => {
  const preventSelectContractTypeHandler = usePreventEntityWithTemplateCreation();

  const creationModes = [
    {
      value: CreationMode.blank,
      label: i18n(translationKeys.forms.contract.creationModes.blank),
    },
    {
      value: CreationMode.template,
      label: i18n(translationKeys.forms.contract.creationModes.template),
    },
    {
      value: CreationMode.upload,
      label: i18n(translationKeys.forms.contract.creationModes.upload),
    },
  ];
  return (
    <MetadataForm
      label={i18n(translationKeys.forms.contract.create.createNew)}
      infoLabel={i18n(translationKeys.forms.contract.create.createNewInfo)}
      type={EntityType.contract}
      isLoading={isLoading}
      onSubmit={handleSubmit(onSubmit)}
    >
      <RoleField />
      <Divider />
      <div className="c-title c-title--uppercase c-title--lh-1">
        {i18n(translationKeys.forms.contract.create.type)}
      </div>
      {creationModes.map(({ value, label }) => (
        <Field
          key={value}
          component={Radio}
          name="createDocumentOption"
          value={value}
          label={label}
          type="radio"
          onChange={preventSelectContractTypeHandler}
          mb8
        />
      ))}
      <Divider />
      <Field
        component={Checkbox}
        name="ownerConditionRequired"
        label={i18n(translationKeys.forms.item.signParty)}
        sx={{
          mb: '8px',
          alignItems: 'center',
          fontWeight: 'normal',
        }}
      />
    </MetadataForm>
  );
};

const fields = ['role', 'createDocumentOption', 'ownerConditionRequired'];
export const ContractMetadataCreate = connectForm<Props, FormContractCreatePayload>(
  Create,
  fields,
  {
    createDocumentOption: CreationMode.blank,
  },
);
