import React, { FC, useCallback, useContext } from 'react';
import cn from 'classnames';
import { isUndefined, noop } from 'lodash';

import { DeviceContext } from 'src/common/features/device';
import { CardUserIconData } from 'src/models/card';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

import { UsersFeed } from './components/UsersFeed';
import { State, StateOption } from './components/State';
import { Icon, IconOption } from './components/Icon';

export enum Intent {
  BlueDark,
  Beige,
  YellowDark,
  GrayPale,
}

const selectedCard = 'c-card__item--active';
const classIntentMap = {
  [Intent.Beige]: 'c-card--beige',
  [Intent.BlueDark]: 'c-card--blue-dark',
  [Intent.GrayPale]: 'c-card--gray-pale',
  [Intent.YellowDark]: 'c-card--yellow-dark',
};

interface Props {
  title: string;
  description: string;
  date?: string;
  author: string;
  status: string;
  showSignedStatus?: boolean;
  state: StateOption;
  users: CardUserIconData[];
  isConfirmationVisible: boolean;
  role: unknown;
  intent?: Intent;
  canDeleteCard: boolean;
  selected?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  onTouch?: (event: React.MouseEvent) => void;
  onDoubleClick?: (event: React.MouseEvent) => void;
  onDeleteClick?: (event: React.MouseEvent) => void;
  onCrossClick?: (event: React.MouseEvent) => void;
  onContextMenu?: (node: HTMLElement | null) => void;
  crossRef: React.RefObject<HTMLDivElement>;
  icon: IconOption;
}

export const Card: FC<Props> = ({
  title,
  description,
  date,
  status,
  state = StateOption.Red,
  author,
  users = [],
  onClick = noop,
  onTouch = noop,
  onDoubleClick,
  onDeleteClick,
  onCrossClick,
  onContextMenu,
  isConfirmationVisible,
  intent,
  canDeleteCard,
  selected = false,
  showSignedStatus = false,
  crossRef,
  icon,
}) => {
  const { isTouchable } = useContext(DeviceContext);
  const clickHandler = useCallback(
    (e: React.MouseEvent) => {
      if (isTouchable) {
        onTouch(e);
      } else {
        onClick(e);
      }
    },
    [isTouchable, onTouch, onClick],
  );

  return (
    <div
      ref={onContextMenu}
      className={cn('c-card__item', !isUndefined(intent) && !selected && classIntentMap[intent], {
        [selectedCard]: selected,
      })}
      data-type="card"
      onClick={clickHandler}
      onDoubleClick={onDoubleClick}
    >
      <div className={cn('c-card__header')}>
        <div className={cn('c-card__title')}>{title}</div>
        <Icon icon={icon} />
      </div>
      <div className="c-card__main">
        <div className="c-card__text">{description}</div>
        <div className="c-card__info">
          {date && (
            <div className="c-card__info--row">
              <div className="c-card__info--title">{i18n(translationKeys.card.fields.date)}</div>
              <div className="c-card__info--value">{date}</div>
            </div>
          )}
          {showSignedStatus && (
            <div className="c-card__info--row">
              <div className="c-card__info--title">{i18n(translationKeys.card.fields.signed)}</div>
              <div className="c-card__info--value">{status}</div>
            </div>
          )}
          <State state={state} />
        </div>
        <div className="c-card__user">
          {author && <div className="c-card__user--name">{author}</div>}

          <UsersFeed users={users} />
        </div>
        {canDeleteCard && (
          <div
            className={cn('c-card__remove', isConfirmationVisible && 'c-card__remove--active')}
            ref={crossRef}
          >
            <div className="c-card__remove--cross" onClick={onCrossClick} />
            <div className="c-card__remove--approve" onClick={onDeleteClick}>
              {i18n(translationKeys.card.delete)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

Card.displayName = 'Card';
