import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { ContextMenuBook } from 'src/app/types';
import { book } from 'src/app/book';
import { MenuDocuments } from 'src/v2/icons/MenuDocuments';
import { ListItemViewComponentProps } from 'src/v2/modules/FsItem/types';

import { FolderView } from '../components/FolderView';
import { setFolderName } from '../utils';

export const DocumentsFolderView: FC<ListItemViewComponentProps> = (props) => {
  const navigate = useNavigate();
  const onOpenHandler = useCallback(() => navigate(book.documents.generatePath()), [navigate]);

  const { isExpanded, isActive, isLoaded, children } = props;

  return (
    <FolderView
      contextMenu={ContextMenuBook.DocumentFolder}
      customIcon={<MenuDocuments />}
      model={setFolderName(props.model, i18n(translationKeys.navigationMenu.documents))}
      onToggleExpand={onOpenHandler}
      onRootClick={onOpenHandler}
      isArrowHidden
      isExpanded={isExpanded}
      isActive={isActive}
      isLoaded={isLoaded}
    >
      {children}
    </FolderView>
  );
};
