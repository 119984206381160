import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getPreferredLanguage } from 'src/v2/features/profile';
import { SignatureId, StampId } from 'src/models/signature';
import {
  useCreateSignatureHandler,
  useEditSignatureHandler,
} from 'src/v2/features/signature/hooks';

import {
  createNewHanko,
  editHanko,
  saveHanko,
  editHankoSignature,
  saveHankoSignature,
  setDefaultSignatureStamp,
} from './store';
import { shouldUseHanko } from './utils';

export const useShouldUseHanko = (): boolean => {
  const language = useSelector(getPreferredLanguage);

  return shouldUseHanko(language);
};

export const useCreateNewHankoHandler = () => {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(createNewHanko());
  }, [dispatch]);
};

export const useEditHankoHandler = () => {
  const dispatch = useDispatch();

  return useCallback(
    (stampId: StampId) => {
      dispatch(editHanko(stampId));
    },
    [dispatch],
  );
};

export const useSubmitHankoHandler = () => {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(saveHanko());
  }, [dispatch]);
};

export const useCreateNewHankoSignatureHandler = () => {
  const handleCreateNewSignature = useCreateSignatureHandler();
  const handleCreateNewHanko = useCreateNewHankoHandler();

  return useCallback(() => {
    handleCreateNewSignature();
    handleCreateNewHanko();
  }, [handleCreateNewSignature, handleCreateNewHanko]);
};

export const useEditHankoSignatureHandler = () => {
  const dispatch = useDispatch();
  const handleEditSignature = useEditSignatureHandler();

  return useCallback(
    (signatureId: SignatureId) => {
      handleEditSignature(signatureId);
      dispatch(editHankoSignature(signatureId));
    },
    [dispatch, handleEditSignature],
  );
};

export const useSubmitHankoSignatureHandler = () => {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(saveHankoSignature());
  }, [dispatch]);
};

export const useDefaultStampChangeHandler = () => {
  const dispatch = useDispatch();
  return useCallback(
    (stampId: StampId): void => {
      dispatch(setDefaultSignatureStamp(stampId));
    },
    [dispatch],
  );
};
