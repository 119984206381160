import { createContext } from 'react';
import { noop } from 'lodash';

import { ActionContextValue } from '../types';

export const SignatureActionContext = createContext<ActionContextValue>({
  isEditing: false,
  isDisabled: false,
  isLoading: false,
  onSubmit: noop,
});
