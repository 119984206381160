import React from 'react';
import { isNull, toNumber } from 'lodash';

import { ModalView } from 'src/v2/features/modal';
import { Button } from 'src/v2/features/button';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { SubscriptionPlan, SubscriptionPlanLabelMap } from 'src/models/billing';

import { formatPrice } from '../utils';

type Props = {
  onClose: () => void;
  onSubmit: any;
  isLoading: boolean;
  plan: SubscriptionPlan | null;
  totalPrice: number;
  tax: number;
  planPrice: number;
};

export const ConfirmationModal: React.FC<Props> = ({
  onClose,
  onSubmit,
  isLoading,
  plan,
  totalPrice,
  tax,
  planPrice,
}) => {
  const planWithPeriod = !isNull(plan) && i18n(SubscriptionPlanLabelMap[plan]);

  return (
    <ModalView isActive>
      {!isLoading && <div className="c-modal__close" onClick={onClose}></div>}
      <div className="c-modal__row">
        <div className="c-title c-title--center c-title--fs-32 h-uppercase">
          {i18n(translationKeys.modal.paymentConfirmation.title)}
        </div>
      </div>
      <div className="c-modal__row">
        <div className="c-description__text c-description--center c-description--fs-14">
          {i18n(translationKeys.modal.paymentConfirmation.subTitle)}
        </div>
      </div>
      <div className="c-modal__row c-modal--mt-32">
        <div className="c-modal__payment">
          <div className="c-modal__payment--row">
            <div className="c-modal__payment--title">
              {i18n(translationKeys.modal.paymentConfirmation.plan)}
            </div>
            <div className="c-modal__payment--value">{planWithPeriod}</div>
          </div>
          <div className="c-modal__payment--row">
            <div className="c-modal__payment--title">
              {i18n(translationKeys.modal.paymentConfirmation.subTotal)}
            </div>
            <div className="c-modal__payment--value">{formatPrice(toNumber(planPrice))}</div>
          </div>
          <div className="c-modal__payment--row">
            <div className="c-modal__payment--title">
              {i18n(translationKeys.modal.paymentConfirmation.tax)}
            </div>
            <div className="c-modal__payment--value">{formatPrice(toNumber(tax))}</div>
          </div>
          <div className="c-modal__payment--row">
            <div className="c-modal__payment--line"></div>
          </div>
          <div className="c-modal__payment--row">
            <div className="c-modal__payment--title">
              <b>{i18n(translationKeys.modal.paymentConfirmation.totalPrice)}</b>
            </div>
            <div className="c-modal__payment--value">{formatPrice(toNumber(totalPrice))}</div>
          </div>
        </div>
      </div>
      <div className="c-modal__row c-modal--mt-32 c-modal--gap-16">
        <Button className="c-button c-button--w-full" onClick={onClose} disabled={isLoading}>
          <span className="c-button__inner">{i18n(translationKeys.buttons.cancel)}</span>
        </Button>
        <Button
          type="submit"
          className="c-button c-button--w-full c-button--green"
          isLoading={isLoading}
          onClick={onSubmit}
        >
          <span className="c-button__inner">
            {i18n(translationKeys.buttons.pay)} {formatPrice(toNumber(totalPrice))}
          </span>
        </Button>
      </div>
    </ModalView>
  );
};
