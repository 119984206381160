import { FC } from 'react';

import { SharedSidebarContextProps } from 'src/v2/features/sharedEntity/EntitySidebar/types';
import { SharedDocumentSidebarContextProvider } from 'src/v2/features/document/DocumentSidebar/SharedDocumentSidebarContextProvider';
import { SharedContractSidebarContextProvider } from 'src/v2/features/contract/ContractSidebar/SharedContractSidebarContextProvider';

import { book } from './book';

export const SharedProjectSidebarContextProvider: FC<SharedSidebarContextProps> = ({
  id,
  children,
  view,
}) => {
  return view === book.viewDocument ? (
    <SharedDocumentSidebarContextProvider id={id}>{children}</SharedDocumentSidebarContextProvider>
  ) : (
    <SharedContractSidebarContextProvider id={id}>{children}</SharedContractSidebarContextProvider>
  );
};
