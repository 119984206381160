import { head } from 'lodash';

import { ObjectBase } from 'src/common/types';
import { SidebarParticipantEntity } from 'src/v2/entities/participants';

import { getObjects } from './objectsStorageSlice';
import { ObjectsContainer } from './types';

interface UserDataSrc {
  [key: string]: ObjectsContainer;
}

export const getUserObj = (
  userId: string,
  data: UserDataSrc,
): SidebarParticipantEntity | undefined => {
  const userObj = { id: userId, type: 'user' } as ObjectBase;
  return head(getObjects([userObj], data)) as SidebarParticipantEntity;
};
