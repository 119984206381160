import React, { useContext, useEffect, useState } from 'react';
import { Image, Text } from '@theme-ui/components';
import { isEmpty } from 'lodash';

import { LegalAgreement } from 'src/v2/components/LegalAgreement';
import { Tooltip } from 'src/v2/features/Tooltip';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { TextSignatureContext } from 'src/v2/features/signature/contexts/TextSignatureContext';

import { SignatureProps } from './types';
import { Avatar } from 'src/v2/components/Avatar';
import tokenIcon from 'src/assets/tokens-icon.png';

const style = {
  container: {
    mb: '20px',
    p: '10px 5px',
    alignSelf: 'flex-start',
    maxWidth: '100%',
  },

  avatarImage: {
    marginLeft: '0px',
    minWidth: '46px',
  },

  signatureImage: {
    maxHeight: '48px',
    pointerEvents: 'none',
    maxWidth: '200px',
  },

  userName: {
    paddingLeft: '15px',
    pointerEvents: 'none',
  },

  divider: {
    mb: '10px',
    paddingTop: '3px',
    color: '#707070',
  },

  bcText: {
    paddingTop: '10px',
    color: '#324353',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: 1,
  },

  bcHash: {
    paddingTop: '3px',
    marginRight: '10px',
    color: '#29cb97',
    fontSize: '12px',
    lineHeight: 1.25,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },

  flexBc: {
    alignItems: 'center',
    position: 'relative',
  },

  coin: {
    mr: '7px',
    width: '30px',
    height: '30px',
  },
};

export const Signature: React.FC<SignatureProps> = ({
  avatarUrl,
  signatureUrl,
  userName,
  signatureHash,
  companyName,
  companyTitle,
  timestamp,
  legalTooltip,
  signatureName,
  children,
}) => {
  const { text, font } = useContext(TextSignatureContext);

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isSignatureLoading, setSignatureLoading] = useState(false);
  const [isImageBroken, setImageStatus] = useState(false);

  const isSignatureVisible =
    (!isImageBroken && !isEmpty(text) && !isEmpty(font) && isSignatureLoading) ||
    (!isImageBroken && !isEmpty(signatureUrl));

  useEffect(() => {
    let timer = setTimeout(() => setSignatureLoading(true), 1000);

    return () => {
      clearTimeout(timer);
      setSignatureLoading(false);
    };
  }, [text, font, setSignatureLoading]);

  const onSignatureClick = (): void => {
    setIsTooltipOpen(true);
  };

  const closeTooltip = (): void => {
    setIsTooltipOpen(false);
  };

  const onError = () => setImageStatus(true);
  const onLoad = () => setImageStatus(false);

  return (
    <div data-type="userSignature-box" className="c-signature__preview">
      <div className="c-signature__selected">
        <div className="c-signature__img">
          <div className="c-signature__img--column">
            <Avatar src={avatarUrl} size={46} sx={style.avatarImage} />
          </div>
          <div className="c-signature__img--column">
            <Image
              onError={onError}
              onLoad={onLoad}
              data-type="signature-image"
              sx={style.signatureImage}
              src={signatureUrl}
              style={{
                visibility: isSignatureVisible ? 'visible' : 'hidden',
              }}
            />
          </div>
          {userName && (
            <Text data-type="signature-text" sx={style.userName}>
              /{userName}/
            </Text>
          )}
          {children}
        </div>
      </div>
      <div className="c-signature__line" />
      <div className="c-signature__blockchain" onClick={onSignatureClick}>
        <div className="c-signature__token">
          <Image src={tokenIcon} sx="icon--token" />
        </div>
        <div className="c-signature__info">
          <div className="c-signature__title">
            {i18n(translationKeys.forms.signature.blockchainId)}
          </div>
          <div className="c-signature__number" data-type="blockchainId-text">
            {signatureHash}
          </div>
        </div>
        {legalTooltip && (
          <Tooltip isOpen={isTooltipOpen} onCrossClick={closeTooltip}>
            <LegalAgreement />
          </Tooltip>
        )}
      </div>

      <Text data-type="blockchainBy-text" sx={style.bcText}>
        {`${i18n(translationKeys.forms.signature.by)}: ${signatureName}`}
      </Text>
      {companyTitle && (
        <Text data-type="companyTitle-text" sx={style.bcText}>
          {`${i18n(translationKeys.forms.signature.title)}: ${companyTitle}`}
        </Text>
      )}
      {companyName && (
        <Text data-type="companyName-text" sx={style.bcText}>
          {`${i18n(translationKeys.forms.signature.company)}: ${companyName}`}
        </Text>
      )}

      <Text data-type="timestamp-text" sx={style.bcText}>
        {timestamp}
      </Text>
    </div>
  );
};
