import React from 'react';
import cn from 'classnames';
import { SelectProps } from '../types';
import { WithValidationMessage } from '../WithValidationMessage';

const classModifiers = {
  disabled: 'c-select--disabled',
  mb8: 'c-select--mb-8',
};

export const Select = (props: SelectProps): JSX.Element => {
  const {
    errorMessage,
    successMessage,
    className,
    options = [],
    mb8 = false,
    ...restProps
  } = props;
  const { disabled, name } = restProps;
  return (
    <WithValidationMessage
      className={cn(className, {
        [classModifiers.disabled]: disabled,
        [classModifiers.mb8]: mb8,
      })}
      errorMessage={errorMessage}
      successMessage={successMessage}
      classPrefix="c-select"
    >
      <div className="c-select__selected">
        <select {...restProps}>
          {options.map(({ label, value, disabled = false }, idx) => (
            <option value={value} disabled={disabled} key={`${name}-${value}-${idx}`}>
              {label}
            </option>
          ))}
        </select>
      </div>
    </WithValidationMessage>
  );
};
