import React from 'react';
import { isUndefined } from 'lodash';
import cn from 'classnames';
import { WithValidationProps } from '../types';

const classModifiers = {
  validationError: '__message--error',
  validationSuccess: '__message--success',
};

export const ValidationMessage = ({
  errorMessage,
  successMessage,
  classPrefix,
}: WithValidationProps): JSX.Element => {
  return (
    <div
      className={cn(`${classPrefix}__message ${classPrefix}__message--bottom`, {
        [`${classPrefix}${classModifiers.validationError}`]: !isUndefined(errorMessage),
        [`${classPrefix}${classModifiers.validationSuccess}`]: !isUndefined(successMessage),
      })}
    >
      {errorMessage || successMessage}
    </div>
  );
};
