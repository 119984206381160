import React, { FC, useCallback, useState } from 'react';
import { find, isUndefined, result } from 'lodash';
import { toastr } from 'react-redux-toastr';

import { FieldType, FieldUpdateType } from 'src/models/editor';
import { updateContentField } from 'src/v2/components/Editor';

import { StaticSection } from './components/StaticSection';
import { StaticProps } from './types';
import { contentConverter } from './contentConverter';
import { StaticSectionsContext } from './StaticSectionsContext';
import { useIsAccountOwner } from '../organization/hooks';
import { isPropagateFieldsValues } from 'src/v2/components/EditorCore/utils';

export const StaticCore: FC<StaticProps> = ({
  sections,
  negotiable,
  fields,
  diff,
  unmatchingSections,
  onFieldUpdate,
  isFieldUpdating,
}) => {
  const [updatedStaticSectionsField, setUpdatedStaticSectionsField] = useState(null);
  const isOwner = useIsAccountOwner();

  const handleFieldUpdate = useCallback(
    (oldContent: string, index: number) =>
      (
        type: FieldType,
        name: string,
        value: string,
        updateType: FieldUpdateType,
        fieldId?: string,
      ): void => {
        if (!fields || !onFieldUpdate) return;
        const field = find(fields, { name });
        const elements = Array.from(document.querySelectorAll('.editable-field[data-id]'));
        const editorStubContent = Array.from(document.querySelectorAll('.content'));
        const fieldBySection = {};
        editorStubContent.forEach((element, index) => {
          fieldBySection[index] = element.querySelectorAll('.editable-field[data-id]');
        });

        const sorterFields: any = [];

        if (elements.length > fields.length) {
          if (isOwner) {
            elements.splice(0, Math.floor(elements.length / 2));
          } else {
            elements.splice(Math.floor(elements.length / 2));
          }
        }
        Array.from(fieldBySection[index])
          .filter((element: any) => {
            return element.getAttribute('data-name') === name;
          })
          .forEach((element: any) => {
            const finded = find(fields, { editorFieldID: element.getAttribute('data-id') });
            return sorterFields.push(finded);
          });

        if (isUndefined(field) || isUndefined(field.id)) {
          toastr.error(
            'Error',
            'This field cannot be updated due to technical reasons. Try to create a new contract',
          );
          return;
        }

        if (type === FieldType.Text && updateType !== FieldUpdateType.Reject) {
          const isFieldChange = field.value !== value;
          if (!isFieldChange) {
            setUpdatedStaticSectionsField(null);
            return;
          }
        }

        const findedField: string = result(
          find(sorterFields, ({ editorFieldID }) => editorFieldID === fieldId),
          'id',
        );

        const content = updateContentField(type, oldContent, name, value, fieldId);
        if (isPropagateFieldsValues() && type === FieldType.Text) {
          onFieldUpdate({
            fields: sorterFields.map(({ id }) => {
              return {
                path: [index],
                text: content,
                action: updateType,
                fieldId: id,
                fieldValue: value,
              };
            }),
          });
        } else {
          onFieldUpdate({
            index,
            content,
            updateType,
            fieldId: !isUndefined(findedField) ? findedField : field.id,
            fieldValue: value,
          });
        }
      },
    [fields, isOwner, onFieldUpdate],
  );

  const context = {
    updatedStaticSectionsField,
    setUpdatedStaticSectionsField,
  };

  return (
    <>
      <StaticSectionsContext.Provider value={context}>
        {sections.map((data, index) => (
          <StaticSection
            index={index}
            key={`${index}_${data.id}`}
            content={contentConverter(data.text, fields)}
            diff={diff}
            highlight={unmatchingSections && unmatchingSections.includes(index)}
            negotiable={negotiable}
            onFieldUpdate={handleFieldUpdate(contentConverter(data.text, fields), index)}
            isFieldUpdating={!!isFieldUpdating}
          />
        ))}
      </StaticSectionsContext.Provider>
    </>
  );
};

export const StaticSections = StaticCore;
