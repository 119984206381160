import { createContext } from 'react';
import { noop } from 'lodash';

import { HankoSignatureSettingsContextValue } from '../types';

export const HankoSignatureSettingsContext = createContext<HankoSignatureSettingsContextValue>({
  localisedSignature: false,
  includeHanko: false,
  toggleLocalisedSignature: noop,
  toggleIncludeHanko: noop,
  isIncludeHankoVisible: false,
  hankoPreviewUrl: null,
  uploadedImageUrl: null,
});
