import { FC, useMemo } from 'react';

import { ParticipantsSection, useNavigateToDefault } from 'src/v2/features/sharedEntity';
import { useGetFullParticipantsByDocumentId } from 'src/v2/features/entityParticipants/hooks';
import {
  useCanEditParticipant,
  useCanRemoveParticipant,
} from 'src/v2/features/document/workflow/documentWorkflow';
import { getParticipantById } from 'src/v2/features/documentParticipants';
import { Divider } from 'src/v2/components/Divider';
import { DocumentEditParticipantForm } from 'src/v2/features/document/participants/DocumentEditParticipantForm';
import { DocumentType, DocumentRole } from 'src/models/document';

export interface Props {
  id: string;
  participantId?: string;
}

export const EditDocumentParticipant: FC<Props> = ({ id, participantId }) => {
  const navigateToDefault = useNavigateToDefault(id);
  const participants = useGetFullParticipantsByDocumentId<DocumentType, DocumentRole>(id);
  const participant = useMemo(
    () => getParticipantById(participants, participantId),
    [participants, participantId],
  );
  const isAllowedToEditParticipant = useCanEditParticipant(id);
  const isAllowedToRemoveParticipant = useCanRemoveParticipant(id);

  return (
    <>
      <ParticipantsSection
        entityId={id}
        isAllowedToEditParticipant={isAllowedToEditParticipant}
        isAllowedToRemoveParticipant={isAllowedToRemoveParticipant}
      />
      <Divider />
      {participant && (
        <DocumentEditParticipantForm
          documentId={id}
          participant={participant}
          onClose={navigateToDefault}
        />
      )}
    </>
  );
};
