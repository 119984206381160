import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, omit } from 'lodash';

import { DocumentType, DocumentRole } from 'src/models/document';
import { EntityType } from 'src/models/paper';
import { DocumentPayload } from 'src/models/document';
import { getOwnerName } from 'src/v2/features/document';
import { updateDocument, getIsCreating } from 'src/v2/features/documentList/documentListSlice';
import { useGetEntityById, ParticipantsSection } from 'src/v2/features/sharedEntity';
import {
  useCanEditParticipant,
  useCanRemoveParticipant,
} from 'src/v2/features/document/workflow/documentWorkflow';

import { DocumentMetadataEdit } from '../../components/DocumentMetadata';

interface Props {
  id: string;
}

export const Edit: React.FC<Props> = ({ id }) => {
  const dispatch = useDispatch();
  const document = useGetEntityById<DocumentType, DocumentRole>(id);
  const isAllowedToEditParticipant = useCanEditParticipant(id);
  const isAllowedToRemoveParticipant = useCanRemoveParticipant(id);

  // @TODO: Use proper selector after refactoring @Dimich-x33
  const isUpdating = useSelector(getIsCreating);

  const onSubmit = useCallback(
    (data: DocumentPayload) => {
      const payload = omit(data, 'createDocumentOption') as DocumentPayload;

      if (!document) return;

      dispatch(
        updateDocument({
          id: get(document, 'id'),
          data: { ...payload, type: EntityType.document },
        }),
      );
    },
    [dispatch, document],
  );

  return document ? (
    <>
      <DocumentMetadataEdit
        isLoading={isUpdating}
        onSubmit={onSubmit}
        id={document.id}
        title={document.title}
        description={document.description}
        createdAt={document.createdAt}
        subType={document.subType}
        subSubType={document.subSubType}
        owner={getOwnerName(document)}
        state={document.state}
        role={document.ownerFunctionRole}
      />
      <ParticipantsSection
        entityId={document.id}
        isAllowedToEditParticipant={isAllowedToEditParticipant}
        isAllowedToRemoveParticipant={isAllowedToRemoveParticipant}
      />
    </>
  ) : null;
};
