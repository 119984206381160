const styles = {
  default: {
    display: 'inline-block',
    fontFamily: 'arial',
    fontSize: '14px',
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: 1,
    letterSpacing: 'normal',
    textAlign: 'center',
    m: 0,
    py: '12px',
    px: '26px',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: [0],
    textTransform: 'uppercase',
    cursor: 'pointer',
    outline: 0,
    '&[disabled]': {
      cursor: 'default',
      opacity: 0.8,
    },
  },
};

export const buttons = {
  primary: {
    ...styles.default,
    color: 'white',
    bg: 'primary',
    borderColor: 'primary',
    outline: 0,
  },

  secondary: {
    ...styles.default,
    color: 'black',
    bg: 'gray',
    borderColor: 'gray',
  },

  thirdary: {
    ...styles.default,
    bg: 'green',
    borderColor: 'green',
    color: 'white',
  },

  danger: {
    ...styles.default,
    bg: '#d57872',
    borderColor: '#d57872',
    color: 'white',
  },

  accent: {
    ...styles.default,
    color: '#2e4354',
    bg: '#e5b42b',
    borderColor: '#e5b42b',
  },

  accentLogin: {
    ...styles.default,
    color: '#ffffff',
    bg: '#2e4354',
    borderColor: '#2e4354',
  },

  accentInverted: {
    ...styles.default,
    color: '#e5b42b',
    bg: '#4d6982',
    borderColor: '#4d6982',
    textTransform: 'normal',
  },

  outline: {
    ...styles.default,
    bg: '#fff',
    color: '#4d6982',
    borderColor: '#4d6982',
  },

  plain: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    outline: 0,
    fontFamily: 'body',
    fontSize: '12px',
    fontWeight: 300,
    color: '#4d6982',
    bg: 'transparent',
    '& img': {
      mr: '5px',
    },
    '&:disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
  },

  addButton: {
    icon: {
      display: 'inline-block',
      width: '40px',
      height: '40px',
      m: 0,
      p: 0,
      outline: 'none',
      cursor: 'pointer',
    },

    card: {
      width: '100%',
      height: '100%',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: '#29CB97',
      bg: '#F4F4F4',
      cursor: 'pointer',
      outline: 0,
      transition: 'all 0.5s ease-in-out',
      ':hover': {
        boxShadow: '0 0.18em 0.36em rgba(0, 0, 0, 0.16)',
      },
    },
  },

  inlineText: {
    display: 'inline-block',
    m: 0,
    p: 0,
    bg: 'transparent',
    outline: 0,
    cursor: 'pointer',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontStyle: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    letterSpacing: 'inherit',
    color: 'inherit',
  },

  icon: {
    cursor: 'pointer',
    outline: 0,
  },
};
