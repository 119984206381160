import { createAction, PayloadAction } from '@reduxjs/toolkit';
import { call, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { getContractDIContainer } from 'src/v2/features/diFactories/contractDIFactory';
// import { createGetRootFolderId } from 'src/v2/features/sharedEntity/createGetRootFolderId';
import { CopyEntityToProjectDTO } from 'src/v2/boundary/requestDTO/project';
import { CopyContractToFolderDTO } from 'src/v2/boundary/requestDTO/contract';

const {
  isContractFolder,
  createGetRootFolderId,
  handleErrorSaga,
  copyEntityToProjectAPI,
  copyContractToFolderAPI,
} = getContractDIContainer();

export const getRootContractFolderId = createGetRootFolderId(isContractFolder);

export const copyContractToFolder = createAction<CopyContractToFolderDTO>(
  'contract/copyContractToFolder',
);
export const copyContractToProject = createAction<CopyEntityToProjectDTO>(
  'contract/copyContractToProject',
);

function* copyContractToFolderSaga(action: PayloadAction<CopyContractToFolderDTO>) {
  try {
    yield call(copyContractToFolderAPI, action.payload.contractId, action.payload.folderId);
    yield call(toastr.success, 'Success', 'Successfully copied');
  } catch (err) {
    yield call(handleErrorSaga, err);
  }
}

function* copyContractToProjectSaga(action: PayloadAction<CopyEntityToProjectDTO>) {
  try {
    yield call(copyEntityToProjectAPI, action.payload.entityId, action.payload.projectId);
    yield call(toastr.success, 'Success', 'Successfully copied');
  } catch (err) {
    yield call(handleErrorSaga, err);
  }
}

export function* watchContractsRootSagas() {
  yield takeLatest(copyContractToFolder, copyContractToFolderSaga);
  yield takeLatest(copyContractToProject, copyContractToProjectSaga);
}
