import { connect } from 'react-redux';

import { EditorSection, DispatchProps } from 'src/v2/features/template/components/EditorSection';
import {
  insertSection,
  updateSection,
  moveSection,
  removeSection,
  uploadFile,
  uploadFileByUrl,
} from '../reducer';

const mapDispatchToProps: DispatchProps = {
  insertSection,
  updateSection,
  moveSection,
  removeSection,
  uploadFile,
  uploadFileByUrl,
};

export default connect(null, mapDispatchToProps)(EditorSection);
