import React, { FC } from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { Box } from '@theme-ui/components';

import { Emails } from 'src/v2/features/reduxForm/Emails';
import { validateInviteForm } from 'src/utils/validate';

interface InviteParticipantsPayload {
  emails: string[];
}

const InviteParticipants: FC<InjectedFormProps<InviteParticipantsPayload>> = ({ handleSubmit }) => (
  <Box data-id="templateLibrary-form-inviteParticipants" as="form" onSubmit={handleSubmit}>
    <Field name="emails" component={Emails} />
  </Box>
);

export const InviteParticipantsForm = reduxForm<InviteParticipantsPayload>({
  form: 'inviteParticipantsForm',
  validate: validateInviteForm,
})(InviteParticipants);
