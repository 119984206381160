import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useGetProjectFoldersTreeByIdWithoutSystemFolders } from 'src/v2/features/quantumNavigator/hooks';
import { closeModal } from 'src/v2/features/modal/store';
import { FolderSelectorWithProjects } from 'src/v2/features/folderSelector/FolderSelectorWithProjects/FolderSelectorWithProjects';

import { useFetchProjectsListEffct } from 'src/v2/features/project/utils';
import { NodeTree } from 'src/models/node';
import { ListItem } from 'src/models/list';
import { isNull } from 'lodash';
import { FolderView } from '../FolderView';
import { getFolderId, getActiveFolderId } from '../store';
import { FolderSelectorWithProjectsProps } from '../types';

export const useGetFoldersTreeAndFetchEffct = (
  folderId: string | undefined | null,
  validateFn: (model: NodeTree<ListItem>) => boolean,
) => {
  useFetchProjectsListEffct(folderId as string);

  return useGetProjectFoldersTreeByIdWithoutSystemFolders(folderId, validateFn);
};

export const FolderSelectorWithProjectsContainer: FC<FolderSelectorWithProjectsProps> = ({
  onActionButtonClick,
  onProjectsActionButtonClick,
  validateFn,
  projectsRootFolderId,
  menuItemFolderId,
  ...props
}) => {
  const [isInProjectsFolder, setIsInProjectsFolder] = useState<boolean>(false);
  const folderId = useSelector(getFolderId);
  const activeFolderId = useSelector(getActiveFolderId);
  const dispatch = useDispatch();
  const model = useGetFoldersTreeAndFetchEffct(folderId, validateFn);
  const handleClose = () => dispatch(closeModal());
  const handleActionButtonClick = useCallback(() => {
    if (!isNull(activeFolderId)) {
      if (isInProjectsFolder) {
        onProjectsActionButtonClick(activeFolderId);
      } else {
        onActionButtonClick(activeFolderId);
      }
    } else {
      console.warn('activeFolderId is null');
    }
  }, [onActionButtonClick, onProjectsActionButtonClick, activeFolderId, isInProjectsFolder]);

  useEffect(() => {
    if (activeFolderId === projectsRootFolderId) {
      setIsInProjectsFolder(true);
    }
    if (activeFolderId === menuItemFolderId) {
      setIsInProjectsFolder(false);
    }
  }, [activeFolderId, menuItemFolderId, projectsRootFolderId]);

  if (!model) return null;

  return (
    <FolderSelectorWithProjects
      model={model}
      viewComponent={FolderView}
      onClose={handleClose}
      onActionButtonClick={handleActionButtonClick}
      {...props}
    />
  );
};
