import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { ListItem } from 'src/models/list';
import { NodeTree } from 'src/models/node';
import { ContextMenu, ContextMenuItem, ContextMenuSeparator } from 'src/v2/components/contextMenu';
import { DropdownDelete } from 'src/v2/icons/DropdownDelete';
import { NewFolder } from 'src/v2/icons/NewFolder';
import { Open } from 'src/v2/icons/Open';
import { Rename } from 'src/v2/icons/Rename';
import { ContextMenuProps } from 'src/v2/features/contextMenu/types';
import {
  setFolderToRename,
  setParentFolderForNew,
} from 'src/v2/features/quantumNavigator/store/quantumNavigatorReducers';
import { useNavigateToConfirmRemoveFolder } from 'src/v2/features/sharedEntity';
import { closeModal } from 'src/v2/features/modal/store';
import { Folder } from 'src/models/folder';

import { useCreateOpenFolderFn } from '../quantumNavigator/hooks';

interface OwnProps {
  isRootFolder: boolean;
  isNewFolderDisabled: boolean;
  onRootFolderClick: ({ id: folderId }: ListItem) => void;
}
export const EntityFolderContextMenu: FC<ContextMenuProps & OwnProps> = ({
  closeOnClick,
  position,
  payload,
  children,
  isRootFolder,
  isNewFolderDisabled,
  onRootFolderClick,
}) => {
  const dispatch = useDispatch();
  const folder = payload as NodeTree<ListItem & Folder>;

  const onOpenSubFolder = useCreateOpenFolderFn(folder.data.id);

  const onOpenFolder = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>) => {
      if (isRootFolder) {
        onRootFolderClick(folder.data);
      } else {
        onOpenSubFolder(event);
      }
    },
    [folder.data, isRootFolder, onOpenSubFolder, onRootFolderClick],
  );

  const onNewFolder = useCallback(() => {
    dispatch(setParentFolderForNew(folder));
  }, [dispatch, folder]);

  const onRenameFolder = useCallback(() => {
    dispatch(setFolderToRename(folder));
    dispatch(closeModal());
  }, [dispatch, folder]);

  const navigateToConfirmRemoveFolder = useNavigateToConfirmRemoveFolder(folder);

  return (
    <ContextMenu {...position}>
      <ContextMenuItem
        icon={Open}
        text={i18n(translationKeys.contextMenu.open)}
        onClick={onOpenFolder}
        data-id="openFolder-menuButton"
      />
      <ContextMenuItem
        icon={NewFolder}
        text={i18n(translationKeys.contextMenu.newFolder)}
        onClick={closeOnClick(onNewFolder)}
        data-id="newFolder-menuButton"
        disabled={isNewFolderDisabled}
      />
      <ContextMenuItem
        icon={Rename}
        text={i18n(translationKeys.contextMenu.rename)}
        onClick={closeOnClick(onRenameFolder)}
        data-id="renameFolder-menuButton"
        disabled={isRootFolder}
      />
      <ContextMenuSeparator />
      {children}
      <ContextMenuSeparator />
      <ContextMenuItem
        icon={DropdownDelete}
        text={i18n(translationKeys.contextMenu.delete)}
        onClick={closeOnClick(navigateToConfirmRemoveFolder)}
        data-id="deleteFolder-menuButton"
        disabled={!folder.data.isRemovable || isRootFolder}
      />
    </ContextMenu>
  );
};
