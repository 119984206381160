import React, { FC } from 'react';

import { Navigator, isDocumentFolder, isRootFolder } from 'src/v2/features/quantumNavigator';
import { ListItem } from 'src/models/list';

const shouldExpandFolderView = (model: ListItem) => isRootFolder(model) || isDocumentFolder(model);

export const DocumentQuantumNavigator: FC = () => (
  <Navigator checkDefaultOpenState={shouldExpandFolderView} />
);
