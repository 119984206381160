/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ObjectSerialized } from 'src/common/types';
import { DefaultSliceStateWithData } from 'src/app/types';

const initialState: DefaultSliceStateWithData = {
  isLoading: false,
  error: '',
  data: [],
};

export const fetchParticipantsSlice = createSlice({
  name: 'fetchParticipants',
  initialState,
  reducers: {
    fetchParticipantsStart(state: DefaultSliceStateWithData): void {
      state.isLoading = true;
      state.error = '';
      state.data = [];
    },

    fetchParticipantsFailed(state: DefaultSliceStateWithData, action: PayloadAction<string>): void {
      state.isLoading = false;
      state.error = action.payload;
    },

    fetchParticipantsSuccess(
      state: DefaultSliceStateWithData,
      action: PayloadAction<ObjectSerialized>,
    ): void {
      state.isLoading = false;
      state.data = ObjectSerialized.dataToBaseObjects(action.payload.data);
    },
  },
});

export default fetchParticipantsSlice.reducer;
