import { createContext } from 'react';
import { noop, stubString } from 'lodash';

import { SharedSidebarContextValue } from './types';

export const SharedSidebarContext = createContext<SharedSidebarContextValue>({
  isAllowedToChat: false,
  isAllowedToManageParticipants: false,
  isAllowedToEditAccess: false,
  navigateToInviteParticipant: noop,
  navigateToEditParticipant: () => noop,
  navigateToQRCode: () => noop,
  getShownParticipantRole: stubString,
});

SharedSidebarContext.displayName = 'SharedSidebarContext';
