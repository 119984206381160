import React from 'react';
import { Box } from '@theme-ui/components';
import { isUndefined } from 'lodash';

import { EditorMode, FieldDiff, FieldType, FieldUpdateType } from 'src/models/editor';
import { EditorStub } from '../../../components/EditorStub/EditorStub';
import { editorStubContentConverter } from '../../../components/EditorStub/utils/EditorStubContentConverters';

const styles = {
  section: {
    mt: '12px',
    backgroundColor: '#efefef',
    borderRadius: '5px',
    position: 'relative',
    ':first-of-type': {
      mt: 0,
    },
    ':nth-of-type(odd)': {
      backgroundColor: 'white',
    },
  },
};

interface Props {
  content: string;
  diff?: FieldDiff[];
  index: number;
  highlight?: boolean;
  negotiable: boolean;
  onFieldUpdate: (
    type: FieldType,
    name: string,
    value: string,
    updateType: FieldUpdateType,
    id?: string,
  ) => void;
  isFieldUpdating: boolean;
}

const getVariant = (highlight?: boolean): string | undefined => {
  if (isUndefined(highlight)) return undefined;
  return highlight ? 'rejected' : 'accepted';
};

export const StaticSection = ({
  content,
  diff,
  highlight,
  negotiable,
  onFieldUpdate,
  isFieldUpdating,
  index,
}: Props): JSX.Element => {
  return (
    <Box sx={styles.section} data-type="static-section">
      {/*<Editor*/}
      {/*   index={index}*/}
      {/*   key={String(negotiable) + content + JSON.stringify(diff)}*/}
      {/*   mode={negotiable ? EditorMode.FieldsEditable : EditorMode.ReadOnly}*/}
      {/*   content={content}*/}
      {/*   diff={diff}*/}
      {/*   onFieldUpdate={onFieldUpdate}*/}
      {/*   variant={getVariant(highlight)}*/}
      {/*   isFieldUpdating={isFieldUpdating}*/}
      {/* />*/}
      <EditorStub
        index={index}
        content={editorStubContentConverter(content)}
        mode={negotiable ? EditorMode.FieldsEditable : EditorMode.ReadOnly}
        diff={diff}
        variant={getVariant(highlight)}
        isFieldUpdating={isFieldUpdating}
        onFieldUpdate={onFieldUpdate}
      />
    </Box>
  );
};
