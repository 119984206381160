import { isArray } from 'lodash';

import { Chips } from 'src/v2/components/Form/Chips';
import { ChipsProps } from 'src/v2/components/Form/types';

import { withReduxForm } from './withReduxForm';

const PureEmails = ({ value, ...other }: ChipsProps) => (
  <Chips
    {...other}
    value={isArray(value) ? value : []}
    inputProps={{ placeholder: 'Emails' }}
    validationRegex={/^[^@\s]+@[^@\s]+\.[^@\s]+$/}
    addKeys={[13, 32, 188]} // Enter, space and comma
    onlyUnique
    addOnBlur
  />
);

export const Emails = withReduxForm<ChipsProps>(PureEmails);
