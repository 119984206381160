import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { persistReducer } from 'redux-persist';
import PersistStorage from 'redux-persist/lib/storage';

import { RootState } from 'src/app/types';
import createFromTemplateReducer from 'src/v2/features/createFromTemplate/reducer';
import { authReducer } from 'src/v2/features/auth';
import signUp from 'src/v2/features/signUp/store';
import layoutReducer from 'src/v2/features/layout/layoutSlice';
import documentListReducer from 'src/v2/features/documentList/documentListSlice';

import documentDetailsReducer from 'src/v2/features/documentDetails/documentDetailsSlice';

import objectsStorageReducer from 'src/v2/features/objectsStorage/objectsStorageSlice';
import { organizationReducer } from 'src/v2/features/organization';
import { profileReducer } from 'src/v2/features/profile';
import { templatesReducer } from 'src/v2/features/template/reducer';
import documentParticipantsReducer from 'src/v2/features/documentParticipants/fetchParticipantsStore';
import documentWorkflowReducer from 'src/v2/features/documentWorkflow/documentWorkflow';
import uploadAttachments from 'src/v2/features/document/uploadAttachments/uploadAttachmentsStore';
import documentAttachmentsList from 'src/v2/features/document/attachmentsList/attachmentsListStore';
import documentSidebarUpload from 'src/v2/features/documentSidebar/documentSidebarUpload/documentSidebarUploadStore';
import { reducer as inviteParticipantReducer } from 'src/v2/features/documentParticipants/inviteParticipantStore';
import { reducer as updateParticipantReducer } from 'src/v2/features/documentParticipants/updateParticipantStore';
import { reducer as removeParticipantReducer } from 'src/v2/features/documentParticipants/removeParticipantStore';
import notificationReducer from 'src/v2/features/notifications/notificationsStore';
import { reducer as chatReducer } from 'src/v2/features/chat/store';
import { searchReducer } from 'src/v2/features/search';
import { billingReducer } from 'src/v2/features/billing';
import { checkoutReducer } from 'src/v2/features/checkout';
import { contextMenuReducer } from 'src/v2/features/contextMenu/store';
import { signatureReducer } from 'src/v2/features/signature';
import { hankoSignatureReducer } from 'src/v2/features/hankoSignature';
import { commonReducer } from 'src/common/store';
import { sortReducer } from 'src/common/sortStore';
import { quantumNavigatorReducer } from 'src/v2/features/quantumNavigator/store/quantumNavigatorReducers';
import { modalReducer as modalV2 } from 'src/v2/features/modal';
import { folderSelectorReducer } from 'src/v2/features/folderSelector/store';
import { objectsListReducer } from 'src/v2/features/objectsList/store';
import { templateSidebarUploadReducer } from 'src/v2/features/template/TemplateSidebar/templateSidebarUpload/templateSidebarUploadStore';
import { templatesListReducer } from 'src/v2/features/sharedEntity/templatesListStore';
import { projectReducer } from 'src/v2/features/project/store/projectReducers';
import { accountRecoveryReducer } from 'src/v2/features/accountRecovery/accountRecoveryStore';
import { qrCodeAccountRecoveryReducer } from 'src/v2/features/qrCodeAccountRecovery/qrCodeAccountRecoveryStore';
import { networkReducer } from 'src/v2/features/network/store';

const authPersistConfig = {
  key: 'auth',
  storage: PersistStorage,
};

const rootReducer = combineReducers<RootState>({
  form: formReducer,
  layout: layoutReducer,
  objectsStorage: objectsStorageReducer,
  // TODO: Move all document related reducers under document reducer as subreducers
  documentList: documentListReducer,
  documentDetails: documentDetailsReducer,
  documentWorkflow: documentWorkflowReducer,
  documentParticipants: documentParticipantsReducer,
  documentInviteParticipant: inviteParticipantReducer,
  documentUpdateParticipant: updateParticipantReducer,
  documentRemoveParticipant: removeParticipantReducer,
  auth: persistReducer(authPersistConfig, authReducer),
  organization: organizationReducer,
  profile: profileReducer,
  toastr: toastrReducer,
  templates: templatesReducer,
  createFromTemplate: createFromTemplateReducer,
  search: searchReducer,
  uploadAttachments,
  documentAttachmentsList,
  documentSidebarUpload,
  chat: chatReducer,
  notifications: notificationReducer,
  signUp,
  billing: billingReducer,
  checkout: checkoutReducer,
  contextMenu: contextMenuReducer,
  signature: signatureReducer,
  hankoSignature: hankoSignatureReducer,
  common: commonReducer,
  sort: sortReducer,
  quantumNavigator: quantumNavigatorReducer,
  modalV2,
  folderSelector: folderSelectorReducer,
  objectsList: objectsListReducer,
  templateSidebarUpload: templateSidebarUploadReducer,
  templatesList: templatesListReducer,
  project: projectReducer,
  accountRecovery: accountRecoveryReducer,
  qrCodeAccountRecovery: qrCodeAccountRecoveryReducer,
  network: networkReducer,
});

export default rootReducer;
