import { get, isUndefined } from 'lodash';

export interface Env {
  PROJECT_SERVICE_URI: string;
  AUTH_SERVICE_URI: string;
  WEBSITE_HOST_URI: string;
  SUPPORT_WEBSITE_URI: string;
  CHAT_SOCKET_GATEWAY: string;
  CHAT_SOCKET_DOMAINS: string;
  NOTIFICATIONS_SOCKET_GATEWAY: string;
  NOTIFICATIONS_SOCKET_DOMAINS: string;
  FOLDER_SOCKET_GATEWAY: string;
  FOLDER_SOCKET_DOMAINS: string;
  SENTRY_DSN: string;
  ENABLE_SENTRY: boolean;
  DOCUMENT_SOCKET_GATEWAY: string;
  DOCUMENT_SOCKET_DOMAINS: string;
  WORKFLOW_SOCKET_GATEWAY: string;
  WORKFLOW_SOCKET_DOMAINS: string;
  FILE_UPLOAD_LIMIT: number;
  STRIPE_API_KEY: string;
  PSPDFKIT_LICENSE_KEY: string;
  REPLACE_DOMAIN: string;
}

export const getEnvVar = (name: string): string => {
  const v = get(process.env, name);
  if (!isUndefined(v)) {
    return v;
  }

  throw new Error(`Environment variable '${name}' is not defined`);
};

export const env: Env = {
  PROJECT_SERVICE_URI: getEnvVar('REACT_APP_BACKEND_API_URL'),
  AUTH_SERVICE_URI: getEnvVar('REACT_APP_AUTH_SERVICE_URI'),
  WEBSITE_HOST_URI: getEnvVar('REACT_APP_WEBSITE_HOST_URI'),
  SUPPORT_WEBSITE_URI: getEnvVar('REACT_APP_SUPPORT_WEBSITE_URI'),
  CHAT_SOCKET_GATEWAY: getEnvVar('REACT_APP_CHAT_SOCKET_GATEWAY'),
  CHAT_SOCKET_DOMAINS: getEnvVar('REACT_APP_CHAT_SOCKET_DOMAINS'),
  NOTIFICATIONS_SOCKET_GATEWAY: getEnvVar('REACT_APP_NOTIFICATIONS_SOCKET_GATEWAY'),
  NOTIFICATIONS_SOCKET_DOMAINS: getEnvVar('REACT_APP_NOTIFICATIONS_SOCKET_DOMAINS'),
  FOLDER_SOCKET_GATEWAY: getEnvVar('REACT_APP_FOLDER_SOCKET_GATEWAY'),
  FOLDER_SOCKET_DOMAINS: getEnvVar('REACT_APP_FOLDER_SOCKET_DOMAINS'),
  SENTRY_DSN: getEnvVar('REACT_APP_SENTRY_DSN'),
  ENABLE_SENTRY: Boolean(getEnvVar('REACT_APP_ENABLE_SENTRY')),
  DOCUMENT_SOCKET_GATEWAY: getEnvVar('REACT_APP_DOCUMENT_SOCKET_GATEWAY'),
  DOCUMENT_SOCKET_DOMAINS: getEnvVar('REACT_APP_DOCUMENT_SOCKET_DOMAINS'),
  WORKFLOW_SOCKET_GATEWAY: getEnvVar('REACT_APP_WORKFLOW_SOCKET_GATEWAY'),
  WORKFLOW_SOCKET_DOMAINS: getEnvVar('REACT_APP_WORKFLOW_SOCKET_DOMAINS'),
  FILE_UPLOAD_LIMIT: parseInt(getEnvVar('REACT_APP_FILE_UPLOAD_LIMIT'), 10),
  STRIPE_API_KEY: getEnvVar('REACT_APP_STRIPE_API_KEY'),
  PSPDFKIT_LICENSE_KEY: getEnvVar('REACT_APP_PSPDFKIT_LICENSE_KEY'),
  REPLACE_DOMAIN: getEnvVar('REACT_APP_REPLACE_DOMAIN'),
};
