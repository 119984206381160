import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ObjectBase, ObjectSerialized } from 'src/common/types';

import { OrganizationState } from 'src/app/types';
import { AvatarPayload, FindUser } from '../types';
import { SetOrganizationData } from './types';
import {
  EditUserInOrganizationActionPayload,
  InviteUserToOrganizationActionPayload,
} from 'src/v2/boundary/actionPayload/organization';

type SetCurrentOrganizationPayload = string;
type SetCurrentOrganizationOwnerPayload = string;

const organizationInitialState: OrganizationState = {
  isLoading: true,
  error: null,
  data: null,
  users: [],
  foundUser: null,
  currentOrgId: null,
  currentOrgOwnerId: null,
};

const organizationSlice = createSlice({
  name: 'organization',
  initialState: organizationInitialState,
  reducers: {
    startLoading: (state): void => {
      state.isLoading = true;
    },
    stopLoading: (state): void => {
      state.isLoading = false;
    },
    emitError: (state: OrganizationState, action: PayloadAction<string>): void => {
      state.error = action.payload;
    },
    fillOrganizationData: (state, { payload }: PayloadAction<ObjectBase[]>): void => {
      state.data = payload;
    },
    fillFoundUser: (state, { payload }: PayloadAction<ObjectSerialized>): void => {
      // eslint-disable-next-line prefer-destructuring
      state.foundUser = ObjectSerialized.dataToBaseObjects(payload.data)[0];
    },
    removeFoundUserReducer: (state): void => {
      state.foundUser = null;
    },
    setCurrentOrganization: (
      state,
      { payload }: PayloadAction<SetCurrentOrganizationPayload>,
    ): void => {
      state.currentOrgId = payload;
    },
    setCurrentOrganizationOwner: (
      state,
      { payload }: PayloadAction<SetCurrentOrganizationOwnerPayload>,
    ): void => {
      state.currentOrgOwnerId = payload;
    },
  },
});

export const {
  startLoading,
  stopLoading,
  emitError,
  fillOrganizationData,
  fillFoundUser,
  removeFoundUserReducer,
  setCurrentOrganization,
  setCurrentOrganizationOwner,
} = organizationSlice.actions;

export const findUserByFiled = createAction<FindUser>('organization/findUserByFiled');
export const clearFoundUser = createAction('organization/clearFoundUser');
export const inviteUser =
  createAction<InviteUserToOrganizationActionPayload>('organization/inviteUser');
export const updateInviteUser = createAction<EditUserInOrganizationActionPayload>(
  'organization/updateInviteUser',
);
export const removeUser = createAction<string>('organization/removeUser');
export const setOrganizationData = createAction<SetOrganizationData>(
  'organization/setOrganizationData',
);
export const setOrganizationLogo = createAction<AvatarPayload>('organization/setOrganizationLogo');

export const organizationReducer = organizationSlice.reducer;
