import { createSelector } from '@reduxjs/toolkit';
import { FormState } from 'redux-form';

import { RootState } from 'src/app/types';

const getState = (state: RootState) => state.billing;
const getBillingInfoFormState = (state: RootState) => state.form.billingInfo;

export const getSubscriptionStatus = createSelector(getState, (state) => state.subscriptionStatus);
export const getSubscriptionBasePrice = createSelector(getState, (state) => state.basePrice);
export const getSubscriptionSalesTax = createSelector(getState, (state) => state.salesTax);
export const getSubscriptionTotalPrice = createSelector(getState, (state) => state.totalPrice);
export const getBillingInfo = createSelector(getState, (state) => state.billingInfo);
export const getIsLoading = createSelector(getState, (state) => state.isLoading);
export const getActivePlan = createSelector(getBillingInfo, (state) =>
  state ? state.activePlan : null,
);
export const getSubscribedPlan = createSelector(getBillingInfo, (state) =>
  state ? state.subscribedPlan : null,
);
export const getSubscribedPeriod = createSelector(getBillingInfo, (state) =>
  state ? state.billingPeriod : null,
);
export const getLatestInvoiceId = createSelector(getBillingInfo, (state) =>
  state ? state.latestInvoiceId : null,
);
export const getPaymentCard = createSelector(getBillingInfo, (state) =>
  state ? state.card : null,
);
export const getSeats = createSelector(getBillingInfo, (state) => state.seat);

export const getShowUpdateModal = createSelector(getState, (state) => state.showUpgradeModal);

export const getSelectedPlanAndPeriod = createSelector(
  getBillingInfoFormState,
  (state: FormState) => state && state.values && state.values.planAndPeriod,
);
