import React, { MouseEvent } from 'react';
import cn from 'classnames';

export interface Props {
  className?: string;
  isActive: boolean;
  onClose?: (e: MouseEvent) => void;
}

export const ModalView: React.FC<Props> = ({ children, className, isActive, onClose }) => (
  <div className={cn('c-modal', className, isActive && 'c-modal--active')}>
    <div className="c-modal__wrap">
      <div className="c-modal__content">
        {onClose && <div className="c-modal__close" onClick={onClose} />}
        {children}
      </div>
    </div>
  </div>
);
