import { ReactElement, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { SignUpLight } from 'src/v2/features/signUp/SignUpLight';
import { SignUpStepComponent, useSignUpProps } from 'src/v2/features/signUp/pages/hooks';
import { WizardStepV2 } from 'src/models/signUp';
import { setStep } from 'src/v2/features/signUp/store';

export const useSignupFormLightComponent = (): SignUpStepComponent => {
  const dispatch = useDispatch();
  const { accountType } = useSignUpProps();

  const handleLoginFlow = useCallback(() => {
    dispatch(
      setStep({
        step: WizardStepV2.StartInvite,
        stepNum: 1,
        maxSteps: 1,
      }),
    );
  }, [dispatch]);

  return [
    (): ReactElement => <SignUpLight accountType={accountType} onLogInFlow={handleLoginFlow} />,
    {},
  ];
};
