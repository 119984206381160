export enum EditorMode {
  ContentEditable,
  FieldsEditable,
  ReadOnly,
}

export interface FieldDiff {
  name: string;
  newValue: string;
  editorFieldId?: string;
}

export interface UploadData {
  hash: string;
  key: string;
  url: string;
}

export enum FieldType {
  Text,
  Checkbox,
}

export enum FieldUpdateType {
  Accept = 'accept',
  Reject = 'reject',
  Modify = 'modify',
}

export enum Align {
  Left = 'left',
  Center = 'center',
  Right = 'right',
  Justify = 'justify',
}
