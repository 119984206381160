export enum SortedFields {
  SubTypes = 'subTypes',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Title = 'title',
  Description = 'description',
  State = 'state',
}

export enum SortParams {
  SortBy = 'sortBy',
  SortDirection = 'sortDirection',
}

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export interface SortUrlParams {
  sortBy: string;
  sortDirection: string;
}
