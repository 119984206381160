import { CompleteObject } from 'src/common/types';
import { PaperModel } from 'src/models/paper';
import { PaperPayload } from 'src/v2/boundary/actionPayload/paperActions';
import { CardPaperModel } from 'src/models/card';
import { TermModel } from 'src/models/term';
import { LockModel } from 'src/models/lock';

export enum DocumentType {
  announcement = 'Announcement',
  application = 'Application',
  bill = 'Bill',
  certificate = 'Certificate',
  collaboration = 'Collaboration',
  communication = 'Communication',
  compliance = 'Compliance',
  document = 'Document',
  instruction = 'Instruction',
  insurance = 'Insurance',
  letter = 'Letter',
  memorandum = 'Memorandum',
  newsletter = 'Newsletter',
  other = 'Other',
  receipt = 'Receipt',
  record = 'Record',
  report = 'Report',
  script = 'Script',
  specification = 'Specification',
  strategy = 'Strategy',
  warranty = 'Warranty',
}

export type Document = PaperModel<DocumentType, DocumentRole>;
export type DocumentCardPaperModel = CardPaperModel<DocumentType, DocumentRole>;

export interface DocumentPayload extends PaperPayload {
  subType?: DocumentType;
}

export interface DocumentContent {
  id: string;
  title: string;
  sections: Section[];
  locks: EntityLock[];
}

export interface Section extends TermModel {
  index?: number;
  isLocked?: boolean;
  isPending?: boolean;
}

export interface EntityLock extends LockModel {
  isMine?: boolean;
}

export interface Field extends CompleteObject {
  name: string;
  value: string;
  valueChangedAt: string;
  editorFieldID?: string;
}

export enum DocumentRole {
  Owner = 'document_owner',
  Manager = 'document_manager',
  Editor = 'document_editor',
  Acknowledger = 'document_acknowledger',
  Commentator = 'document_commentator',
  Viewer = 'document_viewer',
  QRViewer = 'document_qr_viewer',
  SharedViewer = 'document_shared_viewer',
  Downloader = 'document_downloader',
  DocumentSharer = 'document_sharer',
  DocumentSharerDownloader = 'document_sharer_downloader',
  DocumentClippedViewer = 'document_clipped_viewer',
}

export const DefaultDocumentRole = DocumentRole.Viewer;
