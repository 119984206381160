import React, { FC } from 'react';
import { isNil } from 'lodash';

import { ImageEditor, ImageEditorProps } from 'src/v2/components/ImageEditor/ImageEditor';

import './style.css';

const getRoundedCanvas = (sourceCanvas: HTMLCanvasElement): HTMLCanvasElement => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const { width, height } = sourceCanvas;

  canvas.width = width;
  canvas.height = height;

  if (isNil(context)) return sourceCanvas;

  context.imageSmoothingEnabled = true;
  context.drawImage(sourceCanvas, 0, 0, width, height);
  context.globalCompositeOperation = 'destination-in';
  context.beginPath();
  context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
  context.fill();

  return canvas;
};

export const RoundedImageEditor: FC<ImageEditorProps> = (props) => {
  return (
    <ImageEditor className="rounded" onTransform={getRoundedCanvas} aspectRatio={1} {...props} />
  );
};
