import { FC } from 'react';
import cn from 'classnames';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { useToggle } from 'src/common/hooks/useToggle';
import { formatDate } from 'src/instruments';
import { MetadataViewRow } from 'src/v2/features/metadata';

import { TemplateMetaProps } from './types';

interface Props extends TemplateMetaProps {
  isAllowedToEdit: boolean;
  onEdit: () => void;
}

export const TemplateMetadataView: FC<Props> = ({
  title,
  description = '',
  createdAt,
  updatedAt,
  type,
  subType,
  owner,
  industry,
  country,
  province,
  city,
  isAllowedToEdit,
  onEdit,
}) => {
  const [showMore, toggle] = useToggle();

  return (
    <div className="c-employmenеt">
      <div className="c-employmenеt__row c-employmenеt__header">
        <div className="c-title c-title--uppercase">{title}</div>
        {isAllowedToEdit && (
          <div data-type="edit-box" className="c-title c-title__edit" onClick={onEdit}>
            {i18n(translationKeys.buttons.edit)}
          </div>
        )}
      </div>

      <div className="c-employmenеt__row">
        <div
          className={cn(
            'c-employmenеt__description',
            showMore && 'c-employmenеt__description--active',
          )}
        >
          <span className="c-employmenеt__title">{i18n(translationKeys.sorting.description)} </span>
          <span data-type="description-field" className="c-employmenеt__text">
            {description}
          </span>
        </div>
        {description.length >= 24 && (
          <div className="c-employmenеt__row c-employmenеt--center">
            <div className="c-more" onClick={toggle}>
              {showMore
                ? i18n(translationKeys.forms.item.showLess)
                : i18n(translationKeys.forms.item.showMore)}
            </div>
          </div>
        )}
      </div>

      <MetadataViewRow
        dataType="dateCreated-field"
        label={i18n(translationKeys.sorting.dateCreated)}
      >
        {createdAt && formatDate(createdAt)}
      </MetadataViewRow>
      <MetadataViewRow
        dataType="dateUpdated-field"
        label={i18n(translationKeys.forms.item.lastUpdated)}
      >
        {updatedAt && formatDate(updatedAt)}
      </MetadataViewRow>
      <MetadataViewRow
        dataType="documentType-field"
        label={i18n(translationKeys.forms.template.info.type)}
      >
        {type}
      </MetadataViewRow>
      <MetadataViewRow
        dataType="documentSubtype-field"
        label={i18n(translationKeys.forms.template.info.subType)}
      >
        {subType}
      </MetadataViewRow>

      <MetadataViewRow label={i18n(translationKeys.forms.template.info.author)}>
        {owner}
      </MetadataViewRow>
      <MetadataViewRow label={i18n(translationKeys.forms.item.industry)}>
        {industry}
      </MetadataViewRow>
      <MetadataViewRow label={i18n(translationKeys.forms.item.country)}>{country}</MetadataViewRow>
      <MetadataViewRow label={i18n(translationKeys.forms.item.stateProvince)}>
        {province}
      </MetadataViewRow>
      <MetadataViewRow label={i18n(translationKeys.forms.item.city)}>{city}</MetadataViewRow>
    </div>
  );
};
