import { FC } from 'react';
import cn from 'classnames';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

import { SignatureItem } from './SignatureItem';
import { SignatureListProps } from '../../types';
import styles from './SignatureList.module.css';

export const SignatureList: FC<SignatureListProps> = ({
  label,
  className,
  signatures,
  userName,
  defaultSignatureId,
  onSelectDefault,
  onCreateNew,
  onEdit,
  children,
}) => {
  if (!signatures) return null;

  return (
    <>
      {label && (
        <div className="c-signature__caption c-signature--mt-24">
          <div className="c-title c-title--color-theme c-title--fs-24">{label}</div>
        </div>
      )}
      <div
        className={cn('c-signature__default', styles.container, className)}
        data-type="default-signature-selector"
      >
        <div className="c-signature__column">
          {signatures.map((signature) => (
            <SignatureItem
              key={signature.signature_id}
              id={signature.signature_id}
              image={signature.signature_file_link}
              legalName={signature.legal_name || userName}
              checked={signature.signature_id === defaultSignatureId}
              onSelectDefault={onSelectDefault}
              onEdit={onEdit}
            />
          ))}
          <div className="c-signature__create">
            <div className="c-signature__create--new" onClick={onCreateNew}>
              {i18n(translationKeys.forms.signature.createNewSignature)}
            </div>
          </div>
        </div>
        {children}
      </div>
    </>
  );
};
