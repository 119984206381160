import React from 'react';

import { IconOption, Intent } from 'src/v2/components/Card';
import { EntityCard } from 'src/v2/features/sharedEntity';
import { EntityCardProps } from 'src/v2/features/sharedEntity/types';
import { ContextMenuBook } from 'src/app/types';
import { useContextMenu } from 'src/v2/features/contextMenu';
import { TemplateRole } from 'src/models/template';

interface OwnProps {
  canDeleteTemplate: boolean;
}

export const TemplateCard: React.FC<
  Omit<EntityCardProps<TemplateRole>, 'intent' | 'icon'> & OwnProps
> = (props) => {
  const { role } = props.data;
  const contextMenuHandler = useContextMenu(ContextMenuBook.Template, props.data);

  let icon: IconOption = IconOption.View;
  switch (role) {
    case TemplateRole.Owner:
      icon = IconOption.UserEdit;
      break;
  }

  return (
    <EntityCard
      {...props}
      intent={Intent.YellowDark}
      showSignedStatus
      canDeleteEntity={props.canDeleteTemplate}
      onContextMenu={contextMenuHandler}
      icon={icon}
    />
  );
};
