import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation as i18next } from 'react-i18next';
import { isNil } from 'lodash';

import { RootState } from 'src/app/types';
import { StoredUser } from 'src/shared/auth/types';
import { fetchOrganization } from 'src/shared/organization/actions';
import { getPreferredLanguage } from './store';

export const useAuthAndFillOrganization = (): StoredUser | null => {
  const { token, companyId, userId } = useSelector((store: RootState) => store.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (token && userId && companyId) {
      dispatch(fetchOrganization());
    }
  }, [dispatch, token, companyId, userId]);

  return token && userId && companyId ? { token, companyId, userId } : null;
};

export const useSubscribeToLanguageChangeEffct = () => {
  const { i18n } = i18next();
  const language = useSelector(getPreferredLanguage);

  useEffect(() => {
    if (!isNil(language)) {
      i18n.changeLanguage(language);
    }
  }, [i18n, language]);
};
