import React, { FC } from 'react';

import { BaseHeader, Search } from 'src/v2/modules/layout/header/index';
import { HeaderNav } from '../components/HeaderNav';
import { NavProps } from '../../types';

export const SmallHeader: FC<NavProps> = ({ onHamburgerClick }) => {
  return (
    <BaseHeader className="l-header--quantum">
      <Search />

      <HeaderNav onHamburgerClick={onHamburgerClick} />
    </BaseHeader>
  );
};
