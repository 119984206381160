import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

import { logoutAsync } from './store/authStore';

export const useLogoutHook = (): [() => void] => {
  const dispatch = useDispatch();
  const handleLogout = useCallback((): void => {
    dispatch(logoutAsync());
  }, [dispatch]);

  return [handleLogout];
};
