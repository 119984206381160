import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from 'src/utils/hooks';
import { openRecoveryModal } from './accountRecoveryStore';

export const useAccountRecoveryModals = () => {
  const dispatch = useDispatch();

  const query = useQuery();
  const modal = query.get('modal');
  const step = query.get('step');

  const openVerificationModal = useCallback(() => {
    dispatch(openRecoveryModal.verification());
  }, [dispatch]);

  const openOTPModal = useCallback(() => {
    dispatch(openRecoveryModal.otp());
  }, [dispatch]);

  const openResetPasswordModal = useCallback(() => {
    dispatch(openRecoveryModal.resetPassword());
  }, [dispatch]);

  const detect = useCallback(() => {
    if (modal === 'recovery' && step === 'password_reset') {
      openResetPasswordModal();
    }
  }, [modal, openResetPasswordModal, step]);

  return {
    openVerificationModal,
    openOTPModal,
    openResetPasswordModal,
    detect,
  };
};
