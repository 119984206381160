import { ChangeEvent, PropsWithChildren, useCallback, useEffect } from 'react';
import { Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { email, required } from 'redux-form-validators';
import { useDispatch, useSelector } from 'react-redux';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { Input } from 'src/v2/features/reduxForm';
import { InviteUserData } from 'src/api/documents';
import { Divider } from 'src/v2/components/Divider';
import { RootState } from 'src/app/types';
import { inviteUser } from 'src/v2/features/documentParticipants';
import { useAuthAndFillOrganization } from 'src/v2/features/profile/hooks';
import { getIsInviting } from 'src/v2/features/documentParticipants/inviteParticipantStore';

import { Button, Type } from '../button';
import { useAutofill } from './hooks';
import { UserFieldset } from './UserFieldset';
import { InviteParticipantActionPayload } from 'src/v2/boundary/actionPayload/inviteParticipant';
import { validateEmail } from 'src/utils/validate';

type FormProps = InviteUserData;

const FORM_ID = 'inviteUser';
export const formSelector = formValueSelector(FORM_ID);

export interface Props {
  onClose: () => void;
  documentId: string;
  predefinedConditionRequired: boolean;
}

const ParticipantFormComponent = (
  props: PropsWithChildren<Props & InjectedFormProps<FormProps, Props>>,
) => {
  const {
    invalid,
    pristine,
    change,
    onClose,
    handleSubmit,
    documentId,
    submitting,
    predefinedConditionRequired,
    children,
  } = props;

  // FIXME: Set real loading state
  const isInviting = useSelector(getIsInviting);

  const dispatch = useDispatch();
  const conditionRequired: boolean = useSelector((state: RootState) =>
    formSelector(state, 'conditionRequired'),
  );

  const [handleChangeEmail, isUserFound] = useAutofill({
    ...props,
    onClose,
  });
  const submitForm = useCallback(
    (data: InviteParticipantActionPayload['data']) => {
      dispatch(inviteUser({ data, documentId }));
    },
    [dispatch, documentId],
  );

  useEffect(() => {
    if (conditionRequired !== predefinedConditionRequired)
      change('conditionRequired', predefinedConditionRequired);
  }, [change, conditionRequired, predefinedConditionRequired]);

  useAuthAndFillOrganization();

  return (
    <div className="c-fieldset" data-id="documentDetails-form-inviteParticipant">
      <div className="c-title c-title--uppercase c-title--lh-1 c-title--has-image">
        {i18n(translationKeys.forms.inviteUser.title)}
      </div>
      <Field
        component={Input}
        name="email"
        type="email"
        placeholder={i18n(translationKeys.forms.inviteUser.email)}
        validate={[email(), validateEmail]}
        onChange={(e: ChangeEvent<HTMLInputElement>): void => handleChangeEmail(e)}
        mb8
      />
      <UserFieldset isDisabled={isUserFound} />
      <Field
        component={Input}
        name="funcRole"
        placeholder={i18n(translationKeys.forms.inviteUser.funcRole)}
        mb8
        validate={[required()]}
      />
      <Divider />

      {children}

      <Divider />
      <Button
        className="h-uppercase"
        type="submit"
        disabled={invalid || submitting || pristine}
        isLoading={isInviting}
        onClick={handleSubmit(submitForm)}
      >
        {i18n(translationKeys.buttons.sendInvite)}
      </Button>
      <Button
        className="h-uppercase"
        actionType={Type.Danger}
        disabled={isInviting}
        onClick={onClose}
      >
        {i18n(translationKeys.buttons.cancel)}
      </Button>
    </div>
  );
};

export const InviteParticipantForm = reduxForm<FormProps, Props>({
  form: FORM_ID,
  enableReinitialize: true,
})(ParticipantFormComponent);
