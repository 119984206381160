import React from 'react';
import { isUndefined } from 'lodash';
import { useParams } from 'react-router';

import {
  createDocumentFromFileInProject,
  createDocumentFromTemplateInProject,
  createDocumentInProject,
} from 'src/v2/features/project/store/projectReducers';
import { AbstractCreate } from 'src/v2/features/document/DocumentSidebar/pages/AbstractCreate';
import { createConvertFormPayloadToDTO } from 'src/v2/features/document/DocumentSidebar/utils';

export const CreateDocument: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  if (isUndefined(id)) return null;

  return (
    <AbstractCreate
      convertFormPayloadToDTO={createConvertFormPayloadToDTO(id)}
      createDocument={createDocumentInProject}
      createDocumentFromFile={createDocumentFromFileInProject}
      createDocumentFromTemplate={createDocumentFromTemplateInProject}
    />
  );
};
