import React, { FC } from 'react';

import { AnonymousHeaderNav, OneRowHeader, HeaderItem } from 'src/v2/modules/layout';
import { NavProps } from 'src/v2/modules/layout/types';

export const WizardHeader: FC<NavProps> = (props) => {
  return (
    <OneRowHeader>
      <HeaderItem />
      <AnonymousHeaderNav {...props} />
    </OneRowHeader>
  );
};
