import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@theme-ui/components';

import { ContractRole, ContractType } from 'src/models/contract';
import { Field, Section } from 'src/models/document';
import { FieldDiff } from 'src/models/editor';
import { useGetEntityById, useGetUnmatchingSections } from 'src/v2/features/sharedEntity/hooks';
import {
  isOwnerSigned as getIsOwnerSigned,
  isCounterpartySigned as getIsCounterpartySigned,
  updateField,
  getIsFieldUpdating,
  updateFields,
} from 'src/v2/features/documentDetails/documentDetailsSlice';
import { isInWork as getIsInWork } from 'src/v2/features/documentWorkflow';
import { StaticSections } from 'src/v2/features/entitySections/StaticSections';
import { SidebarParticipantEntity } from 'src/v2/entities/participants';
import { UserSignature } from 'src/models/profile';

import { useCanEditContract } from '../permissions/contractPermissions/contractPermissionsHooks';
import { ContractSideUsers } from './ContractSideUsers';
import { ContractSign } from './ContractSign';
import { PaperParticipantsAffiliationType } from 'src/v2/boundary/storageModels/paper/paperParticipant';
import { UpdateFieldPayload, UpdateFieldsPayload } from '../../documentDetails/types';
import { isPropagateFieldsValues } from 'src/v2/components/EditorCore/utils';
import { has } from 'lodash';

const styles = {
  side: {
    flex: 1,
    p: '25px',
  },

  yourSide: {
    borderRadius: '7px',
    backgroundColor: '#f7f1e1',
  },

  sections: {
    mt: '8px',
  },
};

interface Props {
  id: string;
  members: SidebarParticipantEntity[];
  sections: Section[];
  negotiable: boolean;
  fields?: Field[];
  diff?: FieldDiff[];
  unmatchingSections?: number[];
  signatures: UserSignature[];
  type: PaperParticipantsAffiliationType;
}

export const ContractSide: React.FC<Props> = ({
  id,
  members,
  sections,
  fields,
  diff,
  negotiable,
  signatures,
  type,
}) => {
  const dispatch = useDispatch();

  const contract = useGetEntityById<ContractType, ContractRole>(id);
  const isFieldUpdating = useSelector(getIsFieldUpdating);
  const isAllowedToEdit = useCanEditContract(contract);
  const isOwnerSigned = useSelector(getIsOwnerSigned);
  const isInWork = useSelector(getIsInWork);
  const isCounterpartySigned = useSelector(getIsCounterpartySigned);
  const isSignedByBothSides = isOwnerSigned && isCounterpartySigned;
  const isSideNegotiable = (mySide: boolean) =>
    mySide && !isSignedByBothSides && isAllowedToEdit && isInWork;
  const unmatchingSections = useGetUnmatchingSections();

  const handleUpdateField = useCallback(
    (data: UpdateFieldPayload | UpdateFieldsPayload) => {
      return new Promise<{}>((resolve, reject) => {
        if (!isFieldUpdating) {
          if (isPropagateFieldsValues() && has(data, 'fields')) {
            dispatch(updateFields(data)).then(resolve).catch(reject);
          } else {
            dispatch(updateField(data)).then(resolve).catch(reject);
          }
        }
        resolve({});
      });
    },
    [dispatch, isFieldUpdating],
  );

  const classNames = negotiable ? 'c-letter--bg-yellow c-letter--border-radius' : '';

  return (
    <div className={`c-letter__section ${classNames}`}>
      <ContractSideUsers members={members} id={id} />
      <Box sx={styles.sections}>
        <StaticSections
          key={diff?.length}
          sections={sections}
          fields={fields}
          diff={diff}
          negotiable={isSideNegotiable(negotiable)}
          unmatchingSections={unmatchingSections}
          onFieldUpdate={handleUpdateField}
          isFieldUpdating={isFieldUpdating}
        />
      </Box>
      <ContractSign type={type} negotiable={negotiable} signatures={signatures} />
    </div>
  );
};
