import { ReactElement } from 'react';
import { Routes, Navigate, Route } from 'react-router-dom';
import { isNull } from 'lodash';

import { book } from 'src/app/book';
import { SignUpStepComponent, useSignUpProps } from 'src/v2/features/signUp/pages/hooks';

export const useCompleteLightComponent = (): SignUpStepComponent => {
  const { entityType, entityId } = useSignUpProps();
  return [
    (): ReactElement =>
      !isNull(entityType) && !isNull(entityId) ? (
        <Routes>
          <Route
            path="*"
            element={
              <Navigate replace to={book.signup.generateRedirectPath(entityType, entityId, true)} />
            }
          />
        </Routes>
      ) : (
        <></>
      ),
    {},
  ];
};
