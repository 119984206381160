import { FC, useCallback, useState } from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { useDispatch } from 'react-redux';

import { PhoneNumber } from 'src/v2/components/PhoneNumber';
import { normalizeNumbers } from 'src/utils/normalize';
import { validateAuthForm } from 'src/utils/validate';
import { Input } from 'src/v2//features/reduxForm/Input';

import { requestOptCode } from './accountRecoveryStore';
import { BasicRecoveryValues } from './types';
import { Capture } from '../../components/Сapture/Capture';
import { isNull } from 'lodash';
import { useIsNoCaptureMode } from '../../../utils/hooks';

const Form: FC<InjectedFormProps<BasicRecoveryValues>> = ({
  handleSubmit,
  invalid,
  submitting,
  pristine,
}) => {
  const [captureToken, setCaptureToken] = useState<string | null>(null);
  const dispatch = useDispatch();
  const isNoCaptureMode = useIsNoCaptureMode();

  const submitForm = useCallback(
    (values: BasicRecoveryValues): void => {
      const { email, phone } = values;
      dispatch(
        requestOptCode({
          email,
          phone: `+${normalizeNumbers(phone)}`,
          formName: 'recovery',
          captureToken: !isNull(captureToken) ? captureToken : '',
        }),
      );
    },
    [dispatch, captureToken],
  );

  const handleSubmitCapture = (token: string) => {
    setCaptureToken(token);
  };

  return (
    <form data-id="recovery-form-firstStep" onSubmit={handleSubmit(submitForm)}>
      <div className="c-modal__row">
        <div className="c-title c-title--center c-title--fs-32">ACCOUNT RECOVERY</div>
      </div>
      <div className="c-modal__row">
        <div className="c-description__text c-description--center c-description--fs-14">
          We take account security and your identity seriously. Because our platform is built for
          security you will be asked to provide additional information in order to recover your
          account.
        </div>
      </div>
      <div className="c-modal__row">
        <div className="c-line"></div>
      </div>
      <div className="c-modal__row">
        <div className="c-input _c-input--validation" data-title="Email">
          <div className="c-input__message c-input__message--error">Required field</div>
          <Field name="email" type="email" component={Input} />
        </div>
      </div>
      <div className="c-modal__row">
        <Field name="phone" component={PhoneNumber} title="Mobile Number on account" />
      </div>
      <div style={{ display: 'flex' }}>
        {!isNoCaptureMode && (
          <Capture onChange={handleSubmitCapture} isShow={!invalid} position={'center'} />
        )}
      </div>
      <div className="c-modal__row c-modal--justify-center">
        <button
          className="c-button"
          type="submit"
          disabled={invalid || submitting || pristine || (isNull(captureToken) && !isNoCaptureMode)}
        >
          <span className="c-button__inner">RECOVER</span>
        </button>
      </div>
    </form>
  );
};

const AccountRecoveryStepOne = reduxForm<BasicRecoveryValues>({
  form: 'recovery',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateAuthForm,
})(Form);

export const AccountRecoveryStepOneModal: FC = () => {
  return <AccountRecoveryStepOne />;
};
