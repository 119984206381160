import React from 'react';
import cn from 'classnames';

import { useToggle } from 'src/common/hooks/useToggle';
import { AddUserColor } from 'src/v2/icons/AddUserColor';
import { DocumentRole } from 'src/models/document';
import { SidebarParticipantEntity } from 'src/v2/entities/participants';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

import { ParticipantListItem } from './ParticipantListItem';
import { getShownName } from '../../document/selectors';

interface Props<R> {
  list: SidebarParticipantEntity<R>[];
  label?: string;
  onAdd?: () => void;
  onEdit?: () => void;
  onClick?: (id: string) => void;
  controls?: React.FC<SidebarParticipantEntity<R>>;
  canStartChat?: (id: string) => boolean;
  getShownRole: (roleId: R, funcRole: string) => string;
}

export const ParticipantList = <R extends unknown = DocumentRole>({
  list,
  onAdd,
  onEdit,
  onClick,
  controls: ControlsComponent,
  canStartChat,
  label,
  getShownRole,
}: Props<R>) => {
  const [expanded, toggleExpanded] = useToggle();

  return (
    <div className="c-user">
      <div className="c-user__row c-user__header">
        <div className="c-title c-title--color-green c-title--fs-18">{label}</div>
        {onEdit && (
          <div className="c-title c-title__edit" onClick={onEdit}>
            {i18n(translationKeys.buttons.edit)}
          </div>
        )}
      </div>
      <div className={cn('c-user__list', expanded && 'c-user__list--active')}>
        {list.map((p) => (
          <div className="c-user__item" key={p.id}>
            <ParticipantListItem
              canStartChat={!!canStartChat && canStartChat(p.id)}
              avatar={p.avatar}
              name={getShownName(p.firstName, p.lastName, p.email)}
              roles={getShownRole(p.roleId, p.funcRole)}
              unreadCount={p.unreadCount}
              onClick={onClick && (() => onClick(p.id))}
            />
            {ControlsComponent && <ControlsComponent {...p} />}
          </div>
        ))}
      </div>
      {onAdd && (
        <div className="c-user__item c-user--add" onClick={onAdd}>
          <div className="c-user__photo">
            <AddUserColor />
          </div>
          <div className="c-user__info">
            <div className="c-user__name">{i18n(translationKeys.buttons.addUser)}</div>
          </div>
        </div>
      )}
      {list.length > 2 && (
        <span className="c-more" onClick={toggleExpanded}>
          {!expanded
            ? i18n(translationKeys.forms.item.showMore)
            : i18n(translationKeys.forms.item.showLess)}
        </span>
      )}
    </div>
  );
};
