import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { SignatureId } from 'src/models/signature';
import { createNewSignature, editSignature, saveSignature, setDefaultSignature } from './store';

export const useCreateSignatureHandler = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(createNewSignature());
  }, [dispatch]);
};

export const useEditSignatureHandler = () => {
  const dispatch = useDispatch();
  return useCallback(
    (signatureId: SignatureId) => {
      dispatch(editSignature(signatureId));
    },
    [dispatch],
  );
};

export const useSubmitSignatureHandler = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(saveSignature());
  }, [dispatch]);
};

export const useDefaultSignatureChangeHandler = () => {
  const dispatch = useDispatch();
  return useCallback(
    (signatureId: SignatureId): void => {
      dispatch(setDefaultSignature(signatureId));
    },
    [dispatch],
  );
};
