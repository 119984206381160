import { isUndefined } from 'lodash';

import { AccountType, WizardStepV2, InviteType, RegistrationType } from 'src/models/signUp';
import { BillingPeriod, SubscriptionPlan } from 'src/models/billing';

import { LogoModel } from 'src/v2/boundary/storageModels/logo';
import { InviteEntity } from 'src/v2/boundary/storageModels/invite';
import { SignUpAsyncPayload } from './types';
import { sizesAbbreviation } from './constants';
import { translationKeys } from 'src/common/translations';

const pageTranslationsMap: Record<string, Record<string, PageTranslationKeys>> = {
  [AccountType.Personal]: {
    [SubscriptionPlan.Basic]: translationKeys.forms.signup.cases.personalBasic,
    [SubscriptionPlan.Standard]: translationKeys.forms.signup.cases.personalStandard,
    trial: translationKeys.forms.signup.cases.personalBasic,
  },
  [AccountType.Business]: {
    [SubscriptionPlan.Business]: translationKeys.forms.signup.cases.business,
    [SubscriptionPlan.RealEstate]: translationKeys.forms.signup.cases.realestate,
  },
};

interface PageTranslationKeys {
  title: string;
}

export const signUpPageTranslationMap = (
  account: AccountType,
  plan: SubscriptionPlan | undefined,
): PageTranslationKeys => {
  const translation = pageTranslationsMap[account][plan || 'trial'];

  if (isUndefined(translation)) {
    throw new Error(`Not supported account/plan combination: ${account}/${plan}`);
  }

  return translation;
};

export const isOrgAccount = (accountType: AccountType) => accountType === AccountType.Business;

export const getDefaultSubscriptionPlan = (isOrgAccount: boolean, isInvited: boolean) => {
  const defaultPlan = isInvited ? SubscriptionPlan.Basic : SubscriptionPlan.Standard;
  return isOrgAccount ? SubscriptionPlan.Business : defaultPlan;
};

export const getDefaultBillingPeriod = (plan: SubscriptionPlan | undefined) =>
  plan === SubscriptionPlan.Basic || isUndefined(plan) ? BillingPeriod.None : BillingPeriod.Monthly;

export const getAccountTypeBySubscriptionPlan = (plan: SubscriptionPlan) =>
  plan === SubscriptionPlan.Business || plan === SubscriptionPlan.RealEstate
    ? AccountType.Business
    : AccountType.Personal;

export const getDefaultPlanByAccountType = (accountType: AccountType, isInvited: boolean) => {
  return getDefaultSubscriptionPlan(isOrgAccount(accountType), isInvited);
};

export const getStartSignupWizardStep = (payload: SignUpAsyncPayload): WizardStepV2 => {
  if (payload.inviteCode || payload.inviteType === InviteType.QRCode)
    return WizardStepV2.StartInvite;
  return payload.companyName ? WizardStepV2.StartBusiness : WizardStepV2.StartPersonal;
};

export const getRegistrationType = (
  accountType: AccountType,
  inviteType: InviteType | null,
): RegistrationType => {
  if (inviteType === InviteType.QRCode) return RegistrationType.QRInvite;
  if (inviteType === InviteType.Signer) return RegistrationType.SignInvite;
  if (inviteType === InviteType.Viewer) return RegistrationType.ViewInvite;
  if (inviteType === InviteType.Organization) return RegistrationType.Organization;
  if (accountType === AccountType.Business) return RegistrationType.Business;

  return RegistrationType.Personal;
};

export const getRegistrationApiPayload = (step: WizardStepV2, attributes: any) => ({
  data: {
    attributes: {
      step,
      ...attributes,
    },
  },
});

interface ConvertedSize {
  size: number;
  power: number;
}

const convertSize = (bytes: number): ConvertedSize => {
  const base = 1024;
  const power = Math.floor(Math.log(bytes) / Math.log(base));
  const size = parseFloat((bytes / base ** power).toFixed(0));

  return { size, power };
};

const getSizesNames = (): string[] => sizesAbbreviation;

export const getFileSizeInHRF = (bytes: number): string => {
  if (bytes === 0) return '0 Bytes';

  const sizes = getSizesNames();
  const { size, power } = convertSize(bytes);

  return `${size}${sizes[power]}`;
};

export const getLogoFromInvite = (invite: InviteEntity | null): LogoModel | null =>
  invite && invite.organization[0].logo[0];
