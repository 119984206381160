import { Store } from 'redux';
import { PayloadActionCreator } from '@reduxjs/toolkit';

import { WSEvent } from 'src/v2/boundary/socket';

import { SocketConnection } from './SocketConnection';
import { SocketGatewayConfig } from './types';

export class SocketGateway {
  private connections: SocketConnection[] = [];

  private domainMap: Map<string, SocketConnection> = new Map<string, SocketConnection>();

  constructor(config: SocketGatewayConfig) {
    config.connections.forEach(({ id, url, domains, options }) => {
      const conn = new SocketConnection(id, url, options);
      this.connections.push(conn);
      domains.forEach((domain) => {
        if (this.domainMap.has(domain)) {
          throw new Error('WebSockets connections domains intersection detected.');
        }

        this.domainMap.set(domain, conn);
      });
    });
  }

  public setStore(store: Store): void {
    this.connections.forEach((conn) => conn.setStore(store));
  }

  public setToken(token: string): void {
    this.connections.forEach((conn) => conn.setToken(token));
  }

  private getConnectionByDomain(domain: string): SocketConnection {
    if (!this.domainMap.has(domain)) {
      throw new Error(`Domain ${domain} is not registered by any connection`);
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.domainMap.get(domain)!;
  }

  private static parseEventName(value: string): [string, string] {
    const parts = value.split('/');

    // todo: regex validate event name
    if (parts.length !== 2) {
      throw new Error(
        'Bad event format. Should be in following format domain/eventName (chat/sendMessage)',
      );
    }

    return [parts[0], parts[1]];
  }

  private getConnectionByEventName(name: string): SocketConnection {
    const [domain] = SocketGateway.parseEventName(name);
    return this.getConnectionByDomain(domain);
  }

  public async sendCommand<T>(event: WSEvent<T>): Promise<void> {
    const conn = this.getConnectionByEventName(event.command);

    try {
      await conn.sendCommand(event);
    } catch (e: any) {
      // todo: move to SocketConnection
      // introduce timeout
      if (e.code === DOMException.INVALID_STATE_ERR) {
        await this.sendCommand(event);
      }
      console.log(e);

      throw e;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public listenEvents(...args: PayloadActionCreator<any>[]): void {
    args.forEach((ac) => {
      const conn = this.getConnectionByEventName(ac.type);
      conn.listenEvent(ac);
    });
  }
}
