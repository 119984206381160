import React, { useMemo } from 'react';
import { mergeStyles } from 'src/utils/styles';
import { PasswordStrength } from 'src/utils/passwordStrength';

const styles = {
  weak: {
    backgroundColor: '#d57872',
  },
  medium: {
    backgroundColor: '#e4b62c',
  },
  strong: {
    backgroundColor: '#29cb97',
  },
};

interface Props {
  strength: PasswordStrength | undefined;
}

export const PasswordStrengthIndicator: React.FC<Props> = ({ strength }) => {
  const [weak, medium, strong] = useMemo(() => {
    return [
      [styles.weak, strength === PasswordStrength.weak],
      [styles.medium, strength === PasswordStrength.medium],
      [styles.strong, strength === PasswordStrength.strong],
    ];
  }, [strength]);

  const [weakStyle, mediumStyle, strongStyle] = useMemo(() => {
    return [mergeStyles(weak, medium, strong), mergeStyles(medium, strong), mergeStyles(strong)];
  }, [weak, medium, strong]);

  return (
    <div className="c-form__row">
      <div className="c-form__column">
        <div className="c-security">
          <div style={weakStyle} className="c-security__line"></div>
          <div style={mediumStyle} className="c-security__line"></div>
          <div style={strongStyle} className="c-security__line"></div>
        </div>
      </div>
    </div>
  );
};
