import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isUndefined, isNull, find } from 'lodash';

import { ContractType, ContractRole } from 'src/models/contract';
import { useGetCurrentUserRoleByEntity } from 'src/v2/features/sharedEntity';
import { useGetEntityById } from 'src/v2/features/sharedEntity/hooks';
import {
  canEditUser,
  canRemoveUser,
} from 'src/v2/features/contract/permissions/manageParticipantsPermissions/manageParticipantsPermissions';
import { getUserId } from 'src/shared/auth';
import { isParticipantInvitedByUser } from 'src/v2/features/sharedEntity/utils';

export const useCanEditParticipant = (contractId: string): ((participantId: string) => boolean) => {
  const contract = useGetEntityById<ContractType, ContractRole>(contractId);
  const currentUserRole = useGetCurrentUserRoleByEntity<ContractRole>(contract);
  const myUserId = useSelector(getUserId);

  const canEditParticipant = useCallback(
    (participantId: string): boolean => {
      if (isNull(contract)) return false;
      const { paperParticipant } = contract;
      const participant = find(paperParticipant, ({ userId }) => userId === participantId);
      if (isNull(currentUserRole) || isNull(myUserId) || isUndefined(participant)) return false;

      const currentUser = {
        id: myUserId,
        role: currentUserRole,
      };
      const { roleId: role } = participant;
      const invitedByMe = isParticipantInvitedByUser<ContractRole>(participant, myUserId);

      return canEditUser(currentUser, { id: participantId, role }, invitedByMe);
    },
    [contract, currentUserRole, myUserId],
  );

  return canEditParticipant;
};

export const useCanRemoveParticipant = (
  contractId: string,
): ((participantId: string) => boolean) => {
  const contract = useGetEntityById<ContractType, ContractRole>(contractId);
  const currentUserRole = useGetCurrentUserRoleByEntity<ContractRole>(contract);
  const myUserId = useSelector(getUserId);

  const canRemoveParticipant = useCallback(
    (participantId: string): boolean => {
      if (isNull(contract)) return false;
      const { paperParticipant } = contract;
      const participant = find(paperParticipant, ({ userId }) => userId === participantId);
      if (isNull(currentUserRole) || isNull(myUserId) || isUndefined(participant)) return false;

      const currentUser = {
        id: myUserId,
        role: currentUserRole,
      };
      const { roleId: role } = participant;

      return canRemoveUser(currentUser, { id: participantId, role });
    },
    [contract, currentUserRole, myUserId],
  );

  return canRemoveParticipant;
};

export const useCanChangeOwnerCondition = (contractId: string): boolean => {
  const contract = useGetEntityById<ContractType, ContractRole>(contractId);
  const currentUserRole = useGetCurrentUserRoleByEntity<ContractRole>(contract);

  return currentUserRole === ContractRole.Owner;
};
