import React, { useMemo } from 'react';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { isUndefined } from 'lodash';

import { CreationMode } from 'src/api/documents';
import { EntityType } from 'src/models/paper';
import { DocumentSidebarUpload } from 'src/v2/features/documentSidebar/documentSidebarUpload';
import { LibrarySection } from 'src/v2/features/sharedEntity';
import { useCreatePaperPageData } from 'src/v2/features/sharedEntity/EntitySidebar/hooks';
import { Case, Switch } from 'src/v2/components/SwitchCase';
import { ContractMetadataCreate } from 'src/v2/features/contract/components/ContractMetadata';
import {
  CreateContractDTO,
  CreateContractFromTemplateDTO,
} from 'src/v2/boundary/requestDTO/contract';
import { FormContractCreatePayload } from 'src/v2/features/contract/components/ContractMetadata/types';
import { CreateContractFromFilePayload } from 'src/v2/features/documentSidebar/documentSidebarUpload/types';

interface Props {
  convertFormPayloadToDTO: (payload: FormContractCreatePayload) => CreateContractDTO;
  createContract: ActionCreatorWithPayload<CreateContractDTO>;
  createContractFromFile: ActionCreatorWithPayload<CreateContractFromFilePayload>;
  createContractFromTemplate: ActionCreatorWithPayload<CreateContractFromTemplateDTO>;
}

export const AbstractCreate: React.FC<Props> = ({
  convertFormPayloadToDTO,
  createContract,
  createContractFromFile,
  createContractFromTemplate,
}) => {
  const {
    creationMode: state,
    isLoading,
    data: prePopulatedMetadata,
    onFormSubmit: handleSubmit,
    onLibrarySectionSubmit: handleLibrarySectionSubmit,
  } = useCreatePaperPageData<FormContractCreatePayload, CreateContractDTO>(
    convertFormPayloadToDTO,
    createContract,
    createContractFromTemplate,
  );

  const document = useMemo(
    () => !isUndefined(prePopulatedMetadata) && convertFormPayloadToDTO(prePopulatedMetadata),
    [prePopulatedMetadata, convertFormPayloadToDTO],
  );

  return (
    <Switch criteria={state}>
      <Case value={CreationMode.blank}>
        <ContractMetadataCreate isLoading={isLoading} onSubmit={handleSubmit} />
      </Case>
      <Case value={CreationMode.template}>
        <LibrarySection
          onSubmit={handleLibrarySectionSubmit}
          type={EntityType.contract}
          isLoading={isLoading}
        />
      </Case>
      <Case value={CreationMode.upload}>
        {document && (
          <DocumentSidebarUpload<CreateContractDTO>
            document={document}
            uploadFile={createContractFromFile}
          />
        )}
      </Case>
    </Switch>
  );
};
