import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import {
  linkFolderObjectAPI,
  moveFolderObjectAPI,
  unlinkFolderObjectAPI,
} from 'src/api/foldersAPI';
import { storeData } from 'src/v2/features/objectsStorage';
import { handleErrorSaga } from 'src/utils/handleErrorSaga';

import { listenWSEvents, sendWSCommand } from 'src/api/socket/connection';
import { WSObjectSerialized } from 'src/common/types';
import { fetchObjects } from 'src/v2/features/objectsList/store';
import {
  moveFolderObject,
  linkFolderObject,
  unlinkFolderObject,
  folderSubscribe,
  folderUnsubscribe,
  folderUpdate,
} from './actions';
import {
  MoveObjectPayload,
  SubscribeFolderActionPayload,
  SubscribeFolderWsPayload,
  UnlinkFolderObjectPayload,
} from './types';

enum FolderWSCommand {
  Subscribe = 'folder/folderSubscribe',
  Unsubscribe = 'folder/folderUnsubscribe',
}

function* moveFolderObjectSaga({ payload }: PayloadAction<MoveObjectPayload>) {
  try {
    const { objectId, currentFolderId, targetFolderId } = payload;
    const response = yield call(moveFolderObjectAPI, objectId, currentFolderId, targetFolderId);
    yield put(storeData(response));
    yield put(fetchObjects({ folderId: currentFolderId, sortBy: '', sortDirection: '' }));
    yield call(toastr.success, 'Success', 'Moved successfully!');
  } catch (error) {
    yield call(handleErrorSaga, error);
  }
}

function* linkFolderObjectSaga({ payload }: PayloadAction<MoveObjectPayload>) {
  try {
    const response = yield call(linkFolderObjectAPI, payload.objectId, payload.targetFolderId);
    yield put(storeData(response));
    yield call(toastr.success, 'Success', 'Link created successfully!');
  } catch (error) {
    yield call(handleErrorSaga, error);
  }
}

function* unlinkFolderObjectSaga({ payload }: PayloadAction<UnlinkFolderObjectPayload>) {
  try {
    const { entityId, folderId } = payload;
    yield call(unlinkFolderObjectAPI, entityId, folderId);
  } catch (error) {
    yield call(handleErrorSaga, error);
  }
}

function* subscribeFolderSaga({ payload }: PayloadAction<SubscribeFolderActionPayload>) {
  try {
    const wsPayload: SubscribeFolderWsPayload = {
      folder_id: payload,
    };
    yield call(sendWSCommand, { command: FolderWSCommand.Subscribe, payload: wsPayload });
  } catch (err) {
    console.error('subscribeFolderSaga: ', err);
  }
}

function* unsubscribeFolderSaga() {
  try {
    yield call(sendWSCommand, { command: FolderWSCommand.Unsubscribe });
  } catch (err) {
    console.error('unsubscribeFolderSaga: ', err);
  }
}

function* updateFolderSaga({ payload }: PayloadAction<WSObjectSerialized>) {
  try {
    yield put(storeData(payload));
  } catch (err) {
    console.error('updateFolderSaga: ', err);
  }
}

export function* watchFolderObjectsSagas() {
  yield takeLatest(moveFolderObject, moveFolderObjectSaga);
  yield takeLatest(linkFolderObject, linkFolderObjectSaga);
  yield takeLatest(unlinkFolderObject, unlinkFolderObjectSaga);
  yield takeLatest(folderSubscribe, subscribeFolderSaga);
  yield takeLatest(folderUnsubscribe, unsubscribeFolderSaga);
  yield takeLatest(folderUpdate, updateFolderSaga);
}

export const initializeFolderStore = (): void => {
  listenWSEvents(folderUpdate);
};
