import React, { Component, ComponentType } from 'react';
import { isEqual } from 'lodash';

import { Section } from 'src/models/document';

import { EditableProps, StaticProps, AsyncStateProps } from '../types';

type Props = EditableProps | StaticProps;

interface State {
  sections: Section[];
}

export const withAsyncState = (
  WrappedComponent: ComponentType<Props & AsyncStateProps>,
): ComponentType<Props> =>
  class HOC extends Component<Props, State> {
    state: State = {
      sections: [],
    };

    queue: (() => Promise<{}>)[] = [];

    running = false;

    componentDidMount(): void {
      this.syncSections();
    }

    componentDidUpdate(prevProps: Props): void {
      if (!this.running && !isEqual(prevProps.sections, this.props.sections)) {
        this.syncSections();
      }
    }

    addActionToQueue = (
      callback: () => Promise<{}>,
      alterSections: (sections: Section[]) => Section[],
    ): void => {
      this.setState(({ sections }: State) => ({ sections: alterSections(sections) }));
      this.queue = [...this.queue, callback];
      if (!this.running) {
        this.running = true;
        this.runNextQueuedAction();
      }
    };

    runNextQueuedAction = (): void => {
      const next = this.queue.shift();
      if (next) {
        next().catch(this.skipQueue).then(this.runNextQueuedAction);
      } else {
        this.running = false;
        this.syncSections();
      }
    };

    skipQueue = (): void => {
      this.queue = [];
      this.running = false;
      this.syncSections();
    };

    syncSections(): void {
      if (!isEqual(this.state.sections, this.props.sections)) {
        this.setState({ sections: this.props.sections });
      }
    }

    render(): JSX.Element {
      return (
        <WrappedComponent
          {...this.props}
          sections={this.state.sections}
          isRunning={this.running}
          addActionToQueue={this.addActionToQueue}
        />
      );
    }
  };
