import React, { FC, useCallback, useMemo } from 'react';
import { isNil } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { book } from 'src/app/book';
import { TemplateModel } from 'src/models/template';
import { useNavigateToDefault } from 'src/v2/features/sharedEntity';
import { ConfirmSection } from 'src/v2/components/ConfirmSection';
import { canDeleteTemplate } from 'src/v2/features/template/permissions';
import { useGetTemplateById } from 'src/v2/features/template/hooks';
import { deleteTemplate } from '../../reducer';

interface Props {
  id: string;
}

const useCreateDeleteHandlerByTemplateId = ({
  template,
  backUrlAfterSuccess,
}: {
  template: TemplateModel | undefined | null;
  backUrlAfterSuccess: string;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return useCallback((): void => {
    if (!isNil(template) && canDeleteTemplate()) {
      dispatch(deleteTemplate(template.templateId));

      navigate(backUrlAfterSuccess);
    }
  }, [template, dispatch, navigate, backUrlAfterSuccess]);
};

export const Remove: FC<Props> = ({ id }) => {
  const template = useGetTemplateById(id);
  const backUrlAfterSuccess = useMemo(() => book.templates.generatePath(), []);

  const onDelete = useCreateDeleteHandlerByTemplateId({
    template,
    backUrlAfterSuccess,
  });

  const onCancel = useNavigateToDefault(id);

  return <ConfirmSection message="Delete template?" onClose={onCancel} onConfirm={onDelete} />;
};
