import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Box, Text } from '@theme-ui/components';

import { styles } from './styles';

import { NotificationListItem } from './components/NotificationListItem';
import { NotificationEntity } from 'src/v2/entities/notification';

interface Props {
  list: NotificationEntity[];
  totalCount: number;
  onLoadMore: () => void;
  v2?: boolean;
}

const loader = <div className="loader">Loading ...</div>;

export const Notifications = ({ list, onLoadMore, totalCount }: Props): JSX.Element => {
  return (
    <Box sx={styles.container}>
      <InfiniteScroll
        isReverse={false}
        initialLoad={false}
        loadMore={onLoadMore}
        hasMore={list.length < totalCount}
        loader={loader}
        useWindow={false}
      >
        {list.length ? (
          list.map((item) => <NotificationListItem key={item.id} value={item} />)
        ) : (
          <Text key="empty" sx={styles.emptyText}>
            You don't have notifications
          </Text>
        )}
      </InfiniteScroll>
    </Box>
  );
};
