import { isUndefined } from 'lodash';

export const appendDataIfDefined = (
  data: FormData,
  fieldName: string,
  fieldValue: string | File | undefined,
) => {
  if (isUndefined(fieldValue)) return;

  data.append(fieldName, fieldValue);
};

export const appendDataObjectIfDefined = (
  data: FormData,
  objectToAppend: Record<string, string | File | undefined>,
) => {
  Object.keys(objectToAppend).forEach((objectKey) =>
    appendDataIfDefined(data, objectKey, objectToAppend[objectKey]),
  );
};
