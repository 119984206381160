import { History } from 'history';

import { Document, DocumentType } from 'src/models/document';
import { CompleteObject } from 'src/common/types';
import { EntityType, PaperModel, PaperState } from 'src/models/paper';
import { ContractType } from 'src/models/contract';
import { TemplateEntityCompleteModel, TemplateEntityModel } from 'src/models/entity';

export type Template = PaperModel<TemplateType, TemplateRole>;

export enum TemplateContentType {
  text = 'text',
  file = 'file',
}

export interface TemplatePayload {
  id: string;
  title: string;
  description: string;
  state: PaperState;
  paperType: EntityType;
  type: DocumentType | ContractType;
  subType: string;
  isTemplate: boolean;
  createdAt: string;
  industry: string;
  country: string;
  province: string;
  city: string;
  folderId: string;
}

export type TemplateType = DocumentType | ContractType;

export interface TemplateCreatePayload {
  title: string;
  description: string;
  paperType: EntityType;
  type: DocumentType | ContractType;
  subType: string;
  industry: string;
  country: string;
  province: string;
  city: string;
  folderId: string;
}

export enum TemplateRole {
  Owner = 'owner',
  Viewer = 'viewer',
}

export const TemplateIndustryType = {
  Automobile: 'Automobile',
  Education: 'Education',
  Energy: 'Energy',
  Financial: 'Financial',
  Government: 'Government',
  Healthcare: 'Healthcare',
  Legal: 'Legal',
  Other: 'Other',
  RealEstate: 'Real Estate',
  Resource: 'Resource',
  SupplyChain: 'Supply Chain',
  Telecommunication: 'Telecommunication',
};

export interface TemplateFolder extends CompleteObject {
  id: string;
  name: string;
  itemType: EntityType;
  children: TemplateFolder[];
}

export enum FolderType {
  folders = 'folders',
}

export type FetchTemplatesByPaperTypePayload = EntityType;

export type FetchMyTemplatesPayload = EntityType;

export interface CreateTemplateActionPayload {
  payload: TemplatePayload;
  history: History;
}

export interface UpdateTemplateFilePayload {
  templateId: string;
  file: File;
}

export interface InviteParticipantsPayload {
  documentId: Document['id'];
  emails: string[];
}

export interface RemoveParticipantPayload {
  participantId: string;
  templateId: string;
}

export interface RemoveSectionPayload {
  templateId: string;
  sectionIndex: number;
}

export interface CopyTemplatePayload {
  templateId: string;
  folderId: string;
}

export interface TemplateFileDbModel {
  uri: string;
  filename: string;
  mime: string;
  hash: string;
}

export interface TemplateDbModel {
  city: string;
  country: string;
  createdAt: string;
  description: string;
  entityId: string;
  industry: string;
  paperType: string;
  province: string;
  state: string;
  subType: string;
  contentType: string;
  templateId: string;
  title: string;
  type: string;
  file: TemplateFileDbModel;
  updatedAt: string;
}

export interface TemplateBaseModel extends TemplateDbModel {
  paperType: EntityType;
  type: DocumentType | ContractType;
  contentType: TemplateContentType;
}

export interface TemplateCompleteModel extends TemplateBaseModel {
  entity?: TemplateEntityCompleteModel[];
  templateParticipant?: TemplateParticipantCompleteModel[];
}

export interface TemplateModel extends TemplateBaseModel {
  entities: TemplateEntityModel[];
  participants: TemplateParticipantModel[];
  ownerParticipant: TemplateParticipantModel;
  currentUserParticipant: TemplateParticipantModel;
}

interface TemplateParticipantDbModel {
  roleId: string;
  invitedAt: string;
  participantId: string;
}

export interface TemplateBaseParticipantModel extends TemplateParticipantDbModel {
  roleId: TemplateRole;
}

export interface TemplateParticipantCompleteModel extends TemplateBaseParticipantModel {
  templateUser?: TemplateUserDbModel[];
}

export interface TemplateParticipantModel
  extends Pick<TemplateBaseParticipantModel, 'roleId' | 'invitedAt' | 'participantId'>,
    TemplateUserModel {}

interface TemplateUserDbModel {
  avatar: string;
  created_at: string;
  email: string;
  first_name: string;
  last_name: string;
  user_id: string;
}

interface TemplateUserModel extends Pick<TemplateUserDbModel, 'avatar' | 'email'> {
  createdAt: TemplateUserDbModel['created_at'];
  firstName: TemplateUserDbModel['first_name'];
  lastName: TemplateUserDbModel['last_name'];
  userId: TemplateUserDbModel['user_id'];
}
