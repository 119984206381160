/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';

import { ObjectBase } from 'src/common/types';
import { defaultSelectorFactory, GetStateSelector } from 'src/utils/defaultSelectors';
import { WorkflowEntity, WorkflowState, WorkflowType } from 'src/v2/entities/workflow';
import { SidebarParticipantEntity } from 'src/v2/entities/participants';
import { getObject } from 'src/v2/features/objectsStorage/objectsStorageSlice';
import { RootState, DefaultSliceStateWithData } from 'src/app/types';
import { PaperParticipantsAffiliationType } from 'src/v2/boundary/storageModels/paper/paperParticipant';

export const documentWorkflowSelectorsFactory = (
  getState: GetStateSelector<DefaultSliceStateWithData>,
) => {
  const {
    getDataHead: getDocumentWorkflow,
    getIsLoading,
    getError,
  } = defaultSelectorFactory(getState);

  const getWorkflow = createSelector(
    getDocumentWorkflow,
    (state: RootState) => state.objectsStorage.objects.workflow,
    (item, workflow): WorkflowEntity | null =>
      getObject<WorkflowEntity>(item as ObjectBase, {
        workflow,
      }),
  );

  const getWorkflowType = (state: RootState): WorkflowType => {
    switch (get(getWorkflow(state), 'type')) {
      case 'negotiation':
        return WorkflowType.Negotiation;
      case 'acknowledge':
        return WorkflowType.Acknowledge;
      default:
        return WorkflowType.View;
    }
  };

  const getIsNegotiable = (state: RootState): boolean =>
    getWorkflowType(state) === WorkflowType.Negotiation;

  const getWorkflowState = (state: RootState): WorkflowState | null => {
    const wf = getWorkflow(state);
    return wf && wf.state;
  };

  const isReadyToSign = (state: RootState): boolean => {
    const wfState = getWorkflowState(state);
    return !!wfState && wfState === WorkflowState.ReadyToSign;
  };

  const isComplete = (state: RootState): boolean => {
    const wfState = getWorkflowState(state);
    return !!wfState && wfState === WorkflowState.Complete;
  };

  const isInWork = (state: RootState): boolean => {
    const wfState = getWorkflowState(state);
    return !!wfState && wfState === WorkflowState.InWork;
  };

  const getWorkflowParticipants = (state: RootState): SidebarParticipantEntity[] => {
    const workflow = getWorkflow(state);
    if (!workflow) {
      return [];
    }
    const ownerParticipants = workflow.owner.participants.map((e) => ({
      ...e,
      affiliationType: PaperParticipantsAffiliationType.owner,
    }));

    const counterpartyParticipants = (workflow.parties || [])
      .map((ps) => ps.participants)
      .flat()
      .map((e) => ({
        ...e,
        affiliationType: PaperParticipantsAffiliationType.counterparty,
      }));
    return [...ownerParticipants, ...counterpartyParticipants];
  };

  const getAllWorkflowPartiesUsersIds = (state: RootState) =>
    Object.keys(state.objectsStorage.objects.partiesUsers);

  return {
    getDocumentWorkflow,
    getWorkflowParticipants,
    getAllWorkflowPartiesUsersIds,
    getIsLoading,
    getError,
    getWorkflowType,
    getWorkflow,
    getIsNegotiable,
    isInWork,
    isReadyToSign,
    isComplete,
  };
};
