import React, { useCallback } from 'react';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { useDispatch } from 'react-redux';
import { confirmation, length } from 'redux-form-validators';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { CForm, CFormRow } from 'src/v2/components/CForm';
import { Password } from 'src/v2/features/reduxForm/Password';
import { validatePassword } from 'src/utils/passwordStrength';

import { changePassword } from '../store/profileReducer';
import { securityFormName } from '../constants';

type FormValues = {
  newPassword: string;
  oldPassword: string;
  confirmNewPassword: string;
};

const Form: React.FC<InjectedFormProps<FormValues>> = ({
  handleSubmit,
  pristine,
  submitting,
  invalid,
}) => {
  const dispatch = useDispatch();

  const submitForm = useCallback(
    (values) => {
      dispatch(
        changePassword({ oldPassword: values.oldPassword, newPassword: values.newPassword }),
      );
    },
    [dispatch],
  );

  return (
    <CForm data-id="profile-form-security" as="form" onSubmit={handleSubmit(submitForm)}>
      <CFormRow required>
        <Field
          name="oldPassword"
          type="password"
          component={Password}
          placeholder={i18n(translationKeys.forms.changePassword.password)}
          validate={[length({ min: 8 })]}
          required
        />
      </CFormRow>

      <CFormRow required>
        <Field
          name="newPassword"
          type="password"
          component={Password}
          placeholder={i18n(translationKeys.forms.changePassword.newPassword)}
          validate={[validatePassword]}
          required
        />
      </CFormRow>

      <CFormRow required>
        <Field
          name="confirmNewPassword"
          type="password"
          component={Password}
          placeholder={i18n(translationKeys.forms.changePassword.confirmNewPassword)}
          validate={[
            length({ min: 8 }),
            confirmation({
              field: 'newPassword',
              message: i18n(translationKeys.errors.PASSWORD_NOT_MATCH),
            }),
          ]}
          required
        />
      </CFormRow>
      <CFormRow required>{i18n(translationKeys.errors.REQUIRED)}</CFormRow>

      <CFormRow className="c-form--justify-center">
        <button
          data-type="security-submit"
          type="submit"
          className="c-button c-button--fs-12"
          disabled={invalid || submitting || pristine}
        >
          <span className="c-button__inner">
            {i18n(translationKeys.forms.changePassword.title)}
          </span>
        </button>
      </CFormRow>
    </CForm>
  );
};

export const SecurityForm = reduxForm<FormValues>({
  form: securityFormName,
})(Form);
