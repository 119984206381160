import React, { FC, useCallback } from 'react';

import { book } from 'src/app/book';
import { MenuItem } from 'src/v2/modules/layout/navigation';
import { MenuDocuments } from 'src/v2/icons/MenuDocuments';
import { MenuContracts } from 'src/v2/icons/MenuContracts';
import { MenuLibrary } from 'src/v2/icons/MenuLibrary';
import { MenuProjects } from 'src/v2/icons/MenuProjects';
import { MenuCog } from 'src/v2/icons/MenuCog';
import { MenuLogout } from 'src/v2/icons/MenuLogout';
import { useHasTemplateFeature, useHasNavigationFeature } from 'src/v2/features/billing';
import { useShowUpgradeModal } from 'src/v2/features/sharedEntity/hooks';
import { useTranslation } from 'src/common/hooks/useTranslation';
import { translationKeys } from 'src/common/translations';
import { useLogoutHook } from 'src/v2/features/auth/hooks';

export const Navigation: FC = () => {
  const i18n = useTranslation();
  const hasTemplateFeature = useHasTemplateFeature();
  const hasNavigationFeature = useHasNavigationFeature();
  const showUpgradeModal = useShowUpgradeModal();
  const [handleLogout] = useLogoutHook();

  const handleTemplatesMenuClick = useCallback(
    (event: React.MouseEvent) => {
      if (!hasTemplateFeature) {
        showUpgradeModal();
        event.preventDefault();
      }
    },
    [hasTemplateFeature, showUpgradeModal],
  );

  return (
    <>
      <MenuItem
        icon={MenuDocuments}
        link={book.documents.generatePath()}
        data-type="documents-link"
        title={i18n(translationKeys.navigationMenu.documents)}
      />
      <MenuItem
        icon={MenuContracts}
        link={book.contracts.generatePath()}
        data-type="contracts-link"
        title={i18n(translationKeys.navigationMenu.contracts)}
      />
      <MenuItem
        icon={MenuLibrary}
        link={book.templates.pattern}
        data-type="templates-link"
        onClick={handleTemplatesMenuClick}
        title={i18n(translationKeys.navigationMenu.templates)}
      />
      {hasNavigationFeature && (
        <MenuItem
          icon={MenuProjects}
          link={book.projects.generatePath()}
          data-type="projects-link"
          title="Projects"
        />
      )}
      <MenuItem
        icon={MenuCog}
        link={book.profile.personal.generatePath()}
        data-type="settings-link"
        title={i18n(translationKeys.navigationMenu.settings)}
      />
      <MenuItem
        icon={MenuLogout}
        className={'c-menu__link--logout'}
        link={book.logout}
        data-type="logout-link"
        onClick={handleLogout}
        title={i18n(translationKeys.header.logOut)}
      />
    </>
  );
};
