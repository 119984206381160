import { isNil } from 'lodash';

export const editorStubContentConverter = (content: string) => {
  if (!isNil(content)) {
    return content.replaceAll(
      '<span class="editable-field-title">',
      '<span class="editable-field-title" contenteditable="true">',
    );
  }
  return content;
};
