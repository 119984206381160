// Core
import React from 'react';
import ReactSwitch from 'react-switch';
import { Text } from '@theme-ui/components';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

const styles = {
  text: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'arial',
    fontSize: 'tiny',
    fontWeight: 700,
    color: '#ffffff',
    textTransform: 'uppercase',
  },
};

interface Props {
  name: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  id?: string;
}

export const Switch: React.FC<Props> = (props) => {
  return (
    <ReactSwitch
      {...props}
      width={56}
      height={26}
      onColor="#29cb97"
      offColor="#d57872"
      handleDiameter={20.5}
      activeBoxShadow="none"
      checkedIcon={<Text sx={styles.text}>{i18n(translationKeys.switch.on)}</Text>}
      uncheckedIcon={<Text sx={styles.text}>{i18n(translationKeys.switch.off)}</Text>}
    />
  );
};
