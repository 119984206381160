interface MetaDataParentType {
  folderName: string;
  folderLink: string;
}

export interface PDFMetaDataType {
  fileName: string;
  fileSize: string;
  hasReadOnlyAccess: boolean;
  mimeType: string;
  modified: string;
  parent: MetaDataParentType;
}

export type PDFSaveHandler = (
  metaData: PDFMetaDataType,
  content: Int8Array,
  options: unknown,
) => void;

export enum PDFToolbarItems {
  Pager = 'pager',
  Layout = 'layout-config',
  Pan = 'pan',
  ZoomOut = 'zoom-out',
  ZoomIn = 'zoom-in',
  ZoomMode = 'zoom-mode',
  ZoomMarquee = 'marquee-zoom',
  Spacer = 'spacer',
  Text = 'text',
  Annotate = 'annotate',
  Responsive = 'responsive-group',
  Ink = 'ink',
  Image = 'image',
  Line = 'line',
  Arrow = 'arrow',
  Rectangle = 'rectangle',
  Ellipse = 'ellipse',
  Polygon = 'polygon',
  Polyline = 'polyline',
  Note = 'note',
  Print = 'print',
  Search = 'search',
  Debug = 'debug',
  SidebarThumbnails = 'sidebar-thumbnails',
  SidebarAnnotations = 'sidebar-annotations',
  InkSignature = 'ink-signature',
  Signature = 'signature',
  Comment = 'comment',
  DocumentOutLine = 'sidebar-document-outline',
  Bookmarks = 'sidebar-bookmarks',
  Highlighter = 'highlighter',
  TextHighlighter = 'text-highlighter',
  Eraser = 'ink-eraser',
  Stamp = 'stamp',
  Editor = 'document-editor',
  Export = 'export-pdf',
}
