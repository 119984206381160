import React, { HTMLAttributes, useCallback, useMemo } from 'react';
import Dropzone, { defaultClassNames, IDropzoneProps } from 'react-dropzone-uploader';
import { noop } from 'lodash';
import cn from 'classnames';

import { Layout } from './Layout';
import { Placeholder } from './Placeholder';
import styles from './UploadImage.module.css';

type TitleProps = HTMLAttributes<HTMLDivElement> & {
  [key: string]: string | undefined;
};

interface Props {
  onUpload: (data: File) => void;
  onDelete?: () => void;
  titleProps?: TitleProps;
}

export const UploadImage: React.FC<Props> = ({
  children,
  onUpload,
  onDelete = noop,
  titleProps = {},
}) => {
  const onChangeStatus: IDropzoneProps['onChangeStatus'] = useCallback(
    (payload, status): void => {
      if (status === 'done') {
        onUpload(payload.file);
      }

      if (status === 'removed') {
        onDelete();
      }
    },
    [onUpload, onDelete],
  );

  const inputContent = useMemo(() => <Placeholder key={'inputContent'} />, []);
  const inputWithFilesContent = useMemo(() => <Placeholder key={'inputWithFilesContent'} />, []);

  return (
    <div className="c-signature__upload">
      <div className="c-title c-title--color-theme c-title--center h-uppercase" {...titleProps}>
        {children}
      </div>
      <div className="c-signature__area">
        <Dropzone
          LayoutComponent={Layout}
          multiple={false}
          onChangeStatus={onChangeStatus}
          classNames={{
            inputLabelWithFiles: defaultClassNames.inputLabel,
            preview: cn(defaultClassNames.preview, styles.previewContainer),
            previewImage: cn(defaultClassNames.previewImage, styles.previewImage),
          }}
          inputContent={inputContent}
          inputWithFilesContent={inputWithFilesContent}
        />
      </div>
    </div>
  );
};
