import React from 'react';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { Document, DocumentRole } from 'src/models/document';
import {
  useCreateNavigateToDocumentDetails,
  useCreateGetDocumentInfo,
} from 'src/v2/features/document';
import { DocumentCard } from 'src/v2/features/document/components/DocumentCard';
import { SearchCardGrid } from 'src/v2/components/SearchCardsGrid';
import { parseCardDataFactory } from 'src/v2/features/sharedEntity';

interface Props {
  list: Document[];
}

const parseCardData = parseCardDataFactory<DocumentRole>(i18n(translationKeys.document.noTitle));

export const DocumentsCardGrid: React.FC<Props> = ({ list }) => {
  const createHandleOpenDocument = useCreateNavigateToDocumentDetails();
  const createHandleClickFromSearch = useCreateGetDocumentInfo();

  return (
    <SearchCardGrid>
      {list.map(
        (data: Document): JSX.Element => (
          <DocumentCard
            key={data.id}
            data={parseCardData(data)}
            onClick={createHandleClickFromSearch(data.id)}
            onDoubleClick={createHandleOpenDocument(data.id)}
          />
        ),
      )}
    </SearchCardGrid>
  );
};
