import React, { FC } from 'react';
import cn from 'classnames';
import { WithValidationMessage } from '../WithValidationMessage';
import { CheckboxProps } from '../types';

export const Checkbox: FC<CheckboxProps> = ({
  className,
  errorMessage,
  successMessage,
  label,
  disabled,
  ...inputProps
}): JSX.Element => {
  return (
    <WithValidationMessage
      errorMessage={errorMessage}
      successMessage={successMessage}
      classPrefix="c-checkbox"
      className={cn('c-checkbox', { 'c-checkbox--disabled': disabled }, className)}
    >
      <label className="c-checkbox__label">
        <input type="checkbox" hidden disabled={disabled} {...inputProps} />
        <span className="c-checkbox__icon" />
        <span className="c-checkbox__title">{label}</span>
      </label>
    </WithValidationMessage>
  );
};
