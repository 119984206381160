import { FC } from 'react';
import { InjectedFormProps } from 'redux-form';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { DocumentPayload } from 'src/models/document';
import { MetadataForm, connectForm, RoleField } from 'src/v2/features/metadata';
import { EntityType } from 'src/models/paper';
import { DocumentMetaProps } from './types';

interface Props extends DocumentMetaProps {
  isLoading: boolean;
  onSubmit: (data: DocumentPayload) => void;
}

const Edit: FC<Props & InjectedFormProps<DocumentPayload, Props>> = ({
  isLoading,
  onSubmit,
  handleSubmit,
  pristine,
}) => (
  <MetadataForm
    data-id="metadata-form-edit"
    infoLabel={i18n(translationKeys.forms.document.create.createNewInfo)}
    label={i18n(translationKeys.forms.document.update)}
    type={EntityType.document}
    isLoading={isLoading}
    isPristine={pristine}
    onSubmit={handleSubmit(onSubmit)}
  >
    <RoleField />
  </MetadataForm>
);

const fields = ['role', 'createDocumentOption'];
export const DocumentMetadataEdit = connectForm<Props, DocumentPayload>(Edit, fields);
