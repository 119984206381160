import { EventData } from 'src/app/eventBus';
import { FC } from 'react';

export class ObjectSerialized {
  data: Array<ObjectData> = [];

  included: Array<ObjectData> = [];

  static dataToBaseObjects(data: Array<ObjectData>): Array<ObjectBase> {
    return data.map((itm) => ({
      id: itm.id,
      type: itm.type,
    }));
  }
}

export type WSObjectSerialized = ObjectSerialized & { type: string };

export type ScalarType = number | string | boolean | Date;

interface ObjectAttributes {
  [key: string]: ScalarType | ScalarType[];
}

interface ObjectRelations {
  [key: string]: {
    data: ObjectBase[];
  };
}

export interface ObjectBase {
  id: string;
  type: string;
}

export interface ObjectData extends ObjectBase {
  attributes: ObjectAttributes;
  relationships: ObjectRelations;
}

export interface CompleteObject {
  id: string;
  __type: string;
  [key: string]: ScalarType | CompleteObject[] | string[] | object | object[] | undefined;
}

export interface DataModificationPayload {
  data: object;
}

export interface DataStructureError {
  title: string;
  code: number;
  detail: string;
}
export interface BackendError {
  key: string;
  value: string;
}

export enum MimeType {
  pdf = 'application/pdf',
  doc = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  png = 'image/png',
  jpeg = 'image/jpeg',
  jpg = 'image/jpeg',
  gif = 'image/gif',
  xls = 'application/vnd.ms-excel',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ppt = 'application/vnd.ms-powerpoint',
  pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
}

export enum SystemEvent {
  Logout,
  ClickOnRootElement,
  LanguageChange,
}

export type SystemEventData = EventData<SystemEvent>;

export interface JSONApiPayload<P = object> {
  data: {
    attributes: P;
  };
}

export interface SidebarSettings {
  toggleIsCollapsedMobile: () => void;
  isCollapsedMobile: boolean;
  toggleIsHidden: () => void;
  isHidden: boolean;
}

export type NotificationsCount = number | undefined;

export interface StaticSettings {
  navChildren: ReturnType<FC>;
  onSearchSubmit: (query: string) => void;
  onLogoutClick: () => void;
  logoUrl: string;
  helpUrl: string;
  profileUrl: string;
  userAvatarUrl: string;
  organizationLogo?: string;
}
