// Core
import React, { useCallback, useState } from 'react';
import { Box } from '@theme-ui/components';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { size } from 'lodash';
import { toastr } from 'react-redux-toastr';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

import { FilesUploader } from 'src/v2/components/FilesUploader';
import { getUploadsListByDocumentIdFactory, retryUploadFile } from './documentSidebarUploadStore';

const styles = {
  container: {
    height: '80vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  text: {
    opacity: '0.69',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '1.21',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#4d6982',
    marginTop: '6px',
  },
  centralText: {
    marginTop: '10px',
    marginBottom: '14px',
  },
  slideItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '100vh',
    padding: '75px',
    lineHeight: '1.5',
    fontSize: '1.5em',
  },
};

interface Props<P> {
  document: P;
  uploadFile: ActionCreatorWithPayload<{
    file: File;
    document: P;
    documentId: string;
  }>;
}

export const DocumentSidebarUpload = <P extends object>({ document, uploadFile }: Props<P>) => {
  const [documentId] = useState(uuid());
  const getUploadsListByDocumentId = getUploadsListByDocumentIdFactory(documentId);
  const uploadingFiles = useSelector(getUploadsListByDocumentId);
  const dispatch = useDispatch();

  const onDropValid = useCallback(
    (file: File) => dispatch(uploadFile({ file, document, documentId })),
    [dispatch, document, documentId, uploadFile],
  );

  const onRetry = useCallback((fileId: string) => dispatch(retryUploadFile(fileId)), [dispatch]);

  const onValidationError = () => {
    toastr.error(` ${i18n(translationKeys.errors.FILE_FORMAT_ERROR)}`, '');
  };

  return (
    <Box sx={styles.container}>
      <FilesUploader
        files={uploadingFiles}
        onDropValid={onDropValid}
        onRetry={onRetry}
        onValidationError={onValidationError}
        disabled={size(uploadingFiles) > 0}
      >
        <Box sx={styles.text}>{i18n(translationKeys.forms.upload.dragDocumentToUpload)}</Box>
        <Box sx={{ ...styles.text, ...styles.centralText }}>
          {i18n(translationKeys.forms.upload.supportedFileTypes)} (.doc .docx .pdf, .jpg, .png, xls,
          xlsx, .ppt, .pptx)
        </Box>
        <Box sx={styles.text}>{i18n(translationKeys.forms.upload.scannedTextNote)}</Box>
      </FilesUploader>
    </Box>
  );
};
