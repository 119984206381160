import React, { useCallback } from 'react';
import cn from 'classnames';

import { createTextFromError } from 'src/v2/features/fileUpload';
import { UploadableFile } from 'src/v2/features/fileUpload/types';
import { Reload } from 'src/v2/icons/Reload';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

import styles from './FileUploading.module.css';

interface Props {
  file: UploadableFile;
  onRetry?: (fileId: string) => void;
  onCancel?: (fileId: string) => void;
}

export const FileUploading: React.FC<Props> = ({ file, onRetry, onCancel }) => {
  const handleRetry = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (onRetry) onRetry(file.id);
    },
    [file, onRetry],
  );

  const handleCancel = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (onCancel) onCancel(file.id);
    },
    [file, onCancel],
  );

  return (
    <div className="c-upload__progress">
      {!file.err ? (
        <div className="c-upload__progress--row c-upload__progress--justify-end">
          <div className="c-upload__progress--percent">{file.progress}%</div>
          <div className="c-upload__progress--spin">
            <Reload />
          </div>
        </div>
      ) : (
        <div className="c-upload__progress--row">
          <div className={cn('c-upload__progress--description', styles.error)}>
            {createTextFromError(file.err)}
          </div>
        </div>
      )}
      <div className="c-upload__progress--row">
        <div className="c-upload__progress--line">
          <div
            className="c-upload__progress--fill"
            style={{
              width: `${file.progress}%`,
            }}
          />
        </div>
      </div>
      <div className="c-upload__progress--row">
        <div className="c-upload__progress--description">
          {i18n(translationKeys.forms.upload.file)} {file.name}
        </div>
      </div>
      {file.err && onRetry && onCancel && (
        <div className="c-upload__progress--row">
          <div className={styles.controls}>
            <span className="c-nav__link" onClick={handleRetry}>
              {i18n(translationKeys.buttons.retry)}
            </span>
            <span className="c-nav__link" onClick={handleCancel}>
              {i18n(translationKeys.buttons.cancel)}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
