import { FC, useCallback, useContext } from 'react';
import { isUndefined } from 'lodash';
import cn from 'classnames';

import { DeviceContext } from 'src/common/features/device';
import { FolderCard } from 'src/v2/icons/FolderCard';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

interface Props {
  id: string;
  title: string;
  description?: string;
  owner: string;
  onClick: () => void;
  onTouch: () => void;
  onDoubleClick: () => void;
  onDelete: () => void;
  selected: boolean;
  isRemovable: boolean;
}

export const ProjectCard: FC<Props> = ({
  title,
  description,
  owner,
  onClick,
  onTouch,
  onDoubleClick,
  onDelete,
  selected,
  isRemovable,
}) => {
  const { isTouchable } = useContext(DeviceContext);
  const clickHandler = useCallback(() => {
    if (isTouchable) {
      onTouch();
    } else {
      onClick();
    }
  }, [isTouchable, onTouch, onClick]);

  const handleDelete = useCallback(
    (event) => {
      event.stopPropagation();
      onDelete();
    },
    [onDelete],
  );

  return (
    <div
      className={cn('c-card__item', { 'c-card__item--active': selected })}
      data-type="card"
      onClick={clickHandler}
      onDoubleClick={onDoubleClick}
    >
      {isRemovable && (
        <div className="c-card__remove" onClick={handleDelete}>
          <div className="c-card__remove--cross" />
        </div>
      )}
      <div className="c-card__header">
        <FolderCard />
        <div className="c-card__caption">
          <div className="c-card__title">{title}</div>
          {/*
            <div className="c-card__extra">
              {counts.map((text, index) => (
                <>
                  {index !== 0 && <div className="c-card__extra--separator" />}
                  <div className="c-card__extra--text">{text}</div>
                </>
              ))}
            </div>
          */}
        </div>
      </div>
      <div className="c-card__main">
        {!isUndefined(description) && <div className="c-card__text">{description}</div>}
      </div>
      <div className="c-card__footer">
        <div className="c-card__by">
          <b>{i18n(translationKeys.card.fields.createdBy)}</b>
          <br />
          {owner}
        </div>
        {/*
          <div className="c-card__document">
            <DocumentCheck />
            <div className="c-card__document--count">{`x${completedCount}`}</div>
          </div>
        */}
      </div>
    </div>
  );
};
