import React, { HTMLProps } from 'react';
import cn from 'classnames';

interface OwnProps {
  required?: boolean;
}

type Props = OwnProps & React.PropsWithChildren<HTMLProps<HTMLDivElement>>;

const classModifiers = {
  required: 'c-form__required',
};

export const CFormRow: React.FC<Props> = ({ children, required, className, ...other }) => {
  return (
    <div
      className={cn('c-form__row', className, {
        [classModifiers.required]: required,
      })}
      {...other}
    >
      {children}
    </div>
  );
};
