import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Document } from 'src/models/document';

import { getSearchUrlParams } from 'src/utils/search';
import { useQuery } from 'src/utils/hooks';
import { SearchParams } from 'src/api/searchApi';
import { RootState } from 'src/app/types';
import { SearchEntityType } from 'src/v2/features/search/store';
import { getSortDirection, getSortField } from 'src/common/sortStore';
import { useDefaultSortEffct } from 'src/common/hooks/useDefaultSort';
import { useChangeUrlSortParams } from 'src/v2/features/sharedEntity/hooks';
import { fetchSearchList } from 'src/v2/features/search/store/searchActions';

import { getSearchList, getSeeMoreType, isLoading, getError } from 'src/v2/features/search';
import { EntityType } from 'src/models/paper';
import { filterEntitiesByTypes, CardsList } from '../index';
import { DocumentsCardGrid } from '../DocumentsCardGrid';
import { ContractsCardGrid } from '../ContractsCardGrid';
import { TemplatesCardGrid } from '../TemplatesCardGrid';
import { filterTemplatesByTypes } from './SearchPage';
import { SearchLayout } from '../SearchLayout';

const titles = {
  [SearchEntityType.Document]: 'Documents',
  [SearchEntityType.Contract]: 'Contracts',
  [SearchEntityType.DocumentTemplate]: 'Document Templates',
  [SearchEntityType.ContractTemplate]: 'Contract Templates',
};

interface StateProps {
  list: Document[];
  isLoading: boolean;
  seeMoreType: SearchEntityType;
  error: string;
}

interface DispatchProps {
  fetchSearchList: (param: SearchParams) => void;
}

interface Props extends StateProps, DispatchProps {}

const getSeeMoreList = (list: Document[], type: SearchEntityType | null): Document[] => {
  if (!type) return [];

  const moreLists = {
    [SearchEntityType.Document]: filterEntitiesByTypes(list, EntityType.document),
    [SearchEntityType.Contract]: filterEntitiesByTypes(list, EntityType.contract),
    [SearchEntityType.DocumentTemplate]: filterTemplatesByTypes(list, EntityType.document),
    [SearchEntityType.ContractTemplate]: filterTemplatesByTypes(list, EntityType.contract),
  };

  return moreLists[type];
};

const areUrlParamsValid = (
  seeMoreType: SearchEntityType | null,
  seeMore: SearchEntityType | undefined,
) => (seeMoreType && seeMore) || (seeMore && Object.values(SearchEntityType).includes(seeMore));

const SearchSeeMorePage: React.FC<Props> = ({ list, seeMoreType, isLoading, fetchSearchList }) => {
  const query = useQuery();
  const [seeMoreList, setSeeMoreList] = useState<Document[]>([]);
  const { keyword, subSubType, subType, type, author, refineKeyword, seeMore } =
    getSearchUrlParams(query);

  const sortDirection = useSelector(getSortDirection);
  const sortField = useSelector(getSortField);
  useChangeUrlSortParams(sortDirection, sortField);
  useDefaultSortEffct();

  const seeMoreValue = seeMoreType || seeMore || null;

  useEffect((): void => {
    if (areUrlParamsValid(seeMoreType, seeMore)) {
      fetchSearchList({
        keyword,
        subSubType,
        subType,
        type,
        author,
        refineKeyword,
        sortBy: sortField,
        sortDirection,
      });
    }
  }, [
    seeMoreType,
    fetchSearchList,
    keyword,
    subSubType,
    subType,
    type,
    author,
    refineKeyword,
    seeMore,
    sortField,
    sortDirection,
  ]);

  useEffect(() => {
    const value = getSeeMoreList(list, seeMoreValue);
    setSeeMoreList(value);
  }, [list, seeMoreValue]);

  return (
    <SearchLayout isLoading={isLoading}>
      {areUrlParamsValid(seeMoreType, seeMore) && (
        <CardsList
          title={titles[seeMoreType || (seeMore as SearchEntityType)] || ''}
          count={seeMoreList.length}
          type={seeMoreType || SearchEntityType.Document}
        >
          {seeMoreType === SearchEntityType.Document && <DocumentsCardGrid list={seeMoreList} />}
          {seeMoreType === SearchEntityType.Contract && <ContractsCardGrid list={seeMoreList} />}
          {[SearchEntityType.DocumentTemplate, SearchEntityType.ContractTemplate].includes(
            seeMoreType,
          ) && <TemplatesCardGrid list={seeMoreList} />}
        </CardsList>
      )}
    </SearchLayout>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  list: getSearchList(state),
  seeMoreType: getSeeMoreType(state) || SearchEntityType.Document,
  isLoading: isLoading(state),
  error: getError(state),
});

const mapDispatchToProps: DispatchProps = {
  fetchSearchList,
};

const ConnectedSeeMorePage = connect(mapStateToProps, mapDispatchToProps)(SearchSeeMorePage);
export { ConnectedSeeMorePage as SearchSeeMorePage };
