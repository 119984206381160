import { createSelector } from '@reduxjs/toolkit';
import { isNull, isNil } from 'lodash';

import { getPapers } from 'src/v2/features/objectsStorage';
import { getUserId } from 'src/shared/auth';
import {
  convertPaperCompleteModelToPaperModel,
  convertPreviewPaperCompleteModelToPaperModel,
} from 'src/v2/features/sharedEntity/utils';
import { PaperCompleteModel } from 'src/models/paper';

export const getEntityById = createSelector(
  getPapers,
  getUserId,
  (papers, userId) =>
    <T, R>(entityId: string) => {
      if (isNull(userId)) {
        return null;
      }

      const completePaper: PaperCompleteModel<T, R> = !isNull(papers) ? papers[entityId] : null;

      if (isNil(completePaper)) {
        return null;
      }

      try {
        return convertPaperCompleteModelToPaperModel(completePaper, userId);
      } catch (e) {
        console.warn(e);
        return null;
      }
    },
);

export const getPreviewEntityById = createSelector(getPapers, (papers) => (entityId: string) => {
  const completePaper = !isNull(papers) ? papers[entityId] : null;

  if (isNull(completePaper)) {
    return null;
  }

  try {
    return convertPreviewPaperCompleteModelToPaperModel(completePaper);
  } catch (e) {
    console.warn(e);
    return null;
  }
});
