import React, { PropsWithChildren } from 'react';

interface Props<C = string> {
  value: C;
}

// This component should be only used inside switch, the only things it do it provide values props
// which is used inside Switch component
// In general this is lightweight copy of react-router Route
export const Case = <C extends any>({ children }: PropsWithChildren<Props<C>>): JSX.Element => {
  return <>{children}</>;
};
