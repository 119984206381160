import { isUndefined } from 'lodash';
import {
  useNavigateToFn,
  useCreateNavigateToFn,
} from 'src/v2/features/sidebarController/helpers-hooks';
import { book } from './book';

export const useNavigateToEditParticipant = (entityId?: string) =>
  useCreateNavigateToFn({
    params: { v: book.editDocumentParticipant, id: !isUndefined(entityId) ? entityId : null },
  });

export const useNavigateToInviteParticipant = (entityId?: string) =>
  useNavigateToFn({
    params: { v: book.inviteDocumentParticipant, id: !isUndefined(entityId) ? entityId : null },
  });

export const useNavigateToQRCode = (entityId?: string | null) =>
  useNavigateToFn({
    params: { v: book.documentQRCode, id: !isUndefined(entityId) ? entityId : null },
  });
