import React from 'react';
import { Image } from '@theme-ui/components';

import { Signature } from './Signature';
import { SignatureProps } from './types';

interface Props extends SignatureProps {
  stampUrl?: string;
}

const style = {
  stampImage: {
    ml: '16px',
    maxHeight: '48px',
  },
};
export const SignatureWithHanko: React.FC<Props> = ({ stampUrl, ...other }) => (
  <Signature {...other}>
    {stampUrl && (
      <div className="c-signature__img--column">
        <Image data-type="stamp-image" sx={style.stampImage} src={stampUrl} />
      </div>
    )}
  </Signature>
);
