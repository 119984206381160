import { FC } from 'react';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

interface CardInfoEmptyProps {
  onBillingCardClick: () => void;
}

export const PaymentCardInfoEmpty: FC<CardInfoEmptyProps> = ({
  onBillingCardClick,
}): JSX.Element | null => (
  <div className="c-setup__visa--info">
    <span className="c-setup__visa--link" onClick={onBillingCardClick}>
      {i18n(translationKeys.forms.billing.addBillingCard)}
    </span>
  </div>
);
