import {
  ActionCreatorWithPayload,
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { SignUpLightActionPayload } from 'src/v2/boundary/actionPayload/signup';
import { SignUpState } from 'src/app/types';
import { ObjectBase } from 'src/common/types';
import {
  AccountType,
  WizardStepV2,
  InviteType,
  QrCodeAccountRecoveryStep,
} from 'src/models/signUp';

import {
  OrganizationInformationFormPayload,
  ProfileFormPayload,
  ResendSmsPayload,
  SaveNotficiationsSettingsPayload,
  SetProfileAvatarPayload,
  SetStepPayload,
  SignUpAsyncPayload,
  SignUpInitialInfo,
  SignUpPayload,
  VerifyPhonePayload,
  FetchInitialInfoPayload,
  SetQrCodeAccountRecoveryStepPayload,
} from '../types';
import { isUndefined } from 'lodash';

interface UserDataFromToken {
  email: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  inviteType?: InviteType;
}

const signUpInitialState: SignUpState = {
  isLoading: false,
  stepNum: 1,
  maxSteps: 1,
  step: WizardStepV2.SignUp,
  email: null,
  firstName: null,
  lastName: null,
  phone: null,
  isPhoneVerified: false,
  companyName: null,
  isComplete: null,
  notificationsSettings: [],
  plan: null,
  period: null,
  token: null,
  inviteType: null,
  invite: undefined,
  entityType: null,
  entityId: null,
  qrCodeAccountRecoveryStep: QrCodeAccountRecoveryStep.Start,
  captureToken: null,
};

const signUp = createSlice({
  name: 'signUp',
  initialState: signUpInitialState,
  reducers: {
    startLoading: (state): void => {
      state.isLoading = true;
    },

    stopLoading: (state): void => {
      state.isLoading = false;
    },

    fillBasicData: (state, action: PayloadAction<SignUpPayload>): void => {
      const {
        email,
        firstName,
        lastName,
        phone,
        companyName,
        plan,
        period,
        entityType,
        entityId,
        step,
        captureToken,
      } = action.payload;
      state.email = email;
      state.firstName = firstName;
      state.lastName = lastName;
      state.phone = phone;
      state.step = isUndefined(step) ? WizardStepV2.Profile : step;
      state.plan = plan || null;
      state.period = period || null;
      state.entityType = entityType || null;
      state.entityId = entityId || null;
      state.captureToken = captureToken || null;
      if (companyName) state.companyName = companyName;
    },

    fetchNotificationsSettingsSuccess: (state, { payload }: PayloadAction<any>): void => {
      state.notificationsSettings = payload.data.attributes;
    },

    setAccountType: (state, action: PayloadAction<AccountType>): void => {
      state.accountType = action.payload;
    },

    setStep: (state, action: PayloadAction<SetStepPayload>): void => {
      const { step, stepNum, maxSteps } = action.payload;
      state.step = step;
      state.stepNum = stepNum;
      state.maxSteps = maxSteps;
    },

    setQrCodeAccountRecoveryStep: (
      state,
      action: PayloadAction<SetQrCodeAccountRecoveryStepPayload>,
    ): void => {
      const { qrCodeAccountRecoveryStep } = action.payload;
      state.qrCodeAccountRecoveryStep = qrCodeAccountRecoveryStep;
    },

    setIsComplete: (state, action: PayloadAction<boolean>): void => {
      state.isComplete = action.payload;
    },

    getStarted: (state, action: PayloadAction<SignUpInitialInfo>): void => {
      // TODO BE to provide method like getInitialRegistrationData
      // Use this endpoind to get accountType, maxSteps and first step here

      const { firstStep, stepCount, accountType } = action.payload;
      state.step = firstStep;
      state.accountType = accountType;

      state.maxSteps = stepCount;
      state.stepNum = 1;
    },

    setPhoneVerified: (state, action: PayloadAction<boolean>): void => {
      state.isPhoneVerified = action.payload;
    },

    prefillData: (state, action: PayloadAction<UserDataFromToken>): void => {
      const { email, phone, firstName, lastName, inviteType } = action.payload;
      state.email = email;
      if (phone) state.phone = phone;
      if (firstName) state.firstName = firstName;
      if (lastName) state.lastName = lastName;
      if (inviteType) state.inviteType = inviteType;
    },

    setToken: (state, action: PayloadAction<string>): void => {
      state.token = action.payload;
    },

    setInviteType: (state, action: PayloadAction<InviteType | null>): void => {
      state.inviteType = action.payload;
    },

    setEntityId: (state, action: PayloadAction<string | null>): void => {
      state.entityId = action.payload;
    },

    resetState: (state): void => {
      state.isLoading = signUpInitialState.isLoading;
      state.step = signUpInitialState.step;
      state.email = signUpInitialState.email;
      state.firstName = signUpInitialState.firstName;
      state.lastName = signUpInitialState.lastName;
      state.phone = signUpInitialState.phone;
      state.isPhoneVerified = signUpInitialState.isPhoneVerified;
      state.companyName = signUpInitialState.companyName;
      state.isComplete = signUpInitialState.isComplete;
      state.plan = signUpInitialState.plan;
      state.period = signUpInitialState.period;
      state.token = signUpInitialState.token;
      state.inviteType = signUpInitialState.inviteType;
    },

    setInvite: (state, action: PayloadAction<ObjectBase | undefined>): SignUpState => ({
      ...state,
      invite: action.payload,
    }),
  },
});

export const {
  startLoading,
  stopLoading,
  fillBasicData,
  getStarted,
  setIsComplete,
  resetState,
  setPhoneVerified,
  setAccountType,
  setStep,
  fetchNotificationsSettingsSuccess,
  prefillData,
  setToken,
  setInviteType,
  setEntityId,
  setInvite,
  setQrCodeAccountRecoveryStep,
} = signUp.actions;

export const fetchSignUpInitialInfoAction: ActionCreatorWithPayload<FetchInitialInfoPayload> =
  createAction('signup/fetchSignUpInitialInfo');

export const signUpAction: ActionCreatorWithPayload<SignUpAsyncPayload> =
  createAction('signup/signUpAction');

export const signUpLightAction: ActionCreatorWithPayload<SignUpLightActionPayload> = createAction(
  'signup/signUpLightAction',
);

export const verifyPhoneAction: ActionCreatorWithPayload<VerifyPhonePayload> = createAction(
  'signup/verifyPhoneAction',
);

export const resendSmsAsync: ActionCreatorWithPayload<ResendSmsPayload> =
  createAction('signup/resendSmsAsync');

export const saveProfileAction: ActionCreatorWithPayload<ProfileFormPayload> = createAction(
  'signup/saveProfileAction',
);
export const setProfileAvatarAction: ActionCreatorWithPayload<SetProfileAvatarPayload> =
  createAction('signup/setProfileAvatarAction');
export const saveOrgInfoAction: ActionCreatorWithPayload<OrganizationInformationFormPayload> =
  createAction('signup/saveOrgInfoAction');
export const saveNotificationSettingAction: ActionCreatorWithPayload<SaveNotficiationsSettingsPayload> =
  createAction('signup/saveNotificationSettingAction');
export const getNotificationsSettingsAction: ActionCreatorWithPayload<void> = createAction(
  'signup/getNotificationsSettingsAction',
);
export const fetchInfoFromToken: ActionCreatorWithPayload<string> = createAction(
  'signup/fetchInfoFromToken',
);

export const checkSignUpComplete = createAction('signup/checkSignUpComplete');

export default signUp.reducer;
