import { head, isUndefined, isEmpty } from 'lodash';

import {
  PaperParticipantModel,
  PaperUserModel,
} from 'src/v2/boundary/storageModels/paper/paperParticipant';
import { CardParticipantEntity } from 'src/v2/entities/participants';

export interface CardPaperParticipantModel<R> extends PaperParticipantModel<R> {
  cardPaperUser: PaperUserModel[];
}

export const convertCardPaperParticipantsModelToCardParticipantsEntity = <R>(
  cardPaperModel: CardPaperParticipantModel<R>[],
): CardParticipantEntity<R>[] => {
  return cardPaperModel
    .filter((participant) => !isEmpty(participant.cardPaperUser))
    .map((participant) => {
      const cardPaperUser = head(participant.cardPaperUser);
      if (isUndefined(cardPaperUser)) {
        throw Error(
          `convertCardPaperParticipantsModelToCardParticipantsEntity: Can't convert model - user not found in card participants list`,
        );
      }

      return {
        id: cardPaperUser.id,
        userId: cardPaperUser.user_id,
        roleId: participant.roleId,
        email: cardPaperUser.email,
        firstName: cardPaperUser.first_name,
        lastName: cardPaperUser.last_name,
        avatar: cardPaperUser.avatar,
      };
    });
};
