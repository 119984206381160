import { FC } from 'react';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { ListItem } from 'src/models/list';
import { NodeTree } from 'src/models/node';
import { ContextMenuItem } from 'src/v2/components/contextMenu';
import { MoveFolder } from 'src/v2/icons/MoveFolder';
import { ContextMenuProps } from 'src/v2/features/contextMenu/types';
import { getIsNotEditableFolder, isTemplatesFolder } from 'src/v2/features/quantumNavigator/utils';
import { useCanCreateFolderInTemplates } from 'src/v2/features/template/hooks';
import { EntityFolderContextMenu } from 'src/v2/features/sharedEntity/EntityFolderContextMenu';
import { useGetRootFolderId } from 'src/v2/features/sharedEntity/hooks';
import { useOpenModal } from 'src/v2/features/folderSelector/hooks';
import { ModalType } from 'src/v2/features/modal/types';
import { Folder } from 'src/models/folder';
import { useNavigateToTemplateFolder } from 'src/v2/features/quantumNavigator/hooks';

export const TemplateFolderContextMenu: FC<ContextMenuProps> = (props) => {
  const { closeOnClick, payload } = props;
  const folder = payload as NodeTree<ListItem & Folder>;
  const templateRootFolderId = useGetRootFolderId(isTemplatesFolder);
  const isRootFolder = getIsNotEditableFolder(folder);
  const isCanCreateFolder = useCanCreateFolderInTemplates(folder);

  const handleMove = useOpenModal({
    type: ModalType.TemplateMoveFolderTo,
    menuItemFolderId: templateRootFolderId,
    targetId: folder.data.id,
  });

  const handleRootFolderClick = useNavigateToTemplateFolder();

  return (
    <EntityFolderContextMenu
      {...props}
      isRootFolder={isRootFolder}
      isNewFolderDisabled={!isCanCreateFolder}
      onRootFolderClick={handleRootFolderClick}
    >
      <ContextMenuItem
        icon={MoveFolder}
        text={i18n(translationKeys.contextMenu.moveFolder)}
        onClick={closeOnClick(handleMove)}
        data-id="moveFolder-menuButton"
        disabled={!folder.data.isRemovable || isRootFolder}
      />
    </EntityFolderContextMenu>
  );
};
