import { FC } from 'react';
import { FormStateMap } from 'redux-form';
import { ToastrState } from 'react-redux-toastr';

import { ObjectBase } from 'src/common/types';
import {
  AccountType,
  InviteType,
  RedirectEntityType,
  NotificationSetting,
  WizardStepV2,
  QrCodeAccountRecoveryStep,
} from 'src/models/signUp';
import {
  BillingDetailsPayload,
  BillingInfo,
  BillingPeriod,
  SubscriptionPlan,
  SubscriptionStatus,
} from 'src/models/billing';
import { SortDirection, SortedFields } from 'src/models/sort';
import { ListItem } from 'src/models/list';
import { NodeTree } from 'src/models/node';
import { EntityLock } from 'src/models/document';
import { SignatureId, SignatureFont } from 'src/models/signature';
import { UserState } from 'src/v2/features/organization/types';
import { ContextMenuProps } from 'src/v2/features/contextMenu/types';
import { PaymentError, WizardStep } from 'src/v2/features/checkout/types';
import { ChatMessage, RoomPayload } from 'src/v2/features/chat/types';
import { FileUploadState } from 'src/v2/features/fileUpload/types';
import { Counters, SearchEntityType } from 'src/v2/features/search/store/searchTypes';
import { ObjectsContainer } from 'src/v2/features/objectsStorage/types';
import { EntityType } from 'src/models/paper';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { ModalType } from 'src/v2/features/modal/types';

export interface RootState {
  auth: AuthState & PersistPartial;
  billing: BillingState;
  chat: ChatState;
  checkout: CheckoutState;
  common: CommonState;
  contextMenu: ContextMenuState<ContextMenuBook>;
  createFromTemplate: DefaultSliceState;
  documentAttachmentsList: AttachmentsListState;
  documentDetails: DocumentState;
  documentInviteParticipant: DefaultSliceState;
  documentList: DocumentListState;
  documentParticipants: DefaultSliceStateWithData;
  documentRemoveParticipant: DefaultSliceState;
  documentSidebarUpload: FileUploadState;
  documentUpdateParticipant: DefaultSliceState;
  documentWorkflow: DefaultSliceStateWithData;
  folderSelector: FolderSelectorState;
  form: FormStateMap;
  hankoSignature: HankoSignatureState;
  layout: LayoutState;
  modalV2: ModalV2State;
  notifications: NotificationsState;
  objectsStorage: ObjectsStorageState;
  organization: OrganizationState;
  profile: ProfileState;
  quantumNavigator: QuantumNavigatorState;
  search: SearchPageState;
  signature: SignatureState;
  signUp: SignUpState;
  sort: SortState;
  templates: TemplatesState;
  toastr: ToastrState;
  uploadAttachments: FileUploadState;
  objectsList: ObjectsListState;
  templateSidebarUpload: FileUploadState;
  templatesList: TemplatesListState;
  project: ProjectState;
  accountRecovery: AccountRecoveryState;
  qrCodeAccountRecovery: QrAccountRecoveryState;
  network: NetworkState;
}

export interface DefaultSliceState {
  isLoading: boolean;
  error: string;
}

export interface DefaultSliceStateWithData extends DefaultSliceState {
  data: ObjectBase[];
}

export interface DefaultSliceStateWithObject extends DefaultSliceState {
  data: ObjectBase | null;
}

export interface AuthState {
  token: string | null;
  userId: string | null;
  companyId: string | null;
  isLoginAvailable: boolean | null;
  isEmailSent: boolean;
  isLoading: boolean;
  error: null | string;
  notification: Notification | null;
  isSigningup: boolean;
  data: ObjectBase | null;
  companyName: string | null;
  signatureName: string | null;
  companyTitle: string | null;
}

export type Notification = {
  type: string;
  source: string;
  title: string;
  message: string;
  shouldStepBack?: boolean;
};

export interface SignUpState {
  isLoading: boolean;
  step: WizardStepV2;
  stepNum: number;
  maxSteps: number;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  phone: string | null;
  isPhoneVerified: boolean;
  companyName: string | null;
  isComplete: boolean | null;
  accountType?: AccountType;
  notificationsSettings: NotificationSetting[];
  plan: SubscriptionPlan | null;
  period: BillingPeriod | null;
  token: string | null;
  inviteType: InviteType | null;
  invite: ObjectBase | undefined;
  entityType: RedirectEntityType | null;
  entityId: string | null;
  qrCodeAccountRecoveryStep: QrCodeAccountRecoveryStep;
  captureToken: string | null;
}

export interface SortState {
  sortDirection: SortDirection;
  sortField: SortedFields;
}

export enum FilterMode {
  All,
  Open,
}
export interface LayoutState {
  filterMode: FilterMode;
  isLoading: boolean;
}

export interface DocumentListState extends DefaultSliceStateWithData {
  isCreating: boolean;
  deletedId: string | null;
}

export interface DocumentState extends DefaultSliceStateWithObject {
  activeLock: EntityLock | null;
  isFieldUpdating: boolean;
  isFileFullyLoaded?: boolean;
}

export interface OrganizationState {
  isLoading: boolean;
  error: null | string;
  data: ObjectBase[] | null;
  users: UserState[];
  foundUser: ObjectBase | null;
  currentOrgId: string | null;
  currentOrgOwnerId: string | null;
}

export interface FolderSelectorState {
  folderId: string | null;
  activeFolderId: string | null;
  isModalActive: boolean;
  targetId: string | null;
}

export type ContextMenuRegistryType = Record<string, FC<ContextMenuProps>>;

// Only strings are supported by Switch/Case:value component
export enum ContextMenuBook {
  Document = 'document',
  Contract = 'contract',
  Template = 'template',
  SystemTemplate = 'systemTemplate',
  DocumentFolder = 'documentFolder',
  ContractFolder = 'contractFolder',
  TemplateFolder = 'templateFolder',
  SharedFolder = 'sharedFolder',
  DocumentProject = 'documentProject',
  ContractProject = 'contractProject',
}

export enum DocumentSidebarMode {
  hidden,
  viewTemplates,
  viewEditDocumentForm,
  viewCreateDocumentForm,
  viewDocumentMetadata,
  viewEditParticipantForm,
  viewInviteForm,
  viewShareForm,
  viewUpload,
  viewRemoveParticipantConfirmation,
  viewRemoveDocumentConfirmation,
  viewRemoveTemplateConfirmation,
  viewShareDocumentForm,
  viewSidebarMenu,
  viewNotifications,
  viewEditUsersSection,
}

export interface ProfileState extends DefaultSliceStateWithObject {
  signature: ObjectBase | null;
}

export interface ModalV2State {
  type: ModalType | null;
  className?: string;
}

export interface AccountRecoveryState {
  message: string;
}

export interface QrAccountRecoveryState {
  message: string;
}

export interface QuantumNavigatorState {
  parentFolder: NodeTree<ListItem> | null;
  renameFolder: NodeTree<ListItem> | null;
}

export interface BillingState extends DefaultSliceState {
  billingInfo: BillingInfo;
  subscriptionStatus: SubscriptionStatus | null;
  basePrice: number;
  salesTax: number;
  totalPrice: number;
  showUpgradeModal: boolean;
}

export interface CheckoutState extends DefaultSliceState {
  cardError: PaymentError | null;
  wizardStep: WizardStep;
  plan: SubscriptionPlan | null;
  period: BillingPeriod | null;
  billingDetails: BillingDetailsPayload | null;
  numberOfSeats?: number;
}

export interface ChatState {
  currentMessage: string;
  currentChatBase: ObjectBase | null;
  currentChatMessages: ChatMessage[];
  subscribedRoomPayload: RoomPayload | null;
  currentRoom: ObjectBase | null;
  isLoading: boolean;
  isSending: boolean;
  isDisconnected: boolean;
  shouldStickToBottom: boolean;
}

export interface CommonState {
  isSidebarOpen: boolean;
  isSidebarHidden: boolean;
}

export interface ContextMenuState<BookType> {
  sourceType: BookType | undefined;
  payload: unknown | undefined;
  position: Pick<MouseEvent, 'x' | 'y'>;
}

export interface AttachmentsListState {
  data: ObjectBase[];
  isLoading: boolean;
  error: string;
  documentId: string | null;
}

export interface NotificationsState {
  newCount: number;
  totalCount: number;
  notificationList: ObjectBase[];
}

export interface SignatureState extends DefaultSliceStateWithObject {
  signatureId: SignatureId | null;
  hash: string;
  legalName: string | null;
  font: SignatureFont | null;
  signedBy: string | null;
  companyName: string | null;
  companyTitle: string | null;
  includeCompanyMeta: boolean;
  signaturePreview: string | null;
  signatureFile: File | null;
  uploadedImageUrl: string | null;
}

export interface HankoSignatureState extends DefaultSliceState {
  localisedSignature: boolean;
  includeHanko: boolean;
  hankoFile: File | null;
  hankoPreview: string | null;
  uploadedImageUrl: string | null;
}

export interface TemplatesState extends DefaultSliceState {
  list: ObjectBase[];
  folders: ObjectBase[];
  data: ObjectBase | null;
  chosenFolder: string;
  chosenType: EntityType | null;
  createFolderMode: boolean;
  deletedId: string | null;
}

export interface SearchPageState extends DefaultSliceState {
  keyword: string;
  list: ObjectBase[];
  counters: Counters;
  authors: ObjectBase[];
  subTypes: string[];
  seeMoreType: SearchEntityType | null;
}

export interface ObjectsStorageState {
  objects: Record<string, ObjectsContainer>;
}

export type ObjectsListState = DefaultSliceStateWithData;

export interface TemplatesListState {
  userTemplatesList: ObjectBase[];
  sharedTemplatesList: ObjectBase[];
  systemTemplatesList: ObjectBase[];
}

export interface ProjectState extends DefaultSliceStateWithData {
  deletedId: string | null;
}

export interface NetworkState {
  isOnline: boolean;
}
