/* eslint-disable no-param-reassign */
import { createSelector } from '@reduxjs/toolkit';
import { isNil, sortBy } from 'lodash';

import { getFolders as getFoldersFromObjectStorage } from 'src/v2/features/objectsStorage';
import { RootState } from 'src/app/types';
import { Folder } from 'src/models/folder';
import { listToMapWithChildren, mapWithChildrenToTree, listToTree } from 'src/utils/tree';
import { NodeTree } from 'src/models/node';
import { ListItem } from 'src/models/list';

import {
  uniqueSharedContractsName,
  uniqueSharedDocumentsName,
  uniqueSharedProjectsName,
  uniqueSharedTemplatesName,
} from 'src/v2/features/quantumNavigator/consts';
import { getProjects } from 'src/v2/features/objectsStorage/selectors';
import { ProjectsFolderDTO } from 'src/v2/boundary/responseDTO/project';
import { getObject } from 'src/v2/features/objectsStorage/objectsStorageSlice';
import { DbRecordType } from 'src/v2/features/objectsStorage/types';
import { FolderNodes } from '../types';
import {
  adaptFoldersToNodes,
  FolderType,
  getFolderTypeByModelBySimpleFolder,
  isContractFolder,
  isDocumentFolder,
  isProjectsFolder,
  isSharedContractFolder,
  isSharedDocumentFolder,
  isSharedProjectsFolder,
  isSharedTemplatesFolder,
  isTemplatesFolder,
} from '../utils';

const indexByType = {
  [FolderType.Documents]: 1,
  [FolderType.Contracts]: 2,
  [FolderType.Templates]: 3,
  [FolderType.Projects]: 4,
  [FolderType.SharedDocuments]: 99,
  [FolderType.SharedContracts]: 99,
  [FolderType.SharedTemplates]: 99,
  [FolderType.SharedProjects]: 99,
  [FolderType.Other]: 99,
  [FolderType.Root]: 0,
};

const addPriorityIndex = (folder: Folder) => {
  const type = getFolderTypeByModelBySimpleFolder({
    id: folder.folder_id,
    name: folder.name,
    parentId: folder.parent_id,
  });

  return {
    ...folder,
    index: indexByType[type],
  };
};

type RootFoldersMap = Record<
  FolderType.Documents | FolderType.Contracts | FolderType.Templates | FolderType.Projects,
  string
>;

const createRootFolderHashMap = (folders: Folder[], rootFoldersIdByType: RootFoldersMap) => {
  return folders.reduce((idByTypeMap, folder) => {
    if (
      isDocumentFolder({
        name: folder.name,
        parentId: folder.parent_id,
      })
    ) {
      idByTypeMap[FolderType.Documents] = folder.folder_id;
    }

    if (
      isContractFolder({
        name: folder.name,
        parentId: folder.parent_id,
      })
    ) {
      idByTypeMap[FolderType.Contracts] = folder.folder_id;
    }

    if (
      isTemplatesFolder({
        name: folder.name,
        parentId: folder.parent_id,
      })
    ) {
      idByTypeMap[FolderType.Templates] = folder.folder_id;
    }

    if (
      isProjectsFolder({
        name: folder.name,
        parentId: folder.parent_id,
      })
    ) {
      idByTypeMap[FolderType.Projects] = folder.folder_id;
    }

    return idByTypeMap;
  }, rootFoldersIdByType);
};

const modifySharedFoldersParents = (foldersMap: RootFoldersMap) => (folder: Folder) => {
  if (
    isSharedDocumentFolder({
      name: folder.name,
      parentId: folder.parent_id,
    })
  ) {
    return {
      ...folder,
      parent_id: foldersMap[FolderType.Documents],
      name: uniqueSharedDocumentsName,
    };
  }

  if (
    isSharedContractFolder({
      name: folder.name,
      parentId: folder.parent_id,
    })
  ) {
    return {
      ...folder,
      parent_id: foldersMap[FolderType.Contracts],
      name: uniqueSharedContractsName,
    };
  }

  if (
    isSharedTemplatesFolder({
      name: folder.name,
      parentId: folder.parent_id,
    })
  ) {
    return {
      ...folder,
      parent_id: foldersMap[FolderType.Templates],
      name: uniqueSharedTemplatesName,
    };
  }

  if (
    isSharedProjectsFolder({
      name: folder.name,
      parentId: folder.parent_id,
    })
  ) {
    return {
      ...folder,
      parent_id: foldersMap[FolderType.Projects],
      name: uniqueSharedProjectsName,
    };
  }

  return folder;
};

const moveSharedFoldersInsideParents = (folders: Folder[]): Folder[] => {
  const rootFoldersIdByType = createRootFolderHashMap(folders, {
    [FolderType.Documents]: '',
    [FolderType.Contracts]: '',
    [FolderType.Templates]: '',
    [FolderType.Projects]: '',
  });

  const result = sortBy(
    folders.map(addPriorityIndex).map(modifySharedFoldersParents(rootFoldersIdByType)),
    ['index'],
  );

  return result;
};
export const getFoldersSorted: (state: RootState) => Folder[] = createSelector(
  getFoldersFromObjectStorage,
  (data) => {
    const folders = data
      ? Object.keys(data).map(
          (folderKey) =>
            ({
              ...data[folderKey].attributes,
              // TODO: Remove when backend will have this property
              isRemovable: isNil(data[folderKey].attributes.isRemovable)
                ? true
                : data[folderKey].attributes.isRemovable,
            } as unknown as Folder),
        )
      : [];
    return moveSharedFoldersInsideParents(folders);
  },
);

export const getFolderByIdFn = createSelector(
  getFoldersFromObjectStorage,
  (folders) => (id: string) => {
    const folder: Folder | null = getObject(
      {
        id,
        type: DbRecordType.Folder,
      },
      {
        [DbRecordType.Folder]: folders,
      },
    );

    return folder;
  },
);

export const getProjectsAsFoldersSorted: (state: RootState) => Folder[] = createSelector(
  getProjects,
  (data) => {
    const typedData = data as unknown as Record<string, ProjectsFolderDTO>;
    const folders: Folder[] = typedData
      ? Object.keys(typedData).map((folderKey) => {
          const project = typedData[folderKey].attributes;
          return {
            created_at: project.created_at,
            folder_id: project.project_id,
            mount_id: '',
            name: project.title,
            parent_id: project.parent_id,
            state: 'active',
            // TODO: Remove when backend will have this property
            isRemovable: isNil(project.isRemovable) ? true : project.isRemovable,
          };
        })
      : [];

    return moveSharedFoldersInsideParents(folders);
  },
);

export const getFolderTreeCombiner = (data: Folder[]) => {
  const nodes = adaptFoldersToNodes(data);
  return listToTree<FolderNodes>(nodes);
};

export const getFoldersTree: (state: RootState) => NodeTree<FolderNodes>[] = createSelector(
  getFoldersSorted,
  getFolderTreeCombiner,
);

export const getFolderTreeByIdCombiner = (data: Folder[], id: string) => {
  const nodes = adaptFoldersToNodes(data);

  const map = listToMapWithChildren(nodes);
  return mapWithChildrenToTree(map, id);
};

export const getState = (state: RootState) => state.quantumNavigator;
export const getFolderId = (model: NodeTree<ListItem> | null): string | null =>
  model && model.data.id;

export const getParentFolderForNew = (state: RootState) => getState(state).parentFolder;
export const getFolderForRename = (state: RootState) => getState(state).renameFolder;

export const getIsParentForNewFactory = (id: string) => (state: RootState) =>
  getFolderId(getParentFolderForNew(state)) === id;

export const getIsRenameModeFactory = (id: string) => (state: RootState) =>
  getFolderId(getFolderForRename(state)) === id;
