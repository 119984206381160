import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { toastr } from 'react-redux-toastr';
import { get, isNull, isUndefined } from 'lodash';
import { CreatePaymentMethodCardData, PaymentMethod, Stripe, StripeError } from '@stripe/stripe-js';

import { RootState, CheckoutState } from 'src/app/types';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import {
  cancelSubscriptionApi,
  submitBillingInfoApi,
  subscribeApi,
  upgradeSubscriptionApi,
  retrySubscribeApi,
} from 'src/api/billingApi';
import { defaultActionsFactory } from 'src/utils/defaultSlice';
import {
  BillingDetailsPayload,
  BillingPeriod,
  BillingDetailsWithPricingUpdatePayload,
  SubscriptionPlan,
  SubscribePayload,
  BillingInfoWithSubscriptionPayload,
  CheckoutBillingInfoPayload,
} from 'src/models/billing';
import { toJSONApiPayload } from 'src/common/object-utils';
import { fetchPricingInfo } from 'src/v2/features/billing/store/billingReducers';
import { setBillingDetails as setBillingDetailsInfo } from 'src/v2/features/billing/store/billingReducers';
import {
  normalizeBillingDetails,
  normalizeBillingSubscription,
} from 'src/v2/features/billing/store/billingNormalizers';
import { marketingService } from 'src/common/marketing';

import {
  InvoiceResponse,
  PaymentError,
  PaymentRequest,
  PaymentIntent,
  PaymentResponse,
  SubscriptionResponse,
  PaymentStatus,
  StripeConfirmResponse,
  WizardStep,
} from './types';
import {
  emitPaymentSuccess,
  fetchBillingDetails,
  getLatestInvoiceId,
  setSubscriptionSuccess,
} from '../billing';
import { convertPlanAndPeriod } from './config';

const checkoutInitialState: CheckoutState = {
  isLoading: false,
  error: '',
  cardError: null,
  wizardStep: WizardStep.BillingInformation,
  plan: null,
  period: null,
  billingDetails: null,
  numberOfSeats: undefined,
};

const getState = (state: RootState): CheckoutState => state.checkout;
export const getIsLoading = createSelector(getState, (state) => state.isLoading);
export const getError = createSelector(getState, (state) => state.error);
export const getCardError = createSelector(getState, (state) => state.cardError);
export const getWizardStep = createSelector(getState, (state) => state.wizardStep);
export const getBillingDetails = createSelector(getState, (state) => state.billingDetails);
export const getPlan = createSelector(getState, (state) => state.plan);
export const getPeriod = createSelector(getState, (state) => state.period);
export const getNumberOfSeats = createSelector(getState, (state) =>
  !isUndefined(state.numberOfSeats) ? state.numberOfSeats : 1,
);

const { onSuccess, onError, onStart } = defaultActionsFactory();
const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: checkoutInitialState,
  reducers: {
    subscribeStart: onStart,
    subscribeFailed: onError,
    subscribeSuccess: onSuccess,
    cardFailedError(state: CheckoutState): void {
      state.cardError = PaymentError.Card;
    },
    cardSuccess(state: CheckoutState): void {
      state.cardError = null;
    },
    setPlanAndPeriod: (
      state: CheckoutState,
      action: PayloadAction<{ plan: SubscriptionPlan; period: BillingPeriod }>,
    ) => {
      const { plan, period } = action.payload;
      state.plan = plan;
      state.period = period;
    },
    setBillingDetails: (state: CheckoutState, action: PayloadAction<BillingDetailsPayload>) => {
      state.billingDetails = action.payload;
    },
    setNumberOfSeats: (state, action: PayloadAction<number>): void => {
      state.numberOfSeats = action.payload;
    },
  },
});

export const checkoutReducer = checkoutSlice.reducer;
export const {
  subscribeStart,
  subscribeFailed,
  subscribeSuccess,
  cardFailedError,
  cardSuccess,
  setPlanAndPeriod,
  setBillingDetails,
  setNumberOfSeats,
} = checkoutSlice.actions;

export const subscribe = createAction<SubscribePayload>('checkout/subscribe');

export const submitBillingInfoWithSubscription = createAction<BillingInfoWithSubscriptionPayload>(
  'checkout/submitBillingInfoWithSubscription',
);
export const upgradeSubscription = createAction<SubscribePayload>('checkout/upgradeSubscription');

export const retryFailedSubscription = createAction<SubscribePayload>(
  'checkout/retryFailedSubscription',
);
export const submitBillingInfoWithPricingUpdate =
  createAction<BillingDetailsWithPricingUpdatePayload>(
    'checkout/submitBillingInfoWithPricingUpdate',
  );
export const submitBillingInfo = createAction<CheckoutBillingInfoPayload>(
  'checkout/submitBillingInfo',
);
export const cancelSubscription = createAction('checkout/cancelSubscription');

function* submitBillingInfoWithPricingUpdateSaga(
  action: PayloadAction<BillingDetailsWithPricingUpdatePayload>,
) {
  try {
    yield put(subscribeStart());
    yield call(submitBillingInfoApi, toJSONApiPayload<BillingDetailsPayload>(action.payload));
    yield put(setBillingDetailsInfo(normalizeBillingDetails(action.payload)));
    const { plan, billingPeriod, numberOfSeats } = action.payload;
    yield put(fetchPricingInfo({ plan, billingPeriod, numberOfSeats }));
    yield put(subscribeSuccess());
  } catch (error) {
    const message = get(error, 'message') || i18n(translationKeys.errors.SOMETHING_WENT_WRONG);
    yield call(toastr.error, i18n(translationKeys.errors.MESSAGE_TITLE), message);
    yield put(subscribeFailed(message));
  }
}

function* submitBillingInfoSaga(action: PayloadAction<CheckoutBillingInfoPayload>) {
  yield put(cardSuccess());
  yield put(subscribeStart());

  try {
    const { numberOfSeats, ...billingInfoRequest } = action.payload;
    yield call(submitBillingInfoApi, toJSONApiPayload<BillingDetailsPayload>(billingInfoRequest));
    yield put(setBillingDetailsInfo(normalizeBillingDetails(action.payload)));
    if (!isUndefined(numberOfSeats)) {
      yield put(setNumberOfSeats(numberOfSeats));
    }

    const result = convertPlanAndPeriod(action.payload.planAndPeriod);
    if (result) {
      const [plan, period] = result;
      yield put(setPlanAndPeriod({ plan, period }));
      yield put(setBillingDetails(action.payload));
    }
    yield put(cardSuccess());
    yield put(subscribeSuccess());
  } catch (error) {
    const message = get(error, 'message') || i18n(translationKeys.errors.SOMETHING_WENT_WRONG);
    yield call(toastr.error, i18n(translationKeys.errors.MESSAGE_TITLE), message);
    yield put(subscribeFailed(message));
  }
}

function* subscribeAction({
  plan,
  period,
  paymentMethodId,
  numberOfSeats,
}: {
  plan: SubscriptionPlan;
  period: BillingPeriod;
  paymentMethodId?: string;
  numberOfSeats?: number;
}): SagaIterator<PaymentIntent> {
  if (isUndefined(paymentMethodId))
    return {
      status: PaymentStatus.RequiresPaymentMethod,
      client_secret: '',
    };

  const response: PaymentResponse = yield call(
    subscribeApi,
    paymentMethodId,
    plan,
    period,
    numberOfSeats,
  );
  return response.data.latest_invoice.payment_intent;
}

export function* upgradeSubscriptionAction({
  plan,
  period,
}: {
  plan: SubscriptionPlan;
  period: BillingPeriod;
}): SagaIterator<PaymentIntent> {
  const response: SubscriptionResponse = yield call(upgradeSubscriptionApi, plan, period);
  const normalizedResponse: PaymentResponse = normalizeBillingSubscription(response);

  return normalizedResponse.data.latest_invoice.payment_intent;
}

function* retryFailedSubscriptionAction({
  latestInvoiceId,
  paymentMethodId,
}: {
  latestInvoiceId: string | null;
  paymentMethodId?: string;
}): SagaIterator<PaymentIntent> {
  if (isNull(latestInvoiceId)) {
    console.warn('Unexpected input, invoiceId should be string');

    return {
      status: PaymentStatus.RequiresAction,
      client_secret: '',
    };
  }

  if (isUndefined(paymentMethodId)) {
    console.warn('Unexpected input, paymentMethodId required');

    return {
      status: PaymentStatus.RequiresAction,
      client_secret: '',
    };
  }

  const response: InvoiceResponse = yield call(retrySubscribeApi, paymentMethodId, latestInvoiceId);
  return response.data.payment_intent;
}

function* validateOtp(stripe: Stripe, clientSecret: string, paymentMethodId: string) {
  const confirmResponse: StripeConfirmResponse = yield call(
    stripe.confirmCardPayment,
    clientSecret,
    {
      payment_method: paymentMethodId,
    },
  );
  if (get(confirmResponse, 'paymentIntent.status') !== PaymentStatus.Succeeded) {
    yield put(cardFailedError());
    yield put(subscribeFailed(i18n(translationKeys.errors.OTP_VALIDATION_FAILED)));
    yield put(fetchBillingDetails());
    console.error(i18n(translationKeys.errors.UNEXPECTED_STATUS), confirmResponse);
    return get(confirmResponse, 'error.message');
  }

  return null;
}

export function* doPaymentSaga(
  action: PayloadAction<SubscribePayload>,
  subscribeAction: ({
    plan,
    period,
    latestInvoiceId,
    paymentMethodId,
    numberOfSeats,
  }: PaymentRequest) => SagaIterator<PaymentIntent>,
) {
  const { cardNumberElement, plan, period, numberOfSeats, stripe } = action.payload;
  yield put(cardSuccess());
  yield put(subscribeStart());

  try {
    const values: BillingDetailsPayload = yield select(getBillingDetails);
    const latestInvoiceId: string | null = yield select(getLatestInvoiceId);

    const createPaymentMethodPayload: CreatePaymentMethodCardData = {
      type: 'card',
      card: cardNumberElement,
      billing_details: {
        name: `${values.first_name} ${values.last_name}`,
        email: values.email,
        address: {
          line1: values.address.line1,
          city: values.address.city,
          state: values.address.state,
          country: values.address.country,
          postal_code: values.address.postal_code,
        },
      },
    };

    const { error, paymentMethod }: { paymentMethod?: PaymentMethod; error?: StripeError } =
      yield call(stripe.createPaymentMethod, createPaymentMethodPayload);

    if (error) {
      yield put(cardFailedError());
      yield put(
        subscribeFailed(error.message || i18n(translationKeys.errors.UNKNOWN_STRIPE_ERROR)),
      );
      return;
    }

    if (paymentMethod) {
      const paymentIntent: PaymentIntent = yield call(subscribeAction, {
        plan,
        period,
        latestInvoiceId,
        paymentMethodId: paymentMethod.id,
        numberOfSeats,
      });

      if (paymentIntent.status === PaymentStatus.RequiresAction) {
        const error: string | null = yield call(
          validateOtp,
          stripe,
          paymentIntent.client_secret,
          paymentMethod.id,
        );

        if (error) {
          yield put(cardFailedError());
          yield put(subscribeFailed(error));
          return;
        }
      }

      yield put(setSubscriptionSuccess());
      yield put(fetchBillingDetails());
      yield put(cardSuccess());
      yield put(subscribeSuccess());
      yield call(
        toastr.success,
        i18n(translationKeys.messages.success),
        i18n(translationKeys.messages.subscribed),
      );
      yield delay(1000);
      yield call(emitPaymentSuccess);
      yield call(marketingService.signupWithPaymentComplete, plan, period);
    }
  } catch (error) {
    yield put(cardFailedError());
    const message = get(error, 'message') || i18n(translationKeys.errors.SOMETHING_WENT_WRONG);
    yield put(subscribeFailed(message));
  }
}

function* cancelSubscriptionSaga() {
  yield put(cardSuccess());
  yield put(subscribeStart());
  try {
    yield call(cancelSubscriptionApi);
    yield put(cardSuccess());
    yield put(subscribeSuccess());
  } catch (error) {
    const message = get(error, 'message') || i18n(translationKeys.errors.SOMETHING_WENT_WRONG);
    yield put(cardFailedError());
    yield call(toastr.error, i18n(translationKeys.errors.MESSAGE_TITLE), message);
    yield put(subscribeFailed(message));
  }
}

function* subscribeSaga(action: PayloadAction<SubscribePayload>) {
  yield call(doPaymentSaga, action, subscribeAction);
}

function* submitBillingInfoWithSubscriptionSaga(
  action: PayloadAction<BillingInfoWithSubscriptionPayload>,
) {
  const { address, email, phone, first_name, last_name, numberOfSeats, planAndPeriod } =
    action.payload;
  const submitBillingInfoAction = {
    type: action.type,
    payload: { address, email, phone, first_name, last_name, numberOfSeats, planAndPeriod },
  };

  try {
    yield call(submitBillingInfoSaga, submitBillingInfoAction);
    yield call(doPaymentSaga, action, subscribeAction);
  } catch (error) {
    const message = get(error, 'message') || i18n(translationKeys.errors.SOMETHING_WENT_WRONG);
    yield call(toastr.error, i18n(translationKeys.errors.MESSAGE_TITLE), message);
  }
}

export function* upgradeSubscriptionSaga(action: PayloadAction<SubscribePayload>) {
  yield call(doPaymentSaga, action, upgradeSubscriptionAction);
}

export function* retryFailedSubscriptionSaga(action: PayloadAction<SubscribePayload>) {
  yield call(doPaymentSaga, action, retryFailedSubscriptionAction);
}

export function* watchCheckoutSagas(): SagaIterator {
  yield takeLatest(submitBillingInfoWithPricingUpdate, submitBillingInfoWithPricingUpdateSaga);
  yield takeLatest(submitBillingInfo, submitBillingInfoSaga);
  yield takeLatest(subscribe, subscribeSaga);
  yield takeLatest(submitBillingInfoWithSubscription, submitBillingInfoWithSubscriptionSaga);
  yield takeLatest(upgradeSubscription, upgradeSubscriptionSaga);
  yield takeLatest(retryFailedSubscription, retryFailedSubscriptionSaga);
  yield takeLatest(cancelSubscription, cancelSubscriptionSaga);
}
