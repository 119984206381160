import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { NotificationsCountContext } from 'src/common/contexts/NotificationsCountContext';
import { NotificationsCount } from 'src/common/types';
import { getNotificationNewCount } from 'src/v2/features/notifications';

export const NotificationsCountContextProvider: FC = ({ children }) => {
  const notificatinsCount: NotificationsCount = useSelector(getNotificationNewCount);

  return (
    <NotificationsCountContext.Provider value={notificatinsCount}>
      {children}
    </NotificationsCountContext.Provider>
  );
};
