import { OutputSelector } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { AxiosError } from 'axios';
import { CallEffect } from 'redux-saga/effects';

import { ListItem } from 'src/models/list';
import { FolderObjectData } from 'src/models/folder';
import { RootState } from 'src/app/types';
import { ObjectSerialized } from 'src/common/types';

interface contractDIContainer {
  isContractFolder: (model: ListItem) => boolean;
  createGetRootFolderId: (
    predicate: (item: ListItem) => boolean,
  ) => OutputSelector<
    RootState,
    string | undefined,
    (res: FolderObjectData[]) => string | undefined
  >;
  handleErrorSaga: (error: AxiosError<any>) => Generator<CallEffect<void>, void, unknown>;
  copyEntityToProjectAPI: (
    documentId: string,
    projectId: string,
  ) => SagaIterator<ObjectSerialized | null>;
  copyContractToFolderAPI: (
    contractId: string,
    folderId: string,
  ) => SagaIterator<ObjectSerialized | null>;
}
let container: contractDIContainer | null = null;

export const initializeContractDIContainer = (contractContainer: contractDIContainer) => {
  container = contractContainer;
};

export const getContractDIContainer = () => {
  if (!container) throw Error('Container DI not initialized');

  return container;
};
