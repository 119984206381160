import { useContext } from 'react';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { Checkbox } from 'src/v2/components/Form/Checkbox';
import { SignatureSettings } from 'src/v2/features/signature';

import { HankoSignatureSettingsContext } from '../../contexts/HankoSignatureSettingsContext';

export const SignatureSettingsHanko: React.FC = () => {
  const {
    localisedSignature,
    includeHanko,
    toggleLocalisedSignature,
    toggleIncludeHanko,
    isIncludeHankoVisible,
  } = useContext(HankoSignatureSettingsContext);

  return (
    <SignatureSettings>
      <Checkbox
        data-type="include-checkbox"
        label={i18n(translationKeys.forms.signature.includeLocalized)}
        checked={localisedSignature}
        onChange={toggleLocalisedSignature}
      />
      <Checkbox
        data-type="include-checkbox"
        label={i18n(translationKeys.forms.signature.includeHanko)}
        checked={includeHanko}
        onChange={toggleIncludeHanko}
        disabled={!isIncludeHankoVisible}
      />
    </SignatureSettings>
  );
};
