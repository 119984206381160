import { createSelector } from '@reduxjs/toolkit';
import { isUndefined } from 'lodash';

import { ListItem } from 'src/models/list';
import { getFoldersArray } from 'src/v2/features/objectsStorage/selectors';
import { adaptFoldersToListItem } from 'src/v2/features/folder/adapters';

export const createGetRootFolderId = (predicate: (item: ListItem) => boolean) =>
  createSelector(getFoldersArray, (folders) => {
    const folder = folders.find((folderObjectData) => {
      if (isUndefined(folderObjectData)) return false;

      const listItem = adaptFoldersToListItem(folderObjectData.attributes);
      return predicate(listItem);
    });

    if (isUndefined(folder)) return undefined;

    return folder.attributes.folder_id;
  });
