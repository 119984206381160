import React, { FC } from 'react';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

interface Props {
  title?: string;
  description?: string;
  isAllowedToEdit: boolean;
  onEdit: () => void;
}

export const ProjectMetadataView: FC<Props> = ({ title, description, isAllowedToEdit, onEdit }) => {
  return (
    <div className="c-employmenеt">
      <div className="c-employmenеt__row c-employmenеt__header">
        <div className="c-title c-title--uppercase">{title}</div>
        {isAllowedToEdit && (
          <div data-type="edit-box" className="c-title c-title__edit" onClick={onEdit}>
            Edit
          </div>
        )}
      </div>

      <div className="c-employmenеt__row">
        <div className="c-employmenеt__description">
          <span className="c-employmenеt__title">
            {i18n(translationKeys.sorting.description)}:{' '}
          </span>
          <span data-type="description-field" className="c-employmenеt__text">
            {description}
          </span>
        </div>
      </div>
    </div>
  );
};
