import { FC } from 'react';

import { CheckoutWizard } from '../CheckoutWizard';
import { CheckoutLayout } from '../CheckoutLayout';

export const CheckoutPage: FC = () => {
  return (
    <CheckoutLayout>
      <CheckoutWizard />
    </CheckoutLayout>
  );
};
