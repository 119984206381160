/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from '@reduxjs/toolkit';

import { defaultActionsFactoryWithData } from 'src/utils/defaultSlice';
import { RootState, DefaultSliceState } from 'src/app/types';

const initialState: DefaultSliceState = {
  isLoading: false,
  error: '',
};

const { onStart, onError, onSuccess } = defaultActionsFactoryWithData();

export const removeParticipantSlice = createSlice({
  name: 'removeParticipant',
  initialState,
  reducers: {
    start: onStart,
    error: onError,
    success: onSuccess,
  },
});

export const { reducer } = removeParticipantSlice;

export const getState = (state: RootState): DefaultSliceState => state.documentRemoveParticipant;

export const getIsRemoving = createSelector(getState, (state) => state.isLoading);
