import { FC } from 'react';
import { isNull } from 'lodash';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { CardInfo } from 'src/models/billing';
import { getFullName } from 'src/utils/normalize';

import { PaymentCard } from './PaymentCard';

interface CardInfoProps {
  billingCard: CardInfo;
  firstName: string;
  lastName: string;
  onBillingCardClick: () => void;
}

export const PaymentCardInfo: FC<CardInfoProps> = ({
  billingCard,
  firstName,
  lastName,
  onBillingCardClick,
}): JSX.Element | null => {
  if (isNull(billingCard.brand)) return null;

  return (
    <>
      <div className="c-setup__visa--img">
        <PaymentCard cardType={billingCard.brand} />
      </div>
      <div className="c-setup__visa--info">
        <div className="c-setup__visa--user">{getFullName(firstName, lastName)}</div>
        <div className="c-setup__visa--card">
          {i18n(translationKeys.forms.billing.cardEnding, {
            last4: billingCard.last4,
            expMonth: billingCard.expMonth,
            expYear: billingCard.expYear,
          })}
          <br />
          {i18n(translationKeys.forms.billing.featurePayments)}
        </div>
        <span className="c-setup__visa--link" onClick={onBillingCardClick}>
          {i18n(translationKeys.forms.billing.changeBillingCard)}
        </span>
      </div>
    </>
  );
};
