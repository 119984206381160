import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { DocumentType, DocumentRole } from 'src/models/document';
import { useGetEntityById } from 'src/v2/features/sharedEntity/hooks';
import { getTitle } from 'src/v2/features/documentDetails/documentDetailsSlice';
import { useCanEditDocument } from 'src/v2/features/document/permissions/documentPermissions/documentPermissionsHooks';
import { EntityTitle } from 'src/v2/components/Title/Title';
import { EntityBody } from 'src/v2/features/sharedEntity/EntityBody';
import { EntityAcknowledges } from 'src/v2/features/sharedEntity/components/EntityAcknowledges/EntityAcknowledges';
import { Breadcrumb } from 'src/v2/features/breadcrumb';
import { useNavigateToDocumentFolder } from 'src/v2/features/quantumNavigator/hooks';
import { downloadAsFile } from '../sharedEntity/actions';
import { MimeType } from 'src/common/types';
import { isNull } from 'lodash';

interface Props {
  id: string;
}

export const DocumentContent: React.FC<Props> = ({ id }) => {
  const dispatch = useDispatch();
  const title = useSelector(getTitle) || i18n(translationKeys.document.noTitle);
  const document = useGetEntityById<DocumentType, DocumentRole>(id);
  const isAllowedToEdit = useCanEditDocument(document);
  const handleOpenFolder = useNavigateToDocumentFolder();

  const handleExportPdf = useCallback(() => {
    if (!isNull(document)) {
      dispatch(
        downloadAsFile({
          id,
          name: document.title,
          extension: MimeType.pdf,
        }),
      );
    }
  }, [dispatch, document, id]);

  return (
    <>
      <Breadcrumb currentPageName={title} onFolderClick={handleOpenFolder} />
      <EntityTitle title={title} />
      <EntityBody
        id={id}
        isNegotiable={false}
        isAllowedToEdit={isAllowedToEdit}
        isAllowedToSign={true}
        onExportPdf={handleExportPdf}
      />
      <EntityAcknowledges />
    </>
  );
};
