import { createAction, PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  put,
  takeEvery,
  take,
  fork,
  cancel,
  cancelled,
  PutEffect,
  CallEffect,
  TakeEffect,
  ForkEffect,
  all,
} from 'redux-saga/effects';
import { EventChannel, SagaIterator } from 'redux-saga';
import { get, isUndefined } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { toastr } from 'react-redux-toastr';

import { navigateToDocumentDetailsFactory } from 'src/v2/features/document/utils';
import { history } from 'src/initializeHistory';
import { createDocumentInFolderFromUploadAPI } from 'src/api/documents';
import {
  normalizeErrorsResponse,
  fileUploadReducerFactory,
  fileUploadSelectorsFactory,
  showErrorToast,
} from 'src/v2/features/fileUpload';
import {
  CreateUploadFileChannelPayload,
  FileUploadState,
  UploadError,
} from 'src/v2/features/fileUpload/types';
import { CreateDocumentDTO } from 'src/v2/boundary/requestDTO/document';
import {
  CreateContractFromFilePayload,
  CreateDocumentFromFilePayload,
} from 'src/v2/features/documentSidebar/documentSidebarUpload/types';
import { ObjectSerialized } from 'src/common/types';
import { CreateContractDTO } from 'src/v2/boundary/requestDTO/contract';
import { createContractInFolderFromUploadAPI } from 'src/api/contractsAPI';
import { RootState } from 'src/app/types';

const NAMESPACE = 'documentSidebarUpload';

export const {
  actions: { uploadStart, uploadEnd, uploadUpdateProgress, uploadFailed, retryUploadFile },
  reducer,
} = fileUploadReducerFactory<CreateDocumentFromFilePayload>(NAMESPACE);

export const createDocumentFromFile = createAction<CreateDocumentFromFilePayload>(
  `${NAMESPACE}/createDocumentFromFile`,
);

export const createContractFromFile = createAction<CreateContractFromFilePayload>(
  `${NAMESPACE}/createContractFromFile`,
);

export const createUploadAttachmentFilesSaga = <DTO>(
  createDocumentFromUploadApi: (
    file: File,
    document: DTO,
  ) => SagaIterator<EventChannel<ObjectSerialized>>,
) =>
  function* uploadAttachmentFilesSaga({
    id,
    file,
    retry = false,
    document,
    documentId,
  }: {
    id: string;
    file: File;
    document: DTO;
    documentId: string;
    retry?: boolean;
  }): SagaIterator {
    let channel: any;

    try {
      const { name } = file;
      const navigateToDocumentDetails = navigateToDocumentDetailsFactory(history);

      yield retry
        ? put(uploadUpdateProgress({ id, progress: 0 }))
        : put(uploadStart({ id, name, documentId }));
      channel = yield call(createDocumentFromUploadApi, file, document);

      while (true) {
        const { progress = 0, err, data } = (yield take(channel)) as CreateUploadFileChannelPayload;
        if (!isUndefined(err)) {
          const errors = normalizeErrorsResponse(err);
          yield all(errors.map((error) => call(showErrorToast, error)));
          yield put(uploadFailed({ id, err: errors[0] }));
          return;
        }
        if (data) {
          yield put(uploadEnd({ id }));
          const { id: createdDocumentId, type } = data;
          yield call(navigateToDocumentDetails, createdDocumentId, type, get(document, 'folderId'));
          toastr.success('Upload finished', `File ${file.name} was attached to the document`);
          return;
        }
        yield put(uploadUpdateProgress({ id, progress }));
      }
    } catch (error) {
      console.error(error);
      toastr.error('Error', `Oops, something went wrong!`);
    } finally {
      if (yield cancelled()) {
        if (channel) {
          channel.close();
          yield put(uploadUpdateProgress({ id, progress: 0 }));
        }
      }
    }
  };

export const createUploadAttachmentFilesWithRetrySaga = <DTO>(
  uploadAttachmentFilesSaga: (params: {
    id: string;
    file: File;
    document: DTO;
    documentId: string;
    retry?: boolean;
  }) => SagaIterator,
) =>
  function* uploadAttachmentFilesWithRetrySaga(
    action: PayloadAction<{
      file: File;
      document: DTO;
      documentId: string;
    }>,
  ): Generator<PutEffect | CallEffect | TakeEffect | ForkEffect> {
    const { payload } = action;
    const { file, document, documentId } = payload;
    const id = uuidv4();
    let task: any;
    try {
      task = yield fork(uploadAttachmentFilesSaga, { file, id, document, documentId });
      while (true) {
        const { payload: retryId } = (yield take(retryUploadFile)) as PayloadAction<string>;
        if (retryId === id) {
          // @ts-ignore
          yield cancel(task);
          task = yield fork(uploadAttachmentFilesSaga, {
            file,
            id,
            document,
            retry: true,
            documentId,
          });
        }
      }
    } catch (error) {
      console.error(error);
      toastr.error('Error', `Oops, something went wrong!`);
      yield put(uploadFailed({ id, err: UploadError.Unknown }));
    }
  };

const uploadDocumentFile = createUploadAttachmentFilesSaga<CreateDocumentDTO>(
  createDocumentInFolderFromUploadAPI,
);

const uploadContractFile = createUploadAttachmentFilesSaga<CreateContractDTO>(
  createContractInFolderFromUploadAPI,
);

export function* watchDocumentUploadSagas(): Generator {
  yield takeEvery(
    createDocumentFromFile,
    createUploadAttachmentFilesWithRetrySaga(uploadDocumentFile),
  );

  yield takeEvery(
    createContractFromFile,
    createUploadAttachmentFilesWithRetrySaga(uploadContractFile),
  );
}

const getState = (state: RootState): FileUploadState => state.documentSidebarUpload;
export const { getUploadsListByDocumentIdFactory } = fileUploadSelectorsFactory(getState);

export default reducer;
