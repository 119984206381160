/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ContextMenuState, ContextMenuBook } from 'src/app/types';

const initialState: ContextMenuState<ContextMenuBook> = {
  sourceType: undefined,
  payload: undefined,
  position: { x: 0, y: 0 },
};

export const contextMenuSlice = createSlice({
  name: 'contextMenu',
  initialState,
  reducers: {
    openContextMenu: (
      state: ContextMenuState<ContextMenuBook>,
      action: PayloadAction<ContextMenuState<ContextMenuBook>>,
    ) => {
      const { payload, sourceType, position } = action.payload;
      state.sourceType = sourceType;
      state.payload = payload;
      state.position = position;
    },
    closeContextMenu: (state: ContextMenuState<ContextMenuBook>) => {
      state.payload = initialState.payload;
      state.sourceType = initialState.sourceType;
      state.position = initialState.position;
    },
  },
});

export const contextMenuReducer = contextMenuSlice.reducer;

export const { closeContextMenu, openContextMenu } = contextMenuSlice.actions;
