import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useGetFoldersTreeByIdWithoutSystemFolders } from 'src/v2/features/quantumNavigator/hooks';
import { closeModal } from 'src/v2/features/modal/store';

import { FolderSelector } from './FolderSelector';
import { FolderView } from '../FolderView';
import { getFolderId, getActiveFolderId } from '../store';
import { FolderSelectorProps } from '../types';

export const FolderSelectorContainer: FC<FolderSelectorProps> = ({
  onActionButtonClick,
  validateFn,
  ...props
}) => {
  const folderId = useSelector(getFolderId);
  const activeFolderId = useSelector(getActiveFolderId);
  const dispatch = useDispatch();
  const model = useGetFoldersTreeByIdWithoutSystemFolders(folderId, validateFn);
  const handleClose = () => dispatch(closeModal());
  const handleActionButtonClick = useCallback(
    () => onActionButtonClick(activeFolderId as string),
    [onActionButtonClick, activeFolderId],
  );

  if (!model) return null;

  return (
    <FolderSelector
      model={model}
      viewComponent={FolderView}
      onClose={handleClose}
      onActionButtonClick={handleActionButtonClick}
      {...props}
    />
  );
};
