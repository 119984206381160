import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { END, eventChannel, SagaIterator, EventChannel } from 'redux-saga';
import { call, cancelled, delay } from 'redux-saga/effects';
import fileDownload from 'js-file-download';

import { env } from 'src/env';
import { ObjectSerialized, DataModificationPayload } from 'src/common/types';
import { ApiError } from 'src/v2/features/fileUpload/fileUploadStore';

interface Get {
  <T = ObjectSerialized | null, R = T>(url: string, config?: AxiosRequestConfig): SagaIterator<R>;
}

interface Post {
  <T = ObjectSerialized | null>(
    url: string,
    data?: DataModificationPayload | FormData | any,
    config?: AxiosRequestConfig,
  ): SagaIterator<T>;
}

interface UploadFile {
  <T = ObjectSerialized | null>(
    url: string,
    data?: DataModificationPayload | FormData | any,
    config?: AxiosRequestConfig,
  ): SagaIterator<EventChannel<T>>;
}

interface Put {
  <T = ObjectSerialized | null>(
    url: string,
    data?: DataModificationPayload | FormData | any,
    config?: AxiosRequestConfig,
  ): SagaIterator<T>;
}

interface Patch {
  <T = ObjectSerialized | null>(
    url: string,
    data?: DataModificationPayload | FormData | any,
    config?: AxiosRequestConfig,
  ): SagaIterator<T>;
}

interface Delete {
  <T = ObjectSerialized | null>(url: string, config?: AxiosRequestConfig): SagaIterator<T>;
}

interface Download {
  (url: string, name: string, config?: AxiosRequestConfig): SagaIterator;
}

export interface Client {
  get: Get;
  post: Post;
  uploadFile: UploadFile;
  put: Put;
  delete: Delete;
  download: Download;
  patch: Patch;
}

const retryDelay = 3000;
const maxRetries = 3;

export function* pureRequest(config: AxiosRequestConfig = {}): SagaIterator {
  const result = yield call(axios.request, config);
  return result.data;
}

function* retryWithIncrementalDelay(maxRetries: number, delayLength: number, fn: any, args: any) {
  let error: any;

  for (let i = 0; i <= maxRetries; i++) {
    try {
      // @ts-ignore
      const response = yield call(fn, args);
      return response;
    } catch (err: unknown) {
      const axiosError = err as AxiosError<unknown>;
      error = err;

      if (axiosError.response?.status === 403) {
        throw axiosError;
      }

      if (i < maxRetries) {
        yield delay(delayLength * 2 * (i + 1));
      }
    }
  }

  throw new Error(error);
}

export function* request(config: AxiosRequestConfig = {}): SagaIterator {
  const source = axios.CancelToken.source();
  try {
    const result = yield call(axios.request, { cancelToken: source.token, ...config });
    return result.data;
  } finally {
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export function* requestWithRetry(config: AxiosRequestConfig = {}): SagaIterator {
  const source = axios.CancelToken.source();
  try {
    const result = yield call(retryWithIncrementalDelay, maxRetries, retryDelay, pureRequest, {
      cancelToken: source.token,
      ...config,
    });
    return result;
  } catch (e) {
    throw e;
  } finally {
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

export const get: Get = (url, config = {}) => {
  return requestWithRetry({ method: 'get', url, ...config });
};

export const post: Post = (url, data, config = {}) => {
  return request({ method: 'post', url, data, ...config });
};

const UPLOAD_FILE_TIMEOUT = 5 * 60 * 1000; // 5 min

const getMaxSizeUploadError = () => ({
  response: {
    data: {
      errors: [
        {
          detail: ApiError.MaxFileSize,
        },
      ],
    },
  },
});

// @TODO ideally this should be inside post, but can't handle proper function overloading
// @ts-ignore
export const uploadFile: UploadFile = function* (url, data, config = {}) {
  const channel = yield call(eventChannel, (emitter: any) => {
    const source = axios.CancelToken.source();
    axios
      .request({
        method: 'post',
        url,
        data,
        cancelToken: source.token,
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total > env.FILE_UPLOAD_LIMIT) {
            emitter({
              err: getMaxSizeUploadError(),
            });
            emitter(END);
          } else {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            emitter({ progress });
          }
        },
        ...config,
        timeout: UPLOAD_FILE_TIMEOUT,
      })
      .then((response: any) => {
        emitter({
          data: response.data,
        });
        emitter(END);
      })
      .catch((err: any) => {
        emitter({
          err,
        });
        emitter(END);
      });

    return (): void => {
      source.cancel();
    };
  });
  return channel;
};

export const put: Put = (url, data, config = {}) => {
  return request({ method: 'put', url, data, ...config });
};

export const patch: Patch = (url, data, config = {}) => {
  return request({ method: 'patch', url, data, ...config });
};

export const deleteRequest: Delete = (url, config = {}) => {
  return request({ method: 'delete', url, ...config });
};

export const download: Download = function* (url, name, config) {
  const data = yield call(get, url, {
    responseType: 'blob',
    ...config,
  });

  // @ts-ignore
  fileDownload(data, name);
};

export const baseClientFactory = (
  url: string,
  defaultConfigGenerator: (config: AxiosRequestConfig) => AxiosRequestConfig,
): Client => {
  return {
    get: ((url, optionalConfig = {}) => get(url, defaultConfigGenerator(optionalConfig))) as Get,
    post: ((url, data, optionalConfig = {}) =>
      post(url, data, defaultConfigGenerator(optionalConfig))) as Post,
    uploadFile: ((url, data, optionalConfig = {}) =>
      uploadFile(url, data, defaultConfigGenerator(optionalConfig))) as UploadFile,
    put: ((url, data, optionalConfig = {}) =>
      put(url, data, defaultConfigGenerator(optionalConfig))) as Put,
    delete: ((url, data, optionalConfig = {}) =>
      deleteRequest(url, defaultConfigGenerator(optionalConfig))) as Delete,
    download: ((url, name, optionalConfig = {}) =>
      download(url, name, defaultConfigGenerator(optionalConfig))) as Download,
    patch: ((url, data, optionalConfig = {}) =>
      patch(url, data, defaultConfigGenerator(optionalConfig))) as Patch,
  };
};
