import { get } from 'lodash';

export const DEFAULT_ERROR_CODE = 'UNKNOWN_CODE';

type ErrorMessage = { [key: string]: string };

const defaultErrorMessages: ErrorMessage = {
  [DEFAULT_ERROR_CODE]:
    'Unable to perform the action at this time. Refresh the page and try again.',
};

export const extractErrorMessage = (err: Error): string => {
  const errors = get(err, 'response.data.errors');

  if (typeof errors === 'string') {
    return errors;
  }
  if (Array.isArray(errors)) {
    return errors.map((itm) => itm.detail).join(';');
  }

  return defaultErrorMessages[DEFAULT_ERROR_CODE];
};

export const extractErrorCode = (err: Error): string => {
  return (
    get(err, 'response.data.error.code') ||
    get(err, 'response.data[0].error.code') ||
    get(err, 'response.data.data[0].code') ||
    get(err, 'response.data.errors.0.code') ||
    get(err, 'response.data.data[0].error_code') ||
    DEFAULT_ERROR_CODE
  );
};

export const getErrorMessage = (err: Error, errorMessages: ErrorMessage): string => {
  const code = extractErrorCode(err);
  if (code === DEFAULT_ERROR_CODE) {
    // getting message from response for now
    return extractErrorMessage(err);
  }
  let message = defaultErrorMessages[code] || errorMessages[code];

  if (!message) {
    console.warn(`Unmatched error code received: ${code}`);
    message = defaultErrorMessages[DEFAULT_ERROR_CODE];
  }

  return message;
};
