import { get, isNil, isString, isEmpty } from 'lodash';

import defaultAvatar from 'src/assets/placeholders/user-avatar.png';
import { ProfileEntity, SidebarParticipantEntity } from 'src/v2/entities/participants';

import { getAvatar, getProfile } from '../documentParticipants';
import { Avatar, AvatarSize } from './types';

const uriPropBySize: { [key: string]: string } = {
  [AvatarSize.Small]: 'uriSmall',
  [AvatarSize.Medium]: 'uriMedium',
  [AvatarSize.Large]: 'uriLarge',
  [AvatarSize.ExtraLarge]: 'uriExtraLarge',
};

export const hasAvatar = (
  avatar: Avatar | undefined,
  size: AvatarSize = AvatarSize.Medium,
): boolean => !!get(avatar, uriPropBySize[size]);

export const normalizeAvatar = (avatarUrl?: string) =>
  isString(avatarUrl) && !isEmpty(avatarUrl) ? avatarUrl : defaultAvatar;

export function getAvatarUrl(
  avatar: Avatar | string | undefined,
  size: AvatarSize = AvatarSize.Medium,
) {
  if (typeof avatar === 'string') {
    return normalizeAvatar(avatar);
  }

  if (hasAvatar(avatar, size)) {
    return normalizeAvatar(get(avatar, uriPropBySize[size]));
  }

  return defaultAvatar;
}

export const getAvatarUrlByProfile = (profile: ProfileEntity, size?: AvatarSize) => {
  const avatar = getAvatar(profile);
  if (!isNil(avatar)) {
    return getAvatarUrl(avatar, size);
  }

  return get(profile, 'avatar');
};

export const getAvatarUrlByParticipant = (
  user: SidebarParticipantEntity | undefined,
  size?: AvatarSize,
) => {
  return user ? getAvatarUrlByProfile(getProfile(user), size) : normalizeAvatar();
};

export const isValidDate = (date: Date | string | null | undefined) => {
  if (isNil(date)) return false;

  const dateString = date instanceof Date ? date.toString() : date;
  const timestamp = Date.parse(dateString);
  if (isNaN(timestamp) || !isFinite(timestamp)) return false;

  const lowerLimit = new Date('1970-01-01T00:00:00Z');
  if (lowerLimit > new Date(timestamp)) return false;

  return true;
};
