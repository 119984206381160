import { get, isEmpty } from 'lodash';
import { AxiosError } from 'axios';

import { CommonResponseError } from './types';
import { extractErrorMessage } from '../common/errorMessages';

export const responseErrorExtract = (
  err: AxiosError,
  defaultCode = 'UNKNOWN_CODE',
  defaultDetail = 'Oops, something went wrong',
  defaultTitle = 'Error',
): CommonResponseError => {
  const errObj = get(err.response, 'data.data[0]');
  const detailMessage = extractErrorMessage(err);
  const {
    detail = detailMessage || defaultDetail,
    error_code: errorCode = defaultCode,
    response_code: responseCode = 500,
  } = errObj || {};

  return {
    title: defaultTitle,
    detail: isEmpty(detail) ? defaultDetail : detail,
    errorCode: isEmpty(errorCode) ? defaultCode : errorCode,
    responseCode,
  };
};
