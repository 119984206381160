import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getIncludeCompanyMeta,
  setIncludeCompanyMeta as setIncludeCompanyMetaAction,
} from '../store';
import { SignatureSettingsContext } from '../contexts/SignatureSettingsContext';

export const SignatureSettingsContextProvider: FC = ({ children }) => {
  const dispatch = useDispatch();
  const includeCompanyMeta = useSelector(getIncludeCompanyMeta);

  const toggleIncludeCompanyMeta = useCallback(
    () => dispatch(setIncludeCompanyMetaAction(!includeCompanyMeta)),
    [dispatch, includeCompanyMeta],
  );

  const ctx = {
    includeCompanyMeta,
    toggleIncludeCompanyMeta,
  };
  return (
    <SignatureSettingsContext.Provider value={ctx}>{children}</SignatureSettingsContext.Provider>
  );
};
