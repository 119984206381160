import { Observable, from } from 'rxjs';
import { distinctUntilChanged, mapTo, tap, map } from 'rxjs/operators';
import { Store } from 'redux';
import { isEqual } from 'lodash';
import { Persistor } from 'redux-persist';

import { env } from 'src/env';
import { initializeI18n } from 'src/i18n';
import { setApiClient, apiClientFactory } from 'src/common/client';
import { setWebsocketsStore, webSocketsInit } from 'src/api/socket/connection';
import { initializeStore } from 'src/app/store';
import { initializeNotificationStore } from 'src/v2/features/notifications/notificationsStore';
import { initializeChatStore } from 'src/v2/features/chat/store';
import { normalizeStringToEmpty } from 'src/utils/string';
import { getCurrentOrganizationId } from 'src/shared/organization';
import { getToken } from 'src/shared/auth';

import { initializeFolderStore } from 'src/v2/features/folderObjects/sagas';
import { initializeDocumentStore } from 'src/v2/features/documentDetails/documentDetailsSlice';
import { RootState } from './app/types';

export function initialize(): Observable<{ store: Store; persistor: Persistor }> {
  const apiUrl = env.PROJECT_SERVICE_URI;

  const { store, persistor } = initializeStore();
  //@ts-ignore
  const $store = from(store);
  webSocketsInit();
  setWebsocketsStore(store);
  initializeNotificationStore();
  initializeChatStore();
  initializeDocumentStore();
  initializeFolderStore();
  initializeI18n();

  const $ready = $store.pipe(
    // @ts-ignore
    map((state: RootState) => ({
      token: getToken(state),
      orgId: getCurrentOrganizationId(state),
    })),
    map(normalizeStringToEmpty),
    distinctUntilChanged(isEqual),
    tap((params: { token: string; orgId?: string }) => {
      const { token, orgId } = params;
      setApiClient(apiClientFactory(apiUrl, token, orgId));
    }),
    mapTo({ store, persistor }),
  );

  return $ready;
}
