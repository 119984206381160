import React, { FC } from 'react';
import { Field } from 'redux-form';
import { required } from 'redux-form-validators';

import { Button, Type } from 'src/v2/features/button';
import { Divider } from 'src/v2/components/Divider';
import { Input, Textarea, Select } from 'src/v2/features/reduxForm';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

import { EntityType } from 'src/models/paper';
import { getSubTypeOptions } from './utils';

export interface Props {
  label: string;
  infoLabel: string;
  type: EntityType;
  isLoading: boolean;
  isPristine?: boolean;
  onSubmit: () => void;
  childrenBefore?: React.ReactNode;
}

export const MetadataForm: FC<Props> = ({
  label,
  infoLabel,
  type,
  isLoading,
  isPristine,
  onSubmit,
  children,
  childrenBefore,
}) => (
  <div className="c-fieldset">
    <div className="c-title c-title--uppercase c-title--lh-1">
      {i18n(translationKeys.forms.item.title)}
    </div>
    <Field validate={[required()]} component={Input} name="title" />
    <Divider />
    <div className="c-title c-title--uppercase c-title--lh-1">
      {i18n(translationKeys.forms.item.description)}
    </div>
    <Field component={Textarea} name="description" />
    <Divider />
    <div className="c-title c-title--uppercase c-title--lh-1">{infoLabel}</div>

    {childrenBefore}

    <Field component={Select} name="subType" options={getSubTypeOptions(type)} mb8 />
    <Field
      component={Input}
      name="subSubType"
      placeholder={i18n(translationKeys.forms.item.subType)}
      mb8
    />

    {children}

    <Divider />
    <Button
      data-type="metadata-submit"
      type="submit"
      actionType={Type.Secondary}
      onClick={onSubmit}
      disabled={isLoading || isPristine}
      isLoading={isLoading}
    >
      {label}
    </Button>
  </div>
);
