import { isNull } from 'lodash';
import { useSelector } from 'react-redux';

import { NodeTree } from 'src/models/node';
import { ListItem } from 'src/models/list';
import { Contract, ContractRole, ContractType } from 'src/models/contract';
import { PaperModel } from 'src/models/paper';
import { getActivePlan } from 'src/v2/features/billing';
import { useGetCurrentUserRoleByEntity } from 'src/v2/features/sharedEntity';
import { canCreateFolder } from 'src/v2/features/quantumNavigator/utils';

import {
  canEditContractMetadata,
  canEditContractFields,
  canEditContract,
  canDeleteContract,
  canNegotiateContract,
  canAddContractAttachments,
  canCommentInContract,
  canSignContract,
  canManageParticipantInContract,
  canCreateContract,
  canCopyContract,
  canShareContract,
  canDownloadContract,
  canCreateContractTemplate,
  canViewContractHistory,
  canViewContractVersions,
  canRestoreContract,
  canViewContractNotification,
  canChatInContract,
  canViewOnlineParticipantsContract,
  canEditAccessInContract,
  canViewQRCodeInContract,
  canCreateFolderInContracts,
} from './contractPermissions';

export const useCanEditContractMetadata = (contract: Contract | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity<ContractRole>(contract);
  if (isNull(currentUserRole) || isNull(contract)) return false;

  return canEditContractMetadata(currentUserRole);
};

export const useCanEditContractFields = (contract: Contract | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity<ContractRole>(contract);
  if (isNull(currentUserRole) || isNull(contract)) return false;

  return canEditContractFields(currentUserRole);
};

export const useCanEditContract = (contract: Contract | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity<ContractRole>(contract);
  if (isNull(currentUserRole) || isNull(contract)) return false;

  return canEditContract(currentUserRole);
};

export const useCanDeleteContract = (contract: Contract | null): boolean => {
  if (isNull(contract)) return false;

  return canDeleteContract(contract.state);
};

export const useCanNegotiateContract = (contract: Contract | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity<ContractRole>(contract);
  if (isNull(currentUserRole) || isNull(contract)) return false;

  return canNegotiateContract(currentUserRole);
};

export const useCanAddContractAttachments = (contract: Contract | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity<ContractRole>(contract);
  if (isNull(currentUserRole) || isNull(contract)) return false;

  return canAddContractAttachments(currentUserRole);
};

export const useCanCommentInContract = (contract: Contract | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity<ContractRole>(contract);
  if (isNull(currentUserRole) || isNull(contract)) return false;

  return canCommentInContract(currentUserRole);
};

export const useCanSignContract = (contract: Contract | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity<ContractRole>(contract);
  if (isNull(currentUserRole) || isNull(contract)) return false;

  return canSignContract(currentUserRole);
};

export const useCanManageParticipantInContract = (contract: Contract | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity<ContractRole>(contract);
  if (isNull(currentUserRole) || isNull(contract)) return false;

  return canManageParticipantInContract(currentUserRole);
};

export const useCanEditAccessInContract = (contract: Contract | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity<ContractRole>(contract);
  if (isNull(currentUserRole) || isNull(contract)) return false;

  return canEditAccessInContract(currentUserRole);
};

export const useCanCreateContract = (): boolean => {
  const plan = useSelector(getActivePlan);

  return canCreateContract(plan);
};

export const useCanCopyContract = (contract: Contract | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity<ContractRole>(contract);
  const plan = useSelector(getActivePlan);
  if (isNull(currentUserRole) || isNull(contract)) return false;

  return canCopyContract(currentUserRole, plan);
};

export const useCanShareContract = (contract: Contract | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity<ContractRole>(contract);
  if (isNull(currentUserRole) || isNull(contract)) return false;

  return canShareContract(currentUserRole);
};

export const useCanDownloadContract = (contract: Contract | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity<ContractRole>(contract);
  if (isNull(currentUserRole) || isNull(contract)) return false;

  return canDownloadContract(currentUserRole);
};

export const useCanCreateContractTemplate = (contract: Contract | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity<ContractRole>(contract);
  if (isNull(currentUserRole) || isNull(contract)) return false;

  return canCreateContractTemplate(currentUserRole);
};

export const useCanViewContractHistory = (contract: Contract | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity<ContractRole>(contract);
  if (isNull(currentUserRole) || isNull(contract)) return false;

  return canViewContractHistory(currentUserRole);
};

export const useCanViewContractVersions = (contract: Contract | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity<ContractRole>(contract);
  if (isNull(currentUserRole) || isNull(contract)) return false;

  return canViewContractVersions(currentUserRole);
};

export const useCanRestoreContract = (contract: Contract | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity<ContractRole>(contract);
  if (isNull(currentUserRole) || isNull(contract)) return false;

  return canRestoreContract(currentUserRole);
};

export const useCanViewContractNotification = (contract: Contract | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity<ContractRole>(contract);
  if (isNull(currentUserRole) || isNull(contract)) return false;

  return canViewContractNotification(currentUserRole);
};

export const useCanChatInContract = (contract: Contract | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity<ContractRole>(contract);
  if (isNull(currentUserRole) || isNull(contract)) return false;

  return canChatInContract(currentUserRole);
};

export const useCanViewOnlineParticipantsContract = (contract: Contract | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity<ContractRole>(contract);
  if (isNull(currentUserRole) || isNull(contract)) return false;

  return canViewOnlineParticipantsContract(currentUserRole);
};

export const useCanViewQRCodeInContract = (
  document: PaperModel<ContractType, ContractRole> | null,
): boolean => {
  const plan = useSelector(getActivePlan);
  if (isNull(document)) return false;
  return canViewQRCodeInContract(plan);
};

export const useCanCreateFolderInContracts = (folder: NodeTree<ListItem>) => {
  const plan = useSelector(getActivePlan);
  const isCanCreateFolder = canCreateFolder(folder);

  return canCreateFolderInContracts(plan) && isCanCreateFolder;
};
