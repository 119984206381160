import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { LayoutWithStepper } from '../components/LayoutWithStepper';
import { fetchInfoFromToken } from '../store';
import { useIsSignupFromInvite } from './hooks';
import { useComponentAndProps } from './componentAndPropsHook';

export const WizardPage: FC = () => {
  const dispatch = useDispatch();
  const [render, layoutProps] = useComponentAndProps();
  const [isSignupFromInvite, token] = useIsSignupFromInvite();

  useEffect(() => {
    if (isSignupFromInvite) dispatch(fetchInfoFromToken(token));
  }, [dispatch, token, isSignupFromInvite]);

  return <LayoutWithStepper {...layoutProps}>{render()}</LayoutWithStepper>;
};
