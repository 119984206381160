import { createAction } from '@reduxjs/toolkit';
import { CreateDocumentFromTemplateDTO } from 'src/v2/boundary/requestDTO/document';
import { CreateContractFromTemplateDTO } from 'src/v2/boundary/requestDTO/contract';

export const createDocumentFromTemplate = createAction<CreateDocumentFromTemplateDTO>(
  'templates/createDocumentFromTemplate',
);

export const createContractFromTemplate = createAction<CreateContractFromTemplateDTO>(
  'templates/createContractFromTemplate',
);
