import React from 'react';
import { Enlarge } from 'src/v2/icons/Enlarge';
import { Reduce } from 'src/v2/icons/Reduce';
import { Paper } from 'src/v2/icons/Paper';
import { SignatureColor } from 'src/v2/icons/SignatureColor';
import { Dw } from 'src/v2/icons/Dw';

import { HeaderItem } from '../HeaderItem';

interface Props {
  isEnlargeActive?: boolean;
  onClickEnlarge?: (e: React.MouseEvent<HTMLElement>) => void;
  onClickDiff?: (e: React.MouseEvent<HTMLElement>) => void;
  onClickBackFromDiff?: (e: React.MouseEvent<HTMLElement>) => void;
  onClickSign?: (e: React.MouseEvent<HTMLElement>) => void;
}

/* @deprecated */
export const BackTitleAndActions: React.FC<Props> = ({
  onClickDiff,
  onClickBackFromDiff,
  onClickEnlarge,
  onClickSign,
  children,
  isEnlargeActive = false,
}) => {
  return (
    <>
      <HeaderItem>
        <div className="c-action">
          <div className="c-action__column" />
          <div className="c-action__column">
            {onClickSign && (
              <div className="c-action__item" onClick={onClickSign} data-type="ready-to-sign-btn">
                <SignatureColor />
                {children}
              </div>
            )}

            {onClickDiff && (
              <div className="c-action__item" onClick={onClickDiff}>
                <Paper />
              </div>
            )}

            {onClickBackFromDiff && (
              <div className="c-action__item" onClick={onClickBackFromDiff}>
                <Dw />
              </div>
            )}

            {onClickEnlarge && (
              <div className="c-action__item min-960" onClick={onClickEnlarge}>
                {isEnlargeActive ? <Reduce /> : <Enlarge />}
              </div>
            )}
          </div>
        </div>
      </HeaderItem>
    </>
  );
};
