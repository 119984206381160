import React, { FC } from 'react';
import cn from 'classnames';

interface Props {
  header?: ReturnType<FC>;
  navigation?: ReturnType<FC>;
  sidebar?: ReturnType<FC>;
  isShortHeader?: boolean;
}

export const BaseLayout: FC<Props> = ({ children, header, navigation, sidebar, isShortHeader }) => {
  const className = cn('l-app', { 'l-app--short-header': isShortHeader });
  return (
    <div className={className}>
      {header}
      {navigation}

      <main className="l-main l-main--version-01">{children}</main>
      {sidebar}
    </div>
  );
};
