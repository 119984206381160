import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNull } from 'lodash';

import { MenuContracts } from 'src/v2/icons/MenuContracts';
import { isContractFolder } from 'src/v2/features/quantumNavigator';
import { useGetRootFolderId } from 'src/v2/features/sharedEntity/hooks';
import { isSharedFolder } from 'src/v2/features/quantumNavigator/utils';
import { getTargetId, clearTargetId } from 'src/v2/features/folderSelector/store';
import { closeModal } from 'src/v2/features/modal/store';
import { FolderSelectorContainer } from 'src/v2/features/folderSelector/FolderSelector/FolderSelectorContainer';
import { moveFolder } from 'src/v2/features/quantumNavigator/store/quantumNavigatorReducers';
import { useCreateGoToFolderByIdHandler } from 'src/v2/features/folderSelector/hooks';

export const ContractMoveFolderToModal: FC = () => {
  const targetId = useSelector(getTargetId);
  const contractRootFolderId = useGetRootFolderId(isContractFolder);
  const dispatch = useDispatch();

  const validateFn = useCallback(
    (model) => {
      return !isSharedFolder(model.data) && model.data.id !== targetId;
    },
    [targetId],
  );

  const handleActionButtonClick = useCallback(
    (id: string) => {
      if (isNull(targetId)) {
        console.warn(`targetId can't be null`);
        return;
      }

      dispatch(moveFolder({ targetId, destinationId: id }));
      dispatch(closeModal());
      dispatch(clearTargetId());
    },
    [dispatch, targetId],
  );

  const handleContractsItemClick = useCreateGoToFolderByIdHandler(contractRootFolderId);

  const menuItems = useMemo(
    () => [
      {
        icon: <MenuContracts />,
        title: 'Contracts',
        onClick: handleContractsItemClick,
      },
    ],
    [handleContractsItemClick],
  );

  return (
    <FolderSelectorContainer
      heading="Move to Folder"
      actionButtonLabel="Move"
      menuItems={menuItems}
      onActionButtonClick={handleActionButtonClick}
      validateFn={validateFn}
    />
  );
};
