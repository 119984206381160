import React, { FC } from 'react';
import { InjectedFormProps } from 'redux-form';

import { TemplatePayload } from 'src/models/template';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

import { connectTemplateForm } from './utils';
import { TemplateMetaProps } from './types';
import { TemplateMetadataForm } from './TemplateMetadataForm';

interface Props extends TemplateMetaProps {
  isLoading: boolean;
  onSubmit: (data: TemplatePayload) => void;
}

const Edit: FC<Props & InjectedFormProps<TemplatePayload, Props>> = ({
  isLoading,
  onSubmit,
  folderId,
  handleSubmit,
}) => {
  return (
    <TemplateMetadataForm
      label={i18n(translationKeys.buttons.updateTemplate)}
      isLoading={isLoading}
      folderId={folderId}
      onSubmit={handleSubmit(onSubmit)}
    />
  );
};

export const TemplateMetadataEdit = connectTemplateForm<Props, TemplatePayload>(Edit, []);
