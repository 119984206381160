import Validators from 'redux-form-validators';

Object.assign(Validators.messages, {
  email: {
    id: 'form.errors.email',
    defaultMessage: 'is not a valid email address',
  },
  presence: {
    id: 'form.errors.presence',
    defaultMessage: 'this field is required',
  },
  tooShort: {
    id: 'form.errors.tooShort',
    defaultMessage: 'is too short: {count, number} chars minimum',
  },
});
