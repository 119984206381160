export const text = {
  modalHeading: {
    fontFamily: 'body',
    fontSize: '40px',
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: 1.2,
    letterSpacing: 'normal',
    color: 'black',
    textTransform: 'uppercase',
  },

  modalText: {
    fontFamily: 'body',
    fontSize: 'normal',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: 1.2,
    letterSpacing: 'normal',
    color: 'black',
  },
};
