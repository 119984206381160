import React, { FC, useCallback, useContext, useRef, useState } from 'react';
import cn from 'classnames';

import { useTranslation } from 'src/common/hooks/useTranslation';
import { translationKeys } from 'src/common/translations';
import { useOnClickOutside } from 'src/common/hooks/useOnClickOutside';
import { SidebarSettings, StaticSettings } from 'src/common/types';
import { Search as SearchIcon } from 'src/v2/icons/Search';
import { SwitchSidebar } from 'src/v2/icons/SwitchSidebar';

import { StaticSettingsContext } from 'src/common/contexts/StaticSettingsContext';
import { SidebarSettingsContext } from 'src/common/contexts/SidebarSettingsContext';
import { HeaderItem } from '../HeaderItem';

export const Search: FC = () => {
  const i18n = useTranslation();
  const { onSearchSubmit } = useContext<StaticSettings>(StaticSettingsContext);
  const { isCollapsedMobile, toggleIsCollapsedMobile } =
    useContext<SidebarSettings>(SidebarSettingsContext);

  const searchRef = useRef(null);
  const [query, setQuery] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [queryNotSubmitted, setQueryNotSubmitted] = useState(true);
  const activate = useCallback(() => {
    if (!isActive) setIsActive(true);
  }, [isActive]);
  const deactivate = useCallback(() => {
    if (isActive) setIsActive(false);
  }, [isActive]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value !== query) {
        setQuery(event.target.value);
        setQueryNotSubmitted(true);
      }
    },
    [query],
  );

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (queryNotSubmitted) {
        onSearchSubmit(query);
        setQueryNotSubmitted(false);
      }
    },
    [onSearchSubmit, query, queryNotSubmitted],
  );

  useOnClickOutside(searchRef, deactivate);

  return (
    <HeaderItem>
      <form className={cn('c-search', isActive && 'c-search--active')} onSubmit={handleSubmit}>
        <div className="c-search__button" onClick={activate}>
          <SearchIcon />
        </div>
        <div className="c-search__input" ref={searchRef}>
          <input
            name="query"
            placeholder={i18n(translationKeys.header.search)}
            onChange={handleInputChange}
          />
          <button className="c-search__submit" type="submit">
            <span className="c-search__submit--icon">
              <SearchIcon />
            </span>
          </button>
        </div>
      </form>
      <div
        className={cn('l-sidebar__switch', isCollapsedMobile && 'l-sidebar__switch--active')}
        data-type="sidebar-switch"
        onClick={toggleIsCollapsedMobile}
      >
        <SwitchSidebar />
      </div>
    </HeaderItem>
  );
};
