import { BillingDetailsPayload } from 'src/models/billing';
import { BillingDetailsFormValues } from 'src/v2/features/checkout/types';

export const adapterBillingDetailsForm = (
  values: BillingDetailsFormValues,
): BillingDetailsPayload => {
  const { city, country, address, postalCode, state, email, firstName, lastName } = values;
  return {
    address: {
      city,
      country,
      line1: address,
      postal_code: postalCode,
      state,
    },
    email,
    first_name: firstName,
    last_name: lastName,
  };
};
