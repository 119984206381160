import { FC } from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Link } from '@theme-ui/components';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { validateAuthForm } from 'src/utils/validate';
import { Email } from 'src/v2/features/reduxForm/Email';
import { Password } from 'src/v2/features/reduxForm/Password';
import { Button } from 'src/v2/features/button';
import { login } from 'src/v2/features/auth/store/authStore';
import { validatePassword } from 'src/utils/passwordStrength';
import { CForm, CFormRow } from 'src/v2/components/CForm';

export interface Credentials {
  username: string;
  password: string;
}

interface Props {
  onSignUpFlow?: () => void;
  onForgotPasswordFlow?: () => void;
}

export const Form: FC<Props & InjectedFormProps<Credentials, Props>> = ({
  handleSubmit,
  invalid,
  submitting,
  onSignUpFlow,
  onForgotPasswordFlow,
}) => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();

  const submitForm = (values: Credentials): void => {
    dispatch(
      login({
        email: values.username,
        password: values.password,
      }),
    );
  };

  return (
    <>
      <div className="c-modal__row c-modal--mt-24">
        <div className="c-title c-title__auth c-title--color-theme c-title--fs-64">
          {i18n(translationKeys.forms.loginLight.welcome)}
        </div>
      </div>
      <div className="c-modal__row">
        <div className="c-title c-title__auth c-title--color-theme c-title--fw-400">
          {i18n(translationKeys.forms.loginLight.continueReading)}
        </div>
      </div>
      <div className="c-modal__row c-modal--mt-24">
        <CForm className="c-form c-form--grow" onSubmit={handleSubmit(submitForm)} noValidate>
          <CFormRow>
            <Field
              className="c-form__column"
              name="username"
              component={Email}
              placeholder={i18n(translationKeys.forms.loginLight.fields.email)}
            />
          </CFormRow>
          <CFormRow>
            <Field
              className="c-form__column"
              name="password"
              type="password"
              placeholder={i18n(translationKeys.forms.createPassword.password)}
              component={Password}
              validate={validatePassword}
              required
            />
          </CFormRow>
          <CFormRow className="c-form--justify-end">
            <Link
              to={{ pathname, search }}
              className="c-form__link"
              data-type="signupNow-link"
              as={RouterLink}
              onClick={onForgotPasswordFlow}
            >
              {i18n(translationKeys.forms.loginLight.forgotPassword)}
            </Link>
          </CFormRow>
          <CFormRow>
            <div className="c-form__column">
              <Button
                className="c-button c-button--fs-12 c-button--w-full h-uppercase"
                type="submit"
                disabled={invalid || submitting}
              >
                {i18n(translationKeys.buttons.signIn)}
              </Button>
            </div>
          </CFormRow>
          <CFormRow className="c-form--mt-32">
            <div className="c-form__column">
              {i18n(translationKeys.forms.loginLight.noAccount)}{' '}
              <Link
                to={{ pathname, search }}
                className="c-form__link"
                data-type="signupNow-link"
                as={RouterLink}
                onClick={onSignUpFlow}
              >
                {i18n(translationKeys.forms.loginLight.signUp)}
              </Link>
            </div>
          </CFormRow>
        </CForm>
      </div>
    </>
  );
};

export const LoginFormLight = reduxForm<Credentials, Props>({
  form: 'login',
  validate: validateAuthForm,
})(Form);
