import { FC } from 'react';
import { Field, InjectedFormProps } from 'redux-form';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { ContractPayload } from 'src/models/contract';
import { MetadataForm, connectForm, RoleField } from 'src/v2/features/metadata';
import { Checkbox } from 'src/v2/features/reduxForm';
import { EntityType } from 'src/models/paper';
import { ContractMetaProps } from './types';

interface Props extends ContractMetaProps {
  isLoading: boolean;
  isAllowedToChangeOwnerCondition: boolean;
  onSubmit: (data: ContractPayload) => void;
}

const Edit: FC<Props & InjectedFormProps<ContractPayload, Props>> = ({
  isLoading,
  isAllowedToChangeOwnerCondition,
  onSubmit,
  handleSubmit,
  pristine,
}) => (
  <MetadataForm
    label={i18n(translationKeys.forms.contract.update)}
    infoLabel={i18n(translationKeys.forms.contract.create.createNewInfo)}
    type={EntityType.contract}
    isLoading={isLoading}
    isPristine={pristine}
    onSubmit={handleSubmit(onSubmit)}
  >
    <RoleField />
    {isAllowedToChangeOwnerCondition && (
      <Field
        component={Checkbox}
        type="checkbox"
        name="ownerConditionRequired"
        label={i18n(translationKeys.forms.item.signParty)}
        sx={{
          mb: '8px',
          alignItems: 'center',
          fontWeight: 'normal',
        }}
      />
    )}
  </MetadataForm>
);

const fields = ['role', 'createDocumentOption', 'ownerConditionRequired'];
export const ContractMetadataEdit = connectForm<Props, ContractPayload>(Edit, fields);
