import { useContext } from 'react';

import { ContractType, ContractRole } from 'src/models/contract';
import {
  NineSquareMenu as CNineSquareMenu,
  AddUser,
  EditUser,
  SaveTemplate,
  Download,
  Share,
  QRCode,
} from 'src/v2/features/nineSquareMenu';
import { useNavigateToShare } from 'src/v2/features/sharedEntity/EntitySidebar/hooks';
import { useGetEntityById, useDownloadAsFile } from 'src/v2/features/sharedEntity/hooks';
import { useEditParticipantCallback } from 'src/v2/features/entityParticipants/hooks';
import { SharedSidebarContext } from 'src/v2/features/sharedEntity/EntitySidebar/SharedSidebarContext';
import {
  useCanDownloadContract,
  useCanManageParticipantInContract,
  useCanShareContract,
} from 'src/v2/features/contract/permissions/contractPermissions/contractPermissionsHooks';
import { useHasQRCodeFeature } from 'src/v2/features/billing/hasFeature/hooks';

interface Props {
  id?: string;
}

export const ContractNineSquareMenu = ({ id }: Props): JSX.Element => {
  const contract = useGetEntityById<ContractType, ContractRole>(id);
  const handleOnDownload = useDownloadAsFile<ContractType, ContractRole>(contract);

  const { navigateToInviteParticipant, navigateToEditParticipant, navigateToQRCode } =
    useContext(SharedSidebarContext);
  const handleInviteUser = navigateToInviteParticipant;
  const canInviteUser = useCanManageParticipantInContract(contract);
  const canDownloadContract = useCanDownloadContract(contract);
  const canEditUsersInContract = useCanManageParticipantInContract(contract);
  const canUserShareContract = useCanShareContract(contract);
  const handleEditUser = useEditParticipantCallback(navigateToEditParticipant)();
  const handleShare = useNavigateToShare(id);
  const handleQRCode = navigateToQRCode;
  const hasQRCodeFeature = useHasQRCodeFeature();

  return (
    <CNineSquareMenu>
      <AddUser onClick={handleInviteUser} data-id="addUser-menuButton" disabled={!canInviteUser} />
      <EditUser
        onClick={handleEditUser}
        data-id="editUser-menuButton"
        disabled={!canEditUsersInContract}
      />
      <SaveTemplate data-id="saveTemplate-menuButton" disabled />
      <Download
        onClick={handleOnDownload}
        data-id="download-menuButton"
        disabled={!canDownloadContract}
      />
      <Share onClick={handleShare} data-id="share-menuButton" disabled={!canUserShareContract} />
      {hasQRCodeFeature && <QRCode onClick={handleQRCode} data-id="qrCode-menuButton" />}
    </CNineSquareMenu>
  );
};
