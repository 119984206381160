import { useEffect } from 'react';

import { setDefaultSort } from 'src/common/sortStore';

export const useDefaultSortEffct = () => {
  useEffect(() => {
    return () => {
      setDefaultSort();
    };
  }, []);
};
