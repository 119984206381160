import { useSelector } from 'react-redux';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { Link } from 'src/v2/components/Link';
import { book } from 'src/app/book';
import { canEditOrganizationInfo } from 'src/utils/permissions';
import { getCurrentOrganizationUserSystemRole } from 'src/shared/organization';
import { RootState } from 'src/app/types';

export const ProfileSidebarNavView = (): JSX.Element => {
  const companyId = useSelector((state: RootState) => state.auth.companyId);
  const userSystemRole = useSelector(getCurrentOrganizationUserSystemRole);

  return (
    <div data-id="profile-container-navigation" className="c-nav__settings">
      <Link
        data-type="personalInfo-link"
        to={book.profile.personal.generatePath()}
        linkToMatchActive={book.profile.personal.pattern}
        className="c-nav__settings--link"
        activeClassName="c-nav__settings--active"
      >
        {i18n(translationKeys.profileMenu.personalInformation)}
      </Link>
      {!!companyId && (
        <Link
          data-type="orgInfo-link"
          to={book.profile.organization.generatePath()}
          linkToMatchActive={book.profile.organization.pattern}
          className="c-nav__settings--link"
          activeClassName="c-nav__settings--active"
        >
          {i18n(translationKeys.profileMenu.organizationInformation)}
        </Link>
      )}

      <Link
        data-type="security-link"
        to={book.profile.security}
        linkToMatchActive={book.profile.security}
        className="c-nav__settings--link"
        activeClassName="c-nav__settings--active"
      >
        {i18n(translationKeys.profileMenu.security)}
      </Link>

      <Link
        data-type="signature-link"
        to={book.profile.signature}
        linkToMatchActive={book.profile.signature}
        className="c-nav__settings--link"
        activeClassName="c-nav__settings--active"
      >
        {i18n(translationKeys.profileMenu.manageSignature)}
      </Link>

      {!!companyId && canEditOrganizationInfo(userSystemRole) && (
        <Link
          data-type="manageOrgUsers-link"
          to={book.profile.users.generatePath()}
          linkToMatchActive={book.profile.users.pattern}
          className="c-nav__settings--link"
          activeClassName="c-nav__settings--active"
        >
          {i18n(translationKeys.profileMenu.manageOrganizationUsers)}
        </Link>
      )}

      <Link
        data-type="notifications-link"
        to={book.profile.notifications.generatePath()}
        linkToMatchActive={book.profile.notifications.pattern}
        className="c-nav__settings--link"
        activeClassName="c-nav__settings--active"
      >
        {i18n(translationKeys.profileMenu.notifications)}
      </Link>
      <Link
        data-type="pricingAndBilling-link"
        to={book.profile.pricingAndBilling.generatePath()}
        linkToMatchActive={book.profile.pricingAndBilling.pattern}
        className="c-nav__settings--link"
        activeClassName="c-nav__settings--active"
      >
        {i18n(translationKeys.profileMenu.planBillingDetails)}
      </Link>
    </div>
  );
};
