import { SagaIterator } from 'redux-saga';
import { call, takeLatest, put, select } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { PayloadAction } from '@reduxjs/toolkit';
import { isNull, isUndefined } from 'lodash';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { handleErrorSaga } from 'src/utils/handleErrorSaga';
import {
  continueWithBasicSubscriptionApi,
  fetchBillingInfo,
  fetchCardInfo,
  fetchPricingByPlanAndPeriod,
  submitBillingInfoApi,
  updateSubscriptionApi,
  cancelSubscriptionApi,
} from 'src/api/billingApi';
import {
  BillingDetailsPayload,
  BillingInfoResponse,
  CardInfoResponse,
  BillingInfoPayload,
  BillingPlanSubscriptionPayload,
  FetchPricingInfoPayload,
  SubscriptionPrice,
  SubscribePayload,
  SubscriptionPlan,
  BillingPeriod,
} from 'src/models/billing';
import { toJSONApiPayload } from 'src/common/object-utils';
import {
  doPaymentSaga,
  retryFailedSubscription,
  setBillingDetails as setBillingDetailsCheckout,
  setNumberOfSeats,
  upgradeSubscriptionAction,
} from 'src/v2/features/checkout/store';
import { PaymentIntent, PaymentStatus } from 'src/v2/features/checkout/types';
import { convertPlanAndPeriod } from 'src/v2/features/checkout/config';

import { emitUserNeedsToCreatePlan, emitBillingInfoSuccess } from '../billingEventBus';
import {
  fetchBillingDetails,
  fetchBillingDetailsWithCard,
  fetchPricingInfo,
  setBillingInfo,
  setBillingCard,
  setBillingDetails,
  submitBillingDetails,
  submitBillingPlan,
  setCalculatedPrice,
  continueWithBasicSubscription,
  startLoading,
  stopLoading,
  finishLoading,
  resetCalculatedPrice,
  upgradeBillingPlan,
  upgradeBillingCard,
} from './billingReducers';
import { normalizeBillingDetails, extractBillingDetails } from './billingNormalizers';
import { shouldSubscriptionCanceled, shouldSubscriptionBeCreated } from '../utils';
import { getBillingInfo } from './billingSelectors';

function* updateCardDetailsSaga({
  paymentMethodId,
}: {
  paymentMethodId?: string;
}): SagaIterator<PaymentIntent> {
  if (isUndefined(paymentMethodId))
    return {
      status: PaymentStatus.RequiresAction,
      client_secret: '',
    };

  return yield call(updateSubscriptionApi, paymentMethodId);
}

function* updateCardDetailsAndUpgradeSubscriptionSaga({
  plan,
  period,
  paymentMethodId,
}: {
  plan: SubscriptionPlan;
  period: BillingPeriod;
  paymentMethodId?: string;
}): SagaIterator<PaymentIntent> {
  if (isUndefined(paymentMethodId))
    return {
      status: PaymentStatus.RequiresAction,
      client_secret: '',
    };

  yield call(updateCardDetailsSaga, { paymentMethodId });
  return yield call(upgradeSubscriptionAction, { plan, period });
}

function* upgradeBillingPlanSaga(action: PayloadAction<SubscribePayload>) {
  const { card } = yield select(getBillingInfo);
  if (isNull(card.brand)) {
    yield call(doPaymentSaga, action, updateCardDetailsAndUpgradeSubscriptionSaga);
  } else {
    yield call(doPaymentSaga, action, upgradeSubscriptionAction);
  }
}

function* upgradeBillingCardSaga(action: PayloadAction<SubscribePayload>) {
  yield call(doPaymentSaga, action, updateCardDetailsSaga);
}

function* updateBillingDetailsSuccessSaga() {
  yield call(
    toastr.success,
    i18n(translationKeys.messages.success),
    i18n(translationKeys.messages.billingDetailsUpdated),
  );
  yield call(emitBillingInfoSuccess);
}

function* submitBillingDetailsSaga(action: PayloadAction<BillingInfoPayload>) {
  try {
    yield put(startLoading());
    const billingDetails: BillingDetailsPayload = extractBillingDetails(action.payload);
    yield call(submitBillingInfoApi, toJSONApiPayload<BillingDetailsPayload>(billingDetails));
    yield put(setBillingDetails(normalizeBillingDetails(action.payload)));
    const { cardNumberElement, plan, period, stripe, shouldUseRetry } = action.payload;
    if (cardNumberElement && plan && period && stripe) {
      const { activePlan, billingPeriod } = yield select(getBillingInfo);
      yield put(setBillingDetailsCheckout(billingDetails));
      if (shouldUseRetry) {
        return yield put(retryFailedSubscription({ cardNumberElement, plan, period, stripe }));
      }
      if (shouldSubscriptionBeCreated(plan, activePlan)) {
        yield put(upgradeBillingPlan({ cardNumberElement, plan, period, stripe }));
      } else if (period === billingPeriod) {
        yield put(upgradeBillingCard({ cardNumberElement, plan, period, stripe }));
      } else {
        yield call(upgradeSubscriptionAction, { plan, period });
        yield call(updateBillingDetailsSuccessSaga);
      }
    } else {
      yield call(updateBillingDetailsSuccessSaga);
    }
    yield put(finishLoading());
  } catch (error) {
    yield put(stopLoading(error));
    yield call(handleErrorSaga, error);
  }
}

function* submitBillingPlanSaga(action: PayloadAction<BillingPlanSubscriptionPayload>) {
  try {
    yield put(startLoading());
    if (action.payload.planAndPeriod) {
      const result = convertPlanAndPeriod(action.payload.planAndPeriod);
      if (result) {
        const { activePlan, billingPeriod: activePeriod } = yield select(getBillingInfo);
        const [plan, period] = result;
        if (activePlan !== plan || activePeriod !== period) {
          if (shouldSubscriptionCanceled(plan)) {
            yield call(cancelSubscriptionApi);
            yield put(fetchBillingDetails());
            yield call(
              toastr.success,
              i18n(translationKeys.messages.success),
              i18n(translationKeys.messages.billingPlanUpdated),
            );
          } else if (shouldSubscriptionBeCreated(plan, activePlan)) {
            yield call(emitUserNeedsToCreatePlan, { plan, period });
          } else {
            yield call(upgradeSubscriptionAction, { plan, period });
            yield put(fetchBillingDetails());
            yield call(
              toastr.success,
              i18n(translationKeys.messages.success),
              i18n(translationKeys.messages.billingPlanUpdated),
            );
          }
        }
      }
    }
    yield put(finishLoading());
  } catch (error) {
    yield put(stopLoading(error));
    yield call(handleErrorSaga, error);
  }
}

function* fetchBillingInfoSaga() {
  try {
    yield put(startLoading());
    const response: BillingInfoResponse = yield call(fetchBillingInfo);
    yield put(setBillingInfo(response.data));
    yield put(finishLoading());
  } catch (e) {
    console.warn(e);
    yield put(finishLoading());
  }
}

function* fetchBillingInfoWithCardSaga() {
  try {
    yield put(startLoading());
    const responseBilling: BillingInfoResponse = yield call(fetchBillingInfo);
    yield put(setBillingInfo(responseBilling.data));
    const responseCard: CardInfoResponse = yield call(fetchCardInfo);
    yield put(setBillingCard(responseCard.data));
    yield put(finishLoading());
  } catch (e) {
    console.warn(e);
    yield put(finishLoading());
  }
}

export function* fetchPricingByPlanAndPeriodSaga({
  payload: { plan, billingPeriod, numberOfSeats },
}: PayloadAction<FetchPricingInfoPayload>) {
  try {
    yield put(startLoading());
    yield put(resetCalculatedPrice());
    const data: { data: SubscriptionPrice } = yield call(
      fetchPricingByPlanAndPeriod,
      plan,
      billingPeriod,
      numberOfSeats,
    );

    if (!isUndefined(numberOfSeats)) {
      yield put(setNumberOfSeats(numberOfSeats));
    }

    yield put(setCalculatedPrice(data.data));
    yield put(finishLoading());
  } catch (e) {
    console.warn(e);
    yield put(finishLoading());
  }
}

function* continueWithBasicSubscriptionSaga() {
  try {
    yield put(startLoading());
    yield call(continueWithBasicSubscriptionApi);
    yield put(fetchBillingDetails());
    yield put(finishLoading());
  } catch (e) {
    yield put(stopLoading(e));
    console.warn(e);
  }
}

export function* watchBillingSagas(): SagaIterator {
  yield takeLatest(fetchBillingDetails, fetchBillingInfoSaga);
  yield takeLatest(fetchBillingDetailsWithCard, fetchBillingInfoWithCardSaga);
  yield takeLatest(fetchPricingInfo, fetchPricingByPlanAndPeriodSaga);
  yield takeLatest(continueWithBasicSubscription, continueWithBasicSubscriptionSaga);
  yield takeLatest(submitBillingDetails, submitBillingDetailsSaga);
  yield takeLatest(submitBillingPlan, submitBillingPlanSaga);
  yield takeLatest(upgradeBillingPlan, upgradeBillingPlanSaga);
  yield takeLatest(upgradeBillingCard, upgradeBillingCardSaga);
}
