import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload,
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';

import { AuthState, Notification } from 'src/app/types';
import { Credentials } from 'src/shared/auth/types';
import { fetchCompanyDataSaga } from 'src/v2/features/auth/workers/loginSaga';

import { forceLogoutSaga, loginSaga, logoutSaga } from '../workers';

const authInitialState: AuthState = {
  token: null,
  userId: null,
  companyId: null,
  isLoginAvailable: null,
  isEmailSent: false,
  isLoading: false,
  error: null,
  notification: null,
  isSigningup: false,
  data: null,
  companyName: null,
  signatureName: null,
  companyTitle: null,
};

const auth = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    startLoading: (state): void => {
      state.isLoading = true;
    },

    stopLoading: (state): void => {
      state.isLoading = false;
    },

    setAuthToken: (state, action: PayloadAction<string>): void => {
      state.token = action.payload;
    },

    setUserId: (state, action: PayloadAction<string>): void => {
      state.userId = action.payload;
    },

    setCompanyId: (state, action: PayloadAction<string | undefined>): void => {
      state.companyId = action.payload || null;
    },

    setIsLoginAvailable: (state, action: PayloadAction<boolean>): void => {
      state.isLoginAvailable = action.payload;
    },

    logout: (state): void => {
      state.token = null;
      state.userId = null;
      state.companyId = null;
      state.isLoginAvailable = null;
      state.error = null;
      state.data = null;
      state.companyName = null;
      state.signatureName = null;
      state.companyTitle = null;
    },

    emitError: (state, action: PayloadAction<string>): void => {
      state.error = action.payload;
    },

    addNotification: (state, action: PayloadAction<Notification>): void => {
      state.notification = action.payload;
    },

    removeNotification: (state): void => {
      state.notification = authInitialState.notification;
    },
  },
});

export const {
  startLoading,
  stopLoading,
  setAuthToken,
  setUserId,
  setCompanyId,
  setIsLoginAvailable,
  logout,
  emitError,
  removeNotification,
} = auth.actions;

export default auth.reducer;

export const login: ActionCreatorWithPayload<Credentials> = createAction('auth/login');
export const logoutAsync: ActionCreatorWithoutPayload = createAction('auth/logoutAsync');
export const forceLogoutAsync: ActionCreatorWithoutPayload = createAction('auth/forceLogoutAsync');
export const fetchCompanyData = createAction('auth/fetchCompanyData');

export function* watchAuthSagas(): SagaIterator {
  yield takeLatest(fetchCompanyData, fetchCompanyDataSaga);
  yield takeLatest(login, loginSaga);
  yield takeLatest(logoutAsync, logoutSaga);
  yield takeLatest(forceLogoutAsync, forceLogoutSaga);
}
