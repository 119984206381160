import { FC, useEffect } from 'react';
import { isEmpty, isUndefined } from 'lodash';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { AppAdvancedLayout } from 'src/v2/modules/layout';
import { subscribeForRemoveParticipant } from 'src/v2/features/sharedEntity/entityEventBus';
import { useCreateNavigateToDefaultBy } from 'src/v2/features/sharedEntity/EntitySidebar/hooks/nav-hooks';

interface LayoutProps {
  error?: string;
  isLoading: boolean;
}

export const TemplateLayout: FC<LayoutProps> = ({ children, error, isLoading }) => {
  const createNavigateToDefault = useCreateNavigateToDefaultBy();

  useEffect(() => {
    const s$ = subscribeForRemoveParticipant((eventData) => {
      const navigateToDefault = createNavigateToDefault(eventData.payload.documentId);
      navigateToDefault();
    });

    return () => s$.unsubscribe();
  });

  if (isLoading) {
    return (
      <AppAdvancedLayout>
        <b>{i18n(translationKeys.messages.loading)}</b>
      </AppAdvancedLayout>
    );
  }

  if (!isUndefined(error) && !isEmpty(error)) {
    return (
      <AppAdvancedLayout>
        <b style={{ color: 'red' }}>{error}</b>
      </AppAdvancedLayout>
    );
  }

  return <AppAdvancedLayout>{children}</AppAdvancedLayout>;
};
