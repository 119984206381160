import { isNull, isUndefined, transform } from 'lodash';

import { FieldData } from 'src/api/documents';
import { FieldType } from 'src/models/editor';

import { isPropagateFieldsValues } from '../../EditorCore/utils';

function parseFragment(content: string): HTMLTemplateElement {
  const template = document.createElement('template');
  template.innerHTML = content;
  return template;
}

function generate() {
  var ObjectId = (m = Math, d = Date, h = 16, s = (s) => m.floor(s).toString(h)) =>
    s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h));
  // Thanks to Ruben Stolk
  return ObjectId();
}

export function getContentFields(content: string): FieldData[] {
  const fragment = parseFragment(content);
  let uniqFields;
  return transform(
    {
      '.editable-field[data-name]': (el: Element): FieldData => ({
        name: el.getAttribute('data-name') || '',
        offset: content.indexOf(el.outerHTML),
        value: el.querySelector('.editable-field-title')?.innerHTML || '',
        editorFieldId: el.getAttribute('data-id') || '',
      }),
      '.dw-checkbox[id]': (el: Element): FieldData => ({
        name: el.getAttribute('id') || '',
        offset: content.indexOf(el.outerHTML),
        value: el.getAttribute('checked') || '',
        editorFieldId: el.getAttribute('id') || '',
      }),
    },
    (acc: FieldData[], parseFn: (el: Element) => FieldData, selector: string) => {
      const nodes = fragment.content.querySelectorAll(selector);
      // @ts-ignore
      const fields = [...nodes].map(parseFn);
      if (isPropagateFieldsValues()) {
        uniqFields = fields.map((field) => {
          return Object.assign({
            ...field,
            id: generate(),
          });
        });
      } else {
        uniqFields =
          !isUndefined(fields) && fields.length
            ? fields.map((field) => {
                return Object.assign({
                  ...field,
                  id: generate(),
                });
              })
            : fields;
      }
      acc.push(...uniqFields);
    },
    [],
  );
}

export function updateContentField(
  type: FieldType,
  content: string,
  id: string,
  value: string,
  dataId?: string,
): string {
  const fragment = document.createElement('div');
  fragment.innerHTML = content;

  if (type === FieldType.Text) {
    const fields = fragment.querySelectorAll(`.editable-field`);
    if (fields.length)
      fields.forEach((field) => {
        const fieldName = field.getAttribute('data-name');
        const fieldId = field.getAttribute('data-id');

        const titleNodeOfField = field.querySelector(`.editable-field-title`);
        if (!isPropagateFieldsValues() && fieldId === dataId && !isNull(titleNodeOfField)) {
          titleNodeOfField.innerHTML = value;
        }
        if (isPropagateFieldsValues() && id === fieldName && !isNull(titleNodeOfField)) {
          titleNodeOfField.innerHTML = value;
        }
      });
  }

  if (type === FieldType.Checkbox) {
    const checkBoxes = fragment.querySelectorAll(`.dw-checkbox`);
    if (checkBoxes.length) {
      checkBoxes.forEach((checkBox) => {
        const checkBoxId = checkBox.getAttribute('id');
        if (checkBoxId === id) {
          checkBox.setAttribute('checked', value);
          if (value === 'true') {
            checkBox.setAttribute('class', 'dw-checkbox dw-checkboxActive');
          } else {
            checkBox.setAttribute('class', 'dw-checkbox');
          }
        }
      });
    }
  }
  return fragment.innerHTML;
}
