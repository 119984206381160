import React from 'react';
import cn from 'classnames';

import { AttachmentDoc as AttachmentDocIcon } from 'src/v2/icons/AttachmentDoc';
import { AttachmentImg as AttachmentImgIcon } from 'src/v2/icons/AttachmentImg';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

import { AttachmentType } from './types';

interface Props {
  type: AttachmentType;
  title: string;
  lastModified: string;
  url: string;
  onClickIcon: (e: React.MouseEvent<HTMLDivElement>) => void;
  onClickDelete: (e: React.MouseEvent<HTMLDivElement>) => void;
  onClickConfirmDelete: (e: React.MouseEvent<HTMLSpanElement>) => void;
  onClickCancelDelete: (e: React.MouseEvent<HTMLSpanElement>) => void;
  showDeleteConfirmation?: boolean;
  showDelete?: boolean;
}

export const Attachment = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      title,
      lastModified,
      type,
      url,
      showDeleteConfirmation = false,
      showDelete = false,
      onClickIcon,
      onClickDelete,
      onClickConfirmDelete,
      onClickCancelDelete,
    },
    ref,
  ) => {
    const containerStyle = cn('c-attachment__row', {
      'c-attachment__row--remove': showDeleteConfirmation,
    });

    return (
      <div className={containerStyle} ref={ref}>
        <a className="c-attachment__link" href={url} target="_blank" rel="noopener noreferrer">
          <div className="c-attachment__icon" onClick={onClickIcon}>
            {type === AttachmentType.Doc && <AttachmentDocIcon />}
            {type === AttachmentType.Img && <AttachmentImgIcon />}
          </div>
          <div className="c-attachment__info">
            <div className="c-attachment__title">{title}</div>
            <div className="c-attachment__text">
              {i18n(translationKeys.attachment.text)} {lastModified}
            </div>
          </div>
        </a>
        {showDelete ? <div className="c-attachment__remove" onClick={onClickDelete} /> : null}
        <div className="c-attachment__alert c-attachment__alert--active">
          <div className="c-attachment__alert--title">
            {i18n(translationKeys.attachment.delete)}
          </div>
          <div className="c-attachment__alert--action">
            <div className="c-button c-button--green">
              <span className="c-button__inner" onClick={onClickConfirmDelete}>
                {i18n(translationKeys.buttons.yes)}
              </span>
            </div>
            <div className="c-button c-button--red">
              <span className="c-button__inner" onClick={onClickCancelDelete}>
                {i18n(translationKeys.buttons.cancel)}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

Attachment.displayName = 'Attachment';
