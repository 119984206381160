import { includes } from 'lodash';
import { SubscriptionStatus } from 'src/models/billing';

const validExistingSubscriptionsStatuses = [
  SubscriptionStatus.Active,
  SubscriptionStatus.IncompleteExpired,
];

const invalidExistingSubscriptionsStatuses = [
  SubscriptionStatus.Unpaid,
  SubscriptionStatus.Incomplete,
  SubscriptionStatus.IncompleteExpired,
];

export const hasValidExistingSubscription = (currentSubscriptionState: SubscriptionStatus | null) =>
  includes(validExistingSubscriptionsStatuses, currentSubscriptionState);

export const hasInvalidExistingSubscription = (
  currentSubscriptionState: SubscriptionStatus | null,
) => includes(invalidExistingSubscriptionsStatuses, currentSubscriptionState);
