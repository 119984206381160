import React from 'react';
import { useSelector } from 'react-redux';

import { useGetEntityFieldsDiff } from 'src/v2/features/sharedEntity/hooks';
import {
  getOwnerSideMembers,
  getOwnerFields,
  getOwnerSections,
  isOwnerParty as getIsOwnerParty,
  getOwnerSideSignatures,
} from 'src/v2/features/documentDetails/documentDetailsSlice';

import { ContractSide } from './ContractSide';
import { PaperParticipantsAffiliationType } from 'src/v2/boundary/storageModels/paper/paperParticipant';
interface Props {
  id: string;
}

export const ContractOwnerSide: React.FC<Props> = ({ id }) => {
  const ownerFields = useSelector(getOwnerFields);
  const ownerSideMembers = useSelector(getOwnerSideMembers);
  const ownerSections = useSelector(getOwnerSections);
  const { ownerFieldsDiff } = useGetEntityFieldsDiff();
  const ownerSideSignatures = useSelector(getOwnerSideSignatures);
  const isOwnerParty = useSelector(getIsOwnerParty);

  return (
    <ContractSide
      id={id}
      members={ownerSideMembers}
      sections={ownerSections}
      fields={ownerFields}
      diff={ownerFieldsDiff}
      negotiable={isOwnerParty}
      signatures={ownerSideSignatures}
      type={PaperParticipantsAffiliationType.owner}
    />
  );
};
