import { isNull } from 'lodash';

import { Node, NodeMap, NodeTree } from 'src/models/node';

export const listToTree = <N extends Node>(list: N[]): NodeTree<N>[] => {
  const map: Record<string, number> = {};
  let node: NodeTree<N>;
  const roots: NodeTree<N>[] = [];

  const newList: Record<string, NodeTree<N>> = {};

  for (let i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map

    newList[i] = {
      data: list[i],
      children: [],
    };
  }

  for (let i = 0; i < list.length; i += 1) {
    node = newList[i];
    if (node.data.parentId !== '') {
      if (newList[map[node.data.parentId]]) {
        newList[map[node.data.parentId]].children.push(node);
      }
    } else {
      roots.push(node);
    }
  }
  return roots;
};

type MapWithChildren<T extends Node> = Record<Node['id'], NodeMap<T>>;

export const listToMapWithChildren = <N extends Node>(list: N[]): MapWithChildren<N> => {
  const map: MapWithChildren<N> = {};
  const onlyChildrenIdMap: Record<Node['id'], { children: Node['id'][] }> = {};

  for (let i = 0; i < list.length; i += 1) {
    const currentItem = list[i];
    const currentId = currentItem.id;
    const currentParentId = currentItem.parentId;

    if (onlyChildrenIdMap[currentId]) {
      map[currentId] = {
        data: currentItem,
        children: onlyChildrenIdMap[currentId].children,
      };

      delete onlyChildrenIdMap[currentId];
    } else {
      map[currentId] = {
        data: currentItem,
        children: [],
      };
    }

    const currentParent = map[currentParentId];

    if (currentParent) {
      currentParent.children.push(currentId);
    } else {
      onlyChildrenIdMap[currentParentId] = {
        children: [currentId],
      };
    }
  }

  return map;
};

export const mapWithChildrenToTree = <M extends Node>(
  map: MapWithChildren<M>,
  id: Node['id'],
): NodeTree<M> | null => {
  const itemInMap = map[id];
  if (itemInMap) {
    const withChildren = itemInMap.children
      .map((childId) => mapWithChildrenToTree<M>(map, childId))
      .filter((child) => !isNull(child)) as NodeTree<M>[];

    return {
      data: itemInMap.data,
      children: withChildren,
    };
  }

  return null;
};
