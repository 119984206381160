import React, { useMemo } from 'react';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { isUndefined } from 'lodash';

import { CreationMode } from 'src/api/documents';
import { EntityType } from 'src/models/paper';
import { Case, Switch } from 'src/v2/components/SwitchCase';
import { LibrarySection } from 'src/v2/features/sharedEntity';
import { useCreatePaperPageData } from 'src/v2/features/sharedEntity/EntitySidebar/hooks';
import {
  CreateDocumentDTO,
  CreateDocumentFromTemplateDTO,
} from 'src/v2/boundary/requestDTO/document';
import { FormDocumentCreatePayload } from 'src/v2/features/document/components/DocumentMetadata/types';
import { DocumentSidebarUpload } from 'src/v2/features/documentSidebar/documentSidebarUpload';
import { CreateDocumentFromFilePayload } from 'src/v2/features/documentSidebar/documentSidebarUpload/types';

import { DocumentMetadataCreate } from '../../components/DocumentMetadata';

interface Props {
  convertFormPayloadToDTO: (payload: FormDocumentCreatePayload) => CreateDocumentDTO;
  createDocument: ActionCreatorWithPayload<CreateDocumentDTO>;
  createDocumentFromFile: ActionCreatorWithPayload<CreateDocumentFromFilePayload>;
  createDocumentFromTemplate: ActionCreatorWithPayload<CreateDocumentFromTemplateDTO>;
}

export const AbstractCreate: React.FC<Props> = ({
  convertFormPayloadToDTO,
  createDocument,
  createDocumentFromFile,
  createDocumentFromTemplate,
}) => {
  const {
    creationMode: state,
    isLoading,
    data: prePopulatedMetadata,
    onFormSubmit: handleSubmit,
    onLibrarySectionSubmit: handleLibrarySectionSubmit,
  } = useCreatePaperPageData<FormDocumentCreatePayload, CreateDocumentDTO>(
    convertFormPayloadToDTO,
    createDocument,
    createDocumentFromTemplate,
  );

  const document = useMemo(
    () => !isUndefined(prePopulatedMetadata) && convertFormPayloadToDTO(prePopulatedMetadata),
    [prePopulatedMetadata, convertFormPayloadToDTO],
  );

  return (
    <Switch criteria={state}>
      <Case value={CreationMode.blank}>
        <DocumentMetadataCreate isLoading={isLoading} onSubmit={handleSubmit} />
      </Case>
      <Case value={CreationMode.template}>
        <LibrarySection
          onSubmit={handleLibrarySectionSubmit}
          type={EntityType.document}
          isLoading={isLoading}
        />
      </Case>
      <Case value={CreationMode.upload}>
        {document && (
          <DocumentSidebarUpload<CreateDocumentDTO>
            document={document}
            uploadFile={createDocumentFromFile}
          />
        )}
      </Case>
    </Switch>
  );
};
