import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNull, size } from 'lodash';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { SidebarLibrary } from 'src/v2/components/SidebarLibrary';
import { SidebarLibraryItem } from 'src/v2/components/SidebarLibrary/SidebarLibraryItem';
import { Button, Type } from 'src/v2/features/button';
import { EntityType } from 'src/models/paper';
import {
  fetchUserTemplates,
  getSharedTemplatesList,
  getUserTemplatesList,
} from 'src/v2/features/sharedEntity/templatesListStore';

export interface Props {
  type: EntityType;
  onSubmit: (templateId: string) => void;
  isLoading: boolean;
}

export const LibrarySection: FC<Props> = ({ onSubmit, type, isLoading }) => {
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>();
  const dispatch = useDispatch();
  const userTemplates = useSelector(getUserTemplatesList);
  const sharedTemplates = useSelector(getSharedTemplatesList);
  const createOnLibraryItemClick = useCallback(
    (id: string) => () => {
      setSelectedTemplateId(id);
    },
    [],
  );

  const handleClick = () => {
    if (selectedTemplateId) {
      onSubmit(selectedTemplateId);
    }
  };

  useEffect(() => {
    dispatch(fetchUserTemplates(type));
  }, [dispatch, type]);

  return (
    <>
      <SidebarLibrary title={i18n(translationKeys.card.template.title)}>
        {!isNull(userTemplates) && size(userTemplates) > 0 ? (
          userTemplates.map((item) => (
            <SidebarLibraryItem
              key={item.templateId}
              onClick={createOnLibraryItemClick(item.templateId)}
              active={item.templateId === selectedTemplateId}
              title={item.title}
              lastModified={item.updatedAt}
            />
          ))
        ) : (
          <b>{i18n(translationKeys.forms.template.createByTemplate.noTemplates)}</b>
        )}
      </SidebarLibrary>
      <SidebarLibrary title="Shared With Me">
        {!isNull(sharedTemplates) && size(sharedTemplates) > 0 ? (
          sharedTemplates.map((item) => (
            <SidebarLibraryItem
              key={item.templateId}
              onClick={createOnLibraryItemClick(item.templateId)}
              active={item.templateId === selectedTemplateId}
              title={item.title}
              lastModified={item.updatedAt}
            />
          ))
        ) : (
          <b>{i18n(translationKeys.forms.template.createByTemplate.noTemplates)}</b>
        )}
      </SidebarLibrary>
      <Button
        actionType={Type.Secondary}
        onClick={handleClick}
        disabled={!selectedTemplateId}
        isLoading={isLoading}
      >
        {i18n(translationKeys.forms[type].create.createNew)}
      </Button>
    </>
  );
};
