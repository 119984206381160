/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useCallback, useEffect } from 'react';
import { Document } from 'src/models/document';
import { useDispatch } from 'react-redux';
import { findParticipantById } from 'src/v2/features/documentParticipants';
import { openChat, closeCurrentChat } from './store';
import { NormalizedChat } from './types';

export const useOpenChat = (availableChats: NormalizedChat[]) => {
  const dispatch = useDispatch();

  return useCallback(
    (userId: string) => {
      const chat = findParticipantById(availableChats, userId);

      if (chat) {
        dispatch(openChat(chat.id));
      }
    },
    [availableChats, dispatch],
  );
};

// closing currentChat on document change or close
export const useCloseChatOnDocumentChange = (document: Document) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(closeCurrentChat());
    };
  }, [dispatch, document]);
};

export const useCreateCanStartChat = (availableChats: NormalizedChat[]) =>
  useCallback(
    (userId: string): boolean => {
      if (availableChats) {
        return Boolean(findParticipantById(availableChats, userId));
      }

      return false;
    },
    [availableChats],
  );
