import React, { FC } from 'react';

import { ContextMenuBook } from 'src/app/types';
import { ContextMenuTypeContext } from '../../quantumNavigator/ContextMenuTypeContext';

export const ContextMenuTypeContextProvider: FC = ({ children }) => (
  <ContextMenuTypeContext.Provider value={ContextMenuBook.DocumentFolder}>
    {children}
  </ContextMenuTypeContext.Provider>
);
