import { RefObject, useEffect } from 'react';

type emptyFn = () => void;

export const useOnClickOutside = (ref: any, handler: (e: Event | any) => void): emptyFn | void => {
  useEffect(() => {
    const listener = (event: Event): void => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return (): void => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export const useOnClickOutsides = (
  refs: RefObject<any>[],
  handler: (e: Event | any) => void,
): emptyFn | void => {
  useEffect(() => {
    const listener = (event: Event): void => {
      const shouldFireHandler = refs.reduce((result, ref) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return false;
        }
        return result;
      }, true);

      if (!shouldFireHandler) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return (): void => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [refs, handler]);
};
