import { PaperParticipantsAffiliationType } from '../boundary/storageModels/paper/paperParticipant';

export enum ParticipantStatus {
  active = 'active',
  pending = 'pending',
}

export interface ProfileEntity {
  id: string;
  firstName: string;
  lastName: string;
  avatar: string;
  signature?: string;
}

export interface CardParticipantEntity<R = unknown> extends ProfileEntity {
  id: string;
  userId: string;
  roleId: R;
  status?: ParticipantStatus;
  email: string;
  affiliationType?: PaperParticipantsAffiliationType | null;
}

export interface SidebarParticipantEntity<R = unknown> extends CardParticipantEntity<R> {
  unreadCount?: number;
  funcRole: string;
  phone?: string;
  role?: string;
}

export interface BaseInviteParticipantEntity<R> {
  funcRole: string;
  email: string;
  roleId: R;
  firstName?: string;
  lastName?: string;
  phone?: string;
  conditionRequired?: boolean;
  affiliationType?: PaperParticipantsAffiliationType | null;
  emailNotification?: boolean;
}
