import React, { FC } from 'react';
import { CardPaperModel } from 'src/models/card';
import { ContractRole, ContractType } from 'src/models/contract';
import { DocumentRole } from 'src/models/document';
import { useGetEntityIdFromHash } from 'src/v2/features/sharedEntity/hooks';
import { isUndefined } from 'lodash';
import { ProjectContractCard } from 'src/v2/features/project/components/ProjectContractCard';
import { ProjectDocumentCard } from 'src/v2/features/project/components/ProjectDocumentCard';

interface Props {
  itemType: 'contract' | 'document';
  cardPaper: CardPaperModel<unknown, unknown>;
  projectId: string;
}

export const ProjectDetailsCard: FC<Props> = ({ itemType, cardPaper, projectId }) => {
  const id = useGetEntityIdFromHash();
  const selected = !isUndefined(id) && cardPaper.id === id;

  return itemType === 'contract' ? (
    <ProjectContractCard
      cardPaper={cardPaper as CardPaperModel<ContractType, ContractRole>}
      selected={selected}
      projectId={projectId}
    />
  ) : (
    <ProjectDocumentCard
      cardPaper={cardPaper as CardPaperModel<DocumentType, DocumentRole>}
      selected={selected}
      projectId={projectId}
    />
  );
};
