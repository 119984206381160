import { head } from 'lodash';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createPromiseAction } from '@adobe/redux-saga-promise';

import { DownloadTemplateAsPDFDTO } from 'src/v2/boundary/requestDTO/template';
import { TemplatesState } from 'src/app/types';
import { ObjectSerialized, ObjectData } from 'src/common/types';
import { defaultActionsFactory } from 'src/utils/defaultSlice';
import {
  TemplatePayload,
  InviteParticipantsPayload,
  RemoveParticipantPayload,
  CreateTemplateActionPayload,
  UpdateTemplateFilePayload,
  CopyTemplatePayload,
} from 'src/models/template';

const initialState: TemplatesState = {
  isLoading: false,
  error: '',
  list: [],
  folders: [],
  data: null,
  chosenFolder: '',
  chosenType: null,
  createFolderMode: false,
  deletedId: null,
};

const { onStart, onSuccess, onError } = defaultActionsFactory();

export const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    startLoading: onStart,
    stopLoading: onError,
    finishLoading: onSuccess,

    setData(state: TemplatesState, action: PayloadAction<ObjectData[]>): void {
      state.data = head(ObjectSerialized.dataToBaseObjects(action.payload)) || null;
    },
    deleteTemplateStart(state: TemplatesState, action: PayloadAction<string | null>): void {
      state.deletedId = action.payload;
    },

    deleteTemplateFinished(state: TemplatesState): void {
      state.deletedId = null;
    },
  },
});

export const templatesReducer = templatesSlice.reducer;

export const {
  startLoading,
  stopLoading,
  finishLoading,
  setData,
  deleteTemplateStart,
  deleteTemplateFinished,
} = templatesSlice.actions;

export const downloadAsPDF = createAction<DownloadTemplateAsPDFDTO>('templates/downloadAsPDF');
export const fetchTemplate = createAction<string>('templates/fetch');
export const createTemplate = createAction<CreateTemplateActionPayload>('templates/createTemplate');
export const deleteTemplate = createAction<string>('templates/delete');
export const copyTemplate = createAction<CopyTemplatePayload>('templates/copy');
export const updateFile = createAction<UpdateTemplateFilePayload>('templates/updateFile');
export const inviteParticipants = createAction<InviteParticipantsPayload>(
  'templates/inviteParticipants',
);
export const removeParticipant = createAction<RemoveParticipantPayload>(
  'templates/removeParticipant',
);
export const removeAllParticipants = createAction<string>('templates/removeAllParticipants');
export const insertSection = createPromiseAction('templates/insertSection');
export const updateSection = createPromiseAction('templates/updateSection');
export const moveSection = createPromiseAction('templates/moveSection');
export const removeSection = createPromiseAction('templates/removeSection');
export const updateTemplate = createAction<TemplatePayload>('templates/updateTemplate');
export const uploadFile = createPromiseAction('templates/uploadFile');
export const uploadFileByUrl = createPromiseAction('templates/uploadFileByUrl');
