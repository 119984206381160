import { useEffect, useRef, useCallback } from 'react';
import { Box } from '@theme-ui/components';
import { isNil, isUndefined } from 'lodash';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

import { mergeStyles } from '../../../utils/styles';
import { EditorMode, FieldDiff, FieldType, FieldUpdateType } from '../../../models/editor';
import { styles } from './styles';
import './styles.css';
import { useCheckBoxStub } from './CheckBoxesStub/useCheckBoxStub';
import { useEditableFieldsStub } from './EditableFieldsStub/useEditableFieldsStub';
import { defaultConfiguration } from '../EditorCore/defaultConfiguration';

interface Props {
  content: string;
  mode: EditorMode;
  isSorting?: boolean;
  onInsert?: (event?: Event) => void;
  onRemove?: (event?: Event) => void;
  handleInitEditor?: () => void;
  onActivate?: () => void;
  diff?: FieldDiff[];
  variant?: string;
  isFieldUpdating?: boolean;
  index?: number;
  onFieldUpdate?: (
    type: FieldType,
    name: string,
    value: string,
    updateType: FieldUpdateType,
    dataId?: string,
  ) => void;
}

export const EditorStub = ({
  content,
  mode,
  isSorting,
  onInsert,
  onRemove,
  handleInitEditor,
  onActivate,
  diff,
  variant,
  isFieldUpdating,
  onFieldUpdate,
  index,
}: Props): JSX.Element => {
  const containerStyles = mergeStyles(
    styles.container,
    [styles.editor],
    [styles.accepted, variant === 'accepted'],
    [styles.rejected, variant === 'rejected'],
  );

  const allowValuePropagation = defaultConfiguration?.editableField?.allowValuePropagation;

  const handleUpdate = useCallback(
    (id: string, value: boolean | string, fieldUpdateType: FieldUpdateType, fieldId?: string) => {
      let stringValue: string;
      if (typeof value === 'boolean') {
        stringValue = value ? 'true' : 'false';
      } else {
        stringValue = value;
      }

      if (mode === EditorMode.FieldsEditable && onFieldUpdate) {
        if (fieldId) {
          onFieldUpdate(FieldType.Text, id, stringValue, fieldUpdateType, fieldId);
        } else {
          onFieldUpdate(FieldType.Checkbox, id, stringValue, fieldUpdateType);
        }
      }
    },
    [mode, onFieldUpdate],
  );

  const editorStubRef = useRef<HTMLDivElement>();

  const { diffBulkCheckboxes, onCheckboxChanged, onCheckboxDiffReview } = useCheckBoxStub(
    editorStubRef,
    content,
    mode === EditorMode.ReadOnly,
    isFieldUpdating,
  );

  const { onEditableFieldChange, onEditableFieldDiffReview, diffBulkEditableFields } =
    useEditableFieldsStub(
      editorStubRef,
      !isNil(allowValuePropagation) ? allowValuePropagation : true,
      content,
      mode === EditorMode.ReadOnly,
      isFieldUpdating,
    );

  useEffect(() => {
    if (!isNil(onCheckboxChanged)) {
      handleUpdate(onCheckboxChanged.id, onCheckboxChanged.value, FieldUpdateType.Modify);
    }
  }, [handleUpdate, onCheckboxChanged]);

  useEffect(() => {
    if (!isNil(onCheckboxDiffReview)) {
      handleUpdate(
        onCheckboxDiffReview.id,
        onCheckboxDiffReview.value,
        onCheckboxDiffReview.fieldUpdateTyp,
      );
    }
  }, [handleUpdate, onCheckboxDiffReview]);

  useEffect(() => {
    if (!isNil(onEditableFieldChange)) {
      handleUpdate(
        onEditableFieldChange.name,
        onEditableFieldChange.value,
        FieldUpdateType.Modify,
        onEditableFieldChange.id,
      );
    }
  }, [handleUpdate, onEditableFieldChange]);

  useEffect(() => {
    if (!isNil(onEditableFieldDiffReview)) {
      handleUpdate(
        onEditableFieldDiffReview.name,
        onEditableFieldDiffReview.value,
        onEditableFieldDiffReview.fieldUpdateType,
        onEditableFieldDiffReview.id,
      );
    }
  }, [handleUpdate, onEditableFieldDiffReview]);

  useEffect(() => {
    if (!isUndefined(diff)) {
      diffBulkCheckboxes(diff, mode === EditorMode.FieldsEditable);
      diffBulkEditableFields(diff, mode === EditorMode.FieldsEditable);
    }
  }, [diff, diffBulkCheckboxes, diffBulkEditableFields, mode]);

  const handleFocus = (): void => {
    if (mode === EditorMode.ContentEditable && !isNil(onActivate) && !isNil(handleInitEditor)) {
      onActivate();
      handleInitEditor();
    }
  };

  return (
    <>
      {/* <Box onClick={handleSet}>handleSet</Box> */}
      <Box sx={containerStyles} className="editor-stub" onClick={handleFocus} ref={editorStubRef}>
        <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
        <Box
          sx={
            mode === EditorMode.ContentEditable && !isSorting
              ? styles.editorActions
              : styles.editorActionsDisabled
          }
        >
          <Box
            sx={styles.insertButton}
            title={i18n(translationKeys.buttons.createNewSection)}
            onClick={onInsert}
          >
            +
          </Box>
          <Box
            sx={styles.removeButton}
            title={i18n(translationKeys.buttons.removeSection)}
            onClick={onRemove}
          >
            ×
          </Box>
        </Box>
      </Box>
    </>
  );
};
