import i18n from 'src/i18n';

import { translationKeys } from 'src/common/translations';
import { ResendSmsPayload } from 'src/v2/features/signUp/types';

import { PhoneVerificationLightForm, Values } from './components/PhoneVerificationLightForm';

type Props = {
  verifyCode: (props: Values) => void;
  resendSms: (payload: ResendSmsPayload) => void;
};

export const PhoneVerificationLight: React.FC<Props> = (props) => (
  <>
    <div className="c-modal__row c-modal--mt-24">
      <div className="c-title c-title__auth c-title--color-theme c-title--fs-64">
        {i18n(translationKeys.forms.phoneVerificationQR.title)}
      </div>
    </div>
    <div className="c-modal__row">
      <div className="c-title c-title__auth c-title--color-theme c-title--fw-400">
        {i18n(translationKeys.forms.phoneVerificationQR.phoneNumberTitle)}
      </div>
    </div>
    <PhoneVerificationLightForm {...props} />
  </>
);
