import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { BillingPeriod, SubscriptionPlan, PlanAndPeriod } from 'src/models/billing';
import { normalizePrice } from 'src/v2/features/billing';

export const getOptions = () => [
  {
    label: i18n(translationKeys.plans.basic),
    value: PlanAndPeriod.BasicFree,
    plan: SubscriptionPlan.Basic,
    period: BillingPeriod.None,
    forOrgs: false,
  },
  {
    label: i18n(translationKeys.plans.businessMonthly, { price: `$${normalizePrice(4000)}` }),
    value: PlanAndPeriod.BusinessMonthly,
    plan: SubscriptionPlan.Business,
    period: BillingPeriod.Monthly,
    forOrgs: true,
  },
  {
    label: i18n(translationKeys.plans.businessAnnually, { price: `$${normalizePrice(3000 * 12)}` }),
    value: PlanAndPeriod.BusinessAnnually,
    plan: SubscriptionPlan.Business,
    period: BillingPeriod.Annually,
    forOrgs: true,
  },
  {
    label: i18n(translationKeys.plans.realEstateMonthly, { price: `$${normalizePrice(5000)}` }),
    value: PlanAndPeriod.RealEstateMonthly,
    plan: SubscriptionPlan.RealEstate,
    period: BillingPeriod.Monthly,
    forOrgs: true,
  },
  {
    label: i18n(translationKeys.plans.realEstateAnnually, {
      price: `$${normalizePrice(3750 * 12)}`,
    }),
    value: PlanAndPeriod.RealEstateAnnually,
    plan: SubscriptionPlan.RealEstate,
    period: BillingPeriod.Annually,
    forOrgs: true,
  },
];

export const convertPlanAndPeriod = (
  data: PlanAndPeriod,
): [SubscriptionPlan, BillingPeriod] | undefined => {
  if (data === PlanAndPeriod.PremiumMonthly)
    return [SubscriptionPlan.Premium, BillingPeriod.Monthly];

  if (data === PlanAndPeriod.PremiumAnnually)
    return [SubscriptionPlan.Premium, BillingPeriod.Annually];

  if (data === PlanAndPeriod.StandardMonthly)
    return [SubscriptionPlan.Standard, BillingPeriod.Monthly];

  if (data === PlanAndPeriod.StandardAnnually)
    return [SubscriptionPlan.Standard, BillingPeriod.Annually];

  if (data === PlanAndPeriod.TeamsMonthly) return [SubscriptionPlan.Teams, BillingPeriod.Monthly];

  if (data === PlanAndPeriod.TeamsAnnually) return [SubscriptionPlan.Teams, BillingPeriod.Annually];

  if (data === PlanAndPeriod.BusinessMonthly)
    return [SubscriptionPlan.Business, BillingPeriod.Monthly];

  if (data === PlanAndPeriod.BusinessAnnually)
    return [SubscriptionPlan.Business, BillingPeriod.Annually];

  if (data === PlanAndPeriod.RealEstateMonthly)
    return [SubscriptionPlan.RealEstate, BillingPeriod.Monthly];

  if (data === PlanAndPeriod.RealEstateAnnually)
    return [SubscriptionPlan.RealEstate, BillingPeriod.Annually];

  if (data === PlanAndPeriod.BasicFree) return [SubscriptionPlan.Basic, BillingPeriod.None];

  return undefined;
};

export const personalDefaultPlan = SubscriptionPlan.Standard;
export const orgDefaultPlan = SubscriptionPlan.Business;
export const defaultBillingPeriod = BillingPeriod.Monthly;
