import ReactPhone from 'react-phone-input-2';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
export type ValuesPhone = {
  phone: string;
  onChange: (phone: string) => void;
  isTitled?: boolean;
};

export const EditPhone: React.FC<ValuesPhone> = ({ phone, onChange, isTitled = true }) => (
  <>
    {isTitled && (
      <div className="c-verification__title">
        {i18n(translationKeys.forms.phoneVerification.phoneNumberTitle)}:
      </div>
    )}
    <div className="c-form__row">
      {' '}
      <div className="c-form__column">
        <div className="c-input__label c-input--country">
          <ReactPhone
            value={phone}
            country={'us'}
            onChange={onChange}
            dropdownClass="c-country__dropdown"
          />
        </div>
      </div>
    </div>
  </>
);
