import React, { FC } from 'react';

import { Navigator, isRootFolder } from 'src/v2/features/quantumNavigator';
import { isTemplatesFolder } from 'src/v2/features/quantumNavigator/utils';

import { ListItem } from 'src/models/list';

const shouldExpandFolderView = (model: ListItem) => isRootFolder(model) || isTemplatesFolder(model);

export const TemplateQuantumNavigator: FC = () => (
  <Navigator checkDefaultOpenState={shouldExpandFolderView} />
);
