import { FC, useCallback } from 'react';

import { Box } from '@theme-ui/components';

import { shouldClose } from './ClickOutsideWrapper';

const style = {
  closeWrapper: {
    '@media (max-width: 600px)': {
      position: 'absolute',
      zIndex: 9999,
      marginLeft: '-32px',
    },
  },
};

export const ShouldCloseHandler: FC = ({ children }) => {
  const handleClick = useCallback(() => {
    shouldClose.value = false;
  }, []);

  return (
    <Box className="close-wrapper" sx={style.closeWrapper} onClick={handleClick}>
      {children}
    </Box>
  );
};
