import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TemplatePayload } from 'src/models/template';
import { ParticipantsSection } from 'src/v2/features/template/TemplateSidebar/ParticipantsSection';

import { updateTemplate } from '../../reducer';
import { getIsLoading as getIsUpdating } from '../../selectors';
import { TemplateMetadataEdit } from '../../components/TemplateMetadata/TemplateMetadataEdit';
import { Publish } from '../components/Publish';
import { useGetTemplateById } from '../../hooks';
import { getOwnerName } from '../../utils';

interface Props {
  id: string;
}

export const Edit: React.FC<Props> = ({ id }) => {
  const dispatch = useDispatch();

  const isUpdating: boolean = useSelector(getIsUpdating);
  const template = useGetTemplateById(id);

  const onSubmit = useCallback(
    (data: TemplatePayload) => {
      dispatch(
        updateTemplate({
          ...data,
          id,
        }),
      );
    },
    [dispatch, id],
  );

  return template ? (
    <>
      <TemplateMetadataEdit
        isLoading={isUpdating}
        onSubmit={onSubmit}
        id={template.entityId}
        title={template.title}
        description={template.description}
        createdAt={template.createdAt}
        paperType={template.paperType}
        type={template.type}
        subType={template.subType}
        industry={template.industry}
        province={template.province}
        city={template.city}
        country={template.country}
        owner={getOwnerName(template)}
      />
      <Publish templateId={template.entityId} />
      <ParticipantsSection entityId={template.templateId} />
    </>
  ) : null;
};
