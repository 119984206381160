import { FC, ChangeEvent, useCallback, useContext, useMemo } from 'react';
import { isNil } from 'lodash';

import { Input } from 'src/v2/components/Form/Input';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { SignaturePreviewContext } from 'src/v2/features/signature/contexts/SignaturePreviewContext';
import { SignatureSettingsContext } from 'src/v2/features/signature/contexts/SignatureSettingsContext';

import { SignaturePreviewProps } from '../../types';

const useEventValueCallbackFactory = (fn: (value: string) => void) => {
  return useCallback((event: ChangeEvent<HTMLInputElement>) => fn(event.target.value), [fn]);
};

const normalizeNullString = (input: string | null | undefined): string =>
  isNil(input) ? '' : input;

export const SignaturePreview: FC<SignaturePreviewProps> = ({
  component: SignatureView,
  ...viewProps
}) => {
  const {
    avatarUrl,
    signaturePreviewUrl,
    uploadedImageUrl,
    meta: {
      hash,
      signedBy,
      companyName,
      companyTitle,
      setSignedBy,
      setCompanyName,
      setCompanyTitle,
    },
  } = useContext(SignaturePreviewContext);
  const { includeCompanyMeta } = useContext(SignatureSettingsContext);

  const normalizedSignedBy = normalizeNullString(signedBy);
  const normalizedCompanyName = useMemo(() => normalizeNullString(companyName), [companyName]);
  const normalizedCompanyTitle = useMemo(() => normalizeNullString(companyTitle), [companyTitle]);
  const onCompanyNameChange = useEventValueCallbackFactory(setCompanyName);
  const onSignatureNameChange = useEventValueCallbackFactory(setSignedBy);
  const onCompanyTitleChange = useEventValueCallbackFactory(setCompanyTitle);
  const previewCompanyName = useMemo(
    () => (includeCompanyMeta ? normalizedCompanyName : ''),
    [normalizedCompanyName, includeCompanyMeta],
  );
  const previewCompanyTitle = useMemo(
    () => (includeCompanyMeta ? normalizedCompanyTitle : ''),
    [normalizedCompanyTitle, includeCompanyMeta],
  );

  return (
    <div className="c-signature__preview">
      <div className="l-row">
        <div className="c-title c-title--uppercase c-title--color-theme h-uppercase">
          {i18n(translationKeys.forms.signature.signatureDetails)}
        </div>
      </div>
      <div className="l-row" data-id="profile-container-signaturePreview">
        <div className="l-row">
          <Input
            name="company"
            onChange={onCompanyNameChange}
            placeholder={i18n(translationKeys.forms.signature.company)}
            value={normalizedCompanyName}
          />
        </div>
        <div className="l-row l-row--mt-16">
          <Input
            name="name"
            onChange={onSignatureNameChange}
            placeholder={i18n(translationKeys.forms.signature.name)}
            value={normalizedSignedBy}
          />
        </div>
        <div className="l-row l-row--mt-16">
          <Input
            name="title"
            onChange={onCompanyTitleChange}
            placeholder={i18n(translationKeys.forms.signature.title)}
            value={normalizedCompanyTitle}
          />
        </div>
      </div>
      <div className="l-row">
        <div className="c-title c-title--uppercase c-title--color-theme h-uppercase">
          {i18n(translationKeys.forms.signature.preview)}
        </div>
      </div>
      <div className="l-row">
        <SignatureView
          avatarUrl={avatarUrl}
          signatureHash={hash}
          signatureUrl={signaturePreviewUrl || uploadedImageUrl}
          signatureName={signedBy}
          companyName={previewCompanyName}
          companyTitle={previewCompanyTitle}
          {...viewProps}
        />
      </div>
    </div>
  );
};
