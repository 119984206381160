import React from 'react';

interface Props {
  title: string;
  description?: string;
}

export const FormHeader: React.FC<Props> = ({ title, description }) => (
  <div className="c-setup__top">
    <div className="l-row">
      <div className="c-title c-title--color-theme c-title--fs-24">{title}</div>
    </div>
    {description && (
      <div className="l-row l-row--mt-16">
        <div className="c-title c-title--color-theme c-title--lh-16 c-title--fw-400">
          {description}
        </div>
      </div>
    )}
  </div>
);
