import React, { FC, MouseEvent } from 'react';
import cn from 'classnames';

import { MenuDocuments } from 'src/v2/icons/MenuDocuments';
import { MenuContracts } from 'src/v2/icons/MenuContracts';
import { useToggle } from 'src/common/hooks/useToggle';
import i18n from 'src/i18n';
import { translationKeys } from '../../../../common/translations';

interface Props {
  onCreateDocument: (e: MouseEvent) => void;
  onCreateContract: (e: MouseEvent) => void;
}

export const CreateNew: FC<Props> = ({ onCreateContract, onCreateDocument }) => {
  const [isActive, toggle] = useToggle();

  return (
    <div
      className={cn(
        'c-card__item c-card__create c-card__create--document',
        isActive && 'c-card__create--active',
      )}
      data-type="card-create"
      onClick={toggle}
    >
      <div className="c-card__create--title">{i18n(translationKeys.document.createNew)}</div>
      <div className="c-card__choice">
        <div className="c-card__choice--item" onClick={onCreateDocument}>
          <div className="c-card__choice--icon">
            <MenuDocuments />
          </div>
          <div className="c-card__choice--title">Document</div>
        </div>
        <div className="c-card__choice--item" onClick={onCreateContract}>
          <div className="c-card__choice--icon">
            <MenuContracts />
          </div>
          <div className="c-card__choice--title">Contract</div>
        </div>
      </div>
    </div>
  );
};
