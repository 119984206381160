import {
  SubscriptionPlan,
  BillingPeriod,
  PlanAndPeriod,
  SubscribePayload,
} from 'src/models/billing';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

export interface BillingInfoFormValues {
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}
export interface BillingDetailsFormValues extends BillingInfoFormValues {
  planAndPeriod: PlanAndPeriod;
  numberOfSeats?: string;
}

export interface CheckoutFormValues {
  agree?: boolean;
  numberOfSeats?: number;
  submitAction?: ActionCreatorWithPayload<SubscribePayload>;
}

export enum PaymentStatus {
  Canceled = 'canceled', // log
  Processing = 'processing', // log
  RequiresAction = 'requires_action', // otp code
  RequiresCapture = 'requires_capture', // log
  RequiresConfirmation = 'requires_confirmation', // log
  RequiresPaymentMethod = 'requires_payment_method', // invalid_card
  Succeeded = 'succeeded',
}

export interface PaymentIntent {
  status: PaymentStatus;
  client_secret: string;
}

// TODO: Remove 'V2' when all work for billing responses will be done
export interface PaymentIntentV2 {
  status: PaymentStatus;
  clientSecret: string;
}

export interface PaymentRequest {
  plan: SubscriptionPlan;
  period: BillingPeriod;
  latestInvoiceId: string | null;
  paymentMethodId?: string;
  numberOfSeats?: number;
}

export interface SubscriptionResponse {
  billingPlan: SubscriptionPlan;
  billingPeriod: BillingPeriod;
  invoice: {
    paymentIntent: PaymentIntentV2;
  };
}
export interface PaymentResponse {
  data: {
    latest_invoice: {
      payment_intent: PaymentIntent;
    };
  };
}

export interface InvoiceResponse {
  data: {
    payment_intent: {
      status: PaymentStatus;
      client_secret: string;
    };
  };
}

export interface StripeConfirmResponse {
  paymentIntent: {
    status: PaymentStatus;
  };
}

export enum PaymentError {
  Card,
}

export enum WizardStep {
  BillingInformation,
  CardDetails,
}

export interface CommonOrderSummaryProps {
  currentPlan: SubscriptionPlan | null;
  currentPeriod: BillingPeriod | null;
  planPrice: number;
  tax: number;
  totalPrice: number;
  pricePerSeat: number;
  isLoading: boolean;
  showTaxes?: boolean;
  numberOfSeats: number;
}
