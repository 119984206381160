import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getNotifications,
  bellLoadMore,
  getNotificationNewCount,
  getNotificationTotalCount,
  bellMarkEventsViewed,
} from './notificationsStore';

import { Notifications } from './Notifications';

export const OldNotifications: React.FC<{}> = () => {
  const newNotificationsCount = useSelector(getNotificationNewCount);
  const totalNotificationsCount = useSelector(getNotificationTotalCount);
  const list = useSelector(getNotifications);

  const dispatch = useDispatch();

  useEffect((): void => {
    if (newNotificationsCount) {
      dispatch(bellMarkEventsViewed());
    }
  }, [dispatch, newNotificationsCount]);

  const loadMoreNotifications = useCallback(() => {
    dispatch(bellLoadMore());
  }, [dispatch]);

  return (
    <Notifications
      list={list}
      totalCount={totalNotificationsCount}
      onLoadMore={loadMoreNotifications}
      v2
    />
  );
};
