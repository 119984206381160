import { FC } from 'react';
import { keys, flow, get, startCase } from 'lodash';
import { reduxForm, InjectedFormProps } from 'redux-form';

import { DocumentType } from 'src/models/document';
import { withInitialValues } from 'src/utils/hocs';
import { EntityType, PaperState } from 'src/models/paper';
import { ContractType } from 'src/models/contract';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

export interface EntitySubTypes {
  key: string;
  value: string;
}

function getEntitySubTypes(type: EntityType): EntitySubTypes[] {
  if (type === EntityType.document)
    return keys(DocumentType).map((key) => ({
      key,
      value: i18n(get(translationKeys, `forms.document.type.${key}`)),
    }));
  if (type === EntityType.contract)
    return keys(ContractType).map((key) => ({
      key,
      value: i18n(get(translationKeys, `forms.contract.type.${key}`)),
    }));
  console.error('getEntitySubTypes is called with unknown argument value:', type);
  return [];
}

export function getDocumentStateLabel(state: PaperState): string {
  if (state === PaperState.draft) return 'Draft';
  if (state === PaperState.inProgress) return 'In Progress';
  if (state === PaperState.completed) return 'Completed';
  console.error('getDocumentStateLabel is called with unknown argument value:', state);
  return '';
}

export const getSubTypeOptions = (type: EntityType): object[] => [
  { label: i18n(translationKeys.forms.item.type), value: '', disabled: true },
  ...getEntitySubTypes(type).map((item) => ({ label: item.value, value: startCase(item.key) })),
];

const commonFields = ['title', 'description', 'type', 'subType', 'subSubType', 'isTemplate'];

export function connectForm<Props, Payload>(
  Component: FC<Props & InjectedFormProps<Payload, Props>>,
  fields: string[],
  extraProps: object = {},
): FC<Props> {
  const srcMapper = (props: Props) => ({ ...props, isTemplate: false, ...extraProps });
  return flow([
    reduxForm<Payload, Props>({ form: 'editMetadata', enableReinitialize: true }),
    withInitialValues<Payload, Props>([...commonFields, ...fields], srcMapper),
  ])(Component);
}
