import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isUndefined, isNull } from 'lodash';

import { useGetCurrentFolderId } from 'src/v2/features/quantumNavigator/hooks';
import { folderSubscribe, folderUnsubscribe } from 'src/v2/features/folderObjects/actions';
import { ProjectDetailsCard } from 'src/v2/features/project/components/ProjectDetailsCard';
import { useSubscribeForEntityCreateEffct } from 'src/v2/features/sharedEntity/hooks';
import { CreateNew } from 'src/v2/features/project/components/CreateNew';
import {
  useCreateCreateContractMetadataView,
  useCreateCreateDocumentMetadataView,
} from 'src/v2/features/project/ProjectSidebar/nav-hooks';
import { useFetchProjectByIdEffct } from 'src/v2/features/project/hooks';
import { useParams } from 'react-router';
import { useSubscribeFolderContentChangeEffct } from 'src/v2/features/objectsList/utils';
import { useSubscribeToParticipantEventsAndDoCallbackEffct } from 'src/v2/features/sharedEntity/EntitySidebar/hooks/event-hooks';
import { useNavigateToFn } from 'src/v2/features/sidebarController/helpers-hooks';
import { useCanCreateEntityInProject } from 'src/v2/features/project/permissions/projectPermissionsHooks';

import { ProjectLayout } from './ProjectLayout';
import { SidebarNodeContextProvider } from './providers/SidebarNodeContextProvider';
import { DynamicSettingsContextProviderForList } from './providers/DynamicSettingsContextProviderForList';
import {
  getProjectByIdFn,
  getRootProjectsFolderId,
  getIsLoading,
  getError,
} from './store/projectSelectors';

export const ProjectDetailsPage: FC = () => {
  const rootFolderId = useSelector(getRootProjectsFolderId);
  const isLoading = useSelector(getIsLoading);
  const error = useSelector(getError);
  const getProjectById = useSelector(getProjectByIdFn);
  const isCanCreateEntityInProject = useCanCreateEntityInProject();

  const folderId = useGetCurrentFolderId() || rootFolderId;
  const handleCreateDocument = useCreateCreateDocumentMetadataView();
  const handleCreateContract = useCreateCreateContractMetadataView();
  const { id: projectId } = useParams<{ id: string }>();
  const project = getProjectById(projectId as string);
  const list = isNull(project) ? [] : project.papers;
  useFetchProjectByIdEffct(projectId);

  const dispatch = useDispatch();

  const navigateToDefault = useNavigateToFn({ params: { v: 'default' } });
  useSubscribeToParticipantEventsAndDoCallbackEffct(navigateToDefault);

  useEffect(() => {
    if (!isUndefined(folderId)) dispatch(folderSubscribe(folderId));

    return () => {
      dispatch(folderUnsubscribe());
    };
  }, [folderId, dispatch]);

  useSubscribeFolderContentChangeEffct(projectId);
  useSubscribeForEntityCreateEffct();

  return isUndefined(projectId) ? null : (
    <SidebarNodeContextProvider>
      <DynamicSettingsContextProviderForList>
        <ProjectLayout isLoading={isLoading} error={error}>
          <div className="c-card">
            {isCanCreateEntityInProject && (
              <CreateNew
                onCreateContract={handleCreateContract}
                onCreateDocument={handleCreateDocument}
              />
            )}
            {list.map((cardPaper) => (
              <ProjectDetailsCard
                key={`project-card-${cardPaper.id}`}
                cardPaper={cardPaper}
                itemType={cardPaper.type}
                projectId={projectId}
              />
            ))}
          </div>
        </ProjectLayout>
      </DynamicSettingsContextProviderForList>
    </SidebarNodeContextProvider>
  );
};
