import { PropsWithChildren, useCallback, useEffect } from 'react';
import { Field, reduxForm, InjectedFormProps, formValueSelector } from 'redux-form';
import { email, required } from 'redux-form-validators';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { Input } from 'src/v2/features/reduxForm';
import { InviteUserData } from 'src/api/documents';
import { Divider } from 'src/v2/components/Divider';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/app/types';
import { updateParticipant } from 'src/v2/features/documentParticipants';
import { useAuthAndFillOrganization } from 'src/v2/features/profile/hooks';
import { getIsUpdating } from 'src/v2/features/documentParticipants/updateParticipantStore';
import { ParticipantStatus } from 'src/v2/entities/participants';

import { Button, Type } from '../button';
import { UserFieldset } from './UserFieldset';
import { getEntityById } from '../sharedEntity/selectors';
import { InviteParticipantActionPayload } from 'src/v2/boundary/actionPayload/inviteParticipant';
import { validateEmail } from 'src/utils/validate';

type FormProps = InviteUserData;

const FORM_ID = 'editUser';
export const formSelector = formValueSelector(FORM_ID);

export interface Props {
  onClose: () => void;
  documentId: string;
  userId: string;
  predefinedConditionRequired: boolean;
}

const ParticipantFormComponent = (
  props: PropsWithChildren<Props & InjectedFormProps<FormProps, Props>>,
) => {
  const {
    pristine,
    change,
    onClose,
    handleSubmit,
    documentId,
    userId,
    submitting,
    children,
    predefinedConditionRequired,
  } = props;

  const dispatch = useDispatch();
  const isUpdating = useSelector(getIsUpdating);
  const conditionRequired: boolean = useSelector((state: RootState) =>
    formSelector(state, 'conditionRequired'),
  );
  const isParticipantActive: boolean = useSelector(
    (state: RootState) => formSelector(state, 'lifeCycleState') === ParticipantStatus.active,
  );

  const getDocumentById = useSelector(getEntityById);
  const document = getDocumentById(documentId);

  useEffect(() => {
    if (conditionRequired !== predefinedConditionRequired)
      change('conditionRequired', predefinedConditionRequired);
  }, [change, conditionRequired, predefinedConditionRequired]);

  useAuthAndFillOrganization();

  const submitForm = useCallback(
    (data: InviteParticipantActionPayload['data']) => {
      dispatch(
        updateParticipant({
          documentId,
          data,
          userId,
          documentEntity: document?.entity,
        }),
      );
    },
    [dispatch, documentId, userId, document],
  );

  return (
    <div className="c-fieldset" data-id="documentDetails-form-editParticipant">
      <div className="c-title c-title--uppercase c-title--lh-1 c-title--has-image">
        {i18n(translationKeys.forms.editUser.title)}
      </div>
      <Field
        component={Input}
        name="email"
        type="email"
        placeholder={i18n(translationKeys.forms.editUser.email)}
        validate={[email(), validateEmail]}
        disabled={isParticipantActive}
        mb8
      />
      <UserFieldset isDisabled={isParticipantActive} />
      <Field
        component={Input}
        name="funcRole"
        placeholder={i18n(translationKeys.forms.editUser.funcRole)}
        mb8
        validate={[required()]}
      />

      {children && <Divider />}
      {children}

      <Divider />

      <Button
        className="h-uppercase"
        type="submit"
        disabled={pristine || submitting}
        isLoading={isUpdating}
        onClick={handleSubmit(submitForm)}
      >
        {i18n(translationKeys.buttons.update)}
      </Button>
      <Button
        className="h-uppercase"
        actionType={Type.Danger}
        disabled={isUpdating}
        onClick={onClose}
      >
        {i18n(translationKeys.buttons.cancel)}
      </Button>
    </div>
  );
};

export const EditParticipantForm = reduxForm<FormProps, Props>({
  form: FORM_ID,
  enableReinitialize: true,
})(ParticipantFormComponent);
