import { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { SystemRole } from 'src/models/user';
import { useUserCallbackFactory } from 'src/v2/features/profile/profileSidebar';
import { getAvatarUrl } from 'src/v2/features/profile/index';
import { canEditOrganizationUser, canRemoveOrganizationUser } from 'src/utils/permissions';
import { getCurrentOrganizationUserSystemRole } from 'src/shared/organization';
import { UserState } from 'src/v2/features/organization/types';
import { decodeContentToDomainUrl } from 'src/utils/url';

export const getSystemRoles = () => {
  return {
    [SystemRole.User]: i18n(translationKeys.systemRoles.user),
    [SystemRole.Manager]: i18n(translationKeys.systemRoles.manager),
    [SystemRole.Owner]: i18n(translationKeys.systemRoles.owner),
    [SystemRole.Admin]: i18n(translationKeys.systemRoles.admin),
  };
};

export const User = (user: UserState): ReactElement => {
  const fullName = `${user.firstName} ${user.lastName}`;
  const [editUserCallback, deleteUserCallback] = useUserCallbackFactory(user.id);
  const userSystemRole = useSelector(getCurrentOrganizationUserSystemRole);
  const finalAvatarSrc: string = decodeContentToDomainUrl(getAvatarUrl(user.avatar));
  const isCanEditUser = canEditOrganizationUser(userSystemRole, user.permission);
  const isCanRemoveUser = canRemoveOrganizationUser(userSystemRole, user.permission);
  const systemRoles = getSystemRoles();

  return (
    <>
      <tr className="c-manage__tr" data-type="userInfo-container">
        <td className="c-manage__td">
          <div className="c-manage__photo">
            <img alt={`${fullName} avatar`} src={finalAvatarSrc} />
          </div>
        </td>
        <td className="c-manage__td" data-type="fullName-text">
          {fullName}
        </td>
        <td className="c-manage__td" data-type="userRole-text">
          {user.role}
        </td>
        <td className="c-manage__td" data-type="permission-text">
          {systemRoles[user.permission] || user.permission}
        </td>
        <td className="c-manage__td" data-type="email-text">
          {user.email}
        </td>
        <td className="c-manage__td" data-type="phone-text">
          {user.phone}
        </td>
        <td className="c-manage__td" data-type="verifiedUser-text">
          {user.verified ? (
            <div className="c-manage__joined">
              {i18n(translationKeys.forms.organizationUser.joinedTheTeam)}
            </div>
          ) : (
            <div className="c-manage__invite">
              {i18n(translationKeys.forms.organizationUser.inviteSent)}
            </div>
          )}
        </td>
        <td className="c-manage__td">
          <div className="c-manage__action">
            {isCanEditUser && (
              <div className="c-manage__edit" onClick={editUserCallback}>
                {i18n(translationKeys.forms.organizationUser.edit)}
              </div>
            )}
            {isCanEditUser && isCanRemoveUser && <div className="c-manage__delimiter"></div>}
            {isCanRemoveUser && (
              <div className="c-manage__remove" onClick={deleteUserCallback}>
                {i18n(translationKeys.forms.organizationUser.remove)}
              </div>
            )}
          </div>
        </td>
      </tr>
    </>
  );
};
