import React, { ReactElement, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';
import { SignUpStepComponent, useSignUpProps } from './hooks';
import { FormProps as ProfileFormProps, ProfileDataForm } from '../ProfileDataForm';
import { ProfileFormPayload } from '../types';
import { saveProfileAction } from '../store';
import { profileFormName } from '../constants';

export const useProfileDataComponent = (): SignUpStepComponent => {
  const { accountType, maxSteps, step } = useSignUpProps();
  const dispatch = useDispatch();

  const emitProfileFormSubmission = useCallback(() => {
    dispatch(submit(profileFormName));
  }, [dispatch]);

  const submitProfileData = useCallback(
    (values: ProfileFormProps) => {
      const payload: ProfileFormPayload = {
        profile: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phoneNumber: values.phoneNumber,
          jobTitle: values.jobTitle,
          language: values.language,
          address: {
            addressLine: values.address,
            country: values.country,
            state: values.state,
            city: values.city,
            zipCode: values.zipCode,
          },
        },
        accountType,
      };
      dispatch(saveProfileAction(payload));
    },
    [dispatch, accountType],
  );

  const props = {
    onSubmit: submitProfileData,
    accountType,
  };

  return [
    (): ReactElement => <ProfileDataForm {...props} />,
    { step, maxSteps, onNext: emitProfileFormSubmission },
  ];
};
