import { put, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { emitLogout } from 'src/common/systemEventBus';
import { cancelRootSaga } from 'src/common/commonActions';
import * as authActions from '../store/authStore';
import { removeJWTCookie } from './cookiesConfiguration';

function* clearStorageAndEmitEvent(): SagaIterator {
  localStorage.removeItem('persist:auth');
  removeJWTCookie();
  yield call(emitLogout);
}

export function* logoutSaga(): SagaIterator {
  try {
    yield put(authActions.startLoading());
  } catch (err) {
    yield put(authActions.emitError(err.toString()));
  }

  yield put(authActions.logout());
  yield put(authActions.stopLoading());
  yield call(clearStorageAndEmitEvent);
  yield put(cancelRootSaga());
}

export function* forceLogoutSaga(): SagaIterator {
  try {
    yield put(authActions.startLoading());
    yield put(authActions.logout());
    yield put(authActions.stopLoading());
  } catch (err) {
    yield put(authActions.emitError(err.toString()));
  }

  yield call(clearStorageAndEmitEvent);
  yield put(cancelRootSaga());
}
