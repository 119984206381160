import React, { PropsWithChildren } from 'react';
import cn from 'classnames';
import { isUndefined } from 'lodash';

import { useToggle } from 'src/common/hooks/useToggle';

interface Props {
  isExpanded: boolean;
}

export const NavigatorWrapper = React.forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({ children, isExpanded }, ref) => {
    const [isOpen, toggle] = useToggle();

    if (isUndefined(children)) return null;

    return (
      <>
        <div
          className={cn(
            'l-section',
            isOpen && 'l-section--roll',
            isExpanded && 'l-section--active',
          )}
          ref={ref}
        >
          <div className="l-section__toggle" onClick={toggle} />
          {children}
        </div>
      </>
    );
  },
);

NavigatorWrapper.displayName = 'NavigatorWrapper';
