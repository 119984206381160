import React, { FC } from 'react';

import { LeftMenuItemProps } from './types';

export const LeftMenuItem: FC<LeftMenuItemProps> = ({ icon, title, onClick }) => (
  <div className="c-library__item" onClick={onClick}>
    <span className="c-library__link">
      <div className="c-library__icon">{icon}</div>
      <div className="c-library__info">
        <div className="c-library__title">{title}</div>
      </div>
    </span>
  </div>
);
