import React, { FC } from 'react';

import { Message } from 'src/v2/icons/Message';
import { decodeContentToDomainUrl } from 'src/utils/url';

export interface Props {
  avatar: string;
  unreadCount?: number;
  name: string;
  roles: string; // document role and functional role
  canStartChat: boolean;
  onClick?: () => void;
}

const getClickablePartProps = (onClick, canStartChat) => ({
  onClick,
  title: canStartChat ? 'Click on user to start messaging' : undefined,
});

export const ParticipantListItem: FC<Props> = ({
  onClick,
  avatar,
  name,
  roles,
  unreadCount,
  canStartChat,
}) => (
  <>
    <div className="c-user__photo" {...getClickablePartProps(onClick, canStartChat)}>
      {Boolean(unreadCount) && <div className="c-user__count">{unreadCount}</div>}
      {canStartChat && (
        <div className="c-user__message">
          <Message />
        </div>
      )}
      <img src={decodeContentToDomainUrl(avatar)} alt="User avatar" />
    </div>
    <div className="c-user__info" {...getClickablePartProps(onClick, canStartChat)}>
      <div className="c-user__name">{name}</div>
      <div className="c-user__post">({roles})</div>
    </div>
  </>
);
