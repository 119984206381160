import { FC } from 'react';
import { isUndefined } from 'lodash';

import { SolidPlus } from 'src/v2/icons/SolidPlus';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

interface Props {
  gridTitle?: string;
  onAdd?: () => void;
}

export const CardsGrid: FC<Props> = ({
  children,
  gridTitle = i18n(translationKeys.card.document.title),
  onAdd,
}) => {
  return (
    <div className="c-group">
      <div className="c-group__header">
        <div className="c-group__title">{gridTitle}</div>
      </div>
      <div className="c-group__main">
        <div className="c-card">
          {!isUndefined(onAdd) && (
            <div className="c-card__item c-card__add" data-type="card-create" onClick={onAdd}>
              <div className="c-card__plus">
                <SolidPlus />
              </div>
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};
