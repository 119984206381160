import React, { FC, useContext } from 'react';

import { useTranslation } from 'src/common/hooks/useTranslation';
import { translationKeys } from 'src/common/translations';
import { Link } from 'src/v2/components/Link';
import { StaticSettings } from 'src/common/types';

import { StaticSettingsContext } from 'src/common/contexts/StaticSettingsContext';
import { HeaderItem } from './HeaderItem';
import { NavProps } from '../../types';

export const AnonymousHeaderNav: FC<NavProps> = ({ onHamburgerClick }) => {
  const i18n = useTranslation();
  const { helpUrl, onLogoutClick } = useContext<StaticSettings>(StaticSettingsContext);

  return (
    <HeaderItem>
      <div className="c-burger c-burger--header" onClick={onHamburgerClick}>
        <div className="c-burger__line" />
      </div>
      <span />
      <nav className="c-nav">
        <Link className="c-nav__link" to={helpUrl} target="_blank" dataType="help" external>
          {i18n(translationKeys.header.help)}
        </Link>
        <button className="c-nav__logout" onClick={onLogoutClick} data-type="logout-submit">
          {i18n(translationKeys.header.logOut)}
        </button>
      </nav>
    </HeaderItem>
  );
};
