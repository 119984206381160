import React, { FC } from 'react';

import { HeaderItem } from './HeaderItem';

export const BaseActions: FC = ({ children }) => {
  return (
    <>
      <HeaderItem>
        <div className="c-action c-action--quantum">{children}</div>
      </HeaderItem>
    </>
  );
};
