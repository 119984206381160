import React, { FC } from 'react';

import { SignatureAggregatedProvider } from 'src/v2/features/signature';
import { HankoSignatureSettingsContextProvider } from './HankoSignatureSettingsContextProvider';
import { HankoFileSignatureContextProvider } from './HankoFileSignatureContextProvider';
import { HankoActionContextProvider } from './HankoActionContextProvider';
import { HankoSignatureActionContextProvider } from './HankoSignatureActionContextProvider';

export const HankoSignatureAggregatedProvider: FC = ({ children }) => {
  return (
    <SignatureAggregatedProvider>
      <HankoSignatureSettingsContextProvider>
        <HankoFileSignatureContextProvider>
          <HankoActionContextProvider>
            <HankoSignatureActionContextProvider>{children}</HankoSignatureActionContextProvider>
          </HankoActionContextProvider>
        </HankoFileSignatureContextProvider>
      </HankoSignatureSettingsContextProvider>
    </SignatureAggregatedProvider>
  );
};
