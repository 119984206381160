import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isNull } from 'lodash';

import { ContractRole, ContractType } from 'src/models/contract';
import { RootState } from 'src/app/types';
import { getIsUpdating } from 'src/v2/features/documentParticipants/updateParticipantStore';
import { useGetEntityById } from 'src/v2/features/sharedEntity';
import {
  EditParticipantForm,
  formSelector,
} from 'src/v2/features/entityParticipants/EditParticipantForm';
import { ContractPermissionsFieldset } from 'src/v2/features/contract/participants/ContractPermissionsFieldset';
import {
  conditionRequiredVisible,
  getEditRoleOptions,
  getRoleOption,
  getRequiredCondition,
} from 'src/v2/features/contract/participants/utils';
import { SidebarParticipantEntity } from 'src/v2/entities/participants';
import { canEditUserPermissions } from 'src/v2/features/contract/permissions/manageParticipantsPermissions/manageParticipantsPermissions';

export interface Props {
  onClose: () => void;
  contractId: string;
  participant: SidebarParticipantEntity<ContractRole>;
}

export const ContractEditParticipantForm: FC<Props> = (props) => {
  const { contractId, participant, onClose } = props;
  const { id: participantId, roleId: participantRole, affiliationType } = participant;
  const entity = useGetEntityById<ContractType, ContractRole>(contractId);
  const myRole = !isNull(entity) ? entity.myRole : null;
  const myParticipantId = !isNull(entity) ? entity.myParticipant.participantId : '';
  const isUpdating = useSelector(getIsUpdating);
  const roleId: ContractRole = useSelector((state: RootState) => formSelector(state, 'roleId'));
  const conditionRequired: boolean = useSelector((state: RootState) =>
    formSelector(state, 'conditionRequired'),
  );
  const isConditionRequiredVisible = conditionRequiredVisible(roleId);
  const predefinedConditionRequired = getRequiredCondition(roleId, conditionRequired);
  const canEditParticipantRole = useMemo(
    () =>
      !isNull(participantRole) &&
      !isNull(myRole) &&
      canEditUserPermissions(
        { id: myParticipantId, role: myRole },
        { id: participantId, role: participantRole },
      ),
    [myParticipantId, myRole, participantId, participantRole],
  );
  const roleOptions = useMemo(() => {
    if (!canEditParticipantRole && !isNull(participantRole)) {
      const participantRoleOption = getRoleOption(participantRole);
      return !isNull(participantRoleOption) ? [participantRoleOption] : [];
    }

    return isNull(myRole)
      ? []
      : getEditRoleOptions({ id: myParticipantId, role: myRole }, participantId);
  }, [canEditParticipantRole, myParticipantId, myRole, participantId, participantRole]);

  return (
    <EditParticipantForm
      predefinedConditionRequired={predefinedConditionRequired}
      documentId={contractId}
      onClose={onClose}
      initialValues={participant}
      userId={participant.userId}
    >
      {!isNull(myRole) && (
        <ContractPermissionsFieldset
          roleOptions={roleOptions}
          isConditionRequiredVisible={isConditionRequiredVisible}
          isUpdating={isUpdating}
          affiliationType={affiliationType}
        />
      )}
    </EditParticipantForm>
  );
};
