import React from 'react';
import { Flex, Text } from '@theme-ui/components';

import { Avatar } from '../Avatar';

const style = {
  container: {
    alignItems: 'center',
    mr: '30px',
    mb: '20px',
  },
  userName: {
    fontSize: '14px',
    paddingLeft: '12px',
    pointerEvents: 'none',
  },
};

interface Props {
  avatarUrl: string;
  userName?: string;
}

export const Acknowledge: React.FC<Props> = ({ avatarUrl, userName }) => (
  <Flex sx={style.container}>
    <Avatar src={avatarUrl} size={46} />
    <Text sx={style.userName}>Acknowledged by {userName}</Text>
  </Flex>
);
