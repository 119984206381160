import React, { FC } from 'react';

interface Props {
  x: number;
  y: number;
}

export const ContextMenu: FC<Props> = ({ x, y, children }) => {
  return (
    <div className="l-body">
      <div
        className="c-edit c-edit--active"
        data-type="context-menu"
        style={{
          top: y,
          left: x,
        }}
      >
        {children}
      </div>
    </div>
  );
};
