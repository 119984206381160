import { head, isUndefined, size } from 'lodash';

import { ProjectAttributesDTO } from 'src/v2/boundary/responseDTO/project';
import { ProjectCardType, ProjectEntity, ProjectRole } from 'src/v2/entities/project';
import { ProjectObjectsCompleteModel } from 'src/v2/boundary/storageModels/project/projectObjects';
import { createErrorFactory } from 'src/common/error/createErrorFactory';
import { SidebarParticipantEntity } from 'src/v2/entities/participants';
import defaultAvatar from 'src/assets/placeholders/user-avatar.png';
import { CardPaperCompleteModel } from 'src/models/card';
import { convertCardPaperCompleteToCardPaperModel } from 'src/v2/features/objectsList/utils';
import { ObjectsContainer } from 'src/v2/features/objectsStorage/types';
import { ProjectParticipantCompleteModel } from './projectParticipant';

export type ProjectBaseModel = ProjectAttributesDTO;

export interface ProjectCompleteModel extends ProjectBaseModel {
  project_objects?: ProjectObjectsCompleteModel[];
  project_participants?: ProjectParticipantCompleteModel[];
}

export const convertProjectCompleteModelToProjectEntity = (
  projectModel: ProjectCompleteModel,
  users: ObjectsContainer,
  userId: string,
): ProjectEntity => {
  const createError = createErrorFactory('convertProjectCompleteModelToProjectEntity');
  const { title, description, id } = projectModel;

  if (isUndefined(projectModel.project_participants)) {
    throw createError(`project_participants can't be undefined`);
  }

  if (size(projectModel.project_participants) === 0) {
    throw createError(`project_participants can't be empty`);
  }
  const participants = projectModel.project_participants.reduce(
    (convertedParticipants, currentParticipant) => {
      const user = head(currentParticipant.project_participant_users);

      if (isUndefined(user)) {
        throw createError(`project_user can't be undefined or empty`);
      }

      const { id, role } = currentParticipant;
      const { user_id, first_name, last_name, avatar, email, phone } = user;
      const result: SidebarParticipantEntity<ProjectRole> = {
        id,
        userId: user_id,
        roleId: role,
        funcRole: '',
        firstName: first_name || '',
        lastName: last_name || '',
        avatar: avatar || defaultAvatar,
        email,
        phone,
      };

      convertedParticipants.push(result);

      return convertedParticipants;
    },
    [] as SidebarParticipantEntity<ProjectRole>[],
  );

  const ownerParticipant = participants.find(({ roleId }) => roleId === ProjectRole.ProjectOwner);

  if (isUndefined(ownerParticipant)) {
    throw createError(`ownerParticipant can't be undefined`);
  }

  if (isUndefined(projectModel.project_objects)) {
    throw createError(`project_objects can't be undefined`);
  }

  const objects = projectModel.project_objects;
  const cardPapersComplete = objects.reduce(
    (
      cardPapersComplete: CardPaperCompleteModel<ProjectCardType, ProjectRole>[],
      currentProjectObject,
    ) => {
      if (isUndefined(currentProjectObject.cardPaper))
        throw createError(`cardPapers array can't be undefined`);

      const cardPaper = head(currentProjectObject.cardPaper);

      if (isUndefined(cardPaper)) throw createError(`cardPaper can't be undefined`);

      cardPapersComplete.push(cardPaper);

      return cardPapersComplete;
    },
    [],
  );

  const papers = cardPapersComplete.map((cardPaperComplete) =>
    convertCardPaperCompleteToCardPaperModel<ProjectCardType, ProjectRole>(
      cardPaperComplete,
      userId,
    ),
  );

  return {
    id,
    title,
    description,
    participants,
    ownerParticipant,
    papers,
  };
};
