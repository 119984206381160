import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box } from '@theme-ui/components';
import { change, focus, blur, touch } from 'redux-form';
import { useDispatch } from 'react-redux';
import ReactPhone from 'react-phone-input-2';
import { isUndefined } from 'lodash';

import './phoneInputStyle.css';
import { Props, PHONE_MASKS } from './types';

export const PhoneNumber: React.FC<Props> = ({
  label,
  disabled = false,
  input,
  title,
  meta,
  sx,
  onFocus,
  onBlur,
  onChange,
  onCountryCodeChange,
  children,
}) => {
  const [number, setNumber] = useState('');

  const { form, valid, error, touched } = meta;
  const isError = touched && error;
  const isLabel = (!touched || valid) && label;

  const dispatch = useDispatch();
  if (input.value && number === '') {
    setNumber(input.value);
  }

  const handleChange = useCallback(
    (number: string, { countryCode }) => {
      setNumber(number);
      if (!isUndefined(onChange)) onChange(number);
      if (!isUndefined(onCountryCodeChange)) onCountryCodeChange(countryCode);
    },
    [onChange, onCountryCodeChange],
  );

  const handleFocus = useCallback(() => {
    dispatch(focus(form, input.name));
    if (!isUndefined(onFocus)) onFocus();
  }, [dispatch, onFocus, form, input.name]);

  const handleBlur = useCallback(() => {
    dispatch(blur(form, input.name, number));
    dispatch(touch(form, input.name));
    if (!isUndefined(onBlur)) onBlur();
  }, [dispatch, form, input.name, number, onBlur]);

  const ref = useRef<any>();

  useEffect(() => {
    dispatch(change(form, input.name, number));

    if (ref && ref.current) {
      // https://github.com/bl00mber/react-phone-input-2/blob/master/src/index.js#L474
      if (onCountryCodeChange) onCountryCodeChange(ref.current.getCountryData().countryCode);
    }
  }, [dispatch, form, input.name, number, onCountryCodeChange]);

  const errorClass = isError ? 'c-input__message--error' : '';
  const labelClass = isLabel ? 'c-input__message--info' : '';

  return (
    <Box className="c-input c-input--validation" sx={sx} data-title={title}>
      {(isError || isLabel) && (
        <div className={`c-input__message c-input__message--bottom ${errorClass} ${labelClass}`}>
          {isError && error}
          {isLabel && !isError && label}
        </div>
      )}

      <div className="c-input__label">
        <ReactPhone
          ref={ref}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={disabled}
          country={'us'}
          value={number}
          masks={PHONE_MASKS}
          dropdownClass="c-country__dropdown"
        />
      </div>

      {children}
    </Box>
  );
};
