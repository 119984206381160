import React, { FC } from 'react';

import { useNavigateToDefault } from 'src/v2/features/sharedEntity';
import { DocumentInviteParticipantForm } from 'src/v2/features/document/participants/DocumentInviteParticipantForm';

export interface Props {
  id: string;
}

export const InviteDocumentParticipant: FC<Props> = ({ id }) => {
  const navigateToDefault = useNavigateToDefault(id);

  return <DocumentInviteParticipantForm onClose={navigateToDefault} documentId={id} />;
};
