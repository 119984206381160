import React from 'react';

import { TextSignatureContextProvider } from './TextSignatureContextProvider';
import { SignaturePreviewContextProvider } from './SignaturePreviewContextProvider';
import { FileSignatureContextProvider } from './FileSignatureContextProvider';
import { SignatureSettingsContextProvider } from './SignatureSettingsContextProvider';
import { SignatureActionContextProvider } from './SignatureActionContextProvider';

export const SignatureAggregatedProvider: React.FC = ({ children }) => {
  return (
    <SignatureActionContextProvider>
      <TextSignatureContextProvider>
        <SignaturePreviewContextProvider>
          <FileSignatureContextProvider>
            <SignatureSettingsContextProvider>{children}</SignatureSettingsContextProvider>
          </FileSignatureContextProvider>
        </SignaturePreviewContextProvider>
      </TextSignatureContextProvider>
    </SignatureActionContextProvider>
  );
};
