import { SagaIterator } from 'redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, takeLatest, select, put } from 'redux-saga/effects';
import { isNil, size, get, head, isUndefined } from 'lodash';
import { toastr } from 'react-redux-toastr';
import { updateSyncErrors } from 'redux-form';

import {
  signup,
  fetchNotificationsSettings,
  updateNotificationSetting,
  SignUpResponse,
  SaveNotificationRequest,
  fetchUserInfoByToken,
  continueRegistration,
  SignUpResponseData,
  fetchSignupInitialInfo,
} from 'src/api/signUpApi';
import {
  setAuthToken,
  setUserId,
  logoutAsync,
  startLoading as startLoadingAuth,
  stopLoading as stopLoadingAuth,
  setCompanyId,
  setIsLoginAvailable,
} from 'src/v2/features/auth';
import { SignUpLightActionPayload } from 'src/v2/boundary/actionPayload/signup';
import { DbRecordType } from 'src/v2/features/objectsStorage/types';
import { storeData } from 'src/v2/features/objectsStorage';
import {
  updateProfileAvatar,
  fetchProfileSaga,
  setProfile,
  profileUpdateSuccess,
} from 'src/v2/features/profile';
import { normalizeErrors } from 'src/utils/normalize';
import { BackendError, ObjectSerialized } from 'src/common/types';
import { resendSms, updatePhone } from 'src/api/profileApi';
import { getOrganizationV2 } from 'src/api/organizationApi';
import { RootState } from 'src/app/types';
import {
  AccountType,
  WizardStepV2,
  InviteType,
  RegistrationTypeToInviteType,
} from 'src/models/signUp';
import { getUserLanguage } from 'src/i18n';
import { SignUpLightFormName } from 'src/v2/features/signUp/components/SignUpLightForm';
import { fetchOrganizationSaga } from 'src/v2/features/organization/store/organizationSagas';

import { emailPasswordFormName } from '../EmailPasswordForm';
import {
  checkSignUpComplete,
  setStep,
  verifyPhoneAction,
  resendSmsAsync,
  saveProfileAction,
  setAccountType,
  setInviteType,
  startLoading as startLoadingSignUp,
  stopLoading as stopLoadingSignUp,
  setPhoneVerified,
  fetchSignUpInitialInfoAction,
  signUpAction,
  signUpLightAction,
  resetState,
  saveOrgInfoAction,
  getNotificationsSettingsAction,
  fetchNotificationsSettingsSuccess,
  saveNotificationSettingAction,
  startLoading,
  stopLoading,
  setProfileAvatarAction,
  fetchInfoFromToken,
  prefillData,
  setToken,
  setIsComplete,
  getStarted,
  setInvite,
  fillBasicData,
} from './signUpStore';
import {
  SignUpAsyncPayload,
  VerifyPhonePayload,
  ResendSmsPayload,
  SignupErrors,
  ProfileFormPayload,
  OrganizationInformationFormPayload,
  SaveNotficiationsSettingsPayload,
  SetProfileAvatarPayload,
  FetchInitialInfoPayload,
} from '../types';
import { getSignUpData, getUserInfo } from './signUpSelectors';
import { getStartSignupWizardStep, getRegistrationApiPayload, getRegistrationType } from '../utils';
import { getToken } from '../../../../shared/auth';
import { getCurrentOrganizationId } from '../../../../shared/organization';

const showErrorNotification = (errors: BackendError[]): void => {
  errors.forEach((error: BackendError) => {
    toastr.error('Error', error.value);
  });
};

const transformToFormErrors = (errors: BackendError[]): SignupErrors => {
  if (size(errors) > 0) {
    return errors.reduce((formErrors: SignupErrors, error: BackendError) => {
      if (error.key === 'error' && error.value.includes('company')) {
        return {
          ...formErrors,
          companyName: error.value,
        };
      }
      return {
        ...formErrors,
        [error.key]: error.value,
      };
    }, {});
  }
  return {};
};

function* showFormFieldErrors(errors: SignupErrors, formName: string): SagaIterator {
  if (size(errors) > 0) {
    yield put(updateSyncErrors(formName, errors, null));
  }
}

function* verifySignUpComplete(
  step: WizardStepV2 | null,
  stepNum: number,
  maxSteps: number,
  isBusiness: boolean,
  inviteType: InviteType | null,
): SagaIterator {
  const accountType = isBusiness ? AccountType.Business : AccountType.Personal;
  yield put(setAccountType(accountType));
  yield put(setInviteType(inviteType));
  const isComplete = isNil(step) || step === WizardStepV2.Complete;
  yield put(setIsComplete(isComplete));

  if (!isComplete && !isNil(step)) {
    yield put(
      setStep({
        step,
        stepNum,
        maxSteps,
      }),
    );
  }

  return isComplete;
}

function* checkSignUpCompleteSaga(): SagaIterator {
  try {
    yield put(startLoadingSignUp());

    yield call(fetchProfileSaga);

    const signUpData: SignUpResponseData = yield select(getSignUpData);
    yield put(setIsLoginAvailable(signUpData.loginIsAvailable));

    const registration = head(signUpData.registration);
    if (!registration) throw new Error('Registration data is missing');

    const { complete, nextStepNum, stepCount, nextStep, type } = registration;
    const signUpNextStep: WizardStepV2 | null = complete ? null : nextStep;
    const isBusiness = type !== 'personal' && type !== 'organizationInvite';

    const isSignUpComplete = yield call(
      verifySignUpComplete,
      signUpNextStep,
      nextStepNum,
      stepCount,
      isBusiness,
      RegistrationTypeToInviteType[type],
    );

    yield put(stopLoadingSignUp());
    yield put(stopLoadingAuth());

    return isSignUpComplete;
  } catch (e) {
    yield put(stopLoadingSignUp());
    return false;
  }
}

function* signUpUserSaga(payload: SignUpAsyncPayload): SagaIterator {
  const { accountType, inviteType, ...signUpPayload } = payload;
  const userInfo: ReturnType<typeof getUserInfo> = yield select(getUserInfo);
  const step = getStartSignupWizardStep(payload);
  const registrationType = getRegistrationType(accountType, inviteType);

  const registrationPayload = getRegistrationApiPayload(step, {
    ...userInfo,
    ...signUpPayload,
    language: getUserLanguage(),
    registrationType: step === WizardStepV2.StartInvite ? registrationType : undefined,
    realm: payload.companyName || '',
    captureToken: payload.captureToken,
  });
  const response = (yield call(
    signup,
    registrationPayload,
    payload.captureToken,
  )) as SignUpResponse;

  yield put(setAuthToken(response.included[0].attributes.token));
  yield put(setUserId(response.included[0].id));

  if (step === WizardStepV2.StartBusiness) {
    const organizationResponse = yield call(getOrganizationV2);
    const organization = ObjectSerialized.dataToBaseObjects(organizationResponse.data).find(
      (record) => record.type === 'organization',
    );
    if (organization) yield put(setCompanyId(organization.id));
  }

  const data = ObjectSerialized.dataToBaseObjects(response.included).find(
    (record) => record.type === 'user',
  );

  if (!isUndefined(data)) {
    yield put(setProfile(data));
    yield put(profileUpdateSuccess());
  } else {
    toastr.error('Error', 'No profile data');
  }
}

function* signUpSaga(action: PayloadAction<SignUpAsyncPayload>): SagaIterator {
  try {
    yield put(startLoadingAuth());
    yield call(signUpUserSaga, action.payload);
    yield put(stopLoadingAuth());
  } catch (err) {
    const errors = yield call(normalizeErrors, err);
    yield call(showErrorNotification, errors);
    const formErrors = yield call(transformToFormErrors, errors);
    yield call(showFormFieldErrors, formErrors, emailPasswordFormName);
    yield put(stopLoadingAuth());
  }
}

function* signUpLightSaga(action: PayloadAction<SignUpLightActionPayload>): SagaIterator {
  try {
    const signUpPayload = {
      ...action.payload,
      inviteType: InviteType.QRCode,
      isSignupFromQR: true,
    };
    yield put(startLoadingAuth());
    yield put(
      fillBasicData({
        ...signUpPayload,
        step: WizardStepV2.SignUp,
      }),
    );
    yield call(signUpUserSaga, signUpPayload);
    yield put(stopLoadingAuth());
  } catch (err) {
    const errors = yield call(normalizeErrors, err);
    yield call(showErrorNotification, errors);
    const formErrors = yield call(transformToFormErrors, errors);
    yield call(showFormFieldErrors, formErrors, SignUpLightFormName);
    yield put(stopLoadingAuth());
  }
}

function* verifyPhoneSaga(action: PayloadAction<VerifyPhonePayload>): SagaIterator {
  try {
    const { code } = action.payload;
    const payload = getRegistrationApiPayload(WizardStepV2.PhoneVerification, { code });
    yield call(continueRegistration, payload);

    yield put(setPhoneVerified(true));
    yield call(checkSignUpCompleteSaga);
  } catch (err) {
    const errors = yield call(normalizeErrors, err);
    yield call(showErrorNotification, errors);
    yield put(setPhoneVerified(false));
  }
}

function* saveProfileSaga(action: PayloadAction<ProfileFormPayload>): SagaIterator {
  try {
    const payload = getRegistrationApiPayload(WizardStepV2.Profile, {
      profile: action.payload.profile,
    });
    yield call(continueRegistration, payload);
    yield call(checkSignUpCompleteSaga);
  } catch (err) {
    const errors = yield call(normalizeErrors, err);
    yield call(showErrorNotification, errors);
  }
}

function* setProfileAvatarSaga(action: PayloadAction<SetProfileAvatarPayload>): SagaIterator {
  try {
    yield put(startLoading());
    yield put(updateProfileAvatar(action.payload.file));
    yield call(checkSignUpCompleteSaga);
  } catch (err) {
    const errors = yield call(normalizeErrors, err);
    yield call(showErrorNotification, errors);
  } finally {
    yield put(stopLoading());
  }
}

function* saveOrganizationInfoSaga(
  action: PayloadAction<OrganizationInformationFormPayload>,
): SagaIterator {
  try {
    const payload = getRegistrationApiPayload(WizardStepV2.BusinessProfile, {
      ...action.payload,
    });

    yield call(continueRegistration, payload);
    yield call(fetchOrganizationSaga);
    yield call(checkSignUpCompleteSaga);
  } catch (err) {
    const errors = yield call(normalizeErrors, err);
    yield call(showErrorNotification, errors);
  }
}

function* getNotificationsSettingsSaga(): SagaIterator {
  try {
    yield put(startLoading());
    const settings = yield call(fetchNotificationsSettings);
    yield put(fetchNotificationsSettingsSuccess(settings));
  } catch (err) {
    const errors = yield call(normalizeErrors, err);
    yield call(showErrorNotification, errors);
  } finally {
    yield put(stopLoading());
  }
}

function* saveNotificationSettingSaga(
  action: PayloadAction<SaveNotficiationsSettingsPayload>,
): SagaIterator {
  try {
    yield put(startLoading());
    const authUser = yield select((store: RootState) => store.auth);
    const requestData: SaveNotificationRequest = {
      data: {
        type: 'receiverSettings',
        attributes: [
          {
            receiver_id: authUser.userId,
            type: action.payload.type,
            active: action.payload.value,
          },
        ],
      },
    };
    yield call(updateNotificationSetting, requestData);
    yield put(getNotificationsSettingsAction());
  } catch (err) {
    const errors = yield call(normalizeErrors, err);
    yield call(showErrorNotification, errors);
  }
}

function* resendSmsSaga(action: PayloadAction<ResendSmsPayload>): SagaIterator {
  try {
    const { phone, withUpdatePhone, captureToken } = action.payload;
    yield put(startLoadingSignUp());
    yield put(setPhoneVerified(false));
    const token = yield select(getToken);
    const orgId = yield select(getCurrentOrganizationId);
    if (withUpdatePhone) {
      yield call(updatePhone, phone, token, captureToken, orgId);
    } else {
      yield call(resendSms, token, orgId, captureToken);
    }
  } catch (err) {
    const errors = yield call(normalizeErrors, err);
    yield call(showErrorNotification, errors);
  } finally {
    yield put(stopLoadingSignUp());
  }
}

function* logout(): SagaIterator {
  yield put(resetState());
}

function* fetchInfoFromTokenSaga(action: PayloadAction<string>): SagaIterator {
  try {
    yield put(startLoadingSignUp());
    const token = action.payload;
    const response = yield call(fetchUserInfoByToken, token);
    const email = get(response, 'data[0].attributes.email');
    const phone = get(response, 'data[0].attributes.phone');
    const firstName = get(response, 'data[0].attributes.firstName');
    const lastName = get(response, 'data[0].attributes.lastName');
    const inviteType = get(response, 'data[0].attributes.inviteType');

    const data = ObjectSerialized.dataToBaseObjects(response.data).find(
      (record) => record.type === DbRecordType.Invite,
    );

    yield put(setToken(token));
    yield put(prefillData({ phone, email, firstName, lastName, inviteType }));
    yield put(setInvite(data));
    yield put(storeData(response));
    yield put(stopLoadingSignUp());
  } catch (e) {
    console.error('fetchInfoFromToken', e);
    yield put(stopLoadingSignUp());
  }
}

function* fetchSignUpInitialInfoSaga(action: PayloadAction<FetchInitialInfoPayload>): SagaIterator {
  try {
    const { accountType, inviteType } = action.payload;
    const registrationType = getRegistrationType(accountType, inviteType);
    const signUpInfo = yield call(fetchSignupInitialInfo, registrationType);

    const { firstStep, stepCount } = signUpInfo.data[0].attributes;

    yield put(
      getStarted({
        firstStep,
        stepCount,
        accountType,
      }),
    );
  } catch (error) {
    console.error(error);
  }
}

export function* watchSignupSagas(): SagaIterator {
  yield takeLatest(signUpAction, signUpSaga);
  yield takeLatest(signUpLightAction, signUpLightSaga);
  yield takeLatest(verifyPhoneAction, verifyPhoneSaga);
  yield takeLatest(saveProfileAction, saveProfileSaga);
  yield takeLatest(setProfileAvatarAction, setProfileAvatarSaga);
  yield takeLatest(saveOrgInfoAction, saveOrganizationInfoSaga);
  yield takeLatest(getNotificationsSettingsAction, getNotificationsSettingsSaga);
  yield takeLatest(saveNotificationSettingAction, saveNotificationSettingSaga);
  yield takeLatest(resendSmsAsync, resendSmsSaga);
  yield takeLatest(logoutAsync, logout);
  yield takeLatest(checkSignUpComplete, checkSignUpCompleteSaga);
  yield takeLatest(fetchInfoFromToken, fetchInfoFromTokenSaga);
  yield takeLatest(fetchSignUpInitialInfoAction, fetchSignUpInitialInfoSaga);
}
