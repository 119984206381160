import React, { FC, useMemo } from 'react';
import { Field, InjectedFormProps } from 'redux-form';

import { Radio } from 'src/v2/features/reduxForm';
import { EntityType } from 'src/models/paper';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

import { CreateTemplatePayload, TemplateCreationMode } from '../../types';
import { connectTemplateForm } from './utils';
import { TemplateMetaProps } from './types';
import { TemplateMetadataForm } from './TemplateMetadataForm';

interface CreationMode {
  value: string;
  label: string;
}

const getCreationModes = (): Array<CreationMode> => [
  {
    value: TemplateCreationMode.Form,
    label: i18n(translationKeys.forms.template.creationModes.blank),
  },
  {
    value: TemplateCreationMode.File,
    label: i18n(translationKeys.forms.template.creationModes.upload),
  },
];
interface Props extends TemplateMetaProps {
  isLoading: boolean;
  onSubmit: (data: CreateTemplatePayload) => void;
}

const Create: FC<Props & InjectedFormProps<CreateTemplatePayload, Props>> = ({
  isLoading,
  onSubmit,
  handleSubmit,
  folderId,
}) => {
  const currentModes = useMemo(
    () =>
      getCreationModes().map(({ value, label }) => (
        <Field
          key={value}
          component={Radio}
          name="createTemplateOption"
          value={value}
          label={label}
          type="radio"
          mb8
          required
        />
      )),
    [],
  );

  return (
    <TemplateMetadataForm
      label={i18n(translationKeys.forms.template.create.createNew)}
      folderId={folderId}
      isLoading={isLoading}
      onSubmit={handleSubmit(onSubmit)}
      isCreating
    >
      <div className="c-title c-title--uppercase c-title--lh-1">
        {i18n(translationKeys.forms.template.create.type)}
      </div>

      {currentModes}
    </TemplateMetadataForm>
  );
};

const fields = ['createTemplateOption'];
export const TemplateMetadataCreate = connectTemplateForm<Props, CreateTemplatePayload>(
  Create,
  fields,
  { paperType: EntityType.document, createTemplateOption: TemplateCreationMode.Form },
);
