import React, { useContext } from 'react';

import { SidebarSettingsContext } from 'src/common/contexts/SidebarSettingsContext';
import { SidebarSettings } from 'src/common/types';

import { sidebarController, sidebarName } from './routes';
import { SidebarWrapper } from './SidebarWrapper';

export const [useIsOpen, links, pushes, SidebarComponent] = sidebarController;

export const { useOpen, useClose } = links;

export const TemplateSidebar: React.FC = () => {
  const { isHidden } = useContext<SidebarSettings>(SidebarSettingsContext);

  return (
    <SidebarWrapper
      sidebarName={sidebarName}
      sidebarComponent={SidebarComponent}
      isHidden={isHidden}
    />
  );
};
