import React from 'react';
import { Flex, Box, Image, Link } from '@theme-ui/components';
import { Link as RouterLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { book } from 'src/app/book';

import logoImg from 'src/assets/docuwalk-logo.svg';
import logoImgMobile from 'src/assets/docuwalk-logo-mobile.svg';

const styles = {
  header: {
    height: '74px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: 'white',
    position: 'fixed',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: '1200px',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
    '@media (max-width: 600px)': {
      height: '48px',
      minWidth: 'auto',
    },
  },

  logo: {
    px: '18px',
    img: {
      display: 'block',
      width: '70%',
    },
  },

  menu: {
    alignItems: 'center',
    px: '20px',
  },

  link: {
    mx: '20px',
  },

  button: {
    mx: '25px',
    '@media (max-width: 600px)': {
      fontSize: '12px',
      px: '20px',
      py: '8px',
    },
  },
};

export const Header = (): JSX.Element => {
  const isMobile = useMediaQuery({ maxWidth: 600 });

  const logo = (
    <Box sx={styles.logo}>
      <Link href={book.home}>
        <Image src={isMobile ? logoImgMobile : logoImg} alt="DocuWalk logo" />
      </Link>
    </Box>
  );

  const menuItems = (
    <>
      <Link
        data-type="products-link"
        sx={styles.link}
        variant="header"
        href={`${book.home}#products`}
      >
        Products
      </Link>
      <Link
        data-type="contactUs-link"
        sx={styles.link}
        variant="header"
        href={`${book.home}#contact-us`}
      >
        Contact Us
      </Link>
    </>
  );

  const loginBtn = (
    <Link
      data-type="login-link"
      sx={styles.button}
      as={RouterLink}
      variant="outlineButton"
      to={book.login}
    >
      Log In
    </Link>
  );

  if (isMobile) {
    return (
      <Flex data-id="landing-container-header" sx={styles.header}>
        {logo}
        {loginBtn}
      </Flex>
    );
  }

  return (
    <Flex data-id="landing-container-header" sx={styles.header}>
      {logo}
      <Flex sx={styles.menu}>
        {menuItems}
        {loginBtn}
      </Flex>
    </Flex>
  );
};
