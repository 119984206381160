import { v4 as uuidv4 } from 'uuid';

import { Section } from 'src/models/document';

export function insert(src: Section[], index: number): Section[] {
  const arr = [...src];
  arr.splice(index, 0, {
    id: uuidv4(),
    text: '',
    isPending: true,
  });
  return arr;
}

export function update(src: Section[], index: number, content: string): Section[] {
  const arr = [...src];
  arr[index] = {
    ...src[index],
    text: content,
    isPending: true,
  };
  return arr;
}

export function move(src: Section[], from: number, to: number): Section[] {
  const arr = [...src];
  const section = arr.splice(from, 1);
  arr.splice(to, 0, { ...section[0], isPending: true });
  return arr;
}

export function remove(src: Section[], index: number): Section[] {
  const arr = [...src];
  arr.splice(index, 1);
  return arr;
}

export function reindex(src: Section[]): Section[] {
  return src.map((data, index) => ({ ...data, index }));
}

export function setPending(src: Section[], index: number): Section[] {
  const arr = [...src];
  arr[index] = {
    ...src[index],
    isPending: true,
  };
  return arr;
}
