import React, { useContext } from 'react';
import cn from 'classnames';

import { StaticSettings } from 'src/common/types';
import { SolidPlus } from 'src/v2/icons/SolidPlus';
import { StaticSettingsContext } from 'src/common/contexts/StaticSettingsContext';
import { DynamicSettings } from 'src/v2/modules/layout/types';
import { DynamicSettingsContext } from 'src/v2/modules/layout/contexts/DynamicSettingsContext';
import { marketingService } from 'src/common/marketing';

interface Props {
  active: boolean;
  className?: string;
}

export const Navigation = React.forwardRef<HTMLDivElement, Props>(({ className, active }, ref) => {
  const { navChildren } = useContext<StaticSettings>(StaticSettingsContext);
  const { onPlusClick } = useContext<DynamicSettings>(DynamicSettingsContext);

  return (
    <aside
      className={cn('l-aside l-aside--quantum', className, active && 'l-aside--active')}
      onClick={marketingService.testEvent}
    >
      <div className="l-aside__wrap" ref={ref}>
        <div className="l-aside__scroll">
          <div className="l-aside__top" data-id="layout-container-sidebar" />

          <div className="l-aside__bottom">
            {<nav className="c-menu c-menu--quantum">{navChildren}</nav>}
            {onPlusClick && (
              <div className="c-card--in-aside">
                <div className="c-card__plus" onClick={onPlusClick}>
                  <SolidPlus />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </aside>
  );
});

Navigation.displayName = 'PureNavigation';
