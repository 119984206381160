import { ObjectData } from 'src/common/types';

export interface ObjectsContainer {
  [key: string]: ObjectData;
}

export enum DbRecordType {
  Paper = 'paper',
  PaperParticipant = 'paperParticipant',
  PaperUser = 'paperUser',
  CardPaper = 'cardPaper',
  CardPaperParticipant = 'cardPaperParticipant',
  CardPaperUser = 'cardPaperUser',
  User = 'user',
  Participant = 'participant',
  Avatar = 'avatar',
  Profile = 'profile',
  Entity = 'entity',
  Attachment = 'attachment',
  Lock = 'lock',
  Tree = 'tree',
  Term = 'term',
  Field = 'field',
  Template = 'template',
  SharedTemplate = 'sharedTemplate',
  SystemTemplate = 'systemTemplate',
  TemplateParticipant = 'templateParticipant',
  TemplateUser = 'templateUser',
  Project = 'project',
  ProjectParticipant = 'project_participant',
  ProjectUser = 'project_participant_user',
  ProjectObject = 'project_object',
  Folder = 'folder',
  Object = 'object',
  Invite = 'invite',
  Papers = 'papers',
}
