import { createSelector } from '@reduxjs/toolkit';
import { isUndefined } from 'lodash';

import { ObjectsStorageState, RootState } from 'src/app/types';
import { DbRecordType, ObjectsContainer } from 'src/v2/features/objectsStorage/types';
import { FolderObjectData } from 'src/models/folder';

type Objects = ObjectsStorageState['objects'];

const getState = (state: RootState) => state.objectsStorage;
const getObjectsState = createSelector(getState, (state: ObjectsStorageState) => state.objects);
const createGetRecord = (record: DbRecordType) =>
  createSelector(getObjectsState, (objects: Objects) => objects[record]);

const createGetNewFlowRecord = (record: DbRecordType) =>
  createSelector(
    getObjectsState,
    (objects): Record<string, any> | ObjectsContainer => objects[record] || null,
  );

export const getUsers = createGetRecord(DbRecordType.User);

export const getOrganizations = createSelector(
  getObjectsState,
  (objects: Objects) => objects.organization,
);

export const getProfiles = createGetRecord(DbRecordType.Profile);

export const getMembers = createSelector(getObjectsState, (objects: Objects) => objects.member);

export const getAddresses = createSelector(getObjectsState, (objects: Objects) => objects.address);

export const getAvatars = createGetRecord(DbRecordType.Avatar);

export const getLogos = createSelector(getObjectsState, (objects: Objects) => objects.logo);

export const getPapers = createGetNewFlowRecord(DbRecordType.Paper);

export const getCardPapers = createSelector(
  getObjectsState,
  (objects: Objects) => objects[DbRecordType.CardPaper],
);

export const getRegistrations = createSelector(
  getObjectsState,
  (objects: Objects) => objects.registration,
);

export const getOrgProfiles = createSelector(
  getObjectsState,
  (objects: Objects) => objects.organizationProfile,
);

export const getUserSignatures = createSelector(
  getObjectsState,
  (objects: Objects) => objects.user_signatures,
);

export const getSignatures = createSelector(
  getObjectsState,
  (objects: Objects) => objects.signature,
);
export const getSignatureDescriptions = createSelector(
  getObjectsState,
  (objects: Objects) => objects.signature_description,
);

export const getStamps = createSelector(getObjectsState, (objects: Objects) => objects.stamp);

export const getFolders = createGetRecord(DbRecordType.Folder);

export const getFoldersArray = createSelector(getFolders, (folders): FolderObjectData[] => {
  if (isUndefined(folders)) return [];

  return Object.keys(folders).map((folderId) => folders[folderId] as unknown as FolderObjectData);
});

export const getObjects = createGetRecord(DbRecordType.Object);

export const getParticipants = createSelector(
  getObjectsState,
  (objects: Objects) => objects.participants,
);

export const getPaperParticipants = createSelector(
  getObjectsState,
  (objects: Objects) => objects[DbRecordType.PaperParticipant],
);

export const getCardPaperParticipants = createSelector(
  getObjectsState,
  (objects: Objects) => objects[DbRecordType.CardPaperParticipant],
);

export const getCardPaperUsers = createSelector(
  getObjectsState,
  (objects: Objects) => objects[DbRecordType.CardPaperUser],
);

export const getPaperUsers = createSelector(
  getObjectsState,
  (objects: Objects) => objects[DbRecordType.PaperUser],
);

export const getAttachments = createGetRecord(DbRecordType.Attachment);
export const getEntities = createGetRecord(DbRecordType.Entity);
export const getLocks = createGetRecord(DbRecordType.Lock);
export const getTrees = createGetRecord(DbRecordType.Tree);
export const getTerms = createGetRecord(DbRecordType.Term);
export const getFields = createGetRecord(DbRecordType.Field);
export const getTemplates = createGetRecord(DbRecordType.Template);
export const getTemplateParticipants = createGetRecord(DbRecordType.TemplateParticipant);
export const getTemplateUsers = createGetRecord(DbRecordType.TemplateUser);
export const getSharedTemplates = createGetRecord(DbRecordType.SharedTemplate);
export const getSystemTemplates = createGetRecord(DbRecordType.SystemTemplate);
export const getProjects = createGetRecord(DbRecordType.Project);
export const getProjectParticipants = createGetRecord(DbRecordType.ProjectParticipant);
export const getProjectUsers = createGetRecord(DbRecordType.ProjectUser);
export const getProjectObjects = createGetRecord(DbRecordType.ProjectObject);

export const getInviteObjects = createGetRecord(DbRecordType.Invite);
