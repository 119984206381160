import { stubTrue /*, includes*/ } from 'lodash';

import { /*SubscriptionPlan,*/ UserPlan } from 'src/models/billing';
import { TemplateRole } from 'src/models/template';

export const canDeleteTemplateParticipant = (actorRole: TemplateRole, targetRole: TemplateRole) =>
  targetRole !== TemplateRole.Owner && actorRole === TemplateRole.Owner;

export const canDeleteTemplate = stubTrue;

export const canDeleteTemplateByCardPaper = stubTrue;

export const canCopyTemplate = (actorRole: TemplateRole) => actorRole === TemplateRole.Owner;

export const canMoveTemplate = (actorRole: TemplateRole) => true;

export const canCreateFolderInTemplates = (plan: UserPlan): boolean => true;
// !includes([SubscriptionPlan.Basic, SubscriptionPlan.Premium], plan);
