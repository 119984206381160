import { useRef, PropsWithChildren, useMemo } from 'react';

import { useOnClickOutside } from 'src/common/hooks/useOnClickOutside';
import { useToggle } from 'src/common/hooks/useToggle';
import { PublicLayout } from 'src/v2/components/PublicLayout';

import { PublicHeader } from '../PublicHeader';
import { WizardHeader } from './WizardHeader';

export type WizardLayoutProps = PropsWithChildren<{
  startStep: boolean;
}>;

export const WizardLayout = (props: WizardLayoutProps): JSX.Element => {
  const { children, startStep } = props;
  const headerRef = useRef(null);
  const [navIsOpen, , openNavigation, closeNavigation] = useToggle();

  useOnClickOutside(headerRef, closeNavigation);

  const renderHeader = useMemo(() => {
    return startStep ? (
      <PublicHeader
        ref={headerRef}
        onHamburgerClick={openNavigation}
        navIsOpen={navIsOpen}
        {...props}
      />
    ) : (
      <WizardHeader onHamburgerClick={openNavigation} {...props} />
    );
  }, [startStep, openNavigation, navIsOpen, props]);

  return (
    <PublicLayout header={renderHeader} mainClassName="l-main--sign-in">
      {children}
    </PublicLayout>
  );
};
