import { createSelector, createSlice } from '@reduxjs/toolkit';

import { defaultActionsFactoryWithData } from 'src/utils/defaultSlice';
import { RootState, DefaultSliceState } from 'src/app/types';

const initialState: DefaultSliceState = {
  isLoading: false,
  error: '',
};

const { onStart, onError, onSuccess } = defaultActionsFactoryWithData();

export const updateParticipantSlice = createSlice({
  name: 'updateParticipant',
  initialState,
  reducers: {
    start: onStart,
    error: onError,
    success: onSuccess,
  },
});

export const { reducer } = updateParticipantSlice;

export const getState = (state: RootState): DefaultSliceState => state.documentUpdateParticipant;

export const getIsUpdating = createSelector(getState, (state) => state.isLoading);
