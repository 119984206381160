import React, { FC, useMemo } from 'react';

import { NotificationData, NotificationTarget } from 'src/v2/entities/notification';

import { convertPayloadToMap, parseTemplate } from '../utils';
import { Chunk } from './Chunk';

interface Props {
  template: string;
  payload: NotificationData[];
  target: NotificationTarget;
}

export const NotificationTemplateRenderer: FC<Props> = ({ payload, target, template }) => {
  // Convert array based payload [{key: string, value: string}] to {[key]: value} object
  const payloadObj = useMemo(() => convertPayloadToMap(payload), [payload]);

  return (
    <>
      {parseTemplate(template).map((token) => (
        <Chunk payload={payloadObj} target={target} token={token} key={token} />
      ))}
    </>
  );
};
