import { matchPath } from 'react-router-dom';
import { isNull, isUndefined, some } from 'lodash';
import { book } from 'src/app/book';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { getStringFromDate } from 'src/utils/dates';
import {
  BillingInfo,
  BillingPeriod,
  BillingPeriodLabelMap,
  SubscriptionPlan,
  SubscriptionPlanLabelMap,
  SubscriptionStatus,
} from 'src/models/billing';
import { UserState } from 'src/v2/features/organization/types';

const isUserPlanStandard = (userPlan: SubscriptionPlan): boolean =>
  userPlan === SubscriptionPlan.Standard;

const isUserPlanPremium = (userPlan: SubscriptionPlan): boolean =>
  userPlan === SubscriptionPlan.Premium;

const isUserPlanBasic = (userPlan: SubscriptionPlan): boolean =>
  userPlan === SubscriptionPlan.Basic;

const isUserPlanVIP = (userPlan: SubscriptionPlan): boolean => userPlan === SubscriptionPlan.Vip;

const isUserWithBillingPeriod = (billingPeriod: string): boolean =>
  billingPeriod !== BillingPeriod.None;

const isUserPlanWillBeDowngraded = (billingInfo: BillingInfo): boolean =>
  billingInfo.cancelAtPeriodEnd;

const getDescriptionTrialingPremium = (dateExpired: string): string =>
  i18n(translationKeys.forms.billing.freeTrialEndPremium, {
    dateExpired,
  });

const getDescriptionTrialingCommon = (dateExpired: string): string =>
  i18n(translationKeys.forms.billing.freeTrialEnd, {
    dateExpired,
  });

const getDescriptionTrialing = (
  isPremium: boolean,
  dateTrialEnd: string,
  isInOrganization: boolean,
): string => {
  return isPremium && !isInOrganization
    ? getDescriptionTrialingPremium(dateTrialEnd)
    : getDescriptionTrialingCommon(dateTrialEnd);
};

const getDescriptionUnpaid = (dateExpired: string): string =>
  i18n(translationKeys.forms.billing.paidPeriodExpired, {
    dateExpired,
  });

const getDescriptionActiveBasic = (): string =>
  i18n(translationKeys.forms.billing.oneReportBasicFree);

const getDescriptionActivePlan = (dateUntil: string): string =>
  i18n(translationKeys.forms.billing.paidUntil, {
    dateUntil,
  });

const getDescriptionActiveCommon = (
  dateUntil: string,
  isPlanDowngraded: boolean,
  subscribedPlan: string,
): string =>
  getDescriptionActivePlan(dateUntil) +
  (isPlanDowngraded ? getDescriptionForDowngradedPlan(subscribedPlan) : '');

const getDescriptionForDowngradedPlan = (subscribedPlan: string): string =>
  i18n(translationKeys.forms.billing.subscribedPlanAfter, {
    subscribedPlan,
  });

const getDescriptionActive = (
  isBasic: boolean,
  dateUntil: string,
  isPlanDowngraded: boolean,
): string =>
  isBasic
    ? getDescriptionActiveBasic()
    : getDescriptionActiveCommon(
        dateUntil,
        isPlanDowngraded,
        i18n(SubscriptionPlanLabelMap[SubscriptionPlan.Basic]),
      );

const getDescriptionDraft = (): string => i18n(translationKeys.forms.billing.notPaidPeriod);

type SupportedSubscriptionStatus =
  | SubscriptionStatus.Trialing
  | SubscriptionStatus.Unpaid
  | SubscriptionStatus.Active
  | SubscriptionStatus.Draft
  | SubscriptionStatus.Incomplete
  | SubscriptionStatus.IncompleteExpired;

interface MessageFormatterPayload {
  subscriptionStatus: SupportedSubscriptionStatus;
  trialEnd: string;
  validTo: string;
  isUserInOrganization: boolean;
  isUserBasic: boolean;
  isUserStandard: boolean;
  isUserPremium: boolean;
  isPlanDowngraded: boolean;
}

export const formatDescriptionForBillingPeriod = ({
  subscriptionStatus,
  trialEnd,
  validTo,
  isUserInOrganization,
  isUserBasic,
  isUserPremium,
  isPlanDowngraded,
}: MessageFormatterPayload) => {
  switch (subscriptionStatus) {
    case SubscriptionStatus.Trialing:
      return getDescriptionTrialing(isUserPremium, trialEnd, isUserInOrganization);
    case SubscriptionStatus.Unpaid:
      return getDescriptionUnpaid(validTo);
    case SubscriptionStatus.Active:
      return getDescriptionActive(isUserBasic, validTo, isPlanDowngraded);
    case SubscriptionStatus.Draft:
      return getDescriptionDraft();
    case SubscriptionStatus.Incomplete:
      return '';
    case SubscriptionStatus.IncompleteExpired:
      return '';
    default: {
      const state: unknown = subscriptionStatus;
      throw new Error(
        i18n(translationKeys.errors.UNKNOWN_USER_STATE, {
          state,
        }),
      );
    }
  }
};

const adaptBillingInfoToMessageFormatter = (
  billingInfo: BillingInfo,
  isUserInOrganization: boolean,
): MessageFormatterPayload => {
  const userPlan = isNull(billingInfo.subscribedPlan)
    ? billingInfo.activePlan
    : billingInfo.subscribedPlan;
  return {
    subscriptionStatus: billingInfo.state as SupportedSubscriptionStatus,
    trialEnd: getStringFromDate(billingInfo.trialEnd),
    validTo: getStringFromDate(billingInfo.validTo),
    isUserInOrganization,
    isUserBasic: isUserPlanBasic(userPlan),
    isUserStandard: isUserPlanStandard(userPlan),
    isUserPremium: isUserPlanPremium(userPlan),
    isPlanDowngraded: isUserPlanWillBeDowngraded(billingInfo),
  };
};

export const shouldFormatMessage = (subscribedPlan: SubscriptionPlan) =>
  subscribedPlan !== SubscriptionPlan.Vip;

export const getDescriptionForBillingPeriod = (
  billingInfo: BillingInfo,
  isUserInOrganization: boolean,
): string => {
  if (!shouldFormatMessage(billingInfo.activePlan)) return '';

  const payload = adaptBillingInfoToMessageFormatter(billingInfo, isUserInOrganization);
  try {
    return formatDescriptionForBillingPeriod(payload);
  } catch (e) {
    console.error(e);
    return '';
  }
};

const getTitleSingleNoBilling = (userPlan: string) =>
  i18n(translationKeys.forms.billing.userPlan, {
    userPlan,
  });

const getTitleSingleBilling = (userPlan: string, billingPeriod: string) =>
  i18n(translationKeys.forms.billing.singleBillingTitle, {
    userPlan,
    billingPeriod,
  });

interface TitleFormatterCheck {
  userInOrganization: boolean;
  userPlanVIP: boolean;
  userWithBillingPeriod: boolean;
}

const shouldTitleBeBasic = (plan: SubscriptionPlan) => isUserPlanBasic(plan);

export const shouldTitleBeSingleNoBilling = ({
  userInOrganization,
  userPlanVIP,
  userWithBillingPeriod,
}: TitleFormatterCheck): boolean => (!userInOrganization || userPlanVIP) && !userWithBillingPeriod;

export const shouldTitleBeSingleWithBilling = ({
  userInOrganization,
  userPlanVIP,
  userWithBillingPeriod,
}: TitleFormatterCheck): boolean => (!userInOrganization || userPlanVIP) && userWithBillingPeriod;

export const shouldTitleBeTeamWithBilling = ({
  userInOrganization,
  userPlanVIP,
  userWithBillingPeriod,
}: TitleFormatterCheck): boolean => userInOrganization && !userPlanVIP && userWithBillingPeriod;

export const getTitleForBillingInfo = (
  billingInfo: BillingInfo,
  userInOrganization: boolean,
): string => {
  if (isNull(billingInfo.subscribedPlan)) return '';

  const userPlanVIP: boolean = isUserPlanVIP(billingInfo.subscribedPlan);
  const userWithBillingPeriod: boolean = isUserWithBillingPeriod(billingInfo.billingPeriod);

  if (shouldTitleBeBasic(billingInfo.subscribedPlan)) {
    return getDescriptionActiveBasic();
  }

  if (shouldTitleBeSingleNoBilling({ userInOrganization, userPlanVIP, userWithBillingPeriod })) {
    return getTitleSingleNoBilling(i18n(SubscriptionPlanLabelMap[billingInfo.subscribedPlan]));
  }
  if (shouldTitleBeSingleWithBilling({ userInOrganization, userPlanVIP, userWithBillingPeriod })) {
    return getTitleSingleBilling(
      i18n(SubscriptionPlanLabelMap[billingInfo.subscribedPlan]),
      i18n(BillingPeriodLabelMap[billingInfo.billingPeriod]),
    );
  }
  if (shouldTitleBeTeamWithBilling({ userInOrganization, userPlanVIP, userWithBillingPeriod })) {
    return getTitleSingleBilling(
      i18n(SubscriptionPlanLabelMap[billingInfo.subscribedPlan]),
      i18n(BillingPeriodLabelMap[billingInfo.billingPeriod]),
    );
  }

  return getTitleSingleNoBilling(i18n(SubscriptionPlanLabelMap[billingInfo.subscribedPlan]));
};

export const shouldSubscriptionCanceled = (choosePlan: SubscriptionPlan): boolean => {
  return choosePlan === SubscriptionPlan.Basic;
};

export const shouldSubscriptionBeCreated = (
  choosePlan: SubscriptionPlan,
  initialPlan: SubscriptionPlan,
): boolean => {
  return (
    (choosePlan !== SubscriptionPlan.Basic && initialPlan === SubscriptionPlan.Basic) ||
    (choosePlan !== SubscriptionPlan.Basic && initialPlan === SubscriptionPlan.Premium)
  );
};

const excludedFeatures = [book.templates.pattern];

/** @deprecated */
export const shouldShowSubscriptionModal = (
  isValidSubscription: boolean,
  isExcludedPage: boolean,
) => {
  return !isExcludedPage && !isValidSubscription;
};

export const shouldShowSubscriptionModalV2 = (showModal: boolean, isExcludedPage: boolean) => {
  return !isExcludedPage && showModal;
};

export const shouldShowWarningModal = (users: UserState[], numberOfSeats: number): boolean =>
  !isUndefined(users) && users.length >= numberOfSeats;

export const checkIsDisabledFeature = (page: string) =>
  some(excludedFeatures, (path) => matchPath({ path }, page));
