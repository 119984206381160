import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isUndefined, isNull } from 'lodash';

import {
  getError,
  fetchDocument,
  getIsLoading,
} from 'src/v2/features/documentDetails/documentDetailsSlice';
import { openRoom } from 'src/v2/features/chat/store/chatReducers';
import { EntityType } from 'src/models/paper';
import { useGetEntityById } from 'src/v2/features/sharedEntity';
import { useGetEntityIdFromUrl } from 'src/v2/features/sharedEntity/hooks';
import { useFetchFolders } from 'src/v2/features/quantumNavigator/hooks';

import { DocumentLayout } from './DocumentLayout';
import { DocumentContent } from './DocumentContent';
import { DynamicSettingsContextProvider } from './providers/DynamicSettingsContextProvider';
import { SidebarNodeContextProvider } from './providers/SidebarNodeContextProvider';

export const DocumentDetailsPage: React.FC = () => {
  const id = useGetEntityIdFromUrl();
  const document = useGetEntityById(id);
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const error = useSelector(getError);

  useFetchFolders();
  useEffect(() => {
    if (!isUndefined(id)) {
      dispatch(fetchDocument(id));
      // todo: chat
      dispatch(openRoom({ affiliationId: id, affiliationType: EntityType.document }));
    }
  }, [dispatch, id]);

  const isDocumentLoaded = !isNull(document) && !isLoading && !error;

  return (
    <SidebarNodeContextProvider>
      <DynamicSettingsContextProvider>
        <DocumentLayout isLoading={isLoading} error={error}>
          {!isUndefined(id) && isDocumentLoaded && <DocumentContent id={id} />}
        </DocumentLayout>
      </DynamicSettingsContextProvider>
    </SidebarNodeContextProvider>
  );
};
