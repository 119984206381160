import PostalCodeValidator from 'postal-codes-js';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { size } from 'lodash';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { checkPassStrength, PasswordStrength } from 'src/utils/passwordStrength';

interface Error {
  email?: string;
  password?: string;
  phone?: string;
  terms?: string;
  username?: string;
  firstName?: string;
  companyName?: string;
  lastName?: string;
  realm?: string;
  code?: string;
  confirmPassword?: string;
  betaCode?: string;
}

type PasswordRecoveryValues = {
  password: string;
  confirmPassword: string;
};

export const validatePostalCode = (country: string, code: string): boolean => {
  const result = PostalCodeValidator.validate(country, code);
  if (typeof result === 'boolean') {
    return result;
  }
  return false;
};

export const validateRecoveryForm = (values: PasswordRecoveryValues): Error => {
  const { password, confirmPassword } = values;

  const errors: Error = {};

  if (!password) {
    errors.password = 'Password is required';
  }

  if (!confirmPassword) {
    errors.confirmPassword = 'Confirm Password is required';
  }

  if (password && confirmPassword && password !== confirmPassword) {
    errors.confirmPassword = "Confirm password doesn't match";
  }

  if (password && checkPassStrength(password) === PasswordStrength.weak) {
    errors.password = 'Password is weak';
  }

  return errors;
};

type AuthValues = {
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  phone: string;
  username: string;
  password: string;
  terms: boolean;
  realm: string;
  betaCode: string;
  confirmPassword: string;
  code: string;
};

const validatePhoneNumber = (phone: string): string | undefined => {
  const errorMessage = i18n(translationKeys.errors.WRONG_PHONE_FORMAT);

  try {
    if (!isValidPhoneNumber(phone)) {
      return errorMessage;
    }
  } catch (err) {
    return errorMessage;
  }
  return undefined;
};

export const validateEmail = (email: string): string | undefined => {
  const errorMessage = i18n(translationKeys.errors.INVALID_EMAIL_ADDRESS);

  try {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      return errorMessage;
    }
  } catch (err) {
    return errorMessage;
  }
  return undefined;
};

export const validateStripeFields = (value) => (value ? undefined : 'Required');

export const validateAuthForm = (values: AuthValues): Error => {
  const { email, phone, terms, username, firstName, lastName, companyName, realm, code, betaCode } =
    values;

  const errors: Error = {};

  if (!email) {
    errors.email = 'Email is required';
  } else {
    errors.email = validateEmail(email);
  }

  if (!companyName) {
    errors.companyName = 'Company name is required';
  }

  if (!phone) {
    errors.phone = 'Mobile number is required';
  } else {
    errors.phone = validatePhoneNumber(phone);
  }

  if (!terms) {
    errors.terms = 'Required';
  }

  if (!username) {
    errors.username = 'Username is required';
  }

  if (!firstName) {
    errors.firstName = 'First name is required';
  }

  if (!lastName) {
    errors.lastName = 'Last name is required';
  }

  if (!realm) {
    errors.realm = 'Organization name is required';
  }

  if (!code) {
    errors.code = 'Verification code is required';
  } else if (code.length < 6) {
    errors.code = 'Verification code must be 6 characters long';
  }

  if (!betaCode) {
    errors.betaCode = 'Invitation Code is required';
  }

  return errors;
};

export type ValidateNewUserFormProps = {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  permission: string;
};

export type ValidateNewUserFormError = {
  firstName?: string;
  lastName?: string;
  email?: string;
  role?: string;
  permission?: string;
};

export const validateNewUserForm = (values: ValidateNewUserFormProps): ValidateNewUserFormError => {
  const { firstName, lastName, email, role, permission } = values;

  const errors: ValidateUserFormError = {};

  if (!firstName) {
    errors.firstName = 'First name is required';
  }

  if (!lastName) {
    errors.lastName = 'Last name is required';
  }

  if (!email) {
    errors.email = 'Email is required';
  } else {
    errors.email = validateEmail(email);
  }

  if (!role) {
    errors.role = 'Role is required';
  }

  if (!permission) {
    errors.permission = 'Permission is required';
  }

  return errors;
};

export type ValidateUserFormProps = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  role: string;
  permission: string;
};

export type ValidateUserFormError = {
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  role?: string;
  permission?: string;
};

export const validateInviteUserForm = (values: ValidateUserFormProps): ValidateUserFormError => {
  const { phone, email, role, permission } = values;

  const errors: ValidateUserFormError = {};

  if (phone) {
    errors.phone = validatePhoneNumber(phone);
  }

  if (!email) {
    errors.email = 'Email is required';
  } else {
    errors.email = validateEmail(email);
  }

  if (!role) {
    errors.role = 'Role is required';
  }

  if (!permission) {
    errors.permission = 'Permission is required';
  }

  return errors;
};

export const validateUserForm = (values: ValidateUserFormProps): ValidateUserFormError => {
  const { firstName, lastName, phone, email, role, permission } = values;

  const errors: ValidateUserFormError = {};

  if (!firstName) {
    errors.firstName = 'First name is required';
  }

  if (!lastName) {
    errors.lastName = 'Last name is required';
  }

  if (!phone) {
    errors.phone = 'Mobile number is required';
  } else {
    errors.phone = validatePhoneNumber(phone);
  }

  if (!email) {
    errors.email = 'Email is required';
  } else {
    errors.email = validateEmail(email);
  }

  if (!role) {
    errors.role = 'Role is required';
  }

  if (!permission) {
    errors.permission = 'Permission is required';
  }

  return errors;
};

interface ValidateSignUpProfileProps {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  zipCode?: string;
  country?: string;
}

interface ValidateSignUpProfileError {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  zipCode?: string;
}

export const validateSignUpProfileForm = (
  values: ValidateSignUpProfileProps,
): ValidateSignUpProfileError => {
  const { firstName, lastName, phoneNumber, email, zipCode, country } = values;

  const errors: ValidateSignUpProfileError = {};

  if (!firstName) {
    errors.firstName = 'First name is required';
  }

  if (!lastName) {
    errors.lastName = 'Last name is required';
  }

  if (!phoneNumber) {
    errors.phoneNumber = 'Mobile number is required';
  } else {
    errors.phoneNumber = validatePhoneNumber(phoneNumber);
  }

  if (!email) {
    errors.email = 'Email is required';
  } else {
    errors.email = validateEmail(email);
  }

  if (zipCode && country && !validatePostalCode(country, zipCode)) {
    errors.zipCode = 'Invalid code for this country';
  }

  return errors;
};

interface ValidateOrganizationInformationProps {
  companyName: string;
  jobTitle: string;
  industry: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
}

interface ValidateOrganizationInformationError {
  companyName?: string;
  jobTitle?: string;
  industry?: string;
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
}
export const validateOrganizationInformationForm = (
  values: ValidateOrganizationInformationProps,
): ValidateOrganizationInformationError => {
  const { companyName, jobTitle, industry, address, city, state, zipCode, country } = values;

  const errors: ValidateOrganizationInformationError = {};

  if (!companyName) {
    errors.companyName = 'Company name is required';
  }

  if (!jobTitle) {
    errors.jobTitle = 'Job title is required';
  }

  if (!industry) {
    errors.industry = 'Industry is required';
  }

  if (!address) {
    errors.address = 'Address name is required';
  }

  if (!city) {
    errors.city = 'City name is required';
  }

  if (!state) {
    errors.state = 'State name is required';
  }

  if (!zipCode) {
    errors.zipCode = 'Postal Code is required';
  }

  if (zipCode && country && !validatePostalCode(values.country, values.zipCode)) {
    errors.zipCode = 'Invalid code for this country';
  }

  if (!country) {
    errors.country = 'Country name is required';
  }

  return errors;
};

export const isEmailValid = (str: string): boolean => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str);

export const isURLValid = (str: string): boolean => {
  const pattern = new RegExp(
    '^((?:https|http|ws|wss):\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return pattern.test(str);
};

export const validateInviteForm = ({ emails }: { emails?: string[] }): { emails?: string } => {
  if (!size(emails)) return { emails: "Shouldn't be empty" };
  return {};
};
