// Core
import { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isNull, isUndefined } from 'lodash';

import { book } from 'src/app/book';
import { getCompanyId } from 'src/shared/auth';

// Modal
export const useModal = (): [() => boolean, (event?: Event) => void, () => void] => {
  const [isModal, setIsModal] = useState(false);

  const shouldModalOpen = (): boolean => isModal;
  const openModal = (event?: Event): void => {
    setIsModal(true);
    if (!isUndefined(event)) {
      event.stopPropagation();
    }
  };
  const closeModal = (): void => {
    setIsModal(false);
  };

  return [shouldModalOpen, openModal, closeModal];
};

export const useRouterModal = (nestedPath: string): [() => boolean, () => void, () => void] => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const nestedPathRegex = new RegExp(`\\b${nestedPath}\\b`);

  const shouldModalOpen = (): boolean => nestedPathRegex.test(pathname);
  const openModal = (): void => {
    const url = `${pathname}${nestedPath}`;
    navigate(url);
  };
  const closeModal = (): void => {
    const url = pathname.replace(nestedPath, '');
    navigate(url);
  };

  return [shouldModalOpen, openModal, closeModal];
};

export const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

export const useGetQueryParam = (param: string) => {
  return useQuery().get(param);
};

export const useIsNoCaptureMode = (): boolean => {
  const isNoCaptureMode = useGetQueryParam('noCapture');
  return isNoCaptureMode === 'true';
};

export const useDefaultProfilePage = (): string => {
  const companyId = useSelector(getCompanyId);

  return isNull(companyId)
    ? book.profile.personal.generatePath()
    : book.profile.organization.generatePath();
};

export function usePrevious<T>(value: T | undefined): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
