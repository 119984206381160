import { FC, useMemo } from 'react';

import { ParticipantsSection, useNavigateToDefault } from 'src/v2/features/sharedEntity';
import { useGetFullParticipantsByDocumentId } from 'src/v2/features/entityParticipants/hooks';
import {
  useCanEditParticipant,
  useCanRemoveParticipant,
} from 'src/v2/features/contract/workflow/contractWorkflow';
import { getParticipantById } from 'src/v2/features/documentParticipants';
import { Divider } from 'src/v2/components/Divider';
import { ContractRole, ContractType } from 'src/models/contract';
import { ContractEditParticipantForm } from 'src/v2/features/contract/participants/ContractEditParticipantForm';

export interface Props {
  id: string;
  participantId: string;
}

export const EditContractParticipant: FC<Props> = ({ id, participantId }) => {
  const navigateToDefault = useNavigateToDefault(id);
  const participants = useGetFullParticipantsByDocumentId<ContractType, ContractRole>(id);
  const participant = useMemo(
    () => getParticipantById(participants, participantId),
    [participants, participantId],
  );
  const isAllowedToEditParticipant = useCanEditParticipant(id);
  const isAllowedToRemoveParticipant = useCanRemoveParticipant(id);

  return (
    <>
      <ParticipantsSection
        entityId={id}
        isAllowedToEditParticipant={isAllowedToEditParticipant}
        isAllowedToRemoveParticipant={isAllowedToRemoveParticipant}
      />
      <Divider />
      {participant && (
        <ContractEditParticipantForm
          contractId={id}
          participant={participant}
          onClose={navigateToDefault}
        />
      )}
    </>
  );
};
