import React, { FC } from 'react';

import { Visa } from 'src/v2/icons/Visa';
import { MasterCard } from 'src/v2/icons/MasterCard';
import { Maestro } from 'src/v2/icons/Maestro';
import { AmericanExpress } from 'src/v2/icons/AmericanExpress';
import { CardType } from 'src/models/billing';

interface PaymentCardProps {
  cardType: CardType;
}

export const PaymentCard: FC<PaymentCardProps> = ({ cardType }): JSX.Element => (
  <>
    {cardType === CardType.Visa && <Visa />}
    {cardType === CardType.MasterCard && <MasterCard />}
    {cardType === CardType.Maestro && <Maestro />}
    {cardType === CardType.AmericanExpress && <AmericanExpress />}
  </>
);
