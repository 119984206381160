import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { matchPath } from 'react-router-dom';
import { isNull } from 'lodash';

import { book } from 'src/app//book';

import { getRootDocumentFolderId } from '../document/store';
import { getRootContractFolderId } from '../contract/store';
import { getRootTemplatesFolderId } from '../template/selectors';
import { getRootProjectsFolderId } from '../project/store/projectSelectors';

export const useGetRootFolderId = () => {
  const rootDocumentFolderId = useSelector(getRootDocumentFolderId);
  const rootContractFolderId = useSelector(getRootContractFolderId);
  const rootTemplateFolderId = useSelector(getRootTemplatesFolderId);
  const rootProjectsFolderId = useSelector(getRootProjectsFolderId);
  const { pathname } = useLocation();

  if (!isNull(matchPath(book.document.pattern, pathname))) {
    return rootDocumentFolderId;
  }
  if (!isNull(matchPath(book.contract.pattern, pathname))) {
    return rootContractFolderId;
  }
  if (!isNull(matchPath(book.template.pattern, pathname))) {
    return rootTemplateFolderId;
  }
  if (!isNull(matchPath(book.project.pattern, pathname))) {
    return rootProjectsFolderId;
  }

  return rootDocumentFolderId;
};
