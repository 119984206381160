import { useSelector } from 'react-redux';

import { useIsAccountOwner, useIsInOrganization } from 'src/v2/features/organization/hooks';

import { getActivePlan } from '../store';
import {
  hasQRCodeFeature,
  hasTemplateFeature,
  hasPreBuiltTemplatesFeature,
  hasUpdatePlanFeature,
  hasGetPaymentCardFeature,
  hasNavigationFeature,
} from './rules';

export const useHasTemplateFeature = () => {
  const currentPlan = useSelector(getActivePlan);
  return hasTemplateFeature(currentPlan);
};

export const useHasQRCodeFeature = (): boolean => {
  const currentPlan = useSelector(getActivePlan);
  return hasQRCodeFeature(currentPlan);
};

export const useHasPreBuiltTemplatesFeature = (): boolean => {
  const currentPlan = useSelector(getActivePlan);
  return hasPreBuiltTemplatesFeature(currentPlan);
};

export const useHasUpdatePlanFeature = (): boolean => {
  const currentPlan = useSelector(getActivePlan);
  const isInOrganization = useIsInOrganization();
  const isAccountOwner = useIsAccountOwner();

  return hasUpdatePlanFeature(currentPlan) && (isAccountOwner || !isInOrganization);
};

export const useHasGetPaymentCardInfoFeature = (): boolean => {
  const currentPlan = useSelector(getActivePlan);

  return hasGetPaymentCardFeature(currentPlan);
};

export const useHasNavigationFeature = () => {
  const currentPlan = useSelector(getActivePlan);
  return hasNavigationFeature(currentPlan);
};
