import { FC } from 'react';

import { DynamicSettingsContext } from 'src/v2/modules/layout';
import { DynamicSettings } from 'src/v2/modules/layout/types';

import { useNavigateToProjectCreate } from 'src/v2/features/project/ProjectSidebar/nav-hooks';
import { useNotificationTogglePush } from 'src/v2/features/contract/ContractSidebar';
import { useCanCreateProject } from 'src/v2/features/project/permissions/projectPermissionsHooks';

import { ListHeader } from '../components/ListHeader';
import { ProjectQuantumNavigator } from '../ProjectQuantumNavigator';

export const DynamicSettingsContextProviderForList: FC = ({ children }) => {
  const handleShowProjectCreate = useNavigateToProjectCreate();
  const toggleNotifications = useNotificationTogglePush();
  const isCanCreateProject = useCanCreateProject();
  const handleOnPlusClick = isCanCreateProject ? handleShowProjectCreate : undefined;

  const ctx: DynamicSettings = {
    headerChildren: <ListHeader />,
    onBellClick: toggleNotifications,
    navigatorChildren: <ProjectQuantumNavigator />,
    onPlusClick: handleOnPlusClick,
  };

  return <DynamicSettingsContext.Provider value={ctx}>{children}</DynamicSettingsContext.Provider>;
};
