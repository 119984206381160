import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@theme-ui/components';

import { WorkflowAction } from 'src/v2/entities/workflow';
import {
  getAcknowledges,
  workflowAction,
  isAllowedToAcknowledge as getIsAllowedToAcknowledge,
  isAcknowledgedByMe as getIsAcknowledgedByMe,
} from 'src/v2/features/documentDetails/documentDetailsSlice';
import { getDefaultSignatureId } from 'src/v2/features/signature/store';
import { AcknowledgesList } from 'src/v2/features/documentDetails/components/AcknowledgesList';

import { EntityAcknowledgeButton } from './EntityAcknowledgeButton';

const styles = {
  signaturesContainer: {
    flex: '0 0 100%',
    mt: '12px',
  },
};

export const EntityAcknowledges: React.FC = () => {
  const dispatch = useDispatch();
  const isAllowedToAcknowledge = useSelector(getIsAllowedToAcknowledge);
  const isAcknowledgedByMe = useSelector(getIsAcknowledgedByMe);
  const isAcknowledgeableNow = isAllowedToAcknowledge && !isAcknowledgedByMe;
  const acknowledges = useSelector(getAcknowledges);
  const defaultSignatureId = useSelector(getDefaultSignatureId);

  const handleAcknowledge = useCallback((): void => {
    const payload = { signatureId: defaultSignatureId };
    dispatch(workflowAction({ action: WorkflowAction.Acknowledge, payload }));
  }, [defaultSignatureId, dispatch]);

  if (!acknowledges.length && !isAcknowledgeableNow) return null;

  return (
    <Box sx={styles.signaturesContainer}>
      <AcknowledgesList users={acknowledges} />
      {isAcknowledgeableNow && <EntityAcknowledgeButton handleAcknowledge={handleAcknowledge} />}
    </Box>
  );
};
