import React, { FC, useContext } from 'react';

import { CommonAction } from 'src/v2/features/signature';

import { HankoSignatureActionContext } from '../../contexts/HankoSignatureActionContext';

export const HankoSignatureAction: FC = ({ children }) => {
  const context = useContext(HankoSignatureActionContext);

  return (
    <CommonAction {...context} label="signature">
      {children}
    </CommonAction>
  );
};
