import React, { FC } from 'react';
import { DeviceContextProvider } from 'src/common/features/device';
import { SidebarSettingsContextProvider } from 'src/common/SidebarSettingsContextProvider';
import { NotificationsCountContextProvider } from 'src/v2/features/notifications';

import { StaticSettingsContextProvider } from './StaticSettingsContextProvider';

export const AppContextProvider: FC = ({ children }) => {
  return (
    <DeviceContextProvider>
      <SidebarSettingsContextProvider>
        <StaticSettingsContextProvider>
          <NotificationsCountContextProvider>{children}</NotificationsCountContextProvider>
        </StaticSettingsContextProvider>
      </SidebarSettingsContextProvider>
    </DeviceContextProvider>
  );
};
