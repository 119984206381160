import { SagaIterator } from 'redux-saga';
import { stringify } from 'qs';

import { apiClient } from 'src/common/client';
import { ObjectSerialized } from 'src/common/types';
import {
  BillingPeriod,
  SubscriptionPlan,
  BillingInfoResponse,
  CardInfoResponse,
  SubscriptionPrice,
  SubmitBillingDetailsPayload,
} from 'src/models/billing';

export function fetchBillingInfo(): SagaIterator<BillingInfoResponse> {
  return apiClient.get('/billing/billingInfo');
}

export function fetchCardInfo(): SagaIterator<CardInfoResponse> {
  return apiClient.get('/billing/cardInfo');
}

export function fetchPricingByPlanAndPeriod(
  plan: SubscriptionPlan,
  billingPeriod: BillingPeriod,
  numberOfSeats?: number,
): SagaIterator<SubscriptionPrice> {
  return apiClient.get(
    `/billing/priceInfo?${stringify({ plan, billingPeriod, seat: numberOfSeats })}`,
  );
}

export function subscribeApi(
  paymentMethodId: string,
  plan: SubscriptionPlan,
  period: BillingPeriod,
  numberOfSeats?: number,
): SagaIterator<ObjectSerialized | null> {
  const data = { paymentMethodId, plan, billingPeriod: period, seat: numberOfSeats };

  return apiClient.put('/billing/subscription', data);
}

export function cancelSubscriptionApi(): SagaIterator {
  return apiClient.post('/billing/cancelSubscriptionBySchedule');
}

export function retrySubscribeApi(paymentMethodId: string, invoiceId: string): SagaIterator {
  return apiClient.post('/billing/invoices/retryPayment', {
    paymentMethodId,
    invoiceId,
  });
}

export function continueWithBasicSubscriptionApi(): SagaIterator {
  return apiClient.post('/billing/continueWithBasic');
}

export function submitBillingInfoApi(data: SubmitBillingDetailsPayload): SagaIterator {
  return apiClient.post('/billing/billingDetails', data);
}

export function updateSubscriptionApi(paymentMethodId: string): SagaIterator {
  return apiClient.put('/billing/updatePaymentMethod', {
    paymentMethodId,
  });
}

export function upgradeSubscriptionApi(
  plan: SubscriptionPlan,
  period: BillingPeriod,
): SagaIterator<ObjectSerialized | null> {
  const data = { billingPlan: plan, billingPeriod: period };

  return apiClient.put('/gateway/v1/users/me/billing/subscription', data);
}

export function changeSubscriptionBillingPeriodApi(
  period: BillingPeriod,
): SagaIterator<ObjectSerialized | null> {
  const data = { billingPeriod: period };

  return apiClient.post('/billing/changeSubscriptionBillingPeriod', data);
}
