import React, { useContext } from 'react';

import { SidebarSettingsContext } from 'src/common/contexts/SidebarSettingsContext';
import { SidebarSettings } from 'src/common/types';
import { BackTitleAndActions as PureBackTitleAndActions } from 'src/v2/modules/layout';

export const BackTitleAndActions: React.FC = () => {
  const { isHidden, toggleIsHidden } = useContext<SidebarSettings>(SidebarSettingsContext);

  return <PureBackTitleAndActions onClickEnlarge={toggleIsHidden} isEnlargeActive={isHidden} />;
};
