import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { isNull } from 'lodash';

import { useNavigateToProjectEditFactory } from 'src/v2/features/project/ProjectSidebar/nav-hooks';
import { getProjectByIdFn } from '../../store/projectSelectors';
import { ProjectMetadataView } from '../../components/ProjectMetadataView';

interface Props {
  id: string;
}

export const ViewProject: FC<Props> = ({ id }) => {
  const createShowProjectEditHandler = useNavigateToProjectEditFactory();
  const getProjectById = useSelector(getProjectByIdFn);
  const project = getProjectById(id);

  if (isNull(project)) return null;

  return (
    <ProjectMetadataView
      title={project.title}
      description={project.description}
      isAllowedToEdit={/* canEditProjectMetadata(project) */ true}
      onEdit={createShowProjectEditHandler({ id })}
    />
  );
};
