// Core
import React, { ReactNode } from 'react';
import ReactModal from 'react-modal';

// Instruments
import styles from './Modal.module.css';

const timeout = 300;

interface ModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  children: ReactNode;
}

const Modal: React.FC<ModalProps> = ({ onRequestClose, children, ...props }) => {
  return (
    <ReactModal
      {...props}
      onRequestClose={onRequestClose}
      closeTimeoutMS={timeout}
      overlayClassName={{
        base: styles.overlayBase,
        afterOpen: styles.overlayAfterOpen,
        beforeClose: styles.overlayBeforeClose,
      }}
      className={{
        base: styles.contentBase,
        afterOpen: styles.contentAfterOpen,
        beforeClose: styles.contentBeforeClose,
      }}
    >
      <button className={styles.closeBtn} onClick={onRequestClose}>
        &times;
      </button>
      {children}
    </ReactModal>
  );
};

export { Modal };
