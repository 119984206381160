import { createSelector } from '@reduxjs/toolkit';
import { isNull } from 'lodash';

import { RootState } from 'src/app/types';
import { getObjects } from 'src/v2/features/objectsStorage/objectsStorageSlice';
import { getProfiles, getUsers } from 'src/v2/features/objectsStorage';
import { ObjectBase } from 'src/common/types';
import { getEntityById } from 'src/v2/features/sharedEntity/selectors';
import { Document, DocumentType, DocumentRole } from 'src/models/document';
import { DbRecordType } from 'src/v2/features/objectsStorage/types';

import { Counters, SearchEntityType, Author } from './searchTypes';

const getState = createSelector(
  (state: RootState) => state.search,
  (search) => search,
);

export const getList = (state: RootState): ObjectBase[] => getState(state).list;
export const getAuthorList = (state: RootState): ObjectBase[] => getState(state).authors;
export const isLoading = (state: RootState): boolean => getState(state).isLoading;
export const getError = (state: RootState): string => getState(state).error;
export const getKeyword = (state: RootState): string => getState(state).keyword;
export const getCounters = (state: RootState): Counters => getState(state).counters;
export const getSubTypes = (state: RootState): string[] => getState(state).subTypes;
export const getSeeMoreType = (state: RootState): SearchEntityType | null =>
  getState(state).seeMoreType;

export const getSearchList = createSelector(getList, getEntityById, (list, getEntityById) =>
  list
    .filter((item) => item.type === DbRecordType.Paper)
    .reduce((acc: Document[], item) => {
      const entity = getEntityById<DocumentType, DocumentRole>(item.id);
      return !isNull(entity) ? [...acc, entity] : acc;
    }, []),
);

export const getAuthors = createSelector(
  getAuthorList,
  getUsers,
  getProfiles,
  (authors, user, profile) => getObjects(authors, { user, profile }) as Author[],
);
