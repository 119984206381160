import { WrappedFieldProps } from 'redux-form';

export const PHONE_MASKS = {
  ar: '+.. . (..) ....-....',
};
export interface BaseProps extends WrappedFieldProps {
  label?: string;
  title?: string;
  disabled?: boolean;
  sx?: object;
}

export interface Props extends BaseProps {
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (number: string) => void;
  onCountryCodeChange?: (countryCode: string) => void;
}
