import React from 'react';
import { size, slice } from 'lodash';

import { CardUserIconData } from 'src/models/card';
import { decodeContentToDomainUrl } from 'src/utils/url';

const maxUsers = 3;

interface Props {
  users: CardUserIconData[];
}

export const UsersFeed: React.FC<Props> = ({ users }) => {
  const croppedUsers = size(users) > maxUsers ? slice(users, 0, maxUsers) : users;
  const usersLeft = size(users) - maxUsers;

  return (
    <div className="c-card__user--group">
      {croppedUsers.map((user) =>
        user.avatarUrl ? (
          <div className="c-card__user--photo" title={user.fullName} key={user.id}>
            <img src={decodeContentToDomainUrl(user.avatarUrl)} alt="User avatar" />
          </div>
        ) : (
          <div
            className="c-card__user--photo c-card__user--initials"
            title={user.fullName}
            key={user.id}
          >
            {user.initials}
          </div>
        ),
      )}

      {usersLeft > 0 && <div className="c-card__user--photo c-card__user--more">+{usersLeft}</div>}
    </div>
  );
};
