import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  CallEffect,
  ForkEffect,
  put,
  PutEffect,
  TakeEffect,
  takeLatest,
} from 'redux-saga/effects';
import { isNil } from 'lodash';
import { toastr } from 'react-redux-toastr';

import { deleteDocumentAttachmentApi, fetchDocumentAttachments } from 'src/api/documents';
import { ObjectBase, ObjectSerialized, MimeType } from 'src/common/types';
import { storeData, deleteData } from 'src/v2/features/objectsStorage/objectsStorageSlice';
import { ObjectsContainer } from 'src/v2/features/objectsStorage/types';
import { Attachment } from 'src/v2/features/sharedEntity/types';
import { RootState, AttachmentsListState } from 'src/app/types';

const initialState: AttachmentsListState = {
  data: [],
  isLoading: false,
  error: '',
  documentId: null,
};

const attachmentsList = createSlice({
  name: 'documentAttachmentsList',
  initialState,
  reducers: {
    fetchAttachmentsStart(state: AttachmentsListState, action: PayloadAction<string>): void {
      state.isLoading = true;
      state.error = '';
      state.documentId = action.payload;
    },

    fetchAttachmentsFailed(state: AttachmentsListState, action: PayloadAction<string>): void {
      state.isLoading = false;
      state.error = action.payload;
      state.documentId = null;
    },

    fetchAttachmentsSuccess(
      state: AttachmentsListState,
      action: PayloadAction<ObjectSerialized>,
    ): void {
      state.isLoading = false;
      state.data = ObjectSerialized.dataToBaseObjects(action.payload.data);
    },

    addAttachment(state: AttachmentsListState, action: PayloadAction<ObjectBase>): void {
      state.data.push(action.payload);
    },
  },
});

export const { fetchAttachmentsStart, fetchAttachmentsSuccess, addAttachment } =
  attachmentsList.actions;

interface DeleteAttachmentPayload {
  attachmentId: string;
  documentId: string;
}
export const fetchAttachments = createAction<string>(`documentAttachmentsList/fetchAttachments`);
export const deleteAttachment = createAction<DeleteAttachmentPayload>(
  `documentAttachmentsList/deleteAttachment`,
);

function* fetchAttachmentsSaga(
  action: PayloadAction<string>,
): Generator<PutEffect | CallEffect | TakeEffect | ForkEffect> {
  const { payload: documentId } = action;
  try {
    yield put(fetchAttachmentsStart(documentId));
    // @ts-ignore
    const files: ObjectSerialized = yield call(fetchDocumentAttachments, documentId);
    yield put(storeData(files));
    yield put(fetchAttachmentsSuccess(files));
  } catch (error) {
    console.error(error);
    toastr.error('Error', 'Oops, something went wrong!');
  }
}

function* deleteAttachmentSaga(
  action: PayloadAction<DeleteAttachmentPayload>,
): Generator<PutEffect | CallEffect | TakeEffect | ForkEffect> {
  const {
    payload: { attachmentId, documentId },
  } = action;
  try {
    yield call(deleteDocumentAttachmentApi, documentId, attachmentId);
    yield put(fetchAttachments(documentId));
    yield put(deleteData({ id: attachmentId, type: 'attachment' }));
    toastr.success('Success', 'Attachment successfully deleted');
  } catch (error) {
    console.error(error);
    toastr.error('Error', 'Oops, something went wrong!');
  }
}

export function* watchAttachmentsListSagas(): Generator {
  yield takeLatest(fetchAttachments, fetchAttachmentsSaga);
  yield takeLatest(deleteAttachment, deleteAttachmentSaga);
}

const getState = (state: RootState): AttachmentsListState => state.documentAttachmentsList;
const getAttachmentsIds = (state: RootState): ObjectBase[] => getState(state).data;
const getCurrentDocumentId = (state: RootState): string | null => getState(state).documentId;
const getAttachmentsAttributes = (state: RootState): ObjectsContainer =>
  state.objectsStorage.objects.attachment;

export const getAttachmentsList = createSelector(
  getAttachmentsIds,
  getAttachmentsAttributes,
  getCurrentDocumentId,
  (files, attributes, documentId): Attachment[] => {
    const finalDocumentId = isNil(documentId) ? '' : `${documentId}/`;
    return files.reduce<Attachment[]>((result, { id }) => {
      if (!attributes[id]) return result;

      const {
        attributes: { key, url, extension, updatedAt },
      } = attributes[id];

      let normalizedExtension = extension as string as MimeType;
      if (!Object.values(MimeType).includes(extension as string as MimeType)) {
        console.error(`Found unsupported mimetype: ${extension}`);
        normalizedExtension = MimeType.doc;
      }
      const data = {
        id,
        name: (key as string).replace(finalDocumentId, ''),
        url: url as string,
        extension: normalizedExtension,
        lastModified: new Date(updatedAt as string),
      };

      result.push(data);

      return result;
    }, []);
  },
);
export default attachmentsList.reducer;
