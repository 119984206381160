import React from 'react';
import { isUndefined } from 'lodash';

import { isContractFolder } from 'src/v2/features/quantumNavigator';
import { useGetEntityCurrentFolderId } from 'src/v2/features/sharedEntity/hooks';
import { createConvertFormPayloadToDTO } from 'src/v2/features/contract/ContractSidebar/utils';
import { createContract } from 'src/v2/features/documentList/documentListSlice';
import { createContractFromFile } from 'src/v2/features/documentSidebar/documentSidebarUpload/documentSidebarUploadStore';
import { createContractFromTemplate } from 'src/v2/features/createFromTemplate/actions';
import { AbstractCreate } from './AbstractCreate';

export const Create: React.FC = () => {
  const id = useGetEntityCurrentFolderId(isContractFolder);
  if (isUndefined(id)) return null;

  return (
    <AbstractCreate
      convertFormPayloadToDTO={createConvertFormPayloadToDTO(id)}
      createContract={createContract}
      createContractFromFile={createContractFromFile}
      createContractFromTemplate={createContractFromTemplate}
    />
  );
};
