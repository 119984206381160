import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Box, Flex, Image } from '@theme-ui/components';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { Button } from 'src/v2/components/Button';
import { UserSignature } from 'src/models/profile';
import { ContractRole } from 'src/models/contract';
import { WorkflowAction } from 'src/v2/entities/workflow';
import { getFullName } from 'src/utils/normalize';
import {
  workflowAction,
  getOwnerSections,
  getCounterpartySections,
  getCounterpartySideMembers,
  isAllowedToSign as getIsAllowedToSign,
  isReadyToSignByMe as getIsReadyToSignByMe,
  isSignedByMe as getIsSignedByMe,
  getOwnerSideMembers,
  getCounterPartySideSignatures,
  getOwnerSideSignatures,
  getCounterpartyFields,
  getOwnerFields,
} from 'src/v2/features/documentDetails/documentDetailsSlice';
import {
  isInWork as getIsInWork,
  isReadyToSign as getIsReadyToSign,
} from 'src/v2/features/documentWorkflow';
import { SignaturesList } from 'src/v2/features/documentDetails/components/SignaturesList';
import { areSidesEqual, fieldsWithSameNameMapper } from 'src/v2/features/documentDetails/utils';
import { EntitySignatureButton } from 'src/v2/features/sharedEntity/components/EntitySignatures/EntitySignatureButton';
import checkIcon from 'src/assets/editor-icons/check-icon.png';
import errorIcon from 'src/assets/editor-icons/error-icon.png';
import { PaperParticipantsAffiliationType } from 'src/v2/boundary/storageModels/paper/paperParticipant';

const styles = {
  sign: {
    mt: '24px',
  },

  signStatus: {
    alignItems: 'center',
    color: '#324353',
    fontSize: '13px',
    fontWeight: 'bold',
    mt: '14px',
  },

  signIcon: {
    width: '23px',
    height: '23px',
    mr: '8px',
  },

  signaturesList: {
    mt: '24px',
  },

  btnContainer: {
    mt: '20px',
    alignItems: 'flex-start',
  },
};

interface Props {
  negotiable: boolean;
  signatures: UserSignature[];
  type: PaperParticipantsAffiliationType;
}

export const ContractSign: React.FC<Props> = ({ negotiable, signatures, type }) => {
  const dispatch = useDispatch();
  const getSignedMembers = (role: ContractRole) =>
    [
      ContractRole.Owner,
      ContractRole.Manager,
      ContractRole.Signatory,
      ContractRole.Editor,
    ].includes(role);

  const counterpartySections = useSelector(getCounterpartySections);
  const counterpartyFields = useSelector(getCounterpartyFields);
  const counterpartySideMembers = useSelector(getCounterpartySideMembers);
  const counterPartySideSignatures = useSelector(getCounterPartySideSignatures);
  const notSignedCounterPartyMembers = counterpartySideMembers.filter(
    (counterparty) =>
      !counterPartySideSignatures.some((signature) => counterparty.userId === signature.userId) &&
      getSignedMembers(counterparty.role as ContractRole),
  );

  const ownerFields = useSelector(getOwnerFields);
  const ownerSections = useSelector(getOwnerSections);
  const ownerSideMembers = useSelector(getOwnerSideMembers);
  const ownerPartySideSignatures = useSelector(getOwnerSideSignatures);

  const notSignedOwnerMembers = ownerSideMembers.filter(
    (owner) =>
      !ownerPartySideSignatures.some((signature) => owner.userId === signature.userId) &&
      getSignedMembers(owner.role as ContractRole),
  );

  const ownerSectionsWithMappedFields = fieldsWithSameNameMapper(ownerSections, ownerFields);
  const counterpartySectionsWithMappedFields = fieldsWithSameNameMapper(
    counterpartySections,
    counterpartyFields,
  );
  const sidesMatch = areSidesEqual(
    ownerSectionsWithMappedFields,
    counterpartySectionsWithMappedFields,
  );

  const isReadyToSign = useSelector(getIsReadyToSign);
  const isAllowedToSign = useSelector(getIsAllowedToSign);
  const isSignedByMe = useSelector(getIsSignedByMe);
  const isReadyToSignByMe = useSelector(getIsReadyToSignByMe);
  const isInWork = useSelector(getIsInWork);
  const isReadyToSignActive =
    negotiable && isInWork && isAllowedToSign && sidesMatch && !isReadyToSignByMe;
  const isSignableNow = negotiable && isAllowedToSign && !isSignedByMe && isReadyToSign;

  const handleUserReadyToSign = useCallback((): void => {
    dispatch(workflowAction({ action: WorkflowAction.UserReadyToSign }));
  }, [dispatch]);

  const membersGeneratorText = (membersList, signed): string => {
    const members = membersList
      .map(({ firstName, lastName }) => getFullName(firstName, lastName))
      .join(', ');

    const suffix = membersList.length <= 1 ? 'singular' : 'plural';

    return signed
      ? i18n(translationKeys.negotiation[`signed_${suffix}`], { members })
      : i18n(translationKeys.negotiation[`notSigned_${suffix}`], { members });
  };

  return (
    <Box sx={styles.sign}>
      <Flex sx={styles.signStatus}>
        <Image sx={styles.signIcon} src={sidesMatch ? checkIcon : errorIcon} />
        {sidesMatch
          ? i18n(translationKeys.negotiation.matched)
          : i18n(translationKeys.negotiation.notMatched)}
      </Flex>

      {type === PaperParticipantsAffiliationType.owner && (
        <>
          {!isEmpty(ownerPartySideSignatures) && (
            <Flex sx={styles.signStatus}>
              <Image sx={styles.signIcon} src={checkIcon} />
              {membersGeneratorText(ownerPartySideSignatures, true)}
            </Flex>
          )}
          {!isEmpty(notSignedOwnerMembers) && (
            <Flex sx={styles.signStatus}>
              <Image sx={styles.signIcon} src={errorIcon} />
              {membersGeneratorText(notSignedOwnerMembers, false)}
            </Flex>
          )}
        </>
      )}

      {type === PaperParticipantsAffiliationType.counterparty && (
        <>
          {!isEmpty(counterPartySideSignatures) && (
            <Flex sx={styles.signStatus}>
              <Image sx={styles.signIcon} src={checkIcon} />
              {membersGeneratorText(counterPartySideSignatures, true)}
            </Flex>
          )}
          {!isEmpty(notSignedCounterPartyMembers) && (
            <Flex sx={styles.signStatus}>
              <Image sx={styles.signIcon} src={errorIcon} />
              {membersGeneratorText(notSignedCounterPartyMembers, false)}
            </Flex>
          )}
        </>
      )}

      {!isEmpty(signatures) && (
        <Box sx={styles.signaturesList}>
          <SignaturesList users={signatures} />
        </Box>
      )}

      {isSignableNow && <EntitySignatureButton />}
      {isReadyToSignActive && (
        <Flex sx={styles.btnContainer}>
          <Button onClick={handleUserReadyToSign} className="c-button c-button--green">
            {i18n(translationKeys.forms.item.readyToSign)}
          </Button>
        </Flex>
      )}
    </Box>
  );
};
