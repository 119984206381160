import React, { FC, useContext } from 'react';

import { SignaturePreview } from 'src/v2/features/signature';
import { SignaturePreviewProps } from 'src/v2/features/signature/types';

import { HankoSignatureSettingsContext } from '../../contexts/HankoSignatureSettingsContext';
import { getHankoUrlIfIncluded } from '../../utils';

export const HankoSignaturePreview: FC<SignaturePreviewProps> = ({ component }) => {
  const { includeHanko, hankoPreviewUrl, uploadedImageUrl } = useContext(
    HankoSignatureSettingsContext,
  );

  return (
    <SignaturePreview
      component={component}
      stampUrl={getHankoUrlIfIncluded(includeHanko, hankoPreviewUrl || uploadedImageUrl)}
    />
  );
};
