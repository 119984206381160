import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { QuantumNavigatorState } from 'src/app/types';
import { CreateFolderPayload, MoveFolderPayload, RenameFolderPayload } from 'src/models/folder';
import { ListItem } from 'src/models/list';
import { NodeTree } from 'src/models/node';

export const fetchFolders = createAction('quantumNavigator/fetchFolders');
export const createFolder = createAction<CreateFolderPayload>('quantumNavigator/createFolder');
export const renameFolder = createAction<RenameFolderPayload>('quantumNavigator/renameFolder');
export const removeFolder = createAction<string>('quantumNavigator/removeFolder');
export const moveFolder = createAction<MoveFolderPayload>('quantumNavigator/moveFolder');

const initialState: QuantumNavigatorState = {
  parentFolder: null,
  renameFolder: null,
};

const quantumNavigatorSlice = createSlice({
  name: 'quantumNavigatorSlice',
  initialState,
  reducers: {
    setParentFolderForNew: (
      state: QuantumNavigatorState,
      action: PayloadAction<NodeTree<ListItem>>,
    ) => {
      state.parentFolder = action.payload;
      state.renameFolder = null;
    },
    setFolderToRename: (
      state: QuantumNavigatorState,
      action: PayloadAction<NodeTree<ListItem>>,
    ) => {
      state.renameFolder = action.payload;
      state.parentFolder = null;
    },
    clearFoldersState: (state: QuantumNavigatorState) => {
      state.parentFolder = null;
      state.renameFolder = null;
    },
  },
});

export const quantumNavigatorReducer = quantumNavigatorSlice.reducer;
export const { setParentFolderForNew, clearFoldersState, setFolderToRename } =
  quantumNavigatorSlice.actions;
