import React, { FC, useCallback, useState, useEffect } from 'react';
// import { DndProvider } from 'react-dnd';
// import { useMediaQuery } from 'react-responsive';
// import DndBackend from 'react-dnd-html5-backend';
// import { TouchBackend } from 'react-dnd-touch-backend';
import { isNull, flow, noop } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { Button } from 'src/v2/components/Button';
import { usePrevious } from 'src/utils/hooks';

import { EditableSection } from './components/EditableSection';
import { withAsyncSections } from './hocs/withAsyncSections';
import { withSortableSections } from './hocs/withSortableSections';
import { EditableProps, AsyncSectionsProps, SortableProps } from './types';
import { contentConverter } from './contentConverter';
import { EditableSectionsContext } from './EditableSectionsContext';

type Props = EditableProps & AsyncSectionsProps & SortableProps;

const EditableCore: FC<Props> = ({
  entityId,
  sections,
  isSorting,
  isRunning,
  insertSection,
  updateSection,
  moveSection,
  removeSection,
  sortSections,
  applySorting,
  resetSorting,
  setIsSorting,
  uploadFile,
  uploadFileByUrl,
  activeLockIndex,
  lockSection = noop,
  unlockSection = noop,
  fields,
}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [initializedEditorIndex, setInitializedEditorIndex] = useState<number | null>(null);
  const [, setActiveSectionKey] = useState<string>();
  const [updatedEditableSectionsField, setUpdatedEditableSectionsField] = useState(null);
  const wasRunning = usePrevious<boolean>(isRunning);
  // const isMobile = useMediaQuery({ maxWidth: 600 });
  // const backendType = isMobile ? (TouchBackend as any) : DndBackend;

  useEffect(() => {
    // Lock section which became active while action queue was running
    if (wasRunning && !isRunning && !isNull(activeIndex)) lockSection(activeIndex);
  }, [wasRunning, isRunning, activeIndex, lockSection]);

  useEffect(() => {
    // Due to incorrect backend implementation, actual section id changes on every content update,
    // so we need a static key to prevent re-mounting of active section and losing unsaved changes
    if (!isNull(activeIndex)) {
      setInitializedEditorIndex(activeIndex);
    }
    if (!isNull(activeIndex)) setActiveSectionKey(uuidv4());
  }, [activeIndex]);

  const handleActivate = useCallback(
    (index: number) => (): void => {
      setActiveIndex(index);
      if (!isRunning) lockSection(index);
    },
    [lockSection, isRunning],
  );

  const handleDeactivate = useCallback(
    (index: number) => (): void => {
      if (activeIndex === index) setActiveIndex(null);
      if (activeLockIndex === index) unlockSection(index);
    },
    [activeIndex, activeLockIndex, unlockSection],
  );

  const handleInsert = useCallback(
    (after: number) => (): void => {
      const index = after + 1;
      insertSection({ entityId, index });
    },
    [insertSection, entityId],
  );

  const handleUpdate = useCallback(
    (index: number) =>
      (content: string): void => {
        updateSection({ entityId, index, content, fields });
      },
    [updateSection, entityId, fields],
  );

  const handleMove = useCallback(
    (source: number, target: number): void => {
      moveSection({ entityId, source, target });
    },
    [moveSection, entityId],
  );

  const handleRemove = useCallback(
    (index: number) => (): void => {
      removeSection({ entityId, index });
    },
    [removeSection, entityId],
  );

  const handleUploadFile = useCallback(
    (file: File) => uploadFile({ entityId, file }),
    [uploadFile, entityId],
  );

  const handleUploadFileByUrl = useCallback(
    (url: string) => uploadFileByUrl({ entityId, url }),
    [uploadFileByUrl, entityId],
  );

  if (!sections.length) {
    return (
      <Button onClick={handleInsert(0)} data-type="initial-term">
        Create new term
      </Button>
    );
  }

  const context = {
    updatedEditableSectionsField,
    setUpdatedEditableSectionsField,
    initializedEditorIndex,
  };

  return (
    // <DndProvider backend={backendType} options={isMobile && { enableMouseEvents: true }}>
    <EditableSectionsContext.Provider value={context}>
      {sections.map((data, index) => (
        <EditableSection
          fields={fields}
          key={data.id}
          content={contentConverter(data.text, fields)}
          index={index}
          isActive={index === activeIndex}
          isLocked={!!data.isLocked}
          isSaving={!!data.isPending}
          isSorting={isSorting}
          onActivate={handleActivate(index)}
          onDeactivate={handleDeactivate(index)}
          onInsert={handleInsert(index)}
          onChange={handleUpdate(index)}
          onMove={handleMove}
          onRemove={handleRemove(index)}
          onSort={sortSections}
          onApplySorting={applySorting}
          onResetSorting={resetSorting}
          onSetIsSorting={setIsSorting}
          onUploadFile={handleUploadFile}
          onUploadFileByUrl={handleUploadFileByUrl}
          editableFieldDisabled={false}
        />
      ))}
    </EditableSectionsContext.Provider>
    // </DndProvider>
  );
};

export const EditableSectionsV1 = flow([withAsyncSections, withSortableSections])(EditableCore);

export const EditableSections: FC<EditableProps> = ({ v2, ...props }) => (
  <EditableSectionsV1 {...props} />
);
