import React from 'react';
import { useDispatch } from 'react-redux';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { required } from 'redux-form-validators';
import { Flex, Box, Text, Link, Image, Button } from '@theme-ui/components';
import { useMediaQuery } from 'react-responsive';

import { validateEmail } from 'src/utils/validate';
import { TextField } from 'src/v2/components/TextField';

import { subscribe, SubscribeData } from './subscribeSaga';
import { termsUrl, facebookUrl, twitterUrl, linkedinUrl, instagramUrl } from './config';

import facebookIcon from 'src/assets/social/facebook.svg';
import twitterIcon from 'src/assets/social/twitter.svg';
import linkedinIcon from 'src/assets/social/linkedin.svg';
import instagramIcon from 'src/assets/social/instagram.svg';

const styles = {
  footer: {
    alignItems: 'flex-end',
    pb: '32px',
    pl: '16px',
    pr: '140px',
    height: '134px',
    backgroundColor: '#205157',
    color: '#f6c254',
    '@media (max-width: 600px)': {
      height: 'auto',
      px: '24px',
      pt: '40px',
      pb: '16px',
    },
  },
  copyright: {
    fontSize: '18px',
    lineHeight: 1.2,
    '@media (max-width: 600px)': {
      fontSize: '14px',
      textAlign: 'center',
      mt: '12px',
    },
    '@media (min-width: 1800px)': {
      fontSize: '20px',
    },
  },

  terms: {
    color: 'white',
    fontSize: '18px',
    lineHeight: 1.2,
    textDecoration: 'none',
    ml: '32px',
    '&:hover': {
      opacity: 0.9,
    },
    '@media (max-width: 600px)': {
      display: 'block',
      fontSize: '12px',
      width: 'fit-content',
      textDecoration: 'underline',
      mx: 'auto',
      mt: '36px',
    },
    '@media (min-width: 1800px)': {
      fontSize: '20px',
    },
  },

  social: {
    flex: 1,
    ml: '6%',
    pr: '16px',
    '@media (max-width: 600px)': {
      mx: 'auto',
      pr: 0,
      width: 'fit-content',
    },
  },

  caption: {
    fontSize: '24px',
    lineHeight: 1,
    mb: '14px',
    '@media (min-width: 1800px)': {
      fontSize: '30px',
    },
  },

  inlineContainer: {
    alignItems: 'flex-end',
    height: '40px',
    '@media (max-width: 600px)': {
      height: 'auto',
    },
    '@media (min-width: 1800px)': {
      mb: '-4px',
    },
  },

  socialBtn: {
    mx: '8px',
    '&:hover': {
      opacity: 0.9,
    },
    '&:first-of-type': {
      ml: 0,
    },
    '&:last-of-type': {
      mr: 0,
    },
  },

  subscribe: {
    width: '30%',
    '@media (max-width: 600px)': {
      mt: '24px',
      width: 'auto',
    },
  },

  submit: {
    flexShrink: 0,
    height: '40px',
    ml: '8px',
  },
};

const Component = ({
  handleSubmit,
  invalid,
  submitting,
  pristine,
}: InjectedFormProps<SubscribeData>): JSX.Element => {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const dispatch = useDispatch();
  const onSubmit = (data: SubscribeData): void => {
    dispatch(subscribe(data));
  };

  const copyright = <Text sx={styles.copyright}>© 2020 ShelterZoom. All rights reserved.</Text>;

  const terms = (
    <Link sx={styles.terms} href={termsUrl} target="_blank">
      Terms &amp; Conditions
    </Link>
  );

  const social = (
    <Box sx={styles.social}>
      <Text sx={styles.caption}>Follow Us</Text>
      <Flex sx={styles.inlineContainer}>
        <Link sx={styles.socialBtn} href={facebookUrl} target="_blank">
          <Image src={facebookIcon} />
        </Link>

        <Link sx={styles.socialBtn} href={twitterUrl} target="_blank">
          <Image src={twitterIcon} />
        </Link>

        <Link sx={styles.socialBtn} href={linkedinUrl} target="_blank">
          <Image src={linkedinIcon} />
        </Link>

        <Link sx={styles.socialBtn} href={instagramUrl} target="_blank">
          <Image src={instagramIcon} />
        </Link>
      </Flex>
    </Box>
  );

  const subscribeForm = (
    <Box sx={styles.subscribe}>
      <Text sx={styles.caption}>Subscribe</Text>
      <Flex
        data-id="landing-form-subscribeForm"
        sx={styles.inlineContainer}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Field
          component={TextField}
          name="email"
          placeholder="Email"
          type="email"
          validate={[required(), validateEmail]}
        />
        <Button
          data-type="subscribe-submit"
          sx={styles.submit}
          variant="accent"
          disabled={invalid || submitting || pristine}
          type="submit"
        >
          Subscribe
        </Button>
      </Flex>
    </Box>
  );

  if (isMobile) {
    return (
      <Box sx={styles.footer}>
        {social}
        {subscribeForm}
        {terms}
        {copyright}
      </Box>
    );
  }

  return (
    <Flex sx={styles.footer}>
      {copyright}
      {terms}
      {social}
      {subscribeForm}
    </Flex>
  );
};

export const Footer = reduxForm<SubscribeData>({
  form: 'landing/subscribe',
})(Component);
