import React from 'react';
import { Text } from '@theme-ui/components';
import { SxStyleProp } from 'theme-ui';

import { mergeStyles } from 'src/utils/styles';

const styles = {
  error: {
    fontSize: 12,
    fontWeight: 400,
    marginLeft: 5,
    color: 'red',
    '::first-letter': {
      textTransform: 'capitalize',
    },
  },
};

type Props = {
  message: string;
  sx?: SxStyleProp;
};

const ErrorMessage: React.FC<Props> = ({ message, sx }) => (
  <Text as="strong" sx={mergeStyles(styles.error, [sx])}>
    {message}
  </Text>
);

export default ErrorMessage;
