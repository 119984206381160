import { isArray, merge } from 'lodash';

type Block = object | [object] | [object, boolean];

function isValid(block: Block): boolean {
  return !isArray(block) || (block.length === 2 ? block[1] : !!block[0]);
}

function mergeBlock(acc: object, block: Block): object {
  return isArray(block) ? merge(acc, block[0]) : merge(acc, block);
}

export function mergeStyles(...blocks: Block[]): object {
  return blocks.reduce((acc, block) => (isValid(block) ? mergeBlock(acc, block) : acc), {});
}
