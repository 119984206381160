import React from 'react';
import cn from 'classnames';
import { TextareaProps } from '../types';
import { WithValidationMessage } from '../WithValidationMessage';

const classModifiers = {
  disabled: 'c-textarea--disabled',
  readOnly: 'c-textarea--readonly',
  mb8: 'c-textarea--mb-8',
};

export const Textarea = (props: TextareaProps): JSX.Element => {
  const { errorMessage, successMessage, className, mb8 = false, ...restProps } = props;
  const { disabled, readOnly } = restProps;
  return (
    <WithValidationMessage
      className={cn(className, {
        [classModifiers.disabled]: disabled,
        [classModifiers.readOnly]: readOnly,
        [classModifiers.mb8]: mb8,
      })}
      errorMessage={errorMessage}
      successMessage={successMessage}
      classPrefix="c-textarea"
    >
      <div className="c-textarea">
        <textarea {...restProps} />
      </div>
    </WithValidationMessage>
  );
};
