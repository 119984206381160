import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getIsSidebarOpen, toggleSidebarOpen } from 'src/common/store';
import { usePrevious } from 'src/utils/hooks';

export const useOpenMobileSidebarOnViewChangeEffct = (view: string, blacklist: string[]) => {
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector(getIsSidebarOpen);
  const prevView = usePrevious<string>(view);

  const openSidebar = useCallback(() => {
    if (!isSidebarOpen) dispatch(toggleSidebarOpen());
  }, [dispatch, isSidebarOpen]);

  useEffect(() => {
    const viewHasChanged = prevView !== view;
    const isNotBlacklisted = !blacklist.includes(view);

    if (viewHasChanged && isNotBlacklisted) {
      openSidebar();
    }
  }, [prevView, view, blacklist, openSidebar]);
};
