import { CardPaperModel } from 'src/models/card';
import { TemplatePayload, TemplateRole, TemplateType } from 'src/models/template';

export type TemplateCardPaperModel = CardPaperModel<TemplateType, TemplateRole>;

export enum TemplateCreationMode {
  Form = 'form',
  File = 'file',
}

export interface CreateTemplatePayload extends TemplatePayload {
  createTemplateOption: TemplateCreationMode;
}
