import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isNull } from 'lodash';

import { ContractRole, ContractType } from 'src/models/contract';
import { RootState } from 'src/app/types';
import { getIsUpdating } from 'src/v2/features/documentParticipants/updateParticipantStore';
import { useGetEntityById } from 'src/v2/features/sharedEntity';
import { formSelector } from 'src/v2/features/entityParticipants/InviteParticipantForm';
import { ContractPermissionsFieldset } from 'src/v2/features/contract/participants/ContractPermissionsFieldset';
import {
  conditionRequiredVisible,
  getInviteRoleOptions,
  getRequiredCondition,
} from 'src/v2/features/contract/participants/utils';
import { SidebarParticipantEntity } from 'src/v2/entities/participants';
import { InviteParticipantForm } from 'src/v2/features/entityParticipants';

export interface Props {
  onClose: () => void;
  documentId: string;
  initialValues?: SidebarParticipantEntity<ContractRole>;
}

export const ContractInviteParticipantForm: FC<Props> = (props) => {
  const { documentId } = props;
  const entity = useGetEntityById<ContractType, ContractRole>(documentId);
  const myRole = !isNull(entity) ? entity.myRole : null;
  const isUpdating = useSelector(getIsUpdating);
  const roleId: ContractRole = useSelector((state: RootState) => formSelector(state, 'roleId'));
  const conditionRequired: boolean = useSelector((state: RootState) =>
    formSelector(state, 'conditionRequired'),
  );
  const isConditionRequiredVisible = conditionRequiredVisible(roleId);
  const predefinedConditionRequired = getRequiredCondition(roleId, conditionRequired);
  const roleOptions = useMemo(() => (isNull(myRole) ? [] : getInviteRoleOptions(myRole)), [myRole]);

  return (
    <InviteParticipantForm predefinedConditionRequired={predefinedConditionRequired} {...props}>
      {!isNull(myRole) && (
        <ContractPermissionsFieldset
          roleOptions={roleOptions}
          isConditionRequiredVisible={isConditionRequiredVisible}
          isUpdating={isUpdating}
        />
      )}
    </InviteParticipantForm>
  );
};
