import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  CallEffect,
  cancel,
  cancelled,
  delay,
  fork,
  ForkEffect,
  put,
  PutEffect,
  take,
  TakeEffect,
  takeEvery,
  all,
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { isUndefined } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { toastr } from 'react-redux-toastr';

import { RootState } from 'src/app/types';
import { uploadDocumentAttachmentApi } from 'src/api/documents';
import { fetchAttachments } from 'src/v2/features/document/attachmentsList/attachmentsListStore';
import {
  fileUploadReducerFactory,
  fileUploadSelectorsFactory,
  showErrorToast,
  normalizeErrorsResponse,
} from 'src/v2/features/fileUpload';
import {
  CreateUploadFileChannelPayload,
  FileUploadState,
  UploadError,
} from 'src/v2/features/fileUpload/types';

export interface UploadAttachmentFilePayload {
  documentId: string;
  file: File;
  fileId?: string;
}

const NAMESPACE = 'uploadAttachments';

export const {
  actions: {
    uploadStart,
    uploadEnd,
    uploadUpdateProgress,
    uploadFailed,
    uploadFile: uploadAttachmentFile,
    retryUploadFile: retryUploadAttachmentFile,
    cancelUploadFile: cancelUploadAttachmentFile,
  },
  reducer,
} = fileUploadReducerFactory<UploadAttachmentFilePayload>(NAMESPACE);

function* uploadAttachmentFilesSaga({
  id,
  file,
  documentId,
  retry = false,
}: {
  id: string;
  file: File;
  documentId: string;
  retry?: boolean;
}): SagaIterator {
  let channel: any;
  try {
    const { name } = file;

    yield retry
      ? put(uploadUpdateProgress({ id, progress: 0 }))
      : put(uploadStart({ id, name, documentId }));
    channel = yield call(uploadDocumentAttachmentApi, file, documentId);

    while (true) {
      const { progress = 0, err, data } = (yield take(channel)) as CreateUploadFileChannelPayload;

      if (!isUndefined(err)) {
        const errors = normalizeErrorsResponse(err);
        yield all(errors.map((error) => call(showErrorToast, error)));

        yield put(uploadFailed({ id, err: errors[0] }));
        return;
      }
      if (data) {
        toastr.success('Upload finished', `File ${file.name} was attached to the document`);
        yield put(fetchAttachments(documentId));
        yield delay(500);
        yield put(uploadEnd({ id }));
        return;
      }
      yield put(uploadUpdateProgress({ id, progress }));
    }
  } catch (error) {
    console.error(error);
    toastr.error('Error', `Oops, something went wrong!`);
  } finally {
    if (yield cancelled()) {
      if (channel) {
        channel.close();
        yield put(uploadUpdateProgress({ id, progress: 0 }));
      }
    }
  }
}

function* uploadAttachmentFilesWithRetrySaga(
  action: PayloadAction<UploadAttachmentFilePayload>,
): Generator<PutEffect | CallEffect | TakeEffect | ForkEffect> {
  const { payload } = action;
  const { file, documentId } = payload;
  const id = uuidv4();
  let task: any;
  try {
    task = yield fork(uploadAttachmentFilesSaga, { file, documentId, id });
    while (true) {
      const { payload: retryId } = (yield take(retryUploadAttachmentFile)) as PayloadAction<string>;
      if (retryId === id) {
        // @ts-ignore
        yield cancel(task);
        task = yield fork(uploadAttachmentFilesSaga, { file, documentId, id, retry: true });
      }
    }
  } catch (error) {
    console.error(error);
    toastr.error('Error', `Oops, something went wrong!`);
    yield put(uploadFailed({ id, err: UploadError.Unknown }));
  }
}

export function* watchUploadAttachmentsSagas(): Generator {
  yield takeEvery(uploadAttachmentFile, uploadAttachmentFilesWithRetrySaga);
}

const getState = (state: RootState): FileUploadState => state.uploadAttachments;
export const { getUploadsListByDocumentIdFactory } = fileUploadSelectorsFactory(getState);

export default reducer;
