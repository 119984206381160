import React, { useEffect, useMemo, useRef } from 'react';
import { Link } from '@theme-ui/components';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { useAccountRecoveryModals } from 'src/v2/features/accountRecovery/hooks';
import { PublicLayout } from 'src/v2/components/PublicLayout';
import { PublicHeader } from 'src/v2/features/layout/PublicHeader';
import { useToggle } from 'src/common/hooks/useToggle';
import { useOnClickOutside } from 'src/common/hooks/useOnClickOutside';
import { SignHeaderLinkConfigKeys, signHeaderLinks } from 'src/v2/features/layout/headerLinks';

import { LoginForm } from './LoginForm';

export const LoginPage: React.FC = () => {
  const recoveryModals = useAccountRecoveryModals();
  const headerRef = useRef(null);
  const [navIsOpen, , openNavigation, closeNavigation] = useToggle();

  useOnClickOutside(headerRef, closeNavigation);

  const renderHeader = useMemo(() => {
    return <PublicHeader ref={headerRef} onHamburgerClick={openNavigation} navIsOpen={navIsOpen} />;
  }, [openNavigation, navIsOpen]);

  useEffect(() => {
    recoveryModals.detect();
  }, [recoveryModals]);

  return (
    <PublicLayout header={renderHeader} mainClassName="l-main--sign-in">
      <div className="l-container">
        <div className="l-row">
          <div className="c-title c-title--color-theme c-title--fs-64">
            {i18n(translationKeys.forms.logIn.title)}
          </div>
        </div>
        <div className="l-row l-row--mt-16">
          <div className="c-title c-title--color-theme c-title--fs-32">
            {i18n(translationKeys.forms.logIn.subTitle)}
          </div>
        </div>
        <div className="l-row">
          <LoginForm>
            <div className="c-form__row">
              <div className="c-form__column">
                <span
                  className="c-checkbox__link c-checkbox__link--white"
                  onClick={recoveryModals.openVerificationModal}
                >
                  {i18n(translationKeys.forms.logIn.forgotLink)}
                </span>
              </div>
            </div>
            <div className="c-form__row">
              <div className="c-form__column">
                {i18n(translationKeys.forms.logIn.signUpText)}
                <Link
                  className="c-checkbox__link c-checkbox__link--white"
                  data-type="signupNow-link"
                  variant="inline"
                  href={signHeaderLinks[SignHeaderLinkConfigKeys.signUp].href}
                >
                  {i18n(translationKeys.forms.logIn.signUpLink)}
                </Link>
              </div>
            </div>
          </LoginForm>
        </div>
      </div>
    </PublicLayout>
  );
};
