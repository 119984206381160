import { uniq } from 'lodash';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

export enum PasswordStrength {
  strong = 'strong',
  medium = 'medium',
  weak = 'weak',
}

export const scorePassword = (pass: string): number => {
  if (pass && pass.length >= 8) {
    let score = 0;
    const uniqueSymbols = uniq(pass).length;

    score += uniqueSymbols * 5;

    const variations = [/(?=^.{8,}$)/, /\d/, /[a-z]/, /[A-Z]/, /\W/].map((reg) => reg.test(pass));
    const variationCount = variations.reduce((acc, value) => acc + Number(value), 0);

    score += (variationCount - 1) * 10;

    return score;
  }

  return 0;
};

export const checkPassStrength = (pass: string): PasswordStrength => {
  const score = scorePassword(pass);
  if (score >= 80) return PasswordStrength.strong;
  if (score >= 60) return PasswordStrength.medium;

  return PasswordStrength.weak;
};

export const validatePassword = (password: string): undefined | string => {
  const passwordRegexp =
    /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)[0-9a-zA-Z!@#$%^&*()_+\-={};':"|,.<>?~ˆ]*$/g;

  if (checkPassStrength(password) === PasswordStrength.weak || !password.match(passwordRegexp)) {
    return i18n(translationKeys.errors.PASSWORD_MIN_LENGTH);
  }

  return undefined;
};
