import React, { useCallback, useRef, forwardRef, useImperativeHandle, useMemo } from 'react';
import { isUndefined } from 'lodash';

import { CKEditor, CKEditorRef } from '@ckeditor/ckeditor5-react';
import CKEditorInspector from '@ckeditor/ckeditor5-inspector';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import { defaultConfiguration } from './defaultConfiguration';
import { toolbarConfiguration } from './toolBarConfiguration';
import {
  EditorCoreProps,
  EditorCoreRef,
  EventCheckboxDiffReview,
  EventDataCheckboxChange,
  EventDataCheckboxCreate,
  EventDataCheckboxGetChecked,
  EventDataEditableFieldChange,
  EventDataEditableFieldCreate,
  EventDataEditableFieldFieldDiffBul,
  EventDataEditableFieldGetContent,
  EventDataEditableFieldSetContent,
  EventDataEditableFieldSetIsUpdating,
  EventDiffReview,
} from './EditorCoreTypes';
import './styles.css';
import { generateEditorConfig } from './utils';

export const EditorCore = forwardRef<EditorCoreRef, EditorCoreProps>((props, ref) => {
  const {
    config,
    onReady,
    isRestricted,
    showToolbar,
    onEditableFieldChange,
    onEditableFieldCreate,
    onEditableFieldGetContent,
    onEditableFieldSetContent,
    onEditableFieldSetIsUpdating,
    onCheckboxCreate,
    onCheckboxChange,
    onCheckboxGetChecked,
    onEditableFieldDiffBulk,
    onDiffReview,
    onCheckboxDiffReview,
    isDevMode,
    ...propsWithoutConfig
  } = props;

  const editorRef = useRef<CKEditorRef>({ editor: undefined });

  useImperativeHandle(ref, () => ({
    getFieldContent: (name) => {
      editorRef.current.editor?.execute('EditableFieldGetContent', { name });
    },
    getCheckboxChecked: (id) => {
      editorRef.current.editor?.execute('CheckboxGetContent', id);
    },
    setCheckboxChange: (id, checked) => {
      editorRef.current.editor?.execute('CheckboxChange', { checked, id });
    },
    setEnabledFieldContent: (data) => {
      const name = data.name;
      const value = data.value;
      const withoutOutEvent = data.withoutOutEvent;
      editorRef.current.editor?.execute('EditableFieldSetContent', {
        name,
        value,
        withoutOutEvent,
      });
    },

    setEnabledFieldDiff: (name, newValue, canReview) => {
      editorRef.current.editor?.execute('EditableFieldDiff', { name, newValue, canReview });
    },

    setEnabledFieldDiffBulk: (fields, canReview) => {
      editorRef.current.editor?.execute('EditableFieldDiffBulk', { fields, canReview });
    },

    setCheckboxesDiffBulk: (fields, canReview) => {
      editorRef.current.editor?.execute('CheckboxDiffBulk', { fields, canReview });
    },

    setCheckboxesEnabled: (enabled) => {
      editorRef.current.editor?.execute('CheckboxEnabled', enabled);
    },

    setEditableFieldsEnabled: (enabled) => {
      editorRef.current.editor?.execute('EditableFieldSetEnabled', enabled);
    },

    setEnabledFieldUpdating: (data) => {
      editorRef.current.editor?.execute('EditableFieldSetIsUpdating', data);
    },

    getEditorContent: () => {
      return editorRef.current.editor?.data.get();
    },
    setFocusOnEditor: () => {
      editorRef.current.editor?.focus();
    },

    destroyEditor: () => {
      return editorRef.current.editor?.destroy();
    },
  }));

  const onReadyHandler = useCallback(
    (editor: ClassicEditor) => {
      // CKEditor 5 inspector allows you to take a peek into the editor's model and view
      // data layers. Use it to debug the application and learn more about the editor.
      if (!editor) {
        return null;
      }

      if (isDevMode) {
        CKEditorInspector.attach(editor);
      }
      if (!isUndefined(onReady)) {
        onReady(editor);
      }
      if (onEditableFieldChange) {
        editor.on('EditableField.change.text', (event, data: EventDataEditableFieldChange) => {
          onEditableFieldChange(data);
        });
      }
      if (!isUndefined(onEditableFieldCreate)) {
        editor.on('EditableField.create', (event, data: EventDataEditableFieldCreate) => {
          onEditableFieldCreate(data);
        });
      }
      if (!isUndefined(onDiffReview)) {
        editor.on('EditableField.diffReview', (event, data: EventDiffReview) => {
          onDiffReview(data);
        });
      }
      if (!isUndefined(onEditableFieldGetContent)) {
        editor.on('EditableField.getContent', (event, data: EventDataEditableFieldGetContent) => {
          onEditableFieldGetContent(data);
        });
      }
      if (!isUndefined(onEditableFieldSetContent)) {
        editor.on('EditableField.setContent', (event, data: EventDataEditableFieldSetContent) => {
          onEditableFieldSetContent(data);
        });
      }
      if (!isUndefined(onEditableFieldDiffBulk)) {
        editor.on('EditableField.diffBulk', (event, data: EventDataEditableFieldFieldDiffBul) => {
          onEditableFieldDiffBulk(data);
        });
      }
      if (!isUndefined(onCheckboxDiffReview)) {
        editor.on('Checkbox.diffReview', (event, data: EventCheckboxDiffReview) => {
          onCheckboxDiffReview(data);
        });
      }
      if (!isUndefined(onEditableFieldSetIsUpdating)) {
        editor.on(
          'EditableField.setIsUpdating',
          (event, data: EventDataEditableFieldSetIsUpdating) => {
            onEditableFieldSetIsUpdating(data);
          },
        );
      }
      if (!isUndefined(onCheckboxCreate)) {
        editor.on('Checkbox.create', (event, data: EventDataCheckboxCreate) => {
          onCheckboxCreate(data);
        });
      }
      if (!isUndefined(onCheckboxChange)) {
        editor.on('Checkbox.change.checked', (event, data: EventDataCheckboxChange) => {
          onCheckboxChange(data);
        });
      }
      if (!isUndefined(onCheckboxGetChecked)) {
        editor.on('Checkbox.getChecked', (event, data: EventDataCheckboxGetChecked) => {
          onCheckboxGetChecked(data);
        });
      }
    },
    [
      isDevMode,
      onReady,
      onEditableFieldChange,
      onEditableFieldCreate,
      onDiffReview,
      onEditableFieldGetContent,
      onEditableFieldSetContent,
      onEditableFieldDiffBulk,
      onCheckboxDiffReview,
      onEditableFieldSetIsUpdating,
      onCheckboxCreate,
      onCheckboxChange,
      onCheckboxGetChecked,
    ],
  );

  const editorConfig = useMemo(
    () =>
      generateEditorConfig(
        {
          ...defaultConfiguration,
          removePlugins: [!isRestricted ? 'RestrictedEditingMode' : 'StandardEditingMode'],
          toolbar: toolbarConfiguration,
        },
        config,
      ),
    [config, isRestricted],
  );

  return (
    <CKEditor
      ref={editorRef}
      config={editorConfig}
      onReady={onReadyHandler}
      {...propsWithoutConfig}
      editor={ClassicEditor}
    />
  );
});
