import { SearchParams } from 'src/api/searchApi';
import { SearchEntityType, Author } from 'src/v2/features/search/store/searchTypes';
import { get } from 'lodash';
import { calcSortType, decode } from 'src/utils/url';
import { SortDirection, SortedFields } from 'src/models/sort';

export const getSearchUrlParams = (query: URLSearchParams): SearchParams => {
  const seeMoreParam = decodeURIComponent(query.get('seeMore') || '') as SearchEntityType;
  const seeMore =
    seeMoreParam && Object.values(SearchEntityType).includes(seeMoreParam)
      ? seeMoreParam
      : undefined;

  return {
    keyword: decode(query, 'keyword'),
    refineKeyword: decode(query, 'refineKeyword'),
    author: decode(query, 'author'),
    subType: decode(query, 'subType'),
    subSubType: decode(query, 'subType'),
    type: decode(query, 'type'),
    sortBy: decode(query, 'sortBy') || SortedFields.CreatedAt,
    sortDirection: calcSortType(
      decode(query, 'sortBy') || SortedFields.CreatedAt,
      decode(query, 'sortDirection') || SortDirection.Desc,
    ),
    seeMore,
  };
};

export const getAuthorFullName = (author: Author): string => {
  return `${get(author, 'profile[0].firstName', '')} 
          ${get(author, 'profile[0].lastName', '')}`;
};

export const makeSearchParams = (params: SearchParams): SearchParams => {
  return {
    keyword: params.keyword,
    refineKeyword: params.refineKeyword || '',
    author: params.author || '',
    subType: params.subType || '',
    type: params.type || '',
    subSubType: params.subSubType || '',
    sortBy: params.sortBy || '',
    sortDirection: calcSortType(params.sortBy || '', params.sortDirection || ''),
    seeMore: params.seeMore,
  };
};
