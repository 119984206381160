import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { useDispatch } from 'react-redux';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { Input } from 'src/v2/features/reduxForm';
import { CFormRow } from 'src/v2/components/CForm';
import { validateAuthForm } from 'src/utils/validate';
import { validatePassword } from 'src/utils/passwordStrength';
import { Password } from 'src/v2/features/reduxForm/Password';

import { login } from './store/authStore';
export interface Credentials {
  email: string;
  password: string;
}

export const Form: React.FC<InjectedFormProps<Credentials>> = ({
  handleSubmit,
  invalid,
  submitting,
  pristine,
  children,
}) => {
  const dispatch = useDispatch();

  const submitForm = (values: Credentials): void => {
    dispatch(
      login({
        email: values.email,
        password: values.password,
      }),
    );
  };

  return (
    <form
      className="c-form c-form__sign-up"
      data-id="auth-form-login"
      onSubmit={handleSubmit(submitForm)}
      noValidate
    >
      <CFormRow>
        <div className="c-form__column">
          <Field
            name="email"
            type="email"
            placeholder={i18n(translationKeys.forms.logIn.fields.email)}
            variant="accentLogin"
            component={Input}
            required
          />
        </div>
      </CFormRow>
      <CFormRow>
        <div className="c-form__column">
          <Field
            name="password"
            type="password"
            placeholder={i18n(translationKeys.forms.logIn.fields.password)}
            component={Password}
            validate={validatePassword}
          />
        </div>
      </CFormRow>
      <CFormRow>
        <div className="c-form__column">
          <button
            className="c-button c-button--uppercase c-button--fs-12 h-uppercase"
            data-type="login-submit"
            type="submit"
            disabled={invalid || submitting || pristine}
          >
            {i18n(translationKeys.forms.logIn.logIn)}
          </button>
        </div>
      </CFormRow>
      {children}
    </form>
  );
};

export const LoginForm = reduxForm<Credentials>({
  form: 'login',
  validate: validateAuthForm,
})(Form);
