import { sidebarControllerFactory } from 'src/v2/features/sidebarController';
import { RemoveFolder } from 'src/v2/features/sharedEntity/EntitySidebar/pages/RemoveFolder';

import { book } from './book';

import { Create } from './pages/Create';
import { Edit } from './pages/Edit';
import { Remove } from './pages/Remove';
import { View } from './pages/View';
import { RemoveParticipant } from './pages/RemoveParticipant';
import { RemoveTemplateSection } from './pages/RemoveTemplateSection';

export const sidebarName = 'templateSidebar';

export const sidebarController = sidebarControllerFactory(sidebarName, [
  {
    path: book.create,
    render: Create,
  },
  {
    path: book.edit,
    render: Edit,
  },
  {
    path: book.remove,
    render: Remove,
  },
  {
    path: book.view,
    render: View,
  },
  {
    path: book.removeSection,
    render: RemoveTemplateSection,
  },
  {
    path: book.removeParticipant,
    render: RemoveParticipant,
  },
  {
    path: book.removeFolder,
    render: RemoveFolder,
  },
]);
