import { FC } from 'react';
import {
  useGetClickOnRootListener,
  useGetClickOnRootListenerCapture,
} from 'src/common/systemEventBus';

export const shouldClose = { value: false };

export const ClickOutsideWrapper: FC = ({ children }) => {
  const onClickOutside = useGetClickOnRootListener(shouldClose);
  const onClickOutsideCapture = useGetClickOnRootListenerCapture(shouldClose);

  return (
    <span onClick={onClickOutside} onClickCapture={onClickOutsideCapture}>
      {children}
    </span>
  );
};
