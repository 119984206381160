import { PropsWithChildren } from 'react';

import { DropdownDownload } from 'src/v2/icons/DropdownDownload';
import { CardData } from 'src/v2/features/sharedEntity/types';
import { ContextMenu, ContextMenuItem, ContextMenuSeparator } from 'src/v2/components/contextMenu';
import { ContextMenuProps } from 'src/v2/features/contextMenu/types';
import { useGetEntityById } from 'src/v2/features/sharedEntity/index';
import { useDownloadAsFile } from 'src/v2/features/sharedEntity/hooks';

export const EntityProjectContextMenu = <R,>({
  closeOnClick,
  position,
  payload,
  children,
}: PropsWithChildren<ContextMenuProps>) => {
  const { id } = payload as CardData<R>;
  const entity = useGetEntityById(id);
  const handleOnDownload = useDownloadAsFile(entity);

  return (
    <ContextMenu {...position}>
      {children}
      <ContextMenuSeparator />
      <ContextMenuItem
        icon={DropdownDownload}
        text="Download"
        onClick={handleOnDownload}
        data-id="download-menuButton"
      />
    </ContextMenu>
  );
};
