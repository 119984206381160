import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { find, get, head, isUndefined } from 'lodash';
import { startSubmit, setSubmitSucceeded, setSubmitFailed, stopSubmit } from 'redux-form';

import {
  fetchMyUserData,
  getUserByIds,
  setProfileData,
  setProfileAvatar,
  updatePhone,
  verifyPhone,
} from 'src/api/profileApi';
import { ObjectSerialized } from 'src/common/types';
import { storeData } from 'src/v2/features/objectsStorage/objectsStorageSlice';
import { handleErrorSaga, getTranslatedErrorFromResponse } from 'src/utils/handleErrorSaga';
import { UpdateProfilePayload } from 'src/models/account';
import { changePassword as changePasswordApi } from 'src/api/authApi';
import { setUserId, logoutAsync } from 'src/v2/features/auth';
import { AVATAR_MAX_SIZE } from 'src/v2/features/profile/constants';
import { getFileSizeInHRF } from 'src/v2/features/signUp/utils';

import { securityFormName } from '../constants';
import { ChangePasswordPayload } from '../types';
import { UpdatePhonePayload } from '../../../../models/phone';
import { getToken } from '../../../../shared/auth';
import {
  fetchProfile,
  updateProfileData,
  profileUpdateStart,
  profileUpdateFailed,
  profileUpdateSuccess,
  setProfile,
  updateProfileAvatar,
  updatePhoneNumber,
  verifyPhoneNumber,
  changePassword,
} from './profileReducer';
import { getCurrentOrganizationId } from '../../../../shared/organization';
import { prefillData } from '../../signUp/store';

const MAX_USERS_PER_REQUEST = 250;

export function* fetchUsers(userIds: string[]): SagaIterator {
  const uniqIds = Array.from(new Set(userIds));
  const len = uniqIds.length;
  const count = Math.ceil(len / MAX_USERS_PER_REQUEST);
  const parts: string[][] = [];

  for (let i = 0; i < count; i += 1) {
    parts.push(uniqIds.slice(i * MAX_USERS_PER_REQUEST, (i + 1) * MAX_USERS_PER_REQUEST));
  }

  let packs: Array<ObjectSerialized> = [];
  try {
    packs = yield all(parts.map((ids) => call(getUserByIds, ids)));
  } catch (e) {
    console.error(e);
  }

  return packs.reduce(
    /*  eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["res"] }]  */
    (res, pack) => {
      res.data = res.data.concat(pack.data);
      res.included = res.included.concat(pack.included);
      return res;
    },
    { data: [], included: [] },
  );
}

export function* fetchProfileSaga(): SagaIterator {
  try {
    yield put(profileUpdateStart());

    const response = yield call(fetchMyUserData);
    const email = get(response, 'data[0].attributes.email');
    const phone = get(response, 'data[0].attributes.phone');
    const inviteType = get(response, 'data[0].attributes.inviteType');
    const profile = find(response.included, { type: 'profile' });
    const firstName = get(profile, 'attributes.firstName');
    const lastName = get(profile, 'attributes.lastName');

    yield put(prefillData({ phone, email, firstName, lastName, inviteType }));

    const data = head(ObjectSerialized.dataToBaseObjects(response.data));
    yield put(storeData(response));

    if (!isUndefined(data)) {
      yield put(setUserId(data.id));
      yield put(setProfile(data));
      yield put(profileUpdateSuccess());
    } else {
      const error = 'No profile data';
      toastr.error('Error', error);
      yield put(profileUpdateFailed(error));
    }
    return response;
  } catch (err) {
    yield call(handleErrorSaga, err);
    const { detail } = getTranslatedErrorFromResponse(err);
    yield put(profileUpdateFailed(detail));

    return null;
  }
}

export function* updateProfileDataSaga(action: PayloadAction<UpdateProfilePayload>): SagaIterator {
  try {
    yield put(profileUpdateStart());
    yield call(setProfileData, action.payload);
    yield call(toastr.success, 'Success', 'Your personal profile is updated.');
    yield put(profileUpdateSuccess());
    yield call(fetchProfileSaga);
  } catch (error) {
    yield call(handleErrorSaga, error);
    yield put(profileUpdateFailed(error));
  }
}

export function* updateProfileAvatarSaga(action: PayloadAction<File>): SagaIterator {
  try {
    if (AVATAR_MAX_SIZE < action.payload.size) {
      yield call(
        toastr.error,
        'Avatar upload error',
        `File size is more then ${getFileSizeInHRF(AVATAR_MAX_SIZE)}`,
      );
    } else {
      yield call(setProfileAvatar, action.payload);
      yield call(toastr.success, 'Success', 'Your avatar is saved.');
      yield put(fetchProfile());
    }
  } catch (error) {
    yield call(handleErrorSaga, error);
  }
}

function* updatePhoneNumberSaga(action: PayloadAction<UpdatePhonePayload>): SagaIterator {
  const { number, captureToken } = action.payload;
  try {
    const token = yield select(getToken);
    const orgId = yield select(getCurrentOrganizationId);
    yield call(updatePhone, number, token, captureToken, orgId);
    yield call(toastr.success, 'Success', 'Phone successfully changed.');
  } catch (error) {
    yield call(handleErrorSaga, error);
  }
}

function* verifyPhoneNumberSaga(action: PayloadAction<string>): SagaIterator {
  try {
    yield call(verifyPhone, action.payload);
    yield call(toastr.success, 'Success', 'Phone successfully verified.');
  } catch (error) {
    yield call(handleErrorSaga, error);
  }
}

function* changePasswordSaga(action: PayloadAction<ChangePasswordPayload>): SagaIterator {
  try {
    const { oldPassword, newPassword } = action.payload;
    yield put(startSubmit(securityFormName));
    yield call(changePasswordApi, oldPassword, newPassword);
    yield call(toastr.success, 'Success', 'Your password has been updated, please relogin now');
    yield put(setSubmitSucceeded(securityFormName));
    yield put(stopSubmit(securityFormName));
  } catch (error) {
    yield call(toastr.error, 'Error', 'Update password request has failed');
    yield put(setSubmitFailed(securityFormName));
  } finally {
    yield put(logoutAsync());
  }
}

export function* watchProfileSagas(): SagaIterator {
  yield takeLatest(fetchProfile, fetchProfileSaga);
  yield takeLatest(updateProfileData, updateProfileDataSaga);
  yield takeLatest(updateProfileAvatar, updateProfileAvatarSaga);
  yield takeLatest(updatePhoneNumber, updatePhoneNumberSaga);
  yield takeLatest(verifyPhoneNumber, verifyPhoneNumberSaga);
  yield takeLatest(changePassword, changePasswordSaga);
}
