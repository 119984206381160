import { FC, useEffect, useRef, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import PSPDFKitWeb from 'pspdfkit';
import Instance from 'pspdfkit/dist/pspdfkit';
import { SxStyleProp } from 'theme-ui';
import { Box } from '@theme-ui/components';
import { isNil, isUndefined } from 'lodash';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { env } from 'src/env';
import { getToken } from 'src/shared/auth';
import { mergeStyles } from 'src/utils/styles';

import { PDFToolbarItems } from './types';
import {
  getFile,
  getIsPDFSigned,
  setDocumentFieldsEditable,
  disableToolbarButton,
  loadPDFWithoutAuthorization,
  loadPDFWithAuthorization,
} from './utils';

const licenseKey = env.PSPDFKIT_LICENSE_KEY;

interface Props {
  url: string;
  fileName: string;
  allowEditing?: boolean;
  allowFormFieldsEditing?: boolean;
  isSaveButtonEnabled?: boolean;
  allowSigning?: boolean;
  isWithAuthorization: boolean;
  disableAfterSigned?: boolean;
  enabledToolbarItems?: PDFToolbarItems[];
  onSave?: (file: File) => void;
  onExport?: () => void;
  onChange?: (isUserUpdate: boolean) => void;
  onBlur?: (file: File | null) => void;
  sx?: SxStyleProp;
  fileFullyLoaded?: () => void;
}

const styles = {
  pdfContainer: {
    height: 'calc(100vh - 256px)',
    overflowX: 'hidden',
    iframe: {
      width: 'calc(100% + 1px) !important',
    },
  },
};

const baseUrl = `${window.location.protocol}//${window.location.host}/`;

PSPDFKitWeb.Options.IGNORE_DOCUMENT_PERMISSIONS = true;

export const PDFContainer: FC<Props> = ({
  url,
  fileName,
  allowEditing = false,
  allowSigning = false,
  isSaveButtonEnabled = false,
  disableAfterSigned = false,
  isWithAuthorization,
  enabledToolbarItems,
  onSave,
  onChange,
  onBlur,
  onExport,
  fileFullyLoaded,
  sx,
}) => {
  const token = useSelector(getToken);
  const container = useRef<HTMLDivElement | null>(null);
  const instance = useRef<Instance | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isEditable, setIsEditable] = useState(allowEditing || allowSigning);

  const changeHandler = useCallback(
    (event: any) => {
      const isAllowOnChange = isLoaded && isEditable;
      if (isAllowOnChange && event.hasUnsavedChanges && onChange) {
        onChange(true);
      }
    },
    [isEditable, isLoaded, onChange],
  );

  const blurHandler = useCallback(() => {
    if (onBlur && isEditable && instance.current) {
      getFile(instance.current, fileName).then((file) => {
        if (!isNil(file)) {
          onBlur(file);
        }
      });
    }
  }, [fileName, isEditable, onBlur]);

  const onSaveToolbarButtonPress = useCallback(async () => {
    if (!isSaveButtonEnabled || !instance.current || isUndefined(onSave)) {
      return;
    }
    const fileSigned = await getIsPDFSigned(instance.current);
    if (disableAfterSigned && fileSigned) {
      setIsEditable(false);
    }
    const file = await getFile(instance.current, fileName);
    if (!isNil(file)) {
      onSave(file);
    }
  }, [disableAfterSigned, fileName, isSaveButtonEnabled, onSave]);

  const getToolbarItems = useCallback(() => {
    const toolbarItems = PSPDFKitWeb.defaultToolbarItems.map((button) =>
      disableToolbarButton(button, enabledToolbarItems),
    );

    // Customize "export" button (D20-3650)
    toolbarItems.push({
      type: 'custom',
      id: 'export-button',
      onPress: onExport,
      icon: '/img/download.svg',
    });

    if (onSave && isEditable) {
      toolbarItems.push({
        type: 'custom',
        id: 'save-button',
        disabled: !isSaveButtonEnabled,
        title: isSaveButtonEnabled
          ? i18n(translationKeys.buttons.save)
          : i18n(translationKeys.buttons.saved),
        onPress: onSaveToolbarButtonPress,
      });
    }

    return toolbarItems.filter((item) => item.type !== PDFToolbarItems.Export);
  }, [
    onSave,
    isEditable,
    onExport,
    enabledToolbarItems,
    isSaveButtonEnabled,
    onSaveToolbarButtonPress,
  ]);

  const checkPDFState = useCallback(
    async (instance: Instance) => {
      await instance.setViewState((v) => v.set('readOnly', !isEditable));
      if (isEditable && disableAfterSigned) {
        await setDocumentFieldsEditable(instance, allowEditing);
      }
    },
    [allowEditing, disableAfterSigned, isEditable],
  );

  const removeHandlers = useCallback(
    (pdfViewer: typeof Instance) => {
      pdfViewer.removeEventListener('document.saveStateChange', changeHandler);
      pdfViewer.contentWindow.removeEventListener('blur', blurHandler);
    },
    [blurHandler, changeHandler],
  );

  const addHandlers = useCallback(
    (pdfViewer: Instance) => {
      pdfViewer.addEventListener('document.saveStateChange', changeHandler);
      pdfViewer.contentWindow.addEventListener('blur', blurHandler);
    },
    [blurHandler, changeHandler],
  );

  const checkIfSigned = useCallback(
    async (pdfViewer: typeof Instance) => {
      if (disableAfterSigned && isEditable) {
        const fileSigned = await getIsPDFSigned(pdfViewer);
        if (fileSigned) setIsEditable(false);
      }
    },
    [disableAfterSigned, isEditable],
  );

  const loadFile = useCallback(
    async (container: HTMLElement) => {
      try {
        const loadFileCaller = isWithAuthorization
          ? loadPDFWithAuthorization
          : loadPDFWithoutAuthorization;
        instance.current = await loadFileCaller(url, PSPDFKitWeb, token, {
          container,
          licenseKey,
          baseUrl,
        });

        instance.current.setAnnotationPresets((presets) => {
          presets.text = {
            ...presets.text,
            fontSize: 12,
          };
          return presets;
        });

        setIsLoaded(true);
      } catch (error) {
        console.error(`PDF loading error:`, error);
      }
    },
    [isWithAuthorization, token, url],
  );

  useEffect(() => {
    setIsEditable(allowEditing || allowSigning);
  }, [allowEditing, allowSigning]);

  useEffect(() => {
    (async () => {
      if (!container.current) {
        return;
      }
      if (instance.current) {
        setIsLoaded(false);
        await PSPDFKitWeb.unload(instance.current || container.current);
      }

      await loadFile(container.current);
    })();
  }, [loadFile]);

  useEffect(() => {
    if (!instance.current) {
      return;
    }

    if (isLoaded) {
      if (!isUndefined(fileFullyLoaded)) {
        fileFullyLoaded();
      }
      checkIfSigned(instance.current);
      addHandlers(instance.current);
    } else {
      removeHandlers(instance.current);
    }
  }, [addHandlers, checkIfSigned, fileFullyLoaded, isLoaded, removeHandlers]);

  useEffect(() => {
    if (!instance.current || !isLoaded) {
      return;
    }

    checkPDFState(instance.current);
  }, [checkPDFState, isLoaded]);

  useEffect(() => {
    if (!instance.current || !isLoaded) {
      return;
    }

    instance.current.setToolbarItems(getToolbarItems());
  }, [checkPDFState, getToolbarItems, isLoaded]);

  const containerStyles = mergeStyles(styles.pdfContainer, [sx, !isNil(sx)]);

  return <Box ref={container} sx={containerStyles} />;
};
