import React, { FC } from 'react';

import { DropdownShare } from 'src/v2/icons/DropdownShare';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

import { SectionItem } from '../SectionItem';
import { SectionItemProps } from '../types';

export const Share: FC<SectionItemProps> = (props) => (
  <SectionItem icon={DropdownShare} label={i18n(translationKeys.nineSquareMenu.share)} {...props} />
);
