import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isUndefined } from 'lodash';

import { createProject } from 'src/v2/features/project/store/projectReducers';
import { CreateProjectFormPayload } from 'src/v2/features/project/types';
import {
  getIsLoading,
  getRootProjectsFolderId,
} from 'src/v2/features/project/store/projectSelectors';
import { ProjectMetadataCreate } from '../../components/ProjectMetadataCreate';

export const CreateProject: FC = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const projectsRootFolderId = useSelector(getRootProjectsFolderId);

  const createProjectHandler = useCallback(
    (data: CreateProjectFormPayload) => {
      if (isUndefined(projectsRootFolderId)) {
        throw Error(`Can't create project - projectsRootFolderId is undefined`);
      }
      dispatch(
        createProject({
          ...data,
          folderID: projectsRootFolderId,
        }),
      );
    },
    [dispatch, projectsRootFolderId],
  );
  return <ProjectMetadataCreate isLoading={isLoading} onSubmit={createProjectHandler} />;
};
