import React, { useCallback, useMemo } from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { connect, useSelector } from 'react-redux';
import { size } from 'lodash';

import { RootState } from 'src/app/types';
import { AccountType } from 'src/models/signUp';
import { PhoneNumber } from 'src/v2/components/PhoneNumber';
import { validateSignUpProfileForm } from 'src/utils/validate';
import { languageOptions, LanguageCode } from 'src/instruments/languages';
import { getStateList, normalizeStatesCountries, useCountryListMemo } from 'src/utils/countries';
import { getProfileFormDataFromProfile } from 'src/v2/features/profile';
import { CForm, CFormRow } from 'src/v2/components/CForm';
import { Input, Select } from 'src/v2/features/reduxForm';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

import { getProfileCountry } from './store/signUpSelectors';
import { styles } from './styles';
import { FormHeader } from './components/FormHeader';
import { profileFormName } from './constants';
import { isOrgAccount } from './utils';
import { SearchableSelect } from 'src/v2/features/reduxForm/SeachableSelect';

const headingTextByType = {
  [AccountType.Personal]: i18n(translationKeys.profile.informationTextPersonal),
  [AccountType.Business]: i18n(translationKeys.profile.informationTextBusiness),
};

export interface FormProps {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  jobTitle?: string;
  address?: string;
  language?: LanguageCode;
  country?: string;
  state?: string;
  city?: string;
  zipCode?: string;
}

export interface Props {
  accountType: AccountType;
}

export const Form: React.FC<Props & InjectedFormProps<FormProps, Props>> = ({
  handleSubmit,
  accountType,
  change,
  form,
}) => {
  const country = useSelector(getProfileCountry);
  const stateList = useMemo(() => getStateList(country), [country]);
  const statesOptions = useMemo(() => normalizeStatesCountries(stateList), [stateList]);
  const areStatesAvailable = useMemo(() => size(statesOptions), [statesOptions]);

  const handleCountryChange = useCallback(
    (countryCode: string) => {
      change('country', countryCode.toUpperCase());
    },
    [change],
  );

  const countriesOptions = useCountryListMemo('Country');

  return (
    <>
      <FormHeader
        title={i18n(translationKeys.profile.profileTitle)}
        description={headingTextByType[accountType]}
      />
      <div className="c-setup__content">
        <CForm data-id="signup-form-email-password" onSubmit={handleSubmit} noValidate>
          <CFormRow required>
            <Field
              className="c-form__column"
              name="firstName"
              type="text"
              placeholder={i18n(translationKeys.forms.info.firstName)}
              component={Input}
              required
            />
          </CFormRow>
          <CFormRow required>
            <Field
              className="c-form__column"
              name="lastName"
              type="text"
              placeholder={i18n(translationKeys.forms.info.lastName)}
              component={Input}
            />
          </CFormRow>
          <CFormRow required>
            <Field
              className="c-form__column"
              name="email"
              type="email"
              placeholder={i18n(translationKeys.forms.info.email)}
              component={Input}
              disabled
            />
          </CFormRow>
          <CFormRow required>
            <Field
              sx={{
                ...styles.formRow,
                display: 'flex',
                flexDirection: 'column-reverse',
                flex: '1',
              }}
              name="phoneNumber"
              placeholder={i18n(translationKeys.forms.info.mobile)}
              component={PhoneNumber}
              onCountryCodeChange={handleCountryChange}
              disabled
            />
          </CFormRow>
          {!isOrgAccount(accountType) && (
            <CFormRow>
              <Field
                className="c-form__column"
                name="jobTitle"
                type="text"
                placeholder={i18n(translationKeys.forms.info.job)}
                component={Input}
              />
            </CFormRow>
          )}
          <CFormRow>
            <Field
              className="c-form__column"
              name="address"
              type="text"
              placeholder={i18n(translationKeys.forms.info.address)}
              component={Input}
            />
          </CFormRow>
          <CFormRow>
            <Field
              className="c-form__column"
              name="city"
              type="text"
              placeholder={i18n(translationKeys.forms.info.city)}
              component={Input}
            />
          </CFormRow>
          <CFormRow>
            <Field
              className="c-form__column c-form__column--2"
              name="state"
              placeholder={i18n(translationKeys.forms.info.stateSelect)}
              data-id="profileState"
              component={SearchableSelect}
              options={statesOptions}
              disabled={!areStatesAvailable}
              mb8
            />
            <Field
              className="c-form__column c-form__column--2"
              name="zipCode"
              type="text"
              placeholder={i18n(translationKeys.forms.info.zipCode)}
              component={Input}
            />
          </CFormRow>
          <CFormRow>
            <Field
              className="c-form__column"
              name="country"
              placeholder={i18n(translationKeys.forms.info.countrySelect)}
              data-id="profileCountry"
              component={SearchableSelect}
              defaultValue={country}
              options={countriesOptions}
              mb8
            />
          </CFormRow>
          <CFormRow>
            <Field
              className="c-form__column"
              name="language"
              placeholder={i18n(translationKeys.forms.info.language)}
              data-id="language"
              component={Select}
              options={languageOptions}
              mb8
            />
          </CFormRow>
          <CFormRow required>{i18n(translationKeys.errors.REQUIRED)}</CFormRow>
        </CForm>
      </div>
    </>
  );
};

export const ProfileForm = reduxForm<FormProps, Props>({
  form: profileFormName,
  enableReinitialize: true,
  validate: validateSignUpProfileForm,
})(Form);

export const ProfileDataForm = connect((state: RootState) => ({
  initialValues: getProfileFormDataFromProfile(state),
}))(ProfileForm);
