import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isUndefined, isNull, find, intersectionBy, union, isArray, last, lowerCase } from 'lodash';
import { useParams } from 'react-router';
import { history } from 'src/initializeHistory';

import { Field, Section } from 'src/models/document';
import { FieldPair } from 'src/v2/features/documentDetails/types';
import { setSortDirection, setSortField } from 'src/common/sortStore';
import { useUrlManagerHash, useUrlManagerQuery } from 'src/common/hooks/urlManager';
import {
  useGetCurrentFolderId,
  useGetFoldersTreeById,
} from 'src/v2/features/quantumNavigator/hooks';
import { DocumentType, DocumentRole } from 'src/models/document';
import { CreationMode } from 'src/api/documents';
import { fetchObjects } from 'src/v2/features/objectsList/store';
import { ListItem } from 'src/models/list';
import { SortDirection, SortedFields } from 'src/models/sort';
import { getContentFields } from 'src/v2/components/Editor';
import { FieldDiff } from 'src/models/editor';
import {
  getOwnerFields,
  getOwnerSections,
  getCounterpartyFields,
  getCounterpartySections,
  hasCounterparty as getHasCounterparty,
} from 'src/v2/features/documentDetails/documentDetailsSlice';
import { ContentMetadata, EntityType, PaperModel } from 'src/models/paper';
import { getEntityById } from 'src/v2/features/sharedEntity/selectors';
import { navigateToDocumentDetailsFactory } from 'src/v2/features/document/utils';
import { downloadAsFile } from 'src/v2/features/sharedEntity/actions';
import { isProjectsFolder } from 'src/v2/features/quantumNavigator/utils';
import { isPropagateFieldsValues } from 'src/v2/components/EditorCore/utils';
import { MimeType } from 'src/common/types';
import { nbspReplacer } from 'src/utils/string';

import { showUpgradeModal } from '../billing/store';
import { useHasTemplateFeature } from '../billing';
import { subscribeForCreate } from './entityEventBus';
import { UploadedEntityExtension } from './types';

export const useGetEntityIdFromHash = (): string | undefined => {
  const [hash] = useUrlManagerHash();
  const { id } = hash;

  return id as string | undefined;
};

export const useGetEntityIdFromUrl = (): string | undefined => {
  const { id } = useParams();

  return id;
};

export const useNormalizedEntityId = (): string | undefined => {
  const { id: paramId } = useParams<{ id: string }>();
  const [hash] = useUrlManagerHash();
  const { id: hashId } = hash;
  const id = isArray(hashId) ? hashId[0] : hashId;

  return id || paramId || undefined;
};

export const useGetEntityById = <T = DocumentType, R = DocumentRole>(
  id?: string,
): PaperModel<T, R> | null => {
  const getDocumentById = useSelector(getEntityById);
  const result = !isUndefined(id) ? getDocumentById<T, R>(id) : null;
  return isNull(result) ? null : result;
};

export const useFetchEntity = (
  sortDirection: SortDirection = SortDirection.Desc,
  sortedField: SortedFields = SortedFields.Title,
  rootFolderId?: string,
): void => {
  const dispatch = useDispatch();
  const folderId = useGetCurrentFolderId() || rootFolderId;

  useEffect((): void => {
    if (!isUndefined(folderId)) {
      dispatch(
        fetchObjects({
          folderId,
          sortBy: sortedField,
          sortDirection,
        }),
      );
    }
  }, [dispatch, sortDirection, sortedField, folderId]);
};

export const useChangeUrlSortParams = (
  sortDirectionState: SortDirection = SortDirection.Desc,
  sortedFieldState: SortedFields = SortedFields.Title,
): void => {
  const dispatch = useDispatch();
  const [params] = useUrlManagerQuery();
  const { sortBy = SortedFields.Title, sortDirection = SortDirection.Desc } = params;

  useEffect(() => {
    if (sortDirection !== sortDirectionState) {
      dispatch(setSortDirection(sortDirection as SortDirection));
    }
    if (sortBy !== sortedFieldState) {
      dispatch(setSortField(sortBy as SortedFields));
    }
  }, [dispatch, sortBy, sortDirection, sortDirectionState, sortedFieldState]);
};

export const usePreventEntityWithTemplateCreation = () => {
  const dispatch = useDispatch();
  const hasTemplateFeature = useHasTemplateFeature();
  return useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.currentTarget.value === CreationMode.template && !hasTemplateFeature) {
        dispatch(showUpgradeModal());
        event.preventDefault();
      }
    },
    [dispatch, hasTemplateFeature],
  );
};

export const useShowUpgradeModal = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(showUpgradeModal()), [dispatch]);
};

export const useCreateTemplateHandler = (
  onAdd: (type: EntityType) => () => void,
  type: EntityType,
) => {
  const hasTemplateFeature = useHasTemplateFeature();
  const showUpgradeModalHandler = useShowUpgradeModal();
  return hasTemplateFeature ? onAdd(type) : showUpgradeModalHandler;
};

export const useShowDiffHandler = (onShowDiff: () => void) => {
  const hasTemplateFeature = useHasTemplateFeature();
  const showUpgradeModalHandler = useShowUpgradeModal();
  return hasTemplateFeature ? onShowDiff : showUpgradeModalHandler;
};

export const useGetRootFolderId = (predicate: (model: ListItem) => boolean) => {
  const rootTree = useGetFoldersTreeById(undefined);
  const documentRootFolder = useMemo(
    () => rootTree && rootTree.children.find((item) => predicate(item.data)),
    [rootTree, predicate],
  );

  return documentRootFolder ? documentRootFolder.data.id : '';
};

export const useIsProjectsRootFolder = () => {
  const projectsRootFolderId = useGetRootFolderId(isProjectsFolder);

  return (projectId: string) => projectId === projectsRootFolderId;
};

export const useGetEntityCurrentFolderId = (predicate: (model: ListItem) => boolean) => {
  const nestedFolderId = useGetCurrentFolderId();
  const rootFolderId = useGetRootFolderId(predicate);

  return nestedFolderId || rootFolderId;
};

export const useSubscribeForEntityCreateEffct = () => {
  const navigateToEntity = useCallback(
    (id: string, type: EntityType, folderId?: string) =>
      navigateToDocumentDetailsFactory(history)(id, type, folderId),
    [],
  );

  useEffect(() => {
    const create$ = subscribeForCreate((eventData) => {
      const { id, type, folderId } = eventData.payload;
      navigateToEntity(id, type, folderId);
    });

    return (): void => create$.unsubscribe();
  }, [navigateToEntity]);
};

export const useGetEntityFieldsDiff = () => {
  const ownerFields = useSelector(getOwnerFields);
  const counterpartyFields = useSelector(getCounterpartyFields);

  const getUnmatchingFieldPairs = (): FieldPair[] => {
    return ownerFields.reduce((acc: FieldPair[], left: Field): FieldPair[] => {
      const right = find(counterpartyFields, {
        name: left.name,
        sourceFieldID: left.sourceFieldID,
        editorFieldID: left.editorFieldID,
      });
      return right && nbspReplacer(left.value) !== nbspReplacer(right.value)
        ? [...acc, { left, right }]
        : acc;
    }, []);
  };

  const buildFieldsDiff = (fieldPairs: FieldPair[], leftSide: boolean): FieldDiff[] =>
    fieldPairs.reduce((acc: FieldDiff[], { left, right }: FieldPair) => {
      const leftUpdated = new Date(left.valueChangedAt).getTime();
      const rightUpdated = new Date(right.valueChangedAt).getTime();
      const leftIsNewer = leftUpdated > rightUpdated;
      if ((leftSide && !leftIsNewer) || (!leftSide && leftIsNewer)) {
        const newer = leftIsNewer ? left : right;
        const diff = {
          name: newer.name,
          newValue: nbspReplacer(newer.value),
          editorFieldId: newer.editorFieldID || newer.name,
        };
        return [...acc, diff];
      }
      return acc;
    }, []);

  const unmatchingFieldPairs = getUnmatchingFieldPairs();

  const ownerFieldsDiff = buildFieldsDiff(unmatchingFieldPairs, true);
  const counterpartyFieldsDiff = buildFieldsDiff(unmatchingFieldPairs, false);

  return { ownerFieldsDiff, counterpartyFieldsDiff };
};

export const useGetUnmatchingSections = () => {
  const hasCounterparty = useSelector(getHasCounterparty);
  const ownerSections = useSelector(getOwnerSections);
  const counterpartySections = useSelector(getCounterpartySections);
  const { ownerFieldsDiff, counterpartyFieldsDiff } = useGetEntityFieldsDiff();

  if (!hasCounterparty) return undefined;

  const findUnmatchingSections = (sections: Section[], diff: FieldDiff[]): number[] =>
    sections.reduce((acc: number[], section, index) => {
      const fields = getContentFields(section.text);
      const intersection = intersectionBy(
        fields,
        diff,
        isPropagateFieldsValues() ? 'name' : 'editorFieldId',
      );
      return intersection.length ? [...acc, index] : acc;
    }, []);

  return union(
    findUnmatchingSections(ownerSections, ownerFieldsDiff),
    findUnmatchingSections(counterpartySections, counterpartyFieldsDiff),
  );
};

export const usePreventOperationForDeletedEntity = (deletedId: string | null) => {
  const preventOperationForDeletedEntity = useCallback(
    (id, operation) => {
      if (isNull(deletedId) || deletedId !== id) {
        return operation;
      } else {
        return () => {};
      }
    },
    [deletedId],
  );

  return preventOperationForDeletedEntity;
};

export const isContentMetadata = (
  contentMetadata: ContentMetadata | {},
): contentMetadata is ContentMetadata => !isUndefined((contentMetadata as ContentMetadata)?.url);

export const useFile = <T = DocumentType, R = DocumentRole>(
  entity: PaperModel<T, R> | null,
): {
  url: string;
  name: string;
  extension: UploadedEntityExtension;
  type: any;
} =>
  useMemo(() => {
    if (!isNull(entity) && isContentMetadata(entity.contentMetadata)) {
      const metadata = entity.contentMetadata;
      const { url, key, type, shortUrl } = metadata;

      return {
        url,
        shortUrl,
        name: last(key.split('/')) || '',
        extension:
          (lowerCase(last(key.split('.'))) as UploadedEntityExtension) ||
          UploadedEntityExtension.png,
        type,
      };
    }

    return {
      url: '',
      shortUrl: '',
      name: '',
      extension: UploadedEntityExtension.png,
      type: MimeType.pdf,
    };
  }, [entity]);

export const useDownloadAsFile = <T = DocumentType, R = DocumentRole>(
  entity: PaperModel<T, R> | null,
) => {
  const dispatch = useDispatch();
  const { type } = useFile(entity);
  const handleOnDownload = () => {
    if (!isNull(entity)) {
      return dispatch(
        downloadAsFile({
          id: entity.id,
          name: entity.title,
          extension: type,
        }),
      );
    }
  };

  return handleOnDownload;
};
