import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ParsedQuery } from 'query-string';
import { useUrlManagerHash } from 'src/common/hooks/urlManager';

export enum NavigateStrategy {
  Replace,
  Update,
}

interface NavigateToFn {
  (args: { params?: ParsedQuery<string>; strategy?: NavigateStrategy }): () => void;
}

interface CreateNavigateToFn {
  (args: { params?: ParsedQuery<string>; strategy?: NavigateStrategy }): (
    params: ParsedQuery<string>,
  ) => () => void;
}

export const useNavigateToFn: NavigateToFn = ({
  params = {},
  strategy = NavigateStrategy.Replace,
}) => {
  const [, createHash, updateHash] = useUrlManagerHash();
  const navigate = useNavigate();

  const url = strategy === NavigateStrategy.Replace ? createHash(params) : updateHash(params);
  return useCallback(() => {
    navigate(url);
  }, [navigate, url]);
};

export const useCreateNavigateToFn: CreateNavigateToFn = ({
  params = {},
  strategy = NavigateStrategy.Replace,
}) => {
  const [, createHash, updateHash] = useUrlManagerHash();
  const navigate = useNavigate();

  return useCallback(
    (restParams: ParsedQuery<string>) => (): void => {
      const mergedParams = {
        ...params,
        ...restParams,
      };

      const url =
        strategy === NavigateStrategy.Replace ? createHash(mergedParams) : updateHash(mergedParams);
      navigate(url);
    },
    [navigate, strategy, createHash, updateHash, params],
  );
};
