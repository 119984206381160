import { isNull, isUndefined } from 'lodash';

import { Field } from 'src/models/document';
import { decodeContentToDomainUrl } from 'src/utils/url';
import { isPropagateFieldsValues } from 'src/v2/components/EditorCore/utils';

const createCheckBox = (checked: boolean, item: string): string => {
  let id;
  const parser = new DOMParser();
  const doc = parser.parseFromString(item, 'text/html');
  const checkBox = doc.body.querySelector('.checkbox');
  if (checkBox) {
    id = checkBox.getAttribute('data-id');
  }

  if (checked) {
    return `<span class="dw-checkbox dw-checkboxActive ck-widget" checked="true" id=${id} contenteditable="false"></span>`;
  }

  return `<span class="dw-checkbox ck-widget" checked="false" id=${id} contenteditable="false"></span>`;
};

const checkboxConverter = (content: string) => {
  const checkBoxes = content.match(new RegExp('<span class="checkbox" (.*?)>(.*?)</span>', 'ig'));

  if (!isNull(checkBoxes)) {
    return checkBoxes
      .map((item) => {
        if (item.includes('☑')) {
          return createCheckBox(true, item);
        }
        return createCheckBox(false, item);
      })
      .reduce((res, obj, index) => {
        const re = new RegExp(checkBoxes[index]);
        return res.replace(re, obj);
      }, content);
  }
  return content;
};

const editableFieldsConverter = (content: string) => {
  const fields = content.match(new RegExp('<span class="field"(.*?)>(.*?)</span>', 'ig'));

  if (!isNull(fields)) {
    return fields
      .map((item) =>
        item
          .replace('field', 'editable-field')
          .replace('data-id', 'data-name')
          .replace('title', 'data-enabled="true"')
          .replace(
            /([^<>]+)(?=<[^>]+>)/g,
            '<span class="restricted-editing-exception"><span class="editable-field-title">$1</span></span>',
          ),
      )
      .reduce((res, obj, index) => {
        const re = new RegExp(fields[index]);
        return res.replace(re, obj);
      }, content);
  }

  return content;
};

export const contentFieldsWithSameNameMapper = (content: string, fields?: Field[]) => {
  if (isPropagateFieldsValues() && !isUndefined(fields) && fields.length > 0) {
    const fragment = document.createElement('div');
    fragment.innerHTML = content;
    return fragment.innerHTML;
  }
  return content;
};

export const contentConverter = (content: string, fields?: Field[]) => {
  if (!isUndefined(content)) {
    const convertedContentCheckboxes = checkboxConverter(content);
    const convertedContentEditableFields = editableFieldsConverter(convertedContentCheckboxes);
    const convertedContent = decodeContentToDomainUrl(convertedContentEditableFields);

    if (!isUndefined(fields)) {
      return contentFieldsWithSameNameMapper(convertedContent, fields);
    }
    return convertedContent;
  }

  return content;
};
