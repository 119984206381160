import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { Link } from '@theme-ui/components';

import { AccountType, InviteType } from 'src/models/signUp';
import { Input as TextField } from 'src/v2/features/reduxForm/Input';
import { PhoneNumber } from 'src/v2/components/PhoneNumber';
import { validateAuthForm } from 'src/utils/validate';
import { book } from 'src/app/book';
import { normalizeNumbers } from 'src/utils/normalize';
import { Checkbox } from 'src/v2/features/reduxForm/Checkbox';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

import { useGetPlanAndPeriod, useGetRedirectToEntity } from './hooks';
import { fillBasicData, getSignupFormData, fetchSignUpInitialInfoAction } from './store';
import { SignUpPayload } from './types';
import { useIsSignupFromInvite } from './pages/hooks';
import { getStartSignupWizardStep, isOrgAccount } from './utils';

type Props = {
  accountType: AccountType;
  inviteType: InviteType | null;
};

type Values = SignUpPayload;

type SingUpFormProps = Props & InjectedFormProps<Values, Props>;

export const Form: React.FC<SingUpFormProps> = ({
  handleSubmit,
  invalid,
  submitting,
  pristine,
  accountType,
  inviteType,
  initialize,
  children,
}) => {
  const dispatch = useDispatch();
  const data = useSelector(getSignupFormData);
  const [isSignupFromInvite] = useIsSignupFromInvite();
  const [plan, period] = useGetPlanAndPeriod();
  const [entityType, entityId] = useGetRedirectToEntity();

  useEffect(() => {
    if (data.email) initialize(data);
  }, [initialize, data]);

  const submitForm = useCallback(
    (values): void => {
      dispatch(fetchSignUpInitialInfoAction({ accountType, inviteType }));
      const basicDataPayload = {
        ...values,
        phone: `+${normalizeNumbers(values.phone)}`,
        plan,
        period,
        isSignupFromInvite,
        entityType,
        entityId,
      };
      const step = getStartSignupWizardStep({
        ...basicDataPayload,
        inviteType,
      });
      dispatch(
        fillBasicData({
          ...basicDataPayload,
          step,
        }),
      );
    },
    [isSignupFromInvite, dispatch, plan, period, entityType, entityId, accountType, inviteType],
  );

  return (
    <form
      className="c-form__sign-up"
      data-id="auth-form-signup"
      // for apply styles to phone input in this form only. TODO authV2: remove id
      id="signup-form"
      onSubmit={handleSubmit(submitForm)}
      noValidate
    >
      <div className="c-form__row">
        <div className="c-form__column c-form__column--2">
          <Field
            name="firstName"
            placeholder={i18n(translationKeys.forms.signup.fields.firstName)}
            component={TextField}
            autoComplete="off"
          />
        </div>
        <div className="c-form__column c-form__column--2">
          <Field
            name="lastName"
            placeholder={i18n(translationKeys.forms.signup.fields.lastName)}
            component={TextField}
            autoComplete="off"
          />
        </div>
      </div>
      <div className="c-form__row">
        <div className="c-form__column">
          <Field
            name="email"
            type="email"
            placeholder={i18n(translationKeys.forms.signup.fields.email)}
            component={TextField}
            disabled={isSignupFromInvite}
          />
        </div>
      </div>
      <div className="c-form__row">
        <div className="c-form__column">
          <Field
            sx={{ display: 'flex', flexDirection: 'column-reverse' }}
            name="phone"
            component={PhoneNumber}
          />
        </div>
      </div>

      {isOrgAccount(accountType) && (
        <div className="c-form__row">
          <div className="c-form__column">
            <Field
              name="companyName"
              type="companyName"
              placeholder={i18n(translationKeys.forms.info.companyName)}
              autoComplete="off"
              component={TextField}
            />
          </div>
        </div>
      )}

      <div className="c-form__row">
        <div className="c-form__column">
          <div className="c-checkbox c-checkbox--theme">
            <Field
              name="terms"
              label={
                <>
                  {i18n(translationKeys.buttons.agree)}{' '}
                  <Link
                    data-type="readTerms-link"
                    href={book.termsAndConditions}
                    className="c-checkbox__link c-checkbox__link--white"
                    target="_blank"
                  >
                    {i18n(translationKeys.buttons.termsAndCondition)}
                  </Link>
                </>
              }
              component={Checkbox}
              type="checkbox"
              data-type="readTerms-agree"
            />
          </div>
        </div>
      </div>
      <div className="c-form__row">
        <div className="c-form__column">
          <button
            className="c-button c-button--uppercase c-button--fs-12 h-uppercase"
            data-type="login-submit"
            type="submit"
            disabled={invalid || submitting || pristine}
          >
            {i18n(translationKeys.buttons.signUp)}
          </button>
        </div>
      </div>
      {children}
    </form>
  );
};

export const SignUpForm = reduxForm<Values, Props>({
  form: 'signup',
  forceUnregisterOnUnmount: true,
  validate: validateAuthForm,
})(Form);
