import React, { FC } from 'react';

import { DriveActions } from 'src/v2/modules/layout';

import { SortButton } from './SortButton';

interface Props {
  plusTitle: string;
}
export const EntityListHeader: FC<Props> = ({ plusTitle }) => (
  <DriveActions plusTitle={plusTitle}>
    <SortButton />
  </DriveActions>
);
