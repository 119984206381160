import { PermissionsFieldset } from 'src/v2/features/entityParticipants/PermissionsFieldset';
import { DocumentRole } from 'src/models/document';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { SelectOptionType } from 'src/v2/components/Form/withReduxForm';
import { useCanManageParticipantAcknowledge } from 'src/v2/features/document/workflow/documentWorkflow';

interface Props {
  isConditionRequiredVisible: boolean;
  isUpdating: boolean;
  myRole: DocumentRole;
  roleOptions: SelectOptionType[];
  participantId?: string;
}
export const DocumentPermissionsFieldset = (props: Props) => {
  const { participantId } = props;
  const isCanManageParticipantAcknowledge = useCanManageParticipantAcknowledge(participantId);

  return (
    <PermissionsFieldset
      conditionLabel={i18n(translationKeys.forms.document.userRole.acknowledgeRequired)}
      isConditionRequiredDisabled={!isCanManageParticipantAcknowledge}
      {...props}
    />
  );
};
