import { FC } from 'react';
import { useSelector } from 'react-redux';

import { env } from 'src/env';
import { StaticSettings } from 'src/common/types';
import { useLogoutHook } from 'src/v2/features/auth/hooks';
import { getAvatarFromProfile } from 'src/v2/features/profile';
import { useDefaultProfilePage } from 'src/utils/hooks';
import { useSearchSubmitHook } from 'src/v2/features/search';
import { StaticSettingsContext } from 'src/common/contexts/StaticSettingsContext';
import { Navigation } from 'src/v2/features/layout';
import { getCurrentOrganizationLogo } from 'src/shared/organization';
import { getOrgInviteLogo } from 'src/v2/features/signUp/store';

const helpUrl = env.SUPPORT_WEBSITE_URI;

export const StaticSettingsContextProvider: FC = ({ children }) => {
  const [handleSearchSubmit] = useSearchSubmitHook();
  const [handleLogout] = useLogoutHook();
  const profileUrl = useDefaultProfilePage();
  const userAvatar = useSelector(getAvatarFromProfile);
  const userAvatarUrl = userAvatar ? userAvatar.uriSmall : '';

  const organizationLogo = useSelector(getCurrentOrganizationLogo);
  const inviteLogo = useSelector(getOrgInviteLogo);
  const inviteLogoUrl = inviteLogo ? inviteLogo.uriExtraLarge : '';

  const ctx: StaticSettings = {
    organizationLogo: organizationLogo || inviteLogoUrl,
    onSearchSubmit: handleSearchSubmit,
    onLogoutClick: handleLogout,
    logoUrl: '/',
    helpUrl,
    profileUrl,
    navChildren: <Navigation />,
    userAvatarUrl,
  };

  return <StaticSettingsContext.Provider value={ctx}>{children}</StaticSettingsContext.Provider>;
};
