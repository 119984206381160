import { call, put, takeLatest, ForkEffect } from 'redux-saga/effects';
import { createDocumentFromTemplateAPI } from 'src/api/documents';
import { PayloadAction } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { CreateDocumentFromTemplateDTO } from 'src/v2/boundary/requestDTO/document';
import { CreateContractFromTemplateDTO } from 'src/v2/boundary/requestDTO/contract';
import { createContractFromTemplateAPI } from 'src/api/contractsAPI';
import { createContractFromTemplate, createDocumentFromTemplate } from './actions';
import { emitCreate } from '../document';
import {
  createFromTemplateStart,
  createFromTemplateSuccess,
  createFromTemplateFailed,
} from './reducer';

function* createDocumentFromTemplateSaga(action: PayloadAction<CreateDocumentFromTemplateDTO>) {
  try {
    yield put(createFromTemplateStart());
    // @ts-ignore
    const res = yield call(createDocumentFromTemplateAPI, action.payload);
    yield put(createFromTemplateSuccess());
    emitCreate({ id: get(res, 'data[0].id'), type: get(res, 'data[0].attributes.type') });
  } catch (error) {
    yield put(createFromTemplateFailed(error.toString()));
  }
}

function* createContractFromTemplateSaga(action: PayloadAction<CreateContractFromTemplateDTO>) {
  try {
    yield put(createFromTemplateStart());
    // @ts-ignore
    const res = yield call(createContractFromTemplateAPI, action.payload);
    yield put(createFromTemplateSuccess());
    emitCreate({
      id: get(res, 'data[0].id'),
      type: get(res, 'data[0].attributes.type'),
      folderId: action.payload.folderId,
    });
  } catch (error) {
    yield put(createFromTemplateFailed(error.toString()));
  }
}

export function* watchCreateFromTemplate(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(createDocumentFromTemplate, createDocumentFromTemplateSaga);
  yield takeLatest(createContractFromTemplate, createContractFromTemplateSaga);
}
