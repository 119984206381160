import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNull } from 'lodash';

import { ModalView } from './ModalView';
import { getIsOpen, getType, getClassName, closeModal } from './store';
import { componentsMap } from './config';

export const Modal: React.FC = () => {
  const isOpen = useSelector(getIsOpen);
  const type = useSelector(getType);
  const className = useSelector(getClassName);
  const dispatch = useDispatch();
  const handleClose = useCallback(() => dispatch(closeModal()), [dispatch]);

  if (isNull(type)) return null;

  const Component = componentsMap[type];

  return (
    <ModalView className={className} isActive={isOpen} onClose={handleClose}>
      <Component />
    </ModalView>
  );
};
