import React from 'react';
import { isNull } from 'lodash';

import { IconOption, Intent } from 'src/v2/components/Card';
import { EntityCard } from 'src/v2/features/sharedEntity';
import { EntityCardProps } from 'src/v2/features/sharedEntity/types';
import { useGetTemplateById } from 'src/v2/features/template/hooks';
import { canDeleteTemplate as canDelTemplate } from 'src/v2/features/template/permissions';
import { TemplateRole } from 'src/models/template';

export const TemplateCard: React.FC<Omit<EntityCardProps<TemplateRole>, 'intent' | 'icon'>> = (
  props,
) => {
  const { id, role } = props.data;
  const template = useGetTemplateById(id);
  const canDeleteTemplate = !isNull(template) && canDelTemplate();
  let icon: IconOption = IconOption.View;
  switch (role) {
    case TemplateRole.Owner:
      icon = IconOption.UserEdit;
      break;
  }
  return (
    <EntityCard
      {...props}
      intent={Intent.YellowDark}
      canDeleteEntity={canDeleteTemplate}
      icon={icon}
    />
  );
};
