import { FC } from 'react';
import { Field } from 'redux-form';
import { required } from 'redux-form-validators';
import { isUndefined } from 'lodash';
import { useSelector } from 'react-redux';

import { Button, Type } from 'src/v2/features/button';
import { Divider } from 'src/v2/components/Divider';
import { Input, Textarea, Select } from 'src/v2/features/reduxForm';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

import { getTypeOptions, getIndustryOptions, getPaperTypeSelector } from './utils';
import { getSubTypeOptions } from '../../../metadata/utils';

export interface Props {
  label: string;
  isLoading: boolean;
  isPristine?: boolean;
  isCreating?: boolean;
  onSubmit: () => void;
  folderId: string | undefined;
}

const paperTypeSelector = getPaperTypeSelector('editMetadata');

export const TemplateMetadataForm: FC<Props> = ({
  label,
  isLoading,
  isPristine,
  isCreating,
  onSubmit,
  folderId,
  children,
}) => {
  const paperType = useSelector(paperTypeSelector);

  return (
    <div className="c-fieldset">
      <div className="c-title c-title--uppercase c-title--lh-1">
        {i18n(translationKeys.forms.item.title)}
      </div>
      <Field validate={[required()]} component={Input} name="title" />
      <Divider />
      <div className="c-title c-title--uppercase c-title--lh-1">
        {i18n(translationKeys.forms.item.description)}
      </div>
      <Field component={Textarea} name="description" />
      <Divider />
      <div className="c-title c-title--uppercase c-title--lh-1">
        {i18n(translationKeys.forms.template.create.createNewInfo)}
      </div>

      <Field
        component={Select}
        name="paperType"
        options={getTypeOptions()}
        disabled={!isCreating || !isUndefined(folderId)}
        validate={[required()]}
        mb8
      />
      <Divider />

      <Field component={Select} name="type" options={getSubTypeOptions(paperType)} mb8 />
      <Field
        component={Input}
        name="subType"
        placeholder={i18n(translationKeys.forms.template.info.subType)}
        mb8
      />

      <Field
        component={Select}
        name="industry"
        options={getIndustryOptions()}
        validate={[required()]}
        mb8
      />
      <Field
        component={Input}
        name="country"
        placeholder={i18n(translationKeys.forms.item.country)}
        mb8
      />
      <Field
        component={Input}
        name="province"
        placeholder={i18n(translationKeys.forms.item.stateProvince)}
        mb8
      />
      <Field
        component={Input}
        name="city"
        placeholder={i18n(translationKeys.forms.item.city)}
        mb8
      />

      {children}

      <Divider />
      <Button
        data-type="metadata-submit"
        type="submit"
        actionType={Type.Secondary}
        onClick={onSubmit}
        disabled={isLoading || isPristine}
        isLoading={isLoading}
      >
        {label}
      </Button>
    </div>
  );
};
