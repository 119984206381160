import React, { FC } from 'react';

export const FolderSelectorNavigatorBody: FC = ({ children }) => {
  return (
    <div className="c-library c-library--quantum">
      <div className="c-library__row">
        <div className="c-library__list">
          <div className="c-library__item c-library__item--active c-library--mb-0 c-library--pb-0 c-library--bb-0">
            <div className="c-library__dropdown c-library--pl-0">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
