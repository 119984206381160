import React, { FC } from 'react';

import { DropdownAddUser } from 'src/v2/icons/DropdownAddUser';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

import { SectionItem } from '../SectionItem';
import { SectionItemProps } from '../types';

export const AddUser: FC<SectionItemProps> = (props) => (
  <SectionItem
    icon={DropdownAddUser}
    label={i18n(translationKeys.nineSquareMenu.addUser)}
    {...props}
  />
);
