import {
  ActionCreatorWithPayload,
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { AccountRecoveryState } from 'src/app/types';
import {
  RequestOptCodeActionPayload,
  ResetPasswordActionPayload,
  SubmitOtpCodeActionPayload,
} from 'src/v2/boundary/actionPayload/accountRecovery';
import { openModal } from '../modal/store';
import { ModalType } from '../modal/types';

export const requestOptCode: ActionCreatorWithPayload<RequestOptCodeActionPayload> = createAction(
  'accountRecovery/requestOptCode',
);

export const submitOtpCode: ActionCreatorWithPayload<SubmitOtpCodeActionPayload> = createAction(
  'accountRecovery/submitOtpCode',
);

export const resetPasswordAsync: ActionCreatorWithPayload<ResetPasswordActionPayload> =
  createAction('accountRecovery/resetPasswordAsync');

const wrapperClassName = 'c-modal--w-500';

const initialState: AccountRecoveryState = { message: '' };

const accountRecoverySlice = createSlice({
  name: 'accountRecovery',
  initialState,
  reducers: {
    setMessage: (store, action: PayloadAction<string>) => {
      store.message = action.payload;
    },
  },
});

export const accountRecoveryReducer = accountRecoverySlice.reducer;
export const { setMessage } = accountRecoverySlice.actions;

export const openRecoveryModal = {
  verification: () =>
    openModal({
      type: ModalType.AccountRecoveryStepOne,
      wrapperClassName,
    }),
  otp: () =>
    openModal({
      type: ModalType.AccountRecoveryStepTwo,
      wrapperClassName,
    }),
  resetPassword: () =>
    openModal({
      type: ModalType.AccountRecoveryStepThree,
      wrapperClassName,
    }),
  message: () =>
    openModal({
      type: ModalType.AccountRecoveryMessage,
      wrapperClassName,
    }),
};
