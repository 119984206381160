import React from 'react';
import cn from 'classnames';
import { RadioProps } from '../types';
import { WithValidationMessage } from '../WithValidationMessage';

const classModifiers = {
  inline: 'c-radio--inline',
  disabled: 'c-radio--disabled',
  mb8: 'c-radio--mb-8',
};

export const Radio = (props: RadioProps): JSX.Element => {
  const {
    inline = false,
    mb8 = false,
    className,
    label,
    image,
    errorMessage,
    successMessage,
    value,
    name,
    component,
    ...restProps
  } = props;
  const { disabled = false } = restProps;
  const rootProps = component ? restProps : {};

  return (
    <WithValidationMessage
      className={cn(className, 'c-radio', {
        [classModifiers.inline]: inline,
        [classModifiers.disabled]: disabled,
        [classModifiers.mb8]: mb8,
      })}
      errorMessage={errorMessage}
      successMessage={successMessage}
      classPrefix="c-radio"
    >
      <label className="c-radio__label" {...rootProps}>
        {component || <input type="radio" hidden name={name} value={value} {...restProps} />}
        <span className="c-radio__icon" />
        {label && <span className="c-radio__title">{label}</span>}
        {image && (
          <span className="c-radio__img">
            <img src={image} alt="Radio button" />
          </span>
        )}
      </label>
    </WithValidationMessage>
  );
};
