import { call } from '@redux-saga/core/effects';
import { AxiosError } from 'axios';
import { get } from 'lodash';
import { toastr } from 'react-redux-toastr';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

import { CommonResponseError, ErrorMessage } from './types';
import { responseErrorExtract } from './responseErrorExtract';

function getErrorTranslatedMessage(error: CommonResponseError): ErrorMessage {
  const { title, detail, errorCode } = error;
  const errorText = get(translationKeys.errors, errorCode);
  const message = i18n([errorText, detail]);

  return {
    title,
    detail: message,
  };
}

export function getTranslatedErrorFromResponse(error: AxiosError) {
  const responseError = responseErrorExtract(error);
  return getErrorTranslatedMessage(responseError);
}

function* showToast(title: string, message: string) {
  yield call(toastr.error, title, message);
}

export function* handleErrorSaga(error: AxiosError) {
  console.error(error);
  const { title, detail: message } = getTranslatedErrorFromResponse(error);

  yield call(showToast, title, message);
}
