import React from 'react';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { Document } from 'src/models/document';
import { TemplateRole } from 'src/models/template';
import {
  useCreateNavigateToTemplateDetails,
  useCreateGetTemplateInfo,
} from 'src/v2/features/template/utils';
import { TemplateCard } from 'src/v2/features/document/components/TemplateCard';
import { SearchCardGrid } from 'src/v2/components/SearchCardsGrid';
import { parseCardDataFactory } from 'src/v2/features/sharedEntity';

interface Props {
  list: Document[];
}

const parseCardData = parseCardDataFactory<TemplateRole>(i18n(translationKeys.template.noTitle));

export const TemplatesCardGrid: React.FC<Props> = ({ list }) => {
  const createHandleOpenDocument = useCreateNavigateToTemplateDetails();
  const createHandleClickFromSearch = useCreateGetTemplateInfo();

  return (
    <SearchCardGrid>
      {list.map(
        (data: Document): JSX.Element => (
          <TemplateCard
            key={data.id}
            data={parseCardData(data)}
            onClick={createHandleClickFromSearch(data.id)}
            onDoubleClick={createHandleOpenDocument(data.id)}
          />
        ),
      )}
    </SearchCardGrid>
  );
};
