import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState, SortState } from 'src/app/types';
import { SortDirection, SortedFields } from 'src/models/sort';

const sortInitialState: SortState = {
  sortDirection: SortDirection.Desc,
  sortField: SortedFields.Title,
};

const sortSlice = createSlice({
  name: 'sort',
  initialState: sortInitialState,
  reducers: {
    setSortDirection: (state: SortState, action: PayloadAction<SortDirection | null>) => {
      state.sortDirection = action.payload || sortInitialState.sortDirection;
    },
    setSortField: (state: SortState, action: PayloadAction<SortedFields | null>) => {
      state.sortField = action.payload || sortInitialState.sortField;
    },
    setDefaultSort: (state: SortState) => {
      state.sortDirection = sortInitialState.sortDirection;
      state.sortField = sortInitialState.sortField;
    },
  },
});

export const sortReducer = sortSlice.reducer;
export const { setSortDirection, setSortField, setDefaultSort } = sortSlice.actions;

const getState = (state: RootState) => state.sort;

export const getSortDirection = createSelector(getState, (state) => state.sortDirection);
export const getSortField = createSelector(getState, (state) => state.sortField);
