import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { head, isUndefined, isNull } from 'lodash';

import { defaultActionsFactoryWithData } from 'src/utils/defaultSlice';
import { ObjectsListState, RootState } from 'src/app/types';
import { FetchObjectsPayload } from 'src/v2/features/objectsList/types';
import {
  getObjects as getObjectsFromStore,
  storeData,
} from 'src/v2/features/objectsStorage/objectsStorageSlice';
import { ObjectSerialized } from 'src/common/types';
import { fetchFolderObjectsAPI } from 'src/api/foldersAPI';
import {
  getObjects,
  getCardPapers,
  getCardPaperParticipants,
  getCardPaperUsers,
} from 'src/v2/features/objectsStorage/selectors';
import { ObjectBaseModel, ObjectCompleteModel, ObjectModel } from 'src/models/object';
import { convertCardPaperCompleteToCardPaperModel } from 'src/v2/features/objectsList/utils';
import { getUserId } from 'src/shared/auth';
import { DbRecordType } from 'src/v2/features/objectsStorage/types';
import { CardPaperCompleteModel, CardPaperModel } from 'src/models/card';

const initialState: ObjectsListState = {
  isLoading: false,
  error: '',
  data: [],
};

const { onStart, onError, onSuccessWithDataSerialized } = defaultActionsFactoryWithData();

export const objectsListSlice = createSlice({
  name: 'objectsList',
  initialState,
  reducers: {
    start: onStart,
    error: onError,
    success: onSuccessWithDataSerialized,
  },
});

export const { reducer: objectsListReducer } = objectsListSlice;

export const { start, success } = objectsListSlice.actions;

export const getState = (state: RootState) => state.objectsList;

export const getList = createSelector(getState, (state) => state.data);

export const getObjectsList = createSelector(getList, getObjects, (objects, object) =>
  getObjectsFromStore<ObjectBaseModel>(objects, { object }),
);

export const getIsLoading = createSelector(getState, (state) => state.isLoading);
export const getError = createSelector(getState, (state) => state.error);

export const getCompleteObjectsListPure = createSelector(
  getList,
  getObjects,
  getCardPapers,
  getCardPaperParticipants,
  getCardPaperUsers,
  getUserId,
  (objects, object, paper, participants, users, userId) => {
    try {
      return getObjectsFromStore<ObjectCompleteModel<CardPaperCompleteModel<unknown, unknown>>>(
        objects,
        {
          object,
          [DbRecordType.CardPaper]: paper,
          [DbRecordType.CardPaperUser]: users,
          [DbRecordType.CardPaperParticipant]: participants,
        },
      )
        .map((baseObject) => {
          const paper = head(baseObject.cardPaper);

          if (isNull(userId)) {
            console.warn(`getCompleteObjectsListPure: can't normalize paper without user id`);
            return null;
          }

          if (isUndefined(paper)) {
            console.warn(`getCompleteObjectsListPure: can't normalize paper without paper`);
            return null;
          }

          try {
            const result: ObjectModel<CardPaperModel<unknown, unknown>> = {
              ...baseObject,
              cardPaper: convertCardPaperCompleteToCardPaperModel(paper, userId),
            };

            return result;
          } catch (e) {
            console.warn(e);
            return null;
          }
        })
        .filter((p): p is ObjectModel<CardPaperModel<unknown, unknown>> => !isNull(p));
    } catch (e) {
      console.warn(e);
      return [];
    }
  },
);

export const getCompleteObjectsList = <T>(state: RootState): T => {
  return getCompleteObjectsListPure(state) as unknown as T;
};

export const fetchObjects = createAction<FetchObjectsPayload>('objectsList/fetchObjects');

export function* fetchObjectsSaga(action: PayloadAction<FetchObjectsPayload>) {
  yield put(start());
  const list: ObjectSerialized = yield call(fetchFolderObjectsAPI, action.payload);
  yield put(storeData(list));
  yield put(success(list));
}

export function* watchObjectsListSagas() {
  yield takeLatest(fetchObjects, fetchObjectsSaga);
}
