import { StripeCardNumberElement, Stripe } from '@stripe/stripe-js';
import { translationKeys } from 'src/common/translations';

import { JSONApiPayload } from '../common/types';

export interface Address {
  city: string;
  country: string;
  line1: string;
  line2?: string;
  postalCode: string;
  state: string;
}

export interface CheckoutBillingInfoPayload extends BillingDetailsPayload {
  planAndPeriod: PlanAndPeriod;
  numberOfSeats?: number;
}

export enum PlanAndPeriod {
  BasicFree = 'basic_free',
  StandardMonthly = 'standard_monthly',
  StandardAnnually = 'standard_annually',
  PremiumMonthly = 'premium_monthly',
  PremiumAnnually = 'premium_annually',
  TeamsMonthly = 'teams_monthly',
  TeamsAnnually = 'teams_annually',
  BusinessMonthly = 'business_monthly',
  BusinessAnnually = 'business_annually',
  RealEstateMonthly = 'real_estate_monthly',
  RealEstateAnnually = 'real_estate_annually',
}

export interface BillingDetails {
  address: Address;
  email: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
}

export interface Card {
  brand: string;
  exp_month: number;
  exp_year: number;
  funding: string;
  last4: string;
}

export enum CardType {
  Visa = 'visa',
  MasterCard = 'mastercard',
  Maestro = 'maestro',
  AmericanExpress = 'amex',
}

export interface CardInfo {
  brand: CardType | null;
  expMonth: string;
  expYear: string;
  funding: string;
  last4: string;
}

export interface BillingInfoResponse {
  data: BillingInfoResponseData;
}

export interface CardInfoResponse {
  data: CardInfoResponseData;
}

export enum TrialExpirationNotificationState {
  None = 'none',
  ToBeShown = 'to_be_shown',
  Seen = 'seen',
}
export interface AddressResponseData {
  city: string;
  country: string;
  line1: string;
  line2?: string;
  postal_code: string;
  state: string;
}

export interface BillingDetailsResponseData {
  address: AddressResponseData;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
}

export interface BillingInfoResponseData {
  active_plan: SubscriptionPlan;
  subscribed_plan: SubscriptionPlan;
  state: SubscriptionStatus;
  billing_period: BillingPeriod;
  valid_from: Date;
  valid_to: Date;
  trial_start: Date;
  trial_end: Date;
  billing_details: BillingDetailsResponseData;
  card: Card;
  trial_expiration_popup_state: TrialExpirationNotificationState;
  latest_invoice: {
    id: string;
    external_id: string;
  } | null;
  is_cancel_at_period_end: boolean;
  seat: number;
}

export interface CardInfoResponseData {
  card: Card;
}

export interface BillingInfo {
  seat: number;
  activePlan: SubscriptionPlan;
  subscribedPlan: SubscriptionPlan | null;
  state: SubscriptionStatus;
  billingPeriod: BillingPeriod;
  validFrom: Date;
  validTo: Date;
  trialStart: Date;
  trialEnd: Date;
  billingDetails: BillingDetails;
  card: CardInfo;
  trialExpirationPopupState: TrialExpirationNotificationState;
  latestInvoiceId: string | null;
  cancelAtPeriodEnd: boolean;
}

export interface SubscriptionPrice {
  price: number;
  sales_tax: number;
  total: number;
}

export interface FetchPricingInfoPayload {
  plan: SubscriptionPlan;
  billingPeriod: BillingPeriod;
  numberOfSeats?: number;
}

export enum SubscriptionStatus {
  Active = 'active',
  Trialing = 'trialing',
  Unpaid = 'unpaid',
  Draft = 'draft',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
}

export enum SubscriptionPlan {
  Basic = 'basic',
  Premium = 'premium',
  Business = 'business',
  Teams = 'teams',
  Enterprise = 'enterprise',
  Vip = 'vip',
  Standard = 'standard',
  RealEstate = 'realestate',
}

export type UserPlan = SubscriptionPlan | null | undefined;

export enum BillingPeriod {
  None = 'none',
  Monthly = 'monthly',
  Annually = 'annual',
}

const setPlan = (subscriptionPlan: SubscriptionPlan) =>
  translationKeys.subscriptionPlanLabel.plan[subscriptionPlan];
const setPeriod = (billingPeriod: BillingPeriod) =>
  translationKeys.subscriptionPlanLabel.period[billingPeriod];

export const SubscriptionPlanLabelMap = {
  [SubscriptionPlan.Basic]: setPlan(SubscriptionPlan.Basic),
  [SubscriptionPlan.Premium]: setPlan(SubscriptionPlan.Premium),
  [SubscriptionPlan.Business]: setPlan(SubscriptionPlan.Business),
  [SubscriptionPlan.Teams]: setPlan(SubscriptionPlan.Teams),
  [SubscriptionPlan.Enterprise]: setPlan(SubscriptionPlan.Enterprise),
  [SubscriptionPlan.Vip]: setPlan(SubscriptionPlan.Vip),
  [SubscriptionPlan.Standard]: setPlan(SubscriptionPlan.Standard),
  [SubscriptionPlan.RealEstate]: setPlan(SubscriptionPlan.RealEstate),
};

export const BillingPeriodLabelMap = {
  [BillingPeriod.None]: setPeriod(BillingPeriod.None),
  [BillingPeriod.Monthly]: setPeriod(BillingPeriod.Monthly),
  [BillingPeriod.Annually]: setPeriod(BillingPeriod.Annually),
};

export interface SubscribePayload {
  cardNumberElement: StripeCardNumberElement;
  plan: SubscriptionPlan;
  period: BillingPeriod;
  numberOfSeats?: number;
  stripe: Stripe;
}

export interface BillingInfoWithSubscriptionPayload extends CheckoutBillingInfoPayload {
  cardNumberElement: StripeCardNumberElement;
  plan: SubscriptionPlan;
  period: BillingPeriod;
  numberOfSeats?: number;
  stripe: Stripe;
}

export interface BillingDetailsPayload {
  address: {
    city: string;
    country: string;
    line1: string;
    line2?: string;
    postal_code: string;
    state: string;
  };
  email: string;
  phone?: string;
  first_name: string;
  last_name: string;
}

export interface BillingDetailsWithPricingUpdatePayload extends BillingDetailsPayload {
  plan: SubscriptionPlan;
  billingPeriod: BillingPeriod;
  numberOfSeats: number;
}

export interface BillingInfoPayload extends BillingDetailsPayload {
  cardNumberElement: StripeCardNumberElement | null;
  plan: SubscriptionPlan | null;
  period: BillingPeriod | null;
  stripe: Stripe | null;
  shouldUseRetry: boolean;
}

export interface BillingPlanSubscriptionPayload {
  planAndPeriod: PlanAndPeriod;
}

export type SubmitBillingDetailsPayload = JSONApiPayload<BillingDetailsPayload>;
