import { ReplaySubject } from 'rxjs';
import { AxiosRequestConfig } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import { DataModificationPayload, ObjectSerialized } from '../types';
import { Client } from './baseClient';
import { toPromise } from './utils';

export const SelfResovedClientFactory = ($client: ReplaySubject<Client>): Client => {
  const get = function* <T = ObjectSerialized>(
    url: string,
    config: AxiosRequestConfig = {},
  ): SagaIterator<T> {
    // @ts-ignore
    const client: Client = yield toPromise<Client>($client);

    return yield call(client.get, url, config);
  };

  const post = function* <T = ObjectSerialized>(
    url: string,
    data?: DataModificationPayload | FormData | any,
    config: AxiosRequestConfig = {},
  ): SagaIterator<T> {
    // @ts-ignore
    const client: Client = yield toPromise<Client>($client);

    return yield call(client.post, url, data, config);
  };

  const uploadFile = function* <T = ObjectSerialized>(
    url: string,
    data?: DataModificationPayload | FormData | any,
    config: AxiosRequestConfig = {},
  ): SagaIterator<T> {
    // @ts-ignore
    const client: Client = yield toPromise<Client>($client);

    return yield call(client.uploadFile, url, data, config);
  };

  const put = function* <T = ObjectSerialized>(
    url: string,
    data: DataModificationPayload,
    config: AxiosRequestConfig = {},
  ): SagaIterator<T> {
    // @ts-ignore
    const client: Client = yield toPromise<Client>($client);

    return yield call(client.put, url, data, config);
  };

  const patch = function* <T = ObjectSerialized>(
    url: string,
    data: DataModificationPayload,
    config: AxiosRequestConfig = {},
  ): SagaIterator<T> {
    // @ts-ignore
    const client: Client = yield toPromise<Client>($client);

    return yield call(client.patch, url, data, config);
  };

  const deleteRequest = function* <T = ObjectSerialized>(
    url: string,
    config: AxiosRequestConfig = {},
  ): SagaIterator<T> {
    // @ts-ignore
    const client: Client = yield toPromise<Client>($client);

    return yield call(client.delete, url, config);
  };

  const download = function* (url: string, name: string): SagaIterator {
    // @ts-ignore
    const client: Client = yield toPromise<Client>($client);

    return yield call(client.download, url, name);
  };

  return {
    get,
    post,
    uploadFile,
    put,
    delete: deleteRequest,
    download,
    patch,
  };
};
