import React from 'react';
import cn from 'classnames';
import { Image } from '@theme-ui/components';

import { decodeContentToDomainUrl } from 'src/utils/url';

type Props = {
  src: string;
  borderColor?: string;
  size?: number;
  sx?: object;
} & React.HTMLAttributes<HTMLElement>;

export const Avatar = ({
  src,
  className,
  size,
  borderColor,
  sx = {},
  ...other
}: Props): JSX.Element => {
  const customClass = !size ? cn('c-setup__avatar', className) : '';

  return (
    <div className={customClass}>
      <Image
        data-type="avatar-image"
        src={decodeContentToDomainUrl(src)}
        variant="avatar"
        sx={{
          ...sx,
          width: size,
          height: size,
          borderColor,
        }}
        {...other}
      />
    </div>
  );
};
