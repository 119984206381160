import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { book } from 'src/app/book';
import { ContextMenuBook } from 'src/app/types';
import { MenuLibrary } from 'src/v2/icons/MenuLibrary';
import { ListItemViewComponentProps } from 'src/v2/modules/FsItem/types';
import { useHasTemplateFeature } from 'src/v2/features/billing';
import { useShowUpgradeModal } from 'src/v2/features/sharedEntity/hooks';

import { FolderView } from '../components/FolderView';
import { setFolderName } from '../utils';

export const TemplatesFolderView: FC<ListItemViewComponentProps> = (props) => {
  const navigate = useNavigate();
  const hasTemplateFeature = useHasTemplateFeature();
  const showUpgradeModalHandler = useShowUpgradeModal();
  const onOpenHandler = useCallback(
    (event) => {
      if (!hasTemplateFeature) {
        showUpgradeModalHandler();
        event.preventDefault();
      } else {
        navigate(book.templates.generatePath());
      }
    },
    [hasTemplateFeature, showUpgradeModalHandler, navigate],
  );
  const { isExpanded, isActive, isLoaded, children } = props;

  return (
    <FolderView
      contextMenu={ContextMenuBook.TemplateFolder}
      model={setFolderName(props.model, i18n(translationKeys.navigationMenu.templates))}
      customIcon={<MenuLibrary />}
      onToggleExpand={onOpenHandler}
      onRootClick={onOpenHandler}
      isArrowHidden
      isExpanded={isExpanded}
      isActive={isActive}
      isLoaded={isLoaded}
    >
      {children}
    </FolderView>
  );
};
