import { pull, get } from 'lodash';
import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'src/app/types';

import { ErrorBody, FilePayload, FileUploadState, UploadableFile, UploadError } from './types';

const initialState: FileUploadState = {
  files: [],
};

export enum ApiError {
  WrongExtension = 'Wrong file extension',
  MaxFileSize = 'File too large',
  InvalidCharacter = 'Invalid character',
}

const normalizeError = (error: ErrorBody): UploadError => {
  if (error.detail === ApiError.WrongExtension) return UploadError.WrongExtension;

  if (error.detail === ApiError.MaxFileSize) return UploadError.MaxFileSize;

  if (error.detail.includes(ApiError.InvalidCharacter)) return UploadError.DamagedFile;

  return UploadError.Unknown;
};

export const normalizeErrorsResponse = (e: any): UploadError[] => {
  if (!e.response) return [UploadError.NetworkError];

  const errors: ErrorBody[] = get(e, 'response.data.errors');
  if (errors) {
    return errors.map(normalizeError);
  }

  console.warn('Corrupted error response', e);
  return [];
};

export const fileUploadReducerFactory = <UploadFilePayload>(NAMESPACE: string) => {
  const getFileById = (files: UploadableFile[], fileId: string): UploadableFile | undefined =>
    files.find(({ id }) => id === fileId);

  const slice = createSlice({
    name: NAMESPACE,
    initialState,
    reducers: {
      uploadStart(
        state: FileUploadState,
        { payload: { id, name, documentId } }: PayloadAction<FilePayload>,
      ): void {
        state.files.push({
          id,
          name,
          documentId,
          progress: 0,
        });
      },

      uploadUpdateProgress(
        state: FileUploadState,
        { payload }: PayloadAction<{ id: string; progress: number }>,
      ): void {
        const { id, progress } = payload;
        const file = getFileById(state.files, id);
        if (file) file.progress = progress;
      },

      uploadEnd(state: FileUploadState, { payload }: PayloadAction<{ id: string }>): void {
        const { id: fileId } = payload;
        const file = getFileById(state.files, fileId);
        if (file) file.progress = 100;
        pull(state.files, file);
      },

      uploadFailed(
        state: FileUploadState,
        { payload }: PayloadAction<{ id: string; err: UploadError }>,
      ): void {
        const { id: fileId, err } = payload;
        const file = getFileById(state.files, fileId);
        if (file) {
          file.progress = 0;
          file.err = err;
        }
      },

      retryUploadFile(state: FileUploadState, { payload }: PayloadAction<string>): void {
        const file = getFileById(state.files, payload);
        if (file) {
          file.err = undefined;
        }
      },

      cancelUploadFile(state: FileUploadState, { payload }: PayloadAction<string>): void {
        const file = getFileById(state.files, payload);
        pull(state.files, file);
      },
    },
  });

  const uploadFile = createAction<UploadFilePayload>(`${NAMESPACE}/uploadFile`);

  const {
    uploadStart,
    uploadEnd,
    uploadUpdateProgress,
    uploadFailed,
    retryUploadFile,
    cancelUploadFile,
  } = slice.actions;

  return {
    actions: {
      uploadStart,
      uploadEnd,
      uploadUpdateProgress,
      uploadFailed,
      uploadFile,
      retryUploadFile,
      cancelUploadFile,
    },

    reducer: slice.reducer,
  };
};

export const fileUploadSelectorsFactory = (
  storeSelector: (state: RootState) => FileUploadState,
) => {
  const getUploadsList = createSelector(storeSelector, (state: FileUploadState) => state.files);

  const getUploadsListByDocumentIdFactory =
    (documentId: string) =>
    (state: RootState): UploadableFile[] => {
      const files = getUploadsList(state);
      return files.filter((file) => file.documentId === documentId);
    };

  return {
    getUploadsList,
    getUploadsListByDocumentIdFactory,
  };
};
