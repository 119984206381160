import React, { FC } from 'react';
import { Button, Type } from 'src/v2/features/button';

export interface Props {
  title: string;
  isLoading?: boolean;
  onOk: () => void;
  onCancel: () => void;
}

export const ConfirmAction: FC<Props> = ({ title, onOk, onCancel, isLoading }) => {
  return (
    <div className="c-alert c-alert--active">
      <div className="c-alert__title">{title}</div>
      <div className="c-alert__action">
        <Button
          actionType={Type.Secondary}
          isLoading={isLoading}
          onClick={onOk}
          className="c-button--w-100"
          isInline
        >
          YES
        </Button>
        <Button actionType={Type.Danger} onClick={onCancel} className="c-button--w-100" isInline>
          NO
        </Button>
      </div>
    </div>
  );
};
