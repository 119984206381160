import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import qs from 'qs';

import { book } from 'src/app/book';
import { SortDirection, SortedFields } from 'src/models/sort';

export const useSearchSubmitHook = (): [(query: string) => void] => {
  const navigate = useNavigate();
  const handleSearchSubmit = useCallback(
    (query: string) => {
      navigate(
        `${book.search.preview}/?${qs.stringify({
          keyword: query,
          sortBy: SortedFields.CreatedAt,
          sortDirection: SortDirection.Desc,
        })}`,
      );
    },
    [navigate],
  );
  return [handleSearchSubmit];
};
