import { createContext } from 'react';
import { noop } from 'lodash';

import { FileSignatureContextValue } from '../types';

export const FileSignatureContext = createContext<FileSignatureContextValue>({
  signatureId: null,
  setSignatureFile: noop,
  removeSignatureFile: noop,
});
