import axios from 'axios';
import { get, identity } from 'lodash';
import { Store } from 'redux';

import { getIsAuthenticated } from 'src/shared/auth/authSelectors';

import { forceLogoutAsync } from './store/authStore';

export const setupInterceptors = (store: Store): void => {
  axios.interceptors.response.use(identity, (error) => {
    if (get(error, 'response.status') === 401 && getIsAuthenticated(store.getState())) {
      store.dispatch(forceLogoutAsync());
      return Promise.reject(new Error('Session is expired'));
    }
    return Promise.reject(error);
  });
};
