import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { confirmation } from 'redux-form-validators';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { useQuery } from 'src/utils/hooks';
import { CForm, CFormRow } from 'src/v2/components/CForm';
import { Password } from 'src/v2/features/reduxForm/Password';
import { validateAuthForm } from 'src/utils/validate';
import { Button } from 'src/v2/features/button';
import { validatePassword } from 'src/utils/passwordStrength';

import { resetPasswordAsync } from '../qrCodeAccountRecovery/qrCodeAccountRecoveryStore';

export type Values = {
  password: string;
  confirmPassword: string;
};

interface Props {
  onLoginFlow?: () => void;
}

type AccountRecoveryStepFourFormProps = Props & InjectedFormProps<Values, Props>;

export const AccountRecoveryStepFourFormName = 'qr-code-account-recovery-formlight';

export const Form: React.FC<AccountRecoveryStepFourFormProps> = ({
  handleSubmit,
  invalid,
  submitting,
  pristine,
}) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const token = query.get('token');

  useEffect(() => {
    if (!token) {
      toastr.error('Error', 'Wrong reset password link, please click Forget Password');
    }
  }, [token]);

  const submitForm = useCallback(
    (values: Values): void => {
      const { password } = values;

      if (token) {
        dispatch(
          resetPasswordAsync({
            token,
            newPassword: password,
            formName: AccountRecoveryStepFourFormName,
          }),
        );
      }
    },
    [dispatch, token],
  );

  return (
    <div className="c-modal__row c-modal--mt-24">
      <CForm className="c-form c-form--grow" onSubmit={handleSubmit(submitForm)} noValidate>
        <CFormRow>
          <Field
            name="password"
            type="password"
            placeholder="New password"
            component={Password}
            validate={validatePassword}
          />
        </CFormRow>
        <CFormRow>
          <Field
            name="confirmPassword"
            type="password"
            placeholder="Verify password"
            component={Password}
            validate={confirmation({ field: 'password', message: 'Passwords should match' })}
          />
        </CFormRow>
        <CFormRow>
          <div className="c-form__column">
            <Button
              className="c-button c-button--fs-12 c-button--w-full h-uppercase"
              data-type="login-submit"
              type="submit"
              disabled={invalid || submitting || pristine}
            >
              {i18n(translationKeys.buttons.submit)}
            </Button>
          </div>
        </CFormRow>
      </CForm>
    </div>
  );
};

export const AccountRecoveryStepFourForm = reduxForm<Values, Props>({
  form: AccountRecoveryStepFourFormName,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateAuthForm,
})(Form);
