import React, { useContext } from 'react';
import { isArray, isNull, isString, isUndefined } from 'lodash';

import { SidebarSettingsContext } from 'src/common/contexts/SidebarSettingsContext';
import { SidebarSettings } from 'src/common/types';
import { SharedProjectSidebarContextProvider } from 'src/v2/features/project/ProjectSidebar/SharedProjectSidebarContextProvider';
import { useNormalizeURLHashValues } from 'src/v2/features/sharedEntity/EntitySidebar/EntitySidebar';
import { book } from 'src/v2/features/sharedEntity/EntitySidebar/book';

import { sidebarController, sidebarName } from './routes';
import { SidebarWrapper } from './SidebarWrapper';

export const [useIsOpen, links, pushes, SidebarComponent] = sidebarController;

export const { useOpen, useClose } = links;

export const ProjectSidebar: React.FC = () => {
  const { isHidden } = useContext<SidebarSettings>(SidebarSettingsContext);
  const criteria = useNormalizeURLHashValues(book.default);
  const { id, v: view } = criteria;
  let normalizedView: string | undefined | null = isArray(view) ? view[0] : undefined;
  if (!isNull(view) && !isArray(view)) normalizedView = view;

  if (!isString(id)) {
    if (!isUndefined(id)) console.warn(`ID is not string, ${typeof id}`, id);

    return (
      <SidebarWrapper
        sidebarName={sidebarName}
        sidebarComponent={SidebarComponent}
        isHidden={isHidden}
      />
    );
  }

  return (
    <SharedProjectSidebarContextProvider id={id} view={normalizedView}>
      <SidebarWrapper
        sidebarName={sidebarName}
        sidebarComponent={SidebarComponent}
        isHidden={isHidden}
      />
    </SharedProjectSidebarContextProvider>
  );
};
