import { isUndefined } from 'lodash';
import {
  useCreateNavigateToFn,
  useNavigateToFn,
} from 'src/v2/features/sidebarController/helpers-hooks';
import { book } from './book';

export const useNavigateToEditParticipant = (entityId?: string) =>
  useCreateNavigateToFn({
    params: { v: book.editContractParticipant, id: !isUndefined(entityId) ? entityId : null },
  });

export const useNavigateToInviteParticipant = (entityId?: string) =>
  useNavigateToFn({
    params: { v: book.inviteContractParticipant, id: !isUndefined(entityId) ? entityId : null },
  });

export const useNavigateToQRCode = (entityId?: string) =>
  useNavigateToFn({
    params: { v: book.contractQRCode, id: !isUndefined(entityId) ? entityId : null },
  });
