import { EntityModel } from 'src/models/entity';
import { AttachmentDbModel, AttachmentModel } from 'src/models/attachment';
import { MimeType } from 'src/common/types';

export interface ContentMetadata {
  key: string;
  type: MimeType;
  url: string;
  shortUrl: string;
}

export enum AccessLevel {
  Private = 'private',
  Public = 'public',
}

export enum ContentType {
  File = 'file',
  Text = 'text',
}

export interface PaperDbModel {
  createdAt: string;
  createdById: string;
  description?: string;
  id: string;
  state: string;
  subSubType?: string;
  subType: string;
  title: string;
  type: string;
  contentType: string;
  contentMetadata:
    | {
        hash: string;
        url: string;
      }
    | {};
  updatedAt: string;
  ownerConditionRequired: boolean;
}

export enum PaperState {
  draft = 'draft',
  inProgress = 'in_progress',
  completed = 'completed',
}

export enum EntityType {
  document = 'document',
  contract = 'contract',
}

export interface PaperBaseModel<T> {
  id: string;
  createdAt: string;
  createdById: string;
  description?: string;
  state: PaperState;
  subSubType?: string;
  title: string;
  type: EntityType;
  updatedAt: string;
  contentType: ContentType;
  contentMetadata: ContentMetadata | {};
  subType: T;
}

export interface PaperCompleteModel<T, R> extends PaperBaseModel<T> {
  accessLevel: AccessLevel | AccessLevel.Private;
  participants?: PaperParticipantCompleteModel<R>[];
  entity?: EntityModel;
  attachment?: AttachmentDbModel[];
}

export interface PaperModel<T, R> {
  id: string;
  createdAt: string;
  createdById: string;
  description?: string;
  state: PaperState;
  subSubType?: string;
  title: string;
  type: EntityType;
  updatedAt: string;
  contentType: ContentType;
  contentMetadata: ContentMetadata | {};
  subType: T;

  paperParticipant: PaperParticipantModel<R>[];
  myParticipant: PaperParticipantModel<R>;
  owner?: PaperUserModel;
  myRole: R;
  ownerFunctionRole?: string;
  entity: EntityModel;
  attachment: AttachmentModel[];
  accessLevel: AccessLevel | AccessLevel.Private;
}

export interface PaperParticipantCompleteModel<R> {
  roleId: R;
  userId: string;
  inviterId: string;
  funcRole: string;
  user?: PaperUserBaseModel;
  participantId: string;
}

export interface PaperParticipantModel<R> {
  roleId: R;
  userId: string;
  inviterId: string;
  funcRole: string;
  user: PaperUserModel;
  participantId: string;
}

interface PaperUserDbModel {
  avatar?: string;
  id: string;
  createdAt: string;
  email: string;
  firstName: string;
  lastName: string;
}

export type PaperUserBaseModel = PaperUserDbModel;
export type PaperUserModel = PaperUserDbModel;
