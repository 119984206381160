import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { ConfirmSection } from 'src/v2/components/ConfirmSection';
import { useNavigateToDefault } from 'src/v2/features/sharedEntity';

import { removeParticipant } from '../../reducer';

interface Props {
  id: string;
  participantId: string;
}

export const RemoveParticipant: FC<Props> = ({ id, participantId }) => {
  const dispatch = useDispatch();

  const onCancel = useNavigateToDefault(id);

  const handleRemove = useCallback(() => {
    dispatch(
      removeParticipant({
        templateId: id,
        participantId,
      }),
    );
  }, [dispatch, id, participantId]);

  return (
    <ConfirmSection message="Remove Participant?" onClose={onCancel} onConfirm={handleRemove} />
  );
};
