import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { WizardStepV2 } from 'src/models/signUp';

import { getStep } from '../store';
import { useOrganizationInformationComponent } from './organizationInformationHook';
import { useSignupFormComponent } from './signupFormHook';
import { useEmailPasswordComponent } from './emailPasswordHook';
import { useProfileDataComponent } from './profileDataHook';
import { usePhoneVerificationComponent } from './phoneVerification';
import { useCompleteComponent } from './completeHook';
import { SignUpStepComponent } from './hooks';

export const useComponentAndProps = (): SignUpStepComponent => {
  const step: WizardStepV2 = useSelector(getStep);

  useEffect(() => {
    document.getElementsByClassName('l-main')[0].scrollTo({ top: 0 });
  }, [step]);

  const hookByStep = {
    [WizardStepV2.StartPersonal]: useEmailPasswordComponent(),
    [WizardStepV2.StartBusiness]: useEmailPasswordComponent(),
    [WizardStepV2.StartInvite]: useEmailPasswordComponent(),
    [WizardStepV2.SignUp]: useSignupFormComponent(),
    [WizardStepV2.PhoneVerification]: usePhoneVerificationComponent(),
    [WizardStepV2.Profile]: useProfileDataComponent(),
    [WizardStepV2.BusinessProfile]: useOrganizationInformationComponent(),
    [WizardStepV2.Complete]: useCompleteComponent(),
  };

  return hookByStep[step];
};
