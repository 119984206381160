import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from '@theme-ui/components';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { Button } from 'src/v2/components/Button';
import { FieldDiff } from 'src/models/editor';
import { WorkflowAction } from 'src/v2/entities/workflow';
import { areSidesEqual } from 'src/v2/features/documentDetails/utils';
import {
  getOwnerSections,
  getCounterpartySections,
  getOwnerSideSignatures,
  getCounterPartySideSignatures,
  workflowAction,
  hasCounterparty as getHasCounterparty,
  isAllowedToSign as getIsAllowedToSign,
  isSignedByMe as getIsSignedByMe,
  isOwnerParty as getIsOwnerParty,
  isCounterParty as getIsCounterParty,
  isReadyToSignByMe as getIsReadyToSignByMe,
} from 'src/v2/features/documentDetails/documentDetailsSlice';
import {
  isReadyToSign as getIsReadyToSign,
  isInWork as getIsInWork,
} from 'src/v2/features/documentWorkflow';
import { mergeStyles } from 'src/utils/styles';
import { SignaturesList } from 'src/v2/features/documentDetails/components/SignaturesList';

import { EntitySignatureButton } from './EntitySignatureButton';

const styles = {
  signatureContainers: {
    flexWrap: 'wrap',
  },

  signatureContainersReverse: {
    flexDirection: 'row-reverse',
  },

  signaturesContainer: {
    flex: '0 0 100%',
    mt: '12px',
  },

  btnContainer: {
    mt: '20px',
    alignItems: 'flex-start',
  },
};

interface Props {
  ownerFieldsDiff: FieldDiff[];
  counterpartyFieldsDiff: FieldDiff[];
}

export const EntitySignatures: React.FC<Props> = ({ ownerFieldsDiff, counterpartyFieldsDiff }) => {
  const dispatch = useDispatch();
  const hasCounterparty = useSelector(getHasCounterparty);
  const isAllowedToSign = useSelector(getIsAllowedToSign);
  const isSignedByMe = useSelector(getIsSignedByMe);
  const isReadyToSign = useSelector(getIsReadyToSign);
  const isOwnerParty = useSelector(getIsOwnerParty);
  const isCounterParty = useSelector(getIsCounterParty);
  const isInWork = useSelector(getIsInWork);
  const ownerSections = useSelector(getOwnerSections);
  const counterpartySections = useSelector(getCounterpartySections);
  const isReadyToSignByMe = useSelector(getIsReadyToSignByMe);
  const isSignableNow = hasCounterparty && isAllowedToSign && !isSignedByMe && isReadyToSign;
  const isSignableForOwnerNow = isSignableNow && isOwnerParty && !ownerFieldsDiff.length;
  const isSignableForCounterpartyNow =
    isSignableNow && isCounterParty && !counterpartyFieldsDiff.length;
  const sidesMatch = areSidesEqual(ownerSections, counterpartySections);
  const isReadyToSignActive =
    isInWork && isAllowedToSign && sidesMatch && ownerSections.length > 0 && !isReadyToSignByMe;
  const ownerSideSignatures = useSelector(getOwnerSideSignatures);
  const counterPartySideSignatures = useSelector(getCounterPartySideSignatures);

  const handleUserReadyToSign = useCallback((): void => {
    dispatch(workflowAction({ action: WorkflowAction.UserReadyToSign }));
  }, [dispatch]);

  if (
    !isReadyToSignActive &&
    !ownerSideSignatures.length &&
    !counterPartySideSignatures.length &&
    !isSignableForOwnerNow &&
    !isSignableForCounterpartyNow
  )
    return null;

  return (
    <Box>
      {isReadyToSignActive && (
        <Box sx={styles.btnContainer}>
          <Button onClick={handleUserReadyToSign} className="c-button c-button--green">
            {i18n(translationKeys.forms.item.readyToSign)}
          </Button>
        </Box>
      )}
      <Flex
        sx={mergeStyles(styles.signatureContainers, [
          styles.signatureContainersReverse,
          isCounterParty,
        ])}
      >
        <Box sx={styles.signaturesContainer}>
          <SignaturesList users={ownerSideSignatures} />
          {isSignableForOwnerNow && <EntitySignatureButton />}
        </Box>
        <Box sx={styles.signaturesContainer}>
          <SignaturesList users={counterPartySideSignatures} />
          {isSignableForCounterpartyNow && <EntitySignatureButton />}
        </Box>
      </Flex>
    </Box>
  );
};
