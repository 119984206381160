import React, { FC, useCallback } from 'react';

import { ListItemViewComponentProps } from 'src/v2/modules/FsItem/types';
import { Case, Switch } from 'src/v2/components/SwitchCase';
import { useCreateOpenFolderFn } from 'src/v2/features/quantumNavigator/hooks';
import { SharedFolderContextMenuTypeContextProvider } from 'src/v2/features/quantumNavigator/SharedFolderContextMenuTypeContextProvider';
import { SharedFolderView } from 'src/v2/features/quantumNavigator/components/SharedFolderView';
import { ProjectsFolderView } from 'src/v2/features/quantumNavigator/components/ProjectsFolderView';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { useHasNavigationFeature } from 'src/v2/features/billing';

import { FolderView } from '../components/FolderView';
import { DocumentsFolderView } from '../components/DocumentsFolderView';
import { ContractsFolderView } from '../components/ContractsFolderView';
import { TemplatesFolderView } from '../components/TemplatesFolderView';
import { FolderType, getFolderTypeByModel, setFolderName } from '../utils';

export const NavigatorFolderView: FC<ListItemViewComponentProps> = (props) => {
  const { model } = props;
  const folderType = getFolderTypeByModel(model);
  const normalizeShareFolderModel = useCallback(
    (model) => setFolderName(model, i18n(translationKeys.navigationMenu.shared)),
    [],
  );
  const handleClickOnRoot = useCreateOpenFolderFn(model.data.id);
  const hasNavigationFeature = useHasNavigationFeature();

  return (
    <Switch criteria={folderType}>
      <Case value={FolderType.Documents}>
        <DocumentsFolderView {...props} />
      </Case>
      <Case value={FolderType.SharedDocuments}>
        <SharedFolderContextMenuTypeContextProvider>
          <SharedFolderView
            {...props}
            model={normalizeShareFolderModel(model)}
            onRootClick={handleClickOnRoot}
          />
        </SharedFolderContextMenuTypeContextProvider>
      </Case>
      <Case value={FolderType.Contracts}>
        <ContractsFolderView {...props} />
      </Case>
      <Case value={FolderType.SharedContracts}>
        <SharedFolderContextMenuTypeContextProvider>
          <SharedFolderView
            {...props}
            model={normalizeShareFolderModel(model)}
            onRootClick={handleClickOnRoot}
          />
        </SharedFolderContextMenuTypeContextProvider>
      </Case>
      <Case value={FolderType.Templates}>
        <TemplatesFolderView {...props} />
      </Case>
      <Case value={FolderType.SharedTemplates}>
        <SharedFolderContextMenuTypeContextProvider>
          <SharedFolderView
            {...props}
            model={normalizeShareFolderModel(model)}
            onRootClick={handleClickOnRoot}
          />
        </SharedFolderContextMenuTypeContextProvider>
      </Case>
      <Case value={FolderType.Projects}>
        {hasNavigationFeature && <ProjectsFolderView {...props} />}
      </Case>
      <Case value={FolderType.Other}>
        <FolderView {...props} onRootClick={handleClickOnRoot} />
      </Case>

      <Case value={FolderType.Root}>{props.children}</Case>
    </Switch>
  );
};
