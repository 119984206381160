import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Modal from 'react-modal';
import { PersistGate } from 'redux-persist/integration/react';
import 'symbol-observable';
import * as Sentry from '@sentry/react';

import { env } from 'src/env';
import 'src/v2/features/dwStyles/scss/_style.scss';
import { setupInterceptors } from 'src/v2/features/auth';

import './index.css';
import './fonts/fonts.css';
import './initializeDIContainers';
import App from './app/App';
import { initialize } from './initialize';
import { CustomRouter } from './initializeHistory';
import reportWebVitals from './reportWebVitals';

if (env.ENABLE_SENTRY) {
  Sentry.init({
    dsn: env.SENTRY_DSN,
  });
}

const app = document.getElementById('root');

if (!app) throw new Error('Root element not found');
Modal.setAppElement(app);

let appIsMounted = false;
const $servicesInitialized = initialize();
$servicesInitialized.subscribe(({ store, persistor }) => {
  if (appIsMounted) return;
  setupInterceptors(store);

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <CustomRouter>
            <App />
          </CustomRouter>
        </PersistGate>
      </Provider>
    </React.StrictMode>,
    app,
  );

  appIsMounted = true;
});

reportWebVitals();
