import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { isString, isUndefined } from 'lodash';

import { book } from 'src/app/book';
import { ListItem } from 'src/models/list';
import {
  useGetFoldersMap,
  useGetFoldersTreeById,
  useGetCurrentFolderId,
} from 'src/v2/features/quantumNavigator/hooks';
import {
  isContractFolder,
  isDocumentFolder,
  isProjectsFolder,
  isSharedFolder,
  isTemplatesFolder,
} from 'src/v2/features/quantumNavigator/utils';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

import { buildFolderPath } from './utils';
import { getFolderIdWithoutQuery } from './utils/utils';
import { useGetRootFolderId } from './hooks';
import { getFoldersWithDocuments } from '../folderObjects/selectors';
import { getDocumentId } from '../documentDetails/documentDetailsSlice';
import { useUrlManagerQuery } from '../../../common/hooks/urlManager';

interface Props {
  currentPageName?: string;
  onFolderClick: (item: ListItem) => void;
}

export const Breadcrumb: FC<Props> = ({ currentPageName, onFolderClick }) => {
  const navigate = useNavigate();
  const foldersMap = useGetFoldersMap();
  const entityId = useSelector(getDocumentId);
  const foldersList = useSelector(getFoldersWithDocuments);
  const currentFolderIdFromQuery = useGetCurrentFolderId();
  const rootFolderId = useGetRootFolderId();
  const [params] = useUrlManagerQuery();

  const getFolderId = () => {
    if (isString(params.sortBy)) return undefined;

    return isUndefined(currentFolderIdFromQuery)
      ? getFolderIdWithoutQuery(entityId, rootFolderId, foldersList)
      : currentFolderIdFromQuery;
  };

  const currentFolderId = getFolderId();
  const currentFolder = useGetFoldersTreeById(currentFolderId as string);

  const handleClick = useCallback(
    (folder: ListItem) => () => {
      if (isDocumentFolder(folder)) {
        return navigate(book.documents.generatePath());
      }

      if (isContractFolder(folder)) {
        return navigate(book.contracts.generatePath());
      }

      if (isTemplatesFolder(folder)) {
        return navigate(book.templates.generatePath());
      }

      if (isProjectsFolder(folder)) {
        return navigate(book.projects.generatePath());
      }

      return onFolderClick(folder);
    },
    [onFolderClick, navigate],
  );

  if (isUndefined(currentFolderId)) return null;

  return (
    <div className="c-crumb">
      {buildFolderPath(currentFolder, foldersMap).map((folder) => (
        <span className="c-crumb__link" key={folder.id} onClick={handleClick(folder)}>
          {isSharedFolder(folder)
            ? i18n(translationKeys.navigationMenu.shared)
            : i18n(translationKeys.navigationMenu[folder.name.toLowerCase()]) || folder.name}
        </span>
      ))}
      {currentPageName && <span className="c-crumb__link--single">{currentPageName}</span>}
    </div>
  );
};
