import { FC, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { getUserId } from 'src/shared/auth';

import { Messages } from './Messages';
import { Input } from './Input';
import {
  getIsLoading,
  getIsDisconnected,
  currentChatSelector,
  getOppositeMemberSelector,
  markMessagesAsRead,
} from './store';
import { getIsNetworkOnline } from '../network/store';

export const Chat: FC = () => {
  const dispatch = useDispatch();
  const currentChat = useSelector(currentChatSelector);
  const currentUserId = useSelector(getUserId);
  const oppositeMember = useSelector(getOppositeMemberSelector);
  const isLoading = useSelector(getIsLoading);
  const isDisconnected = useSelector(getIsDisconnected);
  const isNetworkOnline = useSelector(getIsNetworkOnline);
  const chatIsNotReady = !currentUserId || !currentChat || !oppositeMember;

  const handleContainerClick = useCallback(() => {
    if (currentChat && currentChat.newCount > 0) dispatch(markMessagesAsRead());
  }, [currentChat, dispatch]);

  if (chatIsNotReady && !isLoading && !isDisconnected) return null;

  if (isDisconnected || !isNetworkOnline) {
    return (
      <div className="c-title c-title--color-red c-title--fs-18">
        {i18n(translationKeys.forms.chat.offline)}
      </div>
    );
  }

  return (
    <>
      <div className="c-line" />
      <div onClick={handleContainerClick} data-id="chat" className="c-message">
        <div className="c-message__row">
          <div className="c-title c-title--color-green c-title--fs-18">
            {i18n(translationKeys.forms.chat.title)}
          </div>
        </div>
        <Messages />
        <Input />
      </div>
    </>
  );
};
