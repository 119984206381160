import { createSelector } from '@reduxjs/toolkit';
import { FormState } from 'redux-form';

import { getObject } from 'src/v2/features/objectsStorage/objectsStorageSlice';
import { RootState, SignUpState } from 'src/app/types';
import { SignUpResponseData } from 'src/api/signUpApi';
import { getUsers, getRegistrations } from 'src/v2/features/objectsStorage';
import { getProfileData } from 'src/v2/features/profile/store/profileSelectors';
import { NotificationSetting } from 'src/models/signUp';
import { convertInviteCompleteModelToInviteEntity } from 'src/v2/boundary/storageModels/invite';
import {
  getInviteObjects,
  getLogos,
  getOrganizations,
} from 'src/v2/features/objectsStorage/selectors';

import { profileFormName } from '../constants';
import { getLogoFromInvite } from '../utils';

const getState = (state: RootState): SignUpState => state.signUp;
const getOrganizationInfoFormState = (state: RootState): FormState => state.form['signup-org-info'];
const getProfileDataFormState = (state: RootState): FormState => state.form[profileFormName];

export const getStep = createSelector(getState, (state: SignUpState) => state.step);
export const getQrCodeAccountRecoveryStep = createSelector(
  getState,
  (state: SignUpState) => state.qrCodeAccountRecoveryStep,
);
export const getStepNum = createSelector(getState, (state: SignUpState) => state.stepNum);
export const getMaxSteps = createSelector(getState, (state: SignUpState) => state.maxSteps);

export const getEmail = createSelector(getState, (state: SignUpState) => state.email);

export const getPhone = createSelector(getState, (state: SignUpState) => state.phone);

export const getFirstName = createSelector(getState, (state: SignUpState) => state.firstName);

export const getLastName = createSelector(getState, (state: SignUpState) => state.lastName);

export const getSignupFormData = createSelector(
  getEmail,
  getPhone,
  getFirstName,
  getLastName,
  (email, phone, firstName, lastName) => {
    const result: { email?: string; phone?: string; firstName?: string; lastName?: string } = {};
    if (email) result.email = email;
    if (phone) result.phone = phone;
    if (firstName) result.firstName = firstName;
    if (lastName) result.lastName = lastName;

    return result;
  },
);

export const getCompanyName = createSelector(getState, (state: SignUpState) => state.companyName);

export const getProfileCountry = createSelector(getProfileDataFormState, (state: FormState) =>
  state && state.values ? state.values.country : 'US',
);

export const getOrganizationCountry = createSelector(
  getOrganizationInfoFormState,
  (state: FormState) => (state && state.values ? state.values.country : 'US'),
);

export const getIsPhoneVerified = createSelector(
  getState,
  (state: SignUpState) => state.isPhoneVerified,
);

export const getUserInfo = createSelector(
  getState,
  ({ lastName, firstName, phone, companyName }: SignUpState) => ({
    lastName: lastName as string,
    firstName: firstName as string,
    phone: phone as string,
    companyName,
  }),
);

export const getCaptureToken = createSelector(getState, (state: SignUpState) => state.captureToken);

export const getSignUpData = createSelector(
  getProfileData,
  getUsers,
  getRegistrations,
  (item, user, registration) => item && getObject<SignUpResponseData>(item, { user, registration }),
);

const getNotificationsSettings = createSelector(getState, (state) => state.notificationsSettings);
const getSettingByType =
  (type: string) =>
  (settings: NotificationSetting[]): boolean => {
    const setting = settings && settings.find((item: NotificationSetting) => item.type === type);
    return setting ? setting.active : false;
  };

export const getSendEmail = createSelector(getNotificationsSettings, getSettingByType('mail'));
export const getSendText = createSelector(getNotificationsSettings, getSettingByType('sms'));
export const getIsComplete = createSelector(getState, (state) => state.isComplete);
export const getAccountType = createSelector(getState, (state) => state.accountType);
export const getIsLoading = createSelector(getState, (state) => state.isLoading);
export const getPlan = createSelector(getState, (state) => state.plan);
export const getPeriod = createSelector(getState, (state) => state.period);
export const getToken = createSelector(getState, (state) => state.token);
export const getInviteType = createSelector(getState, (state) => state.inviteType);
export const getInvite = createSelector(getState, (state) => state.invite);

export const getInviteData = createSelector(
  getInvite,
  getInviteObjects,
  getOrganizations,
  getLogos,
  convertInviteCompleteModelToInviteEntity,
);

export const getOrgInviteLogo = createSelector(getInviteData, getLogoFromInvite);

export const getEntityType = createSelector(getState, (state) => state.entityType);
export const getEntityId = createSelector(getState, (state) => state.entityId);
