export enum SocketConnectionId {
  Chat = 'chat',
  Document = 'document',
  Workflow = 'workflow',
  Notifications = 'notifications',
  Folder = 'folder',
}

export interface WSEvent<T> {
  command: string;
  payload?: T;
}
