import React, { FC, useCallback, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { RootState } from 'src/app/types';
import { normalizeNumbers } from 'src/utils/normalize';
import { validateAuthForm } from 'src/utils/validate';
import { submitOtpCode, requestOptCode } from './accountRecoveryStore';
import { BasicRecoveryValues } from './types';
import { Capture } from '../../components/Сapture/Capture';
import { isNull } from 'lodash';
import { useIsNoCaptureMode } from '../../../utils/hooks';

export const Form: React.FC<InjectedFormProps<BasicRecoveryValues>> = ({
  handleSubmit,
  submitting,
}) => {
  const dispatch = useDispatch();

  const [otpCode, setOtpCode] = useState('');
  const [captureToken, setCaptureToken] = useState<string | null>(null);
  const [isCaptureShown, setIsCaptureShown] = useState<boolean>(false);
  const isNoCaptureMode = useIsNoCaptureMode();
  const { pathname } = useLocation();

  const submitForm = (values: BasicRecoveryValues): void => {
    const { email } = values;
    dispatch(
      submitOtpCode({
        code: otpCode,
        formName: 'recovery',
        email,
        url: pathname,
      }),
    );
  };

  const { email: emailValue, phone: phoneValue } = useSelector((state: RootState) =>
    formValueSelector('recovery')(state, 'email', 'phone'),
  );

  const resendSms = useCallback((): void => {
    setIsCaptureShown(false);
    dispatch(
      requestOptCode({
        email: emailValue,
        phone: `+${normalizeNumbers(phoneValue)}`,
        formName: 'recovery',
        captureToken: !isNull(captureToken) ? captureToken : '',
      }),
    );
    setCaptureToken(null);
  }, [dispatch, emailValue, phoneValue, captureToken]);

  const isValidOTP = otpCode && otpCode.length === 6;

  const handleSubmitCapture = (token: string) => {
    setCaptureToken(token);
  };

  const handleShowCapture = () => {
    setIsCaptureShown(true);
  };

  return (
    <>
      <div className="c-modal__row">
        <div className="c-title c-title--center c-title--fs-32">ACCOUNT RECOVERY</div>
      </div>
      <div className="c-modal__row">
        <div className="c-description__text c-description--center c-description--fs-14">
          Please enter verification code sent to the number associated with your account
        </div>
      </div>
      <div className="c-modal__row">
        <div className="c-line"></div>
      </div>
      <div className="c-modal__row">
        <form data-id="recovery-form-secondStep">
          <OtpInput
            containerStyle="c-verification__form"
            numInputs={6}
            value={otpCode}
            onChange={setOtpCode}
            shouldAutoFocus
          />
        </form>
      </div>
      <div className="c-modal__row c-modal--justify-center">
        <button
          className="c-button"
          type="button"
          onClick={handleSubmit(submitForm)}
          disabled={submitting || !isValidOTP}
        >
          <span className="c-button__inner">VERIFY</span>
        </button>
      </div>
      <div className="c-modal__row c-modal--justify-center c-modal--mt-32 c-modal--text-center">
        <div className="c-title c-title--fs-18 c-title--color-theme">Didn't receive a code?</div>
      </div>
      {!isNoCaptureMode && (
        <Capture
          onChange={handleSubmitCapture}
          isShow={isCaptureShown}
          position={'center'}
          resetCapture={!isCaptureShown}
        />
      )}
      <div className="c-modal__row c-modal--justify-center c-modal--mt-0 c-modal--text-center">
        <button
          className="c-verification__resend"
          type="button"
          onClick={isNull(captureToken) && !isNoCaptureMode ? handleShowCapture : resendSms}
          disabled={submitting}
        >
          RESEND A NEW CODE
        </button>
      </div>
    </>
  );
};

const AccountRecoveryStepTwo = reduxForm<BasicRecoveryValues>({
  form: 'recovery',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateAuthForm,
})(Form);

export const AccountRecoveryStepTwoModal: FC = () => <AccountRecoveryStepTwo />;
