import { documentWorkflowSelectorsFactory } from './selectors';

export const {
  getDocumentWorkflow,
  getWorkflowParticipants,
  getAllWorkflowPartiesUsersIds,
  getIsLoading,
  getError,
  getWorkflowType,
  getWorkflow,
  getIsNegotiable,
  isInWork,
  isReadyToSign,
  isComplete,
} = documentWorkflowSelectorsFactory((state) => state.documentWorkflow);
