import { createAction } from '@reduxjs/toolkit';

import { WSObjectSerialized } from 'src/common/types';
import {
  MoveObjectPayload,
  UnlinkFolderObjectPayload,
  SubscribeFolderActionPayload,
} from './types';

export const moveFolderObject = createAction<MoveObjectPayload>('folder/moveObject');
export const linkFolderObject = createAction<MoveObjectPayload>('folder/linkObject');
export const unlinkFolderObject = createAction<UnlinkFolderObjectPayload>('folder/unlinkObject');
export const folderSubscribe = createAction<SubscribeFolderActionPayload>('folder/folderSubscribe');
export const folderUnsubscribe = createAction('folder/folderUnsubscribe');
export const folderUpdate = createAction<WSObjectSerialized>('folder/updateFolder');
