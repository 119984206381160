import React from 'react';
import iconsSvg from 'src/v2/features/dwStyles/img/_icons.svg';

interface Props {
  code: string;
}

export const BaseIcon = ({ code }: Props): JSX.Element => (
  <svg className={`icon icon--${code}`}>
    <use xlinkHref={`${iconsSvg}#icon--${code}`} />
  </svg>
);
