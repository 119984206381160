import { CreateContractDTO } from 'src/v2/boundary/requestDTO/contract';
import { EntityType } from 'src/models/paper';
import { FormContractCreatePayload } from 'src/v2/features/contract/components/ContractMetadata/types';

export const createConvertFormPayloadToDTO: (
  folderId: string,
) => (payload: FormContractCreatePayload) => CreateContractDTO = (folderId) => (payload) => {
  return {
    data: {
      ...payload,
      type: EntityType.contract,
    },
    folderId,
  };
};
