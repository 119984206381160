import React from 'react';
import { isUndefined } from 'lodash';
import { useParams } from 'react-router';

import {
  createContractFromFileInProject,
  createContractFromTemplateInProject,
  createContractInProject,
} from 'src/v2/features/project/store/projectReducers';
import { AbstractCreate } from 'src/v2/features/contract/ContractSidebar/pages/AbstractCreate';
import { createConvertFormPayloadToDTO } from 'src/v2/features/contract/ContractSidebar/utils';

export const CreateContract: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  if (isUndefined(id)) return null;

  return (
    <AbstractCreate
      convertFormPayloadToDTO={createConvertFormPayloadToDTO(id)}
      createContract={createContractInProject}
      createContractFromFile={createContractFromFileInProject}
      createContractFromTemplate={createContractFromTemplateInProject}
    />
  );
};
