import { FC } from 'react';
import { isUndefined, isEmpty } from 'lodash';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { AppAdvancedLayout } from 'src/v2/modules/layout';
import { useSubscribeForEntityCreateEffct } from 'src/v2/features/sharedEntity/hooks';

import { EntityLayoutProps } from './types';

export const EntityLayout: FC<EntityLayoutProps> = ({ children, error, isLoading }) => {
  useSubscribeForEntityCreateEffct();

  if (isLoading) {
    return (
      <AppAdvancedLayout>
        <b>{i18n(translationKeys.messages.loading)}</b>
      </AppAdvancedLayout>
    );
  }

  if (!isUndefined(error) && !isEmpty(error)) {
    return (
      <AppAdvancedLayout>
        <b style={{ color: 'red' }}>{error}</b>
      </AppAdvancedLayout>
    );
  }

  return <AppAdvancedLayout>{children}</AppAdvancedLayout>;
};
