import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { book } from 'src/app/book';
import { Button } from 'src/v2/features/button';
import { ModalView } from 'src/v2/features/modal';

import { hideUpgradeModal } from './store';

export const UpgradeModal: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const upgradeHandler = useCallback(() => {
    navigate(book.profile.pricingAndBilling.generatePath());
    dispatch(hideUpgradeModal());
  }, [navigate, dispatch]);

  const closeHandler = useCallback(() => {
    dispatch(hideUpgradeModal());
  }, [dispatch]);

  return (
    <ModalView className="c-modal__feature" isActive>
      <button className="c-modal__close" onClick={closeHandler} />
      <div className="c-modal__row">
        <div className="c-title c-title--fs-44 c-title--lh-1 c-title--color-theme">
          This feature is not in your current package
        </div>
      </div>
      <div className="c-modal__row">
        <div className="c-modal__text">Would you like to upgrade?</div>
      </div>
      <div className="c-modal__row">
        <Button onClick={upgradeHandler} className="c-button--uppercase">
          UPGRADE NOW
        </Button>
      </div>
    </ModalView>
  );
};
