import React, { FC } from 'react';
import { isNull } from 'lodash';

import {
  useGetCurrentFolderId,
  useGetFoldersTreeById,
} from 'src/v2/features/quantumNavigator/hooks';
import { isSharedTemplatesFolder } from 'src/v2/features/quantumNavigator/utils';

import { TemplateStandardListPage } from './TemplateStandardListPage';
import { TemplateSharedListPage } from './TemplateSharedListPage';
import { ContextMenuTypeContextProvider } from './providers/ContextMenuTypeContextProvider';

export const TemplateListPage: FC = () => {
  const folderId = useGetCurrentFolderId();
  const current = useGetFoldersTreeById(folderId);
  const isSharedFolder = isNull(current) ? false : isSharedTemplatesFolder(current.data);

  return (
    <ContextMenuTypeContextProvider>
      {isSharedFolder ? <TemplateSharedListPage /> : <TemplateStandardListPage />}
    </ContextMenuTypeContextProvider>
  );
};
