const getDomainName = () => {
  if (window.location.hostname === 'localhost') {
    return window.location.hostname;
  }

  if (window.location.hostname === 'app.docuwalk.com') {
    return '.docuwalk.com';
  }

  return `.${window.location.hostname}`;
};

export const setJWTCookie = (token: string) => {
  const date = new Date();
  date.setTime(date.getTime() + 2 * 24 * 60 * 60 * 1000);

  return (document.cookie = `jwt=${token}; expires=${date.toUTCString()}; path=/; secure; domain=${getDomainName()}`);
};

export const removeJWTCookie = () =>
  (document.cookie = `jwt=; expires=${new Date(
    0,
  ).toUTCString()}; path=/; secure; domain=${getDomainName()}`);
