/* eslint-disable no-console */
// Core
import React, { useMemo } from 'react';
import { Text } from '@theme-ui/components';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { get, size } from 'lodash';
import { required } from 'redux-form-validators';

// Components
import { getStateList, normalizeStatesCountries, useCountryListMemo } from 'src/utils/countries';
import { DateSelect } from 'src/v2/components/DateSelect';
import { Button } from 'src/v2/features/button';

import { languageOptions, LanguageCode } from 'src/instruments/languages';
import { validateEmail, validatePostalCode } from 'src/utils/validate';
import { CForm, CFormRow } from 'src/v2/components/CForm';
import { Input, Select, Textarea } from 'src/v2/features/reduxForm';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { SearchableSelect } from 'src/v2/features/reduxForm/SeachableSelect';
import { RootState } from 'src/app/types';

import { getIsLoading, getProfileFormDataFromProfile, updateProfileData } from '../store';
import { UserPhoneNumber } from './UserPhoneNumber';
import { ProfileFormData } from '../types';

const styles = {
  form: {
    flexDirection: 'column',
  },

  label: {
    pr: '15px',
    fontFamily: 'body',
    fontSize: '16px',
    fontWeight: 400,
    color: '#000000',
  },

  field: {
    alignItems: 'center',
    flexGrow: 1,
    py: '7px',
    '& > div': {
      flexBasis: '360px',
      flexShrink: 0,
    },
  },

  province: {
    flexBasis: '105px',
    flexShrink: 0,
    '& > div': {
      flexGrow: 1,
    },
  },

  code: {
    flexGrow: 1,
    alignItems: 'center',
    '& label': {
      px: '15px',
    },
    '& > div': {
      flexBasis: '115px',
      flexShrink: 0,
    },
  },

  birthDate: {
    justifyContent: 'space-between',
    '& > div:nth-of-type(1)': {
      flexBasis: '135px',
      flexShrink: 0,
    },
    '& > div:nth-of-type(2)': {
      flexBasis: '100px',
      flexShrink: 0,
    },
    '& > div:nth-of-type(3)': {
      flexBasis: '120px',
      flexShrink: 0,
    },
  },

  about: {
    py: '50px',
    width: '610px',
    '& > div:nth-of-type(1)': {
      pb: '10px',
      fontFamily: 'body',
      fontSize: 'large',
      fontWeight: 700,
      lineHeight: 1.25,
      color: '#000000',
    },
    '& textarea': {
      fontFamily: 'body',
      fontSize: '16px',
      fontStyle: 'oblique',
      lineHeight: 1.8,
      color: '#202124',
    },
  },
  buttons: {
    flexDirection: 'column',
    maxWidth: '150px',
  },
  submitBtn: {
    mt: '12px',
    borderRadius: '4px',
    fontSize: '13px',
    fontWeight: 'bold',
    lineHeight: '0.38',
    textAlign: 'center',
    backgroundColor: '#4d6982',
    borderColor: '#4d6982',
    textTransform: 'uppercase',
    color: '#e4b62c',
  },
  phoneNumber: {
    mb: '10px',
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
  },
};

interface Props {
  anyTouched: boolean;
}

const validate = (values: ProfileFormData, props: Props) => ({
  firstName:
    values.firstName || !props.anyTouched ? '' : i18n(translationKeys.errors.FORM_FILED_REQUIRED),
  lastName:
    values.lastName || !props.anyTouched ? '' : i18n(translationKeys.errors.FORM_FILED_REQUIRED),
  zipCode: !values.zipCode
    ? i18n(translationKeys.errors.FORM_FILED_REQUIRED)
    : values.zipCode && values.country && validatePostalCode(values.country, values.zipCode)
    ? ''
    : i18n(translationKeys.errors.INVALID_CODE_FOR_THIS_COUNTRY),
});

const formName = 'profile';

const Form: React.FC<InjectedFormProps<ProfileFormData>> = ({ handleSubmit, pristine }) => {
  const submitting = useSelector(getIsLoading);
  const dispatch = useDispatch();
  const country = useSelector((store: RootState) =>
    get(store, 'form.profile.values.country', 'US'),
  );
  const language = useSelector((store: RootState) =>
    get(store, 'form.profile.values.language', LanguageCode.ENG),
  );
  const phoneVerified = useSelector((store: RootState) =>
    get(store, 'form.profile.values.phoneVerified', true),
  );
  const phoneNumber = useSelector((store: RootState) =>
    get(store, 'form.profile.values.phoneNumber', ''),
  );
  const stateList = useMemo(() => getStateList(country), [country]);

  const statesOptions = useMemo(() => normalizeStatesCountries(stateList), [stateList]);
  const isStatesAvailable = useMemo(() => size(statesOptions) > 0, [statesOptions]);
  const countriesOptions = useCountryListMemo('Country');

  const submitForm = (values: ProfileFormData): void => {
    const profile = {
      firstName: values.firstName,
      lastName: values.lastName,
      address: {
        addressLine: values.address,
        country: values.country,
        state: values.state,
        city: values.city,
        zipCode: values.zipCode,
      },
      language: values.language,
      dateOfBirth: values.dateOfBirth,
      about: values.about,
    };

    dispatch(updateProfileData(profile));
  };

  return (
    <>
      <CForm data-id="profile-form-profileForm" onSubmit={handleSubmit(submitForm)}>
        <CFormRow>
          <Field
            className="c-form__column"
            name="firstName"
            type="text"
            placeholder={i18n(translationKeys.forms.info.firstName)}
            component={Input}
            validate={[required()]}
          />
        </CFormRow>
        <CFormRow>
          <Field
            className="c-form__column"
            name="lastName"
            type="text"
            placeholder={i18n(translationKeys.forms.info.lastName)}
            component={Input}
            validate={[required()]}
          />
        </CFormRow>

        <CFormRow>
          <Field
            className="c-form__column"
            name="email"
            type="email"
            placeholder={i18n(translationKeys.forms.info.email)}
            component={Input}
            validate={[required(), validateEmail]}
            disabled
          />
        </CFormRow>
        <CFormRow>
          <Field
            style={styles.phoneNumber}
            name="phoneNumber"
            placeholder={i18n(translationKeys.forms.info.mobile)}
            phoneVerified={phoneVerified}
            phoneNumber={phoneNumber}
            component={UserPhoneNumber}
          />
        </CFormRow>

        <CFormRow>
          <Field
            className="c-form__column"
            name="address"
            type="text"
            placeholder={i18n(translationKeys.forms.info.address)}
            component={Input}
            validate={[required()]}
          />
        </CFormRow>

        <CFormRow>
          <Field
            className="c-form__column"
            name="city"
            type="text"
            placeholder={i18n(translationKeys.forms.info.city)}
            component={Input}
            validate={[required()]}
          />
        </CFormRow>

        <CFormRow>
          <Field
            className="c-form__column c-form__column--2"
            name="state"
            data-id="profileState"
            placeholder={i18n(translationKeys.forms.info.stateSelect)}
            component={SearchableSelect}
            options={statesOptions}
            disabled={!isStatesAvailable}
          />
          <Field
            className="c-form__column c-form__column--2"
            name="zipCode"
            type="text"
            placeholder={i18n(translationKeys.forms.info.zipCode)}
            component={Input}
          />
        </CFormRow>
        <CFormRow>
          <Field
            className="c-form__column"
            name="country"
            placeholder={i18n(translationKeys.forms.info.countrySelect)}
            data-id="profileCountry"
            component={SearchableSelect}
            defaultValue={country}
            options={countriesOptions}
            mb8
          />
        </CFormRow>
        <CFormRow>
          <Field
            className="c-form__column"
            name="language"
            placeholder={i18n(translationKeys.forms.info.language)}
            data-id="language"
            component={Select}
            defaultValue={language}
            options={languageOptions}
            mb8
          />
        </CFormRow>

        <CFormRow>
          <Text>{i18n(translationKeys.forms.info.birthDate)}</Text>
        </CFormRow>
        <CFormRow className="c-form--mt-4">
          <Field
            name="dateOfBirth"
            data-id="birthDate"
            component={DateSelect}
            props={{
              language,
            }}
          />
        </CFormRow>

        <CFormRow>
          <Text>{i18n(translationKeys.profile.about)}</Text>
        </CFormRow>
        <CFormRow>
          <Field
            name="about"
            className="c-form__column"
            multiline="true"
            rows={6}
            component={Textarea}
          />
        </CFormRow>
        <CFormRow>
          <Button
            data-type="profileForm-submit"
            type="submit"
            disabled={pristine || submitting}
            isLoading={submitting}
          >
            {i18n(translationKeys.buttons.update)}
          </Button>
        </CFormRow>
      </CForm>
    </>
  );
};

const ProfileReduxForm = reduxForm<ProfileFormData>({
  form: formName,
  validate,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(Form);

export const ProfileForm = connect((state: RootState) => ({
  initialValues: getProfileFormDataFromProfile(state),
}))(ProfileReduxForm);
