import React, { FC, useCallback, useEffect, useState } from 'react';
import { Box, Label, Input, Button } from '@theme-ui/components';
import { isNull, isEmpty } from 'lodash';

import { normalizeNumbers } from 'src/utils/normalize';
import { PhoneNumber } from 'src/v2/components/PhoneNumber';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

import { Props } from './types';
import { Capture } from '../Сapture/Capture';
import { useIsNoCaptureMode } from '../../../utils/hooks';

const styles = {
  submitBtn: {
    mt: '12px',
    borderRadius: '4px',
    fontSize: '13px',
    fontWeight: 'bold',
    lineHeight: '0.38',
    textAlign: 'center',
    backgroundColor: '#4d6982',
    borderColor: '#4d6982',
    textTransform: 'uppercase',
    color: '#e4b62c',
  },
};

export const PhoneNumberWithOtp: FC<Props> = ({
  onUpdatePhone,
  onSendCode,
  phoneVerified = true,
  phoneNumber = '',
  ...rest
}) => {
  const [number, setNumber] = useState('');
  const [code, setCode] = useState('');
  const [showEditBlock, setShowEditBlock] = useState(false);
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [captureToken, setCaptureToken] = useState<string | null>(null);
  const isNoCaptureMode = useIsNoCaptureMode();

  useEffect(() => {
    setShowCodeInput(!phoneVerified);
    setShowEditBlock(!phoneVerified);
  }, [phoneVerified]);

  useEffect(() => {
    setNumber(phoneNumber);
  }, [phoneNumber]);

  const changeCode = (event: React.FormEvent<HTMLInputElement>): void => {
    setCode(event.currentTarget.value);
  };

  const handleBlur = useCallback((): void => {
    if (!isEmpty(number)) {
      setShowEditBlock(true);
    }
  }, [number]);

  const handleUpdatePhone = useCallback(
    (e: MouseEvent): void => {
      const token = !isNull(captureToken) ? captureToken : '';
      e.preventDefault();
      const normalizedPhoneNumber = `+${normalizeNumbers(number)}`;
      onUpdatePhone(normalizedPhoneNumber, token);
      setShowCodeInput(true);
    },
    [number, onUpdatePhone, captureToken],
  );

  const handleSendCode = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();

      onSendCode(code);
      setShowCodeInput(false);
      setShowEditBlock(false);
      setCode('');
    },
    [onSendCode, code],
  );

  const handleSubmitCapture = (token: string) => {
    setCaptureToken(token);
  };

  return (
    <PhoneNumber {...rest} onBlur={handleBlur} onChange={setNumber}>
      {!isNoCaptureMode && (
        <Capture onChange={handleSubmitCapture} isShow={!showCodeInput && showEditBlock} />
      )}
      {showEditBlock && (
        <Box>
          {!showCodeInput && (
            <>
              <Button
                onClick={handleUpdatePhone}
                sx={styles.submitBtn}
                disabled={isNull(captureToken) && !isNoCaptureMode}
              >
                {i18n(translationKeys.buttons.confirmPhone)}
              </Button>
            </>
          )}
          {showCodeInput && (
            <Box>
              <Label styles={{ paddingBlock: '16px' }}>
                {i18n(translationKeys.forms.phoneVerification.codeInput, {
                  codeInput: normalizeNumbers(number).slice(-3),
                })}
              </Label>
              <Input
                onChange={changeCode}
                value={code}
                id="phone-code"
                name="title"
                placeholder={i18n(translationKeys.forms.phoneVerification.confirmationCode)}
              />
              <Button onClick={handleSendCode} sx={styles.submitBtn}>
                {i18n(translationKeys.buttons.verify)}
              </Button>
            </Box>
          )}
        </Box>
      )}
    </PhoneNumber>
  );
};
