import { FC } from 'react';
import { useSelector } from 'react-redux';

import { DocumentCard } from 'src/v2/features/document/components/DocumentCard';
import { parseCardData } from 'src/v2/features/document/utils';
import { CardPaperModel } from 'src/models/card';
import { useCreateNavigateToDocumentDetails } from 'src/v2/features/document';
import { DocumentRole } from 'src/models/document';
import {
  useCreateDeleteDocument,
  useCreateShowDocumentMetadataView,
} from 'src/v2/features/project/ProjectSidebar/nav-hooks';
import { ContextMenuBook } from 'src/app/types';
import { usePreventOperationForDeletedEntity } from 'src/v2/features/sharedEntity/hooks';

import { getDeletedEntityId } from '../store/projectSelectors';

interface Props {
  cardPaper: CardPaperModel<DocumentType, DocumentRole>;
  selected: boolean;
  projectId: string;
}

export const ProjectDocumentCard: FC<Props> = ({ cardPaper, projectId, selected }) => {
  const createHandleOpenDocument = useCreateNavigateToDocumentDetails();
  const createHandleDelete = useCreateDeleteDocument(cardPaper, projectId);
  const handleDelete = createHandleDelete();
  const viewDocumentMeta = useCreateShowDocumentMetadataView({ id: cardPaper.id });
  const deletedEntityId = useSelector(getDeletedEntityId);
  const preventOperationForDeletedDocument = usePreventOperationForDeletedEntity(deletedEntityId);

  return (
    <DocumentCard
      contextMenu={ContextMenuBook.DocumentProject}
      data={parseCardData(cardPaper)}
      onClick={preventOperationForDeletedDocument(cardPaper.id, viewDocumentMeta)}
      onDelete={preventOperationForDeletedDocument(cardPaper.id, handleDelete)}
      onDoubleClick={preventOperationForDeletedDocument(
        cardPaper.id,
        createHandleOpenDocument(cardPaper.id),
      )}
      selected={selected}
    />
  );
};
