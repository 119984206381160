// Usage:
// import { createAction } from '@reduxjs/toolkit';
// import { defaultSelectorFactory } from 'src/utils/defaultSelectors';
// import { fetchWorkflowSliceFactory, fetchWorkflowSagaFactory } from '.';
//
// export const fetchWorkflow = createAction<string>('documents/fetchWorkflow');
// export const fetchWorkflowSlice = fetchWorkflowSliceFactory('fetchWorkflowSlice');
// export const fetchWorkflowSaga = fetchWorkflowSagaFactory(fetchWorkflowSlice.actions);
// export const { getDataHead: getDocumentWorkflow, getIsLoading, getError } = defaultSelectorFactory(
//   state => state.documentWorkflow,
// );
//
// export default fetchWorkflowSlice.reducer;

/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { put, call, select } from 'redux-saga/effects';

import { fetchWorkflowApi } from 'src/api/documents';
import { initialStateFactory, defaultActionsFactoryWithData } from 'src/utils/defaultSlice';

import { getAllWorkflowPartiesUsersIds } from './documentWorkflowSelectorsFactory';
import { storeData } from '../objectsStorage/objectsStorageSlice';
import { fetchUsers } from '../profile';

export const fetchWorkflowSliceFactory = (name: string) => {
  const { onStart, onError, onSuccessWithDataSerialized } = defaultActionsFactoryWithData();

  return createSlice({
    name,
    initialState: initialStateFactory(),
    reducers: {
      start: onStart,
      error: onError,
      success: onSuccessWithDataSerialized,
    },
  });
};

export const fetchWorkflowSagaFactory = (
  fetchWorkflowAction: ReturnType<typeof fetchWorkflowSliceFactory>['actions'],
) =>
  function* fetchWorkflowSaga(action: PayloadAction<string>) {
    try {
      yield put(fetchWorkflowAction.start());
      const workflow = yield call(fetchWorkflowApi, action.payload);
      yield put(storeData(workflow));
      const ids = yield select(getAllWorkflowPartiesUsersIds);
      if (ids.length) {
        const users = yield call(fetchUsers, ids);
        yield put(storeData(users));
      }
      yield put(fetchWorkflowAction.success(workflow));
    } catch (error) {
      yield put(fetchWorkflowAction.error(error.toString()));
    }
  };
