import { EventChannel, SagaIterator } from 'redux-saga';
import { isUndefined } from 'lodash';

import {
  CopyContractToFolderAPIDTO,
  CreateContractAPIDTO,
  CreateContractDTO,
  CreateContractFromTemplateAPIDTO,
  CreateContractFromTemplateDTO,
} from 'src/v2/boundary/requestDTO/contract';
import { ObjectSerialized } from 'src/common/types';
import { appendDataObjectIfDefined } from 'src/common/utils';
import { apiClient } from 'src/common/client';
import { createDocumentAPI } from 'src/api/documents';

export function createContractInFolderAPI(
  paper: CreateContractDTO,
): SagaIterator<ObjectSerialized | null> {
  const { role, title, description, subType, subSubType, type, ownerConditionRequired } =
    paper.data;
  const { folderId } = paper;

  const data: CreateContractAPIDTO = {
    data: {
      type: 'document',
      attributes: {
        title,
        description,
        containerType: 'folder',
        containerId: folderId,
        role,
        subType,
        subSubType,
        type,
        ownerConditionRequired,
      },
    },
  };

  return createDocumentAPI(data);
}

export function createContractFromUploadAPI(
  file: File,
  document: CreateContractDTO,
  containerType: 'folder' | 'project',
): SagaIterator<EventChannel<ObjectSerialized>> {
  const data = new FormData();
  const {
    title,
    description,
    subType,
    role,
    type,
    subSubType,
    ownerConditionRequired = false,
  } = document.data;

  appendDataObjectIfDefined(data, {
    title,
    description,
    subType: subType,
    role,
    type,
    subSubType: subSubType,
    ownerConditionRequired: ownerConditionRequired.toString(),
    file,
    containerId: document.folderId,
    containerType,
  });

  return apiClient.uploadFile<ObjectSerialized>('/gateway/v2/papers/fromFile', data);
}

export function createContractInFolderFromUploadAPI(
  file: File,
  contract: CreateContractDTO,
): SagaIterator<EventChannel<ObjectSerialized>> {
  return createContractFromUploadAPI(file, contract, 'folder');
}

export const createContractFromTemplateAPIFactory =
  (containerType: 'project' | 'folder') =>
  (payload: CreateContractFromTemplateDTO): SagaIterator<ObjectSerialized | null> => {
    const { sourceId, folderId, data: payloadData } = payload;
    const { role, title, description, subType, subSubType, type, ownerConditionRequired } =
      payloadData;
    const data: CreateContractFromTemplateAPIDTO = {
      data: {
        type: 'document',
        attributes: {
          sourceId,
          role,
          title,
          description,
          subType,
          subSubType,
          type,
          containerType,
          containerId: folderId,
          ownerConditionRequired: isUndefined(ownerConditionRequired)
            ? false
            : ownerConditionRequired,
        },
      },
    };
    return apiClient.post('/documents/fromTemplate', data);
  };

export const createContractFromTemplateAPI = createContractFromTemplateAPIFactory('folder');

export function copyContractToFolderAPI(
  contractId: string,
  folderId: string,
): SagaIterator<ObjectSerialized | null> {
  const payload: CopyContractToFolderAPIDTO = {
    data: {
      type: 'document',
      attributes: {
        documentId: contractId,
        containerId: folderId,
        containerType: 'folder',
      },
    },
  };
  return apiClient.post(`/documents/copy`, payload);
}
