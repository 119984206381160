import { createContext } from 'react';
import { noop } from 'lodash';

import { ActionContextValue } from 'src/v2/features/signature/types';

export const HankoActionContext = createContext<ActionContextValue>({
  isEditing: false,
  isDisabled: false,
  isLoading: false,
  onSubmit: noop,
});
