import React, { FC, HTMLAttributes } from 'react';
import cn from 'classnames';

import { ContextMenuRow } from './ContextMenuRow';

interface Props extends HTMLAttributes<HTMLDivElement> {
  icon: FC;
  text: string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent) => void;
}

export const ContextMenuItem: FC<Props> = ({
  icon: Icon,
  text,
  disabled,
  onClick,
  children,
  ...other
}) => (
  <ContextMenuRow>
    <div
      className={cn(['c-edit__item', { 'c-edit--disabled': disabled }])}
      onClick={onClick}
      {...other}
    >
      <div className="c-edit__icon">
        <Icon />
      </div>
      <div className="c-edit__text">{text}</div>
      {children && <div className="c-edit__arrow" />}
    </div>
    {children && <div className="c-edit__dropdown">{children}</div>}
  </ContextMenuRow>
);
