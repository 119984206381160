import { createSelector } from '@reduxjs/toolkit';
import { isUndefined } from 'lodash';

import { RootState } from 'src/app/types';

export const getContextMenuState = (state: RootState) => state.contextMenu;

export const getIsContextMenuOpen = createSelector(
  getContextMenuState,
  (state) => !isUndefined(state.sourceType),
);
