import React, { useState, useEffect, useCallback } from 'react';
import { Flex } from '@theme-ui/components';
import { formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { isNull } from 'lodash';
import { useSelector } from 'react-redux';
import OtpInput from 'react-otp-input';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { validateAuthForm } from 'src/utils/validate';
import checkIcon from 'src/assets/editor-icons/check-icon.png';
import { getProfileSelector } from 'src/v2/features/profile';
import { Button } from 'src/v2/features/button';
import { RootState } from 'src/app/types';

import { getIsPhoneVerified } from './store';
import { FormHeader } from './components/FormHeader';
import { EditPhone } from './components/EditPhone';
import { ResendSmsPayload } from './types';
import { useIsNoCaptureMode } from '../../../utils/hooks';
import { Capture } from '../../components/Сapture/Capture';

type Props = {
  verifyCode: (props: Values) => void;
  resendSms: (payload: ResendSmsPayload) => void;
};

export type Values = {
  code: string;
  email: string;
  phone: string;
};

type PhoneVerificationProps = Props & InjectedFormProps<Values, Props>;

export const Form: React.FC<PhoneVerificationProps> = ({ handleSubmit, verifyCode, resendSms }) => {
  const { email: emailFromSignUpValue, phone: phoneFromSignUpValue } = useSelector(
    (state: RootState) => formValueSelector('signup')(state, 'phone', 'email'),
  );
  const isPhoneVerified = useSelector(getIsPhoneVerified);
  const user = useSelector(getProfileSelector);
  const phoneFromProfile = user ? user.phone : null;
  const emailFromProfile = user ? user.email : null;
  const phoneValue = phoneFromSignUpValue || phoneFromProfile || '';
  const emailValue = emailFromProfile || emailFromSignUpValue;
  const [phoneNumber, setPhoneNumber] = useState(phoneValue);
  const [phoneNumberChanged, setPhoneNumberChanged] = useState(false);
  const [smsSent, setSmsSent] = useState(false);
  const [otpCode, setOtpCode] = useState('');
  const [captureToken, setCaptureToken] = useState<string | null>(null);
  const [isCaptureShown, setIsCaptureShow] = useState<boolean>(false);
  const isNoCaptureMode = useIsNoCaptureMode();

  useEffect(() => {
    if (phoneNumber === '') setPhoneNumber(phoneValue);
  }, [phoneValue, phoneNumber]);

  const handleChange = useCallback((number: string): void => {
    setSmsSent(false);
    setPhoneNumberChanged(true);
    setPhoneNumber(number);
  }, []);

  const handleResendSms = useCallback((): void => {
    setOtpCode('');
    setIsCaptureShow(false);
    const payload: ResendSmsPayload = {
      email: emailValue,
      phone: phoneNumber,
      isSignup: true,
      withUpdatePhone: phoneNumberChanged,
      captureToken,
    };
    resendSms(payload);
    setSmsSent(true);
    setPhoneNumberChanged(false);
    setCaptureToken(null);
  }, [emailValue, phoneNumber, phoneNumberChanged, resendSms, captureToken]);

  useEffect(() => {
    if (otpCode.length === 6) {
      verifyCode({
        code: otpCode,
        email: emailValue,
        phone: phoneNumber,
      });
    }
  }, [otpCode, emailValue, verifyCode, phoneNumber]);

  const handleSubmitCapture = (token: string) => {
    setCaptureToken(token);
  };

  const handleShowCapture = () => {
    setIsCaptureShow(true);
  };

  return (
    <>
      <div className="c-setup__top">
        <FormHeader
          title={i18n(translationKeys.forms.phoneVerification.title)}
          description={i18n(translationKeys.forms.phoneVerification.description)}
        />
      </div>
      <div className="c-setup__content">
        <div className="c-verification">
          {smsSent && (
            <div className="c-verification__title c-verification--gray">
              {i18n(translationKeys.forms.phoneVerification.successMessage, { phoneNumber })}
            </div>
          )}
          <EditPhone onChange={handleChange} phone={phoneNumber} />
          <div className="c-verification__title">
            {i18n(translationKeys.forms.phoneVerification.verificationCode)}:
          </div>
          <div className="c-verification__form">
            <form onSubmit={handleSubmit}>
              <Flex data-type="otp-inputs" sx={{ justifyContent: 'left', mt: '5px' }}>
                <OtpInput
                  inputStyle={{
                    width: '40px',
                    height: '40px',
                    margin: '0 5px',
                    fontSize: '20px',
                    borderRadius: 4,
                    border: 'none',
                    textAlign: 'center',
                    backgroundColor: 'white',
                  }}
                  numInputs={6}
                  errorStyle="error"
                  value={otpCode}
                  onChange={(val: string): void => setOtpCode(val)}
                  shouldAutoFocus
                />
              </Flex>
            </form>
          </div>
          <div className="c-verification__title c-verification--gray">
            {i18n(translationKeys.forms.phoneVerification.notrecievedCode)}
          </div>
          {!isNoCaptureMode && (
            <Capture
              onChange={handleSubmitCapture}
              isShow={isCaptureShown}
              resetCapture={isCaptureShown}
            />
          )}
          <Button
            className="h-mt-8 h-uppercase"
            type="button"
            onClick={isNull(captureToken) && !isNoCaptureMode ? handleShowCapture : handleResendSms}
          >
            {i18n(translationKeys.buttons.resendCode)}
          </Button>
        </div>
      </div>

      {isPhoneVerified ? (
        <div className="c-verification__status c-verification__status--active">
          <img className="c-verification__success" src={checkIcon} alt="Verification accepted" />
          {i18n(translationKeys.forms.phoneVerification.acceptedVerificationCode)}
        </div>
      ) : null}
    </>
  );
};
export const verifyPhoneFormName = 'signup';
export const PhoneVerificationForm = reduxForm<Values, Props>({
  form: verifyPhoneFormName,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateAuthForm,
})(Form);
