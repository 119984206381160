export const styles = {
  container: {
    borderRadius: '5px',
  },

  active: {
    // backgroundColor: 'white',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  },

  accepted: {
    border: '1px solid #29cb97',
  },

  disablePointerEvents: {
    '.ck.ck-editor .editable-field[data-new-value]': {
      pointerEvents: 'none !important',
    },
  },

  rejected: {
    border: '1px solid #d57872',
  },

  updating: {
    padding: '16px 18px',
  },

  showToolbar: {
    '.ck.ck-editor .ck.ck-editor__top': {
      opacity: 1,
      maxHeight: '200px',
      overflow: 'hidden',
      pointerEvents: 'all !important',
    },
  },

  editor: {
    // px: '16px',
    // py: '18px',
    backgroundColor: '#FFFFFF',
    // Clear fix for floating images
    '::after': {
      clear: 'both',
      content: '""',
      display: 'block',
    },

    '> div:last-child': {
      top: '100%',
      right: '8px',
      fontSize: '22px',
      overflow: 'hidden',
      position: 'absolute',
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      opacity: 0,
      visibility: 'hidden',
      transition: 'opacity 0.3s, visibility 0.3s',
    },

    ':hover': {
      '> div:last-child': {
        opacity: 1,
        visibility: 'visible',
      },
    },
  },
  editorOnNegotiation: {
    borderRadius: '3px',
    border: '1px solid rgb(41, 203, 151)',
  },
  editorActionsDisabled: {
    display: 'none',
  },
  editorActions: {
    textAlign: 'right',
    fontSize: '25px',
    display: 'flex',
    width: 'max-content',
    background: '#ffffff',
    marginLeft: 'auto',
    border: '1px solid #c4c4c4',
    borderTop: '0px',
    transition: 'opacity 0.15s',
    right: '0px',
    marginBottom: '0px',
    zIndex: '1',
  },

  insertButton: {
    display: 'flex',
    cursor: 'pointer',
    color: '#29cb97',
    padding: '0 10px',
    borderRight: '1px solid #c4c4c4',

    ':hover': {
      backgroundColor: '#FAFAFA',
    },
  },

  removeButton: {
    display: 'flex',
    color: '#FF0066',
    padding: '0 10px',
    cursor: 'pointer',

    ':hover': {
      backgroundColor: '#FAFAFA',
    },
  },
  dragButton: {
    display: 'flex',
    color: '#FF0066',
    padding: '0 10px',
    cursor: 'pointer',

    ':hover': {
      backgroundColor: '#FAFAFA',
    },
  },

  editorContainer: {
    marginBottom: '36px',
    transition: 'all 0.3s',

    ':hover': {
      position: 'relative',
      marginBottom: '56px',
    },
  },
};

export const customStyleMap = {
  SELECTION: {
    backgroundColor: '#d0e2f3',
  },
};
