import { EditorConfig } from 'ckeditor5/src/core';
import { isUndefined } from 'lodash';

import { defaultConfiguration } from './defaultConfiguration';

export function generateEditorConfig(...configs: EditorConfig[]): EditorConfig {
  let acc: EditorConfig[] | EditorConfig = [];
  for (const config of configs) {
    if (config instanceof Array) {
      if (!(acc instanceof Array)) {
        acc = [];
      }
      acc = [...acc, ...config];
    } else if (config instanceof Object) {
      for (let [key, value] of Object.entries(config)) {
        if (value instanceof Object && key in acc) {
          value = generateEditorConfig(acc[key], value);
        }
        acc = { ...acc, [key]: value };
      }
    }
  }
  return acc as EditorConfig;
}

export const isPropagateFieldsValues = () =>
  !isUndefined(defaultConfiguration.editableField) &&
  defaultConfiguration.editableField.allowValuePropagation;
