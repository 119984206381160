import { RemoveParticipant } from './pages/RemoveParticipant';
import { ShareEntityForm } from './pages/ShareEntityForm';
import { QrCodeShareEntityForm } from './pages/QrCodeShareEntityForm';
import { RemoveFolder } from './pages/RemoveFolder';
import { book } from './book';

export const entityParticipantRoutes = [
  {
    path: book.removeParticipant,
    render: RemoveParticipant,
  },
  {
    path: book.shareEntity,
    render: ShareEntityForm,
  },
  {
    path: book.qrShareEntity,
    render: QrCodeShareEntityForm,
  },
  {
    path: book.removeFolder,
    render: RemoveFolder,
  },
];
