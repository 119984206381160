import React, { FC, useMemo, useCallback } from 'react';
import cn from 'classnames';
import Select from 'react-select';

import { SearchableSelectProps } from '../types';
import { WithValidationMessage } from '../WithValidationMessage';

const classModifiers = {
  disabled: 'c-select--disabled',
  mb8: 'c-select--mb-8',
};

const convertToOption =
  (name: string) =>
  ({ label, value, disabled = false }, idx: number) => ({
    label,
    value,
    disabled: { disabled },
    key: `${name}-${value}-${idx}`,
  });

export const SearchableSelect: FC<SearchableSelectProps> = (props) => {
  const {
    errorMessage,
    successMessage,
    className,
    options = [],
    mb8 = false,
    disabled,
    name,
    value,
    onChange,
  } = props;

  const optionsWithLabels = useMemo(() => options.map(convertToOption(name)), [name, options]);
  const selectedOption = useMemo(
    () => optionsWithLabels.find((item) => item.value === value) || null,
    [optionsWithLabels, value],
  );

  const handleChange = useCallback(
    (item) => {
      if (item) onChange(item.value);
    },
    [onChange],
  );

  return (
    <WithValidationMessage
      className={cn(className, {
        [classModifiers.disabled]: disabled,
        [classModifiers.mb8]: mb8,
      })}
      errorMessage={errorMessage}
      successMessage={successMessage}
      classPrefix="c-select"
    >
      <div className="c-select__selected">
        <Select
          name={name}
          className="c-select__searchable"
          options={optionsWithLabels}
          value={selectedOption}
          onChange={handleChange}
        />
      </div>
    </WithValidationMessage>
  );
};
