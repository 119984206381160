import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import {
  getDefaultSignatureId,
  getDefaultSignatureUserName,
  getUserSignatureList,
} from 'src/v2/features/signature/store';
import { SignatureWithHanko as SignatureWithHankoComponent } from 'src/v2/components/Signature';
import { useDefaultSignatureChangeHandler } from 'src/v2/features/signature/hooks';
import { CommonSignature } from 'src/v2/features/signature/components/CommonSignature';
import { signatureFonts, japaneseSignatureFonts } from 'src/v2/features/signature/config';

import { HankoSignatureList } from '../../components/HankoSignatureList';
import { SignatureSettingsHanko } from '../../components/SignatureSettingsHanko';
import { HankoSignaturePreview } from '../../components/HankoSignaturePreview';
import { HankoSignatureAction } from '../../components/HankoSignatureAction';
import { UploadHanko } from '../../components/UploadHanko';
import { HankoSignatureAggregatedProvider } from '../../providers/HankoSignatureAggregatedProvider';
import { getSignatureStamps, getDefaultSignatureStampId, getLocalisedSignature } from '../../store';
import {
  useCreateNewHankoSignatureHandler,
  useEditHankoSignatureHandler,
  useDefaultStampChangeHandler,
} from '../../hooks';

export const SignatureWithHanko: FC = () => {
  const defaultSignatureUserName = useSelector(getDefaultSignatureUserName);
  const signatures = useSelector(getUserSignatureList);
  const stamps = useSelector(getSignatureStamps);
  const defaultSignatureId = useSelector(getDefaultSignatureId);
  const defaultStampId = useSelector(getDefaultSignatureStampId);
  const localized = useSelector(getLocalisedSignature);

  const handleCreateNewHankoSignature = useCreateNewHankoSignatureHandler();
  const handleEditHankoSignature = useEditHankoSignatureHandler();
  const handleDefaultSignatureChange = useDefaultSignatureChangeHandler();
  const handleDefaultStampChange = useDefaultStampChangeHandler();

  useEffect(() => {
    if (defaultSignatureId) handleEditHankoSignature(defaultSignatureId);
  }, [handleEditHankoSignature, defaultSignatureId, defaultStampId]);

  return (
    <HankoSignatureAggregatedProvider>
      <div
        data-id="profile-container-signature"
        className="c-setup c-setup--signature c-setup--bg-transparent c-setup--hanko"
      >
        <CommonSignature
          signatureFonts={localized ? japaneseSignatureFonts : signatureFonts}
          signaturePlaceholder={i18n(translationKeys.forms.signature.yourSignature)}
        >
          <UploadHanko />
          <HankoSignaturePreview component={SignatureWithHankoComponent} />
          <HankoSignatureAction>
            <SignatureSettingsHanko />
          </HankoSignatureAction>
          <HankoSignatureList
            label={i18n(translationKeys.forms.signature.selectDefault)}
            signatures={signatures}
            stamps={stamps}
            userName={defaultSignatureUserName}
            defaultSignatureId={defaultSignatureId}
            defaultStampId={defaultStampId}
            onSelectDefault={handleDefaultSignatureChange}
            onStampChange={handleDefaultStampChange}
            onCreateNew={handleCreateNewHankoSignature}
            onEdit={handleEditHankoSignature}
          />
        </CommonSignature>
      </div>
    </HankoSignatureAggregatedProvider>
  );
};
