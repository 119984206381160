import { includes } from 'lodash';

import { DocumentRole } from 'src/models/document';
import { PaperState } from 'src/models/paper';
import { UserPlan } from 'src/models/billing';

export const canDeleteDocument = (state: PaperState) => state !== PaperState.completed;

export const canEditDocumentMetadata = (actorRole: DocumentRole): boolean =>
  includes([DocumentRole.Owner, DocumentRole.Manager], actorRole);

export const canEditDocument = (actorRole: DocumentRole): boolean =>
  includes([DocumentRole.Owner, DocumentRole.Manager, DocumentRole.Editor], actorRole);

export const canEditAccessInDocument = (actorRole: DocumentRole): boolean =>
  includes([DocumentRole.Owner], actorRole);

export const canSaveDocumentElementTemplate = (actorRole: DocumentRole): boolean =>
  includes([DocumentRole.Owner, DocumentRole.Manager, DocumentRole.Editor], actorRole);

export const canCreateDocumentTemplate = (actorRole: DocumentRole): boolean =>
  includes([DocumentRole.Owner], actorRole);

export const canViewDocumentHistory = (actorRole: DocumentRole): boolean =>
  includes(
    [
      DocumentRole.Owner,
      DocumentRole.Manager,
      DocumentRole.Editor,
      DocumentRole.Acknowledger,
      DocumentRole.Commentator,
    ],
    actorRole,
  );

export const canManageParticipantInDocument = (actorRole: DocumentRole): boolean =>
  includes(
    [
      DocumentRole.Owner,
      DocumentRole.Manager,
      DocumentRole.Editor,
      DocumentRole.Acknowledger,
      DocumentRole.Commentator,
      DocumentRole.Viewer,
    ],
    actorRole,
  );

export const canSpecifyAccessUserInDocument = (actorRole: DocumentRole): boolean =>
  includes([DocumentRole.Owner], actorRole);

export const canAddDocumentAttachments = (actorRole: DocumentRole): boolean =>
  includes([DocumentRole.Owner, DocumentRole.Manager, DocumentRole.Editor], actorRole);

export const canCommentInDocument = (actorRole: DocumentRole): boolean =>
  includes(
    [
      DocumentRole.Owner,
      DocumentRole.Manager,
      DocumentRole.Editor,
      DocumentRole.Acknowledger,
      DocumentRole.Commentator,
    ],
    actorRole,
  );

export const canAcknowledgeDocument = (actorRole: DocumentRole): boolean =>
  includes([DocumentRole.Manager, DocumentRole.Editor, DocumentRole.Acknowledger], actorRole);

export const canShareDocument = (actorRole: DocumentRole): boolean =>
  includes(
    [
      DocumentRole.Owner,
      DocumentRole.Manager,
      DocumentRole.Editor,
      DocumentRole.Acknowledger,
      DocumentRole.Commentator,
      DocumentRole.QRViewer,
      DocumentRole.Viewer,
      DocumentRole.SharedViewer,
      DocumentRole.DocumentSharer,
      DocumentRole.DocumentSharerDownloader,
    ],
    actorRole,
  );

export const canDownloadDocument = (actorRole: DocumentRole): boolean =>
  includes(
    [
      DocumentRole.Owner,
      DocumentRole.Manager,
      DocumentRole.Editor,
      DocumentRole.Acknowledger,
      DocumentRole.Commentator,
      DocumentRole.QRViewer,
      DocumentRole.Viewer,
      DocumentRole.SharedViewer,
      DocumentRole.Downloader,
      DocumentRole.DocumentSharerDownloader,
    ],
    actorRole,
  );

export const canRestoreDocument = (actorRole: DocumentRole): boolean =>
  includes([DocumentRole.Owner], actorRole);

export const canViewDocumentVersions = (actorRole: DocumentRole): boolean =>
  includes([DocumentRole.Owner, DocumentRole.Manager], actorRole);

export const canViewDocumentNotifications = (actorRole: DocumentRole): boolean =>
  includes(
    [
      DocumentRole.Owner,
      DocumentRole.Manager,
      DocumentRole.Editor,
      DocumentRole.Acknowledger,
      DocumentRole.Commentator,
      DocumentRole.QRViewer,
      DocumentRole.Viewer,
      DocumentRole.SharedViewer,
    ],
    actorRole,
  );

export const canChatInDocument = (actorRole: DocumentRole): boolean =>
  includes(
    [
      DocumentRole.Owner,
      DocumentRole.Manager,
      DocumentRole.Editor,
      DocumentRole.Acknowledger,
      DocumentRole.Commentator,
      DocumentRole.QRViewer,
    ],
    actorRole,
  );

export const canCreateDocument = (plan: UserPlan) => true;

export const canCopyDocument = (actorRole: DocumentRole, plan: UserPlan) =>
  includes([DocumentRole.Owner], actorRole);

export const canMoveDocument = (plan: UserPlan) => true;

export const canCreateFolderInDocuments = (plan: UserPlan): boolean => true;
