import React, { FC } from 'react';

import { SidebarNodeContext } from 'src/v2/modules/layout';
import { TemplateSidebar } from '../TemplateSidebar/TemplateSidebar';

export const SidebarNodeContextProvider: FC = ({ children }) => {
  return (
    <SidebarNodeContext.Provider value={<TemplateSidebar />}>
      {children}
    </SidebarNodeContext.Provider>
  );
};
