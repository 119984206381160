import React, { createContext } from 'react';
import { noop } from 'lodash';

import { DynamicSettings } from '../types';

const Empty = () => <></>;

export const DynamicSettingsContext = createContext<DynamicSettings>({
  headerChildren: <Empty />,
  onBellClick: noop,
  onGridClick: noop,
  onQRCodeClick: noop,
  hasQRCodeFeature: false,
});
