import { omit, find, isUndefined, toNumber } from 'lodash';

import {
  BillingInfo,
  BillingInfoResponseData,
  BillingDetailsPayload,
  BillingDetails,
  BillingInfoPayload,
  AddressResponseData,
  Address,
  Card,
  CardInfo,
  CardType,
  SubscriptionPlan,
  SubscriptionStatus,
  BillingPeriod,
  TrialExpirationNotificationState,
} from 'src/models/billing';
import { SubscriptionResponse, PaymentResponse } from 'src/v2/features/checkout/types';

const tomorrow = new Date().setDate(new Date().getDate() + 1);

export const cardInitialState: CardInfo = {
  brand: null,
  expMonth: '',
  expYear: '',
  funding: '',
  last4: '',
};

const billingDetailsInitialState: BillingDetails = {
  address: {
    city: '',
    country: '',
    line1: '',
    line2: '',
    postalCode: '',
    state: '',
  },
  email: '',
  phone: '',
  firstName: '',
  lastName: '',
};

export const billingInfoInitialState: BillingInfo = {
  activePlan: SubscriptionPlan.Standard,
  subscribedPlan: null,
  state: SubscriptionStatus.Active,
  billingPeriod: BillingPeriod.None,
  validFrom: new Date(),
  validTo: new Date(tomorrow),
  trialStart: new Date(tomorrow),
  trialEnd: new Date(tomorrow),
  billingDetails: billingDetailsInitialState,
  card: cardInitialState,
  trialExpirationPopupState: TrialExpirationNotificationState.None,
  latestInvoiceId: null,
  cancelAtPeriodEnd: false,
  seat: 1,
};

export const normalizeAddress = ({
  city,
  country,
  line1,
  line2,
  postal_code,
  state,
}: AddressResponseData): Address => ({
  city,
  country,
  line1,
  line2,
  postalCode: postal_code,
  state,
});

export const normalizeBillingDetails = ({
  address,
  email,
  first_name,
  last_name,
  phone,
}: BillingDetailsPayload): BillingDetails => ({
  address: normalizeAddress(address),
  email,
  name: '',
  firstName: first_name,
  lastName: last_name,
  phone,
});

export const extractBillingDetails = (
  billingDetails: BillingInfoPayload,
): BillingDetailsPayload => {
  return omit(billingDetails, ['cardNumberElement', 'plan', 'period', 'stripe']);
};

const normalizeMonth = (value: number) => String(value).padStart(2, '0');

const normalizeBrand = (value: string): CardType | null => {
  return find(CardType, (cardType) => cardType === value) || null;
};

export const normalizeCardInfo = ({
  brand,
  exp_month,
  exp_year,
  funding,
  last4,
}: Card): CardInfo => ({
  brand: normalizeBrand(brand),
  expMonth: normalizeMonth(exp_month),
  expYear: exp_year.toString(),
  funding,
  last4,
});

export const normalizeBillingInfo = ({
  active_plan,
  subscribed_plan,
  state,
  billing_period,
  valid_from,
  valid_to,
  trial_start,
  trial_end,
  billing_details,
  card,
  trial_expiration_popup_state,
  latest_invoice,
  is_cancel_at_period_end,
  seat,
}: BillingInfoResponseData): BillingInfo => ({
  activePlan: active_plan,
  subscribedPlan: subscribed_plan,
  state,
  billingPeriod: billing_period,
  validFrom: valid_from,
  validTo: valid_to,
  trialStart: trial_start,
  trialEnd: trial_end,
  billingDetails: normalizeBillingDetails(billing_details),
  card: !isUndefined(card) ? normalizeCardInfo(card) : billingInfoInitialState.card,
  trialExpirationPopupState: trial_expiration_popup_state,
  latestInvoiceId: latest_invoice ? latest_invoice.external_id : null,
  cancelAtPeriodEnd: is_cancel_at_period_end || false,
  seat,
});

export const normalizePrice = (value: number) => (value / 100).toFixed(2);

export const normalizeSeats = (numberOfSeats: number | undefined, planPrice: number) =>
  isUndefined(numberOfSeats) ? planPrice : toNumber(planPrice) / numberOfSeats;

export const normalizeBillingSubscription = ({
  billingPlan,
  billingPeriod,
  invoice,
}: SubscriptionResponse): PaymentResponse => ({
  data: {
    latest_invoice: {
      payment_intent: {
        status: invoice.paymentIntent.status,
        client_secret: invoice.paymentIntent.clientSecret,
      },
    },
  },
});
