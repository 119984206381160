import React, { FC, MouseEventHandler } from 'react';

import { ListItemViewComponentProps } from 'src/v2/modules/FsItem/types';
import { FolderView } from 'src/v2/features/quantumNavigator/components/FolderView';

interface OwnProps {
  onRootClick: MouseEventHandler;
}
export const SharedFolderView: FC<ListItemViewComponentProps & OwnProps> = (props) => {
  return <FolderView {...props} onRootClick={props.onRootClick} />;
};
