import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isUndefined, toNumber, get, isNull, find } from 'lodash';

import { book } from 'src/app/book';
import { BillingDetailsWithPricingUpdatePayload, SubscriptionPlan } from 'src/models/billing';
import { getCurrentOrganizationInfo } from 'src/shared/organization/organizationSelectors';
import { getProfileFormDataFromProfile } from 'src/v2/features/profile';
import { OrganizationFormData, ProfileFormData } from 'src/v2/features/profile/types';
import {
  subscribePaymentSuccess,
  getBillingInfo,
  getSubscriptionBasePrice,
  getSubscriptionTotalPrice,
  normalizeSeats,
  getSubscriptionSalesTax,
  getSelectedPlanAndPeriod,
  getSubscribedPlan,
  getSubscribedPeriod,
} from 'src/v2/features/billing';
import { useIsInOrganization } from 'src/v2/features/organization/hooks';
import { getIsLoading as getBillingLoading } from 'src/v2/features/billing/store';
import { getIsLoading as getOrganizationLoading } from 'src/shared/organization';
import { useToggle } from 'src/common/hooks/useToggle';

import { BillingInfoFormValues, BillingDetailsFormValues } from './types';
import { submitBillingInfoWithPricingUpdate } from './store';
import { getPlanAndPeriod, isNotEmptyString } from './utils';

export const useSubscribeToSuccessfulPaymentEffct = () => {
  const isOrgAccount = useIsInOrganization();
  const navigate = useNavigate();
  useEffect(() => {
    const subscription$ = subscribePaymentSuccess(() => {
      const redirectPath = isOrgAccount ? book.profile.users.pattern : '/';
      navigate(redirectPath);
    });

    return () => subscription$.unsubscribe();
  }, [navigate, isOrgAccount]);
};

const DefaultAddress = {
  city: 'Juneau',
  country: 'US',
  address: '7HWX+GM Downtown Juneau',
  postalCode: '99801',
  state: 'Alaska',
};

const DefaultPersonalInfo = {
  firstName: 'John',
  lastName: 'Doe',
  email: 'noemail@gmail.com',
};

export const useIsDefaultBilling = (billingInfo: BillingInfoFormValues | {}) => {
  const address = get(billingInfo, 'address');

  return !isUndefined(address) && address === DefaultAddress.address;
};

const useIsBillingInfoEntered = (billingDetails: BillingDetailsFormValues) => {
  const { plan, period } = useMemo(
    () => getPlanAndPeriod(billingDetails.planAndPeriod),
    [billingDetails.planAndPeriod],
  );

  return (
    isNotEmptyString(billingDetails.address) &&
    isNotEmptyString(billingDetails.city) &&
    isNotEmptyString(billingDetails.country) &&
    isNotEmptyString(billingDetails.email) &&
    isNotEmptyString(billingDetails.firstName) &&
    isNotEmptyString(billingDetails.lastName) &&
    isNotEmptyString(billingDetails.postalCode) &&
    isNotEmptyString(billingDetails.state) &&
    isNotEmptyString(plan) &&
    isNotEmptyString(period)
  );
};

export const useGetBillingInfoFromProfile = (): BillingInfoFormValues => {
  const personalProfile = useSelector(getProfileFormDataFromProfile) as ProfileFormData;

  const getBilling = useMemo(() => {
    return {
      firstName: personalProfile.firstName || '',
      lastName: personalProfile.lastName || '',
      email: personalProfile.email || '',
      address: personalProfile.address || '',
      city: personalProfile.city || '',
      state: personalProfile.state || '',
      postalCode: personalProfile.zipCode || '',
      country: personalProfile.country || 'US',
    };
  }, [personalProfile]);

  return getBilling;
};

export const useGetBillingInfoFromOrganization = (): BillingInfoFormValues | null => {
  const personalProfile = useSelector(getProfileFormDataFromProfile) as ProfileFormData;
  const organizationProfile = useSelector(getCurrentOrganizationInfo) as OrganizationFormData;

  const getBilling = useMemo(() => {
    if (isUndefined(organizationProfile)) return null;

    return {
      firstName: personalProfile.firstName || '',
      lastName: personalProfile.lastName || '',
      email: personalProfile.email || '',
      address: organizationProfile.address || '',
      city: organizationProfile.city || '',
      state: organizationProfile.state || '',
      postalCode: organizationProfile.zipCode || '',
      country: organizationProfile.country || 'US',
    };
  }, [organizationProfile, personalProfile]);

  return getBilling;
};

export const useGetBillingInfoFromBilling = (): BillingInfoFormValues => {
  const billingInfo = useSelector(getBillingInfo);
  const { billingDetails } = billingInfo;

  const getBilling = useMemo(() => {
    return {
      firstName: billingDetails.firstName || '',
      lastName: billingDetails.lastName || '',
      email: billingDetails.email,
      address: billingDetails.address.line1,
      city: billingDetails.address.city,
      state: billingDetails.address.state,
      postalCode: billingDetails.address.postalCode,
      country: billingDetails.address.country,
    };
  }, [
    billingDetails.address,
    billingDetails.email,
    billingDetails.firstName,
    billingDetails.lastName,
  ]);

  return getBilling;
};

export const usePopulateBillingDetails = (
  autofill: (field: string, value: string) => void,
  submitBillingInfo,
) => {
  const [fillInitialBilling, setFillInitialBilling] = useState(false);
  const billingInfoFromProfile = useGetBillingInfoFromProfile();
  const billingInfoFromOrganization = useGetBillingInfoFromOrganization();
  const billingInfoFromBilling = useGetBillingInfoFromBilling();
  const isDefaultBilling = useIsDefaultBilling(billingInfoFromBilling);
  const selectedPlanAndPeriod = useSelector(getSelectedPlanAndPeriod);
  const isBillingInfoEntered = useIsBillingInfoEntered({
    ...billingInfoFromBilling,
    planAndPeriod: selectedPlanAndPeriod,
  });
  const organizationProfile = useSelector(getCurrentOrganizationInfo) as OrganizationFormData;
  const isOrganizationProfile = !isUndefined(organizationProfile);
  const isBillingLoading = useSelector(getBillingLoading);
  const isOrgAccount = useIsInOrganization();
  const isOrganizationLoading = isOrgAccount && !isOrganizationProfile;
  const isLoading = isOrganizationLoading || isBillingLoading;

  let billingInfo = billingInfoFromProfile;
  if (isBillingInfoEntered && !isDefaultBilling) {
    billingInfo = billingInfoFromBilling;
  } else if (isOrganizationProfile && !isNull(billingInfoFromOrganization)) {
    billingInfo = billingInfoFromOrganization;
  }

  useEffect(() => {
    if (!isLoading && !fillInitialBilling) {
      autofill('firstName', billingInfo.firstName);
      autofill('lastName', billingInfo.lastName);
      autofill('email', billingInfo.email);
      autofill('address', billingInfo.address);
      autofill('city', billingInfo.city);
      autofill('state', billingInfo.state);
      autofill('postalCode', billingInfo.postalCode);
      autofill('country', billingInfo.country);
      setFillInitialBilling(true);
      submitBillingInfo();
    }
  }, [autofill, billingInfo, fillInitialBilling, isLoading, submitBillingInfo]);
};

const useGetBillingData = (billingDetails: BillingDetailsFormValues) => {
  const { plan, period } = useMemo(
    () => getPlanAndPeriod(billingDetails.planAndPeriod),
    [billingDetails.planAndPeriod],
  );
  const numberOfSeats = useMemo(
    () => (isUndefined(billingDetails.numberOfSeats) ? 0 : toNumber(billingDetails.numberOfSeats)),
    [billingDetails.numberOfSeats],
  );
  const isBillingInfoEntered = useIsBillingInfoEntered(billingDetails);
  const billingAddress = isBillingInfoEntered ? billingDetails : DefaultAddress;
  const billingProfile = isBillingInfoEntered ? billingDetails : DefaultPersonalInfo;

  const billingInfo: BillingDetailsWithPricingUpdatePayload = useMemo(
    () => ({
      address: {
        city: billingAddress.city,
        country: billingAddress.country,
        line1: billingAddress.address,
        postal_code: billingAddress.postalCode,
        state: billingAddress.state,
      },
      first_name: billingProfile.firstName,
      last_name: billingProfile.lastName,
      email: billingProfile.email,
      numberOfSeats,
      plan,
      billingPeriod: period,
    }),
    [billingAddress, billingProfile, numberOfSeats, period, plan],
  );

  return billingInfo;
};

const getIsBillingInfoChanged = (
  oldValues: BillingDetailsWithPricingUpdatePayload,
  newValues: BillingDetailsWithPricingUpdatePayload,
): boolean => {
  const addressFieldChanged = find(
    newValues.address,
    (value, key) => value !== oldValues.address[key],
  );
  return (
    oldValues.plan !== newValues.plan ||
    oldValues.numberOfSeats !== newValues.numberOfSeats ||
    oldValues.billingPeriod !== newValues.billingPeriod ||
    !isUndefined(addressFieldChanged)
  );
};

export const useFillBillingData = (formValues) => {
  const [billingAfterLoading, , setBillingAfterLoadingTrue] = useToggle(false);
  const billingInfo: BillingDetailsWithPricingUpdatePayload = useGetBillingData(formValues);
  const [lastSavedBilling, setLastSavedBilling] =
    useState<BillingDetailsWithPricingUpdatePayload | null>(null);
  const dispatch = useDispatch();
  const isBillingInfoEntered = useIsBillingInfoEntered(formValues);
  const isOrgAccount = useIsInOrganization();
  const isDataFilled = isOrgAccount ? isBillingInfoEntered : !isBillingInfoEntered;
  const plan = useSelector(getSubscribedPlan);
  const period = useSelector(getSubscribedPeriod);
  const isBillingLoading = useSelector(getBillingLoading);
  const isOrganizationLoading = useSelector(getOrganizationLoading);
  const isLoading = isBillingLoading || isOrganizationLoading;
  const isBillingInfoChanged = !isNull(lastSavedBilling)
    ? getIsBillingInfoChanged(lastSavedBilling, billingInfo)
    : true;

  const dispatchBillingInfo = useCallback(() => {
    if (isBillingInfoChanged && !isLoading) {
      dispatch(
        submitBillingInfoWithPricingUpdate({
          ...billingInfo,
          plan: plan || billingInfo.plan,
          billingPeriod: period || billingInfo.billingPeriod,
        }),
      );
      setLastSavedBilling(billingInfo);
    }
  }, [billingInfo, dispatch, isBillingInfoChanged, isLoading, period, plan]);

  useEffect(() => {
    if (isDataFilled && !isLoading && !billingAfterLoading) {
      dispatchBillingInfo();
      setBillingAfterLoadingTrue();
    }
  }, [
    dispatchBillingInfo,
    isDataFilled,
    isLoading,
    billingAfterLoading,
    setBillingAfterLoadingTrue,
  ]);

  return dispatchBillingInfo;
};

export const useGetCheckoutInfo = (plan: SubscriptionPlan | null, numberOfSeats: number) => {
  const isStandardIndividualPlan = plan && plan === SubscriptionPlan.Standard;
  const planPriceForSeats = useSelector(getSubscriptionBasePrice);
  const totalPriceForSeats = useSelector(getSubscriptionTotalPrice);
  const tax = useSelector(getSubscriptionSalesTax);
  const pricePerSeat = normalizeSeats(toNumber(numberOfSeats) || 1, toNumber(planPriceForSeats));

  const planPrice = toNumber(planPriceForSeats);
  const totalPrice = toNumber(totalPriceForSeats);
  const quantity = !isStandardIndividualPlan ? numberOfSeats : 1;

  return { pricePerSeat, planPrice, totalPrice, tax, quantity };
};
