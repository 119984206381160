import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getFullNameFromProfile, getPreferredLanguage } from 'src/v2/features/profile/store';
import { getDefaultSignatureId, getUserSignatureList } from 'src/v2/features/signature/store';
import { getSignatureStamps } from 'src/v2/features/hankoSignature/store';
import { shouldUseHanko, mergeSignaturesWithStamps } from 'src/v2/features/hankoSignature/utils';
import { WorkflowAction } from 'src/v2/entities/workflow';
import { workflowAction } from 'src/v2/features/documentDetails/documentDetailsSlice';
import { SignContractButton } from 'src/v2/components/SignContractButton';

export const EntitySignatureButton: React.FC = () => {
  const [signatureId, setSignatureId] = useState('');
  const [showSelectSignature, setShowSelectSignature] = useState(false);
  const [showSignConfirm, setShowSignConfirm] = useState(false);

  const dispatch = useDispatch();
  const userLanguage = useSelector(getPreferredLanguage);
  const userSignatures = useSelector(getUserSignatureList) || [];
  const userStamps = useSelector(getSignatureStamps) || [];
  const userSignaturesWithStamps = shouldUseHanko(userLanguage)
    ? mergeSignaturesWithStamps(userSignatures, userStamps)
    : userSignatures;
  const defaultSignatureId = useSelector(getDefaultSignatureId);
  const fullName = useSelector(getFullNameFromProfile);

  const handleSign = useCallback((): void => {
    const payload = { signatureId: signatureId || defaultSignatureId };
    dispatch(workflowAction({ action: WorkflowAction.Sign, payload }));
    setShowSignConfirm(false);
  }, [defaultSignatureId, dispatch, signatureId]);

  const handleSignConfirm = useCallback((): void => {
    if (userSignatures.length > 1) {
      setShowSelectSignature(true);
    } else {
      setShowSignConfirm(true);
    }
  }, [setShowSelectSignature, setShowSignConfirm, userSignatures.length]);

  const handleSelectSignature = useCallback(
    (id: string): void => {
      setShowSelectSignature(false);
      setShowSignConfirm(true);
      setSignatureId(id);
    },
    [setShowSelectSignature, setShowSignConfirm, setSignatureId],
  );

  const handleSignCancel = useCallback((): void => {
    setShowSelectSignature(false);
    setShowSignConfirm(false);
  }, [setShowSelectSignature, setShowSignConfirm]);

  return (
    <SignContractButton
      signatures={userSignaturesWithStamps}
      defaultSignatureId={defaultSignatureId}
      fullName={fullName}
      showSelectSignature={showSelectSignature}
      onSelectSignature={handleSelectSignature}
      showSignConfirm={showSignConfirm}
      onSignConfirm={handleSignConfirm}
      onSignCancel={handleSignCancel}
      onSign={handleSign}
    />
  );
};
