import React from 'react';

import { ContractOwnerSide } from './components/ContractOwnerSide';
import { ContractCounterpartySide } from './components/ContractCounterpartySide';

interface Props {
  id: string;
}

export const ContractNegotiationContent: React.FC<Props> = ({ id }) => {
  return (
    <div className="c-letter c-letter__side c-letter__side--compare">
      <ContractOwnerSide id={id} />
      <ContractCounterpartySide id={id} />
    </div>
  );
};
