// Core
import React, { ReactElement } from 'react';
import { Box, Label } from '@theme-ui/components';
import { WrappedFieldProps } from 'redux-form';
import { BoxOwnProps } from 'theme-ui';
import { WrappedFieldInputProps } from 'redux-form/lib/Field';
import newId from 'src/utils/newId';
import ErrorMessage from '../ErrorMessage';

type GenericInputProps = {
  id?: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
};

export type SelectOptionType = {
  label: string;
  value: string;
  disabled?: boolean;
};

export type SelectProps = {
  options?: SelectOptionType[];
  children?: ReactElement;
};

export type CheckboxProps = {
  label?: string;
};

type FieldProps = GenericInputProps & SelectProps & CheckboxProps & BoxOwnProps;

export type WithReduxFormProps = FieldProps & WrappedFieldProps;

export type WrappedReduxFieldProps = FieldProps & WrappedFieldInputProps;

const withReduxForm =
  (
    Component: React.ComponentType<WrappedReduxFieldProps>,
    options?: {
      omitLabel: boolean;
    },
  ): React.ComponentType<WithReduxFormProps> =>
  (props: WithReduxFormProps): ReactElement => {
    const id = props.id || newId();
    const { label, meta, input, ...otherProps } = props;

    return (
      <Box sx={props.sx}>
        {(!options || !options.omitLabel) && (
          <Label htmlFor={id}>
            {label || ''}
            {meta.touched && <ErrorMessage message={meta.error} />}
          </Label>
        )}
        <Component {...input} {...otherProps} id={id} label={label}>
          {props.children}
        </Component>
      </Box>
    );
  };

export default withReduxForm;
