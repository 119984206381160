import React from 'react';
import { Box, Button, Text } from '@theme-ui/components';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

const styles = {
  container: {
    minWidth: '180px',
    flex: 1,
  },

  headerText: {
    color: '#4d6982',
    fontSize: '25px',
    fontWeight: 'bold',
    lineHeight: 1.2,
  },

  mainText: {
    color: '#4d6982',
    fontSize: '25px',
  },

  buttons: {
    display: 'flex',
    mt: '14px',
  },

  confirmBtn: {
    color: 'white',
    backgroundColor: '#29cb97',
    border: 'none',
    borderRadius: '5px',
  },

  cancelBtn: {
    color: 'white',
    backgroundColor: '#d57872',
    border: 'none',
    borderRadius: '5px',
    ml: '10px',
  },
};

interface Props {
  onConfirm: () => void;
  onClose: () => void;
}

export const ConfirmSign = ({ onConfirm, onClose }: Props): JSX.Element => (
  <Box sx={styles.container} data-type="sign-contract-confirmation-popup">
    <Text sx={styles.headerText}>{i18n(translationKeys.forms.item.signLegalContract)}</Text>
    <Text sx={styles.mainText}>{i18n(translationKeys.forms.item.wishToSign)}</Text>
    <Box sx={styles.buttons}>
      <Button onClick={onConfirm} sx={styles.confirmBtn} data-type="sign-contract-confirm-btn">
        {i18n(translationKeys.buttons.yes)}
      </Button>
      <Button onClick={onClose} sx={styles.cancelBtn} data-type="sign-contract-cancel-btn">
        {i18n(translationKeys.buttons.no)}
      </Button>
    </Box>
  </Box>
);
