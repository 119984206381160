import React from 'react';

export const PoweredBy = () => (
  <div className="c-logo__docuwalk">
    <div className="c-logo__text">Powered By:</div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400.2 100">
      <g transform="translate(52.56 1.246)">
        <linearGradient
          id="a"
          gradientUnits="userSpaceOnUse"
          x1="-160.766"
          y1="429.64"
          x2="-160.766"
          y2="427.921"
          gradientTransform="matrix(35.3 0 0 -47.121 5802.865 20244.285)"
        >
          <stop offset="0" stopColor="#374453" />
          <stop offset="1" stopColor="#55667e" />
        </linearGradient>
        <path fill="url(#a)" d="M97.5-.8l28 81h32.7l-28-81H97.5z" />
        <linearGradient
          id="b"
          gradientUnits="userSpaceOnUse"
          x1="-161.198"
          y1="430.833"
          x2="-161.198"
          y2="429.113"
          gradientTransform="matrix(33.921 0 0 -56.607 5543.334 24387.332)"
        >
          <stop offset="0" stopColor="#374453" />
          <stop offset="1" stopColor="#55667e" />
        </linearGradient>
        <path fill="url(#b)" d="M46.2-.8l25.7 97.3h32.7L78.9-.8H46.2z" />
        <g>
          <linearGradient
            id="c"
            gradientUnits="userSpaceOnUse"
            x1="-159.986"
            y1="429.64"
            x2="-159.986"
            y2="427.921"
            gradientTransform="matrix(32.538 0 0 -47.121 5359.128 20244.285)"
          >
            <stop offset="0" stopColor="#374453" />
            <stop offset="1" stopColor="#7e8fa5" />
          </linearGradient>
          <path fill="url(#c)" d="M158.2 80.2h-32.7l23.3-81h32.7l-23.3 81z" />
          <linearGradient
            id="d"
            gradientUnits="userSpaceOnUse"
            x1="-160.881"
            y1="430.833"
            x2="-160.881"
            y2="429.113"
            gradientTransform="matrix(33.919 0 0 -56.607 5557.932 24387.33)"
          >
            <stop offset="0" stopColor="#374453" />
            <stop offset="1" stopColor="#7e8fa5" />
          </linearGradient>
          <path fill="url(#d)" d="M104.5 96.5H71.8L97.5-.8h32.7l-25.7 97.3z" />
          <linearGradient
            id="e"
            gradientUnits="userSpaceOnUse"
            x1="-160.132"
            y1="424.91"
            x2="-160.132"
            y2="423.19"
            gradientTransform="matrix(26.461 0 0 -28.3 4293.346 12024.142)"
          >
            <stop offset="0" stopColor="#374453" />
            <stop offset="1" stopColor="#7e8fa5" />
          </linearGradient>
          <path fill="url(#e)" d="M66 47.9H33.4L46.2-.8h32.7L66 47.9z" />
        </g>
      </g>
      <g transform="translate(173.308)">
        <linearGradient
          id="f"
          gradientUnits="userSpaceOnUse"
          x1="-262.493"
          y1="410.663"
          x2="-270.786"
          y2="404.99"
          gradientTransform="matrix(11.8978 0 0 -12.2099 3337.843 5016.602)"
        >
          <stop offset="0" stopColor="#7e8fa5" />
          <stop offset="1" stopColor="#374453" />
        </linearGradient>
        <path
          fill="url(#f)"
          d="M190.1 28.8c3.9.7 7.9-.9 10.2-4.1l-6.8-1.1c-2.8 1-5.8-.5-6.8-3.3s.5-5.8 3.3-6.8c1.6-.5 3.3-.3 4.7.6l7.1 1.1c-1.8-5.5-7.7-8.5-13.2-6.8-5.5 1.8-8.5 7.7-6.8 13.2 1.2 3.8 4.4 6.6 8.3 7.2z"
        />
        <linearGradient
          id="g"
          gradientUnits="userSpaceOnUse"
          x1="-280.777"
          y1="431.755"
          x2="-282.685"
          y2="430.565"
          gradientTransform="matrix(51.725 0 0 -58.1541 14755.304 25135.467)"
        >
          <stop offset="0" stopColor="#7e8fa5" />
          <stop offset="1" stopColor="#374453" />
        </linearGradient>
        <path
          fill="url(#g)"
          d="M189.4 47.2c10.4 5.6 23.3 4.1 32.2-3.8l-3.5-3.9c-7.1 6.4-17.4 7.6-25.9 3.2.5-.8 1.1-1.5 1.7-2.2h.2c7.3 3.5 16.1 2.3 22.2-3.1l-3.5-3.9c-4.2 4.3-10.7 5.5-16.1 2.8 9.9-2.6 15.7-12.8 13.1-22.6S196.9-2 187 .6c-9.9 2.6-15.7 12.8-13.1 22.6.6 2.3 1.7 4.4 3.1 6.3-14.4-1.1-30.1 4.8-39.1 18l4.4 2.9c10.3-15.3 30.3-20.7 46.9-12.7-5.3 6.8-8.8 14.9-10 23.5l-.3 2.2 2.2.6c15.9 5 26.7 19.5 26.8 36h5.3c0-9.3-3-18.3-8.7-25.7-5-6.6-11.8-11.6-19.6-14.4.3-1.8.8-3.6 1.4-5.3 13.3 6.8 29.5 4.8 40.7-5.2l-3.5-3.9c-9.7 8.7-23.8 10.3-35.3 4.2.3-.8.8-1.7 1.2-2.5m-10.7-30.8c1.2-7.2 7.9-12.1 15.1-11 6.3 1 11 6.5 11.1 12.9L192 16.2c-1.2-.2-2.4.6-2.6 1.9-.2 1.2.6 2.4 1.9 2.6l12.9 2.1c-2.4 6.9-9.9 10.6-16.8 8.2-6-2.1-9.7-8.3-8.7-14.6z"
        />
        <linearGradient
          id="h"
          gradientUnits="userSpaceOnUse"
          x1="-279.928"
          y1="428.524"
          x2="-282.012"
          y2="426.633"
          gradientTransform="matrix(47.3691 0 0 -36.5741 13495.515 15705.038)"
        >
          <stop offset="0" stopColor="#7e8fa5" />
          <stop offset="1" stopColor="#374453" />
        </linearGradient>
        <path
          fill="url(#h)"
          d="M177.1 66c-.5-.1-.9-.5-.9-.9.4-8.1 2.7-16.1 6.7-23.2l1.8-3-3.4-.8c-6.7-1.6-13.8-1.2-20.3 1.2-6.7 2.5-12.4 7-16.3 12.9l4.4 2.9c6.1-9.1 16.8-13.9 27.6-12.5-.4.8-.8 1.7-1.2 2.5-9.6-.8-18.8 3.6-24.2 11.5l4.4 2.9c2.3-3.4 5.5-6 9.2-7.6-.8 3.3-1.4 6.6-1.7 9.9-8.2 3.6-17.7 1.6-23.9-4.9l-3.8 3.6c7.1 7.5 17.9 10.2 27.7 6.9.1.9.4 1.8.7 2.7-10.8 3.6-22.6.6-30.4-7.7l-3.8 3.6c9.5 10 24.1 13.5 37 8.7.7.8 1.5 1.5 2.4 2-14.3 5.9-30.8 2.4-41.4-8.8l-3.8 3.6c13.5 14.3 35 17.5 52 7.8 9.5 2.3 16.1 10.8 16.2 20.6h5.3c0-12.6-8.9-23.4-21.2-25.9-4.8-.6-8.3-4.9-7.7-9.8.3-4.7 1.2-9.4 2.6-13.9.9-.1 1.9-.2 2.8-.1-1.5 4.7-2.4 9.6-2.6 14.6-.1 3.1 2 5.8 5.1 6.4C189.7 74 199.7 86 199.9 100h5.3c0-8.1-2.9-15.9-8.1-22-5.2-6.1-12.3-10.3-20-12z"
        />
      </g>
      <g fill="#7E8FA5">
        <path d="M13.4 42.138c2.8 0 5.1.5 6.9 1.4 1.7.9 3.1 2.3 4.1 4 1 1.7 1.6 3.8 2 6.2.3 2.4.5 5.1.5 8.1 0 3.6-.2 6.6-.7 9.3-.4 2.6-1.2 4.8-2.2 6.5-1.1 1.7-2.5 3-4.3 3.8-1.8.8-4 1.2-6.8 1.2H0v-40.5h13.4zm-1.6 34.5c1.4 0 2.6-.2 3.5-.7.9-.5 1.6-1.3 2.1-2.4.5-1.1.9-2.6 1-4.5.2-1.9.3-4.2.3-6.9 0-2.3-.1-4.3-.2-6.1s-.5-3.2-.9-4.3c-.5-1.2-1.2-2-2.1-2.6-.9-.6-2.2-.9-3.7-.9H8.3v28.5h3.5zM31.6 60.438c.4-2 1.1-3.7 2-5 .9-1.3 2.2-2.4 3.7-3.1 1.5-.7 3.4-1 5.6-1 2.3 0 4.3.3 5.8.9 1.6.7 2.8 1.7 3.7 3 .9 1.4 1.5 3 1.9 5.1.4 2 .6 4.4.6 7 0 2.6-.2 4.9-.6 6.9-.4 2-1.1 3.7-2 5-.9 1.3-2.2 2.3-3.7 3-1.5.6-3.4 1-5.6 1-2.3 0-4.3-.3-5.8-.9-1.6-.6-2.8-1.6-3.7-3-.9-1.3-1.6-3-1.9-5.1-.4-2-.6-4.4-.6-7-.1-2.5.1-4.8.6-6.8zm7.3 11.2c.1 1.3.3 2.5.5 3.4.3.9.7 1.7 1.3 2.2.6.5 1.3.8 2.3.8 1.6 0 2.7-.8 3.3-2.5.6-1.7.9-4.4.9-8.2 0-3.8-.3-6.6-.9-8.2-.6-1.7-1.7-2.5-3.3-2.5-.9 0-1.7.3-2.3.8-.6.5-1 1.3-1.3 2.2-.3.9-.5 2.1-.5 3.4-.1 1.3-.1 2.8-.1 4.3s0 3 .1 4.3zM73.7 60.838c-.1-.7-.3-1.4-.5-2-.2-.6-.6-1.1-1-1.4-.5-.4-1.1-.5-1.8-.5-.9 0-1.7.2-2.3.7-.6.4-1.1 1.1-1.4 1.9-.3.8-.5 1.9-.6 3.2-.1 1.3-.2 2.8-.2 4.6 0 1.9.1 3.5.2 4.9.1 1.3.3 2.4.6 3.3.3.9.7 1.5 1.3 1.9.5.4 1.3.6 2.1.6 1.3 0 2.3-.6 2.9-1.7.6-1.1.9-3 .9-5.4h7.5c0 4-.9 7.1-2.7 9.2-1.8 2.1-4.8 3.2-8.9 3.2-2 0-3.7-.2-5.1-.7-1.5-.5-2.7-1.3-3.6-2.6-1-1.2-1.7-2.9-2.2-4.9-.5-2.1-.7-4.6-.7-7.7 0-3.1.3-5.7.9-7.8.6-2.1 1.5-3.7 2.6-4.9 1.1-1.2 2.4-2.1 3.9-2.6s3.2-.7 5-.7c3.6 0 6.4 1.1 8.2 3.2 1.8 2.1 2.8 5 2.8 8.6h-7.5c-.2-.9-.3-1.6-.4-2.4zM101 79.038c-.8 1.5-1.9 2.6-3.2 3.2-1.3.6-2.8 1-4.5 1-2.5 0-4.4-.7-5.8-2-1.3-1.3-2-3.5-2-6.6v-22.6h7.8v21.1c0 1.6.3 2.7.8 3.3.5.6 1.4.9 2.6.9 2.8 0 4.1-1.7 4.1-5v-20.3h7.8v30.5h-7.5v-3.6h-.1z" />
      </g>
      <g fill="#637287">
        <path d="M228.6 45.838c.6-1.2 1.4-2.3 2.5-3.1 1-.8 2.3-1.4 3.7-1.8 1.4-.4 3-.6 4.7-.6 1.3 0 2.6.1 3.9.4 1.3.2 2.4.7 3.5 1.4 1 .7 1.9 1.6 2.5 2.9.6 1.2 1 2.8 1 4.8v15.5c0 1.4.7 2.2 2.1 2.2.4 0 .8-.1 1.1-.2v3c-.4.1-.8.1-1.1.2-.3 0-.7.1-1.2.1-.9 0-1.6-.1-2.2-.4-.5-.2-1-.6-1.3-1-.3-.5-.5-1-.6-1.6-.1-.6-.1-1.3-.1-2.1h-.1c-.6.9-1.3 1.8-2 2.5s-1.4 1.4-2.2 1.8c-.8.5-1.7.9-2.8 1.1-1 .3-2.3.4-3.7.4-1.4 0-2.6-.2-3.8-.5-1.2-.3-2.2-.8-3.1-1.5-.9-.7-1.6-1.6-2.1-2.7s-.8-2.4-.8-3.8c0-2 .5-3.6 1.4-4.8.9-1.2 2.1-2 3.6-2.6 1.5-.6 3.2-1 5.1-1.3 1.9-.2 3.8-.5 5.7-.7.8-.1 1.4-.2 2-.3.6-.1 1-.3 1.4-.6.4-.3.7-.7.9-1.2.2-.5.3-1.1.3-1.9 0-1.2-.2-2.2-.6-3-.4-.8-.9-1.4-1.6-1.8-.7-.5-1.5-.8-2.4-.9-.9-.2-1.9-.3-3-.3-2.3 0-4.1.5-5.6 1.6-1.4 1.1-2.2 2.8-2.3 5.2h-3.6c-.2-1.7.2-3.1.8-4.4zm17.9 8.7c-.2.4-.7.7-1.3.9-.6.2-1.2.3-1.7.4-1.5.3-3.1.5-4.7.7-1.6.2-3.1.5-4.4.9-1.3.4-2.4 1-3.3 1.8-.9.8-1.3 1.9-1.3 3.3 0 .9.2 1.7.5 2.4.4.7.8 1.3 1.4 1.8.6.5 1.3.9 2.1 1.2.8.3 1.6.4 2.4.4 1.4 0 2.7-.2 3.9-.6 1.2-.4 2.3-1 3.3-1.8.9-.8 1.7-1.8 2.2-2.9.5-1.1.8-2.4.8-3.9v-4.7h.1zM260.9 30.038v40.5h-3.6v-40.5h3.6zM271.4 30.038v25.4l16.2-14.2h4.8l-12.5 10.8 13.3 18.4h-4.5l-11.6-15.9-5.8 4.8v11.1h-3.6v-40.4h3.7z" />
      </g>
    </svg>
  </div>
);
