import React, { Component, ComponentType } from 'react';
import { isEqual } from 'lodash';
import update from 'immutability-helper';

import { Section } from 'src/models/document';

import { reindex } from '../utils';
import { EditableProps, SortableProps } from '../types';

interface State {
  isSorting: boolean;
  sorting: Sorting | null;
}

interface Sorting {
  from: number;
  to: number;
}

export const withSortableSections = (
  WrappedComponent: ComponentType<EditableProps & SortableProps>,
): ComponentType<EditableProps> =>
  class HOC extends Component<EditableProps, State> {
    state: State = {
      isSorting: false,
      sorting: null,
    };

    handleSetIsSorting = (isSorting: boolean): void => {
      this.setState({ isSorting });
    };

    handleSortSections = (from: number, to: number): void => {
      const sorting: Sorting = { from, to };

      if (!isEqual(this.state.sorting, sorting)) this.setState({ sorting });
    };

    handleResetSorting = (): void => {
      if (this.state.sorting) this.setState({ sorting: null });
    };

    handleApplySorting = (onMove: (from: number, to: number) => void): void => {
      const { sorting } = this.state;

      if (sorting) {
        onMove(sorting.from, sorting.to);
        this.handleResetSorting();
      }
    };

    getSortedSections = (): Section[] => {
      const sections = reindex(this.props.sections);
      const { sorting } = this.state;

      if (!sorting) return sections;

      return update(sections, {
        $splice: [
          [sorting.from, 1],
          [sorting.to, 0, sections[sorting.from]],
        ],
      });
    };

    render(): JSX.Element {
      return (
        <WrappedComponent
          {...this.props}
          sections={this.getSortedSections()}
          isSorting={this.state.isSorting}
          setIsSorting={this.handleSetIsSorting}
          sortSections={this.handleSortSections}
          applySorting={this.handleApplySorting}
          resetSorting={this.handleResetSorting}
        />
      );
    }
  };
