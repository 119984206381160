import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isNull } from 'lodash';

import { RootState, ModalV2State } from 'src/app/types';
import { ModalType } from 'src/v2/features/modal/types';

const initialState: ModalV2State = {
  type: null,
  className: '',
};

const modalSlice = createSlice({
  name: 'modalSlice',
  initialState,
  reducers: {
    openModal: (
      state: ModalV2State,
      action: PayloadAction<{
        type: ModalType;
        wrapperClassName?: string;
      }>,
    ) => {
      state.type = action.payload.type;
      state.className = action.payload.wrapperClassName;
    },
    closeModal: (state: ModalV2State) => {
      state.type = null;
      state.className = '';
    },
  },
});

export const modalReducer = modalSlice.reducer;
export const { openModal, closeModal } = modalSlice.actions;

const getState = (state: RootState) => state.modalV2;
export const getIsOpen = createSelector(getState, (state) => !isNull(state.type));
export const getType = createSelector(getState, (state) => state.type);
export const getClassName = createSelector(getState, (state) => state.className);
