import { createSelector } from '@reduxjs/toolkit';

import { getObject } from 'src/v2/features/objectsStorage/objectsStorageSlice';
import { getUserId } from 'src/shared/auth';
import { RootState } from 'src/app/types';

import { ChatModel, ChatMember, ChatMessage, NormalizedChat, Room } from '../types';

const getState = (state: RootState) => state.chat;
export const getIsLoading = createSelector(getState, (state) => state.isLoading);
export const getIsSending = createSelector(getState, (state) => state.isSending);
export const getIsDisconnected = createSelector(getState, (state) => state.isDisconnected);
export const getCurrentMessage = createSelector(getState, (state) => state.currentMessage);

export const getShouldStickToBottom = createSelector(
  getState,
  (state) => state.shouldStickToBottom,
);

export const currentChatIdSelector = createSelector(
  getState,
  (state) => state.currentChatBase && state.currentChatBase.id,
);

export const subscribedRoomPayloadSelector = createSelector(
  getState,
  (state) => state.subscribedRoomPayload,
);

export const currentRoomSelector = createSelector(
  (state: RootState) => getState(state).currentRoom,
  (state: RootState) => state.objectsStorage.objects.room,
  (state: RootState) => state.objectsStorage.objects.chat,
  (state: RootState) => state.objectsStorage.objects.member,
  (roomPayload, room, chat, member): Room | null =>
    getObject<Room>(roomPayload, {
      room,
      chat,
      member,
    }),
);

export const currentChatSelector = createSelector(
  (state: RootState) => getState(state).currentChatBase,
  (state: RootState) => state.objectsStorage.objects.chat,
  (state: RootState) => state.objectsStorage.objects.member,
  (chatBase, chat, member): ChatModel | null =>
    getObject<ChatModel>(chatBase, {
      chat,
      member,
    }),
);

export const getTotalMessages = createSelector(
  currentChatSelector,
  (chat): number | null => chat && chat.messagesCount,
);

export const defaultChatSelector = createSelector(currentRoomSelector, (room): ChatModel | null => {
  if (!room || !room.chats || room.chats.length === 0) {
    return null;
  }

  let defaultChat: ChatModel = room.chats[0];

  room.chats.forEach((currChat) => {
    const defaultHasUnread = defaultChat.newCount > 0;
    const currHasUnread = currChat.newCount > 0;

    // when both chats don't have unread - showing with latest activity
    if (!defaultHasUnread && !currHasUnread && currChat.lastActivity > defaultChat.lastActivity) {
      defaultChat = currChat;
      // if both chats have unread msgs - showing the one with earlier lastest activity
    } else if (
      defaultHasUnread &&
      currHasUnread &&
      currChat.lastActivity < defaultChat.lastActivity
    ) {
      defaultChat = currChat;
      // one has unread other don't - showing the one which has
    } else if (!defaultHasUnread && currHasUnread) {
      defaultChat = currChat;
    }
  });

  return defaultChat;
});

export const currentChatMessages = (state: RootState): ChatMessage[] =>
  getState(state).currentChatMessages;

export const getOppositeMemberPure = (
  chat: ChatModel | null,
  userId: string | null,
): ChatMember | null => {
  if (!chat || !userId) {
    return null;
  }

  const otherMembers = chat.members.filter((member) => member.id !== userId);

  if (otherMembers.length === 0) {
    console.error('Empty chat met');
    return null;
  }

  if (otherMembers.length > 1) {
    console.error('Met chat with more than 2 members - unhandled scenario');
    return null;
  }

  return otherMembers[0];
};

export const getOppositeMemberSelector = createSelector(
  currentChatSelector,
  getUserId,
  getOppositeMemberPure,
);

export const availableChatsSelector = createSelector(
  currentRoomSelector,
  getUserId,
  (room, userId): NormalizedChat[] => {
    if (!room || !room.chats || !userId) {
      return [];
    }

    return room.chats.reduce<NormalizedChat[]>((result, chat): NormalizedChat[] => {
      if (!chat.members) {
        console.error('Empty chat met');
        return result;
      }

      const oppositeMember = getOppositeMemberPure(chat, userId);
      if (!oppositeMember) {
        return result;
      }

      result.push({ ...chat, oppositeMember });

      return result;
    }, []);
  },
);
