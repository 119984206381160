import { useCallback } from 'react';
import { flow } from 'lodash';

import {
  InsertSectionPayload,
  UpdateSectionPayload,
  MoveSectionPayload,
  RemoveSectionPayload,
} from 'src/models/entity';
import { withProps } from 'src/utils/hocs';

import { withAsyncState } from './withAsyncState';
import { insert, update, remove, reindex, setPending } from '../utils';
import { AsyncEditableProps, AsyncSectionsProps } from '../types';

export const withAsyncSections = flow([
  withProps<AsyncEditableProps, AsyncSectionsProps>(
    ({
      isRunning,
      addActionToQueue,
      insertSection,
      updateSection,
      moveSection,
      removeSection,
    }: AsyncEditableProps) => {
      const handleInsertSection = useCallback(
        (payload: InsertSectionPayload): void => {
          const { index } = payload;
          addActionToQueue(
            (): Promise<{}> => insertSection(payload),
            (sections) => reindex(insert(sections, index)),
          );
        },
        [insertSection, addActionToQueue],
      );

      const handleUpdateSection = useCallback(
        (payload: UpdateSectionPayload): void => {
          const { index, content } = payload;
          addActionToQueue(
            (): Promise<{}> => updateSection(payload),
            (sections) => update(sections, index, content),
          );
        },
        [updateSection, addActionToQueue],
      );

      const handleMoveSection = useCallback(
        (payload: MoveSectionPayload): void => {
          const { target } = payload;
          // Don't use move util here as sections are already pre-sorted by withSortableSections
          addActionToQueue(
            (): Promise<{}> => moveSection(payload),
            (sections) => reindex(setPending(sections, target)),
          );
        },
        [moveSection, addActionToQueue],
      );

      const handleRemoveSection = useCallback(
        (payload: RemoveSectionPayload): void => {
          const { index } = payload;
          addActionToQueue(
            (): Promise<{}> => removeSection(payload),
            (sections) => reindex(remove(sections, index)),
          );
        },
        [removeSection, addActionToQueue],
      );

      return {
        isRunning,
        insertSection: handleInsertSection,
        updateSection: handleUpdateSection,
        moveSection: handleMoveSection,
        removeSection: handleRemoveSection,
      };
    },
  ),
  withAsyncState,
]);
