import React, { FC } from 'react';

import { SidebarNodeContextProvider } from './providers/SidebarNodeContextProvider';
import { DynamicSettingsContextProviderForShared } from './providers/DynamicSettingsContextProviderForShared';
import { DocumentListPageWithoutContext } from './DocumentListPageWithoutContext';

export const DocumentSharedListPage: FC = () => (
  <SidebarNodeContextProvider>
    <DynamicSettingsContextProviderForShared>
      <DocumentListPageWithoutContext />
    </DynamicSettingsContextProviderForShared>
  </SidebarNodeContextProvider>
);
