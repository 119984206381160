// Core
import React from 'react';
// Components
import { sidebarControllerFactory } from 'src/v2/features/sidebarController';
import { ParsedQuery } from 'query-string';
import { useUrlManagerHash } from 'src/common/hooks/urlManager';
import { book } from './book';
import { SearchSideBarView } from './SearchView';

const sidebarName = 'searchSidebar';

const [, , , Sidebar] = sidebarControllerFactory(sidebarName, [
  {
    path: book.search,
    render: SearchSideBarView,
  },
]);

const useNormalizeValues = (): ParsedQuery<string> => {
  const [hash] = useUrlManagerHash();
  const { v, id, ...rest } = hash;

  const normalizedV = v || book.default;

  return {
    ...rest,
    v: normalizedV,
    userId: id,
  };
};

export const SearchSidebar: React.FC = () => {
  const criteria = useNormalizeValues();

  return <Sidebar criteria={criteria} />;
};
