import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { HankoActionContext } from '../contexts/HankoActionContext';
import { getIsHankoEditing, getIsHankoDisabled, getIsLoading } from '../store';
import { useSubmitHankoHandler } from '../hooks';

export const HankoActionContextProvider: FC = ({ children }) => {
  const isEditing = useSelector(getIsHankoEditing);
  const isDisabled = useSelector(getIsHankoDisabled);
  const isLoading = useSelector(getIsLoading);
  const onSubmit = useSubmitHankoHandler();

  const ctx = {
    isEditing,
    isDisabled,
    isLoading,
    onSubmit,
  };

  return <HankoActionContext.Provider value={ctx}>{children}</HankoActionContext.Provider>;
};
