import React, { ReactEventHandler, useCallback } from 'react';
import { isUndefined } from 'lodash';

import { useToggle } from 'src/common/hooks/useToggle';

import { ListItemComponentProps } from '../types';

export const FsItem = <CTX extends {}>({
  model,
  loadFolderContent,
  viewComponent: FsItemView,
  checkDefaultOpenState,
  checkIsActive,
  context,
}: ListItemComponentProps<CTX>) => {
  const { id, isLoaded } = model.data;
  const { children } = model;

  const defaultIsOpen = isUndefined(checkDefaultOpenState)
    ? true
    : checkDefaultOpenState(model.data);

  const defaultIsActive = isUndefined(checkIsActive) ? false : checkIsActive(model.data);

  const [isExpanded, toggle] = useToggle(defaultIsOpen);

  const handleToggleExpand: ReactEventHandler = useCallback(
    (e) => {
      e.stopPropagation();

      if (!isLoaded) {
        loadFolderContent(id);
      }
      toggle();
    },
    [isLoaded, id, loadFolderContent, toggle],
  );

  return (
    <FsItemView
      onToggleExpand={handleToggleExpand}
      model={model}
      isExpanded={isExpanded}
      isLoaded={isLoaded}
      isActive={defaultIsActive}
      context={context}
    >
      {children.map((childModel) => (
        <FsItem<CTX>
          model={childModel}
          key={`model-${childModel.data.id}`}
          loadFolderContent={loadFolderContent}
          viewComponent={FsItemView}
          checkDefaultOpenState={checkDefaultOpenState}
          checkIsActive={checkIsActive}
          context={context}
        />
      ))}
    </FsItemView>
  );
};
