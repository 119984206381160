import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isNull } from 'lodash';

import { ConfirmSection } from 'src/v2/components/ConfirmSection';
import { useDispatch } from 'react-redux';
import { book } from 'src/app/book';
import { removeUser } from 'src/v2/features/organization';

interface Props {
  userId: string | null;
}

export const ConfirmDelete: React.FC<Props> = ({ userId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isNull(userId)) navigate(-1);
  }, [navigate, userId]);

  const goBack = useCallback(() => {
    navigate(book.profile.users.generatePath());
  }, [navigate]);

  const removeUserHandler = useCallback(() => {
    if (!isNull(userId)) {
      dispatch(removeUser(userId));
      goBack();
    }
  }, [dispatch, userId, goBack]);

  return (
    <ConfirmSection
      message={'Remove user from organization?'}
      onConfirm={removeUserHandler}
      onClose={goBack}
    />
  );
};
