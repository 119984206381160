import { FC, createRef, useCallback, useEffect } from 'react';
import { Box } from '@theme-ui/components';
import { useSelector, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { getUserId } from 'src/shared/auth';

import { MessageItem } from './MessageItem';
import {
  getIsLoading,
  currentChatMessages,
  currentChatSelector,
  chatLoadMore,
  getShouldStickToBottom,
} from './store';
import { ChatMessage } from './types';
import { styles } from './styles';

export const Messages: FC = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const messages = useSelector(currentChatMessages);
  const currentUserId = useSelector(getUserId);
  const currentChat = useSelector(currentChatSelector);
  const shouldStickToBottom = useSelector(getShouldStickToBottom);
  const ref = createRef<HTMLElement>();

  const handleLoadPreviousMessages = useCallback(() => {
    dispatch(chatLoadMore());
  }, [dispatch]);

  useEffect(() => {
    if (shouldStickToBottom && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [ref, shouldStickToBottom]);

  if (isLoading)
    return <Box sx={styles.loader}>{i18n(translationKeys.messages.loadingMessages)}</Box>;

  if (!currentChat || !messages || !messages.length) return null;

  return (
    <Box className="c-message__row">
      <InfiniteScroll
        className="c-message__list"
        pageStart={1}
        isReverse={true}
        initialLoad={false}
        loadMore={handleLoadPreviousMessages}
        hasMore={currentChat!.messagesCount > messages.length}
        loader={
          <Box sx={styles.loader}>{i18n(translationKeys.messages.loadingPreviousMessages)}</Box>
        }
        useWindow={false}
      >
        {messages.map((item: ChatMessage) => (
          <MessageItem
            key={item.id}
            author={item.author[0]}
            text={item.text}
            createdAt={item.createdAt}
            currentUserId={currentUserId}
          />
        ))}
        <Box ref={ref} />
      </InfiniteScroll>
    </Box>
  );
};
