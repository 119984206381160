import { FC } from 'react';
import { noop, isUndefined } from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';

import { FolderCardPlus } from 'src/v2/icons/FolderCardPlus';
import i18n from 'src/i18n';
import { translationKeys } from '../../../../common/translations';

interface Props {
  onAdd?: () => void;
}

export const ProjectGrid: FC<Props> = ({ onAdd, children }) => (
  <InfiniteScroll loadMore={noop} hasMore={false} useWindow={false} className="c-card c-card__new">
    {!isUndefined(onAdd) && (
      <div className="c-card__item c-card__create" data-type="project-create" onClick={onAdd}>
        <div className="c-card__create--title">
          {i18n(translationKeys.project.createNewProject)}
        </div>
        <div className="c-card__create--icon">
          <FolderCardPlus />
        </div>
      </div>
    )}

    {children}
  </InfiniteScroll>
);
