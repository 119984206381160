import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { SubscriptionPlanLabelMap } from 'src/models/billing';
import { ChevronDown } from 'src/v2/icons/ChevronDown';

import { formatPrice, getPeriodLabelByPeriod } from '../utils';
import { CommonOrderSummaryProps } from '../types';

export const CommonOrderSummary = ({
  currentPlan,
  currentPeriod,
  planPrice,
  tax,
  totalPrice,
  pricePerSeat,
  isLoading,
  showTaxes = true,
  numberOfSeats,
}: CommonOrderSummaryProps): JSX.Element => {
  const plan = currentPlan && i18n(SubscriptionPlanLabelMap[currentPlan]);
  const period = getPeriodLabelByPeriod(currentPeriod);

  return (
    <div className="c-order">
      {isLoading && (
        <>
          <div className="c-order__row c-order__list">
            <div className="c-order__row">
              <b>{i18n(translationKeys.messages.loading)}</b>
            </div>
          </div>
        </>
      )}
      {!isLoading && (
        <>
          <input type="checkbox" name="checkbox" hidden id="c-order__toggle" />
          <label className="c-order__toggle" htmlFor="c-order__toggle">
            {i18n(translationKeys.checkout.paymentDetails)}
            <ChevronDown />
          </label>
          <div className="c-order__content">
            <div className="c-order__row c-order__list">
              <div className="c-order__item">
                <div className="c-order__title">{i18n(translationKeys.checkout.plan)}</div>
                <div className="c-order__price">{plan}</div>
              </div>
              <div className="c-order__item">
                <div className="c-order__title">{i18n(translationKeys.checkout.period)}</div>
                <div className="c-order__price">{period}</div>
              </div>
              <div className="c-order__item">
                <div className="c-order__title">{i18n(translationKeys.checkout.unitPrice)}</div>
                <div className="c-order__price">{formatPrice(pricePerSeat)}</div>
              </div>
              {numberOfSeats ? (
                <div className="c-order__item">
                  <div className="c-order__title">{i18n(translationKeys.checkout.quantity)}</div>
                  <div className="c-order__price">{numberOfSeats}</div>
                </div>
              ) : null}
            </div>
            <div className="c-order__row">
              <div className="c-order__line" />
            </div>
            <div className="c-order__row">
              <div className="c-order__column">
                <div className="c-order__title">
                  {i18n(translationKeys.forms.signUpOrderSummary.fields.subtotal)}
                </div>
              </div>
              <div className="c-order__column">
                <div className="c-order__price">{formatPrice(planPrice)}</div>
              </div>
            </div>
            {showTaxes && (
              <div className="c-order__row">
                <div className="c-order__column">
                  <div className="c-order__title">
                    {i18n(translationKeys.forms.signUpOrderSummary.fields.tax)}
                  </div>
                </div>
                <div className="c-order__column">
                  <div className="c-order__price">{formatPrice(tax)}</div>
                </div>
              </div>
            )}
            <div className="c-order__row">
              <div className="c-order__line" />
            </div>
            <div className="c-order__row">
              <div className="c-order__column">
                <div className="c-order__total">
                  {i18n(translationKeys.forms.signUpOrderSummary.fields.total)}
                </div>
              </div>
              <div className="c-order__column">
                <div className="c-order__cost">{formatPrice(totalPrice)}</div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
