import { createAction } from '@reduxjs/toolkit';
import { RemoveParticipantPayload } from './types';
import { InviteParticipantActionPayload } from 'src/v2/boundary/actionPayload/inviteParticipant';

export const openDocumentRoom = createAction<string>('chat/open_document_room');
export const openContractRoom = createAction<string>('chat/open_contract_room');

export const fetchTemplateParticipants = createAction<string[]>('template/fetchParticipants');

export const fetchDocumentParticipants = createAction<string[]>('document/fetchParticipants');

export const inviteUser = createAction<InviteParticipantActionPayload>(
  'documents/inviteParticipant',
);

export const updateParticipant = createAction<InviteParticipantActionPayload>(
  'document/updateParticipant',
);

export const removeParticipant = createAction<RemoveParticipantPayload>(
  'document/removeParticipant',
);
