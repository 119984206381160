import PSPDFKitWeb from 'pspdfkit';
import Instance, { FormField, List, Annotation } from 'pspdfkit/dist/pspdfkit';
import { isUndefined } from 'lodash';

import { PDFToolbarItems } from './types';

export const getFile = async (
  PSPDFKitInstance: Instance,
  fileName: string,
): Promise<File | null> => {
  const arrayBuffer = await PSPDFKitInstance.exportPDF();
  const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
  const file = new File([blob], fileName, { type: 'application/pdf' });

  return file;
};

const getInkSignatureForField = async (
  PSPDFKitInstance: Instance,
  formField: FormField,
): Promise<Annotation | undefined> => {
  const pagesAnnotations = await Promise.all(
    Array.from({ length: PSPDFKitInstance.totalPageCount }).map((_, pageIndex) =>
      PSPDFKitInstance.getAnnotations(pageIndex),
    ),
  );
  let signatureBoundingBox;
  const signaturePageAnnotations = pagesAnnotations.filter((pageAnnotations) => {
    const signatureWidget = pageAnnotations.find(
      (annotation) => annotation.id === formField.annotationIds.first(),
    );
    if (signatureWidget) {
      signatureBoundingBox = signatureWidget.boundingBox;
      return true;
    }
    return false;
  });

  return (
    signaturePageAnnotations[0] &&
    signaturePageAnnotations[0].find((annotation) => {
      if (
        annotation instanceof PSPDFKitWeb.Annotations.InkAnnotation ||
        annotation instanceof PSPDFKitWeb.Annotations.StampAnnotation
      ) {
        if (annotation.boundingBox.isRectOverlapping(signatureBoundingBox)) {
          return true;
        }
      }
      return false;
    })
  );
};

export const getNumberOfFilledSignatures = async (
  PSPDFKitInstance: Instance,
  list: List<FormField>,
): Promise<number> => {
  const signatures = await Promise.all(
    list.map((item) => getInkSignatureForField(PSPDFKitInstance, item)),
  );
  return signatures.reduce((number: number, item: any) => {
    return isUndefined(item) ? number : number + 1;
  }, 0);
};

export const getIsPDFSigned = async (PSPDFKitInstance: Instance) => {
  const formFields: List<FormField> = await PSPDFKitInstance.getFormFields();

  const signatures = formFields.filter(
    (formField) => formField instanceof PSPDFKitWeb.FormFields.SignatureFormField,
  );
  const signaturesSignedNumber = await getNumberOfFilledSignatures(PSPDFKitInstance, signatures);
  const noSignaturesFieldsInPDF = signatures.size === 0;
  const allSignaturesFieldsAreSigned = signaturesSignedNumber === signatures.size;

  if (noSignaturesFieldsInPDF || !allSignaturesFieldsAreSigned) {
    return false;
  }

  return true;
};

export const setDocumentFieldsEditable = async (
  PSPDFKitInstance: Instance,
  allowEditing: boolean,
): Promise<boolean> => {
  let isFieldsStateChanged = false;
  const formFields: List<FormField> = await PSPDFKitInstance.getFormFields();
  const updatedFormFields = formFields.map((field) => {
    const isSignatureField = field instanceof PSPDFKitWeb.FormFields.SignatureFormField;
    const isAllowedToEditField = isSignatureField || allowEditing;
    isFieldsStateChanged = isFieldsStateChanged || field.readOnly !== !isAllowedToEditField;

    return field.set('readOnly', !isAllowedToEditField);
  });

  if (isFieldsStateChanged) {
    await PSPDFKitInstance.update(updatedFormFields);
  }

  return isFieldsStateChanged;
};

export const disableToolbarButton = (
  button,
  enabledToolbarItems: PDFToolbarItems[] | undefined,
) => {
  if (
    !isUndefined(enabledToolbarItems) &&
    !Object.values(enabledToolbarItems).includes(button.type as PDFToolbarItems)
  ) {
    // @ts-ignore
    button.disabled = true;
  }
  return button;
};

export async function loadPDFWithAuthorization(url, PSPDFKit, token, configuration) {
  const headers = new Headers();
  headers.set('Authorization', `Bearer ${token}`);

  const pdfResponse = await fetch(url, { headers });
  const entity = await pdfResponse.arrayBuffer();
  return PSPDFKit.load({ ...configuration, document: entity });
}

export async function loadPDFWithoutAuthorization(url, PSPDFKit, token, configuration) {
  const headers = new Headers();
  const pdfResponse = await fetch(url, { headers });
  const entity = await pdfResponse.arrayBuffer();
  return PSPDFKit.load({ ...configuration, document: entity });
}
