import React, { FC } from 'react';

import { DynamicSettingsContext } from 'src/v2/modules/layout';
import { DynamicSettings } from 'src/v2/modules/layout/types';
import { useShowMetadataCreate, useToggle9Square } from 'src/v2/features/sharedEntity';

import { EmptyHeader } from '../components/EmptyHeader';
import { useNotificationTogglePush } from '../TemplateSidebar';

export const DynamicSettingsContextProvider: FC = ({ children }) => {
  const toggleNotifications = useNotificationTogglePush();
  const toggle9squareSidebar = useToggle9Square();
  const showMetadataCreate = useShowMetadataCreate();

  const ctx: DynamicSettings = {
    headerChildren: <EmptyHeader />,
    onBellClick: toggleNotifications,
    onGridClick: toggle9squareSidebar,
    onPlusClick: showMetadataCreate,
  };

  return <DynamicSettingsContext.Provider value={ctx}>{children}</DynamicSettingsContext.Provider>;
};
