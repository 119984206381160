import { SignatureFont } from 'src/models/signature';

import { FontConfig } from './types';

export const signatureFonts: FontConfig[] = [
  {
    name: SignatureFont.Anita,
    style: { fontFamily: 'anita' },
  },
  {
    name: SignatureFont.Silently,
    style: { fontFamily: 'silently' },
  },
  {
    name: SignatureFont.Amorbetia,
    style: { fontFamily: 'amorbetia' },
  },
];

export const japaneseSignatureFonts: FontConfig[] = [
  {
    name: SignatureFont.NotoSansJP,
    style: { fontFamily: 'NotoSansJP' },
  },
  {
    name: SignatureFont.NotoSerifJP,
    style: { fontFamily: 'NotoSerifJP' },
  },
  {
    name: SignatureFont.Roboto,
    style: { fontFamily: 'Roboto' },
  },
];

export const allSignaturesFonts = [...signatureFonts, ...japaneseSignatureFonts];
