import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ChatState } from 'src/app/types';
import { getObjects, getObjectsMap } from 'src/v2/features/objectsStorage/objectsStorageSlice';
import { CompleteObject, ObjectSerialized, WSObjectSerialized } from 'src/common/types';

import { ChatMessage, RoomPayload } from '../types';

const chatInitialState: ChatState = {
  currentMessage: '',
  currentChatBase: null,
  currentChatMessages: [],
  subscribedRoomPayload: null,
  currentRoom: null,
  isLoading: false,
  isSending: false,
  isDisconnected: false,
  shouldStickToBottom: true,
};

// internal
export const openRoom = createAction<RoomPayload>('chat/openRoom');
export const openCurrentChat = createAction<void>('chat/openCurrentChat');
export const closeCurrentRoom = createAction<void>('chat/closeCurrentRoom');
export const closeCurrentChat = createAction<void>('chat/closeCurrentChat');
export const openChat = createAction<string>('chat/openChat');
export const sendMessage = createAction('chat/sendMessage');
export const markMessagesAsRead = createAction<void>('chat/markMessagesAsRead');
export const chatLoadMore = createAction<void>('chat/chatLoadMore');

// exposed
export const roomContent = createAction<WSObjectSerialized>('chat/roomContent');
export const chatContent = createAction<WSObjectSerialized>('chat/chatContent');
export const chatMessage = createAction<WSObjectSerialized>('chat/chatMessage');
export const chatUnreadCount = createAction<WSObjectSerialized>('chat/chatUnreadCount');
export const newChatMessage = createAction<WSObjectSerialized>('chat/chatNewMessage');

const chat = createSlice({
  name: 'chat',
  initialState: chatInitialState,
  reducers: {
    setSubscribedRoomPayload: (state, action: PayloadAction<RoomPayload | null>): void => {
      state.subscribedRoomPayload = action.payload;
    },
    closeChat: (state): void => {
      state.currentChatBase = null;
      state.currentChatMessages = [];
      state.isSending = false;
    },
    setRoomContent: (state, action: PayloadAction<ObjectSerialized>): void => {
      // eslint-disable-next-line prefer-destructuring
      state.currentRoom = ObjectSerialized.dataToBaseObjects(action.payload.data)[0];
    },
    setChatContent: (state, action: PayloadAction<ObjectSerialized>): void => {
      // eslint-disable-next-line prefer-destructuring
      state.currentChatBase = ObjectSerialized.dataToBaseObjects(action.payload.data)[0];
      const map = getObjectsMap(action.payload.included);
      state.currentChatMessages = getObjects(
        action.payload.data[0].relationships.messages.data,
        map,
      )
        // todo: date conversion from unified format on getObjects level
        .map((msg) => ({ ...msg, createdAt: new Date(msg.createdAt as string) }))
        .reverse() as ChatMessage[];
    },
    prependMessages: (state, action: PayloadAction<WSObjectSerialized>): void => {
      const includedMap = getObjectsMap([...action.payload.data, ...action.payload.included]);
      const newMessages = getObjects<CompleteObject>(action.payload.data, includedMap)
        // todo: date conversion from unified format on getObjects level
        .map((msg) => ({ ...msg, createdAt: new Date(msg.createdAt as string) }))
        .reverse() as ChatMessage[];

      state.currentChatMessages = [...newMessages, ...state.currentChatMessages];
    },
    setCurrentMessage: (state, action: PayloadAction<string>): void => {
      state.currentMessage = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>): void => {
      state.isLoading = action.payload;
    },
    setIsSending: (state, action: PayloadAction<boolean>): void => {
      state.isSending = action.payload;
    },
    setIsDisconnected: (state, action: PayloadAction<boolean>): void => {
      state.isDisconnected = action.payload;
    },
    setShouldStickToBottom: (state, action: PayloadAction<boolean>): void => {
      state.shouldStickToBottom = action.payload;
    },
  },
  extraReducers: {
    [newChatMessage.toString()]: (state, action: PayloadAction<WSObjectSerialized>): void => {
      const includedMap = getObjectsMap([...action.payload.data, ...action.payload.included]);
      const newMessages = getObjects<CompleteObject>(action.payload.data, includedMap)
        // todo: date conversion from unified format on getObjects level
        .map((msg) => ({ ...msg, createdAt: new Date(msg.createdAt as string) }))
        .reverse() as ChatMessage[];

      // Contains the message we were sending
      if (state.isSending && newMessages.some((item) => item.text === state.currentMessage)) {
        state.isSending = false;
        state.currentMessage = '';
        state.shouldStickToBottom = true;
      }

      state.currentChatMessages = [...state.currentChatMessages, ...newMessages];
    },
  },
});

// actions
export const {
  closeChat,
  setSubscribedRoomPayload,
  setRoomContent,
  setChatContent,
  prependMessages,
  setCurrentMessage,
  setIsLoading,
  setIsSending,
  setIsDisconnected,
  setShouldStickToBottom,
} = chat.actions;

export const { reducer } = chat;
