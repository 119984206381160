import { get, isNil } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';

import { RootState, DefaultSliceStateWithData } from 'src/app/types';
import { DocumentRole } from 'src/models/document';
import { Avatar } from 'src/v2/features/profile/types';
import { getProfiles, getAvatars, getUsers } from 'src/v2/features/objectsStorage';
import { ProfileEntity, SidebarParticipantEntity } from 'src/v2/entities/participants';

import { getParticipantsWithUserAndProfile } from './combiners';

const getState = (state: RootState): DefaultSliceStateWithData => state.documentParticipants;

const getParticipantsList = createSelector(getState, (state) => state.data);

/** @deprecated */
export const getParticipantsRaw =
  (getDocument: any) =>
  (state: RootState): SidebarParticipantEntity[] =>
    get(getDocument(state), 'participants', []) as SidebarParticipantEntity[];

export const isLoading = (state: RootState): boolean => getState(state).isLoading;

export const getError = (state: RootState): string => getState(state).error;

// @TODO refactor, function is wrong type SidebarParticipantEntity doesnt have profile function D20-1846
/* @deprecated */
export const getProfile = <R = DocumentRole>(user: SidebarParticipantEntity<R>): ProfileEntity => {
  const profile = get(user, 'profile[0]') as ProfileEntity;
  if (isNil(profile)) {
    return {
      id: get(user, 'id') as string,
      firstName: get(user, 'firstName') as string,
      lastName: get(user, 'lastName') as string,
      avatar: get(user, 'avatar') as string,
      signature: get(user, 'signature') as string,
    };
  }

  return profile;
};

export const getEmail = <R = DocumentRole>(user: SidebarParticipantEntity<R>): string =>
  get(user, 'email') as string;

export const hasNames = (profile: ProfileEntity): boolean =>
  profile && !!get(profile, 'firstName') && !!get(profile, 'lastName');

export const getFullName = ({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName: string;
}): string => `${firstName} ${lastName}`;

/**  @deprecated */
export const getShownName = (profile: ProfileEntity, placeholder: string): string =>
  hasNames(profile) ? getFullName(profile) : placeholder;

export const getInitials = (profile: ProfileEntity): string =>
  hasNames(profile) ? `${profile.firstName.charAt(0)}${profile.lastName.charAt(0)}` : '';

export const hasAvatar = (profile?: ProfileEntity): boolean =>
  !!get(profile, 'avatar[0].uriMedium');

export const getAvatar = (profile?: ProfileEntity): Avatar | undefined =>
  hasAvatar(profile) ? (get(profile, 'avatar[0]') as Avatar) : undefined;

export const getParticipantsListWithUsersAndProfile = createSelector(
  getParticipantsList,
  getUsers,
  getProfiles,
  getAvatars,
  getParticipantsWithUserAndProfile,
);
