import React, { FC, useCallback, ChangeEvent } from 'react';

import { SignatureList } from 'src/v2/features/signature';
import { SignatureListProps } from 'src/v2/features/signature/types';
import { decodeContentToDomainUrl } from 'src/utils/url';

import { SignatureStampModel, StampId } from 'src/models/signature';

import styles from './HankoSignatureList.module.css';

interface Props extends SignatureListProps {
  stamps: SignatureStampModel[] | null;
  defaultStampId?: string | null;
  onStampChange: (stampId: StampId) => void;
}

export const HankoSignatureList: FC<Props> = ({
  stamps,
  defaultStampId,
  onStampChange,
  ...other
}) => {
  const handleStampChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onStampChange(event.target.value);
    },
    [onStampChange],
  );

  return (
    <SignatureList {...other}>
      {stamps && (
        <div className="c-signature__column">
          {stamps.map((stamp) => (
            <div
              className="c-radio c-radio--signature"
              data-type="default-signature-stamp"
              key={stamp.stamp_id}
            >
              <label className="c-radio__label">
                <input
                  type="radio"
                  hidden
                  value={stamp.stamp_id}
                  checked={stamp.stamp_id === defaultStampId}
                  onChange={handleStampChange}
                />
                <span className="c-radio__icon" />
                <span className="c-radio__img">
                  <img
                    className={styles.stampImage}
                    src={decodeContentToDomainUrl(stamp.stamp_file_link)}
                    alt="Stamp"
                  />
                </span>
              </label>
            </div>
          ))}
        </div>
      )}
    </SignatureList>
  );
};
