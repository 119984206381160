import { History } from 'history';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { parseCardPaperDataFactory } from 'src/v2/features/sharedEntity/utils';
import { DocumentRole } from 'src/models/document';
import { book } from 'src/app/book';
import { EntityType } from 'src/models/paper';
import { fetchDocumentInfo } from 'src/v2/features/documentDetails/documentDetailsSlice';

export const navigateToDocumentDetailsFactory =
  (history: History) =>
  (id: string, type: EntityType, folderId?: string, isTemplate = false): void => {
    if (!isTemplate) {
      let url = '';

      if (type === EntityType.document) {
        url = book.document.generatePath(id, folderId);
      } else {
        url = book.contract.generatePath(id, folderId);
      }

      history.push(url);
    } else {
      history.push(book.template.generatePath(id, folderId));
    }
  };

export const useCreateNavigateToDocumentDetails = (
  folderId?: string,
): ((id: string) => () => void) => {
  const navigate = useNavigate();

  return useCallback(
    (id: string) => (): void => {
      navigate(book.document.generatePath(id, folderId));
    },
    [navigate, folderId],
  );
};

export const useCreateGetDocumentInfo = (): ((id: string) => () => void) => {
  const dispatch = useDispatch();

  return useCallback(
    (id: string) => (): void => {
      dispatch(fetchDocumentInfo(id));
    },
    [dispatch],
  );
};

export const parseCardData = parseCardPaperDataFactory<DocumentRole>(
  i18n(translationKeys.document.noTitle),
  DocumentRole.Owner,
);
