import React, { useEffect, useState } from 'react';
import { DeviceContext } from './DeviceContext';

function isTouchEnabled(): boolean {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
}

export const DeviceContextProvider: React.FC = ({ children }) => {
  const [isTouchable, setIsTouch] = useState(false);
  const windowOnTouch = window.ontouchstart;
  useEffect(() => {
    setIsTouch(isTouchEnabled());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigator.maxTouchPoints, navigator.msMaxTouchPoints, windowOnTouch]);

  return (
    <DeviceContext.Provider
      value={{
        isTouchable,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};
