import { useEffect, FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { chain, isNil, isNull, isUndefined } from 'lodash';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { book } from 'src/app/book';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { getCompleteObjectsList, getIsLoading, getError } from 'src/v2/features/objectsList/store';
import { ObjectModel } from 'src/models/object';
import { RootState } from 'src/app/types';
import {
  useChangeUrlSortParams,
  useCreateShowMetadataViewFn,
  useFetchEntity,
  useNormalizedEntityId,
} from 'src/v2/features/sharedEntity';
import { CardPaperModel } from 'src/models/card';
import { Breadcrumb } from 'src/v2/features/breadcrumb';
import { CardsGrid } from 'src/v2/components/CardsGrid';
import {
  useGetCurrentFolderId,
  useGetFolderName,
  useOpenFolder,
} from 'src/v2/features/quantumNavigator/hooks';
import { getSortDirection, getSortField } from 'src/common/sortStore';
import { useDefaultSortEffct } from 'src/common/hooks/useDefaultSort';
import { useSubscribeFolderContentChangeEffct } from 'src/v2/features/objectsList/utils';
import {
  useHasNavigationFeature,
  useHasPreBuiltTemplatesFeature,
} from 'src/v2/features/billing/hasFeature/hooks';
import { EntityType } from 'src/models/paper';
import { CarouselCardGrid } from 'src/v2/components/CarouselCardGrid';

import {
  fetchUserSystemTemplates,
  getSystemTemplatesList,
} from '../sharedEntity/templatesListStore';

interface RenderCardProps<T> {
  cardPaper: T;
  onDelete: () => void;
  selected: boolean;
  onClick: () => void;
}

export interface RenderSystemCardProps {
  title: string;
  paperType: string;
  description: string;
  templateId: string;
}

interface RenderLayoutProps {
  isLoading: boolean;
  error?: string;
  children: ReturnType<FC>;
}

interface Props<T> {
  renderCard: (props: RenderCardProps<T>) => ReturnType<FC>;
  renderSystemCard?: (props: RenderSystemCardProps) => ReturnType<FC>;
  renderLayout: (props: RenderLayoutProps) => ReturnType<FC>;
  rootFolderId?: string;
  selectedPaperId: string | null;
  onIdChanged: (id: string) => void;
  onDelete: (paper: T) => void;
  onCreate?: () => void;
  defaultGridTitle?: string;
  entityType?: EntityType;
}

export const ObjectsListPage = <T extends CardPaperModel<unknown, unknown>>({
  renderCard,
  renderSystemCard,
  renderLayout,
  rootFolderId,
  selectedPaperId,
  onIdChanged,
  onDelete,
  onCreate,
  entityType,
  defaultGridTitle,
}: Props<T>) => {
  const [firstRender, setFirstRender] = useState(true);
  const list = useSelector<RootState, ObjectModel<T>[]>(getCompleteObjectsList);
  const isLoading = useSelector(getIsLoading);
  const error = useSelector(getError);
  const sortDirection = useSelector(getSortDirection);
  const sortField = useSelector(getSortField);
  const folderId = useGetCurrentFolderId() || rootFolderId;
  const hasPreBuiltTemplatesFeature = useHasPreBuiltTemplatesFeature();
  const hasNavigationFeature = useHasNavigationFeature();
  const systemTemplates = useSelector(getSystemTemplatesList) || [];
  const folderName = useGetFolderName();
  const gridTitle = folderName ? folderName : defaultGridTitle;
  useFetchEntity(sortDirection, sortField, rootFolderId);
  useChangeUrlSortParams(sortDirection, sortField);
  useDefaultSortEffct();
  const id = useNormalizedEntityId();
  const isRootFolder = rootFolderId === folderId;

  const dispatch = useDispatch();

  const openFolder = useOpenFolder();

  const createOnDelete = useCallback(
    (object: T): (() => void) => {
      return (): void => {
        onDelete(object);
      };
    },
    [onDelete],
  );
  const foldersHasLoaded = !isUndefined(folderId);
  const newObjectsHasLoaded = !isLoading && !firstRender;

  const createShowMetadataViewFn = useCreateShowMetadataViewFn();

  useEffect(() => {
    if (!isUndefined(id) && foldersHasLoaded && newObjectsHasLoaded) {
      dispatch(onIdChanged(id));
    }
  }, [id, dispatch, onIdChanged, foldersHasLoaded, newObjectsHasLoaded]);

  useSubscribeFolderContentChangeEffct(folderId);

  useEffect(() => {
    setFirstRender(false);
  }, []);

  useEffect(() => {
    (() => {
      hasPreBuiltTemplatesFeature && entityType && dispatch(fetchUserSystemTemplates(entityType));
    })();
  }, [dispatch, hasPreBuiltTemplatesFeature, entityType]);

  const showPreBuiltTemplates =
    hasPreBuiltTemplatesFeature &&
    !isNull(entityType) &&
    !isUndefined(onCreate) &&
    systemTemplates.length;

  const pricingLink = (
    <Link className="c-letter__link" to={book.profile.pricingAndBilling.generatePath()} />
  );

  const showAbilityText =
    entityType && hasNavigationFeature && isRootFolder ? (
      <Trans
        i18nKey={translationKeys[entityType].ability}
        components={{
          pricingLink,
        }}
      />
    ) : isUndefined(entityType) && hasNavigationFeature && isRootFolder ? (
      <Trans
        i18nKey={translationKeys.template.ability}
        components={{
          pricingLink,
        }}
      />
    ) : null;

  return renderLayout({
    isLoading,
    error,
    children:
      newObjectsHasLoaded && !error ? (
        <>
          {!showAbilityText && <Breadcrumb onFolderClick={openFolder} />}
          {showPreBuiltTemplates && renderSystemCard ? (
            <CarouselCardGrid gridTitle={i18n(translationKeys.card.templateLibrary.title)}>
              {chain(systemTemplates)
                .orderBy(['createdAt'], ['desc'])
                .map((systemCardPaper) => renderSystemCard(systemCardPaper))
                .value()}
            </CarouselCardGrid>
          ) : null}
          {list.length || !isUndefined(onCreate) ? (
            <CardsGrid onAdd={onCreate} gridTitle={gridTitle}>
              {list.map(({ cardPaper }) =>
                renderCard({
                  cardPaper,
                  onDelete: createOnDelete(cardPaper),
                  onClick: createShowMetadataViewFn({ id: cardPaper.id }),
                  selected: !isNil(selectedPaperId) && selectedPaperId === cardPaper.id,
                }),
              )}
            </CardsGrid>
          ) : (
            showAbilityText
          )}
        </>
      ) : null,
  });
};
