import { FC, useCallback } from 'react';
import cn from 'classnames';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { decodeContentToDomainUrl } from 'src/utils/url';

import { SignatureItemProps } from '../../types';
import styles from './SignatureList.module.css';

export const SignatureItem: FC<SignatureItemProps> = ({
  id,
  image,
  legalName,
  checked,
  onSelectDefault,
  onEdit,
}) => {
  const handleChange = useCallback(() => {
    onSelectDefault(id);
  }, [onSelectDefault, id]);

  const handleEdit = useCallback(() => {
    if (onEdit) onEdit(id);
  }, [onEdit, id]);

  return (
    <div className="c-radio c-radio--signature" data-type="default-signature-item">
      <label className="c-radio__label">
        <input type="radio" hidden value={id} checked={checked} onChange={handleChange} />
        <span className="c-radio__icon" />
        {!image && <span className="c-radio__title">{`/${legalName}/`}</span>}
        {image && (
          <span className="c-radio__img">
            <img
              className={styles.signatureImage}
              src={decodeContentToDomainUrl(image)}
              alt={i18n(translationKeys.forms.signature.signature)}
            />
          </span>
        )}
      </label>
      {onEdit && (
        <div className={cn('c-radio__action', styles.actions)}>
          <div className="c-radio__edit" onClick={handleEdit}>
            {i18n(translationKeys.buttons.edit)}
          </div>
        </div>
      )}
    </div>
  );
};
