import { get, size } from 'lodash';
import { BackendError, DataStructureError } from 'src/common/types';

export const normalizeNumbers = (val: string, length = 15): string => {
  const normalizedValue = val.replace(/[^\d]/g, '');
  return normalizedValue.slice(0, length);
};

export const getFullName = (firstName?: string, lastName?: string): string => {
  if (firstName && lastName) return `${firstName} ${lastName}`;
  if (firstName) return firstName;
  if (lastName) return lastName;
  return 'Unknown';
};

export const normalizeDataErrors = (error: { data: DataStructureError[] }): BackendError[] => {
  const errorsData = get(error, 'response.data.data', []);
  return errorsData.map((err: DataStructureError) => {
    return { key: err.title, value: err.detail };
  });
};

export const normalizeObjectErrors = (err: any): BackendError[] => {
  const multipleErrors = get(err, 'response.data.error.errors', []);
  return Object.keys(multipleErrors).map((key: string) => {
    return { key, value: multipleErrors[key] };
  });
};

export const normalizeMessageError = (err: any): BackendError => {
  const messageText = get(err, 'response.data.error.message') || get(err, 'message');
  return { key: 'error', value: messageText };
};

export const normalizeErrors = (err: any): BackendError[] => {
  let errors = normalizeObjectErrors(err);
  if (size(errors) === 0) {
    errors = normalizeDataErrors(err);
  }
  return size(errors) > 0 ? errors : [normalizeMessageError(err)];
};
