/* eslint-disable no-console */
// Core
import React, { useMemo } from 'react';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { get, size } from 'lodash';

// Components
import { getStateList, normalizeStatesCountries } from 'src/utils/countries';
import { Button } from 'src/v2/components/Button';
import { countries } from 'src/instruments/countries';
import { validateEmail, validatePostalCode } from 'src/utils/validate';
import { SystemRole } from 'src/models/user';
import { canEditOrganizationInfo } from 'src/utils/permissions';
import { CForm, CFormRow } from 'src/v2/components/CForm';
import { Input } from 'src/v2/features/reduxForm';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { setOrganizationData } from 'src/v2/features/organization/store/organizationReducer';
import { SearchableSelect } from 'src/v2/features/reduxForm/SeachableSelect';
import { getCurrentOrganizationInfo } from 'src/shared/organization';
import { RootState } from 'src/app/types';

import { OrganizationFormData } from '../types';

interface Props {
  userRole: SystemRole | null;
}

const validate = (values: OrganizationFormData) => ({
  organizationName: values.organizationName ? '' : i18n(translationKeys.errors.FORM_FILED_REQUIRED),
  contactPerson: values.contactPerson
    ? validateEmail(values.email)
    : i18n(translationKeys.errors.FORM_FILED_REQUIRED),
  email: values.email ? '' : i18n(translationKeys.errors.FORM_FILED_REQUIRED),
  zipCode:
    !values.zipCode || (values.zipCode && validatePostalCode(values.country, values.zipCode))
      ? ''
      : i18n(translationKeys.errors.INVALID_CODE_FOR_THIS_COUNTRY),
});

export const organizationFormName = 'organization-form';
const Form: React.FC<Props & InjectedFormProps<OrganizationFormData, Props>> = ({
  handleSubmit,
  pristine,
  submitting,
  invalid,
  userRole,
}) => {
  const dispatch = useDispatch();
  const country = useSelector((store: RootState) =>
    get(store, 'form.organization-form.values.country', 'US'),
  );
  const stateList = useMemo(() => getStateList(country), [country]);

  const statesOptions = useMemo(() => normalizeStatesCountries(stateList), [stateList]);
  const isStatesAvailable = useMemo(() => size(statesOptions) > 0, [statesOptions]);
  const countriesOptions = useMemo(() => normalizeStatesCountries(countries), []);

  const submitForm = (values: OrganizationFormData): void => {
    const organization = {
      companyName: values.organizationName,
      contactPerson: values.contactPerson,
      contactEmail: values.email,
      address: {
        addressLine: values.address,
        country: values.country,
        city: values.city,
        zipCode: values.zipCode,
        state: values.state,
      },
    };
    dispatch(setOrganizationData(organization));
  };

  const isAbleToEditOrgInfo = canEditOrganizationInfo(userRole);

  return (
    <CForm data-id="profile-form-organization" onSubmit={handleSubmit(submitForm)}>
      <CFormRow>
        <Field
          className="c-form__column"
          name="organizationName"
          data-id="orgName"
          type="text"
          placeholder={i18n(translationKeys.forms.info.organizationName)}
          component={Input}
          disabled={!isAbleToEditOrgInfo}
        />
      </CFormRow>
      <CFormRow>
        <Field
          className="c-form__column"
          name="contactPerson"
          data-id="organizationContactPerson"
          type="text"
          placeholder={i18n(translationKeys.forms.info.organizationContactPerson)}
          component={Input}
          disabled
        />
      </CFormRow>
      <CFormRow>
        <Field
          className="c-form__column"
          name="email"
          type="email"
          placeholder={i18n(translationKeys.forms.info.email)}
          component={Input}
          disabled
        />
      </CFormRow>
      <CFormRow>
        <Field
          className="c-form__column"
          name="address"
          type="text"
          placeholder={i18n(translationKeys.forms.info.address)}
          component={Input}
          disabled={!isAbleToEditOrgInfo}
        />
      </CFormRow>
      <CFormRow>
        <Field
          className="c-form__column"
          name="city"
          type="text"
          placeholder={i18n(translationKeys.forms.info.city)}
          component={Input}
          disabled={!isAbleToEditOrgInfo}
          required
        />
      </CFormRow>
      <CFormRow>
        <Field
          className="c-form__column c-form__column--2"
          name="state"
          placeholder={i18n(translationKeys.forms.info.stateSelect)}
          data-id="countryState"
          component={SearchableSelect}
          options={statesOptions}
          disabled={!isAbleToEditOrgInfo || !isStatesAvailable}
          mb8
        />
        <Field
          className="c-form__column c-form__column--2"
          name="zipCode"
          type="text"
          placeholder={i18n(translationKeys.forms.info.zipCode)}
          component={Input}
          disabled={!isAbleToEditOrgInfo}
        />
      </CFormRow>
      <CFormRow>
        <Field
          className="c-form__column"
          name="country"
          placeholder={i18n(translationKeys.forms.info.countrySelect)}
          data-id="organizationCountry"
          component={SearchableSelect}
          defaultValue={country}
          options={countriesOptions}
          mb8
          disabled={!isAbleToEditOrgInfo}
        />
      </CFormRow>

      <CFormRow>
        {isAbleToEditOrgInfo && (
          <Button
            type="submit"
            style={{
              textTransform: 'uppercase',
              color: '#e4b62c',
            }}
            disabled={invalid || submitting || pristine || !isAbleToEditOrgInfo}
          >
            {i18n(translationKeys.buttons.update)}
          </Button>
        )}
      </CFormRow>
    </CForm>
  );
};

const OrganizationReduxForm = reduxForm<OrganizationFormData, Props>({
  form: 'organization',
  validate,
  enableReinitialize: true,
})(Form);

export const OrganizationForm = connect((state: RootState) => ({
  form: organizationFormName,
  initialValues: getCurrentOrganizationInfo(state),
}))(OrganizationReduxForm);
