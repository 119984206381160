import React, { PropsWithChildren } from 'react';
import { isNil } from 'lodash';
import { defaultMatch } from './utils';

interface Props<C = string> {
  matchFn?: (criteria: C, value: C) => boolean;
  criteria: C;
}

// This component should provides easy way to declarative switch case render
// It is lightweight copy of react-router Router
// Warning: You should only use Case component as a child of Switch
export const Switch = <C extends any>({
  children,
  criteria,
  matchFn = defaultMatch,
}: PropsWithChildren<Props<C>>): JSX.Element => {
  let element: React.ReactNode = null;
  let match = false;

  // Iterating over child case and render only that is matched by criteria
  React.Children.forEach(children, (child) => {
    if (!match && React.isValidElement(child)) {
      element = child;

      // @ts-ignore
      const { value } = child.props;
      match = isNil(value) ? false : matchFn(criteria, value);
    }
  });

  // @ts-ignore
  return match ? React.cloneElement(element) : null;
};
