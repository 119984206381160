export enum ModalType {
  ContractMoveTo,
  ContractCopyTo,
  ContractMoveFolderTo,
  DocumentMoveTo,
  DocumentCopyTo,
  DocumentMoveFolderTo,
  TemplateMoveTo,
  TemplateCopyTo,
  TemplateMoveFolderTo,
  AccountRecoveryStepOne, // verify phone/email
  AccountRecoveryStepTwo, // OTP
  AccountRecoveryStepThree, // password reset
  AccountRecoveryMessage, // success message
}
