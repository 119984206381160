import { find } from 'lodash';
import { Country, countries, State } from 'src/instruments/countries';
import { useMemo } from 'react';

export const getStateList = (countryCode: string): Array<State> => {
  const countryObj = find<Country>(countries, { code: countryCode }) || { states: [] };
  return countryObj.states || [];
};

export const normalizeStatesCountries = (
  list: { name: string; code: string }[],
): { label: string; value: string }[] =>
  list.map((el: { name: string; code: string }) => ({ label: el.name, value: el.code }));

export const useCountryListMemo = (label?: string) =>
  useMemo(() => {
    const options = normalizeStatesCountries(countries);
    return label ? [{ label, value: '', disabled: true }, ...options] : options;
  }, [label]);

export const useStateListMemo = (country: string) => {
  const stateList = getStateList(country);

  return useMemo(() => normalizeStatesCountries(stateList), [stateList]);
};
