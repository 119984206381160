import React from 'react';
import { Field, reduxForm, isDirty, isValid } from 'redux-form';
import { required, length } from 'redux-form-validators';

import { FolderOpen } from 'src/v2/icons/FolderOpen';
import { FolderLinear } from 'src/v2/icons/FolderLinear';
import { useSelector } from 'react-redux';

interface Props {
  onSubmit: (values: FormFields) => void;
  onCancel: () => void;
}

interface FormFields {
  name: string;
}

const handleKeydownEnter =
  (cb: (value: string) => void) => (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Property `value` exists here, but not in the type of Keyboard event
    const target = e.target as HTMLInputElement;
    if (e.keyCode === 13) {
      cb(target.value);
    }
  };

const handleBlur = (cb: (value: string) => void) => (e: React.FocusEvent<HTMLInputElement>) =>
  cb(e.target.value);

const formName = 'editFolderNameForm';

export const EditFolderForm = reduxForm<FormFields, Props>({ form: formName })(
  ({ handleSubmit, onSubmit, onCancel }) => {
    const isValidForm = useSelector(isValid(formName));
    const isDirtyForm = useSelector(isDirty(formName));
    const isReadyToSubmit = isValidForm && isDirtyForm;

    return (
      <div className="c-library__item">
        <span className="c-library__link c-library__link--active">
          <div className="c-library__icon c-library__icon--toggle">
            <FolderLinear />
            <FolderOpen />
          </div>
          <div className="c-library__info">
            <Field
              name="name"
              onBlur={isReadyToSubmit ? handleBlur(handleSubmit(onSubmit)) : onCancel}
              onKeyDown={
                isReadyToSubmit
                  ? handleKeydownEnter(handleSubmit(onSubmit))
                  : handleKeydownEnter(onCancel)
              }
              validate={[required(), length({ min: 1 })]}
              className="c-library__input"
              data-type="folder-rename-field"
              placeholder="Type folder name..."
              component="input"
              type="text"
              autoFocus
            />
          </div>
        </span>
      </div>
    );
  },
);
