import { FC } from 'react';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { ListItem } from 'src/models/list';
import { NodeTree } from 'src/models/node';
import { ContextMenuItem } from 'src/v2/components/contextMenu';
import { MoveFolder } from 'src/v2/icons/MoveFolder';
import { ContextMenuProps } from 'src/v2/features/contextMenu/types';
import { isContractFolder } from 'src/v2/features/quantumNavigator';
import { getIsNotEditableFolder } from 'src/v2/features/quantumNavigator/utils';
import { useCanCreateFolderInContracts } from 'src/v2/features/contract/permissions/contractPermissions/contractPermissionsHooks';
import { EntityFolderContextMenu } from 'src/v2/features/sharedEntity/EntityFolderContextMenu';
import { useGetRootFolderId } from 'src/v2/features/sharedEntity/hooks';
import { useOpenModal } from 'src/v2/features/folderSelector/hooks';
import { ModalType } from 'src/v2/features/modal/types';
import { useNavigateToContractFolder } from 'src/v2/features/quantumNavigator/hooks';

export const ContractFolderContextMenu: FC<ContextMenuProps> = (props) => {
  const { closeOnClick, payload } = props;
  const folder = payload as NodeTree<ListItem>;
  const contractRootFolderId = useGetRootFolderId(isContractFolder);
  const isRootFolder = getIsNotEditableFolder(folder);
  const isCanCreateFolder = useCanCreateFolderInContracts(folder);

  const handleMove = useOpenModal({
    menuItemFolderId: contractRootFolderId,
    targetId: folder.data.id,
    type: ModalType.ContractMoveFolderTo,
  });

  const handleRootFolderClick = useNavigateToContractFolder();

  return (
    <EntityFolderContextMenu
      {...props}
      isRootFolder={isRootFolder}
      isNewFolderDisabled={!isCanCreateFolder}
      onRootFolderClick={handleRootFolderClick}
    >
      <ContextMenuItem
        icon={MoveFolder}
        text={i18n(translationKeys.contextMenu.moveFolder)}
        onClick={closeOnClick(handleMove)}
        data-id="moveFolder-menuButton"
        disabled={isRootFolder}
      />
    </EntityFolderContextMenu>
  );
};
