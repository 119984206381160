import { FC, useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, formValueSelector, InjectedFormProps, getFormValues } from 'redux-form';
import { required } from 'redux-form-validators';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { toNumber, isUndefined, find, get, isNull, isEmpty } from 'lodash';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { useCountryListMemo, useStateListMemo } from 'src/utils/countries';
import { Button } from 'src/v2/features/button';
import { Input } from 'src/v2/features/reduxForm';
import { RootState } from 'src/app/types';
import { getSubscribedPlan, getSubscribedPeriod } from 'src/v2/features/billing';
import { getIsLoading as getBillingLoading } from 'src/v2/features/billing/store';
import { CommonOrderSummary } from 'src/v2/features/checkout/components/CommonOrderSummary';
import { useIsInOrganization } from 'src/v2/features/organization/hooks';
import { validateEmail, validateStripeFields } from 'src/utils/validate';
import { useToggle } from 'src/common/hooks/useToggle';

import { StripeInput } from './StripeInput';
import { BillingDetailsFormValues, CheckoutFormValues } from './types';
import { getIsLoading, cancelSubscription, submitBillingInfoWithSubscription } from './store';
import { formatPrice, getDefaultPlanAndPeriod, normalizeValueOfNumberSeats } from './utils';
import './styles.css';
import {
  usePopulateBillingDetails,
  useIsDefaultBilling,
  useFillBillingData,
  useGetCheckoutInfo,
} from './hooks';
import { SearchableSelect } from '../reduxForm/SeachableSelect';
import { AcceptAgreement } from './components/AcceptAgreement';
import { ConfirmationModal } from './components/ConfirmationModal';
import { SubscriptionPlan } from 'src/models/billing';

const FORM_ID = 'billingInfo';
const formSelector = formValueSelector(FORM_ID);
const formValuesSelector = getFormValues(FORM_ID);

const BillingInfo: FC<
  CheckoutFormValues & InjectedFormProps<BillingDetailsFormValues, CheckoutFormValues>
> = ({ handleSubmit, invalid, submitting, pristine, autofill }) => {
  const dispatch = useDispatch();
  const isCheckoutLoading = useSelector(getIsLoading);
  const isOrgAccount = useIsInOrganization();
  const numberOfSeatsFieldValue = useSelector((state: RootState) =>
    formSelector(state, 'numberOfSeats'),
  );
  const [numberOfSeats, setNumberOfSeats] = useState(1);
  const country = useSelector((state: RootState) => formSelector(state, 'country'));
  const stateCode = useSelector((state: RootState) => formSelector(state, 'state'));
  const stateList = useStateListMemo(country);
  const state = find(stateList, (state) => state.value === stateCode);
  const plan = useSelector(getSubscribedPlan);
  const period = useSelector(getSubscribedPeriod);
  const planAndPeriod = getDefaultPlanAndPeriod(isOrgAccount, plan, period);
  const countries = useCountryListMemo(i18n(translationKeys.forms.signUpBilling.fields.country));
  const isBillingLoading = useSelector(getBillingLoading);
  const isLoading = isCheckoutLoading || isBillingLoading;

  const paymentTermsAndConditions =
    period && plan ? i18n(translationKeys.checkout[period][plan]) : '';
  const [showModal, setShowModal] = useToggle();
  const stripe = useStripe();
  const elements = useElements();

  if (isUndefined(state) && !isEmpty(stateList)) {
    autofill('state', stateList[0].value);
  }

  const billingDetails: BillingDetailsFormValues | {} = useSelector((state: RootState) =>
    formValuesSelector(state),
  );
  const isDefaultBilling = useIsDefaultBilling(billingDetails) || !get(billingDetails, 'address');

  const submitBillingInfoForm = useFillBillingData(billingDetails);
  usePopulateBillingDetails(autofill, submitBillingInfoForm);

  const handleBillingInfoUpdate = useCallback(() => {
    submitBillingInfoForm();
  }, [submitBillingInfoForm]);

  const submitForm = useCallback(
    (values: BillingDetailsFormValues) => {
      if (!stripe || !elements || !plan || !period || isLoading) return;
      const { city, country, address, postalCode, state, email, firstName, lastName } = values;

      const cardNumberElement = elements.getElement(CardNumberElement);
      if (isNull(cardNumberElement)) return;

      if (plan !== SubscriptionPlan.Basic) {
        dispatch(
          submitBillingInfoWithSubscription({
            cardNumberElement,
            plan,
            period,
            stripe,
            address: {
              city,
              country,
              line1: address,
              postal_code: postalCode,
              state,
            },
            email,
            first_name: firstName,
            last_name: lastName,
            planAndPeriod,
            numberOfSeats,
          }),
        );
      } else {
        dispatch(cancelSubscription());
      }
    },
    [dispatch, plan, period, numberOfSeats, isLoading, stripe, elements, planAndPeriod],
  );

  useEffect(() => {
    if (!isUndefined(numberOfSeatsFieldValue)) {
      setNumberOfSeats(toNumber(numberOfSeatsFieldValue));
    }
  }, [numberOfSeatsFieldValue]);

  useEffect(() => {
    handleBillingInfoUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfSeats]);

  const { pricePerSeat, planPrice, totalPrice, tax, quantity } = useGetCheckoutInfo(
    plan,
    numberOfSeats,
  );

  return (
    <div className="l-container">
      {showModal && (
        <ConfirmationModal
          plan={plan}
          totalPrice={totalPrice}
          tax={tax}
          isLoading={isLoading}
          onClose={setShowModal}
          onSubmit={handleSubmit(submitForm)}
          planPrice={planPrice}
        />
      )}
      <form className="c-setup c-setup--checkout c-setup--bg-transparent">
        <div className="l-row">
          <CommonOrderSummary
            showTaxes={!isDefaultBilling}
            planPrice={planPrice}
            tax={toNumber(tax)}
            totalPrice={totalPrice}
            currentPlan={plan}
            currentPeriod={period}
            pricePerSeat={toNumber(pricePerSeat)}
            isLoading={isLoading}
            numberOfSeats={quantity}
          />
        </div>
        <div className="l-row">
          <div className="c-title c-title--color-theme c-title--fs-40">
            {i18n(translationKeys.forms.signUpBilling.title)}
          </div>
        </div>
        <div className="l-row">
          <div className="c-setup__content">
            <div className="c-form c-form__checkout c-form__bg c-form__bg--blue c-form--grow">
              <div className="c-form__row">
                <div className="c-form__title">
                  {i18n(translationKeys.forms.signUpBilling.personalInformation)}
                </div>
              </div>
              <div className="c-form__row">
                <div className="c-form__column c-form__required c-form__column--3">
                  <Field
                    component={Input}
                    name="firstName"
                    placeholder={i18n(translationKeys.forms.signUpBilling.fields.firstName)}
                    validate={[required()]}
                  />
                </div>
                <div className="c-form__column c-form__required c-form__column--3">
                  <Field
                    component={Input}
                    name="lastName"
                    placeholder={i18n(translationKeys.forms.signUpBilling.fields.lastName)}
                    validate={[required()]}
                  />
                </div>
                <div className="c-form__column c-form__required c-form__column--3">
                  <Field
                    component={Input}
                    name="email"
                    placeholder={i18n(translationKeys.forms.signUpBilling.fields.email)}
                    type="email"
                    validate={validateEmail}
                  />
                </div>
              </div>

              <div className="c-form__row">
                <div className="c-form__title">
                  {i18n(translationKeys.forms.signUpBilling.fields.address)}
                </div>
              </div>
              <div className="c-form__row">
                <div className="c-form__column c-form__required">
                  <Field
                    component={Input}
                    name="address"
                    placeholder={i18n(translationKeys.forms.signUpBilling.fields.street)}
                    validate={[required()]}
                    onBlur={handleBillingInfoUpdate}
                    disabled={isLoading}
                  />
                </div>
                <div className="c-form__column c-form__required c-form__column--3-1">
                  <Field
                    component={Input}
                    name="city"
                    placeholder={i18n(translationKeys.forms.signUpBilling.fields.city)}
                    validate={[required()]}
                    onBlur={handleBillingInfoUpdate}
                    disabled={isLoading}
                  />
                </div>
              </div>

              <div className="c-form__row">
                <div className="c-form__column c-form__required c-form__column--3">
                  <Field
                    component={Input}
                    name="state"
                    placeholder={i18n(translationKeys.forms.signUpBilling.fields.state)}
                    validate={[required()]}
                    onBlur={handleBillingInfoUpdate}
                    disabled={isLoading}
                  />
                </div>
                <div className="c-form__column c-form__required c-form__column--3">
                  <Field
                    component={Input}
                    name="postalCode"
                    placeholder={i18n(translationKeys.forms.signUpBilling.fields.postalCode)}
                    validate={[required()]}
                    onBlur={handleBillingInfoUpdate}
                    disabled={isLoading}
                  />
                </div>
                <div className="c-form__column c-form__required c-form__column--3">
                  <Field
                    component={SearchableSelect}
                    name="country"
                    options={countries}
                    validate={[required()]}
                    onBlur={handleBillingInfoUpdate}
                    disabled={isLoading}
                  />
                </div>
              </div>
              {isOrgAccount && (
                <>
                  <div className="c-form__row">
                    <div className="c-form__title">
                      {i18n(translationKeys.forms.signUpBilling.fields.numberOfUsers)}
                    </div>
                  </div>
                  <div className="c-form__row">
                    <div className="c-form__column c-form__required c-form__column--3-1">
                      <Field
                        component={Input}
                        name="numberOfSeats"
                        placeholder={i18n(translationKeys.forms.signUpBilling.fields.numberOfSeats)}
                        normalize={normalizeValueOfNumberSeats}
                        inputMode="numeric"
                        validate={[required()]}
                        disabled={isLoading}
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="c-form__row">
                <div className="c-form__title">
                  {i18n(translationKeys.forms.signUpBilling.paymentInformation)}
                </div>
              </div>
              <div className="c-form__row">
                <div className="c-form__column c-form__required c-form__column--3">
                  <Field
                    component={StripeInput}
                    element={CardNumberElement}
                    name="cardNumber"
                    validate={[validateStripeFields]}
                  />
                </div>
                <div className="c-form__column c-form__column--3">
                  <div className="c-form__card">
                    <div className="c-form__column c-form__required">
                      <Field
                        component={StripeInput}
                        element={CardExpiryElement}
                        name="expiryDate"
                        validate={[validateStripeFields]}
                      />
                    </div>
                    <div className="c-form__column c-form__required">
                      <Field
                        component={StripeInput}
                        element={CardCvcElement}
                        name="cvc"
                        validate={[validateStripeFields]}
                      />
                    </div>
                  </div>
                </div>
                <div className="c-form__column c-form__column--3">
                  <Button
                    type="button"
                    className="c-button c-button--w-full c-button--green c-button--fs-16"
                    onClick={() => setShowModal()}
                    disabled={invalid || submitting || pristine}
                  >
                    {i18n(translationKeys.buttons.pay)} {formatPrice(toNumber(totalPrice))}
                  </Button>
                </div>
              </div>

              <div className="c-form__row c-form__required c-form--fs-12 c-form--color-white">
                {i18n(translationKeys.forms.signUpBilling.fields.requiredFields)}
              </div>

              <div className="c-form__row c-form--fs-12">
                <AcceptAgreement agreement={paymentTermsAndConditions} />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export const BillingInfoForm = reduxForm<BillingDetailsFormValues, CheckoutFormValues>({
  form: FORM_ID,
  destroyOnUnmount: false,
  initialValues: { numberOfSeats: '' },
})(BillingInfo);
