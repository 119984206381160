import { useSelector } from 'react-redux';
import { isNull } from 'lodash';

import { NodeTree } from 'src/models/node';
import { ListItem } from 'src/models/list';
import { PaperModel } from 'src/models/paper';
import { Document, DocumentRole, DocumentType } from 'src/models/document';
import { getActivePlan } from 'src/v2/features/billing';
import { useGetCurrentUserRoleByEntity } from 'src/v2/features/sharedEntity';
import { canCreateFolder } from 'src/v2/features/quantumNavigator/utils';

import {
  canDeleteDocument,
  canEditDocumentMetadata,
  canSaveDocumentElementTemplate,
  canCreateDocumentTemplate,
  canViewDocumentHistory,
  canManageParticipantInDocument,
  canAddDocumentAttachments,
  canAcknowledgeDocument,
  canDownloadDocument,
  canRestoreDocument,
  canViewDocumentVersions,
  canViewDocumentNotifications,
  canCreateDocument,
  canCopyDocument,
  canChatInDocument,
  canCommentInDocument,
  canEditDocument,
  canShareDocument,
  canSpecifyAccessUserInDocument,
  canEditAccessInDocument,
  canCreateFolderInDocuments,
} from './documentPermissions';

export const useCanEditDocumentMetadata = (document: Document | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity(document);
  if (isNull(currentUserRole) || isNull(document)) return false;

  return canEditDocumentMetadata(currentUserRole);
};

export const useCanEditDocument = (document: Document | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity(document);
  if (isNull(currentUserRole) || isNull(document)) return false;

  return canEditDocument(currentUserRole);
};

export const useCanDeleteDocument = (document: Document | null): boolean => {
  if (isNull(document)) return false;

  return canDeleteDocument(document.state);
};

export const useCanAddDocumentAttachments = (document: Document | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity(document);
  if (isNull(currentUserRole) || isNull(document)) return false;

  return canAddDocumentAttachments(currentUserRole);
};

export const useCanCommentInDocument = (document: Document | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity(document);
  if (isNull(currentUserRole) || isNull(document)) return false;

  return canCommentInDocument(currentUserRole);
};

export const useCanViewDocumentHistory = (document: Document | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity(document);
  if (isNull(currentUserRole) || isNull(document)) return false;

  return canViewDocumentHistory(currentUserRole);
};

export const useCanManageParticipantInDocument = (
  document: PaperModel<DocumentType, DocumentRole> | null,
): boolean => {
  if (isNull(document)) return false;
  const currentUserRole = document.myParticipant.roleId;

  return canManageParticipantInDocument(currentUserRole);
};

export const useCanEditAccessInDocument = (
  document: PaperModel<DocumentType, DocumentRole> | null,
): boolean => {
  if (isNull(document)) return false;
  const currentUserRole = document.myParticipant.roleId;

  return canEditAccessInDocument(currentUserRole);
};

export const useCanSpecifyAccessUserInDocument = (
  document: PaperModel<DocumentType, DocumentRole> | null,
): boolean => {
  if (isNull(document)) return false;
  const currentUserRole = document.myParticipant.roleId;

  return canSpecifyAccessUserInDocument(currentUserRole);
};

export const useCanCreateDocument = (): boolean => {
  const plan = useSelector(getActivePlan);

  return canCreateDocument(plan);
};

export const useCanCopyDocument = (document: Document | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity(document);
  const plan = useSelector(getActivePlan);
  if (isNull(currentUserRole) || isNull(document)) return false;

  return canCopyDocument(currentUserRole, plan);
};

export const useCanShareDocument = (document: Document | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity(document);
  if (isNull(currentUserRole) || isNull(document)) return false;

  return canShareDocument(currentUserRole);
};

export const useCanDownloadDocument = (document: Document | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity(document);
  if (isNull(currentUserRole) || isNull(document)) return false;

  return canDownloadDocument(currentUserRole);
};

export const useCanCreateDocumentTemplate = (document: Document | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity(document);
  if (isNull(currentUserRole) || isNull(document)) return false;

  return canCreateDocumentTemplate(currentUserRole);
};

export const useCanSaveDocumentElementTemplate = (document: Document | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity(document);
  if (isNull(currentUserRole) || isNull(document)) return false;

  return canSaveDocumentElementTemplate(currentUserRole);
};

export const useCanViewDocumentVersions = (document: Document | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity(document);
  if (isNull(currentUserRole) || isNull(document)) return false;

  return canViewDocumentVersions(currentUserRole);
};

export const useCanRestoreDocument = (document: Document | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity(document);
  if (isNull(currentUserRole) || isNull(document)) return false;

  return canRestoreDocument(currentUserRole);
};

export const useCanViewDocumentNotifications = (document: Document | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity(document);
  if (isNull(currentUserRole) || isNull(document)) return false;

  return canViewDocumentNotifications(currentUserRole);
};

export const useCanChatInDocument = (
  document: PaperModel<DocumentType, DocumentRole> | null,
): boolean => {
  if (isNull(document)) return false;
  const currentUserRole = document.myParticipant.roleId;

  return canChatInDocument(currentUserRole);
};

export const useCanAcknowledgeDocument = (document: Document | null): boolean => {
  const currentUserRole = useGetCurrentUserRoleByEntity(document);
  if (isNull(currentUserRole) || isNull(document)) return false;

  return canAcknowledgeDocument(currentUserRole);
};

export const useCanCreateFolderInDocuments = (folder: NodeTree<ListItem>) => {
  const plan = useSelector(getActivePlan);
  const isCanCreateFolder = canCreateFolder(folder);

  return canCreateFolderInDocuments(plan) && isCanCreateFolder;
};
