import React, { useCallback, useMemo } from 'react';
import { Field, InjectedFormProps, reduxForm, formValueSelector } from 'redux-form';
import { useDispatch, connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Author, SearchEntityType } from 'src/v2/features/search/store/searchTypes';
import { Input, Select } from 'src/v2/features/reduxForm';
import { Button } from 'src/v2/features/button';

import {
  fetchSearchList,
  getKeyword,
  getCounters,
  getAuthors,
  getSubTypes,
} from 'src/v2/features/search';
import { book } from 'src/app/book';
import { getAuthorFullName, makeSearchParams, getSearchUrlParams } from 'src/utils/search';
import { buildUrlParams } from 'src/utils/url';
import { DocumentCounter } from 'src/v2/features/search/SearchSidebar/components/DocumentCounter';
import { useQuery } from 'src/utils/hooks';
import { Line } from '../components/RowLine';
import { typesOptions, documentOptions, emptyOption } from './types';

interface FormValues {
  type: SearchEntityType;
  usage: string;
  subType: string;
  subSubType: string;
  refineKeyword: string;
  region: string;
  organization: string;
  author: string;
}

interface ConnectedProps {
  selectedType: SearchEntityType;
}

type Props = ConnectedProps;

const SearchViewForm: React.FC<Props & InjectedFormProps<FormValues, Props>> = ({
  handleSubmit,
  submitting,
  selectedType,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const filter = useSelector(getKeyword);
  const counters = useSelector(getCounters);
  const authors = useSelector(getAuthors);
  const subTypes = useSelector(getSubTypes);

  const subTypesOptions = useMemo(() => {
    return [emptyOption].concat(
      subTypes.map((item: string) => {
        return { label: item, value: item };
      }),
    );
  }, [subTypes]);

  const authorOptions = useMemo(() => {
    return [emptyOption].concat(
      authors.map((item: Author) => ({
        label: getAuthorFullName(item),
        value: item.id,
      })),
    );
  }, [authors]);

  const { keyword, sortBy, sortDirection } = getSearchUrlParams(query);

  const onFormSubmit = useCallback(
    (values: FormValues) => {
      const { refineKeyword, author, type, subType, subSubType } = values;

      const params = makeSearchParams({
        keyword: filter || keyword,
        refineKeyword,
        author,
        type,
        subType,
        subSubType,
        sortBy,
        sortDirection,
      });

      navigate(`${book.search.preview}${buildUrlParams(params)}`);
      dispatch(fetchSearchList(params));
    },
    [dispatch, navigate, keyword, filter, sortBy, sortDirection],
  );

  return (
    <form
      data-id="search-form"
      onSubmit={handleSubmit(onFormSubmit)}
      autoComplete="off"
      className="c-fieldset"
    >
      <div className="c-title  c-title--lh-1">REFINE SEARCH</div>
      <div className="c-input">
        <Field name="refineKeyword" placeholder="Keyword" component={Input} />
      </div>
      <Line />

      <div className="c-title  c-title--lh-1">TYPE</div>
      <div className="c-input">
        <Field name="type" placeholder="Select…" component={Select} options={typesOptions} />
      </div>
      <Line />

      <div className="c-title  c-title--lh-1">DOCUMENT TYPE</div>
      <div className="c-select">
        <Field
          name="subType"
          placeholder="Select Type First..."
          component={Select}
          options={documentOptions[selectedType]}
        />
      </div>
      <Line />

      <div className="c-title  c-title--lh-1">DOCUMENT SUBTYPE</div>
      <div className="c-selecdt">
        <Field
          name="subSubType"
          placeholder="Select…"
          component={Select}
          options={subTypesOptions}
        />
      </div>
      <Line />

      <div className="c-usage">
        <DocumentCounter title="Documents" count={counters.documents} />
        <DocumentCounter title="Contracts" count={counters.contracts} />
        <DocumentCounter title="Document Templates" count={counters.documentTemplates} />
        <DocumentCounter title="Contract Templates" count={counters.contractTemplates} />
      </div>
      <Line />

      <div className="c-title  c-title--lh-1">AUTHOR</div>
      <div className="c-select">
        <Field name="author" placeholder="Select…" component={Select} options={authorOptions} />
      </div>
      <Button type="submit" disabled={submitting}>
        Submit
      </Button>
    </form>
  );
};

const SearchViewReduxForm = reduxForm<FormValues, Props>({
  form: 'search',
})(SearchViewForm);

const selector = formValueSelector('search');

export const SearchView = connect((state) => {
  return {
    selectedType: selector(state, 'type'),
  };
})(SearchViewReduxForm);

export const SearchSideBarView = () => <SearchView />;
