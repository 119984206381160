/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultSliceState } from 'src/app/types';

const initialState: DefaultSliceState = {
  isLoading: false,
  error: '',
};

export const createFromTemplateSlice = createSlice({
  name: 'createFromTemplateSlice',
  initialState,
  reducers: {
    createFromTemplateStart(state: DefaultSliceState): void {
      state.isLoading = true;
      state.error = '';
    },

    createFromTemplateFailed(state: DefaultSliceState, action: PayloadAction<string>): void {
      state.isLoading = false;
      state.error = action.payload;
    },

    createFromTemplateSuccess(state: DefaultSliceState): void {
      state.isLoading = false;
    },
  },
});

export const { createFromTemplateStart, createFromTemplateSuccess, createFromTemplateFailed } =
  createFromTemplateSlice.actions;

export default createFromTemplateSlice.reducer;
