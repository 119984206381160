import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ParsedQuery } from 'query-string';
import { noop } from 'lodash';

import {
  NavigateStrategy,
  useCreateNavigateToFn,
  useNavigateToFn,
} from 'src/v2/features/sidebarController/helpers-hooks';
import { CardPaperModel } from 'src/models/card';
import { canDeleteDocument } from 'src/v2/features/document/permissions/documentPermissions/documentPermissions';

import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import {
  deleteContractFromProject,
  deleteDocumentFromProject,
} from 'src/v2/features/project/store/projectReducers';
import { DeleteDocumentFromProjectDTO } from 'src/v2/boundary/requestDTO/project';
import { book } from './book';

export const useCreateShowDocumentMetadataView = (params: ParsedQuery<string>) =>
  useNavigateToFn({ params: { v: book.viewDocument, ...params } });

export const useCreateShowContractMetadataView = (params: ParsedQuery<string>) =>
  useNavigateToFn({ params: { v: book.viewContract, ...params } });

export const useCreateCreateDocumentMetadataView = () =>
  useNavigateToFn({ params: { v: book.createDocument } });

export const useCreateCreateContractMetadataView = () =>
  useNavigateToFn({ params: { v: book.createContract } });

export const useCreateEditDocumentMetadataView = () =>
  useNavigateToFn({ params: { v: book.editDocument }, strategy: NavigateStrategy.Update });

export const useCreateEditContractMetadataView = () =>
  useNavigateToFn({ params: { v: book.editContract }, strategy: NavigateStrategy.Update });

export const createDeleteFactory = (
  action: ActionCreatorWithPayload<DeleteDocumentFromProjectDTO>,
) =>
  function useCreateNavigateToDelete(entity: CardPaperModel<unknown, unknown>, projectId: string) {
    const dispatch = useDispatch();
    return useCallback(() => {
      if (canDeleteDocument(entity.state)) {
        return (): void => {
          dispatch(
            action({
              entityId: entity.id,
              projectId,
            }),
          );
        };
      }

      return noop;
    }, [entity, projectId, dispatch]);
  };

export const useCreateDeleteDocument = createDeleteFactory(deleteDocumentFromProject);
export const useCreateDeleteContract = createDeleteFactory(deleteContractFromProject);

export const useNavigateToProjectCreate = () =>
  useNavigateToFn({ params: { v: book.createProject } });
export const useNavigateToProjectViewFactory = () =>
  useCreateNavigateToFn({ params: { v: book.viewProject } });
export const useNavigateToProjectEditFactory = () =>
  useCreateNavigateToFn({ params: { v: book.editProject } });
export const useNavigateToProjectRemoveFactory = () =>
  useCreateNavigateToFn({ params: { v: book.removeProject } });
