import { isNull } from 'lodash';
import qs from 'qs';

import { SearchParams } from 'src/api/searchApi';
import { SortDirection, SortedFields, SortUrlParams } from 'src/models/sort';

const filterUrlParams = (prefix: string, value: string): string | undefined => {
  if (value) {
    return value;
  }

  return undefined;
};

export const buildUrlParams = (params: SearchParams | SortUrlParams): string => {
  return `?${qs.stringify(params, { filter: filterUrlParams })}`;
};

export const decode = (query: URLSearchParams, key: string): string =>
  decodeURIComponent(query.get(key) || '');

export const getSortUrlParams = (query: URLSearchParams): SortUrlParams => {
  return {
    sortBy: decode(query, 'sortBy'),
    sortDirection: decode(query, 'sortDirection'),
  };
};

export const calcSortType = (sortBy: string, sortDirection: string): string => {
  if (sortBy && !sortDirection) return SortDirection.Desc;
  if (sortBy && sortDirection) return sortDirection;
  return '';
};

export const makeSortParams = (params: SortUrlParams): SortUrlParams => {
  return {
    sortBy: params.sortBy || SortedFields.CreatedAt,
    sortDirection: calcSortType(
      params.sortBy || SortedFields.CreatedAt,
      params.sortDirection || SortDirection.Desc,
    ),
  };
};

export const decodeContentToDomainUrl = (content: string) => {
  const replaceDomainUrl = process.env.REACT_APP_REPLACE_DOMAIN || '';
  const isContentContainsHTML = !content
    .replace(/<([^>]+?)([^>]*?)>(.*?)<\/\1>/gi, '')
    .replace(/(<([^>]+)>)/gi, '')
    .trim();
  const imageUrl = content.match(new RegExp('<img[^>]*src="([^"]+)"[^>]*>', 'gi'));

  if (replaceDomainUrl.length > 0) {
    if (!isContentContainsHTML) {
      return content.replace(/https?:\/\/[^/]+((?=\/)|$)/g, replaceDomainUrl);
    } else if (!isNull(imageUrl)) {
      return imageUrl
        .map((item) => item.replace(/https?:\/\/[^/]+((?=\/)|$)/g, replaceDomainUrl))
        .reduce((res, obj, index) => res.replace(new RegExp(imageUrl[index]), obj), content);
    }
  }

  return content;
};
