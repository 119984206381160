import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { omit, isNull, isString, isUndefined } from 'lodash';
import { ParsedQuery } from 'query-string';

import { useUrlManagerHash } from 'src/common/hooks/urlManager';
import { OldNotifications } from 'src/v2/features/notifications';
import { SidebarRoute } from './SidebarRoute';
import { SidebarRouter } from './SidebarRouter';
import { SidebarFactoryComponent, QueryStringConverter } from './types';
import { book } from './book';
import { useWithHistoryFactory } from './deprecated-hooks';

export const useOmitHashUrlFactory = (key: string, params: ParsedQuery<string> = {}): string => {
  const [hash, createHash] = useUrlManagerHash();

  return createHash({
    ...omit(hash, key),
    ...params,
  });
};

const createUseOpen =
  (name: string) =>
  (params: ParsedQuery<string> = {}): string => {
    const [, , createUpdatedHash] = useUrlManagerHash();

    return createUpdatedHash({ [name]: null, ...params });
  };

const createUseClose = (name: string) => (): string => useOmitHashUrlFactory(name);

const createIsOpen = (name: string) => (): boolean => {
  const [hash] = useUrlManagerHash();

  return isNull(hash[name]);
};

const createUseToggleCallback = (name: string) => (): (() => void) => {
  const useOpen = createUseOpen(name);
  const useClose = createUseClose(name);
  const useIsOpen = createIsOpen(name);
  const isOpen = useIsOpen();
  const navigate = useNavigate();
  const openLink = useOpen();
  const closeLink = useClose();

  return useCallback(
    (): void => navigate(isOpen ? closeLink : openLink),
    [isOpen, openLink, closeLink, navigate],
  );
};

export const useViewLinkFactory = (url: string, params?: ParsedQuery<string>): string => {
  const [, createHash] = useUrlManagerHash();

  return createHash({ v: url, ...params });
};

export const useViewLinkCallbackFactory = (url: string): QueryStringConverter => {
  const [, createHash] = useUrlManagerHash();

  return useCallback((params) => createHash({ v: url, ...params }), [url, createHash]);
};

const useShowNotificationPush = (): (() => void) => {
  const link = useViewLinkFactory(book.notifications);
  return useWithHistoryFactory(link);
};

const useHideNotificationPush = (): (() => void) => {
  const closeLink = useOmitHashUrlFactory('v');

  return useWithHistoryFactory(closeLink);
};

const useNotificationTogglePush = (): (() => void) => {
  const [hash] = useUrlManagerHash();
  const show = useShowNotificationPush();
  const hide = useHideNotificationPush();

  return hash.v === book.notifications ? hide : show;
};

export const sidebarControllerFactory = (
  name: string,
  routes: { path: string; render: FC<any> }[] = [],
): [
  () => boolean,
  Record<string, (...args: any) => any>,
  Record<string, (...args: any) => any>,
  SidebarFactoryComponent,
] => {
  const defaultLinks = {
    useOpen: createUseOpen(name),
    useClose: createUseClose(name),
  };

  const defaultPushes = {
    useToggleCallback: createUseToggleCallback(name),
    useShowNotificationPush,
    useHideNotificationPush,
    useNotificationTogglePush,
  };

  const Component: SidebarFactoryComponent = ({ children, criteria }) => {
    const [defaultCriteria] = useUrlManagerHash();
    const normalizedCriteria = isUndefined(criteria) ? defaultCriteria : criteria;
    const { v, ...restParams } = normalizedCriteria;

    if (!isString(v)) {
      console.warn(`sidebarControllerFactory:: v should be a string not a ${typeof v}`);
      return null;
    }

    return (
      <SidebarRouter view={v}>
        <SidebarRoute value={book.notifications} sidebarName={name}>
          <OldNotifications />
        </SidebarRoute>
        {routes.map(({ path, render }, idx) => (
          <SidebarRoute value={path} key={`${name}-${idx}`} sidebarName={name}>
            {render(restParams)}
          </SidebarRoute>
        ))}
        {children}
      </SidebarRouter>
    );
  };

  Component.displayName = name;

  return [createIsOpen(name), defaultLinks, defaultPushes, Component];
};
