import { FC } from 'react';
import { useSelector } from 'react-redux';

import { DocumentRole } from 'src/models/document';
import { CardData } from 'src/v2/features/sharedEntity/types';
import { Copy } from 'src/v2/icons/Copy';
import { FileMove } from 'src/v2/icons/FileMove';
import { ContextMenuItem } from 'src/v2/components/contextMenu';
import { ContextMenuProps } from 'src/v2/features/contextMenu/types';
import { EntityContextMenu } from 'src/v2/features/sharedEntity/EntityContextMenu';
import { isDocumentFolder } from 'src/v2/features/quantumNavigator';
import { useGetRootFolderId } from 'src/v2/features/sharedEntity/hooks';
import { isProjectsFolder } from 'src/v2/features/quantumNavigator/utils';
import { useOpenModal, useOpenModalWithProjects } from 'src/v2/features/folderSelector/hooks';
import { ModalType } from 'src/v2/features/modal/types';
import { getActivePlan } from 'src/v2/features/billing';
import {
  useNavigateToEditParticipant,
  useNavigateToInviteParticipant,
} from 'src/v2/features/document/DocumentSidebar/nav-hooks';
import { useEditParticipantCallback } from 'src/v2/features/entityParticipants/hooks';
import { DropdownAddUser } from 'src/v2/icons/DropdownAddUser';
import { DropdownEditUser } from 'src/v2/icons/DropdownEditUser';
import {
  canCopyDocument,
  canDownloadDocument,
  canShareDocument,
  canManageParticipantInDocument,
  canMoveDocument,
} from 'src/v2/features/document/permissions/documentPermissions/documentPermissions';

export const DocumentContextMenu: FC<ContextMenuProps> = (props) => {
  const plan = useSelector(getActivePlan);
  const { payload, closeOnClick } = props;
  const { id: documentId, role } = payload as CardData<DocumentRole>;
  const documentRootFolderId = useGetRootFolderId(isDocumentFolder);
  const projectsRootFolderId = useGetRootFolderId(isProjectsFolder);

  const isAllowedToShareEntity = canShareDocument(role);
  const isAllowedToDownloadDocument = canDownloadDocument(role);
  const isAllowedToCopyDocument = canCopyDocument(role, plan);
  const isAllowedToMoveDocument = canMoveDocument(plan);
  const isAllowedToManageParticipants = canManageParticipantInDocument(role);

  const handleMove = useOpenModal({
    type: ModalType.DocumentMoveTo,
    menuItemFolderId: documentRootFolderId,
    targetId: documentId,
  });

  const handleCopyTo = useOpenModalWithProjects({
    type: ModalType.DocumentCopyTo,
    projectsRootFolderId,
    menuItemFolderId: documentRootFolderId,
    targetId: documentId,
  });

  const navigateToInviteParticipant = useNavigateToInviteParticipant(documentId);
  const navigateToEditParticipant = useNavigateToEditParticipant(documentId);

  const handleInviteUser = closeOnClick(navigateToInviteParticipant);
  const handleEditUser = closeOnClick(useEditParticipantCallback(navigateToEditParticipant)());

  return (
    <EntityContextMenu<DocumentRole>
      {...props}
      isAllowedToShareEntity={isAllowedToShareEntity}
      isAllowedToDownloadEntity={isAllowedToDownloadDocument}
      participantChildren={
        <>
          <ContextMenuItem
            icon={DropdownAddUser}
            text="Add user"
            disabled={!isAllowedToManageParticipants}
            onClick={handleInviteUser}
            data-id="addUser-menuButton"
          />
          <ContextMenuItem
            icon={DropdownEditUser}
            text="Edit user"
            onClick={handleEditUser}
            data-id="editUser-menuButton"
          />
        </>
      }
    >
      <ContextMenuItem
        icon={Copy}
        text="Copy To"
        disabled={!isAllowedToCopyDocument}
        onClick={handleCopyTo}
        data-id="copyto-menuButton"
      />

      <ContextMenuItem
        icon={FileMove}
        text="Move To"
        disabled={!isAllowedToMoveDocument}
        onClick={handleMove}
        data-id="movefile-menuButton"
      />
    </EntityContextMenu>
  );
};
