import { useAuthAndFillOrganization } from 'src/v2/features/profile/hooks';
import { ProfilePage } from 'src/v2/features/profile/ProfilePage';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

import { SecurityForm } from './index';

export const Security: React.FC = () => {
  useAuthAndFillOrganization();

  return (
    <ProfilePage>
      <div className="c-setup c-setup--bg-transparent" data-id="profile-container-security">
        <div className="c-setup__top">
          <div className="l-row">
            <div className="c-title c-title--color-theme c-title--fs-24">
              {i18n(translationKeys.forms.changePassword.title)}
            </div>
          </div>
        </div>
        <div className="c-setup__content">
          <SecurityForm />
        </div>
      </div>
    </ProfilePage>
  );
};
