import React, { HTMLProps } from 'react';

export const CForm: React.FC<React.PropsWithChildren<HTMLProps<HTMLFormElement>>> = ({
  children,
  ...other
}) => {
  return (
    <form className="c-form" {...other}>
      {children}
    </form>
  );
};
