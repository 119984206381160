import React, { FC } from 'react';

interface Props {
  label: string;
  icon: FC;
  disabled?: boolean;
  onClick?: () => void;
}

export const SectionItem: FC<Props> = ({ label, icon: Icon, disabled, ...rest }) => (
  <button className="c-panel__item" type="button" disabled={disabled} {...rest}>
    <span className="c-panel__icon">
      <Icon />
    </span>
    <span className="c-panel__title h-uppercase">{label}</span>
  </button>
);
