import { FC, useMemo } from 'react';
import { get } from 'lodash';
import { getEntityLinkByNotificationTarget } from '../utils';
import { NotificationTarget, NotificationTemplateItemType } from 'src/v2/entities/notification';

interface Props {
  target: NotificationTarget;
}

const InviterName: FC<Props> = ({ children }) => <b>{children}</b>;

const DocumentTitle: FC<Props> = ({ children, target }) => {
  const href = useMemo(() => getEntityLinkByNotificationTarget(target), [target]);

  return href ? (
    <a href={href} className="c-history__link">
      {children}
    </a>
  ) : (
    <>{children}</>
  );
};

const Wrappers = {
  [NotificationTemplateItemType.InviterName]: InviterName,
  [NotificationTemplateItemType.DocumentTitle]: DocumentTitle,
};

export const getWrapper = (key: NotificationTemplateItemType) => get(Wrappers, key);
