import React from 'react';

import { useGetFullParticipantsByDocumentId } from 'src/v2/features/entityParticipants/hooks';
import { ParticipantsSectionWithoutUsers } from 'src/v2/features/sharedEntity/EntitySidebar/ParticipantsSectionWithoutUsers';

export interface Props {
  entityId: string;
  isAllowedToEditParticipant: (participantId: string) => boolean;
  isAllowedToRemoveParticipant: (participantId: string) => boolean;
}

export const ParticipantsSection: React.FC<Props> = ({
  entityId,
  isAllowedToEditParticipant,
  isAllowedToRemoveParticipant,
}) => {
  const participants = useGetFullParticipantsByDocumentId(entityId);
  return (
    <ParticipantsSectionWithoutUsers
      participants={participants}
      entityId={entityId}
      isAllowedToEditParticipant={isAllowedToEditParticipant}
      isAllowedToRemoveParticipant={isAllowedToRemoveParticipant}
    />
  );
};
