import React from 'react';

import { ModalView } from 'src/v2/features/modal';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

type Props = {
  continueAddUser: () => void;
  onClose: () => void;
  numberOfSeats: number;
};

export const WarningModal: React.FC<Props> = ({ continueAddUser, onClose, numberOfSeats }) => {
  return (
    <ModalView isActive>
      <div className="c-modal__close" onClick={onClose}></div>
      <div className="c-modal__row">
        <div className="c-title c-title--center c-title--fs-32 h-uppercase">
          {i18n(translationKeys.modal.warning)}
        </div>
      </div>
      <div className="c-modal__row c-modal--text-center c-modal--justify-center">
        {i18n(translationKeys.modal.availableSeats, { numberOfSeats })}
      </div>
      <div className="c-modal__row c-modal--justify-center c-modal--mt-32">
        <div className="c-modal__column">
          <button
            className="c-button c-button--gray c-button--w-140"
            type="button"
            onClick={onClose}
          >
            <span className="c-button__inner h-uppercase">
              {i18n(translationKeys.buttons.goBack)}
            </span>
          </button>
        </div>
        <div className="c-modal__column">
          <button
            className="c-button c-button--green c-button--w-140"
            type="button"
            onClick={continueAddUser}
          >
            <span className="c-button__inner h-uppercase">
              {i18n(translationKeys.buttons.accept)}
            </span>
          </button>
        </div>
      </div>
    </ModalView>
  );
};
