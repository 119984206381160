const styles = {
  default: {
    field: {
      width: '100%',
      m: 0,
      px: '8px',
      minHeight: '40px',
      bg: '#ffffff',
      fontFamily: 'body',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      color: '#000000',
      border: '2px solid #ebeded',
      outline: 0,
      '&::placeholder': {
        color: '#a6adb4',
      },
      '&[disabled]': {
        cursor: 'default',
        opacity: 0.7,
      },
      '&[type="password"]': {
        letterSpacing: '1px',
      },
    },

    label: {
      width: 'auto',
      m: 0,
      py: 0,
      fontFamily: 'body',
      fontSize: '14px',
      fontWeight: 'bold',
      fontStyle: 'normal',
      lineHeight: 1,
      letterSpacing: 'normal',
      color: '#000000',
    },

    select: {
      width: '100%',
      m: 0,
      px: '8px',
      minHeight: '40px',
      bg: '#ffffff',
      fontFamily: 'body',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      color: '#000000',
      border: '2px solid #ebeded',
      boxShadow: 'none',
      outline: 0,
      '& + svg': {
        color: '#8a939f',
      },
      '&[disabled]': {
        cursor: 'default',
        opacity: 0.7,
      },
    },
  },
};

export const forms = {
  // Default
  label: {
    ...styles.default.label,
  },

  input: {
    ...styles.default.field,
  },

  textarea: {
    ...styles.default.field,
    p: '8px',
    resize: 'none',
  },

  checkbox: {
    cursor: 'pointer',
    'input[disabled] ~ &': {
      cursor: 'default',
      opacity: 0.7,
    },
    'input ~ &': {
      size: '19px',
      ml: '-1px',
    },
    'input:checked ~ &': {
      color: '#f4f4f4',
      zIndex: '2',
      position: 'relative',
    },
    'input:focus ~ &': {
      color: '#f4f4f4',
      backgroundColor: 'transparent',
    },
    '& ~ svg': {
      color: 'transparent',
    },
  },

  radio: {
    cursor: 'pointer',
    color: '#29cb97',
    'input:focus ~ &': {
      bg: 'transparent',
    },
    'input:checked ~ &': {
      color: '#29cb97',
    },
    'input[disabled] ~ &': {
      cursor: 'default',
      opacity: 0.4,
    },
  },

  select: {
    ...styles.default.select,
  },

  // Variants
  accentInput: {
    ...styles.default.field,
    border: 'none',
  },

  accentLabel: {
    ...styles.default.label,
    lineHeight: 2,
    textTransform: 'uppercase',
    color: '#e4b62c',
  },

  accentLoginInput: {
    ...styles.default.field,
    border: 'none',
  },

  accentLoginLabel: {
    ...styles.default.label,
    lineHeight: 2,
    textTransform: 'uppercase',
    color: '#2e4354',
  },

  newDocInput: styles.default.field,
  newDocTextarea: styles.default.field,
  newDocLabel: {
    ...styles.default.label,
    color: 'primary',
    fontSize: 'huge',
    opacity: 0.3,
  },

  noBorderRedInput: {
    ...styles.default.field,
    borderRadius: '7px',
    border: 'none',
    '&::placeholder': {
      color: '#d57872',
    },
  },

  noBorderRedSelect: {
    ...styles.default.select,
    border: 'none',
    borderRadius: '7px',
  },
};
