import React, { PropsWithChildren } from 'react';
import { size } from 'lodash';
import { UploadableFile } from 'src/v2/features/fileUpload/types';
import { DragContentPlaceholder } from './DragContentPlaceholder';
import { FileUploading } from './FileUploading';

interface Props {
  files: UploadableFile[];
  onRetry?: (fileId: string) => void;
  onCancel?: (fileId: string) => void;
}

export const FilesListOrPlaceholder: React.FC<PropsWithChildren<Props>> = ({
  children,
  files,
  onRetry,
  onCancel,
}) => {
  return size(files) > 0 ? (
    <>
      {files.map((file) => (
        <FileUploading file={file} key={file.id} onRetry={onRetry} onCancel={onCancel} />
      ))}
    </>
  ) : (
    <DragContentPlaceholder>{children}</DragContentPlaceholder>
  );
};
