import React from 'react';
import TagsInput from 'react-tagsinput';
import cn from 'classnames';

import { ChipsProps } from '../types';
import { WithValidationMessage } from '../WithValidationMessage';
import styles from './Chips.module.css';

const classModifiers = {
  disabled: 'c-input--disabled',
  readOnly: 'c-input--readonly',
  mb8: 'c-input--mb-8',
};

export const Chips = ({
  errorMessage,
  successMessage,
  className,
  mb8 = false,
  ...inputProps
}: ChipsProps): JSX.Element => {
  const { disabled } = inputProps;

  return (
    <WithValidationMessage
      className={cn('c-input', styles.container, className, {
        [classModifiers.disabled]: disabled,
        [classModifiers.mb8]: mb8,
      })}
      errorMessage={errorMessage}
      successMessage={successMessage}
      classPrefix="c-input"
    >
      <TagsInput {...inputProps} />
    </WithValidationMessage>
  );
};
