/* eslint-disable no-param-reassign */

// This toolchain is intended to simplify creation of regular state slices
// Data should be always represented as an array
// Other data extraction manipulation, like `head(data)`, should be placed in selectors
//
// Example of usage:
// const { onStart, onFailWithError, onSuccessWithDataSerialized } = defaultActionsFactory();
//
// export const stateSlice = createSlice({
//   name: 'stateSlice',
//   initialState: initialStateFactory(),
//   reducers: {
//     // Name your actions the way you want
//     actionStart: onStart,
//     actionFail: onFailWithError,
//     actionSuccess: onSuccessWithDataSerialized,
//   },
// });

import { ObjectSerialized } from 'src/common/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { DefaultSliceState, DefaultSliceStateWithData } from 'src/app/types';

export interface DefaultActions {
  onStart: (state: DefaultSliceState) => void;
  onError: (state: DefaultSliceState, action: PayloadAction<string>) => void;
  onSuccess: (state: DefaultSliceState) => void;
}

export interface DefaultActionsWithData extends DefaultActions {
  onSuccessWithDataSerialized: (
    state: DefaultSliceStateWithData,
    action: PayloadAction<ObjectSerialized>,
  ) => void;
}

export const defaultActionsFactory = (): DefaultActions => ({
  onStart: (state: DefaultSliceState): void => {
    state.isLoading = true;
    state.error = '';
  },
  onError: (state: DefaultSliceState, action: PayloadAction<string>): void => {
    state.error = action.payload;
    state.isLoading = false;
  },
  onSuccess: (state: DefaultSliceState): void => {
    state.isLoading = false;
    state.error = '';
  },
});

export const defaultActionsFactoryWithData = (): DefaultActionsWithData => ({
  ...defaultActionsFactory(),
  onSuccessWithDataSerialized: (
    state: DefaultSliceStateWithData,
    action: PayloadAction<ObjectSerialized>,
  ): void => {
    state.data = ObjectSerialized.dataToBaseObjects(action.payload.data);
    state.isLoading = false;
    state.error = '';
  },
});

export const initialStateFactory = (): DefaultSliceStateWithData => ({
  isLoading: false,
  data: [],
  error: '',
});
