import { all, fork, take, cancel } from 'redux-saga/effects';

import { watchTemplatesSagas } from 'src/v2/features/template/sagas';
import { watchCreateFromTemplate } from 'src/v2/features/createFromTemplate/sagas';
import { watchDocumentWorkflowSagas } from 'src/v2/features/documentWorkflow/documentWorkflow';
import { watchChatSagas } from 'src/v2/features/chat/store';
import { watchDocumentListSagas } from 'src/v2/features/documentList/documentListSlice';
import { watchDocumentDetailsSagas } from 'src/v2/features/documentDetails/documentDetailsSlice';
import { watchOrganizationSagas } from 'src/v2/features/organization';
import { watchSubscribe as watchLandingSubscribeSaga } from 'src/v2/features/landing/subscribeSaga';
import { watchProfileSagas } from 'src/v2/features/profile';
import { watchDocumentParticipantsSagas } from 'src/v2/features/documentParticipants/sagas';
import { watchUploadAttachmentsSagas } from 'src/v2/features/document/uploadAttachments/uploadAttachmentsStore';
import { watchAttachmentsListSagas } from 'src/v2/features/document/attachmentsList/attachmentsListStore';
import { watchDocumentUploadSagas } from 'src/v2/features/documentSidebar/documentSidebarUpload/documentSidebarUploadStore';
import { watchNotificationSagas } from 'src/v2/features/notifications/notificationsStore';
import { watchAuthSagas } from 'src/v2/features/auth';
import { watchSignupSagas } from 'src/v2/features/signUp';
import { watchAccountRecoverySagas } from 'src/v2/features/accountRecovery/accountRecoverySagas';
import { watchQrCodeAccountRecoverySagas } from 'src/v2/features/qrCodeAccountRecovery/qrCodeAccountRecoverySagas';
import { watchSearchListSagas } from 'src/v2/features/search';
import { watchDocumentSidebarSagasV2 } from 'src/v2/features/sharedEntity/EntitySidebar/sagas';
import { watchSharedEntitySagas } from 'src/v2/features/sharedEntity/sagas';
import { watchBillingSagas } from 'src/v2/features/billing';
import { watchCheckoutSagas } from 'src/v2/features/checkout';
import { watchQuantumNavigatorSagas } from 'src/v2/features/quantumNavigator';
import { watchSignatureSagas } from 'src/v2/features/signature';
import { watchHankoSignatureSagas } from 'src/v2/features/hankoSignature';
import { watchFolderObjectsSagas } from 'src/v2/features/folderObjects';
import { watchObjectsListSagas } from 'src/v2/features/objectsList/store';
import { watchTemplateUploadSagas } from 'src/v2/features/template/TemplateSidebar/templateSidebarUpload/templateSidebarUploadStore';
import { watchTemplatesListSaga } from 'src/v2/features/sharedEntity/templatesListStore';
import { watchProjectSagas } from 'src/v2/features/project/store/projectSagas';
import { watchContractsRootSagas } from 'src/v2/features/contract/store';
import { watchDocumentsRootSagas } from 'src/v2/features/document/store';
import { watchCustomReduxFormSagas } from 'src/v2/features/reduxForm/store';
import { cancelRootSaga } from '../common/commonActions';

function* tasks() {
  return yield all([
    fork(watchDocumentListSagas),
    fork(watchDocumentParticipantsSagas),
    fork(watchDocumentDetailsSagas),
    fork(watchAuthSagas),
    fork(watchOrganizationSagas),
    fork(watchTemplatesSagas),
    fork(watchLandingSubscribeSaga),
    fork(watchProfileSagas),
    fork(watchCreateFromTemplate),
    fork(watchDocumentWorkflowSagas),
    fork(watchDocumentSidebarSagasV2),
    fork(watchSharedEntitySagas),
    fork(watchUploadAttachmentsSagas),
    fork(watchAttachmentsListSagas),
    fork(watchDocumentUploadSagas),
    fork(watchChatSagas),
    fork(watchNotificationSagas),
    fork(watchSignupSagas),
    fork(watchAccountRecoverySagas),
    fork(watchQrCodeAccountRecoverySagas),
    fork(watchSearchListSagas),
    fork(watchBillingSagas),
    fork(watchCheckoutSagas),
    fork(watchQuantumNavigatorSagas),
    fork(watchSignatureSagas),
    fork(watchHankoSignatureSagas),
    fork(watchFolderObjectsSagas),
    fork(watchObjectsListSagas),
    fork(watchTemplateUploadSagas),
    fork(watchTemplatesListSaga),
    fork(watchProjectSagas),
    fork(watchContractsRootSagas),
    fork(watchDocumentsRootSagas),
    fork(watchCustomReduxFormSagas),
  ]);
}

export default function* rootSaga() {
  while (true) {
    // @ts-ignore
    const allTasks = yield fork(tasks);
    yield take(cancelRootSaga);
    yield cancel(allTasks);
  }
}
