import { FC, useCallback, MouseEvent } from 'react';
import { acceptance } from 'redux-form-validators';
import { Field } from 'redux-form';

import { Checkbox } from 'src/v2/features/reduxForm';

import { TermsLink } from '../TermsLink';

interface Props {
  onLinkClick: () => void;
}

export const AgreementCheckbox: FC<Props> = ({ onLinkClick }) => {
  const handleOnLinkClick = useCallback(
    (event: MouseEvent<HTMLInputElement>) => {
      event.preventDefault();
      onLinkClick();
    },
    [onLinkClick],
  );

  return (
    <Field
      component={Checkbox}
      name="agree"
      validate={[acceptance()]}
      type="checkbox"
      label={<TermsLink onClick={handleOnLinkClick} />}
    />
  );
};
