import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formValueSelector, isInvalid, reset } from 'redux-form';

import { Radio } from 'src/v2/components/Radio';
import { Button, Type } from 'src/v2/features/button/Button';
import { useGetParticipantsByTemplateId } from 'src/v2/features/template/hooks';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { RootState } from 'src/app/types';
import { getUserId } from 'src/shared/auth/authSelectors';
import { TemplateRole } from 'src/models/template';

import { inviteParticipants, removeAllParticipants } from '../../reducer';
import { InviteParticipantsForm } from './InviteParticipantsForm';

export interface Props {
  templateId: string;
}

enum PublishMode {
  Private = 'private',
  SpecificUsers = 'specificUsers',
}

const FORM_ID = 'inviteParticipantsForm';
const formSelector = formValueSelector(FORM_ID);
const isInviteFormInvalid = isInvalid(FORM_ID);
const resetInviteForm = reset(FORM_ID);

export const Publish: React.FC<Props> = ({ templateId }) => {
  const dispatch = useDispatch();
  const inviteFormInvalid = useSelector(isInviteFormInvalid);
  const emails = useSelector((state: RootState) => formSelector(state, 'emails'));
  const participants = useGetParticipantsByTemplateId(templateId);
  const [publishMode, setPublishMode] = useState<PublishMode>();
  const viewerParticipant = participants.filter(({ roleId }) => roleId === TemplateRole.Viewer);
  const currentUserId = useSelector(getUserId);
  const isCurrentUserViewer = viewerParticipant.some(({ userId }) => userId === currentUserId);
  const isPublishDisabled = publishMode === PublishMode.SpecificUsers && inviteFormInvalid;
  const isUnpublishDisabled =
    publishMode === PublishMode.Private && (participants.length <= 1 || isCurrentUserViewer);
  const isSubmitDisabled = !publishMode || isPublishDisabled || isUnpublishDisabled;

  useEffect(() => {
    setPublishMode(participants.length > 1 ? PublishMode.SpecificUsers : PublishMode.Private);
  }, [participants.length, templateId]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    setPublishMode(e.target.value as PublishMode);
  }, []);

  const handleSubmit = useCallback((): void => {
    if (publishMode === PublishMode.Private) {
      dispatch(removeAllParticipants(templateId));
    } else if (publishMode === PublishMode.SpecificUsers) {
      dispatch(inviteParticipants({ documentId: templateId, emails }));
      dispatch(resetInviteForm);
    }
  }, [dispatch, publishMode, templateId, emails]);

  const buttonLabel =
    publishMode !== PublishMode.Private
      ? i18n(translationKeys.buttons.publishTemplate)
      : i18n(translationKeys.buttons.unpublishTemplate);

  return (
    <div className="c-fieldset">
      <div className="c-title c-title--uppercase c-title--lh-1">
        {i18n(translationKeys.forms.item.publish)}
      </div>
      <div className="c-fieldset__group">
        <Radio
          label={i18n(translationKeys.forms.item.private)}
          name="publish"
          onChange={handleChange}
          value={PublishMode.Private}
          checked={publishMode === PublishMode.Private}
        />
      </div>
      <div className="c-fieldset__group">
        <Radio
          label={i18n(translationKeys.forms.item.specificUsers)}
          name="publish"
          onChange={handleChange}
          value={PublishMode.SpecificUsers}
          checked={publishMode === PublishMode.SpecificUsers}
        />
      </div>
      {publishMode === PublishMode.SpecificUsers && <InviteParticipantsForm />}
      <Button
        disabled={isSubmitDisabled}
        onClick={handleSubmit}
        actionType={Type.Secondary}
        label={buttonLabel}
      />
    </div>
  );
};
