import React, { FC, useCallback } from 'react';
import { ConfirmAction, useNavigateToDefault } from 'src/v2/features/sharedEntity';
import { removeParticipant } from 'src/v2/features/documentParticipants';
import { useDispatch, useSelector } from 'react-redux';
import { getIsRemoving } from 'src/v2/features/documentParticipants/removeParticipantStore';

export interface Props {
  id: string;
  participantId: string;
}

export const RemoveParticipant: FC<Props> = ({ id, participantId }) => {
  const dispatch = useDispatch();

  const navigateToDefault = useNavigateToDefault(id);

  const isRemoving = useSelector(getIsRemoving);

  const onRemoveParticipant = useCallback(() => {
    dispatch(
      removeParticipant({
        documentId: id,
        userId: participantId,
      }),
    );
  }, [dispatch, id, participantId]);

  return (
    <ConfirmAction
      title="You are about to remove the user. Do you wish to continue?"
      onOk={onRemoveParticipant}
      onCancel={navigateToDefault}
      isLoading={isRemoving}
    />
  );
};
