import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  put,
  takeEvery,
  take,
  fork,
  cancel,
  cancelled,
  PutEffect,
  CallEffect,
  TakeEffect,
  ForkEffect,
  all,
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { isUndefined, get } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { toastr } from 'react-redux-toastr';

import { history } from 'src/initializeHistory';
import {
  normalizeErrorsResponse,
  fileUploadReducerFactory,
  fileUploadSelectorsFactory,
  showErrorToast,
} from 'src/v2/features/fileUpload';
import {
  CreateUploadFileChannelPayload,
  FileUploadState,
  UploadError,
} from 'src/v2/features/fileUpload/types';
import { TemplatePayload } from 'src/models/template';
import { createTemplateFromUploadApi } from 'src/api/templates';
import { RootState } from 'src/app/types';
import { createNavigateToTemplateDetails } from 'src/v2/features/template/utils';

export interface UploadFilePayload {
  file: File;
  payload: TemplatePayload;
  templateId: string;
  folderId: string;
}

const NAMESPACE = 'templateSidebarUpload';

export const {
  actions: {
    uploadStart,
    uploadEnd,
    uploadUpdateProgress,
    uploadFailed,
    uploadFile,
    retryUploadFile,
  },
  reducer,
} = fileUploadReducerFactory<UploadFilePayload>(NAMESPACE);

function* uploadTemplateFileSaga({
  id,
  file,
  retry = false,
  payload,
  templateId,
  folderId,
}: {
  id: string;
  file: File;
  payload: TemplatePayload;
  templateId: string;
  retry?: boolean;
  folderId: string;
}): SagaIterator {
  let channel: any;

  try {
    const { name } = file;
    const navigateToTemplateDetails = createNavigateToTemplateDetails(history);

    yield retry
      ? put(uploadUpdateProgress({ id, progress: 0 }))
      : put(uploadStart({ id, name, documentId: templateId }));
    channel = yield call(createTemplateFromUploadApi, file, payload, folderId);

    while (true) {
      const { progress = 0, err, data } = (yield take(channel)) as CreateUploadFileChannelPayload;
      if (!isUndefined(err)) {
        const errors = normalizeErrorsResponse(err);
        yield all(errors.map((error) => call(showErrorToast, error)));
        yield put(uploadFailed({ id, err: errors[0] }));
        return;
      }
      if (data) {
        yield put(uploadEnd({ id }));
        const { id: createdTemplateId } = get(data, 'data[0]');
        yield call(navigateToTemplateDetails, createdTemplateId, folderId);
        toastr.success('Upload finished', `File ${file.name} was attached to the document`);
        return;
      }
      yield put(uploadUpdateProgress({ id, progress }));
    }
  } catch (error) {
    console.error(error);
    toastr.error('Error', `Oops, something went wrong!`);
  } finally {
    if (yield cancelled()) {
      if (channel) {
        channel.close();
        yield put(uploadUpdateProgress({ id, progress: 0 }));
      }
    }
  }
}

function* uploadTemplateFileWithRetrySaga(
  action: PayloadAction<UploadFilePayload>,
): Generator<PutEffect | CallEffect | TakeEffect | ForkEffect> {
  const { file, payload, templateId, folderId } = action.payload;
  const id = uuidv4();
  let task: any;
  try {
    task = yield fork(uploadTemplateFileSaga, {
      file,
      id,
      payload,
      templateId,
      folderId,
    });
    while (true) {
      const { payload: retryId } = (yield take(retryUploadFile)) as PayloadAction<string>;
      if (retryId === id) {
        // @ts-ignore
        yield cancel(task);
        task = yield fork(uploadTemplateFileSaga, {
          id,
          file,
          retry: true,
          payload,
          templateId,
          folderId,
        });
      }
    }
  } catch (error) {
    console.error(error);
    toastr.error('Error', `Oops, something went wrong!`);
    yield put(uploadFailed({ id, err: UploadError.Unknown }));
  }
}

export function* watchTemplateUploadSagas(): Generator {
  yield takeEvery(uploadFile, uploadTemplateFileWithRetrySaga);
}

const getState = (state: RootState): FileUploadState => state.templateSidebarUpload;
export const { getUploadsListByDocumentIdFactory } = fileUploadSelectorsFactory(getState);

export const templateSidebarUploadReducer = reducer;
