import React, { FC } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { confirmation } from 'redux-form-validators';

import { useQuery } from 'src/utils/hooks';
import { validatePassword } from 'src/utils/passwordStrength';
import { validateRecoveryForm } from 'src/utils/validate';
import { Password } from 'src/v2/features/reduxForm/Password';

import { resetPasswordAsync } from './accountRecoveryStore';
import { RecoveryValues } from './types';

export const Form: React.FC<InjectedFormProps<RecoveryValues>> = ({
  handleSubmit,
  invalid,
  submitting,
  pristine,
}) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const token = query.get('token');

  useEffect(() => {
    if (!token) {
      toastr.error('Error', 'Wrong reset password link, please click Forget Password');
    }
  }, [token]);

  const submitForm = (values: RecoveryValues): void => {
    const { password } = values;

    if (token) {
      dispatch(
        resetPasswordAsync({
          token,
          newPassword: password,
          formName: 'recovery',
        }),
      );
    }
  };
  return (
    <form data-id="recovery-form-thirdStep" onSubmit={handleSubmit(submitForm)}>
      <div className="c-modal__row">
        <div className="c-title c-title--center c-title--fs-32">ACCOUNT RECOVERY</div>
      </div>
      <div className="c-modal__row">
        <div className="c-description__text c-description--center c-description--fs-14">
          Please enter new password below:
        </div>
      </div>
      <div className="c-modal__row">
        <Field
          name="password"
          type="password"
          placeholder="New password"
          component={Password}
          validate={validatePassword}
        />
      </div>
      <div className="c-modal__row">
        <Field
          name="confirmPassword"
          type="password"
          placeholder="Verify password"
          component={Password}
          validate={confirmation({ field: 'password', message: 'Passwords should match' })}
        />
      </div>
      <div className="c-modal__row c-modal--justify-center">
        <button
          className="c-button"
          type="submit"
          disabled={invalid || submitting || pristine || !token}
        >
          <span className="c-button__inner">Submit</span>
        </button>
      </div>
    </form>
  );
};

const AccountRecoveryStepThree = reduxForm<RecoveryValues>({
  form: 'recovery',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateRecoveryForm,
})(Form);

export const AccountRecoveryStepThreeModal: FC = () => <AccountRecoveryStepThree />;
