import { useSelector } from 'react-redux';
import { includes } from 'lodash';

import { BillingPeriod, SubscriptionPlan } from 'src/models/billing';
import {
  getCurrentOrganizationOwnerId,
  getCurrentOrganizationId,
} from 'src/shared/organization/organizationSelectors';
import { getUserId } from 'src/shared/auth';
import { getSubscribedPlan, getSubscribedPeriod } from 'src/v2/features/billing/store';

export const useIsAccountOwner = () => {
  const ownerId = useSelector(getCurrentOrganizationOwnerId);
  const myUserId = useSelector(getUserId);

  if (ownerId && myUserId) {
    return myUserId === ownerId;
  }
  return false;
};

export const useIsInOrganization = () => {
  const isOrgAccount = !!useSelector(getCurrentOrganizationId);

  return isOrgAccount;
};

export const useIsChangeablePeriod = () => {
  const plan = useSelector(getSubscribedPlan);
  const period = useSelector(getSubscribedPeriod);

  return (
    includes([SubscriptionPlan.Basic, SubscriptionPlan.Business], plan) ||
    (period !== BillingPeriod.Annually && plan === SubscriptionPlan.RealEstate)
  );
};

export const useIsPlanEditable = () => {
  const isOwner = useIsAccountOwner();
  const isOrgAccount = useIsInOrganization();
  const isChangeablePeriod = useIsChangeablePeriod();

  return (isOwner || !isOrgAccount) && isChangeablePeriod;
};
