export const toolbarConfiguration = {
  items: [
    'heading',
    '|',
    'alignment:left',
    'alignment:right',
    'alignment:center',
    'alignment:justify',
    '|',
    'bold',
    'italic',
    'strikethrough',
    'underline',
    'subscript',
    'superscript',
    '|',
    'fontSize',
    'fontFamily',
    'fontColor',
    'fontBackgroundColor',
    'lineHeight',
    '|',
    'specialCharacters',
    'link',
    'Checkbox',
    '|',
    'outdent',
    'indent',
    '|',
    'bulletedList',
    'numberedList',
    'todoList',
    '|',
    'code',
    'codeBlock',
    '|',
    'insertTable',
    '|',
    'insertImage',
    'mediaEmbed',
    'blockQuote',
    '|',
    'undo',
    'redo',
    'EditableField',
  ],
  shouldNotGroupWhenFull: true,
};
