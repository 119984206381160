import { normalizeAvatar } from 'src/v2/features/profile';
import { DocumentRole } from 'src/models/document';
import { TemplateRole } from 'src/models/template';

// Temp
export const getUsers = (length = 1): { id: string; src: string }[] => {
  const users: any[] = [];
  let i = 1;

  while (length >= i) {
    users.push({ id: `${i}`, src: normalizeAvatar() });
    i += 1;
  }

  return users;
};

export const formatDate = (value: string): string =>
  new Date(value).toLocaleDateString('us-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

export const convertUserRole = (role: string): string => {
  switch (role) {
    case TemplateRole.Owner:
    case DocumentRole.Owner:
      return 'Owner';

    case DocumentRole.Viewer:
    case TemplateRole.Viewer:
      return 'Viewer';

    case DocumentRole.Commentator:
    case DocumentRole.QRViewer:
      return 'Commentator';

    case DocumentRole.Acknowledger:
      return 'Acknowledger';

    case DocumentRole.Editor:
      return 'Editor';

    case DocumentRole.Manager:
      return 'Manager';

    case DocumentRole.SharedViewer:
      return 'Shared Viewer';

    default:
      return '';
  }
};
