import { ObjectBase } from 'src/common/types';
import { SidebarParticipantEntity } from 'src/v2/entities/participants';
import { getObjects } from 'src/v2/features/objectsStorage/objectsStorageSlice';
import { ObjectsContainer } from 'src/v2/features/objectsStorage/types';

export const getParticipantsWithUserAndProfile = (
  participants: ObjectBase[],
  user: ObjectsContainer,
  profile: ObjectsContainer,
  avatar: ObjectsContainer,
): SidebarParticipantEntity[] =>
  getObjects(participants, { user, profile, avatar }) as SidebarParticipantEntity[];

export const getParticipantById = <R extends { id: string }>(participants: R[], id?: string) => {
  const index = participants.findIndex((p) => p.id === id);

  if (index !== -1) {
    return participants[index];
  }

  return undefined;
};
