// Core
import { SagaIterator } from 'redux-saga';
import { apiClient } from 'src/common/client';

// Instruments
import { Contacts, Credentials } from 'src/shared/auth/types';

interface ResendTokenPayload {
  email: string;
  code: string;
  url: string;
}

type ResetPasswordProps = {
  newPassword: string;
  token: string;
};

export interface LoginResponse {
  token: string;
}

export interface LoginResponse {
  created: string;
  id: string;
  isBusiness: boolean;
  registrationStep: number;
  ttl: number;
  userId: string;
}

export function login(credentials: Credentials): SagaIterator<LoginResponse> {
  return apiClient.post<LoginResponse>(`/authority/login`, credentials);
}

export function requestResetCode(contacts: Contacts, captureToken?: string): SagaIterator<{}> {
  return apiClient.post('/authority/newResetCode', contacts, {
    headers: { Capture: captureToken },
  });
}

export function sendResetToken(payload: ResendTokenPayload): SagaIterator {
  return apiClient.post(`/authority/newResetToken`, {
    email: payload.email,
    reset_code: payload.code,
    recovery_url: payload.url,
  });
}

export function resetPassword(credentials: ResetPasswordProps): SagaIterator<{}> {
  const { newPassword, token } = credentials;

  return apiClient.post('/authority/resetPassword', { new_password: newPassword, token });
}

export function changePassword(oldPassword: string, newPassword: string): SagaIterator<{}> {
  return apiClient.post('/authority/changePassword', {
    new_password: newPassword,
    old_password: oldPassword,
  });
}
