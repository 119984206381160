import React, { FC, useContext } from 'react';

import { useTranslation } from 'src/common/hooks/useTranslation';
import { translationKeys } from 'src/common/translations';
import { Link } from 'src/v2/components/Link';
import { StaticSettingsContext } from 'src/common/contexts/StaticSettingsContext';
import { normalizeAvatar } from 'src/v2/features/profile';
import { decodeContentToDomainUrl } from 'src/utils/url';

import { HeaderItem } from './HeaderItem';
import { NavProps } from '../../types';
import { StaticSettings } from '../../../../../common/types';

export const HeaderNav: FC<NavProps> = ({ onHamburgerClick }) => {
  const i18n = useTranslation();
  const { helpUrl, profileUrl, onLogoutClick, userAvatarUrl } =
    useContext<StaticSettings>(StaticSettingsContext);
  const avatar = decodeContentToDomainUrl(normalizeAvatar(userAvatarUrl));

  return (
    <HeaderItem>
      <div className="c-burger c-burger--header" onClick={onHamburgerClick}>
        <div className="c-burger__line" />
      </div>
      <Link to={profileUrl} data-type="settings">
        <div className="c-photo c-photo--w-32 c-photo--pointer min-960">
          <img src={avatar} alt="avatar" />
        </div>
      </Link>
      <nav className="c-nav c-nav--grow-0">
        <Link className="c-nav__link" to={helpUrl} target="_blank" dataType="help" external>
          {i18n(translationKeys.header.help)}
        </Link>
        <button className="c-nav__logout" onClick={onLogoutClick} data-type="logout-submit">
          {i18n(translationKeys.header.logOut)}
        </button>
      </nav>
    </HeaderItem>
  );
};
