import { createContext } from 'react';
import { noop } from 'lodash';

import { SidebarSettings } from 'src/common/types';

export const SidebarSettingsContext = createContext<SidebarSettings>({
  isCollapsedMobile: true,
  toggleIsCollapsedMobile: noop,
  toggleIsHidden: noop,
  isHidden: false,
});
