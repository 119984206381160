import { FC } from 'react';

import { CheckoutWizard } from '../CheckoutWizard';
import { CheckoutLayout } from '../CheckoutLayout';
import { retryFailedSubscription } from '../store';

export const RetryPaymentPage: FC = () => {
  return (
    <CheckoutLayout>
      <CheckoutWizard submitAction={retryFailedSubscription} />
    </CheckoutLayout>
  );
};
