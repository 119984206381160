import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FileSignatureContext } from '../contexts/FileSignatureContext';
import {
  getSignatureId,
  setSignatureFile as setSignatureFileAction,
  removeSignatureFile as removeSignatureFileAction,
} from '../store';

export const FileSignatureContextProvider: FC = ({ children }) => {
  const dispatch = useDispatch();

  const signatureId = useSelector(getSignatureId);

  const setSignatureFile = useCallback(
    (data) => dispatch(setSignatureFileAction(data)),
    [dispatch],
  );

  const removeSignatureFile = useCallback(() => dispatch(removeSignatureFileAction()), [dispatch]);

  return (
    <FileSignatureContext.Provider value={{ signatureId, setSignatureFile, removeSignatureFile }}>
      {children}
    </FileSignatureContext.Provider>
  );
};
