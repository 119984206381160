import React from 'react';
import { isUndefined } from 'lodash';

import { DocumentRole } from 'src/models/document';
import { IconOption, Intent } from 'src/v2/components/Card';
import { EntityCard } from 'src/v2/features/sharedEntity';
import { EntityCardProps } from 'src/v2/features/sharedEntity/types';
import { canDeleteDocument } from 'src/v2/features/document/permissions/documentPermissions/documentPermissions';
import { ContextMenuBook } from 'src/app/types';
import { useContextMenu } from 'src/v2/features/contextMenu';

interface Props {
  contextMenu?: ContextMenuBook;
}

export const DocumentCard: React.FC<
  Props & Omit<EntityCardProps<DocumentRole>, 'intent' | 'icon'>
> = (props) => {
  const contextMenuType = props.contextMenu || ContextMenuBook.Document;

  const contextMenuHandler = useContextMenu(contextMenuType, props.data);

  const { status, role } = props.data;
  const canDelete = isUndefined(status) ? false : canDeleteDocument(status);

  let icon: IconOption = IconOption.View;
  switch (role) {
    case DocumentRole.Owner:
      icon = IconOption.UserEdit;
      break;
    case DocumentRole.Editor:
      icon = IconOption.Pencil;
      break;
    case DocumentRole.Acknowledger:
      icon = IconOption.Comment;
      break;
  }

  return (
    <EntityCard
      {...props}
      intent={Intent.BlueDark}
      canDeleteEntity={canDelete}
      onContextMenu={contextMenuHandler}
      icon={icon}
    />
  );
};
