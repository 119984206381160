import { ParsedQuery } from 'query-string';
import { useNavigate } from 'react-router';
import { useCallback } from 'react';
import { isString } from 'lodash';

import { QueryStringConverter } from './types';

/** @deprecated */
export const useWithHistoryCallbackFactory = (
  linkCreator: QueryStringConverter | string,
): ((params?: ParsedQuery<string>) => () => void) => {
  const navigate = useNavigate();

  return useCallback(
    (params?: ParsedQuery<string>) => {
      const link = isString(linkCreator) ? linkCreator : linkCreator(params);
      return (): void => {
        navigate(link);
      };
    },
    [linkCreator, navigate],
  );
};

/** @deprecated */
export const useWithHistoryFactory = (
  linkCreator: QueryStringConverter | string,
  params?: ParsedQuery<string>,
): (() => void) => {
  const navigate = useNavigate();

  return useCallback(() => {
    const link = isString(linkCreator) ? linkCreator : linkCreator(params);
    navigate(link);
  }, [linkCreator, navigate, params]);
};
