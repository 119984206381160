import { useLocation, useParams } from 'react-router-dom';
import { parse } from 'qs';
import { get, includes, isNull } from 'lodash';

import {
  AccountType,
  RedirectEntityType,
  QRRedirectEntityType,
  QRRedirectEntityToRedirectEntity,
} from 'src/models/signUp';
import { BillingPeriod, SubscriptionPlan } from 'src/models/billing';

import { getAccountTypeBySubscriptionPlan, getDefaultBillingPeriod } from './utils';
import { book } from 'src/app/book';

export const useGetAccountType = (): AccountType => {
  const { plan = SubscriptionPlan.Basic, account } = useParams<{
    plan: SubscriptionPlan;
    account: AccountType;
  }>();
  return account || getAccountTypeBySubscriptionPlan(plan);
};

const isBillingPeriod = (period: unknown): period is BillingPeriod =>
  includes([BillingPeriod.Annually, BillingPeriod.Monthly, BillingPeriod.None], period);

export const useGetPlanAndPeriod = (): [
  SubscriptionPlan | undefined,
  BillingPeriod | undefined,
] => {
  const location = useLocation();
  const params = useParams<{
    plan: string | undefined;
    account: AccountType;
  }>();

  const query = parse(location.search, { ignoreQueryPrefix: true });
  const plan = params.plan as SubscriptionPlan;

  const period = isBillingPeriod(query.period) ? query.period : getDefaultBillingPeriod(plan);

  return [plan, period];
};

export const useGetRedirectToEntity = (): [RedirectEntityType | null, string | null] => {
  const { search } = useLocation();
  const params = parse(search, { ignoreQueryPrefix: true });
  const documentType = get(params, 'documentType', null);
  const documentId = get(params, 'documentId', null);

  return [documentType as RedirectEntityType, documentId as string];
};

export const useGetQRParameters = (): [RedirectEntityType | null, string | null] => {
  const { pathname } = useLocation();
  const returnParts = pathname.split('/');
  const isQRInvite = `/${returnParts[1]}` === book.qrInvite;
  if (!isQRInvite) {
    return [null, null];
  }
  const QREntityType = (returnParts[2] as QRRedirectEntityType) || null;
  const entityType = !isNull(QREntityType) ? QRRedirectEntityToRedirectEntity[QREntityType] : null;
  const entityId = returnParts[3] || null;

  return [entityType, entityId];
};
