import i18n from 'src/i18n';

import { translationKeys } from 'src/common/translations';
import { AccountRecoveryStepTwoForm } from './AccountRecoveryStepTwoForm';

type Props = {
  onLoginFlow: () => void;
  onSignUpFlow: () => void;
};

export const AccountRecoveryStepTwo: React.FC<Props> = (props) => (
  <>
    <div className="c-modal__row c-modal--mt-24">
      <div className="c-title c-title__auth c-title--color-theme c-title--fs-64">
        {i18n(translationKeys.forms.forgotPasswordQR.secondStep.title)}
      </div>
    </div>
    <div className="c-modal__row">
      <div className="c-title c-title__auth c-title--color-theme c-title--fw-400">
        {i18n(translationKeys.forms.forgotPasswordQR.secondStep.description)}
      </div>
    </div>
    <AccountRecoveryStepTwoForm {...props} />
  </>
);
