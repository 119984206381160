import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FilterMode, LayoutState, RootState } from 'src/app/types';

const initialState: LayoutState = {
  filterMode: FilterMode.All,
  isLoading: false,
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setFilterMode(state, action: PayloadAction<FilterMode>): void {
      state.filterMode = action.payload;
    },
  },
});

export const getFilterMode = (state: RootState): FilterMode => state.layout.filterMode;
export const { setFilterMode } = layoutSlice.actions;
export default layoutSlice.reducer;
