/* eslint-disable no-param-reassign */
// Core
import { get } from 'lodash';
import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { call, takeLatest, put } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { startSubmit as startFormSubmit, stopSubmit as stopFormSubmit } from 'redux-form';

// Instruments
import { requestResetCode, resetPassword, sendResetToken } from 'src/api/authApi';
import { requestOptCode, resetPasswordAsync, submitOtpCode } from './qrCodeAccountRecoveryStore';
import {
  RequestOptCodeActionPayload,
  ResetPasswordActionPayload,
  SubmitOtpCodeActionPayload,
} from 'src/v2/boundary/actionPayload/accountRecovery';
import { setQrCodeAccountRecoveryStep, setStep } from 'src/v2/features/signUp/store';
import { QrCodeAccountRecoveryStep, WizardStepV2 } from 'src/models/signUp';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

export function* requestOptCodeSaga(
  action: PayloadAction<RequestOptCodeActionPayload>,
): SagaIterator {
  const { email, phone, formName, captureToken } = action.payload;

  try {
    yield put(startFormSubmit(formName));
    yield call(requestResetCode, { email, phone }, captureToken);
    yield put(
      setQrCodeAccountRecoveryStep({
        qrCodeAccountRecoveryStep: QrCodeAccountRecoveryStep.PhoneVerification,
      }),
    );
  } catch (err) {
    toastr.error('Error', 'Send SMS with reset code has failed.');
  } finally {
    yield put(stopFormSubmit(formName));
  }
}

export function* submitOtpSaga(action: PayloadAction<SubmitOtpCodeActionPayload>): SagaIterator {
  try {
    const { code, formName, email, url } = action.payload;
    yield put(startFormSubmit(formName));
    yield call(sendResetToken, { email, code, url });
    yield put(
      setQrCodeAccountRecoveryStep({
        qrCodeAccountRecoveryStep: QrCodeAccountRecoveryStep.Complete,
      }),
    );
  } catch (err) {
    toastr.error(
      'Error',
      'Verify phone request has failed. Please make sure that you have verified your email.',
    );
  } finally {
    const { formName } = action.payload;

    yield put(stopFormSubmit(formName));
  }
}

export function* resetPasswordSaga(
  action: PayloadAction<ResetPasswordActionPayload>,
): SagaIterator {
  try {
    const { formName, newPassword, token } = action.payload;

    yield put(startFormSubmit(formName));

    yield call(resetPassword, { newPassword, token });

    toastr.success('Success', 'You have successfully updated your password!\nYou can now log in.');
    yield put(
      setStep({
        step: WizardStepV2.StartInvite,
        stepNum: 1,
        maxSteps: 1,
      }),
    );
    yield put(
      setQrCodeAccountRecoveryStep({
        qrCodeAccountRecoveryStep: QrCodeAccountRecoveryStep.Start,
      }),
    );
  } catch (err) {
    const errorMessage =
      get(err, 'response.status') === 500
        ? i18n(translationKeys.errors.RESET_TOKEN_EXPIRED)
        : i18n(translationKeys.errors.RESET_PASSWORD_FAILED);
    toastr.error('Error', errorMessage);
  } finally {
    const { formName } = action.payload;

    yield put(stopFormSubmit(formName));
  }
}

export function* watchQrCodeAccountRecoverySagas(): SagaIterator {
  yield takeLatest(requestOptCode, requestOptCodeSaga);
  yield takeLatest(submitOtpCode, submitOtpSaga);
  yield takeLatest(resetPasswordAsync, resetPasswordSaga);
}
