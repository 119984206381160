import { FC, MouseEvent, PropsWithChildren } from 'react';
import cn from 'classnames';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { useToggle } from 'src/common/hooks/useToggle';

import { MetadataViewRow } from './MetadataViewRow';

interface Props {
  labelType: string;
  labelSubType: string;
  title?: string;
  description?: string;
  creationDate?: string;
  updatedData?: string;
  subType?: string;
  subSubType?: string;
  isAllowedToEdit: boolean;
  onEdit: (e: MouseEvent) => void;
  dataId?: string;
}

export const MetadataView: FC<PropsWithChildren<Props>> = ({
  labelType,
  labelSubType,
  title,
  description = '',
  creationDate,
  updatedData,
  subType,
  subSubType,
  isAllowedToEdit,
  onEdit,
  children,
  dataId,
}) => {
  const [showMore, toggle] = useToggle();

  return (
    <div className="c-employmenеt" data-id={dataId}>
      <div className="c-employmenеt__row c-employmenеt__header">
        <div className="c-title c-title--uppercase">{title}</div>
        {isAllowedToEdit && (
          <div data-type="edit-box" className="c-title c-title__edit" onClick={onEdit}>
            {i18n(translationKeys.buttons.edit)}
          </div>
        )}
      </div>

      <div className="c-employmenеt__row">
        <div
          className={cn(
            'c-employmenеt__description',
            showMore && 'c-employmenеt__description--active',
          )}
        >
          <span className="c-employmenеt__title">
            {`${i18n(translationKeys.sorting.description)}: `}
          </span>
          <span data-type="description-field" className="c-employmenеt__text">
            {description}
          </span>
        </div>
        {description.length >= 24 && (
          <div className="c-employmenеt__row c-employmenеt--center">
            <div className="c-more" onClick={toggle}>
              {showMore
                ? i18n(translationKeys.forms.item.showLess)
                : i18n(translationKeys.forms.item.showMore)}
            </div>
          </div>
        )}
      </div>

      <MetadataViewRow
        dataType="dateCreated-field"
        label={i18n(translationKeys.sorting.dateCreated)}
      >
        {creationDate}
      </MetadataViewRow>
      <MetadataViewRow
        dataType="dateUpdated-field"
        label={i18n(translationKeys.forms.item.lastUpdated)}
      >
        {updatedData}
      </MetadataViewRow>
      <MetadataViewRow dataType="documentType-field" label={labelType}>
        {subType}
      </MetadataViewRow>
      <MetadataViewRow dataType="documentSubtype-field" label={labelSubType}>
        {subSubType}
      </MetadataViewRow>

      {children}
    </div>
  );
};
