import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isUndefined } from 'lodash';

import { subscribeLogout } from 'src/common/systemEventBus';

export const ClearStateService: FC = ({ children }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const subscription$ = subscribeLogout(() => {
      navigate('/logout');
      if (!isUndefined(window) && !isUndefined(window.location)) {
        window.location.reload();
      }
    });

    return (): void => subscription$.unsubscribe();
  }, [navigate]);

  return <>{children}</>;
};
