import { filter, find, isNil, isNull } from 'lodash';
import { useSelector } from 'react-redux';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { BillingPeriod, SubscriptionPlan, PlanAndPeriod } from 'src/models/billing';
import {
  hasInvalidExistingSubscription,
  getSubscriptionStatus,
  normalizePrice,
} from 'src/v2/features/billing';

import { defaultBillingPeriod, getOptions, orgDefaultPlan, personalDefaultPlan } from './config';
import { canMigrateTo } from './planMigration/allowedMigration';

export function getPlanAndPeriodOptions(
  plan: SubscriptionPlan | null,
  period: BillingPeriod | null,
) {
  if (isNull(plan) || isNull(period)) return [];

  return (
    filter(getOptions(), (option) => canMigrateTo(plan, period, option.plan, option.period)) || []
  );
}

export function getPlanAndPeriod(value: PlanAndPeriod) {
  const options = getOptions();
  return find(options, { value }) || options[0];
}

export function getDefaultPlanAndPeriod(
  isOrgAccount: boolean,
  preselectedPlan: SubscriptionPlan | null,
  preselectedPeriod: BillingPeriod | null,
) {
  const options = getOptions();
  const plan = preselectedPlan || (isOrgAccount ? orgDefaultPlan : personalDefaultPlan);
  const period = preselectedPeriod || defaultBillingPeriod;
  const optionByPlanAndPeriod = find(options, { plan, period, forOrgs: isOrgAccount });
  const fallbackOption = options[0];

  return (optionByPlanAndPeriod || fallbackOption).value;
}

export function getDefaultPlanAndPeriodWithBasic(
  isOrgAccount: boolean,
  preselectedPlan: SubscriptionPlan | null,
  preselectedPeriod: BillingPeriod | null,
) {
  const options = getOptions();
  const plan = preselectedPlan || (isOrgAccount ? orgDefaultPlan : personalDefaultPlan);
  const period = preselectedPeriod || defaultBillingPeriod;
  const optionByPlanAndPeriod = find(options, { plan, period, forOrgs: isOrgAccount });
  const fallbackOption = options[0];

  return (optionByPlanAndPeriod || fallbackOption).value;
}

export const formatPrice = (price: number) => `US $${normalizePrice(price)}`;

export const getPeriodLabelByPeriod = (period: BillingPeriod | null) => {
  if (!period) return i18n(translationKeys.checkout.monthly.title);

  return period === BillingPeriod.Monthly
    ? i18n(translationKeys.checkout.monthly.title)
    : i18n(translationKeys.checkout.annual.title);
};

export const getSecondPeriodLabelByPeriod = (period: BillingPeriod | null) => {
  if (!period) return i18n(translationKeys.checkout.monthly.period);

  return period === BillingPeriod.Monthly
    ? i18n(translationKeys.checkout.monthly.period)
    : i18n(translationKeys.checkout.annual.period);
};

export const useShouldRetrySubscription = () => {
  const currentSubscriptionState = useSelector(getSubscriptionStatus);

  return hasInvalidExistingSubscription(currentSubscriptionState);
};

export const isNotEmptyString = (checkedString: string): boolean => {
  return !isNil(checkedString) && checkedString.length > 0;
};

export const normalizeValueOfNumberSeats = (value, prevValue) => {
  if (value) {
    return /^(?=.*[1-9])\d{1,5}$/.test(value) ? value : prevValue;
  }
  return value;
};
