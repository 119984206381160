import React, { FC } from 'react';

import { SignatureBlank } from 'src/v2/icons/SignatureBlank';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

export const Placeholder: FC = () => (
  <span className="c-signature__placeholder">
    <SignatureBlank />
    <span className="c-signature__text">{i18n(translationKeys.forms.signature.dragToUpload)}</span>
    <span className="c-signature__text">
      {i18n(translationKeys.forms.upload.supportedFileTypes)}
      <br />
      (.jpg .png .gif)
    </span>
  </span>
);
