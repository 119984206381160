export const translationKeys = {
  heading: {
    attachment: 'heading.attachment',
  },
  screens: {
    welcomeScreen: {
      registrationTitleBig: 'screens.welcomeScreen.registrationTitleBig',
      registrationTitleSmall: 'screens.welcomeScreen.registrationTitleSmall',
      getStarted: 'screens.welcomeScreen.getStarted',
    },
    notificationScreen: {
      notification: 'screens.notificationScreen.notification',
      pushNotifications: 'screens.notificationScreen.pushNotifications',
      sendEmailMessage: 'screens.notificationScreen.sendEmailMessage',
      sendSmsMessage: 'screens.notificationScreen.sendSmsMessage',
    },
  },
  navigationMenu: {
    documents: 'navigationMenu.documents',
    contracts: 'navigationMenu.contracts',
    templates: 'navigationMenu.templates',
    settings: 'navigationMenu.settings',
    projects: 'navigationMenu.projects',
    shared: 'navigationMenu.shared',
    personalInformation: 'navigationMenu.personalInformation',
    organizationInformation: 'navigationMenu.organizationInformation',
    security: 'navigationMenu.security',
    manageOrganizationUsers: 'navigationMenu.manageOrganizationUsers',
    notifications: 'navigationMenu.notifications',
  },
  headerMenu: {
    newDocument: 'headerMenu.newDocument',
    newContract: 'headerMenu.newContract',
    newTemplate: 'headerMenu.newTemplate',
    newProject: 'headerMenu.newProject',
  },
  contextMenu: {
    open: 'contextMenu.open',
    newFolder: 'contextMenu.newFolder',
    moveFolder: 'contextMenu.moveFolder',
    rename: 'contextMenu.rename',
    delete: 'contextMenu.delete',
  },
  nineSquareMenu: {
    addUser: 'nineSquareMenu.addUser',
    editUser: 'nineSquareMenu.editUser',
    saveTemplate: 'nineSquareMenu.saveTemplate',
    download: 'nineSquareMenu.download',
    qrCode: 'nineSquareMenu.qrCode',
    share: 'nineSquareMenu.share',
  },
  header: {
    settings: 'header.settings',
    help: 'header.help',
    logOut: 'header.logOut',
    search: 'header.search',
  },
  sorting: {
    sortBy: 'sorting.sortBy',
    title: 'sorting.title',
    description: 'sorting.description',
    dateCreated: 'sorting.dateCreated',
    dateUpdated: 'sorting.dateUpdated',
    state: 'sorting.state',
    subType: 'sorting.subType',
    owner: 'sorting.owner',
    roleOfCreator: 'sorting.roleOfCreator',
  },
  systemRoles: {
    user: 'systemRoles.user',
    manager: 'systemRoles.manager',
    owner: 'systemRoles.owner',
    admin: 'systemRoles.admin',
  },
  messages: {
    success: 'messages.success',
    info: 'messages.info',
    billingDetailsUpdated: 'messages.billingDetailsUpdated',
    billingPlanUpdated: 'messages.billingPlanUpdated',
    loading: 'messages.loading',
    unsavedChanges: 'messages.unsavedChanges',
    loadingMessages: 'messages.loadingMessages',
    loadingPreviousMessages: 'messages.loadingPreviousMessages',
    subscribed: 'messages.subscribed',
    documentCreated: 'messages.documentCreated',
    contractCreated: 'messages.contractCreated',
    templateCreated: 'messages.templateCreated',
    documentDeleted: 'messages.documentDeleted',
    contractDeleted: 'messages.contractDeleted',
    templateDeleted: 'messages.templateDeleted',
    entityTypeUpdated: 'messages.entityTypeUpdated',
    templateUpdated: 'messages.templateUpdated',
    fileIsDownloading: 'messages.fileIsDownloading',
    fileDownloaded: 'messages.fileDownloaded',
  },
  errors: {
    MESSAGE_TITLE: 'errors.MESSAGE_TITLE',
    UNKNOWN_CODE: 'errors.UNKNOWN_CODE',
    UNPROCESSABLE_PAYLOAD: 'errors.UNPROCESSABLE_PAYLOAD',
    RETRIEVING_THE_FILE: 'errors.RETRIEVING_THE_FILE',
    READING_THE_FILE: 'errors.READING_THE_FILE',
    FILE_UNSUPPORTED_TYPE: 'errors.FILE_UNSUPPORTED_TYPE',
    WRONG_IMAGE_SIZE: 'errors.WRONG_IMAGE_SIZE',
    UPLOADING_FILE: 'errors.UPLOADING_FILE',
    CANT_FIND_ELEMENT: 'errors.CANT_FIND_ELEMENT',
    INVALID_EMAIL_OR_PASSWORD: 'errors.INVALID_EMAIL_OR_PASSWORD',
    INVALID_EMAIL: 'errors.INVALID_EMAIL',
    INVALID_PASSWORD: 'errors.INVALID_PASSWORD',
    INACTIVE_USER: 'errors.INACTIVE_USER',
    INACTIVE_TOKEN: 'errors.INACTIVE_TOKEN',
    REGISTRATION_STEP_NOT_FOUND_IN_PAYLOAD: 'errors.REGISTRATION_STEP_NOT_FOUND_IN_PAYLOAD',
    REGISTRATION_STEP_WRONG_OR_EMPTY: 'errors.REGISTRATION_STEP_WRONG_OR_EMPTY',
    GETTING_TOKEN_FROM_URL: 'errors.GETTING_TOKEN_FROM_URL',
    WRONG_DATE_FORMAT: 'errors.WRONG_DATE_FORMAT',
    WRONG_PHONE_FORMAT: 'errors.WRONG_PHONE_FORMAT',
    SUBSCRIBER_EXIST: 'errors.SUBSCRIBER_EXIST',
    CALLER_IS_NOT_A_SERVICE: 'errors.CALLER_IS_NOT_A_SERVICE',
    USER_ALREADY_ACKNOWLEDGED: 'errors.USER_ALREADY_ACKNOWLEDGED',
    USER_ALREADY_SIGNED: 'errors.USER_ALREADY_SIGNED',
    CANT_GET_EXECUTOR: 'errors.CANT_GET_EXECUTOR',
    CANT_CREATE_WORKFLOW: 'errors.CANT_CREATE_WORKFLOW',
    DATABASE_NOT_AVAILABLE: 'errors.DATABASE_NOT_AVAILABLE',
    NO_RECEIVERS: 'errors.NO_RECEIVERS',
    DIFFERENT_USR_FOR_TOKEN_AND_REQUEST: 'errors.DIFFERENT_USR_FOR_TOKEN_AND_REQUEST',
    INVALID_CODE_FOR_THIS_COUNTRY: 'errors.INVALID_CODE_FOR_THIS_COUNTRY',
    FORM_FILED_REQUIRED: 'errors.FORM_FILED_REQUIRED',
    REQUIRED: 'errors.REQUIRED',
    PASSWORD_NOT_MATCH: 'errors.PASSWORD_NOT_MATCH',
    PASSWORD_MIN_LENGTH: 'errors.PASSWORD_MIN_LENGTH',
    COPIED: 'errors.COPIED',
    INVALID_EMAIL_ADDRESS: 'errors.INVALID_EMAIL_ADDRESS',
    RESET_TOKEN_EXPIRED: 'errors.RESET_TOKEN_EXPIRED',
    RESET_PASSWORD_FAILED: 'errors.RESET_PASSWORD_FAILED',
    UNKNOWN_USER_STATE: 'errors.UNKNOWN_USER_STATE',
    SOMETHING_WENT_WRONG: 'errors.SOMETHING_WENT_WRONG',
    UNKNOWN_STRIPE_ERROR: 'errors.UNKNOWN_STRIPE_ERROR',
    OTP_VALIDATION_FAILED: 'errors.OTP_VALIDATION_FAILED',
    UNEXPECTED_STATUS: 'errors.UNEXPECTED_STATUS',
    WRONG_EXTENSION: 'errors.WRONG_EXTENSION',
    UNKNOWN: 'errors.UNKNOWN',
    UPLOAD_LIMIT: 'errors.UPLOAD_LIMIT',
    FILE_DAMAGED: 'errors.FILE_DAMAGED',
    NETWORK: 'errors.NETWORK',
    COPY_TO_ROOT_PROJECT: 'errors.COPY_TO_ROOT_PROJECT',
    UPLOAD: 'errors.UPLOAD',
    FILE_FORMAT_ERROR: 'errors.FILE_FORMAT_ERROR',
  },
  sizes: {
    sizeMessage: 'sizes.sizeMessage',
    KB: 'sizes.KB',
    MB: 'sizes.MB',
    GB: 'sizes.GB',
    TB: 'sizes.TB',
  },
  organization: {
    informationTitle: 'organization.informationTitle',
    informationText: 'organization.informationText',
    customizedBrandingTitle: 'organization.customizedBrandingTitle',
    customizedBrandingText: 'organization.customizedBrandingText',
    addUsers: 'organization.addUsers',
    seatsInfo: 'organization.seatsInfo',
    numberOfRemainingSeats: 'organization.numberOfRemainingSeats',
    numberOfTotalSeats: 'organization.numberOfTotalSeats',
    tableHeaders: {
      name: 'organization.tableHeaders.name',
      position: 'organization.tableHeaders.position',
      role: 'organization.tableHeaders.role',
      email: 'organization.tableHeaders.email',
      phoneNumber: 'organization.tableHeaders.phoneNumber',
      status: 'organization.tableHeaders.status',
    },
  },
  profile: {
    verifiedProfile: 'profile.verifiedProfile',
    profileTitle: 'profile.profileTitle',
    informationTitle: 'profile.informationTitle',
    informationTextPersonal: 'profile.informationTextPersonal',
    informationTextBusiness: 'profile.informationTextBusiness',
    about: 'profile.about',
    selectYear: 'profile.selectYear',
    selectMonth: 'profile.selectMonth',
    selectDay: 'profile.selectDay',
  },
  profileMenu: {
    personalInformation: 'profileMenu.personalInformation',
    organizationInformation: 'profileMenu.organizationInformation',
    security: 'profileMenu.security',
    manageSignature: 'profileMenu.manageSignature',
    manageOrganizationUsers: 'profileMenu.manageOrganizationUsers',
    notifications: 'profileMenu.notifications',
    planBillingDetails: 'profileMenu.planBillingDetails',
  },
  signUpProfile: {
    title: 'signUpProfile.title',
  },
  document: {
    title: 'document.title',
    noTitle: 'document.noTitle',
    ability: 'contract.ability',
    createNew: 'document.createNew',
  },
  contract: {
    title: 'contract.title',
    noTitle: 'contract.noTitle',
    sign: 'contract.sign',
    ability: 'contract.ability',
    ownerSide: 'contract.ownerSide',
    counterpartySide: 'contract.counterpartySide',
  },
  template: {
    noTitle: 'template.noTitle',
    ability: 'template.ability',
  },
  project: {
    ability: 'project.ability',
    createNewProject: 'project.createNewProject',
  },
  buttons: {
    update: 'buttons.update',
    addLogo: 'buttons.addLogo',
    addPhoto: 'buttons.addPhoto',
    uploadPhoto: 'buttons.uploadPhoto',
    updateTemplate: 'buttons.updateTemplate',
    publishTemplate: 'buttons.publishTemplate',
    unpublishTemplate: 'buttons.unpublishTemplate',
    addUser: 'buttons.addUser',
    edit: 'buttons.edit',
    remove: 'buttons.remove',
    termsAndCondition: 'buttons.termsAndCondition',
    agree: 'buttons.agree',
    signUp: 'buttons.signUp',
    signIn: 'buttons.signIn',
    next: 'buttons.next',
    resendSMS: 'buttons.resendSMS',
    resendCode: 'buttons.resendCode',
    verify: 'buttons.verify',
    confirmPhone: 'buttons.confirmPhone',
    finish: 'buttons.finish',
    share: 'buttons.share',
    download: 'buttons.download',
    cancel: 'buttons.cancel',
    retry: 'buttons.retry',
    yes: 'buttons.yes',
    no: 'buttons.no',
    ok: 'buttons.ok',
    submit: 'buttons.submit',
    pay: 'buttons.pay',
    send: 'buttons.send',
    sendTheCode: 'buttons.sendTheCode',
    goBack: 'buttons.goBack',
    accept: 'buttons.accept',
    sendInvite: 'buttons.sendInvite',
    gotIt: 'buttons.gotIt',
    save: 'buttons.save',
    saved: 'buttons.saved',
    field: 'buttons.field',
    addTable: 'buttons.addTable',
    createNewSection: 'buttons.createNewSection',
    removeSection: 'buttons.removeSection',
  },
  months: {
    jan: 'months.jan',
    feb: 'months.feb',
    mar: 'months.mar',
    apr: 'months.apr',
    may: 'months.may',
    jun: 'months.jun',
    jul: 'months.jul',
    aug: 'months.aug',
    sep: 'months.sep',
    oct: 'months.oct',
    nov: 'months.nov',
    dec: 'months.dec',
  },
  forms: {
    loginLight: {
      title: 'forms.loginLight.title',
      fields: {
        email: 'forms.loginLight.fields.email',
        password: 'forms.loginLight.fields.password',
      },
      noAccount: 'forms.loginLight.noAccount',
      haveAccount: 'forms.loginLight.haveAccount',
      forgotPassword: 'forms.loginLight.forgotPassword',
      continueReading: 'forms.loginLight.continueReading',
      noAccountSignUp: 'forms.loginLight.noAccountSignUp',
      welcome: 'forms.loginLight.welcome',
      signUp: 'forms.loginLight.signUp',
      signIn: 'forms.loginLight.signIn',
    },
    logIn: {
      title: 'forms.logIn.title',
      subTitle: 'forms.logIn.subTitle',
      forgotLink: 'forms.logIn.forgotLink',
      signUpText: 'forms.logIn.signUpText',
      signUpLink: 'forms.logIn.signUpLink',
      fields: {
        email: 'forms.logIn.fields.email',
        password: 'forms.logIn.fields.password',
      },
      logIn: 'forms.logIn.logIn',
    },
    createPassword: {
      title: 'forms.createPassword.title',
      subTitle: 'forms.createPassword.subTitle',
      password: 'forms.createPassword.password',
      verifyPassword: 'forms.createPassword.verifyPassword',
      email: 'forms.createPassword.email',
      companyName: 'forms.createPassword.companyName',
    },
    changePassword: {
      title: 'forms.changePassword.title',
      password: 'forms.changePassword.password',
      newPassword: 'forms.changePassword.newPassword',
      confirmNewPassword: 'forms.changePassword.confirmNewPassword',
    },
    phoneVerification: {
      title: 'forms.phoneVerification.title',
      description: 'forms.phoneVerification.description',
      phoneNumberTitle: 'forms.phoneVerification.phoneNumberTitle',
      verificationCode: 'forms.phoneVerification.verificationCode',
      confirmationCode: 'forms.phoneVerification.confirmationCode',
      notrecievedCode: 'forms.phoneVerification.notrecievedCode',
      acceptedVerificationCode: 'forms.phoneVerification.acceptedVerificationCode',
      codeInput: 'forms.phoneVerification.codeInput',
      successMessage: 'forms.phoneVerification.successMessage',
    },
    phoneVerificationQR: {
      title: 'forms.phoneVerificationQR.title',
      phoneNumberTitle: 'forms.phoneVerificationQR.phoneNumberTitle',
      notrecievedCode: 'forms.phoneVerificationQR.notrecievedCode',
      resend: 'forms.phoneVerificationQR.resend',
    },
    forgotPasswordQR: {
      firstStep: {
        title: 'forms.forgotPasswordQR.firstStep.title',
        description: 'forms.forgotPasswordQR.firstStep.description',
      },
      secondStep: {
        title: 'forms.forgotPasswordQR.secondStep.title',
        description: 'forms.forgotPasswordQR.secondStep.description',
      },
      thirdStep: {
        title: 'forms.forgotPasswordQR.thirdStep.title',
        description: 'forms.forgotPasswordQR.thirdStep.description',
      },
      fourthStep: {
        title: 'forms.forgotPasswordQR.fourthStep.title',
        description: 'forms.forgotPasswordQR.fourthStep.description',
      },
      noAccount: 'forms.forgotPasswordQR.noAccount',
    },
    info: {
      organizationName: 'forms.info.organizationName',
      organizationContactPerson: 'forms.info.organizationContactPerson',
      firstName: 'forms.info.firstName',
      lastName: 'forms.info.lastName',
      mobile: 'forms.info.mobile',
      birthDate: 'forms.info.birthDate',
      email: 'forms.info.email',
      address: 'forms.info.address',
      city: 'forms.info.city',
      stateSelect: 'forms.info.stateSelect',
      zipCode: 'forms.info.zipCode',
      countrySelect: 'forms.info.countrySelect',
      language: 'forms.info.language',
      companyName: 'forms.info.companyName',
      job: 'forms.info.job',
      industry: 'forms.info.industry',
      employees: 'forms.info.employees',
    },
    signup: {
      fields: {
        firstName: 'forms.signup.fields.firstName',
        lastName: 'forms.signup.fields.lastName',
        email: 'forms.signup.fields.email',
      },
      tips: {
        alreadyHaveAnAccount: 'forms.signup.tips.alreadyHaveAnAccount',
      },
      defaultFeatures: ['forms.signup.defaultFeatures.0', 'forms.signup.defaultFeatures.1'],
      cases: {
        qr: {
          title: 'forms.signup.cases.qr.title',
        },
        personalBasic: {
          title: 'forms.signup.cases.personalBasic.title',
        },
        personalStandard: {
          title: 'forms.signup.cases.personalStandard.title',
        },
        business: {
          title: 'forms.signup.cases.business.title',
        },
        realestate: {
          title: 'forms.signup.cases.realestate.title',
        },
      },
    },
    signUpBilling: {
      title: 'forms.signUpBilling.title',
      paymentInformation: 'forms.signUpBilling.paymentInformation',
      personalInformation: 'forms.signUpBilling.personalInformation',
      fields: {
        firstName: 'forms.signUpBilling.fields.firstName',
        lastName: 'forms.signUpBilling.fields.lastName',
        email: 'forms.signUpBilling.fields.email',
        street: 'forms.signUpBilling.fields.street',
        city: 'forms.signUpBilling.fields.city',
        country: 'forms.signUpBilling.fields.country',
        state: 'forms.signUpBilling.fields.state',
        postalCode: 'forms.signUpBilling.fields.postalCode',
        numberOfUsers: 'forms.signUpBilling.fields.numberOfUsers',
        numberOfSeats: 'forms.signUpBilling.fields.numberOfSeats',
        address: 'forms.signUpBilling.fields.address',
        requiredFields: 'forms.signUpBilling.fields.requiredFields',
      },
    },
    signUpCheckout: {
      title: 'forms.signUpCheckout.title',
    },
    signUpOrderSummary: {
      fields: {
        subtotal: 'forms.signUpOrderSummary.fields.subtotal',
        tax: 'forms.signUpOrderSummary.fields.tax',
        total: 'forms.signUpOrderSummary.fields.total',
      },
    },
    upload: {
      avatarFormats: 'forms.upload.avatarFormats',
      avatarSize: 'forms.upload.avatarSize',
      supportedFileTypes: 'forms.upload.supportedFileTypes',
      dragImageToUpload: 'forms.upload.dragImageToUpload',
      dragDocumentToUpload: 'forms.upload.dragDocumentToUpload',
      dragTemplate: 'forms.upload.dragTemplate',
      scannedTextNote: 'forms.upload.scannedTextNote',
      file: 'forms.upload.file',
    },
    billing: {
      title: 'forms.billing.title',
      you: 'forms.billing.you',
      accountOwner: 'forms.billing.accountOwner',
      annualPlanMessage: 'forms.billing.annualPlanMessage',
      oneReportBasicFree: 'forms.billing.oneReportBasicFree',
      userPlan: 'forms.billing.userPlan',
      paidUntil: 'forms.billing.paidUntil',
      subscribedPlanAfter: 'forms.billing.subscribedPlanAfter',
      notPaidPeriod: 'forms.billing.notPaidPeriod',
      singleBillingTitle: 'forms.billing.singleBillingTitle',
      paidPeriodExpired: 'forms.billing.paidPeriodExpired',
      freeTrialEnd: 'forms.billing.freeTrialEnd',
      freeTrialEndPremium: 'forms.billing.freeTrialEndPremium',
      addBillingCard: 'forms.billing.addBillingCard',
      changeBillingCard: 'forms.billing.changeBillingCard',
      featurePayments: 'forms.billing.featurePayments',
      cardEnding: 'forms.billing.cardEnding',
    },
    signature: {
      preview: 'forms.signature.preview',
      blockchainId: 'forms.signature.blockchainId',
      by: 'forms.signature.by',
      name: 'forms.signature.name',
      title: 'forms.signature.title',
      company: 'forms.signature.company',
      upload: 'forms.signature.upload',
      yourSignature: 'forms.signature.yourSignature',
      companyInclude: 'forms.signature.companyInclude',
      create: 'forms.signature.create',
      update: 'forms.signature.update',
      dragToUpload: 'forms.signature.dragToUpload',
      typeName: 'forms.signature.typeName',
      chooseStyle: 'forms.signature.chooseStyle',
      uploadExisting: 'forms.signature.uploadExisting',
      signatureDetails: 'forms.signature.signatureDetails',
      createNewSignature: 'forms.signature.createNewSignature',
      useExistingSignature: 'forms.signature.useExistingSignature',
      selectDefault: 'forms.signature.selectDefault',

      signatureLink: 'forms.signature.signatureLink',
      stampLink: 'forms.signature.stampLink',
      includeLocalized: 'forms.signature.includeLocalized',
      includeHanko: 'forms.signature.includeHanko',
      signed: 'forms.signature.signed',
      signatureCreated: 'forms.signature.signatureCreated',
      signatureUpdated: 'forms.signature.signatureUpdated',
      defaultSignatureUpdated: 'forms.signature.defaultSignatureUpdated',
      signature: 'forms.signature.signature',
    },
    permissions: {
      title: 'forms.permissions.title',
      email: 'forms.permissions.email',
      firstName: 'forms.permissions.firstName',
      lastName: 'forms.permissions.lastName',
      phone: 'forms.permissions.phone',
      role: 'forms.permissions.role',
      chooseRole: 'forms.permissions.chooseRole',
    },
    item: {
      title: 'forms.item.title',
      description: 'forms.item.description',
      dateCreated: 'forms.item.dateCreated',
      lastUpdated: 'forms.item.lastUpdated',
      type: 'forms.item.type',
      subType: 'forms.item.subType',
      selectTemplateType: 'forms.item.selectTemplateType',
      selectIndustry: 'forms.item.selectIndustry',
      industry: 'forms.item.industry',
      country: 'forms.item.country',
      stateProvince: 'forms.item.stateProvince',
      city: 'forms.item.city',
      publish: 'forms.item.publish',
      private: 'forms.item.private',
      specificUsers: 'forms.item.specificUsers',
      invite: 'forms.item.invite',
      emailsList: 'forms.item.emailsList',
      shareWithUsers: 'forms.item.shareWithUsers',
      users: 'forms.item.users',
      showMore: 'forms.item.showMore',
      showLess: 'forms.item.showLess',
      info: 'forms.item.info',
      createIndustry: 'forms.item.createIndustry',
      signParty: 'forms.item.signParty',
      readyToSign: 'forms.item.readyToSign',
      signLegalContract: 'forms.item.signLegalContract',
      wishToSign: 'forms.item.wishToSign',
      roleOfCreator: 'forms.item.roleOfCreator',
    },
    chat: {
      title: 'forms.chat.title',
      message: 'forms.chat.message',
      offline: 'forms.chat.offline',
      description: 'forms.chat.description',
      addPersonalMessage: 'forms.chat.addPersonalMessage',
    },
    inviteUser: {
      title: 'forms.inviteUser.title',
      email: 'forms.inviteUser.email',
      funcRole: 'forms.inviteUser.funcRole',
    },
    editUser: {
      title: 'forms.editUser.title',
      email: 'forms.editUser.email',
      funcRole: 'forms.editUser.funcRole',
    },
    userFields: {
      firstName: 'forms.userFields.firstName',
      lastName: 'forms.userFields.lastName',
      mobile: 'forms.userFields.mobile',
    },
    userPermissions: {
      title: 'forms.userPermissions.title',
      chooseRole: 'forms.userPermissions.chooseRole',
    },
    editor: {
      imageButton: {
        between: 'forms.editor.imageButton.between',
        inline: 'forms.editor.imageButton.inline',
        width: 'forms.editor.imageButton.width',
        height: 'forms.editor.imageButton.height',
        alignment: 'forms.editor.imageButton.alignment',
        buttonInsert: 'forms.editor.imageButton.buttonInsert',
      },
      field: {
        update: 'forms.editor.field.update',
        accept: 'forms.editor.field.accept',
        reject: 'forms.editor.field.reject',
      },
    },
    document: {
      type: {
        acceptedExtension: 'forms.document.type.acceptedExtension',
        announcement: 'forms.document.type.announcement',
        application: 'forms.document.type.application',
        bill: 'forms.document.type.bill',
        certificate: 'forms.document.type.certificate',
        collaboration: 'forms.document.type.collaboration',
        communication: 'forms.document.type.communication',
        compliance: 'forms.document.type.compliance',
        document: 'forms.document.type.document',
        instruction: 'forms.document.type.instruction',
        insurance: 'forms.document.type.insurance',
        letter: 'forms.document.type.letter',
        memorandum: 'forms.document.type.memorandum',
        newsletter: 'forms.document.type.newsletter',
        other: 'forms.document.type.other',
        receipt: 'forms.document.type.receipt',
        record: 'forms.document.type.record',
        report: 'forms.document.type.report',
        script: 'forms.document.type.script',
        specification: 'forms.document.type.specification',
        strategy: 'forms.document.type.strategy',
        warranty: 'forms.document.type.warranty',
      },
      info: {
        type: 'forms.document.info.type',
        subType: 'forms.document.info.subType',
      },
      create: {
        type: 'forms.document.create.type',
        createNew: 'forms.document.create.createNew',
        createNewInfo: 'forms.document.create.createNewInfo',
      },
      update: 'forms.document.update',
      creationModes: {
        blank: 'forms.document.creationModes.blank',
        template: 'forms.document.creationModes.template',
        upload: 'forms.document.creationModes.upload',
      },
      userRole: {
        managerTitle: 'forms.document.userRole.managerTitle',
        editorTitle: 'forms.document.userRole.editorTitle',
        acknowledgerTitle: 'forms.document.userRole.acknowledgerTitle',
        commentatorTitle: 'forms.document.userRole.commentatorTitle',
        qrViewerTitle: 'forms.document.userRole.qrViewerTitle',
        viewerTitle: 'forms.document.userRole.viewerTitle',
        ownerDescription: 'forms.document.userRole.ownerDescription',
        managerDescription: 'forms.document.userRole.managerDescription',
        editorDescription: 'forms.document.userRole.editorDescription',
        acknowledgerDescription: 'forms.document.userRole.acknowledgerDescription',
        commentatorDescription: 'forms.document.userRole.commentatorDescription',
        viewerDescription: 'forms.document.userRole.viewerDescription',
        sharedViewerDescription: 'forms.document.userRole.sharedViewerDescription',
        unknownDescription: 'forms.document.userRole.unknownDescription',
        acknowledgeRequired: 'forms.document.userRole.acknowledgeRequired',
        documentSharerDownloaderDescription:
          'forms.document.userRole.documentSharerDownloaderDescription',
        documentDownloaderDescription: 'forms.document.userRole.documentDownloaderDescription',
        documentDocumentSharerDescription:
          'forms.document.userRole.documentDocumentSharerDescription',
        documentClippedViewerDescription:
          'forms.document.userRole.documentClippedViewerDescription',
      },
    },
    contract: {
      type: {
        billOfSale: 'forms.contract.type.billOfSale',
        consultingAgreement: 'forms.contract.type.consultingAgreement',
        disclosureAgreement: 'forms.contract.type.disclosureAgreement',
        employmentContract: 'forms.contract.type.employmentContract',
        financialAgreement: 'forms.contract.type.financialAgreement',
        jointVentureAgreement: 'forms.contract.type.jointVentureAgreement',
        leasingAgreement: 'forms.contract.type.leasingAgreement',
        listingAgreement: 'forms.contract.type.listingAgreement',
        loanAgreement: 'forms.contract.type.loanAgreement',
        medicalRecordsAgreement: 'forms.contract.type.medicalRecordsAgreement',
        mergerAcquisition: 'forms.contract.type.mergerAcquisition',
        mortgageAgreement: 'forms.contract.type.mortgageAgreement',
        nonDisclosureAgreement: 'forms.contract.type.nonDisclosureAgreement',
        other: 'forms.contract.type.other',
        paymentAgreement: 'forms.contract.type.paymentAgreement',
        purchasingAgreement: 'forms.contract.type.purchasingAgreement',
        salesAgreement: 'forms.contract.type.salesAgreement',
        serviceAgreement: 'forms.contract.type.serviceAgreement',
        will: 'forms.contract.type.will',
      },
      info: {
        type: 'forms.contract.info.type',
        subType: 'forms.contract.info.subType',
      },
      create: {
        type: 'forms.contract.create.type',
        createNew: 'forms.contract.create.createNew',
        createNewInfo: 'forms.contract.create.createNewInfo',
      },
      update: 'forms.contract.update',
      creationModes: {
        blank: 'forms.contract.creationModes.blank',
        template: 'forms.contract.creationModes.template',
        upload: 'forms.contract.creationModes.upload',
      },
      userRole: {
        managerTitle: 'forms.contract.userRole.managerTitle',
        editorTitle: 'forms.contract.userRole.editorTitle',
        signatoryTitle: 'forms.contract.userRole.signatoryTitle',
        commentatorTitle: 'forms.contract.userRole.commentatorTitle',
        qrViewerTitle: 'forms.contract.userRole.qrViewerTitle',
        viewerTitle: 'forms.contract.userRole.viewerTitle',
        ownerDescription: 'forms.contract.userRole.ownerDescription',
        managerDescription: 'forms.contract.userRole.managerDescription',
        editorDescription: 'forms.contract.userRole.editorDescription',
        signatoryDescription: 'forms.contract.userRole.signatoryDescription',
        commentatorDescription: 'forms.contract.userRole.commentatorDescription',
        viewerDescription: 'forms.contract.userRole.viewerDescription',
        sharedViewerDescription: 'forms.contract.userRole.sharedViewerDescription',
        unknownDescription: 'forms.contract.userRole.unknownDescription',
        signatureRequired: 'forms.contract.userRole.signatureRequired',
        chooseAffiliationType: 'forms.contract.userRole.chooseAffiliationType',
      },
      workflow: {
        noCounterparty: 'forms.contract.workflow.noCounterparty',
        counterpartyContentIsLoading: 'forms.contract.workflow.counterpartyContentIsLoading',
      },
    },
    template: {
      type: {
        document: 'forms.template.type.document',
        contract: 'forms.template.type.contract',
      },
      info: {
        type: 'forms.template.info.type',
        subType: 'forms.template.info.subType',
        author: 'forms.template.info.author',
      },
      create: {
        defaultTitle: 'forms.template.create.defaultTitle',
        type: 'forms.template.create.type',
        createNew: 'forms.template.create.createNew',
        createNewInfo: 'forms.template.create.createNewInfo',
      },
      creationModes: {
        blank: 'forms.template.creationModes.blank',
        upload: 'forms.template.creationModes.upload',
      },
      createByTemplate: {
        noTemplates: 'forms.template.createByTemplate.noTemplates',
        createButton: 'forms.template.createByTemplate.createButton',
      },
    },
    organizationUser: {
      joinedTheTeam: 'forms.organizationUser.joinedTheTeam',
      inviteSent: 'forms.organizationUser.inviteSent',
      edit: 'forms.organizationUser.edit',
      remove: 'forms.organizationUser.remove',
    },
  },
  card: {
    document: {
      title: 'card.document.title',
    },
    contract: {
      title: 'card.contract.title',
    },
    template: {
      title: 'card.template.title',
    },
    templateLibrary: {
      title: 'card.templateLibrary.title',
    },
    fields: {
      date: 'card.fields.date',
      signed: 'card.fields.signed',
      createdBy: 'card.fields.createdBy',
    },
    delete: 'card.delete',
  },
  modal: {
    warning: 'modal.warning',
    availableSeats: 'modal.availableSeats',
    paymentConfirmation: {
      title: 'modal.paymentConfirmation.title',
      subTitle: 'modal.paymentConfirmation.subTitle',
      plan: 'modal.paymentConfirmation.plan',
      subTotal: 'modal.paymentConfirmation.subTotal',
      tax: 'modal.paymentConfirmation.tax',
      totalPrice: 'modal.paymentConfirmation.totalPrice',
    },
  },
  checkout: {
    monthly: {
      title: 'checkout.monthly.title',
      period: 'checkout.monthly.period',
      standard: 'checkout.monthly.standard',
      business: 'checkout.monthly.business',
      realestate: 'checkout.monthly.realestate',
    },
    annual: {
      title: 'checkout.annual.title',
      period: 'checkout.annual.period',
      standard: 'checkout.annual.standard',
      business: 'checkout.annual.business',
      realestate: 'checkout.annual.realestate',
    },
    agreement: 'checkout.agreement',
    paymentDetails: 'checkout.paymentDetails',
    plan: 'checkout.plan',
    period: 'checkout.period',
    unitPrice: 'checkout.unitPrice',
    quantity: 'checkout.quantity',
    purchaseSummary: 'checkout.purchaseSummary',
  },
  attachment: {
    delete: 'attachment.delete',
    text: 'attachment.text',
  },
  legalAgreement: {
    description: 'legalAgreement.description',
  },
  dropzone: {
    text: 'dropzone.text',
    supportedFileTypes: 'dropzone.supportedFileTypes',
    note: 'dropzone.note',
  },
  sidebar: {
    lastModifiedText: 'sidebar.lastModifiedText',
  },
  switch: {
    on: 'switch.on',
    off: 'switch.off',
  },
  stripe: {
    title: 'stripe.title',
  },
  negotiation: {
    matched: 'negotiation.matched',
    notMatched: 'negotiation.notMatched',
    signed_singular: 'negotiation.signed_singular',
    notSigned_singular: 'negotiation.notSigned_singular',
    signed_plural: 'negotiation.signed_plural',
    notSigned_plural: 'negotiation.notSigned_plural',
  },
  plans: {
    basic: 'plans.basic',
    businessMonthly: 'plans.businessMonthly',
    businessAnnually: 'plans.businessAnnually',
    realEstateMonthly: 'plans.realEstateMonthly',
    realEstateAnnually: 'plans.realEstateAnnually',
  },
  networkNotifications: {
    title: 'networkNotifications.title',
    online: 'networkNotifications.online',
    offline: 'networkNotifications.offline',
  },
  sectionDeleteConfirm: {
    title: 'sectionDeleteConfirm.title',
    confirm: 'sectionDeleteConfirm.confirm',
    cancel: 'sectionDeleteConfirm.cancel',
  },
  subscriptionPlanLabel: {
    period: {
      none: 'subscriptionPlanLabel.period.none',
      monthly: 'subscriptionPlanLabel.period.monthly',
      annual: 'subscriptionPlanLabel.period.annual',
    },
    plan: {
      basic: 'subscriptionPlanLabel.plan.basic',
      premium: 'subscriptionPlanLabel.plan.premium',
      business: 'subscriptionPlanLabel.plan.business',
      teams: 'subscriptionPlanLabel.plan.teams',
      enterprise: 'subscriptionPlanLabel.plan.enterprise',
      vip: 'subscriptionPlanLabel.plan.vip',
      standard: 'subscriptionPlanLabel.plan.standard',
      realestate: 'subscriptionPlanLabel.plan.realestate',
    },
  },
};
