import { FC } from 'react';
import { formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { toPairs, flow } from 'lodash';

import { RootState } from 'src/app/types';
import { EntityType } from 'src/models/paper';
import { TemplateIndustryType } from 'src/models/template';
import { withInitialValues } from 'src/utils/hocs';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

export const getTypeSelector = (form: string): ((state: RootState) => EntityType) => {
  const selector = formValueSelector(form);
  return (state: RootState): EntityType => selector(state, 'type');
};

export const getPaperTypeSelector = (form: string): ((state: RootState) => EntityType) => {
  const selector = formValueSelector(form);
  return (state: RootState): EntityType => selector(state, 'paperType');
};

export const getIndustryOptions = (): object[] => [
  { label: i18n(translationKeys.forms.item.selectIndustry), value: '', disabled: true },
  ...toPairs(TemplateIndustryType).map(([value, label]) => ({ value, label })),
];

export const getTypeOptions = (): object[] => [
  {
    label: i18n(translationKeys.forms.item.selectTemplateType),
    value: '',
    disabled: true,
  },
  { label: i18n(translationKeys.forms.template.type.document), value: EntityType.document },
  { label: i18n(translationKeys.forms.template.type.contract), value: EntityType.contract },
];

const templateFields = [
  'title',
  'description',
  'paperType',
  'type',
  'subType',
  'industry',
  'country',
  'province',
  'city',
  'isTemplate',
];

export function connectTemplateForm<Props, Payload>(
  Component: FC<Props & InjectedFormProps<Payload, Props>>,
  fields: string[],
  defaultProps?: Partial<Payload>,
): FC<Props> {
  const srcMapper = (props: Props) => ({ ...defaultProps, ...props, isTemplate: true });
  return flow([
    reduxForm<Payload, Props>({ form: 'editMetadata', enableReinitialize: true }),
    withInitialValues<Payload, Props>([...templateFields, ...fields], srcMapper),
  ])(Component);
}
