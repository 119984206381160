import { ReactElement, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { SignUpStepComponent, useSignUpProps } from 'src/v2/features/signUp/pages/hooks';
import { setStep } from 'src/v2/features/signUp/store';
import { WizardStepV2 } from 'src/models/signUp';
import { LoginFormLight } from '../../auth/LoginFormLight';

export const useEmailPasswordLightComponent = (): SignUpStepComponent => {
  const dispatch = useDispatch();
  const { step } = useSignUpProps();

  const handleSignUpFlow = useCallback(() => {
    dispatch(
      setStep({
        step: WizardStepV2.SignUp,
        stepNum: 1,
        maxSteps: 1,
      }),
    );
  }, [dispatch]);

  const handleForgotPasswordFlow = useCallback(() => {
    dispatch(
      setStep({
        step: WizardStepV2.ForgotPassword,
        stepNum: 1,
        maxSteps: 1,
      }),
    );
  }, [dispatch]);

  return [
    (): ReactElement => (
      <LoginFormLight
        onSignUpFlow={handleSignUpFlow}
        onForgotPasswordFlow={handleForgotPasswordFlow}
      />
    ),
    { step },
  ];
};
