import { isUndefined } from 'lodash';
import { BillingPeriod, SubscriptionPlan } from 'src/models/billing';

interface MarketingEventBus {
  testEvent: () => void;
  signupWithPaymentComplete: (plan: SubscriptionPlan, period: BillingPeriod) => void;
}

const fireEvent = (params: Record<string, string>) => {
  if (isUndefined(window.dataLayer)) {
    console.warn('GTM is not initialized');
    return;
  }

  window.dataLayer.push(params);
};

const subscriptionPlanToKey: Record<SubscriptionPlan, string> = {
  [SubscriptionPlan.Basic]: 'basic',
  [SubscriptionPlan.Premium]: 'premium',
  [SubscriptionPlan.Teams]: 'teams',
  [SubscriptionPlan.Business]: 'business',
  [SubscriptionPlan.Enterprise]: 'enterprise',
  [SubscriptionPlan.Vip]: 'vip',
  [SubscriptionPlan.Standard]: 'standard',
  [SubscriptionPlan.RealEstate]: 'realestate',
};

const billingPeriodToKey = {
  [BillingPeriod.None]: 'none',
  [BillingPeriod.Monthly]: 'monthly',
  [BillingPeriod.Annually]: 'annual',
};

export const marketingService: MarketingEventBus = {
  testEvent: () => {
    fireEvent({ event: 'testEvent' });
  },
  signupWithPaymentComplete: (plan: SubscriptionPlan, period: BillingPeriod) => {
    fireEvent({ event: `${subscriptionPlanToKey[plan]}-${billingPeriodToKey[period]}` });
  },
};
