/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createAction, PayloadAction } from '@reduxjs/toolkit';
import { all, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { openContractRoom, openDocumentRoom } from 'src/v2/features/documentParticipants/actions';
import { fetchWorkflow } from 'src/v2/features/documentWorkflow/documentWorkflow';
import { closeCurrentRoom, openRoom } from 'src/v2/features/chat/store';
import { EntityType } from 'src/models/paper';
import {
  shareDocument,
  shareSaga as shareDocumentSaga,
  qrCodeShareDocument,
  qrCodeShareSaga as qrCodeShareDocumentSaga,
} from 'src/v2/features/document/share';
import { LoadSidebarDataPayload } from 'src/v2/features/sharedEntity/EntitySidebar/types';

function* openDocumentRoomSaga({ payload }: PayloadAction<string>): Generator {
  yield put(
    openRoom({
      affiliationType: EntityType.document,
      affiliationId: payload,
    }),
  );
}

function* openContractRoomSaga({ payload }: PayloadAction<string>): Generator {
  yield put(
    openRoom({
      affiliationType: EntityType.contract,
      affiliationId: payload,
    }),
  );
}

function openEntityRoom(entity: { id: string; entityType?: EntityType; type?: EntityType }) {
  const entityType = entity.entityType || entity.type;
  switch (entityType) {
    case EntityType.document:
      return openDocumentRoom(entity.id);
    case EntityType.contract:
      return openContractRoom(entity.id);
    default:
      // fallback in case we try opening room for unhandled entity type
      console.error(`openEntityRoom: unhandled document type met '${entity.type}'`);
      return closeCurrentRoom();
  }
}

export const loadSidebarData = createAction<LoadSidebarDataPayload>('documentSidebar/loadData');

export function* loadSidebarDataSaga({ payload }: PayloadAction<LoadSidebarDataPayload>) {
  yield all([put(openEntityRoom(payload)), put(fetchWorkflow(payload.id))]);
}

/** @deprecated */
export function* watchDocumentSidebarSagasV2(): SagaIterator {
  yield takeLatest(loadSidebarData, loadSidebarDataSaga);
  yield takeLatest(shareDocument, shareDocumentSaga);
  yield takeLatest(qrCodeShareDocument, qrCodeShareDocumentSaga);
  yield takeLatest(openDocumentRoom, openDocumentRoomSaga);
  yield takeLatest(openContractRoom, openContractRoomSaga);
}
