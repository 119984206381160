import React from 'react';

import { Button, Type } from 'src/v2/features/button';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

export interface StateProps {
  message: string;
}

export interface DispatchProps {
  onClose: () => void;
  onConfirm: () => void;
}

export interface Props extends StateProps, DispatchProps {}

const buttonClassName = 'c-button--fs-12 c-button--inline c-button--w-100';

export const ConfirmSection: React.FC<Props> = ({ onClose, onConfirm, message }: Props) => (
  <div className="c-alert c-alert--active">
    <div className="c-alert__title">{message}</div>
    <div className="c-alert__action c-alert--center">
      <Button actionType={Type.Secondary} onClick={onConfirm} className={buttonClassName}>
        {i18n(translationKeys.buttons.yes)}
      </Button>
      <Button actionType={Type.Danger} onClick={onClose} className={buttonClassName}>
        {i18n(translationKeys.buttons.no)}
      </Button>
    </div>
  </div>
);
