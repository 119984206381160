import React from 'react';
import cn from 'classnames';
import { Link } from '@theme-ui/components';

import { HeaderItem } from 'src/v2/modules/layout/header';
import { HeaderLinkConfig, SignHeaderLinkConfig } from 'src/v2/features/layout/headerLinks';

export interface Props {
  onHamburgerClick: () => void;
  navIsOpen: boolean;
  links?: HeaderLinkConfig[];
  signLinks?: SignHeaderLinkConfig;
}

export const PublicHeaderNav = React.forwardRef<HTMLDivElement, Props>(
  ({ onHamburgerClick, navIsOpen, links, signLinks }, ref): JSX.Element => {
    return (
      <HeaderItem>
        <div className="c-burger c-burger--header" onClick={onHamburgerClick}>
          <div className="c-burger__line" />
        </div>

        <nav className={cn('c-nav c-nav--mobile', { 'c-nav--active': navIsOpen })}>
          <div className="c-nav__scroll" ref={ref}>
            {links &&
              links.map((link: HeaderLinkConfig) => (
                <Link className="c-nav__link" href={link.to} key={link.label} external>
                  {link.label}
                </Link>
              ))}
            {signLinks &&
              Object.keys(signLinks).map((key, i) => {
                const { label, href, className } = signLinks[key];
                return (
                  <Link className={`c-nav c-nav__${className}`} href={href} key={label} external>
                    {label}
                  </Link>
                );
              })}
          </div>
        </nav>
      </HeaderItem>
    );
  },
);

PublicHeaderNav.displayName = 'PublicHeaderNav';
