// Core
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Flex, Image } from '@theme-ui/components';
import { isString } from 'lodash';
import { toastr } from 'react-redux-toastr';

import { FileInput } from 'src/v2/components/FileInput';
import { Avatar } from 'src/v2/components/Avatar';
import { useAuthAndFillOrganization } from 'src/v2/features/profile/hooks';
import { getIsLoading } from 'src/v2/features/profile/store';
import { ProfilePage } from 'src/v2/features/profile/ProfilePage';
import verifiedIcon from 'src/assets/verified-icon.png';
import { RoundedImageEditor } from 'src/v2/components/RoundedImageEditor/RoundedImageEditor';
import { handleFileChange, dataURLtoFile, readImageFile, changeImageHash } from 'src/utils/files';
import { AVATAR_SUPPORTED_UPLOAD_FORMATS } from 'src/v2/features/profile/constants';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

import { ProfileForm } from './index';
import { getAvatarFromProfile, updateProfileAvatar } from '../store';
import { getAvatarUrl } from '../utils';
import { MimeType } from '../../../../common/types';

const styles = {
  container: {
    py: '50px',
    pl: '100px',
  },

  containerFlex: {
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },

  formColumn: {
    width: '500px',
    mr: '20px',
  },

  avatar: {
    marginBottom: '20px;',
    '& > div': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
  },

  verifiedLabel: {
    alignItems: 'flex-end',
    fontFamily: 'body',
    fontSize: 'large',
    color: '#4d6982',
    '& img': {
      pr: '5px',
    },
  },

  fileInput: {
    cursor: 'pointer',
    fontFamily: 'body',
    fontWeight: 400,
    fontSize: 'large',
    color: '#29cb97',
  },

  description: {
    py: '20px',
    fontFamily: 'body',
    fontSize: 'large',
    lineHeight: 1.2,
    color: '#707070',
    '& h2': {
      fontFamily: 'inherit',
      fontSize: 'inherit',
      lineHeight: 'inherit',
      color: '#000000',
    },
  },
};

const supportedUploadFormats = AVATAR_SUPPORTED_UPLOAD_FORMATS.join(',');

export const PersonalInfo: React.FC = () => {
  useAuthAndFillOrganization();
  const isLoading = useSelector(getIsLoading);
  const userAvatar = useSelector(getAvatarFromProfile);
  const [imageFile, setImageFile] = useState<string | ArrayBuffer | null>(null);
  const [avatarImage, setImage] = useState(getAvatarUrl(userAvatar));
  const dispatch = useDispatch();

  useEffect(() => {
    setImage(getAvatarUrl(userAvatar));
  }, [userAvatar]);

  const submitAvatar = useCallback((file: File) => dispatch(updateProfileAvatar(file)), [dispatch]);

  const setAvatarImage = useCallback(
    (dataURL: string): void => {
      if (dataURL.length) {
        const imageFile: File = dataURLtoFile(dataURL);
        submitAvatar(imageFile);
        setImageFile(null);
      }
    },
    [submitAvatar],
  );

  const setAvatarFile = useCallback((files: File): void => {
    !AVATAR_SUPPORTED_UPLOAD_FORMATS.includes(files.type as MimeType)
      ? toastr.error(` ${i18n(translationKeys.errors.FILE_FORMAT_ERROR)}`, '')
      : readImageFile(files).then((image: HTMLImageElement): void => {
          setImageFile(changeImageHash(image.src));
        });
  }, []);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      handleFileChange(setAvatarFile)(e);
    },
    [setAvatarFile],
  );

  const handleImageSaved = useCallback(
    (dataURL: string): void => {
      setAvatarImage(dataURL);
    },
    [setAvatarImage],
  );

  return (
    <ProfilePage>
      <div className="c-setup c-setup--bg-transparent" data-id="profile-container-personalInfo">
        <div className="c-setup__top">
          <Flex sx={styles.avatar}>
            <Avatar src={avatarImage} />
            <Flex>
              <Flex sx={styles.verifiedLabel}>
                <Image data-type="personalInfo-verifiedIcon" src={verifiedIcon} />
                {` ${i18n(translationKeys.profile.verifiedProfile)}`}
              </Flex>
              <FileInput
                name="avatar"
                sx={styles.fileInput}
                accept={supportedUploadFormats}
                onChange={handleChange}
              >
                {isLoading
                  ? i18n(translationKeys.messages.loading)
                  : i18n(translationKeys.buttons.addPhoto)}
              </FileInput>
            </Flex>
          </Flex>
          {imageFile && isString(imageFile) && (
            <div className="l-row">
              <RoundedImageEditor
                src={imageFile}
                style={{ height: 192, width: '100%' }}
                onSave={handleImageSaved}
              />
            </div>
          )}
        </div>
        <div className="c-setup__content">
          <div className="l-row">
            <div className="c-title c-title--color-theme c-title--fs-24">
              {i18n(translationKeys.profile.informationTitle)}
            </div>
          </div>
          <div className="l-row l-row--mt-16">
            <div className="c-title c-title--color-theme c-title--lh-16 c-title--fw-400">
              {i18n(translationKeys.profile.informationTextPersonal)}
            </div>
          </div>
          <div className="l-row">
            <ProfileForm />
          </div>
        </div>
      </div>
    </ProfilePage>
  );
};
