import { FC, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { isUndefined } from 'lodash';

import { book } from 'src/app/book';
import { history } from 'src/initializeHistory';
import { setEntityId } from 'src/v2/features/signUp/store/signUpStore';
import { fetchDocumentQR } from 'src/v2/features/documentDetails/documentDetailsSlice';
import { useGetEntityIdFromUrl } from 'src/v2/features/sharedEntity/hooks';

export const QRCodePage: FC = () => {
  const id = useGetEntityIdFromUrl();
  const dispatch = useDispatch();

  const onFetchFailed = useCallback(() => {
    dispatch(setEntityId(null));
    history.push(book.home);
  }, [dispatch]);

  useEffect(() => {
    if (!isUndefined(id)) {
      dispatch(fetchDocumentQR({ entityId: id, onFetchFailed }));
    }
  }, [dispatch, id, onFetchFailed]);
  return null;
};
