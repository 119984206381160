import { FC, MouseEvent } from 'react';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { formatDate } from 'src/instruments';
import { MetadataView, MetadataViewRow, getDocumentStateLabel } from 'src/v2/features/metadata';

import { ContractMetaProps } from './types';

interface Props extends ContractMetaProps {
  isAllowedToEdit: boolean;
  onEdit: (e: MouseEvent) => void;
}

export const ContractMetadataView: FC<Props> = ({
  title,
  description,
  createdAt,
  updatedAt,
  subType,
  subSubType,
  state,
  owner,
  role,
  isAllowedToEdit,
  onEdit,
}) => (
  <MetadataView
    labelType={i18n(translationKeys.forms.contract.info.type)}
    labelSubType={i18n(translationKeys.forms.contract.info.subType)}
    title={title}
    description={description}
    subType={subType}
    subSubType={subSubType}
    creationDate={createdAt && formatDate(createdAt)}
    updatedData={updatedAt && formatDate(updatedAt)}
    isAllowedToEdit={isAllowedToEdit}
    onEdit={onEdit}
  >
    <MetadataViewRow label={i18n(translationKeys.sorting.state)}>
      {state && getDocumentStateLabel(state)}
    </MetadataViewRow>
    <MetadataViewRow label={i18n(translationKeys.sorting.owner)}>{owner}</MetadataViewRow>
    <MetadataViewRow label={i18n(translationKeys.sorting.roleOfCreator)}>{role}</MetadataViewRow>
  </MetadataView>
);
