import React from 'react';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { Document } from 'src/models/document';
import { ContractRole } from 'src/models/contract';
import { ContractCard } from 'src/v2/features/contract/components/ContractCard';
import { SearchCardGrid } from 'src/v2/components/SearchCardsGrid';
import {
  useCreateNavigateToContractDetails,
  useCreateGetContractInfo,
} from 'src/v2/features/contract/utils';
import { parseCardDataFactory } from 'src/v2/features/sharedEntity';

interface Props {
  list: Document[];
}

const parseCardData = parseCardDataFactory<ContractRole>(i18n(translationKeys.contract.noTitle));

export const ContractsCardGrid: React.FC<Props> = ({ list }) => {
  const createHandleOpenDocument = useCreateNavigateToContractDetails();
  const createHandleClickFromSearch = useCreateGetContractInfo();

  return (
    <SearchCardGrid>
      {list.map(
        (data: Document): JSX.Element => (
          <ContractCard
            key={data.id}
            data={parseCardData(data)}
            onClick={createHandleClickFromSearch(data.id)}
            onDoubleClick={createHandleOpenDocument(data.id)}
          />
        ),
      )}
    </SearchCardGrid>
  );
};
