import { useDispatch, useSelector } from 'react-redux';

import { WizardStepV2 } from 'src/models/signUp';
import { getStep, setStep } from 'src/v2/features/signUp/store';
import { SignUpStepComponent } from 'src/v2/features/signUp/pages/hooks';

import { useSignupFormLightComponent } from './signupFormLightHook';
import { useEmailPasswordLightComponent } from './emailPasswordLightHook';
import { usePhoneVerificationLightComponent } from './phoneVerificationLight';
import { useCompleteLightComponent } from './completeLightHook';
import { useEffect } from 'react';
import { useForgotPasswordLightComponent } from '../../qrCodeAccountRecovery/hooks';

export const useComponentAndPropsLight = (): SignUpStepComponent => {
  const step: WizardStepV2 = useSelector(getStep);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setStep({
        step: WizardStepV2.SignUp,
        stepNum: 1,
        maxSteps: 1,
      }),
    );
  }, [dispatch]);

  const hookByStep = {
    [WizardStepV2.StartPersonal]: useEmailPasswordLightComponent(),
    [WizardStepV2.StartInvite]: useEmailPasswordLightComponent(),
    [WizardStepV2.SignUp]: useSignupFormLightComponent(),
    [WizardStepV2.PhoneVerification]: usePhoneVerificationLightComponent(),
    [WizardStepV2.ForgotPassword]: useForgotPasswordLightComponent(),
    [WizardStepV2.Complete]: useCompleteLightComponent(),
  };

  return hookByStep[step];
};
