import { FC } from 'react';

import { DynamicSettingsContext } from 'src/v2/modules/layout';
import { DynamicSettings } from 'src/v2/modules/layout/types';
import { useShowMetadataCreate } from 'src/v2/features/sharedEntity';
import { useCanCreateContract } from 'src/v2/features/contract/permissions/contractPermissions/contractPermissionsHooks';

import { useNotificationTogglePush, useToggle9Square, useToggleQRCode } from '../ContractSidebar';
import { ListHeader } from '../components/ListHeader';
import { ContractQuantumNavigator } from '../ContractQuantumNavigator';
import { useHasQRCodeFeature } from '../../billing';

export const DynamicSettingsContextProviderForList: FC = ({ children }) => {
  const toggleNotifications = useNotificationTogglePush();
  const toggle9squareSidebar = useToggle9Square();
  const toggleQRCodeSidebar = useToggleQRCode();
  const showMetadataCreate = useShowMetadataCreate();
  const isCanCreateContract = useCanCreateContract();
  const handleOnPlusClick = isCanCreateContract ? showMetadataCreate : undefined;
  const hasQRCodeFeature = useHasQRCodeFeature();

  const ctx: DynamicSettings = {
    headerChildren: <ListHeader />,
    onBellClick: toggleNotifications,
    onGridClick: toggle9squareSidebar,
    onQRCodeClick: toggleQRCodeSidebar,
    onPlusClick: handleOnPlusClick,
    navigatorChildren: <ContractQuantumNavigator />,
    hasQRCodeFeature,
  };

  return <DynamicSettingsContext.Provider value={ctx}>{children}</DynamicSettingsContext.Provider>;
};
