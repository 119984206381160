// Core
import React from 'react';
import { Flex, Box, Text } from '@theme-ui/components';

// Components
import { Avatar } from '../Avatar';

const styles = {
  container: {
    flexShrink: 0,
  },

  avatar: {
    backgroundColor: 'white',
  },

  meta: {
    display: 'inline-flex',
    position: 'relative',
    borderRadius: '50%',
    overflow: 'hidden',
  },

  background: {
    position: 'absolute',
    size: '100%',
  },

  extraCount: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    size: '100%',
    fontFamily: 'body',
    fontSize: '14px',
    fontWeight: 700,
  },
};

const offsetMap = {
  smaller: 0.5,
  medium: 0.4,
  bigger: 0.35,
};

interface FeedProps {
  length?: number;
  size?: number;
  offset?: 'smaller' | 'bigger';
  opaque?: boolean;
  bg?: string;
  borderColor?: string;
  color?: string;
  users: { id: string; src: string }[];
}

export const UsersFeed: React.FC<FeedProps> = ({
  length = 4,
  size = 31,
  offset: offsetVariant = 'medium',
  opaque = false,
  bg = 'black',
  borderColor,
  color = 'white',
  users: allUsers,
}) => {
  const extraCount = allUsers.length - length;
  const users = allUsers.slice(0, length);
  // @ts-ignore
  const offset = offsetMap[offsetVariant];

  const avatarStyle = {
    ...styles.avatar,
    size,
    borderColor,
  };

  const offsetStyle = {
    ml: `${-(size * offset)}px`,
    '&:first-child': {
      ml: 0,
    },
  };

  const usersJSX = users.map((user, i) => {
    const notLast = i < users.length - 1;
    const noExtraCount = !(extraCount > 0);

    if (notLast || noExtraCount) {
      return (
        <Avatar
          key={user.id}
          src={user.src}
          size={size}
          borderColor={borderColor}
          sx={{ ...avatarStyle, ...offsetStyle }}
        />
      );
    }

    const metaStyle = {
      ...styles.meta,
      size,
      border: borderColor ? `1px solid ${borderColor}` : 'none',
    };

    const bgStyle = {
      ...styles.background,
      bg,
      opacity: !opaque ? 0.5 : 1,
    };

    const countStyle = {
      ...styles.extraCount,
      color,
      fontSize: size / 2.2,
    };

    return (
      <Box key={user.id} sx={{ ...metaStyle, ...offsetStyle }}>
        <Avatar key={user.id} src={user.src} size={size} sx={avatarStyle} />

        <Box as="span" sx={bgStyle} />

        <Text as="span" sx={countStyle}>
          +{extraCount + 1}
        </Text>
      </Box>
    );
  });

  return <Flex sx={styles.container}>{usersJSX}</Flex>;
};
