import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isNull, isEmpty, isUndefined } from 'lodash';

import { SubscriptionPlan, SubscriptionStatus } from 'src/models/billing';
import { getCurrentOrganizationInfo } from 'src/shared/organization/organizationSelectors';
import { getProfileFormDataFromProfile } from 'src/v2/features/profile';
import { OrganizationFormData, ProfileFormData } from 'src/v2/features/profile/types';
import { subscribePaymentSuccess } from 'src/v2/features/billing';
import { UserState } from 'src/v2/features/organization/types';

import {
  fetchBillingDetailsWithCard,
  fetchBillingDetails,
  getBillingInfo,
  getSubscribedPlan,
  getSubscriptionStatus,
  getShowUpdateModal,
} from './store';
import { subscribeUserNeedsToCreatePlan, subscribeBillingInfoSuccess } from './billingEventBus';
import { useHasTemplateFeature, useHasGetPaymentCardInfoFeature } from './hasFeature/hooks';
import { checkIsDisabledFeature, shouldShowWarningModal } from './utils';
import { UpgradeEvent, BillingEventData } from './types';

const isValidSubscription = (subscriptionStatus: SubscriptionStatus | null) =>
  subscriptionStatus === SubscriptionStatus.Active;

const needToCheckout = (
  subscriptionStatus: SubscriptionStatus | null,
  plan: SubscriptionPlan | null,
) =>
  subscriptionStatus === SubscriptionStatus.Draft &&
  (plan === SubscriptionPlan.Standard ||
    plan === SubscriptionPlan.Business ||
    plan === SubscriptionPlan.RealEstate);

/** @deprecated */
export const useIsValidSubscription = () => {
  const subscriptionStatus = useSelector(getSubscriptionStatus);
  const subscriptionStatusIsLoaded = !isNull(subscriptionStatus);
  return subscriptionStatusIsLoaded ? isValidSubscription(subscriptionStatus) : true;
};

export const useFetchBillingDetails = () => {
  const isCanFetchCardInfo = useHasGetPaymentCardInfoFeature();
  const selectedPlan = useSelector(getSubscribedPlan);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isCanFetchCardInfo && !isNull(selectedPlan)) {
      dispatch(fetchBillingDetailsWithCard());
    } else {
      dispatch(fetchBillingDetails());
    }
  }, [dispatch, isCanFetchCardInfo, selectedPlan]);
};

export const useShouldShowUpgradeModal = (): boolean => {
  const location = useLocation();
  const hasTemplateFeature = useHasTemplateFeature();
  const isDisabledFeature = checkIsDisabledFeature(location.pathname);
  const shouldShowUpgradeModal = useSelector(getShowUpdateModal);

  return (!hasTemplateFeature && isDisabledFeature) || shouldShowUpgradeModal;
};

export const useShouldShowWarningModal = (users: UserState[], seats: number): boolean => {
  return shouldShowWarningModal(users, seats);
};

export const useNeedToCheckout = (): boolean => {
  const subscriptionStatus = useSelector(getSubscriptionStatus);
  const selectedPlan = useSelector(getSubscribedPlan);

  return needToCheckout(subscriptionStatus, selectedPlan);
};

export const usePopulateFromProfile = (autofill: (field: string, value: any) => void) => {
  const personalProfile = useSelector(getProfileFormDataFromProfile) as ProfileFormData;
  const organizationProfile = useSelector(getCurrentOrganizationInfo) as OrganizationFormData;
  useEffect(() => {
    if (!isEmpty(personalProfile) && isEmpty(organizationProfile)) {
      autofill('firstName', personalProfile.firstName);
      autofill('lastName', personalProfile.lastName);
      autofill('email', personalProfile.email);
      autofill('address', personalProfile.address);
      autofill('city', personalProfile.city);
      autofill('state', personalProfile.state);
      autofill('postalCode', personalProfile.zipCode);
      autofill('country', personalProfile.country);
    } else {
      autofill('country', 'US');
      autofill('state', 'DC');
    }
  }, [personalProfile, organizationProfile, autofill]);
};

export const usePopulateFromOrganization = (autofill: (field: string, value: any) => void) => {
  const personalProfile = useSelector(getProfileFormDataFromProfile) as ProfileFormData;
  const organizationProfile = useSelector(getCurrentOrganizationInfo) as OrganizationFormData;
  const isOrganization = !isUndefined(organizationProfile);

  useEffect(() => {
    if (isOrganization && !isEmpty(personalProfile)) {
      autofill('firstName', personalProfile.firstName);
      autofill('lastName', personalProfile.lastName);
      autofill('email', personalProfile.email);
    }
    if (isOrganization && !isEmpty(organizationProfile)) {
      autofill('address', organizationProfile.address);
      autofill('city', organizationProfile.city);
      autofill('state', organizationProfile.state);
      autofill('postalCode', organizationProfile.zipCode);
      autofill('country', organizationProfile.country);
    }
  }, [personalProfile, organizationProfile, autofill, isOrganization]);
};

export const usePopulateFromBilling = (autofill: (field: string, value: string) => void) => {
  const billingInfo = useSelector(getBillingInfo);
  const { card } = billingInfo;

  useEffect(() => {
    if (isNull(card.brand)) return;

    if (!isEmpty(billingInfo) && !isNull(billingInfo) && billingInfo.billingDetails) {
      const { billingDetails } = billingInfo;
      autofill('firstName', billingDetails.firstName || '');
      autofill('lastName', billingDetails.lastName || '');
      autofill('email', billingDetails.email);
      autofill('address', billingDetails.address.line1);
      autofill('city', billingDetails.address.city);
      autofill('state', billingDetails.address.state);
      autofill('postalCode', billingDetails.address.postalCode);
      autofill('country', billingDetails.address.country);
    } else {
      autofill('country', 'US');
      autofill('state', 'DC');
    }
  }, [billingInfo, card.brand, autofill]);
};

export const useSubscribeToUserNeedUpgradePlan = (
  callback: (eventData: UpgradeEvent) => void,
): void => {
  useEffect(() => {
    const subscription$ = subscribeUserNeedsToCreatePlan(callback);

    return () => subscription$.unsubscribe();
  }, [callback]);
};

export const useSubscribeToUserUpdatedBillingInfo = (
  callback: (eventData: BillingEventData) => void,
): void => {
  useEffect(() => {
    const subscription$ = subscribeBillingInfoSuccess(callback);

    return () => subscription$.unsubscribe();
  }, [callback]);
};

export const useSubscribeToUserUpgradeBillingInfo = (
  callback: (eventData: BillingEventData) => void,
): void => {
  useEffect(() => {
    const subscription$ = subscribePaymentSuccess(callback);

    return () => subscription$.unsubscribe();
  }, [callback]);
};
