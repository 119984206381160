import { EditorConfig } from 'ckeditor5/src/core';
import { CKEditorProps } from '@ckeditor/ckeditor5-react';

type ErrorEventEditableField = { name: string; type: 'error'; message?: string };
type DefaultEventEditableField = { name: string; value: string; type: 'ok'; id: string };
type ErrorEventCheckbox = { id: string; type: 'error'; message?: string };
type DefaultEventCheckbox = { id: string; checked: boolean; type: 'ok' };

export const editableFieldEventIsError = (
  data: DefaultEventEditableField | ErrorEventEditableField,
): data is ErrorEventEditableField => (data as ErrorEventEditableField).type === 'error';

export const checkboxEventIsError = (
  data: DefaultEventCheckbox | ErrorEventCheckbox,
): data is ErrorEventCheckbox => (data as ErrorEventCheckbox).type === 'error';

export type EventDataEditableFieldCreate = { name: string };
export type EventDataEditableFieldChange = {
  name: string;
  value: string;
  withoutOutEvent?: boolean;
};
export type EventDataEditableFieldGetContent = DefaultEventEditableField | ErrorEventEditableField;
export type EventDataEditableFieldSetContent = DefaultEventEditableField | ErrorEventEditableField;
export type EventDataEditableFieldSetIsUpdating =
  | DefaultEventEditableField
  | ErrorEventEditableField;
export type EventDataCheckboxCreate = { id: string; checked: boolean };
export type EventDataCheckboxChange = { id: string; checked: boolean };
export type EventDiffReview = { type: string; name: string; value: string };
export type EventCheckboxDiffReview = { type: string; id: string; checked: boolean };
export type EventDataEditableFieldFieldDiffBul = { type: string };
export type EventDataCheckboxGetChecked = DefaultEventCheckbox | ErrorEventCheckbox;

type InEventDataEditableFieldSetIsUpdating = { name: string; value: boolean; id: string };

type DiffItem = { name: string; newValue: string };

export interface EditorCoreRef {
  getFieldContent(name: string): void;
  getCheckboxChecked(id: string): void;
  setCheckboxChange(id: string, checked: boolean): void;
  setEnabledFieldUpdating(data: InEventDataEditableFieldSetIsUpdating): void;
  setEnabledFieldDiff(name: string, newValue: string, canReview: boolean): void;
  setEnabledFieldDiffBulk(fields: DiffItem[], canReview: boolean): void;
  setCheckboxesDiffBulk(fields: DiffItem[], canReview: boolean): void;
  getEditorContent(): string | undefined;
  setCheckboxesEnabled(enabled: boolean): void;
  setEditableFieldsEnabled(enabled: boolean): void;
  setEnabledFieldContent(data: EventDataEditableFieldChange): void;
  setFocusOnEditor(): void;
  destroyEditor(): void;
}

export interface EditorCoreProps extends Omit<CKEditorProps, 'editor'> {
  config: EditorConfig;
  isRestricted: boolean;
  showToolbar?: boolean;
  isDevMode?: boolean;
  onEditableFieldChange?(data: EventDataEditableFieldChange): void;
  onEditableFieldCreate?(data: EventDataEditableFieldCreate): void;
  onEditableFieldGetContent?(data: EventDataEditableFieldGetContent): void;
  onEditableFieldSetContent?(data: EventDataEditableFieldSetContent): void;
  onEditableFieldDiffBulk?(data: EventDataEditableFieldFieldDiffBul): void;
  onEditableFieldSetIsUpdating?(data: EventDataEditableFieldSetIsUpdating): void;
  onDiffReview?(data: EventDiffReview): void;
  /** Checkboxes */
  onCheckboxCreate?(data: EventDataCheckboxCreate): void;
  onCheckboxChange?(data: EventDataCheckboxChange): void;
  onCheckboxGetChecked?(data: EventDataCheckboxGetChecked): void;
  onCheckboxDiffReview?(data: EventCheckboxDiffReview): void;
}
