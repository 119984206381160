import React, { useCallback, useRef } from 'react';

import { useOnClickOutside } from 'src/common/hooks/useOnClickOutside';
import { useToggle } from 'src/common/hooks/useToggle';
import { Card, StateOption } from 'src/v2/components/Card';

import { PaperState } from 'src/models/paper';

import { EntityCardProps } from '../../types';

const stateByStatus = {
  [PaperState.draft]: StateOption.Red,
  [PaperState.inProgress]: StateOption.Yellow,
  [PaperState.completed]: StateOption.Green,
};

export const EntityCard = <R,>({
  data,
  onClick,
  onDoubleClick,
  onDelete,
  intent,
  selected,
  showSignedStatus = false,
  canDeleteEntity = true,
  onContextMenu,
  icon,
}: EntityCardProps<R>) => {
  const { title, description, date, status = PaperState.draft, author, users = [], role } = data;
  const statusText = status === PaperState.completed ? 'YES' : 'NO';
  const authorName = author ? author.name : '';
  const [isConfirmationVisible, , showConfirmation, hideConfirmation] = useToggle();
  const crossRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(crossRef, hideConfirmation);
  const handleDelete = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();

      if (onDelete) onDelete(e);
    },
    [onDelete],
  );
  const handleCrossClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (!isConfirmationVisible) {
        showConfirmation();
      }
    },
    [isConfirmationVisible, showConfirmation],
  );

  return (
    <Card
      crossRef={crossRef}
      title={title}
      description={description}
      date={date}
      status={statusText}
      author={authorName}
      state={stateByStatus[status]}
      onContextMenu={onContextMenu}
      onDeleteClick={handleDelete}
      onCrossClick={handleCrossClick}
      isConfirmationVisible={isConfirmationVisible}
      onClick={onClick}
      onTouch={onDoubleClick}
      onDoubleClick={onDoubleClick}
      users={users}
      intent={intent}
      canDeleteCard={canDeleteEntity}
      selected={selected}
      showSignedStatus={showSignedStatus}
      role={role}
      icon={icon}
    />
  );
};

EntityCard.displayName = 'EntityCard';
