import React, { FC, MouseEvent } from 'react';
import { noop } from 'lodash';

import { NodeTree } from 'src/models/node';
import { ListItem } from 'src/models/list';
import { FsItem } from 'src/v2/modules/FsItem/FsItem';
import { ListItemViewComponentProps } from 'src/v2/modules/FsItem/types';

import { FolderViewContainer } from '../FolderView/FolderViewContainer';
import { FolderSelectorNavigatorBody } from '../FolderSelectorNavigatorBody';
import { LeftMenuItem } from '../LeftMenuItem';
import { FolderSelectorTransitionProps } from '../types';

interface Props extends FolderSelectorTransitionProps {
  onClose: (e: MouseEvent) => void;
  onActionButtonClick: (e: MouseEvent) => void;
  model: NodeTree<ListItem>;
  viewComponent?: FC<ListItemViewComponentProps>;
}

export const FolderSelectorWithProjects: FC<Props> = ({
  onClose,
  heading,
  onActionButtonClick,
  actionButtonLabel,
  menuItems = [],
  model,
  viewComponent = FolderViewContainer,
}) => {
  return (
    <div className="c-move">
      <div className="c-move__top">{heading}</div>
      <div className="c-move__content">
        <div className="c-move__left">
          <div className="c-library c-library--quantum">
            <div className="c-library__row c-library--mt-8">
              <div className="c-library__list">
                {menuItems.map((item) => (
                  <LeftMenuItem {...item} key={item.title} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="c-move__middle">
          <FolderSelectorNavigatorBody>
            {model.children.map((folder) => (
              <FsItem
                key={folder.data.id}
                model={folder}
                viewComponent={viewComponent}
                loadFolderContent={noop}
                checkDefaultOpenState={() => false}
              />
            ))}
          </FolderSelectorNavigatorBody>
        </div>
        <div className="c-move__right">
          <button className="c-button c-button--w-full" type="button" onClick={onActionButtonClick}>
            <span className="c-button__inner">{actionButtonLabel}</span>
          </button>
          <button
            className="c-button c-button--w-full c-button--gray"
            type="button"
            onClick={onClose}
          >
            <span className="c-button__inner">Cancel</span>
          </button>
        </div>
      </div>
    </div>
  );
};
