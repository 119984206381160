import { createContext } from 'react';

interface ContextProps {
  updatedEditableSectionsField: { name: string; value: string; sectionIndex: number } | null;
  setUpdatedEditableSectionsField: any | null;
  initializedEditorIndex: number | null;
}

export const EditableSectionsContext = createContext<ContextProps>({
  updatedEditableSectionsField: null,
  setUpdatedEditableSectionsField: null,
  initializedEditorIndex: null,
});
