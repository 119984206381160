import { createSelector } from '@reduxjs/toolkit';
import { isNil } from 'lodash';

import { RootState } from 'src/app/types';
import { getSignaturesSelector, getDefaultSignature } from 'src/v2/features/signature/store';

import { isIncludeHankoVisible } from '../rules';

export const getSignatureStamps = createSelector(getSignaturesSelector, (userSignatures) =>
  !isNil(userSignatures) ? userSignatures.stamp : null,
);

export const getDefaultSignatureStampId = createSelector(getDefaultSignature, (signature) =>
  !isNil(signature) ? signature.default_stamp_id : null,
);

const getState = (state: RootState) => state.hankoSignature;
export const getIsLoading = createSelector(getState, (state) => state.isLoading);
export const getLocalisedSignature = createSelector(getState, (state) => state.localisedSignature);
export const getIncludeHanko = createSelector(getState, (state) => state.includeHanko);
export const getIsIncludeHankoVisible = createSelector(
  getLocalisedSignature,
  isIncludeHankoVisible,
);
export const getUploadedImageUrl = createSelector(getState, (state) => state.uploadedImageUrl);
export const getHankoFile = createSelector(getState, (state) => state.hankoFile);
export const getHankoPreview = createSelector(getState, (state) => state.hankoPreview);
export const getIsHankoEditing = createSelector(getDefaultSignatureStampId, (stampId) => !!stampId);
export const getIsHankoDisabled = createSelector(
  getUploadedImageUrl,
  getHankoFile,
  (uploadedImageUrl, hankoFile) => !(uploadedImageUrl || hankoFile),
);
