import { createContext } from 'react';
import { noop } from 'lodash';

import { HankoFileSignatureContextValue } from '../types';

export const HankoFileSignatureContext = createContext<HankoFileSignatureContextValue>({
  signatureId: null,
  setHankoFile: noop,
  removeHankoFile: noop,
});
