import React, { useMemo } from 'react';
import { Link } from '@theme-ui/components';
import { Link as RouterLink } from 'react-router-dom';

import i18n from 'src/i18n';
import { book } from 'src/app/book';
import { AccountType, InviteType } from 'src/models/signUp';
import { useGetPlanAndPeriod } from 'src/v2/features/signUp/hooks';
import { translationKeys } from 'src/common/translations';
import { useIsSignupFromInvite } from 'src/v2/features/signUp/pages/hooks';
import { SignUpForm } from 'src/v2/features/signUp/SignUpForm';

import { signUpPageTranslationMap } from './utils';

import './overrides.css';

export interface Props {
  accountType: AccountType;
  inviteType: InviteType | null;
}

export const SignUp: React.FC<Props> = ({ accountType = AccountType.Personal, inviteType }) => {
  const [isSignupFromInvite] = useIsSignupFromInvite();
  const [plan] = useGetPlanAndPeriod();

  const pageKeys = useMemo(
    () => ({
      ...signUpPageTranslationMap(accountType, plan),
      ...translationKeys.forms.signup.tips,
      defaultFeatures: translationKeys.forms.signup.defaultFeatures,
    }),
    [accountType, plan],
  );

  return (
    <>
      <div className="l-row">
        <div className="c-title c-title--color-theme c-title--fs-64">{i18n(pageKeys.title)}</div>
      </div>

      <div className="l-row">
        <div className="c-form">
          <div className="c-form__row">
            <SignUpForm accountType={accountType} inviteType={inviteType}>
              {!isSignupFromInvite && (
                <>
                  <div className="c-form__row">
                    <div className="c-form__column">
                      {i18n(pageKeys.alreadyHaveAnAccount)}{' '}
                      <Link
                        className="c-checkbox__link c-checkbox__link--white h-uppercase"
                        data-type="signupNow-link"
                        variant="inline"
                        as={RouterLink}
                        to={book.login}
                      >
                        {i18n(translationKeys.buttons.signIn)}
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </SignUpForm>
          </div>
        </div>
      </div>
    </>
  );
};
