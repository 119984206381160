import { FC, useCallback } from 'react';
import { WrappedFieldProps } from 'redux-form';

interface Props extends WrappedFieldProps {
  element?: any;
}

const stripeInputStyle = {
  base: {
    color: 'black',
    fontFamily: 'Arial, Helvetica, sans-serif',
    '::placeholder': {
      color: '#cfcfcf',
    },
  },
};

export const StripeInput: FC<Props> = ({ input: { onChange }, element }) => {
  const Component = element;
  const handleChange = useCallback((event: any) => onChange(event.complete), [onChange]);

  return <Component onChange={handleChange} options={{ style: stripeInputStyle }} />;
};
