import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { InviteType } from 'src/models/signUp';
import { setInviteType } from 'src/v2/features/signUp/store/signUpStore';

import { useComponentAndPropsLight } from './componentAndPropsLightHook';
import { LayoutLight } from '../components/LayoutLight';

export const WizardLightPage: FC = () => {
  const dispatch = useDispatch();
  const [render, layoutProps] = useComponentAndPropsLight();

  useEffect(() => {
    dispatch(setInviteType(InviteType.QRCode));
  }, [dispatch]);

  return <LayoutLight {...layoutProps}>{render()}</LayoutLight>;
};
