import { put, select, takeLatest } from 'redux-saga/effects';
import { createAction, PayloadAction } from '@reduxjs/toolkit';
import { getFormValues, getFormMeta, autofill } from 'redux-form';
import { get } from 'lodash';

interface AutofillEmptyPayload {
  formId: string;
  field: string;
  value: string;
}

export const autofillEmpty = createAction<AutofillEmptyPayload>('reduxForm/autofillEmpty');

function* autofillEmptySaga(action: PayloadAction<AutofillEmptyPayload>) {
  const { formId, field, value } = action.payload;
  const values = yield select(getFormValues(formId));
  const meta = yield select(getFormMeta(formId));
  const isEmpty = !values[field];
  const isAutofilled = get(meta, `${field}.autofilled`, false);

  if (isEmpty || isAutofilled) yield put(autofill(formId, field, value));
}

export function* watchCustomReduxFormSagas() {
  yield takeLatest(autofillEmpty, autofillEmptySaga);
}
