export const styles = {
  notificationContainer: {
    bg: '#f4f4f4',
  },
  container: {
    overflow: 'auto',
    height: 'calc(100vh - 170px)',
  },

  historyItem: {
    p: '21px 10px',
    mb: '24px',
    borderRadius: '14px',
    border: 'solid 2px',
    position: 'relative',
    backgroundColor: '#ffffff',

    '&::after': {
      content: '""',
      width: '0px',
      height: '24px',
      position: 'absolute',
      top: '100%',
      left: '50%',
      border: 'inherit',
      borderWidth: '1px',
      transform: 'translateX(-50%)',
    },

    '&:last-child': {
      '&::after': {
        display: 'none',
      },
    },

    img: {
      mr: '16px',
      width: '48px',
      height: '48px',
    },
  },

  flexItem: {
    alignItems: 'flex-start',

    div: {
      flex: '1',
    },
  },

  darkBlue: {
    borderColor: '#4d6982',
  },

  yellow: {
    borderColor: '#e4b62c',
  },

  green: {
    borderColor: '#29cb97',
  },

  historyItemText: {
    color: '#525252',
    fontSize: '16px',
    lineHeight: '1',
    letterSpacing: '0.1px',
  },

  historyItemLink: {
    cursor: 'pointer',
    textDecoration: 'underline !important',
  },

  historyItemTextUnread: {
    fontWeight: 'bold',
  },

  emptyText: {
    pt: '20px',
    fontSize: '18px',
    textAlign: 'center',
    color: '#29cb97',
  },
};
