import { UploadedEntityExtension } from 'src/v2/features/sharedEntity/types';

export enum MimeType {
  pdf = 'application/pdf',
  doc = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  png = 'image/png',
  jpeg = 'image/jpeg',
  jpg = 'image/jpeg',
  gif = 'image/gif',
  xls = 'application/vnd.ms-excel',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ppt = 'application/vnd.ms-powerpoint',
  pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
}

export const extensionByMimeType = {
  [MimeType.pdf]: UploadedEntityExtension.pdf,
  [MimeType.doc]: UploadedEntityExtension.doc,
  [MimeType.docx]: UploadedEntityExtension.docx,
  [MimeType.png]: UploadedEntityExtension.png,
  [MimeType.jpeg]: UploadedEntityExtension.jpeg,
  [MimeType.jpg]: UploadedEntityExtension.jpg,
  [MimeType.gif]: UploadedEntityExtension.gif,
  [MimeType.xls]: UploadedEntityExtension.xls,
  [MimeType.xlsx]: UploadedEntityExtension.xlsx,
  [MimeType.ppt]: UploadedEntityExtension.ppt,
  [MimeType.pptx]: UploadedEntityExtension.pptx,
};
