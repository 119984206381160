import React, { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { isUndefined } from 'lodash';

import { book } from 'src/app/book';
import { translationKeys } from 'src/common/translations';
import { useNormalizedEntityId } from 'src/v2/features/sharedEntity';
import { ProjectCard } from 'src/v2/components/ProjectCard';
import {
  useNavigateToProjectRemoveFactory,
  useNavigateToProjectViewFactory,
  useNavigateToProjectCreate,
} from 'src/v2/features/project/ProjectSidebar/nav-hooks';
import {
  getProjectByIdFn,
  getProjectsList,
  getRootProjectsFolderId,
} from 'src/v2/features/project/store/projectSelectors';
import { getSortDirection, getSortField } from 'src/common/sortStore';
import { useDefaultSortEffct } from 'src/common/hooks/useDefaultSort';
import { SortDirection, SortedFields } from 'src/models/sort';
import { useGetCurrentFolderId } from 'src/v2/features/quantumNavigator/hooks';
import { subscribeCreateProject } from 'src/v2/features/project/projectEventBus';
import { useCanCreateProject } from 'src/v2/features/project/permissions/projectPermissionsHooks';

import { DynamicSettingsContextProviderForList } from './providers/DynamicSettingsContextProviderForList';
import { SidebarNodeContextProvider } from './providers/SidebarNodeContextProvider';
import { ProjectGrid } from './components/ProjectGrid';
import { ProjectLayout } from './ProjectLayout';
import { useCreateOpenProjectHandler } from './hooks';
import { getIsProjectHasSignedContracts, getOwnerName, useFetchProjectsListEffct } from './utils';
import { getIsLoading, getError } from './store/projectSelectors';
import { useHasNavigationFeature } from '../billing';

export const useFetchProjectsList = (
  sortDirection: SortDirection = SortDirection.Desc,
  sortedField: SortedFields = SortedFields.Title,
  rootFolderId?: string,
): void => {
  const folderId = useGetCurrentFolderId() || rootFolderId;

  useFetchProjectsListEffct(folderId, sortDirection, sortedField);
};

export const ProjectListPage: FC = () => {
  const projectsList = useSelector(getProjectsList);
  const selectedCardId = useNormalizedEntityId();
  const isLoading = useSelector(getIsLoading);
  const error = useSelector(getError);

  const createOpenProjectHandler = useCreateOpenProjectHandler();
  const navigateToProjectCreate = useNavigateToProjectCreate();
  const isCanCreateProject = useCanCreateProject();
  const handleShowProjectCreate = isCanCreateProject ? navigateToProjectCreate : undefined;
  const createShowProjectViewHandler = useNavigateToProjectViewFactory();
  const createShowProjectRemoveHandler = useNavigateToProjectRemoveFactory();

  const projectRootFolderId = useSelector(getRootProjectsFolderId);
  const sortDirection = useSelector(getSortDirection);
  const sortField = useSelector(getSortField);
  const createOpenProject = useCreateOpenProjectHandler();

  useFetchProjectsList(sortDirection, sortField, projectRootFolderId);
  useDefaultSortEffct();

  useEffect(() => {
    const subscribe$ = subscribeCreateProject((eventData) => {
      const openProject = createOpenProject(eventData.payload.id);
      openProject();
    });

    return () => subscribe$.unsubscribe();
  }, [createOpenProject]);

  const getProjectById = useSelector(getProjectByIdFn);

  const getIsRemovable = useCallback(
    (id: string) => {
      const project = getProjectById(id);
      return !getIsProjectHasSignedContracts(project);
    },
    [getProjectById],
  );

  const hasNavigationFeature = useHasNavigationFeature();

  const pricingLink = (
    <Link className="c-letter__link" to={book.profile.pricingAndBilling.generatePath()} />
  );

  const showAbilityText = hasNavigationFeature && (
    <Trans
      i18nKey={translationKeys.project.ability}
      components={{
        pricingLink,
      }}
    />
  );

  return (
    <SidebarNodeContextProvider>
      <DynamicSettingsContextProviderForList>
        <ProjectLayout isLoading={isLoading} error={error}>
          {!isUndefined(handleShowProjectCreate) ? (
            <ProjectGrid onAdd={handleShowProjectCreate}>
              {projectsList.map((card) => (
                <ProjectCard
                  key={card.id}
                  id={card.id}
                  title={card.title}
                  description={card.description}
                  owner={getOwnerName(card.ownerParticipant)}
                  onClick={createShowProjectViewHandler({ id: card.id })}
                  onDelete={createShowProjectRemoveHandler({ id: card.id })}
                  onTouch={createOpenProjectHandler(card.id)}
                  onDoubleClick={createOpenProjectHandler(card.id)}
                  selected={selectedCardId === card.id}
                  isRemovable={getIsRemovable(card.id)}
                />
              ))}
            </ProjectGrid>
          ) : (
            showAbilityText
          )}
        </ProjectLayout>
      </DynamicSettingsContextProviderForList>
    </SidebarNodeContextProvider>
  );
};
