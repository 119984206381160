import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSignatureId } from 'src/v2/features/signature/store';

import { HankoFileSignatureContext } from '../contexts/HankoFileSignatureContext';
import {
  setHankoFile as setHankoFileAction,
  removeHankoFile as removeHankoFileAction,
} from '../store';

export const HankoFileSignatureContextProvider: FC = ({ children }) => {
  const dispatch = useDispatch();

  const signatureId = useSelector(getSignatureId);

  const setHankoFile = useCallback((data) => dispatch(setHankoFileAction(data)), [dispatch]);

  const removeHankoFile = useCallback(() => dispatch(removeHankoFileAction()), [dispatch]);

  const ctx = {
    signatureId,
    setHankoFile,
    removeHankoFile,
  };

  return (
    <HankoFileSignatureContext.Provider value={ctx}>{children}</HankoFileSignatureContext.Provider>
  );
};
