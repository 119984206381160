import React from 'react';

import { SidebarFactoryComponent } from 'src/v2/features/sidebarController/types';
import { useNormalizeURLHashValues } from 'src/v2/features/sharedEntity/EntitySidebar/EntitySidebar';
import { useOpenMobileSidebarOnViewChangeEffct } from 'src/v2/features/sidebarController/hooks';

import { book } from './book';

interface Props {
  sidebarComponent: SidebarFactoryComponent;
  sidebarName: string;
  isHidden: boolean;
}

export const SidebarWrapper: React.FC<Props> = ({
  sidebarComponent: SidebarComponent,
  isHidden,
}) => {
  const criteria = useNormalizeURLHashValues(book.viewProject);

  useOpenMobileSidebarOnViewChangeEffct(criteria.v as string, [
    book.viewProject,
    book.viewDocument,
    book.viewContract,
  ]);

  if (isHidden) return null;

  return <SidebarComponent criteria={criteria} />;
};
