import React, { useRef, useState, ChangeEvent, useEffect, useContext, useCallback } from 'react';
import QRCodeStyling from 'qr-code-styling';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Box } from '@theme-ui/components';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { CopyNew } from 'src/v2/icons/CopyNew';
import { updateDocumentAccess } from 'src/v2/features/documentList/documentListSlice';
import { Radio } from 'src/v2/components/Radio';
import { getAccessLevel } from 'src/v2/features/documentDetails/documentDetailsSlice';
import { AccessLevel } from 'src/models/paper';
import { useGetEntityById } from 'src/v2/features/sharedEntity';
import { SharedSidebarContext } from 'src/v2/features/sharedEntity/EntitySidebar/SharedSidebarContext';

import { book } from '../sharedEntity/EntitySidebar/book';
import options from './qrCodeOptions.json';
import { useNavigateToQrShare } from '../sharedEntity/EntitySidebar/hooks/nav-hooks';

interface Props {
  id: string;
}

const styles = {
  canvasContainer: {
    'div canvas': {
      width: '100%!important',
      height: 'auto!important',
      maxWidth: '300px',
    },
  },
};

// @ts-ignore
const qrCode = new QRCodeStyling(options);

const ANALYTICS_TRACKING = '?utm_source=shelterzoom&utm_medium=qr-code';

export const QRCode: React.FC<Props> = ({ id }) => {
  const ref = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const accessLvl = useSelector(getAccessLevel) || AccessLevel.Private;
  const entity = useGetEntityById(id);
  const { isAllowedToEditAccess } = useContext(SharedSidebarContext);

  const [copied, setCopied] = useState(false);
  const [isAccessChanged, setAccessChanging] = useState(false);
  const [accessLevel, setAccessLevel] = useState(accessLvl || AccessLevel.Private);
  const handleShare = useNavigateToQrShare(id);

  let pathname = window.location.pathname.split('/')[1];
  const baseUrl = `${window.location.protocol}//${window.location.host}/${book.qr}/${pathname}/${id}${ANALYTICS_TRACKING}`;

  useEffect(() => {
    setAccessLevel(accessLvl);
  }, [accessLvl]);

  useEffect(() => {
    qrCode.update({
      data: baseUrl,
    });
  }, [baseUrl]);

  const downloadQRCode = () => {
    if (isAccessChanged) {
      onSubmit();
    }
    qrCode.download({
      name: `${entity?.title}-QR`,
      extension: 'png',
    });
    setAccessLevel(accessLevel);
    toastr.success('Success', 'Your QR Code has been downloaded');
  };

  const onShare = () => {
    onSubmit();
    handleShare();
  };

  const copyToClipboard = () => {
    setCopied(true);
    ref.current?.select();
    document.execCommand('copy');
  };

  const onChangeAccess = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      if (!entity) return;
      setAccessLevel(event.target.value as AccessLevel);
      setAccessChanging(!isAccessChanged);
    },
    [entity, isAccessChanged],
  );

  const onSubmit = useCallback(() => {
    if (!entity) return;

    if (isAccessChanged) {
      dispatch(
        updateDocumentAccess({
          id: get(entity, 'id'),
          accessLevel,
        }),
      );
    }

    setAccessLevel(accessLevel);
    setAccessChanging(false);
  }, [dispatch, accessLevel, entity, isAccessChanged]);

  return entity ? (
    <div className="c-qr">
      <div className="c-qr__row">
        <Box className="c-qr__code" sx={styles.canvasContainer}>
          <div
            ref={(ref) => {
              ref && qrCode.append(ref);
            }}
          />
        </Box>
      </div>
      <div className="c-qr__row">
        <div className="c-input c-input--validation">
          {copied ? (
            <div className="c-input__message c-input__message--success">
              {i18n(translationKeys.errors.COPIED)}
            </div>
          ) : null}
          <label className="c-input__label">
            <span
              onClick={copyToClipboard}
              className="c-input__icon c-input__icon--left c-input__icon--copy"
            >
              <CopyNew />
            </span>
            <input type="text" ref={ref} readOnly value={baseUrl} />
          </label>
        </div>
      </div>
      {isAllowedToEditAccess && (
        <>
          <div className="c-qr__row">
            <div className="c-title c-title--lh-1">MAKE THIS DOCUMENT</div>
          </div>
          <div className="c-qr__row">
            <Radio
              label="Private Access"
              name={AccessLevel.Private}
              value={AccessLevel.Private}
              checked={accessLevel === AccessLevel.Private}
              onChange={onChangeAccess}
            />
            <Radio
              label="Public Access"
              name={AccessLevel.Public}
              value={AccessLevel.Public}
              checked={accessLevel === AccessLevel.Public}
              onChange={onChangeAccess}
            />
          </div>
          <div className="c-qr__row">
            <button
              className="c-button c-button--w-full"
              data-type="share-submit"
              type="submit"
              onClick={onShare}
            >
              <span className="c-button__inner">{i18n(translationKeys.buttons.share)}</span>
            </button>
          </div>
        </>
      )}
      <div className="c-qr__row">
        <button
          className="c-button c-button--green c-button--w-full"
          type="button"
          onClick={downloadQRCode}
        >
          <span className="c-button__inner">{i18n(translationKeys.buttons.download)}</span>
        </button>
      </div>
    </div>
  ) : null;
};
