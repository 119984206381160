import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { ContractRole, ContractType } from 'src/models/contract';
import { WorkflowType } from 'src/v2/entities/workflow';
import { PDFToolbarItems } from 'src/v2/components/PDFContainer/types';
import {
  getTitle,
  isCounterpartySigned as getIsCounterpartySigned,
  isOwnerSigned as getIsOwnerSigned,
} from 'src/v2/features/documentDetails/documentDetailsSlice';
import { getWorkflowType, isInWork as getIsInWork } from 'src/v2/features/documentWorkflow';
import { useGetEntityById, useGetEntityFieldsDiff } from 'src/v2/features/sharedEntity/hooks';
import {
  useCanEditContract,
  useCanSignContract,
} from 'src/v2/features/contract/permissions/contractPermissions/contractPermissionsHooks';
import { EntityTitle } from 'src/v2/components/Title/Title';
import { EntityBody } from 'src/v2/features/sharedEntity/EntityBody';
import { EntityAcknowledges } from 'src/v2/features/sharedEntity/components/EntityAcknowledges/EntityAcknowledges';
import { EntitySignatures } from 'src/v2/features/sharedEntity/components/EntitySignatures/EntitySignatures';
import { Breadcrumb } from 'src/v2/features/breadcrumb';
import { useNavigateToContractFolder } from 'src/v2/features/quantumNavigator/hooks';
import { isNull } from 'lodash';
import { downloadAsFile } from '../sharedEntity/actions';
import { MimeType } from 'src/common/types';

interface Props {
  id: string;
}

const allowedToolbarSigneOptions = [
  PDFToolbarItems.Text,
  PDFToolbarItems.Print,
  PDFToolbarItems.Annotate,
  PDFToolbarItems.Pan,
  PDFToolbarItems.ZoomIn,
  PDFToolbarItems.ZoomOut,
  PDFToolbarItems.ZoomMode,
  PDFToolbarItems.ZoomMarquee,
  PDFToolbarItems.Search,
  PDFToolbarItems.Export,
  PDFToolbarItems.Pager,
  PDFToolbarItems.SidebarThumbnails,
  PDFToolbarItems.DocumentOutLine,
  PDFToolbarItems.Bookmarks,
  PDFToolbarItems.SidebarAnnotations,
  PDFToolbarItems.InkSignature,
  PDFToolbarItems.Signature,
];

export const ContractContent: React.FC<Props> = ({ id }) => {
  const dispatch = useDispatch();
  const title = useSelector(getTitle) || i18n(translationKeys.contract.noTitle);
  const contract = useGetEntityById<ContractType, ContractRole>(id);
  const isAllowedToEdit = useCanEditContract(contract);
  const isAllowedToSign = useCanSignContract(contract);
  const workflowType = useSelector(getWorkflowType);
  const isNegotiable = workflowType === WorkflowType.Negotiation;
  const isOwnerSigned = useSelector(getIsOwnerSigned);
  const isCounterpartySigned = useSelector(getIsCounterpartySigned);
  const isSignedByBothSides = isOwnerSigned && isCounterpartySigned;
  const isInWork = useSelector(getIsInWork);
  const isContractNegotiable = isNegotiable && !isSignedByBothSides && isAllowedToEdit && isInWork;
  const { ownerFieldsDiff, counterpartyFieldsDiff } = useGetEntityFieldsDiff();
  const handleOpenFolder = useNavigateToContractFolder();
  const enabledToolbarItems =
    !isAllowedToEdit && isAllowedToSign ? allowedToolbarSigneOptions : undefined;

  const handleExportPdf = useCallback(() => {
    if (!isNull(contract)) {
      dispatch(
        downloadAsFile({
          id,
          name: contract.title,
          extension: MimeType.pdf,
        }),
      );
    }
  }, [dispatch, contract, id]);

  return (
    <>
      <Breadcrumb currentPageName={title} onFolderClick={handleOpenFolder} />
      <EntityTitle title={title} />
      <EntityBody
        id={id}
        isNegotiable={isContractNegotiable}
        isAllowedToEdit={isAllowedToEdit}
        isAllowedToSign={isAllowedToSign}
        disableAfterSigned={true}
        enabledToolbarItems={enabledToolbarItems}
        onExportPdf={handleExportPdf}
      />
      <EntityAcknowledges />
      <EntitySignatures
        ownerFieldsDiff={ownerFieldsDiff}
        counterpartyFieldsDiff={counterpartyFieldsDiff}
      />
    </>
  );
};
