import { includes } from 'lodash';

import { SystemRole } from 'src/models/user';

export const canEditOrganizationInfo = (userSystemRole: SystemRole | null): boolean =>
  SystemRole.Owner === userSystemRole || SystemRole.Admin === userSystemRole;

export const canAddOrganizationUser = (
  currentUserRole: SystemRole | null,
  userSystemRole: SystemRole | null,
): boolean =>
  (SystemRole.Owner !== userSystemRole && SystemRole.Owner === currentUserRole) ||
  (SystemRole.Admin === currentUserRole &&
    includes([SystemRole.Manager, SystemRole.User], userSystemRole));

export const canEditOrganizationUser = (
  currentUserRole: SystemRole | null,
  userSystemRole: SystemRole | null,
): boolean =>
  (SystemRole.Owner !== userSystemRole && SystemRole.Owner === currentUserRole) ||
  (SystemRole.Admin === currentUserRole &&
    includes([SystemRole.Manager, SystemRole.User], userSystemRole));

export const canRemoveOrganizationUser = (
  currentUserRole: SystemRole | null,
  userSystemRole: SystemRole | null,
): boolean =>
  (SystemRole.Owner !== userSystemRole && SystemRole.Owner === currentUserRole) ||
  (SystemRole.Admin === currentUserRole &&
    includes([SystemRole.Manager, SystemRole.User], userSystemRole));
