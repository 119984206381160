import { ParsedQuery } from 'query-string';

import { SystemRole } from 'src/models/user';
import {
  useViewLinkFactory,
  useViewLinkCallbackFactory,
  useWithHistoryCallbackFactory,
} from 'src/v2/features/sidebarController';
import { getSystemRoles } from 'src/v2/features/profile/components/ManageOrgUsers/User';
import { canAddOrganizationUser } from 'src/utils/permissions';

import { book } from './book';

export const useShowUserCreate = (
  params?: ParsedQuery<string>,
): [ReturnType<typeof useViewLinkFactory>, ReturnType<typeof useViewLinkCallbackFactory>] => [
  useViewLinkFactory(book.createUser, params),
  useViewLinkCallbackFactory(book.createUser),
];

export const useShowUserEdit = (
  params?: ParsedQuery<string>,
): [ReturnType<typeof useViewLinkFactory>, ReturnType<typeof useViewLinkCallbackFactory>] => [
  useViewLinkFactory(book.editUser, params),
  useViewLinkCallbackFactory(book.editUser),
];

export const useShowUserDelete = (
  params?: ParsedQuery<string>,
): [ReturnType<typeof useViewLinkFactory>, ReturnType<typeof useViewLinkCallbackFactory>] => [
  useViewLinkFactory(book.deleteUser, params),
  useViewLinkCallbackFactory(book.deleteUser),
];

export const useUserCallbackFactory = (userId: string): [() => void, () => void] => {
  const [, editCallbackFactory] = useShowUserEdit();
  const editUserCallbackFactory = useWithHistoryCallbackFactory(editCallbackFactory);
  const editUserCallback = editUserCallbackFactory({ id: userId });

  const [, deleteCallbackFactory] = useShowUserDelete();
  const deleteUserCallbackFactory = useWithHistoryCallbackFactory(deleteCallbackFactory);
  const deleteUserCallback = deleteUserCallbackFactory({ id: userId });

  return [editUserCallback, deleteUserCallback];
};

const getPermissions = () => {
  const systemRoles = getSystemRoles();

  return [
    { value: SystemRole.User, label: systemRoles[SystemRole.User] },
    { value: SystemRole.Manager, label: systemRoles[SystemRole.Manager] },
    { value: SystemRole.Admin, label: systemRoles[SystemRole.Admin] },
  ];
};

export const useGetAllowedPermissionsForAddingUsers = (currentUserRole: SystemRole | null) => {
  const permissions = getPermissions();

  return permissions.filter((permission) =>
    canAddOrganizationUser(currentUserRole, permission.value),
  );
};
