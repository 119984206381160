import { SagaIterator } from 'redux-saga';
import { SignUpApiPayload } from 'src/shared/auth/types';
import { apiClient } from 'src/common/client';

import { ObjectSerialized } from 'src/common/types';
import { WizardStepV2, RegistrationType } from 'src/models/signUp';
import { OrganizationInviteAttributesDTO } from 'src/v2/boundary/responseDTO/invite';

interface NotificationAttribute {
  receiver_id: string;
  type: string;
  active: boolean;
}

export interface SaveNotificationRequest {
  data: {
    type: string;
    attributes: NotificationAttribute[];
  };
}

export interface CurrentStepResponse {
  result: {
    registrationStep?: number | null;
    isBusiness?: boolean;
  };
}

export interface SignUpResponse {
  data: SignUpResponseData[];
  included: SignUpResponseIncluded[];
}

export interface Registration {
  complete: boolean;
  currentStep: string;
  nextStepNum: number;
  nextStep: WizardStepV2;
  prevStep: string;
  stepCount: number;
  type: string;
}

export interface SignUpResponseData {
  loginIsAvailable: boolean;
  registration: Registration[];
}

export interface SignUpResponseIncluded {
  id: string;
  type: string;
  attributes: {
    email: string;
    emailVerified: boolean;
    phone: string;
    phoneVerified: boolean;
    token: string;
  };
  relationships: any;
}

export function fetchSignupInitialInfo(registrationType: RegistrationType): SagaIterator {
  return apiClient.get(`/registration/info/${registrationType}`);
}

export function signup(
  payload: SignUpApiPayload,
  captureToken?: string,
): SagaIterator<SignUpResponse> {
  return apiClient.post(
    '/registration/start',
    {
      data: {
        attributes: {
          ...payload.data.attributes,
        },
      },
    },
    {
      headers: { Capture: captureToken },
    },
  );
}

export function continueRegistration(payload: SignUpApiPayload): SagaIterator<SignUpResponse> {
  return apiClient.post('/registration/continue', payload);
}

export function fetchNotificationsSettings(): SagaIterator<ObjectSerialized | null> {
  return apiClient.get(`/receiverSettings`);
}

export function updateNotificationSetting(data: SaveNotificationRequest): SagaIterator<{}> {
  return apiClient.put(`/receiverSettings`, data);
}

export function fetchUserInfoByToken(token: string): SagaIterator<OrganizationInviteAttributesDTO> {
  return apiClient.get(`/invite/${token}`, {
    headers: {},
  });
}
