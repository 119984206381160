import React from 'react';

interface Props {
  handleAcknowledge: () => void;
}

export const EntityAcknowledgeButton: React.FC<Props> = ({ handleAcknowledge }) => (
  <button
    type="submit"
    className="c-button c-button--uppercase c-button--green"
    onClick={handleAcknowledge}
  >
    <span className="c-button__inner">Acknowledge</span>
  </button>
);
