import React, { FC } from 'react';
import { Field } from 'redux-form';
import { required } from 'redux-form-validators';

import { Button, Type } from 'src/v2/features/button';
import { Divider } from 'src/v2/components/Divider';
import { Input, Textarea } from 'src/v2/features/reduxForm';

export interface Props {
  label: string;
  isLoading: boolean;
  isPristine?: boolean;
  onSubmit: () => void;
}

export const ProjectMetadataForm: FC<Props> = ({ label, isLoading, isPristine, onSubmit }) => (
  <div className="c-fieldset">
    <div className="c-title c-title--uppercase c-title--lh-1">Title</div>
    <Field validate={[required()]} component={Input} name="title" />
    <Divider />
    <div className="c-title c-title--uppercase c-title--lh-1">Description</div>
    <Field component={Textarea} name="description" />
    <Divider />
    <Button
      data-type="metadata-submit"
      type="submit"
      actionType={Type.Secondary}
      onClick={onSubmit}
      disabled={isLoading || isPristine}
      isLoading={isLoading}
    >
      {label}
    </Button>
  </div>
);
