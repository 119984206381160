import { FormDocumentCreatePayload } from 'src/v2/features/document/components/DocumentMetadata/types';
import { CreateDocumentDTO } from 'src/v2/boundary/requestDTO/document';
import { EntityType } from 'src/models/paper';

export const createConvertFormPayloadToDTO: (
  folderId: string,
) => (payload: FormDocumentCreatePayload) => CreateDocumentDTO = (folderId) => (payload) => {
  return {
    data: {
      ...payload,
      type: EntityType.document,
    },
    folderId,
  };
};
