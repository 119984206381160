import { head, isUndefined } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { getObjects as getObjectsFromStore } from 'src/v2/features/objectsStorage/objectsStorageSlice';
import { getObjects, getFolders, getCardPapers } from 'src/v2/features/objectsStorage/selectors';
import { DbRecordType } from 'src/v2/features/objectsStorage/types';
import { FolderWithEntities } from './types';

export const getFolderIds = createSelector(getFolders, (folders) =>
  folders
    ? Object.keys(folders).map((id) => ({
        id,
        type: DbRecordType.Folder,
      }))
    : [],
);

export const getFoldersWithDocuments = createSelector(
  getFolderIds,
  getFolders,
  getObjects,
  getCardPapers,
  (folderIds, folders, objects, cardPapers) => {
    const obj = getObjectsFromStore<FolderWithEntities>(folderIds, {
      [DbRecordType.Folder]: folders,
      [DbRecordType.Object]: objects,
      [DbRecordType.CardPaper]: cardPapers,
    });

    return obj;
  },
);

export const createGetFolderById = createSelector(
  getFoldersWithDocuments,
  (folders) => (folderId?: string) => {
    if (isUndefined(folderId)) return undefined;

    return folders.find(({ id }) => id === folderId);
  },
);

export const createGetObjectIdByEntityAndFolderId = createSelector(
  createGetFolderById,
  (getFolderById) => (entityId: string | undefined, folderId: string | undefined) => {
    const folder = getFolderById(folderId);

    if (isUndefined(folder)) return null;

    const object = folder.object.find((object) => {
      const item = head(object.cardPaper);
      if (!item) return false;

      return item.id === entityId;
    });

    if (isUndefined(object)) return null;

    return object.id;
  },
);
