import { PayloadAction } from '@reduxjs/toolkit';
import { put, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { startSubmit as startFormSubmit, stopSubmit as stopFormSubmit } from 'redux-form';
import { head, size } from 'lodash';

import { login, LoginResponse } from 'src/api/authApi';
import { getOrganizationV2 } from 'src/api/organizationApi';
import { handleErrorSaga } from 'src/utils/handleErrorSaga';
import { ObjectSerialized } from 'src/common/types';
import { Credentials } from 'src/shared/auth/types';

import { setAuthToken, emitError, startLoading, stopLoading, setCompanyId } from '..';
import { setJWTCookie } from './cookiesConfiguration';

export function* fetchOrganizationId(): SagaIterator<string | undefined | null> {
  const response = (yield call(getOrganizationV2)) as ObjectSerialized;
  const organizations = ObjectSerialized.dataToBaseObjects(response.data);
  if (size(organizations) > 0) {
    return head(organizations)!.id;
  }

  return null;
}

export function* fetchCompanyDataSaga() {
  // @ts-ignore
  const companyId = yield call(fetchOrganizationId);
  if (companyId) {
    yield put(setCompanyId(companyId));
  }
}

export function* loginSaga(action: PayloadAction<Credentials>): SagaIterator {
  try {
    yield put(startFormSubmit('login'));

    const loginData = (yield call(login, action.payload)) as LoginResponse;
    yield put(startLoading());
    yield put(setAuthToken(loginData.token));
    setJWTCookie(loginData.token);
    yield put(stopLoading());
  } catch (err) {
    yield put(stopLoading());
    yield put(emitError(err.toString()));
    yield call(handleErrorSaga, err);
  } finally {
    yield put(stopFormSubmit('login'));
  }
}
