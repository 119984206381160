import React, { FC } from 'react';
import { isNull } from 'lodash';

import {
  useGetCurrentFolderId,
  useGetFoldersTreeById,
} from 'src/v2/features/quantumNavigator/hooks';
import { isSharedContractFolder } from 'src/v2/features/quantumNavigator/utils';

import { ContractSharedListPage } from './ContractSharedListPage';
import { ContractStandardListPage } from './ContractStandardListPage';

export const ContractListPage: FC = () => {
  const folderId = useGetCurrentFolderId();
  const current = useGetFoldersTreeById(folderId);
  const isSharedFolder = isNull(current) ? false : isSharedContractFolder(current.data);

  return isSharedFolder ? <ContractSharedListPage /> : <ContractStandardListPage />;
};
