import { generatePath } from 'react-router';

import { env } from 'src/env';
import { EntityType } from 'src/models/paper';
import { AccountType, RedirectEntityType } from 'src/models/signUp';
import { SubscriptionPlan } from 'src/models/billing';

export const defaultEntityUrlState = '?sortBy=createdAt&sortDirection=desc';

export const createNewSignatureQueryKey = 'create_new';

export const book = Object.freeze({
  home: '/',
  login: '/login',
  logout: '/logout',
  qrInvite: '/qr',
  signup: {
    pattern: `/signup/:account/:plan`,
    qrPattern: `/signup/from_qr?documentType=:documentType&documentId=:id`,
    generatePathWithQR(entityType: RedirectEntityType, entityId: string): string {
      return generatePath(book.signup.qrPattern, {
        documentType: entityType,
        id: entityId,
      });
    },
    generatePath(account: AccountType, plan: SubscriptionPlan): string {
      return generatePath(book.signup.pattern, {
        plan,
        account,
      });
    },
    generateDefaultPath(): string {
      return book.signup.generatePath(AccountType.Personal, SubscriptionPlan.Standard);
    },
    generateRedirectPath(
      entityType: RedirectEntityType,
      entityId: string,
      isFromQR = false,
    ): string {
      switch (entityType) {
        case RedirectEntityType.Document:
          return isFromQR
            ? book.qr.document.generatePath(entityId)
            : book.document.generatePath(entityId);
        case RedirectEntityType.Contract:
          return isFromQR
            ? book.qr.contract.generatePath(entityId)
            : book.contract.generatePath(entityId);
        case RedirectEntityType.Template:
          return book.template.generatePath(entityId);
      }
    },
  },
  signupFromInvite: '/signup/from_token',
  signupFromQR: '/signup/from_qr',
  profile: {
    personal: {
      pattern: '/profile/personal',
      generatePath(): string {
        return `${book.profile.personal.pattern}`;
      },
    },
    signature: '/profile/signature',
    createNewSignature: `/profile/signature?${createNewSignatureQueryKey}`,
    security: '/profile/security',
    organization: {
      pattern: '/profile/organization',
      generatePath(): string {
        return `${book.profile.organization.pattern}`;
      },
    },
    users: {
      pattern: '/profile/users',
      generatePath(): string {
        return `${book.profile.users.pattern}`;
      },
    },
    notifications: {
      pattern: '/profile/notifications',
      generatePath(): string {
        return `${book.profile.notifications.pattern}`;
      },
    },
    pricingAndBilling: {
      pattern: '/profile/pricing-and-billing',
      generatePath(): string {
        return `${book.profile.pricingAndBilling.pattern}`;
      },
    },
  },
  documents: {
    pattern: '/documents',
    generatePath(folderId?: string): string {
      const path = `${book.documents.pattern}${defaultEntityUrlState}`;
      return folderId ? `${path}&folderId=${folderId}` : path;
    },
  },
  document: {
    pattern: '/documents/:id',
    generatePath(id: string, folderId?: string): string {
      const path = generatePath(book.document.pattern, { id });
      return folderId ? `${path}?folderId=${folderId}` : path;
    },
  },
  contracts: {
    pattern: '/contracts',
    generatePath(folderId?: string): string {
      const path = `${book.contracts.pattern}${defaultEntityUrlState}`;
      return folderId ? `${path}&folderId=${folderId}` : path;
    },
  },
  contract: {
    pattern: '/contracts/:id',
    generatePath(id: string, folderId?: string): string {
      const path = generatePath(book.contract.pattern, { id });
      return folderId ? `${path}?folderId=${folderId}` : path;
    },
  },
  template: {
    pattern: '/templates/:id/edit',
    generatePath(id: string, folderId?: string): string {
      const path = generatePath(book.template.pattern, { id });
      return folderId ? `${path}?folderId=${folderId}` : path;
    },
  },
  templates: {
    pattern: '/templates',
    generatePath(folderId?: string): string {
      const path = `${book.templates.pattern}${defaultEntityUrlState}`;
      return folderId ? `${path}&folderId=${folderId}` : path;
    },
  },

  projects: {
    pattern: '/projects',
    generatePath(): string {
      return `${book.projects.pattern}${defaultEntityUrlState}`;
    },
  },

  project: {
    pattern: '/projects/:id',
    generatePath(id: string): string {
      return generatePath(book.project.pattern, {
        id,
      });
    },
  },

  search: {
    preview: '/search',
    all: '/search/all',
  },

  checkout: '/checkout',
  qr: {
    document: {
      pattern: '/qr/documents/:id',
      generatePath(id: string, folderId?: string): string {
        const path = generatePath(book.qr.document.pattern, { id });
        return folderId ? `${path}?folderId=${folderId}` : path;
      },
    },
    contract: {
      pattern: '/qr/contracts/:id',
      generatePath(id: string, folderId?: string): string {
        const path = generatePath(book.qr.contract.pattern, { id });
        return folderId ? `${path}?folderId=${folderId}` : path;
      },
    },
  },
  retrySubscription: '/retry-subscription',
  upgradeSubscription: '/upgrade-subscription',

  tinyCabinTerms: '/tiny-cabin-terms',
  termsAndConditions: `${env.WEBSITE_HOST_URI}/legal-terms-and-conditions.html`,
});

export const documentsOrContractsLinkFactory = (entityType: EntityType): string =>
  entityType === EntityType.document
    ? book.documents.generatePath()
    : book.contracts.generatePath();
