import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useLocation } from 'react-router';

import { LoginPage } from 'src/v2/features/auth/LoginPage';
import { LoginPageLight } from 'src/v2/features/auth/LoginPageLight';
import { WizardPage } from 'src/v2/features/signUp';
import { WizardLightPage } from 'src/v2/features/signUp/pages/WizardLightPage';
import { TinyCabinTermsPage } from 'src/v2/features/landing/TinyCabinTermsPage';

import { book } from './book';

export const Public: React.FC = () => {
  const { pathname } = useLocation();
  const pathIsNotEmpty = pathname !== '' && pathname !== '/';
  const pathIsNotExcluded = pathname !== book.profile.security;
  const returnUrlParam = pathIsNotEmpty && pathIsNotExcluded ? `?returnUrl=${pathname}` : '';

  return (
    <Routes>
      <Route path={book.tinyCabinTerms} element={<TinyCabinTermsPage />} />
      <Route path={book.signup.pattern} element={<WizardPage />} />
      <Route path={book.signupFromInvite} element={<WizardPage />} />
      <Route path={`${book.qrInvite}/*`} element={<LoginPageLight />} />
      <Route path={book.signupFromQR} element={<WizardLightPage />} />
      <Route path={book.login} element={<LoginPage />} />
      <Route path={book.logout} element={<Navigate replace to={book.login} />} />
      <Route path="*" element={<Navigate replace to={`${book.login}${returnUrlParam}`} />} />
    </Routes>
  );
};
