import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { HankoSignatureState } from 'src/app/types';
import { defaultActionsFactory } from 'src/utils/defaultSlice';

export const saveHanko = createAction('hankoSignature/saveHanko');
export const editHankoSignature = createAction<string>('hankoSignature/editHankoSignature');
export const saveHankoSignature = createAction('hankoSignature/saveHankoSignature');
export const setDefaultSignatureStamp = createAction<string>('profile/setDefaultSignatureStamp');

const initialState: HankoSignatureState = {
  error: '',
  isLoading: false,
  localisedSignature: false,
  includeHanko: false,
  hankoFile: null,
  hankoPreview: null,
  uploadedImageUrl: null,
};

const { onStart, onError, onSuccess } = defaultActionsFactory();

const hankoSignatureSlice = createSlice({
  name: 'hankoSignature',
  initialState,
  reducers: {
    startLoading: onStart,

    stopLoading: onError,

    finishLoading: onSuccess,

    createNewHanko(state: HankoSignatureState): void {
      state.localisedSignature = false;
      state.includeHanko = false;
      state.hankoFile = null;
      state.hankoPreview = null;
      state.uploadedImageUrl = null;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    editHanko(state: HankoSignatureState, action: PayloadAction<string>): void {
      state.hankoFile = null;
      state.hankoPreview = null;
    },

    setLocalisedSignature: (state: HankoSignatureState, action: PayloadAction<boolean>) => {
      state.localisedSignature = action.payload;

      if (!state.localisedSignature) {
        state.includeHanko = false;
      }
    },

    setIncludeHanko: (state: HankoSignatureState, action: PayloadAction<boolean>) => {
      state.includeHanko = action.payload;
    },

    setUploadedImageUrl(state: HankoSignatureState, action: PayloadAction<string | null>): void {
      state.uploadedImageUrl = action.payload;
    },

    setHankoFile(state: HankoSignatureState, action: PayloadAction<File>): void {
      state.hankoFile = action.payload;
      state.localisedSignature = true;
      state.includeHanko = true;
    },

    removeHankoFile(state: HankoSignatureState): void {
      state.hankoFile = null;
      state.hankoPreview = null;
      state.includeHanko = false;
    },

    setHankoPreview(state: HankoSignatureState, action: PayloadAction<string>): void {
      state.hankoPreview = action.payload;
    },

    removeHankoPreview(state: HankoSignatureState): void {
      state.hankoPreview = null;
    },
  },
});

export const hankoSignatureReducer = hankoSignatureSlice.reducer;

export const {
  startLoading,
  stopLoading,
  finishLoading,
  createNewHanko,
  editHanko,
  setLocalisedSignature,
  setIncludeHanko,
  setUploadedImageUrl,
  setHankoFile,
  removeHankoFile,
  setHankoPreview,
  removeHankoPreview,
} = hankoSignatureSlice.actions;
