import React, { FC, useContext, useRef } from 'react';

import { useToggle } from 'src/common/hooks/useToggle';
import { useOnClickOutsides } from 'src/common/hooks/useOnClickOutside';

import { BaseLayout } from '../BaseLayout';
import { LargeHeader } from '../header';

import { Navigation } from '../navigation';
import { DynamicSettingsContext } from '../contexts/DynamicSettingsContext';
import { SidebarNodeContext } from '../contexts/SidebarNodeContext';
import { DynamicSettings, SidebarNode } from '../types';
import { NavigatorWrapper } from '../NavigatorWrapper';

export const AppAdvancedLayout: FC = ({ children }) => {
  // @TODO refactor this part to use global store instead
  const navRef = useRef(null);
  const navWrpRef = useRef(null);
  const [navIsOpen, , openNavigation, closeNavigation] = useToggle();
  useOnClickOutsides([navRef, navWrpRef], closeNavigation);
  const { navigatorChildren } = useContext<DynamicSettings>(DynamicSettingsContext);
  const sidebar = useContext<SidebarNode>(SidebarNodeContext);

  return (
    <BaseLayout
      header={<LargeHeader onHamburgerClick={openNavigation} />}
      navigation={
        <>
          <Navigation active={navIsOpen} ref={navRef} />
          <NavigatorWrapper isExpanded={navIsOpen} ref={navWrpRef}>
            {navigatorChildren}
          </NavigatorWrapper>
        </>
      }
      sidebar={sidebar}
    >
      {children}
    </BaseLayout>
  );
};
