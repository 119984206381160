import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isNull, isUndefined } from 'lodash';

import { TemplateListPage as TemplateListPageV2 } from 'src/v2/features/template';
import { PersonalInfo } from 'src/v2/features/profile/components/PersonalInfo';
import { Security } from 'src/v2/features/profile/components/Security';
import { OrganizationInfo } from 'src/v2/features/profile/components/OrganizationInfo';
import { ManageOrgUsers } from 'src/v2/features/profile/components/ManageOrgUsers';
import { WizardPage } from 'src/v2/features/signUp';
import { LoginPageLight } from 'src/v2/features/auth/LoginPageLight';
import { getAccountType, getIsComplete } from 'src/v2/features/signUp/store';
import { getIsLoginAvailable } from 'src/shared/auth';
import { SearchPage, SearchSeeMorePage } from 'src/v2/features/search';
import { DocumentListPage, DocumentDetailsPage } from 'src/v2/features/document';
import {
  ContractListPage,
  ContractDetailsPage,
  ContractNegotiationPage,
} from 'src/v2/features/contract';
import { CheckoutPage, RetryPaymentPage, UpgradeSubscriptionPage } from 'src/v2/features/checkout';
import { Notifications } from 'src/v2/features/profile/components/Notifications';
import { SignaturePage } from 'src/v2/features/profile/components/SignaturePage';
import { BillingInfoPage, getSubscribedPlan, useNeedToCheckout } from 'src/v2/features/billing';
import { TemplateDetailsPage } from 'src/v2/features/template/TemplateDetailsPage';
import { ProjectListPage } from 'src/v2/features/project/ProjectListPage';
import { ProjectDetailsPage } from 'src/v2/features/project/ProjectDetailsPage';
import { QRCodePage } from 'src/v2/features/qrCode/QRCodePage';
import {
  useSignUpProps,
  useIsSignupFromQR,
  useIsSignupFromInvite,
} from 'src/v2/features/signUp/pages/hooks';
import { getDefaultSubscriptionPlan, isOrgAccount } from 'src/v2/features/signUp/utils';
import { AccountType } from 'src/models/signUp';
import { setJWTCookie } from 'src/v2/features/auth/workers/cookiesConfiguration';
import { getToken } from 'src/shared/auth';

import { book } from './book';
export const Private: React.FC = () => {
  const token = useSelector(getToken);
  const isSignupComplete = useSelector(getIsComplete);
  const isLoginAvailable = useSelector(getIsLoginAvailable);
  const needToCheckout = useNeedToCheckout();
  const returnUrlParam = new URLSearchParams(window.location.search).get('returnUrl');
  const { entityType, entityId } = useSignUpProps();
  const accountType = useSelector(getAccountType);
  const isSignupFromQR = useIsSignupFromQR();
  const [isSignupFromInvite] = useIsSignupFromInvite();
  const inviteToEntityPath =
    !isNull(entityType) && !isNull(entityId)
      ? book.signup.generateRedirectPath(entityType, entityId, isSignupFromQR)
      : false;
  const isWizardShow = !isSignupComplete && (!isLoginAvailable || !isSignupFromInvite);
  const Wizard = isSignupFromQR ? LoginPageLight : WizardPage;
  const isBusiness = !isUndefined(accountType) ? isOrgAccount(accountType) : false;
  const isUserInvited = isSignupFromQR || isSignupFromInvite;
  const defaultSubscriptionPlan = getDefaultSubscriptionPlan(isBusiness, isUserInvited);
  const isJWTCookieExist = document.cookie.indexOf('jwt=') >= 0;

  if (!isJWTCookieExist && token) {
    setJWTCookie(token);
  }

  const plan = useSelector(getSubscribedPlan) || defaultSubscriptionPlan;

  if (isNull(isSignupComplete) || isNull(isLoginAvailable)) return null;

  if (isWizardShow) {
    if (isSignupFromQR) {
      return <LoginPageLight />;
    }

    return (
      <Routes>
        <Route path={book.signup.pattern} element={<Wizard />} />
        <Route
          path="*"
          element={
            <Navigate
              replace
              to={book.signup.generatePath(
                isBusiness && !isUserInvited ? AccountType.Business : AccountType.Personal,
                plan,
              )}
            />
          }
        />
      </Routes>
    );
  }

  if (needToCheckout) {
    return (
      <Routes>
        <Route path={book.checkout} element={<CheckoutPage />} />;
        <Route path="*" element={<Navigate to={book.checkout} />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path={book.templates.pattern} element={<TemplateListPageV2 />} />
      <Route path={book.template.pattern} element={<TemplateDetailsPage />} />
      <Route path={book.documents.pattern} element={<DocumentListPage />} />
      <Route path={book.contracts.pattern} element={<ContractListPage />} />
      <Route path={book.document.pattern} element={<DocumentDetailsPage />} />
      <Route path={book.contract.pattern} element={<ContractDetailsPage />} />
      <Route path={book.qr.document.pattern} element={<QRCodePage />} />
      <Route path={book.qr.contract.pattern} element={<QRCodePage />} />
      <Route
        path={`${book.contracts.pattern}/:id/negotiation`}
        element={<ContractNegotiationPage />}
      />
      <Route path={book.projects.pattern} element={<ProjectListPage />} />
      <Route path={book.project.pattern} element={<ProjectDetailsPage />} />
      <Route path={book.profile.personal.pattern} element={<PersonalInfo />} />
      <Route path={book.profile.signature} element={<SignaturePage />} />
      <Route path={book.profile.security} element={<Security />} />
      <Route path={book.profile.organization.pattern} element={<OrganizationInfo />} />
      <Route path={book.profile.users.pattern} element={<ManageOrgUsers />} />
      <Route path={book.profile.notifications.pattern} element={<Notifications />} />
      <Route path={book.profile.pricingAndBilling.pattern} element={<BillingInfoPage />} />

      <Route path={book.search.preview} element={<SearchPage />} />
      <Route path={book.search.all} element={<SearchSeeMorePage />} />

      <Route path={book.checkout} element={<CheckoutPage />} />
      <Route path={book.retrySubscription} element={<RetryPaymentPage />} />
      <Route path={book.upgradeSubscription} element={<UpgradeSubscriptionPage />} />

      {!isSignupComplete && <Route path={book.signup.pattern} element={<WizardPage />} />}
      <Route
        path="*"
        element={
          <Navigate
            replace
            to={returnUrlParam || inviteToEntityPath || book.documents.generatePath()}
          />
        }
      />
    </Routes>
  );
};
