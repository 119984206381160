import React, { ReactElement, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { saveNotificationSettingAction, getSendEmail, getSendText, getIsLoading } from '../store';
import { useSignUpProps, SignUpStepComponent } from './hooks';
import { NotificationsSettings } from '../NotificationsSettings';

export const useNotificationsComponent = (): SignUpStepComponent => {
  const dispatch = useDispatch();
  const { maxSteps, step } = useSignUpProps();
  const isSendEmail = useSelector(getSendEmail);
  const isSendText = useSelector(getSendText);
  const isLoading = useSelector(getIsLoading);

  const saveSettings = useCallback(
    (type: string, value: boolean): void => {
      dispatch(saveNotificationSettingAction({ type, value }));
    },
    [dispatch],
  );

  return [
    (): ReactElement => (
      <NotificationsSettings
        onSettingChange={saveSettings}
        sendEmail={isSendEmail}
        sendText={isSendText}
        playSound={false}
        banner={false}
        isLoading={isLoading}
      />
    ),
    {
      step,
      maxSteps,
    },
  ];
};
