import { isUndefined } from 'lodash';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import {
  canAddUser,
  canEditUserPermissions,
  UserWithRole,
} from 'src/v2/features/contract/permissions/manageParticipantsPermissions/manageParticipantsPermissions';
import { SelectOptionType } from 'src/v2/components/Form/withReduxForm';
import { ContractRole } from 'src/models/contract';

const getParticipantFormAvailableRoles = (): { value: ContractRole; label: string }[] => {
  return [
    {
      value: ContractRole.Manager,
      label: i18n(translationKeys.forms.contract.userRole.managerTitle),
    },
    {
      value: ContractRole.Editor,
      label: i18n(translationKeys.forms.contract.userRole.editorTitle),
    },
    {
      value: ContractRole.Signatory,
      label: i18n(translationKeys.forms.contract.userRole.signatoryTitle),
    },
    {
      value: ContractRole.Commentator,
      label: i18n(translationKeys.forms.contract.userRole.commentatorTitle),
    },
    {
      value: ContractRole.QRViewer,
      label: i18n(translationKeys.forms.contract.userRole.qrViewerTitle),
    },
    {
      value: ContractRole.Viewer,
      label: i18n(translationKeys.forms.contract.userRole.viewerTitle),
    },
  ];
};

export const getRoleOption = (targetRole: ContractRole): SelectOptionType | null =>
  getParticipantFormAvailableRoles().find(({ value }) => value === targetRole) || null;

export const getEditRoleOptions = (actor: UserWithRole, targetId: string): SelectOptionType[] =>
  getParticipantFormAvailableRoles().filter(({ value }) =>
    canEditUserPermissions(actor, { id: targetId, role: value }),
  );

export const getInviteRoleOptions = (actorRole: ContractRole): SelectOptionType[] =>
  getParticipantFormAvailableRoles().filter(({ value }) => canAddUser(actorRole, value));

export const conditionRequiredVisible = (roleId: ContractRole): boolean =>
  [ContractRole.Manager, ContractRole.Editor, ContractRole.Signatory].includes(roleId);

export const getShownRole = (roleId: ContractRole, role?: string): string => {
  const funcRole = !isUndefined(role) ? `, ${role}` : '';
  switch (roleId) {
    case ContractRole.Owner:
      return `${i18n(translationKeys.forms.contract.userRole.ownerDescription)}${funcRole}`;
    case ContractRole.Manager:
      return `${i18n(translationKeys.forms.contract.userRole.managerDescription)}${funcRole}`;
    case ContractRole.Editor:
      return `${i18n(translationKeys.forms.contract.userRole.editorDescription)}${funcRole}`;
    case ContractRole.Signatory:
      return `${i18n(translationKeys.forms.contract.userRole.signatoryDescription)}${funcRole}`;
    case ContractRole.Commentator:
    case ContractRole.QRViewer:
      return `${i18n(translationKeys.forms.contract.userRole.commentatorDescription)}${funcRole}`;
    case ContractRole.Viewer:
      return `${i18n(translationKeys.forms.contract.userRole.viewerDescription)}${funcRole}`;
    case ContractRole.SharedViewer:
      return `${i18n(translationKeys.forms.contract.userRole.sharedViewerDescription)}`;
    default:
      return `${i18n(translationKeys.forms.contract.userRole.unknownDescription)}`;
  }
};

export const getRequiredCondition = (
  roleId: ContractRole,
  conditionRequiredChecked: boolean,
): boolean => {
  if (roleId === ContractRole.Signatory) {
    return true;
  } else if (
    [
      ContractRole.Commentator,
      ContractRole.QRViewer,
      ContractRole.SharedViewer,
      ContractRole.Viewer,
    ].includes(roleId)
  ) {
    return false;
  }

  return conditionRequiredChecked;
};
