import { call, put, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';
import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';

import { fetchUsers } from 'src/v2/features/profile';
import { ObjectSerialized } from 'src/common/types';
import { storeData } from 'src/v2/features/objectsStorage/objectsStorageSlice';
import { searchByParams, SearchParams } from 'src/api/searchApi';
import { SidebarParticipantEntity } from 'src/v2/entities/participants';

import { fetchSearchList } from './searchActions';
import {
  setAuthors,
  setSubTypes,
  setCounters,
  fetchSearchListStart,
  fetchSearchListSuccess,
  fetchSearchListFailed,
  initialCounters,
} from './searchReducer';

function* fetchAuthors(authorsId: string[]): SagaIterator {
  try {
    const users = yield call(fetchUsers, authorsId);
    if (users) {
      yield put(storeData(users));
      yield put(setAuthors(users));
    }
  } catch (e) {
    console.error(e.toString());
  }
}

function* fetchParticipants(data: ObjectSerialized): SagaIterator {
  const userIds = data.included.reduce<string[]>((value, itm) => {
    const id = get(itm, 'attributes.createdById', null);
    if (id) {
      value.push(id);
    }

    const participants: SidebarParticipantEntity[] = get(itm, 'attributes.participants', []);
    const partIds = participants.map((itm) => itm.userId);
    value.push(...partIds);

    return value;
  }, []);

  try {
    const users = yield call(fetchUsers, userIds);
    if (users) {
      yield put(storeData(users));
    }
  } catch (e) {
    console.error(e.toString());
  }
}

function* fetchSearchListSaga(action: PayloadAction<SearchParams>): SagaIterator {
  try {
    yield put(fetchSearchListStart());
    const data: ObjectSerialized = yield call(searchByParams, action.payload);

    yield call(fetchParticipants, data);

    const authorsId = get(data, 'data[0].attributes.authors', []);
    yield call(fetchAuthors, authorsId);

    const subTypes = get(data, 'data[0].attributes.subTypes', []);
    yield put(setSubTypes(subTypes));

    const counters = get(data, 'data[0].attributes', initialCounters);
    yield put(
      setCounters({
        contracts: (counters.contractsCount as number) || 0,
        documents: (counters.documentsCount as number) || 0,
        contractTemplates: (counters.templateContractsCount as number) || 0,
        documentTemplates: (counters.templateDocumentsCount as number) || 0,
      }),
    );
    yield put(storeData(data));
    yield put(fetchSearchListSuccess(data));
  } catch (error) {
    yield put(fetchSearchListFailed(error.toString()));
  }
}

export function* watchSearchListSagas(): SagaIterator {
  yield takeLatest(fetchSearchList, fetchSearchListSaga);
}
