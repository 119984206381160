import React from 'react';

import { AbstractView } from 'src/v2/features/document/DocumentSidebar/pages/AbstractView';
import { useCreateEditDocumentMetadataView } from 'src/v2/features/project/ProjectSidebar/nav-hooks';

interface Props {
  id: string;
}

export const ViewDocument: React.FC<Props> = ({ id }) => {
  const handleEdit = useCreateEditDocumentMetadataView();

  return <AbstractView id={id} onEdit={handleEdit} />;
};
