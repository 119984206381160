import { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNull } from 'lodash';
import { toastr } from 'react-redux-toastr';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { MenuContracts } from 'src/v2/icons/MenuContracts';
import { MenuProjects } from 'src/v2/icons/MenuProjects';
import { isContractFolder } from 'src/v2/features/quantumNavigator';
import { useGetRootFolderId, useIsProjectsRootFolder } from 'src/v2/features/sharedEntity/hooks';
import { isProjectsFolder, isSharedFolder } from 'src/v2/features/quantumNavigator/utils';
import { copyContractToFolder, copyContractToProject } from 'src/v2/features/contract/store';
import { getTargetId, clearTargetId } from 'src/v2/features/folderSelector/store';
import { FolderSelectorWithProjectsContainer } from 'src/v2/features/folderSelector/FolderSelectorWithProjects/FolderSelectorWithProjectsContainer';
import { ListItem } from 'src/models/list';
import { NodeTree } from 'src/models/node';
import { closeModal } from 'src/v2/features/modal/store';
import { useCreateGoToFolderByIdHandler } from 'src/v2/features/folderSelector/hooks';

const validateFn = (model: NodeTree<ListItem>) => {
  return !isSharedFolder(model.data);
};

export const ContractCopyToModal: FC = () => {
  const contractId = useSelector(getTargetId);
  const contractRootFolderId = useGetRootFolderId(isContractFolder);
  const projectsRootFolderId = useGetRootFolderId(isProjectsFolder);
  const isProjectsRootFolder = useIsProjectsRootFolder();
  const dispatch = useDispatch();

  const handleActionCopyToFolder = useCallback(
    (targetFolderId: string) => {
      if (isNull(contractId)) {
        console.warn(`contractId can't be null`);
        return;
      }

      dispatch(
        copyContractToFolder({
          folderId: targetFolderId,
          contractId,
        }),
      );
      dispatch(closeModal());
      dispatch(clearTargetId());
    },
    [contractId, dispatch],
  );

  const handleActionCopyToProject = useCallback(
    (projectId: string) => {
      if (isNull(contractId)) {
        console.warn(`contractId can't be null`);
        return;
      }

      if (isProjectsRootFolder(projectId)) {
        toastr.error(
          i18n(translationKeys.errors.MESSAGE_TITLE),
          i18n(translationKeys.errors.COPY_TO_ROOT_PROJECT),
        );
        return;
      }

      dispatch(
        copyContractToProject({
          projectId,
          entityId: contractId,
        }),
      );
      dispatch(closeModal());
      dispatch(clearTargetId());
    },
    [contractId, dispatch, isProjectsRootFolder],
  );

  const handleContractsItemClick = useCreateGoToFolderByIdHandler(contractRootFolderId);
  const handleProjectsMenuItemClick = useCreateGoToFolderByIdHandler(projectsRootFolderId);

  const menuItems = useMemo(
    () => [
      {
        icon: <MenuContracts />,
        title: 'Contracts',
        onClick: handleContractsItemClick,
      },
      {
        icon: <MenuProjects />,
        title: 'Projects',
        onClick: handleProjectsMenuItemClick,
      },
    ],
    [handleContractsItemClick, handleProjectsMenuItemClick],
  );

  return (
    <FolderSelectorWithProjectsContainer
      menuItemFolderId={contractRootFolderId}
      heading="Copy to Folder"
      actionButtonLabel="Copy"
      menuItems={menuItems}
      projectsRootFolderId={projectsRootFolderId}
      onActionButtonClick={handleActionCopyToFolder}
      onProjectsActionButtonClick={handleActionCopyToProject}
      validateFn={validateFn}
    />
  );
};
