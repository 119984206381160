import { SagaIterator } from 'redux-saga';
import { call, takeLatest, put, takeEvery } from 'redux-saga/effects';
import { capitalize, get, head, isUndefined } from 'lodash';
import { toastr } from 'react-redux-toastr';

import {
  createProjectAPI,
  fetchProjectsAPI,
  fetchProjectDetailsAPI,
  createDocumentInProjectAPI,
  createDocumentInProjectFromUploadAPI,
  createDocumentFromTemplateInProjectAPI,
  createContractInProjectFromUploadAPI,
  createContractFromTemplateInProjectAPI,
  createContractInProjectAPI,
  removeProjectAPI,
  editProjectAPI,
  deleteDocumentFromProjectAPI,
  deleteContractFromProjectAPI,
} from 'src/api/projectsAPI';
import { deleteData, storeData } from 'src/v2/features/objectsStorage/objectsStorageSlice';
import { ObjectSerialized } from 'src/common/types';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  CreateProjectDTO,
  DeleteContractFromProjectDTO,
  DeleteDocumentFromProjectDTO,
  EditProjectDTO,
  FetchProjectDetailsDTO,
  FetchProjectsDTO,
  RemoveProjectDTO,
} from 'src/v2/boundary/requestDTO/project';
import { clearWorkflow } from 'src/v2/features/documentWorkflow';
import { emitCreate, emitDelete } from 'src/v2/features/sharedEntity/entityEventBus';
import { handleErrorSaga } from 'src/utils/handleErrorSaga';
import {
  createDocumentFinished,
  createDocumentStart,
} from 'src/v2/features/documentList/documentListSlice';
import {
  CreateDocumentDTO,
  CreateDocumentFromTemplateDTO,
} from 'src/v2/boundary/requestDTO/document';
import {
  createUploadAttachmentFilesSaga,
  createUploadAttachmentFilesWithRetrySaga,
} from 'src/v2/features/documentSidebar/documentSidebarUpload/documentSidebarUploadStore';
import {
  createFromTemplateFailed,
  createFromTemplateStart,
  createFromTemplateSuccess,
} from 'src/v2/features/createFromTemplate/reducer';
import {
  CreateContractDTO,
  CreateContractFromTemplateDTO,
} from 'src/v2/boundary/requestDTO/contract';
import { DbRecordType } from 'src/v2/features/objectsStorage/types';
import { emitCreateProject } from 'src/v2/features/project/projectEventBus';
import {
  createProject,
  fetchProjectDetails,
  fetchProjectsList,
  createDocumentInProject,
  createDocumentFromFileInProject,
  createDocumentFromTemplateInProject,
  createContractFromFileInProject,
  createContractFromTemplateInProject,
  createContractInProject,
  removeProject,
  startLoading,
  finishLoading,
  stopLoading,
  editProject,
  deleteDocumentFromProject,
  deleteContractFromProject,
  successLoading,
  deleteEntityStart,
  deleteEntityFinished,
} from './projectReducers';

function* fetchProjectDetailsSaga(action: PayloadAction<FetchProjectDetailsDTO>) {
  try {
    yield put(startLoading());
    const list: ObjectSerialized = yield call(fetchProjectDetailsAPI, action.payload);
    yield put(storeData(list));
    yield put(finishLoading());
  } catch (e) {
    yield put(stopLoading(e));
    yield call(handleErrorSaga, e);
  }
}

function* fetchProjectsListSaga(action: PayloadAction<FetchProjectsDTO>) {
  try {
    yield put(startLoading());
    const list: ObjectSerialized = yield call(fetchProjectsAPI, action.payload);
    yield put(storeData(list));
    yield put(successLoading(list));
  } catch (e) {
    yield put(stopLoading(e));
    yield call(handleErrorSaga, e);
  }
}

function* createProjectSaga(action: PayloadAction<CreateProjectDTO>) {
  try {
    yield put(startLoading());
    const response = yield call(createProjectAPI, action.payload);
    const project = head(ObjectSerialized.dataToBaseObjects(response.data));
    yield put(storeData(response));
    if (isUndefined(project)) {
      console.warn(`project can't be undefined`);
      yield put(stopLoading('Oops something went wrong'));
      return;
    }
    yield call(emitCreateProject, { id: project.id });
    yield put(finishLoading());
  } catch (e) {
    yield put(stopLoading(e));
    yield call(handleErrorSaga, e);
  }
}

function* editProjectSaga(action: PayloadAction<EditProjectDTO>) {
  try {
    yield put(startLoading());
    const response = yield call(editProjectAPI, action.payload);
    yield put(storeData(response));
    yield put(finishLoading());
  } catch (e) {
    yield put(stopLoading(e));
    yield call(handleErrorSaga, e);
  }
}

function* createDocumentInProjectSaga(action: PayloadAction<CreateDocumentDTO>) {
  try {
    yield put(createDocumentStart());
    yield put(clearWorkflow());
    const data = action.payload;

    const response = yield call(createDocumentInProjectAPI, data);
    yield put(storeData(response));
    yield put(createDocumentFinished());
    emitCreate({ id: get(response, 'data[0].id'), type: data.data.type });
    toastr.success('Success', `${capitalize(data.data.type)} created successfully`);
  } catch (error) {
    yield call(handleErrorSaga, error);
    yield put(createDocumentFinished());
  }
}

function* createContractInProjectSaga(action: PayloadAction<CreateContractDTO>) {
  try {
    yield put(createDocumentStart());
    yield put(clearWorkflow());
    const data = action.payload;

    const response = yield call(createContractInProjectAPI, data);
    yield put(storeData(response));
    yield put(createDocumentFinished());
    emitCreate({ id: get(response, 'data[0].id'), type: data.data.type });
    toastr.success('Success', `${capitalize(data.data.type)} created successfully`);
  } catch (error) {
    yield call(handleErrorSaga, error);
    yield put(createDocumentFinished());
  }
}

const uploadDocumentFile = createUploadAttachmentFilesSaga<CreateDocumentDTO>(
  createDocumentInProjectFromUploadAPI,
);

const uploadContractFile = createUploadAttachmentFilesSaga<CreateContractDTO>(
  createContractInProjectFromUploadAPI,
);

function* createDocumentFromTemplateSaga(action: PayloadAction<CreateDocumentFromTemplateDTO>) {
  try {
    yield put(createFromTemplateStart());
    const res = yield call(createDocumentFromTemplateInProjectAPI, action.payload);
    yield put(createFromTemplateSuccess());
    emitCreate({ id: get(res, 'data[0].id'), type: get(res, 'data[0].attributes.type') });
  } catch (error) {
    yield put(createFromTemplateFailed(error.toString()));
  }
}

function* createContractFromTemplateSaga(action: PayloadAction<CreateContractFromTemplateDTO>) {
  try {
    yield put(createFromTemplateStart());
    const res = yield call(createContractFromTemplateInProjectAPI, action.payload);
    yield put(createFromTemplateSuccess());
    emitCreate({ id: get(res, 'data[0].id'), type: get(res, 'data[0].attributes.type') });
  } catch (error) {
    yield put(createFromTemplateFailed(error.toString()));
  }
}

function* removeProjectSaga(action: PayloadAction<RemoveProjectDTO>) {
  try {
    yield call(removeProjectAPI, action.payload);
    yield put(deleteData({ id: action.payload, type: DbRecordType.Project }));
    yield call(toastr.success, 'Success', 'Project has been deleted');
  } catch (error) {
    yield call(handleErrorSaga, error);
  }
}

const createDeleteEntityFromProjectSaga = (
  fn: (payload: DeleteDocumentFromProjectDTO | DeleteContractFromProjectDTO) => unknown,
  msg: string,
) =>
  function* (action: PayloadAction<DeleteDocumentFromProjectDTO>) {
    const { entityId, projectId } = action.payload;
    try {
      yield put(deleteEntityStart(entityId));
      yield call(fn, { entityId, projectId });
      yield put(deleteData({ id: entityId, type: DbRecordType.Paper }));
      yield put(deleteData({ id: entityId, type: DbRecordType.CardPaper }));
      yield call(emitDelete);
      yield call(toastr.success, 'Success', msg);
      yield put(deleteEntityFinished());
    } catch (error) {
      yield call(handleErrorSaga, error);
      yield put(deleteEntityFinished());
    }
  };

const deleteDocumentFromProjectSaga = createDeleteEntityFromProjectSaga(
  deleteDocumentFromProjectAPI,
  `Document deleted successfully`,
);

const deleteContractFromProjectSaga = createDeleteEntityFromProjectSaga(
  deleteContractFromProjectAPI,
  `Contract deleted successfully`,
);
export function* watchProjectSagas(): SagaIterator {
  yield takeLatest(fetchProjectDetails, fetchProjectDetailsSaga);
  yield takeLatest(fetchProjectsList, fetchProjectsListSaga);
  yield takeLatest(createProject, createProjectSaga);
  yield takeLatest(editProject, editProjectSaga);
  yield takeLatest(createDocumentInProject, createDocumentInProjectSaga);
  yield takeLatest(createContractInProject, createContractInProjectSaga);
  yield takeEvery(
    createDocumentFromFileInProject,
    createUploadAttachmentFilesWithRetrySaga(uploadDocumentFile),
  );
  yield takeEvery(
    createContractFromFileInProject,
    createUploadAttachmentFilesWithRetrySaga(uploadContractFile),
  );
  yield takeEvery(createDocumentFromTemplateInProject, createDocumentFromTemplateSaga);
  yield takeEvery(createContractFromTemplateInProject, createContractFromTemplateSaga);
  yield takeEvery(removeProject, removeProjectSaga);
  yield takeEvery(deleteDocumentFromProject, deleteDocumentFromProjectSaga);
  yield takeEvery(deleteContractFromProject, deleteContractFromProjectSaga);
}
