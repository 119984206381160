import { Store } from 'redux';
import { PayloadActionCreator } from '@reduxjs/toolkit';

import { WSEvent } from 'src/v2/boundary/socket';

import { SocketGateway } from './SocketGateway';
import { getConnectionsConfig } from './config';

let gateway: SocketGateway | null = null;

export function webSocketsInit(): void {
  gateway = new SocketGateway({
    connections: getConnectionsConfig(),
  });
}

export function setWebsocketsStore(store: Store): void {
  if (!gateway) {
    throw new Error('Gateway should be defined first');
  }

  gateway.setStore(store);
}

export function setWebsocketsToken(token: string): void {
  if (!gateway) {
    throw new Error('Gateway should be defined first');
  }

  gateway.setToken(token);
}

export async function sendWSCommand<T>(event: WSEvent<T>): Promise<void> {
  if (!gateway) {
    throw new Error('Gateway should be defined first');
  }

  await gateway.sendCommand(event);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function listenWSEvents(...args: PayloadActionCreator<any>[]): void {
  if (!gateway) {
    throw new Error('Gateway should be defined first');
  }

  gateway.listenEvents(...args);
}
