import React, { PropsWithChildren } from 'react';

import { Link } from 'src/v2/components/Link';
import { OneRowHeader } from 'src/v2/modules/layout/header';
import { HeaderItem } from 'src/v2/modules/layout/header';
import { headerLinks, signHeaderLinks } from 'src/v2/features/layout/headerLinks';
import { PublicHeaderNav, Props as HeaderNavProps } from 'src/v2/features/layout/PublicHeaderNav';

export type Props = HeaderNavProps;

export const PublicHeader = React.forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  (props: PropsWithChildren<Props>, ref): JSX.Element => {
    return (
      <OneRowHeader>
        <HeaderItem>
          <Link className="c-nav__sign-in c-nav__sign-in--mobile" to="/login">
            Sign In
          </Link>
        </HeaderItem>
        <PublicHeaderNav ref={ref} links={headerLinks} signLinks={signHeaderLinks} {...props} />
      </OneRowHeader>
    );
  },
);

PublicHeader.displayName = 'PublicHeader';
