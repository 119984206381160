import { call, takeLatest } from 'redux-saga/effects';
import { createAction, PayloadAction } from '@reduxjs/toolkit';
import { toastr } from 'react-redux-toastr';

import { getDocumentDIContainer } from 'src/v2/features/diFactories/documentDIFactory';
import { CopyEntityToProjectDTO } from 'src/v2/boundary/requestDTO/project';
import { CopyDocumentToFolderDTO } from 'src/v2/boundary/requestDTO/document';

const {
  isDocumentFolder,
  createGetRootFolderId,
  handleErrorSaga,
  copyEntityToProjectAPI,
  copyDocumentToFolderAPI,
} = getDocumentDIContainer();

export const getRootDocumentFolderId = createGetRootFolderId(isDocumentFolder);

export const copyDocumentToFolder = createAction<CopyDocumentToFolderDTO>(
  'document/copyDocumentToFolder',
);

export const copyDocumentToProject = createAction<CopyEntityToProjectDTO>(
  'document/copyDocumentToProject',
);

function* copyDocumentToFolderSaga(action: PayloadAction<CopyDocumentToFolderDTO>) {
  try {
    yield call(copyDocumentToFolderAPI, action.payload.documentId, action.payload.folderId);
    yield call(toastr.success, 'Success', 'Successfully copied');
  } catch (err) {
    yield call(handleErrorSaga, err);
  }
}

function* copyDocumentToProjectSaga(action: PayloadAction<CopyEntityToProjectDTO>) {
  try {
    yield call(copyEntityToProjectAPI, action.payload.entityId, action.payload.projectId);
    yield call(toastr.success, 'Success', 'Successfully copied');
  } catch (err) {
    yield call(handleErrorSaga, err);
  }
}

export function* watchDocumentsRootSagas() {
  yield takeLatest(copyDocumentToFolder, copyDocumentToFolderSaga);
  yield takeLatest(copyDocumentToProject, copyDocumentToProjectSaga);
}
