import { FC } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { env } from 'src/env';
import { PublicLayout } from 'src/v2/components/PublicLayout';
import { OneRowHeader } from 'src/v2/modules/layout/header';
import { useSubscribeToSuccessfulPaymentEffct } from './hooks';

const stripePromise = loadStripe(env.STRIPE_API_KEY);

export const CheckoutLayout: FC = ({ children }) => {
  useSubscribeToSuccessfulPaymentEffct();

  return (
    <PublicLayout header={<OneRowHeader />} mainClassName="l-main--checkout">
      <Elements stripe={stripePromise}>{children}</Elements>
    </PublicLayout>
  );
};
