import React, { FC } from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';

import { CreateProjectFormPayload } from 'src/v2/features/project/types';
import { ProjectMetadataForm } from './ProjectMetadataForm';

interface Props {
  isLoading: boolean;
  onSubmit: (data: CreateProjectFormPayload) => void;
}

const Form: FC<Props & InjectedFormProps<CreateProjectFormPayload, Props>> = ({
  isLoading,
  onSubmit,
  handleSubmit,
  pristine,
}) => (
  <ProjectMetadataForm
    label="Create New Project"
    isLoading={isLoading}
    isPristine={pristine}
    onSubmit={handleSubmit(onSubmit)}
  />
);

export const ProjectMetadataCreate = reduxForm<CreateProjectFormPayload, Props>({
  form: 'createProjectMetadata',
  enableReinitialize: true,
})(Form);
