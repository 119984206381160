import i18n, { StringMap, TFunctionKeys, TOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { isString } from 'lodash';

import { getLanguageFromLocale, LanguageCode } from 'src/instruments/languages';

/* eslint-disable import/extensions */
import langEN from './locales/en.json';
import langJP from './locales/jp.json';
/* eslint-enable */

export function getUserLanguage(): LanguageCode {
  const locales = navigator.languages || [navigator.language];
  const languages = locales.map(getLanguageFromLocale).filter(isString);
  return languages[0] || LanguageCode.ENG;
}

export function initializeI18n(): void {
  const resources = {
    [LanguageCode.ENG]: langEN,
    [LanguageCode.JPN]: langJP,
  };

  i18n.use(initReactI18next).init({
    resources,
    lng: getUserLanguage(),
    fallbackLng: LanguageCode.ENG,
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
}

export default function geti18n(
  key: string | TemplateStringsArray | TFunctionKeys[],
  options?: string | TOptions<StringMap> | undefined,
) {
  return i18n.t(key, options);
}
