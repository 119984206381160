import { FC, useState } from 'react';
import { isUndefined } from 'lodash';
import { Box } from '@theme-ui/components';

import { SolidPlus } from 'src/v2/icons/SolidPlus';
import { ChevronDown } from 'src/v2/icons/ChevronDown';
import { useHorizontalScroll } from 'src/common/hooks/useHorizontalScroll';

interface Props {
  gridTitle?: string;
  onAdd?: () => void;
}

export const CarouselCardGrid: FC<Props> = ({ children, gridTitle, onAdd }) => {
  const [activeGroup, setActiveGroup] = useState(true);
  const groupClass = activeGroup ? 'c-group__inline--active' : '';

  const onArrowClick = () => setActiveGroup(!activeGroup);

  const scrollRef = useHorizontalScroll();

  return (
    <div className={`c-group c-group__inline ${groupClass}`}>
      <div className="c-group__header">
        <div className="c-group__title">{gridTitle}</div>
        <button className="c-group__arrow" type="button" onClick={onArrowClick}>
          <ChevronDown />
        </button>
      </div>
      <Box className="c-group__main" ref={scrollRef}>
        {!isUndefined(onAdd) && (
          <div className="c-card__item c-card__add" data-type="card-create" onClick={onAdd}>
            <div className="c-card__plus">
              <SolidPlus />
            </div>
          </div>
        )}
        {children}
      </Box>
    </div>
  );
};
