import React, { FC, useContext } from 'react';
import cn from 'classnames';
import { isUndefined } from 'lodash';

import { NotificationsCount } from 'src/common/types';
import { DynamicSettingsContext } from 'src/v2/modules/layout';
import { BaseHeader, HeaderItem, Search } from 'src/v2/modules/layout/header';
import { Bell } from 'src/v2/icons/Bell';
import { Grid } from 'src/v2/icons/Grid';
import { QRCode } from 'src/v2/icons/QrCode';
import { NotificationsCountContext } from 'src/common/contexts/NotificationsCountContext';

import { HeaderNav } from '../components/HeaderNav';
import { DynamicSettings, NavProps } from '../../types';

export const LargeHeader: FC<NavProps> = ({ onHamburgerClick }) => {
  const { headerChildren, onBellClick, onGridClick, onQRCodeClick, hasQRCodeFeature } =
    useContext<DynamicSettings>(DynamicSettingsContext);
  const bellCount = useContext<NotificationsCount>(NotificationsCountContext);

  return (
    <BaseHeader className="l-header--quantum">
      <Search />

      <HeaderNav onHamburgerClick={onHamburgerClick} />

      <HeaderItem />

      {headerChildren}

      <HeaderItem>
        {hasQRCodeFeature && !isUndefined(onQRCodeClick) && (
          <div className="c-dropdown" data-type="qr-code" onClick={onQRCodeClick}>
            <div className="c-dropdown__icon">
              <QRCode />
            </div>
          </div>
        )}

        <div
          className={cn('c-bell', bellCount && 'c-bell--active')}
          data-type="header-bell"
          onClick={onBellClick}
        >
          {!isUndefined(bellCount) && <div className="c-bell__count">{bellCount}</div>}
          <Bell />
        </div>
        {!isUndefined(onGridClick) && (
          <div className="c-dropdown" data-type="header-9square-menu" onClick={onGridClick}>
            <div className="c-dropdown__icon">
              <Grid />
            </div>
          </div>
        )}
      </HeaderItem>
    </BaseHeader>
  );
};
