import { toastr } from 'react-redux-toastr';

import i18n from 'src/i18n';
import { env } from 'src/env';
import { translationKeys } from 'src/common/translations';

import { UploadError } from './types';

const getSizePropsFromBytes = (
  bytes = 0,
): {
  message: string;
  size: number;
} => {
  const BYTES_IN_KILOBYTES = 1024;
  const BYTES_IN_MEGABYTE = BYTES_IN_KILOBYTES * 1024;
  const BYTES_IN_GIGABYTE = BYTES_IN_MEGABYTE * 1024;
  const BYTES_IN_TERABYTE = BYTES_IN_GIGABYTE * 1024;

  if (bytes < BYTES_IN_MEGABYTE) {
    return {
      message: i18n(translationKeys.sizes.KB),
      size: bytes / BYTES_IN_KILOBYTES,
    };
  }
  if (bytes < BYTES_IN_GIGABYTE) {
    return {
      message: i18n(translationKeys.sizes.MB),
      size: bytes / BYTES_IN_MEGABYTE,
    };
  }
  if (bytes < BYTES_IN_TERABYTE) {
    return {
      message: i18n(translationKeys.sizes.GB),
      size: bytes / BYTES_IN_GIGABYTE,
    };
  }

  return {
    message: i18n(translationKeys.sizes.TB),
    size: bytes / BYTES_IN_TERABYTE,
  };
};

const getMaxSize = (): string => {
  const { size, message } = getSizePropsFromBytes(env.FILE_UPLOAD_LIMIT);

  return i18n(translationKeys.sizes.sizeMessage, { size, message });
};

export const createTextFromError = (err: UploadError): string => {
  if (err === UploadError.WrongExtension) return i18n(translationKeys.errors.WRONG_EXTENSION);
  if (err === UploadError.Unknown) return i18n(translationKeys.errors.UNKNOWN);
  if (err === UploadError.MaxFileSize)
    return i18n(translationKeys.errors.UPLOAD_LIMIT, { maxSize: getMaxSize() });
  if (err === UploadError.DamagedFile) return i18n(translationKeys.errors.FILE_DAMAGED);
  if (err === UploadError.NetworkError) return i18n(translationKeys.errors.NETWORK);

  return '';
};

export const showErrorToast = (err: UploadError): void =>
  toastr.error(i18n(translationKeys.errors.UPLOAD), createTextFromError(err));
