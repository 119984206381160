import { Subscription } from 'rxjs';

import { eventBusFactory } from 'src/app/eventBus';
import { ProfileEvent, ProfileEventData } from './types';

const eventBusApi = eventBusFactory<ProfileEventData>();

const { emit, subscribeToEvent } = eventBusApi;

export const emitRemoveUserFromOrganization = (): void =>
  emit({ type: ProfileEvent.RemoveUserFromOrganization });

export const subscribeForRemoveUserFromOrganization = (
  callback: (eventData: ProfileEventData) => void,
): Subscription => subscribeToEvent(ProfileEvent.RemoveUserFromOrganization, callback);
