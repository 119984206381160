import { FocusEvent, useCallback, useState } from 'react';
import EmailValidator from 'email-validator';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

import { InputProps } from '../types';
import { Input } from '../Input';

export const Email = ({
  errorMessage: initErrorMessage,
  mb8 = false,
  ...inputProps
}: InputProps): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState(initErrorMessage);

  const handleOnBlur = useCallback((event: FocusEvent<HTMLInputElement>): void => {
    const isEmailValid = EmailValidator.validate(event.target.value);

    if (!isEmailValid) {
      setErrorMessage(i18n(translationKeys.errors.INVALID_EMAIL));
    } else {
      setErrorMessage(undefined);
    }
  }, []);

  return <Input {...inputProps} errorMessage={errorMessage} onBlur={handleOnBlur} />;
};
