import React, { FC } from 'react';
import { useFetchBillingDetails, useShouldShowUpgradeModal } from './hooks';
import { UpgradeModal } from './UpgradeModal';

export const BillingModalsService: FC = ({ children }) => {
  const shouldShowUpgradeModal = useShouldShowUpgradeModal();
  useFetchBillingDetails();

  return (
    <>
      {shouldShowUpgradeModal && <UpgradeModal />}
      {children}
    </>
  );
};
