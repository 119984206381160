export enum LanguageCode {
  ENG = 'eng',
  JPN = 'jpn',
}

export enum LanguageFormat {
  eng = 'en-US',
  jpn = 'ja-JP',
}

export function getLanguageFromLocale(locale: string): LanguageCode | null {
  const [lang] = locale.split('-');

  switch (lang) {
    case 'en':
      return LanguageCode.ENG;
    case 'ja':
      return LanguageCode.JPN;
    default:
      return null;
  }
}

export const languageOptions = [
  { label: 'Language Preference', value: '', disabled: true },
  { label: 'English', value: LanguageCode.ENG },
  { label: 'Japanese', value: LanguageCode.JPN },
];
