import { useCallback, useEffect, useRef } from 'react';
import { isNull } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Image } from '@theme-ui/components';

import { PublicLayout } from 'src/v2/components/PublicLayout';
import { EntityTitle } from 'src/v2/components/Title/Title';
import { useGetQRParameters } from 'src/v2/features/signUp/hooks';
import { EntityBody } from 'src/v2/features/sharedEntity/EntityBody';
import {
  fetchDocumentPreview,
  getIsFileFullyLoaded,
  getPreviewTitle,
} from 'src/v2/features/documentDetails/documentDetailsSlice';
import { PreviewHeader } from 'src/v2/modules/layout/header';
import { useModal, useQuery } from 'src/utils/hooks';
import docuwalkIcon from 'src/assets/docuwalk-logo.svg';
import onereportIcon from 'src/assets/onereport-logo.svg';
import { setEntityId, setQrCodeAccountRecoveryStep, setStep } from 'src/v2/features/signUp/store';
import { book } from 'src/app/book';
import { history } from 'src/initializeHistory';
import { getIsAuthenticated } from 'src/shared/auth';
import { getError } from 'src/v2/features/documentDetails/documentDetailsSlice';
import { QrCodeAccountRecoveryStep, WizardStepV2 } from 'src/models/signUp';
import { Picture } from 'src/v2/icons/Picture';

import { ModalView } from '../modal';
import { useComponentAndPropsLight } from '../signUp/pages/componentAndPropsLightHook';

const SHOW_MODAL_WINDOW_INTERVAL = 5000;

export const LoginPageLight: React.FC = () => {
  const dispatch = useDispatch();
  const query = useQuery();

  const title = useSelector(getPreviewTitle);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const modal = query.get('modal');
  const step = query.get('step');
  const [, entityId] = useGetQRParameters();
  const [shouldModalOpen, openModal] = useModal();
  const [render] = useComponentAndPropsLight();
  const error = useSelector(getError);
  const isRecoveryProccess = modal === 'recovery' && step === 'password_reset';
  const isFileFullyLoaded = useSelector(getIsFileFullyLoaded);
  const entityContainer = useRef<HTMLDivElement>(null);

  const isAccessError = () => {
    return error.includes('403');
  };

  const accessError = isAccessError();

  const preventEventCall = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();

    return false;
  }, []);

  const onFetchFailed = useCallback(() => {
    dispatch(setEntityId(null));
    history.push(book.home);
  }, [dispatch]);

  useEffect(() => {
    if (!isNull(entityContainer.current)) {
      entityContainer.current.addEventListener('selectstart', preventEventCall);
    }
    document.addEventListener('contextmenu', preventEventCall);

    return () => {
      document.removeEventListener('contextmenu', preventEventCall);
    };
  }, [preventEventCall]);

  useEffect(() => {
    if (!isNull(entityId)) {
      dispatch(fetchDocumentPreview({ entityId, onFetchFailed }));
    }
  }, [dispatch, entityId, onFetchFailed]);

  useEffect(() => {
    if (isRecoveryProccess) {
      dispatch(
        setStep({
          step: WizardStepV2.ForgotPassword,
          stepNum: 1,
          maxSteps: 1,
        }),
      );
      dispatch(
        setQrCodeAccountRecoveryStep({
          qrCodeAccountRecoveryStep: QrCodeAccountRecoveryStep.PasswordRecovery,
        }),
      );
    }
  }, [dispatch, isRecoveryProccess]);

  useEffect(() => {
    if (isAuthenticated || isRecoveryProccess) {
      openModal();
    }
  }, [isAuthenticated, isRecoveryProccess, openModal]);

  useEffect(() => {
    if (isFileFullyLoaded && (!isAuthenticated || !isRecoveryProccess)) {
      const intervalID = setTimeout(() => {
        openModal();
      }, SHOW_MODAL_WINDOW_INTERVAL);

      return () => clearInterval(intervalID);
    }

    if (accessError) {
      openModal();
    }
  }, [openModal, isAuthenticated, isFileFullyLoaded, isRecoveryProccess, accessError]);

  const isModalShow = shouldModalOpen();

  return (
    <>
      <PublicLayout
        mainClassName="l-main--preview"
        header={<PreviewHeader className="l-header--preview" />}
        modal={
          <ModalView isActive={isModalShow} className={'c-modal__auth c-modal--w-600'}>
            <div className="c-modal__row">
              <div className="c-logo__auth">
                <Image className="icon icon--logo-docuwalk" src={docuwalkIcon} />
              </div>
            </div>
            <div className="c-modal__row c-modal--mt-24">
              <div className="c-logo__auth">
                <Image className="icon icon--logo-onereport" src={onereportIcon} />
              </div>
            </div>
            {render()}
          </ModalView>
        }
      >
        {title && <EntityTitle title={title} />}
        {(error || isModalShow) && (
          <div className="c-skeleton">
            <div className="c-skeleton__top"></div>
            <div className="c-skeleton__stripes">
              <div className="c-skeleton__img">
                <div className="c-skeleton__img--item">
                  <Picture />
                </div>
                <div className="c-skeleton__img--item">
                  <Picture />
                </div>
              </div>
            </div>
          </div>
        )}
        {!error && !isModalShow && (
          <div
            className="c-letter"
            onClickCapture={preventEventCall}
            onMouseDown={preventEventCall}
            ref={entityContainer}
          >
            <div className="c-letter__content">
              {!isNull(entityId) && (
                <EntityBody id={entityId} isNegotiable={false} isAllowedToEdit={false} />
              )}
            </div>
          </div>
        )}
      </PublicLayout>
    </>
  );
};
