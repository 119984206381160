import React from 'react';
import { useSelector } from 'react-redux';
import { translationKeys } from 'src/common/translations';

import i18n from 'src/i18n';
import { PaperParticipantsAffiliationType } from 'src/v2/boundary/storageModels/paper/paperParticipant';
import {
  getCounterpartyFields,
  getCounterpartySections,
  getCounterpartySideMembers,
  getCounterPartySideSignatures,
  hasCounterparty as getHasCounterparty,
  isCounterParty as getIsCounterParty,
} from 'src/v2/features/documentDetails/documentDetailsSlice';
import { useGetEntityFieldsDiff } from 'src/v2/features/sharedEntity/hooks';

import { ContractSide } from './ContractSide';
import { CounterpartyPlaceholder } from './CounterpartyPlaceholder';

interface Props {
  id: string;
}

export const ContractCounterpartySide: React.FC<Props> = ({ id }) => {
  const counterpartySideMembers = useSelector(getCounterpartySideMembers);
  const counterpartySections = useSelector(getCounterpartySections);
  const { counterpartyFieldsDiff } = useGetEntityFieldsDiff();
  const counterpartyFields = useSelector(getCounterpartyFields);
  const counterPartySideSignatures = useSelector(getCounterPartySideSignatures);
  const hasCounterparty = useSelector(getHasCounterparty);
  const isCounterParty = useSelector(getIsCounterParty);

  if (!hasCounterparty)
    return (
      <CounterpartyPlaceholder
        text={i18n(translationKeys.forms.contract.workflow.noCounterparty)}
      />
    );

  return (
    <ContractSide
      id={id}
      members={counterpartySideMembers}
      sections={counterpartySections}
      fields={counterpartyFields}
      diff={counterpartyFieldsDiff}
      negotiable={isCounterParty}
      signatures={counterPartySideSignatures}
      type={PaperParticipantsAffiliationType.counterparty}
    />
  );
};
