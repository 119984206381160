import { FC } from 'react';
import { useSelector } from 'react-redux';

import { ContractCard } from 'src/v2/features/contract/components/ContractCard';
import { CardPaperModel } from 'src/models/card';
import {
  useCreateDeleteContract,
  useCreateShowContractMetadataView,
} from 'src/v2/features/project/ProjectSidebar/nav-hooks';
import { ContractRole, ContractType } from 'src/models/contract';
import { useCreateNavigateToContractDetails, parseCardData } from 'src/v2/features/contract/utils';
import { ContextMenuBook } from 'src/app/types';
import { usePreventOperationForDeletedEntity } from 'src/v2/features/sharedEntity/hooks';

import { getDeletedEntityId } from '../store/projectSelectors';

interface Props {
  cardPaper: CardPaperModel<ContractType, ContractRole>;
  selected: boolean;
  projectId: string;
}

export const ProjectContractCard: FC<Props> = ({ cardPaper, projectId, selected }) => {
  const createHandleOpenDocument = useCreateNavigateToContractDetails();
  const createHandleDelete = useCreateDeleteContract(cardPaper, projectId);
  const handleDelete = createHandleDelete();
  const viewDocumentMeta = useCreateShowContractMetadataView({ id: cardPaper.id });
  const deletedEntityId = useSelector(getDeletedEntityId);
  const preventOperationForDeletedContract = usePreventOperationForDeletedEntity(deletedEntityId);

  return (
    <ContractCard
      contextMenu={ContextMenuBook.ContractProject}
      data={parseCardData(cardPaper)}
      onClick={preventOperationForDeletedContract(cardPaper.id, viewDocumentMeta)}
      onDelete={preventOperationForDeletedContract(cardPaper.id, handleDelete)}
      onDoubleClick={preventOperationForDeletedContract(
        cardPaper.id,
        createHandleOpenDocument(cardPaper.id),
      )}
      selected={selected}
    />
  );
};
