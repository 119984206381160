import React, { useCallback, ReactNode } from 'react';

import { SearchEntityType } from 'src/v2/features/search/store';

import { ShowMore } from 'src/v2/icons/ShowMore';
import { isUndefined } from 'lodash';

type Props = {
  title: string;
  count: number;
  type: SearchEntityType;
  children: ReactNode;
  onSeeMoreClick?: (type: SearchEntityType) => void;
};

export const CardsList: React.FC<Props> = ({
  title,
  children,
  type,
  count = 0,
  onSeeMoreClick,
}) => {
  const handleSeeMoreClick = useCallback(() => {
    if (onSeeMoreClick && type) onSeeMoreClick(type);
  }, [onSeeMoreClick, type]);

  return (
    <>
      <div className="l-row l-row--flex l-row--flex-wrap l-row--align-center">
        <div className="c-title c-title--uppercase c-title--fs-18 c-title--lh-1">{title}</div>
        <div className="c-title__count">{count}</div>
        {type && count > 5 && !isUndefined(onSeeMoreClick) && (
          <div className="c-title__see">
            <div className="c-title__see--link" onClick={handleSeeMoreClick}>
              See More
              <ShowMore />
            </div>
          </div>
        )}
      </div>
      <div className="l-row l-row--mt-16">{children}</div>
    </>
  );
};
