import { FC, useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, formValueSelector, InjectedFormProps } from 'redux-form';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { BillingPeriod } from 'src/models/billing';
import { RootState } from 'src/app/types';
import { Button } from 'src/v2/features/button';
import { Select } from 'src/v2/features/reduxForm';
import { getPlanAndPeriodOptions, getPlanAndPeriod } from 'src/v2/features/checkout/utils';
import { useIsChangeablePeriod } from 'src/v2/features/organization/hooks';

import { BillingPlanFormValues, BillingMigrationCallback } from './types';
import { useSubscribeToUserNeedUpgradePlan } from './hooks';
import { getSubscribedPlan, getSubscribedPeriod, submitBillingPlan, getIsLoading } from './store';

interface BillingPlanEditable {
  isPlanEditable: boolean;
}

type BillingPlanFormProps = BillingPlanEditable & BillingMigrationCallback;

const FORM_ID = 'billingPlan';
const formSelector = formValueSelector(FORM_ID);

export const BillingPlan: FC<
  InjectedFormProps<BillingPlanFormValues, BillingPlanFormProps> & BillingPlanFormProps
> = ({ handleSubmit, autofill, children, onMigration, isPlanEditable }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const preselectedPlan = useSelector(getSubscribedPlan);
  const preselectedPeriod = useSelector(getSubscribedPeriod);
  const planAndPeriod = useSelector((state: RootState) => formSelector(state, 'planAndPeriod'));
  const { plan, period } = useMemo(() => getPlanAndPeriod(planAndPeriod), [planAndPeriod]);
  const isChangeablePeriod = useIsChangeablePeriod();

  const planAndPeriodOptions = useMemo(
    () => getPlanAndPeriodOptions(preselectedPlan, preselectedPeriod),
    [preselectedPeriod, preselectedPlan],
  );

  useSubscribeToUserNeedUpgradePlan((event) => {
    onMigration({ plan: event.payload.plan, period: event.payload.period });
  });

  useEffect(() => {
    if (planAndPeriodOptions[0]) {
      autofill('planAndPeriod', planAndPeriodOptions[0].value);
    }
  }, [autofill, planAndPeriodOptions]);

  const submitForm = useCallback(
    (values: BillingPlanFormValues) => {
      if (!plan || !period || isLoading) return;
      if (isChangeablePeriod && isPlanEditable) {
        dispatch(submitBillingPlan({ planAndPeriod: values.planAndPeriod }));
      }
    },
    [plan, period, isLoading, isChangeablePeriod, isPlanEditable, dispatch],
  );

  const isAnnuallyPlan = preselectedPeriod === BillingPeriod.Annually;

  return (
    <form className="c-setup c-setup--bg-transparent" onSubmit={handleSubmit(submitForm)}>
      <div className="c-setup__content">
        <div className="c-form">
          {isAnnuallyPlan && (
            <div className="c-form__row">
              <div className="c-form__column">
                <div className="c-select">
                  {i18n(translationKeys.forms.billing.annualPlanMessage)}
                </div>
              </div>
            </div>
          )}
          <div className="c-form__row">
            <div className="c-form__column">
              <div className="c-select">
                {isChangeablePeriod && isPlanEditable && (
                  <Field component={Select} name="planAndPeriod" options={planAndPeriodOptions} />
                )}
              </div>
              <div className="c-form__row">{children}</div>
            </div>
          </div>
          {isChangeablePeriod && isPlanEditable && (
            <div className="c-form__row c-form--justify-center">
              <Button
                type="submit"
                className="c-button c-button--fs-12"
                isLoading={isLoading}
                disabled={isLoading}
              >
                {i18n(translationKeys.buttons.update)}
              </Button>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export const BillingPlanForm = reduxForm<BillingPlanFormValues, BillingPlanFormProps>({
  form: FORM_ID,
  destroyOnUnmount: false,
})(BillingPlan);
