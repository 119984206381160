import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNull } from 'lodash';

import { editProject } from 'src/v2/features/project/store/projectReducers';
import { CreateProjectDTO } from 'src/v2/boundary/requestDTO/project';
import { ProjectMetadataEdit } from '../../components/ProjectMetadataEdit';
import { getProjectByIdFn, getIsLoading } from '../../store/projectSelectors';

interface Props {
  id: string;
}

export const EditProject: FC<Props> = ({ id }) => {
  const getProjectById = useSelector(getProjectByIdFn);
  const isLoading = useSelector(getIsLoading);
  const project = getProjectById(id);
  const dispatch = useDispatch();
  const handleEdit = useCallback(
    (data: CreateProjectDTO) => {
      dispatch(
        editProject({
          ...data,
          id,
        }),
      );
    },
    [dispatch, id],
  );

  if (isNull(project)) return null;

  return (
    <ProjectMetadataEdit
      title={project.title}
      description={project.description}
      isLoading={isLoading}
      onSubmit={handleEdit}
    />
  );
};
