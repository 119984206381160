import { PaperParticipantsAffiliationType } from 'src/v2/boundary/storageModels/paper/paperParticipant';
// import i18n from 'src/i18n';
// import {translationKeys} from "../../../../common/translations";

// const ownerSide = i18n(translationKeys.contract.ownerSide)
// const counterpartySide = i18n(translationKeys.contract.counterpartySide)

export const affiliationOptions = [
  { label: '' },
  { value: PaperParticipantsAffiliationType.owner, label: 'Owner side' },
  { value: PaperParticipantsAffiliationType.counterparty, label: 'Counterparty side' },
];
