// Core
import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isString } from 'lodash';
import { toastr } from 'react-redux-toastr';

// Components
import { Avatar } from 'src/v2/components/Avatar';
import { setOrganizationLogo } from 'src/v2/features/organization';
import { ProfilePage } from 'src/v2/features/profile';
import { FormHeader } from 'src/v2/features/signUp/components/FormHeader';
import {
  getCurrentOrganizationLogo,
  getCurrentOrganizationUserSystemRole,
  getIsLoading,
} from 'src/shared/organization';
import { handleFileChange, dataURLtoFile, readImageFile, changeImageHash } from 'src/utils/files';
import { canEditOrganizationInfo } from 'src/utils/permissions';
import { ImageEditor } from 'src/v2/components/ImageEditor/ImageEditor';
import { AVATAR_SUPPORTED_UPLOAD_FORMATS } from 'src/v2/features/profile/constants';
import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

import { OrganizationForm } from './index';
import { useAuthAndFillOrganization } from '../hooks';
import { MimeType } from '../../../../common/types';

const supportedUploadFormats = AVATAR_SUPPORTED_UPLOAD_FORMATS.join(',');

export const OrganizationInfo: React.FC = () => {
  const dispatch = useDispatch();
  const [imageFile, setImageFile] = useState<string | ArrayBuffer | null>(null);

  const userRole = useSelector(getCurrentOrganizationUserSystemRole);
  const authUser = useAuthAndFillOrganization();
  const organizationLogo = useSelector(getCurrentOrganizationLogo);

  const onUpdateOrganizationLogo = useCallback(
    (file: File) => {
      dispatch(
        setOrganizationLogo({
          file,
          user: authUser || { userId: '', companyId: '', token: '' },
        }),
      );
    },
    [authUser, dispatch],
  );

  const isLoading = useSelector(getIsLoading);
  const isAbleToEditOrgInfo = canEditOrganizationInfo(userRole);

  const setLogoFile = useCallback((files: File): void => {
    !AVATAR_SUPPORTED_UPLOAD_FORMATS.includes(files.type as MimeType)
      ? toastr.error(` ${i18n(translationKeys.errors.FILE_FORMAT_ERROR)}`, '')
      : readImageFile(files).then((image: HTMLImageElement): void => {
          setImageFile(changeImageHash(image.src));
        });
  }, []);

  const setLogoImage = useCallback(
    (dataURL: string): void => {
      if (dataURL.length) {
        const imageFile: File = dataURLtoFile(dataURL);
        onUpdateOrganizationLogo(imageFile);
        setImageFile(null);
      }
    },
    [onUpdateOrganizationLogo],
  );

  const handleImageSaved = useCallback(
    (dataURL: string): void => {
      setLogoImage(dataURL);
    },
    [setLogoImage],
  );

  const handleFileClick = useCallback((event: React.MouseEvent<HTMLInputElement>) => {
    event.currentTarget.value = '';
  }, []);

  return (
    <ProfilePage>
      <div className="c-setup c-setup--bg-transparent" data-id="profile-container-personalInfo">
        <div className="c-setup__top">
          <FormHeader
            title={i18n(translationKeys.organization.informationTitle)}
            description={i18n(translationKeys.organization.informationText)}
          />
          <div className="c-setup__content">
            <OrganizationForm userRole={userRole} />
            {isAbleToEditOrgInfo && (
              <>
                {(!imageFile || isString(imageFile)) && (
                  <div className="l-row">
                    <div className="c-form__row">
                      <div className="c-title c-title--color-theme c-title--fs-24">
                        {i18n(translationKeys.organization.customizedBrandingTitle)}
                      </div>
                    </div>
                    <div className="l-row l-row--mt-16">
                      <div className="c-title c-title--lh-16 c-title--fw-400">
                        {i18n(translationKeys.organization.customizedBrandingText)}
                      </div>
                    </div>
                  </div>
                )}
                {imageFile && isString(imageFile) && (
                  <div className="l-row">
                    <ImageEditor
                      src={imageFile}
                      style={{ height: 192, width: '100%' }}
                      onSave={handleImageSaved}
                    />
                  </div>
                )}
                <div className="l-row l-row--flex l-row--mt-16">
                  <div className="c-setup__user c-setup__user--upload">
                    <input
                      type="file"
                      accept={supportedUploadFormats}
                      onChange={handleFileChange(setLogoFile)}
                      onClick={handleFileClick}
                    />
                    {organizationLogo && <Avatar src={organizationLogo} />}
                    <span className="c-setup__add">
                      {isLoading
                        ? i18n(translationKeys.messages.loading)
                        : i18n(translationKeys.buttons.addLogo)}
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </ProfilePage>
  );
};
