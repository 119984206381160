import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';

type Props = {
  numberOfMembers: number;
  numberOfTotalSeats: number;
};

export const SeatsInformation: React.FC<Props> = ({ numberOfMembers, numberOfTotalSeats }) => (
  <>
    <div className="l-row">
      <b>
        {i18n(translationKeys.organization.numberOfTotalSeats, {
          numberOfTotalSeats,
        })}
      </b>
    </div>
    <div className="l-row l-row--mt-16">
      <b>
        {i18n(translationKeys.organization.numberOfRemainingSeats, {
          numberOfRemainingSeats: numberOfMembers,
        })}
      </b>
    </div>
  </>
);
