import React, { FC, ComponentType } from 'react';
import { pick } from 'lodash';

export function withProps<P, X>(
  mapper: (props: P) => Partial<X>,
): (C: ComponentType<P>) => FC<P & X> {
  return (C: ComponentType<P>) => (props: P) => <C {...props} {...mapper(props)} />;
}

export function withInitialValues<F, P>(fields: string[], srcMapper?: (props: P) => Partial<P>) {
  return withProps<P, { initialValues: F }>((props: P) => ({
    initialValues: pick(srcMapper ? srcMapper(props) : props, fields) as F,
  }));
}
