import { parseCardPaperDataFactory } from 'src/v2/features/sharedEntity/utils';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { isUndefined } from 'lodash';
import { History } from 'history';
import { useDispatch } from 'react-redux';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import { book } from 'src/app/book';
import {
  TemplateBaseModel,
  TemplateCompleteModel,
  TemplateModel,
  TemplateParticipantModel,
  TemplateRole,
} from 'src/models/template';
import { TemplateEntityModel } from 'src/models/entity';
import { SidebarParticipantEntity } from 'src/v2/entities/participants';
import defaultAvatar from 'src/assets/placeholders/user-avatar.png';

import { fetchTemplate } from './reducer';
import { getFullName } from '../document/selectors';

export const parseCardData = parseCardPaperDataFactory<TemplateRole>(
  i18n(translationKeys.forms.template.create.defaultTitle),
  TemplateRole.Owner,
);

export const createNavigateToTemplateDetails =
  (history: History) => (id: string, folderId?: string) => {
    history.push(book.template.generatePath(id, folderId));
  };

export const useCreateNavigateToTemplateDetails = (
  folderId?: string,
): ((id: string) => () => void) => {
  const navigate = useNavigate();

  return useCallback(
    (id: string) => (): void => {
      navigate(book.template.generatePath(id, folderId));
    },
    [navigate, folderId],
  );
};

export const useCreateGetTemplateInfo = (): ((id: string) => () => void) => {
  const dispatch = useDispatch();

  return useCallback(
    (id: string) => (): void => {
      dispatch(fetchTemplate(id));
    },
    [dispatch],
  );
};

export const convertTemplateCompleteModelToBaseModel = (
  template: TemplateCompleteModel,
): TemplateBaseModel => {
  return {
    paperType: template.paperType,
    type: template.type,
    contentType: template.contentType,
    city: template.city,
    country: template.country,
    createdAt: template.createdAt,
    description: template.description,
    entityId: template.entityId,
    industry: template.industry,
    province: template.province,
    state: template.state,
    subType: template.subType,
    templateId: template.templateId,
    title: template.title,
    file: template.file,
    updatedAt: template.updatedAt,
  };
};

export const convertTemplateCompleteModelToTemplateModel = (
  template: TemplateCompleteModel,
  userId: string,
): TemplateModel => {
  const makeError = (text: string) => {
    return Error(`convertTemplateCompleteModelToTemplateModel: ${text}`);
  };

  if (isUndefined(template.templateParticipant))
    throw makeError('templateParticipants are undefined');

  const participants = template.templateParticipant.map((participant): TemplateParticipantModel => {
    if (isUndefined(participant.templateUser)) throw makeError('templateUser is undefined');
    const { user_id, created_at, first_name, last_name, email, avatar } =
      participant.templateUser[0];

    return {
      ...participant,
      userId: user_id,
      createdAt: created_at,
      firstName: first_name,
      lastName: last_name,
      avatar: avatar || defaultAvatar,
      email,
    };
  });

  const ownerParticipant = participants.find(
    (participant) => participant.roleId === TemplateRole.Owner,
  );

  if (isUndefined(ownerParticipant)) throw makeError('user owner not found in users');

  const currentUserParticipant = participants.find((participant) => {
    return participant.userId === userId;
  });

  if (isUndefined(currentUserParticipant))
    throw makeError('current user is not found in participants');

  if (isUndefined(template.entity)) throw makeError('entity is undefined');

  const normalizedEntities = template.entity
    .map((currentEntity) => {
      let { field } = currentEntity;
      let { lock } = currentEntity;
      if (isUndefined(currentEntity.field)) {
        field = [];
      }

      if (isUndefined(currentEntity.lock)) {
        lock = [];
      }
      return { ...currentEntity, field, lock };
    })
    .filter((currentEntity): currentEntity is TemplateEntityModel => {
      if (isUndefined(currentEntity.tree)) {
        throw makeError('entity tree undefined');
      }

      return true;
    });

  return {
    ...template,
    ownerParticipant,
    currentUserParticipant,
    participants,
    entities: normalizedEntities,
  };
};

export const convertTemplateParticipantsToParticipantModel = (
  templateParticipants: TemplateParticipantModel[],
): SidebarParticipantEntity<TemplateRole>[] => {
  const participants = templateParticipants.map(
    (templateParticipant): SidebarParticipantEntity<TemplateRole> => {
      return {
        ...templateParticipant,
        id: templateParticipant.participantId,
        funcRole: '',
        phone: '',
      };
    },
  );

  return participants;
};

export const canEditTemplateMetaData = (template: TemplateModel | undefined): boolean => {
  if (template) {
    return template.currentUserParticipant.roleId !== TemplateRole.Viewer;
  }
  return false;
};

export const getOwnerName = (data: TemplateModel): string =>
  getFullName(data.ownerParticipant.firstName, data.ownerParticipant.lastName);

export const getShownRole = (roleId: TemplateRole): string => {
  switch (roleId) {
    case TemplateRole.Owner:
      return 'Owner';
    case TemplateRole.Viewer:
      return 'Viewer';
    default:
      return 'Unknown';
  }
};
