import React, { useContext } from 'react';
import { isString, isUndefined } from 'lodash';

import { SidebarSettingsContext } from 'src/common/contexts/SidebarSettingsContext';
import { SidebarSettings } from 'src/common/types';
import { EntitySidebar } from 'src/v2/features/sharedEntity';
import { SharedDocumentSidebarContextProvider } from 'src/v2/features/document/DocumentSidebar/SharedDocumentSidebarContextProvider';
import { useNormalizeURLHashValues } from 'src/v2/features/sharedEntity/EntitySidebar/EntitySidebar';
import { book } from 'src/v2/features/sharedEntity/EntitySidebar/book';

import { DocumentNineSquareMenu } from './pages/NineSquareMenu';
import { sidebarController, sidebarName } from './routes';

export const [useIsOpen, links, pushes, Sidebar] = sidebarController;

export const { useOpen, useClose } = links;

export const DocumentSidebar: React.FC = () => {
  const { isHidden } = useContext<SidebarSettings>(SidebarSettingsContext);
  const criteria = useNormalizeURLHashValues(book.default);
  const { id } = criteria;

  if (!isString(id)) {
    if (!isUndefined(id)) console.warn(`ID is not string, ${typeof id}`, id);

    return (
      <EntitySidebar
        sidebarName={sidebarName}
        sidebarComponent={Sidebar}
        isHidden={isHidden}
        nineSquareMenuComponent={DocumentNineSquareMenu}
      />
    );
  }

  return (
    <SharedDocumentSidebarContextProvider id={id}>
      <EntitySidebar
        sidebarName={sidebarName}
        sidebarComponent={Sidebar}
        isHidden={isHidden}
        nineSquareMenuComponent={DocumentNineSquareMenu}
      />
    </SharedDocumentSidebarContextProvider>
  );
};
