// Core
import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
// Components
import { Switch } from 'src/v2/components/Switch';

import { getNotificationsSettingsAction } from './store';
import { FormHeader } from './components/FormHeader';

// TODO Uncomment after Playsound and Banner  get implememented in system
// import { mergeStyles } from 'src/utils/styles';
// import { NotificationBanner } from './components/NotificationBanner';

interface Props {
  onSettingChange: (name: string, checked: boolean) => void;
  sendEmail: boolean;
  sendText: boolean;
  // receiveMessages: boolean;
  playSound: boolean;
  banner: boolean;
  isLoading?: boolean;
}

export const NotificationsSettings: React.FC<Props> = ({
  onSettingChange,
  sendEmail,
  sendText,
  // playSound,
  // banner,
  isLoading,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNotificationsSettingsAction());
  }, [dispatch]);

  const handleSettingsChange = useCallback(
    (type: string) =>
      (value: boolean): void =>
        onSettingChange(type, value),
    [onSettingChange],
  );

  return (
    <div>
      <FormHeader title={i18n(translationKeys.screens.notificationScreen.notification)} />
      <div className="c-setup__content" data-id="signup-form-notifications">
        <div className="c-setup__notification">
          <div className="c-setup__notification--block">
            <div className="c-setup__notification--row">
              <div className="c-setup__notification--title">
                {i18n(translationKeys.screens.notificationScreen.pushNotifications)}
              </div>
            </div>
            <div className="c-setup__notification--row">
              <div className="c-setup__notification--text">
                {i18n(translationKeys.screens.notificationScreen.sendEmailMessage)}
              </div>
              <div className="c-switch">
                <Switch
                  name="sendEmail"
                  checked={sendEmail}
                  onChange={handleSettingsChange('mail')}
                  disabled={isLoading}
                />
              </div>
            </div>
            <div className="c-setup__notification--row">
              <div className="c-setup__notification--text">
                {i18n(translationKeys.screens.notificationScreen.sendSmsMessage)}
              </div>
              <div className="c-switch">
                <Switch
                  name="sendText"
                  checked={sendText}
                  onChange={handleSettingsChange('sms')}
                  disabled={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/** TODO Uncomment after Playsound and Banner  get implememented in system */}
      {/* <Heading sx={notificationsStyles.group.heading}>Display Notifications</Heading>
        <Box sx={mergeStyles(notificationsStyles.group.row, { mb: '20px' })}>
          <Box>
            <Text sx={mergeStyles(notificationsStyles.text, { mb: 0 })}>Banner</Text>
            <NotificationBanner />
          </Box>

          <Switch
            name="banner"
            checked={banner}
            onChange={(checked: boolean): void => onSettingChange('banner', checked)}
            disabled={!receiveMessages || true}
          />
        </Box>

        <Heading sx={notificationsStyles.group.heading}>Sounds</Heading>
        <Box sx={notificationsStyles.group.row}>
          <Text sx={notificationsStyles.text}>Play sound when you receive a new message</Text>
          <Switch
            name="playSound"
            checked={playSound}
            onChange={(checked: boolean): void => onSettingChange('playSound', checked)}
            disabled={!receiveMessages || true}
          />
        </Box> */}
    </div>
  );
};
