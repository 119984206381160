import React from 'react';
import { Flex } from '@theme-ui/components';

import { UserAcknowledge } from 'src/models/profile';
import { Acknowledge } from 'src/v2/components/Acknowledge';
import { getAvatarUrl } from 'src/v2/features/profile';

interface Props {
  users: UserAcknowledge[];
}

export const AcknowledgesList = ({ users }: Props): JSX.Element => (
  <Flex sx={{ flexWrap: 'wrap' }}>
    {users.map((user) => (
      <Acknowledge
        key={user.userId}
        avatarUrl={getAvatarUrl(user.avatarUrl)}
        userName={`${user.firstName} ${user.lastName}`}
      />
    ))}
  </Flex>
);
