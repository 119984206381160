import React from 'react';
import { isUndefined } from 'lodash';

import { useGetEntityCurrentFolderId } from 'src/v2/features/sharedEntity/hooks';
import { isDocumentFolder } from 'src/v2/features/quantumNavigator';
import { AbstractCreate } from 'src/v2/features/document/DocumentSidebar/pages/AbstractCreate';
import { createConvertFormPayloadToDTO } from 'src/v2/features/document/DocumentSidebar/utils';
import { createDocument } from 'src/v2/features/documentList/documentListSlice';
import { createDocumentFromFile } from 'src/v2/features/documentSidebar/documentSidebarUpload/documentSidebarUploadStore';
import { createDocumentFromTemplate } from 'src/v2/features/createFromTemplate/actions';

export const Create: React.FC = () => {
  const id = useGetEntityCurrentFolderId(isDocumentFolder);
  if (isUndefined(id)) return null;

  return (
    <AbstractCreate
      convertFormPayloadToDTO={createConvertFormPayloadToDTO(id)}
      createDocument={createDocument}
      createDocumentFromFile={createDocumentFromFile}
      createDocumentFromTemplate={createDocumentFromTemplate}
    />
  );
};
