import cn from 'classnames';

import { Visibility } from 'src/v2/icons/Visibility';
import { VisibilityOff } from 'src/v2/icons/VisibilityOff';
import { useToggle } from 'src/common/hooks/useToggle';

import { InputProps } from '../types';
import { WithValidationMessage } from '../WithValidationMessage';

const classModifiers = {
  disabled: 'c-input--disabled',
  readOnly: 'c-input--readonly',
  mb8: 'c-input--mb-8',
};

export const Password = ({
  errorMessage,
  successMessage,
  className,
  mb8 = false,
  ...inputProps
}: InputProps): JSX.Element => {
  const { disabled, readOnly } = inputProps;

  const [isPasswordShown, toggle] = useToggle(false);
  const passwordClass = isPasswordShown ? 'c-input__icon--show' : '';

  return (
    <WithValidationMessage
      className={cn('c-input c-input--password', className, {
        [classModifiers.disabled]: disabled,
        [classModifiers.readOnly]: readOnly,
        [classModifiers.mb8]: mb8,
      })}
      errorMessage={errorMessage}
      successMessage={successMessage}
      classPrefix="c-input"
    >
      <label className="c-input__label">
        <span className={`c-input__icon c-input__icon--right ${passwordClass}`} onClick={toggle}>
          <VisibilityOff />
          <Visibility />
        </span>
        <input
          {...inputProps}
          type={isPasswordShown ? 'text' : 'password'}
          autoComplete="new-password"
        />
      </label>
    </WithValidationMessage>
  );
};
