import { FC } from 'react';

import { DynamicSettingsContext } from 'src/v2/modules/layout';
import { DynamicSettings } from 'src/v2/modules/layout/types';
import { useShowMetadataCreate, useToggleQRCode } from 'src/v2/features/sharedEntity';
import { useCanCreateDocument } from 'src/v2/features/document/permissions/documentPermissions/documentPermissionsHooks';

import { useNotificationTogglePush, useToggle9Square } from '../DocumentSidebar';
import { DocumentQuantumNavigator } from '../DocumentQuantumNavigator';
import { ListHeader } from '../components/ListHeader';
import { useHasQRCodeFeature } from '../../billing';

export const DynamicSettingsContextProviderForList: FC = ({ children }) => {
  const toggleNotifications = useNotificationTogglePush();
  const toggle9squareSidebar = useToggle9Square();
  const toggleQRCodeSidebar = useToggleQRCode();
  const showMetadataCreate = useShowMetadataCreate();
  const isCanCreateDocument = useCanCreateDocument();
  const handleOnPlusClick = isCanCreateDocument ? showMetadataCreate : undefined;
  const hasQRCodeFeature = useHasQRCodeFeature();

  const ctx: DynamicSettings = {
    headerChildren: <ListHeader />,
    onBellClick: toggleNotifications,
    onGridClick: toggle9squareSidebar,
    onQRCodeClick: toggleQRCodeSidebar,
    onPlusClick: handleOnPlusClick,
    navigatorChildren: <DocumentQuantumNavigator />,
    hasQRCodeFeature,
  };

  return <DynamicSettingsContext.Provider value={ctx}>{children}</DynamicSettingsContext.Provider>;
};
