import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isUndefined, isNull, find, get } from 'lodash';

import { PaperParticipantModel } from 'src/models/paper';
import { DocumentType, DocumentRole } from 'src/models/document';
import { useGetEntityById } from 'src/v2/features/sharedEntity/hooks';
import { getAcknowledges } from 'src/v2/features/documentDetails/documentDetailsSlice';
import { useGetCurrentUserRoleByEntity } from 'src/v2/features/sharedEntity';
import {
  canEditUser,
  canRemoveUser,
} from 'src/v2/features/document/permissions/manageParticipantsPermissions/manageParticipantsPermissions';
import { getUserId } from 'src/shared/auth';
import { isParticipantInvitedByUser } from 'src/v2/features/sharedEntity/utils';

export const useCanManageParticipantAcknowledge = (participantId: string | undefined): boolean => {
  const acknowledges = useSelector(getAcknowledges);
  if (isUndefined(participantId)) {
    return true;
  }
  const isParticipantAcknowledged = acknowledges.find(({ userId }) => userId === participantId);

  return isUndefined(isParticipantAcknowledged);
};

export const useCanEditParticipant = (documentId: string): ((participantId: string) => boolean) => {
  const document = useGetEntityById<DocumentType, DocumentRole>(documentId);
  const currentUserRole = useGetCurrentUserRoleByEntity<DocumentRole>(document);
  const myUserId = useSelector(getUserId);

  const canEditParticipant = useCallback(
    (participantId: string): boolean => {
      if (isNull(document)) return false;
      const paperParticipants = get(
        document,
        'paperParticipant',
        [],
      ) as PaperParticipantModel<DocumentRole>[];
      // TODO: D20-1732
      // const { paperParticipant } = document;
      const participant = find(paperParticipants, ({ userId }) => userId === participantId);
      if (isNull(currentUserRole) || isNull(myUserId) || isUndefined(participant)) return false;

      const currentUser = {
        id: myUserId,
        role: currentUserRole,
      };
      const { roleId: role } = participant;
      const invitedByMe = isParticipantInvitedByUser<DocumentRole>(participant, myUserId);

      return canEditUser(currentUser, { id: participantId, role }, invitedByMe);
    },
    [currentUserRole, document, myUserId],
  );

  return canEditParticipant;
};

export const useCanRemoveParticipant = (
  documentId: string,
): ((participantId: string) => boolean) => {
  const document = useGetEntityById<DocumentType, DocumentRole>(documentId);
  const currentUserRole = useGetCurrentUserRoleByEntity<DocumentRole>(document);
  const myUserId = useSelector(getUserId);

  const canEditParticipant = useCallback(
    (participantId: string): boolean => {
      if (isNull(document)) return false;
      const paperParticipants = get(
        document,
        'paperParticipant',
        [],
      ) as PaperParticipantModel<DocumentRole>[];
      // TODO: D20-1732
      // const { paperParticipant } = document;
      const participant = find(paperParticipants, ({ userId }) => userId === participantId);
      if (isNull(currentUserRole) || isNull(myUserId) || isUndefined(participant)) return false;

      const currentUser = {
        id: myUserId,
        role: currentUserRole,
      };
      const { roleId: role } = participant;

      return canRemoveUser(currentUser, { id: participantId, role });
    },
    [currentUserRole, document, myUserId],
  );

  return canEditParticipant;
};
