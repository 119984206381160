import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isUndefined } from 'lodash';

import { book } from 'src/app/book';
import { fetchProjectDetails } from 'src/v2/features/project/store/projectReducers';

export const useCreateOpenProjectHandler = () => {
  const navigate = useNavigate();

  return useCallback(
    (id: string) => (): void => {
      navigate(book.project.generatePath(id));
    },
    [navigate],
  );
};

export const useFetchProjectByIdEffct = (id: string | undefined) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isUndefined(id)) {
      dispatch(fetchProjectDetails(id));
    }
  }, [id, dispatch]);
};
