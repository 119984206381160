import React, { FC } from 'react';
import { Field } from 'redux-form';

import i18n from 'src/i18n';
import { translationKeys } from 'src/common/translations';
import ReduxFormPhone from 'src/v2/components/Form/ReduxFormPhone';
import { PHONE_MASKS } from 'src/v2/components/PhoneNumber/types';

import { Input } from '../reduxForm';

export interface Props {
  isDisabled: boolean;
}

export const UserFieldset: FC<Props> = ({ isDisabled }) => {
  return (
    <div className="c-fieldset__group">
      <Field
        component={Input}
        name="firstName"
        placeholder={i18n(translationKeys.forms.userFields.firstName)}
        disabled={isDisabled}
        mb8
      />
      <Field
        component={Input}
        name="lastName"
        placeholder={i18n(translationKeys.forms.userFields.lastName)}
        disabled={isDisabled}
        mb8
      />
      <Field
        props={{
          placeholder: '+1-650-450-1212',
          autocomplete: 'tel',
        }}
        component={ReduxFormPhone}
        masks={PHONE_MASKS}
        name="phone"
        placeholder={i18n(translationKeys.forms.userFields.mobile)}
        disabled={isDisabled}
        mb8
      />
    </div>
  );
};
