import { Subscription } from 'rxjs';

import { eventBusFactory } from 'src/app/eventBus';

import {
  CreateProjectPayload,
  ProjectEvent,
  ProjectEventData,
  ProjectEventDataWithPayload,
} from './types';

const eventBusApi = eventBusFactory<ProjectEventData>();

const { emit, subscribeToEvent } = eventBusApi;

export const emitCreateProject = ({ id }: CreateProjectPayload): void =>
  emit({ type: ProjectEvent.CreateProject, payload: { id } });
export const subscribeCreateProject = (
  callback: (eventData: ProjectEventDataWithPayload) => void,
): Subscription =>
  subscribeToEvent<ProjectEventDataWithPayload>(ProjectEvent.CreateProject, callback);
