import React, { FC } from 'react';

import { DynamicSettingsContext, HeaderItem } from 'src/v2/modules/layout';
import { DynamicSettings } from 'src/v2/modules/layout/types';
import { useToggle9Square } from 'src/v2/features/sharedEntity';

import { TemplateQuantumNavigator } from '../TemplateQuantumNavigator';
import { useNotificationTogglePush } from '../TemplateSidebar';

export const DynamicSettingsContextProviderForShared: FC = ({ children }) => {
  const toggleNotifications = useNotificationTogglePush();
  const toggle9squareSidebar = useToggle9Square();

  const ctx: DynamicSettings = {
    headerChildren: <HeaderItem />,
    onBellClick: toggleNotifications,
    onGridClick: toggle9squareSidebar,
    navigatorChildren: <TemplateQuantumNavigator />,
  };

  return <DynamicSettingsContext.Provider value={ctx}>{children}</DynamicSettingsContext.Provider>;
};
