import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getIsAuthenticated } from 'src/shared/auth/authSelectors';
import PrivateServices from 'src/app/PrivateServices';
import { Private } from 'src/app/Private';
import { Public } from 'src/app/Public';

export const Routes: FC = () => {
  const isAuthenticated = useSelector(getIsAuthenticated);

  return isAuthenticated ? (
    <PrivateServices>
      <Private />
    </PrivateServices>
  ) : (
    <Public />
  );
};
